import {
  Toolbar,
} from '@material-ui/core';

import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import { MainMenu } from "../../screens/mainMenu";
import { SpreadSelectionScreen } from "../../screens/spreadSelection";
import { SpreadScreen } from "../../screens/spread";
import { ReaderScreen } from "../../screens/reader";
import { ReadersScreen } from "../../screens/readers";
import { SignupScreen } from "../../screens/signup";
import { ProfileScreen } from "../../screens/profile";
import { ConversationsScreen } from "../../screens/conversations";
import { ConversationDetailsScreen } from "../../screens/conversations/details";
import AppRedirectScreen from "../../screens/appRedirectScreen";


function Content () {
  const location = useLocation();

  const redirectToHome = () => (
    <Redirect to={`/`} />
  )

  let baseBir = location.pathname.split("/").filter(x => !!x)[0];

  return (
    <TransitionGroup className="container">
      <CSSTransition timeout={250} classNames="screen" key={baseBir}>
        <Switch location={location}>
            <Route key="decisionscreen" exact path="/" component={MainMenu} />
            <Route key="spreads" exect path={`/spreads/:decktype?`} component={SpreadSelectionScreen} />
            <Route key="spread" path={`/spread/:spread/:decktype`} component={SpreadScreen} />
            <Route key="readers" path={`/readers/`} component={ReadersScreen} />
            <Route key="reader" path={`/reader/:consultantId`} component={ReaderScreen} />
            <Route key="signup" path={`/signup`} component={SignupScreen} />
            <Route key="profile" path={`/profile`} component={ProfileScreen} />
            <Route key="conversations" path={`/conversations`} component={ConversationsScreen} />
            <Route key="conversation" path={`/conversation/:conversationId`} component={ConversationDetailsScreen} />           
            <Route key="store" path="/store" component={AppRedirectScreen} /> 
            
            <Route component={redirectToHome} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

export const MainApplication = () => {
  return (
    <main>
      <tablet><Toolbar /></tablet>
      <Content />      
    </main>
  );
};

