import React, { Component } from 'react';
import { DeckCards, Decks, Spreads } from '../../../constants';
import Tweezer from 'tweezer.js'

import Card from './Card';
import { ContactMailTwoTone } from '@material-ui/icons';

const minHandHeight = 100;
const idealHandHeight = 175;

//calculate the center
function calculateCircleCenter(A,B,C)
{
    var yDelta_a = B.y - A.y;
    var xDelta_a = B.x - A.x;
    var yDelta_b = C.y - B.y;
    var xDelta_b = C.x - B.x;

    var center = {};

    var aSlope = yDelta_a / xDelta_a;
    var bSlope = yDelta_b / xDelta_b;

    center.x = (aSlope*bSlope*(A.y - C.y) + bSlope*(A.x + B.x) - aSlope*(B.x+C.x) )/(2* (bSlope-aSlope) );
    center.y = -1*(center.x - (A.x+B.x)/2)/aSlope +  (A.y+B.y)/2;
    return center;
}

//this should be cached for optimization
let currentCache;
let currentCacheKey;

//calculate all center of cards and the degree of rotation
function calculatePositions(numberOfCards, rect) {
    let key = `${numberOfCards}-${rect.x}-${rect.y}-${rect.w}-${rect.h}`;
    if (currentCacheKey === key) {
        return currentCache;
    }

    let handHeight = rect.h;
    let handWidth = rect.w;
    let vertical = false;

    if (handHeight > handWidth) {
        vertical = true;
        let t = handHeight;
        handHeight = handWidth;
        handWidth = t;
    }

    let leftY = handHeight * 7 / 12;
    let halfW = handWidth / 2;

    let cardHeight = handHeight * 7 / 12; //educated guess
    let cardWidth = cardHeight * 2 / 3;

    let pA = { x: 0, y: leftY };
    let pB = { x: halfW - cardWidth / 2, y: handHeight };
    let pC = { x: handWidth, y: leftY };

    let center = calculateCircleCenter(pA, pB, pC);

    let outerRadius = handHeight - center.y;
    let rPi = Math.asin ((halfW - cardWidth / 2) / outerRadius);

    if (vertical) {
        let t = center.x;
        center.x = center.y;
        center.y = t;
    }

    //calculate handpositions:
    let halfPi = Math.PI / 2;
    let cardcount = numberOfCards;

    let stepPi = rPi * 2 / (cardcount - 1);
    let startPi = rPi + halfPi;
    
    let radius = outerRadius - cardHeight / 2;
    let radius2 = radius + cardHeight * 0.2;
    
    let cx = center.x + rect.x;
    let cy = center.y + rect.y;

    if (vertical) startPi -= halfPi;

    let handCardLocations = [];
    let j = startPi; 
    for (let i = 0; i < cardcount; i++) {
        let cosj = Math.cos(j);
        let sinj = Math.sin(j);
        let cos1 = cosj * radius + cx;
        let sin1 = sinj * radius + cy;

        let cos2 = cosj * radius2 + cx;
        let sin2 = sinj * radius2 + cy;

        handCardLocations.push({x : cos1, y : sin1, hoverX : cos2, hoverY : sin2, rotation : j - halfPi});
        j -= stepPi;
    }
    
    //done
    let result = {
        positions : handCardLocations,
        cardHeight : cardHeight,
        cardWidth : cardWidth
    };

    currentCacheKey = key;
    currentCache = result;
    return result;
} 

//calculate all positions based on width and height (and cache these)
function calculateOffSetWidthAndHeight(tableWidth, tableHeight, spreadWidth, spreadHeight, showHand) {
    let spreadFactor = spreadWidth / spreadHeight;
    let mHh = showHand ? minHandHeight : 0;

    //if wider than higher; f > 1; if higher then wide f < 1
    //try and draw both here
    //first check ratios center the spread
    //where is the most space left?
    let verticalElementFactor = (tableWidth - mHh) / tableHeight;
    let horizontalElementFactor = tableWidth / (tableHeight - mHh);

    let factor;
    
    let handWidth;
    let handHeight;
    let vertical = false;

    let selectionOffSet = {x : 0, y : 0};
    let spreadOffSet = {x : 0, y : 0};

    //check wich ratio comes closest
    if (Math.abs(verticalElementFactor - spreadFactor) < Math.abs(horizontalElementFactor - spreadFactor)) {
        //vertical factoring is better
        vertical = true;
        if (verticalElementFactor > spreadFactor) {
            //element is relatively wider than spread and hand
            factor = spreadHeight / tableHeight;
        } else {
            //element is relatively wider than spread and hand
            factor = spreadWidth / (tableWidth - mHh);
        }

        spreadWidth = spreadWidth / factor;
        spreadHeight = spreadHeight / factor;

        let spaceLeft = Math.min(tableWidth - spreadWidth, spreadWidth / 2.5, idealHandHeight);

        handWidth = tableHeight;
        handHeight = showHand ? Math.max(mHh, spaceLeft) : 0;
        
        let totalWidth = spreadWidth + handHeight;
        selectionOffSet.x = (tableWidth - totalWidth) / 2;
        
        spreadOffSet.x = selectionOffSet.x + handHeight;
        spreadOffSet.y = (tableHeight - spreadHeight) / 2;


    } else {
        //horizontal factoring is better
        if (horizontalElementFactor > spreadFactor) {
            //element is relatively wider than spread and hand
            factor = spreadHeight / (tableHeight - mHh);
        } else {
            //element is relatively wider than spread and hand
            factor = spreadWidth / tableWidth;
        }
        
        spreadWidth = spreadWidth / factor;
        spreadHeight = spreadHeight / factor;

        let spaceLeft = Math.min(tableHeight - spreadHeight, spreadHeight / 2.5, idealHandHeight);
        
        handWidth = tableWidth;
        handHeight = showHand ? Math.max(mHh, spaceLeft) : 0;
        
        let totalHeight = spreadHeight + handHeight;
        selectionOffSet.y = (tableHeight - totalHeight) / 2;
        
        spreadOffSet.y = selectionOffSet.y + handHeight;
        spreadOffSet.x = (tableWidth - spreadWidth) / 2;
    }
    
    let result = { spreadRect : {...spreadOffSet, w : spreadWidth, h : spreadHeight} };
    if (showHand) {
        result.selectionRect = {...selectionOffSet, w: vertical ? handHeight : handWidth, h: vertical ? handWidth : handHeight};
    }
    
    return result;        
}

//calculate location of the cards of the available cards
function getAvailableCards(cards, positions, cardPositions, spreadCount, directory, extension, stopAt, locale, onSelectCard) {
    //make hashmap containing all
    let chosenCards = cardPositions.reduce(function(map, obj) {
        map[obj.filename] = true;
        return map;
    }, {});

    let canChoose = stopAt >= cards.length;
    let cardComponents = [];

    if (spreadCount <= cardPositions.length) {
        stopAt = 0;
    }
    
    for (let i = 0; i < cards.length; i ++) {
        let idx = i;
        if (i > stopAt) {
            idx = Math.floor(stopAt);
        }

        let c = cards[i];
        if (c !== null && !chosenCards[c.filename]) {
            let loc = positions.positions[idx] || {};

            cardComponents.push(<Card
                key={c.filename}
                directory={directory}
                extension={extension}

                onSelectCard={(c) => onSelectCard && onSelectCard(c)}
                allowAnimations={canChoose}
                canChoose={canChoose}
                card={c}
                
                z={200 + i}
                x={loc.x || 0}
                y={loc.y || 0}
                
                hoverX={canChoose && stopAt > 0 ? loc.hoverX : loc.x}
                hoverY={canChoose && stopAt > 0 ? loc.hoverY : loc.y}
                rotation={loc.rotation}
                
                cardWidth={positions.cardWidth}
                cardHeight={positions.cardHeight}
                locale={locale} />)
        }
    }
            
    return cardComponents;
}

function getSpreadCards(spread, cardPositions, enlarged, rect, directory, extension, border, fontcolor, showReaderAids, onZoomIn) {
    let factor = rect.w / spread.width;
    
    return cardPositions.filter((c, i) => i < spread.positions.length).map((c, i) => {
        return <Card
                    key={c.filename}
                    card={c}
                    directory={directory}
                    extension={extension}
                    border={border}
                    fontcolor={fontcolor}

                    rotation={spread.positions[i].rotation}
                    faceUp={true}
                    allowAnimations={true}
                    z={100 + spread.positions[i].z + (enlarged[c.filename] ? 100 : 0)}
                    x={rect.x + spread.positions[i].centerX * factor}
                    y={rect.y + spread.positions[i].centerY * factor}
                    hoverX={rect.x + spread.positions[i].centerX * factor}
                    hoverY={rect.y + spread.positions[i].centerY * factor}

                    cardWidth={spread.cardWidth * factor * (enlarged[c.filename] ? 2 : 1)}
                    cardHeight={spread.cardHeight * factor * (enlarged[c.filename] ? 2 : 1)}
                    
                    locale="nl"

                    canChoose={showReaderAids}
                    onSelectCard={() => showReaderAids && onZoomIn && onZoomIn(c.filename)}
        />
    });
}

function getExtraCards(spread, cardPositions, positions, directory, extension, border, fontcolor, locale) {
    let lastPos = positions.positions[positions.positions.length / 2];
    let cardComponents = [];
    for (let i = spread.positions.length; i<cardPositions.length; i++) {
        let c = cardPositions[i];

        cardComponents.push(<Card
            key={c.filename}
            directory={directory}
            extension={extension}

            allowAnimations={true}
            faceUp={true}
            canChoose={false}
            card={c}
            border={border}
            fontcolor={fontcolor}
            
            z={100 + i}
            x={lastPos.x * 2/3}
            y={lastPos.y * 2/3}
            
            hoverX={lastPos.x * 2/3}
            hoverY={lastPos.y * 2/3}
            rotation={0}
            
            cardWidth={positions.cardWidth * 2}
            cardHeight={positions.cardHeight * 2}
            locale={locale} />)

    }
    return cardComponents;
}

function shuffle(array) {
    var m = array.length, t, i;
    while (m) {
        i = Math.floor(Math.random() * m--);
        t = array[m];
        array[m] = array[i];
        array[i] = t;
    }

    return array;
}

export class Table extends Component {
    constructor (props) {
        super(props);

        this.state = {
            currentCard : 0,
            allowAnimations : false,
            cards : shuffle([...DeckCards[props.deckType].cards])
        };
    }

    componentDidMount() {
        let stepCount = this.state.cards.length;
        if (this.tweezer) {
            this.tweezer.stop();
        }

        this.tweezer = new Tweezer({
            start: 0,
            end: stepCount,
            duration: 1500,
        })

        .on('tick', (v) => this.setState({currentCard : v}))
        .on('done', () => this.setState({allowAnimations : true}))
        .begin();
    }

    /* random */
    shouldComponentUpdate(nextProps) {
        if (nextProps.deckType !== this.props.deckType) {
            this.setState({cards : shuffle([...DeckCards[nextProps.deckType].cards]) });
            return false;
        }

        if (nextProps.rnd !== this.props.rnd) {
            this.setState({cards : shuffle([...DeckCards[nextProps.deckType].cards]) });
            return false;
        }
        
        return true;
    }

    render() {
        let props = this.props;
        let state = this.state;

        let spread = Spreads[props.spread];
        let deck = Decks[props.deck];

        let rects = calculateOffSetWidthAndHeight(props.width, props.height, spread.width, spread.height, props.showHand);
        
        let cards = [];
        let extraCards = [];

        //get the cards ready to be selected:
        let spreadCards = getSpreadCards(spread, props.cardPositions, props.enlarged || {}, rects.spreadRect, deck.directory, deck.extension, deck.border || "white", deck.fontcolor || "black", props.showReaderAids, props.onZoomIn);

        if (!!props.showHand) {
            let positions = calculatePositions(state.cards.length, rects.selectionRect);

            cards = getAvailableCards(state.cards, positions, props.cardPositions, spread.positions.length, deck.directory, deck.extension, state.currentCard, props.locale, props.onSelectCard);
            extraCards = getExtraCards(spread, props.cardPositions, positions, deck.directory, deck.extension, deck.border || "white", deck.fontcolor || "black", props.locale);
        }

        let allCards = cards.concat(spreadCards).concat(extraCards);
        allCards.sort((a,b) => 1* a.key - 1* b.key );
        
        return <div className="cardtable" style={{ position : 'relative' }}>
            {allCards}
        </div>;
    }
}