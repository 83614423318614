import { actionTypes as ActionTypes } from './actionTypes';

export { ActionTypes }

let startingCall = false;
export const startCall = (conversationId, useVideo) => async (dispatch) => {
    if (startingCall) return;

    startingCall = true;
    //first get all media devices we might need
    const gotDevices = (currentStream, deviceInfo) => {
        dispatch({
            type: ActionTypes.START_CALL,
            domain : ActionTypes.DOMAIN,
            conversationId : conversationId,
            useVideo : useVideo,
            initiator : true,
            deviceInfo : deviceInfo,
            initialStream : currentStream
        });
        startingCall = false;
    }

    const handleError = (error) => {
        console.error("Error: ", error);
      }

      console.log("S", "Getting Usermedia");
    let stream = null;
    await navigator.mediaDevices.getUserMedia({audio: true, video: useVideo})
        .then((s) => stream = s)
        .catch(e =>
            navigator.mediaDevices.getUserMedia({audio: true})
            .then((s) => stream = s)
            .catch(handleError)); //always use a fallback to get only audio

    navigator.mediaDevices
        .enumerateDevices()
        .then((di) => gotDevices(stream, di))
        .catch(handleError);    
};


export const sendWebRTCCandidate = (conversationId, candidate) => ({
    $type: "ChatMessages.SendWebRTCCandidate, ChatMessages",
    type : ActionTypes.WEB_RTC,
    domain: ActionTypes.DOMAIN,
    conversationId: conversationId,
    candidate: candidate.candidate,
    sdpMid: candidate.sdpMid,
    sdpMLineIndex: candidate.sdpMLineIndex,
    usernameFragment: candidate.usernameFragment,
});

export const sendWebRTCSdp = (conversationId, sdp) => ({
    $type: "ChatMessages.SendWebRTCSdp, ChatMessages",    
    type : ActionTypes.WEB_RTC,
    domain: ActionTypes.DOMAIN,
    conversationId: conversationId,
    sdpType : sdp.type,
    sdp : sdp.sdp
});

export const sendWebRTCHangup = (conversationId) => ({
    $type: "ChatMessages.SendWebRTCHangup, ChatMessages",
    type : ActionTypes.WEB_RTC,
    subType : ActionTypes.HANG_UP,
    domain: ActionTypes.DOMAIN,
    conversationId: conversationId    
});

export const sdpReceived = (evt) => ({
    type: ActionTypes.RCV_RTC,
    subType : ActionTypes.RTC_SDP_RECEIVED,
    domain: ActionTypes.DOMAIN,
    senderId : evt.senderId,
    senderDisplayName : evt.senderDisplayName,
    conversationId : evt.conversationId,
    sdp: {        
        type : evt.sdpType,
        sdp : evt.sdp
    }
});

export const candidateReceived = (evt) => {
    let result =
    {
        type: ActionTypes.RCV_RTC,
        subType : ActionTypes.RTC_CANDIDATE_RECEIVED,
        domain: ActionTypes.DOMAIN,
    
        senderId : evt.senderId,
        conversationId : evt.conversationId,

        candidate: evt
    };
    return result;
};

export const hangupReceived = (evt) => {
    let result =
    {
        type: ActionTypes.RCV_RTC,
        subType : ActionTypes.RTC_HANGUP_RECEIVED,
        domain: ActionTypes.DOMAIN,
    
        senderId : evt.senderId,
        conversationId : evt.conversationId
    };

    return result;
}

let answering = false;
export const answerCall = (conversationId, useVideo) => async (dispatch) => {
    if (answering) return;

    answering = true;
    //first get all media devices we might need
    const gotDevices = (currentStream, deviceInfo) => {
        dispatch({
            type: ActionTypes.ANSWER_CALL,
            domain : ActionTypes.DOMAIN,
            conversationId : conversationId,
            useVideo : useVideo,
            initiator : false,
            deviceInfo : deviceInfo,
            initialStream : currentStream
        });
        answering = false;
    }

    const handleError = (error) => {
        console.error("Error: ", error);
    }

    let stream = null;
    console.log("ANS", "Getting Usermedia");
    await navigator.mediaDevices.getUserMedia({audio: true, video: useVideo})
        .then((s) => stream = s)
        .catch(e =>
            navigator.mediaDevices.getUserMedia({audio: true})
            .then((s) => stream = s)
            .catch(handleError)); //always use a fallback to get only audio

    navigator.mediaDevices
        .enumerateDevices()
        .then((di) => gotDevices(stream, di))
        .catch(handleError);
}