import { combineReducers } from 'redux';
import authenticationReducer from './authenticationReducer';
import profileReducer from './profileReducer';
import chatsReducer from './chatsReducer';
import activeCallsReducer from './activeCallsReducer';

export default (history) => {
    return combineReducers(
    {
        auth : authenticationReducer,
        profile : profileReducer,
        chats : chatsReducer,
        rtc : activeCallsReducer
    });
}