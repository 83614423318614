import { DeckTypes } from './deckTypes';

export const Spreads = {
    cardoftheday : {
        id : "cardoftheday",
        text : {
            nl : {
                title: "Dagkaart",
                description: "Welke kaart trek je vandaag. Kijk als het ware in een spiegel en maak gebruik van je onbewuste weten."
            },
            de : {
                title: "Karte des Tages",
                description: "Welche Karte ziehen Sie heute? Schauen Sie in den Spiegel und nutzen Sie Ihr Unterbewusstsein."
            },
            en : {
                title: "Card of the day",
                description: "Which card will you draw today. Use your intuition as if you were looking in the mirror."
            },
            fr : {
                title: "Carte du Jour",
                description: "Quelle carte tirez-vous aujourd’hui ? Regardez pour ainsi dire dans le miroir et utilisez votre subconscient."
            },
            sp : {
                title: "Tarjeta del dia",
                description: "¿Qué carta dibujas hoy? Mírate en un espejo, por así decirlo, y utiliza tu conocimiento inconsciente."
            }
        },

        width : 400,
        height : 700,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,

        //cardChooser: Grid,
        //priceForConsult = 200,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Dagkaart",
                    de : "Karte des Tages",
                    en : "Card of the day",
                    fr : "Carte du Jour",
                    sp : "Tarjeta del dia"
                },
                centerX : 200,
                centerY : 350,
                z : 1,
                rotation : 0
            }
        ]        
    },
    pair : {
        id : "pair",
        text : {
            nl : {
                title: "Advies bij twijfel",
                description: "Dit patroon van twee kaarten kan je net een steuntje in de rug geven wanneer je twijfelt. Het geeft zicht op wat je kunt doen, maar ook op wat je beter kunt laten."
            },
            de : {
                title: "Hinweis im Zweifelsfall",
                description: "Die Ziehung der Tageskarte (zwei Karten) kann Ihnen im Falle eines Zweifels nützlich sein. Es ermöglicht Ihnen einen besseren Überblick darüber, was Sie tun können, aber auch, was Sie vermeiden sollten."
            },
            en : {
                title: "Advice when in doubt",
                description: "When in doubt, this two card spread can be of help. It clarifies what you could do and what you better not do."
            },
            fr : {
                title: "Conseil en cas de doute",
                description: "Le tirage Carte du Jour (de deux cartes) peut vous être utile en cas de doute. Il vous permet d’obtenir une meilleure vue concernant ce que vous pouvez faire mais également ce que vous devriez omettre."
            },
            sp : {
                title: "Asesoramiento en caso de duda",
                description: "Este patrón de dos cartas puede darte una mano amiga en caso de duda. Da una visión de lo que puedes hacer, pero también de lo que puedes dejar lo mejor"
            }
        },
        
        width : 825,
        height : 700,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Wat je het beste kunt doen ...",
                    de : "Das Beste, was man tun kann.......",
                    en : "What is the best approach...",
                    fr : "La meilleure chose à faire...",
                    sp : "Lo que puedes hacer lo mejor ..."
                },
                centerX : 200,
                centerY : 350,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat je het beste kunt laten ...",
                    de : "Was man vermeiden sollte...",
                    en : "What not to do ...",
                    fr : "La chose à laisser ...",
                    sp : "Lo que puedes dejar lo mejor…"
                },
                centerX : 625,
                centerY : 350,
                z : 1,
                rotation : 0
            }
        ]        
    },
    pastpresentfuture : {
        id : "pastpresentfuture",
        text : {
            nl : {
                title: "Heden, verleden en toekomst",
                description: "Het verleden is een herinnering, de toekomst een fantasie. Handel in het heden om je fantasie waar te kunnen maken. Deze legging geeft je een beeld van waar je nu staat, wat vooraf ging en waar je naartoe kunt."
            },
            de : {
                title: "Die Vergangenheit, die Gegenwart und die Zukunft",
                description: "Die Vergangenheit ist nur eine Erinnerung, die Zukunft ist nur eine Fantasie. Arbeiten Sie heute an der Fantasie von morgen. Diese Ziehung gibt Ihnen einen Überblick darüber, wo Sie sich heute befinden, was vorausgegangen ist und wohin Sie das führen könnte."
            },
            en : {
                title: "Present, past, future",
                description: "The past is a memory, the future is a fantasy. Act in the present to create your fantasy. This spread gives you insight of where you are at, what preceded and the probable outcome."
            },
            fr : {
                title: "Le passé, le présent et le future ",
                description: "Le passé n’est qu’une mémoire, le future n’est qu’un fantasme. Travaillez aujourd’hui au fantasme de demain. Ce tirage vous donne une vue d’où vous vous trouvez aujourd’hui, ce qui a précédé et où cela pourrait-vous mener."
            },
            sp : {
                title: "Presente, pasado y futuro ",
                description: "El pasado es un recuerdo, el futuro una fantasía. Actúa en el presente para hacer realidad tu fantasía. Estas polainas te dan una idea de dónde estás ahora, qué te precedió y dónde puedes ir."
            }
        },
        
        width : 1200,
        height : 600,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: false,
        onHomepage: true,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Waar je nu staat (heden)",
                    de : "Wo Sie sich im Moment gerade befinden (Gegenwart)",
                    en : "Where you are at (present)",
                    fr : "Où vous vous trouvez à l’instant (présent)",
                    sp : "Dónde estás ahora (presente)"
                },
                centerX : 600,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat vooraf ging (verleden)",
                    de : "Was vorausgegangen ist (Vergangenheit)",
                    en : "What preceded (past)",
                    fr : "Ce qui a précédé (passé)",
                    sp : "Lo que precedió (pasado)"
                },
                centerX : 200,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "Waar je naartoe kunt (toekomst)",
                    de : "Wohin Sie das führen könnte (Zukunft)",
                    en : "What is the probable outcome (future)",
                    fr : "Où cela pourrait vous mener (future)",
                    sp : "A dónde puedes ir (futuro)"
                },
                centerX : 1000,
                centerY : 300,
                z : 1,
                rotation : 0
            }
        ]        
    },
    bridge : {
        id : "bridge",
        text : {
            nl : {
                title: "Blik op de toekomst",
                description: "In dit patroon is een brug te herkennen. Ontdek waar je nu staat, waar je naartoe wilt en wat je zelf kunt doen om daar te komen waar jij wilt zijn."
            },
            de : {
                title: "Blick in die Zukunft",
                description: "In dieser Ziehung ist es möglich, einen Bezugspunkt zu finden. Finden Sie heraus, wo Sie jetzt gerade sind, wohin Sie gehen wollen und was Sie tun können, um dorthin zu gelangen."
            },
            en : {
                title: "A look at the future",
                description: "In this spread a bridge is depicted. Discover where you are at now, where you want to go and what you can do to get there."
            },
            fr : {
                title: "Vue sur le future",
                description: "Dans ce tirage il est possible de trouver un repère. Découvrez où vous vous trouvez à l’instant, où vous souhaitez aller et ce que vous pouvez faire afin d’y arriver."
            },
            sp : {
                title: " La mirada hacia el futuro ",
                description: " En este patrón puedes reconocer un puente. Descubre dónde estás ahora, a dónde quieres ir y qué puedes hacer tú mismo para llegar a donde quieres estar."
            }
        },

        path : [ "M 300 950 A 400 400, 0, 1, 1, 1100 950 L 900 950 A 200 200, 0, 1, 0, 500 950 Z " ],
        stroke : "#915b3e",
        fill : 'rgba(145,91,62,0.2)',
        
        width : 1400,
        height : 950,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: false,
        onHomepage: true,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Waar je nu staat (heden)",
                    de : "Wo Sie sich gerade befinden (Gegenwart)",
                    en : "Where you are at (present)",
                    fr : "Où vous vous trouvez à l’instant (présent)",
                    sp : "Dónde estás ahora (presente)"
                },
                centerX : 200,
                centerY : 650,
                z : 1,
                rotation : 0
            },          
            {
                title: {
                    nl : "Waar je naartoe wilt (toekomst)",
                    de : "Wohin Sie gelangen möchten (Zukunft)",
                    en : "Where do you want to get (future)",
                    fr : "Où vous voulez arriver (future)",
                    sp : "A dondé quieres ir (futuro)"
                },
                centerX : 1200,
                centerY : 650,
                z : 1,
                rotation : 0
            },  
            {
                title: {
                    nl : "Wat je kunt doen om er te komen",
                    de : "Was Sie tun können, um dorthin zu gelangen.",
                    en : "What can you do to get there",
                    fr : "Ce que vous pouvez faire afin d’y arriver",
                    sp : "¿Qué puedes hacer para llegar allí?"
                },
                centerX : 700,
                centerY : 300,
                z : 1,
                rotation : 0
            }
        ]        
    },
    conscience : {
        id : "conscience",
        text : {
            nl : {
                title: "Bewust en Onbewust",
                description: "Dit patroon biedt de mogelijkheid om zicht te krijgen op de wisselwerking tussen ons bewuste en ons onbewuste. Dit patroon geeft een aanwijzing over hoe je beide met elkaar in balans kunt brengen."
            },
            de : {
                title: "Bewusst und unbewusst",
                description: "Diese Ziehung bietet Ihnen die Möglichkeit, einen Blick auf die Interaktion zwischen dem Bewussten und dem Unbewussten zu werfen. Diese Ziehung gibt einen Hinweis darauf, wie man die beiden ausbalanciert."
            },
            en : {
                title: "Conscious and unconscious",
                description: "This spread offers the possibility to gain insight about the interaction between our conscious and unconscious. This spread gives indications of how to balance both."
            },
            fr : {
                title: "Consciemment et inconsciemment",
                description: "Ce tirage vous offre la possibilité d’avoir une vue sur l’interaction entre le conscient et l’inconscient. Ce tirage donne une indication comment de balancer les deux."
            },
            sp : {
                title: "Consciente e inconsciente ",
                description: "Este patrón ofrece la oportunidad de obtener información sobre la interacción entre nuestro consciente y nuestro inconsciente. Este patrón proporciona una indicación de cómo puedes equilibrar ambos."
            }
        },
        
        width : 1000,
        height : 1900,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Mijn zijn (nu)",
                    de : "Mein Wesen (Gegenwart)",
                    en : "Who am I (now)",
                    fr : "Mon être (présent)",
                    sp : "Mi son (ahora)"                    
                },
                centerX : 200,
                centerY : 950,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Waar ik me bewust van ben",
                    de : "Was mir bewusst ist",
                    en : "What am I conscious about",
                    fr : "Ce dont je suis conscient",
                    sp : "De lo que soy consciente"
                },
                centerX : 200,
                centerY : 300,
                
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Mijn onbewuste",
                    de : "Mein Unterbewusstsein",
                    en : "My subconscious",
                    fr : "Mon subconscient",
                    sp : "Mi inconsciente"
                },
                centerX : 200,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat je kan doen om beide in balans te krijgen",
                    de : "Was ich tun könnte, um ein Gleichgewicht zu erreichen.",
                    en : "What can I do to balance both",
                    fr : "Ce que je pourrais faire afin d’atteindre un équilibre",
                    sp : "Lo que puedes hacer para equilibrar ambos"
                },
                centerX : 800,
                centerY : 950,
                z : 1,
                rotation : 0
            }
        ]        
    },
    square : {
        id : "square",
        text : {
            nl : {
                title: "Het vierkant",
                description: "Het patroon het Vierkant is uitermate geschikt als je een duidelijk beeld wilt krijgen van je mogelijkheden om je doel te kunnen bereiken."
            },
            de : {
                title: "Das Quadrat",
                description: "Die Ziehung \"Das Quadrat\" ist sehr gut geeignet, wenn Sie sich ein genaues Bild von den Möglichkeiten machen wollen, um Ihr Ziel zu erreichen."
            },
            en : {
                title: "The Square",
                description: "The Square spread is very useful when you need clarity about your possibilities to achieve your objective."
            },
            fr : {
                title: "Le Carré",
                description: "Le tirage, Le Carré est très propice si l’on veut obtenir une image précise des possibilités pour acquérir son objectif."
            },
            sp : {
                title: "El cuadrado",
                description: " El patrón del cuadrado es ideal si deseas obtener una imagen clara de tus posibilidades para alcanzar tu objetivo."
            }
        },
        
        path : [ "M 0 0 L 1350 0 L 1350 1350 L 0 1350 L 0 0 L 20 20 L 20 1330 L 1330 1330 L 1330 20 L 20 20 Z " ],
        stroke : "#6fa2b7",
        fill : 'rgba(186,227,244,0.2)',

        width : 1350,
        height : 1350,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Je eigen inbreng (nu)",
                    de : "Meine eigene Rolle (Gegenwart)",
                    en : "Your own input (now)",
                    fr : "Mon propre rôle (présent)",
                    sp : "Tu propia aportación (ahora)"
                },
                centerX : 450,
                centerY : 350,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat je in je voordeel kunt gebruiken",
                    de : "Was Sie zu Ihrem Vorteil nutzen können",
                    en : "What you can use to your advantage",
                    fr : "Ce que vous pouvez utiliser en votre faveur",
                    sp : "Lo que puedes usar para tu ventaja"
                },
                centerX : 900,
                centerY : 350,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat het lastig voor je maakt",
                    de : "Was es nachteilig macht",
                    en : "What makes it problematic for you",
                    fr : "Ce qui le défavorise",
                    sp : "Lo que te dificulta"
                },
                centerX : 900,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Waar het toe KAN leiden",
                    de : "Wohin könnte es Sie führen?",
                    en : "Where it could lead to",
                    fr : "Où cela pourrait-vous mener",
                    sp : "A dónde puedes conducir"
                },
                centerX : 450,
                centerY : 1000,
                z : 1,
                rotation : 0
            }
        ]        
    },
    celticcross : {
        id : "celticcross",
        text : {
            nl : {
                title: "Het Keltisch Kruis",
                description: "Het Keltisch Kruis is een van de meest bekende patronen. Het geeft een algemeen beeld en is geschikt om te kijken naar zowel een thema, een algmene of meer specifieke vraag.<br />In dit patroon kun je een mens herkennen (kaarten 1 t/m 6) die een staf vasthoudt (kaarten 7 t/m 10)."
            },
            de : {
                title: "Das Keltenkreuz",
                description: "Die Ziehung \"Das Keltenkreuz\" ist eine der berühmtesten Ziehungen.  Es gibt einen allgemeinen Überblick und ist sowohl für ein allgemeines Thema als auch für eine spezifischere Frage günstig. In dieser Ziehung ist es möglich, eine Person (Karten 1 bis 6) zu erkennen, die einen Stock hält (Karten 7 bis 10)."
            },
            en : {
                title: "The Celtic Cross",
                description: "The Celtic Cross is one of the most famous spreads. It gives a general overview and is suitable to either look at a theme, a general or more specific question. <br /> In this spread a person is depicted (cards1 to 6) holding a wand (cards 7 to 10)."
            },
            fr : {
                title: "La Croix Celtique",
                description: "Le tirage, La Croix Celtique est un des tirages le plus connu.  Il donne un aperçu général et est favorable aussi bien pour un thème général que pour une question plus spécifique.<br />Dans ce tirage il est possible de reconnaitre une personne (cartes 1 jusqu’à 6) qui tient un bâton (cartes 7 jusqu’à 10)."
            },
            sp : {
                title: "La cruz celta",
                description: "La cruz celta es uno de los patrones más famosos. Da una imagen general y es adecuada para mirar tanto un tema, una pregunta general o más específica. <br /> En este patrón, puede reconocer a una persona (tarjetas 1 a 6) que tiene un bastón (tarjetas 7 hasta 10)."
            }
        },
        
        width : 1885,
        height : 2540,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Dit bedekt je",
                    de : "Stellt Ihre aktuelle Situation dar.",
                    en : "This covers you",
                    fr : "Représente votre situation actuelle",
                    sp : "Esto te cubre"
                },
                description: {
                    nl : "Deze positie staat voor de plek van je hart. Het geeft je een beeld van je kern.",
                    de : "Repräsentiert Ihr Herz. Gibt Ihnen eine Vorstellung von Ihrer Essenz.",
                    en : "This card stands for the place of your heart. It gives insight on who you are.",
                    fr : "Représente votre cœur. Vous donne une idée de votre essence.",
                    sp : "Esta posición representa la ubicación de tu corazón. Te da una idea de tu núcleo."                
                },
                centerX : 700,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Dit kruist je",
                    de : "Das geht Sie an.",
                    en : "This crosses you",
                    fr : "Ceci vous croise",
                    sp : "Esto te cruza"
                },
                description: {
                    nl : "Deze positie staat voor de dwarligger. Het geeft je een beeld wat lastig voor je is, jouw blokkade.",
                    de : "Diese Ziehung zeigt, was Ihnen nicht passt. Das fällt Ihnen schwer, es blockiert Sie.",
                    en : "This card stands for an obstacle. It shows what is difficult for you, what blocks you.",
                    fr : "Ce tirage démontre ce qui ne vous convient pas. Ceci vous est difficile, vous bloque.",
                    sp : "Esta posición representa al durmiente. Te da una imagen que es difícil para ti, tu bloqueo"
                },
                centerX : 700,
                centerY : 1000,
                z : 2,
                rotation : Math.PI * 0.5
            },
            {
                title: {
                    nl : "Dit gaat onder je",
                    de : "Dies wurde erreicht",
                    en : "This lays beneath you",
                    fr : "Ceci est acquis",
                    sp : "Esto va debajo de ti"
                },
                description: {
                    nl : "Deze positie staat voor de plek van je voeten. Het geeft een beeld van hoe je er in staat.",
                    de : "In dieser Karte geht es um das, was bereits erworben wurde. Es gibt einen Überblick darüber, wie es Sie beeinflusst.",
                    en : "This card indicates the position of your feet. It gives insight on how you are dealing with the situation.",
                    fr : "Cette carte parle de ce qui est déjà acquis. Elle donne une vue du comment cela vous affecte.",
                    sp : "Esta posición representa la ubicación de tus pies. Da una imagen de cómo te quedas ahi."
                },
                centerX : 700,
                centerY : 1650,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Dit gaat achter je",
                    de : "Diese Karte handelt von der Vergangenheit.",
                    en : "This is behind you",
                    fr : "Cette carte parle du passé.",
                    sp : "Esto esta detras de tí"
                },
                description: {
                    nl : "Deze positie staat voor de plek van je hand. Het geeft een beeld van hoe je hebt gehandeld.",
                    de : "Diese Karte spricht über den Ort Ihrer Hand und gibt einen Überblick darüber, wie Sie sich verhalten haben.",
                    en : "This card indicates the position of the hand. It gives insight on how you handled the situation.",
                    fr : "Cette carte parle de l’endroit de votre main et donne une vue du comment vous avez agi.",
                    sp : "Esta posición representa la ubicación de tu mano. Da una impresión de cómo has actuado."
                },
                centerX : 200,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Dit gaat boven je",
                    de : "Diese Karte befindet sich über Ihnen.",
                    en : "This is above you",
                    fr : "Cette carte se situe au-dessus de vous.",
                    sp : "Esto está más allá de ti"
                },
                description: {
                    nl : "Deze positie staat voor de plek van je hoofd. Het geeft een beeld van hoe je erover denkt.",
                    de : "Bei dieser Karte geht es um den Geist. Es zeigt, was Sie davon halten.",
                    en : "This card indicates the position of the head. It gives insight on how you think about the situation.",
                    fr : "Cette carte parle de l’esprit. Elle montre ce que vous en pensez.",
                    sp : "Esta posición representa la ubicación de tu cabeza. Da una impresión de cómo te sientes al respecto"
                },
                centerX : 700,
                centerY : 350,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Dit gaat voor je",
                    de : "Das muss noch kommen",
                    en : "This is in front of you",
                    fr : "Ceci doit encore venir",
                    sp : "Esto va para ti"
                },
                description: {
                    nl : "Deze positie staat voor de plek van je hand. Het geeft een beeld van hoe je kan handelen.",
                    de : "Bei dieser Karte geht es um den Platz der Hand. Es zeigt, was Sie tun können.",
                    en : "This card indicates the position of the hand. It gives insight on how you could handle the situation.",
                    fr : "Cette carte parle de l’endroit de la main. Elle montre ce que vous pouvez faire.",
                    sp : "Esta posición representa la ubicación de tu mano. Da una impresión de cómo puedes actuar"
                },
                centerX : 1200,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Dit is je spiegel",
                    de : "Das ist Ihr Spiegel.",
                    en : "This is your mirror",
                    fr : "Ceci est votre miroir",
                    sp : "Este es tu espejo"
                },
                description: {
                    nl : "Deze positie geeft een beeld van je innerlijke zelf.",
                    de : "Diese Karte gibt Ihnen einen Überblick über das reine Wesen.",
                    en : "This card gives insight on your inner self.",
                    fr : "Cette carte vous donné une vue de l’être propre.",
                    sp : "Esta posición da una imagen de tu ser interior."
                },
                centerX : 1470,
                centerY : 2240,
                z : 1,
                rotation : 0.1
            },
            {
                title: {
                    nl : "Dit is de ander",
                    de : "Das ist der andere.",
                    en : "This is the other",
                    fr : "Ceci est l’autre",
                    sp : "Este es el otro"
                },
                description: {
                    nl : "Deze positie geeft een beeld van de wisselwerking tussen jou en de ander.",
                    de : "Diese Karte gibt einen Überblick über die Interaktion zwischen Ihnen und dem anderen.",
                    en : "This card gives insight on the interaction between you and the other.",
                    fr : "Cette carte donne une vue de la interaction entre vous et l’autre.",
                    sp : "Esta posición ofrece una imagen de la interacción entre tí y el otro."
                },
                centerX : 1535,
                centerY : 1620,
                z : 1,
                rotation : 0.1
            },
            {
                title: {
                    nl : "Je hoop en je angst",
                    de : "Was Sie erhoffen und was Sie verängstigt.",
                    en : "Your hopes and fears",
                    fr : "Ce que vous espérez et ce qui vous angoisse.",
                    sp : "Tu esperanza y tu miedo"
                },
                description: {
                    nl : "Deze positie geeft een beeld van waar je op hoopt en/ of tegenop ziet.",
                    de : "Diese Karte gibt einen Überblick über das erwartete Ergebnis und/oder darüber, worüber Sie sich Sorgen machen.",
                    en : "This card gives insight on your hopes and/or fears.",
                    fr : "Cette carte donne une vue du résultat espéré et/ou ce qui vous angoisse.",
                    sp : "Esta posición ofrece una imagen de lo que espera y / o admira."
                },
                centerX : 1600,
                centerY : 1000,
                z : 1,
                rotation : 0.1
            },
            {
                title: {
                    nl : "Hier kan het toe leiden...",
                    de : "Das mögliche Ergebnis.....",
                    en : "Possible outcome...",
                    fr : "Le résultat possible...",
                    sp : "Esto puede conducir a ..."
                },
                description: {
                    nl : "Deze positie geeft een beeld van je beloning mocht je actie ondernemen.",
                    de : "Diese Karte gibt einen Ausblick auf die Belohnung, wenn Sie etwas unternehmen.",
                    en : "This card gives insight on the rewards should you take action.",
                    fr : "Cette carte donne une vue concernant la récompense si vous preniez action.",
                    sp : "Esta posición ofrece una imagen de tu recompensa si tomas medidas "
                },
                centerX : 1665,
                centerY : 380,
                z : 1,
                rotation : 0.1
            }
        ]        
    },
    director : {
        id : "director",
        text : {
            nl : {
                title: "De Regiseur",
                description: "Het patroon De Regisseur biedt de mogelijkheid om te kijken naar het thema: `wat je bezighoudt`. Wat is je eigen positie. Wat is je eigen positie, welke mogelijkheden liggen er, welke kansen zou je kunnen grijpen en met welke omstandigheden dien je rekening te houden? Wees de regisseur van je eigen leven!"
            },
            de : {
                title: "Der Regisseur",
                description: "Die Ziehung \"Der Regisseur\" bietet die Möglichkeit, das Thema \"Was Sie beschäftigt\" genauer zu betrachten. Wie ist Ihre Position, welche Möglichkeiten haben Sie, welche Möglichkeiten könnten Sie nutzen und welche Umstände sollten Sie berücksichtigen? Seien Sie der Regisseur Ihres eigenen Lebens!"
            },
            en : {
                title: "The Director",
                description: "The Director’s spread offers the opportunity to have a closer look at a certain topic: `what bothers you`. What is your own take on things. What are your possibilities, which opportunities could you seize and which circumstances do you need to take into account? Be the Director of your own life!"
            },
            fr : {
                title: "Le Régisseur",
                description: "Le tirage, Le Régisseur offre la possibilité de regarder de plus près le thème `qui vous occupe`. Quelle est votre position, quelles sont vos possibilités, quelles chances pourriez-vous saisir et de quelles circonstances devez-vous tenir compte ? Soyez le régisseur de votre propre vie!"
            },
            sp : {
                title: "El director",
                description: " l patrón El Director ofrece la posibilidad de mirar el tema: \"lo que te ocupa\". ¿Cuál es tu posición? ¿Cuál es su posición, lo que las oportunidades están ahí, ¿qué oportunidades se pudiera tomar y qué circunstancias hay que tener en cuenta? ¡Sé el director de tu propia vida!"
            }
        },
        
        width : 2350,
        height : 1850,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Waar sta je nu ... (heden)",
                    de : "Wo Sie sich im Moment befinden (Gegenwart)",
                    en : "Where do you stand ... (present)",
                    fr : "Où vous trouvez-vous à l’instant... (présent)",
                    sp : "¿Dónde estás ahora ... (presente)?"
                },
                centerX : 590,
                centerY : 925,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Kansen en mogelijkheden (1)",
                    de : "Chancen und Möglichkeiten (1)",
                    en : "Opportunities and possibilities (1)",
                    fr : "Opportunités et possibilités (1)",
                    sp : "Oportunidades y posibilidades (1) "
                },
                centerX : 200,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Kansen en mogelijkheden (2)",
                    de : "Chancen und Möglichkeiten (2)",
                    en : "Opportunities and possibilities (2)",
                    fr : "Opportunités et possibilités (2)",
                    sp : "Oportunidades y posibilidades (2) "
                },
                centerX : 590,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Kansen en mogelijkheden (3)",
                    de : "Chancen und Möglichkeiten (3)",
                    en : "Opportunities and possibilities (3)",
                    fr : "Opportunités et possibilités (3)",
                    sp : "Oportunidades y posibilidades (3) "
                },
                centerX : 980,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat het lastig kan maken (1)",
                    de : "Mögliche Schwierigkeiten (1)",
                    en : "What can make it tough (1)",
                    fr : "Difficultés possibles (1)",
                    sp : "Lo que puede dificultarlo (1)"
                },
                centerX : 200,
                centerY : 1550,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat het lastig kan maken (2)",
                    de : "Mögliche Schwierigkeiten (2)",
                    en : "What can make it tough (2)",
                    fr : "Difficultés possibles (2)",
                    sp : "Lo que puede dificultarlo (2)"
                },
                centerX : 590,
                centerY : 1550,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat het lastig kan maken (3)",
                    de : "Mögliche Schwierigkeiten (3)",
                    en : "What can make it tough (3)",
                    fr : "Difficultés possibles (3)",
                    sp : "Lo que puede dificultarlo (3)"
                },
                centerX : 980,
                centerY : 1550,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Mogelijke ontwikkeling, als je aan de slag gaat (1)",
                    de : "Mögliche Entwicklung, wenn Sie es selbst angehen (1)",
                    en : "Possible development if you get started  (1)",
                    fr : "Développement possible, si vous vous-y-mettez (1)",
                    sp : "Posible desarrollo, si comienzas (1)"
                },
                centerX : 1370,
                centerY : 925,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Mogelijke ontwikkeling, als je aan de slag gaat (2)",
                    de : "Mögliche Entwicklung, wenn Sie es selbst angehen (2)",
                    en : "Possible development if you get started (2)",
                    fr : "Développement possible, si vous vous-y-mettez (2)",
                    sp : "Posible desarrollo, si comienzas (2)"
                },
                centerX : 1760,
                centerY : 925,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Mogelijke ontwikkeling, als je aan de slag gaat (3)",
                    de : "Mögliche Entwicklung, wenn Sie es selbst angehen (3)",
                    en : "Possible development if you get started (3)",
                    fr : "Développement possible, si vous vous-y-mettez (3)",
                    sp : "Posible desarrollo, si comienzas (3)"
                },
                centerX : 2150,
                centerY : 925,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat je kunt bereiken",
                    de : "Was Sie erreichen können",
                    en : "What you can achieve",
                    fr : "Ce que vous pouvez achever",
                    sp : "Lo que puedes lograr"
                },
                centerX : 2150,
                centerY : 300,
                z : 1,
                rotation : 0
            }
        ]        
    },
    meintheworld : {
        id : "meintheworld",
        text : {
            nl : {
                title: "Ik, in Mijn Wereld",
                description: "Dit patroon biedt inzicht op vier thema's, waarvan je zelf het centrale midden vormt. Als mens sta je in het centrum van jouw wereld: thuis, op je werk, in je relatie en ontwikkeling."
            },
            de : {
                title: "Ich, in meiner Welt",
                description: "Die Ziehung \"Ich, in Meiner Welt\" identifiziert die vier Themen, die Ihr Zentrum bilden. Als Mensch sind Sie der Mittelpunkt Ihrer Welt: Zuhause, Arbeit, Beziehung und Entwicklung."
            },
            en : {
                title: "Me , in my World",
                description: "This spread gives insight on 4 topics, of which you form the center. As a human you are the center of your world: at home, at work, in your relation and your development."
            },
            fr : {
                title: "Moi, dans mon Monde",
                description: "Le tirage, Moi dans mon Monde, discerne les quatre thèmes d’où vous formez le centre. En tant qu’humain vous formez le centre de votre monde; foyer, travail  relation et développement."
            },
            sp : {
                title: "Yo, en mi mundo",
                description: "Este patrón proporciona información sobre cuatro temas, de los cuales tí mismo forma el centro central. Como persona, tu estás en el centro de tu mundo: en casa, en el trabajo, en tu relación y desarrollo "
            }
        },
        
        width : 2200,
        height : 3200,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Jezelf te midden van de wereld",
                    de : "Sie im Zentrum der Welt.",
                    en : "You in the center of the world",
                    fr : "Vous au centre du Monde.",
                    sp : "Tu en la mitad del mundo "
                },
                centerX : 1100,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Heden; relatie",
                    de : "Gegenwart; Beziehung",
                    en : "Present, relation",
                    fr : "Présent; relation",
                    sp : "Presente; relación "
                },
                centerX : 650,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Heden; werk",
                    de : "Gegenwart; Arbeit",
                    en : "Present, work",
                    fr : "Présent; travail",
                    sp : "Presente; trabajo "
                },
                centerX : 1100,
                centerY : 950,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Heden; je ontwikkeling",
                    de : "Gegenwart; Entwicklung",
                    en : "Present, development",
                    fr : "Présent; dévelopment",
                    sp : "Presente; tu desarrollo"
                },
                centerX : 1550,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Heden; thuis",
                    de : "Gegenwart; Zuhause",
                    en : "Present, home",
                    fr : "Présent; foyer",
                    sp : "Presente; en casa"
                },
                centerX : 1100,
                centerY : 2250,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Advies; relatie verbeteren",
                    de : "Rat: Verbesserung der Beziehung",
                    en : "Advice: enhance relation",
                    fr : "Conseil; améliorer la relation",
                    sp : "Consejos; mejorar la relación"
                },
                centerX : 200,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {                    
                    nl : "Advies; werksituatie verbeteren",
                    de : "Rat : Verbesserung des Arbeitsumfeldes",
                    en : "Advice: enhance work environment",
                    fr : "Conseil; améliorer l’environnement du travail",
                    sp : "Consejos; mejorar la situación laboral"
                },
                centerX : 1100,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Advies; ontwikkeling stimuleren",
                    de : "Rat : Verbesserung des Arbeitsumfeldes",
                    en : "Advice: stimulate development",
                    fr : "Conseil; stimuler le développement",
                    sp : "Consejos; estimular el desarrollo"
                },
                centerX : 2000,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Advies; thuissituatie verbeteren",
                    de : "Rat: Verbesserung des Familienlebens",
                    en : "Advice: enhance home-situation",
                    fr : "Conseil; améliorer la vie familiale",
                    sp : "Consejos; mejorar la situación del hogar"
                },
                centerX : 1100,
                centerY : 2900,
                z : 1,
                rotation : 0
            }
        ]        
    },
    relations : {
        id : "relations",
        text : {
            nl : {
                title: "Zicht op relaties",
                description: "Dit patroon biedt de mogelijkheid om zicht te krijgen op  huidige relatie en geeft een beeld van het aandeel wat jij en je partner daarin hebben op zowel materieel, emotioneel en rationeel gebied."
            },
            de : {
                title: "Übersicht der Beziehungen",
                description: "Diese Ziehung bietet die Möglichkeit, einen Überblick über die aktuelle Beziehung und den Beitrag zu erhalten, den Sie als Partner in materieller, emotionaler und rationaler Hinsicht leisten"
            },
            en : {
                title: "Insight in relations",
                description: "This spread offers the possibility to gain insight in a current relation and the role you and your partner play in it materially, emotionally  as well as rationally."
            },
            fr : {
                title: "Aperçu concernant les relations",
                description: "Ce tirage offre la possibilité d’avoir une vue sur la relation tel qu’el est à présent et de la contribution que vous, tant votre partenaire, y-avez sur le point matériel, émotionnel et rationnel."
            },
            sp : {
                title: "Ver en las relaciones",
                description: "Este patrón ofrece la posibilidad de obtener información sobre la relación actual y da una impresión de la participación que tu y tu pareja tienen en ella en términos materiales, emocionales y racionales"
            }
        },
        
        width : 1500,
        height : 2525,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Je relatie nu",
                    de : "Ihre Beziehung in der Gegenwart",
                    en : "Your relation now",
                    fr : "Votre relation au présent",
                    sp : "Tu relación actual"
                },
                centerX : 750,
                centerY : 1840,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "De verborgen factor in je relatie",
                    de : "Was in Ihrer Beziehung verborgen ist.",
                    en : "The hidden elements in your relation",
                    fr : "Ce qui est caché dans votre relation",
                    sp : "El factor oculto en tu relación"
                },
                centerX : 750,
                centerY : 1070,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Advies: Wat je het beste kunt doen",
                    de : "Rat: Was ist das Beste, was Sie tun können?",
                    en : "Advice: best thing you can do",
                    fr : "Conseil: Quelle est la meilleure chose à faire.",
                    sp : "Consejo: qué puedes hacer mejor"
                },
                centerX : 750,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Je eigen inbreng (materieel)",
                    de : "Ihr eigener (materieller) Beitrag.",
                    en : "Personal contribution (material)",
                    fr : "Votre propre contribution (matérielle).",
                    sp : "Su propia aportación (material)"
                },
                centerX : 200,
                centerY : 2225,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Hoe ervaar jij je partners inbreng",
                    de : "Wie empfinden Sie den Beitrag Ihres Partners?",
                    en : "How do you perceive your partners’ contribution",
                    fr : "Comment ressentez-vous la contribution de votre partenaire",
                    sp : "¿Cómo te sientes acerca de la aportación de tu pareja"
                },
                centerX : 1300,
                centerY : 2225,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Je eigen inbreng (emotioneel)",
                    de : "Ihr eigener (emotionaler) Beitrag",
                    en : "Personal contribution (emotional)",
                    fr : "Votre propre contribution (émotionnelle)",
                    sp : "Tu propia aportación (emocional)"
                },
                centerX : 200,
                centerY : 1455,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Hoe ervaar jij je partners inbreng",
                    de : "Wie empfinden Sie den Beitrag Ihres Partners?",
                    en : "How do you perceive your partners’ contribution",
                    fr : "Comment ressentez-vous la contribution de votre partenaire",
                    sp : "¿Cómo te sientes acerca de la aportación de tu pareja"
                },
                centerX : 1300,
                centerY : 1455,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Je eigen inbreng (rationeel)",
                    de : "Ihr eigener (rationaler) Beitrag",
                    en : "Personal contribution (rational)",
                    fr : "Votre propre contribution (rationnelle)",
                    sp : "Tu propia aportación (racional)"
                },
                centerX : 200,
                centerY : 685,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Hoe ervaar jij je partners inbreng",
                    de : "Wie empfinden Sie den Beitrag Ihres Partners?",
                    en : "How do you perceive your partners’ contribution",
                    fr : "Comment ressentez-vous la contribution de votre partenaire",
                    sp : "¿Cómo te sientes acerca de la aportación de tu pareja"
                },
                centerX : 1300,
                centerY : 685,
                z : 1,
                rotation : 0
            }
        ]        
    },
    meandrelations : {
        id : "meandrelations",
        text : {
            nl : {
                title: "Ik, en mijn relaties",
                description: "Als mens staan wij in verbinding met anderen en hebben we met elk van hen een ander soort relatie. Dit patroon biedt zicht op diverse relaties die je hebt, zowel met anderen als op de relatie met jezelf."
            },
            de : {
                title: "Ich und meine Beziehungen",
                description: "Die Ziehung \"Ich und meine Beziehungen\"; als Mensch sind wir in Verbindung mit anderen, und mit jedem von ihnen haben wir eine andere Beziehung. Diese Ziehung gibt uns einen Einblick in die verschiedenen Beziehungen, die wir  sowohl mit anderen als auch mit uns selbst haben."
            },
            en : {
                title: "Me and my relations",
                description: "As a human we are connected to others and with each we have a different kind of relation. This spread offers insight in the different relations you have, with others as well as with yourself."
            },
            fr : {
                title: "Moi, et mes Relations",
                description: "Le tirtage, Moi et mes Relations ; en tant qu’humain nous sommes en connexion avec d’autres et avec chacun d’entre eux nous avons une relation différente. Ce tirage nous donne une vue sur les diverses relations que nous avons, tant bien avec d’autres qu’avez nous-même."
            },
            sp : {
                title: "Yo y mis relaciones",
                description: "Como humanos, estamos conectados con otros y tenemos  con cada uno de ellos una relación diferente. Este patrón proporciona información sobre varias relaciones que tienes, tanto con los demás como con la relación contigo mismo."
            }
        },
        
        width : 1940,
        height : 1850,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Waar je nu staat (heden)",
                    de : "Wo Sie sich im Moment gerade befinden (Gegenwart)",
                    en : "Where are you at (present)",
                    fr : "Où vous vous trouvez à l’instant (présent)",
                    sp : "Dónde estás ahora (presente)"
                },
                centerX : 200,
                centerY : 925,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Relatie met je (innerlijke) zelf",
                    de : "Beziehung zu sich selbst",
                    en : "Relation with your (inner) self",
                    fr : "Relation avec soi-même",
                    sp : "Relación con tu ser (interior)"
                 },
                centerX : 775,
                centerY : 1550,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Relatie binnenshuis",
                    de : "Beziehung zu Hause",
                    en : "Relation at home",
                    fr : "Relation chez soi",
                    sp : "Relación en casa"
                },
                centerX : 775,
                centerY : 925,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Relatie buitenshuis",
                    de : "Beziehungen im Äussern",
                    en : "Outdoor relations",
                    fr : "Relation à l’extérieur",
                    sp : "Relación fuera de la puerta"
                },
                centerX : 775,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Doen om de relatie met jezelf te verbeteren",
                    de : "Was zu tun ist, um die Beziehung zu sich selbst zu verbessern.",
                    en : "To do in order to enhance the relation with yourself",
                    fr : "A faire, afin d’améliorer la relation avec vous-même",
                    sp : "Para mejorar la relación contigo mismo"
                },
                centerX : 1165,
                centerY : 1550,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Doen om je relatie binnenshuis te verbeteren",
                    de : "Was zu tun, um die Beziehung zu Hause zu verbessern.",
                    en : "To do in order to enhance the relation at home",
                    fr : "A faire, afin d’améliorer la relation chez soi",
                    sp : "Para mejorar tu relación en casa"
                },
                centerX : 1165,
                centerY : 925,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Doen om je relatie buitenshuis te verbeteren",
                    de : "Was zu tun ist, um endlich die Beziehungen im Äusseren zu verbessern.",
                    en : "To do in order to enhance outdoor relations",
                    fr : "A faire, enfin d’améliorer la relation à l’extérieur",
                    sp : "Para mejorar tu relación fuera de la puerta"
                },
                centerX : 1165,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Doen om je relaties te verbeteren",
                    de : "Was zu tun ist, um Ihre Beziehungen zu verbessern.",
                    en : "To do in order to enhance relations",
                    fr : "A faire, afin d’améliorer vos relations",
                    sp : "Para mejorar tus relaciones"
                 },
                centerX : 1740,
                centerY : 925,
                z : 1,
                rotation : 0
            }            
        ]        
    },
    rowboat : {
        id : "rowboat",
        text : {
            nl : {
                title: "Roeien met de riemen die je hebt",
                description: "Vanuit de gedachte dat je `dient te roeien met de riemen die je hebt` biedt dit patroon de mogelijkheid om je ontdekken in welk schuitje je momenteel zit. Dit patroon biedt daarnaast ook inzicht welke riemen je ter beschikking hebt en op welk baken jij je kunt richten om je doel te bereiken."
            },
            de : {
                title: "Spielen Sie die Karten, die Sie haben.",
                description: "Aus dem Gedanken heraus, dass Sie die Karten spielen sollten, die Sie zur Verfügung haben, gibt uns diese Ziehung die Möglichkeit, herauszufinden, wo Sie sich gerade befinden. Diese Ziehung bietet auch einen Überblick über die Ihnen zur Verfügung stehenden Karten und wo Sie sich orientieren können, um Ihr Ziel zu erreichen."
            },
            en : {
                title: "Playing the cards you have been dealt",
                description: "Based on the idea that you have to play the cards you have been dealt, this spread offers the possibility to discover where you stand. It also gives insight on what you have at your disposal and where to turn to in order to achieve your objective."
            },
            fr : {
                title: "Jouer les cartes que nous avons",
                description: "De la pensé qu’il faut jouer les cartes que l’on a à offrir, ce tirage nous offre la possibilité de découvrir où vous vous trouvez en ce moment. Ce tirage offre de plus une vue sur les cartes que vous avez à votre disposition et où s’orienter afin d’atteindre votre objectif."
            },
            sp : {
                title: " Rema con las correas que tienes ",
                description: " Basado en la idea de que \"tienes que remar con las correas que tienes\", este patrón te ofrece la oportunidad de descubrir en qué barco estás actualmente. Este patrón también proporciona información sobre qué correas tienes disponibles y en qué baliza puedes enfocarte para lograr tu objetivo."
            }
        },
        
        width : 2000,
        height : 2550,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Thema: het 'schuitje' waar je in zit",
                    de : "Thema: Das Problem vor Ihnen",
                    en : "Topic: the situation you find yourself in",
                    fr : "Thème: le problème devans lequel vous vous trouvez",
                    sp : "Tema: el 'barco' en el que te encuentras"
                },
                centerX : 1000,
                centerY : 1950,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Jouw inbreng: de 'roeier'",
                    de : "Ihr Beitrag",
                    en : "Your own contribution: what you do",
                    fr : "Votre contribution",
                    sp : "Tu entrada: el 'remero'"
                },
                centerX : 1000,
                centerY : 1300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Belemmering: linker roeispaan",
                    de : "Hindernis: linke Karte",
                    en : "Obstacle: left",
                    fr : "Obstacle: carte de gauche",
                    sp : "Obstáculo: remo izquierdo"
                },
                centerX : 610,
                centerY : 1550,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Talent: rechter roeispaan",
                    de : "Talent: rechte Karte",
                    en : "Talent: right",
                    fr : "Talent: carte de droite",
                    sp : "Talento: remo derecho"
                },
                centerX : 1390,
                centerY : 1550,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "De eerste slag: omgaan met je belemmering",
                    de : "Der erste Zug; sich seinem Hindernis stellen.",
                    en : "The first strike: dealing with your obstacle",
                    fr : "Le premier coup; faire face à votre obstacle",
                    sp : "El primer paso: lidiar con su obstrucción"
                },
                centerX : 200,
                centerY : 2275,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "De eerste slag: gebruik je talent",
                    de : "Der erste Zug; nutzen Sie Ihr Talent",
                    en : "The first strike: use your talent",
                    fr : "Le premier coup; utilisez votre talent",
                    sp : "El primer golpe: usa tu talento"
                },
                centerX : 1800,
                centerY : 2275,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Doel: het baken, om op te richten",
                    de : "Ziel: das Leuchtfeuer, an dem man sich orientiert",
                    en : "Objective: the beacon to turn to",
                    fr : "Objectif: la balise à laquelle se diriger",
                    sp : "Propósito: la baliza, para configurar"
                },
                centerX : 1000,
                centerY : 300,
                z : 1,
                rotation : 0
            }
        ]        
    },
    theroadahead : {
        id : "theroadahead",
        text : {
            nl : {
                title: "Mijn weg",
                description: "Mijn Weg biedt zicht op mogelijkheden wanneer je wel weet wat je wilt, maar nog geen zicht hebt op hoe je daar kunt komen. Wat is voor jou de meest haalbare, leukste of de meest verstandige weg?"
            },
            de : {
                title: "Mein Weg",
                description: "Die Ziehung \"Mein Weg\" bietet die Möglichkeit, einen Überblick über den Weg zu erhalten, wenn man bereits weiß, was man will. Was ist der machbarste, angenehmste oder vernünftigste Weg für Sie?"
            },
            en : {
                title: "My way",
                description: "My Way offers insight in the possibilities when you know what you want but are not sure how to get there yet. What is the most feasible, most fun or most reasonable thing to do?"
            },
            fr : {
                title: "Ma Route",
                description: "Le tirage, Ma Route, offre la possibilité de recevoir une vue due comment y arriver si vous savez déjà ce que vous voulez. Quel est pour vous le chemin le plus réalisable, le plus sympa ou le plus raisonnable?"
            },
            sp : {
                title: "Mi camino",
                description: " Mi camino te ofrece una visión de las posibilidades cuando sabes lo que quieres, pero aún no tienes una visión de cómo puedes llegar allí. ¿Cuál es la forma más factible, divertida o sensata para ti?"
            }
        },
        
        width : 1900,
        height : 2800,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Jouw houding in de keuze waar je voor staat",
                    de : "Ihre Einstellung bezüglich der zu treffenden Wahl",
                    en : "Your attitude towards the choice you need to make",
                    fr : "Votre attitude concernant le choix à faire",
                    sp : "Tu actitud en la elección que representa"
                },
                centerX : 950,
                centerY : 2500,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Het heden",
                    de : "Die Gegenwart",
                    en : "The present",
                    fr : "Le présent",
                    sp : "El presente"
                },
                centerX : 950,
                centerY : 1850,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Kruizing: De keuze waar je voor staat",
                    de : "Kreuzung: die Entscheidung, die zu treffen ist.",
                    en : "Crossroads: The choice you have to make",
                    fr : "Intersection : le choix à faire",
                    sp : "Interseccción: la elección que se representa"
                },
                centerX : 950,
                centerY : 1200,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "De weg die leidt naar doel op 5.",
                    de : "Der Weg, der zum Ziel der 5 führt.",
                    en : "The path that leads to the objective in card 5.",
                    fr : "Le Chemin qui mène à l’objectif du 5.",
                    sp : "El camino que lleva a la meta en 5."
                },
                centerX : 575,
                centerY : 750,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Het doel dat hoort bij keuze voor 4.",
                    de : "Der Weg, der zur Wahl der 4 gehört.",
                    en : "The path that leads to the objective in card 4.",
                    fr : "Le Chemin qui appartient au choix du 4.",
                    sp : "El objetivo que pertenece a elegir 4."
                },
                centerX : 200,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "De weg die leidt naar doel op 7.",
                    de : "Der Weg, der zum Ziel der 7 führt.",
                    en : "The path that leads to the objective in card 7.",
                    fr : "Le Chemin qui mène à l’objectif du 7.",
                    sp : "El camino que lleva a la meta en 7."
                },
                centerX : 1325,
                centerY : 750,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Het doel dat hoort bij keuze voor 6.",
                    de : "Der Weg, der zur Wahl der 6 gehört.",
                    en : "The path that leads to the objective in position 6.",
                    fr : "Le Chemin qui appartient au choix du 6.",
                    sp : "El objetivo que pertenece a elegir 6."
                },
                centerX : 1700,
                centerY : 300,
                z : 1,
                rotation : 0
            }
        ]        
    },
    thestar : {
        id : "thestar",
        text : {
            nl : {
                title: "De ster",
                description: "De Ster toont je de `de naakte waarheid` en geeft zicht op je mogelijkheden. De Ster toont dit door middel van twee verhalen. Het is zoals het is ....<br /> Verhaal 1: geeft een beeld van de situatie (kaarten 1 t/m 3)<br /> Verhaal 2: geeft een beeld van je eigen mogelijkheden (kaarten 4 t/m 6)"
            },
            de : {
                title: "Der Stern",
                description: " Die Ziehung \"Der Stern\" zeigt uns die Wahrheit, wie sie ist, und zeigt uns Möglichkeiten. Diese Ziehung zeigt dies in zwei Geschichten. Die Situation ist so, wie sie ist....<br /> Story 1: gibt einen Überblick über die aktuelle Situation (Karten 1 bis 3)<br /> Story 2: gibt einen Überblick über Ihre Möglichkeiten (Karten 4 bis 6)."
            },
            en : {
                title: "The Star",
                description: "The Star shows you the ‘full blown truth` and offers insight on your possibilities. The Star does this by means of two stories. It is the way it is.... <br />  Story 1: offers insight about the situation (cards 1 to 3) <br />  Story 2: offers insight about your own possibilities (cards 4 to 6)"
            },
            fr : {
                title: "L’étoile",
                description: "Le tirage, de l’Etoile, nous montre la vérité telle qu’elle est et nous montre des possibilités. Ce tirage nous montre cela par deux récits. La situation est telle qu’elle est ....<br /> Récit 1: donne une vue de la situation actuelle (cartes 1 jusqu’à 3)<br /> Récit 2: donne une vue de vos possibilités (cartes 4 jusqu’à 6)"
            },
            sp : {
                title: "La Estrella",
                description: " La Estrella te muestra la \"verdad desnuda\" y te da una idea de tus opciones. La Estrella te muestra esto a través de dos historias. Es como es ... <br /> Historia 1: te da una idea de la situación (cartas 1 a 3) <br /> Historia 2: te da una idea de tus propias posibilidades (cartas 4 a 3) 6)"
            }
        },
        
        path : [ "M 0 500 L 1600 500 L 800 2300 Z", "M 0 1800 L 1600 1800 L 800 0 Z" ],
        stroke : "#999",
        fill : 'transparent',
        
        width : 1600,
        height : 2300,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Situatie waar je naar wilt kijken",
                    de : "Situation, die Sie überprüfen möchten",
                    en : "Situation you want to investigate",
                    fr : "Situation que vous voulez réviser",
                    sp : "La situación que quieres mirar"
                },
                centerX : 800,
                centerY : 400,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Situatie op het moment",
                    de : "Aktuelle Situation (Gegenwart)",
                    en : "Present situation",
                    fr : "Situation à l’instant (présent)",
                    sp : "La situación del momento"
                },
                centerX : 1350,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "De tendens de komende periode",
                    de : "Die Entwicklung der kommenden Periode",
                    en : "The trend during the time ahead",
                    fr : "L’évolution de la période à venir",
                    sp : "La tendencia para el próximo período."
                },
                centerX : 250,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Het advies, wat je het beste kan doen",
                    de : "Was sollte man am besten tun?",
                    en : "The advice: your best option",
                    fr : "Quel est la meilleure chose à faire",
                    sp : "El consejo, qué puedes hacer mejor"
                },
                centerX : 800,
                centerY : 1900,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "De wisselwerking, tussen jou en de betrokkenen",
                    de : "Die Interaktion zwischen Ihnen und den anderen Menschen",
                    en : "The interaction between you and the others involved",
                    fr : "L’interaction entre vous et les autres",
                    sp : "La interacción entre tí y las personas involucradas."
                },
                centerX : 250,
                centerY : 700,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Makkelijke en lastige dingen, voor- en nadelen",
                    de : "Was einfach und schwierig ist, die Vor- und Nachteile.",
                    en : "The easy and tough stuff, pros and cons",
                    fr : "Ce qui est facile et difficile, les pours et les contres",
                    sp : "Cosas fáciles y torpes, pros y contras"
                },
                centerX : 1350,
                centerY : 700,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Waar het toe kan leiden",
                    de : "Wohin dies führen könnte",
                    en : "Possible outcome",
                    fr : "Où cela pourrait mener",
                    sp : "Hacia dónde puede conducir"
                },
                centerX : 800,
                centerY : 1150,
                z : 1,
                rotation : 0
            }
        ]        
    },
    thearch : {
        id : "thearch",
        text : {
            nl : {
                title: "De boog",
                description: "In dit patroon is een boog te herkennen. Je kunt de kaart op positie 4 beschouwen als een pijlpunt. Welke pijl wil jij afschieten? Welk doel wil jij bereiken? Tegen welk obstakel loop je aan?"
            },
            de : {
                title: "Der Bogen",
                description: "Die Ziehung \"der Bogen\" zeigt uns einen Bogen. Die Karte an der Position 4 gilt als die Spitze des Pfeils. Welchen Pfeil wollen Sie verschiessen? Welches Ziel wollen Sie erreichen? Auf welches Hindernis treffen Sie?"
            },
            en : {
                title: "The Arch",
                description: "This spread depicts an arch. The card in position 4 can be seen as the arrowhead. Which arrow would you like to shoot? Which objective do you want to reach? What is the constraint in this matter?"
            },
            fr : {
                title: "L’arc",
                description: "Le tirage, L’Arc, nous démontre un arc. La carte en position 4 est considéré comme la pointe de la flèche. Quelle flèche voulez-vous tirer? Quel objectif voulez-vous atteindre? Quel obstacle vous percute ?"
            },
            sp : {
                title: "El arco",
                description: "Se puede reconocer un arco en este patrón. Puedes considerar la tarjeta en la posición 4 como una punta de flecha. ¿Qué flecha quieres disparar? ¿Qué objetivo quieres alcanzar? ¿Qué obstáculo encuentras?"
            }
        },

        path : [ "M 0 1500 C 200 -500, 2450 -500, 2650 1500", "M 20 1520 C 200 -480, 2450 -480, 2630 1520" ],
        stroke : "#999",
        fill : 'transparent',
        
        width : 2650,
        height : 1500,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Het verleden",
                    de : "Die Vergangenheit",
                    en : "The past",
                    fr : "Le passé",
                    sp : "El pasado"
                },
                centerX : 200,
                centerY : 1200,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Het heden",
                    de : "Die Gegenwart",
                    en : "The present",
                    fr : "Le présent",
                    sp : "La presente"
                },
                centerX : 575,
                centerY : 700,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "De verborgen factor",
                    de : "Die verborgene Seite",
                    en : "Hidden elements",
                    fr : "La face cachée",
                    sp : "El factor oculto"
                },
                centerX : 950,
                centerY : 400,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Je doel",
                    de : "Das Ziel",
                    en : "Your goal",
                    fr : "L’objectif",
                    sp : "Tu objetivo"
                },
                centerX : 1325,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "De wisselwerking met je omgeving",
                    de : "Die Interaktion mit Ihrer Umgebung",
                    en : "The interaction with your surroundings",
                    fr : "L’interaction avec votre environnement",
                    sp : "La interacción con tu entorno."
                },
                centerX : 1700,
                centerY : 400,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Het advies",
                    de : "Der Ratschlag",
                    en : "The advice",
                    fr : "Le conseil",
                    sp : "El consejo"
                },
                centerX : 2075,
                centerY : 700,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Waar het je mogelijk kan brengen",
                    de : "Wohin könnte Sie das führen?",
                    en : "Possible outcome",
                    fr : "Où cela pourrait-vous mener",
                    sp : "Donde posiblemente te pueda llevar"
                },
                centerX : 2450,
                centerY : 1200,
                z : 1,
                rotation : 0
            }
        ]        
    },
    newrelation : {
        id : "newrelation",
        text : {
            nl : {
                title: "Zicht op een nieuwe relatie",
                description: "Dit patroon biedt de mogelijkheid om zicht te krijgen op een nieuwe relatie. Het geeft beeld van het kunnen delen met elkaar. (gevoelens, seksualiteit, praten en luisteren)"
            },
            de : {
                title: "Sicht auf eine neue Beziehung",
                description: "Diese Ziehung gibt Ihnen eine Sicht über eine neue Beziehung. Es gibt Ihnen einen Einblick in das, was wir miteinander teilen können (Emotionen, Sexualität, Sprechen und Hören)."
            },
            en : {
                title: "A view on a new relation",
                description: "This spread offers the possibility to get a view on a new relationship. A view of being able to share. (Emotions, sexuality, talking and listening)"
            },
            fr : {
                title: "Vue sur une nouvelle relation",
                description: "Ce tirage vous donne une vue concernant une nouvelle relation. Il vous donne un aperçu de ce que l’on peut partager l’un l’autre. (émotions, sexualité, parler et écouter)"
            },
            sp : {
                title: " Vista de una nueva relación ",
                description: " Este patrón te ofrece la oportunidad de obtener información sobre una nueva relación. Te da una imagen de poder compartir entre sí. (Sentimientos, sexualidad, hablar y escuchar.)"
            }
        },
        
        width : 1690,
        height : 1880,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Je relatie nu",
                    de : "Die Beziehung im Moment (Gegenwart)",
                    en : "Your present relation",
                    fr : "La relation à l’instant (présent)",
                    sp : "Tu relación ahora"
                },
                centerX : 200,
                centerY : 940,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Het kunnen delen van gevoelens",
                    de : "Wissen, wie man seine Emotionen teilt.",
                    en : "Being able to share emotions",
                    fr : "Savoir partager ses émotions",
                    sp : "El poder de compartir sentimientos"
                },
                centerX : 630,
                centerY : 1580,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Het kunnen praten en luisteren",
                    de : "Fähig sein, zu sprechen und zuzuhören.",
                    en : "Being able to talk and listen",
                    fr : "Savoir parler et écouter",
                    sp : "El poder de hablar y escuchar."
                },
                centerX : 630,
                centerY : 940,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Het kunnen delen van seksualiteit",
                    de : "Wissen, wie man seine Sexualität teilt.",
                    en : "Being able to share sexuality",
                    fr : "Savoir partager sa sexualité",
                    sp : "El poder de compartir sexualidad"
                },
                centerX : 630,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat het lastig maakt",
                    de : "Was erschwert",
                    en : "What makes it tough",
                    fr : "Ce qui complique",
                    sp : "Lo que lo hace difícil"
                },
                centerX : 1060,
                centerY : 940,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Advies: wat kun je het beste doen",
                    de : "Ratschlag: Was ist das Beste, das man tun kann?",
                    en : "Advice: what is the best option",
                    fr : "Conseil: quelle est la meilleure chose à faire",
                    sp : "Consejo: ¿qué puedes hacer mejor?"
                },
                centerX : 1490,
                centerY : 940,
                z : 1,
                rotation : 0
            }
        ]        
    },
    causeandeffect : {
        id : "causeandeffect",
        text : {
            nl : {
                title: "Probleem en oplossing, Oorzaak en Gevolg",
                description: "Dit patroon biedt de mogelijkheid om zicht te krijgen op iets wat lastig voor je is en waar je steeds weer tegen aan lijkt te lopen."
            },
            de : {
                title: "Problem und Lösung. Ursache und Wirkung",
                description: "Diese Ziehung gibt Ihnen einen Einblick darin, was für Sie schwierig ist und welchen Fehler Sie jedes Mal machen."
            },
            en : {
                title: "Problem and solution. Cause and effect",
                description: "This spread offers the possibility to gain insight into something that is difficult for you, a recurrent problem."
            },
            fr : {
                title: "Problème et solution. Cause et effet",
                description: "Ce tirage nous donne une vue sur ce qui vous est difficile et l’erreur que vous refaites à chaque coup."
            },
            sp : {
                title: "Problema y solución, causa y efecto",
                description: "Este patrón te ofrece la posibilidad de obtener información sobre algo que es difícil para tí y que parece encontrarte constantemente"
            }
        },
        
        width : 1960,
        height : 1280,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "De huidige situatie",
                    de : "Die Gegenwart",
                    en : "The current situation",
                    fr : "Le présent",
                    sp : "La situación actual"
                },
                centerX : 590,
                centerY : 640,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat vooraf ging",
                    de : "Die oben genannten",
                    en : "What preceded",
                    fr : "Ce qui précède",
                    sp : "Lo que precedió"
                },
                centerX : 200,
                centerY : 640,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat je het beste kunt doen",
                    de : "Das Beste, was Sie tun können.",
                    en : "What is your best option",
                    fr : "La meilleure chose à faire",
                    sp : "Lo que puedes hacer mejor"
                },
                centerX : 980,
                centerY : 640,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat gevoelsmatig lastig is",
                    de : "Was für Sie emotional schwierig ist.",
                    en : "What is your best option",
                    fr : "Ce qui vous-est difficile émotionnellement",
                    sp : "Lo cual es emocionalmente difícil"
                },
                centerX : 1370,
                centerY : 980,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat rationeel lastig is",
                    de : "Was für Sie rational schwierig ist.",
                    en : "The rational burden",
                    fr : "Ce qui vous-est difficile rationnellement",
                    sp : "Lo cual es racionalmente difícil"
                },
                centerX : 1370,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Waar het toe kan leiden",
                    de : "Wohin könnte Sie das führen?",
                    en : "Possible outcome",
                    fr : "Où cela pourrait-vous mener",
                    sp : "Hacia dónde se puede conducir"
                },
                centerX : 1760,
                centerY : 640,
                z : 1,
                rotation : 0
            }
        ]        
    },
    wheeloffortune : {
        id : "wheeloffortune",
        text : {
            nl : {
                title: "Het rad van Fortuin",
                description: "Kansen komen elke dag voorbij. Durf jij ze te grijpen? Zelfs wanneer een ander er niets in ziet? Zorg dat jij de touwtjes van jouw laven in handen hebt. Draai zelf aan het rad."
            },
            de : {
                title: "Das Rad des Schicksals",
                description: "Täglich werden Chancen geschaffen. Trauen Sie sich, sie zu packen? Auch wenn ein anderer darin nichts Gutes sieht? Übernehmen Sie die Kontrolle über Ihr eigenes Leben. Drehen Sie das Rad."
            },
            en : {
                title: "The Wheel of Fortune",
                description: "New opportunities arise each day. Dare you grab them? Even if no-one else sees them as an opportunity? Are you the puppet master of your life? Take a turn at the wheel of fortune."
            },
            fr : {
                title: "La Roue de la Fortune",
                description: "Opportunités se font chaque jour. Osez-vous les saisir ? Même si un autre nie voit rien de bon ? Prenez en main votre propre vie.  Tournez la roue."
            },
            sp : {
                title: "La rueda de la fortuna",
                description: "As oportunidades pasan todos los días. ¿Te atreves a agarrarlos? ¿Incluso cuando alguien más no ve nada en ellos? Asegúrese de tener el control de tí personal. Gires la rueda tí mismo"
            }
        },

        path : [ "M 1100 200 A 600 600, 0, 1, 1, 1100 1400 A 600 600, 0, 1, 1, 1100 200 M 1524.3 375.7 L 675.7 1224.3 M 675.7 375.7 L 1524.3 1224.3" ],
        stroke : "#999",
        fill : 'rgba(114,75,156,0.2)',
        
        width : 2200,
        height : 1600,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Waar je nu staat",
                    de : "Wo Sie sich gerade befinden (Gegenwart)",
                    en : "Where you stand",
                    fr : "Où vous vous trouvez à l’instant (présent)",
                    sp : "Donde estas ahora"
                },
                centerX : 200,
                centerY : 800,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Hoe je verder wilt",
                    de : "Wie wollen Sie fortfahren?",
                    en : "How you wish to proceed",
                    fr : "Comment voulez-vous continuer",
                    sp : "Como quieres proceder"
                },
                centerX : 1100,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat je ervoor dient te doen",
                    de : "Was sollten Sie tun, um dies zu erreichen?",
                    en : "What you need to do to get there",
                    fr : "Que devriez-vous faire afin d’y arriver",
                    sp : "Lo que tienes que hacer por eso"
                },
                centerX : 680,
                centerY : 800,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat je beter kunt laten",
                    de : "Was Sie vermeiden sollten",
                    en : "What you should not do",
                    fr : "Ce que vous devez omettre",
                    sp : "Lo que mejor te vayas"
                },
                centerX : 1100,
                centerY : 1300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Je volgende stap (advies)",
                    de : "Der nächste Schritt (Ratschlag)",
                    en : "Your next step (advice)",
                    fr : "L’étape suivante (conseil)",
                    sp : "Tu próximo paso (consejo)"
                },
                centerX : 1520,
                centerY : 800,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Waar je kunt komen",
                    de : "Wohin könnte Sie das führen?",                    
                    en : "Possible outcome",
                    fr : "Où cela pourrait-vous mener",
                    sp : "Donde puedes llegar"
                },
                centerX : 2000,
                centerY : 800,
                z : 1,
                rotation : 0
            }
        ]        
    },
    bedrijfsplan : {
        id : "bedrijfsplan",
        text : {
            nl : {
                title: "Mijn (bedrijfs) Plan",
                description: "Dit patroon leent zich bij uitstek om zicht te krijgen op je (bedrijfs) plan. Wat werkt in je voordeel, wat maakt het je lastig en waar heb je mogelijk niet aan gedacht. In dit patroon is een pijl te herkennen, waarbij je de kaart op  positie 5 kunt zien als signaalkaart."
            },
            de : {
                title: "Mein (Entwicklungs-)Plan",
                description: "Diese Ziehung gibt Ihnen den besten Überblick über Ihren (Entwicklungs-)Plan. Was zu Ihren Gunsten funktioniert, was kompliziert ist und woran Sie noch nicht gedacht haben. Diese Ziehung zeigt Ihnen einen Pfeil, in welchem die Karte Nr. 5 die Zusatzkarte ist."
            },
            en : {
                title: "My (business) Plan",
                description: "This spread is ideally suited to gain insight on your (business) plan. What works in your favor, what makes it difficult and what have you failed to consider. This spread depicts an arrow, where the card in position 5 can be interpreted as a signal-card."
            },
            fr : {
                title: "Mon plan (de développement)",
                description: "Ce tirage vous donne la meilleure vue sur votre plan (de développement). Ce qui joue en votre faveur, ce qui complique et à quoi vous n’avez pas pensé. Ce tirage vous démontre une flèche, dans lequel la carte n° 5 est celle d’appoint."
            },
            sp : {
                title: "Mi plan (de negocios)",
                description: "Este patrón es ideal para obtener información sobre tu plan (de negocios). Lo que funciona a tu favor, lo que lo hace difícil para tí y lo que puedes no haber pensado. En este patrón, puedes reconocer una flecha, donde puedes ver la tarjeta en la posición 5 como una tarjeta de señal"
            }
        },
        
        width : 1150,
        height : 2250,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Mijn plan",
                    de : "Mein Plan",
                    en : "My plan",
                    fr : "Mon plan",
                    sp : "Mi plan"
                },
                centerX : 575,
                centerY : 1300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat in mijn voordeel is",
                    de : "Was gut für mich ist.",
                    en : "What is in my favor",
                    fr : "Ce qui m’est favorable",
                    sp : "Lo cual es para mi ventaja"
                },
                centerX : 200,
                centerY : 750,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat in mijn nadeel is",
                    de : "Was mir Nachteile bringt",
                    en : "What is holding me back",
                    fr : "Ce qui me défavorise",
                    sp : "Lo cual es una desventaja"
                },
                centerX : 950,
                centerY : 750,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Waar ik niet aan gedacht heb",
                    de : "Woran ich nicht gedacht habe.",
                    en : "What have I failed to consider",
                    fr : "Auquel je n’ai pas pensé",
                    sp : "Lo que no he pensado"
                },
                centerX : 575,
                centerY : 1950,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Waar ik me van bewust dien te zijn",
                    de : "Worauf ich achten muss.",
                    en : "What I need to be aware of",
                    fr : "Auquel il me faut d’être conscient",
                    sp : "Lo que debo tener en cuenta"
                },
                centerX : 575,
                centerY : 300,
                z : 1,
                rotation : 0
            }
        ]        
    },
    startbedrijf : {
        id : "startbedrijf",
        text : {
            nl : {
                title: "Ik wil een eigen bedrijf starten",
                description: "Wanneer je van plan bent om een eigen bedrijf te starten kun je met dit patroon je eigen mogelijkheden en mogelijke valkuilen onderzoeken."
            },
            de : {
                title: "Ich möchte mein eigenes Unternehmen gründen.",
                description: "Wenn Sie ein eigenes Unternehmen gründen wollen, können Sie in dieser Ziehung Ihre Möglichkeiten, Ihre Schwierigkeiten und möglichen Fallstricke analysieren."
            },
            en : {
                title: "I want to start my own company",
                description: "When planning on starting your own company, this spread  offers you the possibility to investigate your own advantages and downfalls."
            },
            fr : {
                title: "Je voudrais démarrer ma propre société",
                description: "Si vous voulez démarrer votre propre société, ce tirage pour permet d’examiner vos possibilités, vos difficultés et les pièges possibles."
            },
            sp : {
                title: "Quiero comenzar mi propia empresa",
                description: "Si planeas iniciar tu propio negocio, puedes utilizar este patrón para investigar tus propias posibilidades y posibles dificultades"
            }
        },
        
        width : 1150,
        height : 1850,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl: "Je huidige situatie",
                    de: "Die Gegenwart",
                    en: "Your present situation",
                    fr: "Le présent",
                    sp: "Tu situación actual"
                },
                centerX : 575,
                centerY : 925,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl: "Waarom je een bedrijf wilt starten",
                    de: "Was ist der Grund, warum Sie Ihr eigenes Unternehmen gründen wollen?",
                    en: "Why you want to start your own business",
                    fr: "Quel est la raison que vous voulez démarrer votre propre société",
                    sp: "¿Por qué quieres iniciar un negocio?"
                },
                centerX : 200,
                centerY : 925,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl: "Wat je stimuleert",
                    de: "Was Sie anregt",
                    en: "What stimulates you",
                    fr: "Ce qui vous stimule",
                    sp: "Lo que te estimula"
                },
                centerX : 575,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl: "Wat je nog tegenhoudt (de verborgen factor)",
                    de: "Was Sie zurückhält; die verborgene Seite",
                    en: "What Is still keeping you (hidden element)",
                    fr: "Ce qu’il vous retient; la face cachée)",
                    sp: "Lo que todavía te está frenando (el factor oculto)"
                },
                centerX : 575,
                centerY : 1550,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl: "Wat je kunt doen om je doel te bereiken",
                    de: "Was Sie tun könnten, um das Ziel zu erreichen.",
                    en: "What are your options to achieve your objective",
                    fr: "Ce que vous pourriez faire afin d’atteindre l’objectif",
                    sp: "Qué puedes hacer para alcanzar tu meta"
                },
                centerX : 950,
                centerY : 925,
                z : 1,
                rotation : 0
            }
        ]        
    },
    chrystalball : {
        id : "chrystalball",
        text : {
            nl : {
                title: "De Glazen Bol",
                description: "Met het patroon De Glazen Bol kijk je als het ware vanaf een afstandje naar een situatie, om meer zicht te kunnen krijgen op wat er speelt rond het thema: wat je bezighoudt."
            },
            de : {
                title: "Die Kristallkugel",
                description: "Die Ziehung \"Die Kristallkugel\" gibt Ihnen die Möglichkeit, die Situation aus einer bestimmten Entfernung zu betrachten, um einen Überblick darüber zu erhalten, was bei diesem Thema auf dem Spiel steht: Was Sie beschäftigt."
            },
            en : {
                title: "The Crystal Ball",
                description: "The Crystal Ball spread helps you to look at a situation from a distance in order to gain insight at what is at play, what is biting you."
            },
            fr : {
                title: "La Boule de Cristal",
                description: "Le tirage, La Boule de Criustal, vous donne la possibilité de regarder d’une certaine distance la situation afin d’avoir une vue concernant ce qui joue autour de ce thème : ce qui vous occupe."
            },
            sp : {
                title: "El globo de cristal",
                description: "Con el patrón el globo de cristal, observas una situación desde la distancia, por así decirlo, para obtener una mejor idea de lo que está sucediendo en torno al tema: lo que te preocupes"
            }
        },

        path : [ "M 625 1650 A 860 860, 0, 1, 1, 1375 1650 " ],
        stroke : "#6fa2b7",
        fill : 'rgba(186,227,244,0.2)',
        
        width : 2000,
        height : 2000,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl: "Jou, als kijker",
                    de: "Sie als Zuschauer",
                    en: "You, as a viewer",
                    fr : "Vous, en tant que spectateur",
                    sp: "Tu, como espectador"
                },
                centerX : 1000,
                centerY : 1650,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl: "Hoe jij de situatie ervaart",
                    de: "Wie empfinden Sie diese Situation?",
                    en: "How you experience the situation",
                    fr: "Comment ressentez-vous cette situation",
                    sp: "Cómo experimentas la situación"
                },
                centerX : 625,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            
            {
                title: {
                    nl: "Hoe de situatie is",
                    de: "Die gegenwärtige Situation",
                    en: "How the situation actually is",
                    fr: "La situation tel qu’elle est",
                    sp: "Cual es la situación"
                },
                centerX : 1375,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl: "Wat je het beste kunt doen",
                    de: "Das Beste, was Sie tun können.",
                    en: "What is your best option",
                    fr: "La meilleure chose à faire",
                    sp: "Lo que puedes hacer mejor"
                },
                centerX : 1000,
                centerY : 350,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl: "Waar het toe kan leiden",
                    de: "Wohin könnte Sie das führen?",
                    en: "Possible outcome",
                    fr: "Où cela pourrait-vous mèner",
                    sp: "Hacia dónde se puede conducir"
                },
                centerX : 1000,
                centerY : 1000,
                z : 1,
                rotation : 0
            }
        ]        
    },
    developing : {
        id : "developing",
        text : {
            nl : {
                title: "Op Weg, In Ontwikkeling",
                description: "Het patroon Op weg, In ontwikkeling biedt de mogelijkheid om te kijken naar een proces in je leven. Vanuit waar jij je nu bevindt, biedt het zicht op je volgende te nemen stap."
            },
            de : {
                title: "Unterwegs, in Entwicklung",
                description: "Die Ziehung \"Unterwegs, in Entwicklung\" bietet Ihnen einen Einblick in eine Phase Ihres Lebens. Es gibt Ihnen einen Überblick über die Richtung, die Sie einschlagen müssen, wo Sie sich jetzt gerade befinden."
            },
            en : {
                title: "On my way, Developing",
                description: "The On my Way, developing spread offers the possibility to view a phase in your life. From where you stand if offers insight in the next steps to take."
            },
            fr : {
                title: "En Route, en Evolution",
                description: "Le tirage, En Route, en Evolution, vous offre une vue concernant une phase dans votre vie. Il vous offre une vue de la direction à prendre, d’où vous vous situez à l’instant."
            },
            sp : {
                title: "En el camino, en desarrollo",
                description: "El patrón En el camino, En desarrollo te ofrece la oportunidad de ver un proceso en tu vida. Desde donde te encuentras ahora, te ofrece una vista de tu próximo paso a seguir."
            }
        },
        
        width : 1200,
        height : 1800,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl: "Hier sta je nu",
                    de: "Wo Sie sich gerade befinden (Gegenwart)",
                    en: "Where you stand",
                    fr: "Où vous vous trouvez à l’instant (présent)",
                    sp: "Aqui estas ahora"
                },
                centerX : 200,
                centerY : 900,
                z : 1,
                rotation : 0
            },           
            {
                title: {
                    nl: "Het proces",
                    de: "Der Prozess",
                    en: "The phase",
                    fr: "Le processus",
                    sp: "El proceso"
                },
                centerX : 1000,
                centerY : 900,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl: "Wat je het beste kunt doen",
                    de: "Das Beste, was Sie tun können.",
                    en: "What is your best option",
                    fr: "La meilleure chose à faire",
                    sp: "Lo que puedes hacer mejor"
                },
                centerX : 600,
                centerY : 300,
                z : 1,
                rotation : 0
            },

            {
                title: {
                    nl: "Je volgende stap",
                    de: "Der nächste Schritt",
                    en: "The next step",
                    fr: "L’étape suivante",
                    sp: "Tu siguiente paso"
                },
                centerX : 600,
                centerY : 1500,
                z : 1,
                rotation : 0
            }
        ]        
    },
    relations2 : {
        id : "relations2",
        text : {
            nl : {
                title: "Relatie legging",
                description: "Met dit Patroon kun je een goed inzicht krijgen in een relatie, dit kan je parter zijn, maar ook elke andere relatie zoals een collega, vader, moeder, of met wie dan ook waar je meer over wilt weten."
            }
        },
        
        width : 1000,
        height : 1900,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.VOYAGER ],
        positions : [
            {
                title: {
                    nl : "Hoe sta jij in de relatie",                                       
                },
                centerX : 200,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Hoe staat de ander in deze relatie",                                       
                },
                centerX : 800,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat ben jij aan het leren",
                },
                centerX : 200,
                centerY : 950,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat is de ander aan het leren"
                },
                centerX : 800,
                centerY : 950,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat is aan jou om te doen om de relatie te verbeteren"
                },
                centerX : 200,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Wat is aan de ander om te doen om de relatie te verbeteren"
                },
                centerX : 800,
                centerY : 1600,
                z : 1,
                rotation : 0
            }
        ]        
    },
    goals : {
        id : "goals",
        text : {
            nl : {
                title: "Doelen bereiken",
                description: "Met dit patroon kun je kijken wat je doel is in een bepaalde situatie en hoe je dit kunt bereiken."
            }
        },
        
        width : 1200,
        height : 1900,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.VOYAGER ],
        positions : [
            {
                title: {
                    nl: "Hoe is het nu",                    
                },
                centerX : 200,
                centerY : 950,
                z : 1,
                rotation : 0
            },           
            {
                title: {
                    nl: "Wat is je doel",                    
                },
                centerX : 1000,
                centerY : 950,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl: "Wat kom je tegen"
                },
                centerX : 600,
                centerY : 300,
                z : 1,
                rotation : 0
            },

            {
                title: {
                    nl: "Wat is jou om te doen"
                },
                centerX : 600,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl: "Waar gaat het naar toe"
                },
                centerX : 600,
                centerY : 950,
                z : 1,
                rotation : 0
            }
        ]        
    },
    threecard : {
        id : "threecard",
        text : {
            nl : {
                title: "3 kaarten legging",
                description: "Het is een korte legging, die van links naar rechts wordt gelezen. De middelste kaart heeft het meeste gewicht en vormt de focus van de legging. Vaak geeft deze kaart het hoofdantwoord en vullen de andere kaarten de details in."
            }
        },
        
        width : 1200,
        height : 600,
        cardWidth : 400,
        cardHeight : 600,

        positions : [
            {
                title: {
                    nl : "de centrale kaart"
                },
                centerX : 600,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart links"
                },
                centerX : 200,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart rechts"
                },
                centerX : 1000,
                centerY : 300,
                z : 1,
                rotation : 0
            }
        ]        
    },
    fivecard : {
        id : "fivecard",
        text : {
            nl : {
                title: "5 kaarten legging",
                description: "Het is een korte legging, die van links naar rechts wordt gelezen. In feite is dit een meer gedetailleerde versie van de drie kaart legging. De middelste kaart heeft het meeste gewicht en vormt de focus van de legging. Vaak geeft deze kaart het hoofdantwoord en vullen de andere kaarten de details in."
            }
        },
        
        width : 2000,
        height : 600,
        cardWidth : 400,
        cardHeight : 600,

        positions : [
            {
                title: {
                    nl : "de centrale kaart"
                },
                centerX : 1000,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 1"
                },
                centerX : 200,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 5"
                },
                centerX : 1800,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 2"
                },
                centerX : 600,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 4"
                },
                centerX : 1400,
                centerY : 300,
                z : 1,
                rotation : 0
            }
        ]        
    } ,
    boxspread : {
        id : "boxspread",
        text : {
            nl : {
                title: "9 kaart legging (3x3)",
                description: "Het is een populaire legging die in veel kaartleesmethodes voorkomt, niet alleen in Lenormand. Je hebt het misschien al eerder gebruikt zien worden in Tarot, Petit Etteilla, of in een cartomancy lezing met een spel speelkaarten. Het is tenslotte een van de meest populaire 9 kaarten spreidingen. <br /><br />Wanneer u een Lenormand 3×3 spread leest, helpt het om de legging te zien als een mini-Grand Tableau vanwege de hoeveelheid informatie die u kunt krijgen en de hoeveelheid tijd die de legging kan beslaan. U kunt hem gebruiken wanneer u een direct antwoord op een vraag wilt of wanneer u een blik wilt werpen op de nabije toekomst (ruwweg 6 weken)."
            }
        },
        
        width : 1200,
        height : 1900,
        cardWidth : 400,
        cardHeight : 600,

        positions : [
            {
                title: {
                    nl : "Kaart 1"
                },
                centerX : 200,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 2"
                },
                centerX : 600,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 3"
                },
                centerX : 1000,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 4"
                },
                centerX : 200,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 5"
                },
                centerX : 600,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 6"
                },
                centerX : 1000,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 7"
                },
                centerX : 200,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 8"
                },
                centerX : 600,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 9"
                },
                centerX : 1000,
                centerY : 1600,
                z : 1,
                rotation : 0
            }
        ]        
    },
    grandtableau1 : {
        id : "grandtableau1",
        text : {
            nl : {
                title: "Grand tableau 9x4",
                description: "Het Lenormand Grand Tableau is de meest bekende en gebruikte methode om Lenormand te lezen."
            }
        },
        
        width : 3600,
        height : 2550,
        cardWidth : 400,
        cardHeight : 600,

        positions : [
            {
                title: {
                    nl : "Kaart 1"
                },
                centerX : 200,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 2"
                },
                centerX : 600,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 3"
                },
                centerX : 1000,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 4"
                },
                centerX : 1400,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 5"
                },
                centerX : 1800,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 6"
                },
                centerX : 2200,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 7"
                },
                centerX : 2600,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 8"
                },
                centerX : 3000,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 9"
                },
                centerX : 3400,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Kaart 10"
                },
                centerX : 200,
                centerY : 950,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 11"
                },
                centerX : 600,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 12"
                },
                centerX : 1000,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 13"
                },
                centerX : 1400,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 14"
                },
                centerX : 1800,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 15"
                },
                centerX : 2200,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 16"
                },
                centerX : 2600,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 17"
                },
                centerX : 3000,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 18"
                },
                centerX : 3400,
                centerY : 950,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Kaart 19"
                },
                centerX : 200,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 20"
                },
                centerX : 600,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 21"
                },
                centerX : 1000,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 22"
                },
                centerX : 1400,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 23"
                },
                centerX : 1800,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 24"
                },
                centerX : 2200,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 25"
                },
                centerX : 2600,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 26"
                },
                centerX : 3000,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 27"
                },
                centerX : 3400,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Kaart 28"
                },
                centerX : 200,
                centerY : 2250,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 29"
                },
                centerX : 600,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 30"
                },
                centerX : 1000,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 31"
                },
                centerX : 1400,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 32"
                },
                centerX : 1800,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 33"
                },
                centerX : 2200,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 34"
                },
                centerX : 2600,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 35"
                },
                centerX : 3000,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 36"
                },
                centerX : 3400,
                centerY : 2250,
                z : 1,
                rotation : 0
            }
        ]        
    },
    grandtableau2 : {
        id : "grandtableau2",
        text : {
            nl : {
                title: "Grand tableau 8 x 4 + 4",
                description: "Het Lenormand Grand Tableau is de meest bekende en gebruikte methode om Lenormand te lezen."
            }
        },
        
        width : 3200,
        height : 3200,
        cardWidth : 400,
        cardHeight : 600,

        positions : [
            {
                title: {
                    nl : "Kaart 1"
                },
                centerX : 200,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 2"
                },
                centerX : 600,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 3"
                },
                centerX : 1000,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 4"
                },
                centerX : 1400,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 5"
                },
                centerX : 1800,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 6"
                },
                centerX : 2200,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 7"
                },
                centerX : 2600,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 8"
                },
                centerX : 3000,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Kaart 9"
                },
                centerX : 200,
                centerY : 950,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 10"
                },
                centerX : 600,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 11"
                },
                centerX : 1000,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 12"
                },
                centerX : 1400,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 13"
                },
                centerX : 1800,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 14"
                },
                centerX : 2200,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 15"
                },
                centerX : 2600,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 16"
                },
                centerX : 3000,
                centerY : 950,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Kaart 17"
                },
                centerX : 200,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 18"
                },
                centerX : 600,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 19"
                },
                centerX : 1000,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 20"
                },
                centerX : 1400,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 21"
                },
                centerX : 1800,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 22"
                },
                centerX : 2200,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 23"
                },
                centerX : 2600,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 24"
                },
                centerX : 3000,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Kaart 25"
                },
                centerX : 200,
                centerY : 2250,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 26"
                },
                centerX : 600,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 27"
                },
                centerX : 1000,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 28"
                },
                centerX : 1400,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 29"
                },
                centerX : 1800,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 30"
                },
                centerX : 2200,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 31"
                },
                centerX : 2600,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 32"
                },
                centerX : 3000,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 33"
                },
                centerX : 1000,
                centerY : 2900,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 34"
                },
                centerX : 1400,
                centerY : 2900,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 35"
                },
                centerX : 1800,
                centerY : 2900,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 36"
                },
                centerX : 2200,
                centerY : 2900,
                z : 1,
                rotation : 0
            }
        ]        
    },
    grandtableau1ext : {
        id : "grandtableau1ext",
        text : {
            nl : {
                title: "Grand tableau 9x4 + 4x2",
                description: "Het Lenormand Grand Tableau is de meest bekende en gebruikte methode om Lenormand te lezen."
            }
        },
        
        width : 3600,
        height : 3850,
        cardWidth : 400,
        cardHeight : 600,

        positions : [
            {
                title: {
                    nl : "Kaart 1"
                },
                centerX : 200,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 2"
                },
                centerX : 600,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 3"
                },
                centerX : 1000,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 4"
                },
                centerX : 1400,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 5"
                },
                centerX : 1800,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 6"
                },
                centerX : 2200,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 7"
                },
                centerX : 2600,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 8"
                },
                centerX : 3000,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 9"
                },
                centerX : 3400,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Kaart 10"
                },
                centerX : 200,
                centerY : 950,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 11"
                },
                centerX : 600,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 12"
                },
                centerX : 1000,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 13"
                },
                centerX : 1400,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 14"
                },
                centerX : 1800,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 15"
                },
                centerX : 2200,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 16"
                },
                centerX : 2600,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 17"
                },
                centerX : 3000,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 18"
                },
                centerX : 3400,
                centerY : 950,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Kaart 19"
                },
                centerX : 200,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 20"
                },
                centerX : 600,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 21"
                },
                centerX : 1000,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 22"
                },
                centerX : 1400,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 23"
                },
                centerX : 1800,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 24"
                },
                centerX : 2200,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 25"
                },
                centerX : 2600,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 26"
                },
                centerX : 3000,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 27"
                },
                centerX : 3400,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Kaart 28"
                },
                centerX : 200,
                centerY : 2250,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 29"
                },
                centerX : 600,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 30"
                },
                centerX : 1000,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 31"
                },
                centerX : 1400,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 32"
                },
                centerX : 1800,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 33"
                },
                centerX : 2200,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 34"
                },
                centerX : 2600,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 35"
                },
                centerX : 3000,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 36"
                },
                centerX : 3400,
                centerY : 2250,
                z : 1,
                rotation : 0
            },                        
            {
                title: {
                    nl : "kaart 37"
                },
                centerX : 1000,
                centerY : 2900,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 38"
                },
                centerX : 1400,
                centerY : 2900,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 39"
                },
                centerX : 1800,
                centerY : 2900,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 40"
                },
                centerX : 2200,
                centerY : 2900,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 41"
                },
                centerX : 1000,
                centerY : 3550,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 42"
                },
                centerX : 1400,
                centerY : 3550,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 43"
                },
                centerX : 1800,
                centerY : 3550,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 44"
                },
                centerX : 2200,
                centerY : 3550,
                z : 1,
                rotation : 0
            }
        ]        
    },
    grandtableau2ext : {
        id : "grandtableau2ext",
        text : {
            nl : {
                title: "Grand tableau 8x4 + 3x4",
                description: "Het Lenormand Grand Tableau is de meest bekende en gebruikte methode om Lenormand te lezen."
            }
        },
        
        width : 3200,
        height : 4500,
        cardWidth : 400,
        cardHeight : 600,

        positions : [
            {
                title: {
                    nl : "Kaart 1"
                },
                centerX : 200,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 2"
                },
                centerX : 600,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 3"
                },
                centerX : 1000,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 4"
                },
                centerX : 1400,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 5"
                },
                centerX : 1800,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 6"
                },
                centerX : 2200,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 7"
                },
                centerX : 2600,
                centerY : 300,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 8"
                },
                centerX : 3000,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Kaart 9"
                },
                centerX : 200,
                centerY : 950,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 10"
                },
                centerX : 600,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 11"
                },
                centerX : 1000,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 12"
                },
                centerX : 1400,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 13"
                },
                centerX : 1800,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 14"
                },
                centerX : 2200,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 15"
                },
                centerX : 2600,
                centerY : 950,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 16"
                },
                centerX : 3000,
                centerY : 950,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Kaart 17"
                },
                centerX : 200,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 18"
                },
                centerX : 600,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 19"
                },
                centerX : 1000,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 20"
                },
                centerX : 1400,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 21"
                },
                centerX : 1800,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 22"
                },
                centerX : 2200,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 23"
                },
                centerX : 2600,
                centerY : 1600,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 24"
                },
                centerX : 3000,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Kaart 25"
                },
                centerX : 200,
                centerY : 2250,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "kaart 26"
                },
                centerX : 600,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 27"
                },
                centerX : 1000,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 28"
                },
                centerX : 1400,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 29"
                },
                centerX : 1800,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 30"
                },
                centerX : 2200,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 31"
                },
                centerX : 2600,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 32"
                },
                centerX : 3000,
                centerY : 2250,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 33"
                },
                centerX : 1000,
                centerY : 2900,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 34"
                },
                centerX : 1400,
                centerY : 2900,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 35"
                },
                centerX : 1800,
                centerY : 2900,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 36"
                },
                centerX : 2200,
                centerY : 2900,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 37"
                },
                centerX : 1000,
                centerY : 3550,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 38"
                },
                centerX : 1400,
                centerY : 3550,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 39"
                },
                centerX : 1800,
                centerY : 3550,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 40"
                },
                centerX : 2200,
                centerY : 3550,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 41"
                },
                centerX : 1000,
                centerY : 4200,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 42"
                },
                centerX : 1400,
                centerY : 4200,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 43"
                },
                centerX : 1800,
                centerY : 4200,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "kaart 44"
                },
                centerX : 2200,
                centerY : 4200,
                z : 1,
                rotation : 0
            }
        ]        
    }
}