import React, { Component } from 'react';
import T from 'prop-types';
import './Card.scss';

class Card extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMouseOver: false
        }
    }

    onMouseOver = () => { this.setState({ isMouseOver: true }) }
    onMouseOut = () => { this.setState({ isMouseOver: false }) }

    onCardClick = () => {
        if (this.props.canChoose && this.props.onSelectCard) {
            this.props.onSelectCard(this.props.card);
        }
    }

    onRemoveClick = () => {
        if (this.props.onDeselectCard) {
            this.props.onDeselectCard(this.props.card);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        let nl = nextProps.locale || '';
        let tl = this.props.locale || '';

        let result =
            nextProps.faceUp !== this.props.faceUp ||
            nextProps.allowAnimations !== this.props.allowAnimations ||            
            nextState.isMouseOver !== this.state.isMouseOver ||
            nextProps.x !== this.props.x ||
            nextProps.y !== this.props.y ||
            nextProps.cardWidth !== this.props.cardWidth ||
            nextProps.cardHeight !== this.props.cardHeight ||
            nextProps.hoverX !== this.props.hoverX ||
            nextProps.hoverY !== this.props.hoverY || 
            ((nextProps.faceUp || false) && (nl !== tl));

        return result;
    }

    render() {
        let pr = this.props;
        const { isMouseOver } = this.state;

        if (!this.props.cardWidth) return null;
        let borderRadius = `${this.props.cardWidth / 25}px`;
        let borderPx = `${this.props.cardWidth / 30}`;

        let locale = pr.locale || 'nl';
        let cardLocale = pr.card[locale] || pr.card.nl;
        
        return <div id={pr.id} className={`card ${pr.faceUp ? '' : 'flipped'} ${pr.allowAnimations ? 'animate' : ''}`}
                    style={{
                        position: 'absolute',
                        top: (isMouseOver ? pr.hoverY : pr.y) - pr.cardHeight / 2,
                        left: (isMouseOver ? pr.hoverX : pr.x) - pr.cardWidth / 2,
                        width: pr.cardWidth,
                        height: pr.cardHeight,
                        transform: `rotate(${pr.rotation}rad)`,
                        zIndex : pr.z                    
                    }}>
                                     <div
                    onClick={this.onCardClick}
                    onMouseOver={this.onMouseOver}
                    onMouseOut={this.onMouseOut}

                    className="flipper" style={{ width: pr.cardWidth, height: pr.cardHeight }}>
                    <div className="front">
                        <figure>
                            <img src={`${pr.directory}${pr.card.filename}.${pr.extension}`} alt={cardLocale.name}
                                style={{
                                    borderRadius : borderRadius,
                                    borderWidth : `${cardLocale.subtitle ? pr.cardHeight / 14 : borderPx}px ${borderPx}px ${pr.cardHeight / 14}px ${borderPx}px`,
                                    borderColor : pr.border,
                                }}
                            />

                            <h2 style={{
                                fontSize: pr.cardHeight / 22,
                                color : pr.fontcolor,
                                lineHeight : (pr.cardHeight / 14) + 'px',
                                height : pr.cardHeight / 14 }}>
                            {cardLocale.name}</h2>

                            {cardLocale.subtitle && <h3 className="subtitle" style={{
                                fontSize: pr.cardHeight / 22,
                                color : pr.fontcolor,
                                lineHeight : (pr.cardHeight / 14) + 'px',
                                height : pr.cardHeight / 14 }}>
                            {cardLocale.subtitle}</h3>}
                        </figure>
                        
                    </div>
                    <div className="back">
                        <img src={`./images/back.jpg`} alt="" style={{borderRadius : borderRadius}} />
                    </div>
                </div>      
        </div>
    }
}

Card.propTypes = {
    id: T.string,
    x: T.number,
    y: T.number,
    z: T.number,

    hoverX: T.number,
    hoverY: T.number,

    cardWidth: T.number,
    cardHeight: T.number,

    rotation: T.number,

    canChoose : T.bool,
    directory: T.string,
    extension: T.string,
    
    onSelectCard : T.func,
    onDeselectCard : T.func,

    locale : T.string,
    faceUp : T.bool,
    allowAnimations : T.bool
};

export default Card;