export const GuidanceFields = {
    DEFAULT : "DEFAULT",
    RELATIONSHIP : "RELATIONSHIP",
    WORK: "WORK",
    SPIRITUAL: "SPIRITUAL"    
}

export const DeckTypes = {
    TAROT: "TAROT",
    TAROT_MAYOR_ARCANA: "TAROT-MAYOR-ARCANA",
    VOYAGER : "VOYAGER",
    LENORMAND : "LENORMAND",
    EXTENDED_LENORMAND : "EXTENDED_LENORMAND"
}

//🜂 fire  ♈ ♌ ♐           
//🜄 water ♋ ♏ ♓
//🜁 air ♊ ♎ ♒
//🜃 earth ♉ ♍ ♑

let _deckCards = {
    [DeckTypes.EXTENDED_LENORMAND] : {
        id : DeckTypes.EXTENDED_LENORMAND,
        cards : [
            {
                filename: "00",
                en : {
                    name : "Rider",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Ruiter",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "01",
                en : {
                    name : "Clover",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Klaver",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "02",
                en : {
                    name : "Ship",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Schip",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "03",
                en : {
                    name : "House",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Huis",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "04",
                en : {
                    name : "Tree",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Boom",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "05",
                en : {
                    name : "Clouds",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Clouds",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "06",
                en : {
                    name : "Snake",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Snake",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "07",
                en : {
                    name : "Coffin",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Coffin",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "08",
                en : {
                    name : "Flowers",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Flowers",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "09",
                en : {
                    name : "Scythe",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Scythe",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "10",
                en : {
                    name : "Broom",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Broom",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "11",
                en : {
                    name : "Birds",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Birds",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "12",
                en : {
                    name : "Child",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Child",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "13",
                en : {
                    name : "Fox",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Fox",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "14",
                en : {
                    name : "Bear",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Bear",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "15",
                en : {
                    name : "Stars",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Stars",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "16",
                en : {
                    name : "Stork",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Stork",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "17",
                en : {
                    name : "Dog",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Dog",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "18",
                en : {
                    name : "Tower",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Tower",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "19",
                en : {
                    name : "Park",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Park",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },{
                filename: "20",
                en : {
                    name : "Mountain",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Mountain",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "21",
                en : {
                    name : "Choice",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Choice",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "22",
                en : {
                    name : "Mice",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Mice",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "23",
                en : {
                    name : "Heart",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Heart",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "24",
                en : {
                    name : "Ring",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Ring",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "25",
                en : {
                    name : "Book",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Book",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "26",
                en : {
                    name : "Letter",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Letter",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "27",
                en : {
                    name : "Man",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Man",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "28",
                en : {
                    name : "Lady",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Lady",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "29",
                en : {
                    name : "Lilies",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Lilies",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "30",
                en : {
                    name : "Sun",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Sun",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "31",
                en : {
                    name : "Moon",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Moon",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "32",
                en : {
                    name : "Key",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Key",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "33",
                en : {
                    name : "Fish",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Fish",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "34",
                en : {
                    name : "Anchor",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Anchor",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "35",
                en : {
                    name : "Cross",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Cross",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "36",
                en : {
                    name : "Time",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Time",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "37",
                en : {
                    name : "Bridges",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Bridges",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "38",
                en : {
                    name : "Dice",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Dice",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "39",
                en : {
                    name : "Mask",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Mask",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "40",
                en : {
                    name : "Well",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Put",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "41",
                en : {
                    name : "Compass",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Kompas",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "42",
                en : {
                    name : "Labyrinth",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "Doolhof",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            },
            {
                filename: "43",
                en : {
                    name : "Magnifying glass",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                },
                nl : {
                    name : "vergrootglas",
                    keywords_nouns : [ "News", "Message" ],
                    keywords_adjectives : [ "Fast", "Passionate", "Athletic" ]
                }
            }
        ]
    },
    [DeckTypes.TAROT] : {
        id : DeckTypes.TAROT,
        cards: [
            {
                filename: "00",
                numerology : 0,
                en : {
                    name : "The fool",
                    planet : "♅ - Uranus",
                    archetype : "The child",
                    theme : "childlike open-mindedness",
                    description : "The Fool talks about childlike open-mindedness, curiosity  being open, limitless possibilities.",

                    bulletpoints : [],
                    questions : [ "How open-minded can you (still) be?", "Can you (still) be open to new things?", "What can you enthusiastically discover?" ],

                    soalPoints : [ "Naivety", "Receptivity", "Wonder", "Openness", "Fun", "Zest for life", "Carefree" ],
                    soalText : "Wonder is the very beginning of a (discovery) journey or of an investigation. Curiosity provides the incentive to start the experiment. A child spontaneously runs into the solution of the problem.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Fool leads his life free of worries and prejudice. He discovers the world with an open mind, not knowing nor worrying where he will end up. He has a childlike innocence and is filled with wonder and excitement. He is open to change, renewal and growth. Still, one should be careful not to be overly optimistic of naive. Sometimes it is wiser to watch your step than to go and jump in at the deep end.</p><p>Often this card also points out that now is a good time for new beginnings, for taking a fresh start.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Fool asks you to look at your personal relationships in a different way, to change your perspective. Now may be a good time to ask yourself if you are truly ready to commit. And do not worry if you are not, it’s not a problem as long as you are being honest about it. Do not give in to pressure from your surroundings. You are the only one who knows how intense you want your relationship(s) to be right now.</p><p>The Fool also tells you that fun times are coming your way and invites you to enjoy them. But don’t exaggerate, make sure you are still open for true love in case it crosses your path.</p>",
                        [GuidanceFields.WORK] : "<p>Look at your work or career with an open and inquisitive mind, ask yourself if there is room for learning and improvement. Maybe the time is right for new experiences, for change. If you have new ideas or proposals,you may notice that others don't understand or support you, that they are not interested in what you have to offer. Be clear and open minded, help them see where you're coming from, how you reached your conclusions, then they will probably understand.</p><p>The Fool may also indicate the time is right to look for a different job or career, or even to start your own business.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You are developing an interest in spirituality and sometimes the possibilities, the abundance of topics you can choose from is overwhelming. It may be hard to see the wood from the trees. You are definitely uncertain yet which direction you would like to go. Take your time to find the path that fits you best. Don’t limit yourself right now, keep an open mind.You may also have to accept that people around you possibly don't understand your need for spirituality.</p><p>Possibly you're also discovering abilities and talents you didn't even realize you had.</p>",
                    }
                },
                fr : {
                    name : "Le Fou",
                    planet : "♅ - Uranus",
                    archetype : "L'enfant",
                    description : "Le Fou, nous parle de la confiance enfantine, la curiosité, garder l’esprit ouvert, les possibilités illimitées.",
                    questions : [ "A quel point pouvez-vous (encore) avoir l’esprit ouvert?", "Pouvez-vous (encore) être ouvert à de nouvelles choses?", "Que pouvez-vous découvrir avec enthousiasme?" ],
                    theme : "l'ouverture d'esprit enfantin",

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le fou représente toujours quelque chose de nouveau et d'innovateur. Il mène sa vie à l'abri des soucis, des pensées et des préjugés. Il découvre le monde avec un esprit ouvert, sans savoir, sans demander où il va finir. Il a une innocence enfantine et une attitude à la vie pleine d'émerveillement et d'enthousiasme. Il est toujours ouvert au changement, au renouvellement et à la croissance.</p><p>Néanmoins, il faut être prudent et ne pas être trop optimiste ou naïf. Il est parfois plus sage d'être très prudent au lieu de tomber dans un abîme à la fin.</p><p>Souvent, cette carte indique que c'est le bon moment pour un nouveau départ, pour un changement ou pour démarrer un nouveau projet.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Fool asks you to look at your personal relationships in a different way, to change your perspective. Now may be a good time to ask yourself if you are truly ready to commit. And do not worry if you are not, it’s not a problem as long as you are being honest about it. Do not give in to pressure from your surroundings. You are the only one who knows how intense you want your relationship(s) to be right now.</p><p>The Fool also tells you that fun times are coming your way and invites you to enjoy them. But don’t exaggerate, make sure you are still open for true love in case it crosses your path.</p>",
                        [GuidanceFields.WORK] : "<p>Look at your work or career with an open and inquisitive mind, ask yourself if there is room for learning and improvement. Maybe the time is right for new experiences, for change. If you have new ideas or proposals,you may notice that others don't understand or support you, that they are not interested in what you have to offer. Be clear and open minded, help them see where you're coming from, how you reached your conclusions, then they will probably understand.</p><p>The Fool may also indicate the time is right to look for a different job or career, or even to start your own business.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You are developing an interest in spirituality and sometimes the possibilities, the abundance of topics you can choose from is overwhelming. It may be hard to see the wood from the trees. You are definitely uncertain yet which direction you would like to go. Take your time to find the path that fits you best. Don’t limit yourself right now, keep an open mind.You may also have to accept that people around you possibly don't understand your need for spirituality.</p><p>Possibly you're also discovering abilities and talents you didn't even realize you had.</p>",
                    }
                },
                sp:{
                    name:"El Loco",
                    planet: "♅ - Urano",
                    archetype: "El niño",
                    theme: "apertura de espíritu infantil",
                    description: "El Loco es acerca de la confianza infantil, de la curiosidad, de ser abierto, de las posibilidades ilimitadas",
                    questions: ["¿Qué abierto puedes ser (todavía)?", "¿Puedes (todavía) ser abierto a cosas nuevas?", "¿Qué puedes descubrir con entusiasmo?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Tonto lleva su vida libre de preocupaciones y prejuicios. Descubre el mundo con una mente abierta, sin saber ni preocuparse donde va a terminar. Él tiene una inocencia infantil y se llena de asombro y emoción. Él está abierto al cambio, la renovación y el crecimiento. Aún así, hay que tener cuidado de no ser demasiado optimista de ingenuo. A veces es más sabio que ver su paso de ir y saltar en la parte más profunda. A menudo esta tarjeta también señala que ahora es un buen momento para un nuevo comienzo, para tomar un nuevo comienzo.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name: "De Dwaas",
                    planet : "♅ - Uranus",
                    archetype : "Het kind",
                    theme : "kinderlijke onbevangenheid",
                    description : "De zot gaat over kinderlijk vertrouwen, over nieuwsgierigheid, over open staan, over onbeperkte mogelijkheden.",
                    questions : [ "Hoe onbevangen kun jij (nog) zijn?", "Kun jij (nog) open staan voor nieuwe dingen?", "Wat kun jij met enthousiasme ontdekken?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Dwaas leidt zijn leven vrij van zorgen, piekeren en vooroordelen. Hij onderzoekt de wereld met een open geest, hij vraagt zich niet af waar hij terecht zal komen en maakt zich er ook niet druk om. Hij heeft een kinderlijke naïviteit en loopt over van verwondering en levensvreugde. Hij staat altijd open voor verandering, vernieuwing en groei. Mogelijk herken je jezelf hierin, of misschien kan je wat meer van deze eigenschappen gebruiken in je leven.</p><p>Let toch op dat je niet té optimistisch of naïef bent. Soms is het nu eenmaal verstandiger om te kijken waar je loopt dan zomaar in het diepe te springen. Als je deze kaart trekt dan wijst dit er vaak op dat het voor jou nu een goed moment is voor een nieuw begin, om een nieuwe start te nemen, een nieuw project op te zetten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Dwaas vraagt je om op een andere manier naar je relaties te kijken, vanuit een ander perspectief. Dit kan je kijk op de dingen totaal veranderen. Als het over de liefde gaat, kan je je afvragen of je er echt klaar voor bent om je te binden, wil je je wel voor langere tijd vastleggen? Maak je niet ongerust als dit niet het geval is, zolang je eerlijk bent zit je op het goede spoor. Het is ook mogelijk dat een reeds bestaande relatie betekenisvoller en diepgaander kan worden, als je daar klaar voor bent tenminste.</p><p>De Dwaas kan ook betekenen dat er leuke tijden aankomen, hij nodigt je uit om ervan te genieten. Maar overdrijf hier niet mee, zorg dat je er klaar voor bent als de ware liefde op je pad komt.</p>",
                        [GuidanceFields.WORK] : "<p>Kijk met een open en onderzoekende geest naar je werk en/of carrière, vraag je af of er ruimte en gelegenheid is voor bijleren en verbetering. Misschien is de tijd nu rijp voor verandering, voor nieuwe ervaringen. Het is wel mogelijk dat niet iedereen openstaat voor jouw nieuwe ideeën en voorstellen, dat ze er geen begrip voor hebben en je niet steunen. Probeer zo duidelijk en open mogelijk te zijn, vertel ze hoe je tot je nieuwe conclusies gekomen bent.</p><p>Soms vertelt De Dwaas ons dat het tijd is om op zoek te gaan naar een andere baan, een nieuwe carrière. Misschien kan je nu zelfs gaan denken aan het opzetten van je eigen bedrijf!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit tijdstip in je leven begin je interesse te krijgen voor spiritualiteit. Je raakt soms wat overweldigd door de veelheid aan onderwerpen en mogelijke richtingen die je kan kiezen. Misschien ontdek je op een gegeven moment ook krachten of talenten bij jezelf waarvan je nog niet wist dat je ze in je had. Het komt erop neer dat je echt nog niet weet welke kant je op wil. Maak je niet ongerust, neem gewoon de tijd om net dat pad te kiezen dat het beste bij je past en aanvaard dat de mensen om je heen mogelijk niet altijd zullen begrijpen waarom je nood hebt aan spiritualiteit in je leven.</p>",
                    }
                },
                de : {
                    name: "Der Narr",
                    planet : "♅ - Uranus",
                    archetype : "Das Kind",
                    theme : "Die kindliche Offenheit",
                    description : "Der Narr spricht über kindliches Vertrauen, Neugierde, Offenheit und unbegrenzte Möglichkeiten",
                    questions : ["Wie offen können Sie (noch) sein?", " Können Sie (noch) offen sein für Neues", "Was können Sie mit Begeisterung entdecken?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der Narr steht immer für Neues und Erneuerungen. Er führt sein Leben frei von Sorgen, Grübeleien oder Vorurteilen. Er entdeckt die Welt mit einem offenen Geist, ohne zu wissen, ohne zu fragen, wo er landen wird. Er hat eine kindliche Unschuld und eine Einstellung zum Leben voller Staunen und Begeisterung. Er ist immer offen gegenüber Veränderung, Erneuerung und Wachstum.</p><p>Dennoch sollte man vorsichtig sein und nicht zu optimistisch oder naiv vorgehen. Manchmal ist es klüger, ganz vorsichtig vorzugehen anstatt am Ende in einen Abgrund zu fallen. Oft weist diese Karte darauf hin, dass jetzt ein guter Zeitpunkt für einen Neuanfang ist, für Veränderung oder für den Start eines neuen Projekts.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Der Narr fragt Sie, auf eine andere Weise Ihre persönlichen Beziehungen zu betrachten, damit Sie Ihre Perspektive ändern. Jetzt kann es eine gute Zeit sein, sich zu fragen, ob Sie wirklich bereit sind sich zu engagieren. Und keine Sorge, wenn das nicht der Fall wäre. Es ist kein Problem, solange Sie darüber ehrlich und klar sind. Geben Sie nicht auf unter Druck Ihrer Umgebung. Sie sind der Einzige, der genau weiß, wie intensiv Sie Ihre Beziehung (en) im Moment haben möchten.</p><p>Der Narr sagt Ihnen auch, dass Zeiten zum Spaß auf Ihren Weg kommen werden und lädt Sie ein, sie zu genießen. Aber übertreiben Sie nicht, stellen Sie sicher, dass Sie für die wahre Liebe offen stehen würden, falls sie an Sie vorbeikommen würde.</p>",
                        [GuidanceFields.WORK] : "<p>Betrachten Sie Ihre Arbeit oder Karriere mit einem offenen Auge und einem neugierigen Geist und fragen Sie Sich, ob es Raum und Möglichkeiten gibt zum Lernen und zur Verbesserung. Vielleicht ist die Zeit jetzt da für neue Erfahrungen, für Änderungen. Sollten Sie neue Ideen oder Vorschläge haben, könnten Sie feststellen, dass andere Sie nicht verstehen oder unterstützten und dass sie nicht interessiert sind an dem, was Sie zu bieten haben. Seien Sie ihnen gegenüber klar und offen und helfen Sie sie zu sehen, woher Sie kommen und wie Sie zu Ihren Schlussfolgerungen gekommen sind. Erst dann werden sie Sie wahrscheinlich verstehen. Der Narr kann auch bedeuten, dass die Zeit gekommen ist sich umzusehen nach einem anderen Job oder eine neue Karriere anzufangen, oder sogar Ihr eigenes Unternehmen zu gründen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie entwickeln ein großes Interesse an Spiritualität und manchmal sind die Möglichkeiten und der Überfluss an Themen, aus denen Sie wählen können, überwältigend. Es kann schwierig sein, durch die Bäume den Wald zu erkennen. Sie sind auf jedem Fall noch nicht sicher, in welche Richtung Sie gehen möchten. Nehmen Sie sich Zeit, um den Weg zu finden, der Ihnen am besten passt. Beschränken Sie Sich nicht gerade jetzt, behalten Sie einen offenen Geist. Sie müssen möglicherweise auch akzeptieren, dass die Leute um Sie herum möglicherweise nicht Ihr Bedürfnis nach Spiritualität verstehen. Möglicherweise entdecken Sie auch Fähigkeiten und Talente in Sich, die Sie Sich nimmer zugetraut hätten.</p>",
                    }
                }            
            },
            {
                filename: "01",
                numerology : 1,
                en : {
                    name : "The Magician",
                    bulletpoints : ["Manifestation","Willpower","Creation"],
                    planet : "☿ - Mercury",
                    archetype : "The wizard",
                    theme : "Create your own future!",
                    description : " The Magician talks about taking action to create what you want, to act with what is potentially present.", 
                    questions : ["Do you know what your possibilities are?", "Which action could you take?", "Which could be your first step?", "What will you actually do?"],

                    soalPoints : [ "Scheppingskracht", "Wilskracht", "Vaardigheid", "Wijsheid", "Kracht", "Meesterschap en diplomatie" ],
                    soalText : "Toveren is niet magisch, het is creatief gebruik maken van al je kwaliteiten om tot een, zo goed mogelijk, resultaat te komen. Als je weet hoe het werkt, is alles eenvoudig. Je kunt alleen iets bereiken als je het ook echt wilt.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Magician invites you to look at all the possibilities, opportunities and directions you can choose from, to ponder on all life's questions. This card invites you to make better use of your spiritual, emotional and energetic powers. It asks you to be alert and active, to realize that you have the inspiration, knowledge and strength you need to face your challenges.</p><p>This card also tells you that you can be meaningful, in your own life as well as for others, maybe even for the world. You can make this a better place, even if you start with only one step.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Magician indicates that existing relationships can now grow stronger and more profound, you will be able to reach new levels of intimacy, joy and commitment. If you are looking for love you are very likely to meet someone new. Now is a good time to work on your social life, you will discover that people are attracted to you, they want to get to know you. Still, The Magician also warns you not to become selfish or self-centered, remember the world doesn’t actually revolve around you!</p>",
                        [GuidanceFields.WORK] : "<p>The Magician indicates that soon there will probably be an improvement in your work or career. The time is right to get a promotion or to find a new job, but you will have to take the first step, you will have to show initiative. Set yourself goals (raise, promotion, …) and take them up with the right people. Have faith in yourself, have faith in your talent to succeed! Just make sure you help people to see and understand your point of view by telling them about your experiences, your views. And don’t get overly emotional when discussing these topics.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may have a strong desire to spiritually grow and develop, to study and further explore certain topics. Now is the perfect time to do so. Make sure you surround yourself with kindred spirits who can teach and guide you. Right now it is very important for you to discuss the spiritual topics that interest you, to hear other people’s opinions and compare them with yours. Maybe you will even find a mentor who will stay with you for years to come.</p>",
                    }
                },
                fr : {
                    name : "Le Magicien",
                    planet : "☿ - Mercure",
                    archetype : "Le Sorcier",
                    theme : "Créez votre propre avenir!",
                    description : "Le magicien, nous parle d’agir pour réaliser ce que l'on veut, agir avec ce qui est potentiellement présent.", 
                    questions : ["Savez-vous quelles sont vos possibilités?", "Quelle action pourriez-vous prendre?", "Quel premier pas pourriez-vous faire?", "Qu'allez-vous réellement faire?"],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le magicien vous invite à voir les possibilités, les opportunités et les directions que vous pouvez choisir pour réfléchir sur tous les aspects de la vie. Cette carte vous invite à faire un meilleur usage de vos pouvoirs mentaux, émotionnels et énergétiques. Elle vous invite à être vigilant afin d'avoir suffisamment d'inspiration, de connaissances et de force pour relever les défis à venir.</p><p>Cette carte vous dit aussi que vous pouvez être utile, tant pour votre propre vie que pour celle des autres, peut-être même pour le monde entier. Vous serez peut-être en mesure de faire de ce monde un meilleur endroit, en commençant par un seul pas.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Magician indicates that existing relationships can now grow stronger and more profound, you will be able to reach new levels of intimacy, joy and commitment. If you are looking for love you are very likely to meet someone new. Now is a good time to work on your social life, you will discover that people are attracted to you, they want to get to know you. Still, The Magician also warns you not to become selfish or self-centered, remember the world doesn’t actually revolve around you!</p>",
                        [GuidanceFields.WORK] : "<p>The Magician indicates that soon there will probably be an improvement in your work or career. The time is right to get a promotion or to find a new job, but you will have to take the first step, you will have to show initiative. Set yourself goals (raise, promotion, …) and take them up with the right people. Have faith in yourself, have faith in your talent to succeed! Just make sure you help people to see and understand your point of view by telling them about your experiences, your views. And don’t get overly emotional when discussing these topics.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may have a strong desire to spiritually grow and develop, to study and further explore certain topics. Now is the perfect time to do so. Make sure you surround yourself with kindred spirits who can teach and guide you. Right now it is very important for you to discuss the spiritual topics that interest you, to hear other people’s opinions and compare them with yours. Maybe you will even find a mentor who will stay with you for years to come.</p>",
                    }
                },
                sp:{
                    name:"El Mago",
                    planet: "☿ - Mercurio",
                    archetype: "El Mago",
                    theme: "¡crea tu propio futuro!",
                    description: "El Mago se trata de tomar acción para realizar lo que quieres, de actuar con lo que está potencialmente presente", 
                    questions: ["¿Sabes qué posibilidades tienes?", "¿Qué acción podrías tomar?", "¿Qué primer paso podrías dar? ¿Qué vas a hacer?"],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El mago invita a mirar a todas las posibilidades, oportunidades y direcciones que usted puede elegir, para reflexionar sobre cuestiones de toda la vida. Esta tarjeta le invita a hacer un mejor uso de sus poderes espirituales, emocionales y energéticos. Les pide que se mantenga alerta y activo, para darse cuenta  que usted tiene la inspiración, el conocimiento y la fuerza que necesita para hacer frente a sus desafíos. Esta tarjeta también le dice que puede ser significativo, en su propia vida, así como para otros, tal vez incluso para el mundo. Usted puede hacer esto en un lugar mejor, incluso si usted comienza con un solo paso.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name: "De Magiër",
                    planet : "☿ - Mercurius",
                    archetype : "De tovenaar",
                    theme : "creëer je eigen toekomst!",
                    description : "De Magiër gaat over het in actie komen om te kunnen realiseren wat jij wilt, over gaan handelen met hetgeen in potentie aanwezig is.", 
                    questions : ["Weet jij welke mogelijkheden je hebt?", "Welke actie zou je kunnen ondernemen?", "Welke eerste stap kun je zetten?", "Wat ga jij daadwerkelijk doen?"],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Magiër nodigt je uit om naar alle mogelijkheden, kansen en richtingen te kijken waar je op dit moment uit kan kiezen. Denk goed na over alle vragen die het leven je nu stelt.</p><p>Deze kaart nodigt je ook uit om beter gebruik te maken van je spirituele, emotionele en energetische krachten. Ze vraagt je om alert en actief te zijn, om te beseffen dat jij al de nodige inspiratie, kennis en kracht in je hebt om de uitdagingen aan te gaan waarmee je geconfronteerd wordt.</p><p>Ze vertelt je ook dat je van betekenis kan zijn zowel in je eigen leven als in dat van anderen, misschien zelfs in de wereld als geheel. Je kan er een betere plek van maken, al begin je met één enkele stap.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Magiër vertelt je dat de bestaande relaties in je leven steeds sterker en diepgaander worden. Je zal nieuwe hoogten bereiken wat betreft vreugde, intimiteit en betrokkenheid. Als je op zoek bent naar een liefdesrelatie dan is het erg waarschijnlijk dat je nu iemand zal ontmoeten. Als je wil werken aan je sociale leven dan is het daarvoor nu de juiste tijd. Je zal merken dat mensen zich tot jou aangetrokken voelen, ze willen je leren kennen.</p><p>Toch is het belangrijk dat je erop let niet te egoïstisch of egocentrisch te worden want ook al ben je populair, de wereld draait niet rond jou.</p>",
                        [GuidanceFields.WORK] : "<p>Volgens De Magiër is het erg waarschijnlijk dat er binnenkort verbetering komt in je job of je carrière. Als je al lang een promotie wilde vragen of als je een nieuwe job wil vinden dan is nu het geschikte moment. Let wel, jij zal de eerste stap moeten zetten, jij zal initiatief moeten nemen. Zorg dat je de juiste doelen voor ogen houdt (opslag, promotie, ...) en dat je hierover praat met de juiste mensen. Geloof in jezelf, geloof in je talent om te slagen! Het is aan jou om de anderen te overtuigen van jouw visie en toekomstplannen door hen te vertellen over je ervaringen en inzichten. Wees niet te kwistig met je emoties terwijl je deze gesprekken voert, houd het rationeel.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het is waarschijnlijk dat je op dit moment een grote behoefte hebt aan spirituele groei en ontwikkeling. Je kijkt ernaar uit om te studeren, te evolueren en bepaalde onderwerpen grondig te onderzoeken. De Magiër vertelt je dat het perfecte moment hiervoor aangebroken is. Omring jezelf met gelijkgestemde zielen die je kunnen helpen en leiden, praat met hen, vergelijk de verschillende opinies. Misschien vind je nu zelfs een mentor die je de komende jaren kan ondersteunen bij je evolutie en groei.</p>",
                    }
                },
                de : {
                    name: "Der Magier",
                    planet : "♅ - Merkur",
                    archetype : "Der Zauberer",
                    theme : "Seine eigene Zukunft gestalten",
                    description : "Der Magier spricht über das Handeln, um das zu erreichen, was wir wollen, mit dem zu handeln, was potenziell vorhanden ist.",
                    questions : ["Wissen Sie, welche Ihre Möglichkeiten sind?", "Welche Maßnahmen könnten Sie ergreifen?", "Welchen ersten Schritt könnten Sie tun?", "Was werden Sie wirklich tun?"],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der Magier lädt Sie ein, Möglichkeiten, Chancen und Richtungen zu sehen, die Sie wählen können, um über alle Lebensfragen nachzudenken. Diese Karte bittet Sie, Ihre geistigen, emotionalen und energetischen Kräfte besser zu nützen. Diese Karte lädt Sie ein, wachsam zu sein, damit Sie über ausreichend Inspiration, Wissen und Kraft verfügen, den auf Sie zukommenden Herausforderungen begegnen zu können.</p><p>Diese Karte sagt Ihnen auch, dass Sie sinnvoll sein können, sowohl für Ihr eigenes Leben als auch für das Leben Anderer, ja vielleicht sogar für die ganze Welt. Sie können die Welt möglicherweise zu einem besseren Ort machen, es beginnt alles mit einem Schritt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Der Magier zeigt an, dass bestehende Beziehungen sich jetzt verstärken und vertiefen können und Sie sind jetzt in der Lage erhöhte Ebene in Intimität, Freude und Engagement zu erreichen. Wenn, Sie auf der Suche nach Liebe sind Sie sehr wahrscheinlich zu neuen Bekanntschaften kommen. Jetzt ist der richtige Zeitpunkt an Ihr soziales Leben zu arbeiten und Sie werden feststellen, dass die Leute sich zu Ihnen hingezogen fühlen und Sie gerne näher kennenlernen möchten. Dennoch warnt der Magier Sie auch sich nicht zu egoistisch oder egozentrisch aufzustellen. Bedenken Sie Sich, dass die Welt sich nicht wirklich um Sie alleine herum dreht!</p>",
                        [GuidanceFields.WORK] : "<p>Der Magier zeigt an, dass es bald sehr wahrscheinlich eine Verbesserung in Ihrer Arbeit oder Karriere geben wird. Die Zeit ist reif für eine Beförderung oder um einen neuen Job zu finden, aber Sie müssen den ersten Schritt machen und die Initiative dazu nehmen. Setzen Sie Sich Ziele (zu Gehaltserhöhung, Beförderung, …), und unterhalten Sie Sich darüber mit den richtigen Leuten. Haben Sie Vertrauen in sich selbst, glauben Sie an Ihr Talent, um erfolgreich zu sein! So stellen Sie sicher, dass Sie die Leute helfen Ihren Standpunkt zu sehen und zu verstehen, indem Sie Ihre Erfahrungen und Ihre Ansichten mit ihnen teilen. Und werden Sie nicht übermäßig emotional, wenn es um diese Themen handelt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie haben einen starken Wunsch, spirituell zu wachsen und sich zu entwickeln und bestimmten Themen genauer zu untersuchen und weiterzuerforschen. Jetzt ist die perfekte Zeit, um dies zu tun. Achten Sie darauf, dass Sie von Gleichgesinnten umgeben sind, die Sie unterrichten und leiten können. Gerade jetzt ist es sehr wichtig für Sie über die spirituellen Themen, die Sie interessieren die Meinung anderer Leute zu hören und sie mit ihnen zu besprechen. Vielleicht werden Sie sogar einen Mentor begegnen, der für die kommenden Jahre zu Ihnen stehen wird.</p>",
                    }
                }
            },
            {
                filename: "02",
                numerology : 2,
                en : {
                    name : "The High Priestess",
                    planet : "☽ - Moon",
                    archetype : "The maiden",
                    theme : "to know intuitively",
                    description : "The High Priestess talks about what is hidden, about patiently waiting, intuitively knowing. ",
                    questions : [ "Are you in doubt?", "Are you waiting?", "What is holding you back to continue?", "Dare you listen to you intuition?" ],
                    bulletpoints : ["Intuition", "Mysteries", "Inner Voice"],

                    soalPoints : [ "Onderbewust", "Het verborgene", "Intuïtie", "Passiviteit", "Geduld", "Innerlijke stem" ],
                    soalText : "De verborgen waarheid is datgene wat niemand kan zien maar wat van binnen wordt ervaren, het innerlijke weten. Wacht af en luister naar je innerlijke stem wanneer het tijd is om te handelen. Het allerbeste is om op dit moment de dingen te laten gebeuren.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The High Priestess symbolizes feminine values and powers: intuition, sensitivity, goodness, forgiveness, faith. She urges you to reflect on your thoughts and feelings in silence, to make room for visions and intuitive realizations.</p><p>This card indicates the unconscious powers that live within you, the dark side we all harbor. If you have the strength and courage to face those shadows, you will no longer be guided by them and be free to fully explore and utilize your feminine side.</p><p>Now is also a time to pay extra attention to serendipity and synchronicity.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>You may find yourself attracted to a person who's mysterious, hard to get to know, maybe even seeming distant and uninterested. Take your time, use your intuition, she/he may very well be \"the one\".</p><p>On the other hand it's possible you find people being really attracted to you, without you making any effort. Use this power wisely, don't succumb to superficiality but show compassion, try to be understanding and caring.</p>",
                        [GuidanceFields.WORK] : "<p>The High Priestess makes you aware that now is the time to experience your work in serenity, fully conscious and aware, to be Here Now. Don't let anything distract you from listening to your inner voice, your intuition, your heart. First you observe, then you decide. Possibly these observations will help you find new and improved ways to do your job, and those findings will help you reduce stress.</p><p>One warning: for the moment it would be unwise to participate if people are judging or criticizing colleagues in your presence.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may have known for a while that you do have spiritual abilities, and now is the time to find out what your area of expertise is and to further study and grow. If you have a problem accepting your spirituality, the way to cope with this is to study, learn, evolve. One tool you can use to achieve this is talking to people with a lot of spiritual experience. Even though some of the things they tell you may be somehow frightening, they will also help you reach a higher plane.</p>",
                    }
                },
                fr : {
                    name : "La Prêtresse",
                    planet : "☽ - Lune",
                    archetype : "La Vierge",
                    theme : "le savoir intuitif",
                    description : "La Prêtresse, nous parle de ce qui est caché, d’attendre patiemment, du savoir intuitif.",                  
                    questions : ["Hésitez-vous?", "Attendez-vous ?", "Qu’est ce qu’il vous empêche d'avancer?", "Osez-vous vraiment écouter votre intuition?"],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La grande prêtresse symbolise les valeurs et les forces féminines telles que l'intuition, la sensibilité, la bonté, le pardon, la confiance. Elle vous exhorte à méditer en silence sur vos pensées et vos sentiments, afin que l'espace devienne libre pour des visions et des intuitions.</p><p>Cette carte montre les forces inconscientes qui vivent en vous, un côté sombre que nous avons tous. Si vous avez la force et le courage d'affronter ces côtés moins connus, vous ne serez plus inconsciemment guidée par eux et libre de les explorer pleinement et d'utiliser pleinement ce côté féminin.</p><p>C'est aussi le moment d'accorder une attention particulière à la sensibilité et à la synchronicité (par exemple, vous pensez à quelqu'un et cette personne vous appelle au même moment).</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>You may find yourself attracted to a person who's mysterious, hard to get to know, maybe even seeming distant and uninterested. Take your time, use your intuition, she/he may very well be \"the one\".</p><p>On the other hand it's possible you find people being really attracted to you, without you making any effort. Use this power wisely, don't succumb to superficiality but show compassion, try to be understanding and caring.</p>",
                        [GuidanceFields.WORK] : "<p>The High Priestess makes you aware that now is the time to experience your work in serenity, fully conscious and aware, to be Here Now. Don't let anything distract you from listening to your inner voice, your intuition, your heart. First you observe, then you decide. Possibly these observations will help you find new and improved ways to do your job, and those findings will help you reduce stress.</p><p>One warning: for the moment it would be unwise to participate if people are judging or criticizing colleagues in your presence.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may have known for a while that you do have spiritual abilities, and now is the time to find out what your area of expertise is and to further study and grow. If you have a problem accepting your spirituality, the way to cope with this is to study, learn, evolve. One tool you can use to achieve this is talking to people with a lot of spiritual experience. Even though some of the things they tell you may be somehow frightening, they will also help you reach a higher plane.</p>",
                    }
                },
                sp:{
                    name:"La Suma Sacerdotisa",
                    planet: "☽ - Luna",
                    archetype: "La Virgen",
                    theme: "conocimiento intuitivo",
                    description: "La Suma Sacerdotisa es sobre lo oculto, sobre la espera paciente, sobre el conocimiento intuitivo",
                    questions: ["¿Estás dudando?", "¿Estás esperando?", "¿Qué te retiene seguir adelante?", "¿Te atreves a escuchar tu intuición?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La Sacerdotisa simboliza los valores femeninos y facultades: la intuición, la sensibilidad, la bondad, el perdón, la fe. Ella le insta a reflexionar sobre sus pensamientos y sentimientos en silencio, para dar cabida a visiones y comprensión intuitiva. Esta tarjeta indica los poderes inconscientes que viven dentro de usted, el lado oscuro en todos los puertos. Si usted tiene la fuerza y el coraje para enfrentarse a esas sombras, ya no se guiará por ellos y tener la libertad de explorar al fondo y utilizar su lado femenino. Ahora también es un momento de prestar más atención a la casualidad y la sin cronicidad.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name: "De Hogepriesteres",
                    planet : "☽ - Maan",
                    archetype : "De maagd",
                    theme : "het intuïtief weten",
                    description : "De Hogepriesteres gaat over het verborgene, over het geduldig afwachtende, over het intuïtief weten",
                    questions : [ "Twijfel je?", "Wacht je af?", "Wat houdt je tegen om verder te gaan?", "Durf jij echt te luisteren naar je intuïtie?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Hogepriesteres symboliseert vrouwelijke waarden en krachten: intuïtie, gevoeligheid, goedheid, vergeving, geloof. Ze spoort je aan om in stilte na te denken over je gedachten en gevoelens, om ruimte te maken voor inzichten, visioenen en intuïtieve bewustwording.</p><p>Deze kaart wijst je op de onbewuste krachten die in je diepste kern leven, de donkere zijde die we allemaal hebben. Als je de kracht en de moed hebt om de confrontatie aan te gaan met deze minder bekende kant, dan zal je er niet langer onbewust door overheerst worden. Je zal de vrijheid verwerven om deze vrouwelijke kant vrijuit te onderzoeken en te gebruiken.</p><p>Besteed in deze periode van je leven extra aandacht aan zogenaamde toevalligheden en aan zaken die gelijktijdig gebeuren (bvb. je denkt aan iemand en die belt je).</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Het is goed mogelijk dat je je in deze fase van je leven aangetrokken voelt tot iemand die mysterieus overkomt, iemand die je niet gemakkelijk kan doorgronden. Misschien lijkt deze persoon zelfs afstandelijk en ongeïnteresseerd. Neem de tijd om die persoon te benaderen, gebruik je intuïtie, misschien is hij/zij wel de liefde van je leven.</p><p>Aan de andere kant is het ook mogelijk dat de mensen in je omgeving zich erg tot je aangetrokken voelen zonder dat jij daar enige inspanning voor hoeft te doen. Wees wijs in het omgaan hiermee, trap niet in de val van oppervlakkigheid maar toon mededogen en begrip voor die ander, behandel de mensen die je benaderen met openheid en gevoel.</p>",
                        [GuidanceFields.WORK] : "<p>De Hogepriesteres wil je duidelijk maken dat je nu in een periode zit waarin je je werk moet benaderen met sereniteit en hoger bewustzijn, het is belangrijk om in het Hier en Nu te leven.</p><p>Luister in de eerste plaats naar je innerlijke stem, naar je hart, en zorg dat niets je daarvan kan afleiden. Het is belangrijk om nu eerst rustig te observeren en dan pas beslissingen te nemen. Je observaties kunnen je helpen om manieren van werken te vinden die minder stresserend zullen zijn.</p><p>Een tip: als collega’s kritiek hebben op anderen terwijl je erbij bent, doe er dan niet aan mee.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Waarschijnlijk ben je je er nu al enige tijd van bewust dat je spirituele vaardigheden en inzichten hebt. Nu komt het erop aan om te ontdekken waar je grootste kracht zit, welk aspect je het beste ligt en van daaruit verder te gaan onderzoeken, studeren en groeien.</p><p>Als je het moeilijk vindt om je eigen spiritualiteit te aanvaarden, dan is het nog belangrijker dat je gaat onderzoeken en groeien want dat is de manier om vrede te vinden met het spirituele aspect dat in je schuilt.</p>",
                    }
                },
                de : {
                    name: "Die Hohepriesterin",
                    planet : "☽ - Mond",
                    archetype : "Die Jungfrau",
                    theme : "Das intuitive Wissen",
                    description : "Die Priesterin spricht über das Verborgene, geduldig zu warten, über intuitives Wissen.",
                    questions : [ "Zögern Sie?", "Warten Sie zu?", "Was hindert Sie daran, voranzukommen?", "Wagen Sie es wirklich, auf Ihre Intuition zu hören?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die Hohepriesterin symbolisiert weibliche Werte und Stärken wie Intuition, Sensibilität, Güte, Vergebung und Vertrauen. Sie ermahnt Sie, über Ihre Gedanken und Gefühlen in aller Stille nachzudenken, damit Platz frei wird für Visionen und intuitive Erkenntnisse.</p><p>Diese Karte zeigt die unbewussten Kräfte, die in Ihnen leben, eine dunkle Seite, die wir alle haben. Falls Sie die Kraft und den Mut haben, diesen weniger bekannten Seiten zu begegnen, dann werden Sie sich nicht mehr unbewusst von ihnen führen lassen sondern frei sein, diese in vollem Umfang zu erforschen und  diese weibliche Seite vollständig für sich zu nutzen.</p><p>Jetzt ist auch die Zeit gekommen, Spürsinn und Synchronizität (z.B. Sie denken an jemanden und diese Person ruft Sie im gleichen Augenblick an) zusätzliche Aufmerksamkeit zu schenken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Persönliche Beziehungen und Liebe</p><p>Sie können Sich zu einer Person angezogen fühlen, die geheimnisvoll und unzugänglich, sogar vielleicht scheinbar distanziert und desinteressiert ist. Nehmen Sie Sich die Zeit, benutzen Sie Ihre Intuition, er/ sie kann sehr gut “der/die Ware”sein.</p><p>Auf der anderen Seite ist es auch möglich, Menschen zu treffen, die sich wirklich zu Ihnen angezogen fühlen, ohne jede Anstrengung Ihrerseits. Verwenden Sie diese Macht weise und neige nicht zur Oberflächlichkeit aber zeigen Sie Mitgefühl, Verständnis und Fürsorge.</p>",
                        [GuidanceFields.WORK] : "<p>Die Hohepriesterin macht Ihnen bewusst, dass jetzt die Zeit da ist, um Ihre Arbeit in Ruhe und bei vollem Bewusstsein zu erfahren, und tatsächlich jetzt da zu sein. Lassen Sie Sich nicht ablenken von Ihrer inneren Stimme, Ihrer Intuition und hören Sie auf Ihrem Herzen. Beobachten Sie zuerst bevor Sie Sich entscheiden. Möglicherweise werden diese Beobachtungen Ihnen helfen Ihre Arbeit leichter zu machen und damit Stress zu reduzieren.</p><p>Eine Warnung: Zurzeit wäre es unklug Sich einzumischen, wenn die Leute Ihre Kollegen in Ihrer Gegenwart beurteilen oder kritisieren.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie könnten für eine Weile bereits gewusst haben, dass Sie über spirituelle Fähigkeiten verfügen und jetzt ist die Zeit um herauszufinden, was genau Ihr Fachgebiet ist und es weiter kennenzulernen und zu wachsen. Falls Sie ein Problem haben um Ihre Spiritualität zu akzeptieren, sollten Sie versuchen durch weiterzustudieren, lernen und entwickeln, damit zurechtzukommen. Ein Mittel um dies zu erreichen wäre, mit Menschen ins Gespräch zu kommen, die über eine Menge spirituelle Erfahrung verfügen. Auch wenn einige Dinge, die sie sagen, manchmal irgendwie beängstigend sein können, werden sie Ihnen auch helfen eine höhere Ebene zu erreichen.</p>",
                    }
                }
            },
            {
                filename: "03",
                numerology : 3,
                en : {
                    name : "The Empress",
                    planet : "♀ - Venus",
                    archetype : "The mother",
                    theme : "growth and development, give it space",
                    description : " The Empress  talks about growth and development, about taking good care of that what is growing and needs time and space.",
                    questions : [ "What or who needs that extra attention or space?", "Can you give it the time and space needed?", "Is this the right time?" ],
                    bulletpoints : ["Fertility", "Harmony", "Nurturing"],

                    soalPoints : [ "Levendigheid", "Overvloed", "Moeder natuur", "Weelderigheid", "Groei", "Vruchtbare bodem", "Rijkdom" ],
                    soalText : "Moeder natuur biedt je een vruchtbare bodem waarop je al je plannen kunt laten groeien en bloeien. Alles wat je wenst kun je hier tot ontwikeling brengen, er is genoeg aanwezig om het ook echt te realiseren.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Empress symbolizes fertility. She depicts the ever recurring cycle of creating new life and expresses physical and spiritual renewal. She tells you that for every kind of growth there has to be patience and nurturing, love and attention.</p><p>This card also indicates that you may feel a strong need to listen to your emotions and passions, to follow your intuition. This may attract people to you, you may find that people around you want to talk to you and seek your advice. Now is a good time to share those life lessons you learnt at some or maybe great cost.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Empress indicates change and renewal as well as vivacity, abundance and pleasant developments.</p><p>You may find yourself being very popular, people wanting to be near you, to talk to you, to seek your advice. If you are single, true love may well be within reach.</p><p>If you are in a committed relationship, it may become more fertile (figuratively and sometimes even literally speaking).</p>",
                        [GuidanceFields.WORK] : "<p>You are experiencing an endless stream of creativity and resourcefulness. You inspire people with your ideas, your approach, your energy. Listen to your innermost self to make sure your creative energy doesn’t turn into nervousness or insecurity. Financially you're on a roll, make sure to share this abundance in order to keep the flow going. Right now you can’t go wrong if you just listen to your inner voice, your intuition.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may have very strong intuition and right now this sense is even further growing and developing. Embrace this gift, it's trying to send you messages, signs. Make certain you don't miss them, they have something important to tell you.</p><p>Even though you are used to people needing you, now is the time to help yourself rather than others. It is up to you to make the time to do so.</p>",
                    }
                },
                fr : {
                    name : "L'Impératrice",
                    planet : "♀ - Vénus",
                    archetype : "La mère",
                    theme : "croissance et développement, donner de l'espace à",
                    description : "L’Impératrice, nous parle de croissance et de développement, de prendre soin avec amour de ce qui a besoin de croissance, de soins et d'espace.",
                    questions : ["Quoi ou qui a besoin de plus d'attention ou d'espace? ", " Pouvez-vous donner cet espace et ce temps? ", " Est-ce le moment propice? "],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>L’impératrice symbolise la fertilité. Elle représente le cycle récurrent de la création d'une vie nouvelle et représente le renouvellement physique et spirituel. Elle vous dit que toute forme de croissance demande, patience, s’occuper, amour et attention.</p><p>Cette carte montre aussi que vous avez peut-être un fort besoin d'écouter vos sentiments, vos passions et de suivre votre intuition. Ce besoin pourrait attirer les gens vers vous. Vous apprendrez peut-être que les gens autour de vous, veulent vous parler et vous demander conseil. C'est le bon moment pour partager les leçons de la vie que vous avez apprise à un moment donné et peut-être même à un prix élevé.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Empress indicates change and renewal as well as vivacity, abundance and pleasant developments.</p><p>You may find yourself being very popular, people wanting to be near you, to talk to you, to seek your advice. If you are single, true love may well be within reach.</p><p>If you are in a committed relationship, it may become more fertile (figuratively and sometimes even literally speaking).</p>",
                        [GuidanceFields.WORK] : "<p>You are experiencing an endless stream of creativity and resourcefulness. You inspire people with your ideas, your approach, your energy. Listen to your innermost self to make sure your creative energy doesn’t turn into nervousness or insecurity. Financially you're on a roll, make sure to share this abundance in order to keep the flow going. Right now you can’t go wrong if you just listen to your inner voice, your intuition.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may have very strong intuition and right now this sense is even further growing and developing. Embrace this gift, it's trying to send you messages, signs. Make certain you don't miss them, they have something important to tell you.</p><p>Even though you are used to people needing you, now is the time to help yourself rather than others. It is up to you to make the time to do so.</p>",
                    }
                },
                sp:{
                    name:"La Emperatriz",
                    planet: "♀ - Venus",
                    archetype: "La madre",
                    theme: "crecimiento y desarrollo, dale espacio",
                    description: " La Emperatriz trata sobre el crecimiento y el desarrollo, sobre el cuidado amoroso de aquello que necesita crecimiento, cuidado y espacio",
                    questions: ["¿Qué o quién necesita más atención o espacio?", "¿Puedes darle ese espacio y ese tiempo?", "¿Es el tiempo justo?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La Emperatriz simboliza la fertilidad. Ella representa el ciclo nunca se repite de crear nueva vida y expresa la renovación física y espiritual. Ella le dice que para cada tipo de crecimiento que tiene que haber paciencia y cariño, amor y atención. Esta tarjeta también indica que usted puede sentir una fuerte necesidad de escuchar a sus emociones y pasiones, a seguir su intuición. Esto puede atraer a la gente a usted, usted puede encontrar que las personas que te rodean quieren hablar con usted y buscar su consejo. Ahora es un buen momento para compartir esas lecciones de vida que aprendió en algún o tal vez un gran costo.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name: "De Keizerin",
                    planet : "♀ - Venus",
                    archetype : "De moeder",
                    theme : "groei en ontwikkeling, geef het de ruimte",
                    description : "De Keizerin gaat over groei en ontwikkeling, over het liefdevol zorg dragen voor dat wat groei, zorg en ruimte behoeft.",
                    questions : [ "Wat of wie heeft extra aandacht of ruimte nodig?", "Kun jij die ruimte en tijd geven?", "Is het nu de jusite tijd?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Keizerin staat voor vruchtbaarheid. Ze toont je de steeds weerkerende cirkel van creatie van nieuw leven en staat dus voor lichamelijke en spirituele vernieuwing. Ze vertelt je dat er voor groei altijd nood zal zijn aan geduld en koesteren, liefde en aandacht.</p><p> Als je deze kaart trekt dan is het waarschijnlijk zo dat je een grote behoefte hebt om naar je emoties en passies te luisteren, om je intuïtie te volgen. Het is goed mogelijk dat deze instelling je extra interessant maakt voor de mensen om je heen. Je zal merken dat mensen met je willen praten, je om goede raad vragen. Daarom is het nu het ideale moment om de levenslessen die je vergaard hebt ook weer door te geven, levenslessen die je soms behoorlijk wat gekost hebben voor je ze echt doorhad. Laat anderen meegenieten van je wijsheid!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Keizerin wijst op verandering en vernieuwing maar ook op levendigheid, overvloed en positieve ontwikkelingen.</p><p>Het is goed mogelijk dat je op dit moment erg populair bent, mensen vinden het fijn om in je gezelschap te zijn, ze willen met je praten, je advies inwinnen. Als je op dit ogenblik geen relatie hebt, dan is dit een goede periode om de ware liefde te vinden.</p><p>Als je een vaste relatie hebt dan zal deze relatie alsmaar vruchtbaarder worden (in figuurlijke zin maar mogelijk ook letterlijk).</p>",
                        [GuidanceFields.WORK] : "<p>Op dit ogenblik in je leven ervaar je een eindeloze stroom van creativiteit en vindingrijkheid. Je inspireert de mensen om je heen door je aanpak, je ideeën, je energie. Luister heel goed naar je diepste zelf want het risico bestaat dat deze creatieve energie zou omslaan in zenuwachtigheid en onzekerheid. Financieel verloopt alles heel vlot, zorg ervoor dat je je eigen overvloed ook deelt met anderen zodat alles blijft stromen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment heb je waarschijnlijk een heel sterke intuïtie die zelfs nog aan het groeien en ontwikkelen is. Sluit dit geschenk in je armen, geniet ervan en luister ernaar, want het is dé manier om tekenen en boodschappen door te krijgen. Let heel goed op, mis niets, want het universum heeft je een aantal belangrijke dingen te vertellen.</p><p>Ook al ben je eraan gewend dat andere mensen je nodig hebben en op je steunen, op dit moment moet je in de eerste plaats voor jezelf zorgen en dan pas aan anderen denken.</p>",
                    }
                },
                de : {
                    name: "Die Herrscherin",
                    planet : "♀ - Venus",
                    archetype : "Die Mutter",
                    theme : "Wachstum und Entwicklung, Raum geben",
                    description : "Die Kaiserin spricht über Wachstum und Entwicklung, über die liebevolle Pflege dessen, was Wachstum, Pflege und Raum braucht.",
                    questions : [ "Was oder wer braucht mehr Aufmerksamkeit oder Raum?", "Können Sie diesen Raum und diese Zeit geben?", "Ist das der richtige Zeitpunkt?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die Herrscherin symbolisiert Fruchtbarkeit. Sie steht für den immer wiederkehrenden Zyklus der Schöpfung neuen Lebens und vertritt körperliche und geistige Erneuerung. Sie sagt Ihnen, dass für jede Art von Wachstum Geduld, Pflege, Liebe und Aufmerksamkeit erforderlich sind.</p><p>Diese Karte zeigt auch, dass Sie ein starkes Bedürfnis haben können, auf Ihre Gefühle und Ihre Leidenschaften zu hören und Ihrer Intuition zu folgen. Dieses Bedürfnis könnte sein, Menschen für sich zu gewinnen. Sie erfahren möglicherweise, dass die Menschen in Ihrer Umgebung mit Ihnen reden möchten und Sie gerne um Ihren Rat fragen. Jetzt ist ein guter Zeitpunkt, um die Lektionen des Lebens zu teilen, die Sie irgendwann und vielleicht sogar zu einem hohen Preis gelernt haben.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Persönliche Beziehungen und Liebe</p><p>Die Herscherin deutet auf Veränderung und Erneuerung sowie Lebendigkeit, Fülle und angenehme Entwicklungen.</p><p>Sie können feststellen, dass Sie sehr beliebt sind und dass die Leute in Ihrer Nähe sein wollen und mit Ihnen sprechen möchten, um Ihren Rat zu bitten. Wenn Sie alleine sind, kann die wahre Liebe sich auch innerhalb Ihrer Reichweite befinden.</p><p>Wenn Sie eine feste Beziehung haben, kann diese fruchtbarer werden (manchmal sogar buchstäblich).</p>",
                        [GuidanceFields.WORK] : "<p>Sie erleben einen endlosen Strom an Kreativität und Einfallsreichtum. Sie begeistern die Menschen mit Ihren Ideen, Ihrem Ansatz, Ihrer Energie. Hören Sie auf Ihre inneren Selbst um sicherzustellen, dass Ihre kreative Energie sich nicht in Nervosität oder Unsicherheit umwandelt. Finanziell befinden Sie Sich in Gleichgewicht. Stellen Sie Sich sicher diesen Überfluss zu teilen, damit die Strömung weiter fließen kann. In diesem Moment können Sie nichts falsch machen, wenn Sie nur auf Ihrer inneren Stimme, Ihrer Intuition hören.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie können jetzt eine sehr starke Intuition haben die noch weiter wachsen und sich entwickeln kann. Umfassen Sie dieses Geschenk, es versucht Sie ein Zeichen zu geben. Stellen Sie sicher, dass Sie es nicht verpassen. Es könnte Ihnen etwas Wichtiges zu sagen haben.</p><p>Auch wenn Sie daran gewöhnt sind, dass Menschen Sie brauchen, ist jetzt die Zeit, um Sich Selber zuerst zu helfen, mehr als jetzt andere Leute. Es liegt an Ihnen, dies der Zeit zu überlassen.</p>",
                    }
                }
            },
            {
                filename: "04",
                numerology : 4,
                en : {
                    name : "The Emperor",
                    bulletpoints : ["Structure","Authority","Power"],
                    zodiac : "♈ - Aries",
                    archetype : "The father",
                    theme : "dealing with structure",
                    description : " The Emperor talks about structure, justice and order, defining and upholding boundaries, directing your own life (your own kingdom).", 
                    questions : [ "Are you being clear?", "Do you uphold your boundaries?", "Do you need to put structures in place?", "Could you have too many structures at hand?" ],

                    soalPoints : [ "Structuur", "Vaste vorm", "Nuchterheid", "Realiteit", "Verstrarring", "Leider", "Beperken", "Praktisch" ],
                    soalText : "Structuur is datgene wat je nodig hebt om zaken een bepaalde vorm te geven. Hierdoor komt er orde en overzicht. Het verwezenlijken van je wensen kun je doen door jezelf geen illusies te maken, consequent te zijn en spijkers met koppen te slaan. Draag daarbij de verantwoording en concentreer je op dat wat aanwezig is.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Emperor doesn't leave any room for coincidence. Stability, security, structure, rigidity and perfectionism are the values he stands for. He depicts man's struggle for independence, his intent to place himself above the rules of nature and maybe going a bit overboard. Still, The Emperor offers you valuable qualities such as responsibility and pragmatism.</p><p>If you draw this card the time may be right for putting rationality over emotion. When you are confronted with hard decisions and difficult choices, it is often wise to stay focused, to use your brain.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In personal relationships you are a stable, secure and trustworthy person, but you have to remember that good relationships need maintaining. If you don’t take care of a relationship it will get an expiration date before you know it. Accept changes and try to be flexible and patient, if you don’t you willrun the risk of becoming overbearing and rigid. </p>",
                        [GuidanceFields.WORK] : "<p>The Emperor's favorite task is creating order out of chaos. He is steadfast, logical and determined. If you are looking for a job it is very important to try and find out how others perceive you, to analyze (and even write down) the reasons why they should choose you for a certain position. It is up to you to convince them of your unique qualities. No matter what your job is, to succeed now you'll need discipline and dedication. </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You tend to approach everyday life with logic and reason but you also have a strong need for spiritual development and growth you sometimes neglect. Remember that spirituality can benefit from a logical approach.Remember that logic and spirit are not contradictory. Actually, they are both an important and integral part of who you are.</p>",
                    }
                },
                fr : {
                    name : "L'Empereur",
                    zodiac : "♈ - Bélier",
                    archetype : "Le père",
                    theme : "gérer la structure",
                    description : "L'empereur nous parle d’une question de structure, d'ordre et d'autorité, de limites et de garde, de diriger sa propre vie (votre propre empire).", 
                    questions : ["Êtes-vous clair?", "Gardez-vous vos propres limites?", "Êtes-vous sensé indiquer une structure?", "Êtes-vous peut-être trop structuré?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>L’empereur ne quitte pas une pièce par accident. Stabilité, sécurité, structure, fermeté et perfectionnisme sont les valeurs qu'il représente. Il montre la lutte de l'homme pour l'indépendance, se plaçant au-dessus des règles de la nature et peut-être même en allant un peu trop loin. Néanmoins, l’empereur vous offre des qualités précieuses telles que la responsabilité et le pragmatisme.</p><p>Si vous tirez cette carte, il est peut-être temps de mettre la raison avant l'émotion. Lorsque vous êtes confronté à des décisions dures et difficiles, il est souvent conseillé d'être rationnel et concentré afin de pouvoir agir raisonnablement.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In personal relationships you are a stable, secure and trustworthy person, but you have to remember that good relationships need maintaining. If you don’t take care of a relationship it will get an expiration date before you know it. Accept changes and try to be flexible and patient, if you don’t you willrun the risk of becoming overbearing and rigid. </p>",
                        [GuidanceFields.WORK] : "<p>The Emperor's favorite task is creating order out of chaos. He is steadfast, logical and determined. If you are looking for a job it is very important to try and find out how others perceive you, to analyze (and even write down) the reasons why they should choose you for a certain position. It is up to you to convince them of your unique qualities. No matter what your job is, to succeed now you'll need discipline and dedication. </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You tend to approach everyday life with logic and reason but you also have a strong need for spiritual development and growth you sometimes neglect. Remember that spirituality can benefit from a logical approach.Remember that logic and spirit are not contradictory. Actually, they are both an important and integral part of who you are.</p>",
                    }
                },
                sp:{
                    name:"El Emperador",
                    zodiac: "♈ - Carnero",
                    archetype: "El padre",
                    theme: "cómo tratar con la estructura",
                    description: "El Emperador es sobre la estructura, sobre el orden y la autoridad, sobre establecer y guardar límites, sobre gobernar tu propia vida (tu propio imperio)", 
                    questions: ["¿Estás claro?", "¿Estás protegiendo sus propios límites", " ¿Estás quizás demasiado estructurado?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Emperador no deja lugar a la casualidad. La estabilidad, la seguridad, la estructura, la rigidez y el perfeccionismo son los valores que representa. Él representa la lucha del hombre por la independencia, su intención de colocar a su mismo por encima de las reglas de la naturaleza y tal vez ir un poco por la borda. Aún así, el emperador le ofrece valiosas cualidades como la responsabilidad y el pragmatismo. Si saca esta tarjeta el tiempo puede ser adecuado para poner racionalidad sobre la emoción. Cuando uno se enfrenta con decisiones difíciles y opciones difíciles, a menudo es aconsejable mantener la concentración, para usar su cerebro.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name: "De Keizer",
                    zodiac : "♈ - Ram",
                    archetype : "De vader",
                    theme : "omgaan met structuur",
                    description : "De keizer gaat over structuur, over orde en gezag, over grenzen stellen en bewaken, over het regiseren van je eigen leven (je eigen keizerrijk)", 
                    questions : [ "Bij jij duidelijk?", "Bewaak jij je eigen grenzen?", "Dien jij structuur aan te geven?", "Ben jij wellicht te gestructureerd?" ], 
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Keizer laat geen ruimte voor toeval.</p><p> Stabiliteit, nuchterheid, structuur, ernst en perfectionisme zijn de waarden waar hij voor staat. Geef jij deze eigenschappen voldoende ruimte in je leven? De Keizer symboliseert ook de strijd voor onafhankelijkheid, het doel dat de mens nastreeft om boven de wetten van de natuur uit te stijgen. Misschien overdrijft hij hier zelfs een beetje in. Toch heeft De Keizer waardevolle eigenschappen te bieden zoals verantwoordelijkheid en rationaliteit.</p><p>Als je deze kaart kiest dan is de tijd waarschijnlijk rijp om eerder verstandelijk dan emotioneel te werk te gaan. Als je nu zware beslissingen moet nemen, moeilijke keuzes moet maken, dan is het verstandig om rationeel en gefocust te zijn, om je hersenen te gebruiken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In persoonlijke relaties ben je een stabiel, zelfzeker en betrouwbaar iemand. Probeer wel te onthouden dat echt goede relaties ook onderhouden nodig hebben, ze blijven niet vanzelf goed. In een relatie moet je investeren maar ook meegroeien, evolueren. Aanvaard veranderingen en probeer soepel en geduldig te zijn, anders loop je het risico dat je te dominant en streng wordt.</p>",
                        [GuidanceFields.WORK] : "<p>De favoriete bezigheid van De Keizer is om orde te scheppen vanuit chaos. Hij is standvastig, logisch en vastberaden. Als je op zoek bent naar werk dan is het erg belangrijk dat je nu aandacht besteedt aan de manier waarop anderen naar je kijken, je ervaren. Analyseer de redenen waarom ze voor jou zouden moeten kiezen in verband met een bepaalde job. Het is nu aan jou om hen te overtuigen van je unieke kwaliteiten.</p><p>Het maakt niet uit welk werk je doet, om nu succes te hebben, heb je discipline en toewijding nodig. Let er ook op dat je gestructureerd te werk gaat.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je hebt de neiging om het dagelijkse leven aan te pakken met logica en redeneren, maar je hebt ook een sterke behoefte aan spirituele ontwikkeling en groei en die behoefte verwaarloos je soms. Onthoud goed dat je spiritualiteit ook op een logische manier kan benaderen, logica en “spirit” zijn niet met mekaar in tegenspraak. Integendeel, ze zijn beide een belangrijk onderdeel van wie jij bent en je kan ze gecombineerd gebruiken om spiritueel te groeien.</p>",
                    }
                },
                de : {
                    name: "Der Herrscher",
                    zodiac : "♈ - Widder",
                    archetype : "Der Vater",
                    theme : "Die Struktur verwalten",
                    description : "Der Herrscher spricht über die Frage von Struktur, Ordnung und Autorität, Grenzen und Schutz, um sein eigenes Leben (sein eigenes Reich) zu lenken",
                    questions : [ "Sind Sie im Klaren?", "Halten Sie Ihre eigenen Grenzen ein?", "Sollten Sie eine Struktur bestimmen?", "Sind Sie vielleicht zu strukturiert?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der Herrscher verlässt keinen Raum zufällig. Stabilität, Sicherheit, Struktur, Festigkeit und Perfektionismus sind die Werte, für die er steht. Er zeigt den Kampf des Menschen um Unabhängigkeit, wobei er sich über die Regeln der Natur stellt und dabei vielleicht auch ein bisschen übertreibt. Trotzdem bietet der Herrscher Ihnen wertvolle Qualitäten wie Verantwortung und Pragmatismus an.</p><p>Wenn Sie diese Karte ziehen kann es an der Zeit sein,  die Vernunft über das Gefühl zu stellen. Wenn Sie mit harten und schwierigen Entscheidungen konfrontiert sind, ist es oft ratsam, rational und konzentriert zu sein, um vernünftig entscheiden und handeln zu können.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Persönliche Beziehungen und Liebe</p><p>In persönlichen Beziehungen Sie sind eine stabile, sichere und vertrauenswürdige Person, aber Sie müssen bedenken, dass gute Beziehungen Pflege brauchen. Wenn Sie Sich nicht kümmern um eine Beziehung, wird sie zu Ende gehen bevor Sie es wissen. Akzeptieren Sie Änderungen und versuchen Sie biegsam und geduldig zu sein. Sonst besteht die Gefahr, dass Sie anmaßend und starr werden.</p>",
                        [GuidanceFields.WORK] : "<p>Lieblingsaufgabe des Herschers ist Ordnung aus Chaos zu schaffen. Er ist standhaft, logisch und entschlossen. Wenn Sie auf der Suche nach einem Job sind ist es sehr wichtig zu versuchen um herauszufinden, wie andere Sie wahrnehmen, um die Gründe zu analysieren (und sogar aufzuschreiben), warum sie Sie für eine bestimmte Position auswählen sollten. Es liegt an Ihnen, sie von Ihren einzigartigen Qualitäten zu überzeugen. Egal, was Ihr Beruf jetzt auch ist, um erfolgreich zu sein, müssen Sie über Disziplin und Hingabe verfügen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie neigen dazu, den Alltag mit Logik und Vernunft zu betrachten aber Sie haben auch ein starkes Bedürfnis nach spiritueller Entwicklung und Wachstum, die Sie manchmal vernachlässigen. Denken Sie daran, dass ein logischer Ansatzt die Spiritualität nutzen kann. Logik und Geist sind nicht miteinander in Widerspruch. Eigentlich sind sie beide wichtige und integrale Teile Ihres Seins.</p>",
                    }
                }
            },
            {
                filename: "05",
                numerology : 5,
                en : {
                    name : "The Hierophant",
                    bulletpoints : ["Knowledge","Wisdom","Dogma"],
                    zodiac : "♉ - Taurus",
                    archetype : "The teacher",
                    theme : "gaining and passing on knowledge",
                    description : "The Hierophant talks about learning and teaching, finding your individuality, norms and values, learning (life) lessons.", 
                    questions : [ "Are you learning a (life) lesson?", "Which problem are you encountering time and again?", "Can you communicate about it?" ], 

                    soalPoints : [ "Vertrouwen", "Diep geloof", "Innerlijke leraar", "Religie", "Wijsheid in spirituele zaken", "Godsdienst", "Traditie", "Rituelen" ],
                    soalText : "Geloven is een overtuiging die je hebt en het weten dat deze ook is zoals jij denkt dat hij is. Het kan je kracht geven om door te gaan, houvast in moeilijke tijden en zekerheid door vertrouwen.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is sometimes called The High Priest. It symbolizes faith and profound trust. Faith is a matter of heart and soul, one doesn't need proof. Faith is founded on deep inner knowledge, and gives one great trust and inner peace.</p><p>The Hierophant stands for trust, self-confidence and belief in the future. It symbolizes a clear and distinct awareness of what's right and wrong. Still, maybe you'll ask yourself if what's generally right is also the right thing to do for you personally.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Hierophant teaches you that the trust and affection you feel towards others is slowly building which indicates that your relationships are growing and intensifying. If you are involved in a romantic relationship it will very likely be growing stronger, more profound. You're becoming more aware of your ideals and principles and your (personal) virtues will be defining your behavior. </p>",
                        [GuidanceFields.WORK] : "<p>In your work you are looking for depth, you are trying to find out if your job is really what you want to be doing in your heart of hearts. Still, The Hierophant may confront you with a dilemma: is it time to go looking for a different job or career, or should you be loyal and remain where you are?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Even if you are not a religious person, right now you are focused on spirituality. It's possible that your vision of spirituality conflicts with opinions and views of others around you. To find inner balance, it might be a good idea to afford yourself the time to meditate and try and create new rituals, these will bring you structure and harmony. </p>",
                    }
                },
                fr : {
                    name : "Le Hierophant",
                    zodiac : "♉ - Taureau",
                    archetype : "L'enseignant",
                    theme : "acquérir et transmettre la connaissance",
                    description : "Le Pape, nous parle d’apprendre et d’apprendre de, de (re)trouver son individualité, ses valeurs et ses normes, d’apprendre des leçons (de vie).", 
                    questions : ["Apprenez-vous une leçon (de vie)?", "Quel problème rencontrez-vous à chaque fois?", "Pouvez-vous communiquer à propos de ce sujet?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte est aussi connue sous le nom de Grand Prêtre. Il symbolise la foi et la confiance profonde. La foi est une question de cœur et d'âme et n'a besoin d'aucune preuve. La foi est basée sur une profonde connaissance intérieure et offre une grande confiance et la paix intérieure.</p><p>L'Hiérophante représente exactement cette confiance, confiance en soi et confiance en l'avenir. Il symbolise une conscience claire et sans ambiguïté de ce qui est bien et de ce qui est mal. Néanmoins, il peut être judicieux de vous demander si ce qui serait normalement bien pour vous est vraiment bon pour vous personnellement. Après tout, chaque situation est unique en son genre.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Hierophant teaches you that the trust and affection you feel towards others is slowly building which indicates that your relationships are growing and intensifying. If you are involved in a romantic relationship it will very likely be growing stronger, more profound. You're becoming more aware of your ideals and principles and your (personal) virtues will be defining your behavior. </p>",
                        [GuidanceFields.WORK] : "<p>In your work you are looking for depth, you are trying to find out if your job is really what you want to be doing in your heart of hearts. Still, The Hierophant may confront you with a dilemma: is it time to go looking for a different job or career, or should you be loyal and remain where you are?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Even if you are not a religious person, right now you are focused on spirituality. It's possible that your vision of spirituality conflicts with opinions and views of others around you. To find inner balance, it might be a good idea to afford yourself the time to meditate and try and create new rituals, these will bring you structure and harmony. </p>",
                    }
                },
                sp:{
                    name:"El Sumo Sacerdote",
                    zodiac: "♉ - Toro",
                    archetype: "El maestro",
                    theme: "adquisición y transmisión de conocimientos",
                    description: "El Sumo Sacerdote se trata de aprender á y aprender de encontrar la individualidad de uno mismo, de valores y normas, de aprender lecciones (de la vida)", 
                    questions: ["¿Estás aprendiendo una lección (de vida)?", "¿con qué te encuentras siempre? ", "¿Puedes comunicarte al respecto?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es a veces llamado el sumo sacerdote. Simboliza la fe y la confianza profunda. La fe es un asunto del corazón y el alma, uno no necesita pruebas. La fe se basa en el conocimiento interior profundo, y le da una gran confianza y la paz interior. El Hierofante representa la confianza, la confianza en su mismo y la fe en el futuro. Simboliza una conciencia clara y distinta de lo que es correcto y lo incorrecto. Aún así, tal vez usted se pregunta si lo que es generalmente correcto es también lo que hay que hacer por usted personalmente.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name: "De Hogepriester",
                    zodiac : "♉ - Stier",
                    archetype : "De leraar",
                    theme : "kennis opdoen en doorgeven",
                    description : "De hogepriester gaat over leren aan en leren van, over het vinden van je eigenheid, over waarden en normen, over het leren van (levens) lessen", 
                    questions : [ "Ben jij een (levens) les aan het leren?", "Waar loop je steeds tegenaan?", "Kun je er over communiceren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart wordt soms ook De Hiërophant genoemd. Ze staat voor geloof en diep vertrouwen. Geloof speelt zich af in je hart en je ziel, je hebt geen bewijzen nodig. Geloof heeft een diep innerlijk weten als basis, en je krijgt er intens vertrouwen en innerlijke vrede door.</p><p>De Hogepriester staat voor vertrouwen, zelfvertrouwen en geloof in de toekomst. Hij staat ook voor een duidelijk en scherp bewustzijn van wat goed en fout is.</p><p>Als je hiermee geconfronteerd wordt in jouw leven, sta er dan toch even bij stil of datgene wat in het algemeen als goed beschouwd wordt, ook het juiste is voor jou. Tenslotte zijn alle situaties, alle te nemen beslissingen uniek.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Hogepriester leert je dat het vertrouwen en de genegenheid die je voelt voor anderen geleidelijk aan het vermeerderen zijn. Dit betekent dat je relaties nu groeien en meer diepgang krijgen. Als je op het ogenblik een liefdesrelatie hebt dan is het erg waarschijnlijk dat de relatie aan het evolueren is, intenser aan het worden is. Je wordt je ook steeds meer bewust van je idealen en principes en je persoonlijke deugden en waarden zullen je gedrag steeds meer gaan bepalen.</p>",
                        [GuidanceFields.WORK] : "<p>Je bent in je werk op zoek naar diepgang. Je bent aan het uitzoeken of de baan die je nu hebt echt wel datgene is wat je heel diep vanbinnen wil doen. Toch kan De Hogepriester je ook confronteren met een dilemma: is het tijd om een andere baan of carrière te overwegen of is het beter om loyaal te zijn en te blijven waar je nu bent? Vertrouw op jezelf èn op het universum, dan komt de juiste beslissing vanzelf in je op.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Zelfs als je geen religieus persoon bent, je zal toch merken dat je op het ogenblik nogal gefocust bent op het spirituele. Ook is het beste mogelijk dat het beeld dat jij hebt van spiritualiteit indruist tegen de meningen en visies van anderen. Om innerlijk evenwicht te vinden is het belangrijk dat je jezelf de tijd gunt om te mediteren en nieuwe rituelen voor jezelf te creëren. Als je dit doet dan zal je merken dat je je leven structureel en op een harmonieuze manier verrijkt.</p>",
                    }
                },
                de : {
                    name: "Der Hierophant",
                    zodiac : "♉ - Stier",
                    archetype : "Der Lehrmeister",
                    theme : "Wissen erwerben und vermitteln",
                    description : "Der Hierophant spricht über das Lernen generell,  über das Lernen, unsere Individualität, seine Werte und Normen (wieder) zu finden, um (Lebens-) Lektionen zu lernen.", 
                    questions : [ "Lernen Sie eine (Lebens-)Lektion?", "Welches Problem treffen Sie jedes Mal an?", "Können Sie über dieses Thema kommunizieren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte wird auch als der Hohepriester bezeichnet. Sie symbolisiert Glauben und tiefes Vertrauen. Der Glaube ist eine Sache des Herzens und der Seele, und braucht keinen Beweis. Der Glaube ist auf tiefem, innerem Wissen begründet und bietet großes Vertrauen und inneren Frieden.</p><p>Der Hierophant steht für genau dieses Vertrauen, Selbstbewusstsein und Vertrauen in die Zukunft. Er symbolisiert ein klares und deutliches Bewusstsein davon, was richtig und falsch ist. Trotzdem kann es sinnvoll sein, sich zu fragen, ob das, was generell richtig scheint, auch tatsächlich für Sie persönlich richtig ist. Schließlich ist jede Situation auf ihre eigene Weise einzigartig.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Der Hierophant lehrt Sie, dass Vertrauen und Zuneigung anderen gegenüber sich langsam aufbauen und Ihre Beziehungen wachsen und intensiver werden. Wenn Sie in einer romantischen Beziehung beteiligt sind, wird diese sich sehr wahrscheinlich weiter vertiefen und verstärken. Sie sind sich zunehmend bewusst von Ihren Idealen und Grundsätzen und Ihre (persönlichen) Tugenden werden Ihr Verhalten bestimmen.</p>",
                        [GuidanceFields.WORK] : "<p>In Ihrer Arbeit werden Sie Tiefe suchen. Sie werden versuchen herauszufinden, ob Ihr Job wirklich den ist, was Sie in Ihrem tiefsten Herzen tun möchten. Dennoch kann der Hierophant Sie mit einem Dilemma konfrontieren: ist es Zeit auf der Suche zu gehen nach einem anderen Job oder einer neuen Karriere, oder sollten Sie treu sein und bleiben, wo Sie sind?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Auch wenn Sie nicht ein religiöser Mensch sind, sind Sie im Augenblick auf Spiritualität konzentriert. Es ist wohl möglich, dass Sie Ihre Meinung über spirituelle Konflikte sich stößt mit Meinungen und Ansichten der Leute in Ihrer Umgebung. Um das innere Gleichgewicht zu finden, könnte es eine gute Idee sein, Sich die Zeit zu nehmen zum Meditieren und damit zu versuchen neue Rituale zu schaffen die Ihnen neue Struktur und Gleichgewicht bringen könnten.</p>",
                    }
                }
            },
            {
                filename: "06",
                numerology : 6,
                en : {
                    name : "Lovers",
                    bulletpoints : ["Unity","Harmony","Love"],
                    zodiac : "♊ - Gemini",
                    archetype : "The choice",
                    theme : "follow your hearts’ desire",
                    description : "The Lovers talk about making free choices, following your hearts’ desire, uniting the male and female.", 
                    questions : [ "Do you need to make a choice?", "Is it your choice?", "Are you following your hearts’ desire in order to make yourself feel good?" ], 

                    soalPoints : [ "Keuze van het hart", "Volmondig ja-zeggen", "Beslissing", "Liefde", "Liefdeservaring", "Verbinding" ],
                    soalText : "Keuze van het hart is dat waar je voor kiest met alles wat je in je hebt. Er zijn hierbij geen alternatieven die openblijven en ook geen voorbehouden. Het is een volledig ja-zeggen tegen die ene persoon of situatie.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Lovers symbolizes love being a higher cause between two people. Attraction, love, friendship, uniting opposites … when two people are mirroring eachother, these feelings heighten their consciousness.</p><p>Sometimes this card represents the doubts you have when being confronted with difficult choices. Often, when you have to choose, it is hard to decide if you should listen to your heart or to your head, because both may have valid points. The Lovers gives you the wisdom and courage to choose wholeheartedly. That is why this card is also called \"The Decision\".</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Lovers confronts you with the fact that there's a great love in your life. This love deeply touches you and you will have to make a choice. When you choose for this love then you have to make sure you do so with all your heart and soul. You have to be convinced you're making the right choice. The Lovers motivates you to be truly honest with yourself, to realize there are no fairytales, no knights in shining armor. When choosing for love, you accept the other person as is, without reservations.</p>",
                        [GuidanceFields.WORK] : "<p>The Lovers makes you realize you will have to make a decision. Maybe it's time to change jobs or careers, maybe there are some aspects of your current job that need changing. Analyze all available information before you choose, when looking more closely, a negative may turn into a positive.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card tells you the time may have come to start a spiritual relationship and probably the right person has already appeared in your life. Take your time to study the wisdom this person shares with you, but remember to question everything while listening to yourself. Do not blindly follow, but keep filtering all information by running it by your intuition.</p>",
                    }
                },
                fr : {
                    name : "Les Amoureux",
                    zodiac : "♊ - Gémeaux",
                    archetype : "Le choix",
                    theme : "suivre le choix de votre cœur",
                    description : "Les amoureux, nous parle de choisir en toute liberté, de suivre le choix de son cœur, d’ unir le masculin et le féminin.", 
                    questions : ["Devez-vous faire un choix?", "Choisissez vous-même?", "Suivez-vous votre cœur afin de vous sentir bien?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte \"Les Amoureux\" fait souvent référence à une relation intense que vous avez avec quelqu'un. C'est généralement une relation intime et romantique. Il symbolise l'amour comme une affaire supérieure entre deux personnes. L’attraction, le coup de foudre, l'amitié, la tendance à unir les opposés quand deux personnes se reflètent. Ce sentiment favorise la prise de conscience, la perspicacité et la croissance.</p><p>Parfois, cette carte représente aussi le doute et l'ambivalence que vous ressentez lorsque vous êtes confronté à des décisions difficiles. Lorsque vous devez prendre une décision, il est souvent difficile de déterminer si vous devez écouter votre cœur ou votre raison, car les deux sont précieux. \"Les amoureux\" vous demandent de décider de tout votre cœur pour la sagesse et le courage. Pour cette raison, cette carte sera également appelée « la Décision ».</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Lovers confronts you with the fact that there's a great love in your life. This love deeply touches you and you will have to make a choice. When you choose for this love then you have to make sure you do so with all your heart and soul. You have to be convinced you're making the right choice. The Lovers motivates you to be truly honest with yourself, to realize there are no fairytales, no knights in shining armor. When choosing for love, you accept the other person as is, without reservations.</p>",
                        [GuidanceFields.WORK] : "<p>The Lovers makes you realize you will have to make a decision. Maybe it's time to change jobs or careers, maybe there are some aspects of your current job that need changing. Analyze all available information before you choose, when looking more closely, a negative may turn into a positive.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card tells you the time may have come to start a spiritual relationship and probably the right person has already appeared in your life. Take your time to study the wisdom this person shares with you, but remember to question everything while listening to yourself. Do not blindly follow, but keep filtering all information by running it by your intuition.</p>",
                    }
                },
                sp:{
                    name:"Los Enamorados",
                    zodiac: "♊ - Gemelos",
                    archetype: "La elección",
                    theme: "sigue la elección de tu corazón",
                    description: "Los Enamorados se trata de elegir desde la libertad, de seguir la elección de tu corazón, de unir lo masculino y lo femenino", 
                    questions: ["¿Deberías elegir?", "¿Te eliges a ti mismo?", "¿Sigues a tu corazón para que se sienta bien por ti?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Los amantes simbolizan el amor de ser una causa más elevada entre dos personas. Atracción, amor, amistad, uniendo los opuestos ... cuando dos personas están reflejando entre sus mismos, estos sentimientos aumentan su conciencia. A veces, esta carta representa las dudas que usted tiene cuando se enfrenta a decisiones difíciles. A menudo, cuando usted tiene que elegir, es difícil decidir si debe escuchar a su corazón o a la cabeza, ya que ambos pueden tener puntos válidos. Los amantes le dan la sabiduría y el coraje para elegir de todo corazón. Es por ello que esta tarjeta también se llama \"La Decisión\".</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name: "De Geliefden",
                    zodiac : "♊ - Tweelingen",
                    archetype : "De keuze",
                    theme : "de keuze van je hart volgen",
                    description : "De geliefden gaat over kiezen vanuit vrijheid, over de keuze van je hart volgen, over het verenigen van het mannelijke en het vrouwelijke.", 
                    questions : [ "Dien jij een keuze te maken?", "Kies jij zelf?", "Volg jij je hart, zodat het voor jou goed voelt?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart, De Geliefden, gaat bijna altijd over een intieme, sterke relatie tussen twee personen en gaat dus vaak over een liefdesrelatie. De kaart symboliseert dan deze liefde als een belangrijk doel. Aantrekkingskracht, liefde, vriendschap, het verenigen van tegengestelden ... als twee mensen mekaar spiegelen dan kunnen deze gevoelens hun bewustzijn (en daardoor ook hun groei en inzichten) sterk verhogen.</p><p>Soms staat deze kaart ook voor de twijfels die je kunnen overvallen als je geconfronteerd wordt met belangrijke keuzes. Als je moet kiezen dan is het vaak moeilijk om te beslissen of je naar je hart of naar je hoofd moet luisteren. Beide hebben immers hun eigen unieke standpunten en kwaliteiten.</p><p>Als je De Geliefden trekt, dan belooft deze kaart je de wijsheid en moed om met volle overtuiging je keuze te maken. Daarom noemt men deze kaart ook soms “De Keuze”.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Geliefden confronteert je met de realiteit van een grote, diepe liefde in je leven. Je wordt intens geraakt door deze liefde en je zal een keuze moeten maken. Als je besluit om voor deze liefde te kiezen, zorg er dan voor dat je het ook echt doet met hart en ziel. Je moet er helemaal van overtuigd zijn dat je de juiste keuze maakt. De Geliefden wil je motiveren om echt eerlijk te zijn met jezelf, om te beseffen dat sprookjes niet bestaan, net zomin als prinsen op een wit paard. Als je voor de liefde kiest, dan aanvaard je de ander zoals hij/zij is, zonder voorbehoud.</p>",
                        [GuidanceFields.WORK] : "<p>De Geliefden wil dat je je ervan bewust wordt dat het tijd is om een keuze te maken. Misschien is de tijd gekomen om van baan of van carrière te veranderen, of misschien zijn er bepaalde aspecten of onderdelen van je huidige baan waar je verandering in wil brengen. Voordat je een keuze maakt, is het belangrijk om alle aspecten van je huidige baan grondig te analyseren, na observatie kan iets wat negatief lijkt best in iets positiefs veranderen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart vertelt je dat het waarschijnlijk een goed moment is in je leven om een spirituele relatie te gaan opbouwen met iemand. Het is best mogelijk dat de juiste persoon zelfs al in je omgeving is. Als je met deze persoon een gesprek aangaat, neem dan ruim de tijd om de wijsheid die hij/zij met jou deelt te bestuderen, maar onthoud tegelijkertijd dat het goed is om alles in vraag te stellen als je hart je dat aangeeft.</p>",
                    }
                },
                de : {
                    name: "Die Liebenden",
                    zodiac : "♊ - Zwillinge",
                    archetype : "Die Entscheidung",
                    theme : "Der Wahl seines Herzens folgen",
                    description : "Die Liebenden sprechen davon, dass wir uns frei entscheiden sollen, der Wahl unseres Herzens zu folgen, das Männliche und das Weibliche zu vereinen.", 
                    questions : [ "Müssen Sie eine Entscheidung treffen?", "Wählen Sie selbst?", "Folgen Sie Ihrem Herzen, um Sich wohl zu fühlen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte „ Die Liebenden“ bezieht sich häufig auf eine intensive Beziehung, die Sie zu jemandem haben. Es handelt sich meistens um eine intime, romantische Beziehung. Sie symbolisiert Liebe als eine höhere Angelegenheit zwischen zwei Menschen. Anziehungskraft,  Liebe, Freundschaft, die Neigung, Gegensätze zu vereinen, wenn zwei Menschen sich gegenseitig spiegeln. Dieses Gefühl fördert Bewusstsein, Einsicht und Wachstum.</p><p>Manchmal steht diese Karte auch für Zweifel und Ambivalenz, die Sie erfahren, wenn Sie mit schwierigen Entscheidungen konfrontiert sind. Wenn Sie eine Entscheidung treffen müssen, ist es oft schwer zu bestimmen, ob Sie auf Ihr Herz oder auf Ihre Vernunft hören sollen, weil beide wertvoll sind. „Die Liebenden“ bitten Sie, sich von ganzem Herzen für Weisheit und Mut zu entscheiden. Aus diesem Grund wird diese Karte auch „die Entscheidung“ genannt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Die Liebenden konfrontieren Sie mit der Tatsache, dass es eine große Liebe in Ihrem Leben gibt. Diese Liebe berührt Sie tief, und Sie müssen eine Entscheidung treffen. Wenn Sie für diese Liebe wählen, dann müssen Sie sicherstellen, dass Sie dies mit ganzem Herzen und ganzer Seele tun. Sie müssen davon überzeugt sein, dass Sie die richtige Wahl treffen. Die Liebenden motiviert Sie, um wirklich ehrlich mit Sich Selbst zu sein, um anzuerkennen, dass es keine Märchen gibt, keine Helfer in der Not. Bei der Auswahl für diese Liebe müssen Sie die andere Person völlig und ganz akzeptieren, wie sie ist, ohne Vorbehalten.</p>",
                        [GuidanceFields.WORK] : "<p>Die Liebenden deuten darauf hin, dass Sie eine Entscheidung treffen müssen. Vielleicht ist es Zeit, um der Arbeitsplatz oder die Karriere zu ändern. Vielleicht auch gibt es einige Aspekte in der aktuellen Arbeit, die Änderung benötigen. Analysieren Sie alle verfügbaren Informationen, bevor Sie sich entscheide. Wenn man genauer hinschaut, kann eine negative Information sich in eine positive verwandeln.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Diese Karte zeigt Ihnen, dass die Zeit gekommen sein kann, um eine spirituelle Beziehung anzufangen und wahrscheinlich ist die richtige Person bereits in Ihrem Leben erschienen. Nehmen Sie sich Zeit, um die Weisheit zu betrachten, die diese Person mit Ihnen teilt, aber denken Sie daran, alles infrage zu stellen, was Sie mit Sich Selbst überlegen. Folgen Sie nicht blind, aber filtern Sie alle Informationen und lassen Sie Sich führen durch Ihre Intuition.</p>",
                    }
                }
            },
            {
                filename: "07",
                numerology : 7,
                en : {
                    name : "The Chariot",
                    bulletpoints : ["Control","Balance","Willpower"],
                    zodiac : "♋ - Cancer",
                    archetype : "going on",
                    theme : "define your objective and go for it",  
                    description : "The Chariot talks about purposefully doing your own thing, defining your own objective, knowing your objective, focus on the objective.",   
                    questions : [ "Do you know what you want?", "What is your objective?", "Can you purposely take further steps to achieve this objective?" ],

                    soalPoints : [ "Nieuw (zelf)bewustzijn", "Doorbreken van routine", "Afwerpen van overgenomen opvattingen", "Vertrek naar het nieuwe", "Kracht", "Moed" ],
                    soalText : "Het vertrek is het loslaten van oude patronen en een begin van nieuwe doelen. ",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Chariot symbolizes goal oriented energy that may result in a big leap forward. It's a very positive card that urges you to get out of your comfort zone and choose a new direction. You are mainly motivated by your ambition, determination and a longing for freedom.  It's a very energetic card, the purpose being to direct this energy towards a specific, carefully chosen goal.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>A new relationship may be in the making. Before you embark on this new adventure, make sure you have properly ended the previous one. Try to do this with respect and compassion.</p><p>The Chariot may also indicate that your relationship is stuck in a rut and it's time to get rid of old patterns, habits and opinions. Or maybe someone wants a relationship with you and you aren't interested; if this is the case, let him/her down gently but firmly.</p>",
                        [GuidanceFields.WORK] : "<p>The Chariot stands for triumph, accomplishment.You have taken a big step forward on the road to success. You haven't yet reached all of your goals but you're well on the way. Still, you should be careful not to overestimate yourself. You know very well what you want to accomplish and which sacrifices you are prepared to make in order to get there.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>As far as spirituality goes it may be a good time for you to travel, and not just literally. Explore new subjects, different ways of thinking, domains you have yet to get to know. You have the energy and stamina to go great lengths. Maybe you should finally take that course you've been talking about for ages, or go to that meditation camp you read about. Trying out and learning new things will have benefits you can't even imagine yet.</p>",
                    }
                },
                fr : {
                    name : "Le Chariot",
                    zodiac : "♋ - Cancer",
                    archetype : "continuer",
                    theme : "Fixer un objectif et aller de l’avant",  
                    description : "Le Chariot, nous parle de suivre délibérément sa propre voie, c'est définir son propre but, c'est (re-)connaître son but, pour pouvoir s'y concentrer.",   
                    questions : ["Savez-vous ce que vous voulez?", "Quel est votre objectif?", "Pouvez-vous réellement faire un pas, avancer, pour atteindre votre objectif?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le Chariot symbolise l'énergie orientée vers le but qui peut mener à un grand saut en avant. C'est une carte très positive qui vous met au défi de quitter votre zone de confort et de découvrir de nouvelles voies. Vous êtes motivé avant tout par l'ambition, la détermination et le désir de liberté. Il s'agit d'une carte très énergique dont le but est de vous diriger, vous et votre énergie, vers un but précis et soigneusement choisi.</p><p>Le seul avertissement que cette carte vous donne est de ne pas surestimer votre force et de ne pas trop compter sur elle car elle pourrait ne pas être suffisante.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>A new relationship may be in the making. Before you embark on this new adventure, make sure you have properly ended the previous one. Try to do this with respect and compassion.</p><p>The Chariot may also indicate that your relationship is stuck in a rut and it's time to get rid of old patterns, habits and opinions. Or maybe someone wants a relationship with you and you aren't interested; if this is the case, let him/her down gently but firmly.</p>",
                        [GuidanceFields.WORK] : "<p>The Chariot stands for triumph, accomplishment.You have taken a big step forward on the road to success. You haven't yet reached all of your goals but you're well on the way. Still, you should be careful not to overestimate yourself. You know very well what you want to accomplish and which sacrifices you are prepared to make in order to get there.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>As far as spirituality goes it may be a good time for you to travel, and not just literally. Explore new subjects, different ways of thinking, domains you have yet to get to know. You have the energy and stamina to go great lengths. Maybe you should finally take that course you've been talking about for ages, or go to that meditation camp you read about. Trying out and learning new things will have benefits you can't even imagine yet.</p>",
                    }
                },
                nl : {
                    name: "De Zegewagen",
                    zodiac : "♋ - Kreeft",
                    archetype : "verder gaan",
                    theme : "stel je doel en ga ervoor",  
                    description : "De zegewagen gaat over doelbewust je eigen weg gaan, over je eigen doel bepalen, over het kennen van je doel, om je je daar op te kunnen richten.",   
                    questions : [ "Weet jij wat je wilt?", "Wat is je doel?", "Kun jij daadwerkelijk een stap zetten, verder gaan, om je doel te bereiken?" ],                 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Zegewagen staat voor doelgerichte energie die als resultaat zal hebben dat je een grote stap vooruit zet. Dit is een heel positieve kaart die je aanspoort om buiten je eigen comfortzone te treden en nieuwe, voorheen onbekende richtingen te kiezen. Je belangrijkste motieven zullen waarschijnlijk ambitie, vastberadenheid en een verlangen naar vrijheid zijn.</p><p>Dit is een sterk energetische kaart die als betekenis heeft dat ze je energie stuurt in de richting van een specifiek, zorgvuldig uitgekozen doel.</p><p>Er schuilt slechts één waarschuwing in deze kaart: wees niet té zelfzeker want dan zou je tekort kunnen schieten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Je staat mogelijk op het punt om aan een nieuwe relatie te beginnen. Het is erg belangrijk dat je je vorige relatie op een zuivere en liefdevolle manier afsluit voor je aan dit nieuwe avontuur begint.</p><p>De Zegewagen kan je er soms op wijzen dat je met je relatie een beetje in een sleur zit en dat het tijd wordt dat je oude patronen gaat loslaten.</p><p>De Zegewagen kan ook betekenen dat iemand in jou geïnteresseerd is maar dat jij niet hetzelfde voelt. Als je je in deze situatie bevindt, wees dan liefdevol maar kordaat in het afwijzen van die persoon.</p>",
                        [GuidanceFields.WORK] : "<p>De Zegewagen staat symbool voor triomf, vaardigheid, volbrenging. Je hebt een grote stap vooruit gezet op je weg naar succes. Dit betekent niet dat je al je doelen al bereikt hebt maar je zit absoluut in de goede richting. Toch is het belangrijk om voorzichtig te zijn en jezelf niet te overschatten. Je weet eigenlijk heel goed wat je wil bereiken en tot welke opofferingen je bereid bent om tot bij je doel te geraken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In verband met je spiritualiteit is het nu een goede tijd om te reizen, en dat niet alleen letterlijk. Onderzoek nieuwe onderwerpen, bekijk andere denkwijzen, vergaar informatie over materie die je nog niet bekeken had. Je hebt de energie en het uithoudingsvermogen om veel werk te verzetten. Misschien is nu wel het goede moment om eindelijk die cursus te volgen waar je al zo lang over aan het praten en nadenken bent, of om met die meditatie te beginnen waar je over gelezen hebt. Je kan je nu nog niet voorstellen hoe goed je je zal voelen als je nieuwe dingen bijleert en uitprobeert. Ga ervoor!</p>",
                    }
                },
                sp:{
                    name:"El Carro",
                    zodiac : "♋ - Langosta",
                    archetype: "continuar",
                    theme: "Establece tu objetivo y hazlo",  
                    description: "El Carro  se trata de seguir tu propio camino deliberadamente, de definir tu propio objetivo, de conocer tu objetivo, para poder concentrarte en él",   
                    questions: ["¿Sabes lo que quieres?", "¿Cuál es tu objetivo?", "¿Puedes realmente dar un paso, ir más allá, para alcanzar tu objetivo?" ],   
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El carro simboliza objetivo orientado energía que puede resultar en un gran salto adelante. Es una tarjeta muy positiva que le insta a salir de su zona de confort y elegir una nueva dirección. Usted está motivado principalmente por su ambición, determinación y un anhelo de libertad. Es una tarjeta muy energética, con el propósito de dirigir esta energía hacia una meta específica, cuidadosamente elegido.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                de: {
                    name: "Der Wagen",
                    zodiac : "♋ - Krebs",
                    archetype : "Aufbruch",
                    theme : "Sich ein Ziel setzenund vorwärts gehen.",  
                    description : "Der Wagen spricht darüber, dass Sie bewusst Ihren eigenen Weg gehen, Ihr eigenes Ziel definieren, Ihr Ziel (wieder) erkennen, um sich darauf konzentrieren zu können.",   
                    questions : [ "Wissen Sie, was Sie wollen", "Was ist Ihr Ziel", "Können Sie wirklich einen Schritt machen, vorankommen, um Ihr Ziel zu erreichen?" ],                 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der Wagen symbolisiert zielorientierte Energie, die zu einem großen Sprung vorwärts führen kann. Dies ist eine sehr positive Karte, die Sie herausfordert, Ihre Komfort-Zone zu verlassen und neue Wege zu entdecken. Sie werden vor allem durch Ehrgeiz, Zielstrebigkeit und einer Sehnsucht nach Freiheit motiviert. Dies ist eine sehr energische Karte mit dem Ziel, Sie und Ihre Energie auf ein bestimmtes, sorgfältig ausgewähltes Ziel hin zu lenken.</p><p>Die einzige Warnung, die Ihnen diese Karte gibt, ist die, Ihre Kraft nicht zu überschätzen und sich nicht zu sehr auf sie zu verlassen, weil sie nicht ausreichend sein könnte.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Eine neue Beziehung kann auf Sie zukommen. Bevor Sie Sich mit diesem neuen Abenteuer einlassen, sollen Sie Sich sicher sein, dass die vorherige Beziehung ordnungsgemäß abgeschlossen ist. Versuchen Sie das mit Respekt und Mitgefühl zu tun.</p><p>Der Wagen kann auch bedeuten, dass die heutige Beziehung fest gelaufen ist und dass es jetzt die Zeit ist, um alte Muster, Gewohnheiten und Meinungen zu beseitigen. Oder vielleicht ist jemand interessiert in einer Beziehung mit Ihnen aber Sie nicht; Sollte dies der Fall sein, lassen Sie ihn/ sie vorsichtig, aber fest entschlossen gehen.</p>",
                        [GuidanceFields.WORK] : "<p>Der Wagen steht für Triumph und Leistung. Sie haben einen großen Schritt vorwärts gemacht auf dem Weg zum Erfolg. Obwohl Sie noch nicht alle Ziele erreicht haben, sind Sie einen Stück auf dem Wege. Dennoch sollten Sie darauf achten, Sich nicht zu überschätzen. Sie wissen sehr gut, was Sie erreichen wollen und welche Opfer Sie bereit sind zu machen um dorthin zu gelangen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>So weit es Spiritualität anbelangt kann es jetzt eine gute Zeit für Sie sein zu reisen, und nicht nur buchstäblich. Entdecken Sie neue Themen, unterschiedliche Denkweisen, Terräne, die Sie noch nicht kennengelernt haben. Sie haben die Energie und Ausdauer zu großen Strecken. Vielleicht sollten Sie endlich mal das Studium machen, worüber Sie schon seit Ewigkeiten reden, oder fahren Sie in dieses Meditation Lager wovon Sie gelesen haben. Ausprobieren und neue Dinge entdecken könnte Vorteile haben, von denen Sie noch gar keine Vorstellung haben.</p>",
                    }
                }
            },
            {
                filename: "08",
                numerology : 8,
                en : {
                    name : "Strength",
                    bulletpoints : ["Confidence","Inner Strength","Courage"],
                    zodiac : "♌ - Leo",
                    archetype : "inner strength",
                    theme : "using your inner strength",    
                    description : "Strength talks about the importance of putting your inner strength at work even if it seems insurmountable.",
                    questions : [ "Are you a procrastinator?", "Is something holding you back?", "What could you tackle?", "What is it that you should no longer postpone?" ],

                    soalPoints : [ "Moed", "Levenslust", "Hartstocht", "Passie", "Vurigheid", "Dierlijke instincten", "(Scheppings)kracht", "Oerkracht", "Geduld", "Zachtheid" ],
                    soalText : "Kracht is de natuurlijke drift die aangeeft hoe sterk je levenslust is. Het is je oerkracht, instinct of dierlijke kracht die in jou verborgen zit. Deze komt, als het goed is, soms wel eens aan de oppervlakte.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card depicts the man basic primal strength possesses, emerging from profound inner harmony. Once a person has learnt to control his/her emotions and to open the gates towards higher consciousness, all unconscious strength, passion and courage emerge. This card shows that only when you have lived through and transformed basic animal instincts, only then can your values be truly noble. Among others, Strength is about mind over matter.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card emphasizes zeal and physical passion in all kinds of personal relationships. It may describe a relationship that seems to be deep and passionate but turns out to be short lived and lack consistence, once the passion is gone. Only as long as the relationship lasts one feels strong and powerful, to quickly lose that strength once the relationship is over. Then it turns out that there never was any true inner strength. On the other hand: if you are in a strong relationship, right now it is virtually unbreakable! When you are single and you draw this card, the time may be exactly right for you to go out and meet new people, you will find yourself very confident and outgoing.</p>",
                        [GuidanceFields.WORK] : "<p>You are likely to succeed at any goal you set yourself because you are very passionate and energetic right now. Still, you only experience this drive as long as you are motivated; lose motivation and you will find the energy lacking. This is a good time to look for a new job or to go for that promotion or raise you've been wanting for a long time. Don't underestimate yourself, you have a multitude of options!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card reminds you of your strength, you probably are much stronger than you think or realize. As long as you pay equal attention to every aspect of your being (spiritual, physical, intellectual, …) you will discover a great source of inner strength. Be patient and persistent, go through your fears and you will reach goals you never thought possible. </p>",
                    }
                },
                fr : {
                    name : "La Force",
                    zodiac : "♌ - Lion",
                    archetype : " propre moyen",
                    theme : "utiliser ses propre moyens",    
                    description : "Le Force, nous parle de l'importance d'utiliser ses propres moyens, même si on a l'impression d’être confronté à un sentiment insurmontable.",
                    questions : ["Êtes-vous procrastinateur?", "Y-a-t-il quelque chose que vous faites à contrecœur?", "Que pourriez-vous faire?", "Que devriez-vous enfin faire?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente la force primordiale que l'homme possède et qui provient de son harmonie intérieure la plus profonde. Une fois qu'une personne a appris à contrôler ses sentiments et à ouvrir la porte à une conscience supérieure, les forces inconscientes, la passion et le courage coulent ensemble. Le pouvoir vous motive également à utiliser votre énergie pour vous concentrer positivement sur ce que vous voulez réaliser. Cette carte montre que vos valeurs sont vraiment nobles que si vous avez vécu et transformé l'instinct animal. Entre autres choses, la force représente finalement l'esprit dominant la matière.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card emphasizes zeal and physical passion in all kinds of personal relationships. It may describe a relationship that seems to be deep and passionate but turns out to be short lived and lack consistence, once the passion is gone. Only as long as the relationship lasts one feels strong and powerful, to quickly lose that strength once the relationship is over. Then it turns out that there never was any true inner strength. On the other hand: if you are in a strong relationship, right now it is virtually unbreakable! When you are single and you draw this card, the time may be exactly right for you to go out and meet new people, you will find yourself very confident and outgoing.</p>",
                        [GuidanceFields.WORK] : "<p>You are likely to succeed at any goal you set yourself because you are very passionate and energetic right now. Still, you only experience this drive as long as you are motivated; lose motivation and you will find the energy lacking. This is a good time to look for a new job or to go for that promotion or raise you've been wanting for a long time. Don't underestimate yourself, you have a multitude of options!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card reminds you of your strength, you probably are much stronger than you think or realize. As long as you pay equal attention to every aspect of your being (spiritual, physical, intellectual, …) you will discover a great source of inner strength. Be patient and persistent, go through your fears and you will reach goals you never thought possible. </p>",
                    }
                },
                sp:{
                    name:"La Fuerza",
                    zodiac: "♌ - León",
                    archetype: "poder propio",
                    theme: "usar tu propio poder",    
                    description: "La Fuerza se trata de la importancia de usar tu propio poder, incluso si crees que se siente como escalar una montaña ",
                    questions: ["¿Eres un retrasado?", "¿(Tienes hipo)? Qué odías", "¿Qué podrías hacer?", "¿Qué deberías hacer finalmente?" ],                  
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta representa la fuerza primigenia básica que el hombre posee, al salir de una profunda armonía interior. Una vez que una persona ha aprendido a controlar su / sus emociones y para abrir las puertas hacia una conciencia superior, toda la fuerza inconsciente, pasión y coraje emergen. Esta tarjeta muestra que sólo cuando se ha vivido y se transforma instintos animales básicos, entonces sólo es posible que sus valores sean verdaderamente noble. Entre otros, la fuerza es acerca de la mente sobre la materia.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name: "De Kracht",
                    zodiac : "♌ - Leeuw",
                    archetype : "eigen kracht",
                    theme : "je eigen kracht inzetten",    
                    description : "Kracht gaat over het belang van het inzetten van je eigen kracht, zelfs als je daar gevoelsmatig als een berg tegenop ziet.",
                    questions : [ "Ben jij een uitsteller?", "Is er iets waar je tegenaan hikt?", "Wat zou jij aan kunnen pakken?", "Wat zou je eindelijk eens moeten doen?" ],                  
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart gaat over de fundamentele primaire kracht die elk mens bezit, een kracht die voortkomt uit diepgaande innerlijke harmonie. De Kracht stimuleert je ook om deze energie te gebruiken in positieve zin: kijk naar wat je wil in plaats van naar wat je niet wil.</p><p>Als je geleerd hebt om je gevoelens in de hand te houden en je hebt de poorten naar een hoger bewustzijn geopend, dan komt alle onbewuste kracht, passie en moed die je in je hebt tevoorschijn. Deze kaart vertelt je dat je eerst doorheen je fundamentele dierlijke instincten heen moet gaan, je moet ze erkennen en beleven.</p><p>Pas daarna kan je aan de slag om bewust je eigen morele waarden en kracht vormen. Daarom kan je de betekenis van deze kaart samenvatten als: de kracht van de geest die het materiële, het fysieke overwint.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Kracht benadrukt dat vuur en fysieke passie belangrijk zijn in alle soorten relaties. Deze kaart kan bijvoorbeeld een relatie beschrijven die in eerste instantie intens en gepassioneerd is, maar die uiteindelijk kort en inhoudsloos blijkt te zijn op het moment dat de passie uitdooft. Je voelt je als persoon sterk zolang deze relatie duurt, maar als ze dan eindigt, verlies je al die kracht heel snel en blijkt het zo te zijn dat er eigenlijk nooit sprake was van echte innerlijke sterkte. Aan de andere kant: als je relatie sterk is dan is ze op dit moment ook echt onbreekbaar!</p><p>Als je deze kaart trekt dan is het voor jou een goede tijd om erop uit te trekken en nieuwe mensen te ontmoeten, want je hebt erg veel zelfvertrouwen.</p>",
                        [GuidanceFields.WORK] : "<p>Je bent op dit moment erg gepassioneerd en energiek. Daarom is het waarschijnlijk dat je slaagt in alle doelen die je voor jezelf vooropstelt. Toch is het zo dat je deze energie enkel voelt als je echt gemotiveerd bent. Als je er niet in slaagt om gemotiveerd te blijven dan merk je dat je ook je energie stilaan kwijtraakt.</p><p>Als je een nieuwe baan wil of je wil promotie of opslag vragen, dan is het nu het juiste moment. Onderschat jezelf niet, er liggen veel wegen open voor jou, je hebt meer opties dan je op het eerste zicht zou vermoeden!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart herinnert je aan jouw eigen innerlijke kracht, je bent waarschijnlijk veel sterker dan je zelf denkt of beseft. Het is op dit moment vooral belangrijk dat je evenveel aandacht besteedt aan alle aspecten van je zelf: spiritueel, fysiek, intellectueel, ... Als je dat doet dan zal je in jezelf een diepe bron van innerlijke kracht vinden die je zal helpen om dingen aan te pakken waar je eerder tegenop zag. Wees geduldig en houd vol, ga door je angsten heen en je zal doelen bereiken die je voorheen onbereikbaar achtte!</p>",
                    }
                },
                de : {
                    name: "Die Kraft",
                    zodiac : "♌ - Löwe",
                    archetype : "Eigene Mittel und Wege",
                    theme : "Seine eigenen Mittel einsetzen",    
                    description : "Die Kraft spricht über die Bedeutung des Einsatzes der eigenen Mittel, auch wenn man den Eindruck hat, mit einem unüberwindbaren Gefühl konfrontiert zu werden.",
                    questions : [ "Sind Sie ein Zauderer?", "Gibt es etwas, was Sie widerstrebend tust?", "Was könnten Sie tun?", "Was sollten Sie endlich tun?" ],                  
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte stellt die Urkraft dar, die der Mensch besitzt und die aus seiner tiefsten inneren Harmonie stammt. Sobald ein Mensch gelernt hat, seine Gefühle unter Kontrolle zu halten und die Tür zum höheren Bewusstsein zu öffnen, fließen unbewusste Kräfte, Leidenschaft und Mut zusammen. Die Kraft motiviert Sie zudem, Ihre Energie für einen positiven Fokus auf dasjenige anzuwenden, was Sie erreichen wollen, mehr als auf das, was Sie möchten. Diese Karte zeigt, dass Ihre Werte nur dann wirklich edel sind, wenn Sie die tierischen Instinkte durchlebt und transformiert haben. Unter anderem steht Kraft schließlich für Macht des Geistes über Materie.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte betont Eifer und körperliche Leidenschaft in allen Arten von persönlichen Beziehungen. Es kann eine Beziehung sein, die tief und leidenschaftlich scheint, aber die sich herausstellt als eine kurzfristige wo die Konsistenz fehlt, sobald die Leidenschaft weg ist. Nur so lange, wie die Beziehung dauert man sich stark und mächtig fuhlt. Sobald die Beziehung aber vorbei ist, verliert man schnell wieder die Kraft. Dann stellt sich heraus, dass es niemals eine wahre innere Kraft gegeben hat. Auf der anderen Seite: Wenn Sie in diesem Moment in einer starken Beziehung sind, ist sie praktisch unzerbrechlich! Wenn Sie alleine sind und Sie diese Karte ziehen, kann dieser Moment genau der richtige für Sie sein auszugehen und neue Leute kennenzulernen. Sie fühlen Sich jetzt voller Vertrauen und haben Lust zum Ausgehen.</p>",
                        [GuidanceFields.WORK] : "<p>Sie erreichen zurzeit wahrscheinlich jedes Ziel, dass Sie Sich stellen, weil Sie im Moment sehr leidenschaftlich und energisch sind. Dennoch werden Sie diese Energie nur erleben, solange Sie motiviert sind. Wenn, die Motivation verschwindet wird Ihnen auch die Energie fehlen. Dies ist ein guter Zeitpunkt, um einen neuen Job zu suchen oder für die Gehaltserhöhung oder Beförderung zu gehen, die Sie Sich schon lange gewünscht haben. Unterschätzen Sie Sich nicht, Sie haben eine Vielzahl von Möglichkeiten!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Diese Karte erinnert Sie an Ihre Stärke und Sie sind wahrscheinlich viel stärker als Sie denken oder als Sie Sich realisieren. Solange Sie auf jedem Aspekt Ihres Seins achten (die spirituellen, körperlichen, geistigen, …) werden Sie eine große Quelle Ihrer inneren Kraft entdecken. Seien Sie geduldig und ausdauernd, gehen Sie durch Ihre Ängste und Sie werden Ziele erreichen, die Sie nie für möglich gehalten haben.</p>",
                    }
                }
            },
            {
                filename: "09",
                numerology : 9,
                en : {
                    name : "The Hermit",
                    bulletpoints : ["Seeking Truth","Contemplation","Solitude"],
                    zodiac : "♍ - Virgo",
                    archetype : "the old wise man",
                    theme : "daring to trust ones’ own wisdom", 
                    description : "The Hermit talks about knowing, inner peace, daring to trust your inner wisdom.",
                    questions : [ "What are you good at?", "Do you have an idea of what you know?", "Dare you trust your inner wisdom?" ],

                    soalPoints : [ "Zoeken", "Introvert", "Afzondering", "Dieper inzicht", "Wijsheid", "Geslotenheid", "Weten wat echt belangrijk is", "Alleen-leven" ],
                    soalText : "Zelfbezinning is het goed overdenken van dingen, (zelf)bewust bezig zijn.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card shows the virtues of loneliness, of withdrawal from public life and repentance.  It's about an introvert way of life, introspection necessary to come closer to your True Self.  In order to attain this, you disconnect from external stimuli, you leave the outside world for a while. When you are in this phase of your life you may be confronted with two extremes: an in depth confrontation with your inner thoughts and feelings and the heightened insights you gain. It's very important to allow yourself the time to be alone, to clear your head, to not worry. This is not a time for brooding but for observing.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Hermit informs you it is quite possible that right now you are feeling lonely, even when being in a relationship. This may be a sign telling you that the loneliness is within yourself, that you need to reconnect with your True Self. It may also mean that you're inquisitive about the true meaning of this relationship and the security it's offering you. You are well and truly searching for insight. If at this time a former partner tries to reconnect, be sure to ask yourself if this really is what you want, even if it may come in handy right now.</p>",
                        [GuidanceFields.WORK] : "<p>The Hermit tells you that, at this time, you're reflecting on your job or career. Is this the job you really want to do? Are you happy? Does the job suit you? You may need to talk about this, but make sure that the opinions of others only inspire you and do not direct you. At the end of the day it's important for you to retreat to your own private space and sense if you really should choose another job, one that truly fits.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This is a time of being alone by choice, of retreating from everyday life, of centering yourself and your spiritual energy. You are focused on ways to get closer to your True Self, maybe through meditation, contemplation, spiritual exercises or even fasting. Possibly someone will come into your life who can guide you, coach you on this journey. Even if at times it is hard, it will certainly be worth the effort!</p>",
                    }
                },
                fr : {
                    name : "L'Ermite",
                    zodiac : "♍ - Vierge",
                    archetype : "le sage",
                    theme : "Oser s'appuyer sur sa propre sagesse", 
                    description : "L'Ermite, nous parle de savoir ce que/qui vous êtes, de la paix intérieure, de faire confiance à notre propre sagesse.",
                    questions : ["Quels sont vos points forts?", "Savez-vous réellement ce que vous savez?", "Osez-vous faire confiance à votre propre sagesse?" ],
 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente les vertus de la solitude, du retrait de la vie publique et de la pénitence. Il s'agit d'un mode de vie introverti dans un monde bruyant, afin de se rapprocher de soi à travers l'auto-observation nécessaire. Pour y parvenir, vous devez vous séparer des stimuli extérieurs et ignorer le monde extérieur pendant un certain temps. Dans cette phase de votre vie, vous pouvez être confronté à deux expériences : L’une, la confrontation avec vos pensées et sentiments intérieurs et l’autre, l'amélioration de vos connaissances, vous gagnez à travers cette confrontation : Il est très important de prendre le temps d'être seul, de se vider la tête et de ne pas s'inquiéter. Ce n'est pas le moment de ruminer, mais d'observer, d'accepter ce qui s'en vient. Il s'agit donc d’observer le ruisseau et pas de le suivre.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Hermit informs you it is quite possible that right now you are feeling lonely, even when being in a relationship. This may be a sign telling you that the loneliness is within yourself, that you need to reconnect with your True Self. It may also mean that you're inquisitive about the true meaning of this relationship and the security it's offering you. You are well and truly searching for insight. If at this time a former partner tries to reconnect, be sure to ask yourself if this really is what you want, even if it may come in handy right now.</p>",
                        [GuidanceFields.WORK] : "<p>The Hermit tells you that, at this time, you're reflecting on your job or career. Is this the job you really want to do? Are you happy? Does the job suit you? You may need to talk about this, but make sure that the opinions of others only inspire you and do not direct you. At the end of the day it's important for you to retreat to your own private space and sense if you really should choose another job, one that truly fits.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This is a time of being alone by choice, of retreating from everyday life, of centering yourself and your spiritual energy. You are focused on ways to get closer to your True Self, maybe through meditation, contemplation, spiritual exercises or even fasting. Possibly someone will come into your life who can guide you, coach you on this journey. Even if at times it is hard, it will certainly be worth the effort!</p>",
                    }
                },
                sp:{
                    name:"El Ermitaño",
                    zodiac: "♍ - Virgen",
                    archetype: "el viejo sabio",
                    theme: "Atreverse a confiar en su propia sabiduría", 
                    description: "El Ermitaño se trata de saber lo que eres, de la paz interior, de atreverse a confiar en tu propia sabiduría",
                    questions: ["¿En qué eres bueno?", "¿Sabes lo que sabes?", "¿Te atreves a confiar en tu propia sabiduría?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta muestra las virtudes de la soledad, de la retirada de la vida pública y el arrepentimiento. Se trata de una forma introvertida de la vida, la introspección necesaria para acercarse a su verdadero yo. Para lograr esto, se desconecta de los estímulos externos, de salir al mundo exterior por un tiempo. Cuando usted está en esta etapa de su vida es posible que se enfrentan con dos extremos: un enfrentamiento en profundidad con sus pensamientos y sentimientos y las percepciones aumentadas usted gana. Es muy importante que le permita tener el tiempo para estar solo, para aclarar su cabeza, que no se preocupe. Este no es un tiempo para cavilaciones pero para la observación.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name: "De Kluizenaar",
                    zodiac : "♍ - Maagd",
                    archetype : "de oude wijze man",
                    theme : "durven vertrouwen op je eigen wijsheid", 
                    description : "De kluizenaar gaat over weten wat je, over innerlijke rust, over het durven vertrouwen op je eigen wijsheid.",
                    questions : [ "Waar ben jij goed in?", "Weet jij eigenlijk wat je weet?", "Durf jij te vertrouwen op je eigen wijsheid?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart toont de positieve kracht van eenzaamheid, van je terugtrekken uit het sociale leven om tot inkeer te komen. Het gaat hier om een leven gericht op het innerlijke, over de introspectie die je nodig hebt om dichter bij je Ware Zelf te komen. Om dit te bereiken is het belangrijk dat je je afsluit voor prikkels en indrukken, dat je de buitenwereld even loslaat. Als je dit stadium in je leven bereikt hebt, dan kan je met twee ervaringen geconfronteerd worden: je krijgt een intense confrontatie met je diepste gedachten en gevoelens, en net daardoor bereiken de inzichten die je hierdoor krijgt een ongekend hoog niveau.</p><p>Het is erg belangrijk dat je jezelf de tijd gunt om alleen te zijn, om je hoofd leeg te maken, om niet na te denken. Het is nu niet de juiste tijd om te piekeren maar wel om te observeren, gewoon bekijken en aanvaarden wat op je afkomt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Kluizenaar vertelt je dat het goed mogelijk is dat je je op het moment eenzaam voelt, ook al heb je een liefdesrelatie. Dit kan een teken zijn dat de eenzaamheid vanbinnen zit, vanuit jezelf komt en dat je dringend weer in contact moet komen met je Innerlijke Zelf. Als je een relatie hebt, is het mogelijk dat je je vragen aan het stellen bent over de ware betekenis van deze relatie en de veiligheid en geborgenheid die je erin vindt. Je bent op dit moment echt op zoek naar inzichten. Het is mogelijk dat je in deze periode een vorige partner ontmoet die contact zoekt met jou en de oude relatie weer nieuw leven wil inblazen. Stel jezelf de vraag of dit echt is wat je wil of dat het enkel een gemakkelijkheidsoplossing zou zijn. Misschien is het zinvoller om te werken aan je huidige relatie.</p>",
                        [GuidanceFields.WORK] : "<p>Volgens deze kaart is het belangrijk om op het ogenblik diep na te denken over je baan of carrière. Wil je deze baan echt wel hebben? Ben je gelukkig? Past deze baan bij jou? Je hebt er behoefte aan om dit te bespreken met de mensen om je heen maar let er wel op dat de meningen van anderen je enkel inspireren, laat je niet in een bepaalde richting duwen.</p><p>Als je alle nodig informatie verzameld hebt, is het aan jou om je terug te trekken op je eigen plek en dan te beslissen of je huidige baan de juiste is of dat je op zoek moet gaan naar een andere, eentje die echt bij je past.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Als je eerlijk bent met jezelf dan weet je dat je in een periode zit waarin je er de voorkeur aan geeft om alleen te zijn, om je terug te trekken uit het dagelijkse leven en je te focussen op jezelf en je spirituele energie. Je bent op zoek naar manieren om dichter bij je Ware Zelf te komen. Misschien wil je dit doel bereiken door te mediteren, door bespiegeling, spirituele oefeningen of misschien zelfs door vasten. Het is waarschijnlijk dat er nu iemand in je leven komt die je kan gidsen, coachen op deze spirituele reis.</p>",
                    }
                },
                de : {
                    name: "Der Eremit",
                    zodiac : "♍ - Jungfrau",
                    archetype : "Der Weise",
                    theme : "Es wagen, sich auf seine eigene Weisheit zu verlassen.", 
                    description : "Der Eremit spricht darüber zu wissen, wer oder was wir sind, über den inneren Frieden, über das Vertrauen in unsere eigene Weisheit.",
                    questions : [ "Was sind Ihre Stärken?", "Wissen Sie wirklich, was Sie wissen?", "Trauen Sie sich, Ihrer eigenen Weisheit zu vertrauen?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für die Tugenden der Einsamkeit, des Rückzugs aus dem öffentlichen Leben und der Buße. Es geht um eine introvertierte Lebensweise in einer lauten Welt, um durch die notwendige Selbstbeobachtung näher zu Ihrem wahren Selbst zu kommen. Um dies zu erreichen, müssen Sie sich von äußeren Reizen trennen und die Außenwelt für eine Weile ignorieren. In dieser Phase Ihres Lebens können Sie mit zwei Erfahrungen konfrontiert sein:  Mit einer Konfrontation mit Ihren inneren Gedanken und Gefühlen und mit den verbesserten Einsichten, die Sie durch diese Konfrontation gewinnen: Es ist sehr wichtig, sich die Zeit zu nehmen, um allein zu sein, um den Kopf frei zu machen und sich keine Sorgen zu machen. Dies ist keine Zeit für Grübelei, sondern für Beobachtung, für das Akzeptieren, was auf Sie zukommt. Es geht also darum, den Strom zu beobachten und nicht darum, um mit ihm zu schwimmen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Der Eremit sagt Ihnen, dass es durchaus möglich ist, dass Sie Sich gerade jetzt einsam fühlen, auch wenn Sie gerade in einer Beziehung sind. Dies kann ein Zeichen sein, das besagt, dass diese Einsamkeit sich in Sie Selbst befindet und dass Sie mit Ihrem wahren Selbst wieder in Kontakt treten müssen. Es kann auch bedeuten, dass Sie Sich nicht klar sind über die wahre Bedeutung dieser Beziehung und über die Sicherheit, die sie Ihnen gibt. Sie sind wahrhaft auf der Suche nach Einsicht. Wenn zu diesem Zeitpunkt ein ehemaliger Partner versucht, die Verbindung wieder herzustellen, müssen Sie sich fragen, ob das wirklich ist, was Sie wollen, auch wenn es sich zu diesem Zeitpunkt als nützlich erscheinen lässt.</p>",
                        [GuidanceFields.WORK] : "<p>Der Eremit sagt Ihnen, dass Sie im Moment auf Ihrem Job oder Karriere reflektiert sind. Ist das die Arbeit, die Sie wirklich wollen? Sind Sie glücklich? Passt die Arbeit Ihnen? Möglicherweise müssen Sie darüber reden, aber stellen Sie sicher, dass die Meinungen der Anderen Sie nicht nur inspirieren können sondern auch führen. Am Ende des Tages ist es wichtig für Sie, um Sich in Ihren eigenen privaten Raum zurückziehen und Sich zu fragen, ob Sie Sich wirklich eine andere Arbeit suchen sollten, einer der Ihnen wirklich passt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Dies ist eine Zeit um durch Wahl alleine zu sein, Sich zurückzuziehen vom Alltag, Sich zu richten auf Ihre geistige Energie. Sie suchen nach Möglichkeiten, um näher an Ihr wahres Selbst zu kommen, vielleicht durch Meditation, Kontemplation, spirituelle Übungen oder sogar durch Fasten. Möglicherweise wird jemand in Ihr Leben kommen, der Sie führen, unterstützen kann auf dieser Reise. Auch wenn diese manchmal schwierig ist, wird es sich sicherlich die Mühe lohnen!</p>",
                    }
                }
            },
            {
                filename: "10",
                numerology : 10,
                en : {
                    name : "Wheel of Fortune",

                    planet : "♃ - Jupiter",
                    archetype : "movement",
                    theme : "self-management",
                    description : "The Wheel of Fortune talks about movement, about what has been, what is and what will always be, about new cycles, new chances, self-management.",
                    questions : [ "Who decides in your life?", "Are you moving in the direction of your choice?", "What could you change about your life?" ],

                    bulletpoints : ["Change","Fate","Cycles"],
                    birthCombinationShort: "Your path is of resourcefulness in an over changing world.",
                    birthCombinationText : "The spinning Wheel represents the cycles of change that are always present in life. You recognize these changes and are easily able to adapt to them thanks to the resourcefulness of the Magician.",

                    soalPoints : [ "Het lot", "Bestemming", "(Levens)taak", "Voor alles is een tijd" ],
                    soalText : "(Levens)taak is het uitvoeren en volbrengen van datgene wat jou is opgedragen. Je kunt hier niet voor weglopen. Jouw weg gaat langs alle personen, situaties en andere zaken die voor je bedoeld zijn. Je kunt hiervan leren en door groeien daar waar het nodig is.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Wheel of Fortune is a very spiritual card that's mostly about change, sometimes pointing out that you're feeling helpless, you're confronted with situations you barely have any influence on, or maybe it's change itself that intimidates you. Yes, change can sometimes be very traumatic.</p><p>Sometimes the card stands for a positive change involving circumstances that were at first beyond your control. You may realize you truly are the master of your own fate. </p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card indicates that for the moment you're uncomfortable with your love life. Maybe you're currently single and would like to be in a relationship, or maybe the relationship you're in is problematic, unsatisfying. Anyway, you will have to ponder on what you really want and then take action to change your situation. Remember that change is an integral part of life, we need to change in order to grow and evolve. Also try to live in the Now and happily welcome anything that life throws at you.</p>",
                        [GuidanceFields.WORK] : "<p>Right now you're not happy with your work situation, you are feeling powerless, as if you have no influence whatsoever on the path you've been taking. Maybe you feel like you're in a rut, following old patterns. Quite possibly your heart is no longer in it. The time may have come for you to find out what your dream really is and follow it. Talk to as many people as possible, do your research, think deep and hard and ultimately you will find what you're looking for.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>It's time to realize that you can't possibly control every aspect of your life, but still, in nearly every situation you have some responsibility. Most of the time life doesn't just happen to you. Whatever is going on in your life, accept the impact you have on the situation and learn from it. Now is also a good time to face and analyze past experiences, work through them, find the hidden lesson and finally let go.</p>",
                    }
                },
                fr : {
                    name : "La Roue de Fortune",
                    planet : "♃ - Jupiter",
                    archetype : "mouvement",
                    theme : "Prendre la barre",
                    description : "La Roue de la Fortune, nous parle du mouvement, ce qui a été, ce qui est et ce qui sera toujours, de nouveaux cycles, de nouvelles opportunités, de donner direction à et dans votre propre vie.",
                    questions : ["Qui tire aux ficelles de votre vie?", "Allez-vous dans la direction de votre choix?", "Quelle direction pourriez-vous donner à votre vie?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La \"Roue de la Fortune\" est une carte très spirituelle qui porte principalement sur le changement et indique parfois que vous vous sentez impuissant. Vous êtes confronté à des situations sur lesquelles vous avez peu d'influence. Où peut-être est-ce le changement lui-même qui vous intimide. C'est vrai : Parfois, un changement peut être vécu comme très traumatisant.</p><p>Parfois, cependant, la carte représente aussi un changement positif qui fait référence à des circonstances qui étaient initialement hors de votre contrôle. Vous pouvez voir que vous êtes vraiment maître de votre propre destin. Que la roue de la fortune tourne à gauche ou à droite, vous contrôlez où elle s'arrête !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card indicates that for the moment you're uncomfortable with your love life. Maybe you're currently single and would like to be in a relationship, or maybe the relationship you're in is problematic, unsatisfying. Anyway, you will have to ponder on what you really want and then take action to change your situation. Remember that change is an integral part of life, we need to change in order to grow and evolve. Also try to live in the Now and happily welcome anything that life throws at you.</p>",
                        [GuidanceFields.WORK] : "<p>Right now you're not happy with your work situation, you are feeling powerless, as if you have no influence whatsoever on the path you've been taking. Maybe you feel like you're in a rut, following old patterns. Quite possibly your heart is no longer in it. The time may have come for you to find out what your dream really is and follow it. Talk to as many people as possible, do your research, think deep and hard and ultimately you will find what you're looking for.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>It's time to realize that you can't possibly control every aspect of your life, but still, in nearly every situation you have some responsibility. Most of the time life doesn't just happen to you. Whatever is going on in your life, accept the impact you have on the situation and learn from it. Now is also a good time to face and analyze past experiences, work through them, find the hidden lesson and finally let go.</p>",
                    }
                },
                sp:{
                    name:"La Rueda de la Fortuna",
                    planet: "♃ - Júpiter",
                    archetype: "movimiento",
                    theme: "Envíalo tú mismo",
                    description: "La Rueda de la Fortuna es sobre el movimiento, sobre lo que fué, lo que es y lo que siempre será, sobre nuevas rondas, nuevas oportunidades, sobre dar dirección a y en tu propia vida",
                    questions: ["¿Quién mueve los hilos de tu vida?", "¿Vas en la dirección que quieres?", "¿Qué giro podrias dar a tu vida?" ],  
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La Rueda de la Fortuna es una tarjeta muy espiritual que es sobre todo un cambio, a veces señalando que se siente impotente, usted se enfrenta con situaciones que apenas tiene ninguna influencia en, o tal vez es el cambio mismo que le intimida. Sí, el cambio puede a veces ser muy traumático. A veces la tarjeta representa un cambio positivo que concurran circunstancias que estaban en primera fuera de su control. Usted puede darse cuenta de que realmente eres el dueño de tu propio destino.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name: "Het Rad van Fortuin",
                    planet : "♃ - Jupiter",
                    archetype : "beweging",
                    theme : "zelf sturen",
                    description : "Het rad van fortuin gaat over beweging, over wat was, wat is en wat altijd zal zijn, over nieuwe rondes, nieuwe kansen, over zelf richting geven aan en in je eigen leven.",
                    questions : [ "Wie trekt aan de touwtjes in jouw leven?", "Ga je de door jou gewenste kant op?", "Welke draai zou jij aan je leven kunnen geven?" ],  
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Het Rad van Fortuin is een opvallend spirituele kaart. Ze staat vooral voor verandering en maakt je duidelijk dat je je mogelijk hulpeloos voelt, geconfronteerd wordt met situaties waarop je nauwelijks invloed hebt. Mogelijk is het zelfs de verandering zelf die je als beangstigend ervaart. En het klopt natuurlijk dat verandering vaak erg traumatisch kan zijn. Toch staat ons hele leven in het teken ervan want groei kan enkel ontstaan vanuit verandering.</p><p>Soms gaat deze kaart over positieve veranderingen in je leven waarvan je in eerste instantie dacht dat je er geen invloed op had. En toch is het mogelijk dat je op een gegeven moment beseft dat je voor een stuk toch zelf je lot bepaald hebt. Of het Rad van Fortuin nu links- of rechtsom draait, jij hebt impact op de plaats waar het stopt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart wijst erop dat je je op dit moment niet goed voelt in je liefdesleven. Misschien ben je single en verlang je naar een relatie, of misschien is de relatie waar je op het moment deel van uitmaakt erg problematisch, mogelijk zelfs onbevredigend. In elk geval zal je de tijd moeten nemen om na te denken over wat je echt wil en dan beslissen wat je eraan zal doen om dat te bereiken.</p><p>Houd jezelf voor ogen dat verandering een onmisbaar deel van het leven is, dat we nu eenmaal moeten veranderen als we willen groeien en evolueren. Probeer tegelijk ook in het Nu te leven en stel je open voor wat er op je af komt.</p>",
                        [GuidanceFields.WORK] : "<p>Je bent waarschijnlijk niet echt tevreden met je werksituatie, je voelt je een stuk machteloos, alsof je geen enkele invloed hebt op de richting die je aan het uitgaan bent. Mogelijk heb je het gevoel dat je in een sleur zit, dat je gewoon maar oude patronen aan het volgen bent. Je doet je werk niet meer met hart en ziel. Misschien moet je wel op zoek gaan om erachter te komen wat je droom is en daarna plannen maken om die droom waar te maken. Praat hierover met zoveel mogelijk mensen, ga op onderzoek uit en je zal zien dat je uiteindelijk zal vinden waarnaar je op zoek bent.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het Rad van Fortuin wijst je erop dat je onmogelijk alle aspecten van je leven onder controle kan hebben, maar natuurlijk heb je in elke situatie en gebeurtenis wel een stukje verantwoordelijkheid. Meestal is het niet zo dat het leven je zomaar overkomt. Gelijk wat er op dit moment aan de hand is, aanvaard de impact die jij op de situatie hebt, neem die verantwoordelijkheid en probeer ervan te leren. Neem nu ook de tijd om naar vorige ervaringen te kijken, ze te analyseren, er de nodige lessen uit te trekken en dan alles los te laten.</p>",
                    }
                },
                de : {
                    name: "Das Rad des Schicksals",
                    planet : "♃ - Jupiter",
                    archetype : "Bewegung",
                    theme : "Das Ruder übernehmen",
                    description : "Das Rad des Schicksals spricht über die Bewegung, was war, was ist und was immer sein wird, neue Zyklen, neue Möglichkeiten, Ihrem Leben eine neue Richtung zu geben.",
                    questions : [ "Wer zieht die Fäden Ihres Lebens?", "Gehen Sie in die Richtung Ihrer Wahl?", "Welche Richtung könnten Sie Ihrem Leben geben?" ],  
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Das „Rad des Schicksals“ ist eine sehr spirituelle Karte, die vor allem von Veränderung handelt und manchmal darauf hinweist, dass Sie das Gefühl haben, hilflos zu sein. Sie werden mit Situationen konfrontiert, auf die man kaum Einfluss hat. Oder ist es vielleicht die Veränderung selbst, die Sie einschüchtert. Es stimmt: Manchmal kann eine Veränderung als sehr traumatisch erlebt werden.</p><p>Manchmal steht die Karte aber auch für eine positive Veränderung, die sich auf Umstände bezieht, die sich zunächst außerhalb Ihrer Kontrolle befanden. Sie können erkennen, dass Sie schließlich wirklich Meister Ihres eigenen Schicksals sind. Egal, ob das Rad des Schicksals sich linksherum oder rechtsherum dreht, Sie haben die Kontrolle darüber, wo es aufhört sich zu drehen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte zeigt an, dass Sie im Augenblick unzufrieden sind mit Ihrem Liebesleben. Vielleicht sind Sie noch alleine und möchten Sie gerne in einer Beziehung sein, oder vielleicht ist die Beziehung, in der Sie sind, problematisch, und unbefriedigend. Wie auch immer, müssen Sie über das, was Sie wirklich wollen, nachdenken, und dann Maßnahmen treffen, um Ihre Situation zu ändern. Denken Sie daran, dass Änderung ein integraler Bestandteil des Lebens ist, wir müssen ändern um zu wachsen und entwickeln. Versuchen Sie auch, im Jetzt zu leben und glücklich zu sein und begrüßen Sie alles, was das Leben Ihnen zuwirft.</p>",
                        [GuidanceFields.WORK] : "<p>Zurzeit sind Sie nicht zufrieden mit Ihrer Arbeitsumständen. Sie fühlen sich machtlos, als ob Sie keinerlei Einfluss haben auf dem Wege, den Sie gegangen sind. Vielleicht fühlen Sie Sich wie in einer Furche wobei Sie alte Mustern folgen. Ziemlich vielleicht ist Ihr Herz auch nicht mehr dabei. Die Zeit kann gekommen sein herauszufinden, was Ihr Traum wirklich ist und den zu folgen. Sprechen Sie mit so vielen Menschen wie möglich, untersuchen Sie alles, denken Sie tief und hart nach und Sie werden schließlich finden was Sie suchen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Jetzt ist die Zeit gekommen zu erkennen, dass Sie unmöglich jeden Aspekt Ihres Lebens kontrollieren können, aber trotzdem haben Sie in fast allen Fällen eine gewisse Verantwortung. Meistens kommt das Leben nicht einfach nur auf Sie zu. Was auch immer los ist in Ihrem Leben, akzeptieren Sie den Einfluss, der es auf Sie hat und lernen Sie davon. Jetzt ist auch eine gute Zeit Erfahrungen aus der Vergangenheit zu durchleben und durchzuarbeiten, die versteckte Lehre zu finden und schließlich loszulassen.</p>",
                    }
                }
            },
            {
                filename: "11",
                numerology : 11,
                en : {
                    name : "Justice",

                    zodiac : "♎ - Libra",
                    archetype : "the judge",
                    theme : "judging objectively",    
                    description : "Justice talks about cause and effect, acting objectively, honesty and responsibility.", 
                    questions : [ "Is honesty important to you?", "Are you being confronted with dishonesty?", "Are you really the sole responsible?", "Where can you take responsibility and where can you delegate?" ],

                    bulletpoints : ["Fairness","Truth","Clarity"],
                    birthCombinationShort: "Your path unites clarity with deep intuitive understanding.",
                    birthCombinationText : "You are very intuitive in almost every situation. The Justice provides you with logical clarity, which helps you make quick decisions and remain objective. In contrast, the High Priestess keeps you highly connected with your inner voice.",

                    soalPoints : [ "Eerlijkheid", "Oordeel", "Rechtvaardigheid", "Spiegel", "Objectiviteit", "Evenwicht", "Verantwoordelijkheid" ],
                    soalText : "Oordeel is de objectieve beslissing die je neemt nadat je alles goed tegen elkaar hebt afgewogen. Helderheid en oprechtheid is hierin belangrijk. De ander zal je altijd terugspiegelen zoals je jezelf opstelt, je krijgt terug wat je weggeeft. Je bent zelf verantwoordelijk voor alles wat je doet of juist niet doet.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Justice is symbolic for being honest and true. Can you look everyone around you straight in the eye? This card is all about clear and objective insights, about decisions that are well thought through, about balance and integrity. In everyday life Justice reflects on how you experience cause and effect where behavior is concerned. If you are honest and sincere you can expect the same behavior from the people around you. If you are insincere and untrue you will find yourself getting confronted with tricky and difficult situations. People will see through you, unmask you. This card asks you to show true inner integrity and to take responsibility with all your being.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In your personal relationships this card symbolizes being well-balanced. Even if you don’t have a love interest in your life for the moment, you are at peace with your situation. You know perfectly well what you expect from a relationship and because of that, you will easily find it.</p><p>Justice also stands for harmony and reciprocity: if you treat your loved ones in a completely honest and above-board manner, they will do so too. </p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned you can count on being treated in a fair and well balanced manner. You are honest and outspoken and good at what you do. Still, at times a certain passion may be missing, you may find it hard to really be absorbed in your work, you don’t totally immerse yourself in what you do. Also, your work/life balance may be in need of some attention. Attaining this balance is absolutely necessary to find happiness and harmony.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually the Justice card is about balance and being grounded. Do you feel as if you are truly connected to the earth, to the world you are living in as well as to your fellow man? Does your connectedness have a naturally flowing feeling? Or is there a certain rigidity in your behavior and sentiments? Moderation and balance are integral parts of leading a sensible and spiritual life.</p>",
                    }
                },
                fr : {
                    name : "La Justice",
                    zodiac : "♎ - Balance",
                    archetype : "le juge",
                    theme : "jugement objectif",    
                    description : "La justice est une question de cause à effet, d'action objective, d'honnêteté et de responsabilité.", 
                    questions : ["L'honnêteté vous-est-elle importante?", "Êtes-vous confronté à l’injustice?", "Êtes-vous réellement responsable de tout?", "Où pouvez-vous prendre votre responsabilité ou peut-être la déléguer?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La carte de la justice symbolise l'honnêteté et la vérité. Pouvez-vous regarder tout le monde autour de vous dans l'œil droit? Cette carte porte sur des idées claires et objectives, des décisions bien réfléchies, l'équilibre et l'intégrité. Dans la vie de tous les jours, la justice représente la façon dont vous vivez la cause et l'effet quand il s'agit de comportement. Si vous êtes honnête et sincère, vous pouvez vous attendre au même comportement de la part des gens qui vous entourent. Si vous êtes dans l'erreur et le mensonge, vous serez confronté à des situations difficiles et perfides. Les gens devineront vos intentions et vous dénonceront. Cette carte vous invite à montrer votre intégrité intérieure et à assumer la responsabilité de tout votre être.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In your personal relationships this card symbolizes being well-balanced. Even if you don’t have a love interest in your life for the moment, you are at peace with your situation. You know perfectly well what you expect from a relationship and because of that, you will easily find it.</p><p>Justice also stands for harmony and reciprocity: if you treat your loved ones in a completely honest and above-board manner, they will do so too. </p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned you can count on being treated in a fair and well balanced manner. You are honest and outspoken and good at what you do. Still, at times a certain passion may be missing, you may find it hard to really be absorbed in your work, you don’t totally immerse yourself in what you do. Also, your work/life balance may be in need of some attention. Attaining this balance is absolutely necessary to find happiness and harmony.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually the Justice card is about balance and being grounded. Do you feel as if you are truly connected to the earth, to the world you are living in as well as to your fellow man? Does your connectedness have a naturally flowing feeling? Or is there a certain rigidity in your behavior and sentiments? Moderation and balance are integral parts of leading a sensible and spiritual life.</p>",
                    }
                },
                sp:{
                    name: "Justicia",
                    zodiac: "♎ - Libra",
                    archetype: "el juez",
                    theme: "juicio objetivo",    
                    description: "La justicia es causa y efecto, es acción objetiva, es honestidad y responsabilidad", 
                    questions: ["¿La honestidad es importante para tí?", "¿Te enfrentas a la deshonestidad?", "¿Eres realmente responsable de todo?", "¿Dónde puedes asumir tu responsabilidad o quizás transferirla?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La justicia es simbólica por ser honesto y verdadero. ¿Puedes mirar todos en su alrededor a los ojos? Esta tarjeta tiene que ver con ideas claras y objetivas, sobre las decisiones que están bien pensada, el equilibrio y la integridad. En la vida cotidiana Justicia reflexiona sobre cómo la experiencia de la causa y el efecto en el comportamiento se refiere. Si usted es honesto y sincero que puede esperar el mismo comportamiento de las personas que le rodean. Si no es sincero y falso usted se encuentra el conseguir confrontados con situaciones complicadas y difíciles. La gente va a ver a través de usted, desenmascarar a usted. Esta tarjeta le pide que muestre la verdadera integridad interior y asumir la responsabilidad con todo su ser.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name: "Gerechtigheid",
                    zodiac : "♎ - Weegschaal",
                    archetype : "de rechter",
                    theme : "objectief oordelen",    
                    description : "Gerechtigheid gaat over oorzaak en gevolg, over objectief handelen, over eerlijkheid en verantwoordelijkheid.", 
                    questions : [ "Is eerlijkheid belangrijk voor je?", "Word je geconfronteerd met oneerlijkheid?", "Ben jij echt voor alles verantwoordelijk?", "Waar kun jij je verantwoordelijkheid nemen of wellicht overdragen?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Gerechtigheid staat voor eerlijk en oprecht zijn. Kan jij iedereen om je heen recht in de ogen kijken?</p><p>Bij deze kaart gaat het ook om heldere en objectieve inzichten, weldoordachte beslissingen, evenwicht en integriteit.</p><p>Wat het leven van alledag betreft, vraagt deze kaart je om je bewust te worden van oorzaak en gevolg als het om gedrag gaat. Immers, als je zelf eerlijk en oprecht bent, dan kan je over het algemeen hetzelfde gedrag verwachten van de mensen om je heen. Als je zelf oneerlijk en onoprecht bent, dan weet je dat je vroeg of laat terecht zal komen in moeilijke en bedrieglijke situaties. Het is erg waarschijnlijk dat mensen je uiteindelijk door zullen hebben, dat ze je zullen ontmaskeren. Deze kaart vraagt je om echte innerlijke integriteit te tonen en je volle verantwoordelijkheid te nemen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Bij deze kaart gaat het erom evenwichtig te zijn en te blijven in al je relaties. Zelfs als je op het ogenblik geen liefdesrelatie in je leven hebt en er mogelijk wel naar verlangt, je voelt je toch in harmonie met je leven en je situatie. Je weet nu duidelijk wat je wil en verwacht van een relatie en net daarom wordt het makkelijker om het ook te vinden.</p><p>Gerechtigheid vertegenwoordigt ook evenwicht en wederkerigheid: als je de mensen waar je om geeft volkomen eerlijk benadert en behandelt, dan zullen zij dat meestal ook met jou doen.</p>",
                        [GuidanceFields.WORK] : "<p>Wat je werk betreft kan je er op dit moment waarschijnlijk op rekenen dat je op een rechtvaardige en evenwichtige manier behandeld wordt. Je bent eerlijk, je komt openlijk voor je mening uit en je bent goed in wat je doet. Toch is het mogelijk dat je bij momenten een stuk passie mist, dat je het moeilijk vindt om echt op te gaan in je werk. Ook is het mogelijk dat het evenwicht tussen werk en privé niet helemaal goed zit. Daarin het juiste evenwicht bereiken is absoluut nodig om geluk en harmonie te kunnen vinden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De betekenis van Gerechtigheid heeft vooral te maken met evenwicht en gegrond zijn. Voel je je echt verbonden met de aarde, met de wereld waarin je leeft en met de mensen om je heen? Geeft deze verbondenheid je een natuurlijk, vloeiend en dynamisch gevoel? Of zit er een zekere stroefheid in je gedrag en je gevoelens? Gematigdheid en evenwicht zijn onmisbare voorwaarden om een verstandig en spiritueel leven te leiden.</p>",
                    }
                },
                de : {
                    name: "Die Gerechtigkeit",
                    zodiac : "♎ - Waage",
                    archetype : "Der Richter",
                    theme : "Objektives Urteil",    
                    description : "Gerechtigkeit ist eine Frage von Ursache und Wirkung, objektivem Handeln, Ehrlichkeit und Verantwortung.", 
                    questions : [ "Ist Ihnen Ehrlichkeit wichtig?", "Sind Sie mit Ungerechtigkeit konfrontiert?", "Sind Sie wirklich für alles verantwortlich?", "Wo können Sie Ihre Verantwortung übernehmen oder vielleicht delegieren?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die Gerechtigkeitskarte steht symbolisch für Ehrlichkeit und Wahrheit. Können Sie allen Menschen in Ihrer Umgebung direkt in die Augen schauen? Bei dieser Karte geht es um klare und objektive Erkenntnisse, um gut durchdachte Entscheidungen, um Ausgewogenheit und Integrität. Im Alltag steht Gerechtigkeit dafür, wie Sie Ursache und Wirkung erleben, wenn es das Verhalten betrifft. Wenn Sie ehrlich und aufrichtig sind können Sie das gleiche Verhalten von den Menschen Ihrer Umgebung erwarten. Wenn Sie falsch und unwahr sind werden Sie mit schwierigen und tückischen Situationen konfrontiert sein. Die Menschen werden Sie durchschauen und entlarven. Diese Karte fordert Sie auf, Ihre innere Integrität zu zeigen und Verantwortung für Ihr gesamtes Wesen zu übernehmen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte symbolisiert eine gute Ausgewogenheit in Ihren persönlichen Beziehungen. Auch wenn Sie nicht gerade ein Interesse an einer speziellen Liebe in Ihrem Leben haben, sind Sie in Frieden mit Ihrer Situation. Sie wissen ganz genau, was Sie von einer Beziehung erwarten und aus diesem Grund werden Sie sie leicht finden.</p><p>Gerechtigkeit steht auch für Harmonie und Gegenseitigkeit: wenn Sie Ihre Geliebten ganz ehrlich behandeln, werden sie das genau so mit Ihnen tun.</p>",
                        [GuidanceFields.WORK] : "<p>Wo es die Arbeit betrifft können Sie damit rechnen auf einer fairen und ausgewogenen Weise behandelt zu werden. Sie sind ehrlich und freimütig und gut in dem was Sie tun. Dennoch, manchmal könnte eine gewisse Leidenschaft fehlen und können Sie es schwer finden Sich völlig von Ihrer Arbeit aufgenommen zu fühlen. Auch erfahren Sie im Moment kein Gleichgewicht zwischen Arbeit und Leben. Das Erreichen dieses Gleichgewichts ist aber absolut notwendig um Glück und Harmonie zu finden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spirituell handelt die Karte der Gerechtigkeit über Gleichgewicht und Stabilität. Haben Sie das Gefühl, als ob Sie wirklich mit der Erde verbunden sind, verbunden mit der Welt in der Sie leben und mit Ihren Mitmenschen? Ist Ihre Verbundenheit ein natürlich fließendes Gefühl? Oder gibt es eine gewisse Starrheit in Ihrem Verhalten und Ihren Gefühlen? Mäßigung und Gleichgewicht sind integrale Bestandteile für die Führung eines sinnvollen und spirituellen Lebens.</p>",
                    }
                }
            },
            {
                filename: "12",
                numerology : 12,
                en : {
                    name : "Hanged Man",

                    planet : "♆ - Neptune",
                    archetype : "seclusion",
                    theme : "time-out",
                    description : "The Hanged Man talks about purposely taking a time out to look at things from a different perspective,  \"watch-the-place\", a time-out.",
                    questions : [ "Are you stuck somewhere?", "Has this taken enough time yet?", "Can you look at it from a different perspective?", "Is it time for a change?" ],

                    bulletpoints : ["Suspension","Delays","Sacrifice"],
                    birthCombinationShort: "Yours is the path of gentle kindness progress in the face of delays",
                    birthCombinationText : "Like the Hanged Man in suspension, you are able to easily handle any delays or pauses in your life journeys. This grace, as well as your nurturing spirit, comes from the Empress tarot birth card.",

                    soalPoints : [ "Crisis", "Stilstand", "Ommekeer", "Geduld", "Innerlijke groei", "Offer", "Het verlichte denken", "Verandering" ],
                    soalText : "Stilstand is het stagneren van een natuurlijk verloop in ontwikkelingen. Dit zorgt er voor dat de energie niet door kan stromen, wat een ongezonde situatie oplevert. Hierin kan alleen verandering komen als je de tijd neemt, de stiltstand, om te groeien en de dingen anders te gaan zien. Meestal gaat de verandering gepaard met een offer dat je zult moeten brengen.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>It looks as if The Hanged Man has no way to turn, nowhere to go. This card may be reminiscent of a burnout or some other affliction that makes it impossible for you to continue your life in the same manner you are used to. Change is imminent. Even though this situation may be rather uncomfortable, you can also experience it as the start of a new era, a reversal of fortune. Circumstances are forcing you to take the time to observe your life, to really understand who you are, how you reached this point. You will truly and deeply comprehend your situation and gain totally new and profound insights.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in an intimate relationship it is well possible that currently you find yourself in a situation you're uncomfortable with. There are tensions between you that aren't being openly discussed. Possibly you don't think your partner's frustrations are important enough or vice versa. Also, you may be underestimating the impact your actions have on your partner.</p><p>If you are currently single it's very possible that you are trying way too hard to find someone. The time is right to do some soul searching and look yourself in the eye. Try to find out what you truly want and expect from an intimate relationship.</p>",
                        [GuidanceFields.WORK] : "<p>The Hanged Man confronts you with the fact that you're stuck. Maybe you don't like your job anymore, maybe there's a task or project you're unsuccessful with. Observe your situation objectively and decide if you want to wait for things to change or if you actively want to make things change. The decision is yours. </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You might well be at a crossroads. Now is the time to relax, lay back and observe your situation as well as your outlook on life. Do you want to stay where you are or do you want change? Observe yourself while realizing that everything you think and feel has an impact on reality. You may want to get rid of some negative or limiting thoughts and beliefs. Visualize the way you want yourself and your life to be and you will discover you can actively mold your own future.</p>",
                    }
                },
                fr : {
                    name : "Le Pendu",
                    planet : "♆ - Neptune",
                    archetype : "isolement",
                    theme : "time-out",
                    description : "Le Pendu prend consciemment de la distance pour lavoir un point de vue différent, d'un recul par rapport à, \"d'un time-out.\"",
                    questions : ["Vous attardez-vous?", "Cela vous a pris assez de temps?", " Pouvez-vous le voir d'un autre angle?", "Est-il temps pour un changement?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>On dirait que le pendu n'a aucun moyen de tourner, aucun moyen d'aller nulle part. Cette carte peut rappeler une dépression mentale ou d'autres souffrances. Ce souvenir vous empêche de continuer votre vie de la même façon que vous l'aviez l'habitude de le faire. Le changement est imminent. Même si cette situation peut être très désagréable, vous pouvez aussi voir ce changement comme le début d'une nouvelle ère, comme un changement de fortune. Les circonstances vous obligeront à prendre le temps de regarder votre vie en face pour vraiment comprendre qui vous êtes et comment vous en êtes arrivé là. Vous comprendrez vraiment et profondément votre situation d’aujourd'hui et vous irez vers des perspectives complètement nouvelles et profondes.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in an intimate relationship it is well possible that currently you find yourself in a situation you're uncomfortable with. There are tensions between you that aren't being openly discussed. Possibly you don't think your partner's frustrations are important enough or vice versa. Also, you may be underestimating the impact your actions have on your partner.</p><p>If you are currently single it's very possible that you are trying way too hard to find someone. The time is right to do some soul searching and look yourself in the eye. Try to find out what you truly want and expect from an intimate relationship.</p>",
                        [GuidanceFields.WORK] : "<p>The Hanged Man confronts you with the fact that you're stuck. Maybe you don't like your job anymore, maybe there's a task or project you're unsuccessful with. Observe your situation objectively and decide if you want to wait for things to change or if you actively want to make things change. The decision is yours. </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You might well be at a crossroads. Now is the time to relax, lay back and observe your situation as well as your outlook on life. Do you want to stay where you are or do you want change? Observe yourself while realizing that everything you think and feel has an impact on reality. You may want to get rid of some negative or limiting thoughts and beliefs. Visualize the way you want yourself and your life to be and you will discover you can actively mold your own future.</p>",
                    }
                },
                sp:{
                    name:"El Colgado",
                    planet: "♆ - Neptuno",
                    archetype: "reclusión",
                    theme: "tiempo muerto",
                    description: "El Colgado se trata de tomar conscientemente una distancia para mirarla desde un lado diferente, de un \"pase en el lugar\", de un tiempo muerto",
                    questions: ["¿Te quedas estacado?", "¿ Eso duró lo suficiente?", "¿Puedes verlo desde otro lado?", "¿Es hora de dar un giro?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Parece como si el hombre colgado no tiene manera de convertir, a dónde ir. Esta tarjeta puede ser una reminiscencia de una quemadura o alguna otra afección que hace que sea imposible para que usted pueda continuar con su vida de la misma manera que estamos acostumbrados. El cambio es inminente. A pesar de que esta situación puede ser bastante incómoda, también puede experimentar como el comienzo de una nueva era, un cambio de fortuna. Las circunstancias le obligan a tomar el tiempo para observar su vida, para entender realmente quién es, cómo llegó a este punto. Usted realmente y profundamente comprenderá su situación y ganara totalmente nuevas y profundas penetraciones.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name: "De Gehangene",
                    planet : "♆ - Neptunes",
                    archetype : "afzondering",
                    theme : "time-out",
                    description : "De gehangene gaat over bewust afstand nemen om het van een adere kant te kunnen bekijken, over een \"pas-op-de-plaats\", over een time-out.",
                    questions : [ "Blijf je ergens in hangen?", "Heeft dat inmiddels al lang genoeg geduurd?", "Kun jij het van een andere kant bekijken?", "Is het tijd voor een ommekeer?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Gehangene heeft geen speelruimte, hij kan nergens heen, zoveel is duidelijk. Volgens deze kaart word je mogelijk geconfronteerd met een onaangename situatie of omstandigheden. Deze maken het je onmogelijk om je leven van alledag te leiden, zoals je het tot nu toe deed. Misschien ben je depressief of zit je om andere redenen niet goed in je vel. Mogelijk zijn het nog andere dingen die je tegenhouden. Hier zal echter verandering in komen. Denk hieraan op de momenten dat je je slecht voelt in je huidige situatie. Je moet het nu kalm aan doen, maar die “vertraging” geeft je ook de kans om je leven te observeren. Nu kan je gaan begrijpen wie je bent en hoe je in deze situatie terechtgekomen bent. Neem de tijd om jezelf en je situatie grondig te onderzoeken, je zal tot verrijkende en diepgaande inzichten komen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op het ogenblik een liefdesrelatie hebt dan vertelt De Gehangene je dat je in een situatie zit die je als onaangenaam ervaart. Er hangen spanningen in de lucht, die niet duidelijk genoeg zijn verwoord of die niet door beiden even belangrijk gevonden worden. Misschien vind je de frustraties van je geliefde niet belangrijk genoeg of omgekeerd.</p><p>Indien je geen relatie hebt, is het mogelijk dat je te krampachtig op zoek bent naar een geliefde. De situatie vraagt je om naar binnen te keren en jezelf in de ogen te kijken. Vraag je eerlijk af wat je werkelijk wil en verwacht van een relatie, dat is de eerste stap.</p>",
                        [GuidanceFields.WORK] : "<p>De Gehangene confronteert je met het feit dat je vastzit. Misschien vind je je baan niet meer leuk, misschien is er een taak of project waarin je maar niet tot resultaten komt. Probeer je situatie objectief te bekijken en beslis dan: misschien wil je afwachten of de dingen vanzelf veranderen, of wil je actief voor die verandering gaan. Deze beslissing ligt helemaal in jouw handen. En uiteindelijk zullen de dingen veranderen, ook al gaat dat misschien niet zo snel als je zou willen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het is goed mogelijk dat je je op een kruispunt bevindt. Nu is het ideale moment om te ontspannen, achterover te leunen en zowel je situatie als je visie op het leven te observeren. Wil je blijven zoals je bent of wil je veranderen? Bedenk dat alles wat je voelt en doet van invloed is op de realiteit. Misschien wil je sommige negatieve of beperkende gedachten en denkwijzen wel loslaten. Probeer je beeld van jezelf en je leven zoals jij het echt wil te visualiseren. Als je dit geregeld doet, zal je merken dat je echt actief impact hebt op de vorm die jouw toekomst aanneemt.</p>",
                    }
                },
                de : {
                    name: "Der Gehängte",
                    planet : "♆ - Neptun",
                    archetype : "Distanz",
                    theme : "Auszeit",
                    description : "Der Gehängte nimmt bewusst Abstand, um einen anderen Blickwinkel zu erhalten, um Distanz zu gewinnen, eine Auszeit.",
                    questions : [ "Halten Sie sich auf?", " Haben Sie lange genug dafür gebraucht?", "Können Sie es aus einem anderen Blickwinkel betrachten?", "Ist es Zeit für eine Veränderung?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Es sieht so aus, als ob der Gehängte keine Möglichkeit hat, sich zu drehen, irgendwo hin zu gehen. Diese Karte kann an einen geistigen Zusammenbruch oder an ein anderes Leiden erinnern. Diese Erinnerung macht es Ihnen unmöglich, Ihr Leben auf die gleiche Art und Weise weiter zu führen, wie Sie es gewohnt waren. Veränderung steht unmittelbar bevor. Auch wenn diese Situation sehr unangenehm sein könnte, können Sie diese Änderung auch als den Beginn einer neuen Ära, als eine Schicksalswende sehen. Die Umstände werden Sie zwingen, sich die Zeit zu nehmen, Ihr Leben zu überschauen, um wirklich zu verstehen, wer Sie sind, wie Sie diesen Punkt erreicht haben. Sie werden Ihre heutige Lage wahrhaftig und tief verstehen und zu völlig neuen und tiefen Einsichten kommen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie gerade in einer intimen Beziehung sind, ist es gut möglich, dass Sie Sich zurzeit in einer Situation befinden, die Ihnen unangenehm ist. Es gibt Spannungen zwischen Ihr beide, die nicht offen ausgesprochen werden. Möglicherweise meinen Sie, dass die Frustrationen Ihres Partners nicht wichtig genug sind oder umgekehrt. Außerdem können Sie die Auswirkungen Ihrer Handlungen auf Ihrem Partner unterschätzen.</p><p>Wenn Sie derzeit alleine sind ist es auch sehr gut möglich, dass Sie zu hart versuchen jemanden zu finden. Die Zeit ist jetzt da, tief in Ihrer eigenen Seele zu suchen Sich richtig in die Augen zu schauen. Versuchen Sie herauszufinden, was Sie wirklich wollen und aufrecht erwarten von einer intimen Beziehung.</p>",
                        [GuidanceFields.WORK] : "<p>Der Gehängte konfrontiert Sie mit der Tatsache, dass Sie nicht weiterkommen. Vielleicht gefällt Ihnen Ihre Arbeit nicht mehr, vielleicht gibt es ein Projekt oder eine Aufgabe die nicht erfolgreich für Sie ist. Beobachten Sie Ihre Situation objektiv und entscheiden Sie, ob Sie warten möchten, bis die Dinge sich von alleine ändern oder ob Sie sich aktiv bemühen wollen, um die Dinge zu ändern. Die Entscheidung liegt bei Ihnen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie könnten in diesem Moment an eine Kreuzung anbelangt sein. Jetzt ist die Zeit zu entspannen, zurückzulehnen und Ihre Lage sowie Ihre Einstellung zum Leben zu beobachten. Möchten Sie bleiben, wo Sie sind, oder möchten Sie ändern? Beobachten Sie sich selbst genau, während Sie erkennen, dass alles, was Sie denken und fühlen, einen Einfluss hat auf die Realität. Vielleicht möchten Sie Sich von einigen negativen oder begrenzenden Gedanken und Überzeugungen befreien. Visualisieren Sie, wie Sie Sich das Leben wünschen und Sie werden entdecken, dass Sie tatsächlich Ihre eigene Zukunft bilden können.</p>",
                    }
                }
            },
            {
                filename: "13",
                numerology : 13,
                en : {
                    name : "Death",

                    zodiac : "♏ - Scorpio",
                    archetype : "transformation",
                    theme : "time of change",    
                    description : "Death, as a card, does not mean an actual physical death! Rather a process of transformation, a slow change, letting go of the old to make space for the new.",

                    bulletpoints : ["Ending Cycle","New Beginning","Metamorphosis"],
                    birthCombinationShort: "Your path creates stability at the end of cycles to usher the new.",
                    birthCombinationText : "When one door closes, you have the stability and authority of the Emperor to open a new one and pursue that new path. Death may bring the end of a cycle, but, for you, it is a chance for new beginnings.",

                    soalPoints : [ "Transformatie", "Loslaten", "Afscheid", "Verlossen", "Het oude en het nieuwe" ],
                    soalText : "Transformatie is de totale verandering die er voor zorgt dat het oude nooit meer kan zijn en er alleen nog maar ruimte is voor het nieuwe. Dit kan niet zonder dat er een, soms zeer pijnlijk of ingrijpend, afscheid aan vooraf is gegaan. Deze verandering kan je ook verlossen van dat wat misschien allang niet meer goed was.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Every renewal is preceded by a period of decay. In autumn the old dying leaves must fall to make room for the new fresh green leaves in spring.  This is what Death symbolizes. This card stands for letting go, saying goodbye to what is no longer necessary. New doors will open and lead us to new beginnings. Sometimes these changes will cause us emotional pain because it's hard to let go of what's familiar. If you accept this pain as being a temporary phenomenon that's a necessary part of change, it will go away soon enough and make way for all that's fresh and new.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In a loving relationship Death may signify the end of a chapter or a phase you are experiencing together. Sometimes it may signify the end of a relationship but mostly it's about realizing the growth and evolution you can go through together to get the relationship to the next level. If, in spite of all your efforts, you are confronted with a breakup, don’t linger too long, your intuition will tell you when to let go of the past.</p><p>Death may also mean that friends you've known and counted on for a long time may drift out of your life, making room for new acquaintances. </p>",
                        [GuidanceFields.WORK] : "<p>Maybe your job isn't enough of a challenge, maybe you've been thinking about leaving for quite a while. Consider all your options, today might be the right day to make some changes. Your quality of life may greatly benefit from a brand new start! Death may also mean that a certain task or project is coming to an end, making way for a new one. Even if it's hard, make sure you stay focused on this task or project until it is well and truly finished. Make sure you can be proud of the results you achieve.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Change is about feeling the pain of saying goodbye but it's also about letting go, about emotionally and spiritually stepping away from what is in the past. Make sure you don't drown in sadness and sorrow, and if you feel you're lingering, ask for help. There's many people around you who will be glad to pull you out of your negative mood. Staying stuck in sadness for too long is just another way of fighting change.</p>",
                    }
               },
               fr : {
                    name : "La Mort",
                    zodiac : "♏ - Scorpion",
                    archetype : "transformation",
                    theme : "processus de changement",    
                    description : "La \"Mort\" ne concerne pas la mort physique ! La mort est un processus de transformation, de changement lent, de lâcher prise de l'ancien pour faire place au nouveau.", 
                    questions : ["Reconnaissez-vous votre processus de changement?", "Pour qui ou quoi voulez-vous faire de l'espace?", "Que pourriez-vous abandonner?", "Que pouvez-vous faire pour passer à la phase suivante?" ],
                
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Tout renouvellement précède un temps de désintégration. En automne, les vieilles feuilles mourantes doivent tomber pour faire place à de nouvelles feuilles vertes fraîches au printemps. Ceci est symbolisé par la mort. Cette carte signifie lâcher prise, dire adieu à ce qui n'est plus nécessaire. De nouvelles portes s'ouvrent et nous conduisent à de nouveaux départs. Parfois, ces changements vous causeront de la douleur émotionnelle parce qu'il est difficile d'abandonner ce qui vous est familier. Si vous ressentez cette douleur comme un phénomène temporaire et que vous la considérez comme un élément nécessaire de ce changement et que vous l'acceptez, la douleur disparaîtra bientôt et ouvrira la voie à tout ce qui est nouveau et frais.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In a loving relationship Death may signify the end of a chapter or a phase you are experiencing together. Sometimes it may signify the end of a relationship but mostly it's about realizing the growth and evolution you can go through together to get the relationship to the next level. If, in spite of all your efforts, you are confronted with a breakup, don’t linger too long, your intuition will tell you when to let go of the past.</p><p>Death may also mean that friends you've known and counted on for a long time may drift out of your life, making room for new acquaintances. </p>",
                        [GuidanceFields.WORK] : "<p>Maybe your job isn't enough of a challenge, maybe you've been thinking about leaving for quite a while. Consider all your options, today might be the right day to make some changes. Your quality of life may greatly benefit from a brand new start! Death may also mean that a certain task or project is coming to an end, making way for a new one. Even if it's hard, make sure you stay focused on this task or project until it is well and truly finished. Make sure you can be proud of the results you achieve.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Change is about feeling the pain of saying goodbye but it's also about letting go, about emotionally and spiritually stepping away from what is in the past. Make sure you don't drown in sadness and sorrow, and if you feel you're lingering, ask for help. There's many people around you who will be glad to pull you out of your negative mood. Staying stuck in sadness for too long is just another way of fighting change.</p>",
                    }
               },
                sp:{
                    name:"La Muerte",
                    zodiac: "♏ - Scorpio",
                    archetype: "transformación",
                    theme: "proceso de cambio",    
                    description: "La tarjeta con el nombre \"La Muerte\" no es sobre la muerte física! La muerte se trata de un proceso de transformación, de un cambio lento, de dejar ir lo viejo para hacer espacio para  lo nuevo", 
                    questions: ["¿Reconoces tu proceso de cambio?", "¿Para qué quieres espacio?", "¿Qué puedes dejar ir?", "¿Qué puedes hacer para pasar a la siguiente fase?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cada renovación está precedida por un período de decadencia. En otoño las hojas viejas moribundos deben caer para hacer espacio para las nuevas hojas verdes frescas en la primavera. Esto es lo que simboliza la muerte. Esta tarjeta es sinónimo de dejar ir, diciendo adiós a lo que ya no es necesario. Nuevas puertas se abrirán y conducirnos a nuevos comienzos. A veces, estos cambios nos causarán dolor emocional, porque es difícil de dejar de lado lo que es familiar. Si acepta este dolor como un fenómeno temporal que es una parte necesaria del cambio, que va a desaparecer pronto y dar paso a todo lo que es fresco y nuevo.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
               nl : {
                   name: "De Dood",
                   zodiac : "♏ - Schorpioen",
                   archetype : "transformatie",
                   theme : "veranderingsproces",    
                   description : "De kaart met de naam \"De Dood\" gaat niet over de fysieke dood! De dood gaat over een transformatieproces, over een langzame verandering, over het oude loslaten om ruimte te maken voor het nieuwe", 
                   questions : [ "Herken je jouw veranderingsproces?", "Waar wil jij ruimte voor?", "Wat zou jij los kunnen laten?", "Wat kan jij doen om door te gaan naar een volgende fase?" ],                 
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Opdat er iets nieuws zou kunnen ontstaan, moet er eerst iets ouds afsterven. Een mooi voorbeeld hiervan vind je in de natuur: in de herfst vallen de verkleurde stervende bladeren van de bomen af. Ze maken zo plaats voor de frisse groene blaadjes die in de lente zullen groeien.</p><p>Dit is wat deze kaart betekent: een belangrijk aspect van het leven is afscheid nemen, loslaten wat je niet langer nodig hebt. Soms kunnen zo’n veranderingen je enorm verdriet doen omdat je mensen en dingen die je vertrouwd zijn, moet laten gaan. Zonder afscheid kan er echter geen verandering zijn. Daarom is het belangrijk om het verdriet te aanvaarden. De pijn zal uiteindelijk vervagen en plaats maken voor mooie nieuwe ontmoetingen en ervaringen.</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>In een liefdesrelatie kan De Dood het einde van een hoofdstuk of een fase inluiden, een evolutie waar je samen doorheen moet. Soms kan deze kaart ook het einde van een relatie betekenen, maar meestal staat ze voor het waarmaken van gezamenlijke groei en evolutie, waardoor je samen je relatie naar het volgende niveau tilt. Als dit toch niet lukt en je stevent af op een breuk, sta er dan niet te lang bij stil, luister naar je intuïtie als die je vertelt om los te laten.</p><p>De Dood kan ook betekenen dat vrienden die je al erg lang kent en waar je altijd op gerekend hebt, langzaam uit je leven zullen verdwijnen om plaats te maken voor nieuwe kennismakingen.</p>",
                       [GuidanceFields.WORK] : "<p>Misschien vind je je baan geen uitdaging meer, of mogelijk ben je al een tijdje aan het overwegen om er weg te gaan. Bekijk al je opties want misschien is vandaag wel de juiste dag om dingen te veranderen. Je levenskwaliteit kan er enorm op vooruitgaan dankzij een nieuwe start!</p><p>De Dood kan ook het einde inluiden van een bepaalde taak of project, waardoor er ruimte komt om aan iets nieuws te beginnen. Zelfs al heb je het gevoel dat je \"er bijna vanaf bent\", probeer toch om geconcentreerd te blijven op je taak of project tot het echt helemaal afgewerkt is.</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>Verandering gaat niet alleen over het voelen van de pijn van een afscheid maar ook over loslaten, je emotioneel en spiritueel vrijmaken van wat voorbij is. Let erop dat je niet verdrinkt in verdriet en droefheid, en als je toch het gevoel hebt dat je erin blijft vastzitten, zoek dan hulp. Er zijn echt wel voldoende mensen in je omgeving die voor je klaarstaan om je uit je negatieve stemming te trekken. Als je te lang blijft hangen in je verdriet dan heb je eigenlijk enkel een nieuwe manier gevonden om je te verzetten tegen verandering.</p>",
                   }
               },
               de : {
                   name: "Der Tod",
                   zodiac : "♏ - Skorpion",
                   archetype : "Transformation",
                   theme : "Veränderungsprozess",    
                   description : "Beim \"Tod\" geht es nicht um den physischen Tod! Der Tod ist ein Prozess der Transformation, des langsamen Wandels, des Loslassens des Alten, um Platz für das Neue zu schaffen.", 
                   questions : [ "Erkennen Sie Ihren Veränderungsprozess", "Für wen oder wofür wollen Sie Raum schaffen", "Was könnten Sie aufgeben?", "Was könnten Sie tun, um zur nächsten Phase zu gelangen?" ],                 
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Jeder Erneuerung geht eine Zeit des Zerfalls vorher. Im Herbst müssen die alten sterbenden Blätter fallen, um Platz für neue, frische grüne Blätter im Frühjahr zu machen. Das symbolisiert der Tod. Diese Karte steht für das Loslassen, für den Abschied von dem, was nicht mehr notwendig ist. Neue Türen öffnen sich und führen uns zu neuen Anfängen. Manchmal werden diese Änderungen emotionalen Schmerz bei Ihnen verursachen, weil es schwer ist, aufzugeben, was vertraut ist. Wenn Sie diese Schmerzen als vorübergehende Erscheinung erfahren und sie als einen notwendigen Teil dieser Veränderung sehen und sie akzeptieren, dann werden die Schmerzen bald wieder verschwinden und den Weg frei machen für alles, was frisch und neu ist.</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>In einer liebevollen Beziehung kann der Tod das Ende eines Kapitels oder einer Phase sein, die Sie gemeinsam erlebt haben. Manchmal kann es das Ende einer Beziehung bedeuten, aber meistens geht es um die Verwirklichung von Wachstum und Evolution die man gemeinsam durchlebt, um die Beziehung auf die nächste Ebene zu führen. Wenn Sie trotz all Ihrer Bemühungen mit einer Trennung konfrontiert werden, müssen Sie nicht zu lange an diesem Punkt verweilen. Ihre Intuition wird Ihnen sagen, wann Sie die Vergangenheit loslassen müssen.</p><p>Der Tod kann auch bedeuten, dass Freunde, die Sie für lange Zeit gekannt und vertraut haben, aus Ihrem Leben vertrieben werden. Sie werden aber Platz machen für neue Bekanntschaften.</p>",
                       [GuidanceFields.WORK] : "<p>Vielleicht ist Ihre Arbeit nicht herausfordernd genug, vielleicht haben Sie darüber nachgedacht für eine lange Weile zu verschwinden. Betrachten Sie alle Möglichkeiten, vielleicht wäre Heute der richtige Tag, um einige Änderungen vorzunehmen. Ihre Lebensqualität könnte stark profitieren von einem neuen Anfang! Der Tod kann auch bedeuten, dass eine bestimmte Aufgabe oder ein Projekt zu Ende geht, um Platz für neue zu machen. Auch wenn es schwer ist, bleiben Sie Sich konzentrieren auf diese Aufgabe oder das Projekt, bis es ganz und wirklich fertig ist. Stellen Sie sicher, dass Sie stolz sein können auf die Ergebnisse, die Sie erreichen können.</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>Veränderung handelt über den Schmerz des Abschieds aber auch über das Loslassen. Emotional und spirituell wegtreten von dem, was in der Vergangenheit war. Achten Sie darauf, nicht in Trauer und Kummer zu ertränken, und wenn Sie das Gefühl haben runter zu gehen, bitten Sie um Hilfe. Es gibt viele Menschen um Sie herum, die sich freuen würden, Sie aus Ihrer negativen Stimmung ziehen zu können. Zu lange in Traurigkeit hängen bleiben ist nur eine andere Art um Veränderung zu bekämpfen.</p>",
                   }
               }
            },
            {
                filename: "14",
                numerology : 14,
                en : {
                    name : "Temperance",

                    zodiac : "♐ - Sagittarius",
                    archetype : "harmony",
                    theme : "balance",    
                    description : "Temperance talks about the conscious and the unconscious, the right balance, the ability to match, the art of life.",
                    questions : [ "Are you balanced?", "Are you on dry land but in contact with your feelings?", "Can you find the right balance?", "Can you balance usefully and have fun?" ],

                    bulletpoints : ["Patience","Calm","Serenity"],
                    birthCombinationShort: "Yours is the path of guidance by wisdom and peace.",
                    birthCombinationText : "You have the qualities to be an exceptional leader. You possess the great wisdom of the Hierophant, which is a necessary quality to effectively lead. And you also are able to resolve conflicts because of Temperance. You bring calm serenity to any situation.",

                    soalPoints : [ "Evenwicht", "Van alles genoeg", "Harmonie", "Lichaam en geest in balans", "Gelatenheid", "(Innerlijke) zielenrust", "Zielengids", "Genezing" ],
                    soalText : "Juiste mengsel is de balans tussen geest en lichaam en het evenwicht van je innerlijke wereld met de omgeving. Dit kun je bereiken door iedere keer weer vanuit je centrum te handelen. Dan ben je in harmonie met jezelf en kun je ook in harmonie omgaan met de buitenwereld.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Temperance focuses on balance, moderation, harmony, resignation, peace of mind, both in yourself and in relationships. You should be internally balanced, taking good care of yourself in order to optimally experience peace and joy.</p><p>Still, if you draw this card it may be time to have a closer look at all your relationships and ask yourself if you have issues or problems that are standing in the way of harmony. Now is the time to find out what it takes to achieve balance in every aspect of your life.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells us that, in our relationship(s), we may have experienced tumultuous times with many emotions to work through.But right now we are experiencing peace, relaxation and harmony. It's wise to use these quiet times to let our newly acquired insights come to fruition within us.</p><p>Temperance may indicate that a new level of commitment is nearby. Maybe you are thinking about moving in together or getting engaged or possibly even getting married.</p>",
                        [GuidanceFields.WORK] : "<p>At work, you accomplish your tasks with resignation. This may indicate that you're performing your tasks in a quiet and harmonious atmosphere, but it may also mean that you're leaning towards an apathetic attitude. The latter may be a sign of no longer being connected to yourself and your work, which will decrease the quality of your output and your creativity.</p><p>Temperance also tells us that, even if it may take some time, our accomplishments will not go unnoticed. Someday, people will let you know they are happy with what you do.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Temperance urges you to meditate, turn inward. Even though your life and relationships may be going very well, you may need to observe deeper personal issues and questions to find out if there's anything standing in the way of your development and growth. If you address these issues and work through them, youwill be able to integrate the insights you gain. Then you will regain balance, within yourself, in your relationships and all other aspects of your life.</p>",
                    }
                },
                fr : {
                    name : "La Tempérance",
                    zodiac : "♐ - Sagittaire",
                    archetype : "harmonie",
                    theme : "équilibre",    
                    description : "La modération nous parle du conscient et de l'inconscient de la bonne mesure, de l'art de mélanger, de l'art de vivre.",
                    questions : ["Êtes-vous en harmonie?", "Êtes-vous en contact avec vos sentiments, dans la réalité?", "Pouvez-vous trouver votre juste milieu?", "Pouvez-vous de manière ludique jongler entre l'utile et le plaisir?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La tempérance est synonyme d'équilibre, de modération, d'harmonie, de résignation et de tranquillité, en soi et dans les relations. Ils doivent être équilibrés intérieurement et prendre soin de soi afin de faire l'expérience optimale de la paix et la joie.</p><p>Si vous tirez cette carte, cependant, le temps est peut-être venu de repenser en profondeur toutes vos relations et de vous demander s'il y a des questions ou des problèmes qui font obstacles à l'harmonie. Il est temps de découvrir ce qui est nécessaire pour atteindre l'équilibre dans tous les aspects de votre vie.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells us that, in our relationship(s), we may have experienced tumultuous times with many emotions to work through.But right now we are experiencing peace, relaxation and harmony. It's wise to use these quiet times to let our newly acquired insights come to fruition within us.</p><p>Temperance may indicate that a new level of commitment is nearby. Maybe you are thinking about moving in together or getting engaged or possibly even getting married.</p>",
                        [GuidanceFields.WORK] : "<p>At work, you accomplish your tasks with resignation. This may indicate that you're performing your tasks in a quiet and harmonious atmosphere, but it may also mean that you're leaning towards an apathetic attitude. The latter may be a sign of no longer being connected to yourself and your work, which will decrease the quality of your output and your creativity.</p><p>Temperance also tells us that, even if it may take some time, our accomplishments will not go unnoticed. Someday, people will let you know they are happy with what you do.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Temperance urges you to meditate, turn inward. Even though your life and relationships may be going very well, you may need to observe deeper personal issues and questions to find out if there's anything standing in the way of your development and growth. If you address these issues and work through them, youwill be able to integrate the insights you gain. Then you will regain balance, within yourself, in your relationships and all other aspects of your life.</p>",
                    }
                },
                sp:{
                    name:"La Templanza",
                    zodiac: "♐ - Sagitario",
                    archetype: "armonía",
                    theme: "equilibrio",    
                    description: "La Templanza es sobre la conciencia y el inconsciente, sobre el tamaño correcto, sobre el arte de mezclar, sobre el arte de vivir",
                    questions: ["¿Estás en equilibrio?", "¿Tienes contacto con tus sentimientos, estás de pie en la realidad", "¿Puedes encontrar tu media dorada?", "¿Puedes lidiar juguetonamente con algo útil y divertido?"],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Templanza se centra en el equilibrio, la moderación, la armonía, la resignación, la paz de la mente, tanto en uno mismo y en las relaciones. Usted debe ser un equilibrio interno, teniendo buen cuidado de su mismo para experimentar de manera óptima la paz y la alegría. Aún así, si se traza esta tarjeta puede ser el momento para echar un vistazo más de cerca a todas sus relaciones y se pregunta si tiene problemas o problemas que se interponen en el camino de la armonía. Ahora es el tiempo para averiguar lo que se necesita para lograr el equilibrio en todos los aspectos de su vida.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Gematigdheid",
                    zodiac : "♐ - Boogschutter",
                    archetype : "harmonie",
                    theme : "evenwicht",    
                    description : "Gematigdheid gaat over bewust en onbewust, over de juiste maat, over de kunst van het mixen, over levenskunst.",
                    questions : [ "Ben jij in balans?", "Heb jij contact met je gevoel, staande in de werkelijkheid?", "Kun jij je gulden middenweg vinden?", "Kun je speels omgaan met nuttig en leuk?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart concentreert zich op evenwicht, gematigdheid, harmonie, aanvaarding en innerlijke rust, niet enkel in jezelf maar ook in je relaties. Als je innerlijk in evenwicht bent en goed voor jezelf zorgt dan kan je op de best mogelijke manier genieten van vrede en vreugde.</p><p>Wanneer je deze kaart trekt dan is het goed om even stil te staan bij je relaties: zijn er mogelijk problemen of spanningen die in de weg staan van harmonie? Wat kan je eraan doen om dit te veranderen? Vraag je ook af wat je kan doen om evenwicht terug te brengen in elk aspect van je leven.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart symboliseert dat je op dit ogenblik waarschijnlijk aan het genieten bent van een vredige, ontspannen en harmonieuze tijd in je relatie(s). Waarschijnlijk is deze fijne periode voorafgegaan door een drukke, verwarrende tijd waarin je erg veel emoties moest verwerken. En misschien heb je dankzij het verwerken van deze gevoelens een aantal nieuwe inzichten verworven. Neem er nu ruim de tijd voor om deze inzichten te laten rijpen en doordringen.</p><p>Gematigdheid kan ook een aanwijzing zijn voor het feit dat je klaar bent om een stap verder te gaan in je relatie, vooral wat engagement en toewijding betreft.</p>",
                        [GuidanceFields.WORK] : "<p>Volgens Gematigdheid is het zo dat je op dit moment de taken op je werk uitvoert met een zekere berusting. Dit kan er enerzijds op wijzen dat je aan het werken bent in een rustige en harmonieuze atmosfeer, maar anderzijds kan het ook betekenen dat je een beetje apathisch aan het worden bent, misschien zelfs wat moedeloos. Als dat laatste het geval is dan is het best mogelijk dat je de verbinding met jezelf en je werk een beetje kwijt bent, en dat zowel de kwaliteit van je werk als je creativiteit daaronder te lijden hebben.</p><p>Deze kaart kan ook betekenen dat, zelfs al duurt het nog even voor men je dat laat weten, je goede werk niet onopgemerkt blijft. Er hangen complimentjes in de lucht, geef de moed niet op!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Gematigdheid spoort je aan om te mediteren, naar je innerlijke kern te gaan. Het is best mogelijk dat alles wat je leven en je relaties betreft van een leien dakje loopt. Toch kan het nodig zijn om diepere persoonlijke problemen en vragen te bekijken. Op die manier kan je er achter komen of er iets in de weg staat van je ontwikkeling en groei. Als je problemen ontdekt en ze aanpakt en verwerkt, zal je merken dat je de inzichten die je hieruit haalt gemakkelijk kan integreren en gebruiken. En daardoor zal je je evenwicht vinden, zowel binnenin jezelf als in je relaties en andere aspecten van je leven.</p>",
                    }
                },
                de : {
                    name: "Die Mässigkeit",
                    zodiac : "♐ - Schütze",
                    archetype : "Harmonie",
                    theme : "Gleichgewicht",    
                    description : "Die Mässigkeit spricht vom Bewussten und Unbewussten des richtigen Maßes, von der Kunst des Mischens, von der Kunst des Lebens.",
                    questions : [ "Sind Sie in Harmonie?", "Sind Sie in Kontakt mit Ihren Gefühlen, in der Wirklichkeit?", "Können Sie Ihren Mittelweg finden?", "Können Sie spielerisch zwischen Nützlichkeit und Vergnügen jonglieren?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die Mäßigkeit steht für ein Gleichgewicht, für Mäßigung, Harmonie und Ruhe, sowohl in sich selbst als auch in Beziehungen. Sie sollten innerlich ausgeglichen werden, und gut für sich selbst sorgen, um den Frieden und die Freude optimal erleben zu können.</p><p>Wenn Sie diese Karte ziehen kann dennoch die Zeit gekommen sein, alle Ihrer Beziehungen gründlich zu überdenken und sich zu fragen, ob es Fragen oder Probleme gibt, die der Harmonie im Wege stehen. Jetzt ist es Zeit, um herauszufinden, was notwendig ist, damit Sie ein Gleichgewicht in jedem Aspekt Ihres Lebens erreichen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte sagt uns, dass Sie in Ihrer Beziehung (en) stürmische Zeiten mit vielen Emotionen erlebt und durch gearbeitet haben. Aber jetzt erfahren Sie die Ruhe, Entspannung und Harmonie. Es wäre klug, diese ruhigen Zeiten zu benutzen um zu den neu erworbenen Einsichten zu geraten, um diese jetzt Früchte tragen zu lassen.</p><p>Die Mäßigkeit kann darauf hindeuten, dass eine neue Ebene in Verpflichtung auf Sie zukommt. Vielleicht denken Sie daran mit jemandem zusammenzuziehen oder sich zu verloben oder vielleicht sogar zu heiraten.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit verfüllen Sie Ihre Aufgaben mit Gelassenheit. Dies kann darauf hinweisen, dass Sie die Ausführung Ihrer Aufgaben in einer ruhigen und harmonischen Atmosphäre machen, aber es kann auch bedeuten, dass Sie zu einem apathischen Haltung neigen. Letzteres kann darauf deuten, dass Sie nicht mehr mit Sich Selbst und Ihre Arbeit verbunden sind, was die Qualität Ihrer Leistung und Kreativität verringern kann.</p><p>Die Mäßigkeit sagt uns auch, dass, auch wenn es einige Zeit dauern kann, unsere Leistungen nicht unbemerkt bleiben werden. Eines Tages werden die Leute Sie sagen, dass sie mit dem, was Sie tun, zufrieden sind.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Mäßigkeit fordert Sie auf zu meditieren, Sich nach innen zu wenden. Auch wenn Ihr Leben und Ihre Beziehungen im Moment sehr gut sein können, müssen Sie möglicherweise tiefere persönliche Probleme und Fragen näher untersuchen um herauszufinden, ob Ihre Entwicklung und Ihren Wachstum etwas im Wege steht. Falls Sie diese Probleme angehen und durcharbeiten, werden Sie imstande sein, die erworbene Erkenntnisse zu integrieren. Dann werden Sie das Gleichgewicht wieder erfinden in Sich Selbst, in Ihren Beziehungen und in allen anderen Aspekten Ihres Lebens.</p>",
                    }
                }
            },
            {
                filename: "15",
                numerology : 15,
                en : {
                    name : "The Devil",

                    zodiac : "♑ - Capricorn",
                    archetype : "the manipulator",
                    theme : "self-inflicted limitation",    
                    description : "The Devil talks about seemingly being stuck, thinking to be trapped, self-inflicted limitation.",  
                    questions : [ "Are you being cheated upon, or are you cheating on yourself?", "Can you break the chains of your limitations?", "Are you open to enjoy life?", "Can you still be spontaneous?" ],

                    bulletpoints : ["Sensuality","Materialism","Excess"],
                    birthCombinationShort: "Your path is one of restoring beauty to the beastly.",
                    birthCombinationText : "You are very sensual and passionate about everything you do. The Devil card means that you are playful, which can lead to problems such as materialism. However, the addition of the Lovers adds passion into the mix, which restores a better balance.",

                    soalPoints : [ "Afhankelijkheid", "Verstrikking", "Verleiding", "Overdaad", "Verslaving", "Schaduwzijde", "Vrijwillig vastzitten" ],
                    soalText : "Verleiding is dat waarvan je denkt dat het mooi en goed is, maar waarbij je innerlijke stem weet dat het niet goed voor je is. En toch wil je steeds meer. Het is de verlokking die jou aanzet om dingen te doen die tegen je principes ingaan.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>From all the Tarot cards The Devil is the hardest to interpret, mainly because The Devil shows a different apparition to everyone. Still, there are some general aspects that can be named; dependence, apathy, impossibility to take initiative. The Devil tells us we're playing with fire and we should be very careful not to get burned. It also tells us that when we feel bound, imprisoned in a situation, we often unknowingly have the solution within ourselves. In any situation, no matter how grave, there always are options. Even if people around you are telling you that there is only one way to choose, only one path you can take, make sure that you check it all out for yourself because probably there will be other choices, you only have to find them.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Devil's temptations are stimulating and passionate which may be very pleasant. But there always is a downside. Playing with fire may be tempting, but the fire can become all consuming, destroying. The Devil tells us to look objectively at what we are doing.</p><p>If you're in a long term relationship, maybe you or your partner feel trapped, bored, tired. Try to talk about this to change and hopefully save the relationship. If you're single and looking for love you may well have reached the point of desperation. Remind yourself that this is utterly unattractive and do everything you can to change your outlook.</p>",
                        [GuidanceFields.WORK] : "<p>The Devil wants us to gain insight into our motives. Are we taking advantage of a colleague's gullibility or goodwill? Are we misleading colleagues or business partners? Observe yourself, be honest, don't judge, just decide to do better in the future. You want to be able to look at yourself in the mirror every day.</p><p>The Devil may also indicate that you feel trapped in your job, maybe even hate it, Observe the situation objectively: is the sense of security you get out of this job worth staying in a situation you intensely dislike?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Take a look around you, analyze the people you are surrounding yourself with, privately and in the workplace. Are they mostly positive, energetic, stimulating? Or do you have negative, cynical, criticizing people around you who havea destructive influence on you and your self-image? Surround yourself with the positive people, the ones who give you energy and inspiration. Use affirmations, meditation, … anything that can help you become and stay positive.</p>",
                    }
               },
               fr : {
                    name : "Le Diable",
                    zodiac : "♑ - Capricorne",
                    archetype : "le manipulateur",
                    theme : "restriction auto-imposée",    
                    description : "Le Diable, nous parle de l'impression d'être bloqué, de penser d’être en chaînés, de s'imposer des restrictions.",  
                    questions : ["Avez-vous été dupé ou trompez-vous vous-même?", "Pouvez-vous vous libérer de ce qui vous limite?", "Vous permettez-vous de profiter de la vie?", "Vous est-il encore possible de vous défouler?" ],
                
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De toutes les cartes de tarot, le diable est le plus difficile à interpréter, surtout parce que le diable se présente à chaque personne sous une forme différente. Néanmoins, il y a quelques aspects généraux que l'on peut nommer : la dépendance, l'apathie, l'impossibilité de prendre des initiatives. Le diable nous dit que nous sommes en train de jouer avec le feu et de faire très attention à ne pas nous brûler. La carte nous dit aussi que lorsque nous nous sentons liés et piégés dans une situation, nous avons souvent inconsciemment un certain contrôle sur la solution en nous. Dans n'importe quelle situation, quelle qu'en soit la gravité, il y a toujours des possibilités. Même si les gens autour de vous disent qu'il n'y a qu'un seul moyen, qu'une seule décision que vous pouvez prendre, vérifié les données par vous-même et validé ce qui est en accord avec vous .... Sûrement d'autres possibilités existent, à vous de les trouver !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Devil's temptations are stimulating and passionate which may be very pleasant. But there always is a downside. Playing with fire may be tempting, but the fire can become all consuming, destroying. The Devil tells us to look objectively at what we are doing.</p><p>If you're in a long term relationship, maybe you or your partner feel trapped, bored, tired. Try to talk about this to change and hopefully save the relationship. If you're single and looking for love you may well have reached the point of desperation. Remind yourself that this is utterly unattractive and do everything you can to change your outlook.</p>",
                        [GuidanceFields.WORK] : "<p>The Devil wants us to gain insight into our motives. Are we taking advantage of a colleague's gullibility or goodwill? Are we misleading colleagues or business partners? Observe yourself, be honest, don't judge, just decide to do better in the future. You want to be able to look at yourself in the mirror every day.</p><p>The Devil may also indicate that you feel trapped in your job, maybe even hate it, Observe the situation objectively: is the sense of security you get out of this job worth staying in a situation you intensely dislike?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Take a look around you, analyze the people you are surrounding yourself with, privately and in the workplace. Are they mostly positive, energetic, stimulating? Or do you have negative, cynical, criticizing people around you who havea destructive influence on you and your self-image? Surround yourself with the positive people, the ones who give you energy and inspiration. Use affirmations, meditation, … anything that can help you become and stay positive.</p>",
                    }
               },
               sp:{
                name:"El Diablo",
                zodiac: "♑ - Capricornio",
                archetype: "el manipulador",
                theme: "Restricción autoimpuesta",    
                description: "El Diablo se trata de estar atascado, de pensar en estar encadenado, de la restricción autoimpuesta",  
                questions: ["¿Estás siendo engañado, o estás engañándote a ti mismo?", "¿Puedes liberarte de lo que te limita?", "¿Te permites disfrutar de la vida?", "¿Alguna vez saltaste de la banda? "],
                
                cardoftheday: {
                    [GuidanceFields.DEFAULT] : "<p>De todas las cartas del Tarot El Diablo es el más difícil de interpretar, sobre todo porque el diablo muestra una aparición diferente a todos. Sin embargo, hay algunos aspectos generales que pueden ser nombradas; la dependencia, la apatía, la imposibilidad de tomar la iniciativa. El diablo nos dice que estamos jugando con fuego y debemos tener mucho cuidado de no quemarse. También nos dice que cuando nos sentimos obligados, encarcelado en una situación, a menudo sin saberlo, tenemos la solución dentro de nosotros mismos. En cualquier situación, no importa cuán grave, siempre hay opciones. Incluso si las personas que le rodean le están diciendo que sólo hay una manera de elegir, sólo un camino que puede tomar, asegúrese de que usted echa todo por su mismo, porque probablemente habrá otras opciones, es suficiente para encontrarlos.</p>",
                    [GuidanceFields.RELATIONSHIP] : "",
                    [GuidanceFields.WORK] : "",
                    [GuidanceFields.SPIRITUAL] : "",
                },
                },
               nl : {
                   name: "De Duivel",
                   zodiac : "♑ - Steenbok",
                   archetype : "de manipulator",
                   theme : "zelf opgelegde beperking",    
                   description : "De duivel gaat over schijnbaar vastzitten, over denken geketend te zijn, over zelf opgelegde beperking.",  
                   questions : [ "Wordt jij beduveld, of beduvel jij (je)zelf?", "Kun jij je losmaken van dat wat je beperkt?", "Sta jij jezelf toe om van het leven te genieten?", "Spring jij (nog) weleens uit de band?" ],  
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Van alle Tarotkaarten is deze het moeilijkst om te interpreteren. De Duivel betekent immers voor iedereen iets anders en ziet er daarom voor iedereen ook anders uit. Toch zijn er wel een aantal algemene kenmerken van “duivelse” invloed: lusteloosheid, afhankelijkheid, geen initiatief kunnen nemen. Vaak vertelt deze kaart je dat je met vuur aan het spelen bent en dus goed moet opletten dat je je niet brandt. Mogelijk heb je ook het gevoel dat je vastzit in een bepaalde situatie. En toch, misschien zonder het te weten, draag je de oplossing diep in jezelf.</p><p>Hoe ernstig en moeilijk je situatie ook is, je hebt altijd opties, mogelijkheden. Zelfs als de mensen om je heen je vertellen dat er maar één uitweg is, dan nog is het belangrijk dat je grondig nagaat of je niet toch nog een andere optie kan vinden. Probeer om niet in beperkingen te denken maar in mogelijkheden, dat is dé manier om De Duivel te verschalken!</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>De verleidingen van De Duivel zijn opwindend en vol passie, en dat kan natuurlijk erg leuk zijn. Toch is er altijd een negatieve kant aan. Met vuur spelen kan heel verleidelijk zijn maar dat vuur kan alles verslindend worden, vernietigend. De Duivel vertelt je dat het altijd goed is om eens objectief te kijken naar wat je aan het doen bent.</p><p>Als je een langdurige relatie hebt dan is het best mogelijk dat jij zelf of je partner (of mogelijk allebei) je gevangen voelt, verveeld, moe. Probeer hierover te praten, zo kan je de situatie veranderen en hopelijk de relatie redden.</p><p>Als je single bent en op zoek naar liefde dan is het best mogelijk dat je wanhopig aan het worden bent. Denk eraan dat wanhoop erg onaantrekkelijk is en doe er alles aan om je anders te voelen zodat je ook anders overkomt.</p>",
                       [GuidanceFields.WORK] : "<p>De Duivel wil dat je inzicht krijgt in je motieven. Ben je misbruik aan het maken van je collega's goedgelovigheid of goede wil? Ben je je collega's of zakenpartners aan het misleiden? Observeer jezelf, wees eerlijk, oordeel of veroordeel niet en als je inderdaad fout zit, beslis dan om het in de toekomst anders te doen. Je wil jezelf tenslotte elke dag in de spiegel in de ogen kunnen kijken.</p><p>Het is ook mogelijk dat De Duivel je erop wijst dat je je voelt vastzitten in je job, dat je wat je doet misschien zelfs haat. Kijk open en objectief naar je situatie: is de zekerheid van deze job voldoende belangrijk voor je om in een situatie te blijven waar je echt een hekel aan hebt?</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>Kijk eens om je heen, analyseer de mensen waar je je mee omringt, zowel privé als op het werk. Zijn het overwegend positieve, energieke, stimulerende mensen? Of heb je vooral negatieve, cynische, bekritiserende mensen om je heen die dus ook een negatieve invloed hebben op jou en je zelfbeeld?</p><p>Zorg ervoor dat je vooral omgaat met positieve mensen, die je energie en inspiratie geven, je een goed gevoel bezorgen. Gebruik affirmatietechnieken, meditatie, ... alles wat kan helpen om een positieve instelling te krijgen en te behouden.</p>",
                   }
               },
               de : {
                   name: "Der Teufel",
                   zodiac : "♑ - Steinbock",
                   archetype : "Der Manipulator",
                   theme : "Selbstauferlegte Einschränkung",    
                   description : "Der Teufel spricht vom Eindruck, blockiert zu sein, das Gefühl zu haben, in Ketten gelegt zu sein, uns selbst Einschränkungen aufzuerlegen.",  
                   questions : [ "Sind Sie getäuscht worden oder betrügen Sie sich selbst?", "Können Sie sich von dem befreien, was Sie einschränkt?", "Erlauben Sie sich, das Leben zu genießen?", "Ist es Ihnen noch möglich, sich auszutoben?" ],  
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Von allen Tarot-Karten ist der Teufel am schwierigsten zu interpretieren, vor allem deshalb, weil der Teufel sich bei jedem Menschen in einer anderen Erscheinungsform zeigt. Dennoch gibt es einige allgemeine Aspekte, die benannt werden können: Abhängigkeit, Apathie, die Unmöglichkeit Initiative zu ergreifen. Der Teufel sagt uns, dass wir mit dem Feuer spielen, und dass wir sehr vorsichtig sein sollten, damit wir uns nicht verbrennen. Die Karte sagt uns auch, dass, wenn wir das Gefühl haben, gebunden und in einer Situation gefangen zu sein, wir oft unbewusst über die Kontrolle über die Lösung in uns selbst verfügen. In jeder Situation, egal wie ernst diese ist, gibt es immer Möglichkeiten. Selbst wenn die Menschen in Ihrer Umgebung Ihnen sagen, dass es nur einen Weg gibt, nur eine Entscheidung, die Sie treffen können, überprüfen und verifzieren Sie alles selbst. Vielleicht gibt es dann doch noch andere Möglichkeiten, die Sie nur zu finden brauchen.</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>Die Teufels Versuchungen sind anregend und leidenschaftlich was sehr angenehm sein kann. Aber es gibt immer eine Kehrseite. Spiel mit dem Feuer kann verlockend sein, aber das Feuer kann auch alles in sich aufnehmen und zerstören. Der Teufel sagt uns, dass wir alles, was wir machen objektiv betrachten sollen.</p><p>Wenn Sie in einer langfristigen Beziehung sind, dann fühlen Sie oder Ihr Partner sich vielleicht gefangen, gelangweilt und müde. Versuchen Sie, darüber zu sprechen, um diese Gefühle zu ändern und die Beziehung hoffentlich zu retten. Wenn Sie alleine und auf der Suche nach Liebe sind können Sie mittlerweile auch den Punkt der Verzweiflung erreicht haben. Erinnern Sie sich daran, dass dies völlig unattraktiv ist und tun Sie alles was möglich ist, um Ihre Ausstrahlung zu ändern.</p>",
                       [GuidanceFields.WORK] : "<p>Der Teufel will, dass wir Einblick in unsere Motive gewinnen. Sind wir dabei der Gutgläubigkeit oder Firmenwert eines Kollegen auszunützen? Sind wir irreführende Kollegen oder Geschäftspartnern? Beobachten Sie sich, seien Sie ehrlich, urteilen Sie nicht, entscheiden Sie nur es in der Zukunft besser zu tun. Sie möchten doch gerne in der Lage sein, wo man sich selbst jeden Tag im Spiegel ansehen kann.</p><p>Der Teufel kann auch bedeuten, dass Sie Sich in Ihrer Arbeit gefangen fühlen, sie vielleicht sogar hassen. Beobachten Sie diese Situation objektiv: ist das Gefühl der Sicherheit, die diese Arbeit Ihnen gibt, groß genug um es wert zu sein darin hängenzubleiben obwohl Sie sich wirklich unwohl damit fühlen?</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>Werfen Sie einen Blick um Sich herum, die Menschen, womit Sie Sich umgeben, sowohl privat als in der Arbeit und analysieren Sie sie. Sind sie überwiegend positiv, energisch, und anregend? Oder haben Sie nur negative, zynische, kritisierende Menschen um Sie herum, die einen zerstörenden Einfluss auf Sie und Ihr Selbstbild haben? Umgeben Sie sich mit positiven Menschen, die Sie die Energie und Inspiration geben. Verwenden Sie Affirmationen, Meditationen, … alles, was Ihnen helfen kann positiv zu werden und zu bleiben.</p>",
                   }
               }
            },
            {
                filename: "16",
                numerology : 16,
                en : {
                    name : "The Tower",

                    planet : "♂ - Mars",
                    archetype : "break-through",
                    theme : "discharge",
                    description : "The Tower talks about high pressure, breaking old habits, sudden insights.",
                    questions : [ "Do you recognize the tension?", "Have you raised a wall?", "What do you what to break trhough?", "What could you do yourselve?" ],

                    bulletpoints : ["Revolution","Upheaval","Rage"],
                    birthCombinationShort: "Your path is of restoring balance in the face of transformation",
                    birthCombinationText : "You possess the ability to carry forward even in turbulent times. The Tower is a destructive force, but amidst the rubble, new paths emerge. Then the Chariot leads the way forward, maintaining a careful control over all impulses.",

                    soalPoints : [ "Plotseling", "Doorbraak", "Ineenstorting", "Ontsteltenis", "Donderslag bij heldere hemel", "Ingrijpende verandering van oude patronen", "Bevrijding" ],
                    soalText : "Doorbraak is soms nodig, patronen zijn vastgeroest en geven geen ruimte meer voor groei en nieuwe ontwikkeling.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>To describe The Tower one can use many terms and sometimes it will seem as if they are each other's opposite. The Tower can depict sudden insight but also a total lack of insight, depending on the person and the situation that person is in. The card can symbolize a traumatic experience, shock, carelessness. Actually, The Tower offers you clear insights into your own illusions, f.e. you think there are certain securities in your life and then you realize that these can suddenly and unexpectedly vanish. You are or were holding on to an illusion of security, that is what The Tower wants to tell you. Gaining this insight may give you a deep shock because you were totally convinced of your illusive beliefs. But getting rid of illusions can be a truly positive experience because it sets you free to explore new options and possibilities.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Tower may but does not necessarily indicate the end of a relationship. If you are absolutely sure you want to hold on to your partner then it is time to study all possible problems and obstacles there might be. It's also very important to find out what your partner really truly wants, do not interpret or guess but find out for sure, and then decide if you can and really want to live with these conditions.</p><p>The Tower can portray a change in thinking, acting or behavior in your relationship. Maybe you have held on to your partner too tightly or claimed him/her. You didn't freely decide to make a commitment because you were afraid to lose your partner, to be left behind. Sometimes The Tower tells you that even though you've been deeply hurt, now you are carefully opening up to a new relationship.</p>",
                        [GuidanceFields.WORK] : "<p>Use The Tower's height to climb on top of it and gain an overview on your career. Things may look totally different and turn out not to be what they seemed. Possibly there's a chance you may get fired, your expectations aren't met or your career is 'in ruins'. Take the time to work out what you would do in the event of losing your current job, who would you turn to, what resources would you use to find another job? After all, forewarned is forearmed.</p><p>You also may have to beware of conflict in the workplace.Try not to be too sensitive to what people say to you, and donot engage in discussions, heated or otherwise. </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This is a time of change, of letting go of illusions and fantasies. Life is about movement and transformation and there is no way you can stop that process so don't even try it. Be wise and go with the flow, see where life takes you and keep a positive attitude about it. Possibly some of your convictions and beliefs need to be torn down to make way for fresh, new insights. Try to enjoy this process.</p>",
                    }
                },
                fr : {
                    name : "La Tour",
                    planet : "♂ - Mars",
                    archetype : "l’avancée",
                    theme : "décharge",
                    description : "La tour nous parle de la haute tension, la rupture de vieux schémas, de l'intuition soudaine.",
                    questions : ["Reconnaissez-vous la tension?", "Avez-vous construit un mur?", "Que voulez-vous avancer?", "Que pourriez-vous y remédier?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Pour décrire la tour, on pourrait utiliser de nombreux termes, et il semble parfois qu'ils soient contradictoires.... La tour peut être le symbole d'une intuition soudaine, mais aussi d'une absence totale d'intuition, selon la personne et la situation dans laquelle elle se trouve. Cela dépend de l'endroit où vous vous trouvez dans la tour.  En haut, en bas ou au milieu. La carte peut aussi symboliser une expérience traumatisante, un choc ou une négligence grave. En fait, la tour vous offre un aperçu clair de vos propres illusions, par exemple : Vous pensez qu'il y a certaines sécurités dans votre vie, et puis vous vous rendez compte qu'elles disparaissent soudainement et de façon inattendue. Vous avez ou aviez une illusion de sécurité. C'est ce que la Tour veut vous dire. Cette découverte peut vous causer un choc profond parce que vous étiez complètement convaincu de votre foi illusoire. Mais se libérer de ces illusions peut aussi être une expérience vraiment positive parce qu'elle vous permet de découvrir de nouvelles options et possibilités.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Tower may but does not necessarily indicate the end of a relationship. If you are absolutely sure you want to hold on to your partner then it is time to study all possible problems and obstacles there might be. It's also very important to find out what your partner really truly wants, do not interpret or guess but find out for sure, and then decide if you can and really want to live with these conditions.</p><p>The Tower can portray a change in thinking, acting or behavior in your relationship. Maybe you have held on to your partner too tightly or claimed him/her. You didn't freely decide to make a commitment because you were afraid to lose your partner, to be left behind. Sometimes The Tower tells you that even though you've been deeply hurt, now you are carefully opening up to a new relationship.</p>",
                        [GuidanceFields.WORK] : "<p>Use The Tower's height to climb on top of it and gain an overview on your career. Things may look totally different and turn out not to be what they seemed. Possibly there's a chance you may get fired, your expectations aren't met or your career is 'in ruins'. Take the time to work out what you would do in the event of losing your current job, who would you turn to, what resources would you use to find another job? After all, forewarned is forearmed.</p><p>You also may have to beware of conflict in the workplace.Try not to be too sensitive to what people say to you, and donot engage in discussions, heated or otherwise. </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This is a time of change, of letting go of illusions and fantasies. Life is about movement and transformation and there is no way you can stop that process so don't even try it. Be wise and go with the flow, see where life takes you and keep a positive attitude about it. Possibly some of your convictions and beliefs need to be torn down to make way for fresh, new insights. Try to enjoy this process.</p>",
                    }
                },
                sp:{
                    name:"La Torre",
                    planet: "♂ - Marte",
                    archetype: "el gran avance",
                    theme: "Aprobación de la gestión",
                    description: "La Torre es sobre la alta tensión, sobre la ruptura de viejos patrones, sobre la visión repentina",
                    questions: ["¿Reconoces la tensión?", "¿Has construido un muro?", "¿Qué quieres romper?", "¿Qué podrías hacer al respecto?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Para describir la torre uno puede utilizar muchos términos y, a veces, parecerá como si estuvieran frente del otro. La torre puede representar una visión repentina, sino también una falta total de visión, dependiendo de la persona y de la situación de esa persona se encuentra. La tarjeta puede simbolizar una experiencia traumática, golpes, falta de cuidado. En realidad, la Torre le ofrece claras ideas sobre sus propias ilusiones, Fe Cree que hay ciertos valores en su vida y luego le da cuenta que estas pueden desaparecer de repente y de forma inesperada. Usted está o estaba aferrando a una ilusión de seguridad, que es lo que la Torre quiere decirle. Ganando esta idea puede darle un shock profundo porque estaba totalmente convencido de sus creencias esquivas. Pero deshacerse de las ilusiones puede ser una experiencia realmente positiva porque le hace libre a explorar nuevas opciones y posibilidades.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "De Toren",
                    planet : "♂ - Mars",
                    archetype : "de doorbraak",
                    theme : "ontlading",
                    description : "De toren gaat over hoog opgelopen spanning, over doorbreken van oude patronen, over plotseling inzicht.",
                    questions : [ "Herken je de spanning?", "Heb je een \"muur\" opgetrokken?", "Wat wil je doorbreken?", "Wat zou je hier aan kunnen doen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Je kan de betekenis van De Toren op vele manieren omschrijven en vaak lijken de termen die dan gebruikt worden met mekaar in tegenspraak. Deze kaart kan bijvoorbeeld staan voor een plots inzicht dat je krijgt maar ook voor een totaal gebrek aan inzicht. Het hangt er gewoon van af in welke situatie je je bevindt, waar je in De Toren staat (bovenaan, onderaan of misschien halfweg?).</p><p>De kaart kan ook een pijnlijke ervaring symboliseren, een schok, een gevoel alsof je hard wakker geschud wordt.</p><p>Waar het op neerkomt is dat De Toren je inzicht wil bieden in je eigen illusies. Je kan wel denken dat er een aantal zekerheden in je leven zijn, maar soms word je ermee geconfronteerd dat die zekerheden plots van je weggenomen worden. Je houdt of hield je eigenlijk vast aan een illusie van veiligheid, dàt wil De Toren je vertellen. Ook al is het schokkend om te beseffen dat niets eigenlijk zeker is, toch kan het ook behoorlijk bevrijdend zijn om je illusies los te laten. Je opent immers de weg voor een boel nieuwe mogelijkheden!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Toren kan wijzen op het einde van een relatie al is dat niet altijd zo. Als je er volkomen zeker van bent dat je bij je partner wil blijven, dan is het nu het juiste moment om alle problemen en obstakels te bestuderen en aan te pakken. Neem ook de tijd om er achter te komen wat je partner echt wil en beslis dan of je met die wensen en verlangens wil en kan leven.</p><p>De Toren symboliseert soms een verandering van denken, handelen of gedrag in je relatie. Misschien heb je je vastgeklampt aan je partner of hem/haar geclaimd. Dat kan betekenen dat je niet in volle vrijheid beslist hebt om je te engageren in de relatie omdat je bang was om je partner te verliezen, om verlaten te worden. Soms vertelt De Toren je dat je er klaar voor bent om je voorzichtig te gaan openstellen voor een nieuwe relatie, ook al ben je intens gekwetst geweest in een vorige verbintenis.</p>",
                        [GuidanceFields.WORK] : "<p>Maak gebruik van de hoogte van De Toren om naar de top te klimmen en van daaruit een overzicht te krijgen over je carrière. Het is best mogelijk dat de dingen er helemaal anders uitzien en niet datgene blijken wat je eerst dacht.</p><p>Deze kaart wijst erop dat je mogelijk risico loopt op ontslag, dat je verwachtingen niet tegemoetgekomen worden of dat je carrière echt \"een ruïne\" is. Neem de tijd om uit te zoeken wat je zou doen als je je huidige baan zou verliezen, tot wie zou je je wenden, welke bronnen zou je gebruiken om een andere baan te vinden? Tenslotte is een gewaarschuwd mens er twee waard.</p><p>Mogelijk word je op het moment op het werk geconfronteerd met conflicten. Probeer om niet te gevoelig te zijn voor wat mensen tegen je zeggen en doe niet mee met discussies, of ze nu verhit zijn of niet.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Volgens De Toren is het tijd voor verandering, om illusies en fantasieën los te laten. Leven is tenslotte bewegen, transformeren en dat proces kan je niet stoppen, zelfs al zou je het proberen. Wees verstandig en ga mee met de stroming, kijk waar je terechtkomt en zorgt dat je instelling positief is. Het is best mogelijk dat sommige van je overtuigingen vernietigd moeten worden om ruimte te maken voor frisse, nieuwe inzichten. Probeer om te genieten van dit gebeuren!</p>",
                    }
                },
                de : {
                    name: "Der Turm",
                    planet : "♂ - Mars",
                    archetype : "Der Fortschritt",
                    theme : "Entladung",
                    description : "Der Turm spricht von Hochspannung, dem Aufbrechen alter Muster, plötzlicher Intuition...",
                    questions : [ "Erkennen Sie die Spannung?", "Haben Sie eine Mauer gebaut?", "Was wollen Sie vorantreiben?", "Was konnten Sie dagegen tun?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Um den Turm zu beschreiben, könnte man viele Begriffe verwenden, und manchmal hat es den Anschein, als ob diese gegensätzlich wären. Der Turm kann für eine plötzliche Einsicht stehen, aber auch für einen völligen Mangel an Einsicht, abhängig von der Person und der Lage, in der diese Person gerade ist. Das hängt davon ab, wo man sich im Turm befindet.  Ganz oben, ganz unten oder in der Mitte. Die Karte kann auch eine traumatische Erfahrung, einen Schock, oder grobe Nachlässigkeit symbolisieren. Eigentlich bietet der Turm Ihnen klare Einblicke in Ihre eigenen Illusionen, z.B.: Sie denken, dass es bestimmte Sicherheiten in Ihrem Leben gibt, und dann merken Sie, dass diese plötzlich und unerwartet verschwinden. Sie haben oder hatten an einer Illusion von Sicherheit festgehalten. Das ist es, was Ihnen der Turm sagen will. Diese Entdeckung kann bei Ihnen einen tiefen Schock auslösen, weil Sie von Ihrem illusorischen Glauben vollkommen überzeugt waren. Aber sich von diesen Illusionen zu befreien, kann auch eine wirklich positive Erfahrung sein, weil es Sie frei macht, neue Optionen und Möglichkeiten zu entdecken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Der Turm kann, aber muss nicht unbedingt auf das Ende einer Beziehung deuten. Wenn Sie absolut davon überzeugt sind, dass Sie zu Ihrem Partner halten wollen, dann ist jetzt die Zeit, um alle mögliche Probleme und Hindernisse zu untersuchen. Es ist auch sehr wichtig um herauszufinden, was Ihr Partner wirklich will. Sie sollten nicht interpretieren oder versuchen zu erraten, aber tatsächlich herausfinden, was verlangt wird. Dann können Sie Sich entscheiden, ob Sie wirklich unter diesen Bedingungen leben wollen.</p><p>Der Turm kann eine Veränderung im Denken, Handeln und Verhalten in Ihrer Beziehung darstellen. Vielleicht haben Sie Ihren Partner zu fest gehalten oder zu viel beansprucht. Sie haben Sich nicht freiwillig entschieden eine Verpflichtung einzugehen, weil Sie Angst hatten von Ihrem Partner, zurückgelassen zu werden oder sie/ihn zu verlieren. Manchmal sagt der Turm Ihnen, dass, obwohl Sie tief verletzt wurden, Sie Sich jetzt vorsichtig öffnen zu einer neuen Beziehung.</p>",
                        [GuidanceFields.WORK] : "<p>Benutzen Sie die Höhe des Turms nach oben zu klettern und Sich einen Überblick über Ihre Karriere zu geben. Es sieht dann ganz anders aus und es könnte sich herausstellen, dass es nicht so aussieht wie sie zu sein schien. Möglicherweise gibt es eine Chance, dass Sie gefeuert werden, Ihre Erwartungen nicht erfüllt werden oder Ihre Karriere ist \"in Trümmern\" gefallen. Nehmen Sie Sich Zeit herauszufinden, was Sie in dem Fall, dass Sie Ihre aktuelle Arbeit verlieren tun würden. An wem würden Sie Sich wenden, welche Quellen würden Sie beanspruchen um eine andere Arbeit zu finden? Immerhin: vorgewarnt ist gewappnet.</p><p>Sie müssen möglicherweise auch den Streit an der Arbeitsstelle eingehen. Seien Sie vorsichtig und nicht zu empfindlich für das was die Leute sagen. Beteiligen Sie nicht an Diskussionen, erhitzt oder anderweitig.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Dies ist eine Zeit der Veränderung, des Loslassens von Illusionen und Fantasien. Das Leben handelt über Bewegung und Veränderung und es gibt keine Möglichkeit, diesen Prozess zu stoppen. Sie sollen es nicht einmal versuchen. Seien Sie klug und schwimmen Sie mit dem Strom um zu sehen, wo das Leben Sie hinführt und behalten eine positive Einstellung. Möglicherweise müssen Sie Sich von einigen Ihrer Überzeugungen und Glaubenssätze verabschieden, damit für frische Platz gemacht werden kann. Versuchen Sie es, diesen Prozess zu genießen.</p>",
                    }
                }
            },
            {
                filename: "17",
                numerology : 17,
                en : {
                    name : "The Star",

                    zodiac : "♒ - Aquarius",
                    archetype : "full-blown truth",
                    theme : "things are the way they are",    
                    description : "The Star talks about the actual self, the way it is, the full blown truth.",
                    questions : [ "How visible are you?", "Can you show the real you?", "Are you vulnerable doing so?", "Which part of yourself could you show?" ],

                    bulletpoints : ["Hope","Faith","Rejuvenation"],
                    birthCombinationShort: "This path gives you hope as the source of your courage.",
                    birthCombinationText : "A well known icon of wishing, the Star represents hope and an overall optimistic outlook on life. This stems from a great inner Strength, which can carry you through the hard times of life.",

                    soalPoints : [ "Nieuwe mooie toekomst", "Hoop", "Goede ster", "Geluk en vervulling" ],
                    soalText : "Hoop is dat waar je jezelf op kunt richten om met kracht de richting in te gaan waar je hart vol van is. Deze ster wijst je een goede weg, zij is het \"lichtpuntje\" waar je jezelf vol vertrouwen op kunt richten. Later in de tijd zul je merken dat deze richting goed voor je was.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Star is a card of hope, inspiration, wisdom and higher insight. It tells you that the results of what you are working on right now, will shed their light far into the future.  You are serving the higher good for all concerned. The Star is, along with The High Priestess and The Hierophant, a card that promises protection. Feel free to be hopeful about the future and have faith that your needs will be met, be they material, spiritual or emotional. Think big, think positive.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In personal relationships The Star symbolizes promising encounters. Maybe you will meet someone new, but it's also possible you will see someone you already know in a totally new light. This encounter will not be fleeting, it will stay with you far into the future. Relationships depicted by The Star are deemed to be under a lucky star. No matter if you meet someone new or if you find new dimensions in your current partner, these are wonderful times.</p>",
                        [GuidanceFields.WORK] : "<p>The Star indicates that you are starting a new task or even a new job. It's also possible that your own plans or proposals are finally set in motion.</p><p>If you are not happy with your current job this is the perfect time to go on a job hunt.Just make sure you are open to and ready for new opportunities. Prepare yourself for the challenge, brush up your cv, think about tactics... When you draw The Star, prospects are very bright.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Star tells you that you are truly and deeply in touch with your spiritual side. Now is the perfect time to reach out and touch the people around you. You have a lot of wisdom, kindness, serenity and happiness to share. And while you are freely giving and sharing, you will realize that you are being rewarded for your generosity over and over again. The Star tells you that you are ready to let your inner light shine.</p>",
                    }
               },
               fr : {
                    name : "l’Etoile",
                    zodiac : "♒ - Verseau",
                    archetype : "la pure vérité",
                    theme : "Les choses sont ce qu’elles sont",    
                    description : "L'étoile nous parle du Moi essentiel, du Moi tel qu'il est, à propos de notre pure vérité.",
                    questions : ["Dans quelle mesure êtes-vous visible", "Pouvez-vous montrer votre Moi essentiel", "Êtes-vous vulnérable dans cette situation", "Que pourriez-vous montrer de vous-même?" ],
                
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>L'étoile est une carte d'espoir, d'inspiration, de sagesse et de perspicacité supérieure. Il vous dit que les choses sur lesquelles vous travaillez jetteront leur lumière loin dans le futur. Ils servent les valeurs supérieures de toutes les personnes concernées. L'étoile, avec la Grande Prêtresse et l’Hiérophante, est une carte qui promet la protection. N'hésitez pas à regarder l'avenir avec espoir et à avoir confiance que vos besoins seront satisfaits, qu'ils soient matériels, spirituels ou émotionnels. Voyez grand et positif.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In personal relationships The Star symbolizes promising encounters. Maybe you will meet someone new, but it's also possible you will see someone you already know in a totally new light. This encounter will not be fleeting, it will stay with you far into the future. Relationships depicted by The Star are deemed to be under a lucky star. No matter if you meet someone new or if you find new dimensions in your current partner, these are wonderful times.</p>",
                        [GuidanceFields.WORK] : "<p>The Star indicates that you are starting a new task or even a new job. It's also possible that your own plans or proposals are finally set in motion.</p><p>If you are not happy with your current job this is the perfect time to go on a job hunt.Just make sure you are open to and ready for new opportunities. Prepare yourself for the challenge, brush up your cv, think about tactics... When you draw The Star, prospects are very bright.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Star tells you that you are truly and deeply in touch with your spiritual side. Now is the perfect time to reach out and touch the people around you. You have a lot of wisdom, kindness, serenity and happiness to share. And while you are freely giving and sharing, you will realize that you are being rewarded for your generosity over and over again. The Star tells you that you are ready to let your inner light shine.</p>",
                    }
               },
               sp:{
                name:"La Estrella",
                zodiac: "♒ - Acuario",
                arquetipo: "la verdad desnuda",
                theme: "es como es",    
                description: "La Estrella es sobre el ser esencial, sobre lo que es, sobre la verdad desnuda",
                questions: ["¿Qué tan visible eres?", "¿Puedes mostrar tu ser esencial?", "¿Eres vulnerable en esto?", "¿Qué podrías mostrar de ti mismo?" ],
                
                cardoftheday: {
                    [GuidanceFields.DEFAULT] : "<p>La estrella es una tarjeta de esperanza, inspiración, sabiduría y mayor penetración. Le dice que los resultados de lo que se está trabajando en este momento, se derramaron su luz en el futuro. Usted está sirviendo el bien mayor para todos los interesados. La estrella es, junto con La Sacerdotisa y El Sumo Sacerdote, una tarjeta que promete protección. Siéntase libre de ser optimista sobre el futuro y tener fe en que se cumplan sus necesidades, ya sean materiales, espirituales o emocionales. Piensa en grande, pensar en positivo.</p>",
                    [GuidanceFields.RELATIONSHIP] : "",
                    [GuidanceFields.WORK] : "",
                    [GuidanceFields.SPIRITUAL] : "",
                },
                },
               nl : {
                   name: "De Ster",
                   zodiac : "♒ - Waterman",
                   archetype : "de naakte waarheid",
                   theme : "het is zoals het is",    
                   description : "De ster gaat over het wezenlijke zelf, over het is zoals het is, over de naakte waarheid.",
                   questions : [ "Hoe zichtbaar ben jij?", "Kun jij je wezenlijke zelf laten zien?", "Ben je daarin kwetsbaar?", "Wat zou jij van jezelf kunnen tonen?" ], 
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Deze kaart staat voor hoop, inspiratie, wijsheid en diepe inzichten. De Ster ligt er om je te vertellen dat je harde werk vruchten zal afwerpen tot ver in de toekomst. Je werkt niet enkel voor jezelf maar ook voor het welzijn van alle betrokkenen. De Ster is, samen met De Hogepriesteres en De Hogepriester, een kaart die bescherming belooft. Voel je vrij om hoop te hebben voor de toekomst en geloof erin dat je datgene zal krijgen wat je nodig hebt, niet enkel op materieel vlak maar ook spiritueel en emotioneel. Denk ruim, denk positief!</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>In verband met relaties staat De Ster voor ontmoetingen. Misschien komt er een nieuw iemand op je weg, maar het is ook mogelijk dat je iemand die je al kent in een totaal ander licht zal gaan zien. Deze ontmoeting waarvan sprake is niet zomaar oppervlakkig en voorbijgaand maar zal je tot ver in de toekomst bijblijven. Relaties die in verband staan met De Ster ontwikkelen zich vaak onder een goed gesternte. Of je nu een nieuwe ontmoeting hebt of je vindt nieuwe dimensies in je huidige partner, het zijn fantastische tijden op relatievlak.</p>",
                       [GuidanceFields.WORK] : "<p>Volgens De Ster begin je binnenkort aan een nieuwe taak of zelfs aan een nieuwe baan. Het is ook mogelijk dat projecten of plannen die jij voorgesteld hebt, nu eindelijk uitgevoerd worden.</p><p>Als je niet tevreden bent met je huidige job dan is het nu de perfecte tijd om op zoek te gaan naar een andere. Het is wel belangrijk dat je open staat en klaar bent voor nieuwe kansen. Zorg dat je zin hebt in de uitdaging, poets je CV op, bestudeer sollicitatietechnieken...</p><p>Als je De Ster trekt dan zijn je vooruitzichten over het algemeen erg positief.</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>De Ster vertelt je dat je oprecht en diepgaand contact hebt met je spirituele kant. Mede daarom is het een perfecte tijd om de hand te reiken naar de mensen om je heen en ook echt contact te maken. Je hebt veel wijsheid, gelijkmoedigheid, vreugde, warmte te delen. En terwijl je dat allemaal aan het uitdelen en delen bent, zal je merken dat je telkens weer beloond wordt voor je vrijgevigheid.</p><p>Volgens De Ster ben je er klaar voor om je innerlijke licht te laten schijnen op de mensen om je heen.</p>",
                   }
               },
               de : {
                   name: "Der Stern",
                   zodiac : "♒ - Wassermann",
                   archetype : "Die reine Wahrheit",
                   theme : "Die Dinge sind, was sie sind.",    
                   description : "Der Stern spricht über das wesentliche Selbst, das Selbst, wie es ist, über unsere reine Wahrheit.",
                   questions : [ "Inwieweit sind Sie sichtbar?", "Können Sie Ihr essentielles Selbst zeigen?", "Sind Sie in dieser Situation verletzlich?", "Was können Sie von sich selbst zeigen?" ], 
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Der Stern ist eine Karte der Hoffnung, der Inspiration, der Weisheit und der höheren Einsicht. Er sagt Ihnen, dass die Dinge, an denen Sie gerade arbeiten, ihr Licht weit in die Zukunft werfen werden. Sie dienen den höheren Werten aller Beteiligten. Der Stern ist zusammen mit der Hohepriesterin und dem Hierophanten eine Karte, die Schutz verspricht. Fühlen Sie sich frei, hoffnungsvoll in die Zukunft zu blicken und haben Sie den Glauben, dass Ihre Bedürfnisse erfüllt werden, seien es materielle, geistige oder emotionale. Denken Sie groß und positiv.</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>In persönlichen Beziehungen symbolisiert der Stern vielversprechende Begegnungen. Vielleicht werden Sie jemanden treffen, der bis jetzt noch unbekannt ist, aber es ist auch durchaus möglich, das es hier jemanden betrifft, den Sie bereits kennen aber auf einmal in einem völlig neuen Licht sehen. Diese Begegnung wird nicht flüchtig sein aber ganz weit mit Ihnen in die Zukunft gehen. Beziehungen, die von dem Stern dargestellt werden, gelten als stehen sie unter einem guten Stern. Egal, ob Sie jemanden treffen der bis jetzt noch unbekannt war, oder ob Sie eine neue Dimension in Ihrem aktuellen Partner finden, es werden wunderbare Zeiten.</p>",
                       [GuidanceFields.WORK] : "<p>Der Stern zeigt an, dass Sie Im Anfang einer neuen Aufgabe oder sogar einer neuen Arbeit sind. Es ist auch möglich, dass sich schließlich Ihre eigenen Pläne oder Vorschläge in Bewegung setzen.</p><p>Wenn Sie nicht in Ihrer aktuellen Arbeit glücklich sind, ist dies die perfekte Zeit, um auf eine andere Arbeit zu jagen. Stellen Sie nur sicher, dass Sie offen und bereit sind für neue Möglichkeiten. Bereiten Sie Sich vor auf die Herausforderung, putzen Sie Ihren Lebenslauf, und denke über den Taktik nach… Wenn Sie den Stern ziehen, sind die Voraussichten hell und klar.</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>Der Stern zeigt Ihnen, dass Sie jetzt tief und wirklich in Kontakt sind mit Ihrer spirituellen Seite. Jetzt ist die perfekte Zeit die Hände auszustrecken und die Leute um Sie herum zu berühren. Sie haben eine Menge Weisheit, Freundlichkeit, Ruhe und Glück zu teilen. Und während Sie diese freigeben um zu teilen, werden Sie feststellen, dass Sie für Ihre Großzügigkeit mehr als belohnt werden und das immer wieder. Der Stern sagt, dass Sie bereit sind Ihr inneres Licht leuchten zu lassen.</p>",
                   }
               }
            },
            {
                filename: "18",
                numerology : 18,
                en : {
                    name : "The Moon",

                    zodiac : "♓ - Pisces",
                    archetype : "the night",
                    theme : "handle insecurity",    
                    description : "The Moon talks about confusion, mood swings, feeling you need to go without knowing where to (yet).",
                    questions : [ "Do you feel you need to leave?", "Are you insecure?", "Dare you go your own way?" ],

                    bulletpoints : ["Illusion","Phantoms","Dreams"],
                    birthCombinationShort: "Your path is finding the clarity in the darkness.",
                    birthCombinationText : "As a Hermit, you tend to be solitary, preferring to follow your own inner voice and intelligence. You use this to find your path through the darkness and dreams that the Moon brings.",

                    soalPoints : [ "Duisternis", "Schaduwzijde", "Dromen", "Angsten", "Onzekerheid", "Bange voorgevoelens" ],
                    soalText : "Duisternis is het donker waardoor je geen helder (in)zicht hebt in zaken. Je bent bang en onzeker omdat je niet weet waar je aan toe bent. Ergens in jouw verleden heeft deze angst zich diep genesteld in je.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Moon tells you to explore the mysterious world of your psyche and intuition. The bright side of The Moon symbolizes romantic dreams, vivid fantasies and profound emotions.  The dark side refers to forces within you like fears, uncertainty, hesitation to explore the yet unknown, that are limiting you, maybe even unconsciously. Being confronted with your light and dark side may tip you out of balance, confuse you. Right now it may be hard to understand yourself and the people around you. Don't push yourself or others but take the time to regain balance and insight.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Where personal relationships are concerned, The Moon tells you that you tend to lose yourself in romantic dreams, you sometimes even build castles in the air. You are straying too far from reality, possibly because you are insecure and frightened. The Moon also symbolizes relationships defined by jealousy and fear of abandonment. This may not be a good time to make decisions concerning relationships, be it to end an existing one or to start a new one. Try to treat your partner primarily as a friend, in full confidence they have the best of intentions about you.</p>",
                        [GuidanceFields.WORK] : "<p>The Moon tells you there may be a reason to be afraid or unsettled. Possibly you are not certain you will be able to complete a certain task, or you are afraid people will see through you and realize you're not as good as you seemed to be. There's one thing for certain: you cannot run away from your fears. Take a deep breath, recognize your anxieties and confront them. Analyze the situation and discover if there really are things to be afraid of or if maybe you just created the fear(s) all by yourself. Often, when you look fear straight in the eye, it disappears.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Moon tells you that you are living a very spiritual phase.Now is a good time to have your cards read, get a Reiki treatment, a chakra healing, … You are spiritually open and alert, you take the time to pay attention to your thoughts, feelings and ideas. You are also very sensitive to messages that come to you from spiritual or even unknown sources. If you have to make decisions or evaluate people and relationships, go with your gut feeling, trust your instincts.</p>",
                    }
                },
                fr : {
                    name : "La Lune",
                    zodiac : "♓ - Poisson",
                    archetype : "la nuit",
                    theme : "faire face à l'incertitude",    
                    description : "La Lune, nous parle de la confusion, des changements d'humeur, du sentiment qu'il faut démarrer, sans savoir (encore) ce que l'on peut rencontrer...",
                    questions : ["Vous sentez-vous obligé de continuer votre chemin?", "Êtes-vous incertain?", "Osez-vous suivre votre propre chemin?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La lune vous invite à explorer le monde mystérieux de la psyché et de l'intuition. Le côté lumineux de la lune symbolise les rêves romantiques, les fantasmes vifs et les émotions profondes. Le côté obscur fait référence à des forces en vous comme la peur, l'insécurité et l'hésitation à découvrir l'inconnu. Ce sont des forces qui vous limitent, peut-être même inconsciemment. Lorsque vous êtes confronté à vos côtés clairs et sombres, vous pouvez devenir déséquilibré, vous pouvez vous sentir confus.  À ce moment, il peut être difficile de se comprendre soi-même et les gens autour de soi. Ne poussez, ni vous, ni les autres et prenez le temps de retrouver votre équilibre et votre perspicacité.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Where personal relationships are concerned, The Moon tells you that you tend to lose yourself in romantic dreams, you sometimes even build castles in the air. You are straying too far from reality, possibly because you are insecure and frightened. The Moon also symbolizes relationships defined by jealousy and fear of abandonment. This may not be a good time to make decisions concerning relationships, be it to end an existing one or to start a new one. Try to treat your partner primarily as a friend, in full confidence they have the best of intentions about you.</p>",
                        [GuidanceFields.WORK] : "<p>The Moon tells you there may be a reason to be afraid or unsettled. Possibly you are not certain you will be able to complete a certain task, or you are afraid people will see through you and realize you're not as good as you seemed to be. There's one thing for certain: you cannot run away from your fears. Take a deep breath, recognize your anxieties and confront them. Analyze the situation and discover if there really are things to be afraid of or if maybe you just created the fear(s) all by yourself. Often, when you look fear straight in the eye, it disappears.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Moon tells you that you are living a very spiritual phase.Now is a good time to have your cards read, get a Reiki treatment, a chakra healing, … You are spiritually open and alert, you take the time to pay attention to your thoughts, feelings and ideas. You are also very sensitive to messages that come to you from spiritual or even unknown sources. If you have to make decisions or evaluate people and relationships, go with your gut feeling, trust your instincts.</p>",
                    }
                },
                sp:{
                    name:"La Luna",
                    zodiac: "♓ - Pesca",
                    archetype: "la noche",
                    theme: " Lidiar con la incertidumbre",    
                    description: "La Luna es sobre la confusión, sobre el cambio de humor, sobre el sentimiento de que tienes que seguir tu camino, sin (todavía) saber lo que puedes encontrar",
                    questions: ["¿Sientes que tienes que seguir tu camino?", "¿Estás inseguro?", "¿Te atreves a seguir tu propio camino?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La Luna le dice  explorar el misterioso mundo de su psique y la intuición. El lado bueno de La Luna simboliza sueños románticos, vívidas fantasías y emociones profundas. El lado oscuro se refiere a las fuerzas dentro de ti como el temor, la incertidumbre, vacilación para explorar la aún desconocida, que limitan, tal vez incluso de manera inconsciente. Ser confrontado con su luz y el lado oscuro que podría volcar fuera de balance, confundirte. En este momento puede ser difícil de entender a sí mismo y a las personas que te rodean. No empuje usted mismo a otros, pero tómese el tiempo para recuperar el equilibrio y la visión.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name: "De Maan",
                    zodiac : "♓ - Vissen",
                    archetype : "de nacht",
                    theme : "omgaan met onzekerheid",    
                    description : "De maan gaat over verwarring, over wisselende stemmingen, over het voelen dat je op weg dient te gaan (nog) niet wetend wat je tegen kunt komen.",
                    questions : [ "Voel je dat je op weg dient te gaan?", "Ben je onzeker?", "Durf jij je eigen weg te gaan?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Maan spoort je aan om de geheimzinnige wereld van je geest en je intuïtie te onderzoeken. De heldere kant van De Maan staat voor romantische dromen, levendige fantasieën en diepgaande emoties. De donkere kant staat voor krachten binnenin je zoals angsten, onzekerheid, huiverigheid om onbekende dingen te onderzoeken. Die laatste zijn allemaal zaken die jou, misschien zelfs onbewust, beperken en tegenhouden. Geconfronteerd worden met je lichte èn donkere kant kan je uit evenwicht brengen, je verwarren. Op dit moment hebt je het misschien moeilijk om niet alleen jezelf te begrijpen maar ook de mensen om je heen. Zet jezelf en anderen niet teveel onder druk maar neem de tijd om terug in evenwicht te komen en de nodige inzichten te verwerven.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wat je relaties betreft vertelt De Maan je dat je op het ogenblik de neiging hebt om jezelf te verliezen in romantische dromen en het bouwen van luchtkastelen. Je dwaalt nogal ver af van de realiteit, misschien omdat je diep vanbinnen onzeker en angstig bent.</p><p>De Maan kan ook staan voor relaties die bepaald of gekleurd worden door jaloezie en verlatingsangst. Het is nu geen goede tijd om belangrijke beslissingen te nemen in verband met je relatie(s), of het er nu om gaat een relatie te beëindigen of om er één te beginnen. Probeer in elk geval je partner in eerste instantie te behandelen als vriend(in), met het volste vertrouwen in zijn/haar goede bedoelingen.</p>",
                        [GuidanceFields.WORK] : "<p>De Maan vertelt je dat er misschien een reden is om angstig of onzeker, ongedurig te zijn. Het is mogelijk dat je er niet zeker van bent dat je een bepaalde taak wel kan afwerken, je bent bang om doorzien te worden, of je begint te beseffen dat je niet zo goed bent als je dacht te zijn. Eén ding is een feit: je kan niet weglopen van deze angsten. Haal eens diep adem, (h)erken je angsten en pak ze aan. Analyseer de situatie en probeer te ontdekken of er echt dingen zijn waar je bang voor hoort te zijn of dat je die angsten misschien helemaal zelf gecreëerd hebt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Volgens De Maan maak je momenteel een heel spirituele fase door. Het is nu de juiste tijd om je kaarten te laten leggen, een Reikihealing te krijgen, een Chakrabehandeling, ... Op spiritueel vlak ben je open en alert, je neemt de tijd om aandacht te besteden aan je gevoelens, gedachten en ideeën. Je bent ook erg gevoelig voor boodschappen die je doorkrijgt vanuit spirituele of zelfs onbekende bron. Als je beslissingen moet nemen of mensen en relaties moet evalueren, ga dan mee met je buikgevoel, vertrouw je intuïtie en je instinct.</p>",
                    }
                },
                de : {
                    name: "Der Mond",
                    zodiac : "♓ - Fische",
                    archetype : "Die Nacht",
                    theme : "Umgang mit Ungewissheit",    
                    description : "Der Mond spricht von der Verwirrung, den Stimmungsschwankungen, dem Gefühl, dass man anfangen muss, ohne zu wissen, was einem begegnen kann.....",
                    questions : [ "Fühlen Sie sich verpflichtet, Ihre Reise fortzusetzen?", "Sind Sie unsicher?", "Wagen Sie es, Ihren eigenen Weg zu gehen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der Mond lädt Sie ein, die geheimnisvolle Welt der Psyche und der Intuition zu erforschen. Die helle Seite des Mondes symbolisiert romantische Träume, lebhafte Fantasien und tiefe Emotionen. Die dunkle Seite bezieht sich auf Kräften in Ihnen wie Angst, Unsicherheit und Zögerlichkeit, das Unbekannte zu entdecken. Es handelt sich um Kräfte, die Sie einschränken, vielleicht sogar unbewusst. Wenn Sie mit Ihren hellen und dunklen Seiten konfrontiert werden, können Sie aus dem Gleichgewicht geraten, Sie können sich verwirrt fühlen.  In diesem Moment kann es schwierig sein, sich selbst und die Menschen in Ihrer Umgebung zu verstehen. Treiben Sie sich oder Andere nicht an und nehmen Sie die Zeit, um Gleichgewicht und Einsicht wieder zu finden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wo es persönliche Beziehungen betrifft, sagt der Mond Ihnen, dass Sie dazu neigen Sich Selbst zu verlieren in romantischen Träumen, Sie manchmal sogar Luftschlösser bauen. Sie sind zu weit abgewichen von der Wirklichkeit, vielleicht weil Sie Sich unsicher und ängstlich fühlen. Der Mond symbolisiert auch Beziehungen, die durch Eifersucht und Angst vor dem Verlassen erfüllt sind. Es ist keine gute Zeit um Entscheidungen über Beziehungen zu treffen, sei es, um eine vorhandene zu beenden oder eine neue zu starten. Versuchen Sie, Ihr Partner in erster Linie als Ihren besten Freund zu behandeln, im vollen Vertrauen. Er/sie hat meistens das Beste mit Ihnen vor.</p>",
                        [GuidanceFields.WORK] : "<p>Der Mond sagt Ihnen, es könnte einen Grund zur Angst oder Unsicherheit geben. Möglicherweise sind Sie nicht sicher, ob Sie eine bestimmte Aufgabe erfüllen können, oder Sie haben Angst, dass die Leute Sie durchschauen werden und feststellen können, Sie sind nicht so gut, wie Sie zu sein schienen. Eine Sache ist sicher: Sie können nicht weglaufen von Ihren Ängsten. Atmen Sie tief ein, erkennen Sie Ihre Ängste und konfrontieren Sie Sich damit. Analysieren Sie die Situation und finden Sie heraus, ob es wirklich Sachen gibt, wofür Sie Angst haben müssen oder ob es vielleicht gerade die Angst selber ist. Oft, wenn Sie Angst in die Augen schauen, verschwindet sie.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Der Mond sagt Ihnen, dass Sie Sich in einer sehr spirituellen Phase Ihres Lebens befinden. Dies ist ein guter Zeitpunkt, um die Karten zu legen, eine Reiki-Behandlung zu untergehen oder eine Chakra-Heilung, … Sie sind geistig offen und wach. Sie nehmen die Zeit auf Ihre Gedanken zu hören, auf Ihren Gefühlen und Ideen. Sie sind auch sehr empfindlich für Nachrichten, die von spirituellen oder sogar unbekannten Quellen stammen. Wenn Sie Entscheidungen treffen müssen oder Menschen oder eine Beziehung beurteilen müssen, hören Sie auf Ihrem Bauchgefühl, vertrauen Sie auf Ihrem Instinkt.</p>",
                    }
                }
            },
            {
                filename: "19",
                numerology : 19,
                en : {
                    name : "The Sun",

                    zodiac : "☉ - The Sun",
                    archetype : "the day",
                    theme : "Be your true self",    
                    description : "The Sun talks about radiating, light and warmth, energy, the true self, how you actually are.",
                    questions : [ "Do you possess that much energy?", "Or do you rather feel burned our?", "Do you guard your boundaries?", "Can you be your true self?" ],

                    bulletpoints : ["Peace","Abundance","Joy"],
                    birthCombinationShort: "Your path is adapting to change with grace, abundance and peace.",
                    birthCombinationText : "The Wheel of Fortune card keeps you in touch with ever changing world around you. You find it easy to adapt to whatever comes your way, thanks to the Magician’s powers. And much like the Sun, you always have a bright outlook on life, bringing you joy and contentment.",

                    soalPoints : [ "Levensvreugde", "Kracht", "Vitaliteit", "Scheppingskracht", "Creativiteit", "Herboren", "Voelen", "Warmte", "Helder licht" ],
                    soalText : "Levenslust is sterke energie die je de kracht geeft om alles positief te bekijken, te genieten en alles er uit te halen wat er in zit. Het zorgt voor vrolijke, zonnige en gelukkige tijden waarbij je intens van het leven kunt genieten. ",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Sun radiates warmth, strength, optimism and joy of life. Her light also shines on your darker sides to make it easier for you to get clarity about your worries, frustrations and annoyances. This card helps you to see the sunny side of life. The Sun motivates and encourages you to let the light shine into your life, to try out new experiences, to take a break, to relax. After doing this you will feel more free, more vital and energetic. Now is the time to take life 'the sunny side up'!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Sun symbolizes the warmth and pleasure of being in a committed relationship. It depicts unconditional love, we give and keep giving without wanting something in return. Chances are that you're able to fulfill your partner's deepest wishes. But be careful not to become clingy or needy, your partner may need a lot of space right now, and if you're honest with yourself, you can use some space yourself too.</p>",
                        [GuidanceFields.WORK] : "<p>The Sun tells you that you enjoy your work and on top of that, you are very successful at it right now. You radiate positive energy and your kindness helps you attain your goals in a natural and spontaneous way. Be careful to stay close to your feelings and instincts, or you might become a bit hypocritical. And no matter how well things are going for you, keep your ego under control by sharing your success with your colleagues.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Sun invites you to take a spiritual 'vacation', to look around and try out different approaches and interpretations concerning the true meaning of life. The Sun may also tell you that you might truly grasp the beauty and enjoyment that life can bring you. One only has to really look around to find them. Share this discovery with all the people around you, so they can have a great time too.Also make sure you don't forget all the (sometimes little) things that bring quality to your life.</p>",
                    }
               },
               fr : {
                    name : "Le Soleil",
                    zodiac : "☉ - Le soleil",
                    archetype : "le jour",
                    theme : "être comme vous êtes réellement",    
                    description : "Le Soleil nous parle de rayons, de lumière et de chaleur, d'énergie, d'énergie propre, de son être réel.",
                    questions : ["Avez-vous tellement d'énergie", "Ou vous sentez-vous épuisé?", "Gardez-vous vos limites?", "Pouvez-vous simplement être vous-même?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le soleil rayonne la chaleur, la force, l'optimisme et la joie de vivre. Votre lumière brille également sur vos côtés ombragés, ce qui vous permet de mieux comprendre vos soucis, vos frustrations et votre colère. Cette carte vous aide à voir le côté positif de la vie. Le soleil vous motive et vous invite à laisser la lumière briller sur votre vie, à faire de nouvelles expériences et à faire une pause. Oubliez toutes les choses matérielles pendant un certain temps et profitez des choses intangibles comme l'amour et l'amitié. Après avoir fait cela, vous vous sentirez plus libre, plus énergique et plein de vie. C'est le moment de regarder la vie du bon côté !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Sun symbolizes the warmth and pleasure of being in a committed relationship. It depicts unconditional love, we give and keep giving without wanting something in return. Chances are that you're able to fulfill your partner's deepest wishes. But be careful not to become clingy or needy, your partner may need a lot of space right now, and if you're honest with yourself, you can use some space yourself too.</p>",
                        [GuidanceFields.WORK] : "<p>The Sun tells you that you enjoy your work and on top of that, you are very successful at it right now. You radiate positive energy and your kindness helps you attain your goals in a natural and spontaneous way. Be careful to stay close to your feelings and instincts, or you might become a bit hypocritical. And no matter how well things are going for you, keep your ego under control by sharing your success with your colleagues.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Sun invites you to take a spiritual 'vacation', to look around and try out different approaches and interpretations concerning the true meaning of life. The Sun may also tell you that you might truly grasp the beauty and enjoyment that life can bring you. One only has to really look around to find them. Share this discovery with all the people around you, so they can have a great time too.Also make sure you don't forget all the (sometimes little) things that bring quality to your life.</p>",
                    }
               },
               sp:{
                name:"El Sol",
                zodiac: "☉ - El sol",
                archetype: "el día",
                theme: "ser como realmente eres",    
                description: "El Sol es sobre los rayos, sobre la luz y el calor, sobre la energía, sobre tu verdadero yo, sobre tu propio ser real",
                questions: ["¿Tienes tanta energía?", "¿O te sientes agotado?", "¿Guardas tus límites? ¿Puedes ser tú mismo?" ],       
                
                cardoftheday: {
                    [GuidanceFields.DEFAULT] : "<p>El Sol irradia calidez, fuerza, optimismo y alegría de vivir. Su luz brilla también en sus lados más oscuros para hacer más fácil para usted para obtener claridad acerca de sus preocupaciones, frustraciones y molestias. Esta tarjeta le ayuda a ver el lado soleado de la vida. El Sol motiva y le anima a dejar que la luz brille en su vida, para probar nuevas experiencias, para tomar un descanso, para relajarse. Después de hacer esto, usted se sentirá más libre, más vital y enérgico. Ahora es el momento de tomar la vida 'el lado soleado para arriba \"!</p>",
                    [GuidanceFields.RELATIONSHIP] : "",
                    [GuidanceFields.WORK] : "",
                    [GuidanceFields.SPIRITUAL] : "",
                },
                },
               nl : {
                   name: "De Zon",
                   zodiac : "☉ - De zon",
                   archetype : "de dag",
                   theme : "zijn zoals je echt bent",    
                   description : "De zon gaat over stralen, over licht en warmte, over energie, over je ware zelf, over je eigen werkelijke zijn.",
                   questions : [ "Heb jij zoveel energie?", "Of voel jij je juist opgebrand?", "Bewaak jij je grenzen?", "Kun jij gewoon jezelf zijn?" ],                     
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Koester je in De Zon en je voelt warmte, energie, optimisme en vreugde. De Zon laat haar licht schijnen op jouw donkere kanten zodat jij meer duidelijkheid krijgt over je zorgen en frustraties. En zo krijg je meer ruimte om de positieve kanten van het leven te ervaren. De Zon motiveert je, ze moedigt je aan om haar licht in je leven te laten schijnen, om nieuwe dingen uit te proberen, even stil te staan en te genieten, om je te ontspannen. Denk even wat minder aan materiële zaken maar laat je vooral leiden door het niet tastbare, door vriendschap, liefde, positieve energie. Geniet van De Zon en je zal merken dat je je vrijer voelt, levendiger en energieker. Nu is het juiste moment om het leven van de zonnige kant te bekijken. Laat het zonlicht binnen!</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>De Zon symboliseert de warmte en vreugde die het je geeft als je in een toegewijde relatie zit. De kaart staat voor onvoorwaardelijke liefde, we geven en blijven geven zonder dat we er iets voor in de plaats willen. Nu is het echt waarschijnlijk dat je de diepste wensen en verlangens van je partner vervult.</p><p>Maar let erop dat je niet te aanhankelijk of behoeftig wordt, want je partner heeft op dit ogenblik echt veel ruimte nodig. En als je eerlijk bent met jezelf, dan weet je dat jij af en toe ook wel wat ruimte kan gebruiken.</p>",
                       [GuidanceFields.WORK] : "<p>De Zon vertelt je dat je op het ogenblik echt kan genieten van je werk. En er is meer dan dat, je bent waarschijnlijk ook erg succesvol. Je straalt positieve energie uit en je vriendelijkheid helpt je om je doelen te bereiken op een natuurlijke en spontane manier. Zorg ervoor dat je erg dicht bij je gevoelens en instincten blijft, want anders zou je een beetje hypocriet, onoprecht kunnen worden. En gelijk hoe fantastisch de dingen voor jou aan het lopen zijn, houd je ego onder controle door je succes te delen met je collega's.</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>De Zon nodigt je uit om spiritueel \"met vakantie\" te gaan, om rond te kijken en allerlei verschillende benaderingen en interpretaties uit te proberen, en dit in verband met alles dat te maken heeft met de ware zin van het leven. Het is ook mogelijk dat De Zon je vertelt dat je op dit moment echt doorhebt hoeveel schoonheid en vreugde het leven je kan brengen, het enige wat een mens daarvoor moet doen is echt rondkijken en zo deze dingen ontdekken.</p><p>Deel je ontdekking met de mensen om je heen, dan kunnen zij ook een fantastische tijd hebben. Zorg ervoor dat je oog blijft hebben voor die dingen die echt kwaliteit toevoegen aan je leven.</p>",
                   }
               },
               de : {
                   name: "Die Sonne",
                   zodiac : "☉ - Die Sonne",
                   archetype : "Der Tag",
                   theme : "So zu sein, wie Sie wirklich sind.",    
                   description : "Die Sonne spricht über Strahlen, Licht und Wärme, Energie, saubere Energie, ihr wahres Wesen.",
                   questions : [ "Haben Sie so viel Energie?", "Oder fühlen Sie sich erschöpft?", "Respektieren Sie Ihre Grenzen?", "Können Sie einfach sich selbst sein?" ],                     
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Die Sonne strahlt Wärme, Kraft, Optimismus und Lebensfreude aus. Ihr Licht scheint auch auf Ihre Schattenseiten, so dass es einfacher für Sie wird, Klarheit über Ihre Sorgen, Frustrationen und Ärger zu erlangen. Diese Karte hilft Ihnen, die positive Seite des Lebens zu sehen. Die Sonne motiviert Sie und fordert Sie dazu auf, das Licht auf Ihr Leben scheinen zu lassen, neue Erfahrungen und eine Pause zu machen. Vergessen Sie für eine Weile alle materiellen Dinge und genießen Sie immaterielle Dinge wie Liebe und Freundschaft. Nachdem Sie das gemacht haben, werden Sie sich freier, vitaler und energiegeladener fühlen. Jetzt ist es Zeit, um das Leben von der Sonnenseite aus zu betrachten!</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>Die Sonne symbolisiert die Wärme und das Vergnügen in einer festen Beziehung. Er veranschaulicht die bedingungslose Liebe, zu geben und zu nehmen, geben ohne etwas dafür zurückzubekommen. Die Chancen stehen gut, dass Sie in der Lage sein werden die tiefsten Wünsche Ihres Partners zu erfüllen. Aber Vorsicht, nicht zu anhänglich oder bedürftig zu werden, Ihr Partner könnte jetzt viel Raum gebrauchen und wenn Sie ehrlich mit Sich Selbst sind, könnten Sie eigentlich im Moment auch schon ein bisschen Raum gebrauchen.</p>",
                       [GuidanceFields.WORK] : "<p>Die Sonne sagt, dass Sie jetzt Ihre Arbeit  genießen und obendrein gerade jetzt sehr erfolgreich darin sind. Sie strahlen positive Energie aus und Ihre Freundlichkeit hilft Ihnen, Ihre Ziele auf einer natürlichen und spontanen Art und Weise zu erreichen. Achten Sie darauf, nahe an Ihre Gefühle und Instinkte zu bleiben, sonst könnten Sie ein wenig heuchlerisch werden. Und egal, wie gut die Dinge für Sie gehen, behalten Sie Ihr Ego unter Kontrolle, indem Sie Ihre Erfolge mit Ihren Kollegen teilen.</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>Die Sonne lädt Sie in einen spirituellen „Urlaub„ zu nehmen, um sich mal umzusehen und verschiedene Ansätze und Interpretationen zu betrachten hinsichtlich dem wahren Sinn des Lebens. Die Sonne kann auch sagen, dass Sie jetzt vielleicht wirklich die Schönheit und die Freude, die das Leben Ihnen bietet, zu schätzen wissen und annehmen werden. Man muss nur wirklich umschauen, sie zu finden. Teilen Sie diese Entdeckung mit all den Menschen um Sie herum, sodass auch sie eine tolle Zeit haben können. Stellen Sie alle (auch kleine) Dinge sicher, die Qualität in Ihr Leben bringen könnten.</p>",
                   }
               }
            },
            {
                filename: "20",
                numerology : 20,
                en : {
                    name : "Judgement",

                    zodiac : "♇ - Pluto",
                    archetype : "liberation",
                    theme : "judge for yourself",    
                    description : "The Judgement talks about hearing… and being heard, your calling, \"coming out of the closet\", judging for yourself.",
                    questions : [ "Are you judging for yourself?", "Are you too harsh on yourself?", "What is your higher calling?", "Are you honest about it?", "Let yourself be heard !" ],

                    bulletpoints : ["Resurrection","Objectivity","Awakening"],
                    birthCombinationShort: "Your path is one of breaking limitations, by following your intuition",
                    birthCombinationText : "Intuition is what guides the High Priestess. Your best results always come when you trust the voice within. Because of the Judgment card, you are not afraid to re-evaluate any situation and make the best decision, even if you must break through many limitations to achieve this.",

                    soalPoints : [ "Verlossing", "Bevrijding", "Je ware schat", "Iets verlopen duikt weer op", "De kus op de kikker" ],
                    soalText : "Verlossing is het oplossen van een probleem, bevrijd worden van een nare situatie, verlost worden van het nare wat je zo erg bezig houdt. Het is de bevrijding van dat waar je al zo lang mee roncloopt, waar je over piekert of nog steeds geen oplossing voor gevonden hebt.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Do not confuse Judgement with condemnation and punishment, that's not what this card is about, it's all about resurrection. Beyond judgement you take the important step towards self-realization and transformation from low to higher layers of consciousness. In everyday life you will become conscious of this change when fears and worries disappear and you find yourself confronting challenges with courage, creativity and decisiveness. On the other hand Judgement can also indicate that you are too quick with your judgements and conclusions. You might want to slow down a bit and give yourself and others more chances.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>When you are already in a relationship Judgment tells you that you are at a defining moment. Be honest and open about the things you want from the relationship and try your hardest to make them happen. If you're looking for a relationship Judgment tells you to be careful about judging your potential partner, be it negatively or positively.</p><p>In general you will notice that you are transforming within your relationships, you are conquering your fears and coming closer to your True Self. This helps you to come much closer to your loved one(s) and moreover, it brings you intense joy.</p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned Judgment tells you that you're entering a decisive phase, change may be imminent, be it caused by yourself or by your superiors. If you work hard it will very likely pay off, if you're not working hard it is high time to start doing so. Whatever you do right now, it does not go unnoticed, it is being ‘judged’.</p><p>This card is very often allabout choices and the consequences of all the choices you make, be they conscious or subconscious.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Judgment may remind you that it's important to be open to new ways of thinking, new experiences. Life is all about change and so is spiritual life. This doesn't mean you should immediately change your convictions or fields of interest, but a bit of shopping around doesn't hurt. It may even help you to find truths you didn't even think were there in the first place. Be open to change, to the unusual, it will help you grow.</p>",
                    }
               },
               fr : {
                    name : "Le Jugement",
                    zodiac : "♇ - Pluton",
                    archetype : "libération",
                    theme : "Porter votre propre jugement",    
                    description : "Le Jugement nous parle d'audience..... et d'être entendu, de se sentir appelé à , de \"sortir du placard\", du propre jugement.",
                    questions : ["Vous jugez-vous vous-même?", "Êtes-vous très sévère avec vous-même?", "A quoi vous sentez-vous appelé?", "Admettez-vous?", "Faites-vous entendre !" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Il ne faut pas confondre le jugement avec la condamnation et la punition, la carte n'est pas faite pour cela. Au contraire, il représente la résurrection. Au-delà du jugement, vous faites un pas important vers la réalisation de soi et la transformation des couches inférieures vers les couches supérieures de la conscience. Dans la vie de tous les jours, vous prendrez conscience de ce changement lorsque les peurs et les inquiétudes disparaîtront.  Vous découvrirez que vous relevez les défis avec courage, créativité et détermination.</p><p>D'un autre côté, le jugement peut aussi signifier que vous êtes trop rapide dans vos jugements et conclusions. Peut-être souhaitez-vous ralentir, pour donner à vous-même et aux autres, plus qu’une seule chance.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>When you are already in a relationship Judgment tells you that you are at a defining moment. Be honest and open about the things you want from the relationship and try your hardest to make them happen. If you're looking for a relationship Judgment tells you to be careful about judging your potential partner, be it negatively or positively.</p><p>In general you will notice that you are transforming within your relationships, you are conquering your fears and coming closer to your True Self. This helps you to come much closer to your loved one(s) and moreover, it brings you intense joy.</p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned Judgment tells you that you're entering a decisive phase, change may be imminent, be it caused by yourself or by your superiors. If you work hard it will very likely pay off, if you're not working hard it is high time to start doing so. Whatever you do right now, it does not go unnoticed, it is being ‘judged’.</p><p>This card is very often allabout choices and the consequences of all the choices you make, be they conscious or subconscious.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Judgment may remind you that it's important to be open to new ways of thinking, new experiences. Life is all about change and so is spiritual life. This doesn't mean you should immediately change your convictions or fields of interest, but a bit of shopping around doesn't hurt. It may even help you to find truths you didn't even think were there in the first place. Be open to change, to the unusual, it will help you grow.</p>",
                    }
               },
               sp:{
                name:"El Juicio",
                zodiac: "♇ - Plutón",
                archetype: "liberación",
                theme: "Juzga por ti mismo",    
                description: "El Juicio es sobre el oído.... y ser escuchado, sentirse llamado por ti, por \"salir del armario\", por el juicio mismo",
                questions: ["¿Te juzgas a ti mismo?", "¿Eres muy estricto contigo mismo?", "¿A qué te sientes llamado?", "¿Saldrás por eso?", "¡Déjate escuchado!"],
                
                cardoftheday: {
                    [GuidanceFields.DEFAULT] : "<p>No se debe confundir con la condena Juicio y castigo, que no es lo que esta tarjeta es de, es todo acerca de la resurrección. Más allá del juicio de dar el paso importante hacia la autorrealización y la transformación de bajo a altas capas de la conciencia. En la vida cotidiana se convertirá consciente de este cambio cuando los temores y las preocupaciones desaparecen y le encuentra enfrentar retos con valentía, creatividad y capacidad de decisión. Por otro lado Juicio también puede indicar que usted es demasiado rápido con sus juicios y conclusiones. Es posible que desee frenar un poco y darle a usted ya otras más posibilidades.</p>",
                    [GuidanceFields.RELATIONSHIP] : "",
                    [GuidanceFields.WORK] : "",
                    [GuidanceFields.SPIRITUAL] : "",
                },
                },
               nl : {
                   name: "Het Oordeel",
                   zodiac : "♇ - Pluto",
                   archetype : "bevrijding",
                   theme : "oordeel zelf",    
                   description : "Het oordeel gaat over horen... en je laten horen, over je geroepen voelen, over \"kom-uit-de-kast\", over oordeel zelf",
                   questions : [ "Oordeel je zelf?", "Ben je erg streng voor jezelf?", "Waartoe voel jij je geroepen?", "Kom je daarvoor uit?", "Laat je eens horen!" ], 
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Denk bij deze kaart niet aan veroordeeld worden en straf krijgen, daar gaat het hier niet om. In tegendeel, Het Oordeel vertelt je over heropstanding. Je zet die belangrijke stap naar zelfrealisatie en transformatie, je groeit van lage naar hogere delen van je bewustzijn. Je zal je steeds bewuster worden van deze verandering en merken dat je minder angsten en zorgen hebt. Je gaat de dagelijkse uitdagingen nu aan met moed, creativiteit en vastberadenheid.</p><p>Maar er is ook een andere kant aan Het Oordeel: misschien ben je te snel met oordelen en conclusies trekken. Als dat zo is, doe het dan iets rustiger aan en geef jezelf en anderen meer dan slechts die ene kans.</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>Als je al een relatie hebt dan vertelt Het Oordeel je dat een allesbepalend moment aangebroken is. Wees eerlijk en open over alles wat je wil van de relatie en probeer om je verlangens waar te maken.</p><p>Als je op zoek bent naar een relatie dan vertelt het Oordeel je om voorzichtig te zijn bij het beoordelen van een potentiële partner, of het nu negatief of positief is. Over het algemeen zal je merken dat je aan het veranderen bent in de relatie, je bent je angsten aan het overwinnen en dichter bij je Ware Zelf aan het komen. Dit helpt je ook om toenadering te vinden tot je geliefde(n) en brengt je intense vreugde.</p>",
                       [GuidanceFields.WORK] : "<p>Het Oordeel staat voor een erg belangrijke fase als het over werk en carrière gaat. Verandering komt alsmaar dichterbij, of deze nu veroorzaakt wordt door jou of door je meerderen. Als je hard werkt zal dat zeker zijn vruchten afwerpen, maar als je niet hard werkt, begin er dan onmiddellijk mee.</p><p>Bij deze kaart gaat het heel vaak over keuzes en de gevolgen van de keuzes die je maakt, of je dat nu bewust of onbewust doet.</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>Het Oordeel herinnert je eraan dat het belangrijk is om open te staan voor nieuwe denkwijzen, nieuwe ervaringen. Het leven draait om verandering en dat geldt ook voor het spirituele leven.</p><p>Dit betekent niet dat je onmiddellijk je overtuigingen of interessegebieden moet veranderen, maar een beetje rondkijken kan echt geen kwaad. Het kan je zelfs helpen om waarheden te ontdekken waarvan je niet eens wist dat ze bestonden. Sta open voor verandering, voor het ongewone, het zal je helpen groeien.</p>",
                   }
               },
               de : {
                   name: "Das Gericht",
                   zodiac : "♇ - Pluto",
                   archetype : "Befreiung",
                   theme : "Treffen Sie Ihr eigenes Urteil",    
                   description : "Das Gericht spricht  über das Publikum - gehört zu werden, sich berufen zu fühlen, \"aus dem Schrank zu kommen\", dem eigenen Urteil.",
                   questions : [ "Verurteilen Sie sich selbst?", "Sind Sie sehr streng mit sich selbst?", "Wozu fühlen Sie sich berufen?", "Geben Sie es zu?", "Verschaffen Sie sich Gehör!" ], 
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Das Gericht ist nicht mit Verurteilung und Bestrafung zu verwechseln, dafür steht die Karte nicht. Im Gegenteil, sie steht für die Auferstehung. Über die Beurteilung hinaus gehen Sie den wichtigen Schritt in Richtung Selbstverwirklichung und Transformation, von niedrigen zu höheren Schichten des Bewusstseins. Im Alltag werden Sie sich dieser Veränderung bewusst werden, wenn Ängste und Sorgen verschwinden. Sie werden feststellen, dass Sie Herausforderungen mit Mut, Kreativität und Entschlossenheit entgegentreten.</p><p>Auf der anderen Seite kann das Gericht auch bedeuten, dass Sie zu schnell mit Ihren Urteilen und Schlussfolgerungen sind. Vielleicht möchten Sie es ein wenig langsamer angehen und sich selbst und Anderen mehr als nur diese eine Chance geben.</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie bereits in einer Beziehung sind, sagt das Gericht Ihnen, dass Sie an einem entscheidenden Punkt anbelangt sind. Seien Sie ehrlich und offen über die Dinge, die Sie aus der Beziehung erwarten und versuchen Sie Ihr Bestes diese herauszuziehen. Wenn Sie auf der Suche sind, nach einer Beziehung sagt das Urteil Ihnen, dass Sie vorsichtig sein müssen in der Beurteilung Ihrer potenziellen Partner, sei es negativ oder positiv.</p><p>Im Allgemeinen werden Sie feststellen, dass Sie Ihr Verhalten in Ihren Beziehungen ändern. Sie haben Ihre Ängste überwunden und kommen näher an Ihr wahres Selbst dran. Das hilft Ihnen viel näher an Ihre Liebsten heranzukommen und darüber hinaus bringt es Ihnen tiefe Freude.</p>",
                       [GuidanceFields.WORK] : "<p>Wo es die Arbeit betrifft das Gericht Ihnen, dass Sie Sich in einer entscheidenden Phase befinden und dass eine Änderung unmittelbar bevorstehen kann, die sogar durch Ihre Vorgesetzten verursacht werden kann. Wenn Sie hart arbeiten, wird das sich sehr wahrscheinlich ausbezahlen. Wenn Sie nicht so hart arbeiten, ist es jetzt die höchste Zeit das zu tun. Was immer Sie auch gerade jetzt tun, es bleibt nicht unbemerkt, man wird Sie beurteilen.</p><p>Diese Karte handelt sehr oft über Auswahl und die Folgen aller Entscheidungen, die Sie treffen, sei es bewusst oder unbewusst.</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>Das Urteil kann darauf hinweisen, dass es wichtig ist, offen zu sein für neue Denkweisen, neue Erfahrungen. Im Leben dreht sich alles nur um Veränderung und das trifft auch zu auf dem geistigen Leben. Dies bedeutet nicht, dass Sie sofort Ihre Überzeugungen oder Interessengebieten ändern sollten, aber ein bisschen Shopping in der Umgebung tut nicht weh. Es kann sogar helfen, Wahrheiten zu finden, von denen Sie nicht einmal geglaubt haben, sie wären da. Seien Sie offen für Veränderungen, für das Ungewöhnliche, es wird Ihnen helfen zu wachsen.</p>",
                   }
               }
            },
            {
                filename: "21",
                numerology : 21,
                en : {
                    name : "The World",

                    zodiac : "♄ - Saturn",
                    archetype : "home",
                    theme : "your place in the world",    
                    description : "The World talks about the totality, completely feeling at home, having your place in the world.",
                    questions : [ "Do you feel good?", "What do you need to feel at home?", "Are you taking your place in the world?" ],

                    bulletpoints : ["Fulfillment","Culmination","Perfection"],
                    birthCombinationShort: "Your path is one of self-actualization guided by love.",
                    birthCombinationText : "As the Empress, your primary guiding force is love. The act of giving and receiving love from those in your life is very important to you. You seek to unify the various aspects of your live to create a harmonious and welcoming World.",

                    soalPoints : [ "Heelheid", "Eenheid", "Het doel", "Voltooiing", "Voldoening", "De juiste plaats innemen", "(Eind)punt" ],
                    soalText : "Het doel is datgene wat je als eindresultaat hoopt te bereiken. Een voltooing van dat waar je misschien al zo lang naar toe hebt gewerkt. ",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The World can remind you of the fact that you're carrying all your loads by yourself. Maybe it would be wise to ask for some help now and then.</p><p>This card may also point out that you've found your own spot, wherever it may be. You are experiencing the bliss of harmony and the happiness one feels when successfully finishing a task or an activity. You may have taken an important step towards true authenticity and completeness.</p><p>In general this card symbolizes happy times.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>You are feeling perfectly at home in your current relationship. Your partner isn't just some passer-by, on the contrary! She/he is of the utmost importance for your quality of life and your development. The way you mirror eachother is helping your consciousness grow.</p><p>If you're looking for a partner, it is well possible you will find someone quite a long way from home, maybe even while you are on holiday.</p>",
                        [GuidanceFields.WORK] : "<p>The World indicates that you're feeling quite at home at the workplace. You really like your job and you're good at it. Still, it may be time to ask yourself if your talents and capabilities are truly acknowledged and rewarded. If you realize this isn't the case, then it's important to get help finding another job. If you're happy to stay where you are, it might be time to further develop yourself, to evolve. Maybe you can take some courses or ask for new or extra tasks.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The World may indicate that you are getting closer to who you really are. New insights and realizations may be coming to you on a regular base. Still, these experiences may be challenging and exhausting so maybe you should take some time off to work through your new insights. Don’t try to do this alone but share your experiences with others, talk about them, do not go this journey alone. After all, this (life) journey is, in itself, just as important as the arrival and it would benefit you to have some company!</p>",
                    }
                },
                fr : {
                    name : "Le Monde",
                    zodiac : "♄ - Saturne",
                    archetype : "la maison",
                    theme : "votre place dans le monde",    
                    description : "Le Monde, nous parle de la totalité - indemne, de se sentir chez soi, d’avoir sa propre place dans le monde.",
                    questions : ["Êtes-vous à l'aise dans votre propre peau?", "De quoi avez-vous besoin pour vous sentir chez vous?", "Prenez-vous votre propre place (dans le monde) )?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le monde peut vous rappeler que vous devez porter seul vos charges et que vous pouvez sentir le poids du monde sur vos épaules. Il serait sage de demander de l'aide maintenant. Vous vous sentirez tellement mieux et plus léger !</p><p>Cette carte peut également indiquer que vous avez trouvé votre propre place, où qu'elle se trouve. Vous ressentez le bonheur lorsqu’il y a harmonie et lorsque vous accomplissez avec succès une tâche ou une activité. Peut-être avez-vous fait un pas important vers l'authenticité et l'exhaustivité.</p><p>Habituellement, cette carte symbolise les moments heureux, que ce soit dans le présent ou dans un proche avenir.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>You are feeling perfectly at home in your current relationship. Your partner isn't just some passer-by, on the contrary! She/he is of the utmost importance for your quality of life and your development. The way you mirror eachother is helping your consciousness grow.</p><p>If you're looking for a partner, it is well possible you will find someone quite a long way from home, maybe even while you are on holiday.</p>",
                        [GuidanceFields.WORK] : "<p>The World indicates that you're feeling quite at home at the workplace. You really like your job and you're good at it. Still, it may be time to ask yourself if your talents and capabilities are truly acknowledged and rewarded. If you realize this isn't the case, then it's important to get help finding another job. If you're happy to stay where you are, it might be time to further develop yourself, to evolve. Maybe you can take some courses or ask for new or extra tasks.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The World may indicate that you are getting closer to who you really are. New insights and realizations may be coming to you on a regular base. Still, these experiences may be challenging and exhausting so maybe you should take some time off to work through your new insights. Don’t try to do this alone but share your experiences with others, talk about them, do not go this journey alone. After all, this (life) journey is, in itself, just as important as the arrival and it would benefit you to have some company!</p>",
                    }
                },
                sp:{
                    name:"El Mundo",
                    zodiac: "♄ - Saturno",
                    archetype: "(hogar) en casa",
                    theme: "tu lugar en el mundo",    
                    description: "El Mundo es sobre la totalidad - la piel entera, sobre sentirse como en casa, sobre tu propio lugar en el mundo",
                    questions: ["¿Estás cómodo en tu piel?", "¿Qué necesitas para sentirte como en casa?", "¿Tomas tu propio lugar (en el mundo)?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El mundo puede recordar el hecho de que usted está llevando todas sus cargas por su mismo. Tal vez sería conveniente pedir un poco de ayuda de vez en cuando. Esta tarjeta también puede indicar que usted ha encontrado su propio lugar, donde quiera que sea. Usted está experimentando la dicha de la armonía y la felicidad que se siente al terminar con éxito una tarea o una actividad. Es posible que haya dado un paso importante hacia la verdadera autenticidad e integridad. En general, esta carta simboliza momentos felices.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "De Wereld",
                    zodiac : "♄ - Saturnus",
                    archetype : "thuis",
                    theme : "jouw plek in de wereld",    
                    description : "De wereld gaat over totaliteit - heelhuid, over je thuis voelen, over je eigen plek in de wereld.",
                    questions : [ "Zit jij lekker in je vel?", "Wat heb jij nodig om je thuis te kunnen voelen?", "Neem jij je eigen plek (in de wereld) in?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Als je deze kaart krijgt dan heb je misschien het gevoel dat je het gewicht van De Wereld op je schouders draagt. Denk eraan dat je niet alleen bent, er zijn mensen om je heen die je graag willen helpen met het dragen van je last. En zoals je eigenlijk wel weet: gedeelde last is halve last!</p><p>Vaak betekent deze kaart ook dat je je plek gevonden hebt, waar dat ook is. Je hebt een gevoel van harmonie, je voelt de vreugde van het succesrijk afronden van een taak, een activiteit, een opdracht. Je hebt een belangrijke stap gezet in de richting van “echtheid”, je voelt je \"geheel\".</p><p>Deze kaart vertegenwoordigt bijna altijd mooie en vreugdevolle tijden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Volgens deze kaart voel je je helemaal thuis in je huidige relatie. Je partner is niet zomaar een voorbijganger, in tegendeel! Zij/hij is ontzettend belangrijk voor je levenskwaliteit en je ontwikkeling. De manier waarop jullie mekaar spiegelen, helpt om je bewustzijn te laten groeien.</p><p>Als je op zoek bent naar een partner dan is het goed mogelijk dat je iemand zal vinden als je tamelijk ver weg van huis bent, bijvoorbeeld als je met vakantie gaat.</p>",
                        [GuidanceFields.WORK] : "<p>De Wereld vertelt je dat je je goed thuis voelt op je werkplek. Je vindt je job echt leuk en je bent er ook goed in.</p><p>Toch is het misschien tijd om je af te vragen of je talenten en capaciteiten echt wel erkend en beloond worden. Als je beseft dat dit niet het geval is, vraag dan hulp van je omgeving om een andere baan te vinden.</p><p>Als je tevreden bent en op dezelfde plek wil blijven, is het waarschijnlijk dat de tijd rijp is om je verder te ontwikkelen, te groeien. Misschien kan je een cursus volgen of andere of bijkomende taken vragen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Als je De Wereld krijgt dan kan dit betekenen dat je dichterbij aan het komen bent bij wie je echt bent. Je kan geregeld nieuwe inzichten en realisaties krijgen. Toch is het mogelijk dat deze ervaringen uitdagend en uitputtend zijn, dus misschien moet je wat vrije tijd nemen om je nieuwe inzichten te verwerken.</p><p>Probeer niet om dit alleen te doen maar deel je ervaringen met anderen, praat erover, maak deze reis niet in je eentje. Deze reis is tenslotte even belangrijk als het bereiken van je doel, dus bewandel dit (levens)pad in goed gezelschap!</p>",
                    }
                },
                de : {
                    name: "Die Welt",
                    zodiac : "♄ - Saturn",
                    archetype : "Das Haus",
                    theme : "Ihr Platz in der Welt",    
                    description : "Die Welt spricht zu von der Gesamtheit - unversehrt zu sein, sich zu Hause zu fühlen, seinen eigenen Platz in der Welt zu haben.",
                    questions : [ "Fühlen Sie sich wohl in Ihrer eigenen Haut?", "Was brauchen Sie, um sich zu Hause zu fühlen?", "Nehmen Sie Ihren eigenen Platz (in der Welt) ein?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die Welt kann Sie daran erinnern, dass Sie Ihre Lasten alleine  tragen müssen, Sie können die Last der Welt auf Ihren Schultern spüren. Möglicherweise wäre es klug, jetzt um Hilfe zu fragen. Sie werden sich dann so viel besser und leichter fühlen!</p><p>Diese Karte kann auch darauf hinweisen, dass Sie Ihren eigenen Platz gefunden haben, wo auch immer dies sein mag. Sie erleben das Glück der Harmonie und das Glück, das man fühlt, wenn man eine Aufgabe oder eine Tätigkeit erfolgreich abschließt. Vielleicht haben Sie einen wichtigen Schritt zu wahrer Authentizität und Vollständigkeit gesetzt.</p><p>In der Regel symbolisiert diese Karte glückliche Zeiten, entweder bereits in der Gegenwart oder in der nahen Zukunft.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Sie fühlen Sich perfekt zu Hause in Ihrer aktuellen Beziehung. Ihr Partner ist nicht nur einen Passanten, im Gegenteil! Sie/ er ist für Ihre Lebensqualität und Ihre Entwicklung von größter Bedeutung. Die Art und Weise wie Sie Sich aneinander spiegeln hilft zum Wachstum Ihres Bewusstseins.</p><p>Wenn Sie jetzt auf der Suche sind nach einem Partner, ist es sehr gut möglich, dass Sie jemanden ganz weit weg von Hause finden, vielleicht sogar, während Sie im Urlaub sind.</p>",
                        [GuidanceFields.WORK] : "<p>Die Welt zeigt Ihnen, dass Sie Sich ganz wohlfühlen an Ihrem Arbeitsplatz. Sie mögen Ihren Job wirklich und Sie sind gut darin. Dennoch kann es Zeit sein, sich zu fragen, ob Ihre Talente und Fähigkeiten wirklich anerkannt und belohnt werden. Wenn Sie feststellen, ist dies nicht der Fall ist, dann ist es wichtig, Hilfe Einzurufen um einen neuen Arbeitsplatz zu finden. Wenn Sie glücklich sind, wo Sie sind, dann kann es Zeit sein, um sich weiterzuentwickeln. Vielleicht können Sie was Unterricht nehmen oder fordern Sie neue oder zusätzliche Aufgaben an.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Welt kann darauf hindeuten, dass Sie näher an den kommen, wer Sie wirklich sind. Neue Einsichten und Erkenntnisse können zu Ihnen kommen auf einem regelmäßigen Basis. Dennoch können diese Erfahrungen anspruchsvoll und anstrengend sein. Vielleicht sollten Sie Sich einige Zeit nehmen, um Ihre neuen Einsichten durch zu arbeiten. Versuchen Sie nicht, dies allein zu tun, sondern teilen Sie Ihre Erfahrungen mit anderen, reden Sie mit anderen darüber. Sie müssen ja diesen Weg nicht allein gehen. Immerhin ist dieser Lebensweg selber ebenso wichtig, wie der Ankunft und es könnte günstiger sein sich unterwegs ein bisschen Gesellschaft zu leisten.</p>",
                    }
                }
            },
            {
                filename: "22",
                numerology : 1,
                en : {
                    name: "Ace of Wands",
                    suit : "Wands",
                    element : "🜂 - fire", 
                    theme : "I want",    
                    description : "Ace of Wands – element fire, talks about what you want in its totality. Everything is potentially present, but what is it that you want?",
                    questions : [ "Do you know what you want?", "Can you open up to new ideas?", "Is it fitting for you or is it \"imposed\" or non-fitting and should you not follow the idea?", "What is it that you want?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>In Tarot an ace card mostly stands for opportunities, possibilities, en generally they have a positive meaning.</p><p>Wands stand for the fire element which means that the opportunities that are presented often involve self-development, joy of life and persuasiveness.</p><p>Now is the time to reach your goals. You are feeling strong and being driven by the power of persuasion. It's very possible that you are feeling positive, goal oriented, energetic, hopeful, and with good reason!</p><p>There is almost nothing that can hold you back right now, so take your chances!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Ace of Wands symbolizes vivacity in your present relationship. It's also about the enthusiasm you share with the people you are close to. You are feeling really good and you radiate high-spiritedness. You are fully aware of the old wisdom: the feeling you radiate will come back to you threefold.</p><p>If you are single right now it is probable that a new relationship is coming your way. Make sure you show your availability in every way possible.</p><p>Generally now is the perfect time to express yourself, be it in a relationship or not.</p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned you are truly willing to take chances right now. Your passion and ambition are your guiding principles and your performance is defined by zeal and enthusiasm.</p><p>If you are looking for a new job right now, you will probably notice that great chances and opportunities are coming your way, Don not hesitate to ask for what you really want, you might be way more successful in getting it than you thought possible.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Regularly remind yourself that the connection between your body and mind is more close and intimate than you think. There is always intense interaction between the two, the body feels what the mind thinks and vice versa. </p><p>The Ace of Wands tells you that on a spiritual level something or someone new will come into your life. Do you have spiritual \"heroes\", people youlook up to? It may be time to find someone like that, someone to guide you and help you grow and evolve. You will be pleasantly surprised to see new opportunities present themselves, once you open up to them.</p>",
                    }
                },
                fr : {
                    name : "l’As de Bâtons",
                    suit : "Bâtons",
                    element :  "🜂 - feu", 
                    theme : "Je veux",    
                    description : "L'As de bâtons, correspond à l'élément feu, nous parle de notre volonté dans sa totalité. Tout est potentiellement présent, mais que voulez-vous?",
                    questions : ["Savez-vous ce que vous voulez?", "Pouvez-vous être ouvert à une nouvelle idée?", "Est-ce qu'elle vous convient ou est-elle imposée?", "Ou une idée qui ne vous convient pas et que vous devriez rejeter?", "Que voulez-vous vraiment?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Dans le tarot, une carte as représenté généralement les chances et les possibilités. Habituellement, il a une signification positive.</p><p>Les bâtons représentent l'élément feu, ce qui signifie que les opportunités qui sont souvent offertes concernent le développement personnel, la joie de vivre et la persuasion. L'élément feu tourne autour de tout ce que vous voulez.</p><p>Le moment est venu d'atteindre vos objectifs. Vous vous sentez fort et motivé par la persuasion. Il est très probable que vous soyez maintenant positif, orienté vers un but, énergique et plein d'espoir. Vous avez ce sentiment pour une bonne raison ! Il n'y a presque rien qui puisse vous retenir maintenant, alors prenez le risque !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Ace of Wands symbolizes vivacity in your present relationship. It's also about the enthusiasm you share with the people you are close to. You are feeling really good and you radiate high-spiritedness. You are fully aware of the old wisdom: the feeling you radiate will come back to you threefold.</p><p>If you are single right now it is probable that a new relationship is coming your way. Make sure you show your availability in every way possible.</p><p>Generally now is the perfect time to express yourself, be it in a relationship or not.</p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned you are truly willing to take chances right now. Your passion and ambition are your guiding principles and your performance is defined by zeal and enthusiasm.</p><p>If you are looking for a new job right now, you will probably notice that great chances and opportunities are coming your way, Don not hesitate to ask for what you really want, you might be way more successful in getting it than you thought possible.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Regularly remind yourself that the connection between your body and mind is more close and intimate than you think. There is always intense interaction between the two, the body feels what the mind thinks and vice versa. </p><p>The Ace of Wands tells you that on a spiritual level something or someone new will come into your life. Do you have spiritual \"heroes\", people youlook up to? It may be time to find someone like that, someone to guide you and help you grow and evolve. You will be pleasantly surprised to see new opportunities present themselves, once you open up to them.</p>",
                    }
                },
                sp:{
                    name:"As de Bastos",
                    suit:  "bastos",
                    element: "🜂 –  fuego", 
                    theme: "Quiero",    
                    description: "El As de Bastos, que coincide con el elemento fuego, es acerca de tu voluntad en tu totalidad. Todo está potencialmente presente, pero ¿qué es lo que quieres?",
                    questions: ["¿Sabes lo que quieres?", "¿Puedes abrirte a una nueva idea?", "¿Te ajusta o es una idea que no te conviene y deberías ignorarla?", "¿Qué es lo que realmente quieres?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>En el Tarot un as destaca sobre todo por las oportunidades, posibilidades, en general, tienen un significado positivo. Bastos representan el elemento fuego que significa que las oportunidades que se presentan a menudo implican el desarrollo personal, la alegría de la vida y la persuasión. Ahora es el tiempo para alcanzar sus metas. Se siente fuerte y siendo impulsado por el poder de la persuasión. Es muy posible que usted se siente positivo, orientado, enérgico, esperanzado, y con buena razón meta! No hay casi nada que se pueda celebrar de nuevo en este momento, así que tome sus posibilidades!</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Aas",
                    suit : "Staven",
                    element : "🜂 - vuur", 
                    theme : "ik wil",    
                    description : "De aas van staven, passend bij het element vuur, gaat over je willen in zijn totaliteit. Alles is in potentie aanwezig, maar wat wil jij?",
                    questions : [ "Weet jij wat je wilt?", "Kun jij open staan voor een nieuw idee?", "Past het bij of ishet het \"opgedrongen\" of een niet bij je passend idee en kun je beter naast je neer leggen?", "Wat wil jij eigenlijk?" ],                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>In de Tarot is een Aas meestal een symbool voor kansen en mogelijkheden en heeft de kaart dus vaak een positieve betekenis.</p><p>De Staven zijn een Vuurteken wat betekent dat de mogelijkheden waar de Aas op wijst over het algemeen te maken hebben met persoonlijke groei, vreugde en vastberadenheid. Het Vuurelement draait om wat jij wil.</p><p>De Aas van Staven vertelt je dat deze periode ideaal is om je doelen te bereiken. Je voelt je sterk en hebt ook veel overtuigingskracht. Op dit moment ben je waarschijnlijk doelgericht, positief en energiek en voel je je ook vervuld van hoop.</p><p>Groot gelijk dat je je zo voelt! Er is nu bijna niets dat je kan tegenhouden dus grijp alle kansen die op je weg komen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Aas van Staven is symbolisch voor levendigheid in je liefdesrelatie maar ook voor het enthousiasme dat je deelt met de mensen die je dierbaar zijn. Je voelt je echt goed en je straalt daardoor positieve levenskracht uit. Je bent je ten volle bewust van de oude wijsheid: het gevoel dat jij uitstraalt zal driedubbel naar je terugkomen. Als je op dit moment single bent dan is het heel waarschijnlijk dat je binnenkort een potentiële partner zal ontmoeten. Zorg ervoor dat je door je gedrag en uitstraling duidelijk maakt dat je beschikbaar bent.</p><p>Over het algemeen is dit een goede periode om je te uiten, zichtbaar te zijn, of je nu een relatie hebt of niet.</p>",
                        [GuidanceFields.WORK] : "<p>Wat werk betreft ben je op dit moment echt bereid om risico’s te nemen. Je passie en ambitie zijn je leidraad en je prestaties worden gekenmerkt door vuur en energie. Als je behoefte hebt aan groei, grijp dan nu de kans om je meerdere aan te spreken, hij/zij zal onder de indruk zijn van je uitstraling.</p><p>Als je op zoek bent naar werk dan zal je merken dat er grote kansen en mogelijkheden op je weg komen. Aarzel niet om te streven naar wat je echt wil. Er is een grote kans dat je succesvoller zal zijn in je streven dan je ooit verwacht had.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Herinner jezelf er geregeld aan dat de verbinding tussen lichaam en geest intenser en intiemer is dan je eigenlijk denkt. Er is sprake van een constante interactie tussen de twee, je lichaam voelt wat je geest denkt en omgekeerd. Besteed hier voldoende aandacht aan.</p><p>Als je deze kaart krijgt dan betekent het dat er op spiritueel vlak binnenkort een nieuw iets of iemand in je leven zal komen. Heb je spirituele “helden”, mensen naar wie je opkijkt? Als dat niet het geval is, dan is het nu een goed moment om ernaar op zoek te gaan. Probeer iemand te vinden die je leiding kan geven, je kan gidsen en helpen in je groei en verandering. Stel je open voor nieuwe mogelijkheden, je zal aangenaam verrast worden door wat er allemaal op je weg komt.</p>",
                    }
                },
                de : {
                    name: "Ass der Stäbe",
                    suit : "Stäbe",
                    element : "🜂 - Feuer", 
                    theme : "Das Spiel",    
                    description : "Das Ass der Stäbe entspricht dem Feuerelement, erzählt von unserem Willen in seiner Gesamtheit. Alles ist potentiell vorhanden, aber was wollen Sie?",
                    questions : [ "Wissen Sie, was Sie wollen?", "Können Sie offen sein für eine neue Idee?", "Entspricht sie Ihnen oder wird sie aufgezwungen?", "Oder ist es eine Idee, die die Ihnen nicht passt und die Sie ablehnen sollten?", "Was wollen Sie wirklich?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Im Tarot steht eine Ass-Karte meist für Chancen und Möglichkeiten. In der Regel hat sie eine positive Bedeutung.</p><p>Stäbe stellen das Feuer-Element dar, das heißt, dass die Chancen, die oft angeboten werden, sich auf Selbstentwicklung, Lebensfreude und Überzeugungskraft beziehen. Das Feuer-Element dreht sich um alles, was Sie wollen.</p><p>Jetzt ist die Zeit gekommen, Ihre Ziele zu erreichen. Sie fühlen sich stark und werden von Überzeugungskraft angetrieben. Es ist sehr wahrscheinlich, dass Sie jetzt positiv, zielorientiert, energisch und hoffnungsvoll sind. Sie haben dieses Gefühl aus gutem Grund! Es gibt fast nichts, was Sie jetzt zurückhalten kann, ergreifen Sie also Ihre Chancen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Das Ass der Stäbe symbolisiert Lebendigkeit in Ihre derzeitige Beziehung. Es geht auch um das Teilen der Begeisterung mit den Menschen, die in Ihrer Nähe sind. Sie fühlen sich wirklich gut und strahlen eine große Lebendigkeit aus. Sie sind Sich voll und ganz bewusst von der alten Weisheit: das Gefühl, das Sie ausstrahlen, wie in dreifach bei Ihnen zurückkommen.</p><p>Wenn Sie jetzt alleine sind, ist es wahrscheinlich, dass eine neue Beziehung auf Sie zukommt. Achten Sie darauf, Ihre Verfügbarkeit in jeder möglichen Weise zu zeigen.</p><p>Im Allgemeinen ist jetzt der perfekte Zeitpunkt, um sich klar auszudrücken, sei es in einer Beziehung oder da draußen.</p>",
                        [GuidanceFields.WORK] : "<p>Wo es die Arbeit betrifft sind Sie jetzt wirklich bereit, die Chancen sofort zu ergreifen. Ihre Leidenschaft und Ehrgeiz sind Ihre Leitprinzipien und Ihre Leistung wird durch Eifer und Begeisterung definiert.</p><p>Wenn Sie im Moment nach einem neuen Job suchen, werden Sie wahrscheinlich feststellen, dass große Chancen und Möglichkeiten auf Ihren Weg kommen werden. Zögern Sie nicht das zu verlangen was Sie wirklich wollen. Sie werden mehr erfolgreich sein als Sie je für möglich gehalten haben.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Regelmäßig erinnern Sie Sich, dass die Verbindung zwischen Körper und Geist näher und intimer ist, als Sie denken. Es gibt immer intensive Interaktionen zwischen den beiden, der Körper fühlt, was der Geist denkt und umgekehrt.</p><p>Das Ass der Stäbe sagt Ihnen, dass auf einer spirituellen Ebene etwas oder jemand neues in Ihr Leben kommen wird. Haben Sie spirituelle \"Helden\", Leute zu wen Sie hoch schauen? Es kann an der Zeit sein so einen zu finden, der Sie helft, wachsen und entwickeln Sie werden angenehm überrascht sein neue Möglichkeiten zu entdecken, sobald Sie sich dazu öffnen.</p>",
                    }
                }
            },
            {
                filename: "23",
                numerology : 2,
                en : {
                    name: "Two of Wands",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "duality of the will power",    
                    description : "Two of wants talks about not being certain about what you want and face sus with inner doubt, making it hard to get into action.",
                    questions : [ "Do you know your hearts’ desires?", "What is your inutition telling you?", "Are you brave enough to listen and go for it?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>These are times in which you may find it difficult to stay balanced. You may have an indifferent attitude, turning your back on life and not seeming to care about anything or anyone. You experience everything to be \"so so\" and that's what you communicate too. You may seem neutral, not being carried away by your emotions, but is that really the case? Maybe you experience a feeling of being almost paralyzed, maybe it seems impossible for you to be touched by anyone, by anything. </p><p>You may think you are ok being indifferent and neutral but possibly the time has come to take a stand, to feel the emotions flowing through you again.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that you're shielding yourself from the people around you, nothing seems to genuinely touch you, you're distant. Even love doesn't seem to interest you for the moment, as if feelings don't matter right now. Take a long hard look in the mirror and ask yourself what's really important to you, what you need and expect from a loving relationship: understanding, recognition, comforting arms around you?</p><p>If you're not currently in a relationship it's possible that someone you know is interested in you as a potential partner. Don't dismiss this person right away but take the time to really get to know him/her.Maybe what they have to offer, fits your needs much better than you could have imagined.</p>",
                        [GuidanceFields.WORK] : "<p>In a way, it may be hard for the people around you to understand if you're indifferent about your job or if you're just trying to stay neutral. Are you happy with the position you're taking? Is it artificial or is it truly who you are right now?</p><p>If you're looking for a job, things may really be flowing for you, as if you don't have to put in a lot of energy. Don't underestimate yourself, where finding a job is concerned you are pretty balanced.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually this card is also about balance. The primary and most important spiritual relationship in your life is the one you have with yourself. That is why you shouldn't forget to take the time and energy to really take good care of yourself, not only spiritually but also emotionally.</p><p>A connection with someone who will do you a world of good, may be in the making. Observe your surroundings so you don't miss out on this opportunity.</p>",
                    }
                },
                fr : {
                    name : "Le Deux de Bâton",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "dualité dans l'aspect de la volonté",    
                    description : "Les deux de Bâtons nous parlent de ne pas savoir précisément ce que nous voulons réellement et de notre doute intérieur, ce qui rend l'action difficile.",
                    questions : ["Savez-vous ce que vous voulez vraiment au fond de vous-même?", "Que vous dis votre voix intérieure?", "Osez-vous l’écouter et la suivre?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Ce sont des moments où vous trouvez difficile de rester en équilibre. Vous pouvez montrer une attitude indifférente, tourner le dos à la vie. Vous semblez vous intéresser à rien et à personne. Vous vivez tout comme \"ainsi\" et c'est ainsi que vous communiquez avec le monde extérieur. Vous semblez neutre et non guidé par vos émotions, mais est-ce vraiment le cas? Peut-être éprouvez-vous le sentiment d'être presque paralysé ou peut-être qu'il vous semble impossible d'être touché par quelqu'un ou quelque chose.</p><p>Vous pensez peut-être qu'il est normal d'être indifférent et neutre, mais le moment est peut-être venu de prendre position pour que les émotions puissent à nouveau circuler en vous.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that you're shielding yourself from the people around you, nothing seems to genuinely touch you, you're distant. Even love doesn't seem to interest you for the moment, as if feelings don't matter right now. Take a long hard look in the mirror and ask yourself what's really important to you, what you need and expect from a loving relationship: understanding, recognition, comforting arms around you?</p><p>If you're not currently in a relationship it's possible that someone you know is interested in you as a potential partner. Don't dismiss this person right away but take the time to really get to know him/her.Maybe what they have to offer, fits your needs much better than you could have imagined.</p>",
                        [GuidanceFields.WORK] : "<p>In a way, it may be hard for the people around you to understand if you're indifferent about your job or if you're just trying to stay neutral. Are you happy with the position you're taking? Is it artificial or is it truly who you are right now?</p><p>If you're looking for a job, things may really be flowing for you, as if you don't have to put in a lot of energy. Don't underestimate yourself, where finding a job is concerned you are pretty balanced.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually this card is also about balance. The primary and most important spiritual relationship in your life is the one you have with yourself. That is why you shouldn't forget to take the time and energy to really take good care of yourself, not only spiritually but also emotionally.</p><p>A connection with someone who will do you a world of good, may be in the making. Observe your surroundings so you don't miss out on this opportunity.</p>",
                    }
                },
                sp:{
                    name:"El Dos de Bastos ",
                    suit: "Bastos",
                    element: "🜂 – “Fuego",
                    theme: "dualidad en el aspecto de la voluntad",    
                    description: "El  Dos de Bastos se trata sobre no saber exactamente lo que realmente quieres y nos señalan nuestra duda interior, lo que dificulta la acción",
                    questions: ["¿Sabes lo que quieres en el fondo?", "¿Qué dice tu voz interior?", "¿Te atreves a escuchar eso y dar un paso?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Estos son tiempos en los que puede tener dificultades para mantener el equilibrio. Es posible que tenga una actitud indiferente, dando la espalda a la vida y no parecía importarle nada ni nadie. Experimenta todo para ser \"regular\" y eso es lo que se comunica también. Puede parecer neutral, no dejarse llevar por sus emociones, pero es que realmente el caso? Tal vez usted experimenta una sensación de estar casi paralizado, quizás parece imposible que pueda ser tocado por nadie, por nada. Usted puede pensar que puede parecer indiferente y neutral pero posiblemente ha llegado el momento de tomar una posición, de sentir las emociones que fluyen a través de nuevo.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Twee",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "dualiteit in het wilsaspect",    
                    description : "De twee van staven gaat over het niet precies weten wat je werkelijk wilt en wijst ons op onze innerlijke twijfel, waardoor het lastig is om in actie te komen.",
                    questions : [ "Weet jij wat je eigenlijk diep van binnen wilt?", "Wat zegt je innerlijke stem?", "Durf jij daar naar te luisteren en een stap te zetten?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Het is best mogelijk dat je het op dit moment moeilijk vindt om in evenwicht te blijven. Misschien heb je een tamelijk onverschillige houding aangenomen, keer je je af van het leven, geef je weinig of niets om mensen en dingen. Je ervaart alles alsof het maar “zo zo” is en zo stel je je ook op naar de mensen om je heen en de rest van de wereld. Het kan erop lijken dat je neutraal bent, dat je je niet laat meeslepen door je emoties, maar is dat wel echt het geval? Misschien is het eerder zo dat je je bijna verlamd voelt, dat het onmogelijk voor je lijkt om echt geraakt te worden door iets of iemand.</p><p>Best mogelijk dat je het veilig en comfortabel vindt om je onverschillig en neutraal op te stellen, maar misschien is nu de tijd gekomen om een standpunt in te nemen en om alles wat er door je heen gaat ook weer echt te gaan voelen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt je dat je je afschermt van de mensen om je heen. Het lijkt alsof niets je echt kan raken, je bent afstandelijk. Zelfs de liefde interesseert je nu even niet, alsof gevoelens en emoties nu geen belang hebben. Ga eens voor de Spiegel staan en kijk naar jezelf, neem er de tijd voor. Stel jezelf de vraag wat echt belangrijk voor je is in een liefdevolle relatie: is het begrip, (h)erkenning, warme armen om je heen? Als het antwoord ja is, laat dan je afweer los.</p><p>Als je single bent is het mogelijk dat er iemand in je omgeving interesse voor je heeft als potentiële partner. Je eerste reactie is om die persoon af te wijzen, de optie weg te wuiven. Maar misschien neem je beter even de tijd om deze persoon wat beter te leren kennen. Je zou kunnen ontdekken dat wat zij/hij te bieden heeft veel beter bij je past dan je gedacht had.</p>",
                        [GuidanceFields.WORK] : "<p>Op een bepaalde manier is het misschien moeilijk voor de mensen om je heen om te begrijpen of je nu onverschillig bent over je werk of dat je juist heel hard probeert om neutraal te blijven. Ben jij tevreden met de positie die je inneemt? Doe je een beetje alsof of toon je echt wie je bent en wat je voelt?</p><p>Als je op dit moment op zoek bent naar werk dan zit je waarschijnlijk in een positieve flow en hoef je niet veel energie te investeren in je zoektocht. Onderschat jezelf niet, wat werk zoeken betreft zit je op dit moment heel goed in evenwicht!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart heeft het over spiritueel evenwicht. De meest intense en unieke spirituele relatie die je ooit in je leven zal hebben is de relatie met jezelf. Net daarom is het belangrijk dat je er altijd aan denkt om voldoende tijd en energie te besteden aan het goed voor jezelf zorgen en dat niet enkel op spiritueel vlak maar ook emotioneel. Waarschijnlijk komt er binnenkort iemand op je pad waarmee je een positief en opbouwend spiritueel contact zal hebben dat je enorm veel vreugde en verrijking zal brengen. Wees aandachtig, observeer iedereen die je ontmoet zodat je deze persoon niet over het hoofd ziet.</p>",
                    }
                },
                de : {
                    name: "Zwei der Stäbe",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "Dualität im Aspekt des Willens",    
                    description : "Die Zwei der Stäbe spricht davon, dass wir nicht genau wissen, was wir wirklich wollen, und über unsere inneren Zweifel, der es uns schwierig macht zu handeln.",
                    questions : [ "Wissen Sie, was Sie wirklich tief in sich selbst wollen?", "Was sagt Ihnen Ihre innere Stimme?", "Wagen Sie es, auf sie zu hören und ihr zu folgen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Dies sind Zeiten, in denen Sie es schwierig finden, ausgewogen zu bleiben. Sie können eine gleichgültige Haltung zeigen, dem Leben den Rücken zukehren. Sie scheinen sich um nichts und niemanden zu kümmern. Sie erfahren alles als \"so so la la\" und so kommunizieren Sie auch mit der Außenwelt. Sie erscheinen neutral und nicht von Ihren Emotionen geleitet. Aber ist das auch wirklich der Fall? Vielleicht erleben Sie das Gefühl, fast gelähmt zu sein oder vielleicht scheint es unmöglich für Sie, von jemandem oder etwas berührt zu werden.</p><p>Sie denken vielleicht, dass es in Ordnung ist, gleichgültig und neutral zu sein, aber vielleicht ist die Zeit gekommen, Stellung zu beziehen, damit die Emotionen wieder durch Sie hindurchfließen können.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte sagt Ihnen, dass Sie Sich für die Menschen um Sie herum abschließen, nichts scheint Ihnen wirklich zu berühren, Sie bleiben Fern. Auch die Liebe scheint Ihnen im Moment nicht zu interessieren, als ob Gefühle nicht wichtig sind. Schauen Sie Sich lange und gründlich an im Spiegel und fragen Sie sich, was wirklich wichtig für Sie ist, was Sie brauchen und von einer liebevollen Beziehung erwarten: Verständnis, Anerkennung, tröstende Arme um Sie?</p><p>Wenn Sie derzeit nicht in einer Beziehung sind, es ist möglich, dass es jemanden gibt, der sehr interessiert an Ihnen ist und Sie möglicherweise als einen potenziellen Partner sieht. Weisen Sie diese Person nicht sofort ab, aber nehmen Sie die Zeit, um ihn / sie wirklich besser kennen zu lernen. Vielleicht passt das was er / sie zu bieten hat viel besser zu Ihren Ansprüchen, als Sie je gedacht haben.</p>",
                        [GuidanceFields.WORK] : "<p>In gewisser Weise kann es schwierig sein für die Menschen, um Sie herum zu verstehen, ob Sie gleichgültig Ihrem Job gegenüber stehen oder nur versuchen neutral zu bleiben. Sind Sie mit der Position die Sie einnehmen glücklich? Ist es künstlich oder ist es wirklich so wie Sie gerade sind?</p><p>Wenn Sie nach einem Job suchen, könnte es wirklich erscheinen als, ob Sie kaum Energie eingesetzt haben. Unterschätzen Sie Sich nicht, bei der Suche nach einem Arbeitsplatz sind Sie ziemlich ausgeglichen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spirituell handelt diese Karte auch über Ausgewogenheit. Die erste und wichtigste spirituelle Beziehung in Ihrem Leben ist die, die Sie mit Sich Selbst haben. Das ist, warum man nicht vergessen sollte, die Zeit und Energie zu benutzen, um wirklich gut für sich zu sorgen, nicht nur spirituell, sondern auch emotional.</p><p>Eine Verbindung mit jemandem, der Sie eine Welt von Gutes bringt, kann in Entwicklung sein. Beobachten Sie Ihre Umgebung, so dass Sie diese Gelegenheit nicht verpassen.</p>",
                    }
                }
            },
            {
                filename: "24",
                numerology : 3,
                en : {
                    name: "Three of Wands",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "make way for what you want",    
                    description : "Three of Wands talks about making way for what you want and taking the long view about your future’s perspectives.",
                    questions : [ "How do you see your future?", "Can you make way for what you desire?", "Which steps can you make in that direction?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>It may have been a long and arduous journey, but right now you can look back on this expedition with satisfaction and a sense of accomplishment. You are right where you want to be and should be feeling strong, in control.</p><p>Take the opportunity to rest and observe your surroundings from this higher level. This is a good time to ponder on the future and realistically plan your goals and aspirations.</p><p>At the moment you are fully capable of taking the right decisions, which also means there will be no big obstacles to slow you down. You're standing on a firm base to build your future on.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Even though your relationship is probably going very well at the moment, it is important for you to find out if your business and private life are well and truly balanced. You may have a tendency to put too much time into your work. With a few minor adjustments you can create healthy and harmonious surroundings for you and your loved one to continue growing and evolving within the relationship.</p><p>When you are currently single it is very likely that you will soon meet someone new. Make sure you give this new encounter the attention it deserves.</p>",
                        [GuidanceFields.WORK] : "<p>When you're on the lookout for a job, this may be the perfect time to find one. Don't be too modest, approach job interviews with a healthy dose of self-confidence and make sure you are fully aware of all your qualities and accomplishments. The job you will find may be a lot better than you expected.</p><p>If you are currently employed, you may have reached an important goal, an important achievement. Still, it may take some time for your superiors to realize that too. Now is the right time to do some long term planning.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You already have an elaborate view on spirituality, but now the time may be right to shop around, investigate. Your spiritual growth is a constant journey, it's about change and evolution. Don't be hesitant when you experience your convictions and opinions are evolving.Change is necessary and natural. Ask yourself where you want to be in a few years, on a spiritual level. Are you willing and able to give yourself the space to attain your goals?</p>",
                    }
                },
                fr : {
                    name : "Le Trois de Bâton",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "Donner de l'espace à ce que vous voulez",    
                    description : "Le Trois de Bâtons nous parle de donner l'espace à ce que nous voulons et de regarder ce que nous voulons de notre avenir.",
                    questions : ["Que voulez-vous dans le futur?", "Pouvez-vous faire de la place pour ce que vous voulez?", "Quelle mesure pouvez-vous faire dans cette direction?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Ce fut peut-être un long et fastidieux voyage, mais en ce moment, vous pouvez regarder cette expédition avec satisfaction et un sentiment d'accomplissement. Vous êtes exactement là où vous voulez être et vous devriez vous sentir fort et sous contrôle.</p><p>Profitez de l'occasion pour vous reposer et observer votre environnement depuis ce niveau supérieur. C'est le bon moment pour penser à l'avenir et planifier vos objectifs et vos désirs de façon réaliste.</p><p>En ce moment, vous êtes en mesure de prendre les bonnes décisions. Cela signifie également qu'il n'y a pas de gros obstacles pour vous ralentir maintenant. Vous possédez une base solide sur laquelle vous pouvez bâtir votre avenir.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Even though your relationship is probably going very well at the moment, it is important for you to find out if your business and private life are well and truly balanced. You may have a tendency to put too much time into your work. With a few minor adjustments you can create healthy and harmonious surroundings for you and your loved one to continue growing and evolving within the relationship.</p><p>When you are currently single it is very likely that you will soon meet someone new. Make sure you give this new encounter the attention it deserves.</p>",
                        [GuidanceFields.WORK] : "<p>When you're on the lookout for a job, this may be the perfect time to find one. Don't be too modest, approach job interviews with a healthy dose of self-confidence and make sure you are fully aware of all your qualities and accomplishments. The job you will find may be a lot better than you expected.</p><p>If you are currently employed, you may have reached an important goal, an important achievement. Still, it may take some time for your superiors to realize that too. Now is the right time to do some long term planning.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You already have an elaborate view on spirituality, but now the time may be right to shop around, investigate. Your spiritual growth is a constant journey, it's about change and evolution. Don't be hesitant when you experience your convictions and opinions are evolving.Change is necessary and natural. Ask yourself where you want to be in a few years, on a spiritual level. Are you willing and able to give yourself the space to attain your goals?</p>",
                    }
                },
                sp:{
                    name:"El Tres de Bastos ",
                    suit: "Bastos",
                    element: "🜂 – Fuego",
                    theme: "Da á lo que quieras espacio ",    
                    description: "El Tres de Bastos se trata de dar espacio a lo que quieres y nos apunta a mirar lo que queremos con nuestro futuro",
                    questions: ["¿Qué quieres en el futuro?", "¿Puedes dar espacio á lo que quieres?", "¿Qué paso puedes dar en esa dirección?" ],
                    
                    
                     cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Puede haber sido un viaje largo y arduo, pero ahora usted puede mirar hacia atrás en esta expedición con satisfacción y un sentido de logro. Usted tiene razón en la que desea ser y debe sentirse fuerte, en control. Aproveche la oportunidad de descansar y observar sus alrededores desde este nivel superior. Este es un buen momento para reflexionar sobre el futuro y realista planear sus metas y aspiraciones. En el momento en que son plenamente capaces de tomar las decisiones correctas, lo que también significa que no habrá grandes obstáculos para reducir la velocidad. Usted está de pie sobre una base firme para construir su futuro sobre.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Drie",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "geef dat wat jij wilt de ruimte",    
                    description : "De drie van staven gaat over ruimte geven aan wat je wilt en wijst ons op het kijken naar wat wij met onze toekomst willen.",
                    questions : [ "Wat wil jij in de toekomst?", "Kun jij, dat wat je wilt, de ruimte geven?", "Welke stap kun je zetten in die richting?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Het was een lange en soms zware reis maar op dit moment kan je erop terugkijken met een tevreden gevoel van voleinding, het is volbracht! Je bevindt je exact op de plek waar je wil zijn en je voelt je sterk, je hebt alles onder controle.</p><p>Neem de gelegenheid te baat om uit te rusten en naar je omgeving te kijken met nieuwe ogen. Dit is een goede tijd om na te denken over je toekomst en realistisch te gaan plannen wat je wil bereiken.</p><p>Je bent nu perfect in staat om alle juiste beslissingen te nemen. Dit betekent ook dat je geen grote obstakels op je weg hoeft te verwachten. Je hebt voor jezelf een stevige basis gelegd en je kan nu beginnen met bouwen aan je toekomst.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Waarschijnlijk gaat het op dit ogenblik erg goed met je liefdesrelatie. Neem toch de tijd om te bekijken of je privéleven en je carrière wel goed in evenwicht zijn. Het is immers mogelijk dat je tegenwoordig de neiging hebt om wat teveel tijd in je werk te stoppen. En door (misschien kleine) aanpassingen door te voeren kan je ervoor zorgen dat je een gezonde en harmonieuze omgeving creëert waarin jij en je partner zorgeloos samen kunnen groeien.</p><p>Als je op dit moment alleen bent, is het heel waarschijnlijk dat je binnenkort iemand zal ontmoeten. Zorg ervoor dat je voldoende aandacht besteedt aan deze ontmoeting, spring er niet te zorgeloos mee om.</p>",
                        [GuidanceFields.WORK] : "<p>Als je op zoek bent naar werk dan is dit de perfecte periode om een baan te vinden. Wees niet te bescheiden, benader interviews met een gezonde dosis zelfvertrouwen en zorg ervoor dat je je ten volle bewust bent van je kwaliteiten en prestaties. Het zit er dik in dat je een job vindt die stukken beter is dan je verwacht had.</p><p>Als je op dit moment aan het werk bent dan heb je waarschijnlijk een belangrijk doel bereikt of een moeilijke taak goed volbracht. Toch is het best mogelijk dat het je oversten nog wat tijd zal kosten om dit te beseffen en te belonen, maak je daar niet druk om. Concentreer je nu op plannen voor de langere termijn.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je hebt al een duidelijke en uitgebreide visie op spiritualiteit maar toch is het nu het goede moment om wat rond te kijken en op onderzoek uit te gaan. Je spirituele evolutie is immers een voortdurende reis, de weg draait om groei en verandering.</p><p>Aarzel niet als je merkt dat je opvattingen en overtuigingen aan het veranderen zijn, omarm de nieuwe ontdekkingen. Verandering is noodzakelijk en natuurlijk. Stel jezelf de vraag waar je op spiritueel niveau binnen een paar jaar wil zijn. Ben je ertoe bereid om jezelf de ruimte te geven die doelen ook te bereiken?</p>",
                    }
                },
                de : {
                    name: "Drei der Stäbe",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "Geben Sie dem Raum, was Sie wollen.",    
                    description : "Die Drei der Stäbe spricht darüber, dem Raum zu geben, was wir wollen und zu sehen, was wir von unserer Zukunft erwarten.",
                    questions : [ "Was wollen Sie in Zukunft?", "Können Sie dem Platz machen, was Sie wollen?", "Welche Maßnahmen können Sie in diese Richtung ergreifen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Vielleicht war es eine lange und mühsame Reise, aber im Moment können Sie auf diese Expedition mit Befriedigung und einem Gefühl der Erfüllung zurückblicken. Sie befinden sich genau dort, wo Sie sein wollen, und Sie sollten sich stark fühlen und alles im Griff haben.</p><p>Nutzen Sie die Gelegenheit, um sich auszuruhen und Ihre Umgebung von diesem höheren Niveau aus zu beobachten. Jetzt ist ein guter Zeitpunkt, um über die Zukunft nachzudenken und Ihre Ziele und Wünsche realistisch zu planen.</p><p>Im Moment sind Sie durchaus in der Lage, die richtigen Entscheidungen zu treffen. Es bedeutet auch, dass es jetzt keine großen Hindernisse gibt, die Sie bremsen. Sie stehen auf einer festen Basis, auf die Sie Ihre Zukunft bauen können.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Auch wenn Ihre Beziehung wahrscheinlich sehr gut ist im Moment, ist es wichtig für Sie, um herauszufinden, ob Ihr Unternehmen und Privatleben wirklich gut und ausgewogen sind. Sie können eine Neigung haben zu viel Zeit in die Arbeit zu stecken. Mit einigen kleinen Anpassungen könnten Sie eine gesunde und harmonische Umgebung für Sie und Ihre Liebsten schaffen, um innerhalb der Beziehung weiter zu wachsen und entwickeln.</p><p>Wenn Sie noch alleine sind, ist es sehr wahrscheinlich, dass Sie bald neue Bekanntschaften machen. Achten Sie darauf, diese Sie diese neuen Begegnungen die Aufmerksamkeit geben, die sie verdienen.</p>",
                        [GuidanceFields.WORK] : "<p>Wenn Sie auf der Suche nach einem Job sind, kann dies die perfekte Zeit, einen zu finden. Seien Sie nicht zu bescheiden. Gehe alle Vorstellungsgespräche ein mit einer gesunden Portion Selbstvertrauen und stellen Sie sicher, dass Sie Sich voll und ganz bewusst sind von all Ihren Qualitäten und Leistungen. Den Job, den Sie finden werden ist viel besser, als Sie erwartet haben.</p><p>Wenn Sie derzeit beschäftigt sind, könnten Sie jetzt ein wichtiges Ziel, einen wichtigen Erfolg erreicht haben. Dennoch kann es einige Zeit dauern bevor Ihre Vorgesetzten das erkennen. Jetzt ist die richtige Zeit um einige langfristige Planung zu machen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie haben bereits einen ausgeklügelten Blick auf Spiritualität, aber jetzt ist die Zeit um Sich mal herum zu schauen, zu untersuchen. Ihr spirituelles Wachstum ist eine ständige Reise, es geht um Veränderung und Entwicklung. Zögern Sie nicht, wenn Sie bemerken, dass Ihre Überzeugungen und Meinungen sich entwickeln. Änderung ist notwendig und natürlich. Fragen Sie sich, wo Sie in ein paar Jahren sein wollen, auf welcher spirituellen Ebene. Sind Sie bereit und in der Lage sich den Raum zu geben, um Ihre Ziele zu erreichen?</p>",
                    }
                }
            },
            {
                filename: "25",
                numerology : 4,
                en : {
                    name: "Four of Wands",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "having a handle on what you want",    
                    description : "Four of Wands talks about the handle you have on things and shows a joyous goodbye before entering a new journey.",
                    questions : [ "Is it clear to you what you truly want?", "Which phase can you follow through joyously?", "What do you want to shape?", "Is there anything you are able to change in the direction of your choice?"],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about sociability, pleasure, happiness, joy and open mindedness, generally having a good time. Make sure you enjoy these times in the company of your loved ones. For the moment there are practically no worries or sorrows standing in your way, you can look at the world cheerfully.</p><p>Maybe the tasks lying ahead will be demanding, but you have well enough strength and energy to face them. Don't worry, approach everything that comes your way in a positive way, enjoy!</p><p>Sometimes this card stands for the purchase of a house or moving.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In your relationship it's sunny times right now, or they're about to come your way. You feel safe and comfortable, right at home with your partner. A true commitment is in the making, maybe there will even be an engagement or a wedding.</p><p>If you're single be sure to not miss out on parties and festive occasions in general, you are bound to meet someone interesting while you are having fun.</p><p>Four of Wands tells you that, for the moment, you have a caring and relaxed attitude towards your loved ones.</p>",
                        [GuidanceFields.WORK] : "<p>Normally things should be going pretty well career-wise. You enjoy your work and are motivated to turn every challenge into a success.</p><p>Maybe recently a promotion or a new job has come your way, it could also still be in the making.Anyway, you will follow your new path with a lot of enthusiasm.</p><p>But be careful, to be on top right now means that it will take some effort to stay there.Enjoy every second of today, but remember to conserve enough energy to get through tomorrow.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The choices you make in order to achieve spiritual growth are very personal. Possibly people around you may not understand or condone your choices or the roads you are exploring spiritually. If this annoys you or makes you feel insecure, remember that there is a reason for the choices you make, even if you don't see it right now. It's always wise to listen to other people's opinions, but finally choosing and deciding is your responsibility. You should do what really feels right for you.</p>",
                    }
                },
                fr : {
                    name : "Le Quatre de Bâton",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "s’accrocher à ce que l’on veut",    
                    description : "Le Quatre de Bâtons, nous parle de prendre en main ce que nous voulons et nous rappelle la joie de dire adieu afin de pouvoir passer à la phase suivante.",
                    questions : ["Est-ce que vous savez déjà clairement ce que vous voulez réellement?", "Quelle phase pouvez-vous conclure joyeusement?", "Que voulez-vous façonner?", "Pouvez-vous changer quelque chose dans la direction que vous souhaitez?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte est synonyme de convivialité, de joie, de bonheur et d'ouverture d'esprit, le plus souvent pour un bon moment que vous appréciez. Assurez-vous de profiter de ce moment en compagnie de vos proches. En ce moment, il n'y a pratiquement pas de soucis, aucun chagrin ne se dresse sur votre chemin, vous pouvez regarder le monde avec joie.</p><p>Peut-être que les tâches qui vous attendent sont exigeantes, mais vous avez assez de force et d'énergie pour vous battre. Ne vous inquiétez pas, acceptez tout ce qui vous arrive positivement, amusez-vous bien ! Parfois, cette carte symbolise l'achat d'une maison ou un déménagement.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In your relationship it's sunny times right now, or they're about to come your way. You feel safe and comfortable, right at home with your partner. A true commitment is in the making, maybe there will even be an engagement or a wedding.</p><p>If you're single be sure to not miss out on parties and festive occasions in general, you are bound to meet someone interesting while you are having fun.</p><p>Four of Wands tells you that, for the moment, you have a caring and relaxed attitude towards your loved ones.</p>",
                        [GuidanceFields.WORK] : "<p>Normally things should be going pretty well career-wise. You enjoy your work and are motivated to turn every challenge into a success.</p><p>Maybe recently a promotion or a new job has come your way, it could also still be in the making.Anyway, you will follow your new path with a lot of enthusiasm.</p><p>But be careful, to be on top right now means that it will take some effort to stay there.Enjoy every second of today, but remember to conserve enough energy to get through tomorrow.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The choices you make in order to achieve spiritual growth are very personal. Possibly people around you may not understand or condone your choices or the roads you are exploring spiritually. If this annoys you or makes you feel insecure, remember that there is a reason for the choices you make, even if you don't see it right now. It's always wise to listen to other people's opinions, but finally choosing and deciding is your responsibility. You should do what really feels right for you.</p>",
                    }
                },
                sp:{
                    name: "El Cuatro de Bastos ",
                    suit: "Bastos",
                    element : "🜂 – Fuego",
                    theme: "agarrarse a lo que quieres",    
                    description: "El cuatro de Bastos es para agarrar lo que quieres y nos señala la alegría de despedirnos para poder entrar en la siguiente fase",
                    questions: ["¿Ya tienes claro lo que quieres?", "¿Qué fase puedes cerrar con alegría", "¿A qué quieres dar forma", "¿Puedes cambiar algo en la dirección que deseas?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es la sociabilidad, el placer, la felicidad, la alegría y el espíritu de apertura, que generalmente tienen un buen tiempo. Asegúrese de que disfruten de estos momentos en compañía de sus seres queridos. Por el momento no hay prácticamente preocupaciones o dolores de pie en su camino, se puede ver en el mundo con alegría. Tal vez las tareas que tenemos por delante serán exigentes, pero tiene  bien suficientemente fuerza y energía para enfrentarse a ellos. No se preocupe, acércate a todo lo que se le presente de una manera positiva, disfrutar! A veces, esta carta significa la compra de una casa o en movimiento.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Vier",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "grip op wat je wilt",    
                    description : "De vier van staven gaat over grip op dat wat je wilt en wijst ons op het vreugdevol afscheid nemen om een volgende fase in te kunnen gaan.",
                    questions : [ "Is voor jou al duidelijk wat je werkelijk wilt?", "Welke fase kun jij vreugdevol afsluiten?", "Wat wil jij vorm gaan geven?", "Kun jij iets veranderen in je eigen gewenste richting?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart draait om gezelligheid, vreugde, geluk, genieten en openheid. Eigenlijk is de essentie gewoon om een fijne tijd te hebben. Zorg ervoor dat je in het gezelschap bent van al je geliefden als je deze mooie tijden meemaakt, doe het niet alleen. Op dit moment zijn er eigenlijk geen zorgen, is er geen verdriet dat je in de weg staat, je kan een vrolijke en positieve kijk op de wereld hebben.</p><p>Misschien liggen er moeilijkere tijden in het verschiet, dat is best mogelijk. Maar op dit moment heb je ruim voldoende kracht en energie om alles wat op je weg komt aan te pakken op een positieve en opbouwende manier. Maar ga nu niet piekeren over wat de toekomst misschien zal brengen, eerst is het tijd voor genieten!</p><p>Soms staat deze kaart ook symbool voor verhuizen of de aankoop van een woning.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op gebied van relaties ben je onderweg naar zonnige tijden. Je voelt je veilig en comfortabel en helemaal thuis met en bij je partner. Het is goed mogelijk dat je een volgende stap aan het overwegen bent, dat je misschien zelfs een verloving of een huwelijk overweegt.</p><p>Als je op zoek bent naar een partner dan is het nu de juiste tijd om alle mogelijke feestelijke gelegenheden op te zoeken. Het is heel waarschijnlijk dat je daar iemand zal ontmoeten die boeiend en aantrekkelijk is en waar je fijne tijden mee kan beleven.</p><p>De Vier van Staven vertelt je dat het op dit moment goed is om je betrokken en toch ontspannen op te stellen naar je geliefden toe. Oefen geen druk uit, wees open.</p>",
                        [GuidanceFields.WORK] : "<p>Wat je werk betreft zou op dit moment alles behoorlijk goed moeten verlopen. Je geniet van je werk en je bent echt gemotiveerd om van elke uitdaging een succes te maken.</p><p>Misschien heb je onlangs een promotie gekregen of ben je aan een nieuwe baan begonnen maar het is ook mogelijk dat zoiets binnenkort zal gebeuren. Wat ook je nieuwe pad moge zijn, je zal er vol enthousiasme aan beginnen.</p><p>Wees toch wat voorzichtig, als je aan de top staat dan betekent dit ook dat je de nodige moeite zal moeten doen om daar te blijven. Geniet van elk moment van elke dag maar zorg ervoor dat je genoeg energie overhoudt om ook morgen de dag door te komen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De keuzes die je maakt om spiritueel te groeien zijn een heel persoonlijke zaak. Het is best mogelijk dat de mensen om je heen jouw spirituele keuzes en zoektochten niet begrijpen of ze zelfs niet goedkeuren. Als je dit vervelend vindt of als je er zelfs onzeker van wordt, denk er dan aan dat er altijd een dieperliggende reden is voor alle keuzes die je maakt, ook al zijn die redenen je op dit moment niet duidelijk. Het is altijd verstandig om naar de mening van anderen te luisteren, maar uiteindelijk is het jouw verantwoordelijkheid om te kiezen en te beslissen. Het gaat erom dat <strong>jij</strong> doet wat goed voelt voor <strong>jou</strong>.</p>",
                    }
                },
                de : {
                    name: "Vier der Stäbe",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "An dem festhalten, was Sie wollen.",    
                    description : "Die Vier der Stäbe spricht über die Übernahme der Kontrolle über das, was wir wollen, und erinnert uns an die Freude, loszulassen, damit wir zur nächsten Phase übergehen können.",
                    questions : [ "Wissen Sie schon klar, was Sie wirklich wollen?", "Welche Phase können Sie glücklich abschließen?", "Was wollen Sie gestalten?", "Können Sie etwas in die von Ihnen gewünschte Richtung ändern?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für Geselligkeit, Freude, Glück und Aufgeschlossenheit, in der Regel für eine gute Zeit, die Sie genießen. Achten Sie darauf, dass Sie diese Zeit in der Gesellschaft Ihrer Liebsten genießen. Im Moment gibt es praktisch keine Sorgen, kein Kummer steht Ihnen im Wege, Sie können die Welt fröhlich betrachten.</p><p>Vielleicht sind die Aufgaben, die auf Sie zukommen, anspruchsvoll, aber Sie haben genügend Kraft und Energie, um zu kämpfen. Keine Sorge, nehmen Sie alles, was auf Ihrem Wege kommt, positiv an, viel Spaß! Manchmal symbolisiert diese Karte den Kauf eines Hauses oder einen Umzug.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In Ihrer Beziehung sind es sonnige Zeiten im Augenblick, oder sie kommen bald auf Sie zu. Sie fühlen sich sicher und komfortabel, wie zu Hause mit Ihrem Partner. Eine echte Verbindung ist in Entwicklung, vielleicht gibt es sogar eine Verlobung oder eine Hochzeit.</p><p>Wenn Sie alleine sind, sollten Sie sicher nicht verpassen teil zu nehmen an Feier und festliche Anlässe. Sie werden jemanden interessanten treffen während Sie Spaß haben.</p><p>Vier der Stäbe sagt Ihnen, dass Sie im Moment eine liebevolle und entspannende Beziehung zu Ihren Geliebten haben.</p>",
                        [GuidanceFields.WORK] : "<p>Normalerweise laufen die Dinge ziemlich gut Karriere-weise. Sie genießen Ihre Arbeit und sind motiviert um jede Herausforderung zu einem Erfolg zu machen.</p><p>Vielleicht haben Sie vor kurzem eine Beförderung oder einen neuen Job bekommen, aber es könnte auch noch auf Sie zukommen. Auf jedem Fall werden Sie Ihren neuen Weg mit viel Begeisterung einschlagen.</p><p>Aber seien Sie vorsichtig, obwohl Sie vielleicht im Moment an der Spitze sein könnten, es kostet einige Mühe dazubleiben. Genießen Sie jede Sekunde von heute, aber behalten Sie genügend Energie übrig um auch Morgen genießen zu können.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Entscheidungen, die Sie, um spirituelles Wachstum zu erreichen machen, sind sehr persönlich. Möglicherweise können die Leute um Sie sie nicht verstehen, oder akzeptieren sie Ihre Auswahl der Straßen für spirituelle Erforschung nicht. Wenn dies Sie ärgert oder Sie unsicher macht, denken Sie daran, dass es einen Grund gibt für die Auswahl, die Sie machen, auch wenn Sie es gerade jetzt noch nicht sehen. Es ist immer ratsam die Meinungen anderer Menschen zu hören, aber schließlich liegt die Wahl und die Entscheidung in Ihrer Verantwortung. Sie sollten tun, was Sie für Richtig halten.</p>",
                    }
                }
            },
            {
                filename: "26",
                numerology : 5,
                en : {
                    name: "Five of Wands",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "own desires",    
                    description : "Five of Wands talks about learning to communicate about what you want and to learn how to communicate about this to others.",
                    questions : [ "Do you know what you truly want yet?", "Do you talk to others about this?", "Can you listen to others?", "Would you like to impose your own will?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card tells you that at this moment in your life, it is very important for you to truly believe in yourself and your abilities. Five of Wands is also about competition, possibly a contest or playful battle of some kind, be it private or work related. And the challenge you meet is all about furthering yourself, growing, evolving. Even though the situation is in no way destructive, be ready to encounter some resistance and be sure to stay fair at all times.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Five of Wands often is all about choosing and communicating your choice. Maybe there is more than one person romantically interested in you.It will be up to you to choose the best candidate. Or maybe you really like someone only to find stiff competition. In that case you will have to stand your ground, prove yourself and your feelings. Be sure you didn't leave any road unexplored.</p><p>Even though this is a positive card, it's notabout romance and candlelight dinners. It's about competition, battle. In this case the battle may be necessary to bring you and your (new) partner to further heights in your relationship.</p>",
                        [GuidanceFields.WORK] : "<p>You are facing a challenge right now and it won't be easy to meet it. Still, if you carefully choose your path and tactics they will surely lead you to success. Look at this challenge as if it were a test, an exercise, not something people will judge you by. Also, sometimes it pays to listen to other’s opinions, even if you have already made your decision.</p><p>Financially you may have some problems for the moment, but don't worry too much, your situation will change shortly.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Right now it seems that life is all about competition for you.This can make you a bit overzealous and chaotic. There's no repose in your mind and heart, no serenity. That is why it's important for you to calm down, take some rest, look around you and smell the roses. Lay back and enjoy the true infinite power of the universe. Ask yourself if you really know what you want. Do you talk about your goals to others, do you listen to their opinions? Or do you prefer to hold on to your own opinions?</p>",
                    }
                },
                fr : {
                    name : "Le Cinq de Bâton",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "propre volonté",    
                    description : " Le Cinq de Bâtons, nous parle d’apprendre à communiquer sur ce que nous voulons et que nous pouvons apprendre à échanger des idées à ce sujet avec d'autres.",
                    questions : ["Savez-vous déjà ce que vous voulez réellement?", "En parlez-vous également avec d'autres?", "Savez-vous également écouter les autres?", "Voulez-vous pousser votre propre volonté?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte dit qu'à ce moment de votre vie, il est très important que vous croyiez vraiment en vous-même et en vos capacités. Faites preuve de conviction lorsque vous communiquez cette croyance. Le \"Cinq de Bâton\" est synonyme de compétition, éventuellement une compétition ou un combat ludique sous quelque forme que ce soit, qu'elle soit privée ou professionnelle. Le défi auquel vous êtes confronté concerne avant tout l'épanouissement personnel, la croissance personnelle et le développement personnel. Bien que cette situation ne soit en aucun cas destructeur, préparez-vous à rencontrer de la résistance et soyez honnête en tout temps.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Five of Wands often is all about choosing and communicating your choice. Maybe there is more than one person romantically interested in you.It will be up to you to choose the best candidate. Or maybe you really like someone only to find stiff competition. In that case you will have to stand your ground, prove yourself and your feelings. Be sure you didn't leave any road unexplored.</p><p>Even though this is a positive card, it's notabout romance and candlelight dinners. It's about competition, battle. In this case the battle may be necessary to bring you and your (new) partner to further heights in your relationship.</p>",
                        [GuidanceFields.WORK] : "<p>You are facing a challenge right now and it won't be easy to meet it. Still, if you carefully choose your path and tactics they will surely lead you to success. Look at this challenge as if it were a test, an exercise, not something people will judge you by. Also, sometimes it pays to listen to other’s opinions, even if you have already made your decision.</p><p>Financially you may have some problems for the moment, but don't worry too much, your situation will change shortly.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Right now it seems that life is all about competition for you.This can make you a bit overzealous and chaotic. There's no repose in your mind and heart, no serenity. That is why it's important for you to calm down, take some rest, look around you and smell the roses. Lay back and enjoy the true infinite power of the universe. Ask yourself if you really know what you want. Do you talk about your goals to others, do you listen to their opinions? Or do you prefer to hold on to your own opinions?</p>",
                    }
                },
                sp:{
                    name:"El Cinco de Bastos",
                    suit: "bastos",
                    element : "🜂 – fuego",
                    theme: "voluntad propia",    
                    description: "El Cinco de Bastos es sobre aprender a comunicar lo que quieres y nos señala que podemos aprender a intercambiar ideas sobre esto con otros",
                    questions: ["¿Ya sabes lo que quieres?", " ¿También hablas con otros sobre esto?", "¿Puedes escuchar a  otros también?", "¿Te gustaría empujar tu propia voluntad?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta carta le dice que en este momento en su vida, es muy importante que usted cree realmente en su mismo y sus habilidades. Cinco de Varitas es también acerca de la competencia, posiblemente un concurso o batalla lúdica de algún tipo, ya sea privada o el trabajo relacionado. Y el desafío que conoce es todo acerca de la promoción de su mismo, creciendo, evolucionando. Aunque la situación no es de ninguna manera destructiva, está listo para encontrarse con cierta resistencia y asegúrese de mantenerse limpio en todo momento.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Vijf",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "eigen willen",    
                    description : "De vijf van staven gaat over leren communiceren over wat je wilt en wijst ons op het kunnen leren om hierover van gedachten te wisselen met anderen.",
                    questions : [ "Weet jij al wat je werkelijk wilt?", "Praat jij daar ook over met anderen?", "Kun je ook naar anderen luisteren?", "Wil jij graag je eigen wil doordrukken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart vertelt je dat het op dit moment in je leven erg belangrijk is dat je oprecht gelooft in jezelf en je mogelijkheden. Toon jouw geloof ook duidelijk aan anderen, gelijk waarover je het hebt.</p><p>Staven Vijf is ook symbolisch voor concurrentie, het kan zelfs om een echte wedstrijd gaan of over een speelse soort competitie, in je privéleven of op het werk. De ware uitdaging ligt niet in het beter zijn dan anderen maar in het beter worden dan je nu bent, je eigen groei, je evolutie. En ook al gaat het hier niet om een negatieve soort van wedijver, bereid je er toch op voor dat je tegenstand kan krijgen. En let erop dat je te allen tijde eerlijk en rechtvaardig blijft.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Bij de Vijf van Staven gaat het vaak over kiezen en het kenbaar maken van je keuze. Het is best mogelijk dat er op dit moment meer dan één persoon geïnteresseerd is in jou als potentiële partner. De keuze ligt nu in jouw handen.</p><p>Het kan ook zijn dat je diepe gevoelens hebt voor iemand maar dat er nog andere geïnteresseerden zijn, je hebt concurrentie. Of misschien is je partner onder de indruk van iemand anders, is er twijfel in zijn/haar hart. Als dit het geval is, dan is het belangrijk dat je vastberaden blijft, wees duidelijk over wie je bent en wat je voor de ander voelt, ga ervoor. Zorg ervoor dat je alle mogelijke kansen grijpt en uitbuit. Staven Vijf is weliswaar een positieve kaart maar dat betekent niet dat het hier enkel om romantische gebaren gaat zoals etentjes bij kaarslicht. Je hebt hier te maken met concurrentie, competitie. Je zal strijd moeten leveren om het volgende stadium van je relatie te bereiken.</p>",
                        [GuidanceFields.WORK] : "<p>Op je werk word je geconfronteerd met een uitdaging en het wordt niet gemakkelijk om er een succes van te maken. Zorg ervoor dat je grondig nadenkt over de weg en de tactieken die je wil gaan gebruiken en je zal merken dat je toch je doel kan bereiken. Bekijk deze uitdaging alsof het een wedstrijd is of een spelletje, iets wat je voor jezelf doet. Kijk er niet naar alsof het iets is waarover andere mensen je zullen beoordelen of veroordelen. Luister wel even naar wat anderen erover te zeggen hebben. Ook al heb je al beslist welke weg je zal volgen, je kan altijd handige tips gebruiken.</p><p>Deze kaart kan wijzen op tijdelijke financiële spanningen. Maak je hier niet te druk om, je situatie zal binnenkort weer veranderen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het lijkt erop alsof alles in je leven om competitie draait op dit moment. Misschien word je daar overijverig van, soms zelfs een beetje chaotisch omdat je niet weet waar eerst aan te beginnen. Je hebt geen rust in je hoofd en je hart, geen evenwicht. Daarom is het nu erg belangrijk dat je uit alle macht probeert om tot rust te komen, wat te ontspannen. Kijk eens kalm en traag om je heen en probeer te genieten van wat je ziet. Wees eens gewoon lui en laat de oneindige kracht en macht van het universum op je inwerken. En vraag je dan af of je eigenlijk wel weet wat je wil. Praat je met anderen over de doelen die je wil bereiken? Luister je ook naar hun mening? Of vind je het op dit moment erg belangrijk om vast te houden aan je eigen opinies? Spiritueel groeien is geen wedstrijd, we zijn immers allemaal uniek en hebben elk een unieke spirituele weg te gaan.</p>",
                    }
                },
                de : {
                    name: "Fünf der Stäbe",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "Eigener Wille",    
                    description : "Die Fünf der Stäbe spricht darüber zu lernen, wie wir kommunizieren, was wir wollen und dass wir lernen können, Ideen mit anderen auszutauschen.",
                    questions : [ "Wissen Sie schon, was Sie wirklich wollen?", "Sprechen Sie auch mit anderen darüber?", "Können Sie auch auf andere hören?", "Wollen Sie Ihren eigenen Willen durchsetzen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte sagt, dass es in diesem Moment in Ihrem Leben sehr wichtig ist, dass Sie wirklich an sich selbst und an Ihre Fähigkeiten glauben. Zeigen Sie Überzeugung, wenn Sie diesen Glauben kommunizieren. Die „Fünf der Stäbe“ steht für Wettbewerb, möglicherweise einen Wettbewerb oder einen spielerischen Kampf in irgendeiner Form, sei es privat oder beruflich. Die Herausforderung, die auf Sie zukommt, hat vor allem mit Selbstförderung zu tun, mit Selbstwachstum und Selbstentwicklung. Auch wenn diese Situation in keiner Weise zerstörerisch ist bereiten Sie sich darauf vor, auf Widerstand zu stoßen und achten Sie darauf, zu allen Zeiten ehrlich zu bleiben.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Bei der fünf der Stäbe geht es oft um die Auswahl und die Kommunikation Ihrer Wahl. Vielleicht gibt es mehr als ein romantischer Person, der an Ihnen interessiert ist. Es ist an Ihnen den besten Kandidaten zu wählen. Oder vielleicht suchen Sie wirklich jemanden nur um starke Konkurrenz zu finden. In diesem Fall müssen Sie stand halten und sich selber und Ihre Gefühle beweisen. Seien Sie sicher, dass Sie keinen Weg unerforscht gelassen haben.</p><p>Auch wenn, dies eine positive Karte ist handelt es sich nicht um Romantik und Abendessen bei Kerzenlicht. Es geht um Wettbewerb, Kampf. In diesem Fall kann der Kampf notwendig sein, um Sie und Ihren (neuen) Partner auf weitere Höhen in Ihrer Beziehung zu bringen.</p>",
                        [GuidanceFields.WORK] : "<p>Gerade jetzt stehen Sie vor einer Herausforderung, und es wird nicht leicht, sie zu erfüllen. Dennoch, wenn Sie sorgfältig Ihren Weg auswählen wert diese Sie sicherlich zum Erfolg führen. Betrachten Sie diese Herausforderung als einen Test, eine Übung, nicht etwas, worauf Sie beurteilt werden. Auch, manchmal lohnt es sich, auf die Meinung anderer zu hören, auch wenn Sie bereits Ihre Entscheidung getroffen haben.</p><p>Finanziell können Sie einige Probleme begegnen, aber nicht zu viel Sorgen, Ihre Situation wird sich in Kürze ändern.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Im Moment scheint es, dass das Leben für Sie einen Wettbewerb ist. Das kann Sie ein bisschen übereifrig und chaotisch machen. Es gibt keine Ruhe in Ihrem Herz und Verstand, keine Gelassenheit. Deshalb ist es wichtig für Sie, sich zu beruhigen, nehmen Sie sich etwas Ruhe, schauen Sie Sich um und atme den Duft der Rosen. Lehnen Sie sich zurück und genießen Sie die wahre unendliche Macht des Universums. Fragen Sie sich, ob Sie wirklich wissen, was Sie wollen. Haben Sie über Ihre Ziele mit anderen gesprochen, haben Sie ihre Meinung gehört? Oder setzen Sie lieber auf Ihren eigenen Meinung?</p>",
                    }
                }
            },
            {
                filename: "27",
                numerology : 6,
                en : {
                    name: "Six of Wands",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "going for what you want",    
                    description : "Six of Wands talks about making your choice and showing it.",
                    questions : [ "What do you truly want?", "Can you truly make that choice?", "Do you show it?", "Do you make your own choices or do you follow \"the herd\"?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Getting the Six of Wands it's a very positive sign. This card is about success, fame, recognition, the satisfaction you experience from a job well done. It's about overcoming difficulties and challenges and finally coming out on top. It's also about evolving, moving, positive changes are about to come be it in business or in your private life. If you're open to it, positive things will surely come your way.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Six of Wands depicts success, it symbolizes finally reaping what you have been sowing. This means you may well be on your way towards a beautiful loving relationship, possibly with someone you've had your eye on for a while.</p><p>It may also signify a highlight in your current relationship, you feel closer to your partner than you have ever done before. It is also possible that you succeeded in solving a deep rooted relational problem you had. Now you are finally lying on a bed of roses together. </p>",
                        [GuidanceFields.WORK] : "<p>If you're in a job you really want to hang on to, you will soon notice that generally your situation is getting way better than you ever expected. You will harvest success, </p><p>appreciation and acknowledgment. Maybe you have been working extremely hard in order to achieve all this, but right now you are getting truly and abundantly rewarded.</p><p>If you are currently looking for a job, this card tells you that the position you will find will be way better than you ever expected.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Where spirituality is concerned this card tells you that you are on the right way to experience an intense love of life. Make contact with that intense love inside you and freely share it with everyone around you. You have a lot to give right now.</p><p>Now may also be a good time to ask yourself which road you really want to take, and if you have the freedom to go for it. Do you feel strong enough to march to your own drum?</p>",
                    }
                },
                fr : {
                    name : "Le Six de Bâton",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "choisir ce que vous voulez",    
                    description : " Le Six de Bâtons nous parle de choisir ce que l’on veut et qu'il faut également le montrer.",
                    questions : ["Que voulez-vous vraiment?", "Pouvez-vous vraiment choisir cela?", "Montrez-vous cela?", "Suivez-vous votre propre choix ou êtes-vous un \"larbin\"?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Si vous obtenez le « Six de Bâton », c'est un signe très positif. Cette carte est synonyme de succès, de gloire et de reconnaissance. Il symbolise la satisfaction que vous éprouvez lorsque vous avez bien fait une tâche. Il signifie surmonter les difficultés et les défis, après quoi vous atteignez enfin le sommet. Elle est aussi synonyme de développement et de mouvement. Des changements favorables s'opèrent chez vous, que ce soit dans votre travail ou dans votre vie privée. Si vous vous y ouvrez, vous ferez certainement l'expérience de choses positives. Cette carte vous montre que la confiance en vos propres croyances est toujours payante à long terme, et une fois reçue, assurez-vous de vous offrir quelque chose. Vous le méritez !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Six of Wands depicts success, it symbolizes finally reaping what you have been sowing. This means you may well be on your way towards a beautiful loving relationship, possibly with someone you've had your eye on for a while.</p><p>It may also signify a highlight in your current relationship, you feel closer to your partner than you have ever done before. It is also possible that you succeeded in solving a deep rooted relational problem you had. Now you are finally lying on a bed of roses together. </p>",
                        [GuidanceFields.WORK] : "<p>If you're in a job you really want to hang on to, you will soon notice that generally your situation is getting way better than you ever expected. You will harvest success, </p><p>appreciation and acknowledgment. Maybe you have been working extremely hard in order to achieve all this, but right now you are getting truly and abundantly rewarded.</p><p>If you are currently looking for a job, this card tells you that the position you will find will be way better than you ever expected.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Where spirituality is concerned this card tells you that you are on the right way to experience an intense love of life. Make contact with that intense love inside you and freely share it with everyone around you. You have a lot to give right now.</p><p>Now may also be a good time to ask yourself which road you really want to take, and if you have the freedom to go for it. Do you feel strong enough to march to your own drum?</p>",
                    }
                },
                sp:{
                    name:"El Seis de Bastos",
                    suit: "bastos",
                    element: "🜂 –fuego",
                    theme: "elige lo que quieras",    
                    description: "El Seis de Bastos se trata de elegir lo que quieres y nos recuerda que nosotros también tenemos que mostrarlo",
                    questions: ["¿Qué es lo que realmente quieres?", "¿Puedes elegir eso?", "¿Lo demuestras?", "¿Sigues tu propia elección o eres un \"seguidor\"?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Conseguir el Seis de Varitas es una señal muy positiva. Esta tarjeta es sobre el éxito, la fama, el reconocimiento, la satisfacción que experimenta de un trabajo bien hecho. Se trata de superar las dificultades y retos, y finalmente llegando a la cima. También se trata de evolución, movimiento, cambios positivos están a punto de llegar ya sea en los negocios o en su vida privada. Si usted está abierto a él, las cosas positivas que seguramente llegarán a usted.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Zes",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "kiezen voor wat je wilt",    
                    description : "De zes van staven gaat over kiezen voor wat je wilt en wijst ons er op dat we dat tevens dienen te laten zien.",
                    questions : [ "Wat wil jij echt?", "Kun jij daar daadwerkelijk voor kiezen?", "Laat je dat ook zien?", "Volg jij je eigen keuze of ben je een \"meeloper\"?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Als je de Zes van Staven trekt dan is dit een heel positief teken. Deze kaart duidt op succes, erkenning en welslagen. Ze symboliseert de tevredenheid die je ervaart als je iets tot een goed einde gebracht hebt. Ze vertelt je dat je moeilijkheden, obstakels en uitdagingen op je weg gekregen hebt en dat je uiteindelijk de top bereikt hebt.</p><p>Deze kaart gaat ook over groeien, evolueren. Ze vertelt je dat je positieve gebeurtenissen mag verwachten, privé of wat je werk betreft. Als je jezelf er bewust voor openstelt dan zullen er aangename veranderingen jouw kant op komen.</p><p>Tenslotte, als je in jezelf en je overtuigingen blijft geloven, dan zal je uiteindelijk succes hebben. En op het moment dat je dat succes ook echt beleeft, is het belangrijk dat je jezelf eens goed in de bloemetjes zet, je hebt het verdiend!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Zes van Staven symboliseert welslagen en eindelijk oogsten wat je gezaaid hebt. Op relationeel vlak betekent dit dat je goed op weg bent om een mooie diepgaande liefdesrelatie te krijgen, mogelijk met iemand die je al een tijdje op het oog hebt. Als je al een lange periode moeite gedaan hebt voor iemand, geef het dan nu niet op want succes is nabij!</p><p>Als je al een relatie hebt, dan komt er binnenkort een intens positieve periode waarin je je dichter bij je partner voelt dan je ooit ervaren hebt. Het is ook mogelijk dat jullie eindelijk dat moeilijke probleem of die langdurige onenigheid opgelost hebben. In elk geval is nu de tijd gekomen om te genieten van wat de liefde en jouw volhouden je gebracht heeft, neem je tijd om van dit succes te proeven!</p>",
                        [GuidanceFields.WORK] : "<p>Als je op dit moment een baan hebt die je absoluut wil houden dan zal je binnenkort merken dat je positie een stuk beter geworden is dan je zelf dacht. Alle energie en inspanningen die je geïnvesteerd hebt in je baan blijken nu succesvol. Je krijgt erkenning en waardering van alle kanten. Misschien heb je ontzettend hard moeten werken om dit punt te bereiken, misschien werd het je soms teveel en dacht je het niet vol te kunnen houden. Maar je wordt er nu ook uitbundig en intens voor beloond, geniet ervan!</p><p>Als je op dit moment op zoek bent naar een baan dan wijst deze kaart erop dat je een job zal vinden die een stuk fijner en passender voor je is dan je ooit had kunnen denken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op het gebied van spiritualiteit vertelt deze kaart je dat je op de goede weg bent om binnenkort een intense liefde voor het leven en de wereld te ervaren. Erken deze diepe liefde, maak er contact mee en deel ze met iedereen om je heen. Je hebt op dit moment heel veel te bieden.</p><p>Neem nu ook eens de tijd om na te denken over de weg die je diep vanbinnen wil gaan kiezen. Voel je je vrij en sterk genoeg om helemaal op jezelf te vertrouwen? Ben je er klaar voor om jouw eigen unieke pad te gaan volgen?</p>",
                    }
                },
                de : {
                    name: "Sechs der Stäbe",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "Wählen Sie, was Sie wollen",    
                    description : "Die Sechs der Stäbe sagt uns zu wählen, was wir wollen und dass wir es auch zeigen sollen.",
                    questions : [ " Was wollen Sie wirklich?", "Können Sie das wirklich wählen?", "Zeigen Sie sich das?", "Folgen Sie Ihrer  eigenen Wahl oder sind Sie ein \"Handlanger\"?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Ziehen Sie die „Sechs der Stäbe“, dann ist das ein sehr positives Zeichen. Diese Karte steht für Erfolg, Ruhm und Anerkennung. Sie symbolisiert die Zufriedenheit, die Sie erfahren, wenn Sie eine Aufgabe gut erledigt haben. Sie steht für die Überwindung von Schwierigkeiten und Herausforderungen, nach der Sie schließlich an die Spitze kommen. Sie steht auch für Entwicklung und Bewegung. Günstige Veränderungen kommen auf Sie zu, entweder im Beruf oder in Ihrem Privatleben. Wenn Sie Sich dafür öffnen werden Sie sicher Positives erleben. Diese Karte zeigt Ihnen, dass sich der Glaube an Ihre eigenen Überzeugungen langfristig immer auszahlt. Und sobald Sie empfangen haben stellen Sie sicher, dass Sie sich selbst etwas gönnen. Sie haben es verdient!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Die Sechs der Stäbe deutet auf Erfolg symbolisiert schließlich ernten, was Sie gesät haben. Das heißt, Sie könnten auf dem Weg zu einer schönen liebevollen Beziehung sein, möglicherweise zu jemandem auf dem Sie gerade das Auge fallen gelassen haben.</p><p>Es kann auch deuten auf einen Höhepunkt in der aktuellen Beziehung. Sie fühlen Sie Sich näher zu Ihrem Partner als jemals zuvor. Es ist auch möglich, dass Sie gerade ein tief verwurzelten rationellen Problem gelöst haben. Jetzt können Sie endlich zusammen auf einem Bett aus Rosen liegen.</p>",
                        [GuidanceFields.WORK] : "<p>Wenn Sie wirklich an Ihrem Job hängen, werden Sie schnell bemerken, dass in der Regel Ihre Situation immer besser wird, wie nie erwartet. Sie werden Erfolg ernten, Wertschätzung und Anerkennung. Vielleicht haben Sie sehr hart gearbeitet, um all dies zu erreichen, aber im Moment werden Sie wirklich und reichlich belohnt.</p><p>Wenn Sie derzeit auf der Suche nach einem Job sind, sagt diese Karte Ihnen, dass die Position, die Sie finden werden viel besser sein wird als Sie jemals erwarte haben.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Wo es die Spiritualität anbelangt, sagt diese Karte Ihnen, dass Sie auf dem richtigen Weg sind um eine intensive Liebe des Lebens zu erfahren. Nehmen Sie Kontakt auf mit dieser intensiven Liebe in Sie, befreie sie und teilen Sie sie mit jeder um Sie. Sie haben eine Menge zu geben, gerade jetzt.</p><p>Jetzt kann auch eine gute Zeit sein, sich zu fragen, welchen Weg Sie wirklich nehmen müssen und ob Sie die Freiheit haben, diesen zu gehen. Fühlen Sie sich stark genug, um zum Schlag Ihrer eigenen Trommeln zu marschieren?</p>",
                    }
                }
            },
            {
                filename: "28",
                numerology : 7,
                en : {
                    name: "Seven of Wands",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "going on with what you want",    
                    description : "Seven of Wands talks about going on with what you want and the possible battle with invisible obstacles.",
                    questions : [ "Can you be opinionated?", "Do you feel the need to defend yourself?", "Against what? Against who? Why?", "Is there a need for being that headstrong?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Seven of Wands points out that you possibly have an adversary and it feels as if she/he is stronger and bigger than you are. Maybe the adversary is really someone else, maybe he/she is hidden within you. Although your enemy seems stronger, it is important that you stand your ground, don't give up, your fighting spirit will take you very far, quite possibly towards victory. Now is the time not to succumb to selfdoubt, his isn't only about power, it's also about the strength of forces like love, wisdom, intelligence. </p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that your relationship may be going through a rough patch. Possibly there's a third person involved, be it on your side or your partner's. Talk to your partner, be open and loving about how you feel and what you think, this is the only way to solve any issues you may have. It's also possible that you yourself are having a negative and disturbing influence on other relationships. Ask yourself if maybe you are being too defensive. Is it possible you are seeing problems where there aren’t any?</p><p>If you are currently single, you may have to look for love in new and different places, your present \"hunting grounds\" aren't interesting for you anymore.</p>",
                        [GuidanceFields.WORK] : "<p>This card tells you that right now you are in a difficult situation workwise, there's a lot of competition. Maybe there's a colleague who's interested in your job, maybe it's someone further away, or maybe you only suspect that your job is threatened and you feel the need to protect your position before you are actually being ‘attacked’. Question yourself, ask yourself what's really going on and once you have a clear picture, treat the situation with integrity.</p><p>Have you ever dreamed about being self-employed? Now is the time to really consider taking the step towards independence. Just remember it will take a lot of time and energy to make your dream come true.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Seven of Wands symbolizes that you may be experiencing quite some anxieties, and they could be holding you back. If you want to grow stronger and be ready to take on any challenge you might be facing, now is the time to face your fears and turn them into courage. Without fear, courage wouldn't exist. Your anxiety doesn't need to control you, so if there is a possibility right in front of you, let your fears go and replace them with the courage hidden underneath. Put aside your doubts and hesitations and go for whatever you've been dreaming of.</p><p>Possibly people will ask you for spiritual advice. Don't hesitate to give it to them, you are very clear minded right now.</p>",
                    }
                },
                fr : {
                    name : "Le Sept de Bâton",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "suivre votre choix",    
                    description : " Le Sept de Bâtons nous parle de continuer ce que l’on a choisi et de la lutte contre les obstacles invisibles.",
                    questions : ["Etes-vous entêté de suivre votre propre voie?", "Avez-vous l'impression de devoir vous défendre?", "Contre quoi? Contre qui? Pourquoi?", "Avez-vous besoin d'une attitude défensive?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Sept de Bâton\" indique que vous avez peut-être un ennemi et que vous avez l'impression qu'il ou elle est plus fort et plus grand que vous. Peut-être que votre adversaire est vraiment quelqu'un d'autre, mais peut-être qu'il ou elle est caché en vous. Même si votre ennemi (intérieur) semble plus fort, il est important que vous restiez au sol, n'abandonnez pas. Votre esprit combatif vous mènera très loin dans la bonne direction, très probablement jusqu'à la victoire. Ce n'est pas le moment de douter de vous-même, qui est votre véritable ennemi intérieur. Il ne s'agit pas seulement de pouvoir et de contrôle, mais aussi de pouvoir d'amour, d'intelligence et de sagesse.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that your relationship may be going through a rough patch. Possibly there's a third person involved, be it on your side or your partner's. Talk to your partner, be open and loving about how you feel and what you think, this is the only way to solve any issues you may have. It's also possible that you yourself are having a negative and disturbing influence on other relationships. Ask yourself if maybe you are being too defensive. Is it possible you are seeing problems where there aren’t any?</p><p>If you are currently single, you may have to look for love in new and different places, your present \"hunting grounds\" aren't interesting for you anymore.</p>",
                        [GuidanceFields.WORK] : "<p>This card tells you that right now you are in a difficult situation workwise, there's a lot of competition. Maybe there's a colleague who's interested in your job, maybe it's someone further away, or maybe you only suspect that your job is threatened and you feel the need to protect your position before you are actually being ‘attacked’. Question yourself, ask yourself what's really going on and once you have a clear picture, treat the situation with integrity.</p><p>Have you ever dreamed about being self-employed? Now is the time to really consider taking the step towards independence. Just remember it will take a lot of time and energy to make your dream come true.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Seven of Wands symbolizes that you may be experiencing quite some anxieties, and they could be holding you back. If you want to grow stronger and be ready to take on any challenge you might be facing, now is the time to face your fears and turn them into courage. Without fear, courage wouldn't exist. Your anxiety doesn't need to control you, so if there is a possibility right in front of you, let your fears go and replace them with the courage hidden underneath. Put aside your doubts and hesitations and go for whatever you've been dreaming of.</p><p>Possibly people will ask you for spiritual advice. Don't hesitate to give it to them, you are very clear minded right now.</p>",
                    }
                },
                sp:{
                    name:"El Siete de Bastos",
                    suit: "bastos",
                    element: "🜂 – fuego",
                    theme: "continúa con lo que quieras",    
                    description: "El Siete de Bastos se trata de continuar con lo que quieres y nos indica nuestra posible batalla contra obstáculos invisibles",
                    questions: ["¿Puedes seguir tu propio camino?", "¿Sientes que tienes que defenderte", "¿Contra qué? ¿Contra quién?", "¿Por qué?", "¿Necesitas una actitud defensiva?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Siete de Varitas señala que posiblemente tiene un adversario y se siente como si él / ella es más fuerte y más grande que usted. Tal vez el adversario es realmente otra persona, tal vez él / ella está escondida dentro de usted. Aunque su enemigo parece más fuerte, es importante que usted mantiene en su posición, no se rinde, su espíritu de lucha le llevará muy lejos, muy posiblemente hacia la victoria. Ahora es el momento de no sucumbir a la libre duda, la suya es no sólo sobre el poder, se trata también de la fortaleza de las fuerzas como el amor, la sabiduría, la inteligencia.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Zeven",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "verder gaan met wat je wilt",    
                    description : "De zeven van staven gaat over verder gaan met wat je wilt en wijst ons op onze mogelijke strijd tegen onzichtbare obstakels.",
                    questions : [ "Kun jij je eigenzinnige weg gaan?", "Heb jij het gevoel dat jij je dient te verdedigen?", "Tegen wat? Tegen wie? Waarom?", "Is een verdedigende houding wel nodig?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Zeven van Staven wijst je erop dat je misschien een tegenstander hebt en dat je het gevoel kan hebben dat die tegenstander sterker en groter is dan jij bent. Het is mogelijk dat die vijand effectief iemand in je omgeving is maar het kan ook dat hij eigenlijk diep in jezelf verborgen zit, dat je je eigen grootste vijand bent op dit moment. En ook al lijkt die (innerlijke) vijand sterker dan jij, geef het toch niet op, verdedig jezelf. Je strijdlustige geest zal je helpen om ver te komen, mogelijk behaal je zelfs een overwinning. Nu is niet het moment om je te laten beïnvloeden door twijfel aan jezelf, want dat is namelijk je grootste innerlijke vijand, trap er niet in! Het gaat hier niet enkel om macht en controle, maar vooral over de kracht van liefde, inzicht en wijsheid.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt je dat het mogelijk is dat je een moeilijke periode doormaakt in je relatie. Misschien is er zelfs een derde persoon bij betrokken, aan jouw kant of bij je partner. Praat met je geliefde, vertel hem/haar open en liefdevol wat je voelt en denkt. Dit is de enige manier om de problemen die jullie hebben op te lossen. Vraag je ook even af of je misschien wat teveel in de verdediging zit. Zou het kunnen dat je op dit moment problemen ziet die er eigenlijk niet zijn?</p><p>Als je single bent is het nu het goede moment om op zoek te gaan naar liefde op nieuwe en ongewone plekken. Je huidige “jachtgebied” wordt namelijk alsmaar minder interessant.</p>",
                        [GuidanceFields.WORK] : "<p>Deze kaart geeft aan dat je op het ogenblik mogelijk in een moeilijke situatie zit op het werk die ook te maken heeft met concurrentie. Misschien is er een collega die een oogje heeft op jouw functie, misschien gaat het om iemand die wat verder van je af staat. Het kan ook zijn dat je enkel vermoedt dat je job in gevaar is en dat je het nodig vindt om jezelf te beschermen nog voordat je ook echt “aangevallen” wordt. Het is belangrijk dat je jezelf nu een stuk in vraag stelt, vraag je af wat er echt gaande is. En eens je een duidelijk en realistisch beeld hebt, pak het probleem dan aan in alle eerlijkheid en integriteit.</p><p>Droom je er soms van om zelfstandig te zijn? Dan is het nu de juiste tijd om echt te overwegen die stap naar onafhankelijkheid te zetten. Denk er wel aan dat je een massa energie en tijd zal moeten investeren om je droom waar te maken. Ben je daartoe bereid, kan je dat aan?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Volgens deze kaart hebt je op dit moment mogelijk last van nogal wat angsten die je belemmeren in je dagelijkse functioneren. De tijd is gekomen om de confrontatie met je angsten aan te gaan en ze te transformeren in moed. Je zal hier sterker door worden en elke uitdaging aankunnen die op je weg komt. Bedenk dat zonder angst het begrip moed niet zou bestaan. Toch is het belangrijk dat angst je niet overheerst.</p><p>Als er een probleem op je afkomt, laat dan de angst los en grijp de moed die vlak daaronder verborgen zit. Zet je twijfels en onzekerheden opzij en doe net datgene waar je enkel van durfde te dromen.</p><p>Deze kaart zegt ook dat het mogelijk is dat mensen je om spiritueel advies vragen. Aangezien je geest op dit moment helder en scherp is, kan je hen vrijelijk laten delen in je wijsheid.</p>",
                    }
                },
                de : {
                    name: "Sieben der Stäbe",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "Folgen Sie Ihrer Wahl",    
                    description : "Die Sieben der Stäbe spricht darüber, fortzusetzen, was wir gewählt haben, und gegen unsichtbare Hindernisse zu kämpfen.",
                    questions : [ "Sind Sie hartnäckig darin, Ihren eigenen Weg zu gehen?", "Haben Sie das Gefühl, dass Sie sich verteidigen müssen?", "Gegen was? Gegen wen? Warum?", "Brauchen Sie eine defensive Haltung?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Sieben der Stäbe“ weist darauf hin, dass Sie möglicherweise einen Feind haben; es fühlt sich so an, als ob sie oder er stärker und größer ist als Sie. Vielleicht ist Ihr Gegner wirklich jemand anderer, vielleicht aber ist er oder sie in Ihnen selbst versteckt. Auch wenn Ihr (innerer) Feind stärker erscheint, ist es wichtig, dass Sie auf dem Boden bleiben, geben Sie nicht auf. Ihr Kampfgeist wird Sie sehr weit in die richtige Richtung bringen, sehr wahrscheinlich sogar zum Sieg. Jetzt ist nicht die Zeit für Selbstzweifel, der Ihr wahrer innerer Feind ist. Es geht nicht nur um Macht und Kontrolle, sondern auch um die Kraft der Liebe, um Intelligenz und Weisheit.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte sagt Ihnen, dass Ihre Beziehung jetzt durch eine schwierige Phase gehen kann. Möglicherweise ist eine dritte Person mit rein bezogen, sei es auf Ihrer Seite oder Ihres Partners. Sprechen Sie mit Ihrem Partner, offen und liebevoll, wie Sie sich fühlen und was Sie denken. Dies ist der einzige Weg, um alle Probleme die Sie haben könnten, zu lösen. Es ist auch möglich, dass Sie es selbst sind die einen negativen und störenden Einfluss auf andere Beziehungen hat. Fragen Sie sich, ob Sie vielleicht zu defensiv sind. Ist es möglich, dass Sie Probleme sehen, wo es keine gibt?</p><p>Wenn Sie noch alleine sind, müssen Sie möglicherweise für die Liebe in neuen und anderen Stellen und Orten suchen, Ihre aktuelle \"Jagdgründe\" sind für Sie nicht mehr interessant.</p>",
                        [GuidanceFields.WORK] : "<p>Diese Karte sagt, dass Sie jetzt in einer schwierigen Situation in Ihrer Arbeit sind. Es gibt eine Menge Konkurrenz. Vielleicht gibt es einen Kollegen, der sich für Ihre Arbeit interessiert, ist es vielleicht jemand der nicht in direkter Nähe ist, oder vielleicht vermuten Sie nur, dass Ihre Arbeit gefährdet ist und haben Sie das Bedürfnis, Ihre Position zu schützen bevor Sie tatsächlich 'angegriffen' werden. Fragen Sie sich, was wirklich vor sich geht, und wenn Sie ein klares Bild haben behandeln Sie die Situation dann mit Integrität.</p><p>Haben Sie jemals von Selbständigkeit geträumt? Jetzt ist die Zeit um wirklich in Betracht ziehen, den Schritt in die Selbständigkeit zu machen. Denken Sie daran, es wird eine Menge Zeit und Energie kosten um Ihren Traum zu verwirklichen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Sieben der Stäbe symbolisiert, dass Sie möglicherweise viel Ängste haben, die Sie zurück halten können. Wenn Sie bereit sind stärker zu werden und auf jeder Herausforderung mit der Sie konfrontiert werden, einzugehen, ist jetzt die Zeit, um Ihre Ängste zu erkennen und sie in Mut umzuwandeln. Ohne Angst wäre keinen Mut. Ihre Angst muss Sie nicht beherrschen und führen und wenn es eine Möglichkeit gibt direkt vor Ihre Nase, dann lassen Sie Ihre Ängste gehen und ersetzen Sie sie mit dem Mut der drinnen verborgen liegt. Legen Sie Ihre Zweifel und Bedenken beiseite und gehen Sie für das, was Sie geträumt haben.</p><p>Möglicherweise werden manche Leute Sie um spirituellen Rat fragen. Zögern Sie nicht, es ihnen zu geben, Sie haben jetzt einen ganz klaren Geist.</p>",
                    }
                }
            },
            {
                filename: "29",
                numerology : 8,
                en : {
                    name: "Eight of Wands",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "taking responsibility for what you want",    
                    description : "Eight of Wands talks about taking responsibility for what you want and to take action whenever we want something.",
                    questions : [ "What do you want to achieve?", "Have you taken action to achieve it?", "Have you set things in motion?", "Do you patiently need to wait for a reaction to your action?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Out of all 78 Tarot cards The Eight of Wands is the only card that indicates something is about to happen very soon if not right now. If you're fed up with waiting, frustrated and tired, hold on just a little bit longer, change is about to come your way shortly.</p><p>You have done everything necessary to set the wheels in motion, now you only have to wait for your actions to take effect. The deeper sense is that a thing is going on in your life that has a thoroughly positive influence on you.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you're in a relationship and you feel totally ready for the next step, it is possible that your partner doesn't feel the same way. She/he might need some more time. Don't get impatient, love and commitment can't be rushed. Your partner will take the next step when ready.</p><p>If you are looking for a partner it is possible that someone you are in love with doesn't look at you the same way. Give him/her the time to get to know you and his/her feelings will surely grow.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are walking around with some questions. The Eight of Wands lets you know that your answers will be coming to you shortly. Because it's someone else who has to give you those answers, there is nothing for you to do but wait and train your patience, especially if it’s not your strong suit.</p><p>This card sometimes also indicates that a change you've been expecting for quite some time now, will soon take effect.</p><p>No matter what, the result will very likely be positive for you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In these demanding and hectic times almost everyone is struggling with the concept of patience now and then. It's not an easy thing to acquire or to systematically incorporate into your life. Now is a good time to turn to your true inner self and find quiet and serenity, in order to achieve true patience. Use any means necessary, be it meditation, affirmation, projection or a mix of all three. Maybe you will not be successful the first time you try, but don't give up. It is very important for you to find true patience within yourself right now.</p>",
                    }
                },
                fr : {
                    name : "Le Huit de Bâton",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "prendre la responsabilité de ce que l'on veut",    
                    description : " Le Huit de Bâtons nous parle de prendre la responsabilité pour ce que l’on veut et qu’il faut agir si l’on veut quelque chose.",
                    questions : ["Que voulez-vous réaliser?", "Avez-vous déjà pris des mesures pour y parvenir?", "Avez-vous mis quelque chose en marche?", "Devriez-vous attendre patiemment la réaction à votre action?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Des 78 cartes de Tarot, le \"Huit de Bâton\" est la seule carte qui indique que quelque chose va se passer très bientôt, si ce n'est pas maintenant. Si vous en avez assez d'attendre, d'être frustré et fatigué, tenez bon encore un peu. Le changement est en marche et vous le rencontrerez bientôt.</p><p>Vous avez fait tout ce qui était nécessaire pour mettre les roues en marche. Il ne vous reste plus qu'à attendre les effets. Lorsque vous vous posez la question, vous constaterez probablement que vous avez fait tout ce qui était nécessaire.</p><p>La signification profonde est que quelque chose se passe dans votre vie qui aura un impact positif et profond sur vous.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you're in a relationship and you feel totally ready for the next step, it is possible that your partner doesn't feel the same way. She/he might need some more time. Don't get impatient, love and commitment can't be rushed. Your partner will take the next step when ready.</p><p>If you are looking for a partner it is possible that someone you are in love with doesn't look at you the same way. Give him/her the time to get to know you and his/her feelings will surely grow.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are walking around with some questions. The Eight of Wands lets you know that your answers will be coming to you shortly. Because it's someone else who has to give you those answers, there is nothing for you to do but wait and train your patience, especially if it’s not your strong suit.</p><p>This card sometimes also indicates that a change you've been expecting for quite some time now, will soon take effect.</p><p>No matter what, the result will very likely be positive for you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In these demanding and hectic times almost everyone is struggling with the concept of patience now and then. It's not an easy thing to acquire or to systematically incorporate into your life. Now is a good time to turn to your true inner self and find quiet and serenity, in order to achieve true patience. Use any means necessary, be it meditation, affirmation, projection or a mix of all three. Maybe you will not be successful the first time you try, but don't give up. It is very important for you to find true patience within yourself right now.</p>",
                    }
                },
                sp:{
                    name:"El Ocho de Bastos",
                    suit: "bastos",
                    element: "🜂 – fuego",
                    theme: "Asumir la responsabilidad de lo que se quiere",    
                    description: "El Ocho de Bastos (compases) se trata sobre asumir la responsabilidad de lo que quieres y nos recuerda que si queremos algo, tenemos que actuar nosotros mismos",
                    questions: ["¿Qué quieres lograr?", "¿Ya hecho algo para lograrlo?", "¿Has puesto algo en marcha?", "¿Deberías esperar pacientemente la reacción a tu acción?" ], 
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Fuera de todas las tarjetas de 78 Tarot El Ocho de Varitas es la única tarjeta que indica que algo está a punto de suceder muy pronto si no en este momento. Si está harto de esperar, frustrado y cansado, espera un poco más largo, el cambio está a punto de venir a usted. Ha hecho todo lo necesario para establecer las ruedas en movimiento, ahora sólo tiene que esperar a que sus acciones tengan efecto. El sentido más profundo es que una cosa está pasando en su vida que tiene una influencia positiva a fondo en usted.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Acht",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "verantwoording nemen voor wat je wilt",    
                    description : "De acht van staven gaat over verantwoording nemen voor wat je wilt en wijst ons dat erop dat wanneer we iets willen dat we dan zelf actie dienen te ondernemen.",
                    questions : [ "Wat wil jij bereiken?", "Ben je al in actie gekomen om het te kunnen bereiken?", "Heb je iets in gang gezet?", "Dien je geduldig de reactie op je actie af te wachten?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Van alle 78 Tarotkaarten is Staven Acht de enige kaart die je vertelt dat er op korte termijn iets te gebeuren staat of zelfs al aan het gebeuren is. Als je het wachten moe bent, als je gefrustreerd bent, houd het dan nog even vol. Verandering is onderweg naar jou!</p><p>Je hebt alles gedaan wat nodig was om de boel in gang te trekken, nu kan je enkel nog afwachten tot de gevolgen van jouw daden zichtbaar worden. Als je jezelf en je handelingen nog eens onderzoekt, zal je waarschijnlijk beseffen dat je alles gedaan hebt wat nodig was.</p><p>De diepere betekenis van deze kaart is dat er iets gaande is in je leven dat een grondige positieve invloed op je zal hebben.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je een relatie hebt en je bent er klaar voor om de volgende stap te zetten, is het mogelijk dat je partner (nog) niet op dezelfde golflengte zit. Hij/zij heeft mogelijk meer tijd nodig om tot het punt te komen waar jij nu bent. Zorg ervoor dat je niet ongeduldig wordt want liefde en engagement hebben nu eenmaal hun eigen tempo. Je partner zal de volgende stap zetten als zij/hij er klaar voor is.</p><p>Als je op zoek bent naar een relatie en je hebt gevoelens voor iemand dan is het best mogelijk dat die persoon je gevoelens (nog) niet beantwoordt. Geef die ander alle tijd en ruimte om je te leren kennen en je zal merken dat de diepere gevoelens dan ook zullen komen.</p>",
                        [GuidanceFields.WORK] : "<p>Je zit op het werk met een aantal vragen. Zwaarden Acht vertelt je dat je binnenkort de nodige antwoorden zal krijgen. Je kan op dit moment niets anders doen dan afwachten en geduld hebben, ook al is dat niet je sterkste kant. Deze kaart wijst er soms ook op dat je al een hele tijd op veranderingen aan het wachten was en dat deze nu zullen plaatsvinden.</p><p>In elk geval wijst deze kaart er duidelijk op dat de resultaten positief zullen zijn voor jou.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>We leven in hectische en veeleisende tijden waardoor iedereen af en toe wel eens geconfronteerd wordt met het concept “geduld” in allerlei vormen. Het is niet gemakkelijk om geduldig te zijn, om het geduld als vanzelfsprekend in je leven te verwerken.</p><p>Deze kaart wijst je erop dat het moment nu gekomen is om naar je diepe innerlijke zelf te gaan en daar rust en sereniteit te vinden. Daardoor zal je het ware geduld ontdekken en kunnen toepassen. Gebruik hiervoor alle mogelijke middelen zoals meditatie, affirmatie, projectie of een mengeling ervan. Misschien zal je er de eerste keer niet in slagen om dichter bij je geduld te komen maar geef het niet op. Het is op dit moment in je leven heel belangrijk voor je om intens geduld binnenin jezelf te ontdekken.</p>",
                    }
                },
                de : {
                    name: "Acht der Stäbe",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "Verantwortung für das zu übernehmen, was man will.",    
                    description : "Die Acht der Stäbe sagt, dass wir Verantwortung für das übernehmen sollen, was wir wollen, und dass wir handeln müssen, wenn wir etwas wollen.",
                    questions : [ "Was wollen Sie erreichen?", "Haben Sie schon Schritte unternommen, um es zu erreichen?", "Haben Sie schon etwas begonnen?", "Sollten Sie geduldig auf die Reaktion auf Ihre Aktion warten?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Von allen 78 Tarot-Karten ist die „Acht der Stäbe“ die einzige Karte, die anzeigt, dass etwas sehr bald geschehen wird, wenn nicht gerade jetzt. Wenn Sie des Wartens müde sind, frustriert und erschöpft, dann halten Sie nur noch ein wenig länger durch. Veränderung ist auf dem Wege und Sie werden ihr in Kürze begegnen.</p><p>Sie haben alles Notwendige getan, um die Räder in Bewegung zu setzen. Jetzt müssen Sie nur auf die Auswirkungen warten. Bei der Nachfrage in sich selbst werden Sie wahrscheinlich feststellen, dass Sie tatsächlich alles Notwendige getan haben.</p><p>Der tiefere Sinn ist, dass etwas los ist in Ihrem Leben, das einen durch und durch positiven Einfluss auf Sie haben wird.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer Beziehung sind und Sie fühlen sich völlig bereit für den nächsten Schritt ist es möglich, dass Ihr Partner das nicht in der gleichen Weise fühlt. Sie / er kann etwas mehr Zeit benötigen. Nicht ungeduldig werden, Liebe und Engagement können nicht überstürzt werden. Ihr Partner wird den nächsten Schritt machen, wenn er/sie fertig ist.</p><p>Wenn Sie auf der Suche nach einem Partner sind ist es möglich, dass jemand Ihre Liebe nicht gleich beantwortet. Geben Sie ihm / ihr die Zeit, um Sie näher kennen zu lernen und seine / ihre Gefühle werden sicher wachsen</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit laufen Sie herum mit einigen Fragen. Die Acht der Stäbe lässt Sie wissen, dass Ihre Antworten in Kurzem auf Sie zukommen. Weil es jemand anders ist, der Ihnen die Antworten geben muss, gibt es nichts Weiteres für Sie tun, als zu warten und Ihre Geduld üben, etwas, das nicht zu Ihren Stärken gehört.</p><p>Diese Karte zeigt manchmal auch an, dass eine Änderung, die Sie schon seit einiger Zeit erwartet haben, nicht mehr lange auf sich warten lässt.</p><p>Egal was das Ergebnis sein wird, es wird sehr wahrscheinlich positiv für Sie sein.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In dieser anspruchsvollen und hektischen Zeit hat fast jeder ab und zu mit dem Konzept der Geduld zu kämpfen. Es ist keine einfache Sache sie zu bewahren oder systematisch in Ihr Leben zu integrieren. Jetzt ist eine gute Zeit, um Sich zu Ihrem wahren inneren Selbst zu kehren und die Ruhe und Gelassenheit zu finden, die man braucht um die wahre Geduld zu erreichen. Verwenden Sie alle Mitteln, sei es Meditation, Affirmation, Projektion oder eine Mischung aus allen dreien. Vielleicht werden Sie nicht erfolgreich sein beim ersten Versuch, aber geben Sie nicht auf. Es ist sehr wichtig, dass Sie die wahre Geduld in Ihnen gerade jetzt finden.</p>",
                    }
                }
            },
            {
                filename: "30",
                numerology : 9,
                en : {
                    name: "Nine of Wands",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "knowing what you want",    
                    description : "Nine of Wands speaks of being conscious about what you want and owning it.",
                    questions : [ "Are you aware of what you want?", "Have you bumped your \"head\" before?", "Will it happen again or have you learned your lesson now?", "Use your knowledge." ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Being stubborn is the central theme of this card. You are not about to give in and are determined to hold on to your vision, conviction or opinion.</p><p>It may be a good idea to ask yourself why you won't budge. Is there something you are afraid of? Do you feel caving might be threatening? Is it too hard to let go of your ego? Examine the answers to these questions thoroughly and rethink your decision. Maybe it's time to let go of your stubbornness.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now you are afraid of being hurt by your partner. Maybe you are afraid he/she may have intimate feelings for someone else, maybe you are afraid that she/he doesn't really ‘see’ you anymore. To avoid being hurt you have built a wall around yourself, you are wearing a symbolic suit of armor. It is important for you to realize that when you are shutting yourself off this way, you are also denying yourself love and other positive feelings. Look deeply into your own heart: is this what you truly want?</p><p>If you are single right now it may be wise to first start healing yourself emotionally from the wounds you sustained in a previous relationship. Once you’ve done that, you can consider going out and looking for a new love.</p>",
                        [GuidanceFields.WORK] : "p>The Nine of Wands tells you that you might feel threatened at work, and there might even be valid reasons for the way you feel. Maybe a colleague is trying to settle a score or possibly your position is being reconsidered. First, try to see the difference between true and perceived threats. Then you can stop worrying about the ones you only think exist. Finally it's time to consult your conscience and find out which role you have played in creating the present situation. Your first step is to recognize your mistakes, the second one is to find out if there is still time to set the record straight.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>For the moment you may be feeling rather self-indulgent, maybe even sorry for yourself and your circumstances. Now is absolutely not the time to wallow in it! On the contrary, the time is right to apply some self discipline in order to attain your spiritual goals. Focus on studying, make a plan, set yourself some goals … do everything and anything necessary for you to attain higher levels of spiritual commitment. Surround yourself with people who have a positive and proactive attitude, people who can help you reach a higher level.</p>",
                    }
                },
                fr : {
                    name : "Le Neuf de Bâton",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "savoir ce que vous voulez",    
                    description : "Le Neuf de Bâtons nous parle d'être conscient de ce que nous voulons et de pouvoir le défendre",
                    questions : ["Etes-vous conscient de ce que vous voulez?", "Vous êtes-vous déjà cogné la tête?", "Cela arrive-t-il encore ou en avez-vous appris?", "Utilisez ce que venez d’apprendre." ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>L'obstination est le thème central de cette carte. Vous n'êtes pas prêt à céder et vous êtes déterminé à insister sur votre vision, votre conviction ou votre opinion.</p><p>Ce serait peut-être une bonne idée de vous demander pourquoi vous êtes si tenace. Vous avez peur de quelque chose? S'agit-il de l'enfant brûlé qui a peur du feu? C'est peut-être trop dur de se séparer de son ego? Examinez attentivement les réponses à ces questions et reconsidérez votre décision. Il est peut-être temps de lâcher votre entêtement.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now you are afraid of being hurt by your partner. Maybe you are afraid he/she may have intimate feelings for someone else, maybe you are afraid that she/he doesn't really ‘see’ you anymore. To avoid being hurt you have built a wall around yourself, you are wearing a symbolic suit of armor. It is important for you to realize that when you are shutting yourself off this way, you are also denying yourself love and other positive feelings. Look deeply into your own heart: is this what you truly want?</p><p>If you are single right now it may be wise to first start healing yourself emotionally from the wounds you sustained in a previous relationship. Once you’ve done that, you can consider going out and looking for a new love.</p>",
                        [GuidanceFields.WORK] : "p>The Nine of Wands tells you that you might feel threatened at work, and there might even be valid reasons for the way you feel. Maybe a colleague is trying to settle a score or possibly your position is being reconsidered. First, try to see the difference between true and perceived threats. Then you can stop worrying about the ones you only think exist. Finally it's time to consult your conscience and find out which role you have played in creating the present situation. Your first step is to recognize your mistakes, the second one is to find out if there is still time to set the record straight.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>For the moment you may be feeling rather self-indulgent, maybe even sorry for yourself and your circumstances. Now is absolutely not the time to wallow in it! On the contrary, the time is right to apply some self discipline in order to attain your spiritual goals. Focus on studying, make a plan, set yourself some goals … do everything and anything necessary for you to attain higher levels of spiritual commitment. Surround yourself with people who have a positive and proactive attitude, people who can help you reach a higher level.</p>",
                    }
                },
                sp:{
                    name:"El Nueve de Bastos",
                    suit: "Bastos",
                    element: "🜂 – fuego",
                    theme: "saber lo que quieres",    
                    description: "El Nueve de Bastos  se trata de ser consciente de lo que quieres y nos recuerda que podemos pararnos justo enfrente ",
                    questions: ["¿Eres consciente de lo que quieres?", "¿Alguna vez te has golpeado la cabeza?", "¿Va a ser otra vez o ya sabes mejor?", "Usa lo que sabes ahora" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Ser tenaz es el tema central de esta tarjeta. Usted no está a punto de ceder y está decidido a aferrarse a su visión, convicción ó opinión. Puede ser una buena idea que preguntarse por qué no se movió. ¿Hay algo que da miedo? ¿Se siente espeleología podría estar amenazando? ¿Es muy difícil dejar de lado su ego? Examine las respuestas a estas preguntas a fondo y repensar su decisión. Tal vez es hora de dejar de lado su terquedad.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Negen",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "weten wat je wilt",    
                    description : "De negen van staven gaat over bewust zijn van wat je wilt en wijst ons er op dat we daar dan pal voor kunnen gaan staan.",
                    questions : [ "Ben jij bewust van wat je wilt?", "Heb jij je \"kop\" al eens gestoten?", "Overkomt je dat nogmaals of weet je nu beter?", "Maak gebruik van wat je nu weet." ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Het centrale thema van deze kaart is koppigheid. Je bent niet van plan om toe te geven, je bent vastberaden om je te blijven vasthouden aan jouw visie, jouw mening, jouw overtuiging.</p><p>Misschien is het wel een goed idee om je eens af te vragen waarom je zo koppig bent. Is er iets waar je bang voor bent? Ervaar je toegeven als een soort bedreiging? Verbind je toegeven met een onaangename ervaring uit het verleden? Of misschien vind je het op dit moment te moeilijk om je ego los te laten. Onderzoek jouw antwoorden op deze vragen grondig en denk nog eens na over je beslissing(en). Misschien is het nu echt wel de juiste tijd om je koppigheid los te laten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment ben je bang dat je partner je zal kwetsen. Misschien denk je dat hij/zij gevoelens heeft voor iemand anders of heb je het gevoel dat je partner je niet meer echt “ziet”. Om te vermijden dat je gekwetst zou worden, heb je een stevige muur om je heen gebouwd, je draagt als het ware een emotioneel harnas. Nu is het heel belangrijk dat je echt gaat beseffen dat je jezelf hierdoor helemaal afsluit, niet enkel voor negatieve gevoelens en ervaringen, maar ook voor de positieve zoals liefde en tederheid. Kijk diep in je hart: is dit echt wat je wil?</p><p>Als je op dit moment alleen bent dan is het verstandig om eerst de nodige tijd te besteden aan het genezen van de wonden die een vorige relatie je toegebracht heeft. Pas als dit gebeurd is, kan je op zoek gaan naar een nieuwe liefde.</p>",
                        [GuidanceFields.WORK] : "<p>Staven Negen vertelt je dat je je mogelijk bedreigd voelt op het werk en het kan best zijn dat daar ook een aanleiding voor is. Misschien is er een collega die een rekening met jou te vereffenen heeft of mogelijk wordt er nagedacht over jouw functie. Probeer in de eerste plaats te ontdekken welke de reële bedreigingen zijn en welke enkel in je fantasie bestaan. Eens dit duidelijk is, kan je stoppen met piekeren over de vermeende problemen en enkel de reële aanpakken. En dan is het tijd om je geweten te raadplegen en te bekijken welke rol jij gespeeld hebt in het ontstaan van de huidige situatie. De eerste stap is je fouten onder ogen zien, pas daarna kan je gaan kijken of je de boel nog kan rechttrekken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment ben je behoorlijk gefocust op jezelf, misschien heb je zelfs medelijden met jezelf vanwege de situatie waarin je je bevindt. Maar het is echt geen goede tijd om je onder te dompelen in zelfmedelijden! Integendeel, net nu is het belangrijk dat je wat strenger bent voor jezelf en dat je gestructureerd te werk gaat om je spirituele doelen te bereiken. Concentreer je op kennis verwerven, maak een plan op, stel jezelf uitdagingen ... Doe alles wat je maar kan bedenken om een hoger niveau van spirituele toewijding te bereiken. Omring jezelf met mensen die een positieve en proactieve instelling hebben, mensen die je kunnen helpen om dat hogere plan te bereiken.</p>",
                    }
                },
                de : {
                    name: "Neun der Stäbe",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "Wissen, was Sie wollen",    
                    description : "Die Neun von Stäbe spricht darüber, dass wir uns dessen bewusst sind, was wir wollen, und dass wir es verteidigen können.",
                    questions : [ "Sind Sie sich dessen bewusst, was Sie wollen?", "Haben Sie sich jemals den Kopf gestoßen?", "Passiert das noch oder haben Sie daraus gelernt?", "Nutzen Sie, was Sie gerade gelernt haben." ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Sturheit ist das zentrale Thema dieser Karte. Sie sind nicht bereit, nachzugeben und sind entschlossen, auf Ihrer Vision, Ihren Überzeugung oder Ihrer Meinung zu bestehen.</p><p>Es könnte eine gute Idee sein, sich zu fragen, warum Sie so hartnäckig sind. Gibt es etwas, wovor Sie Angst haben? Haben Sie das Gefühl, nachzugeben könnte in irgendeiner Weise eine Bedrohung für Sie darstellen? Ist es eine Frage des gebrannten Kindes, das das Feuer scheut? Vielleicht ist es einfach zu schwer, sich von Ihrem Ego zu trennen? Untersuchen Sie die Antworten auf diese Fragen gründlich und überdenken Sie Ihre Entscheidung. Vielleicht ist es Zeit, Ihre Sturheit loszulassen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Gerade jetzt haben Sie Angst, von Ihrem Partner verletzt zu werden. Vielleicht haben Sie Angst, dass er / sie intime Gefühle für jemand anderes haben könnte, oder vielleicht haben Sie Angst, dass sie / er Sie nicht mehr wirklich \"sieht\". Um nicht verletzt zu werden, haben Sie einen Mauer um sich aufgebaut, wie eine symbolische Rüstung. Es ist wichtig für Sie zu verstehen, dass Sie auf dieser Weise auch nicht in der Lage sind Liebe und andere positive Gefühle zu empfangen. Schauen Sie tief in Ihrem Herzen: ist es das, was Sie wirklich wollen?</p><p>Wenn Sie jetzt alleine sind, könnte es klug sein, erstmal Sich Selbst zu heilen von den emotionalen Wunden, die Sie noch haben aus einer früheren Beziehung. Sobald Sie das getan haben, können Sie Ausgehen und auf der Suche nach einer neuen Liebe gehen.</p>",
                        [GuidanceFields.WORK] : "<p>Die Neun der Stäbe sagt Ihnen, dass Sie Sich vielleicht bei der Arbeit bedroht fühlen, und es könnte sogar stichhaltige Gründe für dieses Gefühl geben. Vielleicht könnte ein Kollege versuchen, eine alte Rechnung zu begleichen oder möglicherweise wird Ihre Position neu erwogen. Versuchen Sie zunächst, den Unterschied zwischen echten und wahrgenommenen Bedrohungen zu sehen. Dann können Sie aufhören sich Sorgen zu machen über Bedrohungen von denen Sie nur denken, dass sie existieren. Schließlich ist es Zeit, Ihr Gewissen zu befragen und herauszufinden, welche Rolle Sie bei der Erstellung der gegenwärtigen Situation gespielt haben. Ihr erster Schritt muss sei, Ihre Fehler zu erkennen, der zweite wäre, herauszufinden, ob es noch Zeit gibt um die Sache noch zu reparieren.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Zur Zeit können Sie schwelgerisch sein und vielleicht sogar Mitleid mit Sich Selbst und Ihren Umständen fühlen. Jetzt ist absolut nicht die Zeit, um in sich zu schwelgen! Im Gegenteil, jetzt ist die Zeit reif, um etwas Selbstdisziplin darzustellen um Ihre spirituelle Ziele zu erreichen. Konzentrieren Sie Sich auf das Studium, einen Plan machen, setzen Sie sich einige Ziele … tun Sie alles, was notwendig ist für Sie um eine höhere spirituelle Bindung zu erreichen. Umgeben Sie Sich mit Menschen, die eine positive und proaktive Haltung haben, Menschen, die Ihnen helfen können, ein höheres Niveau zu erreichen.</p>",
                    }
                }
            },
            {
                filename: "31",
                numerology : 10,
                en : {
                    name: "Ten of Wands",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "wanting a lot",    
                    description : "Ten of Wands talks about wanting a lot and the possibility of losing perspective.",
                    questions : [ "Do you want a lot?", "At the same time?", "Is that costing you a lot of energy?", "How long can you keep that up?", "Are you taking it to heart?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Ten of Wands depicts a situation in which you are feeling down, depressed, burdened, you are feeling the weight of the world on your shoulders. Maybe you have been exerting yourself for a long period, personally and at work. You may feel afraid that the future will only hold more difficulties, more problems, more work to be done. Desperation may be close.</p><p>The first thing to do in order to get rid of this feeling of being a victim, is finding out if part of your burden is maybe belonging to someone else. Have you been taking on the problems and worries from people around you? If so, give them back, they are not yours. And then it is time to stand still, turn inward, go beyond what you are feeling and turn to your inner core, find out which role you played in all these problems and change them. If for the moment you lack courage for this confrontation, do not hesitate to seek help, there is no shame in wanting to heal!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Relationships and love</p><p>You feel a lot of self-pity right now.You think your relationship problems are caused by others, your partner, friends, external influences. Even though this may be partly true, your attitude won't help you solve those problems. The only way out of them is to look at the situation from a different angle and acknowledge your own part and doings. This will help you find a new and positive way out.</p><p>Possibly your partner has problems that have got nothing to do with you. You may feel relieved about that, but don't forget he/she still needs your help and support.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are dealing with a lot of stress and responsibility.Possibly your workload is way too big and even growing. Be honest about this and tell your supervisor you will have to put off some jobs in order to do the more urgent ones. It does nobody any good to suffer in silence, on the contrary, it is counterproductive. If you hesitate to bring up the subject, ask yourself why, what are you afraid might happen? </p><p>If you are looking for a new job you probably aren't very successful for the moment. Don't despair, times will soon change for the better.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Ten of Wands tells you that spiritually you might be in a time of need. Maybe you have reached a point beyond which you cannot evolve without help and assistance. If this is the case, don't hesitate to ask support from the people around you. You may also go out and find new sources of wisdom and spiritual energy.</p><p>But maybe you are just plain tired, maybe you have overexerted yourself, gone too far, beyond your capabilities. Now may be the perfect time to rest and relax, to give your spirit the time to get back to basics.</p>",
                    }
                },
                fr : {
                    name : "Le Dix de Bâton",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "vouloir beaucoup",    
                    description : " Le Dix de Bâtons, nous parle de vouloir beaucoup et beaucoup à la fois, on risque de perdre la vue d'ensemble",
                    questions : ["Voulez-vous beaucoup?", "En même temps?", "Cela vous coûte-t-il beaucoup d'énergie?", "Combien de temps pouvez-vous encore continuer?", "Prenez-vous cela trop au sérieux?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Dix de Bâton\" représente une situation dans laquelle vous vous sentez déprimé et alourdi. Vous sentez le poids du monde sur vos épaules. Vous avez peut-être déjà dû faire des efforts sur une plus longue période de temps, en privé ou professionnellement. Vous craignez peut-être qu'il n'y ait d'autres problèmes à l'avenir. Le sentiment de désespoir peut être près de vous.</p><p>La première chose que vous devez faire pour éviter de devenir une victime de ce sentiment est de savoir si une partie de votre fardeau appartient à quelqu'un d'autre. Avez-vous pris en charge les problèmes et les soucis des gens qui vous entourent? Si c'est le cas, retournez-les, elles ne vous appartiennent pas. Et puis il est temps de rester calme et de se tourner vers l'intérieur, d'aller au-delà de ce que vous ressentez, et de vous concentrer sur votre Soi intérieur. Découvrez le rôle que vous avez joué dans tous ces problèmes et changez votre approche. Si en ce moment vous n'avez pas le courage pour cette confrontation, n'hésitez pas à demander de l'aide. Il n'y a pas de honte à vouloir aller mieux !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Relationships and love</p><p>You feel a lot of self-pity right now.You think your relationship problems are caused by others, your partner, friends, external influences. Even though this may be partly true, your attitude won't help you solve those problems. The only way out of them is to look at the situation from a different angle and acknowledge your own part and doings. This will help you find a new and positive way out.</p><p>Possibly your partner has problems that have got nothing to do with you. You may feel relieved about that, but don't forget he/she still needs your help and support.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are dealing with a lot of stress and responsibility.Possibly your workload is way too big and even growing. Be honest about this and tell your supervisor you will have to put off some jobs in order to do the more urgent ones. It does nobody any good to suffer in silence, on the contrary, it is counterproductive. If you hesitate to bring up the subject, ask yourself why, what are you afraid might happen? </p><p>If you are looking for a new job you probably aren't very successful for the moment. Don't despair, times will soon change for the better.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Ten of Wands tells you that spiritually you might be in a time of need. Maybe you have reached a point beyond which you cannot evolve without help and assistance. If this is the case, don't hesitate to ask support from the people around you. You may also go out and find new sources of wisdom and spiritual energy.</p><p>But maybe you are just plain tired, maybe you have overexerted yourself, gone too far, beyond your capabilities. Now may be the perfect time to rest and relax, to give your spirit the time to get back to basics.</p>",
                    }
                },
                sp:{
                    name:"El Diez de Bastos",
                    suit: "bastos",
                    element: "🜂 – fuego",
                    theme: "quiero mucho",    
                    description: "El Diez de Bastos se trata de mucha voluntad y nos recuerda que cuando queremos mucho a la vez, podemos perder la visión general",
                    questions: ["¿Quieres mucho?", "¿Al mismo tiempo?", "¿Te cuesta mucha energía?", "¿Cuánto tiempo puedes mantenerlo en alto?", "¿Levantas mucho peso?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Diez de Varitas representa una situación en la que se siente triste, deprimido, agobiado, se siente el peso del mundo sobre sus hombros. Tal vez usted ha estado ejerciendo en su mismo durante un largo periodo, en lo personal y en el trabajo. Usted puede sentir miedo de que el futuro sólo tenga más dificultades, más problemas, más trabajo por hacer. La desesperación puede estar cerca. Lo primero que debe hacer para deshacerse de este sentimiento de ser una víctima, es averiguar si parte de su carga es tal vez perteneciente a otra persona. Ha estado teniendo en los problemas y las preocupaciones de la gente que le rodea? Si es así, les dan la espalda, que no son los suyos. Y entonces es el momento de detenerse, girar hacia adentro, ir más allá de lo que está sintiendo y gire a su núcleo interior, saber qué papel que jugó en todos estos problemas y cambiarlos. Si por el momento le falta coraje para esta confrontación, no dude en buscar ayuda, no hay vergüenza en su deseo de sanar!</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Tien",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "heel veel willen",    
                    description : "De tien van staven gaat over heel veel willen en wijst ons erop dat wanneer we heel veel tegelijk willen we mogelijk het overzicht kunnen verliezen.",
                    questions : [ "Wil jij veel?", "Tegelijkertijd?", "Kost je dat veel energie?", "Hoe lang hou je dat nog vol?", "Til je er zwaar aan?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Staven Tien symboliseert een situatie waarin je je triest voelt, gedeprimeerd, overbelast. Je hebt het idee dat het volle gewicht van de wereld op je schouders rust. Misschien heb je jezelf al een tijd overbelast, zowel privé als op het werk. Het is best mogelijk dat je nu het gevoel hebt dat de toekomst enkel nog meer werk, meer problemen, meer conflicten zal brengen. Misschien zit je op het randje van de wanhoop.</p><p>Een eerste en heel belangrijke stap is dat je jezelf toestaat om uit de slachtofferrol te komen en je af te vragen of een deel van de last die je draagt misschien van iemand anders is. Heb je de verantwoordelijkheid voor een aantal problemen in je omgeving misschien overgenomen? Als dat het geval is, geef die verantwoordelijkheid dan terug aan de persoon of personen waarover het gaat, het zijn niet jouw problemen maar die van hen, geef hen dan ook de ruimte om ze zelf op te lossen. De tweede stap is de tijd nemen om stil te staan, tot rust te komen, je te richten op je innerlijk. Ga voorbij je emoties en focus op je kern, je essentie. Zoek uit welke rol je gespeeld hebt in al die problemen die je overweldigen en verander daarna die rol, zoek manieren om de dingen anders aan te pakken. Als je op dit moment niet de moed hebt om deze uitdaging alleen aan te gaan, zoek dan hulp. Heling en verandering willen is helemaal niet fout of beschamend, integendeel!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Je hebt op dit moment nogal wat medelijden met jezelf. Je hebt het idee dat de problemen in je relatie door anderen veroorzaakt worden, door je partner, vrienden, invloeden van buitenaf. Ook al is dat deels de waarheid, toch zal deze houding je niet verder helpen, de problemen geraken er niet door opgelost. De enige manier om dat te doen is de situatie vanuit een andere hoek te bekijken, zoek een nieuwe visie op het geheel. Door het zo aan te pakken zal je je eigen aandeel beter kunnen zien en ook erkennen. Eens je zover bent, zal je een positieve en constructieve manier vinden om de dingen op te lossen.</p><p>Soms wijst deze kaart er ook op dat je partner problemen heeft die niets met jou te maken hebben, waar je dus geen aandeel in hebt. Ook al is dat misschien een opluchting voor je, denk eraan dat zij/hij je steun en hulp nodig heeft. Het is aan jou om deel uit te maken van de oplossing, anders vorm je toch een probleem.</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk heb je op dit moment nogal wat stress en verantwoordelijkheid. Misschien is je takenpakket te groot en nog steeds aan het groeien. Als dat het geval is, kaart dit dan eerlijk aan met je verantwoordelijke, vertel dat je een aantal taken (tijdelijk) zal moeten laten vallen om de dringende dingen af te kunnen werken. Niemand heeft er iets aan als jij in stilte aan het lijden bent, integendeel, dat is contraproductief.</p><p>Misschien ben je wat huiverig om hierover te praten. Vraag je dan af waar je bang voor bent, wat is het ergste dat zou kunnen gebeuren?</p><p>Als je op zoek bent naar werk is het best mogelijk dat die zoektocht niet zo vlot verloopt. Wanhoop niet, dit is een tijdelijke situatie die binnenkort een positievere kant opgaat.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Staven Tien wijst erop dat je spiritueel gezien in een nogal behoeftige periode zit. Misschien ben je op een punt gekomen dat je niet verder kan groeien zonder hulp en ondersteuning van anderen. Als dit het geval is, aarzel dan niet en vraag steun aan de mensen om je heen. Misschien is het ook een goed idee om nieuwe spirituele bronnen aan te boren, onderwerpen en richtingen die je nog niet onderzocht hebt.</p><p>Deze kaart kan je ook vertellen dat je gewoon heel moe bent, dat je wat teveel hooi op je vork genomen hebt, dat je over je grenzen heen gegaan bent. Als dat het geval is, dan is nu een goed moment om te rusten en te ontspannen, geef je geest en ziel de tijd om tot zichzelf te komen, terug naar de basis.</p>",
                    }
                },
                de : {
                    name: "Zehn der Stäbe",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "Viel wollen",    
                    description : "Die Zehn der Stäbe spricht davon, viel zu wollen und viel gleichzeitig zu wollen, man riskiert den Blick für's Ganze zu verlieren.",
                    questions : [ "Wollen Sie viel?", "Zur gleichen Zeit?", "Kostet es Sie viel Energie?", "Wie lange können Sie noch weitermachen?", "Nehmen Sie das zu ernst?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Zehn der Stäbe“ steht für eine Situation, in der Sie sich niedergeschlagen und beschwert fühlen. Sie spüren das Gewicht der Welt auf Ihren Schultern. Vielleicht haben Sie sich bereits über einen längeren Zeitraum hinweg anstrengen müssen, privat oder beruflich. Sie können Angst haben, dass es in Zukunft nur noch mehr Probleme geben könnte. Das Gefühl der Verzweiflung kann in Ihrer Nähe sein.</p><p>Das erste, was Sie tun müssen, damit Sie nicht Opfer dieses Gefühls werden, ist herauszufinden, ob ein Teil Ihrer Belastung vielleicht jemand anderem gehört. Haben Sie die Probleme und Sorgen der Menschen Ihrer Umgebung auf sich genommen? Wenn dies der Fall ist, geben Sie diese zurück, sie sind nicht die Ihren. Und dann ist es Zeit, still zu stehen und sich nach innen zu wenden, über das hinauszugehen, was Sie fühlen, und sich auf Ihr Inneres zu konzentrieren. Finden Sie einmal raus, welche Rolle Sie bei all diesen Problemen gespielt haben und ändern Sie Ihre Vorgehensweise. Wenn es Ihnen in diesem Moment an Mut für diese Konfrontation fehlt, dann zögern Sie nicht Hilfe zu suchen. Es ist keine Schande, gesund werden zu wollen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Sie fühlen sich voller Selbstmitleid im Moment. Sie meinen, dass die Probleme in Ihrer Beziehung verursacht werden durch andere, Ihren Partner, Freunde, sonstige äußere Einflüsse. Auch wenn dies zum Teil wahr sein könnte, wird Ihre Haltung nicht dazu beitragen diese Probleme zu lösen. Der einzige Weg raus ist, dass Sie Sich die Situation aus einem anderen Blickwinkel ansehen und Ihren eigenen Teil hierin erkennen. Dies wird Ihnen helfen einen neuen und positiven Ausweg zu finden.</p><p>Möglicherweise hat Ihr Partner Probleme, die nichts mit Ihnen zu tun haben. Möglicherweise fühlen Sie Sich erleichtert darüber, aber vergessen Sie, dass er / sie immer noch Ihre Hilfe und Unterstützung braucht.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit gibt es eine Menge Stress und Verantwortlichkeiten. Möglich haben Sie viel zu viel Arbeit und das wird sogar vielleicht noch wachsen. Seien Sie ehrlich darüber und informieren Sie Ihren Vorgesetzten und erzählen Sie ihnen, Sie müssen einige Aufgaben abschieben um diejenigen zu tun, die dringender und wichtiger sind. Es tut niemanden Gut in der Stille zu leiden, im Gegenteil, es ist kontraproduktiv. Wenn Sie zögern, das Thema heraus zu bringen, fragen Sie sich, warum, für was haben Sie Angst, was könnte passieren?</p><p>Wenn Sie nach einem neuen Job suchen sind Sie wahrscheinlich nicht sehr erfolgreich für den Moment. Verzweifeln Sie nicht, bald werden bessere Zeiten kommen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Zehn der Stäbe sagt Ihnen, dass Sie spirituell jetzt in Not sein könnten. Vielleicht haben Sie einen Punkt erreicht, an dem Sie nicht ohne Hilfe und Unterstützung zur weiterer Entwicklung kommen können. Wenn dies der Fall ist, zögern Sie nicht, um die Unterstützung der Menschen um Sie herum zu bitten. Sie können auch ausgehen und neue Quellen der Weisheit suchen für spirituelle Energie.</p><p>Aber vielleicht sind Sie einfach nur müde, vielleicht haben Sie sich über angestrengt, sind Sie ein bisschen zu weit über Ihre Fähigkeiten gegangen. Jetzt kann die perfekte Zeit sein, um auszuruhen und zu entspannen, Ihren Geist die Zeit zu geben zurück zu gehen zu der Basis.</p>",
                    }
                }
            },
            {
                filename: "32",
                numerology : 11,
                en : {
                    name: "Page of wands",
                    suit : "Wands",
                    court : "Page",
                    element : [ "🜂 - fire", "🜃 - earth" ],
                    theme : "impartially looking at what you want",    
                    description : "The Page of Wands talks about impartially looking at what we want with a child-like light-heartedness.",
                    questions : [ "What would you like?", "What sounds fun to you?", "Can you look at it impartially?", "Be game with your plans." ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>In Tarot the Page cards often stand for chances, opportunities being offered. The Page of Wands carries a strong positive energy, but if you don't actively use that energy it may easily get you distracted, in danger of losing focus. </p><p>If you draw this card you will probably be getting an offer shortly. If you decide to accept this offer it will bring you a lot of chances to grow and evolve and become more conscious. On top of that it may lead to fun things too! Possibly accepting the offer may require some daring and courage, don't let that scare you off. Every step we take in life leads to new experiences, to unknown situations. Staying with what we know doesn't help us grow.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Possibly you aren't very happy in your relationship. Maybe you even have doubts. First ask yourself what the problems are and if you are both willing to try and solve them, to change. Only when you work at this together you will be successful. If your partner isn't ready to commit, it's time to say goodbye and move on. A relationship has to be a two way street.</p><p>If you are single you may well meet someone who really sweeps you off your feet. Life may become a tempting adventure, a sensational journey. Take this new opportunity and enjoy it to the fullest.</p>",
                        [GuidanceFields.WORK] : "<p>At work it's very likely that you are getting a lot of chances and opportunities these days. And even if they’re unexpected, try to accept them without hesitation. They are bound to turn out positively and help you grow. You may even get a promotion and/or a raise.</p><p>If you are looking for a job, you will notice that people are happy to help you, to assist you in your search. You will have many good prospects and are sure to find a job that exceeds your expectations.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Drawing the Page of Wands tells you that spiritually it is a good time to let go of too much seriousness. Set your inner child free! Let it play and enjoy itself without wondering if any goals are being reached, any lessons are being learnt. The purpose of this period is not to study and achieve things, it is about playing, relaxing, dreaming. Maybe the only goal to reach is enriching and bettering your quality of life.</p>",
                    }
                },
                fr : {
                    name : "Le Valet de Bâtons",
                    suit : "Bâtons",
                    court : "Valet",
                    element :  ["🜂 - feu", "🜃 - terre" ],
                    theme : "regarder ce que l'on veut sans préjugé",    
                    description : "Valet de Bâtons,  nous parle de regarder ce que l'on veut sans préjugé et d’un esprit enfantin.",
                    questions : ["Que voulez-vous?", "Qu' aimez-vous?", "Pouvez-vous regarder cela avec un esprit ouvert?", "Jouez avec vos projets." ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "p>Dans le tarot, le valet est souvent synonyme d'opportunités, de possibilités offertes. Le \"Valet de Bâton\" est porteur d'une forte énergie positive. Néanmoins, il est important que vous utilisiez activement cette énergie. Si vous ne le faites pas, il peut facilement arriver que vous soyez distrait, en danger et que vous perdiez votre concentration.</p><p>Si vous tirez cette carte, vous recevrez probablement bientôt une offre. Si vous acceptez cette offre, vous aurez beaucoup d'occasions de grandir, d'évoluer et de devenir plus conscient. En plus de cela, il peut vous mener au plaisir! Accepter l'offre peut vous demander de l'audace et du courage, mais ne vous laissez pas décourager. Chaque pas que nous faisons dans la vie mène à de nouvelles expériences, à des situations inconnues. Si nous nous en tenons à ce que nous savons, cela ne nous aide pas à grandir.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Possibly you aren't very happy in your relationship. Maybe you even have doubts. First ask yourself what the problems are and if you are both willing to try and solve them, to change. Only when you work at this together you will be successful. If your partner isn't ready to commit, it's time to say goodbye and move on. A relationship has to be a two way street.</p><p>If you are single you may well meet someone who really sweeps you off your feet. Life may become a tempting adventure, a sensational journey. Take this new opportunity and enjoy it to the fullest.</p>",
                        [GuidanceFields.WORK] : "<p>At work it's very likely that you are getting a lot of chances and opportunities these days. And even if they’re unexpected, try to accept them without hesitation. They are bound to turn out positively and help you grow. You may even get a promotion and/or a raise.</p><p>If you are looking for a job, you will notice that people are happy to help you, to assist you in your search. You will have many good prospects and are sure to find a job that exceeds your expectations.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Drawing the Page of Wands tells you that spiritually it is a good time to let go of too much seriousness. Set your inner child free! Let it play and enjoy itself without wondering if any goals are being reached, any lessons are being learnt. The purpose of this period is not to study and achieve things, it is about playing, relaxing, dreaming. Maybe the only goal to reach is enriching and bettering your quality of life.</p>",
                    }
                },
                sp:{
                    name:"Soto de bastos",
                    suit: "bastos",
                    court: "Soto",
                    element: ["🜂 - fuego", "🜃 - tierra" ],
                    theme: "mirar lo que te quieres con la mente abierta",    
                    description: "El Soto de Bastos trata de mirar lo que quieres con una mente abierta y nos recuerda que podemos mirarlo con una mentalidad infantil y abierta",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>En las cartas de Tarot las tarjetas de Sota menudo representan posibilidades, las oportunidades que se ofrecen. La Sota de Varita lleva una energía positiva muy fuerte, pero si no se utiliza de forma activa que la energía se puede conseguir fácilmente distraído, en peligro de perder el foco. Si dibuja esta carta probablemente será conseguir una oferta en breve. Si usted decide aceptar esta oferta que le traerá una gran cantidad de oportunidades para crecer y evolucionar y ser más consciente. Además de eso puede llevar a cosas divertidas también! Posiblemente la aceptación de la oferta puede requerir algún atrevido y valentía, no deje que eso le asuste. Cada paso que damos en la vida conduce a nuevas experiencias, a situaciones desconocidas. Quedarse con lo que conocemos no nos ayuda a crecer.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Page van Staven",
                    suit : "Staven",
                    court : "Page",
                    element : [ "🜂 - vuur", "🜃 - aarde" ],
                    theme : "onbevooroordeeld kijken naar wat je wilt",    
                    description : "De page van staven gaat over onbevooroordeeld kijken naar wat je wilt en wijst ons erop dat we daar met kinderlijke onbevangenheid naar mogen kijken.",
                    questions : [ "Wat wil jij graag?", "Wat lijkt jou leuk?", "Kun jij daar met een open blik naar kijken?", "Speel eens met je plannetjes." ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>In de Tarot is de Page vaak het symbool voor nieuwe kansen en mogelijkheden die op je weg komen. De Page van Staven heeft een heel sterke positieve energie. Toch blijft het belangrijk dat je zelf actief aan de slag gaat met die energie. Als je dat niet doet en het gewoon over je heen laat komen dan zal je gemakkelijk afgeleid en verstrooid zijn en daardoor niet enkel je focus verliezen, maar ook het mogelijke positieve resultaat.</p><p>Als je deze kaart trekt dan is het heel waarschijnlijk dat je binnenkort een aanbod krijgt. Als je dit aanbod aanvaardt dan zal dit leiden tot verschillende mogelijkheden om te groeien en bewuster te worden. Daar bovenop kan het aanbod ook leiden tot leuke en fijne ervaringen!</p><p>Misschien zal je wat moed en durf nodig hebben om op dit aanbod in te gaan maar laat je dat niet afschrikken. Elke stap die we in dit leven zetten leidt ons immers naar nieuwe ervaringen en onbekende situaties. Je kan nu eenmaal niet groeien als je gewoon blijft waar je bent.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment ben je misschien niet echt gelukkig in je relatie. Misschien heb je er zelfs twijfels over of je er wel mee wil doorgaan. Voor je actie onderneemt is het verstandig om je in de eerste plaats af te vragen wat de problemen juist zijn èn of jullie allebei bereid zijn om te proberen werken aan een oplossing ervoor, om te veranderen. Enkel als jullie dit samen aanpakken, zal je succes hebben. Als je partner niet bereid is om zich te engageren bij het zoeken van een oplossing, dan is het tijd om afscheid te nemen en verder te gaan met je leven. Een relatie kan enkel werken als er sprake is van tweerichtingsverkeer.</p><p>Als je op zoek bent naar een partner dan is het goed mogelijk dat je iemand zal ontmoeten die je in vuur en vlam zet. Daardoor wordt je leven ineens een verleidelijk avontuur, een spannende reis. Grijp deze nieuwe kans aan en geniet er ten volle van!</p>",
                        [GuidanceFields.WORK] : "<p>Het zit er dik in dat je op dit moment in je werkomgeving nogal wat kansen en mogelijkheden krijgt. Probeer ze zonder aarzelen aan te pakken, ook al komen ze onverwacht. Zoals het klimaat nu is zal je waarschijnlijk succes hebben en zullen alle nieuwe initiatieven vruchten afwerpen. Je maakt zelfs kans op opslag of een promotie.</p><p>Als je een baan zoekt dan zal je merken dat de mensen er echt voor openstaan om je daarin te ondersteunen, je te helpen bij je zoektocht. Er komen veel goede vooruitzichten en de kans is groot dat je een baan vindt die je verwachtingen overtreft.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Page van Staven vertelt je dat je op dit ogenblik je spirituele leven niet al te ernstig moet nemen, til niet te zwaar aan de dingen en laat je innerlijke kind naar buiten komen! Speel, geniet en vraag je daarbij niet af of je wel bepaalde doelen zal bereiken of lessen zal leren. De bedoeling van deze (fijne) periode is niet om te studeren en jezelf uitdagingen op te leggen. Neen, het gaat hier om ontspannen, genieten en dromen zonder meer. Het enige doel wat je jezelf kan stellen is om je levenskwaliteit te verrijken en te verbeteren. Geniet ervan!</p>",
                    }
                },
                de : {
                    name: "Bube der Stäbe",
                    suit : "Stäbe",
                    court : "Bube",
                    element : [ "🜂 - Feuer", "🜃 - Erde" ],
                    theme : "Schauen, was man will, ohne Vorurteile.",    
                    description : "Der Bube der Stäbe spricht darüber zu schauen, was man will, ohne Vorurteile und mit einem kindlichen Geist.",
                    questions : [ "Was wollen Sie?", "Was mögen Sie?", "Können Sie das mit einem offenen Geist betrachten?", "Spielen Sie mit Ihren Projekten." ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Im Tarot steht der Bube oft für Chancen, für sich anbietende Möglichkeiten. Die „Bube der Stäbe“ trägt eine starke positive Energie. Dennoch ist es wichtig, dass Sie diese Energie auch aktiv nützen. Wenn Sie das nicht tun, kann es leicht passieren, dass Sie abgelenkt werden, in Gefahr geraten und den Fokus verlieren.</p><p>Wenn Sie diese Karte ziehen bekommen Sie wahrscheinlich in Kürze ein Angebot. Wenn Sie dieses Angebot annehmen wird es Ihnen eine Menge Chancen bringen um zu wachsen, sich weiter zu entwickeln und bewusster zu werden. Obendrein kann es Sie zum Spaß führen! Möglicherweise kann die Annahme des Angebots Kühnheit und Mut von Ihnen verlangen, aber lassen Sie sich nicht abschrecken. Jeder Schritt, den wir im Leben tun, führt zu neuen Erfahrungen, zu unbekannten Situationen. Wenn wir bei dem bleiben, was wir kennen, hilft uns das nicht beim Wachsen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Möglicherweise sind Sie nicht sehr glücklich in Ihrer Beziehung. Vielleicht haben Sie sogar Zweifel. Fragen Sie Sich zuerst, was die Probleme sind und ob sie beide bereit sind zu versuchen die Probleme zu lösen und die Situation zu ändern. Erst wenn Sie auf dieser Weise zusammenarbeiten, werden Sie erfolgreich sein. Wenn Ihr Partner nicht bereit ist sich dazu zu verpflichten, ist es Zeit, sich zu verabschieden und weiterzumachen. Eine Beziehung kann keine Einbahnstraße sein.</p><p>Wenn Sie alleine sind können Sie auch jemanden treffen der Sie wirklich von den Füssen bläst. Das Leben kann ein verlockendes Abenteuer, eine sensationelle Reise werden. Nutzen Sie diese neue Chance und genießen Sie es in vollen Zügen.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit ist es sehr wahrscheinlich, dass Ihnen in diesen Tagen eine Menge von Chancen und Möglichkeiten angeboten werden. Und auch wenn das unerwartet ist, versuchen Sie sie ohne Zögern zu akzeptieren. Sie sollten positiv auspacken und Ihnen helfen zu wachsen. Sie könnten sogar eine Beförderung und / oder eine Gehaltserhöhung empfangen</p><p>Wenn Sie auf der Suche nach einem Job sind, werden Sie feststellen, dass die Menschen glücklich und bereit sind Ihnen bei Ihrer Suche zu helfen. Sie haben viele gute Perspektiven und werden bestimmt einen Job finden, der Ihre Erwartungen übertrifft.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Ziehen Sie den Bube der Stäbe, dann heißt das, dass es spirituell eine gute Zeit ist zu viel Ernsthaftigkeit abzulegen. Lassen Sie Ihr inneres Kind frei! Lassen Sie es spielen und genießen ohne sich zu fragen, ob irgendwelche Ziele erreicht worden sind, oder alle Lektionen gelernt wurden. Der Zweck dieser Periode ist nicht studieren und Ziele erreichen. Sie dient zu spielen, entspannen, träumen. Vielleicht das einzige Ziel das zu erreichen wäre, ist Bereicherung und Besserung der Lebensqualität.</p>",
                    }
                }
            },
            {
                filename: "33",
                numerology : 12,
                en : {
                    name: "Knight of Wands",
                    suit : "Wands",
                    court : "Knight",
                    element : [ "🜂 - fire", "🜁 - air" ],
                    theme : "enthusiastically go public",    
                    description : "Knight of Wands talks about showing what we want and enthusiastically going public with it.",
                    questions : [ "What makes you go into raptures?", "Can you show that enthusiastically?", "Can you motivate others passionately?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Knight of Wands is a card with many faces. It shows you that for the moment you have great passion and enthousiasm in fulfilling a certain task or project. But it also warns us not to be overzealous, not to get carried away by that enthousiasm, to respect and acknowledge certain borders. Your motives are noble but it is very important to keep an oversight of the situation.</p><p>This is a time in which you may receive positive and uplifting messages, more often work related than personal. These messages speak of success and appreciation, of achieving goals, often better than expected.</p><p>This card may also tell us now is a good time to travel.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>For the moment you are feeling very close to your partner and very enthusiastic about your relationship. Take the time to regularly communicate, to have profound talks about how you feel and what you expect. If you do that, you can rest assured that your partner feels the same way you do.</p><p>Or maybe you are passionately and energetically on the lookout for a new love interest. You might ask yourself if your desire to be searching isn't bigger than your need to find someone. Is there a chance you are surpassing yourself unknowingly?</p>",
                        [GuidanceFields.WORK] : "<p>If you are currently looking for a job, this card tells you that good news is on the way. You will probably get the job you are hoping for and you can rest assured you will be happy and successful once you have it.</p><p>If you already have a position, you are very zealous and maybe you should be careful not to overdo it. You know your own temperament very well, but you cannot expect the same from everybody around you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>When you draw the Knight of Wands you are looking for new challenges, new subjects to study, new roads to explore. You feel like experiencing something different, something fresh. And suddenly you may find a new field or approach to spirituality that totally enchants you, gets your rapt attention. Even though it is a good thing to find new interests, it is also important to give attention to the old ones. Giving time, energy, maybe even money to spiritual causes is a powerful form of meditation too.</p>",
                    }
                },
                fr : {
                    name : "Le Cavalier des Bâtons",
                    suit : "Bâtons",
                    court : "Cavalier",
                    element :  ["🜂 - feu", "🜁 - air" ],
                    thème : " Aller de l’avant passionnément",    
                    description : "Le Cavalier des Bâtons nous parle de montrer ce que nous voulons  et que nous pouvons montrer notre enthousiasme au monde autour de nous.",
                    questions : ["Que vous excite-t-il?", "Pouvez-vous montrer votre enthousiasme?", "Pouvez-vous motiver les autres pleins de fougue?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le «Chevalier de Bâton» est une carte aux multiples visages. Il vous montre qu'en ce moment vous avez une grande passion et un grand enthousiasme pour accomplir une tâche ou un projet particulier. Mais il vous avertit aussi de ne pas faire trop de zèle. Ne vous laissez pas emporter par cet enthousiasme, mais respectez et reconnaissez certaines limites. Vos motivations sont nobles, mais il est très important de garder une vue d'ensemble de la situation.</p><p>C'est une période où vous pourriez recevoir des messages positifs et édifiants qui ont plus à voir avec les relations d'affaires qu'avec les relations personnelles. Ces messages parlent de succès et de reconnaissance, les objectifs sont généralement mieux atteints que prévu.</p><p>Cette carte peut aussi dire que c'est le bon moment pour voyager.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>For the moment you are feeling very close to your partner and very enthusiastic about your relationship. Take the time to regularly communicate, to have profound talks about how you feel and what you expect. If you do that, you can rest assured that your partner feels the same way you do.</p><p>Or maybe you are passionately and energetically on the lookout for a new love interest. You might ask yourself if your desire to be searching isn't bigger than your need to find someone. Is there a chance you are surpassing yourself unknowingly?</p>",
                        [GuidanceFields.WORK] : "<p>If you are currently looking for a job, this card tells you that good news is on the way. You will probably get the job you are hoping for and you can rest assured you will be happy and successful once you have it.</p><p>If you already have a position, you are very zealous and maybe you should be careful not to overdo it. You know your own temperament very well, but you cannot expect the same from everybody around you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>When you draw the Knight of Wands you are looking for new challenges, new subjects to study, new roads to explore. You feel like experiencing something different, something fresh. And suddenly you may find a new field or approach to spirituality that totally enchants you, gets your rapt attention. Even though it is a good thing to find new interests, it is also important to give attention to the old ones. Giving time, energy, maybe even money to spiritual causes is a powerful form of meditation too.</p>",
                    }
                },
                sp:{
                    name:"Caballo de bastos",
                    suit: "bastos",
                    court: "Caballo",
                    element: ["🜂 - fuego", "🜁 - aire"],
                    theme: "salir con entusiasmo",    
                    description: "El caballo de Bastos trata de mostrar lo que quieres y nos recuerda que podemos mostrar nuestro entusiasmo al mundo que nos rodea",
                    questions: ["¿De qué estás emocionado?", "¿Puedes demostrarlo con entusiasmo?", "¿Puedes motivar a otros lleno de fuego?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Caballo de Varitas es una tarjeta con muchas caras. Le muestra que para el momento en que tiene una gran pasión y entusiasmo en el cumplimiento de una determinada tarea o proyecto. Pero también advierte que no seamos demasiado entusiasta, no dejarse llevar por el entusiasmo, a respetar y reconocer ciertas fronteras. Sus motivos son nobles, pero es muy importante mantener una supervisión de la situación. Este es un momento en el que puede recibir mensajes positivos y edificantes, más a menudo relacionadas con el trabajo que personal. Estos mensajes hablan de éxito y reconocimiento, de la consecución de objetivos, a menudo mejor de lo esperado. Esta tarjeta puede también decirnos que ahora es un buen momento para viajar.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Ridder van Staven",
                    suit : "Staven",
                    court : "Ridder",
                    element : [ "🜂 - vuur", "🜁 - lucht" ],
                    theme : "enthousiast naar buiten treden",    
                    description : "De ridder van staven gaat over laten zien wat je wilt en wijst ons erop dat we ons enthousiasme aan de wereld om ons heen mogen tonen.",
                    questions : [ "Waar loop jij warm voor?", "Kun jij dat enthousiast tonen?", "Kun jij anderen vol vuur motiveren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Ridder van Staven is een kaart met vele gezichten. Ze kan je vertellen dat je op dit ogenblik in je leven met grote passie en enthousiasme bezig bent met een bepaalde taak of project. Maar ze kan je ook waarschuwen om niet overijverig te zijn, niet teveel hooi op je vork te nemen. Laat je niet meeslepen door dat enthousiasme maar erken en respecteer bepaalde grenzen van anderen. Je motieven zijn weliswaar nobel genoeg maar het is toch belangrijk om af en toe naar het grotere geheel te kijken. Je bent niet alleen op de wereld.</p><p>In deze periode kan je ook positieve en opbouwende berichten krijgen en meestal is het eerder in verband met je werk dan met privézaken. Deze berichten vertellen je dat je succes hebt en gewaardeerd wordt, dat je je gestelde doelen bereikt, over het algemeen eerder en beter dan verwacht werd.</p><p>Deze kaart kan je ook vertellen dat het nu een goed moment is om op reis te gaan of een uitstap te plannen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment voel je je heel dicht bij je partner en ben je echt positief over je relatie. Neem voldoende tijd om geregeld te communiceren, om diepgaande gesprekken aan te gaan over hoe je je voelt en wat je verwachtingen zijn. Als je dat geregeld doet, dan zal je merken dat jij en je partner totaal op dezelfde golflengte zitten.</p><p>Misschien ben je op dit moment energetisch vol overtuiging op zoek naar een nieuwe liefde. Vraag je eens af of je eigenlijk niet enthousiaster bent over het zoeken zelf dan over het vinden van iemand. Gaat je interesse op dit moment niet meer naar de jacht dan naar het resultaat ervan? Is het mogelijk dat je jezelf wat aan het voorbijlopen bent? Probeer je eens te focussen op het doel van je zoektocht: wie wil je vinden aan het einde van de rit?</p>",
                        [GuidanceFields.WORK] : "<p>Als je op dit moment op zoek bent naar werk dan vertelt deze kaart je dat er binnenkort goed nieuws komt. Er is een grote kans dat je de baan krijgt waar je op hoopt en eens aan de slag zal je tevreden en succesvol zijn in je werk. Als je al aan het werk bent dan ben je op dit moment heel ijverig bezig. Misschien zou je het iets kalmer aan moeten doen? Je kent jezelf goed, je weet dat je temperamentvol en energiek bent, maar je kan hetzelfde niet verwachten van iedereen om je heen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Als je de Ridder van Staven trekt dan betekent dit dat je op zoek bent naar nieuwe uitdagingen op spiritueel niveau, je wil onbekende onderwerpen bestuderen, niet vertrouwde wegen inslaan en onderzoeken. Je hebt echt zin om je tanden te zetten in iets nieuws, iets anders. En je zal dat andere onderwerp, die andere aanpak ook vinden, je zal er vol energie induiken en er intens van genieten.</p><p>Ook al is het zinvol en positief om geregeld van focus te veranderen, geef toch ook nog even aandacht aan de interessevelden die je achterlaat. Tijd, energie, soms zelfs een schenking geven aan spirituele doelen kan een heel sterke vorm van meditatie zijn.</p>",
                    }
                },
                de : {
                    name: "Ritter der Stäbe",
                    suit : "Stäbe",
                    court : "Ritter",
                    element : [ "🜂 - Feuer", "🜁 - Luft" ],
                    theme : "Mit Leidenschaft voranschreiten",    
                    description : "Der Ritter der Stäbe spricht darüber zu zeigen, was wir wollen und dass wir unsere Begeisterung der Welt um uns herum zeigen können.",
                    questions : [ "Was begeistert Sie?", "Können Sie Ihre Begeisterung zeigen?", "Können Sie andere voller Energie motivieren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der „Ritter der Stäbe“ ist eine Karte mit vielen Gesichtern. Sie zeigt Ihnen, dass Sie im Moment eine große Leidenschaft und Begeisterung für die Erledigung einer bestimmten Aufgabe oder eines Projektes haben. Aber sie warnt Sie auch davor, nicht zu übereifrig zu werden. Lassen Sie sich nicht von dieser Begeisterung mitreißen, sondern respektieren und erkennen Sie bestimmte Grenzen. Ihre Motive sind edel, aber es ist sehr wichtig, eine allgemeine Übersicht über die Situation zu behalten.</p><p>Dies ist eine Zeit, in der Sie positive und erhebende Nachrichten empfangen könnten, die eher mit geschäftlichen als mit persönlichen Beziehungen zu tun haben. Diese Nachrichten sprechen von Erfolg und Anerkennung, Ziele werden in der Regel besser als erwartet erreicht.</p><p>Diese Karte kann auch sagen, dass jetzt eine gute Zeit für eine Reise ist.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Zur Zeit fühlen Sie Sich ganz in der Nähe von Ihrem Partner und sind sehr begeistert von Ihrer Beziehung. Nehmen Sie sich Zeit, um regelmäßig zu kommunizieren, um tiefe Gespräche zu führen über wie Sie sich fühlen und was Sie erwarten können. Wenn Sie das tun, können Sei sicher sein, dass Ihr Partner sich genau so fühlt wie Sie.</p><p>Oder vielleicht sind Sie leidenschaftlich und energisch auf der Suche nach einer neuen interessanten Liebe. Sie fragen sich, ob Ihr Wunsch nach der Suche nicht größer ist als die Notwendigkeit jemanden zu finden. Könnte es sein, dass Sie Sich ungemein übertreffen?</p>",
                        [GuidanceFields.WORK] : "<p>Wenn Sie derzeit auf der Suche nach einem Job sind, sagt diese Karte, dass Sie in Kurzem eine gute Nachricht empfangen werden. Sie werden wahrscheinlich den Job bekommen und falls Sie den haben, können Sie sicher sein, dass Sie darin glücklich und erfolgreich sein werden.</p><p>Wenn Sie bereits eine gute Stellung haben, sind Sie sehr eifrig und vielleicht sollten Sie darauf achten es nicht zu übertreiben. Sie kennen Ihr eigenes Temperament sehr gut, aber man kann nicht das gleiche von jedem um Sie erwarten.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Wenn Sie den Ritter der Stäbe ziehen, sind Sie auf der Suche nach neuen Herausforderungen und wollen Sie neue Themen studieren, neue Wege entdecken. Sie wollen etwas anderes, etwas Frisches erleben. Und plötzlich finden Sie einen neuen Bereich oder Ansatz zur Spiritualität, der Sie völlig verzaubert und Ihre gespannte Aufmerksamkeit weckt. Auch wenn es eine gute Sache ist, neue Interessen zu finden, ist es auch wichtig, die Aufmerksamkeit auf den alten zu behalten. Das Schenken von Zeit, Energie, und vielleicht sogar Geld für spirituelle Ziele ist ein kräftiger Form zur Meditation.</p>",
                    }
                }
            },
            {
                filename: "34",
                numerology : 13,
                en : {
                    name: "Queen of Wands",
                    suit : "Wands",
                    court : "Queen",
                    element : [ "🜂 - fire", "🜄 - water" ],
                    theme : "taking care of what you want",    
                    description : "Queen of Wands speaks of paying attention to what you want. Make way for what you want",
                    questions : [ "Do you know your heart’s desire?", "Are you aware of it?", "Can you express it?", "Are you a feisty one?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card depicts the feminine power of the element Fire, it stands for fertility, both literally and figuratively. The Queen of Wands is about life energy and inventiveness, sense of initiative, it's about looking at the world with pride and confidence.</p><p>Even though this card is very positive, it may also point out that you might be a bit self-absorbed for the moment. Take the time to ponder on how others perceive you and take that into account.</p><p>Generally, this card carries a very positive energy and meaning.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you get this card it means that you are experiencing or about to experience stormy and stirring times in your relationship. The only thing for you to do now is fully enjoy these experiences. Just make sure you don’t get sidetracked.</p><p>If you are currently single, this is absolutely not the time to be alone. Get out, go out into the world and enjoy whatever situation you get yourself into. No matter where, no matter how, you are very likely to meet someone and find what it is you are looking for.</p>",
                        [GuidanceFields.WORK] : "<p>At work you feel a strong desire to get more responsibility, more independence. Seize every opportunity that presents itself with both hands. You will soon be successful and achieve the results you were craving. This process will help you develop and grow in your job.</p><p>If you're looking for a position, you are very likely to get help from someone you meet. This person will help you find just that one chance you needed to prove yourself.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Queen of Wands indicates that you are experiencing a sort of spiritual hunger. You are on the lookout for new knowledge, new teachings and new insights. You are looking for those everywhere and anywhere. A lot of what you find will be interesting and stimulating but be aware, you might also encounter some false prophets. If you think you have finally found the true meaning of life and living, make sure you discuss this find with friends and relatives. Dissect it, study it thoroughly before you make it your life's new goal.</p>",
                    }
                },
                fr : {
                    name : "La Reine des Bâtons",
                    suit : "Bâtons",
                    court : "Reine",
                    element :  [ "🜂 - feu", "🜄 - eau" ],
                    theme : "prendre soin de ce que vous voulez",    
                    description : "La Reine des Bâtons, nous parle de porter de l’attention à ce que nous voulons. Donnez de l’espace à ce que vous voulez.",
                    questions : ["Savez-vous ce que vous voulez au fond de vous?", "Êtes-vous attentif à cela?", "Pouvez-vous l'exprimer?", "Êtes-vous sévère?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte montre le pouvoir féminin de l'élément feu, il représente la fertilité, au sens propre et figuré. La \"Reine de Bâton\" est synonyme de vie, d'énergie, d'inventivité et d'initiative. Il s'agit de regarder le monde avec fierté et confiance.</p><p>Même si cette carte est très positive, elle peut indiquer que vous êtes un peu trop occupé avec vous-même en ce moment. Prenez le temps de percevoir comment les autres le vivent et d'en tenir compte.</p><p>En général, cette carte a une énergie et une signification très positives.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you get this card it means that you are experiencing or about to experience stormy and stirring times in your relationship. The only thing for you to do now is fully enjoy these experiences. Just make sure you don’t get sidetracked.</p><p>If you are currently single, this is absolutely not the time to be alone. Get out, go out into the world and enjoy whatever situation you get yourself into. No matter where, no matter how, you are very likely to meet someone and find what it is you are looking for.</p>",
                        [GuidanceFields.WORK] : "<p>At work you feel a strong desire to get more responsibility, more independence. Seize every opportunity that presents itself with both hands. You will soon be successful and achieve the results you were craving. This process will help you develop and grow in your job.</p><p>If you're looking for a position, you are very likely to get help from someone you meet. This person will help you find just that one chance you needed to prove yourself.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Queen of Wands indicates that you are experiencing a sort of spiritual hunger. You are on the lookout for new knowledge, new teachings and new insights. You are looking for those everywhere and anywhere. A lot of what you find will be interesting and stimulating but be aware, you might also encounter some false prophets. If you think you have finally found the true meaning of life and living, make sure you discuss this find with friends and relatives. Dissect it, study it thoroughly before you make it your life's new goal.</p>",
                    }
                },
                sp:{
                    name:"La Reina de bastos",
                    suit: "bastos",
                    court: "Reina",
                    element: ["🜂 - fuego", "🜄 - agua" ],
                    theme: "cuida lo que quieres",    
                    description: "La Reina de Bastos se trata de prestar atención a lo que quieres. Da espacio a lo que quieras",
                    questions: ["¿Sabes lo que quieres en el fondo?", "¿Estás alerta en eso?", "¿Puedes expresarlo?", "¿Eres una tía picante?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta carta representa el poder femenino del elemento fuego, que representa la fertilidad, tanto en sentido literal y figurado. La Reina de Varitas se trata de la energía vital y la inventiva, sentido de la iniciativa, se trata de mirar al mundo con orgullo y confianza. A pesar de que esta tarjeta es muy positiva, también puede señalar que usted puede ser un poco de auto-absorbido por el momento. Tómese el tiempo para reflexionar sobre cómo le perciben los demás y tomar eso en cuenta. Generalmente, esta tarjeta tiene una energía muy positiva y significado.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Koningin van Staven",
                    suit : "Staven",
                    court : "Koningin",
                    element : [ "🜂 - vuur", "🜄 - water" ],
                    theme : "zorg dragen voor wat je wilt",    
                    description : "De Koningin van Staven gaat over aandacht schenken aan wat je wilt. Geef wat jij wilt de ruimte",
                    questions : [ "Weet jij wat je diep van binnen wilt?", "Ben jij daar alert op?", "Kun jij dat uiten?", "Ben jij een pittige tante?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart symboliseert de vrouwelijke kracht van het element Vuur. Het gaat hier om vruchtbaarheid, zowel letterlijk als figuurlijk. De Koningin van Staven staat voor levensenergie en vindingrijkheid, voor initiatief nemen. Deze kaart vraagt je om trots en vol vertrouwen naar de wereld te kijken.</p><p>Ook al is deze kaart energetisch heel positief, toch kan er ook een waarschuwing in schuilen: mogelijk ga je op dit moment een beetje teveel in jezelf op. Neem even de tijd om erover na te denken hoe anderen je zien, hoe je overkomt en houd daar dan rekening mee. In een legging geeft deze kaart altijd positieve energie en betekenis aan het geheel.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je deze kaart trekt dan betekent het dat je op dit moment een stormachtige en opwindende tijd beleeft in je relatie. Je hoeft eigenlijk niet meer te doen dan volop genieten van de ervaring. Let wel op dat je niet teveel afgeleid raakt hierdoor; er zijn nog andere dingen in je leven dan enkel je relatie.</p><p>Als je single bent dan is dit voor jou eigenlijk echt geen goede tijd om alleen te zijn. Trek erop uit, ga de wereld verkennen en geniet van alle ervaringen die op je afkomen. Het maakt niet uit waar, het maakt niet uit hoe, het zit er dik in dat je iemand zal ontmoeten en daardoor zal vinden waar je naar op zoek was.</p>",
                        [GuidanceFields.WORK] : "<p>Op werkvlak verlang je tegenwoordig heel sterk naar meer verantwoordelijkheid en onafhankelijkheid. Grijp alle kansen die zich voordoen dan ook met beide handen aan. Gelijk wat je doet, je zal er succes mee boeken en je zal bereiken waar je naar verlangt. Proactief en actief zijn zal ertoe leiden dat je groeit en je volop ontwikkelt in je baan.</p><p>Als je op dit moment op zoek bent naar werk dan zal je binnenkort waarschijnlijk iemand ontmoeten die je daarbij kan helpen. Deze persoon kan je de ideale gelegenheid bieden om jezelf te bewijzen en daardoor een fijne job te vinden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Koningin van Staven wijst je erop dat je op dit moment een soort spirituele honger ervaart. Je bent op zoek naar nieuwe kennis, nieuwe lessen en nieuwe inzichten. Je bent zo leergierig dat je niet weet waar het eerst te gaan zoeken. Je zal natuurlijk veel interessante en stimulerende dingen vinden, de wereld is er vol van. Maar let op voor de valse profeten die je op je weg zal vinden. Als er een moment komt waarop je denkt eindelijk de ware leer gevonden te hebben, ga er dan over praten met je vrienden. Denk erover na, voer er gesprekken over, analyseer alle gegevens. Pas als je dan nog altijd helemaal overtuigd bent, kan dit je nieuwe spirituele overtuiging worden.</p>",
                    }
                },
                de : {
                    name: "Königin der Stäbe",
                    suit : "Stäbe",
                    court : "Königin",
                    element : [ "🜂 - Feuer", "🜄 - Wasser" ],
                    theme : "Sich darum kümmern, was man will.",    
                    description : "Die Königin der Stäbe spricht darüber, darauf zu achten, was wir wollen. Geben Sie dem Raum, was Sie wollen.",
                    questions : [ "Wissen Sie tief im Inneren, was Sie wollen?", "Achten Sie darauf?", "Können Sie es ausdrücken?", "Sind Sie ernst?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte symbolisiert die weibliche Kraft des Elements Feuer, sie steht für Fruchtbarkeit, im wörtlichen und im übertragenen Sinne. Die „Königin der Stäbe“ steht für das Leben, für Energie, für Ideenreichtum und für Eigeninitiative. Es geht darum, die Welt mit Stolz und Zuversicht zu betrachten.</p><p>Auch wenn diese Karte sehr positiv ist kann sie darauf hinweisen, dass Sie in diesem Moment ein bisschen zu viel mit sich selbst beschäftigt sind. Nehmen Sie sich Zeit wahrzunehmen, wie anderen das erfahren und berücksichtigen Sie das.</p><p>Im Allgemeinen hat diese Karte eine sehr positive Energie und Bedeutung.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Falls Sie diese Karte bekommen bedeutet es, dass Sie stürmische und bewegte Zeiten in Ihrer Beziehung erleben. Das einzige, was für Sie jetzt zu tun ist, ist voll und ganz diese Erfahrungen genießen So stellen Sie sicher, dass Sie nicht abgelenkt werden.</p><p>Dies ist absolut nicht der richtige Zeitpunkt um allein zu sein. Raus, raus in die Welt und genießen, egal in welcher Situation Sie geraten. Egal wo, egal wie, es ist sehr wahrscheinlich, dass Sie jemanden treffen und dann gefunden haben werden was Sie suchen.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit fühlen Sie einen starken Wunsch mehr Verantwortung, mehr Unabhängigkeit zu erhalten. Nutzen Sie jede Gelegenheit, die sich anbietet mit beiden Händen. Sie werden bald erfolgreich sein und die Ergebnisse erzielen, die Sie Sich gewünscht haben. Dieser Prozess wird Ihnen helfen Sich zu entwickeln und zu wachsen in Ihrem Job.</p><p>Wenn Sie jetzt eine neue Arbeit suchen, bekommen Sie sehr wahrscheinlich Hilfe von jemanden, den Sie treffen werden. Diese Person wird Ihnen helfen, genau diese Chance zu finden, die Sie brauchen um Sich zu beweisen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Königin der Stäbe zeigt an, dass Sie eine Art von geistigen Hunger empfinden. Sie sind auf der Suche nach neuem Wissen, neuem Lehren und neuen Erkenntnissen. Sie suchen nach den überall und an jedem Ort. Eine Menge von dem, was Sie finden ist interessant und anregend, aber seien Sie Sich bewusst, dass es auch einige falsche Propheten geben kann. Wenn Sie denken, dass Sie endlich die wahre Bedeutung des Lebens und das Leben gefunden haben, dann diskutiere mit Freunden und Verwandten darüber. Zergliedern und studieren Sie es gründlich, bevor Sie es zu einem neuen Ziel in Ihrem Leben machen.</p>",
                    }
                }
            },
            {
                filename: "35",
                numerology : 14,
                en : {
                    name: "King of wands",
                    suit : "Wands",
                    court : "King",
                    element : "🜂 - fire",
                    theme : "actively handle from your own will",    
                    description : "King of Wands speaks of effectively handle from your won will. Shape it.",
                    questions : [ "Are you aware of what you want?", "Are you acting according to it?", "Are you dominant or not?", "Can you stand up for yourself?"],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card depicts the male power of the element Fire, it stands for self confidence, power and greatness. Right now you may rightly feel that you have a lot of confidence in your own capabilities. Be careful not to flaunt this belief. The only thing that matters is that you yourself know what you are capable of, you shouldn't want to put yourself above others. Too great an ego makes you a less great person.</p><p>Right now the only thing that matters is that you have the energy and power to accomplish anything you set your mind to.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card symbolizes the warmthand connectedness you radiate towards the people you love, feelings they reciprocate. You feel close to them and you want to help and support them without asking for anything in return. This sentiment of really unconditionally wanting to give is answered in a cosmic way by you receiving the help you need. When you are giving with an honest and open heart it is always rewarded.</p><p>These are times in which you experience a wealth of positive energy and you tend to use it wisely.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are feeling confident, goal oriented and convinced of your own course. Possibly you meet someone who will act as a mentor for you. Watch and learn, this man has great wisdom for you to feast on. It is a good time to emphasize your organizational and people skills. Be dynamic, show guts and initiative. Still, be careful not to overdo it, not to overact. You are not performing in a theatre show, you’re at a workplace.</p><p>If you are looking for a job it is very likely that you will soon find the position you have been looking for since forever. If someone offers help, accept it graciously, it will surely come in handy.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Lately you have reached a lot of your spiritual goals and you are eager to reach some more, to go forward energetically and actively.</p><p>But now may be a better time to simply stand still, to experience, to listen to silence maybe. This is also part of the spiritual process. Spirituality is not all about reaching goals and working hard, it is also about turning inward and not acting for a while. Try this out and see where it gets you.</p>",
                    }
                },
                fr : {
                    name : "Le Roi de Bâtons",
                    suit : "Bâtons",
                    court : "Roi",
                    element :  "🜂 - feu",
                    theme : "Agir activement de sa propre volonté",    
                    description : "Le Roi des Bâtons, nous parle d’agir à partir de ce que l'on veut. Donner-forme à.",
                    questions : ["Êtes-vous conscient de ce que vous voulez?", "Agissez-vous en conséquence?", "Êtes-vous dominant ou non?", "Pouvez-vous vous affirmer?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente le pouvoir masculin de l'élément feu, elle représente la confiance en soi, le pouvoir et la grandeur. En ce moment, vous pouvez avec raison sentir que vous avez une grande confiance en vos propres capacités. Faites attention de ne pas trop montrer cette croyance. La seule chose qui compte, c'est que vous sachiez de quoi vous êtes capable. Vous ne voulez pas vous mettre au-dessus des autres. Un ego trop grand fait de vous une personne moins grande.</p><p>Pour l'instant, la seule chose qui compte, c'est que vous ayez l'énergie et le pouvoir de réaliser tout ce que vous voulez.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card symbolizes the warmthand connectedness you radiate towards the people you love, feelings they reciprocate. You feel close to them and you want to help and support them without asking for anything in return. This sentiment of really unconditionally wanting to give is answered in a cosmic way by you receiving the help you need. When you are giving with an honest and open heart it is always rewarded.</p><p>These are times in which you experience a wealth of positive energy and you tend to use it wisely.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are feeling confident, goal oriented and convinced of your own course. Possibly you meet someone who will act as a mentor for you. Watch and learn, this man has great wisdom for you to feast on. It is a good time to emphasize your organizational and people skills. Be dynamic, show guts and initiative. Still, be careful not to overdo it, not to overact. You are not performing in a theatre show, you’re at a workplace.</p><p>If you are looking for a job it is very likely that you will soon find the position you have been looking for since forever. If someone offers help, accept it graciously, it will surely come in handy.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Lately you have reached a lot of your spiritual goals and you are eager to reach some more, to go forward energetically and actively.</p><p>But now may be a better time to simply stand still, to experience, to listen to silence maybe. This is also part of the spiritual process. Spirituality is not all about reaching goals and working hard, it is also about turning inward and not acting for a while. Try this out and see where it gets you.</p>",
                    }
                },
                sp:{
                    name:"El Rey de bastos",
                    suit: "bastos",
                    court: "Rey",
                    element: "🜂 – fuego",
                    theme: "Actuar activamente desde tu propia voluntad",    
                    description: "El Rey de Bastas se trata de actuar desde lo que tú quieras. Dale forma",
                    questions: ["¿Eres consciente de lo que quieres?", "¿Estás actuando en consecuencia?", "¿Eres dominante o no?", "¿Puedes defenderte?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta carta representa el poder masculino del elemento fuego, que significa confianza en uno mismo, el poder y la grandeza. En este momento es posible que con razón siente que usted tiene un montón de confianza en sus propias capacidades. Tenga cuidado de no hacer alarde de esta creencia. La única cosa que importa es que su mismo sabe lo que es capaz de hacer, usted no debe querer ponerse por encima de los demás. Demasiado grande un ego que una persona hace menos grande. En este momento lo único que importa es que usted tiene la energía y el poder para lograr cualquier cosa que le propongas.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Koning van Staven",
                    suit : "Staven",
                    court : "Koning",
                    element : "🜂 - vuur",
                    theme : "actief handelen vanuit je eigen willen",    
                    description : "De Koning van Staven gaat over daadwerkelijk handelen vanuit dat wat jij wilt. Geef het vorm.",
                    questions : [ "Ben jij je bewust van wat je wilt?", "Handel jij daar ook naar?", "Ben je dominant of juist niet?", "Kun jij voor jezelf opkomen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart symboliseert de mannelijke kracht van het element Vuur. Dit staat voor zelfvertrouwen, kracht en grootsheid. Op dit moment voel je waarschijnlijk dat je veel vertrouwen hebt in je eigen waarde, mogelijkheden en kracht, en terecht. Wees toch voorzichtig en loop er niet teveel mee te koop. Het enige wat telt is dat jij zelf weet wat je waard bent, je hoeft anderen er niet van te overtuigen en hen zeker niet de indruk te geven dat je je mogelijk beter of sterker voelt dan zij. Immers, naarmate je ego groter wordt, word jij kleiner als mens.</p><p>Het enige wat telt is dat je op dit moment alle nodige kracht en energie hebt om alles te bereiken wat je maar wil.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt over de warmte en verbondenheid die jij uitstraalt naar de mensen waar je van houdt, en dat zij hetzelfde voor jou voelen. Je voelt je heel dicht bij hen en je wil hen helpen en ondersteunen zonder dat je daar iets voor in de plaats wil. Je voelt op dit moment dat je echt onvoorwaardelijk wil geven, er hoeft niets terug te komen. En net omdat je dit voelt, zal je op een kosmische manier datgene krijgen wat je nodig hebt. Als je geeft met een eerlijk en open hart dan wordt dit altijd beloond op een of andere manier, al is het niet altijd op de manier die jij het liefste zou willen.</p><p>Je ervaart op dit moment een rijkdom aan positieve energie en je gaat er verstandig mee om.</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk voel je je zelfzeker, doelgericht en overtuigd van je eigen koers. Het is best mogelijk dat je binnenkort iemand zal ontmoeten die je mentor wordt, waar je veel aan zal hebben. Kijk en leer, deze persoon heeft een schat aan wijsheid waar jij je voordeel mee kan doen.</p><p>Nu is ook een goede tijd om volop aan de slag te gaan met je organisatietalent en sociale vaardigheden. Wees dynamisch, toon lef en initiatief. Maar let er toch ook op dat je niet overdrijft, dat je niet te hevig overkomt. Je staat niet op het toneel, het blijft gewoon een werkplek, ook al barst jij van de energie.</p><p>Als je op dit moment op zoek bent naar werk dan zal je binnenkort waarschijnlijk een geschikte baan vinden. Als iemand aanbiedt om je daarbij te helpen, aanvaard die hulp dan zeker, je zal er veel baat bij hebben.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je hebt de laatste tijd een behoorlijk aantal spirituele doelen bereikt die je jezelf gesteld had. Je bent ook gretig om er nog meer te bereiken, je wil energiek en actief blijven vooruitgaan.</p><p>Maar op dit moment is het beter voor jou om gewoon even stil te staan en dat bewust te ervaren. Luister eens naar de stilte, focus je daarop. Ook zo’n ervaring maakt deel uit van het spirituele groeiproces. Spiritualiteit gaat niet enkel om hard werken en doelen bereiken, het gaat er ook om dat je jezelf naar je eigen kern toekeert en even niets doet. Probeer dit eens uit, doe niets en bekijk welke invloed het op je heeft. Waarschijnlijk zal je merken dat je je geheeld voelt.</p>",
                    }
                },
                de : {
                    name: "König der Stäbe",
                    suit : "Stäbe",
                    court : "König",
                    element : "🜂 - Feuer",
                    theme : "Aus freiem Willen aktiv handeln",    
                    description : "Der König der Stäbe spricht über das Handeln gemäss dem, was wir wollen. Geben Sie ihm Form.",
                    questions : [ "Sind Sie sich dessen bewusst, was Sie wollen?", "Handeln Sie auch dementsprechend?", "Sind Sie dominant oder nicht?", "Können Sie sich durchsetzen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte stellt die männliche Kraft des Elementes Feuer dar, sie vertritt das Selbstvertrauen, die Kraft und die Größe. Gerade jetzt können Sie zu Recht das Gefühl haben, dass Sie ein großes Vertrauen in Ihren eigenen Fähigkeiten haben. Achten Sie darauf, diesen Glauben nicht zu viel zur Schau zu stellen. Das Einzige, was zählt, ist, dass Sie selbst wissen, wozu Sie in der Lage sind. Sie wollen sich ja nicht über andere stellen. Ein zu großes Ego macht Sie zu einem weniger großartigen Menschen.</p><p>Im Augenblick ist das Einzige, was zählt, dass Sie die Energie und Kraft haben, alles zu erreichen, was Sie erreichen wollen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte symbolisiert die warmherzige Verbundenheit die Sie ausstrahlen gegenüber den Menschen, die Sie lieben, Gefühle, die sie auch beantworten. Sie fühlen sich in der Nähe von ihnen und helfen und unterstützen sie ohne etwas als Gegenleistung zu verlangen. Dieses Gefühl des wirklich bedingungslos Gebens wird auf einer kosmischen Art durch von Ihnen benötigten Hilfe beantwortet. Wenn Sie mit einem ehrlichen und offenen Herzen geben, wird das immer belohnt.</p><p>Dies sind Zeiten, in denen Sie eine Fülle an positive Energie erleben und Sie neigen dazu, sie weise zu nutzen.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit fühlen Sie Sich zuversichtlich, zielorientiert und von Ihren eigenen Kurs überzeugt. Möglicherweise begegnen Sie jemanden, der als Mentor für Sie auftreten wird. Beobachten Sie ihn und lernen Sie von ihm, dieser Mann hat große Weisheit für Sie und ist ein Fest. Es ist eine gute Zeit, um Ihre organisatorische und soziale Kompetenz zu betonen. Seien Sie dynamisch, zeigen Sie Mut und Initiative. Doch seien Sie vorsichtig, nicht zu übertreiben. Sie sind nicht auf der Bühne in einem Theatershow, Sie sind an einem Arbeitsplatz.</p><p>Wenn Sie auf der Suche sind nach einem Job ist es sehr wahrscheinlich, dass Sie bald die Position die Sie schon seit ewigen Zeiten suchen, finden. Wenn jemand Hilfe bietet, nehmen Sie diese dann gnädig an, es wird sicherlich als nützlich erweisen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In letzter Zeit haben Sie einen großen Teil Ihrer spirituellen Ziele erreicht und Sie hätten gerne etwas mehr erreichen wollen, um weiter energisch und aktiv voran zu gehen.</p><p>Aber jetzt könnte es eine bessere Zeit sein, einfach still zu stehen, zu erleben, um vielleicht Stille zu hören. Dies ist auch ein Teil des spirituellen Prozesses. Spiritualität ist nicht alles über erreichte Ziele und das harte arbeiten daran, es ist auch über das nach innen Kehren und für eine Weile nicht handeln. Probieren Sie das mal aus und sehen Sie mal, wie es Ihnen bekommt.</p>",
                    }
                }
            },
            {
                filename: "36",
                numerology : 1,
                en : {
                    name: "Ace of Cups",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "I feel",    
                    description : "Ace of Cups, element water,  talks about your emotions in their totality. Everything is potentially present, but what do you feel?",
                    questions : [ "Are you open to new feelings?", "Is it your own feeling or is it 'imposed'?", "Can you show your emotions?", "What do you actually feel?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Ace of Cups is one of the most auspicious cards of the whole Tarot deck and drawing this card tells you that you are entering a very positive stage in your life. The card signifies that you have a lot of potential. If you become more conscious of this potential and start using it your life will change for the better.</p><p>The Ace of Cups will bring you joy, gratitude and a feeling of unity, togetherness. The positivity this card brings will be focused mostly on relationships and emotions. It often also signifies that you will experience a new start, new beginnings. You will also experience a lot of good will from the people around, it will be very easy to make new friends now.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card is a good omen where love is concerned. If you already are in a loving relationship, you will probably experience deep and unconditional love. You may even want to take the relationship a step further and truly commit by getting engaged or even getting married.</p><p>If you are looking for love, right now you have every chance of finding it. Falling in love will come easy and you will probably get the whole package: butterflies, moments of intense joy and harmony, passion, … Go for it and savor every moment!</p>",
                        [GuidanceFields.WORK] : "<p>When you draw the Ace of Cups things should be going pretty well for you at work. Your job gives you a deep sense of fulfillment and it is obvious that the things you do are appreciated by your coworkers and superiors. You may be getting a promotion or a raise, or maybe you will get new tasks you will enjoy even more. Your communication skills are excellent right now, put them to good use!</p><p>If you are looking for a job you are really confident and fully aware of your qualities. Your job interviews will go very well and you will have no problem at all convincing people of your values.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card is all about positivity, energy, feeling good. On a spiritual level it may be a good idea to share some of this positivity. Use it to get closer to everyone around you, to the universe as a whole. Share some of your happiness and wellbeing, some of your positive energy. Giving a lot also means receiving a lot right now. And doing so will help you to keep connected to the universal energy we all are a part of. Meditate on feeling loved, united with the cosmos, being basked in light. In doing so you will truly feel energetic and energized!</p>",
                    }
                },             
                fr : {
                    name : "l’As de Coupes",
                    suit : " Coupes",
                    element :  "🜄 - eau",
                    theme : "Je sens",    
                    description : "L'As de Coupes, assorti à l'élément eau, nous parle de nos émotions dans leur intégralité. Tout est potentiellement présent, mais que ressentez-vous?",
                    questions : ["Êtes-vous ouvert à un nouveau sentiment?", "Est-ce votre propre sentiment ou est-il \"forcé?\"", "Pouvez-vous laisser couler votre propre sentiment?", "Que ressentez-vous réellement?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>L'As de Coupe est l'une des cartes les plus prometteuses de tout le Tarot. Si vous tirez cette carte, elle vous indique que vous êtes dans une phase très positive de votre vie. La carte indique que vous avez un grand potentiel. Au fur et à mesure que vous prendrez conscience de ce potentiel et que vous commencerez à l'utiliser, votre vie changera toujours en mieux !</p><p>L'\"As de Coupe\" vous apporte joie, gratitude et un sentiment d'unité, de communion et de solidarité. Cette carte positive fait essentiellement référence aux relations et aux émotions. Cela signifie souvent que vous prendrez un nouveau départ. Vous éprouverez aussi beaucoup de bonne volonté de la part des gens qui vous entourent. Maintenant, il sera facile de vous faire de nouveaux amis ou connaissances....  Si vous essayez de tomber enceinte, cette carte est un signe très positif.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card is a good omen where love is concerned. If you already are in a loving relationship, you will probably experience deep and unconditional love. You may even want to take the relationship a step further and truly commit by getting engaged or even getting married.</p><p>If you are looking for love, right now you have every chance of finding it. Falling in love will come easy and you will probably get the whole package: butterflies, moments of intense joy and harmony, passion, … Go for it and savor every moment!</p>",
                        [GuidanceFields.WORK] : "<p>When you draw the Ace of Cups things should be going pretty well for you at work. Your job gives you a deep sense of fulfillment and it is obvious that the things you do are appreciated by your coworkers and superiors. You may be getting a promotion or a raise, or maybe you will get new tasks you will enjoy even more. Your communication skills are excellent right now, put them to good use!</p><p>If you are looking for a job you are really confident and fully aware of your qualities. Your job interviews will go very well and you will have no problem at all convincing people of your values.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card is all about positivity, energy, feeling good. On a spiritual level it may be a good idea to share some of this positivity. Use it to get closer to everyone around you, to the universe as a whole. Share some of your happiness and wellbeing, some of your positive energy. Giving a lot also means receiving a lot right now. And doing so will help you to keep connected to the universal energy we all are a part of. Meditate on feeling loved, united with the cosmos, being basked in light. In doing so you will truly feel energetic and energized!</p>",
                    }
                },       
                sp:{
                    name:"As de copas",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "Siento",    
                    description: "El As de Copas, que coincide con el elemento agua, trata de tus emociones en su totalidad. Todo está potencialmente presente, pero ¿qué sientes?",
                    questions: ["¿Estás abierto a un nuevo sentimiento?", "¿Es tu propio sentimiento o es \"forzado\"?", "¿Puedes dejar  fluir tu propio sentimiento?", "¿Qué es lo que realmente sientes?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El As de Copas es una de las cartas más propicias de toda la baraja del Tarot y sacando esta tarjeta le dice que está entrando en una etapa muy positiva en su vida. La tarjeta significa que usted tiene un gran potencial. Si se vuelves más consciente de este potencial y empezar a usarlo su vida va a cambiar para el mejor. El As de Copas le traerá alegría, gratitud y un sentimiento de unidad. La positividad esta tarjeta trae se centrará sobre todo en las relaciones y emociones. A menudo también significa que usted experimentará un nuevo comienzo. También experimentará un montón de buena voluntad de la gente alrededor, va a ser muy fácil conseguir nuevos amigos ahora.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },      
                nl : {
                    name: "Beker Aas",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "ik voel",    
                    description : "De Aas van Bekers, passend bij het element water, gaat over je emoties in zijn totaliteit. Alles is in potentie aanwezig, maar wat voel jij?",
                    questions : [ "Sta jij open voor een nieuw gevoel?", "Is het je eigen gevoel of is het 'opgedrongen'?", "Kun jij je eigen gevoel laten stromen?", "Wat voel jij eigenlijk?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Aas van Bekers is een van de meest positieve kaarten van de hele Tarot. Als je deze kaart trekt dan is het een duidelijk teken dat je onderweg bent naar een heel positieve fase in je leven. Deze Aas vertelt je dat je veel potentieel hebt. Het is nu zaak om je daar steeds bewuster van te worden en dat potentieel ook ten volle te gaan gebruiken. Je zal merken dat dit een grote verbetering zal betekenen voor je levenskwaliteit!</p><p>De Aas van Bekers zal je vreugde brengen, dankbaarheid en een gevoel van eenheid, van samenzijn. De positiviteit die deze kaart inhoudt zal vooral voelbaar zijn op het gebied van relaties, gevoelens en emoties. Vaak zal je merken dat je een nieuwe start krijgt, een nieuw begin. En je zal ook veel goede wil en een positieve instelling ervaren van de mensen om je heen. Het wordt nu echt gemakkelijk om nieuwe mensen te leren kennen, nieuwe vrienden te maken.</p><p>Als je graag zwanger wil worden dan is deze kaart een heel positief teken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart is een positief voorteken op liefdesvlak. Als je een liefdesrelatie hebt dan ervaar je nu waarschijnlijk diepe en onvoorwaardelijke liefde. Het is ook best mogelijk dat je je relatie naar het volgende niveau wil brengen en dat je je echt gaat engageren door je te verloven, te gaan samenwonen, te trouwen of aan kinderen te beginnen. Nu is er geen ruimte voor twijfel en onzekerheid: de gevoelens van jou en je partner zijn oprecht en intens, handel ernaar.</p><p>Als je op zoek bent naar liefde dan heb je nu alle kansen om die ook te vinden. Je zal merken dat het je gemakkelijk valt om verliefd te worden en dat je het ook intens beleeft: vlinders in de buik, euforie, intense vreugde en harmonie, diepe passie ... ga ervoor en geniet van elk moment!</p>",
                        [GuidanceFields.WORK] : "<p>Als je nu de Aas van Bekers trekt dan zit het waarschijnlijk behoorlijk goed wat je werk betreft. Je haalt een intens gevoel van tevredenheid en vervulling uit je baan en het is duidelijk dat je collega’s en bazen je enorm waarderen voor wat je doet en wie je bent. Het is ook best mogelijk dat je binnenkort opslag krijgt of zelfs een promotie, of je krijgt een nieuw takenpakket dat je nog fijner zal vinden dan het huidige. Je communicatieve vaardigheden zijn op dit ogenblik ook heel sterk, maak er ten volle gebruik van!</p><p>Als je op zoek bent naar werk dan mag je nu vol vertrouwen zijn en je ten volle bewust zijn van je kwaliteiten. Je zal heel goed tot je recht komen tijdens interviews en je vindt het geen enkel probleem om mensen te overtuigen van je vaardigheden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart heeft het over een positieve instelling, energie en je goed voelen. Op spiritueel niveau is het een goed idee om al deze positiviteit te delen met anderen. Gebruik je energie om nader te komen tot de mensen om je heen en zelfs tot het universum als geheel. Deel je vreugde en je goede gevoel en deel zeker wat van dat positieve uit aan iedereen die op je weg komt. Op dit moment is het zo dat hoe meer je geeft, hoe meer je zal ontvangen, het is gelijk oversteken. En net door al dat geven zal je merken dat het alsmaar gemakkelijker wordt om verbonden te zijn en te blijven met de universele energie waar we allemaal deel van uitmaken. Mediteer op je gevoel van liefde, eenheid met de kosmos, baden in het energetische licht. Hierdoor zal je je helemaal stralend en opgeladen voelen!</p>",
                    }
                },              
                de : {
                    name: "Ass der Kelche",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Ich fühle",    
                    description : "Das Ass der Kelche, dem Wasserelement zugeordnet, spricht von unseren Emotionen in ihrer Gesamtheit. Alles ist potentiell vorhanden, aber was spüren Sie?",
                    questions : [ "Sind Sie offen für ein neues Gefühl?", "Ist es Ihr eigenes Gefühl oder ist es \"erzwungen\"?", "Können Sie Ihren eigenen Gefühl freien Lauf lassen?", "Was fühlen Sie wirklich?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Das „Ass der Kelche“ ist eine der vielversprechendsten Karten des gesamten Tarot. Falls Sie diese Karte ziehen, sagt sie Ihnen, dass Sie sich in einer sehr positiven Phase Ihres Leben befinden. Die Karte zeigt an, dass Sie über großes Potenzial verfügen. Wenn Sie sich dieses Potenzials stärker bewusst werden und beginnen, es zu nützen, wird Ihr Leben sich in jedem Fall zum Besseren zu verändern!</p><p>Das „Ass der Kelche“ bringt Ihnen Freude, Dankbarkeit und ein Gefühl der Einheit, der Zusammengehörigkeit. Diese positive Karte bezieht sich im Wesentlichen auf Beziehungen und Emotionen. Dies bedeutet oft, dass Sie einen neuen Anfang machen werden. Sie werden auch viel Wohlwollen von den Menschen in Ihrer Umgebung erfahren. Jetzt wird es Ihnen leichtfallen, neue Freunde oder Bekanntschaften zu machen. Wenn Sie versuchen, schwanger zu werden, ist diese Karte ein sehr positives Zeichen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte ist ein gutes Omen, wo es die Liebe anbelangt. Wenn Sie bereits in einer liebevollen Beziehung sind, werden Sie wahrscheinlich eine tiefe und bedingungslose Liebe erleben. Sie möchten vielleicht sogar die Beziehung einen Schritt weiter helfen und sogar würden Sie Sich verloben oder sogar heiraten.</p><p>Wenn Sie auf der Suche nach Liebe sind, haben Sie jetzt alle Chancen sie zu finden. Sich verlieben kommt von alleine und Sie werden wahrscheinlich das ganze Paket dazu bekommen: Schmetterlinge, Momente intensiver Freude und Harmonie, Leidenschaft … Gehen Sie dafür und genießen Sie jeden Augenblick!</p>",
                        [GuidanceFields.WORK] : "<p>Wenn Sie das Ass der Kelchen ziehen laufen die Dinge eigentlich ziemlich gut für Sie bei der Arbeit. Ihre Arbeit gibt Ihnen ein tiefes Gefühl der Erfüllung und es ist offensichtlich, dass Sie durch Ihre Kollegen und Vorgesetzten sehr geschätzt werden. Möglicherweise steht eine Beförderung oder eine Gehaltserhöhung bevor, oder vielleicht werden Sie neue Aufgaben bekommen, die Sie noch mehr genießen. Ihre kommunikativen Fähigkeiten sind jetzt sehr gut, benützen Sie sie für einen guten Zweck!</p><p>Wenn Sie auf der Suche nach einem Job sind Sie im Moment wirklich sicher und umfassend über Ihre Qualitäten. Ihre Vorstellungsgespräche werden sehr gut verlaufen und Sie werden keine Probleme begegnen andere zu überzeugen von Ihren Fähigkeiten.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Diese Karte handelt über Positivität, Energie und ein gutes Gefühl. Auf einer spirituellen Ebene kann es eine gute Idee sein, diese Positivität zu teilen. Verwenden Sie sie um näher an jedem um Sie herum zu geraten, an dem Universum an sich. Teilen Sie Ihr Glück und Wohlbefinden, Ihre positive Energie. Viel geben bedeutet in diesem Moment auch viel empfangen. Und dies wird Ihnen helfen, verbunden zu sein mit der universellen Energie von der wir alle ein Teil sind. Meditiere über das Gefühl geliebt zu werden, vereint mit dem Kosmos, im Licht gebadet. Dabei werden Sie wirklich das Gefühl haben energisch zu sein und voll Energie empfangen zu können!</p>",
                    }
                }
            },
            {
                filename: "37",
                numerology : 2,
                en : {
                    name: "Two of Cups",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "contradictory feelings",    
                    description : "Two of Cups talks about a duality in your feelings and the benefits of sharing our feelings equally with others.",
                    questions : [ "Are you feeling doubts?", "Can you share your feelings without feeling yourself less or better than the other?", "Can you share your deepest feelings with some one?", "Can you feel how beneficial this is?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about balance, love, passion, sympathy and spontaneous connections. If you draw Cups Two it can mean that your love relationship is going very smoothly at the moment. It can also mean that there is a cheerful, positive atmosphere at work and that you have a good contact with your colleagues. Right now is definitely a good time to get to know new people. Just be yourself and you will notice that you are welcomed with open arms.</p><p> On the other hand, this card can sometimes indicate that you have conflicting feelings related to people and/or situations in your life, feelings that you cannot reconcile. These feelings cause problems and in order to solve them it is important that you listen to your deepest core, your intuition. Then you will know which side to choose.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that your relationship is built on solid foundations and you both are truly committed. You may feel as if you are the perfect couple. Still, this doesn’t mean you can do anything you want right now. Every relationship, even an almost perfect one, needs work. The foundations are solid but you have to keep building and maintaining the house every day.</p><p>If you are currently single, this card tells you that love truly is in the air right now. Go out, have fun, relax and enjoy the ride, you will soon meet a potential partner!</p>",
                        [GuidanceFields.WORK] : "<p>No matter if you really love your job or just plain accept it, right now the atmosphere at work is very good for the moment. Your colleagues and superiors like you and appreciate the things you do. If you are thinking about changing jobs, now is a good time to go out and look for new possibilities. And if you are currently unemployed, this card tells you it’s very likely you will soon find a job that suits you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You seem to be spiritually growing, your emotions are becoming deeper and more grounded. You intensely enjoy coming into contact with others, sharing with them your love for the universe and for everything and everyone that is a part of it. You feel open and positive towards all that is. Now is the time to share that love, that joy with others. In doing so you will make a lasting impression on everyone you meet.</p>",
                    }
                },
                fr : {
                    name : "Le Deux de Coupe",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "Sentiments contradictoires",    
                    description : " Le Deux de Coupe nous parle de la dualité de nos sentiments et des effets bénéfiques de partager nos sentiments, à égalité, avec d'autres.",
                    questions : ["Avez-vous, instinctivement, des doutes?", "Osez-vous partager vos sentiments, sans vous sentir plus ou moins que l'autre?", "Pouvez-vous partager vos sentiments les plus profonds avec quelqu'un?", "Pouvez-vous sentir combien cela vous est bénéfique ?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente l'équilibre, l'amour, la passion, la compassion et les relations spontanées. Cela peut indiquer que les choses se développent très bien dans votre relation amoureuse. Cela peut aussi signifier qu'il y a une atmosphère de travail positive et agréable et une bonne coopération avec vos collègues. C'est le moment idéal pour rencontrer de nouvelles personnes et établir des relations étroites et chaleureuses. Vous pouvez même trouver une âme sœur ! N'hésitez pas à vous ouvrir aux personnes que vous rencontrez. Vous serez chaleureusement embrassés.</p><p>D'un autre côté, cette carte peut dire que vous avez des sentiments ambivalents et contradictoires au sujet de certaines personnes ou situations de votre vie.  Pour résoudre ces contradictions, il est très important que vous écoutiez vos sentiments et votre intuition. Alors vous saurez où aller.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that your relationship is built on solid foundations and you both are truly committed. You may feel as if you are the perfect couple. Still, this doesn’t mean you can do anything you want right now. Every relationship, even an almost perfect one, needs work. The foundations are solid but you have to keep building and maintaining the house every day.</p><p>If you are currently single, this card tells you that love truly is in the air right now. Go out, have fun, relax and enjoy the ride, you will soon meet a potential partner!</p>",
                        [GuidanceFields.WORK] : "<p>No matter if you really love your job or just plain accept it, right now the atmosphere at work is very good for the moment. Your colleagues and superiors like you and appreciate the things you do. If you are thinking about changing jobs, now is a good time to go out and look for new possibilities. And if you are currently unemployed, this card tells you it’s very likely you will soon find a job that suits you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You seem to be spiritually growing, your emotions are becoming deeper and more grounded. You intensely enjoy coming into contact with others, sharing with them your love for the universe and for everything and everyone that is a part of it. You feel open and positive towards all that is. Now is the time to share that love, that joy with others. In doing so you will make a lasting impression on everyone you meet.</p>",
                    }
                },
                sp:{
                    name:"El Dos de copas",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "Sentimientos conflictivos",    
                    description: "El Dos de Copas trata sobre la dualidad en tus sentimientos y nos señala el efecto beneficioso cuando compartimos nuestros sentimientos, en igualdad de condiciones, con otra persona",
                    questions: ["¿Tienes tus dudas instintivamente?", "¿Te atreves a compartir tus sentimientos, sin sentirte más o menos que el otro", "¿Puedes compartir tus sentimientos más profundos con alguien? ], ¿Puedes sentir qué beneficioso es eso?"],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es sobre el amor, la pasión, la simpatía y conexiones espontáneas. Esto puede indicar que las cosas van muy bien en su relación romántica, o puede haber una atmósfera positiva, feliz en el trabajo y una buena relación con sus compañeros. Ahora es el momento perfecto para conocer gente nueva y construir relaciones fuertes y cálidas. Usted puede incluso descubrir un alma gemela! No dude en abrir a personas que conozca, para realmente ser su mismo. Le daremos la bienvenida con los brazos abiertos. A veces, esta carta indica que usted está teniendo contradictorio, conflictivo sentimientos sobre personas o situaciones en su vida. Para resolver estos problemas y averiguar qué hacer con ellos es importante que usted escuches su sentimiento más íntimo, su intuición. Entonces usted sabrá qué hacer.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Bekers Twee",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "tegenstrijdige gevoelens",    
                    description : "De Twee van Bekers gaat over dualiteit in je gevoel en wijst ons op de heilzame werking wanneer we onze gevoelens, in gelijkwaardigheid, delen met een ander.",
                    questions : [ "Heb je, gevoelsmatig, je twijfels?", "Durf jij je gevoel te delen, zonder je meer of minder dan die ander te voelen?", "Kun jij met iemand je diepste gevoelens delen?", "Kun je voelen hoe heilzaam dat is?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart heeft als onderwerp evenwicht, liefde, passie, sympathie en spontane connecties. Als je Bekers Twee trekt kan het betekenen dat je liefdesrelatie heel soepel verloopt op dit moment. Het kan ook betekenen dat er op je werk een vrolijke, positieve sfeer hangt en dat je een goed contact hebt met je collega’s. Op dit moment is het absoluut een goede tijd om nieuwe mensen te leren kennen. Wees gewoon jezelf en je zal merken dat je met open armen verwelkomd wordt.</p><p>Aan de andere kant kan deze kaart er soms ook op wijzen dat je tegenstrijdige gevoelens hebt in verband met mensen en/of situaties in je leven, gevoelens die je niet met mekaar kan verzoenen. Deze gevoelens zorgen voor problemen en om die op te lossen is het belangrijk dat je luistert naar je diepste kern, naar je intuïtie. Dan zal je weten welke kant je moet kiezen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment lijkt het erop dat je (liefdes)relatie gebouwd is op stevige fundamenten en dat jullie allebei totaal betrokken en geëngageerd zijn. Het voelt alsof jullie het perfecte koppel zijn. Toch betekent dit niet dat je zomaar alles kan doen waar je zin in hebt. Elke relatie, zelfs een bijna perfecte, vraagt werk en onderhoud. Ook al is jullie basis heel stevig, je moet toch elke dag aan de slag gaan om verder te bouwen en alles te onderhouden.</p><p>Als je op dit moment alleen bent dan vertelt deze kaart je dat er echt wel liefde in de lucht hangt. Ga op stap, doe leuke dingen, ontspan je en geniet en je zal merken dat er al gauw een potentiële geliefde op je weg komt!</p>",
                        [GuidanceFields.WORK] : "<p>Misschien houd je heel veel van je baan en ben je er totaal aan toegewijd. Of misschien aanvaard je het werken gewoon als iets dat je nu eenmaal moet doen om je leven te bekostigen. Hoe je ook in je job staat, op dit moment is de sfeer op het werk heel fijn. Je kan goed opschieten met je collega’s en je meerderen en je merkt dat ze alles wat je doet ook echt waarderen. Je voelt je positief en ontspannen.</p><p>Als je aan het overwegen bent om van baan te veranderen, dan is het er nu het juiste moment voor. Trek erop uit en ga op zoek naar andere mogelijkheden, je zal niet teleurgesteld worden!</p><p>Als je nu werkloos bent en op zoek naar een baan, dan vertelt deze kaart je dat je binnenkort succesvol zal zijn in je zoektocht. Houd het nog even vol, je zal binnenkort een aantal mooie kansen krijgen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment ben je spiritueel volop aan het groeien. Je merkt dat je emoties en inzichten diepgaander aan het worden zijn en je voelt je algemeen meer gegrond. Je geniet er nu ook intens van om in contact te komen met anderen, om met hen jouw liefde voor het universum (en voor alles en iedereen die er deel van uitmaakt) te delen. Je voelt je open en positief tegenover alles wat bestaat. Nu is het perfecte tijdstip om deze liefde en vreugde met anderen te delen. Door dit te doen zal je niet enkel een diepe en blijvende indruk maken op iedereen die je ontmoet, je zal ook je spirituele kern voelen groeien.</p>",
                    }
                },
                de : {
                    name: "Zwei der Kelche",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Widersprüchliche Empfindungen",    
                    description : "Die Zwei der Kelche spricht von der Dualität unserer Gefühle und den positiven Auswirkungen, wenn wir unsere Gefühle auf Augenhöhe mit anderen teilen.",
                    questions : [ "Haben Sie instinktiv Zweifel?", "Trauen Sie sich, Ihre Gefühle zu teilen, ohne sich besser oder geringer zu fühlen als die anderen?", "Können Sie Ihre tiefsten Gefühle mit jemandem teilen?", "Können Sie spüren, wie gut Ihnen das tut?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für Gleichgewicht, Liebe, Leidenschaft, Mitgefühl und spontane Verbindungen. Sie kann darauf hinweisen, dass sich die Dinge in Ihrer romantischen Beziehung sehr reibungslos entwickeln. Sie kann auch bedeuten, dass es eine positive, gemütliche Atmosphäre in der Arbeit und eine gute Zusammenarbeit mit Ihren Kollegen gibt. Jetzt ist die perfekte Zeit, um neue Leute kennenzulernen und enge, warme Beziehungen aufzubauen. Sie können sogar einen Seelenverwandten finden! Zögern Sie nicht, sich Menschen, denen Sie begegnen, zu öffnen. Man wird Sie warmherzig umarmen.</p><p>Auf der anderen Seite kann diese Karte sagen, dass Sie im Hinblick auf bestimmte Menschen oder Situationen in Ihrem Leben zwiespältige, widersprüchliche Gefühle haben. Damit diese Widersprüche aufgelöst werden können ist es sehr wichtig, dass Sie auf Ihre Gefühle und auf Ihre Intuition hören. Dann werden Sie wissen, welchen Weg Sie gehen müssen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte sagt Ihnen, dass Ihre Beziehung auf einem soliden Fundament gebaut ist und Sie beide wirklich einander gegenüber engagiert sind. Sie können Sich als das perfekte Paar fühlen. Dennoch bedeutet dies nicht, Sie können alles machen, was Sie wollen. Jede Beziehung, auch eine nahezu perfekte, braucht Pflege. Die Grundlagen sind solide, aber Sie müssen jeden Tag weiter bauen, um das Haus zu perfektionieren und zu pflegen. Falls Sie im Moment alleine sind sagt diese Karte, dass die Liebe jetzt in der Luft ist. Gehen Sie aus, machen Sie Spaß, entspannen Sie Sich und genießen Sie die Fahrt. Sie werden bald einen potenziellen Partner treffen!</p>",
                        [GuidanceFields.WORK] : "<p>Egal, ob Sie Ihren Job wirklich lieben oder einfach im Moment nur akzeptieren, gerade jetzt ist die Atmosphäre in der Arbeit sehr gut. Ihre Kollegen und Vorgesetzten schätzen Sie und die Dinge, die Sie tun. Wenn Sie über einen Wechsel des Arbeitsplatzes denken, ist es jetzt eine gute Zeit aus zu gehen und sich umzusehen nach neuen Möglichkeiten. Und wenn Sie jetzt arbeitslos sind, sagt diese Karte Ihnen, dass es sehr wahrscheinlich ist, dass Sie in Kurzem genau den Job finden werden, der zu Ihnen passt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie scheinen spirituell zu wachsen, Ihre Gefühle werden immer tiefer und gegründet. Sie genießen intensiv die Kontakte mit anderen, um mit ihnen Ihre Liebe für das Universum zu teilen und Ihre Liebe für alles und jeden der einen Teil von ihr ist. Sie fühlen sich offen und positiv gegenüber allem, was da ist. Jetzt ist die Zeit diese Liebe und diese Freude mit anderen zu teilen. Dabei werden Sie einen bleibenden Eindruck auf jedem von ihnen machen.</p>",
                    }
                }
            },
            {
                filename: "38",
                numerology : 3,
                en : {
                    name: "Three of Cups",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "making room for your feelings",    
                    description : "Three of Cups talks about making room for your feelings and sharing them.",
                    questions : [ "Can you share your feelings?", "What could you celebrate joyfully?", "Which feelings would you like to share with others?", "Whom will you share those feelings with?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This is a festive card, it is about celebration. It is often a reunion that is being celebrated but it can also be a wedding or a birth or any other happy occasion.</p><p>It is also possible that you are being or will be reunited with someone of something you have been missing for some time. Or maybe you have accomplished something that makes you feel fulfilled and content.</p><p>In general, when you draw this card, you will be feeling careless and grateful and your life will be harmonious and full of joy.</p><p>Sometimes this card indicates that financial or material wealth is coming your way.</p><p>Anyway, this is a time to relax and enjoy and to share your wellbeing with the ones you love!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This is a time when you feel like helping people and they feel the same towards you. You want to be socially active, enjoy the company of others, generally be open to people and what they have to offer. You are happy and fulfilled in your relationship and you want to share this with everyone around you. Why not throw a party?</p><p>If you are single, it is very likely that you will soon meet someone who really is what you were looking for. But if you catch up with an “old” love, this will probably not be the one. Even if this cards often hints at reunions, the right person for you to have a relationship with is likely not to be found in your past.</p>",
                        [GuidanceFields.WORK] : "<p>When you draw this card you should also be rather positive about work right now. Maybe you are in line for a promotion or a raise. Or maybe your superiors are very outspoken about your qualities and achievements. If you want to change jobs, now is the perfect time.</p><p>If you are currently looking for a position, don’t give up, keep looking. Some pretty good opportunities should be coming your way right about now. If someone from your past contacts you regarding work, this may be the perfect opportunity.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Maybe you are the kind of person who enjoys spirituality on your own. You may like to meditate, visualize, ponder all by yourself. Still, it may be a good idea to go out and mingle, to find people who can inspire you, who can help you see totally new spiritual paths and challenges. If you do so, you may have a new and meaningful experience that completely changes your spiritual awareness.</p><p>These new “teachers” don’t have to be gurus or specialists, for all you know it can be your neighbor who helps you “see the light”.</p>",
                    }
                },
                fr : {
                    name : "Le Trois de Coupe",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "Laisser l’espace à vos sentiments",    
                    description : "Le Trois de Coupes, nous parle de donner l'espace à ce que nous ressentons et nous autorise à exprimer nos sentiments.",
                    questions : ["Pouvez-vous exprimer vos sentiments?", "Que pourriez-vous fêter joyeusement?", "Quel sentiment aimeriez-vous partager avec les autres?", "Avec qui allez-vous faire cela?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Il s'agit d'une carte solennelle qui symbolise les célébrations. Souvent, c'est une retrouvaille qui est célébrée, mais cela peut aussi être un mariage, un anniversaire ou tout autre événement joyeux.</p><p>Ce sera peut-être une réunion avec quelqu'un ou quelque chose qui vous manquait depuis un certain temps. Ou peut-être qu'il y a une réalisation qui vous comble de chaleur et de satisfaction.... Si vous tirez cette carte, vous vous sentirez généralement calme, insouciant et reconnaissant. Votre vie sera harmonieuse et pleine de joie.</p><p>Parfois, cette carte indique que vous pouvez vous attendre à une richesse financière ou matérielle. Quoi qu'il en soit, c'est le moment de vous détendre, de profiter et de partager votre bien-être avec vos proches !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This is a time when you feel like helping people and they feel the same towards you. You want to be socially active, enjoy the company of others, generally be open to people and what they have to offer. You are happy and fulfilled in your relationship and you want to share this with everyone around you. Why not throw a party?</p><p>If you are single, it is very likely that you will soon meet someone who really is what you were looking for. But if you catch up with an “old” love, this will probably not be the one. Even if this cards often hints at reunions, the right person for you to have a relationship with is likely not to be found in your past.</p>",
                        [GuidanceFields.WORK] : "<p>When you draw this card you should also be rather positive about work right now. Maybe you are in line for a promotion or a raise. Or maybe your superiors are very outspoken about your qualities and achievements. If you want to change jobs, now is the perfect time.</p><p>If you are currently looking for a position, don’t give up, keep looking. Some pretty good opportunities should be coming your way right about now. If someone from your past contacts you regarding work, this may be the perfect opportunity.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Maybe you are the kind of person who enjoys spirituality on your own. You may like to meditate, visualize, ponder all by yourself. Still, it may be a good idea to go out and mingle, to find people who can inspire you, who can help you see totally new spiritual paths and challenges. If you do so, you may have a new and meaningful experience that completely changes your spiritual awareness.</p><p>These new “teachers” don’t have to be gurus or specialists, for all you know it can be your neighbor who helps you “see the light”.</p>",
                    }
                },
                sp:{
                    name:"El Tres de copas",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "Da espacio a lo que sientes",    
                    description: "El Tres de Copas trata de dar espacio a lo que sientes y señalarnos que se nos permita expresar nuestros sentimientos",
                    questions: ["¿Puedes expresar tus sentimientos?", "¿Qué podrías celebrar con alegría?", "¿Qué sentimiento te gustaría compartir con otros?", "¿Con quién vas a hacer eso?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta es una tarjeta de festivo, se trata de la celebración. A menudo es una reunión que se celebra, pero también puede ser una boda o un nacimiento o cualquier otra ocasión feliz. También es posible que usted estés siendo o va a reunirse con alguien de algo que se ha estado perdiendo desde hace algún tiempo. O tal vez usted ha logrado algo que le hace sentir satisfecho y contento. En general, cuando se saca esta tarjeta, usted se sentirá descuidado y agradecidos y su vida será armoniosa y llena de alegría. A veces, esta tarjeta indica que la riqueza financiera o material está llegando a su manera. De todos modos, este es un tiempo para relajarse y disfrutar y compartir su bienestar con sus seres queridos!</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name: "Bekers Drie",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "geef dat wat jij voelt de ruimte",    
                    description : "De Drie van Bekers gaat over ruimte geven aan wat je voelt en wijst ons op het mogen uiten van onze gevoelens.",
                    questions : [ "Kun jij je gevoelens uiten?", "Wat zou jij vreugdevol kunnen vieren?", "Welk gevoel zou je met anderen willen delen?", "Met wie ga jij dat doen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Dit is een feestelijke kaart die vaak betrekking heeft op vieringen. Het kan gaan om een weerzien of hereniging die gevierd wordt, maar het kan ook een huwelijk, verjaardag, geboorte of gelijk welke andere heuglijke gebeurtenis zijn.</p><p>Het is ook mogelijk dat er opnieuw contact komt met iemand die je al lang hebt moeten missen. Of misschien heb je een taak of opdracht afgewerkt, heb je iets volbracht waar je trots op bent, iets dat je vervult met tevredenheid. Als je deze kaart trekt dan is dat over het algemeen een teken dat je je onbekommerd en dankbaar zal voelen en dat je leven harmonieus en vol vreugde zal zijn. Soms betekent deze kaart ook dat er op financieel en materieel gebied een merkbare verbetering in de maak is.</p><p>In elk geval duidt deze kaart erop dat het nu tijd is om te ontspannen en te genieten en om je goede gevoel ook te delen met de mensen om je heen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment heb je de behoefte om anderen te helpen en te ondersteunen. Dit gevoel is wederzijds want je zal merken dat anderen jou ook hulp en warmte willen bieden. Je hebt behoefte aan sociale activiteit, je wil genieten van het gezelschap van je geliefden en vrienden. Je staat op dit ogenblik in je leven echt open voor anderen, je hebt oog voor wat ze je te bieden hebben op menselijk vlak. Je voelt je gelukkig en bevredigd in je relatie en dit goede gevoel wil je met iedereen delen. Een ideaal moment om een feestje te bouwen!</p><p>Als je alleen bent is het heel waarschijnlijk dat je nu tegen iemand op loopt die helemaal is waar je naar op zoek bent. Maar let op: als je een ‘oude’ liefde opnieuw ontmoet, dan is het echt onwaarschijnlijk dat dit de goede partner voor je zal zijn, let op voor het herhalen van vergissingen, leg de nadruk eerder op ‘nieuwe’ ontmoetingen. Want ook al duidt deze kaart vaak op herenigingen, de juiste partner voor jou is niet in je verleden te vinden.</p>",
                        [GuidanceFields.WORK] : "<p>Deze kaart vertelt je dat je gevoel in verband met je werk en werkomgeving op dit moment grotendeels positief is. Het is ook best mogelijk dat je goed nieuws krijgt in verband met je job, een positieve evaluatie, een aantal complimenten van je meerderen. En dat kan dan weer leiden tot een promotie of opslag. Als je overweegt om van baan te veranderen dan is het nu het juiste moment, je straalt immers zelfvertrouwen uit!</p><p>Als je nu werkloos bent dan is het zaak om te blijven zoeken, geef het niet op. Er komen binnenkort een aantal mooie kansen je kant op. Schenk ook voldoende aandacht aan mensen uit het verleden die op je pad komen want zij zouden de sleutel kunnen zijn naar de perfecte baan voor jou.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het is best mogelijk dat jij iemand bent die vooral van spirituele ervaringen geniet in je eentje. Misschien mediteer je graag alleen, werk je met visualisaties en affirmaties,gewoon alleen met jezelf, daar voel je je goed bij.</p><p>Toch is het op dit moment in je leven verstandig om eens op zoek te gaan naar het gezelschap van anderen, mensen die je kunnen inspireren, die je op weg kunnen zetten naar totaal andere spirituele paden en uitdagingen. Het zijn net die anderen die je vormen van spiritueel beleven kunnen aanreiken waar je zelf nooit zou zijn opgekomen. En net door die ongewone ervaringen kan er voor jou een totaal nieuwe spirituele wereld opengaan. De mensen die je hiervoor aanspreekt hoeven geen goeroes of leermeesters te zijn, in tegendeel. Een gesprekje met je buurman of het bezoeken van een nieuwe website kan al een totaal andere zienswijze bij jou teweegbrengen. Stel jezelf open voor anderen en laat je verrassen!</p>",
                    }
                },
                de : {
                    name: "Drei der Kelche",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Lassen Sie Platz für Ihre Gefühle",    
                    description : "Die Drei der Kelche spricht darüber, dem Raum zu geben, was wir empfinden und uns zu erlauben, unsere Gefühle auszudrücken.",
                    questions : [ "Können Sie Ihre Gefühle ausdrücken?", "Was könnten Sie mit Freude feiern?", "Welches Gefühl möchten Sie mit anderen teilen?", "Mit wem werden Sie das tun?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Das ist eine feierliche Karte und steht für Feiern. Oft ist es ein Wiedersehen, das gefeiert wird, aber es kann auch eine Hochzeit, ein Geburtstag oder ein anderes freudiges Ereignis sein.</p><p>Vielleicht wird es ein Wiedersehen sein mit jemandem oder mit etwas, dass Sie seit einiger Zeit vermissen. Oder vielleicht gibt es etwas, dass Sie erreicht haben, das Sie mit Wärme und Zufriedenheit erfüllt. Wenn Sie diese Karte ziehen werden Sie sich in der Regel ruhig, sorglos und dankbar fühlen. Ihr Leben wird harmonisch und voller Freude sein.</p><p>Manchmal zeigt diese Karte an, dass Sie finanziellen oder materiellen Reichtum zu erwarten haben. Wie auch immer, dies ist eine Zeit zum Entspannen und Genießen, und Ihr Wohlbefinden mit Ihren Geliebten zu teilen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Dies ist eine Zeit wo Sie das Gefühl haben Menschen helfen zu können und Sie empfinden das gleiche Gefühl Ihnen gegenüber. Sie möchten sozial aktiv sein, die Gesellschaft von anderen genießen und Sie sind in der Regel offen für Menschen und das was sie zu bieten haben. Sie sind glücklich und in Ihrer Beziehung erfüllt und Sie möchten das gerne mit jedem um Sie teilen. Warum nicht mal eine Feier machen?</p><p>Wenn Sie alleine sind, ist es sehr wahrscheinlich, dass Sie bald jemanden begegnen, der wirklich das ist, was Sie gesucht haben, um Ihr Leben zu erfüllen. Aber eine Versöhnung mit einer \"alten\" Liebe wird wahrscheinlich nicht das bringen, was Sie suchen. Auch, wenn diese Karten deutet auf ein Zusammentreffen mit der richtigen Person für Sie in einer Beziehung werden Sie die wahrscheinlich nicht in Ihrer Vergangenheit finden.</p>",
                        [GuidanceFields.WORK] : "<p>Wenn Sie diese Karte ziehen, fühlen Sie Sich gerade eher positiv über Ihre Arbeit. Vielleicht sind Sie in der Reihe für eine Beförderung oder eine Gehaltserhöhung. Oder vielleicht sind Ihre Vorgesetzten sehr positiv über Ihre Qualitäten und Leistungen. Wenn Sie die Absicht haben den Arbeitsplatz zu wechseln, ist jetzt der perfekte Zeitpunkt. Wenn Sie zur Zeit auf der Suche nach einer anderen Position sind, geben Sie nicht auf, suchen Sie weiter. Einige ziemlich gute Chancen sollten gerade jetzt auf Ihrem Weg kommen. Wenn jemand aus der Vergangenheit sich mit Ihnen in Verbindung setzt in Bezug auf Arbeit, kann dies die perfekte Gelegenheit sein.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Vielleicht sind Sie die Art von Person, die Spiritualität auf eigene Faust genießt. Sie mögen gerne alleine meditieren, visualisieren, erwägen. Dennoch kann es eine gute Idee sein aus zu gehen, um Sich zu mischen mit anderen Leuten, die Sie begeistern können, die Ihnen helfen können, völlig neue spirituelle Wege zu finden und neue Herausforderungen zu sehen. Wenn Sie dies tun, könnten Sie eine neue und sinnvolle Erfahrung haben, die Ihr spirituelles Bewusstsein völlig verändert.</p><p>Diese neuen \"Lehrer\" müssen nicht unbedingt Gurus oder Spezialisten sein. Es kann auch einfach der Nachbar sein, der Ihnen helft das “Licht zu sehen”.</p>",
                    }
                }
            },
            {
                filename: "39",
                numerology : 4,
                en : {
                    name: "Four of Cups",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "having a handle on your feelings",    
                    description : "Four of Cups talks about our handle on feelings and an unsettling  feeling which keeps us stuck.",
                    questions : [ "Are you stuck within your feelings?", "Are you feeling unhappy?", "What could you possibly do about it?", "Stay alert to what else may be problematic." ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Four of Cups is about mixed feelings. On one hand you are not satisfied with certain parts of your life, the grass seems greener on the other side of the fence. On the other hand, you have an abundance of things to be thankful for, in some ways your cup is overflowing, but still this doesn’t make you happy. It seems as if you are determined to focus on the negatives instead of looking at the positives, you are filling yourself to the brim with dissatisfaction. Actually the solution might be to simply look at what is instead of concentrating on what could be.</p><p>Right now it may be wise to get a grip and to count your blessings and cherish them, enjoy the positive energy this will bring you.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Generally, when you draw this card, it indicates that your situation is less than optimal. Maybe your relationship is boring you, maybe you feel empty, hollow, devoid of feelings. Possibly you can’t even remember why you ever chose this partner in the first place. It’s as if you’re thinking: this cannot be my life, I’m sure I ordered a better one! You seem to be dissatisfied and you tend to linger on this dissatisfaction.</p><p>Maybe you could think about the things that are going right for a while, it will certainly give you a lot more energy than what you’re doing right now.</p><p>If you are single, make sure you don’t put all your energy towards conquering someone who is actually beyond your reach. Instead, make sure your goals are realistic, don’t set yourself up for failure.</p>",
                        [GuidanceFields.WORK] : "<p>Nowadays, at work, it is very hard for you to stay motivated. It all seems so same old same old. No challenges, nothing new or exciting, no new people to liven it all up a bit. You feel as if you’ve been there forever, doing the same boring things day after day. So maybe you don’t have a dream job, maybe your career is in a bit of a rut. But on the upside: you still have a job! Try to be at least a bit happy about that. There are so many people nowadays who don’t even have a ‘boring’ job to go to. Shake off that ‘poor me’ attitude and go look for things you really want to do!</p><p>If you are looking for a job, it might be a good idea to ask yourself if you actually want to find one. If this isn’t the case, maybe you should first focus on what kind of work you would really love to do.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The way you think and feel has a big impact on your spirituality. Right now, you seem to be lost in emotions that are bordering on self-pity and discontentment. Your mindset is mainly passive and negative. You keep thinking along the lines of ‘if only X were different, I would feel better’. If you keep this up, nothing will change and you will feel more and more miserable. Forget the past and the future and focus on the present. Sit down and think about the things you actually like about your life, even if there’s only a few. What makes you happy? What fulfills you? Who do you like? Don’t discard anything, if it makes you happy to see your dog when you come home, then that’s a positive. Focusing on these things can change your feelings really quickly and will help get you out of this rut you are in.</p>",
                    }
                },
                fr : {
                    name : "Le Quatre de Coupe",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "Avoir la notion de ce que vous ressentez",    
                    description : " Le Quatre de Coupe, nous parle de la notion de nos sentiments et d’être coincé dans un sentiment d'instabilité.",
                    questions : ["Êtes-vous coincé dans vos sentiments?", "Avez-vous un sentiment insatisfaisant?", "Que pourriez-vous faire à ce sujet?", "Restez-vous attentif à ce qu'il pourrait y avoir d'autre?"],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Les \"Quatre de Coupe\" représentent des sentiments mitigés. D'une part, vous n'êtes pas satisfait de certaines parties de votre vie. L'herbe semble plus verte de l'autre côté de la clôture. D'un autre côté, vous savez que vous pouvez être reconnaissant pour beaucoup de choses. D'une certaine façon, votre tasse est plus que pleine, mais cela ne vous rend toujours pas heureux. Il semble que vous soyez déterminé à vous concentrer sur le négatif plutôt que sur le positif. Vous êtes rempli à ras bord d'insatisfaction. En fait, la solution pourrait être de vous concentrer sur ce que vous avez vraiment et non sur ce qui pourrait être.</p><p>En ce moment, il peut être utile de se ressaisir, de compter et d'apprécier ses bienfaits. Profitez de l'énergie positive que cela vous apportera.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Generally, when you draw this card, it indicates that your situation is less than optimal. Maybe your relationship is boring you, maybe you feel empty, hollow, devoid of feelings. Possibly you can’t even remember why you ever chose this partner in the first place. It’s as if you’re thinking: this cannot be my life, I’m sure I ordered a better one! You seem to be dissatisfied and you tend to linger on this dissatisfaction.</p><p>Maybe you could think about the things that are going right for a while, it will certainly give you a lot more energy than what you’re doing right now.</p><p>If you are single, make sure you don’t put all your energy towards conquering someone who is actually beyond your reach. Instead, make sure your goals are realistic, don’t set yourself up for failure.</p>",
                        [GuidanceFields.WORK] : "<p>Nowadays, at work, it is very hard for you to stay motivated. It all seems so same old same old. No challenges, nothing new or exciting, no new people to liven it all up a bit. You feel as if you’ve been there forever, doing the same boring things day after day. So maybe you don’t have a dream job, maybe your career is in a bit of a rut. But on the upside: you still have a job! Try to be at least a bit happy about that. There are so many people nowadays who don’t even have a ‘boring’ job to go to. Shake off that ‘poor me’ attitude and go look for things you really want to do!</p><p>If you are looking for a job, it might be a good idea to ask yourself if you actually want to find one. If this isn’t the case, maybe you should first focus on what kind of work you would really love to do.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The way you think and feel has a big impact on your spirituality. Right now, you seem to be lost in emotions that are bordering on self-pity and discontentment. Your mindset is mainly passive and negative. You keep thinking along the lines of ‘if only X were different, I would feel better’. If you keep this up, nothing will change and you will feel more and more miserable. Forget the past and the future and focus on the present. Sit down and think about the things you actually like about your life, even if there’s only a few. What makes you happy? What fulfills you? Who do you like? Don’t discard anything, if it makes you happy to see your dog when you come home, then that’s a positive. Focusing on these things can change your feelings really quickly and will help get you out of this rut you are in.</p>",
                    }
                },
                sp:{
                    name:"El Cuatro de copas",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "Agarra  lo que sientes",    
                    description: "El Cuatro de Copas trata de controlar tus sentimientos y nos indica que podemos estar atrapados en un sentimiento de inestabilidad",
                    questions: ["¿Estás atascado en tus sentimientos?", "¿Tienes un sentimiento insatisfactorio", "¿Qué podrías hacer al respecto?  Mantente alerta a lo que podría ser" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Cuatro de Copas se trata de una mezcla de sentimientos. Por un lado no está satisfecho con ciertas partes de su vida, la hierba parece más verde al otro lado de la valla. Por otra parte, usted tiene una gran cantidad de cosas que agradecer, en cierto modo su copa rebosa, pero aún esto no le hace feliz. Parece como si usted está decidido a centrarse en los aspectos negativos en lugar de buscar el lado positivo, usted está llenando a su mismo hasta el borde de la insatisfacción. En realidad, la solución podría ser simplemente mirar lo que está en lugar de concentrarse en lo que podría ser. En este momento puede ser sabio para conseguir un agarre y contar tus bendiciones y asistirla, disfrutar de la energía positiva que esto traerá.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Bekers Vier",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "grip op wat je voelt",    
                    description : "De Vier van Bekers gaat over grip op je gevoel en wijst ons op een wellicht onbestendig gevoel, waarin we mogelijk vastzitten.",
                    questions : [ "Zit jij vast in je gevoel?", "Heb jij een onbevredigend gevoel?", "Wat zou jij er aan kunnen doen?", "Blijf alert op wat er mogelijk nog meer kan zijn." ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Vier van Bekers heeft het over gemengde gevoelens. Aan de ene kant ben je ontevreden over bepaalde aspecten van je leven, het lijkt alsof het gras elke keer weer groener is aan de overkant. En aan de andere kant weet je eigenlijk best dat je een heleboel dingen hebt om dankbaar voor te zijn. Het lijkt alsof je glas halfvol is maar jij ervaart het als halfleeg. Je hebt op dit moment de neiging om je te focussen op het negatieve in plaats van het positieve. Je laat jezelf overspoelen met ontevredenheid en ongenoegen. Eigenlijk is het best mogelijk dat er een simpele oplossing is voor dit probleem: kijk naar wat er <strong>is</strong> en waardeer het, kijk niet naar wat jij <strong>zou willen</strong> dat er is. Je mag best een beetje streng zijn voor jezelf op dit moment: dwing jezelf om die dingen te tellen waarmee het leven je gezegend heeft, geniet ervan, koester ze. Je zal merken dat deze houding je vervult met positieve energie, en dat maakt het dan weer gemakkelijker om over die zaken heen te stappen die je op dit moment niet hebt. De kracht van positief denken verbetert altijd je levenskwaliteit!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je de Vier van Bekers trekt dan wijst dit er over het algemeen op dat je situatie niet optimaal is. Misschien verveelt je relatie je een beetje, vind je het maar een saaie boel. Of misschien voel je je leeg, lijkt je gevoel voor je partner opgebruikt te zijn. Misschien kan je je zelfs niet meer herinneren waarom je ooit voor deze persoon gekozen hebt, wat je zo aantrekkelijk vond aan hem of haar. Het lijkt alsof je denkt: dit kan mijn leven niet zijn, ik weet zeker dat ik iets beters verdien! Je bent behoorlijk ontevreden en je gaat helemaal op in dit gevoel. Probeer je eens te focussen op wat wel goed zit in je relatie. Je zal merken dat je daar meer kracht uit put dan uit de houding die je nu aanneemt. En als blijkt dat je er niet in slaagt om ook maar één positief aspect te vinden in je partner, trek dan je conclusies en ga alleen verder. Als je single ben en op zoek, let er dan op dat je niet al je energie investeert in het veroveren van iemand die geen interesse heeft of die te hoog gegrepen is voor jou. Denk er goed over na of je doelen wel realistisch zijn, anders heb je het falen al ingebouwd in je poging.</p>",
                        [GuidanceFields.WORK] : "<p>je vindt het tegenwoordig moeilijk om gemotiveerd te blijven in je werksituatie. Het lijkt allemaal zo saai en herkenbaar, zo leeg. Je ziet geen uitdagingen, je vindt geen nieuwe of opwindende aspecten in je job, er zijn geen nieuwe mensen die de boel wat kunnen opvrolijken. Je hebt het gevoel alsof je daar al een eeuwigheid zit en telkens weer, elke dag, dezelfde saaie dingen moet doen. En misschien is het ook zo dat dit niet je droomjob is, misschien zit je wel wat in een sleur. Maar toch is er ook een positieve kant: je <strong>hebt</strong> tenminste een baan! Probeer hierin toch iets van vreugde te vinden. Laat dat ‘arme ik’ los en ga op zoek naar dingen die je wel wil doen, waar je je wel goed bij zal voelen.</p><p>Als je op zoek bent naar werk dan is het mogelijk zinvol om je in de eerste plaats eens af te vragen of je echt wel werk wil vinden. Als dat niet het geval is, is het verstandiger om je energie eerst te investeren in het ontdekken welke job je graag zou doen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je gedachten en gevoelens zijn van grote invloed op je spirituele leven. Op dit moment lijkt het erop dat je jezelf verliest in emoties die heen en weer gaan tussen zelfmedelijden en ontevredenheid. Je ingesteldheid is voornamelijk passief en negatief. Je blijft maar dingen denken als ‘Als X maar anders zou zijn dan zou ik me vast beter voelen’. Als je hierin blijft hangen dan zal er niets veranderen en zal je alsmaar ongelukkiger worden. Vergeet nu zowel het verleden als de toekomst en concentreer je op het heden, het Nu. Ga er even bij zitten en denk aan de dingen die je wel fijn vindt in je leven, zelfs al zijn het er niet veel. Wat maakt je gelukkig? Waar voel je je tevreden bij? Wie vind je leuk, sympathiek? Schuif niets opzij, als het je een goed gevoel geeft dat je hond blij is als je ’s avonds thuiskomt dan is dat iets positiefs in je leven. Eens je een (misschien kort) lijstje gemaakt hebt, kan je op die dingen gaan focussen en daardoor je algemene gevoel veranderen. Je zal merken dat dit een weg is om je uit die negatieve sleur te halen waar je in zit. Jij bent de architect van wat je voelt!</p>",
                    }
                },
                de : {
                    name: "Vier der Kelche",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Darauf achten, was man fühlt.",    
                    description : "Die Vier der Kelche spricht über die Wahrnehmung unserer Gefühle und darüber, dass wir in einem Gefühl der Instabilität gefangen sind.",
                    questions : [ "Stecken Sie in Ihren Gefühlen fest?", "Haben Sie ein unbefriedigendes Gefühl?", "Was könnten Sie dagegen tun?", "Bleiben Sie aufmerksam, was es sonst noch geben könnte." ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Bei den „Vier der Kelche“ handelt es sich um gemischte Gefühle. Auf der einen Seite sind Sie nicht zufrieden mit bestimmten Teilen Ihres Lebens. Das Gras erscheint grüner auf der anderen Seite des Zauns. Auf der anderen Seite wissen Sie, dass Sie für viele Dinge dankbar sein können. In gewisser Weise ist Ihre Tasse übervoll, aber das macht Sie immer noch nicht glücklich. Es scheint, dass Sie entschlossen sind, sich auf das Negative anstatt auf das Positive zu konzentrieren. Sie sind bis zum Rand mit Unzufriedenheit erfüllt. Tatsächlich könnte die Lösung sein, sich einfach auf das zu konzentrieren, was Sie wirklich haben und nicht auf das, was sein könnte.</p><p>Im Moment kann es sinnvoll sein, sich in den Griff zu bekommen sowie Ihre Segnungen zu zählen und zu schätzen. Genießen Sie die positive Energie, die Ihnen das bringen wird.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Im Allgemeinen, wenn Sie diese Karte ziehen, bedeutet dies, dass Ihre Situation nicht optimal ist. Vielleicht ist Ihre Beziehung langweilig und haben Sie das Gefühl, leer, hohl, ohne Gefühle zu sein. Möglicherweise können Sie Sich nicht einmal daran erinnern, warum Sie überhaupt diesen Partner ausgewählt haben. Es ist, als ob Sie denken: Das kann nicht mein Leben sein, ich bin sicher, ich habe ein besseres bestellt! Sie scheinen unzufrieden zu sein und Sie neigen dazu, in dieser Unzufriedenheit zu verweilen.</p><p>Vielleicht müssen Sie denken über die Dinge, die gerade für eine Weile schon gut gehen. Es wird Ihnen sicherlich mehr Energie geben als das, was Sie jetzt machen.</p><p>Wenn Sie alleine sind, stellen Sie sicher, dass Sie nicht all Ihre Energie verschwenden, um jemanden zu erobern, der eigentlich unerreichbar ist. Stattdessen stellen Sie Sich sicher, dass Ihre Ziele realistisch sind und nicht zum Scheitern führen.</p>",
                        [GuidanceFields.WORK] : "<p>Im Moment ist es für Sie sehr schwierig bei der Arbeit motiviert zu bleiben. Es scheint alles so gleichmäßig zu sein, immer das alte Lied. Keine Herausforderungen, nichts Neues oder Aufregendes, keine neuen Menschen, um alles ein wenig lebendiger zu machen. Sie fühlen sich, als ob Sie für immer dort waren, die gleichen langweiligen Dinge Tag für Tag. Vielleicht müssen Sie nicht einen Traumjob haben, Ihre Karriere ist vielleicht ein wenig in einer Furche. Aber auf der andere Seite: Sie haben noch einen Job! Versuchen Sie, zumindest ein wenig glücklich zu sein. Es gibt so viele Menschen heute, die nicht einmal einen \"langweilig\" Job haben. Abschütteln, diese \"ich Armer\" Einstellung und suchen Sie Dinge, die Sie wirklich wollen!</p><p>Wenn Sie auf der Suche sind nach einem Job, könnte es eine gute Idee sein, Sich zu fragen, ob Sie wirklich einen finden wollen. Ist dies nicht der Fall ist, sollten Sie Sich vielleicht erstmal fragen welche Art von Arbeit Sie wirklich gerne tun würden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Ihre Art von Denken und Fühlen hat jetzt einen großen Einfluss auf Ihre Spiritualität. Gerade jetzt haben Sie Gefühle, die an Selbstmitleid und Unzufriedenheit grenzen. Ihre Denkweise ist vor allem passiv und negativ. Sie haben die Neigung zu denken \"wenn nur X anders war, dann würde ich mich besser fühlen\". Wenn Sie so weitermachen, wird sich nichts ändern und Sie fühlen sich mehr und mehr unglücklich. Vergessen Sie die Vergangenheit und die Zukunft und konzentrieren Sie Sich auf der Gegenwart. Setzen Sie Sich und denken Sie über die Dinge Ihres Lebens nach, die Sie tatsächlich Freude bereiten, auch wenn es nur ein Paar sind. Was macht sie glücklich? Was erfüllt sie? Wen mögen Sie? Unterlassen Sie nichts. Wenn es Sie glücklich macht, um Ihren Hund zu sehen, wenn Sie nach Hause kommen, dann ist das eine positive Gedanke. Die Konzentration auf diesen Dingen können Ihre Gefühle sehr schnell ändern und es wird Ihnen helfen, sich aus diesem Trott zu befreien, worin Sie sich befinden.</p>",
                    }
                }
            },
            {
                filename: "40",
                numerology : 5,
                en : {
                    name: "Five of Cups",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "self-worth",    
                    description : "Five of Cups talks about learning to communicate about your feelings and learning from disappointments.",
                    questions : [ "Are you disappointed?", "Are you clinging in difficult times?", "Can you sort through disappointments?", "Which feeling are you (yet) unable to let go?", "When is it enough?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card evokes feelings of loss, sadness and disappointment but it also points out that you have an influence on how painful and intense your grief is by deciding what you will focus on: that which is lost or the things that are still there. If you decide to concentrate on your sadness and to carry it all alone, coping with it may turn out to be very hard. But if you accept that you still have some blessings to count too and that one of those blessings is that there are people around you to help and support you, then going through the grief may become a bit easier. </p><p>When confronted with loss and disappointment it is often easier to go with the flow, to let those feelings come over you and while you are experiencing them, to not lose sight of the positives in your life. If you try to fight the grief, it will hit you even harder afterwards, and if you focus on it too much, you may find yourself drowning in it. Only you can get through this difficult period, but you don’t have to do it alone.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you get the Five of Cups your relationship may be on the verge of ending or it has just ended. Right now it is almost impossible for you to see what’s good about this. You are overwhelmed by grief and disappointment. You don’t (want to) understand why this relationship has to end. You want everything to stay the same as it was, you hate it that things are changing. Take your time to experience and work through this grief and disappointment, but don’t wallow in it. There’s a whole world out there with people to meet and het to know. Among them is your new partner, don’t make him/her wait too long!</p><p>If you are single right now, don’t allow yourself to be overwhelmed by your sense of loneliness. It’s way more productive to focus on the possibilities and options you have. Go out and find yourself someone!</p>",
                        [GuidanceFields.WORK] : "<p>You may have some problems at your job nowadays. Maybe your superiors aren’t happy with the work you delivered, they may even have been at bit harsh about it. Maybe you don’t get along with your colleagues as well as you would like to. Before you start taking action to change things, first allow yourself some time to find out if you really want to be and stay in this job. Possibly the time has come to open up to new opportunities, to consider changing jobs. Your feeling of disappointment could actually stem from your need to find a new challenge.</p><p>If you’re looking for a job, don’t take it personally if things aren’t quite going the way you want them to. Just quietly go about your business, things will speed up and chances will come your way soon enough.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Possibly you are feeling frustrated and disappointed right now. Some people haven’t acted the way you wanted them to. It feels as if they let you down and maybe they really have. Still, now is not the time to focus on the part others played or on their shortcomings. The most important task right now is for you to find out what you can learn from these situations. Once you discover the life lessons within, you will realize it is easy to forgive others and be at peace with yourself and them.</p>",
                    }
                },
                fr : {
                    name : "Le Cinq de Coupe",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "sentiment personnel",    
                    description : " Le Cinq de Coupe, nous parle de l'occasion d'apprendre à communiquer sur ce que nous ressentons et d'apprendre de nos déceptions.",
                    questions : ["Êtes-vous déçu?", "Retenez-vous votre souffle dans une période turbulente?", "Pouvez-vous donner une place à votre déception?", "Quel sentiment ne pouvez-vous pas (encore) lâcher?", "Quand allez-vous vous ressaisir?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte suscite des sentiments de perte, de chagrin et de déception. Cependant, cela indique aussi que vous avez une influence sur la douleur et l'intensité de ces sentiments en choisissant sur quoi vous vous concentrez : ce qui a été perdu ou ce qui est encore là. Si vous vous concentrez sur votre deuil et que vous portez ce sentiment tout seul, cela peut s'avérer très difficile. Mais si vous acceptez que vous soyez béni et que les gens autour de vous soient une bénédiction pour vous soutenir, alors la douleur sera un peu plus facile à supporter.</p><p>Lorsque vous êtes confronté à la perte et à la déception, il est souvent plus facile d’aller avec le courant et de laisser passer ces sentiments. Si vous ressentez ces sentiments, vous ne devriez pas perdre de vue le côté positif de votre vie. Si vous essayez de combattre la douleur, elle ne vous frappera que plus fort. Si vous vous concentrez trop dessus, vous allez vous noyer dans la douleur. Vous seul pouvez traverser cette période difficile, mais vous n'avez pas besoin de le faire seul.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you get the Five of Cups your relationship may be on the verge of ending or it has just ended. Right now it is almost impossible for you to see what’s good about this. You are overwhelmed by grief and disappointment. You don’t (want to) understand why this relationship has to end. You want everything to stay the same as it was, you hate it that things are changing. Take your time to experience and work through this grief and disappointment, but don’t wallow in it. There’s a whole world out there with people to meet and het to know. Among them is your new partner, don’t make him/her wait too long!</p><p>If you are single right now, don’t allow yourself to be overwhelmed by your sense of loneliness. It’s way more productive to focus on the possibilities and options you have. Go out and find yourself someone!</p>",
                        [GuidanceFields.WORK] : "<p>You may have some problems at your job nowadays. Maybe your superiors aren’t happy with the work you delivered, they may even have been at bit harsh about it. Maybe you don’t get along with your colleagues as well as you would like to. Before you start taking action to change things, first allow yourself some time to find out if you really want to be and stay in this job. Possibly the time has come to open up to new opportunities, to consider changing jobs. Your feeling of disappointment could actually stem from your need to find a new challenge.</p><p>If you’re looking for a job, don’t take it personally if things aren’t quite going the way you want them to. Just quietly go about your business, things will speed up and chances will come your way soon enough.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Possibly you are feeling frustrated and disappointed right now. Some people haven’t acted the way you wanted them to. It feels as if they let you down and maybe they really have. Still, now is not the time to focus on the part others played or on their shortcomings. The most important task right now is for you to find out what you can learn from these situations. Once you discover the life lessons within, you will realize it is easy to forgive others and be at peace with yourself and them.</p>",
                    }
                },
                sp:{
                    name:"El Cinco de Copas",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "tu sentimiento propio",    
                    description: "El Cinco de Copas se trata de aprender a comunicar lo que sientes y nos indica la capacidad de aprender de nuestras decepciones",
                    questions: ["¿Estás decepcionado?", "¿Estás aguantando la respiración en un momento turbulento?", "¿Puedes dar un lugar a tu decepción?", "¿Qué sentimiento no puedes (todavía) dejar ir?", "¿Cuándo te das la vuelta?"],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta evoca sentimientos de pérdida, tristeza y decepción pero señala también que tiene una influencia sobre el doloroso y  el intenso.  Su queja es  por decidir en lo que se centrará: la que se perdió, y no en las cosas que están todavía allí. Si decide concentrarse en su tristeza y llevar todo solo, hacer frente a la que puede llegar a ser muy duro. Pero si usted acepta que usted todavía tiene algunas de las bendiciones que valar demasiado y que una de esas bendiciones es que hay gente que le rodea para ayudar y apoyar, a continuación, pasar por el dolor puede llegar a ser un poco más fácil. Cuando nos enfrentamos a la pérdida y decepción a menudo es más fácil ir con la corriente, a dejar que esos sentimientos vienen sobre usted y mientras usted está experimentando ellos, para no perder de vista los aspectos positivos en su vida. Si usted trata de luchar contra el dolor, que le golpeará aún más difícil después, y si usted se centra demasiado en ello, puede que le encuentre ahogarse en ella. Sólo usted puede ir a través de este período difícil, pero usted no tiene que hacerlo solo.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Bekers Vijf",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "eigen gevoel",    
                    description : "De Vijf van Bekers gaat over leren communiceren over wat je voelt en wijst ons op het kunnen leren van onze teleurstellingen.",
                    questions : [ "Ben jij teleurgesteld?", "Hou jij je krampachtig staande in een woelige tijd?", "Kun jij je teleurstelling een plek geven?", "Welk gevoel kun jij (nog) niet loslaten?", "Wanneer keer jij je om?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart heeft als onderwerp gevoelens van verlies, verdriet en teleurstelling. Toch wijst de Vijf van Bekers er ook op dat je zelf een invloed hebt op de grootte en intensiteit van je verdriet. Jij beslist waarop je je wil concentreren; de personen/dingen die je kwijt bent of de personen/dingen die je nog hebt. Als jij besluit om je te focussen op je verdriet en om het ook nog alleen te dragen, dan zal blijken dat ermee omgaan een zware taak wordt. Maar als je inziet en aanvaardt dat er ook nog positieve aspecten aan je leven zijn èn dat je mensen om je heen hebt die je willen steunen en helpen, dan zal je merken dat het verwerken van je verdriet iets makkelijker wordt.</p><p>Als je geconfronteerd wordt met verlies en teleurstelling dan is het zinvol om mee te gaan met de stroom, de gevoelens over je heen te laten komen. En terwijl je ze ten volle ervaart, kan je ook kijken naar de goede kanten van je leven. Als je probeert om te vechten tegen het verdriet zal het je daarna twee keer zo hard raken. En als je er teveel in opgaat, loop je het risico om erin te verdrinken. Jij bent de enige die door deze zware periode heen moet geraken, maar je hoeft het niet alleen te doen. Laat je meevoeren met de stroom van het verdriet en nodig iemand uit om je hand vast te houden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je de Vijf van Bekers trekt dan kan dit erop wijzen dat je relatie op het punt staat verbroken te worden of dat je net een breuk achter de rug hebt. Op dit moment vind je het vast onmogelijk om te aanvaarden dat er ook iets positief is aan deze situatie. Je wordt overweldigd door verdriet, teleurstelling en mogelijk zelfs frustratie. Je begrijpt niet of wil niet begrijpen waarom deze relatie moest eindigen. Je wil gewoon dat alles zo blijft als het was, je vindt het verschrikkelijk dat de dingen nu gaan veranderen. Probeer jezelf wel de tijd te gunnen om deze negatieve gevoelens te ervaren en te verwerken, maar jezelf er niet in te gaan onderdompelen. Er is nog een hele grote wereld daarbuiten met massa’s mensen die je kan ontmoeten en leren kennen. En daarbuiten loopt er ook iemand rond die jouw nieuwe partner zal worden, laat hem/haar niet te lang op je wachten en maak werk van je verwerkingsproces!</p><p>Als je op dit moment geen relatie hebt, sta jezelf dan niet toe om helemaal op te gaan in je eenzaamheid. Het is veel productiever om je te concentreren op de mogelijkheden en opties die je hebt. Ga naar buiten, treed de wereld tegemoet en vind een partner!</p>",
                        [GuidanceFields.WORK] : "<p>Het is best mogelijk dat je wat problemen hebt op je werk. Misschien zijn je meerderen niet tevreden met het werk dat je levert, misschien zijn ze zelfs nogal grof in hun mededelingen. Of misschien kan je niet zo goed opschieten met een aantal collega’s en vind je dat erg moeilijk. Gun jezelf de tijd om eens goed na te denken over de vraag of je echt wel zeker bent dat je deze job wil blijven uitoefenen, dat je op deze werkplek wil blijven. Misschien is het nu wel de goede tijd om uit te k naar nieuwe mogelijkheden, andere opties. Misschien kan je een baan vinden die beter bij je past, waar je je ontspannener en gelukkiger zal voelen. De teleurstelling en rusteloosheid die je voelt kan immers ook betekenen dat je behoefte hebt aan een nieuwe uitdaging.</p><p>Als je op zoek bent naar werk is het mogelijk dat je geconfronteerd wordt met nogal wat tegenvallers en teleurstellingen. Het feit dat de dingen op dit moment niet gaan zoals je het graag wil, zegt niets over jou als persoon of als mogelijke werknemer. Ga gewoon rustig verder zoals je bezig bent, je zal merken dat de omstandigheden geleidelijk veranderen en dat je weer kansen en mogelijkheden op je weg zal krijgen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je voelt je mogelijk nogal teleurgesteld en gefrustreerd tegenwoordig, misschien zelfs verdrietig. Er zijn immers mensen in je omgeving die zich niet gedragen hebben zoals jij het van hen verwachtte en hoopte. Je hebt het gevoel dat ze je in de steek gelaten hebben en misschien is dat ook echt zo. Toch is het nu niet de juiste tijd om te gaan focussen op het foute gedrag van anderen of op hun tekortkomingen.</p><p>Jouw belangrijkste taak is nu om door je gevoelens van verdriet en teleurstelling heen te gaan, ze een plaats te geven en te gaan bekijken wat jij kan leren van deze ervaring(en). Op het moment dat je de geboden levenslessen ontdekt in deze situatie zal je merken dat het een stuk gemakkelijker wordt om die andere(n) het slechte gedrag te vergeven en vrede te sluiten met hen en met jezelf. En dan heb je alweer een stap gezet op je persoonlijke pad van spirituele groei!</p>",
                    }
                },
                de : {
                    name: "Die Fünf der Kelche",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Persönliche Meinung",    
                    description : "Die Fünf der Kelche spricht von der Möglichkeit, zu lernen, wie man darüber kommuniziert, wie man sich fühlt, und aus unseren Enttäuschungen zu lernen.",
                    questions : [ "Sind Sie enttäuscht?", "Halten Sie den Atem an in turbulenten Zeiten?", "Können Sie Ihrer Enttäuschung Raum geben?", "Welches Gefühl können Sie (noch) nicht loslassen?", "Wann werden Sie sich wieder erholen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte weckt Gefühle von Verlust, Trauer und Enttäuschung. Dennoch weist sie auch darauf hin, dass Sie einen Einfluss darauf haben, wie schmerzhaft und intensiv diese Gefühle sind, indem Sie sich dafür entscheiden, worauf  Sie sich  konzentrieren müssen: das, was verloren gegangen ist, oder die Dinge, die noch da sind. Wenn Sie sich auf Ihre Trauer konzentrieren und sie dieses Gefühl ganz allein tragen kann sich das als sehr hart herausstellen. Aber wenn Sie akzeptieren, dass Sie gesegnet sind, dass die Menschen in Ihrer Umgebung ein Segen sind, die Sie unterstützen können, dann wird der Schmerz ein bisschen leichter zu ertragen sein.</p><p>Wenn Sie mit Verlust und Enttäuschung konfrontiert sind ist es oft einfacher, mit dem Strom zu schwimmen und diese Gefühle über sich ergehen zu lassen. Wenn Sie diese Gefühle empfinden sollten Sie das Positive in Ihrem Leben nicht aus den Augen verlieren. Wenn Sie versuchen, den Schmerz zu bekämpfen, wird er Sie schließlich nur noch härter treffen. Wenn Sie sich zu viel darauf konzentrieren werden Sie im Schmerz ertrinken. Nur Sie selbst können durch diese schwierige Zeit kommen, aber Sie brauchen das nicht allein zu tun.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Persönliche Beziehungen und Liebe</p><p>Wenn Sie die Fünf Cups ziehen, könnte Ihre Beziehung fast am Ende sein, oder sich gerade beendet haben. Gerade jetzt ist es fast unmöglich für Sie um zu sehen was daran gut sein könnte. Sie werden von Trauer und Enttäuschung überwältigt. Sie können (wollen) nicht verstehen, warum diese Beziehung zu Ende gehen musste. Sie möchten, dass alles beim alten geblieben wäre. Sie hassen es, dass sich die Dinge ändern. Nehmen Sie Sich die Zeit diese Erfahrung, diese Trauer und diese Enttäuschung zu verdauern, aber schwelgen Sie nicht darin. Es gibt eine ganze Welt da draußen mit Leuten, mit denen Sie Sich treffen könnten. Unter ihnen ist Ihr neuer Partner, lassen Sie ihn / sie nicht zu lange warten!</p><p>Wenn Sie jetzt alleine sind, lassen Sie sich nicht von Ihrem Gefühl der Einsamkeit überwältigen. Es ist viel produktiver sich auf den Möglichkeiten und Optionen zu konzentrieren, die Sie haben. Gehen Sie aus und finden Sie jemanden!</p>",
                        [GuidanceFields.WORK] : "<p>Sie können einige Probleme in Ihrem Job empfinden heutzutage. Vielleicht sind Ihre Vorgesetzten nicht zufrieden mit der Arbeit, die Sie geliefert haben und könnten sie darüber auch ein wenig hart gewesen sein. Vielleicht kommen Sie auch nicht so gut zurecht mit Ihren Kollegen wie Sie eigentlich möchten. Bevor Sie Maßnahmen ergreifen, um die Dinge zu ändern, lassen Sie sich zunächst einige Zeit, um herauszufinden, ob Sie wirklich in diesem Job sein und bleiben möchten. Möglicherweise ist die Zeit gekommen sich zu öffnen für neue Möglichkeiten und eine mögliche Arbeitsplatz wechsel zu berücksichtigen. Ihr Gefühl der Enttäuschung könnte tatsächlich verursacht werden von der Notwendigkeit, eine neue Herausforderung zu finden. Wenn Sie nach einem Job suchen, nehmen Sie es nicht persönlich, wenn die Dinge nicht ganz wohl vor sich gehen wie Sie es wollen. Gehen Sie nur vorsichtig voran. Der Moment zur Beschleunigung und die Chancen kommen schneller als Sie denken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Möglicherweise fühlen Sie Sich gerade jetzt frustriert und enttäuscht. Einige Leute haben nicht so gehandelt, wie Sie es wollten. Es fühlt sich an, als ob sie Sie im Stich gelassen haben und vielleicht haben sie das wirklich. Dennoch ist jetzt nicht die Zeit Sich auf Ihre Fehler zu konzentrieren. Die wichtigste Aufgabe ist jetzt für Sie, um herauszufinden, was Sie aus solchen Situationen lernen können. Sobald Sie die Lektionen des Lebens entdecken, werden Sie feststellen, dass es leicht ist, andere zu vergeben und in Frieden mit Sich Selbst und ihnen zu leben.</p>",
                    }
                }
            },
            {
                filename: "41",
                numerology : 6,
                en : {
                    name: "Six of Cups",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "choosing for what you feel",    
                    description : "Six of Cups talks about emotional memories and that whatever they are, we can choose to move on.",
                    questions : [ "Can you make a choice based on your feelings?", "Can you choose what feels good for you?", "Which emotional memory is still at play?", "Can you choose to let go of an awful feeling?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Six of Cups is often about reminiscing, remembering the way things were, the way you were. In this period of your life you think back on happy relationships you used to have, people you used to know, maybe even happy childhood memories come to mind. All these memories may leave you hungry for a sense of security and safety. Though it is good not to forget about the past, it is also important to remember the lessons you can learn from it, lessons you can apply to your present situation. And it is quite possible that you can look back on painful experiences and realize that they don’t have the power to hurt you anymore, you have grown, evolved. </p><p>Just make sure you don’t linger on the past for too long, remember that the best years haven’t gone by, they are yet to come!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Six of Cups can indicate that it may be time tothink about the way your relationship is going. Do you have clear goals, things you want to achieve together? Or are you mainly going with the flow? Now maybe the right time to decide together which way you want to go from here.</p><p>This card can also symbolize the return of a former lover in your life. Even though such an encounter can be great and reminiscing together can be fulfilling, think twice before you decide to rekindle what used to be. You both are different people now and what used to work won’t necessarily work today; after all you split up for a reason. Maybe you should let bygones be bygones.</p><p>If you are looking for love now is a good time to revisit the past and reassess the lessons you have learned. How can these experiences help you in finding the right partner?</p>",
                        [GuidanceFields.WORK] : "<p>You may be feeling quite nostalgic for the moment. You enjoy reminiscing about former jobs and ex-colleagues. Possibly you even go as far back as your childhood memories. Revisiting the past gives you a sense of security, of wellbeing. Still, this has an influence on your work too, you are not really ‘present’, you tend to be a bit dreamy. Is it simply a matter of harmless and temporary nostalgia? Or is it an indication that you don’t really enjoy your present job anymore? Is some part of you feeling unfulfilled? Do you need new challenges, new learning experiences? If this is the case, you may want to start looking into a career change.</p><p>If you don’t have a job right now, maybe it’s a good idea to revisit the past and remember all the things you used to love doing? This could really put you in the right direction towards finding the perfect position.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In order to grow spiritually, now may be the right time to consult your inner child. Do you remember the intense happiness and joy you used to experience as a child? That great feeling that life was an adventure and you were discovering new things all the time? Find your inner child and release it, give it the space it needs to open up and tell you what you can do to recapture those sentiments. And while you’re at it, you might just be able to help it recover from some less positive past experiences.</p>",
                    }
                },
                fr : {
                    name : "Le Six de Coupe",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "choisir ce que l'on ressent",    
                    description : " Le Six de Coupe, nous parle de souvenirs émotionnels et que tels qu’ils sont nous pouvons choisir de continuer dans le présent.",
                    questions : ["Pouvez-vous faire un choix en fonction de vos sentiments?", "Pouvez-vous choisir ce qu’il vous fait du bien?", "Quelle mémoire émotionnelle joue encore un rôle?", "Pouvez-vous choisir de laisser derrière vous un vilain sentiment?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Six de Coupe\" représentent souvent de baigner dans les mémoires, le souvenir de la façon dont les choses étaient autre fois, nostalgique. En ce moment de votre vie, vous vous souvenez des relations épanouies que vous avez eues avec des gens que vous avez connus et aimés. Peut-être que même les souvenirs heureux de l'enfance vous reviendront en mémoire. Tous ces souvenirs éveillent en vous un désir de sécurité. Bien qu'il soit bon de ne pas oublier le passé, il est également important de se rappeler les leçons que vous avez apprises. Vous pouvez appliquer les expériences du passé à votre situation actuelle. Et il est fort possible que vous vous souveniez d'expériences douloureuses et que vous réalisiez qu'elles ne peuvent plus vous faire de mal parce que vous avez grandi et que vous vous êtes développé.</p><p>Ne vous attardez pas trop longtemps dans le passé. Rappelez-vous que les meilleures années ne sont pas terminées, elles sont encore à venir !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Six of Cups can indicate that it may be time tothink about the way your relationship is going. Do you have clear goals, things you want to achieve together? Or are you mainly going with the flow? Now maybe the right time to decide together which way you want to go from here.</p><p>This card can also symbolize the return of a former lover in your life. Even though such an encounter can be great and reminiscing together can be fulfilling, think twice before you decide to rekindle what used to be. You both are different people now and what used to work won’t necessarily work today; after all you split up for a reason. Maybe you should let bygones be bygones.</p><p>If you are looking for love now is a good time to revisit the past and reassess the lessons you have learned. How can these experiences help you in finding the right partner?</p>",
                        [GuidanceFields.WORK] : "<p>You may be feeling quite nostalgic for the moment. You enjoy reminiscing about former jobs and ex-colleagues. Possibly you even go as far back as your childhood memories. Revisiting the past gives you a sense of security, of wellbeing. Still, this has an influence on your work too, you are not really ‘present’, you tend to be a bit dreamy. Is it simply a matter of harmless and temporary nostalgia? Or is it an indication that you don’t really enjoy your present job anymore? Is some part of you feeling unfulfilled? Do you need new challenges, new learning experiences? If this is the case, you may want to start looking into a career change.</p><p>If you don’t have a job right now, maybe it’s a good idea to revisit the past and remember all the things you used to love doing? This could really put you in the right direction towards finding the perfect position.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In order to grow spiritually, now may be the right time to consult your inner child. Do you remember the intense happiness and joy you used to experience as a child? That great feeling that life was an adventure and you were discovering new things all the time? Find your inner child and release it, give it the space it needs to open up and tell you what you can do to recapture those sentiments. And while you’re at it, you might just be able to help it recover from some less positive past experiences.</p>",
                    }
                },
                sp:{
                    name:"El Seis de Copas",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "elegir por lo que sientes",    
                    description: "El Seis de Copas es sobre recuerdos emocionales y nos recuerda que, sean lo que sean, podemos elegir continuar en el presente ahora",
                    questions: ["¿Puedes hacer una elección basada en tus sentimientos?", "¿Puedes elegir lo que te hace sentir bien?", "¿Qué memoria emocional todavía juega un papel?", "¿Puedes elegir dejar un mal presentimiento atrás?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Seis de Copas es a menudo acerca de recordar el pasado, recordando cómo eran las cosas, la forma en que estaban. En este período de su vida que usted piensa de nuevo en las relaciones de pareja que solía tener, la gente que solía conocer, tal vez incluso recuerdos felices de la niñez vienen a la mente. Todos estos recuerdos pueden dejará usted con hambre de una sensación de seguridad y la seguridad. Aunque es bueno no olvidar el pasado, también es importante recordar las lecciones que podemos aprender de ella, las lecciones se pueden aplicar a su situación actual. Y es muy posible que usted puede mirar hacia atrás en las experiencias dolorosas y darse cuenta de que ellos no tienen el poder para hacerte daño nunca más, has crecido, evolucionado. Sólo asegúrese de no quedarse en el pasado por demasiado tiempo, recuerde que los mejores años no han pasado, que aún están por venir!</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Bekers Zes",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "kiezen voor wat je voelt",    
                    description : "De Zes van Bekers gaat over gevoelsmatige herinneringen en wijst ons er op dat, hoe die ook zijn, we ervoor kunnen kiezen om in het nu verder te gaan.",
                    questions : [ "Kun jij, gebaseerd op je gevoel, een keuze maken?", "Kun jij kiezen voor wat voor jou goed voelt?", "Welke gevoelsmatige herinnering speelt nog een rol?", "Kun jij kiezen om een rotgevoel achter je te laten?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Zes van Bekers heeft het over mijmeren, je herinneren hoe de dingen vroeger waren, hoe jij vroeger was. Je maakt tijd om terug te denken aan fijne vriendschaps- en liefdesrelaties die je gehad hebt, aan mensen die je gekend en bemind hebt. Misschien keer je wel nog verder terug in de tijd en haal je een paar fijne jeugdherinneringen op. Het is positief en zinvol om je het verleden te herinneren, terug te denken aan mooie (en soms minder mooie) tijden. Toch is het ook belangrijk om je te herinneren wat je indertijd geleerd hebt en er even bij stil te staan of je nu nog iets kan leren van dat verleden. Sommige inzichten die je vroeger verworven hebt, kunnen je misschien een betere kijk geven op dingen die je nu meemaakt. Wat ook verrijkend is, dat is om erbij stil te staan dat pijnlijke en trieste herinneringen geen macht meer hebben over jou, ze kunnen je niet meer kwetsen omdat je gegroeid en veranderd bent.</p><p>Let er wel op dat je niet te lang in het verleden blijft hangen, je beste jaren zijn niet voorbij, ze komen er nu pas aan!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Zes van Bekers kan erop wijzen dat het nu tijd is om eens te gaan bekijken hoe het zit met je relatie en jullie plannen voor de toekomst. Hebben jullie duidelijke doelen, zaken die jullie samen willen bereiken? Of ga je gewoon mee met de stroom, plan je niets en laat je het aan het universum over waar jullie zullen uitkomen? Als dat laatste het geval is, dan is het nu een goed moment om samen te bekijken wat je in de toekomst wil bereiken, niet enkel op relationeel vlak maar ook qua gemeenschappelijke doelen.</p><p>Deze kaart kan je ook vertellen dat er een ex-geliefde opnieuw in je leven zal opduiken. Zo’n ontmoeting kan heel fijn zijn, het kan behoorlijk bevredigend en zelfs spannend zijn om samen herinneringen op te halen en ze ook een stuk te herbeleven. Maar denk toch eens goed na voor je zou beslissen dat je de oude vlam opnieuw wil laten branden. Jullie zijn totaal andere mensen geworden en datgene wat jullie vroeger bij mekaar bracht, doet dat daarom vandaag niet. Daar bovenop waren er waarschijnlijk ook gegronde redenen waarom jullie de relatie verbroken hebben. Misschien laat je het verleden beter gewoon waar het thuishoort, in die goeie ouwe tijd.</p><p>Als je op zoek bent naar een relatie maak dan eerst even ruimte om je relationele verleden nog eens te overlopen en de lessen die je geleerd hebt op een rijtje te zetten. Hoe kunnen die lessen je helpen om de juiste partner te vinden?</p>",
                        [GuidanceFields.WORK] : "<p>Misschien voel je je op het moment behoorlijk nostalgisch. Het kan leuk zijn om terug te denken aan vorige banen en collega’s. Misschien denk je zelfs na over je jeugd en de ambities die je toen had. Wilde jij als kind astronaut worden of clown of ...? Het verleden bezoeken kan je een gevoel van veiligheid geven, van tevredenheid over het heden. Toch is het mogelijk dat je hierdoor op het werk minder aanwezig lijkt, alsof je in je hoofd ergens anders zit dan op je werkplek, je komt wat dromerig over. Is dit simpelweg een gevoel van onschuldige en tijdelijke nostalgie? Of is het een aanwijzing dat je je huidige job niet meer zo fijn vindt? Is er misschien een stuk van jou dat zich onbevredigd voelt? Heb je het idee dat je nieuwe uitdagingen nodig hebt, nieuwe kansen om bij te leren, te groeien? Als dat laatste het geval is, ga dan op zoek naar een andere baan, je zal je een stuk tevredener voelen! Als je op zoek bent naar een baan, neem dan even de tijd om te overlopen welke dingen je vroeger fijn en bevredigend vond om te doen. Als je alles op een rijtje zet kunnen dit de perfecte aanwijzingen zijn in welke richting je best kan zoeken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Om aan je spirituele groei te werken is het op dit moment aangewezen om contact te maken met je innerlijke kind. Probeer in je geest terug te keren naar je jeugd, maak er misschien een meditatie of een ontspanningsoefening van. Herinner je je de intense vreugde, het diepe geluk dat je als kind kon ervaren? Herinner je je nog dat fantastische gevoel dat het leven één groot avontuur was en dat je elk moment nieuwe dingen ontdekte, dat je fantasie grenzeloos was?</p><p>Ga op zoek naar je innerlijke kind en geef hem/haar dan de vrijheid, geef dat kind de ruimte om open te komen en je te vertellen wat je kan doen om zo’n momenten opnieuw te beleven. Leer opnieuw te spelen en te ontdekken, rond te kijken met de ogen van een kind. En terwijl je daarmee bezig bent, kan je datzelfde kind ook helpen om te herstellen van pijnlijke ervaringen waar het misschien nog steeds mee zit. Je innerlijke kind helen en vieren is een stap vooruit zetten in je spirituele groei!</p>",
                    }
                },
                de : {
                    name: "Sechs der Kelche",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Wählen was man fühlt",    
                    description : "Die Sechs der Kelch spricht von emotionalen Erinnerungen und dass, egal, wie diese auch sind, wir uns entscheiden können, in der Gegenwart weiterzumachen.",
                    questions : [ "Können Sie eine Wahl unabhängig von Ihren Gefühlen treffen?", "Können Sie wählen, was Ihnen gut tut?", "Welche emotionale Erinnerung spielt noch eine Rolle?", "Können Sie sich dafür entscheiden, ein schlechtes Gefühl hinter sich zu lassen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Sechs der Kelche“ stehen oft für das sich Ergehen in Erinnerungen, die Erinnerung an die Art und Weise der Dinge, wie sie waren. In dieser Zeit Ihres Lebens denken Sie zurück an erfüllte Beziehungen, die Sie mit Menschen gehabt haben, die Sie gekannt und geliebt haben. Vielleicht kehren sogar glückliche Kindheitserinnerungen in Ihr Gedächtnis zurück. All diese Erinnerungen wecken in Ihnen die Sehnsucht nach einem Gefühl der Sicherheit. Obwohl es gut ist, die Vergangenheit nicht zu vergessen, ist es auch wichtig, sich an die Lektionen, die Sie gelernt haben, zu erinnern. Die Erfahrungen der Vergangenheit können Sie in Ihrer jetzigen Lage anwenden. Und es ist durchaus möglich, dass Sie auf schmerzliche Erfahrungen zurückblicken und erkennen, dass sie Sie nicht mehr verletzen können, weil Sie erwachsen sind und sich entwickelt haben.</p><p>Verweilen Sie nicht zu lange in der Vergangenheit. Denken Sie daran, dass die besten Jahre noch nicht vorbei sind, die kommen erst noch!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Die Sechs der Kelche kann darauf hin deuten, dass es an die Zeit ist nachzudenken über die Art und Weise worauf Ihre Beziehung sich entwickelt. Haben Sie klare Ziele, Dinge, die Sie gemeinsam erreichen möchten? Oder fließen Sie hauptsächlich mit dem Strom? Jetzt vielleicht der richtige Zeitpunkt, um gemeinsam zu entscheiden, welchen Weg Sie von hier aus gehen wollen.</p><p>Diese Karte kann auch die Rückkehr einen ehemaligen Liebhaber in Ihrem Leben symbolisieren. Auch wenn eine solche Begegnung großartig sein kann groß sein und gemeinsame Erinnerungen können erfüllend sein können, müssen Sie Sich zweimal überlegen, bevor Sie sich entscheiden, wieder zu erleben, was früher war. Sie beide sind jetzt verschiedene Menschen und was damals funktioniert hat, braucht jetzt nicht unbedingt auch wieder zu funktionieren. Schließlich haben Sie sich damals nicht ohne Grund getrennt. Vielleicht sollten Sie die Vergangenheit nicht mehr berühren.</p><p>Wenn Sie auf der Suche nach Liebe sind, ist jetzt ein guter Zeitpunkt, um die Vergangenheit zu überdenken und die Lektionen, die Sie gelernt haben neu zu beurteilen. Wie können diese Erfahrungen Ihnen behilflich sein bei der Suche nach dem richtigen Partner?</p>",
                        [GuidanceFields.WORK] : "<p>Sie haben im Moment ein ganz nostalgisches Gefühl. Sie genießen die Erinnerungen an früheren Arbeitsplätzen und Ex-Kollegen. Möglicherweise gehen Sie sogar so weit bis auf Ihre Kindheit zurück. Eine Revision der Vergangenheit gibt Ihnen ein Gefühl der Sicherheit, des Wohlbefindens. Dennoch hat es einen Einfluss auf Ihre Arbeit. Sie sind nicht wirklich anwesend, Sie sind in der Regel ein wenig verträumt. Ist es einfach eine Frage der harmlosen und vorübergehenden Nostalgie? Oder ist es ein Zeichen, dass Sie Ihrem Job nicht mehr wirklich genießen? Ist ein Teil Ihres Gefühls unerfüllt? Brauchen Sie neue Herausforderungen, neue Lernerfahrungen? Wenn dies der Fall ist, sollten Sie Sich umsehen nach einer Änderung in Ihrem beruflichen Verfassung.</p><p>Wenn Sie jetzt nicht gerade einen Job haben, ist es vielleicht eine gute Idee, um die Vergangenheit zu überdenken und Sich all diese Dinge, die Sie gerne getan haben zu erinnern. Das könnte Sie wirklich in die richtige Richtung bringen in der Suche nach der perfekten Position.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Um spirituell zu wachsen, könnte es jetzt der richtige Zeitpunkt sein, um Ihr inneres Kind zu konsultieren. Erinnern Sie sich an das tiefe Glück und die große Freude, die Sie fühlten, um als Kind zu leben? Das gute Gefühl, dass das Leben ein Abenteuer ist und Sie ständig neue Dinge entdecken konnten? Finden Sie Ihr inneres Kind und lassen Sie ihn, geben Sie ihm den Raum, den es braucht, um sich zu öffnen, um Ihnen zu sagen, was Sie tun können, um diese Gefühle wieder einzufangen. Und während Sie gerade dabei sind, können Sie es vielleicht helfen, sich von einigen weniger positiven Erfahrungen in der Vergangenheit zu erholen.</p>",
                    }
                }
            },
            {
                filename: "42",
                numerology : 7,
                en : {
                    name: "Seven of Cups",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "following your own feelings",    
                    description : "Seven of Cups talks about moving on with what you feel and possible illusions which can keep us from moving on.",
                    questions : [ "Are you the type of person who says, if… then-I-will?", "Do you have a lot of fantasies?", "Which one would you like to accomplish?", "If something sounds too good to be true does that keep you from doing it?", "Is that really the case, or is that an illusion?", "Dare you discover?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about choices, possibly and probably too many of them. The abundance of options and possibilities may be confusing you, making you feel as if there is way too much going on in your life. Maybe you even feel as if it is impossible to get and stay organized. If you are feeling overwhelmed there is always a danger of not seeing the wood for the trees, or possibly even take your wishes for reality and start building castles in the air. Now is the time to face up to reality, to look at what is really there and what is only going on in your mind.</p><p>Even if there are some aspects of your life you have no control over, there are things you can manage, it is up to you to take on the things you can influence and to accept the ones you can’t.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right nowit is important you stay realistic where your relationship is concerned. No matter how tempting it is to dream and fantasize about a myriad of possibilities, you have to stay real. Decide which goals you and your partner want to reach and go for them. Don’t lose yourself in superficial (though romantic) gestures and daydreams. Building a viable relationship is mainly a matter of commitment and realism.</p><p>If you are single and you fall head over heels for someone, ask yourself if there’s more than just infatuation. Is this person someone you can see yourself spending your life with? Do you have a lot in common? Could the two of you survive in the ‘real’ world together? Don’t let your (temporary) passion blind you, look beyond it.</p>",
                        [GuidanceFields.WORK] : "<p>You may be feeling very creative and inspired at work, you feel as if you have an abundance of options, many possible ways to go. Still, before you try out any of those options, it may be a good idea to investigate them with a large pinch of realism. Are all those ideas viable? Or are you getting carried away by your enthusiasm, your creativity? Make sure you only set yourself those goals that you are realistically able to reach. Don’t set yourself up for disappointment or failure. You do have a lot of qualities, but you don’t have them all.</p><p>If you are looking for a job, be sure to apply only for those positions you are actually suited for. Take a realistic look at your abilities and act accordingly. </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>On a spiritual level you may find yourself a bit overwhelmed. There seem to be so many choices and options, so many possible projects.What to choose, what to do first? Maybe the best first step would be to look for some help and guidance. There are a lot of positive and spiritual people around who can guide you through this jungle of possibilities. And your own positive attitude will provide the perfect foundation to build on.</p>",
                    }
                },
                fr : {
                    name : "Le Sept de Coupe",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "Suivez vos propres sentiments",    
                    description : " Le Sept de Coupe, nous parle de continuer avec ce que nous ressentons et d’illusions possibles qui peuvent inutilement nous empêcher de le faire.",
                    questions : ["Êtes-vous quelqu’un qui pose des conditions avant de le faire?", "Avez-vous beaucoup de fantasmes?", "Quels fantasmes voulez-vous réaliser?", "Vous laissez-vous arrêter si cela semble trop beau pour être vrai?", "Est-ce vraiment vrai, ou est-ce une illusion?", "Osez-vous le découvrir?"],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente des décisions, peut-être et probablement trop nombreuses. Cette abondance d'options et de possibilités peut vous embrouiller. Cela peut vous donner l'impression qu'il se passe trop de choses dans votre vie. Vous pourriez même penser qu'il est impossible de s'organiser et de rester organisé. Si vous êtes submergés, il y a toujours le danger de ne pas voir l’arbre qui cache la forêt, de croire que vos désirs sont réalité ou de construire des illusions. Maintenant, il est temps de faire face à la réalité, de se concentrer sur ce qui est réellement là et non sur ce qui vit seulement dans votre esprit.</p><p>Bien qu'il y ait certains aspects de votre vie sur lesquels vous n'avez aucun contrôle, sur d’autres c’est possible. C'est votre décision de vous concentrer sur ce que vous pouvez influencer et non sur ce qui ne l’est pas. Vous devriez l'accepter.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right nowit is important you stay realistic where your relationship is concerned. No matter how tempting it is to dream and fantasize about a myriad of possibilities, you have to stay real. Decide which goals you and your partner want to reach and go for them. Don’t lose yourself in superficial (though romantic) gestures and daydreams. Building a viable relationship is mainly a matter of commitment and realism.</p><p>If you are single and you fall head over heels for someone, ask yourself if there’s more than just infatuation. Is this person someone you can see yourself spending your life with? Do you have a lot in common? Could the two of you survive in the ‘real’ world together? Don’t let your (temporary) passion blind you, look beyond it.</p>",
                        [GuidanceFields.WORK] : "<p>You may be feeling very creative and inspired at work, you feel as if you have an abundance of options, many possible ways to go. Still, before you try out any of those options, it may be a good idea to investigate them with a large pinch of realism. Are all those ideas viable? Or are you getting carried away by your enthusiasm, your creativity? Make sure you only set yourself those goals that you are realistically able to reach. Don’t set yourself up for disappointment or failure. You do have a lot of qualities, but you don’t have them all.</p><p>If you are looking for a job, be sure to apply only for those positions you are actually suited for. Take a realistic look at your abilities and act accordingly. </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>On a spiritual level you may find yourself a bit overwhelmed. There seem to be so many choices and options, so many possible projects.What to choose, what to do first? Maybe the best first step would be to look for some help and guidance. There are a lot of positive and spiritual people around who can guide you through this jungle of possibilities. And your own positive attitude will provide the perfect foundation to build on.</p>",
                    }
                },
                sp:{
                    name:"El Siete de Copas",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "Sigue tus propios sentimientos",    
                    description: "El Siete de Copas trata de continuar con lo que sientes y nos señala nuestras posibles ilusiones que pueden impedirnos (hacerlo) innecesariamente",
                    questions: ["¿Eres alguien: sí esto es lo primero ... entonces .. iré?", "¿Tienes muchas fantasías?", "¿Qué fantasías quieres realizar?", "¿Te dejas detener si te parece demasiado hermoso para ser verdad?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es acerca de elecciones, posibles y probablemente muchos de ellos. La abundancia de opciones y posibilidades puede ser confuso á usted, haciéndose sentir como si hay demasiado pasando en su vida. Tal vez incluso se siente como si esta casi  imposible conseguir y mantenerse organizado. Si se siente abrumado siempre hay un peligro de no ver el bosque por los árboles, o posiblemente incluso tomar sus deseos por la realidad y empezar a construir castillos en el aire. Ahora es el momento de afrontar la realidad, a ver lo que realmente está allí y lo único que está pasando en tu mente. Aunque hay algunos aspectos de su vida sobre que no tiene control, hay cosas que usted puede manejar, es a usted para asumir las cosas que puede influir y para aceptar las que no pueden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Bekers Zeven",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "je eigen gevoel volgen",    
                    description : "De Zeven van Bekers gaat over verder gaan met wat je voelt en wijst ons op onze mogelijke illusies die ons daar onnodig van kunnen weerhouden.",
                    questions : [ "Ben jij iemand van:áls eerst dit..dán..ga ik wel...?", "Heb jij veel fantasieën?", "Welke fantasie wil jij verwezenlijken?", "Laat jij je weerhouden als het te mooi lijkt om waar te kunnen zijn?", "Is dat werkelijk zo, of is dat een illusie?", "Durf jij het te ontdekken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Zeven van Bekers symboliseert keuzes, ze kan er zelfs op wijzen dat je teveel keuzes hebt op dit moment. Een overweldigende hoeveelheid mogelijkheden en opties kan verwarrend werken. Het kan je het gevoel geven dat er gewoon teveel aan de hand is in je leven. Misschien heb je zelfs het gevoel dat het onmogelijk is om wat structuur in je leven te krijgen en te houden. Als je je overweldigd voelt dan loop je altijd het gevaar om door de bomen het bos niet meer te zien. Het is dan ook mogelijk dat je je wensen voor waarheid aanneemt en luchtkastelen gaat bouwen. Nu is het nochtans zaak om de realiteit onder ogen te zien en te bekijken wat er echt aan de hand is en wat enkel in je verbeelding bestaat.</p><p>Er zijn altijd bepaalde aspecten van je leven waar je geen enkele controle over hebt, maar er zijn ook altijd dingen die jij kan bepalen. Nu is het aan jou om die dingen die je niet kan bepalen te aanvaarden en om aan de slag te gaan met de rest. Acceptatie + Actie = Groei!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment is het belangrijk dat je een realistische kijk blijft houden op je relatie. Ook al is het heel verleidelijk om te gaan dromen en fantaseren over oneindige mogelijkheden, het is de realiteit waarmee je het moet doen. Overleg met je partner, beslis samen welke doelen jullie willen bereiken en ga dan aan de slag. Verlies je niet in oppervlakkige (misschien wel romantische) gebaren en dagdromen. Een langdurige levensvatbare relatie wordt nu eenmaal opgebouwd met toewijding en realiteitszin.</p><p>Als je single bent zit het erin dat je hals over kop verliefd wordt op iemand. Als dit het geval is, vraag je dan af of er wel iets meer is dan enkel vlinders in je buik. Kijk eens objectief naar deze persoon en stel jezelf de volgende vragen: kan je je voorstellen dat je de rest van je leven samen bent met deze persoon? Hebben jullie veel dingen gemeenschappelijk? Zouden jullie het samen kunnen maken in de ‘echte’ wereld? Laat je niet verblinden door je (tijdelijke) passie, kijk ook wat verder.</p>",
                        [GuidanceFields.WORK] : "<p>Je voelt je waarschijnlijk behoorlijk creatief en geïnspireerd in verband met je werk. Het voelt alsof je onmetelijk veel opties hebt, allerlei wegen die je kan kiezen. Voordat je die nieuwe opties uitprobeert, is het verstandig om ze te kruiden met een behoorlijke dosis realiteitszin. Zijn jouw ideeën echt levensvatbaar? Of laat je je overweldigen door je enthousiasme en je creativiteit? Zorg ervoor dat de doelen die je jezelf stelt ook haalbaar zijn, dat ze gerealiseerd kunnen worden. Anders ga je voor jezelf teleurstellingen plannen in plaats van successen. Je hebt vele kwaliteiten maar je bent niet perfect!</p><p>Als je op zoek bent naar werk dan is het belangrijk dat je nu enkel gaat solliciteren voor jobs die echt geschikt zijn voor jou. Kijk realistisch naar je kwaliteiten en talenten en kies de vacatures in functie daarvan.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op spiritueel vlak is het best mogelijk dat je je wat overweldigd voelt door alle mogelijkheden die je op dit moment ziet: er zijn zoveel theorieën om te onderzoeken, zoveel mogelijke keuzes, zoveel praktische toepassingen, de opties lijken wel oneindig. Hoe beslis je wat je uitkiest, wat pak je het eerst aan?</p><p>Dit is voor jou het juiste moment om hulp en leiding te vragen. Er zijn best veel positieve en spirituele mensen die je kunnen gidsen doorheen deze jungle van mogelijkheden. Je eigen positieve instelling zal de perfecte bagage zijn om deze zoektocht mee te ondernemen!</p>",
                    }
                },
                de : {
                    name: "Sieben der Kelche",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Den eigenen Gefühlen folgen.",    
                    description : "Die Sieben der Kelche spricht darüber, damit fortzufahren, was wir fühlen, und mit möglichen Illusionen, die uns unnötigerweise daran hindern können, dies zu tun.",
                    questions : [ "Sind Sie jemand, der vorher Bedingungen stellt?", " Haben Sie viele Fantasien?", "Welche Fantasien wollen Sie verwirklichen?", "Lassen Sie sich aufhalten, wenn es zu gut ist, um wahr zu sein?", "Ist es wirklich wahr oder ist es eine Illusion?", "Wagen Sie es, dies zu entdecken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für Entscheidungen, möglicherweise und wahrscheinlich für zu viele. Dieser Überfluss an Optionen und Möglichkeiten kann Sie verwirren. Er kann Ihnen das Gefühl geben, als wäre in Ihrem Leben zu viel los. Man könnte sogar das Gefühl bekommen, dass es unmöglich ist, sich zu organisieren und organisiert zu bleiben. Wenn Sie überwältigt sind besteht immer die Gefahr, den Wald vor lauter Bäumen nicht mehr zu sehen, oder vielleicht sogar Ihre Wünsche für die Wirklichkeit zu halten und Luftschlösser zu bauen. Jetzt ist es Zeit, sich mit der Realität auseinanderzusetzen und auf das zu konzentrieren, was tatsächlich da ist, und nicht auf das, was nur in Ihrem Geist lebt.</p><p>Auch wenn es einige Aspekte Ihres Lebens gibt, über die Sie keine Kontrolle haben, gibt es auch Dinge, die Sie beherrschen können. Es ist Ihre Entscheidung, sich auf die Dinge zu konzentrieren,  die Sie beeinflussen <b>können</b> und nicht auf die, die Sie nicht beeinflussen können. Das sollten Sie akzeptieren.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Gerade jetzt ist es wichtig, dass Sie realistisch bleiben, wo es Ihre Beziehung anbelangt. Egal, wie verlockend es ist zu träumen und fantasieren über eine Vielzahl von Möglichkeiten, Sie haben jetzt wirklich zu tun. Entscheiden Sie, welche Ziele Sie und Ihr Partner erreichen wollen und ziele sie nach. Verlieren Sie Sich nicht in oberflächliche (wenn auch romantischen) Gesten und Tagträume. Der Aufbau einer tragfähigen Beziehung ist vor allem eine Frage des Engagements und Realismus.</p><p>Wenn Sie alleine sind und Sie sich Hals über Kopf in jemanden verlieben, fragen Sie sich, ob es tatsächlich mehr als nur Verliebtheit ist. Ist diese Person jemand, mit der Sie Ihr Leben verbringen wollen? Haben Sie viel gemeinsam? Könnte Ihr beide zusammen überleben in der \"realistischen\" Welt? Lassen Sie Sich von Ihrem (vorübergehenden) Leidenschaft nicht blind machen, schauen Sie darüber hinaus.</p>",
                        [GuidanceFields.WORK] : "<p>Sie fühlen Sich sehr kreativ und inspiriert bei der Arbeit. Sie fühlen Sich, als ob Sie eine Fülle von Optionen zur Verfügung haben, viele Möglichkeiten. Dennoch, bevor Sie diese mal ausprobieren, kann es eine gute Idee sein sie erst mal mit einem großen Sinn für Realismus zu untersuchen. Sind all diese Ideen tragfähig? Oder lassen Sie Sich führen von Ihrem Enthusiasmus und Kreativität? Setzen Sie Sich nur die Ziele, die realistisch gesehen erreichbar sind. Stellen Sie Sich nicht offen für Enttäuschung oder Misserfolg. Sie haben eine Menge von Eigenschaften, aber Sie müssen sie nicht alle haben.</p><p>Wenn Sie auf der Suche nach einem Job sind, sollten Sie nur für die für Sie geeignete Positionen wählen. Werfen Sie einen realistischen Blick auf Ihre Fähigkeiten und handeln Sie dem entsprechend.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Auf spiritueller Ebene sind Sie vielleicht etwas überfordert. Es gibt so viele Möglichkeiten und Optionen, so viele mögliche Projekte. Was zu wählen, was als Erstes zu tun? Vielleicht wäre der beste erste Schritt erst mal nach etwas Hilfe und Beratung zu suchen. Es gibt eine Menge von positiven und spirituellen Menschen, die Sie durch den Dschungel der Möglichkeiten führen könnte. Und Ihre eigene positive Einstellung wird die perfekte Grundlage bieten, um darauf aufzubauen.</p>",
                    }
                }
            },
            {
                filename: "43",
                numerology : 8,
                en : {
                    name: "Eight of Cups",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "taking responsibility for your feelings",    
                    description : "Eight of Cups talks about taking responsibilities for what you feel and know that what we feel is right, even if the end of the road is yet invisible.",
                    questions : [ "Do you feel that you have to take a different path?", "Is the result still unknown to you?", "Can you follow your feeling, even if you don't know what it leads to?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>If you draw this card it is very likely that you are being confronted with a choice. You are no longer comfortable with a situation, a person, a relationship of maybe even a living environment, and you are contemplating on putting an end to it. You are the only one who can make this choice because only you know exactly why you are no longer happy, you are aware of what is missing. It is well possible that on the outside everything is looking hunky dory but you know this is not what you want, you want more and you are ready to go for it, even if you are not entirely sure what you will be looking for. And even though you are sad about the things or persons you have to leave behind, you realize that going away is in your best interest. You have to close one door before you can start opening the next one.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Getting the Eight of Cups generally means an ending of some sorts.Maybe you are actually breaking up with your partner because the relationship is no longer offering you what you want and need. It is also possible that you suddenly see your partner in a new light. You may have been keeping the perception of her/him the way you saw them at the start of your relationship. Now reality comes knocking and you find yourself with a totally different person. If this is the case, don’t take too long to decide if you’re going to stay or go. Nobody appreciates being left in the dark.</p><p>If you consider looking for a partner, take your time to find out if you really are over the last one. Still being hung up on an ex is not exactly the best way to start a relationship.</p>",
                        [GuidanceFields.WORK] : "<p>Nowadays at work it seems as if you’ve disengaged, you have no sense of connection with your job anymore, maybe you are even feeling bored. Do you want to stay where you are and try to change the circumstances? It could be as easy as having a heart to heart with your superiors to make you feel appreciated again, to rediscover your former motivation. Or do you want a clean slate, do you want to go out and find a different job? There are no right or wrong choices here, you are the only one who knows what to do. Go with your gut, your intuition.</p><p>If you are looking for a job, it may be hard to stay motivated. Consider the possibility of taking a small break, to get rid of some of your stress. You will be surprised how motivated you’ll feel after your ‘sabbatical’.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Right now you may feel unsatisfied on a spiritual level. Books, courses, lectures … you’ve been there, done that, bought the t-shirt. The things you are looking for probably won’t be found in the outside world. Now is the time to turn inside, to seek your own company in the first place, to listen to your inner voice. You have been evolving quite a lot spiritually and you will be able to harvest at least some of the fruits of your labor by simply listening to yourself.</p>",
                    }
                },
                fr : {
                    name : "Le Huit de Coupe",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "prendre la responsabilité de ce que l'on ressent",    
                    description : "Le Huit de Coupe, nous parle de prendre la responsabilité de ce que nous ressentons et d’aller dans la direction que nous estimons être la nôtre, même lorsque le point final n'est pas encore visible.",
                    questions : ["Sentez-vous que vous devez prendre un autre chemin?", "Le résultat vous est-il encore inconnu?", "Pouvez-vous suivre vos sentiments, même si vous ne savez pas encore où ils vous mènent?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Si vous tirez cette carte, il est très probable que vous serez confronté à une décision.  Vous ne vous sentez plus à l'aise avec une situation, avec une personne, avec une relation, peut-être même avec un habitat. Vous avez atteint un point où vous pouvez mettre fin à quelque chose. Vous êtes le seul à pouvoir prendre cette décision. Parce que vous seul savez exactement pourquoi vous n'êtes plus heureux. Vous êtes le seul à être pleinement conscient de ce qui manque. Il est tout à fait possible que votre vie, de l'extérieur, semble être en parfait ordre. Mais vous savez que ce n'est pas ce que vous voulez.  Vous en voulez plus et vous êtes prêt à vous engager pour ce sujet, tout en ne sachant pas exactement ce que vous cherchez. Même si vous êtes triste par rapport à des situations ou des gens que vous laisserez derrière vous, vous savez que c'est la meilleure option. Parfois, il faut fermer une porte avant que la suivante ne s'ouvre.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Getting the Eight of Cups generally means an ending of some sorts.Maybe you are actually breaking up with your partner because the relationship is no longer offering you what you want and need. It is also possible that you suddenly see your partner in a new light. You may have been keeping the perception of her/him the way you saw them at the start of your relationship. Now reality comes knocking and you find yourself with a totally different person. If this is the case, don’t take too long to decide if you’re going to stay or go. Nobody appreciates being left in the dark.</p><p>If you consider looking for a partner, take your time to find out if you really are over the last one. Still being hung up on an ex is not exactly the best way to start a relationship.</p>",
                        [GuidanceFields.WORK] : "<p>Nowadays at work it seems as if you’ve disengaged, you have no sense of connection with your job anymore, maybe you are even feeling bored. Do you want to stay where you are and try to change the circumstances? It could be as easy as having a heart to heart with your superiors to make you feel appreciated again, to rediscover your former motivation. Or do you want a clean slate, do you want to go out and find a different job? There are no right or wrong choices here, you are the only one who knows what to do. Go with your gut, your intuition.</p><p>If you are looking for a job, it may be hard to stay motivated. Consider the possibility of taking a small break, to get rid of some of your stress. You will be surprised how motivated you’ll feel after your ‘sabbatical’.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Right now you may feel unsatisfied on a spiritual level. Books, courses, lectures … you’ve been there, done that, bought the t-shirt. The things you are looking for probably won’t be found in the outside world. Now is the time to turn inside, to seek your own company in the first place, to listen to your inner voice. You have been evolving quite a lot spiritually and you will be able to harvest at least some of the fruits of your labor by simply listening to yourself.</p>",
                    }
                },
                sp:{
                    name:"El Ocho de copas",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "Asumir la responsabilidad de lo que sientes",    
                    description: "El Ocho de Copas se trata de tomar responsabilidad por lo que sientes y señalarnos que tenemos que ir por ese camino que sentimos que es nuestro camino, incluso cuando el punto final aún no es visible",
                    questions: ["¿Sientes que tienes que tomar un camino diferente?", "¿Sigues sin conocer el resultado?", "¿Puedes seguir tus sentimientos, aunque todavía no sepas a qué lleva eso?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Si saca esta tarjeta es muy probable que usted estés confrontado con una elección. Ya no se siente cómodo con una situación, una persona, una relación de tal vez incluso un entorno de vida, y usted está contemplando en poner fin a la misma. Usted es el único que puede tomar esta decisión porque sólo usted sabe exactamente por qué ya no es feliz, es consciente de lo que falta. Es bien posible que en el todo lo que no esté buscando miel sobre hojuelas, pero usted sepa que esto no es lo que quieres, quieres más y ya está listo para ir por ella, incluso si no está del todo seguro de lo que está buscando. Y a pesar de estar triste por las cosas o las personas que tienen que dejar atrás, le da cuenta que lo que va a desaparecer es en su mejor interés. Tiene que cerrar una puerta antes de que pueda empezar a abrir la siguiente.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Bekers Acht",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "verantwoording nemen voor wat je voelt",    
                    description : "De Acht van Bekers gaat over verantwoording nemen voor wat je voelt en wijst ons er op dat we die weg dienen te gaan waarvan we voelen dat het onze weg is, zelfs wanneer het eindpunt nog niet zichtbaar is.",
                    questions : [ "Voel jij dat je een andere weg in dient te slaan?", "Is het gevolg daarvan je nog onbekend?", "Kun jij je gevoel volgen, ook al weet je nog niet waartoe dat leidt?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Als je deze kaart trekt dan is het goed mogelijk dat je op dit moment geconfronteerd wordt met een keuze of keuzes. Je bent niet langer tevreden met een bepaalde situatie, persoon, relatie of misschien zelfs met je woonomgeving en je overweegt om er een eind aan te breien. Jij bent de enige die deze keuze kan maken omdat jij en jij alleen exact weet waarom je niet langer gelukkig bent. Jij bent je ten volle bewust van wat je mist, welke behoefte niet vervuld wordt. Het is trouwens best mogelijk dat je leven er aan de buitenkant perfect normaal en evenwichtig uitziet. Maar jij weet dat dit niet is wat je wil, je wil meer dan dit. Je bent er klaar voor om naar dat ‘meer’ op zoek te gaan, ook al weet je misschien niet nog duidelijk wat het inhoudt, waar je naar op zoek bent. En ook al ben je verdrietig omdat je bepaalde mensen, situaties, dingen achter je zal moeten laten, je weet en gelooft dat deze stap op dit moment de beste is voor jou. Soms moet je eerst een deur dichtdoen en kan je daarna pas de weg zien naar de volgende deur.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In verband met relaties wijst de Acht van Bekers over het algemeen op een soort einde, een afsluiting. Het is mogelijk dat je effectief een punt zet achter de relatie met je geliefde omdat je er niet meer kan uithalen wat je nodig hebt en waar je naar verlangt. Het is ook mogelijk dat je je partner plots in een nieuw licht ziet. Vaak is het zo dat je je geliefde blijft waarnemen zoals je dat in het begin van je relatie deed, je beeld verandert dan niet of nauwelijks. Nu komt de realiteit aankloppen en besef je dat je partner een totaal andere persoon geworden is. Als dit het geval is, doe er dan niet te lang over om de knoop door te hakken en te beslissen of je al dan niet kan leven met deze nieuwe visie op je wederhelft. Tenslotte vindt niemand het fijn om niet te weten waar hij/zij aan toe is.</p><p>Als je van plan bent om op zoek te gaan naar een partner, bekijk dan eerst voor jezelf of je echt wel over je vorige relatie heen bent. Het is heel belangrijk dat je op geen enkele manier nog verlangt naar je ex, dat je niets mist van wat er was. Anders zal dit je nieuwe relatie van in het begin op een negatieve manier kleuren.</p>",
                        [GuidanceFields.WORK] : "<p>Het kan tegenwoordig op het werk zo lijken alsof je afgehaakt hebt, alsof je je niet meer verbonden voelt met je werk en je werkomgeving. Misschien verveel je je zelfs. Je hebt nu twee opties: wil je blijven waar je bent en proberen om de situatie te veranderen? Het is best mogelijk dat je dit relatief simpel kan bereiken door bvb. een open gesprek te hebben met je meerderen over je behoefte aan waardering.</p><p>Daardoor kan je vroegere motivatie terug openbloeien. Of wil je met een schone lei starten en een andere baan gaan zoeken? Er zijn hier geen goede of foute keuzes, jij bent de enige die echt weet wat je wil doen. Nu is het belangrijk dat je je buikgevoel vertrouwt, luister naar je intuïtie.</p><p>Als je op zoek bent naar werk is het op dit moment niet gemakkelijk om gemotiveerd te blijven. Overweeg eens of het misschien verstandig zou zijn om een korte pauze in te lassen zodat je je stressniveau wat kan verlagen. Je zal merken dat je een stuk gemotiveerder bent na je ‘vakantie’!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Waarschijnlijk voel je je op spiritueel vlak een stuk onbevredigd op dit moment. Boeken, cursussen, lezingen, oefeningen ... je hebt het allemaal al gezien en meegemaakt, er is niets dat je nog echt aanspreekt, het voelt allemaal te vertrouwd. Eigenlijk komt het erop neer dat je wat jij zoekt niet zal vinden in de wereld om je heen, hoe hard je ook zoekt. Het is nu zaak om je naar binnen te keren, je vooral te wijden aan je eigen gezelschap, naar je innerlijke stem te luisteren. Je bent in de voorbije periode behoorlijk gegroeid op spiritueel vlak, als is dat bijna ongemerkt gegaan. Nu is het zaak om de vruchten te plukken van die groei en naar je eigen spirituele stem te luisteren, stel je open voor jezelf!</p>",
                    }
                },
                de : {
                    name: "Acht der Kelche",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Die Verantwortung für seine Gefühle übernehmen",    
                    description : "Die Acht der Kelche spricht von der Verantwortung für das, was wir fühlen, und dass wir in die Richtung gehen sollen, die wir für unsere eigene halten, auch wenn der Endpunkt noch nicht sichtbar ist.",
                    questions : [ "Haben Sie das Gefühl, dass Sie einen anderen Weg gehen sollten?", "Ist Ihnen das Ergebnis noch unbekannt?", "Können Sie Ihren Gefühlen folgen, auch wenn Sie noch nicht wissen, wohin sie Sie führen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Wenn Sie diese Karte ziehen  ist es sehr wahrscheinlich, dass Sie mit einer Entscheidung konfrontiert werden. Sie fühlen sich nicht mehr wohl mit einer Situation, mit einer Person, mit einer Beziehung, vielleicht sogar mit einem Lebensraum. Sie sind an einem Punkt angelangt, etwas zu beenden. Sie sind der Einzige, der diese Entscheidung treffen kann. Denn nur Sie wissen genau, warum Sie nicht mehr glücklich sind. Sie sind der Einzige, der sich voll und ganz dessen bewusst ist, was fehlt. Es ist durchaus möglich, dass Ihr Leben, von außen betrachtet, zwar in bester Ordnung zu sein scheint. Aber Sie wissen, dass es nicht das ist, was Sie wollen. Sie wollen mehr und sind bereit, sich dafür einzusetzen. Auch wenn Sie nicht ganz sicher wissen, was Sie genau suchen. Auch wenn Sie wegen Dinge oder Personen, die Sie zurücklassen müssen, traurig sind, so wissen Sie doch, dass das die beste Option ist. Manchmal muss man eine Tür schließen, bevor sich die nächste öffnet.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Das Ziehen der Acht der Kelche bedeutet in der Regel ein Ende von etwas. Vielleicht sind Sie tatsächlich dabei aufzubrechen mit Ihrem Partner, weil die Beziehung Ihnen nicht mehr das bietet, was Sie wollen und brauchen. Es ist auch möglich, dass Sie plötzlich Ihren Partner in einem ganz neuen Licht sehen. Möglicherweise wurde die Wahrnehmung, so wie die am Anfang Ihrer Beziehung war, beibehalten. Jetzt klopft die Realität an der Tür und sehen Sie jetzt einen völlig anderen Person. Wenn dies der Fall ist, zögern Sie nicht zu lange Sich zu entscheiden, ob Sie gehen oder bleiben möchten. Niemand schätzt es lange im Dunkeln gelassen zu werden. Wenn Sie auf der Suche nach einem Partner sind, nehmen Sie sich Zeit, um herauszufinden, ob Sie wirklich über die letzte Beziehung hinweg gekommen sind. Wenn man immer noch an eine alte Beziehung hängt ist das nicht gerade das beste Moment, um eine neue Beziehung anzufangen.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit scheint es, als ob Sie Sich davon gelöst haben, keinen Sinn mehr haben für Verbindung mit Ihrem Job. Vielleicht langweilen Sie Sich sogar. Wollen Sie bleiben, wo Sie sind und versuchen, die Umstände zu ändern? Es könnte so einfach sein ein gutes Gespräch mit Ihrem Vorgesetzten zu haben, damit Sie Sich wieder geschätzt fühlen und Ihre ehemalige Motivation wieder entdecken. Oder möchten Sie mit einer sauberen Weste anfangen und Sich einen anderen Job suchen? Es gibt keine richtigen oder falschen Entscheidungen hier, Sie sind der einzige, der weiß, was zu tun ist. Gehen Sie mit Ihrem Gefühl, mit Ihrer Intuition.</p><p>Wenn Sie einen Job suchen, kann es schwierig sein, motiviert zu bleiben. Betrachten Sie die Möglichkeit, eine kleine Pause zu machen um Ihren Stress loszuwerden. Sie werden überrascht sein, wie motiviert Sie Sich nach Ihrem \"Sabbatical\" fühlen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Im Moment fühlen Sie sich möglicherweise auf einer spirituellen Ebene unzufrieden. Bücher, Kurse, Vorträge … Sie sind dort gewesen sind, haben alles getan, sogar Sich dieses T-Shirt gekauft. Die Dinge, die Sie suchen, werden Sie wahrscheinlich nicht in der Außenwelt finden. Jetzt ist die Zeit sich nach Innen zu wenden, um Ihre eigene Gesellschaft zu suchen und um auf Ihre innere Stimme zu hören. Sie haben Sich spirituell ziemlich entwickelt und Sie werden jetzt bestimmt in der Lage sein, zumindest einige der Früchte Ihrer Arbeit zu ernten, indem Sie einfach auf Sich Selbst hören.</p>",
                    }
                }
            },
            {
                filename: "44",
                numerology : 9,
                en : {
                    name: "Nine of Cups",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "knowing what you feel",    
                    description : "Nine of Cups talks about being aware of our own feelings and that it is ok to show them.",
                    questions : [ "Are you aware of your own feelings?", "Can you show them or are you always ok?", "How open can you be about your real feelings?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Nine of Cups is generally one of the most positive cards in the Tarot card deck, it speaks of domestic bliss, being carefree, having fun, generally enjoying yourself. If you have certain hopes and dreams you really wish would come true then this card tells you that this is very likely to be happening in the somewhat near future. If you are having doubts or questions on certain issues, then drawing this card means that a positive outcome is probable.</p><p>There is one thing to watch out for: don’t become too smug, too sure of yourself.</p><p>This card is also being associated with team spirit, finding it easy to work together, experiencing harmony in your contacts (private and business). Enjoy this uplifting experience and make sure things stay this way by consciously working at maintaining this harmonious spirit.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This is a great time to be in a relationship. You and your partner are very likely to go to the next level, to reach new heights of fulfillment. Your love for eachother is getting stronger every day and you are finding new ways of expressing it. You may find new depths of emotion you didn’t realize were possible.</p><p>If you are single, now is the time to go out and have fun. You will radiate a positivity that helps you meet all kinds of people. Don’t focus on finding a partner too much but enjoy the ride. Mr or Miss Right will probably be among the people you meet and all will become clear in time.</p>",
                        [GuidanceFields.WORK] : "<p>This card is sometimes being associated with team spirit, finding it easy to work together, Right now there is a lot of harmony in your contacts (private and business). Enjoy this uplifting experience and make sure things stay this way: consciously work at maintaining the harmony.</p><p>For the moment it seems as if you can’t go wrong, there’s success in everything you do. If you were thinking about a new technique or a new way of organizing things, now is the time to set the wheels in motion. Your ideas will surely be welcomed.</p><p>If you are looking for work, be sure to set up a lot of interviews. You will dazzle the interviewers with your vibrant personality and positive outlook!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Nine of Cups isn’t the most spiritual of cards because it’s all about enjoyment and fulfilment of the senses. Still, thoroughly enjoying all aspects of everyday life can help you to find spiritual balance and harmony.</p><p>No matter how happy you are with things as they are right now, take some time to look inside yourself and to create a mental image of the things you still want to achieve spiritually. Don’t be shy, go for gold!</p>",
                    }
                },
                fr : {
                    name : "Le Neuf de Coupe",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "savoir ce que l'on ressent",    
                    description : " Le Neuf de Coupe, nous parle d’être conscient de ses propres sentiments et que nous pouvons les montrer.",
                    questions : ["Êtes-vous conscient de ce que vous ressentez?", "Pouvez-vous le montrer, ou dites-vous toujours que tout va bien?", "Dans quelle mesure pouvez-vous être ouvert concernant ce que vous ressentez vraiment?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La \"Neuf de Coupe\" est l'une des cartes les plus positives du jeu de Tarot. La carte parle de bonheur domestique, d'insouciance et de plaisir. Maintenant, il est temps de profiter de toutes sortes de joies, qu'il s'agisse d'une très bonne nourriture, d'un bain de soleil ou d'une aventure érotique. Tous les souhaits peuvent maintenant être exaucés.</p><p>Sur un autre plan, cette carte vous dit que vous avez certains espoirs et rêves dont vous souhaitez la réalisation. C'est très probable dans un proche avenir. Si vous avez des doutes ou des questions sur certains sujets, cette carte signifie qu'un résultat positif est imminent. C'est pourquoi le \"Neuf de Coupe\" est aussi appelé carte de vœux.</p><p>Mais faites attention à ne pas devenir trop complaisant, trop sûr de vous. Et n'oubliez pas que toutes vos actions ont des conséquences, non seulement pour vous-même, mais pour toutes les personnes concernées. Le fait que vous vous sentiez bien dans certaines choses ne signifie pas que d'autres en font l'expérience.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This is a great time to be in a relationship. You and your partner are very likely to go to the next level, to reach new heights of fulfillment. Your love for eachother is getting stronger every day and you are finding new ways of expressing it. You may find new depths of emotion you didn’t realize were possible.</p><p>If you are single, now is the time to go out and have fun. You will radiate a positivity that helps you meet all kinds of people. Don’t focus on finding a partner too much but enjoy the ride. Mr or Miss Right will probably be among the people you meet and all will become clear in time.</p>",
                        [GuidanceFields.WORK] : "<p>This card is sometimes being associated with team spirit, finding it easy to work together, Right now there is a lot of harmony in your contacts (private and business). Enjoy this uplifting experience and make sure things stay this way: consciously work at maintaining the harmony.</p><p>For the moment it seems as if you can’t go wrong, there’s success in everything you do. If you were thinking about a new technique or a new way of organizing things, now is the time to set the wheels in motion. Your ideas will surely be welcomed.</p><p>If you are looking for work, be sure to set up a lot of interviews. You will dazzle the interviewers with your vibrant personality and positive outlook!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Nine of Cups isn’t the most spiritual of cards because it’s all about enjoyment and fulfilment of the senses. Still, thoroughly enjoying all aspects of everyday life can help you to find spiritual balance and harmony.</p><p>No matter how happy you are with things as they are right now, take some time to look inside yourself and to create a mental image of the things you still want to achieve spiritually. Don’t be shy, go for gold!</p>",
                    }
                },
                sp:{
                    name:"El Nueve de Copas",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "saber cómo te sientes",    
                    description: "El Nueve de Copas se trata de ser consciente de tus propios sentimientos y nos recuerda que podemos mostrar nuestros sentimientos",
                    questions: ["¿Eres consciente de lo que sientes?", "¿Puedes demostrarlo, o eres una persona  que lo haces mejor de lo que es?", "¿Qué abierto puedes estar sobre lo que realmente sientes?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Nueve de Copas es generalmente una de las cartas más positivos en la baraja del tarot, se habla de la felicidad doméstica, no tener preocupaciones, divertirse, en general disfrutar de su mismo. Si usted tiene ciertas esperanzas y sueños que realmente desea haría realidad entonces esta tarjeta le dice que esto es muy probable que se realiza en el futuro algo cercano. Si tiene dudas o preguntas sobre ciertos temas, entonces, sacando esta tarjeta significa que un resultado positivo es probable. Hay una cosa a tener en cuenta: no se haga demasiado engreído, muy seguro de su mismo. Esta tarjeta también se asocia con el espíritu de equipo, la búsqueda trabajando juntos fácilmente, experimentar la armonía en sus contactos (particulares y empresas). Disfrute de esta experiencia edificante y hacen que todo esté así por trabajar conscientemente a mantener este espíritu armonioso.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Bekers Negen",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "weten wat je voelt",    
                    description : "De Negen van Bekers gaat over bewust zijn van je eigen gevoel en wijst ons er op dat we onze gevoelens kunnen tonen.",
                    questions : [ "Ben jij je bewust van hetgeen je voelt?", "Kun jij dat tonen, of ben je een 'mooi-weer-speler'?", "Hoe open kun jij zijn over wat je echt voelt?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Negen van Bekers is een van de meest positieve kaarten van de hele Tarot. Trefwoorden zijn huiselijk geluk, zorgeloosheid, plezier en vreugde. Het komt er nu op aan dat je gaat genieten, of het nu gaat om een uurtje zonnen, een wandeling, een lekkere maaltijd of meer sensuele bezigheden. Je zintuigen staan op scherp, voed ze!</p><p>Op een ander niveau vertelt deze kaart je dat, als je bepaalde wensen of verlangens hebt waarvan je hoopt dat ze vervuld zullen worden, dit waarschijnlijk zal gaan gebeuren in de nabije toekomst. Als je vragen of twijfels hebt in verband met iets, dan vertelt deze kaart je dat je mag hopen op een positieve uitkomst. Daarom wordt de Negen van Bekers vaak ook de Wenskaart genoemd.</p><p>Let er wel voor op dat je niet te zelfzeker wordt of te zorgeloos want dat houdt risico’s in. Het blijft immers zo dat alles wat je doet gevolgen heeft, niet enkel voor jezelf maar ook voor de mensen om je heen. Het feit dat jij je heel goed voelt bij bepaalde zaken of situaties betekent niet automatisch dat dit ook voor anderen geldt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In je liefdesleven beleef je nu waarschijnlijk mooie tijden. Het zit er in dat jullie van plan zijn om jullie relatie naar het volgende niveau te tillen, en jullie beleven de liefde en betrokkenheid ook steeds intenser. Jullie gevoelens worden elke dag sterker en je vindt ook steeds weer nieuwe manieren om die emoties uit te drukken naar je partner toe. Het is best mogelijk dat je nu een intensiteit van gevoel ervaart die je nooit tevoren meegemaakt hebt. Geniet ervan!</p><p>Als je single bent dan is het nu een goede tijd om de wereld in te trekken en plezier te maken. Je straalt een sterke positiviteit uit die ervoor zorgt dat allerlei mensen zich tot je aangetrokken voelen en je willen leren kennen. Concentreer je niet teveel op het vinden van een partner maar eerder op het genieten van wat je doet. Je zal merken dat ergens in die groep nieuwe mensen zich de juiste persoon voor jou bevindt.</p>",
                        [GuidanceFields.WORK] : "<p>Deze kaart is soms symbolisch voor teamspirit, ze wijst erop dat je het gemakkelijk vindt om samen te werken, om naar een gemeenschappelijk doel toe te werken. Op dit moment ervaar je een intense harmonie in je contacten (privé en werk). Geniet hier ten volle van en zorg er ook voor dat het zo blijft: richt je op het behouden van die harmonie.</p><p>Op dit moment lijkt het alsof je geen fouten kan maken, alles wat je doet is succesvol. Misschien heb je een nieuwe techniek in je hoofd of een andere manier op bepaalde zaken te organiseren. Als dit het geval is dan is nu het juiste moment om die dingen uit te proberen. Je ideeën zullen zeker welkom zijn.</p><p>Als je op zoek bent naar een baan, plan dan nu zoveel mogelijk gesprekken. Je straalt op dit ogenblik een sterke positieve energie uit die vast indruk zal maken!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Negen van Bekers is niet direct een heel spirituele kaart omdat ze de nadruk legt op genieten en het bevredigen van je zintuigen. Toch is het zo dat als je grondig kan genieten van alle aspecten van het dagelijkse leven, je ook makkelijker spiritueel evenwicht en harmonie zal bereiken.</p><p>Ook al ben je op dit moment druk met het bevredigen van je zintuigen, neem toch even de tijd om bij jezelf naar binnen te kijken en te visualiseren welke doelen je nog wil bereiken op spiritueel vlak. Wees niet te bescheiden, the sky is the limit!</p>",
                    }
                },
                de : {
                    name: "Neun der Kelche",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Wissen, was man fühlt",    
                    description : "Die Neun der Kelche sprechen darüber, dass wir uns unserer eigenen Gefühle bewusst sein sollen und dass wir sie zeigen können.",
                    questions : [ "Sind Sie sich dessen bewusst, was Sie fühlen?", "Können Sie es zeigen, oder sagen Sie immer, dass alles in Ordnung ist?", "Inwieweit können Sie offen darüber sein, wie Sie sich wirklich fühlen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Neun der Kelche“ ist eine der positivsten Karten im Tarot-Karten-Deck. Die Karte spricht von häuslichem Glück, von Sorglosigkeit und von Spaß. Jetzt ist es Zeit, alle Arten von Freude zu genießen, ob es nun wirklich gutes Essen, ein Sonnenbad oder ein erotisches Abenteuer ist. Alle Wünsche können jetzt erfüllt werden.</p><p>Auf einer anderen Ebene erzählt diese Karte, dass Sie bestimmte Hoffnungen und Träume haben, von denen Sie wirklich wollen, dass sie in Erfüllung gehen. Das ist sehr wahrscheinlich in der nahen Zukunft der Fall. Wenn Sie zweifeln oder Fragen zu bestimmten Themen haben bedeutet diese Karte, dass ein positives Ergebnis bevorsteht. Deshalb wird die „Neun der Kelche“ auch als Wunsch-Karte zu bezeichnen.</p><p>Achten Sie aber darauf, nicht zu selbstgefällig, zu selbstsicher zu werden. Und vergessen Sie nicht, dass alle Ihre Handlungen Folgen haben, nicht nur für Sie selbst, sondern für alle Beteiligten. Die Tatsache, dass Sie sich bezüglich bestimmter Dinge ganz gut fühlen, bedeute nicht, dass auch Andere das ebenso erfahren.</p>",
                        [GuidanceFields.RELATIONSHIP] : "p>Dies ist eine gute Zeit, um in einer Beziehung zu sein. Sie und Ihr Partner sind sehr wahrscheinlich dabei auf die nächste Ebene zu gehen, um neue Höhen der Erfüllung zu erreichen. Ihre Liebe für einander wird jeden Tag stärker und Sie finden neue Wege das auszudrücken. Sie können neue Tiefe in Gefühle finden, die Ihnen noch nicht klar waren.</p><p>Wenn Sie alleine sind, ist jetzt die Zeit aus zu gehen und Spaß zu haben. Sie strahlen eine Positivität aus, die Ihnen helfen wird alle Arten von Menschen zu begegnen. Konzentrieren Sie Sich nicht zu viel auf der Suche nach einem Partner, aber genießen Sie den Ausflug. Wahrscheinlich ist Herr oder Fräulein “Richtig” wahrscheinlich unter den Menschen, die Sie treffen und alles wird in der Zeit klar werden.</p>",
                        [GuidanceFields.WORK] : "<p>Diese Karte wird manchmal mit Teamgeist verbunden. Es fällt Ihnen leicht mit anderen zusammen zu arbeiten und gerade jetzt gibt es eine Menge von Harmonie in Ihren Kontakten (privat und geschäftlich). Genießen Sie diese erhebende Erfahrung und stellen Sie sicher, dass die Dinge so bleiben: arbeiten Sie bewusst aber mit Aufrechterhaltung der Harmonie.</p><p>Im Moment scheint es, als ob Sie nichts falsch machen können. Sie haben Erfolg bei allem, was Sie tun. Wenn Sie nachdenken würden über eine neue Technik oder eine neue Art der Organisation von Dingen ist jetzt die Zeit, um die Räder in Bewegung zu setzen. Ihre Ideen werden sicherlich warm begrüßt werden.</p><p>Wenn Sie auf der Suche nach Arbeit sind, verabreden Sie Sich dann für viele Interviews. Sie werden den Interviewer mit Ihrer lebendigen Persönlichkeit und positiven Aussicht blenden!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Neun der Kelche ist nicht die meist spirituelle der Karten, hier geht es um Genuss und Erfüllung der Sinne. Dennoch das gründlich genießen aller Aspekte des täglichen Lebens kann Ihnen helfen, geistiges Gleichgewicht und Harmonie zu finden.</p><p>Egal, wie glücklich Sie sind mit den Dingen, wie sie jetzt sind, nehmen Sie sich Zeit, um in Sich Selbst zu suchen und machen Sie in Ihrem Geist ein Bild der Dinge, die Sie spirituell noch erreichen möchten. Scheuen Sie Sich nicht, gehen Sie für Gold!</p>",
                    }
                }
            },
            {
                filename: "45",
                numerology : 10,
                en : {
                    name: "Ten of Cups",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "feeling a lot",    
                    description : "Ten of Cups talks about feeling a lot and that we want to see everyone happy.",
                    questions : [ "Do you feel a lot?", "Do you want to pleasure every one?", "Are you aware that everyone includes yourself?", "What gives you the ultimate good vibe?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Ten of Cups is also a very positive and uplifting card. The difference with the Nine of Cups is that this card indicates a general sense of wellbeing, where the former primarily promised fulfilment of wishes and dreams. The Ten of Cups tells you that you can feel safe, confident, and that you are surrounded with people who love and cherish you. Now is the time to just enjoy life and be happy with your situation the way it is now. There is nothing to worry about, no need to make plans, just taste life to the fullest.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now, you can generally feel relaxed and content in your relationship. Whatever hurt, frustration and pain you have known in the past, you worked through them and let go, you have freed yourself from the burden of past experiences. There’s nothing to hold you back anymore, so now is the time to bask yourself in the love and intimacy you share with your partner. Maybe you feel ready to commit yourself even more. If this is the case, give your partner the time and space to get to this point in his/her own time. Do not worry about this, they will get there when they’re ready.</p><p>If you are single, now is the time to enjoy your social life to the fullest. Don’t go frantically looking for someone to love, just have fun! All will come to you in due time.</p>",
                        [GuidanceFields.WORK] : "<p>The Ten of Cups indicates that you are having a good time at work. Your colleagues and superiors notice your efforts and generally like working with you. You are a real team player and that is truly appreciated. Even though you are well liked, now is not the time to slow down and take it easy. To maintain this popularity you will have to keep investing your time and efforts. It’s an ongoing process of give and take.</p><p>Now is also a good time to make new contacts because your communication skills are at a high. Use those skills to get the interview that could land you your dream job!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Right now you are probably experiencing a sense of joy and inner peace. You are feeling harmony and oneness with everything and everyone. Use these emotions to create peace and harmony around you, in your relationship, your family, with friends. Share your spiritual wealth with everyone you meet. For the moment you have an abundance of positive energy and offering some of it to others will make you even happier. After all, happiness shared really is happiness doubled!</p>",
                    }
                },
                fr : {
                    name : "Le Dix de Coupe",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "ressentir beaucoup",    
                    description : " Le Dix de Coupe, nous parle de beaucoup de sentiments et que nous voulons que tout le monde soit heureux",
                    questions : ["Ressentez-vous beaucoup?", "Voulez-vous que tout le monde se sente heureux?", "Savez-vous que vous aussi faites partie de tout le monde?", "Que vous rend-il enfin heureux?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Dix de Coupe\" ainsi que le \"Neuf de Coupe\" sont également une carte très positive et édifiante. La différence est que cette carte indique un sentiment général de bien-être et la précédente promet surtout l'accomplissement des désirs et des rêves. Le \"Neuf de Coupe\" ont dit que les bénédictions viennent sur votre chemin. Quand vous recevez le \"Dix de Coupe\", vous comptez et appréciez vos bénédictions. Cette carte vous montre que vous pouvez vous sentir en sécurité, en confiance, que vous êtes entourés de gens qui vous aiment et vous apprécient. C'est le moment de simplement profiter de la vie et d'être satisfait de votre situation telle qu'elle est. Il n'y a rien à craindre ou à planifier. Profitez pleinement de votre vie.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now, you can generally feel relaxed and content in your relationship. Whatever hurt, frustration and pain you have known in the past, you worked through them and let go, you have freed yourself from the burden of past experiences. There’s nothing to hold you back anymore, so now is the time to bask yourself in the love and intimacy you share with your partner. Maybe you feel ready to commit yourself even more. If this is the case, give your partner the time and space to get to this point in his/her own time. Do not worry about this, they will get there when they’re ready.</p><p>If you are single, now is the time to enjoy your social life to the fullest. Don’t go frantically looking for someone to love, just have fun! All will come to you in due time.</p>",
                        [GuidanceFields.WORK] : "<p>The Ten of Cups indicates that you are having a good time at work. Your colleagues and superiors notice your efforts and generally like working with you. You are a real team player and that is truly appreciated. Even though you are well liked, now is not the time to slow down and take it easy. To maintain this popularity you will have to keep investing your time and efforts. It’s an ongoing process of give and take.</p><p>Now is also a good time to make new contacts because your communication skills are at a high. Use those skills to get the interview that could land you your dream job!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Right now you are probably experiencing a sense of joy and inner peace. You are feeling harmony and oneness with everything and everyone. Use these emotions to create peace and harmony around you, in your relationship, your family, with friends. Share your spiritual wealth with everyone you meet. For the moment you have an abundance of positive energy and offering some of it to others will make you even happier. After all, happiness shared really is happiness doubled!</p>",
                    }
                },
                sp:{
                    name:"El Diez de Copas",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "sentir mucho",    
                    description: "El Diez de Copas es sobre un montón de sentimientos y nos recuerda que queremos ver a todos felices",
                    questions: ["Sientes mucho?", "¿Quieres dar a 'todos' un buen sentimiento?", "¿Sabes que 'todos' están incluyendo ti mismo?", "¿Qué es lo que te hace sentir bien?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Diez de Copas es también una tarjeta muy positiva y edificante. La diferencia con el Nueve de Copas es que esta carta indica una sensación general de bienestar, donde el ex prometido principalmente cumplimiento de los deseos y sueños. El Diez de Copas le dice que usted puede sentirse seguro, confiado, y que está rodeado de personas que aman y te aprecian. Ahora es el momento de disfrutar de la vida y ser feliz con su situación como está ahora. No hay nada de qué preocuparse, no hay necesidad de hacer planes, simplemente saborear la vida al máximo.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Bekers Tien",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "heel veel voelen",    
                    description : "De Tien van Bekers gaat over heel veel gevoel en wijst ons er op dat we graag iedereen gelukkig willen zien.",
                    questions : [ "Voel jij veel?", "Wil jij 'iedereen' een goed gevoel geven?", "Weet jij dat 'iedereen' inclusief jezelf is?", "Waar voel jij je ultiem goed bij?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Tien van Bekers is, net zoals de Negen van Bekers, een positieve en opgewekte kaart. Het verschil met de vorige kaart is dat het hier gaat om een algemeen goed gevoel, terwijl de Negen van Bekers het vervullen van wensen en dromen belooft. De Negen vertelt je dat er zegeningen onderweg zijn naar jou, de Tien stelt vast dat je op dit moment je zegeningen aan het tellen bent en er volop van geniet. Je kan je nu veilig en vol zelfvertrouwen voelen in het bewustzijn dat je omgeven wordt door mensen die van je houden en je koesteren. Nu is het een moment om gewoon van het leven te genieten en blij te zijn met je situatie zoals die is. Ontspan je, neem de tijd om op te gaan in alle leuke dingen die je kan bedenken. Bekijk het alsof je nu even vakantie neemt van zorgen en piekeren. Je hoeft niets te plannen, geen problemen op te lossen, enkel te leven!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment kan je je gerust tevreden en ontspannen voelen in je relatie. Ook al heb je in het verleden pijn, frustratie en stress gekend, je hebt al die gevoelens doorleefd en verwerkt. Nu je al dat negatieve losgelaten hebt, ga je niet meer gebukt onder die oude bagage. Niets houdt je nu nog tegen, je kan ten volle genieten van de liefde en intimiteit die je deelt met je geliefde. Het is ook best mogelijk dat je nu een stap verder wil zetten, je nog meer wil engageren. Dat kan gaan van samenwonen tot trouwen of misschien samen een kind krijgen. Let er wel op dat je je partner de tijd en ruimte geeft om zelf ook tot op dit punt te geraken. Maak je niet ongerust, het juiste moment komt zeker. Het is gewoon een kwestie van mekaars tempo respecteren.</p><p>Als je op zoek bent naar een partner dan is het op dit moment beter om niet fanatiek op zoek te gaan maar integendeel de tijd te nemen om gewoon te genieten van je sociale leven. Ontspan je en maak plezier, de juiste persoon zal dan vanzelf in je leven komen.</p>",
                        [GuidanceFields.WORK] : "<p>De Tien van Bekers wijst erop dat alles op dit moment goed verloopt op het werk. Je collega’s en meerderen weten heel goed hoe hard je werkt en ze vinden het fijn om met jou samen te werken. Je bent een echte teamspeler en dat wordt zeker gewaardeerd. Maar ook al ben je populair, het is nu niet te goede tijd om je te ontspannen en het rustig aan te gaan doen. Om een positieve indruk te blijven maken is het belangrijk dat je tijd en moeite blijft investeren. Een goede sfeer op het werk is een kwestie van consequent geven en nemen.</p><p>Als je op zoek bent naar een (andere) baan dan is het nu het juiste moment om contacten te leggen. Je communiceert tegenwoordig namelijk heel positief en duidelijk, je straalt engagement uit. Deze kenmerken zorgen ervoor dat je je van je beste kant laat zien in interviews!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritueel gezien ervaar je nu waarschijnlijk een gevoel van intense vreugde en innerlijke vrede. Je voelt je in harmonie met alles en iedereen, je voelt je één met het universum. Gebruik deze gevoelens om ook in je omgeving, je relaties, familie en vrienden een gevoel van vrede en harmonie te creëren. Deel je spirituele rijkdom met iedereen die op je weg komt. Op dit moment geniet je van een overvloed aan positieve energie. Door dit te delen met anderen zal je je nog beter voelen. Tenslotte is het echt wel zo dat gedeelde vreugde dubbele vreugde betekent!</p>",
                    }
                },
                de : {
                    name: "Zehn der Kelche",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Viel fühlen",    
                    description : "Die Zehn der Kelche spricht von vielen Gefühlen und dass wir wollen, dass alle glücklich sind",
                    questions : [ "Empfinden Sie viel?", "Wollen Sie, dass sich alle glücklich fühlen?", "Wissen Sie, dass auch Sie Teil von allen sind?", "Was macht Sie letztendlich glücklich?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Zehn der Kelche“ ist - wie die „Neun der Kelche“ - ebenfalls eine sehr positive und erhebende Karte. Der Unterschied besteht darin, dass diese Karte ein allgemeines Gefühl des Wohlbefindens anzeigt, und die vorherige hauptsächlich die Erfüllung von Wünschen und Träumen verspricht. Die „Neun der Kelche“ sagte, dass Segnungen auf Ihren Weg kommen. Wenn Sie die „Zehn der Kelche“ bekommen, sind Sie dabei, Ihre Segnungen zu zählen und zu genießen. Diese Karte zeigt Ihnen, dass Sie sich sicher und selbstbewusst fühlen können, und dass Sie umgeben sind von Menschen, die Sie lieben und schätzen. Jetzt ist es Zeit, das Leben einfach nur zu genießen und mit Ihrer Situation, so wie sie jetzt ist, zufrieden zu sein. Es gibt nichts, worüber Sie sich Sorgen machen oder wofür sie Pläne machen müssen. Genießen Ihr Leben in vollen Zügen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Gerade jetzt können Sie das Gefühl haben entspannt und zufrieden in Ihrer Beziehung zu sein. Was auch immer in der Vergangenheit weh getan hat, welche Frustrationen und Schmerzen Sie auch gehabt haben, Sie haben sie durchgearbeitet und losgelassen. Sie haben Sich von der Last der Erfahrungen aus der Vergangenheit befreit. Es gibt jetzt nichts mehr, um Sie zurückzuhalten. Also ist jetzt die Zeit da, sich in der Liebe und Intimität die Sie mit Ihrem Partner teilen zu wärmen. Vielleicht fühlen Sie sich bereit, noch mehr zu investieren. Wenn dies der Fall ist, geben Sie Ihrem Partner die Zeit und Raum um zu diesem Punkt in seine / Ihre eigenen Moment zu kommen. Kümmern Sie Sich nicht, er / sie wird dorthin gelangen, wenn er / sie bereit sind. Wenn Sie alleine sind, ist jetzt die Zeit, um Ihr Sozialleben in vollen Zügen zu genießen. Gehen Sie nicht auf der Suche nach jemanden zum lieben, haben Sie nur Spaß! Das Andere kommt zu gegebener Zeit auf Ihnen zu.</p>",
                        [GuidanceFields.WORK] : "<p>Die Zehn der Kelche zeigt an, dass Sie eine gute Zeit in der Arbeit erleben. Ihre Kollegen und Vorgesetzten schätzen Ihre Bemühungen und arbeiten in der Regel gerne mit Ihnen zusammen. Sie sind ein echter Teamplayer und das wird wirklich geschätzt. Auch wenn Sie gut herankommen, ist jetzt nicht die Zeit zu verlangsamen aber nehmen Sie es einfach hin. Um diese Popularität zu behalten, müssen Sie Zeit und Mühe investieren. Es ist ein dauerndes Prozess von Geben und Nehmen. Jetzt ist auch eine gute Zeit, um neue Beziehungen einzugehen, weil Ihre Kommunikationsfähigkeiten sich jetzt auf einer hohen Ebene befinden. Verwenden Sie diese Fähigkeiten in dem Interview, das Sie vielleicht Ihren Traumjob bieten könnte!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Im Moment erleben Sie wahrscheinlich ein Gefühl der Freude und des inneren Friedens. Sie fühlen sich in Harmonie und Einheit mit allem und jedem. Benutzen Sie diese Gefühle von Frieden und Harmonie in Ihrer Umgebung, in Ihrer Beziehung, mit Ihrer Familie, mit Freunden. Teile Ihren geistigen Reichtum mit jedem, den Sie treffen. Im Moment haben Sie eine Fülle von positiver Energie und diese zu teilen mit anderen wird Sie noch glücklicher machen. Immerhin geteiltes Glück ist wirklich doppeltes Glück!</p>",
                    }
                }
            },
            {
                filename: "46",
                numerology : 11,
                en : {
                    name: "Page of Cups",
                    court : "Page",
                    suit : "Cups",
                    element : [ "🜄 - water", "🜃 - earth" ],
                    theme : "having an impartial look at your own feelings",    
                    description : "Page of Cups talks about being impartial about our own feelings and to look at them with a child-like light-heartedness.",
                    questions : [ "Do you allow yourself your own feelings?", "Dare you follow your gut only from that child-like light-heartedness?", "How does that feel?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about playfulness, spontaneity but also about reconciliation and giving people second chances.</p><p>The Page of Cups tells you that now is a good time to enjoy life on a simple level, to be spontaneous, not to think things through too much but just follow your instincts and simply enjoy what comes to mind. Give your inner child some room to do as it pleases, you will find that this will thoroughly relax you and give you positive energy.</p><p>Another aspect of this card is that you may be in a conflict with someone and now this person is offering to make up, to reconcile. Maybe your first reflex is to keep your distance, but if you allow yourself some time you may realize that you too want to make it up with this person. Accept this peace offer, it will do you a world of good to let go of some negativity.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Now is the time to listen to your heart and not your head. You may be overflown with love for your partner and feeling very romantic … do not hesitate, act upon it. Organize a weekend getaway to that special place, buy that present of which only you two really know what it means, surprise him/her, shower him/her with love. Whatever comes to mind, no matter how corny, act upon it, don’t let your brain hold you back, be truly romantic now!</p><p>If you are having a quarrel, don’t let your mind rule but follow your heart and make up. Life is too short to spend it fighting with your loved one.</p><p>If you are single, be prepared to fall victim to total infatuation and enjoy it while it lasts!</p>",
                        [GuidanceFields.WORK] : "<p>Getting the Page of Cups means there is hope, a ray of sunshine behind the clouds. Even if you realize now that you are truly unhappy at work, even if you really are in a rut, this card tells you that now the time has come to look at your dreams and find a way to make them come true. No matter how difficult your situation, there’s always choices and possibilities. Look at your inner child and listen carefully to what it has to tell you, it will probably point you in the right direction.</p><p>If you are looking for a job, now is the time to give your dreams another chance. Reach for the stars, there’s no limits to what you might achieve!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>It is quite possible that you have formed some spiritual habits throughout the years, certain ways of approaching things you have gotten used to. No matter how comfortable you feel with those habits, now is the time to throw them out the window and go look for new ones. Sit down and listen to your intuition, your gut feeling, allow yourself to be inspired by them and see where they lead you. There’s a whole spiritual universe out there for you to dive into, feel free to explore it!</p>",
                    }
                },
                fr : {
                    name : "Le Valet des Coupes",
                    court : "Valet",
                    suit : "Coupes",
                    element :  ["🜄 - eau", "🜃 - terre" ],
                    theme : "regarder ce que l'on ressent sans préjugés",    
                    description : "Le Valet des Coupes, nous parle de regarder ce que l’on ressent à  esprit ouvert et enfantin.", 
                    questions : ["Pouvez-vous vraiment sentir ce que vous ressentez?", "Osez-vous suivre vos sentiments, \"juste\" à cause de cet esprit ouvert enfantin?", "Qu'est-ce que cela vous fait?" ],


                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente la joie et la spontanéité, mais aussi la réconciliation, elle donne une seconde chance aux gens.</p><p>La carte \"Valet de Coupe\" vous dit que c'est le bon moment pour profiter de la vie à un niveau simple, pour être spontané, et peut-être même un peu enfantin. Ne ruminez pas les choses, suivez simplement votre instinct et appréciez ce qui vous vient à l'esprit. Donnez à votre enfant intérieur l'espace nécessaire pour faire ce qu'il ou elle veut. Vous trouverez que vous êtes complètement détendu et que vous pouvez puiser beaucoup d'énergie positive.</p><p>Un autre aspect de cette carte est, que vous avez peut-être été en conflit avec quelqu'un et que cette personne offre maintenant la réconciliation. Votre première réaction est peut-être de garder vos distances. Mais prenez votre temps et vous constaterez que vous voulez aussi vous réconcilier avec cette personne. Acceptez l'offre de paix. Cela vous fera du bien de vous débarrasser d'une partie de cette négativité.</p><p>Si vous voulez tomber enceinte, la carte \"Valet de Coupe\" est un signe très positif.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Now is the time to listen to your heart and not your head. You may be overflown with love for your partner and feeling very romantic … do not hesitate, act upon it. Organize a weekend getaway to that special place, buy that present of which only you two really know what it means, surprise him/her, shower him/her with love. Whatever comes to mind, no matter how corny, act upon it, don’t let your brain hold you back, be truly romantic now!</p><p>If you are having a quarrel, don’t let your mind rule but follow your heart and make up. Life is too short to spend it fighting with your loved one.</p><p>If you are single, be prepared to fall victim to total infatuation and enjoy it while it lasts!</p>",
                        [GuidanceFields.WORK] : "<p>Getting the Page of Cups means there is hope, a ray of sunshine behind the clouds. Even if you realize now that you are truly unhappy at work, even if you really are in a rut, this card tells you that now the time has come to look at your dreams and find a way to make them come true. No matter how difficult your situation, there’s always choices and possibilities. Look at your inner child and listen carefully to what it has to tell you, it will probably point you in the right direction.</p><p>If you are looking for a job, now is the time to give your dreams another chance. Reach for the stars, there’s no limits to what you might achieve!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>It is quite possible that you have formed some spiritual habits throughout the years, certain ways of approaching things you have gotten used to. No matter how comfortable you feel with those habits, now is the time to throw them out the window and go look for new ones. Sit down and listen to your intuition, your gut feeling, allow yourself to be inspired by them and see where they lead you. There’s a whole spiritual universe out there for you to dive into, feel free to explore it!</p>",
                    }
                },
                sp:{
                    name:"El Soto de Copas",
                    court: "soto",
                    suit: "Copas",
                    element: ["🜄 - agua", "🜃 - tierra" ],
                    theme: "mirando lo que sientes sin prejuicios",    
                    description: "El Soto de Copas se trata de mirar con la mente abierta a lo que sientes y nos recuerda que podemos mirarlo con la mente abierta de un niño",
                    questions: ["¿(Puedes) Te permites sentir lo que sientes?", "¿Te atreves a seguir tus sentimientos, ‘sólo' de esa apertura infantil?", "¿Cómo te sientes?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es la alegría, la espontaneidad, sino también acerca de la reconciliación y darle a la gente una segunda oportunidad. La paja de copas le dice que ahora es un buen momento para disfrutar de la vida en un nivel simple, ser espontáneo, no para pensar las cosas demasiado profundas, pero sólo tienes que seguir sus instintos y simplemente disfrutar de lo que viene a la mente. Da a su niño interior algo de espacio para hacer lo que quiera, usted encontrará que este fondo  se relajará y le dará energía positiva. Otro aspecto de esta tarjeta es que usted puede estar en un conflicto con alguien y ahora esta persona está ofreciendo de compensar, de conciliar. Tal vez su primer reflejo es mantener su distancia, pero si usted se permite algún tiempo usted puede darse cuenta de que usted también quiere hacer las paces con esta persona. Acepta esta oferta de paz, lo hará usted un mundo de bueno dejar de lado un poco de negatividad.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Page van Bekers",
                    court : "Page",
                    suit : "Bekers",
                    element : [ "🜄 - water", "🜃 - aarde" ],
                    theme : "onbevooroordeeld kijken naar wat je voelt",    
                    description : "De Page van Bekers gaat over onbevooroordeeld kijken naar wat je voelt en wijst ons er op dat we daar met kinderlijke onbevangenheid naar mogen kijken.",
                    questions : [ "Mag jij van jezelf voelen wat je voelt?", "Durf jij je gevoel te volgen, 'gewoon' vanuit die kinderlijke onbevangenheid?", "Hoe voelt dat?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart symboliseert speelsheid en spontaniteit maar ook verzoening en mensen nog een kans geven.</p><p>De Page van Bekers vertelt je dat het op dit moment goed is om op een eenvoudige manier van het leven te genieten. Je mag best spontaan zijn, mogelijk zelfs een beetje kinderlijk. Denk niet teveel na over de dingen maar volg je intuïtie en geniet gewoon van alles wat in je opkomt. Geef je innerlijke kind alle ruimte om te doen waar het zin in heeft. Je zal merken dat je op deze manier totaal  ontspannen en een heleboel positieve energie creëren en opnemen.</p><p>De tweede betekenis van deze kaart wijst je erop dat je mogelijk al enige tijd een conflict hebt met iemand. Die ander komt nu naar je toe om het goed te maken, hij/zij verlangt naar een verzoening. Misschien is je eerste reactie om afstand te houden en hier niet op in te gaan. Geef jezelf toch wat tijd om over de situatie na te denken. Best mogelijk dat je dan beseft dat je het toch goed wil maken, dat je die ander terug in je leven wil. Aanvaard dit vredesaanbod met beide handen, het zal je echt goed doen om alle negativiteit die om de situatie heen hing los te laten. De Page van Bekers trekken is ook een positief signaal als je graag zwanger wil worden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment is het belangrijk dat je naar je gevoel luistert en niet naar je verstand. Als je je hart voelt overlopen van liefde voor je partner, als je een romantische bui hebt ... aarzel dan niet, ga aan de slag. Organiseer een weekend om samen te ontspannen en te genieten van mekaar, koop een geschenk waarvan enkel je partner echt weet wat je ermee bedoelt. Gebruik je fantasie en je hart om je partner te verrassen en te verwennen. Maak je niet druk of je misschien sentimenteel overkomt, laat je verstand niet in de weg komen, volg je gevoel en wees echt romantisch voor een keer!</p><p>Als je op dit moment een conflict hebt met je partner probeer het dan zo snel mogelijk bij te leggen. Het leven is te kort om ook maar een minuut te besteden aan ruzie!</p><p>Als je op zoek bent naar een geliefde bereid je er dan op voor dat je binnenkort waarschijnlijk iemand zal ontmoeten waar je smoorverliefd op wordt. Ga helemaal op in dit gevoel en geniet ervan zolang als het duurt.</p>",
                        [GuidanceFields.WORK] : "<p>Als je de Page van Bekers krijgt in verband met je werksituatie dan betekent dit dat de zon binnenkort zal tevoorschijn komen van achter de wolken, er is hoop. Zelfs al ben je op dit moment ongelukkig in je baan, heb je het gevoel dat je in een geestdodende sleur zit, dan nog vertelt deze kaart je dat het nu het juiste moment is om je dromen en verlangens eens goed te bekijken en een manier te zoeken om ze waar te maken. Het maakt niet uit hoe moeilijk je situatie nu is, je hebt altijd keuzes en mogelijkheden. Ga eens te rade bij je innerlijke kind en luister zorgvuldig naar wat het te vertellen heeft, op die manier zal je ontdekken welke richting de juiste is voor jou.</p><p>Als je op zoek bent naar werk dan kan je nu best je dromen nog eens een kans geven, ook al heb je ze misschien al lang opgegeven. Herbekijk ze, reik naar de sterren, geloof in de mogelijkheid dat jij nu het onmogelijke kan waarmaken!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Waarschijnlijk heb je doorheen de jaren een aantal spirituele gewoontes gekregen, heb je ook een bepaalde manier om naar de dingen te kijken waar je helemaal aan gewend bent. Vergeet nu even hoe comfortabel je je voelt bij het oude vertrouwde. Zet ramen en deuren open en gooi alles buiten wat met je spirituele verleden te maken heeft. De tijd is gekomen om nieuwe dingen te ontdekken, nieuwe rituelen te vinden. Luister naar je intuïtie, je innerlijke kern, laat je inspireren door je ware zelf en kijk waar je uitkomt. Je hebt een oneindig grote spirituele vijver om in te vissen, voel je vrij en ga ervoor!</p>",
                    }
                },
                de : {
                    name: "Bube der Kelche",
                    court : "Bube",
                    suit : "Kelche",
                    element : [ "🜄 - Wasser", "🜃 - Erde" ],
                    theme : "Schauen, was man fühlt, ohne Vorurteile.",    
                    description : "Der Bube der Kelche spricht darüber, wie wir mit einem offenen und kindlichen Geist betrachten, was wir fühlen.",
                    questions : [ "Können Sie wirklich spüren, wie Sie sich fühlen?", "Trauen Sie sich, Ihren Gefühlen zu folgen? \"nur\" aufgrund dieser kindlichen Aufgeschlossenheit?", "Was bewirkt das in Ihnen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für Spielfreude und Spontaneität, aber auch für Versöhnung, sie gibt Menschen eine zweite Chance.</p><p>Die „Bube der Kelche“-Karte sagt Ihnen, dass jetzt der richtige Zeitpunkt gekommen ist, um das Leben auf einer einfacheren Ebene zu genießen, um spontan, und vielleicht sogar ein bisschen kindlich zu sein. Grübeln Sie nicht über die Dinge nach, sondern folgen Sie einfach Ihrem Instinkt und genießen Sie, was Ihnen in den Sinn kommt. Geben Sie Ihrem inneren Kind den Raum, das zu tun, was es will. Sie werden feststellen, dass Sie dies vollkommen entspannt und Sie viel positive Energie schöpfen können.</p><p>Ein weiterer Aspekt dieser Karte ist, dass Sie sich möglicherweise mit jemandem in einem Konflikt befunden haben, und dass diese Person jetzt eine Versöhnung anbietet. Vielleicht ist Ihre erste Reaktion, Distanz zu wahren. Doch lassen Sie sich etwas Zeit, dann werden Sie nämlich feststellen, dass auch Sie sich mit dieser Person versöhnen möchten. Nehmen Sie das Friedensangebot an. Es wird Ihnen sehr gut tun, etwas von dieser Negativität loszulassen.</p><p>Wenn Sie schwanger werden wollen ist die „Bube der Kelche“-Karte ein sehr positives Zeichen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Jetzt ist die Zeit um auf Ihrem Herz zu hören und nicht auf dem Vernunft. Sie können überflogen sein mit Liebe und sehr romantischen Gefühlen für Ihren Partner … Zögern Sie nicht, dementsprechend zu reagieren. Organisieren Sie ein Wochenende in diesem für Ihr beide besonderen Ort, kauf das Geschenk, von dem nur Ihr beide wirklich weißt was es bedeutet, überraschen Sie ihn / sie, überfließe ihn / sie mit Liebe. Was auch immer in den Sinn kommt, egal wie blöd, reagieren Sie darauf, lassen Sie Sich nicht von Ihrem Vernunft zurück halten, werden Sie jetzt wirklich romantisch!</p><p>Wenn Sie gerade in einem Streit sind, folgen Sie Ihrem Herzen. Das Leben ist zu kurz, um es mit kämpfen mit Ihrem Liebsten zu verbringen.</p><p>Wenn Sie alleine sind, dann sei bereit Sich zu übergeben an völlige Vernarrtheit. Genießen Sie es so lange es dauert!</p>",
                        [GuidanceFields.WORK] : "<p>Ziehen Sie der Bube der Kelche, dann bedeutet das, es gibt Hoffnung, einen Sonnenstrahl hinter den Wolken. Auch wenn Sie jetzt wissen, dass Sie bei der Arbeit wirklich unzufrieden sind, auch wenn Sie wirklich in einer Furche liegen sagt Ihnen diese Karte, dass jetzt die Zeit gekommen ist, um Ihre Träume zu folgen und einen Weg zu finden, um sie zu verwirklichen. Egal, wie schwierig Ihre Situation sein mag, es gibt immer Entscheidungen und Möglichkeiten. Schauen Sie auf Ihr inneres Kind und hören Sie genau zu, was es Ihnen zu sagen hat. Es wird Ihnen wahrscheinlich die richtige Richtung weisen.</p><p>Wenn Sie gerade einen Job suchen ist jetzt die Zeit, um Ihre Träume eine weitere Chance zu geben. Greife nach den Sternen, es gibt keine Grenzen für was Sie vielleicht zu erreichen vermögen!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Es ist durchaus möglich, dass Sie im Laufe der Jahre einige spirituelle Gewohnheiten gebildet haben, eine bestimmte Art und Weise Dinge zu betrachten, an denen Sie Sich vertraut haben. Egal, wie gut Sie sich mit diesen Gewohnheiten fühlen, jetzt ist die Zeit, um sie aus dem Fenster zu werfen und sich neue zu suchen. Setzen Sie sich hin und hören Sie auf Ihre Intuition, Ihr Bauchgefühl. Lassen Sie Sich davon inspirieren und sehen Sie mal wo sie Sie hinführen. Es gibt ein riesiges spirituelles Universum da draußen zum Untertauchen und um näher zu erforschen!</p>",
                    }
                }
            },
            {
                filename: "47",
                numerology : 12,
                en : {
                    name: "Knight of Cups",
                    suit : "Cups",
                    court : "Knight",
                    element : [ "🜄 - water", "🜁 - air" ],
                    theme : "showing your emotions to the world",    
                    description : "Knight of Cups talks about showing our feelings to the world around us.",
                    questions : [ "What do you feel?", "Are you showing what you feel?", "Can you go with the flow?", "Are you capable of showing your real emotions?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>When you draw this card it is very likely that you will be getting some very good news rather soon. The Knight of Cups also tells you that you are entering a period of simple happiness and enjoyment. Things are generally going well for you and you are feeling energetic and active. There is no stress or conflict, just peace and equilibrium. You are enjoying a loving atmosphere everywhere you go. Possibly a new love will come into your life or maybe you will meet someone who will become a good and reliable friend. These are verygoodtimes, enjoy!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you have questions about your love life, the Knight of Cups is a very positive card to get. It tells you that you and your partner are totally committed and that you may even be ready to take the relationship to the next level (anyone hear those wedding bells?). Even if you are not ready yet for this big a step, you will be enjoying a lot of loving feelings coming your way. Reciprocate them, be open about how you feel and you will make your partner very happy!</p><p>If you are single, now may be the time when you meet someone who really blows you away, who makes a deep and lasting impression on you. Do not hesitate, go for it, the timing is perfect!</p>",
                        [GuidanceFields.WORK] : "<p>At work everything is likely to go very well, partly because right now you feel relaxed and positive. You also feel very creative, your mind is full of ideas and possibilities. Creativity is a good thing, no doubt about that. But the Knight of Cups warns you that true creativity is based in reality. No matter how wonderful an idea may seem when you dream it up, it still has to be executed and proven successful. Make sure you devote a good part of your energy towards realizing what you have fantasized about.</p><p>Same goes for those on the lookout for a job: thinking about what would be the perfect job for you makes sense, but don’t forget you will also have to go out and look for it.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Knight of Cups can be seen as a spiritual messenger, bringing you an invitation. The card tells you to be open to messages of all kinds. You may meet someone who tells you about a certain spiritual concept, but the message may also be less obvious. Keep all your senses open, pay attention to the smallest of signs, the next step of your spiritual quest will be found among them. Once you received ‘your’ sign, don’t linger upon it for too long, take action. You spiritual growth needs to be firmly rooted in reality.</p>",
                    }
                },
                fr : {
                    name : "Le Cavalier des Coupes",
                    suit : "Coupes",
                    court : "Cavalier",
                    element :  ["🜄 - eau", "🜁 - air" ],
                    theme : "s’exprimer avec émotions",    
                    description : "Le Cavalier des Coupes, nous parle de montrer ce que l’on ressent et de montrer nos émotions au monde qui nous entoure.",
                    questions : ["Que ressentez-vous?", "Cela, le montrez- vous?", "Pouvez-vous suivre le courant?", "Êtes-vous capable de montrer vos émotions telles qu'elles sont?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Si vous tirez cette carte, il est très probable que vous recevrez bientôt de très bonnes nouvelles. Le \"Chevalier de Coupe\" vous dit aussi que vous entrez dans une période de bonheur et de joie simples. Les choses vont généralement bien pour vous et vous vous sentez énergique et actif. Il n'y a ni stress ni conflit, seulement calme et équilibre. Vous profitez d'une atmosphère d'amour partout où vous allez. C'est le moment idéal pour rencontrer des gens qui peuvent devenir de bons et fiables amis. Maintenant les temps sont très bons, amusez-vous bien !</p><p>Un avertissement : Même si vous êtes heureux, ne fermez pas les yeux sur les problèmes des autres. Ils ne disparaîtront pas juste parce que vous vous sentez mieux.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you have questions about your love life, the Knight of Cups is a very positive card to get. It tells you that you and your partner are totally committed and that you may even be ready to take the relationship to the next level (anyone hear those wedding bells?). Even if you are not ready yet for this big a step, you will be enjoying a lot of loving feelings coming your way. Reciprocate them, be open about how you feel and you will make your partner very happy!</p><p>If you are single, now may be the time when you meet someone who really blows you away, who makes a deep and lasting impression on you. Do not hesitate, go for it, the timing is perfect!</p>",
                        [GuidanceFields.WORK] : "<p>At work everything is likely to go very well, partly because right now you feel relaxed and positive. You also feel very creative, your mind is full of ideas and possibilities. Creativity is a good thing, no doubt about that. But the Knight of Cups warns you that true creativity is based in reality. No matter how wonderful an idea may seem when you dream it up, it still has to be executed and proven successful. Make sure you devote a good part of your energy towards realizing what you have fantasized about.</p><p>Same goes for those on the lookout for a job: thinking about what would be the perfect job for you makes sense, but don’t forget you will also have to go out and look for it.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Knight of Cups can be seen as a spiritual messenger, bringing you an invitation. The card tells you to be open to messages of all kinds. You may meet someone who tells you about a certain spiritual concept, but the message may also be less obvious. Keep all your senses open, pay attention to the smallest of signs, the next step of your spiritual quest will be found among them. Once you received ‘your’ sign, don’t linger upon it for too long, take action. You spiritual growth needs to be firmly rooted in reality.</p>",
                    }
                },
                sp:{
                    name:"Caballo de Copas",
                    suit: "Copas",
                    court: "Caballo",
                    element: ["🜄 - agua", "🜁 - aire" ],
                    theme: "Salir con sentimiento",    
                    description: "El Caballo de Copas trata de mostrar lo que sientes y nos recuerda que podemos mostrar nuestras emociones al mundo que nos rodea",
                    questions: ["¿Qué sientes?", "¿Muestras eso?", "¿Puedes seguir la corriente?", "¿Eres capaz de mostrar tus emociones como son?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Al sacando esta tarjeta es muy probable que usted va a obtener muy buenas noticias en lugar pronto. El Caballero de Copas también le indica que está entrando en un período de felicidad simple y de disfrutarle. Las cosas por lo general van bien para usted y usted se siente con energía y activa. No hay tensión o conflicto, sólo la paz y el equilibrio. Usted está disfrutando de un ambiente de amor donde quiera que vaya. Posiblemente un nuevo amor vendrá a tu vida o tal vez usted se encontrará a alguien que va a ser un amigo bueno y fiable. Estos son muy buenos tiempos, disfrutarlos!</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Ridder van Bekers",
                    suit : "Bekers",
                    court : "Ridder",
                    element : [ "🜄 - water", "🜁 - lucht" ],
                    theme : "met gevoel naar buiten treden",    
                    description : "De Ridder van Bekers gaat over laten zien wat je voelt en wijst ons er op dat we onze emoties aan de wereld om ons heen mogen tonen.",
                    questions : [ "Wat voel jij?", "Toon je dat?", "Kun jij 'meegaan op de stroom'?", "Ben jij in staat om je emoties te laten zien zoals ze zijn?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Als je deze kaart krijgt dan is het heel waarschijnlijk dat je binnenkort goed nieuws zal krijgen. De Ridder van Bekers vertelt je dat je binnenkort aan een periode in je leven begint waarbij je vooral simpele vreugde en genieten zal ervaren. Over het algemeen gaat alles goed voor jou en voel je je energiek en actief. Je hebt geen last van stress of conflicten, je voelt vooral vrede en evenwicht. Overal waar je komt is er een liefdevolle sfeer. Dit is een ideale tijd om nieuwe mensen in je leven uit te nodigen die mettertijd goede en betrouwbare vrienden kunnen worden. Dit zijn goede tijden, geniet ervan!</p><p>Een waarschuwing: ook al ben jij op dit moment heel gelukkig, houd er toch rekening mee dat anderen wel problemen kunnen hebben en besteed daar voldoende aandacht aan. Complicaties en conflicten in de levens van je vrienden en familie verdwijnen niet gewoon omdat jij je momenteel heel goed voelt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je met twijfels of vragen zit in verband met je relatie dan is de Ridder van Bekers een heel positieve kaart die je vertelt dat jij en je partner helemaal aan mekaar toegewijd zijn en dat jullie er mogelijk zelfs klaar voor zijn om een volgende stap te zetten die kan gaan van samenwonen over een verloving tot een huwelijk. En zelfs als je nog niet helemaal klaar bent om zo’n stap te zetten dan zal je toch merken dan dit een liefdevolle tijd is, je krijgt veel warmte en aandacht van je partner. Beantwoord dit met je eigen liefdevolle gebaren, je zal je geliefde heel gelukkig maken!</p><p>Als je alleen bent dan is het goed mogelijk dat je nu iemand zal ontmoeten die je echt van je sokken blaast en een diepe en blijvende indruk op je maakt. Aarzel niet, ga ervoor, de timing is perfect!</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk verloopt alles waarschijnlijk heel goed als je deze kaart krijgt. Dit is deels een gevolg van het feit dat jij er behoorlijk ontspannen en positief bij loopt. Je voelt je ook heel creatief, je hoofd zit vol ideeën en mogelijkheden. Ook al is creativiteit een positieve eigenschap, toch waarschuwt deze kaart je ervoor dat echt zinvolle creativiteit ook een basis in de realiteit heeft. Het maakt niet uit hoe fantastisch een idee kan lijken als je erover aan het mijmeren bent, je moet het nog altijd eerst uitproberen èn het moet ook nog lukken. Zorg ervoor dat je het grootste deel van je energie gebruikt om je dromen in realiteit om te zetten, niet enkel om ze te dromen. Hetzelfde geldt als je op zoek bent naar een baan: het is zeker zinvol om erover na te denken wat de ideale job voor jou zou zijn, maar je moet er natuurlijk ook op uittrekken om die baan te vinden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Ridder van Bekers kan gezien worden als een spirituele boodschapper die je een uitnodiging brengt. De kaart vraagt je om nu open te staan voor alle mogelijke signalen en berichten. Misschien ontmoet je iemand die je meer vertelt over een bepaald spiritueel concept, maar het bericht kan ook minder opvallend zijn. Houd je geest open, wees alert op alle mogelijke signalen want de boodschap die je krijgt, kondigt de volgende stap van je spirituele reis aan. Eens je het signaal ontvangen hebt, sta er dan niet te lang bij stil maar ga er actief mee aan de slag. Het is immers belangrijk dat je spirituele groei stevig gegrond is in de realiteit.</p>",
                    }
                },
                de : {
                    name: "Ritter der Kelche",
                    suit : "Kelche",
                    court : "Ritter",
                    element : [ "🜄 - Wasser", "🜁 - Luft" ],
                    theme : "Sich mit Emotionen ausdrücken",    
                    description : "Der Ritter der Kelche sagt uns zu zeigen, was wir fühlen und unsere Emotionen der Welt um uns herum zu zeigen.",
                    questions : [ "Was empfinden Sie?", "Das, zeigen Sie es?", "Können Sie mit dem Strom schwimmen?", "Können Sie Ihre Emotionen so zeigen, wie sie sind?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Wenn Sie diese Karte ziehen ist es sehr wahrscheinlich, dass Sie in Kürze einige sehr gute Nachrichten erhalten werden. Der „Ritter der Kelche“ erzählt Ihnen auch, dass Sie in eine Periode des einfachen Glücks und der Freude eintreten. Die Dinge laufen in der Regel gut für Sie, und Sie fühlen sich energiegeladen und aktiv. Es gibt keinen Stress oder Konflikte, nur Ruhe und Ausgeglichenheit. Sie genießen eine liebevolle Atmosphäre, wo immer Sie auch hinkommen. Jetzt ist eine ausgezeichnete Zeit, Menschen zu begegnen, die gute und zuverlässige Freunde werden können. Jetzt sind die Zeiten sehr gut, viel Spaß!</p><p>Eine Warnung: Egal, wie glücklich Sie auch sind, schließen Sie nicht die Augen vor den Problemen Anderer.  Sie werden nicht allein deshalb verschwinden, weil Sie sich jetzt ganz wohl fühlen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie Fragen zu Ihrem Liebesleben haben, dann ist der Ritter der Schale eine sehr positive Karte. Sie sagt Ihnen, dass Sie und Ihr Partner völlig verbunden sind und dass Sie vielleicht sogar bereit sind, die Beziehung auf die nächste Stufe zu bringen (hört man bereits die Hochzeitsglöckchen?). Sogar wenn Sie noch nicht so weit sind für diesen großen Schritt, werden eine Menge liebevolle Gefühle auf Sie zukommen und Sie werden sie genießen. Öffnen Sie Sich zu ihm beantworten Sie sie und Sie und Ihren Partner werden sehr glücklich!</p><p>Wenn Sie alleine sind, kann jetzt die Zeit sein jemanden zu treffen, der Sie wirklich umbläst und einen tiefen und bleibenden Eindruck auf Sie macht. Zögern Sie nicht aber gehen Sie dafür, die Zeitwahl ist perfekt!</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit läuft im Moment wahrscheinlich alles sehr gut, zum Teil, weil Sie Sich jetzt entspannt und positiv fühlen. Sie fühlen sich auch sehr kreativ, Ihr Geist ist voll von Ideen und Möglichkeiten. Kreativität ist eine gute Sache, daran besteht kein Zweifel. Aber der Ritter der Schale warnt Sie, dass wahre Kreativität basiert ist auf der Realität. Unabhängig davon, wie wunderbar eine Idee sein mag, wenn sie im Traum existiert, muss sie immer noch ausgeführt und bewährt werden. Stellen Sie sicher, dass das größte Teil Ihrer Energie gespendet wird an die Realisierung von dem was Sie geträumt haben.</p><p>Das Gleiche gilt auch für die, die auf der Suche nach einem Job sind: denken Sie darüber nach, was der perfekte Job für Sie wäre, aber vergessen Sie nicht auch raus zu gehen und ihn zu suchen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Der Ritter der Schäle kann als ein spiritueller Botschafter gesehen werden, der Sie eine Einladung bringt. Die Karte sagt, dass Sie offen für Nachrichten aller Art sein müssen. Sie können jemanden begegnen, der Sie über ein bestimmtes spirituelles Konzept erzählt, aber die Botschaft kann auch weniger offensichtlich sein. Behalten Sie alle Ihre Sinne offen, achten Sie auf das kleinste Zeichen, der nächste Schritt Ihrer spirituellen Suche wird sich darunter befinden. Sobald Sie \"Ihr Zeichen\" erhalten, sollten Sie nicht zu lange verweilen und Maßnahmen ergreifen. Ihr spiritueller Wachstum muss in der Realität verankert werden.</p>",
                    }
                }
            },
            {
                filename: "48",
                numerology : 13,
                en : {
                    name: "Queen of Cups",
                    court : "Queen",
                    suit : "Cups",                    
                    element : "🜄 water",
                    theme : "taking care of your own feelings",    
                    description : "Queen of Cups talks about paying attention to our feelings. Make way for your feelings.",
                    questions : [ "Are you feeling a lot?", "Can you pay attention to what is going on around you?", "Are you paying too much attention to your feelings?", "Are you sensible to your surroundings?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about love and compassion and taking care of yourself and the people around you. Right now you are empathic, sensitive and intuitive and you will feel these capacities getting even stronger and more powerful within you. Be sure to not only use these qualities to help others but also treat yourself with love and compassion, you deserve it.</p><p>The Queen of Cups sometimes indicates that there is a woman close to you who can really be supportive in a difficult situation. Don’t hesitate to accept this support, you will become a stronger and wiser person for it. </p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Queen of Cups tells you that right now you are very strong and supportive on an emotional level. You are open to other people’s feelings and you can truly sympathize. Your own emotions tend to be strong and deeply felt too. This state of mind can mean that you are feeling very close to your partner right now, you thoroughly enjoy the feelings you have for eachother.Just take care you don’t wallow in them, life is about more than just feeling.</p><p>If you are single now is a good time to go out and meet new people because you will probably encounter a prospective partner. Once you do, be careful not to get carried away, try to keep paying attention to other parts of your life too.</p>",
                        [GuidanceFields.WORK] : "<p>At work things may seem to be rather quiet, you are going with the flow. But maybe this doesn’t apply to some of your coworkers. The Queen of Cups invites you to be sensitive to the emotions of people around you. Take some time to listen to that quiet colleague’s story, to ask how things are when you are on a coffee break, to generally be open to whatever may burden the people around you. Try to create a safe haven for your colleagues by being open and receptive.</p><p>This card can also indicate that possibly you are in a bit of a rut. Keep your eyes open because your own “Queen of Cups” may be close by and wants to lend a helping hand.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Queen of Cups invites you to get in touch with your emotions, with your empathy. If you allow yourself to do so you will notice that it becomes fairly easy to get in touch with the universe, to feel oneness with all beings. Now is the perfect time to discover and develop every spiritual ability you already have or aspire. Be open to life’s energy, let it freely flow through you and awaken your senses. Share your experiences with others, offer them the benefit of your abundance. Now would be a good time to book a Reiki session, you may even feel inspired to further pursue it.</p>",
                    }
                },
                fr : {
                    name : "La Reine des Coupes",
                    court : "Reine",
                    suit : "Coupes",
                    element :  "🜄 eau",
                    theme : "Prendre soin de ce que l’on ressent",    
                    description : "La Reine des Coupes, nous parle de faire attention à ce que l'on ressent. Donnez de l'espace à vos sentiments.",
                    questions : ["Ressentez-vous beaucoup?", "Voyez-vous ce qui se passe autour de vous?", "Faites-vous (trop) attention à ce que vous ressentez?", "Êtes-vous sensible à votre environnement?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente l'amour et la compassion. C'est aussi prendre soin de soi et des gens autour de soi. Vous vous sentez maintenant empathique, sensible et intuitif. Vous sentez que ces capacités en vous continuent de grandir et de se renforcer. En ce moment, vous vous entendez très bien avec les autres sur le plan émotionnel. C'est facile pour vous d'aider. Néanmoins, il est tout aussi important de vous traiter avec amour et compassion. Vous le méritiez autant que n'importe qui d'autre.</p><p>Cette carte vous dit aussi de faire confiance à vos tripes, à votre intuition. Si vous n'aimez pas quelqu'un ou quelque chose, c'est probablement vrai.</p><p>La \"Reine de Coupe\" dit parfois qu'il y a une femme près de chez vous qui pourrait être très utile dans les situations difficiles. N'hésitez pas à accepter ce soutien, vous deviendrez une personne plus forte et plus intelligente.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Queen of Cups tells you that right now you are very strong and supportive on an emotional level. You are open to other people’s feelings and you can truly sympathize. Your own emotions tend to be strong and deeply felt too. This state of mind can mean that you are feeling very close to your partner right now, you thoroughly enjoy the feelings you have for eachother.Just take care you don’t wallow in them, life is about more than just feeling.</p><p>If you are single now is a good time to go out and meet new people because you will probably encounter a prospective partner. Once you do, be careful not to get carried away, try to keep paying attention to other parts of your life too.</p>",
                        [GuidanceFields.WORK] : "<p>At work things may seem to be rather quiet, you are going with the flow. But maybe this doesn’t apply to some of your coworkers. The Queen of Cups invites you to be sensitive to the emotions of people around you. Take some time to listen to that quiet colleague’s story, to ask how things are when you are on a coffee break, to generally be open to whatever may burden the people around you. Try to create a safe haven for your colleagues by being open and receptive.</p><p>This card can also indicate that possibly you are in a bit of a rut. Keep your eyes open because your own “Queen of Cups” may be close by and wants to lend a helping hand.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Queen of Cups invites you to get in touch with your emotions, with your empathy. If you allow yourself to do so you will notice that it becomes fairly easy to get in touch with the universe, to feel oneness with all beings. Now is the perfect time to discover and develop every spiritual ability you already have or aspire. Be open to life’s energy, let it freely flow through you and awaken your senses. Share your experiences with others, offer them the benefit of your abundance. Now would be a good time to book a Reiki session, you may even feel inspired to further pursue it.</p>",
                    }
                },
                sp:{
                    name:"La Reina de Copas",
                    court: "Reina",
                    suit: "Copas",
                    element: "🜄 agua",
                    theme: "Cuida lo que sientes",    
                    description: "La Reina de Copas se trata de prestar atención a lo que sientes. Dale espacio a tus sentimientos",
                    questions: ["¿Sientes mucho?", "¿Ves lo que pasa a tu alrededor?", "¿Pones (demasiada) atención a lo que sientes?", "¿Eres sensible a las atmósferas?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es sobre el amor y la compasión y el cuidado de uno mismo y las personas que le rodean. En este momento usted es empático, sensible e intuitiva y se sentirá estas capacidades para conseguir aún más fuerte y más poderoso dentro de usted. Asegúrese de no sólo utilizar estas cualidades para ayudar a los demás, sino también disfrutar de amor y compasión,  usted lo merece. La Reina de Copas a veces indica que hay una mujer cerca de usted que realmente puede ser de apoyo en una situación difícil. No dude en aceptar este apoyo, que se convertirá en una persona más fuerte y más sabio por ello.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Koningin van Bekers",
                    court : "Koningin",
                    suit : "Bekers",                    
                    element : "🜄 water",
                    theme : "zorg dragen voor wat je voelt",    
                    description : "De Koningin van Bekers gaat over aandacht schenken aan wat je voelt. Geef je gevoel de ruimte.",
                    questions : [ "Voel jij veel?", "Kun je oog hebben voor wat er om je heen gaande is?", "Schenk je (te) veel aandacht aan wat je voelt?", "Ben je gevoelig voor sferen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart heeft het over liefde en mededogen. De boodschap is ook om goed voor jezelf en de mensen om je heen te zorgen. Op dit moment bent je sterk empathisch, intuïtief en gevoelig. Deze eigenschappen zullen steeds sterker en intenser worden. Je zal merken dat je heel goed contact kan maken met anderen op het emotionele vlak. Daardoor wordt het ook gemakkelijk voor je om hen te begrijpen en te helpen. Toch blijft het even belangrijk om ook jezelf te benaderen met liefde en mededogen, je verdient dit net zoveel als anderen.</p><p>Deze kaart wijst er ook op dat het belangrijk is om op je gevoel te vertrouwen, je intuïtie. Als iets of iemand niet goed voelt, dan klopt je gevoel waarschijnlijk. De Koningin van Bekers vertelt je soms ook dat er een wijze vrouw in je omgeving is waarop je kan vertrouwen, die je door een moeilijke situatie heen kan helpen. Aarzel niet om haar steun te aanvaarden, je zal er sterker en geaarder door worden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Koningin van Bekers vertelt je dat je op dit moment heel sterk en ondersteunend bent op emotioneel vlak. Je staat open voor de gevoelens van anderen en je kan ook echt intens meeleven. Je eigen emoties zijn op dit moment ook diep en hevig. Dit kan betekenen dat je je heel dicht bij je partner voelt, dat je echt kan genieten van de gevoelens die je hebt voor die ander. Genieten is natuurlijk positief maar let ervoor op dat je je niet laat overspoelen door je emoties, het leven is meer dan enkel voelen.</p><p>Als je alleen bent is het een goed idee om erop uit te trekken en nieuwe mensen te ontmoeten want je zal waarschijnlijk een potentiële partner vinden. Als dat gebeurt, let er dan wel voor op dat je je niet teveel laat meeslepen door je nieuwe geluk. Besteed zeker voldoende aandacht aan alle andere aspecten van je leven.</p>",
                        [GuidanceFields.WORK] : "<p>Op het gebied van werk is het nogal rustig op dit moment, je laat je meevoeren door de stroom. Maar misschien geldt dat niet voor sommige van je collega’s. De Koningin van Bekers nodigt je uit om je gevoelig op te stellen voor de emoties van de mensen om je heen. Neem eens de tijd om te luisteren naar wat die stille collega te vertellen heeft, vraag tijdens je koffiepauze eens aan iemand hoe het ermee gaat. Stel je algemeen open voor de zorgen van anderen, luister en leef mee. Probeer een veilige haven te creëren voor je medemensen door open en ontvankelijk te zijn. Deze kaart kan er ook op wijzen dat je misschien wat in een sleur zit. Als dat het geval is, stel je dan alert op want er kan zomaar een “Koningin” opduiken die je de helpende hand reikt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Koningin van Bekers nodigt je uit om contact te zoeken met je emoties en je empathie. Als je jezelf dit toestaat dan zal je merken dat het ook gemakkelijker wordt om in verbinding te treden met het universum, om je één te voelen met alles wat leeft. Maak gebruik van dit gevoel van eenheid om allerlei spirituele vaardigheden bij jezelf te ontdekken en te ontwikkelen. Stel je open voor de universele levensenergie, laat die door je heen stromen en je zintuigen wakker schudden. Deel je ervaringen met anderen, laat hen meegenieten van je overvloed. Nu is het een goed moment om een Reikibehandeling te boeken. Mogelijk word je er zelfs door geïnspireerd om zelf met energieën aan de slag te gaan.</p>",
                    }
                },
                de : {
                    name: "Königin der Kelche",
                    court : "Königin",
                    suit : "Kelche",                    
                    element : "🜄 Wasser",
                    theme : "Sich darum kümmern, was man fühlt.",    
                    description : "Die Königin der Kelche spricht darüber, darauf zu achten, was man fühlt. Geben Sie Ihren Gefühlen Raum.",
                    questions : [ "Empfinden Sie viel?", "Sehen Sie, was um Sie herum passiert?", "Achten Sie (zu) viel darauf, was Sie empfinden?", "Sind Sie empfindlich für Ihre Umgebung?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für Liebe und Mitgefühl. Es geht auch um die Fürsorge für sich selbst und für die Menschen in Ihrer Umgebung. Jetzt fühlen Sie sich empathisch, einfühlsam und intuitiv. Sie fühlen, dass diese Fähigkeiten in Ihrem Inneren immer noch mehr wachsen und stärker werden. In diesem Augenblick verstehen Sie sich auf  emotionaler Ebene sehr gut mit Anderen. Es fällt Ihnen leicht, Ihnen zu helfen. Dennoch ist es genauso wichtig, sich selbst mit Liebe und Mitgefühl zu behandeln. Sie haben es genauso verdient wie jeder andere auch.</p><p>Diese Karte sagt Ihnen auch, auf Ihr Bauchgefühl, auf Ihre Intuition zu vertrauen. Wenn jemand oder etwas Ihnen nicht gefällt, dann stimmt das wahrscheinlich auch.</p><p>Die „Königin der Kelche“ sagt manchmal, dass es eine Frau in Ihrer Nähe gibt, die Ihnen in schwierigen Situationen sehr behilflich sein kann. Zögern Sie nicht, diese Unterstützung anzunehmen. Sie werden ein stärkerer und klügerer Mensch werden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Die Königin der Kelche sagt Ihnen, dass Sie gerade jetzt sehr stark und unterstützend auf einer emotionalen Ebene sind. Sie sind offen für die Gefühle anderer Menschen und Sie können wirklich mitfühlend sein. Ihre eigenen Emotionen empfinden Sie jetzt auch stark und tief. Dieser Zustand des Geistes kann bedeuten, dass Sie Sich gerade jetzt ganz nahe fühlen zu Ihrem Partner. Sie genießen die tiefen Gefühle, die Sie für einander haben. Schwelgen Sie aber nicht darin, das Leben ist mehr als nur ein Gefühl.</p><p>Wenn Sie alleine sind ist es jetzt ist es eine gute Zeit aus zu gehen und neue Leute kennen zu lernen, weil Sie wahrscheinlich Ihren zukünftigen Partner begegnen werden. Sobald dies geschieht, dann achten Sie darauf nicht davon weggetragen zu werden aber versuchen Sie Ihre Aufmerksamkeit auch auf andere Teile Ihres Lebens zu richten.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit scheint es eben mal ruhig zu sein und Sie gehen mit dem Fluss. Aber vielleicht trifft das nicht zu auf Ihren Mitarbeitern. Die Königin der Kelche lädt Sie ein empfänglich für die Gefühle der Menschen, um Sie herum zu sein. Nehmen Sie Sich die Zeit, um die Geschichte dieses ruhigen Kollegen an zu hören, fragen Sie, wie die Dinge laufen, wenn Sie eine Kaffeepause haben und stellen Sie Sich offen für das was die Menschen um sie herum belastet. Versuchen Sie, einen sicheren Hafen für Ihre Kollegen zu sein, indem Sie Sich offen und empfänglich aufstellen.</p><p>Diese Karte kann auch bedeuten, dass Sie möglicherweise ein wenig in einer Furche sind. Haltet die Augen offen, weil Ihre eigene \"Königin der Schale\" in der Nähe sein kann und Ihnen eine helfende Hand reichen möchte.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Königin der Kelche lädt Sie ein mit Ihren Gefühlen in Kontakt zu kommen, mit Ihrer Empathie. Wenn Sie Sich erlauben, dies zu tun, werden Sie feststellen, dass es ziemlich einfach ist in Kontakt mit dem Universum zu geraten, um sich Eins zu fühlen mit allen Wesen. Jetzt ist die perfekte Zeit um jede geistige Fähigkeit die Sie bereits haben oder anstreben, zu entdecken und zu entwickeln. Seien Sie offen für Lebensenergie, lassen Sie es frei durch Sie fließen und Ihre Sinne wecken. Teilen Sie Ihre Erfahrungen mit anderen, bieten Sie sie den Nutzen Ihrer Hülle und Fülle. Jetzt wäre ein guter Zeitpunkt, um eine Reiki-Sitzung zu buchen. Sie können Sich sogar inspiriert fühlen diese weiter zu verfolgen.</p>",
                    }
                }
            },
            {
                filename: "49",
                numerology : 14,
                en : {
                    name: "King of Cups",
                    court : "King",
                    suit : "Cups",
                    element : [ "🜄 - water", "🜂 - fire" ],
                    theme : "acting from your own feelings",    
                    description : "King of Cups talks about actually handling from your own feelings. Go create.",
                    questions : [ "Are you acting from your own feelings?", "Are you manipulated by others?", "Can you be flexible without diverting from your own course?", "Can you trust your own feelings?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The King of Cups represents a balanced person, someone who is feeling whole, partof the universal energy, main qualities being compassion and kindness.</p><p>This card tells you that now would be a good time to turn to your core, to open yourself up for your own essence, to develop your inner strength and then letting this strength influence your life and that of others by acting upon it. Let people know who you are by showing them with your actions, not by telling them. Only if you can accept yourself for who and what you are, can you accept others.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now empathy comes easy, you sense and understand how other people feel and you can sympathize with them. Because of this attitude you feel very close to everyone around you. Helping others by exchanging feelings and experiences comes easy to you right now.</p><p>If you are having a loving relationship you will feel one with your partner, sharing a level of intimacy you didn’t know existed.</p><p>If you are single and looking for love you are likely to meet someone who really suits your emotional needs. You may feel very close to this person rather quickly, do not let that worry or unsettle you.</p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned you don’t use the intellectual approach right now. You are more open to atmospheres and sensitivities, to the needs and frustrations of others.</p><p>When there are problems or conflicts, you use your intuition to shed light on the situation and help solve it.</p><p>Even though you may not be aware of it, your colleagues really appreciate your efforts and personality. Right now it is you they come to when there’s an issue.</p><p>The King of Cups may also indicate that there’s a male figure at work who is really on your side right now. If you need help or support he is the right person to approach, he will not hesitate to lend a helping hand.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The King of Cups is about being balanced and in order tobe spiritually balanced you need a sense of boundaries. How far can you go in a certain direction and, more importantly, how far do you want to go? This card shows an actively compassionate person, someone who listens to and cares about the feelings of others and wants to help them grow. Still, it is important to set yourself and others some limits in order not to go overboard in your willingness to help. Now is a good time to meditate on these subjects in order to find spiritual serenity.</p>",
                    }
                },
                fr : {
                    name : "Le Roi des Coupes",
                    court : "Roi",
                    suit : "Coupes",
                    element :  ["🜄 - eau", "🜂 - feu" ],
                    theme : "Agir activement à partir de son intuition",    
                    description : "Le Roi des Coupes, nous parle d’agir à partir de ses propres sentiments. Les donner forme",
                    questions : ["Agissez-vous en fonction de vos propres sentiments?", "Laissez-vous vous influencer par les autres?", "Pouvez-vous être flexible, sans dévier de votre propre voie?", "Pouvez-vous compter sur vos propres intuitions?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Roi de Coupe\" n'est pas une carte typiquement masculine ou féminine, elle représente simplement une personne équilibrée. Cette carte représente quelqu'un qui se sent complètement entier et fait partie de l'énergie universelle. Ses qualités sont la compassion et la gentillesse.</p><p>La carte vous montre que le moment est venu de retourner à votre cœur pour vous ouvrir à votre véritable être. Essayez de développer vos vérités intérieures et laissez-les influencer votre vie et celle des autres en agissant en conséquence. Faites savoir qui vous est par vos actes, mais pas par vos paroles. Les actes sont plus parlants que les mots. Ce n'est que lorsque vous vous acceptez tel que vous êtes que vous pouvez accepter les autres et interagir honnêtement avec eux.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now empathy comes easy, you sense and understand how other people feel and you can sympathize with them. Because of this attitude you feel very close to everyone around you. Helping others by exchanging feelings and experiences comes easy to you right now.</p><p>If you are having a loving relationship you will feel one with your partner, sharing a level of intimacy you didn’t know existed.</p><p>If you are single and looking for love you are likely to meet someone who really suits your emotional needs. You may feel very close to this person rather quickly, do not let that worry or unsettle you.</p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned you don’t use the intellectual approach right now. You are more open to atmospheres and sensitivities, to the needs and frustrations of others.</p><p>When there are problems or conflicts, you use your intuition to shed light on the situation and help solve it.</p><p>Even though you may not be aware of it, your colleagues really appreciate your efforts and personality. Right now it is you they come to when there’s an issue.</p><p>The King of Cups may also indicate that there’s a male figure at work who is really on your side right now. If you need help or support he is the right person to approach, he will not hesitate to lend a helping hand.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The King of Cups is about being balanced and in order tobe spiritually balanced you need a sense of boundaries. How far can you go in a certain direction and, more importantly, how far do you want to go? This card shows an actively compassionate person, someone who listens to and cares about the feelings of others and wants to help them grow. Still, it is important to set yourself and others some limits in order not to go overboard in your willingness to help. Now is a good time to meditate on these subjects in order to find spiritual serenity.</p>",
                    }
                },
                sp:{
                    name:"El Rey de Copas",
                    court: "Rey",
                    suit: "Copas",
                    element: ["🜄 - agua", "🜂 - fuego" ],
                    theme: "Actuar activamente desde tus propios sentimientos",    
                    description: "El Rey de Copas se trata de actuar desde tus propios sentimientos. Dale forma",
                    questions: ["¿Actúas desde tus propios sentimientos?", "¿Dejas que otros te influyan?", "¿Puedes ser flexible, sin salirte de tu propio curso?", "¿Puedes confiar en tus propios sentimientos?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Rey de Copas representa una persona equilibrada, alguien que se siente todo, parte de la energía universal, las principales cualidades son la compasión y la bondad. Esta tarjeta le dice que ahora sería un buen momento para acudir a su núcleo, de abrirse para su propia esencia, para desarrollar su fuerza interior y luego dejar que esta fuerza influir en su vida y la de los demás, actuando sobre ella. Que la gente sepa quién es, mostrándole con sus acciones, no diciéndole. Sólo si  puede aceptarse a su mismo  quién y qué eres, puede aceptar a los demás.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Koning van Bekers",
                    court : "Koning",
                    suit : "Bekers",
                    element : [ "🜄 - water", "🜂 - vuur" ],
                    theme : "actief handelen vanuit je eigen gevoel",    
                    description : "De Koning van Bekers gaat over daadwerkelijk handelen vanuit je eigen gevoel. Geef het vorm.",
                    questions : [ "Handel jij vanuit je eigen gevoel?", "Laat jij je beïnvloeden door anderen?", "Kun jij flexibel zijn, zonder van je eigen koers te geraken?", "Kun jij op je eigen gevoel varen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Koning van Bekers is geen typisch mannelijke of vrouwelijke kaart, ze vertegenwoordigt vooral een persoon in evenwicht. Deze kaart symboliseert iemand die zich (ge)heel voelt, onderdeel van de universele energie. De belangrijkste kwaliteiten hierbij zijn medeleven en vriendelijkheid.</p><p>Er wordt je gevraagd om je te concentreren op je innerlijke kern, om jezelf open te stellen voor je ware essentie, je ware Zijn. Streef ernaar om je innerlijke waarheden en inzichten te ontwikkelen en er ook naar te handelen. Door dit te doen heb je een grote veranderende invloed op je eigen leven en ook op dat van de mensen om je heen. Toon aan anderen wie je bent door middel van je gedrag, niet enkel door wat je zegt. Daden spreken op dit moment veel luider dan woorden dat kunnen.</p><p>Aanvaard in de eerste plaats jezelf zoals je bent. Pas als je jezelf helemaal accepteert kan je anderen ook aanvaarden zoals ze zijn en kan je echt met hen communiceren.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment vind je het gemakkelijk om empathisch te zijn, je voelt en begrijpt wat in anderen omgaat en je hebt echt sympathie voor hen. Door deze houding voel je je eigenlijk dicht bij iedereen om je heen. Gevoelens en ervaringen uitwisselen en mensen op die manier verder helpen voelt heel natuurlijk aan. In je relatie voel je je nu één met je partner, jullie ervaren een niveau van intimiteit dat je nooit eerder gekend hebt.</p><p>Als je op dit moment op zoek bent naar een geliefde dan zal je waarschijnlijk iemand ontmoeten die op emotioneel vlak perfect bij je aansluit. Je zal je heel snel eigen voelen bij deze persoon, het zal mogelijk zelfs aanvoelen alsof jullie mekaar al veel langer kennen. Maak je niet ongerust, het zit goed!</p>",
                        [GuidanceFields.WORK] : "<p>Wat werk betreft voel je op dit moment niets voor de intellectuele aanpak. Je bent meer open voor sferen en gevoelens, je voelt de noden en frustraties van anderen. Als er problemen of conflicten zijn dan gebruik je je intuïtie om de dingen duidelijker te maken en mensen te helpen om het probleem op te lossen.</p><p>Je bent je er misschien niet van bewust maar je collega’s waarderen je inspanningen en je manier van zijn echt wel. Op dit moment komen ze naar jou toe als er iets op te lossen valt.</p><p>De Koning van Bekers kan erop wijzen dat je op dit moment veel steun mag verwachten van een mannelijke figuur. Hij is degene die je moet benaderen als je een probleem of een vraag hebt, als je steun nodig hebt. Hij zal je zonder aarzelen te hulp komen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Koning van Bekers gaat over evenwicht. Om spiritueel in evenwicht te komen en te blijven is het nodig dat je je bewust bent van grenzen. Hoe ver kan je gaan in een bepaalde richting? En nog belangrijker: hoe ver wil je gaan?</p><p>Deze kaart symboliseert een actief meelevende persoon die luistert naar en geeft om de gevoelens van anderen, die hen wil helpen om te groeien. En net als je veel positieve energie in anderen investeert, komt er ook een boel positieve energie terug naar jou toe. Helpen is eigenlijk een wisselwerking, al merk je dat soms niet meteen.</p><p>Toch is het belangrijk om jezelf en anderen grenzen te stellen zodat je niet gaat overdrijven in je helpersrol. Mediteer geregeld over deze onderwerpen en je zal merken dat je een diep spiritueel evenwicht gaat ervaren.</p>",
                    }
                },
                de : {
                    name: "König der Kelche",
                    court : "König",
                    suit : "Kelche",
                    element : [ "🜄 - Wasser", "🜂 - Feuer" ],
                    theme : "Aktives Handeln auf Basis seiner Intuition", 
                    description : "Der König der Kelche sagt uns, dass wir nach unseren eigenen Gefühlen handeln sollen. Geben Sie ihnen Form.",
                    questions : [ "Handeln Sie nach Ihren eigenen Gefühlen?", "Lassen Sie sich von anderen beeinflussen?", "Können Sie flexibel sein, ohne vom eigenen Weg abzuweichen?", "Können Sie sich auf Ihre eigenen Erkenntnisse verlassen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der „König der Kelche“ ist keine typisch männliche oder weibliche Karte, sie stellt einfach eine ausgewogene Person dar. Diese Karte steht für jemanden, der sich vollkommen ganz und Teil der universellen Energie fühlt. Seine Qualitäten sind Mitgefühl und Freundlichkeit.</p><p>Die Karte zeigt Ihnen, dass jetzt ein guter Zeitpunkt wäre, um zu Ihrem Kern zurückzukehren, um sich für Ihr wahres Wesen zu öffnen. Bemühen Sie sich, Ihre inneren Wahrheiten zu entwickeln und lassen Sie dann diese Wahrheiten, diese Erkenntnisse Ihr Leben und das von anderen beeinflussen, indem Sie danach handeln. Lassen Sie die Menschen durch Ihr Verhalten aber nicht durch Ihre Worte wissen, wer Sie sind. Taten sagen mehr als  Worte. Nur wenn Sie sich selbst akzeptieren so wie Sie sind können Sie Andere akzeptieren und ehrlich mit ihnen umgehen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Gerade jetzt kommt die Empathie einfach. Sie spüren und verstehen, wie andere Menschen fühlen und Sie können mit ihnen sympathisieren. Wegen dieser Haltung fühlen Sie Sich sehr nah an alle um Sie herum. Es kommt Ihnen jetzt ganz einfach vor andere zu helfen durch den Austausch von Gefühlen und Erfahrungen.</p><p>Wenn Sie in einer liebevollen Beziehung sind, werden Sie eins mit Ihrem Partner sein und ein Gefühl der Intimität haben, das Sie bisher nicht einmal kannten.</p><p>Wenn Sie alleine sind und auf der Suche nach Liebe finden Sie wahrscheinlich jemanden, der wirklich passt zu Ihren emotionalen Bedürfnissen. Sie könnten Sich sehr schnell ganz nahe an diese Person angezogen fühlen. Machen Sie Sich deswegen keine Sorgen und lassen Sie Sich nicht beunruhigen.</p>",
                        [GuidanceFields.WORK] : "<p>Wo es die Arbeit anbelangt, haben Sie jetzt nicht den intellektuellen Ansatz. Sie sind offen für Atmosphären und Empfindlichkeiten, die Bedürfnisse und die Frustrationen von anderen.</p><p>Wenn es Probleme oder Konflikte gibt, verwende dann Ihre Intuition um Licht auf die Situation zu werfen und zu helfen sie zu lösen.</p><p>Auch wenn Sie es Sich vielleicht nicht bewusst sind, schätzen Ihre Kollegen Ihre Bemühungen und Persönlichkeit. Gerade jetzt sind Sie es zu dem sie kommen, wenn es ein Problem gibt.</p><p>Der König der Kelche kann auch bedeuten, dass es eine männliche Figur in der Arbeit gibt, der wirklich gerade jetzt auf Ihrer Seite ist. Wenn Sie Hilfe oder Unterstützung brauchen ist er die richtige Person zum Ansprechen. Er wird nicht zögern Ihnen den helfenden Hand zu reichen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Der König der Kelche handelt sich um Ausgeglichenheit. Damit Sie ausgewogen und spirituell ausgeglichen sind, brauchen Sie ein Gefühl für Grenzen. Wie weit kann man in eine bestimmte Richtung gehen und, noch wichtiger, wie weit möchten Sie gehen? Diese Karte zeigt einen aktiv mitfühlenden Menschen, jemand, der zuhört und sich kümmert um die Gefühle anderer und er will ihnen helfen zu wachsen. Dennoch ist es wichtig, Sich Selbst und andere einige Grenzen zu setzen, um nicht über Bord zu fallen in Ihrer Bereitschaft zu helfen. Jetzt ist ein guter Zeitpunkt um über diese Themen zu meditieren, um geistige Ruhe zu finden.</p>",
                    }
                }
            },            
            {
                filename: "50",
                numerology : 1,
                en : {
                    name: "Ace of Swords",
                    suit : "Swords",
                    element : "🜁 - air",
                    theme : "I think",    
                    description : "Ace of Swords, element air, talks about your thinking in its totality. Everything is potentially present but what do you think about it?",
                    questions : [ "Are you looking for clarity?", "Are you open to new concepts?", "Are these your thoughts or are they 'imposed'?", "Can you follow your own concepts?", "What do you think?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Ace of Swords is about power, courage, victory, confidence. You are deciding or will decide to make a new start in life, possibly on more than one level. This new chapter can be work related, about making changes in your relationship or maybe even both at once. You have the knowledge and courage to make these decisions hidden deep inside you. Now it is up to you to find this source and make full use of it. As with everything in life it is the first step that is the hardest, and it may feel like a leap of faith. But when you think about the big picture, you know that taking this step or these steps is in your best interest.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are currently in a committed relationship The Ace of Swords may indicate that something in your relationship doesn’t feel right. One way or another you are feeling uncomfortable, maybe even unhappy. Maybe you have been feeling this way for quite some time, but up until now you lacked the courage to do something about it. Maybe you were afraid of losing your partner, but now the time has come to have this important conversation in which you openly discuss what is bothering you. After all telling the truth is to be preferred to being unhappy.</p><p>If you are looking for love right now, it is very important to be open and outspoken about what you are looking for. It is better to have one truly meaningful encounter than ten superficial ones.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are ready to let go of old patterns and familiar habits. You have been thinking of new and different ways to do things and you are feeling inspired to bring about change. Now is the perfect moment to solve complex issues that have been there for a while. Also it may be helpful to talk through some procedures with colleagues, they might be ready for some change too. Even if you don’t get appreciation for your efforts right away, rest assured that “the powers that be” are noticing your work and in time will reward you for it.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card may be telling you that you are in a rut spiritually. You have had the same beliefs and convictions for quite a while now and you are starting to feel as if they don’t fit you anymore. You have grown out of them. Take the time to explore other beliefs and ways of thinking, talk to people who are following different paths. Allow yourself to be inspired by knowledge that didn’t interest you up until today. There is no shame in changing your mind. It doesn’t mean that what you used to believe was wrong, it only means you have thoroughly changed.</p>",
                    }
                },
                fr : {
                    name : "L’As des Épées",
                    suit : "Épées",
                    element :  "🜁 - air",
                    theme : "Je pense",    
                    description : "L'As des épées, élément air, nous parle de nos pensées dans son intégralité. Tout est potentiellement présent, mais qu'en pensez-vous?",
                    questions : ["Voulez-vous de la lucidité?", "Êtes-vous ouvert à une nouvelle idée?", "Est-elle la vôtre, ou est-elle \"imposée\"?", "Pouvez-vous suivre vos propres idées?", "Qu’en pensez-vous?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Les \"As d'Épée\" représentent le pouvoir, le courage, la victoire et la confiance en soi. Il décide d'un nouveau départ dans la vie, si possible à plusieurs niveaux. Ce nouveau chapitre peut concerner le travail, les changements dans votre relation, ou peut-être les deux à la fois. Vous avez les connaissances et le courage de prendre ces décisions qui sont cachées profondément en vous. Maintenant c'est à vous de trouver cette source et de l'utiliser pour le meilleur. Comme pour tout dans la vie, la première étape est la plus difficile. Vous pourriez la vivre comme un saut dans la confiance. Mais lorsque vous pensez à l'ensemble de la situation, vous savez qu'avec cette ou ces pas, vous faites de votre mieux dans votre propre intérêt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are currently in a committed relationship The Ace of Swords may indicate that something in your relationship doesn’t feel right. One way or another you are feeling uncomfortable, maybe even unhappy. Maybe you have been feeling this way for quite some time, but up until now you lacked the courage to do something about it. Maybe you were afraid of losing your partner, but now the time has come to have this important conversation in which you openly discuss what is bothering you. After all telling the truth is to be preferred to being unhappy.</p><p>If you are looking for love right now, it is very important to be open and outspoken about what you are looking for. It is better to have one truly meaningful encounter than ten superficial ones.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are ready to let go of old patterns and familiar habits. You have been thinking of new and different ways to do things and you are feeling inspired to bring about change. Now is the perfect moment to solve complex issues that have been there for a while. Also it may be helpful to talk through some procedures with colleagues, they might be ready for some change too. Even if you don’t get appreciation for your efforts right away, rest assured that “the powers that be” are noticing your work and in time will reward you for it.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card may be telling you that you are in a rut spiritually. You have had the same beliefs and convictions for quite a while now and you are starting to feel as if they don’t fit you anymore. You have grown out of them. Take the time to explore other beliefs and ways of thinking, talk to people who are following different paths. Allow yourself to be inspired by knowledge that didn’t interest you up until today. There is no shame in changing your mind. It doesn’t mean that what you used to believe was wrong, it only means you have thoroughly changed.</p>",
                    }
                },
                sp:{
                    name:"El As de espadas",
                    suit: "Espadas",
                    element: "🜁 – aire",
                    theme: "Pienso",    
                    description: "El As de Espadas, que encaja el elemento aire, es sobre tu pensamiento en su totalidad. Todo está potencialmente presente, pero ¿qué opinas?",
                    questions: ["¿Quieres claridad?", "¿Estás abierto a una nueva idea?", "¿Lo crees, o es forzado'", "¿Puedes seguir tus propias ideas?", "¿Qué piensas?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El As de Espadas es sobre el poder, el coraje, la victoria, la confianza. Usted está decidiendo o se decide hacer un nuevo comienzo en la vida, disponible en más de un nivel. Este nuevo capítulo puede ser un trabajo relacionado, en hacer cambios en su relación o incluso ambos a la vez. Usted tiene el conocimiento y el coraje para tomar estas decisiones ocultas dentro de usted. Ahora le toca a usted para encontrar esta fuente y hacer pleno uso de ella. Como con todo en la vida es el primer paso que es el más difícil, y puede sentirse como un acto de fe. Pero cuando se piensa en el cuadro grande, usted sabe que dar este paso o estos pasos es en su mejor interés.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Zwaarden Aas",
                    suit : "Zwaarden",
                    element : "🜁 - lucht",
                    theme : "ik denk",    
                    description : "De Aas van Zwaarden, passend bij het element lucht, gaat over je denken in zijn totaliteit. Alles is in potentie aanwezig, maar wat denk jij?",
                    questions : [ "Wil jij helderheid?", "Sta jij open voor een nieuw denkbeeld?", "Denk je zelf zo, of is het 'opgedrongen'?", "Kun jij je eigen denkbeelden volgen?", "Wat denk jij?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Aas van Zwaarden vertegenwoordigt macht, moed, overwinning, vertrouwen. Jij alleen kan beslissen of je een nieuwe start zal maken, een start die zich op meer dan één niveau kan afspelen. Het nieuwe hoofdstuk dat je zo begint te schrijven, kan met werk te maken hebben, met veranderingen in je relatie of misschien zelfs met allebei tegelijk. De kennis en de moed om deze beslissingen te nemen kan je diep binnenin jezelf vinden. Het is nu aan jou om je bron te ontdekken en er volop gebruik van te maken. Zoals bij alles in dit leven is de eerste stap de moeilijkste, je kan zelfs het gevoel hebben dat je met je ogen dicht in het diepe duikt. Maar als je naar het grotere plaatje kijkt, dan besef je dat deze stap(pen) die je zet je in de juiste richting zullen voeren.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit ogenblik een vaste relatie hebt dan vertelt de Aas van Zwaarden je dat er iets in deze relatie aan de hand is waar jij je niet goed bij voelt. Op de één of andere manier voel je je niet comfortabel bij de dingen zoals ze zijn, misschien ben je zelfs ongelukkig. Het is mogelijk dat je al een tijdje met deze gevoelens zit maar dat je tot op heden de moed nog niet had om er iets aan te doen. Misschien was je te bang om je partner te verliezen of wilde je zijn/haar kant van het verhaal liever niet horen. Maar nu is het moment gekomen om een gesprek aan te gaan waarin je openlijk praat over alle dingen die voor jou niet goed zitten. Tenslotte is eerlijk zijn, hoe moeilijk het ook is, toch een stuk beter dan ongelukkig zijn en blijven. Als je op zoek bent naar liefde is het belangrijk dat je heel open en duidelijk bent over je verlangens en wensen. Op dit moment is het beter om één diepgaand en zinvol contact te hebben dan tien oppervlakkige.</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk ben je er klaar voor om oude patronen en vertrouwde gewoontes los te laten. Je bent al een tijdje aan het nadenken over nieuwe en totaal andere manieren om de dingen aan te pakken. Je voelt je geïnspireerd en klaar om de dingen aan te pakken. Nu is het juiste moment gekomen om soms ingewikkelde problemen, waarvan je al een tijdje weet, aan te pakken. Het kan zinvol zijn om met collega’s over deze dingen te praten, misschien staan ze ook wel open voor veranderingen. Zelfs als je niet onmiddellijk waardering krijgt voor je acties, vertrouw erop dat je meerderen zien wat je doet en je ervoor zullen belonen, geef hen de tijd om erover na te denken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart vertelt je dat het mogelijk is dat je spiritueel gezien op een dood spoor zit. Je houdt nu al een tijdje vast aan dezelfde overtuigingen, hetzelfde geloof. Je begint het gevoel te krijgen alsof ze je niet meer passen, je lijkt er wel uit gegroeid te zijn. Neem je tijd, onderzoek andere manieren van denken, andere overtuigingen, praat uitgebreid met mensen die op een ander spiritueel pad zitten dan jij. Sta jezelf toe om geïnspireerd te worden door zaken die je tot nu toe niet interesseerden. Er is helemaal niets fout met veranderen van mening. Het betekent enkel dat jijzelf ook grondig veranderd bent.</p>",
                    }
                },
                de : {
                    name: "Ass der Schwerter",
                    suit : "Schwerter",
                    element : "🜁 - Luft",
                    theme : "Ich denke",    
                    description : "Das Ass der Schwerter, ein Luftelement, spricht von unseren Gedanken in ihrer Gesamtheit. Alles ist potentiell vorhanden, aber was denken Sie?",
                    questions : [ "Wollen Sie Klarheit?", "Sind Sie offen für eine neue Idee?", "Ist es Ihre Idee, oder wurde sie Ihnen \"aufgezwungen\"?", "Können Sie Ihren eigenen Ideen folgen?", "Wie denken Sie darüber?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die Karte „Ass der Schwerter“ steht für Macht, Mut, Sieg und Selbstvertrauen. Sie entscheiden sich für einen neuen Start ins Leben, möglicherweise auf mehreren Ebenen. Dieses neue Kapitel kann die Arbeit, Veränderungen in Ihrer Beziehung oder vielleicht sogar beides auf einmal betreffen. Sie haben das Wissen und den Mut, diese Entscheidungen, die tief in Ihnen verborgen sind, zu treffen. Jetzt liegt es an Ihnen, diese Quelle zu finden und sie zum Besten zu nutzen. Wie bei allem im Leben ist der erste Schritt der schwierigste. Sie könnten es als Sprung ins Ungewisse erfahren. Aber wenn Sie über das große Ganze nachdenken wissen Sie, dass Sie mit diesem Schritt oder mit diesen Schritten das Beste in Ihrem eigenem Interesse tun.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer festen Beziehung sind, kann das Ass der Schwerter anzeigen, dass etwas in der Beziehung nicht richtig fühlt. Wie es auch sein mag, es fühlt sich an als unangenehm, vielleicht sogar unglücklich. Vielleicht haben Sie dieses Gefühl bereits seit einiger Zeit, aber bis jetzt hat Ihnen den Mut, etwas dagegen zu tun gefehlt. Vielleicht haben Sie Angst Ihren Partner zu verlieren, aber jetzt ist die Zeit gekommen, um dieses wichtige Gespräch zu führen, in dem Sie angeben, was Sie stört. Denn die Wahrheit ist zu bevorzugen über Ungemütlichkeit</p><p>Wenn Sie im Augenblick nach der Liebe suchen, ist es sehr wichtig, offen und freimütig zu sein über das, was Sie suchen. Es ist besser, eine wirklich sinnvolle Begegnung zu haben als zehn oberflächlichen.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit sind Sie bereit alte Muster und Gewohnheiten aufzugeben. Sie haben daran gedacht, neue und andere Möglichkeiten zu überprüfen, andere Dinge zu tun, und Sie fühlen sich inspiriert Änderungen durchzuführen. Jetzt ist die perfekte Zeit, um komplizierte Fragen, die Sie bereits eine Weile haben, zu lösen. Es kann es hilfreich sein über einige Verfahren mit Kollegen zu sprechen, auch sie könnten bereit sein eine Veränderung durch zu führen. Auch wenn Sie nicht sofort Anerkennung bekommen für Ihre Bemühungen, dann können Sie sicher sein, dass „die Mächtigen„ aufmerksam werden auf Ihre Arbeit und in der Zeit werden sie Sie dafür belohnen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Diese Karte kann sagen, dass Sie geistig in einer Furche sind. Sie haben für eine ganze Weile den gleichen Glauben und Überzeugungen gehabt und jetzt scheinen sie Ihnen nicht mehr zu passen. Sie sind mit ihnen aufgewachsen. Nehmen Sie sich Zeit, um andere Überzeugungen und Denkweisen zu erforschen, sprechen Sie mit Menschen, die unterschiedliche Wege gegangen sind. Gestatten Sie Sich Sich inspirieren zu lassen von Wissen, das Ihnen bis heute nicht interessiert hat. Es ist keine Schande in dem Verstand zu ändern. Es bedeutet nicht, dass das, was Sie glaubten, falsch war, es bedeutet nur, dass Sie Sich gründlich verändert haben.</p>",
                    }
                }
            },
            {
                filename: "51",
                numerology : 2,
                en : {
                    name: "Two of Swords",
                    suit : "Swords",
                    element : "🜁 - air",
                    theme : "conflicting opinions",    
                    description : "Two of Swords talks about not having an opinion yet and our self-doubt which makes it hard to form our own decision.",
                    questions : [ "Are you influenced by what others think?", "Can you consciously wall up against that?", "Which truth is hidden deep within?", "What is your own opinion?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The perfectly balanced swords you see on this card, held by a blindfolded person, show the need for a balanced and stable mind and life and for looking at both sides of a situation, a problem. This card is about doubts, hesitation and the need for balance. Now is the time to ask yourself if all situations and relationships in your life are fully balanced. Do you see things as they really are or is your judgment and vision clouded, are you blind in some ways? Do people treat you as equal? Or are you the weaker or stronger person? Before “pulling out your swords” and seeking confrontation with others, it may be wise to first search for answers inside, to listen to your inner voice. Once you know how committed you are to bringing more balance into your life, you can start to communicate with others in order to achieve your goals.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a committed relationship this card tells you that a difficult period is finally past you and an easier, more balanced episode is starting. This is not a time for overthinking, hesitating or being skeptical, but for listening to and following your heart. Enjoy the balanced relationship you have created together.</p><p>If you are looking for a partner you should first take the time to do some soul searching. Are you really and truly over past relationships? Or are there still issues you need to work through? If there are, address them now. You don’t want to get to know potential partners with a chip on your shoulder. Also remind yourself that a partner is not there to make you happy. In an ideal situation you are already happy and then you find someone with whom you enjoy that happiness.</p>",
                        [GuidanceFields.WORK] : "<p>In connection with work you are feeling doubts on which way to go from here. Of course, reflecting on how to proceed is fine and can be really positive. Get all your ducks in a row and make a decision. Be careful not to hesitate too long, you could become apathetic and indifferent.</p><p>It is also possible that you are waiting for a decision to be made by your superiors. If this is the case, give them all the time they need and if you are getting impatient, try not to show it! </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually you are questioning and doubting yourself, asking yourself if maybe you allow yourself to be influenced by other people’s opinions and thoughts too much, or maybe you should reassess your own beliefs. Stop worrying! You are spiritually way more balanced than you give yourself credit for! You have grown and evolved in ways you don’t even realize. The beliefs you have, suit you perfectly right now and that is the only thing that counts. Do not compare yourself to others, you are unique! </p>",
                    }
                },
                fr : {
                    name : "Le Deux d’Épée",
                    suit : "Épées",
                    element :  "🜁 - air",
                    theme : "Opinions contradictoires",    
                    description : "Le Deux d’épée, nous parle de ne pas savoir exactement notre propre opinion et nous fait part de nos doutes innés, ce qui rend difficile de prendre une décision par nous-mêmes.",
                    questions : ["Êtes-vous influencé par des pensées opposantes?", "Pouvez-vous consciemment vous en éloigner?", "Quelle vérité se cache au fond de vous-même?", "Quelle est votre propre opinion?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Les épées parfaitement équilibrées que vous voyez sur cette carte sont tenues par une personne aux yeux bandés. Ils montrent le besoin d'un esprit et d'une vie équilibrées et stables. La carte symbolise également la vue des deux côtés d'un problème. Cette carte représente le doute, l'hésitation et le besoin d'équilibre. Maintenant, il est temps de vous demander si toutes les situations et les relations dans votre vie sont complètement en équilibre. Voyez-vous les choses telles qu'elles sont réellement ou votre jugement et votre vision sont-ils brouillés, êtes-vous aveugle d'une manière ou d'une autre? Les gens vous traitent-ils comme leurs égaux? Où êtes-vous la personne la plus faible ou la plus forte? Avant de sortir vos épées et de confronter les autres, il est peut-être utile de chercher d'abord des réponses en vous et d'écouter votre voix intérieure. Une fois que vous savez à quel point vous êtes déterminé à trouver un meilleur équilibre dans votre vie, vous pouvez parler aux autres pour atteindre vos objectifs.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a committed relationship this card tells you that a difficult period is finally past you and an easier, more balanced episode is starting. This is not a time for overthinking, hesitating or being skeptical, but for listening to and following your heart. Enjoy the balanced relationship you have created together.</p><p>If you are looking for a partner you should first take the time to do some soul searching. Are you really and truly over past relationships? Or are there still issues you need to work through? If there are, address them now. You don’t want to get to know potential partners with a chip on your shoulder. Also remind yourself that a partner is not there to make you happy. In an ideal situation you are already happy and then you find someone with whom you enjoy that happiness.</p>",
                        [GuidanceFields.WORK] : "<p>In connection with work you are feeling doubts on which way to go from here. Of course, reflecting on how to proceed is fine and can be really positive. Get all your ducks in a row and make a decision. Be careful not to hesitate too long, you could become apathetic and indifferent.</p><p>It is also possible that you are waiting for a decision to be made by your superiors. If this is the case, give them all the time they need and if you are getting impatient, try not to show it! </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually you are questioning and doubting yourself, asking yourself if maybe you allow yourself to be influenced by other people’s opinions and thoughts too much, or maybe you should reassess your own beliefs. Stop worrying! You are spiritually way more balanced than you give yourself credit for! You have grown and evolved in ways you don’t even realize. The beliefs you have, suit you perfectly right now and that is the only thing that counts. Do not compare yourself to others, you are unique! </p>",
                    }
                },
                sp:{
                    name:"El Dos de Espadas",
                    suit: "Espadas",
                    element: "🜁 –aire",
                    theme: "Opiniones contradictorias",    
                    description: "El Dos de Espadas se trata de no saber exactamente cuál es tu propia opinión y nos señala nuestra duda interior, lo que hace difícil tomar una decisión por nuestra cuenta",
                    questions: ["¿Estás influenciado por otros pensadores?", "¿Puedes cerrarte conscientemente a ellos?", "¿Qué verdad está escondida en tu interior?", "¿Cuál es tu propia opinión?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Las espadas perfectamente equilibradas que se ven en esta tarjeta, en manos de una persona con los ojos vendados, muestran la necesidad de una mente y vida equilibrada y estable y por mirar a ambos lados de una situación, un problema. Esta tarjeta es la duda, la vacilación y la necesidad de equilibrio. Ahora es el momento de preguntarse si todas las situaciones y relaciones en su vida son balanceadas. ¿Usted ve las cosas como realmente son o se su juicio y visión nublada, está ciego en algunos aspectos? ¿La gente le trata como iguales? ¿O es usted la persona más débil o más fuerte? Antes de \"sacando sus espadas\" y la búsqueda de la confrontación con los demás, puede ser sabio para buscar primero para obtener respuestas en el interior, para escuchar su voz interior. Una vez que sepa lo interesado que está a traer más equilibrio en su vida, usted puede comenzar a comunicarse con los demás con el fin de lograr sus objetivos.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Zwaarden Twee",
                    suit : "Zwaarden",
                    element : "🜁 - lucht",
                    theme : "tegenstrijdige meningen",    
                    description : "De Twee van Zwaarden gaat over nog niet precies weten wat je eigen mening is en wijst ons op onze innerlijke twijfel, waardoor het lastig is om zelf een beslissing te nemen.",
                    questions : [ "Word jij beïnvloed door anders denkenden?", "Kun jij je daar bewust voor afsluiten?", "Welke waarheid ligt diep in jezelf verborgen?", "Wat is je eigen mening?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Op deze kaart zie je een geblinddoekte persoon die twee zwaarden vasthoudt die perfect in evenwicht zijn. Deze zwaarden wijzen je op het belang van evenwicht in je innerlijk en in je leven. Ze symboliseren ook hoe belangrijk het is om naar beide kanten van een situatie, een probleem te kijken. Deze kaart gaat over twijfelen en aarzelen èn over de behoefte aan evenwicht. Op dit moment is het belangrijk dat je je afvraagt of alle situaties en relaties in je leven helemaal in evenwicht zijn. Zie je de dingen echt zoals ze zijn? Of is je oordeel en visie troebel, ben je mogelijk blind op bepaalde vlakken? Behandelen mensen je als hun gelijke? Of ben je de sterkere of zwakkere persoon in het contact? Alvorens je “je zwaarden trekt” en de confrontatie gaat opzoeken, is het misschien verstandig om eerst binnenin jezelf op zoek te gaan naar antwoorden, om te luisteren naar je innerlijke stem. Eens je voor jezelf vastgesteld hebt hoe belangrijk het is om meer evenwicht in je leven te krijgen, kan je met anderen gaan communiceren om je doelen te bereiken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een relatie hebt dan is Zwaarden Twee een teken dat je een moeilijke periode eindelijk achter je kan laten. Je staat aan het begin van een ontspannener en evenwichtiger hoofdstuk. Denk hier niet teveel over na, twijfel niet, wees niet sceptisch ... nu is het tijd om naar je hart te luisteren en het te volgen, ga voor je gevoel. Geniet volop van deze evenwichtige relatie die jullie samen waargemaakt hebben!</p><p>Als je op zoek bent naar een relatie, gun jezelf dan nu de tijd om aan wat zelfonderzoek te doen. Heb je je vorige relatie echt helemaal verwerkt? Of zijn er nog problemen en vragen die je beter zou doornemen om ze daarna te kunnen loslaten? Als dat laatste het geval is, pak deze dingen dan allereerst aan. Het zou te gek zijn om een potentiële partner te ontmoeten terwijl je nog half in het verleden zit. Bedenk ook dat een partner er niet is om jou gelukkig te maken. De ideale situatie is dat jij al gelukkig bent en dat je dan iemand vindt waarmee je dat geluk kan delen.</p>",
                        [GuidanceFields.WORK] : "<p>Als het over werk gaat dan vertelt Zwaarden Twee je dat je met wat twijfels zit in verband met je toekomst, welke weg je best zou kiezen. Het is altijd verstandig en positief om zorgvuldig na te denken over het te volgen pad en te nemen besluiten. Zet alles op een rijtje en neem dan een beslissing. Waak ervoor om niet te lang te twijfelen, blijf niet hangen in het nadenken. Je loopt het risico dat je dan apathisch wordt of zelfs onverschillig.</p><p>Het is ook mogelijk dat je op dit moment aan het wachten bent op een beslissing van je meerderen. Probeer rustig te blijven en geef hen alle tijd die ze nodig hebben. Als je ongeduldig aan het worden bent, toon dit dan zeker niet!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op spiritueel vlak stel je jezelf een aantal vragen en zit je ook met twijfels. Je vraagt je aan de ene kant af of je je teveel hebt laten beïnvloeden door de gedachten en meningen van andere mensen, aan de andere kant heb je het gevoel dat je je geloofssysteem misschien moet gaan herbekijken. Stop met piekeren!</p><p>Je bent spiritueel veel beter in evenwicht dan je van jezelf denkt. Je bent gegroeid, geëvolueerd op manieren die je zelf niet doorhebt. De overtuigingen die je hebt, passen perfect bij je en dat is het enige wat telt op dit moment. Ga je spirituele zelf niet vergelijken met anderen, je bent uniek!</p>",
                    }
                },
                de : {
                    name: "Zwei der Schwerter",
                    suit : "Schwerter",
                    element : "🜁 - Luft",
                    theme : "Widersprüchliche Meinungen",    
                    description : "Die Zwei der Schwerter spricht darüber, dass wir unsere eigene Meinung nicht genau kennen und teilt uns unsere angeborenen Zweifel mit, was es uns schwierig macht, selber eine Entscheidung zu treffen.",
                    questions : [ "Werden Sie durch gegensätzlichen Gedanken beeinflusst?", "Können Sie sich bewusst von ihnen distanzieren?", "Welche Wahrheit verbirgt sich tief in Ihnen?", "Was ist Ihre eigene Meinung?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die perfekt ausbalancierten Schwerter, die Sie auf dieser Karte sehen, werden von einer Person mit verbundenen Augen gehalten. Sie zeigen das Bedürfnis nach einem ausgewogenen und stabilen Geist und nach einem ebensolchen Leben. Die Karte symbolisiert auch den Blick auf beiden Seiten einer Problemlage. Diese Karte steht für Zweifel, Zögern sowie die Notwendigkeit eines Gleichgewichts. Jetzt ist es Zeit sich zu fragen, ob alle Situationen und Beziehungen in Ihrem Leben wirklich im Gleichgewicht sind. Sehen Sie die Dinge vollkommen klar, so wie sie wirklich sind, oder sind Ihr Urteilsvermögen und Ihre Vision getrübt, sind Sie in gewisser Weise blind? Behandeln die Menschen Sie als ihren Ebenbürtigen? Oder sind Sie die schwächere oder stärkere Person? Bevor Sie Ihre Schwerter ziehen und die Konfrontation mit anderen angehen kann es sinnvoll sein, erst einmal nach Antworten in Ihrem Inneren zu suchen und  auf Ihre innere Stimme zu hören. Sobald Sie wissen, wie engagiert Sie sind, um mehr Gleichgewicht in Ihr Leben zu bringen, können Sie sich mit anderen unterhalten, um Ihre Ziele zu erreichen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer festen Beziehung sind sagt diese Karte, dass eine schwierige Zeit endlich vorbei ist und ein einfacher, ausgewogener Folge beginnt. Dies ist keine Zeit für Grübeln, zögern oder Skeptiker, aber für das Hören auf und das Handeln nach Deinem Herzen. Genießen Sie das zusammen geschaffene ausgewogene Verhältnis.</p><p>Wenn Sie auf der Suche nach einem Partner sind, sollten Sie zunächst die Zeit nehmen, einige Seele Untersuchungen zu machen. Sind Sie wirklich und wahrhaftig über vergangene Beziehungen hinweg? Oder gibt es noch Punkte, die Sie durcharbeiten müssen? Wenn es sie gibt, rechnen Sie jetzt damit ab. Sie wollen nicht erleben, dass ein potenzieller Partner Sie mit einem Chip auf Ihre Schulter kennenlernt. Merken Sie sich auch, dass ein Partner nicht da ist, um Sie glücklich zu machen. Im Idealfall sind Sie bereits glücklich und suchen dann jemanden, mit dem Sie dieses Glück genießen können.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit fühlen Sie Zweifel, welchen Weg sie gehen müssen von hier. Natürlich, nachdenken über was und wie zu verfahren ist in Ordnung und kann wirklich positiv sein. Erhalten Sie alle Ihre Enten in einer Reihe und treffen Sie eine Entscheidung. Seien Sie vorsichtig und zögern Sie nicht zu lange. Sie könnten apathisch und gleichgültig werden.</p><p>Es ist auch möglich, dass Sie warten auf eine Entscheidung, die von Ihren Vorgesetzten genommen werden muss. Wenn dies der Fall ist, geben Sie ihnen die ganze Zeit, die sie benötigen, und wenn Sie ungeduldig werden, versuchen Sie es nicht zu zeigen!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spirituell haben Sie Fragen und Zweifel an sich selbst, fragen Sie sich, ob Sie Sich vielleicht zu sehr beeinflussen lassen von Meinungen und Gedanken anderer Leute, oder dass Sie doch mal vielleicht erst mal Ihre eigene Überzeugungen überdenken müssen. Keine Sorgen! Sie sind spirituell ausgeglichener als Sie Selbst meinen! Sie sind gewachsen und haben Sich entwickelt in einer Weise, die Sie Sich nicht einmal eindenken könnten. Die Überzeugungen, die Sie haben, passen Sie jetzt perfekt, und das ist das einzige, was zählt. Vergleichen Sie sich nicht mit anderen, Sie sind einzigartig!</p>",
                    }
                }
            },
            {
                filename: "52",
                numerology : 3,
                en : {
                    name: "Three of Swords",
                    suit : "Swords",
                    element : "🜁 - air",
                    theme : "make room for what you think",    
                    description : "Three of Swords talks about airing our own thoughts and the pain when we let the mind take the upper hand over the feelings, which can be heart-breaking.",
                    questions : [ "What is bothering you?", "Which decision goes against your better judgement?", "Speak openly.", "Stick up for your opinion." ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Three of Swords depicts a heart that is being punctured by three swords, an image that clearly involves pain to some degree. But don’t worry, this card isn’t all about pain, it is about growth too.</p><p>Maybe you have made a sensible decision that your heart doesn’t agree with. If this is the case, you may temporarily experience a feeling of relief because you are no longer in a difficult situation. But your emotions will catch up with you and you will have to work through them someday, or you may end up making the same decision again and again because your heart puts you in the same situation over and over.</p><p>It is also possible that you have some deep rooted emotional issues that you hesitate to work through. Remember that trying to hide will not make them go away. Face them head on and be done with them.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a loving relationship you are about to experience or are experiencing heartache. This doesn’t necessarily mean that you are headed for a breakup. Possibly you will just be going through a rough patch. Or maybe you are disappointed in your partner or in the relationship because what you hoped for doesn’t come true. Just remember that, no matter how hard you try, you cannot make someone love you or treat you in a certain way. If your partner doesn’t spontaneously experience the love you need him/her to experience, no investments from your side will change that. Maybe it’s time to reach a conclusion and act upon it.</p>",
                        [GuidanceFields.WORK] : "<p>Possibly there are situations at work that you don’t like, maybe there even are things happening that hurt your ego and self-worth. Try to remember that your value, who you are as a person, does not revolve around your work alone. There are many more aspects to who you are.</p><p>It’s also possible that you are about to make a work related decision that is not in your best interest, consider your options carefully.</p><p>If you are looking for a job then possibly you are not looking in the right place. Maybe you are under- or overestimating yourself or possibly there are areas you didn’t even consider that could be interesting for you to explore. Try to expand your view.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Now may be a good moment to take some time to yourself and consider how you are feeling: do you feel whole, balanced and happy with everything and everybody in your life? Or do you need to let go of some things? Maybe there are people you don’t want around anymore, maybe there are habits you want to let go of.</p><p>Once you have finished your “spring cleaning” it is time to consider which new options you want to explore. Who and what do you want to invite into your life? Even though the answers to these questions are within you, maybe you could use some help unearthing them. Do not hesitate to ask for assistance!</p>",
                    }
                },
                fr : {
                    name : "Le Trois d’Épée",
                    suit : "Épées",
                    element :  "🜁 - air",
                    theme : "Donnez de l'espace à ce que vous pensez",    
                    description : "Le Trois d'épée, nous parle de donner libre cours à ce que l’on pense de douleur quand l’esprit prends le dessus sur nos sentiments et de peines de cœur.",
                    questions : ["Que vous tracasse-t-il?", "Quelle décision va à l'envers de vos sentiments?", " Ne mangez pas votre rage", "Pouvez-vous défendre votre opinion?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le «Trois d'épée» montre un cœur transpercé par trois épées. Une image qui est clairement liée à la douleur d'une certaine façon. Mais ne vous inquiétez pas, cette carte n'est pas seulement synonyme de douleur, mais aussi de croissance.</p><p>Peut-être avez-vous pris une décision raisonnable et rationnelle avec laquelle votre cœur n'est pas d'accord. Si c'est le cas, il se peut que vous éprouviez temporairement un sentiment de soulagement parce que vous n'êtes plus dans une position difficile. Mais vos sentiments vous rattraperont et vous devrez les affronter un jour. Si vous ne le faites pas, vous pouvez finir par prendre la même décision encore et encore une fois parce que votre cœur continue de vous mettre dans la même situation.</p><p>Il est également possible que vous ayez des problèmes émotionnels profondément enracinés et que vous hésitiez à les traiter. Notez que lorsque vous essayez de les cacher, ils ne disparaissent pas comme ça. Traiter un problème pour les résoudre.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a loving relationship you are about to experience or are experiencing heartache. This doesn’t necessarily mean that you are headed for a breakup. Possibly you will just be going through a rough patch. Or maybe you are disappointed in your partner or in the relationship because what you hoped for doesn’t come true. Just remember that, no matter how hard you try, you cannot make someone love you or treat you in a certain way. If your partner doesn’t spontaneously experience the love you need him/her to experience, no investments from your side will change that. Maybe it’s time to reach a conclusion and act upon it.</p>",
                        [GuidanceFields.WORK] : "<p>Possibly there are situations at work that you don’t like, maybe there even are things happening that hurt your ego and self-worth. Try to remember that your value, who you are as a person, does not revolve around your work alone. There are many more aspects to who you are.</p><p>It’s also possible that you are about to make a work related decision that is not in your best interest, consider your options carefully.</p><p>If you are looking for a job then possibly you are not looking in the right place. Maybe you are under- or overestimating yourself or possibly there are areas you didn’t even consider that could be interesting for you to explore. Try to expand your view.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Now may be a good moment to take some time to yourself and consider how you are feeling: do you feel whole, balanced and happy with everything and everybody in your life? Or do you need to let go of some things? Maybe there are people you don’t want around anymore, maybe there are habits you want to let go of.</p><p>Once you have finished your “spring cleaning” it is time to consider which new options you want to explore. Who and what do you want to invite into your life? Even though the answers to these questions are within you, maybe you could use some help unearthing them. Do not hesitate to ask for assistance!</p>",
                    }
                },
                sp:{
                    name:"El Tres de espadas",
                    suit: "Espadas",
                    element: "🜁 –  aire",
                    theme: "Da espacio a lo que piensas",    
                    description: "El Tres de Espadas se trata de dar aire a lo que piensas y señala nuestro dolor cuando usamos nuestra mente para ir en contra de nuestros sentimientos, perforando nuestro corazón",
                    questions: ["¿Qué te molesta?", "¿Qué decisión va en contra de tus sentimientos?", "No conviertas tu corazón en un hoyo de asesinato", "¿Puedes defender tu opinión?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Tres de espadas representa un corazón que está siendo perforado por tres espadas, una imagen que implica claramente el dolor en cierto grado. Pero no se preocupe, esta tarjeta no tiene que ver con el dolor, se trata de un crecimiento demasiado. Tal vez usted ha tomado una decisión sensata con que su corazón no está de acuerdo. Si este es el caso, puede experimentar temporalmente una sensación de alivio porque ya no está en una situación difícil. Pero sus emociones se pondrá al día con usted y usted tendrá que trabajar a través de ellos algún día, o que puede llegar a hacer la misma decisión una y otra vez porque su corazón le pone en la misma situación una y otra vez. También es posible que usted tenga algunos problemas emocionales profundamente arraigados que dudan en trabajar a través. Recuerde que tratando de ocultar no hará que desaparezcan. Encararlos de frente y acabar con ellos.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Zwaarden Drie",
                    suit : "Zwaarden",
                    element : "🜁 - lucht",
                    theme : "geef dat wat jij denkt de ruimte",    
                    description : "De Drie van Zwaarden gaat over lucht geven aan wat je denkt en wijst ons op onze pijn wanneer we met ons verstand tegen ons gevoel in gaan, ons hart doorboren.",
                    questions : [ "Wat zit je dwars?", "Welke beslissing gaat tegen je gevoel in?", "Maak van je hart geen moordkuil.", "Kun jij opkomen voor je mening?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De afbeelding bij Zwaarden Drie is een hart dat doorboord wordt door drie zwaarden. Dit beeld maakt duidelijk dat er op een of andere manier pijn komt kijken bij de betekenis van deze kaart. Maar maak je niet ongerust, het gaat niet enkel over pijn maar ook over groeien.</p><p>Als je deze kaart krijgt dan is het mogelijk dat je een verstandige, intellectuele beslissing genomen hebt maar dat je hart het er niet mee eens kan zijn. Als dit het geval is, dan kan je weliswaar tijdelijk een gevoel van opluchting ervaren omdat je je niet langer in een moeilijke situatie bevindt. Maar op een dag zullen je gevoelens je inhalen, word je opnieuw geconfronteerd met je (verstandelijke) beslissing en zal je deze emoties moeten verwerken. Als je dat niet doet dan zal je merken dat je telkens weer dezelfde beslissing neemt omdat je hart je telkens weer in dezelfde situatie terecht doet komen.</p><p>Het is ook mogelijk dat je deze kaart krijgt omdat je diep gewortelde emotionele problemen hebt die je niet durft aan te pakken. Denk eraan dat deze problemen niet verdwijnen als je ze verstopt en negeert. Ga de confrontatie aan met jezelf zodat je alles een plek kan geven en dan loslaten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een liefdesrelatie hebt dan heb je of zal je binnenkort verdriet hebben. Dit betekent niet automatisch dat er een breuk zal volgen. Het is ook mogelijk dat jullie het samen gewoon even moeilijk hebben. Misschien ben je teleurgesteld in je partner of je relatie omdat waar je op hoopte niet uitgekomen is. De realiteit kan soms minder fijn of intens zijn dan je fantasie. Probeer voor ogen te houden dat je iemand niet kan dwingen om van je te houden of je op een bepaalde manier te behandelen, al probeer je nog zo hard. Als je partner niet spontaan van je houdt dan kan je dat niet veranderen, gelijk hoeveel je investeert in de relatie. Misschien is de tijd gekomen om je conclusies te trekken en ernaar te handelen.</p>",
                        [GuidanceFields.WORK] : "<p>Het is best mogelijk dat je de situatie op je werk op dit moment behoorlijk onaangenaam vindt. Misschien zijn er zelfs dingen gaande die je kwetsen in je ego en je eigenwaarde. Denk eraan dat jouw waarde, wie jij bent als mens, niet enkel en zelfs niet vooral afhangt van je werk. Je bent veel gevarieerder en gecompliceerder dan enkel dat ene stukje.</p><p>Wat ook kan is dat je op het punt staat een beslissing te nemen in verband met je werk die eigenlijk in je nadeel zou kunnen uitdraaien. Bekijk al je opties zorgvuldig en denk goed na voor je iets doet dat je niet meer kan terugdraaien.</p><p>Als je op zoek bent naar werk dan ben je mogelijk niet in de juiste richting aan het zoeken. Het zou kunnen dat je jezelf onder- of overschat. En er zijn ook tal van gebieden die je nog niet onderzocht hebt maar die best interessant voor je kunnen zijn. Verbreed je blikveld een beetje.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op spiritueel gebied is dit een goed moment om wat tijd voor jezelf te nemen en je af te vragen hoe je je voelt: voel je je compleet en evenwichtig, ben je gelukkig met alles en iedereen in je leven? Of zijn er toch wat dingen die je beter zou loslaten? Misschien gaat het om mensen die je liever niet meer om je heen wil, misschien gaat het om gewoontes die je wil veranderen. Wat het ook is dat je niet zint, pak het aan. Eens je klaar bent met je “voorjaarsschoonmaak” kan je je gaan afvragen welke nieuwe mogelijkheden je wil gaan onderzoeken. Wie en wat wil je verwelkomen in je leven? Ook al kan je de antwoorden op deze vragen enkel binnenin jezelf vinden, het is toch mogelijk dat je wel wat ondersteuning kan gebruiken bij je zoektocht. Aarzel niet om hulp te vragen!</p>",
                    }
                },
                de : {
                    name: "Drei der Schwerter",
                    suit : "Schwerter",
                    element : "🜁 - Luft",
                    theme : "Raum geben für das, was man denkt.",        
                    description : "Die Drei der Schwerter spricht darüber, dass wir dem, was wir von Schmerz halten, freien Lauf lassen, wenn der Geist unsere Gefühle und unseren Kummer überwältigt.",
                    questions : [ "Was bedrückt Sie?", "Welche Entscheidung widerspricht Ihren Gefühlen?", "Schlucken Sie Ihre Wut nicht herunter", "Können Sie Ihre Meinung verteidigen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Drei der Schwerter“ zeigt ein Herz, das von drei Schwertern durchbohrt wird. Ein Bild, das eindeutig in gewisser Hinsicht mit Schmerzen zusammenhängt. Aber keine Sorge, diese Karte steht nicht nur für Schmerzen, sondern auch für Wachstum.</p><p>Vielleicht haben Sie eine sinnvolle, rationale Entscheidung getroffen, mit der Ihr Herz  nicht einverstanden ist. Wenn dies der Fall ist können Sie vorübergehend ein Gefühl der Erleichterung erfahren, weil Sie sich nicht mehr in einer schwierigen Lage befinden. Aber Ihre Gefühle werden Sie einholen und Sie müssen sich ihnen eines Tages stellen. Wenn Sie das nicht tun können Sie am Ende die gleiche Entscheidung wieder und wieder treffen, weil Ihr Herz Sie immer wieder in die gleiche Situation führt.</p><p>Es ist auch möglich, dass Sie einige tief verwurzelte emotionale Probleme haben und sie zögern, diese zu verarbeiten. Beachten Sie, dass, wenn man versucht sie zu verbergen, sie nicht einfach weggehen. Setzen Sie Sich direkt mit diesen Problemen auseinander und lösen Sie sie.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer liebevollen Beziehung sind, werden Sie Herzschmerzen erleben oder Sie sind bereits mitten drin. Dies bedeutet nicht zwangsläufig, dass Sie sich auf eine Trennung zusteuern. Möglicherweise werden Sie nur durch eine schwierige Phase gehen. Oder vielleicht sind Sie enttäuscht von Ihrem Partner oder in der Beziehung weil, was Sie gehofft haben, nicht geschehen ist. Denken Sie daran, dass, egal wie sehr man es versucht, man kann jemanden nicht zwingen Sie zu lieben oder zu behandeln so wie Sie das gerne möchten. Wenn Ihr Partner nicht spontan die Liebe gibt, die Sie wünschen, wird keine einzige Investierung Ihrerseits das ändern. Vielleicht ist es Zeit, um eine Schlussfolgerung zu erreichen und darauf zu reagieren.</p>",
                        [GuidanceFields.WORK] : "<p>Möglicherweise gibt es Situationen bei der Arbeit, die Sie nicht mögen, vielleicht sind auch Dinge passiert, die Ihr Ego und Selbstwertgefühl verletzt haben. Versuchen Sie daran zu denken, dass der Wert, die Sie als Person haben, nicht nur basiert ist auf Ihrer Arbeit. Es gibt viele weitere Aspekte die bestimmen, wer Sie sind.</p><p>Es ist auch möglich, dass Sie dabei sind, eine Entscheidung zu treffen, die Ihre Arbeit anbelangt. Diese wird nicht in Ihrem besten Interesse sein. Prüfen Sie Ihre Optionen sorgfältig.</p><p>Wenn Sie nach einem Job suchen, dann suchen Sie möglicherweise nicht an der richtigen Stelle. Vielleicht unter- oder überschätzen Sie Sich Selbst oder vielleicht gibt es Bereiche, von denen Sie nicht einmal in Erwägung genommen haben, dass diese interessant sein könnten für Sie zu entdecken. Versuchen Sie, Ihre Blickweite zu vergrößern.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Jetzt könnte es ein guter Zeitpunkt sein, um etwas Zeit für sich selbst zunehmen um zu überlegen, wie Sie sich fühlen: Fühlen Sie Sich komplett, ausgewogen und zufrieden mit allem und jedem in Ihrem Leben? Oder müssten Sie einige Dinge hinterlassen? Vielleicht gibt es Menschen, die Sie nicht mehr um Sich wollen oder vielleicht gibt es Gewohnheiten, die Sie abgewöhnen sollten.</p><p>Sobald Sie Ihre „Frühjahrsputz„ abgeschlossen haben, ist es Zeit zu überlegen, welche neue Möglichkeiten Sie erforschen möchten. Wer und was wollen Sie neu zu Ihrem Leben einladen? Auch wenn Sie die Antworten auf diese Fragen bereits wissen, könnten Sie vielleicht etwas Hilfe gebrauchen sie auszugraben. Zögern Sie nicht, um Hilfe zu bitten!</p>",
                    }
                }
            },
            {
                filename: "53",
                numerology : 4,
                en : {
                    name: "Four of Swords",
                    suit : "Swords",
                    element : "🜁 - air",
                    theme : "having a handle on your thoughts",    
                    description : "Four of Swords talks about constructive thinking and taking the time to think things through.",
                    questions : [ "Is there chaos in your mind?", "Which thoughts need to be evened out?", "Are you taking the necessary time to have those evened out?", "What could you calmly think through?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about taking a rest (be it voluntarily or not) but also about boundaries. Maybe you need a break or maybe circumstances are forcing you to take one. Or possibly someone who is important to you will need some time to her- or himself.</p><p>If it is you who needs or takes a break then it will be very important to you that people respect your boundaries and do not try to convince you to do otherwise.</p><p>Think about this when someone close to you wants a pause, and respect their boundaries too, they are sure to have good reasons to temporarily take some distance.</p><p>The Four of Swords tells you that the time has come for you to say no or to respect it when others say no to you.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are currently in a relationship you may be feeling distant. You are having a hard time to connect to your partner and generally you feel bad about the situation. Even though it is important to openly communicate with him/her, you need to remind yourself that it is not up to them to make you feel better about yourself and the relationship. It is up to you to think about how you feel and do something about it. Possibly you just need some time to yourself.</p><p>If you are currently single this is not the right time to go looking for a partner. Take a break to decide what qualities you are looking for in a partner. Rest assured that it will be time well spent.</p>",
                        [GuidanceFields.WORK] : "<p>You may have a feeling as if things are not at all going the way you would want them to go. Still, this is not the right time to try and push people in certain directions. After all, some processes take time. It would be much better to take some time off if you can, to get yourself some well earned rest and recreation.</p><p>If you are looking for a job, it may be wise not to plan interviews for a while. You aren’t the most patient person right now and that may have a negative effect on your chances to get hired.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually speaking you are in dire need of some time to just be alone with yourself. Possibly the people around you would like to pick your brain for insights and answers, but it just isn’t the right time for this. You need to focus on yourself, calm down and then tune in to the cosmic energies and vibrations around you. Feel their soothing effects inside you. Relax, heal, become whole again.</p>",
                    }
                },
                fr : {
                    name : "Le Quatre d’ Épée",
                    suit : "Épées",
                    element :  "🜁 - air",
                    theme : " prendre en main ce que vous pensez",    
                    description : " Le Quatre d’ Épée, nous parle d’ une réflexion constructive et de prendre le temps de mettre les choses au clair.",
                    questions : ["Est-ce le chaos dans votre esprit?", "Quelles pensées doivent être réglées?", "Est-ce que vous vous donnez le temps de régler les choses?", "A quoi pourriez-vous réfléchir tranquillement?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente une pause (volontaire ou non), mais aussi des limites. Peut-être que vous avez besoin d'une pause ou que les circonstances vous obligent à faire une pause, peut-être qu'il y a quelqu'un qui est important pour vous et qui, il ou elle, a besoin d'un peu de votre temps.</p><p>Si c'est le cas, alors il est très important que les gens autour de vous respectent vos limites et n'essayez pas de vous persuader de le faire différemment.</p><p>Rappelez-vous que lorsqu'un être cher a besoin d'une pause, respectez ses limites. Vous pouvez être sûr qu'elle a de bonnes raisons de faire une pause temporaire.</p><p>Le « Quatre d'Épée » vous dit que le temps est venu pour vous de dire non et de respecter si les autres vous disent non.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are currently in a relationship you may be feeling distant. You are having a hard time to connect to your partner and generally you feel bad about the situation. Even though it is important to openly communicate with him/her, you need to remind yourself that it is not up to them to make you feel better about yourself and the relationship. It is up to you to think about how you feel and do something about it. Possibly you just need some time to yourself.</p><p>If you are currently single this is not the right time to go looking for a partner. Take a break to decide what qualities you are looking for in a partner. Rest assured that it will be time well spent.</p>",
                        [GuidanceFields.WORK] : "<p>You may have a feeling as if things are not at all going the way you would want them to go. Still, this is not the right time to try and push people in certain directions. After all, some processes take time. It would be much better to take some time off if you can, to get yourself some well earned rest and recreation.</p><p>If you are looking for a job, it may be wise not to plan interviews for a while. You aren’t the most patient person right now and that may have a negative effect on your chances to get hired.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually speaking you are in dire need of some time to just be alone with yourself. Possibly the people around you would like to pick your brain for insights and answers, but it just isn’t the right time for this. You need to focus on yourself, calm down and then tune in to the cosmic energies and vibrations around you. Feel their soothing effects inside you. Relax, heal, become whole again.</p>",
                    }
                },
                sp:{
                    name:"El Cuatro de Espadas",
                    suit: "Espadas",
                    element: "🜁 - aire",
                    theme: "agarrarte a lo que piensas",    
                    description: "El Cuatro de Espadas es sobre el pensamiento constructivo y indica que debemos tomarnos el tiempo para aclarar las cosas",
                    questions: ["¿Hay caos en tu cabeza?", "¿Qué pensamientos hay que resolver?", "¿Te das tiempo para resolverlos?", "¿En qué podrías pensar tranquilamente?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es acerca de tomar un descanso (ya sea voluntariamente o no), pero también sobre los límites. Tal vez usted necesita un descanso o tal vez las circunstancias que están obligando a tomar uno. O posiblemente alguien que es importante para usted necesitará un poco de tiempo para ella- o él mismo. Si es usted quien necesita o se toma un descanso, entonces será muy importante para usted que la gente respetar sus límites y no tratan de convencerlo de lo contrario. Piense en esto cuando alguien cercano a usted quiere una pausa, y respeten sus límites también, que están seguros de tener buenas razones para tomar temporalmente cierta distancia. El Cuatro de espadas le dice que ha llegado el momento para que usted diga o no respetarla cuando otros dicen que no.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Zwaarden Vier",
                    suit : "Zwaarden",
                    element : "🜁 - lucht",
                    theme : "grip op wat je denkt",    
                    description : "De Vier van Zwaarden gaat over constructief denken en wijst ons op bewust de tijd nemen om het een en ander op een rijtje te kunnen zetten.",
                    questions : [ "Is het een chaos in je hoofd?", "Welke gedachten behoeven ordening?", "Gun jij jezelf de tijd om alles op een rijtje te zetten?", "Wat zou jij rustig kunnen overdenken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Zwaarden Vier kondigt in de eerste plaats een (al dan niet vrijwillige) rustperiode aan maar heeft het ook over grenzen. Misschien heb je behoefte aan een pauze maar het kan ook zijn dat de omstandigheden je ertoe dwingen. Een andere mogelijkheid is dat iemand die belangrijk voor je is behoefte heeft aan wat tijd voor zichzelf.</p><p>Als jij degene bent die behoefte heeft aan een onderbreking dan zal je merken dat het heel belangrijk voor je is dat de mensen om je heen je grenzen respecteren en je niet proberen te overhalen om het anders aan te pakken.</p><p>Denk hieraan als mensen in je omgeving ook een pauze willen en respecteer hun grenzen. Ze hebben vast goede redenen om tijdelijk wat afstand te willen. Zwaarden Vier vertelt je dat het nu de goede tijd is voor jou om neen te leren zeggen en om het ook te respecteren als anderen neen tegen jou zeggen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit ogenblik een relatie hebt dan is het mogelijk dat je een zekere afstand voelt. Je vindt het moeilijk om te communiceren met je partner, om je echt ‘samen’ te voelen en je voelt je daar helemaal niet goed bij. Alhoewel het heel belangrijk is om open te communiceren met je partner, denk er ook aan dat het niet de taak van jouw partner is om ervoor te zorgen dat jij je beter voelt bij hem/haar en bij de relatie. Het is jouw taak om je gevoelens te onderzoeken en er vervolgens iets mee te doen. Misschien heb je gewoon wat tijd voor jezelf nodig om dingen op een rijtje te zetten.</p><p>Als je op dit moment alleen bent dan is het niet de juiste tijd om op zoek te gaan naar een partner. Geef jezelf wat ruimte om erover na te denken waar je juist naar op zoek bent, welke eigenschappen wil je dat je toekomstige partner heeft? Je zal achteraf blij zijn dat je hiervoor de tijd genomen hebt.</p>",
                        [GuidanceFields.WORK] : "<p>In je werksituatie heb je mogelijk het gevoel dat de dingen helemaal niet gaan zoals jij zou willen dat ze gaan. En toch is het nu het verkeerde moment om veranderingen door te voeren of mensen in een bepaalde richting te duwen. Sommige veranderingsprocessen hebben tijd nodig. Op dit moment zou het beter zijn om wat vrijaf te nemen als dat mogelijk is, zodat je wat kan rusten en ontspannen. Je hebt het verdiend!</p><p>Als je op zoek bent naar werk dan is het een goed idee om de komende tijd geen sollicitatiegesprekken te plannen. Je bent op dit moment niet echt geduldig en dat kan je kansen om aangenomen te worden erg negatief beïnvloeden. Neem wat tijd voor jezelf, je hebt het nodig.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op spiritueel vlak is het op dit ogenblik erg belangrijk dat je de tijd neemt om eens helemaal alleen met jezelf te zijn. Het is best mogelijk dat de mensen om je heen op zoek zijn naar inzichten en antwoorden en dat ze denken die bij jou te vinden, maar op dit moment is dat een slecht idee. Je hebt het nu echt nodig om op je Zelf te focussen, tot rust te komen en je te concentreren op de kosmische energieën en vibraties om je heen. Laat hun helende kracht op je inwerken. Ontspan je, genees, word weer geheel, één met het universum.</p>",
                    }
                },
                de : {
                    name: "Vier der Schwerter",
                    suit : "Schwerter",
                    element : "🜁 - Luft",
                    theme : "Die Kontrolle darüber übernehmen, was man denkt.",     
                    description : "Die Vier der Schwerter spricht über eine konstruktive Reflexion und sich die Zeit zu nehmen, die Dinge zu klären.",
                    questions : [ "Hat es Chaos in Ihrem Kopf?", "Welche Gedanken müssen noch bereinigt werden?", "Geben Sie sich Zeit, um Dinge zu bereinigen?", "Worüber könnten Sie in Ruhe nachdenken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für eine Pause (sei es freiwillig oder nicht), aber auch für Grenzen. Vielleicht brauchen Sie eine Pause, oder Umstände zwingen Sie zu einer Pause. Vielleicht gibt es auch jemanden, der wichtig für Sie ist und der einige Zeit für sich selbst oder für Sie braucht.</p><p>Wenn Sie es sind, dann ist es sehr wichtig, dass die Menschen in Ihrer Umgebung Ihre Grenzen respektieren und nicht versuchen, Sie zu überreden, es anders zu machen.</p><p>Denken Sie daran, wenn eine geliebte Person eine Pause braucht, die Grenzen dieser Person zu respektieren. Sie können sich sicher sein, dass sie gute Gründe hat, vorübergehend ein wenig Abstand zu nehmen.</p><p>Die „Vier der Schwerter“ sagt Ihnen, dass die Zeit für Sie gekommen ist, nein zu sagen und auch zu respektieren, wenn andere nein zu Ihnen sagen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer Beziehung sind fühlen Sie Sich weit entfernt davon. Sie haben Schwierigkeiten um mit Ihrem Partner in Verbindung zu geraten und in der Regel fühlen Sie Sich schlecht darüber. Auch wenn es natürlich wichtig ist, offen mit ihm / Ihr zu kommunizieren, müssen Sie Sich realisieren, dass es nicht an seine /Ihre Verantwortlichkeit ist, dass Sie sich besser über sich selbst und die Beziehung fühlen. Es liegt an Ihnen zu bestimmen und darüber nachzudenken, wie Sie Sich fühlen. Möglicherweise brauchen Sie nur etwas Zeit für Sich Selbst.</p><p>Wenn Sie derzeit alleine sind ist dies nicht der richtige Zeitpunkt um sich einen Partner zu suchen. Machen Sie eine Pause um zu entscheiden, welche Qualitäten Sie in einem Partner suchen. Seien Sie versichert, dass es gut investierte Zeit ist.</p>",
                        [GuidanceFields.WORK] : "<p>Sie können das Gefühl haben, dass die Dinge überhaupt nicht gehen so wie Sie möchten, dass sie gehen. Dennoch ist dies nicht der richtige Zeitpunkt, um zu versuchen, die Menschen in bestimmte Richtungen zu drängen. Immerhin brauchen bestimmte Prozesse Zeit. Es wäre viel besser, etwas Zeit zu nehmen, wenn Sie könnten, um Sich Selbst etwas wohlverdiente Ruhe und Erholung zu leisten.</p><p>Wenn Sie einen Job suchen, könnte es jetzt klug sein, für eine Weile keine Interviews zu planen. Sie sind gerade nicht die meist geduldige Person und dass könnte einen negativen Einfluss auf Ihre Chancen haben.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spirituell gesprochen brauchen Sie jetzt dringend einige Zeit für Sich allein. Möglicherweise möchten die Menschen um Sie gerne Ihr Gehirn mal nützen für Ihre eigene Einsichten oder um sich Antworten zu holen, aber es ist einfach nicht der richtige Zeitpunkt dafür. Sie müssen sich auf sich selbst konzentrieren, Sich Selbst beruhigen und dann in die kosmischen Energien und Schwingungen einsteigen. Fühlen Sie Ihre wohltuende Wirkung in Sich. Sie sollten ausruhen, heilen, und danach komplett wieder weiter gehen.</p>",
                    }
                }
            },
            {
                filename: "54",
                numerology : 5,  
                en : {
                    name: "Five of Swords",
                    suit : "Swords",
                    element : "🜁 - air",
                    theme : "own thinking",    
                    description : "Five of Swords talks about being conscious about your own opinion and the need to learn about the futility of fighting over it.",
                    questions : [ "Do you always need to be right?", "Do you think everything is a battle?", "Which battle has become obsolete?", "Which battle are you letting go of?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>If you draw the Five of Swords it is rather likely that you are not feeling positive at all. There seem to be a lot of things that you’re not happy with, possibly even some people who are rubbing you the wrong way. Even though it is important that you recognize these feelings and deal with the, it may be wise to avoid confrontations. For the moment you are not the most reasonable person and you may possibly break things that aren’t easily repaired. Before you confront someone it would be wise to count to 10, and then once more. Think long and hard, then only act on what you are feeling and thinking if it is truly unavoidable.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that there are serious issues in your relationship that need to be addressed. Maybe your partner is crossing boundaries in a way that is unacceptable for you. Possibly you are experiencing negative feelings such as hatred, anger, need for revenge. Try to stay focused on the problems at hand and ask yourself what is really the issue. If you are clear about that, it is time to talk to your partner. Maybe you can solve these problems together. </p><p>If you are single this may not be the right time to go looking for a partner because you are feeling frustrated and even angry about being on your own. Deal with your feelings first and only when you feel better about yourself and your situation, then you can go look for a partner.</p>",
                        [GuidanceFields.WORK] : "<p>The Five of Swords tells you that you are unhappy and frustrated where work is concerned. Things are absolutely not going your way. Maybe you could stop to consider what your role in the matter is. Possibly your frustration is seeping through in the comments you give, maybe you are too biased to think clearly. Before you act and get into an open conflict, it would be good to realize that this is a situation in which even the winner loses. Work through your negative emotions, let them go and try to be constructive.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card tells you that you may feel too secure, convinced of the fact that your spiritual path, findings and answers are the right ones. Possibly you are trying to convince the people around you that they have been wrong all the time and you are right. Remember that what fits you doesn’t necessarily fit everybody else. Be happy that you found your answers and allow others to keep looking for theirs.</p><p>It is also possible that you feel disconnected from spiritual truths you have been holding dear for a long time. This may be very unsettling but try to see it as a challenge for you to go out and look for new answers.</p>",
                    }
                },              
                fr : {
                    name : "Le Cinq d’ Épée",
                    suit : "Épées",
                    element :  "🜁 - air",
                    theme : "la propre pensée",    
                    description : "Le Cinq d'épée, nous parle d’être conscience ses propres opinions et de la capacité d'apprendre à ne pas (plus) mener de batailles inutiles.",
                    questions : ["Voulez-vous toujours avoir raison?", "Pensez-vous que vous devez toujours vous battre?", "Quel combat n'a plus de raison?", "Dans quel combat ne met-on plus d'énergie?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Si vous tirez le \"Cinq d'Épée\", il est plus probable que vous n'ayez plus aucun sentiment positif. Il semble y avoir beaucoup de choses dont vous n'êtes pas satisfait en ce moment. Il y a peut-être aussi des gens que vous ne confrontez pas de la bonne façon. Il est important que vous reconnaissiez ces sentiments et sachiez comment les gérer. Il peut maintenant être sage d'éviter la confrontation. En ce moment, vous n'êtes pas la personne la plus sensée et vous pourriez être capable de casser des choses qui ne sont pas faciles à réparer. Avant de rencontrer quelqu'un, il serait sage de compter jusqu'à dix et d'essayer à nouveau. Réfléchissez longuement et sérieusement et n'agissez en fonction de vos sentiments et de vos pensées, que lorsque c'est vraiment inévitable.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that there are serious issues in your relationship that need to be addressed. Maybe your partner is crossing boundaries in a way that is unacceptable for you. Possibly you are experiencing negative feelings such as hatred, anger, need for revenge. Try to stay focused on the problems at hand and ask yourself what is really the issue. If you are clear about that, it is time to talk to your partner. Maybe you can solve these problems together. </p><p>If you are single this may not be the right time to go looking for a partner because you are feeling frustrated and even angry about being on your own. Deal with your feelings first and only when you feel better about yourself and your situation, then you can go look for a partner.</p>",
                        [GuidanceFields.WORK] : "<p>The Five of Swords tells you that you are unhappy and frustrated where work is concerned. Things are absolutely not going your way. Maybe you could stop to consider what your role in the matter is. Possibly your frustration is seeping through in the comments you give, maybe you are too biased to think clearly. Before you act and get into an open conflict, it would be good to realize that this is a situation in which even the winner loses. Work through your negative emotions, let them go and try to be constructive.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card tells you that you may feel too secure, convinced of the fact that your spiritual path, findings and answers are the right ones. Possibly you are trying to convince the people around you that they have been wrong all the time and you are right. Remember that what fits you doesn’t necessarily fit everybody else. Be happy that you found your answers and allow others to keep looking for theirs.</p><p>It is also possible that you feel disconnected from spiritual truths you have been holding dear for a long time. This may be very unsettling but try to see it as a challenge for you to go out and look for new answers.</p>",
                    }
                },
                sp:{
                    name:"El Cinco de espadas",
                    suit: "Espadas",
                    element: "🜁 - aire",
                    theme: "tu pensamiento propio",    
                    description: "El Cinco de Espadas es sobre ser consciente de tu propia opinión y nos señala la habilidad de aprender a no pelear (nunca más) en una batalla innecesaria",
                    questions: ["¿Siempre quieres tener razón?", "¿Crees que siempre debes luchar?", "¿Qué lucha no tiene sentido nunca más", "¿En qué lucha ya no pones energía?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Si dibuja el Cinco de Espadas es bastante probable que no se siente del todo positiva. Parece que hay un montón de cosas con que usted no está contento, posiblemente, incluso algunas personas que le están rozando en una forma incorrecta. Aunque es importante que reconozca estos sentimientos y tratar con él, puede ser prudente evitar confrontaciones. Por el momento, usted no es la persona más razonable y  posiblemente puede romper las cosas que no se reparan fácilmente. Antes se enfrenta a alguien, sería conveniente contar hasta 10, y luego una vez más. Piense largo y duro, a continuación, sólo actúa sobre lo que está sintiendo y pensando si es verdaderamente inevitable.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                de : {
                    name: "Fünf der Schwerter",
                    suit : "Schwerter",
                    element : "🜁 - Luft",
                    theme : "Der eigene Gedanke",    
                    description : "Die Fünf der Schwerter spricht über das Bewusstsein seiner eigenen Meinungen und die Fähigkeit zu lernen, keine unnötigen Kämpfe (mehr) zu führen.",
                    questions : [ "Wollen Sie immer Recht haben?", "Denken Sie, Sie sollten immer kämpfen?", "Welcher Kampf hat keinen Sinn mehr?", "In welchen Kampf stecken wir keine Energie mehr?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Wenn Sie die „Fünf der Schwerter“ ziehen ist es eher wahrscheinlich, dass Sie überhaupt keine positiven Gefühle haben. Es scheint im Moment viele Dinge zu geben, mit denen Sie nicht zufrieden sind. Möglicherweise gibt es auch einige Menschen, die Ihnen nicht auf die richtige Weise begegnen. Es ist wichtig, dass Sie diese Gefühle erkennen und wissen, wie Sie damit umgehen sollen. Es kann jetzt klug sein, Konfrontationen zu vermeiden. Im Moment sind Sie nicht die vernünftigste Person, und Sie können möglicherweise Dinge kaputt machen, die nicht leicht zu reparieren sind.  Bevor Sie jemandem begegnen wäre es klug, bis zehn zu zählen, und es dann noch einmal zu versuchen. Denken Sie lange und gründlich nach und handeln Sie erst dann auf der Basis Ihrer Gefühle und Gedanken, wenn es wirklich unvermeidlich ist.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte sagt Ihnen, dass es ernsthafte Probleme in Ihrer Beziehung gibt, die Ihre Aufmerksamkeit bedürfen. Vielleicht hat Ihr Partner Grenzen überschritten in einer Weise, die für Sie nicht akzeptabel ist. Möglicherweise erleben Sie negative Gefühle wie Hass, Zorn, Bedürfnis nach Rache. Versuchen Sie Sich auf die tatsächlichen Probleme zu konzentrieren und fragen Sie Sich, was wirklich die Ursache ist. Wenn Sie Sich darüber klar sind, ist es Zeit mit Ihrem Partner zu sprechen. Vielleicht können Sie diese Probleme gemeinsam lösen.</p><p>Wenn Sie alleine sind, ist dies möglicherweise nicht der richtige Zeitpunkt um Sich einen Partner zu suchen, weil Sie frustriert und sogar wütend darüber sind, dass Sie alleine sind. Kommen Sie zuerst mal mit Ihren Gefühlen ins Klare und wenn Sie Sich besser fühlen in Ihrer Situation, dann können Sie ausgehen und Sich einen Partner suchen.</p>",
                        [GuidanceFields.WORK] : "<p>Die Fünf der Schwerter sagt Ihnen, dass Sie unglücklich und frustriert sind, wo es die Arbeit anbelangt. Die Dinge laufen absolut nicht, so wie Sie es gerne hätten. Vielleicht sollten Sie aufhören Sich zu fragen welche Rolle Sie in der Angelegenheit spielen. Möglicherweise sickert Ihre Frustration durch in die Kommentare, die Sie geben, vielleicht sind Sie zu voreingenommen um klar zu denken. Bevor Sie handeln und sich in einen offenen Konflikt begeben, wäre es gut zu erkennen, dass es sich hier um eine Situation handelt, in der sogar der Gewinner verliert. Arbeiten Sie sich durch Ihre negativen Gefühle, lassen Sie sie gehen und versuchen Sie konstruktiv zu sein.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Diese Karte sagt Ihnen, dass Sie Sich vielleicht zu sicher fühlen über die Tatsache, dass Ihr spiritueller Weg, Ihre Erkenntnisse und Antworten die einzig richtigen sind. Möglicherweise versuchen Sie die Menschen, um Sie herum zu überzeugen, dass sie es die ganze Zeit falsch gemacht haben und Sie haben immer recht gehabt. Denken Sie daran, dass das, was Ihnen passt, nicht unbedingt allen anderen auch passen muss. Seien Sie froh, dass Sie Ihre Antworten gefunden haben und gestatten Sie die Andere Ihre eigene Antworten zu suchen.</p><p>Es ist auch möglich, dass Sie Sich getrennt fühlen von geistigen Wahrheiten die Sie lange Zeit gehegt haben. Dies kann sehr beunruhigend sein, sondern versuchen Sie es, als eine Herausforderung für Sie zu sehen, um auf die Suche zu gehen nach neuen Antworten.</p>",
                    }
                },
                nl : {
                    name: "Zwaarden Vijf",
                    suit : "Zwaarden",
                    element : "🜁 - lucht",
                    theme : "eigen denken",    
                    description : "De Vijf van Zwaarden gaat over bewust zijn van je eigen mening en wijst ons op het kunnen leren geen onnodige strijd (meer) te voeren.",
                    questions : [ "Wil je altijd gelijk hebben?", "Denk jij dat je altijd moet strijden?", "Welke strijd heeft geen zin (meer)?", "In welke strijd stop jij geen energie meer?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Als je Zwaarden Vijf trekt dan is het goed mogelijk dat je je helemaal niet positief voelt. Het lijkt erop dat er nogal wat dingen gaande zijn in je leven waar je niet gelukkig mee bent. Mogelijk zijn er ook mensen in je omgeving die de dingen op zo’n manier aanpakken dat je er nerveus van wordt. Alhoewel het belangrijk is dat je deze gevoelens onderkent en dat je ze probeert aan te pakken, is het op dit moment waarschijnlijk het slimste om even geen confrontaties aan te gaan. Je bent tegenwoordig niet de meest redelijke persoon en als je mensen op hun gedrag aanspreekt is het mogelijk dat je brokken maakt die niet gauw gelijmd kunnen worden. Als je iemand ergens op wil aanspreken, haal dan eerst diep adem en tel tot 10, en dan nog eens. Denk er goed en diep over na en voer dat gesprek enkel en alleen als je zeker bent dat het onvermijdelijk is.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt je dat er in je relatie belangrijke knelpunten zijn die aangepakt moeten worden. Het is mogelijk dat je partner op zo’n manier over je grenzen heengaat dat je het niet meer kan accepteren. Hierdoor komen er bij jou misschien bepaalde negatieve gevoelens boven zoals boosheid, tegenzin, mogelijk zelfs een behoefte om wraak te nemen. Probeer je te concentreren op de problemen zelf en vraag je af wat nu de knelpunten zijn. Als je die eenmaal duidelijk hebt, kan je de volgende stap zetten en met je partner gaan praten. Het is best mogelijk dat je deze problemen samen kan oplossen.</p><p>Als je op dit moment single bent, dan is het waarschijnlijk niet de juiste tijd om iemand te vinden. Je voelt je nu immers gefrustreerd en misschien zelfs boos omdat je alleen bent. Pak deze gevoelens eerst aan, verwerk ze, geef ze een plek. Pas als je beter in je vel zit, als je jezelf en je situatie kan aanvaarden, pas dan is de tijd gekomen om op zoek te gaan naar een partner.</p>",
                        [GuidanceFields.WORK] : "<p>Zwaarden Vijf wijst je erop dat je niet gelukkig bent in je werksituatie, misschien ben je zelfs behoorlijk gefrustreerd. De dingen verlopen helemaal niet zoals jij het liefst zou willen. Misschien is het nu het juiste moment om er even bij stil te staan welke rol jij speelt in deze situatie. Misschien sijpelt je frustratie wel door in de commentaren die je geeft, mogelijk ben je op dit ogenblik iets te bevooroordeeld om echt helder en objectief te denken. Voor je de eerste stap zet in de richting van een conflict, is het misschien verstandig om te bedenken dat er in deze situatie waarschijnlijk geen winnaars zullen zijn, iedereen zal er iets bij inschieten. Pak je negatieve gevoelens aan, probeer ze te verwerken en los te laten. Eens je dat gedaan hebt, zal je merken dat je opnieuw opbouwend kan gaan denken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart vertelt je dat je op dit moment misschien wat te zelfverzekerd bent. Je bent ervan overtuigd dat jouw spirituele weg, je ontdekkingen en de antwoorden die je gevonden hebt de enige juiste zijn. Misschien probeer je zelfs de mensen om je heen ervan te overtuigen dat ze fout zitten en dat jij de goede kant aan het opgaan bent. Denk eraan dat wat past voor jou misschien niet past voor anderen, we zijn immers allemaal uniek en hebben daarom ook unieke antwoorden en inzichten.</p><p>Wees blij dat jij je antwoorden gevonden hebt en geef anderen de ruimte om die van hen te vinden.</p><p>Het is ook mogelijk dat je tegenwoordig het gevoel hebt dat je geen voeling meer hebt met de spirituele inzichten die zo lang heel belangrijk voor je waren. Dit kan je erg onzeker maken, je uit balans halen. Probeer ernaar te kijken als een uitdaging: trek erop uit en zoek nieuwe antwoorden en inzichten!</p>",
                    }
                },
            },
            {
                filename: "55",
                numerology : 6,
                en : {
                    name: "Six of Swords",
                    suit : "Swords",
                    element : "🜁 - air",
                    theme : "opting to follow your own opinion",    
                    description : "Six of Swords talks about consciously opting for your own opinion and to deliberately setting your own course.",
                    questions : [ "Can you opt for your own opinion?", "Despite what others might think?", "Which course are you willing to set?", "Can you freely opt for it?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Six of Swords usually indicates a, possibly small, change for the better. This change will always be associated with a move that might be literal or figurative. This doesn’t mean that your situation or the way you are feeling will suddenly be perfect, but it will be better than it has been.</p><p>This card also indicates that this may be the right time to travel or move, to change your circumstances, to get out of your comfort zone, to try and create your own spot somewhere else. In some ways you may be preparing to take a courageous step, leaving behind some things that are familiar to you, although it doesn’t necessarily mean you are leaving behind everything you know. Maybe you are just taking a short break to get closer to yourself and what you really want and need.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card signifies that you are ready for and maybe even need a change. If you are in a relationship, try to change the patterns you and your loved one are used to. Take him/her to different places, do different things, talk about other topics than you usually do. Change is good right now.</p><p>If you are single and looking for a relationship it is time to leave old patterns behind. Don’t keep looking for love in all the old and familiar places. Be adventurous and take paths you have never walked before, go to places that are new to you. You may be more successful than you anticipated!</p>",
                        [GuidanceFields.WORK] : "<p>Where work and career are concerned, you are entering a new phase, starting a new chapter. Maybe you are being assigned new tasks, getting a promotion or maybe you will even be starting a new job. It is also possible that you are simply finding new ways to look at your “old” job. One thing is for sure, you are leaving behind what feels familiar and taking on new challenges and experiences, you are growing. Ask yourself where you want to be, what your goals are and trust the people around you to help you get there.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In your spiritual quest you may have been asking yourself the same old questions over and over without really finding satisfying answers. Now is the time to stop asking questions and to start acting. Change your routines, stop reading the same old books and having the same old conversations. You could even change the way you meditate and visualize! This is a time for experimenting, for travelling through the spiritual realm. Also pay extra attention to your dreams, they have messages for you, they can show you things that aren’t clear when you are awake. </p>",
                    }
                },
                fr : {
                    name : "Le Six d’épée",
                    suit : "Épées",
                    element :  "🜁 - air",
                    theme : "choisir de suivre sa propre opinion",    
                    description : " Le Six d’épée, nous parle de choisir consciemment sa propre opinion et que l'on peut délibérément mettre le cap sur",
                    questions : ["Pouvez-vous choisir votre propre opinion?", "Peu importe ce que les autres pourraient penser?", "Quelle voie voulez-vous suivre?", "Pouvez-vous choisir cela consciemment?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Six d'Épée\" indique généralement un changement possiblement vers le mieux. Ce changement est toujours associé à un mouvement, le mouvement peut être littéral ou figuratif. Cela ne signifie pas que votre situation ou la façon dont vous vous sentez sera soudainement parfaite, mais elle sera meilleure qu'avant.</p><p>Cette carte indique également que c'est le bon moment pour voyager ou pour changer de situation, pour quitter votre zone de confort, pour essayer de trouver votre propre place ailleurs. D'une certaine façon, vous pourriez être prêt pour un pas en avant audacieux qui laissera derrière vous des choses familières. Même si cela ne signifie pas nécessairement que vous laisserez tout ce que vous savez derrière vous. Peut-être êtes-vous sur le point de faire une courte pause pour vous rapprocher de ce que vous voulez vraiment et de ce dont vous avez besoin.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card signifies that you are ready for and maybe even need a change. If you are in a relationship, try to change the patterns you and your loved one are used to. Take him/her to different places, do different things, talk about other topics than you usually do. Change is good right now.</p><p>If you are single and looking for a relationship it is time to leave old patterns behind. Don’t keep looking for love in all the old and familiar places. Be adventurous and take paths you have never walked before, go to places that are new to you. You may be more successful than you anticipated!</p>",
                        [GuidanceFields.WORK] : "<p>Where work and career are concerned, you are entering a new phase, starting a new chapter. Maybe you are being assigned new tasks, getting a promotion or maybe you will even be starting a new job. It is also possible that you are simply finding new ways to look at your “old” job. One thing is for sure, you are leaving behind what feels familiar and taking on new challenges and experiences, you are growing. Ask yourself where you want to be, what your goals are and trust the people around you to help you get there.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In your spiritual quest you may have been asking yourself the same old questions over and over without really finding satisfying answers. Now is the time to stop asking questions and to start acting. Change your routines, stop reading the same old books and having the same old conversations. You could even change the way you meditate and visualize! This is a time for experimenting, for travelling through the spiritual realm. Also pay extra attention to your dreams, they have messages for you, they can show you things that aren’t clear when you are awake. </p>",
                    }
                },
                sp:{
                    name:"El Seis de espadas",
                    suit: "Espadas",
                    element: "🜁 - aire",
                    theme: "elige seguir tu propia opinión",    
                    description: "El Seis de Espadas se trata de elegir conscientemente tu propia opinión y nos recuerda que podemos tomar un curso diferente" ,
                    questions: ["¿Puedes elegir tu propia opinión?", "Independientemente de lo que piensen los otros", "¿Qué curso quieres seguir?", "¿Puedes elegir conscientemente para eso?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Seis de Espadas suele indicar una, posiblemente pequeño, el cambio para mejor. Este cambio siempre estará asociado con un movimiento que podría ser literal o figurado. Esto no quiere decir que su situación o la forma en que se siente repentinamente serán perfectas, pero será mejor de lo que ha sido. Esta tarjeta también indica que este puede ser el momento adecuado para viajar o moverse, cambiar sus circunstancias, a salir de su zona de confort, para tratar de crear su propio lugar en otro lugar. En cierto modo, es posible que  esté preparando para dar un paso valiente, dejando atrás algunas cosas que son familiares para usted, aunque no significa necesariamente que usted está dejando atrás todo lo que sabes. Quizás esté sólo tomando un breve descanso para estar más cerca de usted y lo que realmente quiere y necesita.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Zwaarden Zes",
                    suit : "Zwaarden",
                    element : "🜁 - lucht",
                    theme : "kiezen om je eigen mening te volgen",    
                    description : "De Zes van Zwaarden gaat over bewust kiezen voor je eigen mening en wijst ons er op dat we doelbewust een koers in kunnen slaan.",
                    questions : [ "Kun jij kiezen voor je eigen mening?", "Ongeacht wat anderen er van zouden kunnen denken?", "Welke koers wil jij gaan?", "Kun jij daar bewust voor kiezen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Zwaarden Zes wijst je erop dat er binnenkort in je leven een (misschien kleine) verandering ten goede zal komen. Deze verbetering heeft altijd iets te maken met beweging, verplaatsing, al kan dit zowel letterlijk als figuurlijk zijn. Dit betekent niet automatisch dat je situatie en hoe jij je voelt ineens perfect zullen zijn, maar het zal wel een stukje beter gaan dan voorheen.</p><p>Deze kaart geeft ook een aanwijzing dat het nu een goede tijd is om op reis te gaan of te verhuizen, je te verplaatsen, je omstandigheden te veranderen. Nu is het moment gekomen om je buiten je comfortzone te begeven en ergens anders een plek te gaan zoeken waar je je goed kan voelen. Let op: dit kan nog steeds zowel letterlijk als figuurlijk zijn!</p><p>Op een bepaalde manier ben je je aan het voorbereiden om een moedige stap vooruit te zetten, om een aantal vertrouwde zaken achter je te laten. Dit betekent niet noodzakelijk dat je al het bekende definitief achterlaat. Het is ook mogelijk dat je gewoon een pauze neemt om dichter bij jezelf te komen en te ontdekken wat je echt wil en nodig hebt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt je dat je klaar bent voor verandering, misschien heb je die zelfs echt nodig op dit moment. Als je in een relatie zit, dan is het nu een goede tijd om een aantal patronen te doorbreken waar jullie aan gewend zijn. Zoek nieuwe plekken op, praat over andere onderwerpen, kies een andere soort films of boeken. Verandering is op dit ogenblik gewoon erg goed voor jullie, je zal er positieve resultaten mee boeken.</p><p>Als je single bent en op zoek naar een partner dan is ook voor jou de tijd rijp om oude gewoontes los te gaan laten. Ga de liefde niet meer zoeken op de bekende plekken en de bekende manieren. Wees avontuurlijk en sla wegen in die je totaal onbekend zijn. Was internetdaten nooit iets voor jou? Probeer het dan nu eens uit. Je zal merken dat je meer succes hebt dan je verwachtte met je nieuwe methodes!</p>",
                        [GuidanceFields.WORK] : "<p>Op het gebied van werk en carrière begin je nu aan een nieuwe fase, een nieuw hoofdstuk. Misschien krijg je nieuwe taken toegewezen, zit er een promotie in of verander je daadwerkelijk van baan. Het is ook mogelijk dat je volop nieuwe manieren aan het ontdekken bent om naar je ‘oude’ job te kijken. Eén ding is zeker, je laat een aantal vertrouwde zaken achter je en je gaat nu voor nieuwe uitdagingen en ervaringen, je bent volop aan het groeien. Vraag je nu af waar je heen wil, wat je wil bereiken, welke doelen je jezelf wil stellen. En vertrouw erop dat de mensen om je heen je willen ondersteunen bij deze evolutie.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>JMogelijk besef je op dit moment dat je in je spirituele zoektocht alsmaar dezelfde vragen aan het stellen bent en dat je er maar niet in slaagt om bevredigende antwoorden te vinden. Als dit het geval is, hou dan op met vragen stellen en ga actief aan de slag. Verander je gewoontes, stop met het raadplegen van alsmaar dezelfde boeken en sites, hou op met die gesprekken waarvan je het resultaat toch al kent. Verander ook eens je manier van mediteren en visualiseren. Het is een goed idee om nu aan het experimenteren te gaan, om onontgonnen terrein te verkennen, om eens nieuwe continenten te gaan bezoeken in de spirituele wereld! Besteed ook extra aandacht aan je dromen, je kan er boodschappen in vinden, ze kunnen je inzicht geven in zaken die je niet onderkent als je wakker bent.</p>",
                    }
                },
                de : {
                    name: "Sechs der Schwerter",
                    suit : "Schwerter",
                    element : "🜁 - Luft",
                    theme : "Beschliessen, seiner eigenen Meinung zu folgen.",    
                    description : "Die Sechs der Schwerter sagt uns, dass wir uns bewusst unsere eigene Meinung bilden sollen und dass wir bewusst einen Kurs setzen können.",
                    questions : [ "Können Sie sich eine eigene Meinung aussuchen?", "Egal, was andere denken mögen?", "Welchen Weg wollen Sie gehen?", "Können Sie das bewusst wählen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Sechs der Schwerter“ deuten gewöhnlich auf eine möglicherweise kleine Veränderung zum Besseren hin. Diese Veränderung wird immer mit einer Bewegung in Verbindung gebracht, die Bewegung kann entweder wörtlich oder bildlich sein. Dies bedeutet nicht, dass Ihre Lage oder die Art und Weise, wie Sie Sich fühlen, plötzlich perfekt sein wird, aber es wird besser sein als bisher.</p><p>Diese Karte zeigt auch an, dass dies der richtige Zeitpunkt ist, um zu reisen oder um Ihre Situation zu verändern, um Ihre Komfort-Zone zu verlassen, um zu versuchen, Ihren eigenen Platz irgendwo anders  zu finden. In gewisser Weise könnten Sie für einen mutigen Schritt vorwärts bereit sein, mit dem Sie ein paar vertraute Dinge zurücklassen. Auch wenn das nicht unbedingt bedeutet, dass Sie alles, was Sie kennen, zurücklassen werden. Vielleicht sind Sie gerade dabei, eine kurze Pause zu machen, um dem näher zu kommen, was Sie wirklich wollen und brauchen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte bedeutet, dass Sie bereit sind für eine Veränderung und diese vielleicht auch brauchen. Wenn Sie in einer Beziehung sind, versuchen Sie dann das Muster, das Sie und Ihre Liebste angehängt haben zu ändern. Nehmen Sie ihn / sie mit an verschiedene Orte, machen Sie andere Dinge, reden Sie über andere Themen, als wie Sie bis jetzt gemacht haben. Veränderung ist jetzt gut.</p><p>Wenn Sie alleine sind und auf der Suche nach einer Beziehung sind, ist es Zeit, um alte Muster hinter sich zu lassen. Suchen Sie nicht nach Liebe in all den alten und vertrauten Orten. Seien Sie abenteuerlich und nehmen Sie nie die Wege, die schon gegangen sind, gehen Sie zu Orten, die neu für Sie sind. Sie können erfolgreicher sein, als Sie erwartet haben!</p>",
                        [GuidanceFields.WORK] : "<p>Wo es Arbeit und Beruf betrifft, so sind Sie in eine neue Phase anbelangt, in ein neues Kapitel. Vielleicht hat man Ihnen neue Aufgaben zugewiesen, haben Sie eine Beförderung erhalten oder vielleicht werden Sie sogar in einer neuen Stellung anfangen. Es ist auch möglich, dass Sie einfach nur neue Wege suchen, um Ihren \"alten\" Job aus einem anderen Winkel anzusehen. Eines ist sicher, lassen Sie alles was vertraut fühlt hinter Sich und gehen Sie neue Herausforderungen und Erfahrungen ein, damit Sie wachsen. Fragen Sie sich, wo Sie sein möchten, was Ihre Ziele sind und vertrauen Sie die Menschen um Sie herum, um Ihnen zu helfen, dorthin zu gelangen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In Ihrem spirituellen Suche haben Sie Sich immer wieder die gleichen alten Fragen gestellt, ohne wirklich befriedigende Antworten zu finden. Jetzt ist die Zeit aufzuhören Fragen zu stellen und anzufangen zu handeln. Ändern Sie Ihre Routinen, hören Sie auf die gleichen alten Bücher zu lesen und die gleichen alten Gespräche zu führen. Sie könnten sogar die Weise wie Sie meditieren und visualisieren ändern! Dies ist eine Zeit zum Experimentieren, für eine Reise durch den spirituellen Bereich. Achten Sie auch besonders auf Ihre Träume, haben sie Nachrichten für Sie? Sie können ihnen Dinge zeigen die nicht klar sind, wenn Sie wach sind.</p>",
                    }
                }
            },
            {
                filename: "56",
                numerology : 7,
                en : {
                    name: "Seven of Swords",
                    suit : "Swords",
                    element : "🜁 - air",
                    theme : "follow your own course",    
                    description : "Seven of Swords talks about setting your own course and leaving ideas behind that no longer serve us.",
                    questions : [ "Are you following your own course?", "Which information can you consciously take with you?", "Can you do it decisively and light-heartedly?", "Can you be diplomatic?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about dishonesty in all its forms and appearances. Drawing this card may mean that either you are being badly treated or that you are doing the mistreating yourself. The dishonesty can range from lying over deceit to actual theft or sabotage. If you draw the Seven of Swords it is important to be watchful and to question people’s motives. But it is also important to look at and question your own motives because you may be the dishonest one right now.</p><p>And maybe the worst case scenario is true and you are deceiving and being deceived at the same time.</p><p>No matter how the situation turns out to be, the time has come to get rid of all the secrecy in your life.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a relationship at this moment, the time has come to ask yourself some tough questions: do you trust your partner, are you having doubts or hesitations, can you yourself be trusted or are you wandering? It is important for you to realize that these questions need to be answered. Engage in an open and honest conversation with your partner, ask the hard questions and answer them if your partner has some of those too. Now is the time to be honest, it can save your relationship. If you are alone and looking for a partner, make sure you are honest and open, don’t play games, don’t act uninterested if the opposite is true. You could lose the perfect partner just because you aren’t being real.</p>",
                        [GuidanceFields.WORK] : "<p>Prepare yourself for some hard times at work because it is possible your boss is taking a very close look at what you do and how you do it. Make sure all you do is above reproach, be the very best you can be. Try not to get mixed up in gossip and conflicts, do not choose sides, be your own person. Be as neutral as you possibly can be. If you are looking for work right now, it may be interesting to approach former colleagues and old friends or contacts, they may have a good lead for you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may be searching right now, you may feel a certain spiritual restlessness. If you are looking for answers, be sure to look for them in the right places. Don’t be tempted to listen to so-called spiritual leaders, don’t take that expensive course, don’t buy those books. Now is the right time to turn inward, to look inside and realize how much knowledge you have already gathered. Work through that knowledge, let it blossom and grow and pick the fruits of your labor. You can teach yourself many valuable lessons right now! </p>",
                    }
                },
                fr : {
                    name : "Le Sept Épée",
                    suit : "Épées",
                    element :  "🜁 - air",
                    theme : "suivre sa propre conviction",    
                    description : " Le Sept Épée, nous parle de suivre notre propre conviction et de la possibilité de laisser derrière nous les opinions qui ne nous conviennent plus.",
                    questions : ["Suivez-vous votre propre conviction?", "Que pouvez-vous délibérément en apprendre?", "Pouvez-vous le faire avec détermination et grâce?", "Pouvez-vous être diplomate?"],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente la malhonnêteté dans toutes ses manifestations. Tirer cette carte peut signifier que vous êtes maltraité ou que vous faites du mal à quelqu'un. La malhonnêteté pourrait être liée à des choses comme la fraude ou même le vol et le sabotage. Si vous tirez le \"Sept d'épée\", il est important d'être vigilant et de remettre en question les motivations des gens. Mais il est également important d'être conscient de vos propres motifs et de les vérifier, car vous pouvez être vous-même le plus malhonnête en ce moment.</p><p>Et peut-être que le pire des cas arrivera et que vous vous séduirez et serez trompé en même temps.</p><p>Quelle que soit la situation, le moment est venu de vous libérer de tous les secrets de votre vie.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a relationship at this moment, the time has come to ask yourself some tough questions: do you trust your partner, are you having doubts or hesitations, can you yourself be trusted or are you wandering? It is important for you to realize that these questions need to be answered. Engage in an open and honest conversation with your partner, ask the hard questions and answer them if your partner has some of those too. Now is the time to be honest, it can save your relationship. If you are alone and looking for a partner, make sure you are honest and open, don’t play games, don’t act uninterested if the opposite is true. You could lose the perfect partner just because you aren’t being real.</p>",
                        [GuidanceFields.WORK] : "<p>Prepare yourself for some hard times at work because it is possible your boss is taking a very close look at what you do and how you do it. Make sure all you do is above reproach, be the very best you can be. Try not to get mixed up in gossip and conflicts, do not choose sides, be your own person. Be as neutral as you possibly can be. If you are looking for work right now, it may be interesting to approach former colleagues and old friends or contacts, they may have a good lead for you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may be searching right now, you may feel a certain spiritual restlessness. If you are looking for answers, be sure to look for them in the right places. Don’t be tempted to listen to so-called spiritual leaders, don’t take that expensive course, don’t buy those books. Now is the right time to turn inward, to look inside and realize how much knowledge you have already gathered. Work through that knowledge, let it blossom and grow and pick the fruits of your labor. You can teach yourself many valuable lessons right now! </p>",
                    }
                },
                sp:{
                    name:"El Siete de Espadas",
                    suit: "Espadas",
                    element: "🜁 - aire",
                    theme: "sigues tu propia opinión",    
                    description: "El Siete de Espadas trata de seguir tu propia opinión y nos señala la capacidad de dejar atrás aquellas opiniones que ya no nos convienen",
                    questions: ["¿Sigues tu propia opinión?", "¿Qué puedes llevarte a propósito?", "¿Puedes hacerlo con decisión y ligereza?", "¿Puedes ser diplomático?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es la falta de honradez en todas sus formas y apariencias. Sacando esta tarjeta puede significar que o bien está siendo tratado mal o que está haciendo el maltratar a su mismo. La falta de honradez puede variar de mentir sobre el engaño al robo real o sabotaje. Si saca el Siete de Espadas es importante estar atentos y cuestionar los motivos de la gente. Pero también es importante tener en cuenta y cuestionar sus propios motivos, ya que puede ser el deshonesto en este momento. Y tal vez el peor de los casos es cierto y usted está engañando y siendo engañados al mismo tiempo. No importa cómo la situación resulta ser, ha llegado el momento de deshacerse de todo el secreto en su vida.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Zwaarden Zeven",
                    suit : "Zwaarden",
                    element : "🜁 - lucht",
                    theme : "je eigen mening volgen",    
                    description : "De Zeven van Zwaarden gaat over het volgen van je eigen mening en wijst ons op het achter ons kunnen laten van die opvattingen die niet meer bij ons passen.",
                    questions : [ "Volg jij je eigen mening?", "Wat kun jij doelbewust meenemen?", "Kun jij dat met daadkracht en lichtvoetig doen?", "Kun jij diplomatiek zijn?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart heeft het over oneerlijkheid in alle mogelijke vormen en verschijningen. Als je Zwaarden Zeven trekt dan is het goed mogelijk dat je op dit moment heel slecht behandeld wordt of dat jij dit aan het doen bent met een ander. De oneerlijkheid waarvan sprake kan gaan van liegen over bedrog tot sabotage en manipulatie. Deze kaart vertelt je dat het belangrijk is om aandachtig en alert te zijn en je altijd vragen te stellen in verband met de motieven van anderen. Het is ook belangrijk dat je aandacht besteedt aan jezelf en jouw motieven want misschien ben jij zelf ook niet echt eerlijk bezig op dit moment.</p><p>In het allerslechtste geval kan het zo zijn dat jij zelf oneerlijk bent en dat iemand anders ook oneerlijk is ten opzichte van jou.</p><p>Maar eigenlijk maakt het niet uit hoe ingewikkeld of eenvoudig de situatie in mekaar zit, het gaat erom dat je er nu, op dit moment, voor moet zorgen dat er geen oneerlijkheid, onduidelijkheid of geheimen meer zijn in je leven.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een relatie hebt dan is de tijd gekomen om jezelf wat moeilijke vragen te stellen: vertrouw jij je partner, heb je twijfels of vragen, ben je zelf betrouwbaar bezig of wijk je af van het rechte pad? Het is belangrijk om aan jezelf toe te geven dat deze en andere vragen echt gesteld en beantwoord moeten worden. Ga een open en eerlijk gesprek aan met je partner, stel die moeilijke vragen en beantwoord ze ook eerlijk als je partner ze aan jou stelt. Nu komt het erop aan om open kaart te spelen, het kan je relatie redden!</p><p>Als je alleen bent en op zoek naar een relatie, zorg er dan voor dat je dit open en eerlijk doet. Speel geen spelletjes, doe niet alsof iemand je niet interesseert als het omgekeerde net waar is. Je zou de ideale partner kunnen missen omdat je niet oprecht overkomt.</p>",
                        [GuidanceFields.WORK] : "<p>In je werksituatie is het op dit moment verstandig om je voor te bereiden op mogelijk zware tijden. Het zit erin dat je meerdere van plan is om je werkmethodes en resultaten van heel nabij te onderzoeken. Zorg ervoor dat alles in orde is, dat zij/hij zo weinig mogelijk op- of aanmerkingen kan maken. Houd zoveel mogelijk afstand van roddel en conflicten, kies niemands kant, houd je bij jezelf. Wees op alle vlakken zo neutraal mogelijk.</p><p>Als je op zoek bent naar werk is het nu een goede tijd om vroegere collega’s en oude contacten te benaderen, je zou er behoorlijk wat kansen mee kunnen creëren.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Waarschijnlijk ben je op dit moment zoekende, voel je een soort spirituele rusteloosheid. Als je op zoek bent naar antwoorden zorg er dan voor dat je dit op de juiste plekken doet. Laat je niet verleiden tot het luisteren naar zogenaamde spirituele leiders, laat die dure cursus of workshop links liggen, koop die boeken die je ontdekt hebt niet. Besteed je tijd nu aan het focussen op jezelf, keer je naar je binnenste, je diepste kern en besef ten volle hoeveel kennis je al vergaard hebt. Verwerk al deze wijsheid, laat ze groeien en bloeien en pluk de vruchten van het harde werk dat je in het verleden verzet hebt. Op dit ogenblik kan je jezelf vele waardevolle lessen leren!</p>",
                    }
                },
                de : {
                    name: "Sieben der Schwerter",
                    suit : "Schwerter",
                    element : "🜁 - Luft",
                    theme : "Seiner eigenen Überzeugung folgen",    
                    description : "Die Sieben der Schwerter spricht darüber, seiner eigenen inneren Überzeugung zu folgen und die Möglichkeit offen zu halten, die Meinungen hinter uns zu lassen,  die uns nicht mehr passen.",
                    questions : [ "Folgen Sie Ihrer eigenen Überzeugung?", "Was können Sie bewusst daraus lernen?", "Können Sie es mit Entschlossenheit und Anmut tun?", "Können Sie diplomatisch sein?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für Unehrlichkeit in allen seinen Erscheinungsformen. Das Ziehen dieser Karte kann bedeuten, dass Sie entweder schlecht behandelt werden, oder dass Sie selbst jemandem Unrecht tun. Die Unehrlichkeit könnte sich auf Dinge wie Betrug oder sogar auf Diebstahl und Sabotage beziehen. Wenn Sie die „Sieben der Schwerter“ ziehen ist es wichtig, wachsam zu sein und die Motive der Menschen in Frage zu stellen. Aber es ist auch wichtig, sich Ihrer eigenen Motive bewusst zu werden und zu überprüfen, da Sie doch selbst in diesem Augenblick der Unehrliche sein könnten.</p><p>Und vielleicht tritt der schlimmste Fall ein und Sie betrügen und werden  gleichzeitig selber betrogen.</p><p>Gleichgültig, wie die Situation ist, jetzt ist die Zeit gekommen, um sich von aller Geheimniskrämerei in Ihrem Leben zu befreien.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie zu diesem Zeitpunkt in einer Beziehung sind, ist die Zeit gekommen, sich einige schwierige Fragen zu fragen: Können Sie Ihren Partner vertrauen. Haben Sie Zweifel oder Bedenken, können Sie sich trauen oder sind Sie beim Wandern? Es ist wichtig, dass Sie erkennen, dass diese Fragen beantwortet werden müssen. Gehen Sie ein offenes und ehrliches Gespräch mit Ihrem Partner ein, fragen Sie die harten Fragen und beantworten Sie sie, wenn Ihr Partner auch einige hat. Jetzt ist die Zeit, um ehrlich zu sein, es kann Ihre Beziehung retten. Wenn Sie alleine und auf der Suche nach einem Partner sind, müssen Sie sicher sein, dass Sie ehrlich und offen sind. Sie sollten nicht spielen, nicht uninteressiert handeln, wenn das Gegenteil wahr ist. Sie könnten den perfekten Partner verlieren, nur weil Sie nicht realistisch sind.</p>",
                        [GuidanceFields.WORK] : "<p>Bereiten Sie sich auf harte Zeiten bei der Arbeit vor, weil es möglich ist, dass Ihr Vorgesetzter genau im Auge behält was Sie tun und wie Sie es tun. Sorgen Sie dafür, dass alles, was Sie tun über jeden Zweifel erhoben ist, machen Sie Ihres Bestes. Versuchen Sie nicht, Sich in Klatsch und Konflikte einzumischen, wählen Sie keine Seiten in eigener Person. Seien Sie so neutral wie Sie nur sein können. Wenn Sie jetzt nach Arbeit suchen, könnte es nützlich sein Kontakt mit ehemaligen Kollegen und alten Freunden aufzunehmen. Sie könnten eine gute Führung für Sie haben.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie können jetzt suchende sein, können eine gewisse spirituelle Unruhe fühlen. Wenn Sie auf der Suche nach Antworten sind, stellen Sie sicher, dass Sie an den richtigen Stellen suchen. Lassen Sie Sich nicht verführen diese sogenannten spirituellen Führer anzuhören, folgen Sie nicht diesen teureren Lehrgang und kaufen Sie nicht diese teureren Bücher. Jetzt ist der richtige Zeitpunkt, um Sich nach innen zu wenden, nach innen zu schauen und zu erkennen, wie viel Wissen Sie bereits gesammelt haben. Arbeiten Sie sich durch dieses Wissen, lassen Sie es blühen und wachsen und ernten Sie die Früchte Ihrer Arbeit. Sie können Sich Selbst viele wertvolle Lektionen lernen, gerade jetzt!</p>",
                    }
                }
            },
            {
                filename: "57",
                numerology : 8,
                en : {
                    name: "Eight of Swords",
                    suit : "Swords",
                    element : "🜁 - air",
                    theme : "taking responsibility for what you think",    
                    description : "Eight of Swords talks about taking responsibility for what we think and that we can either block or detach ourselves. Cause and effect.",
                    questions : [ "Are you thinking you cannot get there?", "Are you sabotaging yourself by your thoughts?", "Can you detach from it?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Eight of Swords is about being stuck and generally you are stuck because of your own doubts and fears. You are not allowing yourself to evolve, to grow, to change. There are oppurtunities and chances waiting for you and you are standing still, afraid to move towards them. You are being dictated by “Yes, but …”.</p><p>Now is the time to confront yourself with your fears and doubts and to find out where they are coming from. You will probably see that there is no need for them, that you are holding yourself back because of an illusion, like the monsters you thought were living under your bed when you were small.</p><p>Try to answer the question why you don’t allow yourself to completely be the person you could and should be, without paralyzing inhibitions or fears.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a relationship, now is the time to ask yourself: why am I here, with this person? Maybe you are staying in this relationship because you are afraid of being lonely. Maybe you feel insecure, not strong enough to do it all by yourself. Or maybe you think you can’t do better, as if this is as good as it gets even if it’s not really good. If you recognize one of these motives, you should thoroughly evaluate your relationship and decide if you really and truly want to be in it. Being alone isn’t that bad, and it sure is better than staying in a relationship you don’t believe in.</p><p>If you are single right now, take a moment to evaluate your life for yourself: do you really like yourself, are you happy with who you are? If the answer is yes, you are ready for a relationship. </p>",
                        [GuidanceFields.WORK] : "<p>The time has come to honestly and openly look at yourself and your job or career and ask yourself if you really want to stay where you are right now. Do you love your job? Do you wake up smiling every morning because you are looking forward to going to work? Or are you dragging your feet, wishing you could stay in bed, wondering why you are doing this job anyway? If you are unhappy with your work, you should realize and accept that you are the only one who has the power to change this situation. The decision is yours: either you stay where you are and accept it for what it is, or you go out and find another job. What will it be?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may be feeling insecure, in doubt, wondering who to be and what to feel. You may have a lot of questions, not knowing where to find the right answers. The answers you are looking for are to be found within yourself, you have all the keys. Try to connect to the universal energy by meditating, being alone with yourself, concentrating on your core being. Be open to signs and remember those signs may be as mundane as hearing a song on the radio or seeing the same number everywhere. Focus on the fact that the only one holding you back right now is you. Allow those insights in!</p>",
                    }
                },
                fr : {
                    name : "Le Huit d’Épée",
                    suit : "Épées",
                    element :  "🜁 - air",
                    theme : " Prendre la responsabilité de ce que l'on pense",    
                    description : " Le Huit d’Épée, nous parle de prendre la responsabilité de ce que l’on pense et que nous pouvons nous enfermer ou nous libérer. Cause & Conséquence",
                    questions : ["Etes-vous fixés dans votre pensée?", "Vous enfermez-vous dans vos  pensées?", "Pouvez-vous vous en détacher?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Huit d'Épée\" souligne que vous vous bloquez habituellement par vos propres doutes et peurs. Vous ne vous permettez pas de vous développer, de grandir, de changer. Il y a des possibilités et des opportunités qui vous attendent, mais vous restez immobile parce que vous avez peur d'aller vers elles. Vous êtes dominé par \"Oui, mais ....\".</p><p>C'est le moment de faire face à vos peurs et à vos doutes et de découvrir d'où ils viennent. Vous constaterez probablement qu'il n'y a aucune raison pour eux de se retenir à cause d'une illusion qui est comme un monstre qu'ils croyaient quand ils étaient enfants et qui vivaient sous votre lit.</p><p>Essayez de répondre à la question suivante : Pourquoi ne pas vous permettre d'être la personne que vous pourriez et devriez être sans paralyser les inhibitions et les peurs?</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a relationship, now is the time to ask yourself: why am I here, with this person? Maybe you are staying in this relationship because you are afraid of being lonely. Maybe you feel insecure, not strong enough to do it all by yourself. Or maybe you think you can’t do better, as if this is as good as it gets even if it’s not really good. If you recognize one of these motives, you should thoroughly evaluate your relationship and decide if you really and truly want to be in it. Being alone isn’t that bad, and it sure is better than staying in a relationship you don’t believe in.</p><p>If you are single right now, take a moment to evaluate your life for yourself: do you really like yourself, are you happy with who you are? If the answer is yes, you are ready for a relationship. </p>",
                        [GuidanceFields.WORK] : "<p>The time has come to honestly and openly look at yourself and your job or career and ask yourself if you really want to stay where you are right now. Do you love your job? Do you wake up smiling every morning because you are looking forward to going to work? Or are you dragging your feet, wishing you could stay in bed, wondering why you are doing this job anyway? If you are unhappy with your work, you should realize and accept that you are the only one who has the power to change this situation. The decision is yours: either you stay where you are and accept it for what it is, or you go out and find another job. What will it be?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may be feeling insecure, in doubt, wondering who to be and what to feel. You may have a lot of questions, not knowing where to find the right answers. The answers you are looking for are to be found within yourself, you have all the keys. Try to connect to the universal energy by meditating, being alone with yourself, concentrating on your core being. Be open to signs and remember those signs may be as mundane as hearing a song on the radio or seeing the same number everywhere. Focus on the fact that the only one holding you back right now is you. Allow those insights in!</p>",
                    }
                },
                sp:{
                    name:"El Ocho de Espadas",
                    suit: "Espadas",
                    element: "🜁 - aire",
                    theme: "Asumir la responsabilidad de lo que piensas",    
                    description: "El Ocho de espadas se trata de asumir la responsabilidad de lo que piensas y nos recuerda que podemos encerrarnos y liberarnos. Causa y Consecuencia",
                    questions: ["¿Crees que no puedes hacerlo de todos modos?", "¿Te encierras con lo que piensas?", "¿Puedes desconectarte de eso?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La Ocho de Espadas es acerca de ser atrapado y generalmente usted está atascado debido a sus propias dudas y temores. No se permiten evolucionar, de crecer, de cambiar. Hay oportunidades y posibilidades que le esperan y están de pie todavía, miedo de moverse hacia ellos. Usted está siendo dictado por \"Sí, pero ...\". Ahora es el momento de enfrentarse a su mismo con sus miedos y dudas y para averiguar dónde están viniendo. Es probable que vea que no hay necesidad de ellos, que tiene en sus manos a su mismo de nuevo a causa de una ilusión, como los monstruos que pensaba vivían debajo de tu cama cuando eras pequeño. Trate de responder a la pregunta de por qué no permite ser completamente a la persona que podría y debería ser, sin paralizar inhibiciones ni temores.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Zwaarden Acht",
                    suit : "Zwaarden",
                    element : "🜁 - lucht",
                    theme : "verantwoording nemen voor wat je denkt",    
                    description : "De Acht van Zwaarden gaat over verantwoording nemen voor wat je denkt en wijst ons er op dat we ons zelf én vast kunnen zetten én los kunnen maken. Oorzaak & Gevolg.",
                    questions : [ "Denk jij dat je het toch niet kan?", "Zet jij jezelf vast door dat wat je denkt?", "Kun jij je daarvan losmaken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Acht van Zwaarden heeft het over vastzitten. Over het algemeen word je geblokkeerd door je eigen twijfels en angsten. Je staat jezelf niet toe om te groeien, te evolueren, te veranderen. De mogelijkheden en kansen lachen je toe en jij blijft stilstaan. Je bent bang om ze te grijpen, je wordt overheerst door “Ja maar ...”.</p><p>De tijd is nu rijp om jezelf te confronteren met je angsten en twijfels en je af te vragen waar ze vandaan komen. Het is heel waarschijnlijk dat je ontdekt dat ze nergens voor nodig zijn, dat je jezelf tegenhoudt vanwege een fantasie, een illusie. Het is net als toen je klein was en je echt geloofde dat er monsters onder je bed of in de kast zaten.</p><p>Probeer de volgende vraag te beantwoorden: waarom sta jij jezelf niet toe om helemaal die persoon te zijn die je zou kunnen en moeten zijn? Waarom sta jij jezelf toe om verlamd te zijn door angsten en onzekerheden?</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je een partner hebt, stel jezelf dan de volgende vraag: waarom ben ik hier, met deze persoon? Misschien blijf je in deze relatie zitten omdat je bang bent voor eenzaamheid. Misschien voel je je onzeker, vrees je ervoor dat je niet sterk genoeg bent om het alleen te redden. Of misschien denk je dat je niets beters kan krijgen, dat dit het best mogelijke is, ook al is het niet echt fantastisch. Als je één van deze motieven herkent bij jezelf, evalueer je relatie dan eens grondig, beslis nu voor eens en altijd of dit het is wat je echt wil. Alleen zijn is helemaal niet zo erg, en het is zeker beter dan blijven hangen in een relatie die je eigenlijk niet wil en waar je niet in gelooft.</p><p>Als je alleen bent, neem dan eens de tijd om je huidige leven grondig te evalueren: vind jij jezelf echt ok, ben je gelukkig met wie je bent? Als het antwoord ja is, dan ben je er klaar voor om op zoek te gaan naar een partner.</p>",
                        [GuidanceFields.WORK] : "<p>Neem nu even de tijd om jezelf, je baan en je carrière open en eerlijk te bekijken en je dan de vraag te stellen of je echt wel op deze plek wil zijn en ook blijven. Houd je van je baan? Word je ‘s ochtends vrolijk wakker en ben je blij dat je aan de slag kan gaan? Of sleep je jezelf naar het werk, en zou je eigenlijk liever in bed blijven liggen, vraag je je elke dag af waarom je deze job ook alweer gekozen hebt? Als je niet gelukkig bent met je baan dan is het belangrijk dat je beseft en ook aanvaardt dat jij de enige bent die de macht heeft om deze situatie te veranderen. Het is jouw beslissing: of je blijft waar je nu bent en je aanvaardt het zoals het is met alles erop en eraan, of je gaat op zoek naar een andere baan? Wat is jouw keuze?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het is best mogelijk dat je je wat onzeker voelt, dat je twijfels hebt, je afvraagt wie je wil zijn en wat je wil voelen. Je zit misschien vol met vragen en je weet niet goed waar je de juiste antwoorden moet gaan zoeken. En net deze antwoorden kan je enkel binnenin jezelf vinden. <strong>Jij</strong> hebt alle sleutels in handen. Probeer een verbinding tot stand te brengen met de universele energie, ga mediteren, wees alleen met jezelf, concentreer je op je diepste innerlijke kern. Sta open voor tekens en signalen en denk eraan dat deze tekens zo simpel kunnen zijn als een bepaald liedje horen op de radio of hetzelfde cijfer overal zien. Concentreer je op het gegeven dat jij zelf de enige bent die je tegenhoudt. Laat dat inzicht binnenstromen, geef het de ruimte!</p>",
                    }
                },
                de : {
                    name: "Acht der Schwerter",
                    suit : "Schwerter",
                    element : "🜁 - Luft",
                    theme : "Die Verantwortung dafür übernehmen, was man denkt.",    
                    description : "Die Acht der Schwerter spricht darüber, die Verantwortung für das übernehmen, was wir denken und dass wir uns entweder einschließen oder befreien können. Ursache & Wirkung",
                    questions : [ "Sind Sie in Ihren Gedanken fixiert?", "Verschliessen Sie sich in Ihren Gedanken?", "Können Sie sich von ihnen lösen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Acht der Schwerter“ verweist darauf, dass Sie sich in der Regel durch Ihre eigenen Zweifel und Ihre eigenen Ängste blockieren. Sie erlauben es sich nicht sich zu entwickeln, zu wachsen, sich zu verändern. Es gibt Möglichkeiten und Chancen, die auf Sie warten, aber Sie stehen still, weil Sie Angst davor haben, sich auf sie zu zubewegen. Sie werden beherrscht vom \"Ja, aber ...\".</p><p>Jetzt ist die Zeit gekommen, um sich mit Ihren Ängsten und Zweifeln auseinanderzusetzen und um herauszufinden, woher sie kommen. Sie werden wahrscheinlich feststellen, dass es keinen Grund für sie gibt, dass Sie sich selbst zurück halten wegen einer Illusion, die wie ein Monster ist, von dem sie als Kind geglaubt haben, dass es unter Ihrem Bett lebt.</p><p>Versuchen Sie, folgende Frage zu beantworten: Warum gestatten Sie sich selbst nicht, die Person zu sein, die Sie sein könnten und sollten, ohne lähmende Hemmungen und Ängste?</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer Beziehung sind, ist jetzt die Zeit, sich zu fragen: warum bin ich hier, mit diesem Menschen? Vielleicht wollen Sie in dieser Beziehung bleiben, weil Sie Angst haben, einsam zu sein. Vielleicht fühlen Sie Sich unsicher, nicht stark genug, um alles alleine zu tun. Oder Sie denken, es könnte nicht mehr werden als jetzt, auch wenn es eigentlich nicht wirklich gut geht. Wenn Sie eines dieser Motive erkennen, sollten Sie sorgfältig Ihre Beziehung überprüfen und entscheiden, ob Sie wirklich und wahrhaftig in dieser Beziehung sein wollen. Allein zu sein ist nicht so schlimm, und es ist immerhin besser als in einer Beziehung zu sein an der Sie nicht glauben.</p><p>Wenn Sie jetzt alleine sind, nehmen Sie sich dann einen Moment Zeit, um Ihr Leben für Sich Selbst zu beurteilen: mögen Sie Sich Selbst gerne, sind Sie mit Sich Selbst zufrieden? Wenn die Antwort ja ist, sind Sie bereit eine Beziehung einzugehen.</p>",
                        [GuidanceFields.WORK] : "<p>Die Zeit ist gekommen, um offen und ehrlich zu Sich Selbst und Ihrem Job oder Karriere zu schauen und Sich zu fragen, ob Sie wirklich bleiben wollen, wo Sie gerade sind. Lieben Sie Ihre Arbeit? Wachen Sie jeden Morgen lächelnd auf, weil Sie Sich bereits freuen auf dem Weg zur Arbeit? Oder wünschten Sie, Sie könnten im Bett bleiben, fragen Sie Sich, warum Sie diese Arbeit überhaupt tun? Wenn Sie mit Ihrer Arbeit zufrieden sind, sollten Sie wissen und akzeptieren, dass Sie der Einzige sind, der die Macht hat, diese Situation zu ändern. Die Entscheidung liegt bei Ihnen: Entweder Sie bleiben, wo Sie sind und Sie akzeptieren es so wie es ist, oder Sie suchen Sich einen anderen Job. Was wird es sein?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Möglicherweise fühlen Sie Sich unsicher, fragen Sie Sich in Verzweiflung, wer Sie sind und was Sie fühlen. Sie könnten eine Menge Fragen haben, ohne zu wissen, wo Sie die richtigen Antworten finden könnten. Die Antworten, die Sie suchen sind in Ihnen Selbst, Sie besitzen alle Schlüssel. Versuchen Sie, mit der universellen Energie durch Meditation in Verbindung zu kommen, allein zu sein mit Sich Selbst, konzentrieren Sie Sich auf Ihren Kern. Seien Sie offen für Zeichen und denken Sie daran, dass dieses Zeichen so banal sein kann als das Hören eines Liedes im Radio oder die ganze Zeit die gleiche Zahl sehen. Konzentrieren Sie Sich auf der Tatsache, dass der einzige der im Moment, zurückhält sie selber sind. Laden Sie diese Erkenntnisse ein!</p>",
                    }
                }
            },
            {
                filename: "58",
                numerology : 9,
                en : {
                    name: "Nine of Swords",
                    suit : "Swords",
                    element : "🜁 - air",
                    theme : "knowing what you think",    
                    description : "Nine of Swords talks about being conscious about what we think and the power to bring peace into it ourselves.",
                    questions : [ "Are you brooding a lot?", "Does it keep you awake?", "Can you appease your mind?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Nine of Swords is about sleepless nights caused by worrying and anxiety. You have a lot on your mind and you are feeling really down. Maybe you even have a guilty conscience that is keeping you awake at night.</p><p>Chances are that you are looking for people and circumstances that may or may not cause the way you are feeling. Forget about the rest of the world and focus on yourself and what you can do to change the way you are feeling. Even if there is a relationship or situation that causes you grief, it is up to you to analyze what is happening and what you can do to change it.</p><p>Maybe the way life feels right now makes you think “Is this all there is, shouldn’t there be more?”. If this is the case, try and concentrate on the small things that are going right, that can give you joy and inner peace. Often the beauty of life lies in the ordinary things.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Nine of Swords is about doubts and hesitations. Are you afraid of being left behind? Or are you considering to break off a relationship before your partner can do so? Or maybe you have a feeling that something is not quite right but you’re afraid to ask. Now is not the time to ignore your gut feelings. On the contrary, you should act on them. Talk to your partner, ask those difficult questions, insist on getting answers. But don’t stay suspicious once you get those answers. If you can’t trust your partner to answer honestly, then maybe you shouldn’t be together.</p><p>If you have just started a relationship, it is important to accept that being able to really trust someone takes time. Give yourself a chance to let the relationship and the trust grow slowly and steadily.</p>",
                        [GuidanceFields.WORK] : "<p>You may be feeling depressed and down. Are these feelings caused by the contents of your job, or are you having trouble with a colleague or a superior? Or maybe you feel paralyzed by fear of failure? Where work is concerned there are many factors you can’t control: you can’t change your colleagues, you can’t assign a new boss, you can’t choose your tasks. But there are still a few things you do control, and now it’s time to start focusing on those. Let your worries and fears go, leave them behind and focus on the things you can change.</p><p>If you are looking for a job now is a good time to find some help. Talk to friends, neighbors and acquaintances, one of them may have the perfect contact, the perfect opening for you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>For the moment it may feel as if you’ve been thinking and brooding 24/7. Maybe you have the impression you aren’t able to stop your brain from worrying. And it’s not only about real conflicts and challenges, but also about imaginary ones. Maybe you are even creating a good deal of problems out of thin air. It could be an idea to try and use your spirituality to stop this negative pattern. Meditation may be the perfect way to help calm and soothe your never ending thoughts, possibly even put a stop to them. If you are ‘too far gone’ to just pick up meditation, you could try and find a book (f.e. a book of quotes) that helps you relax. Or take a yoga class. Do whatever it takes to feed your brain positive thoughts and energy, it’s urgently needed!</p>",
                    }
                },
                fr : {
                    name : "Le Neuf d’ Épée",
                    suit : "Épées",
                    element :  "🜁 - air",
                    theme : "savoir ce que l'on pense",    
                    description : "Le Neuf d'épée, nous parle d’être conscient de ce que l’on pense et que nous pouvons nous même apaiser la situation.",
                    questions : ["Vous inquiétez-vous beaucoup?", "En avez-vous des nuits blanches?", "Pouvez-vous calmer vos pensées?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Les \"Neuf d'Épée\" représentent des nuits d'insomnie causées par l'inquiétude et la peur. Vous avez beaucoup de choses en tête et vous vous sentez vraiment déprimé. Vous pouvez même avoir une conscience coupable qui vous empêche de dormir la nuit.</p><p>Il est possible que vous soyez à la recherche des personnes et des circonstances qui pourraient être à l'origine de ce sentiment. Oubliez le reste du monde et concentrez-vous sur vous-même : Que pouvez-vous changer dans vos sentiments? Même s'il y a une relation ou une situation qui vous cause du chagrin, c'est à vous d'analyser ce qui se passe et de découvrir ce que vous pouvez faire pour la changer.</p><p>Peut-être que la façon dont vous vous sentez maintenant vous amène à la question : \"C'est tout ce qu'il y a, ne devrait-il pas y en avoir plus? Si c'est le cas, essayez de vous concentrer sur les petites choses qui vont bien. Cela pourrait vous donner la joie et la paix intérieure. Souvent, la beauté de la vie réside dans les bonnes choses de la vie quotidienne, la divinité réside dans les détails.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Nine of Swords is about doubts and hesitations. Are you afraid of being left behind? Or are you considering to break off a relationship before your partner can do so? Or maybe you have a feeling that something is not quite right but you’re afraid to ask. Now is not the time to ignore your gut feelings. On the contrary, you should act on them. Talk to your partner, ask those difficult questions, insist on getting answers. But don’t stay suspicious once you get those answers. If you can’t trust your partner to answer honestly, then maybe you shouldn’t be together.</p><p>If you have just started a relationship, it is important to accept that being able to really trust someone takes time. Give yourself a chance to let the relationship and the trust grow slowly and steadily.</p>",
                        [GuidanceFields.WORK] : "<p>You may be feeling depressed and down. Are these feelings caused by the contents of your job, or are you having trouble with a colleague or a superior? Or maybe you feel paralyzed by fear of failure? Where work is concerned there are many factors you can’t control: you can’t change your colleagues, you can’t assign a new boss, you can’t choose your tasks. But there are still a few things you do control, and now it’s time to start focusing on those. Let your worries and fears go, leave them behind and focus on the things you can change.</p><p>If you are looking for a job now is a good time to find some help. Talk to friends, neighbors and acquaintances, one of them may have the perfect contact, the perfect opening for you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>For the moment it may feel as if you’ve been thinking and brooding 24/7. Maybe you have the impression you aren’t able to stop your brain from worrying. And it’s not only about real conflicts and challenges, but also about imaginary ones. Maybe you are even creating a good deal of problems out of thin air. It could be an idea to try and use your spirituality to stop this negative pattern. Meditation may be the perfect way to help calm and soothe your never ending thoughts, possibly even put a stop to them. If you are ‘too far gone’ to just pick up meditation, you could try and find a book (f.e. a book of quotes) that helps you relax. Or take a yoga class. Do whatever it takes to feed your brain positive thoughts and energy, it’s urgently needed!</p>",
                    }
                },
                sp:{
                    name:"El Nueve de espadas",
                    suit: "Espadas",
                    element: "🜁 - aire",
                    theme: "saber lo que piensas",    
                    description: "El Nueve de Espadas se trata de ser consciente de lo que piensas y nos recuerda que podemos traer la paz a eso nosotros mismos",
                    questions: ["¿Te preocupas mucho?", "¿Te acuestas despierto?", "¿Puedes calmar tu pensamiento?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La Nueve de Espadas se trata de noches de insomnio causados por preocupación y ansiedad. Usted tiene mucho en su mente y se siente realmente mal. Tal vez incluso tiene una conciencia culpable que le mantiene despierto por la noche. Lo más probable es que usted está buscando para las personas y las circunstancias que pueden o no pueden ser la causa de la forma en que se siente. Olvidarse del resto del mundo y centrarse en su mismo y lo que puede hacer para cambiar la forma en que se siente. Incluso si hay una relación o situación que le hace el dolor, le corresponde a usted para analizar lo que está sucediendo y lo que puede hacer para cambiarlo. Tal vez la forma en la vida se siente en este momento te hace pensar \"¿Es esto todo lo que hay, no debe haber más?\". Si este es el caso, tratar de concentrarse en las pequeñas cosas que van bien, que le puede dar alegría y paz interior. A menudo, la belleza de la vida radica en las cosas ordinarias.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Zwaarden Negen",
                    suit : "Zwaarden",
                    element : "🜁 - lucht",
                    theme : "weten wat je denkt",    
                    description : "De Negen van Zwaarden gaat over bewust zijn van wat je denkt en wijst ons er op dat we daar zelf rust in kunnen brengen.",
                    questions : [ "Pieker jij veel?", "Lig jij er wakker van?", "Kun jij je denken tot rust brengen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Negen van Zwaarden kan wijzen op slapeloze nachten vol gepieker en angsten. Het is mogelijk dat je op dit ogenblik veel aan je hoofd hebt en dat je je gedeprimeerd voelt. Misschien heb je zelfs een stukje een schuldig geweten dat je ’s nachts wakker houdt.</p><p>Toch is het best mogelijk dat je vooral op zoek bent naar mensen en omstandigheden die de oorzaak kunnen zijn van je huidige gevoelens. Probeer de rest van de wereld los te laten en concentreer je op jezelf: wat kan jij doen om je gevoelens te veranderen? Zelfs als het zo is dat je verdriet hebt over een bepaalde relatie of een bepaalde situatie, dan nog kan jij gaan uitzoeken wat er exact aan de hand is en wat jij eraan kan doen om je gevoel erbij te veranderen.</p><p>Misschien voel je je op dit moment zo dat je denkt:”Is dit echt alles wat er is? Zou er niet meer moeten zijn?”. Als dat het geval is, probeer je dan te concentreren op de kleine dingen die wèl goed zitten en waar je vreugde en rust uit kan halen. De schoonheid van het leven zit ‘m dikwijls in de kleine dingen, er schuilt goddelijkheid in vaak onopvallende details.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Zwaarden Negen gaat over twijfels en aarzelen. Ben je bang om achtergelaten te worden? Of ben je aan het overwegen om de relatie te verbreken voor je partner ertoe overgaat? Of misschien heb je het gevoel dat er iets helemaal niet goed zit maar ben je te bang om erover te beginnen? Op dit moment mag je je intuïtie echt niet negeren. Praat met je partner, stel de moeilijke vragen, sta erop dat je ook antwoorden krijgt. Maar wees niet meer wantrouwig eens je die antwoorden gekregen hebt. Als je er niet op kan vertrouwen dat je partner je eerlijk antwoordt, dan horen jullie misschien niet (meer) samen te zijn.</p><p>Als je net aan een nieuwe relatie begonnen bent, dan is het belangrijk dat je aanvaardt dat het tijd kost om vertrouwen op te bouwen. Geef jezelf en je partner de tijd en de ruimte om het vertrouwen èn de relatie langzaam maar zeker te laten groeien.</p>",
                        [GuidanceFields.WORK] : "<p>Best mogelijk dat je je op dit moment een beetje down voelt, gedeprimeerd. Vraag je eens af of deze gevoelens veroorzaakt worden door je job zelf of door problemen met je baas of een collega. Of misschien ben je op het ogenblik verlamd door angst om te falen? In verband met je werksituatie zijn er vele aspecten die je niet onder controle hebt: je kan je collega’s niet veranderen, je kan geen nieuwe baas aanstellen, je hebt je taken niet voor het uitkiezen. Maar toch zijn er een aantal dingen waar je wel invloed op hebt en het is belangrijk dat je nu op die dingen gaat focussen. Laat je zorgen en angsten los en concentreer je op alle aspecten die je wel kan veranderen.</p><p>Als je op zoek bent naar werk dan is het nu de juiste tijd om hulp in te schakelen. Praat met vrienden, buren en kennissen want het is best mogelijk dat er iemand tussen zit die de perfecte contactpersoon heeft voor jou, die de ideale vacature aan je kan doorgeven.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment kan het voelen alsof je dag en nacht aan het nadenken en zelfs piekeren bent. Misschien heb je de indruk dat je niet in staat bent om je brein stil te zetten. Je denkt niet enkel na over echte conflicten en uitdagingen maar je piekert ook over ingebeelde dingen. Het is best mogelijk dat je een aantal problemen zelf aan het creëren bent. Probeer je spiritualiteit te gebruiken om een einde te maken aan dit negatieve patroon. Meditatie kan een goede manier zijn om je tot rust te brengen en je nooit eindigende gedachtenstroom te stoppen. Als je het te moeilijk hebt om te kunnen mediteren, ga dan op zoek naar een goed boek, bv. eentje met citaten. Je kan ook een yogales volgen of een reikibehandeling nemen. Probeer allerlei dingen uit tot je erin slaagt om je brein tot positieve gedachten te brengen, het is dringend nodig!</p>",
                    }
                },
                de : {
                    name: "Neun der Schwerter",
                    suit : "Schwerter",
                    element : "🜁 - Luft",
                    theme : "Wissen, was man denkt",    
                    description : "Die Neun der Schwerter spricht darüber, sich bewusst zu sein, was man denkt und dass wir die Situation selbst beruhigen können.",
                    questions : [ "Machen Sie sich große Sorgen?", "Haben Sie schlaflose Nächte?", "Können Sie Ihre Gedanken beruhigen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Neun der Schwerter“ steht für durch Sorgen und Ängste verursachte schlaflose Nächte. Sie haben eine Menge auf dem Herzen und Sie fühlen sich richtig bedrückt. Vielleicht haben Sie sogar ein schlechtes Gewissen, das Sie nachts wach hält.</p><p>Es könnte möglich sein, dass Sie Menschen und Umstände suchen, die möglicherweise die Ursache für dieses Gefühl sind. Vergessen Sie den Rest der Welt und konzentrieren Sie sich auf sich selbst: Was können Sie an Ihrem Gefühl ändern? Auch wenn es eine Beziehung oder eine Situation gibt, die Ihnen Kummer bereitet, ist es an Ihnen, zu analysieren, was geschieht um heraus zu finden, was Sie tun können, um dies zu ändern.</p><p>Vielleicht führt die Art und Weise, wie Sie sich jetzt fühlen, Sie zu folgender Frage: „Ist das alles, was es gibt, sollte da nicht mehr sein?” Wenn dies der Fall ist versuchen Sie sich auf die kleinen Dinge, die gut gehen, zu konzentrieren. Das könnte Ihnen Freude und inneren Frieden bereiten. Oft liegt die Schönheit des Lebens in den guten Dingen des Alltags, liegt Göttlichkeit im Detail.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Die Neun der Schwerter handelt über Zweifel und Zögern. Haben Sie Angst zurückgelassen zu werden? Oder überlegen Sie Sich eine Beziehung abzubrechen bevor Ihr Partner es tut? Oder vielleicht haben Sie das Gefühl, dass etwas nicht ganz richtig ist, aber Sie haben es noch nicht gewagt Sich zu fragen was? Jetzt ist nicht die Zeit, um Ihr Bauchgefühl zu ignorieren. Im Gegenteil, Sie sollten darauf hören. Sprechen Sie mit Ihrem Partner, fragen Sie diese schwierigen Fragen, bestehen Sie auf Antworten. Aber bleiben Sie nicht misstrauisch, wenn Sie die Antworten bekommen. Wenn Sie Ihren Partner nicht zutrauen ehrlich zu antworten, dann könnten Sie vielleicht nicht zusammen sein.</p><p>Wenn Sie nur gerade eben eine Beziehung angefangen haben, ist es wichtig zu akzeptieren, dass wenn man einen anderen wirklich vertrauen will und kann, das Zeit braucht. Gönnen Sie sich eine Chance die Beziehung und das Vertrauen langsam und stetig wachsen zu lassen.</p>",
                        [GuidanceFields.WORK] : "<p>Möglicherweise fühlen Sie Sich deprimiert. Werden diese Gefühle von Ihrer Arbeit verursacht, oder haben Sie Probleme mit einem Kollegen oder Vorgesetzten? Oder vielleicht sind Sie durch Versagensangst gelähmt? So weit es die Arbeit betrifft es viele Faktoren, die Sie nicht beeinflussen können, Sie können Ihre Kollegen nicht ändern, Sie können Sie nicht einen neuen Chef zuordnen, Sie können nicht Ihre Aufgaben wählen. Aber es gibt doch ein paar Dinge, die Sie kontrollieren können und jetzt ist es Zeit anzufangen sich darauf zu konzentrieren Lassen Sie Ihre Sorgen und Ängste gehen, lassen Sie diese hinter Sich und konzentrieren Sie Sich auf die Dinge, die Sie ändern könnten.</p><p>Wenn Sie auf der Suche nach einem Job sind, ist jetzt ein guter Zeitpunkt, um etwas Hilfe zu finden. Sprechen Sie mit Freunden, Nachbarn und Bekannten, einer von ihnen könnte den perfekten Kontakt, die perfekte Eröffnung für Sie haben.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Zur Zeit kann es fühlen, als hätten Sie darüber Tag und Nacht nachgedacht und gebrütet. Vielleicht haben Sie den Eindruck, Sie sind nicht in der Lage Ihr Gehirn aufhören zu lassen sich Sorgen zu machen. Und es betrifft nicht nur wirkliche Konflikte und Herausforderungen, aber auch eingebildete. Vielleicht schaffen Sie sogar ein großes Teil dieser Probleme aus dem Nichts. Es könnte eine Idee sein, zu versuchen Ihre Spiritualität anzuwenden, um diese Negativität zu stoppen. Meditation kann der perfekte Weg sein um zu helfen, Ruhe zu bringen in die nie endenden Gedanken, möglicherweise sogar um ein Ende daran zu setzen. Wenn Sie \"zu weit hin sind\", um zur Meditation zu geraten, versuchen Sie dann einen Buch zu finden (z. B. ein Buch mit Zitaten), das Ihnen hilft zu entspannen. Oder nehmen Sie einen Yoga-Kurs. Tun Sie aber alles um Ihr Gehirn zu füttern mit positiven Gedanken und Energie, es ist dringend erforderlich!</p>",
                    }
                }
            },
            {
                filename: "59",
                numerology : 10,
                en : {
                    name: "Ten of Swords",
                    suit : "Swords",
                    element : "🜁 - air",
                    theme : "brooding a lot",    
                    description : "Ten of Swords talks about brooding a lot and that humans are deadly over-thinkers.",
                    questions : [ "Are you thinking a lot?", "Do you think you are stuck?", "Can you consciously put an end to it?",],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Firstly this card indicates a powerful, maybe even aggressive, ending and a new beginning. A situation or relationship in your life will come or is coming to an end and possibly this is not your choice. Even if you are deeply unhappy about this change it is important for you to remember that ending a chapter does not mean that the world is coming to an end. Try to relax and let go and trust the universe to guide you to a better future.</p><p>Secondly the Ten of Swords is also about dishonesty and betrayal. If you are having doubts if someone is trustworthy or not, remember that it’s better to be safe than sorry. If an offer sounds too good to be true, it probably is. If someone is being too nice, they most possibly have ulterior motives.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you get the Ten of Swords it’s quite possible you are experiencing a breakup or will do so in the near future. Maybe the problems were quietly building while you weren’t paying attention? And then one day you woke up and realized they had become too big, too complicated to solve? Accept that you cannot heal a relationship by yourself, it takes two. Take your losses and let go.</p><p>If you are looking for a relationship these may not be the best of times. When you meet someone who seems to be a good candidate, think twice and then again. They may not be who they seemed to be at first sight.</p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned, now is the time to be attentive, vigilant. It is quite possible you will have to be on the lookout for a new position. This doesn’t necessarily mean you weren’t doing a good job. The fact that you may be no longer needed doesn’t always reflect on the quality of your work. There may be other factors involved.</p><p>But no matter how or what, it is vital that you don’t retreat to the ‘poor me’ position right now. Even if you feel wronged, even if you think there was some backstabbing involved, try to keep an open mind. The future is literally yours now, it is up to you to shape it, so try your hardest to make it a positive and bright one!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Now may be the time that your whole spiritual belief system comes tumbling down around you. The things you thought were true turn out to be false. Gurus, guides you truly believed in seem to have their eyes on your wallet rather than on your wellbeing. The upside is that you can let go of convictions that weren’t good for you anyway. You have a brand new blank slate to fill. This is the opportunity of a lifetime to go out and seek new wisdom. Rely on your instincts this time, they will tell you who to trust!</p>",
                    }
                },
                fr : {
                    name : "Le Dix d’ Épée",
                    suit : "Epées",
                    element :  "🜁 - air",
                    theme : "réfléchir énormément",    
                    description : " Le Dix d’ Épée, nous parle de beaucoup réfléchir et de détruire en réfléchissant de trop.",
                    questions : [ "Réfléchissez-vous beaucoup?", " Réfléchissez -vous que vous êtes piégés?", "Pouvez-vous consciemment tirer une croix?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Premièrement, cette carte indique une fin puissante, peut-être même agressive, et un nouveau départ. Une situation ou une relation dans votre vie touche à sa fin et ce n'est peut-être pas votre décision. Peut-être êtes-vous profondément mécontent de ce changement. Cependant, il est important de se rappeler que la fin d'un chapitre ne signifie pas nécessairement la fin du monde. Essayez de vous détendre, de lâcher prise et de croire que l'univers vous réserve un avenir meilleur.</p><p>Deuxièmement, le \"Dix d'Épée\" parle aussi de malhonnêteté et de trahison. Si vous doutez de la fiabilité d'une personne, rappelez-vous qu'il vaut mieux être du bon côté que d'être blessé. Si une offre semble trop belle pour être vraie, il se peut très bien qu'il en soit ainsi. Si quelqu'un est trop gentil, il a probablement des arrière-pensées.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you get the Ten of Swords it’s quite possible you are experiencing a breakup or will do so in the near future. Maybe the problems were quietly building while you weren’t paying attention? And then one day you woke up and realized they had become too big, too complicated to solve? Accept that you cannot heal a relationship by yourself, it takes two. Take your losses and let go.</p><p>If you are looking for a relationship these may not be the best of times. When you meet someone who seems to be a good candidate, think twice and then again. They may not be who they seemed to be at first sight.</p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned, now is the time to be attentive, vigilant. It is quite possible you will have to be on the lookout for a new position. This doesn’t necessarily mean you weren’t doing a good job. The fact that you may be no longer needed doesn’t always reflect on the quality of your work. There may be other factors involved.</p><p>But no matter how or what, it is vital that you don’t retreat to the ‘poor me’ position right now. Even if you feel wronged, even if you think there was some backstabbing involved, try to keep an open mind. The future is literally yours now, it is up to you to shape it, so try your hardest to make it a positive and bright one!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Now may be the time that your whole spiritual belief system comes tumbling down around you. The things you thought were true turn out to be false. Gurus, guides you truly believed in seem to have their eyes on your wallet rather than on your wellbeing. The upside is that you can let go of convictions that weren’t good for you anyway. You have a brand new blank slate to fill. This is the opportunity of a lifetime to go out and seek new wisdom. Rely on your instincts this time, they will tell you who to trust!</p>",
                    }
                },
                sp:{
                    name:"El Diez de espadas",
                    suit: "Espadas",
                    element: "🜁 - aire",
                    theme: "pensar muchísimo",    
                    description: "El Diez de Espadas es sobre un montón de pensamiento y nos señala que la gente tiene el capaz de pensar demasiado en todo el tipo de cosas",
                    questions: ["¿Piensas mucho?", "¿Piensas que no puedes ir más allá?", "¿Puedes poner una línea debajo de eso deliberadamente?","" ],
                    
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>En primer lugar esta carta indica un poderoso, tal vez incluso agresiva, que termina y un nuevo comienzo. Una situación o relación en su vida vendrán o está llegando a su fin y, posiblemente, este no es su opción. Incluso si usted es profundamente infeliz acerca de este cambio es importante que usted recuerde que el fin de un capítulo no significa que el mundo está llegando a su fin. Trate de relajarse y dejarse y confiar en el universo que lo guíe hacia un futuro mejor. En segundo lugar el Diez de Espadas es también acerca de la deshonestidad y traición. Si tiene dudas si alguien es de confianza o no, recuerde que es mejor prevenir que curar. Si una oferta parece demasiado buena para ser verdad, probablemente lo es. Si alguien está siendo demasiado agradable, lo más posiblemente tienen motivos ulteriores.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Zwaarden Tien",
                    suit : "Zwaarden",
                    element : "🜁 - lucht",
                    theme : "heel veel denken",    
                    description : "De Tien van Zwaarden gaat over heel veel denken en wijst ons er op dat wij mensen in staat zijn om van alles kapot te kunnen denken.",
                    questions : [ "Denk jij veel?", "Denk jij dat je geen kant meer op kunt?", "Kun jij er bewust een streep onder zetten?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>In de eerste plaats wijst deze kaart op een krachtig, mogelijk zelfs een agressief eind en een nieuwe start. Een situatie of een relatie in je leven is net gestopt of stopt binnenkort. Het is mogelijk dat dit einde niet jouw keuze was. Misschien ben je zelfs diep ongelukkig over deze verandering. Toch is het belangrijk voor je om eraan te denken dat het einde van een hoofdstuk iets totaal anders is dan het einde van jouw wereld. Probeer je te ontspannen, los te laten, en vertrouw erop dat het universum je de weg zal wijzen naar een betere toekomst.</p><p>In de tweede plaats kan Zwaarden Tien ook wijzen op oneerlijkheid en mogelijk zelfs verraad. Als je twijfels hebt over iemands betrouwbaarheid, denk er dan aan dat het beter is om op veilig te spelen dan dat je achteraf spijt krijgt. Als een aanbod of een voorstel te goed klinkt om waar te zijn, dan is het meestal ook zo. Als iemand te vriendelijk of te gedienstig is, dan heeft die persoon vaak andere motieven dan je op het eerste zicht zou denken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je Zwaarden Tien krijgt dan is het goed mogelijk dat je op dit moment een relatiebreuk doormaakt of dat er eentje zit aan te komen. Misschien waren de problemen in je relatie zich stilletjes aan het opstapelen terwijl jij met iets anders bezig was? En mogelijk werd je op een dag wakker en besefte je dat de situatie zo ingewikkeld, zo moeilijk geworden was dat geloven in een oplossing misschien niet meer realistisch was. Aanvaard het gegeven dat je een relatie niet in je eentje kan repareren, daar moet je met zijn tweeën voor zijn. Leg je neer bij je verlies en laat los.</p><p>Als je op zoek bent naar een relatie dan is dit er echt niet de beste tijd voor. En als je iemand zou ontmoeten die een goede kandidaat lijkt, denk er dan goed over na en nog eens en nog eens. De persoon die je ontmoet kan zich namelijk totaal anders voordoen dan ‘ie in werkelijkheid is.</p>",
                        [GuidanceFields.WORK] : "<p>Wat je werksituatie betreft, is het belangrijk dat je op dit moment aandachtig en alert bent. Het is goed mogelijk dat je binnenkort misschien zal moeten gaan uitkijken naar een andere baan. Dit betekent niet noodzakelijk dat je je werk niet goed deed, daar gaat het hier waarschijnlijk niet om. Het feit dat men mogelijk niet langer beroep op je wil doen, heeft vaak niets te maken met de kwaliteit van je werk. Er zijn nog tal van andere factoren die meespelen.</p><p>Maar gelijk hoe de situatie in mekaar zit of wat de oorzaken zijn, het is nu erg belangrijk dat je jezelf niet toestaat om “arme ik” te zijn. Zelfs al vind je dat je onrecht is aangedaan, ook al heeft iemand je een mes in de rug gestoken, probeer je geest open te houden. De toekomst behoort jou toe, het is nu aan jou om haar vorm te geven. Doe dus je uiterste best om er een positieve en schitterende toekomst van te maken!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment zou het best kunnen dat je spirituele overtuigingen, wat je gelooft en misschien zelfs zeker denkt te weten, aan het afbrokkelen zijn. Mogelijk blijken bepaalde dingen waar je echt van overtuigd was toch onwaar te zijn. Goeroes, gidsen, raadgevers ... mensen waar je echt in geloofde blijken eerder geïnteresseerd in je geld dan in je spirituele groei. De positieve kant van dit verhaal is dat je de dingen kan gaan loslaten die toch al niet goed voor je waren. Je kan beginnen met een gloednieuwe schone lei. Dit is echt de kans van je leven: trek erop uit en ga op zoek naar wijsheid en inzichten. En vertrouw deze keer op je intuïtie, die zal je vertellen wie je wel en niet kan vertrouwen!</p>",
                    }
                },
                de : {
                    name: "Zehn der Schwerter",
                    suit : "Schwerter",
                    element : "🜁 - Luft",
                    theme : "Gründlich nachdenken",    
                    description : "Die Zehn der Schwerter spricht vom vielem Nachdenken und vom Zerstören, wenn man zu viel nachdenkt.",
                    questions : [ "Denken Sie viel nach?", "Denken Sie darüber nach, dass Sie gefangen sind?", "Können Sie bewusst einen Strich ziehen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Erstens verweist diese Karte auf ein kraftvolles, vielleicht sogar aggressives Ende und auf einen neuen Anfang. Eine Situation oder Beziehung in Ihrem Leben geht zu Ende und möglicherweise ist dies nicht Ihre Entscheidung. Vielleicht sind Sie über diese Veränderung zutiefst unglücklich. Dennoch ist es wichtig, dass Sie daran denken, dass das Ende eines Kapitels nicht unbedingt bedeutet, dass die Welt untergeht. Versuchen Sie sich zu entspannen, lassen Sie los und vertrauen Sie darauf, dass das Universum eine bessere Zukunft für bereithält.</p><p>Zweitens handelt die „Zehn der Schwerter“ auch von Unehrlichkeit und Verrat. Wenn Sie an der Vertrauenswürdigkeit einer Person zweifeln, denken Sie daran, dass es besser ist, auf der sicheren Seite zu sein als zu Schaden zu kommen. Wenn ein Angebot zu gut klingt, um wahr zu sein, dann kann das sehr wohl der Fall sein. Wenn jemand zu nett ist, hat er wahrscheinlich Hintergedanken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie die Zehn der Schwerter bekommen, es ist durchaus möglich, dass Sie gerade in einer Trennung sind oder dass diese in der nahe Zukunft auf Sie zukommt. Vielleicht haben die Probleme sich ruhig aufgebaut, während Sie nicht aufgepasst haben? Und dann sind Sie eines Tages aufgewacht und haben erkennen müssen, dass sie zu groß, zu kompliziert waren um sie noch lösen zu können? Akzeptieren Sie, dass Sie eine Beziehung nicht alleine heilen können, dazu braucht es zwei. Nehmen Sie Ihre Verluste und lassen Sie los.</p><p>Wenn Sie nach einer Beziehung suchen, wird dieser Zeitpunkt möglicherweise nicht der beste aller Zeiten sein. Wenn Sie jemanden begegnen, der ein guter Kandidat zu sein scheint, überlegen Sie Sich zweimal, und dann noch einmal. Es kann sich herausstellen, dass diese Person nicht so richtig sein mag, wie es am ersten Blick aussieht.</p>",
                        [GuidanceFields.WORK] : "<p>Wo es die Arbeit betrifft, ist es jetzt die Zeit aufmerksam und wachsam zu sein. Es ist durchaus möglich, dass Sie auf der Suche gehen müssen nach einer neuen Arbeit. Dies bedeutet nicht unbedingt, dass Sie nicht gut geleistet haben in Ihrem Job, aber dass Sie vielleicht nicht mehr benötigt sind, was nicht immer zurückzuführen ist auf die Qualität Ihrer Arbeit. Möglicherweise gibt es andere Faktoren, die daran zu Grunde liegen.</p><p>Aber egal, wie oder was, es ist es wichtig, dass Sie Sich jetzt nicht auf den \"armen ich\" Position zurückziehen. Auch wenn Sie Unrecht fühlen, auch wenn Sie denken, Sie waren an einer Messerstecherei beteiligt, versuchen Sie trotzdem, einen offenen Geist zu behalten. Die Zukunft ist buchstäblich jetzt die Ihre und es ist es an Ihnen, sie zu gestalten. So versuchen Sie Ihr Bestes, um es zu einer positiven und hellen zu machen!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Jetzt könnte es die Zeit sein, wo Ihr ganzes spirituelles Glaubenssystem um Sie herum einstürzt. Die Dinge, wovon Sie dachten Sie wären richtig, erweisen sich als falsch zu sein. Gurus, die Führer, an denen Sie wirklich geglaubt haben, scheinen Ihre Augen auf Ihren Geldbeutel gerichtet zu haben und nicht auf Ihrem Wohlbefinden. Der Vorteil ist, dass Sie jetzt die Überzeugungen, die sowieso nicht gut für Sie waren, hinter sich lassen können. Sie haben ein brandneues unbeschriebenes Blatt zu füllen. Dies ist die Chance Ihres Lebens auszugehen und neue Weisheit zu suchen. Verlassen Sie sich auf Ihrem Instinkt; dieses Mal es wird Ihnen sagen, wem man vertrauen kann!</p>",
                    }
                }
            },
            {
                filename: "60",
                numerology : 11,
                en : {
                    name: "Page of Swords",
                    court : "Page",
                    suit : "Swords",
                    element : [ "🜁 - air", "🜃 - earth" ],
                    theme : "having an impartial look at what you think",    
                    description : "Page of Swords talks about impartially gathering information and to see it with a child-like light-heartedness.",
                    questions : [ "Are you eager to learn?", "Dare you ask questions?", "Do you decide for yourself?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>In Tarot all Page cards stand for opportunities. The Page of Swords tells you that you should be open for new insights and clarity. If you are in a conflict with someone now is the time you will suddenly understand what went wrong and why it went wrong. Now truly is the time when you will be learning from your experiences.</p><p>This card also tells you that you may have a lot of energy nowadays and you may be using it in ways that bother the people around you. Ask yourself if you are not pushing to hard, being too straight forward, possibly the people around you take offence of the comments you make. And maybe you don’t find it easy yourself to accept the criticism you get from others.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In your personal relationships there’s clearly tension in the air. Unresolved conflicts are surfacing, small frictions you didn’t address, demand your attention now. The choice is yours: do you want to let sleeping dogs lie even if they seem to be half awake already? Or do you want to confront the problems with the risk of not being able to solve them? The Page of Swords tells you that any choice you make will have consequences. Even if you should decide to do nothing at all, things will change anyway. There’s no way of pleasing everyone. Right now the best way is to go where your energy tells you to go, follow your intuition, do what you feel is best.</p><p>If you are single, now is the time to come out of your shell. Love won’t come knocking at your door, you will have to go out and look for it!</p>",
                        [GuidanceFields.WORK] : "<p>If you have a job right now, expect some conflict, some problems. Remind yourself that for some people the truth is too hard to hear and behave accordingly. You don’t have to speak your mind every time, sometimes it’s better to observe others and find out which way the wind is blowing.</p><p>If you are looking for a job, you might want to tone it down a notch in your interviews. Listen to people, show them you are open to other visions and beliefs than yours. Make it clear you can easily adapt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>As the song goes: free your mind and the rest will follow. Go explore the spiritual world with the same enthusiasm you usually reserve for worldly matters. Find books to read, people to talk to, meditations to try out. Gather as much knowledge as you can and find people to discuss it with. By exchanging information, insights and opinions you will feel yourself growing towards true spiritual experiences. This growth will have a profound influence on other aspects of your life.</p>",
                    }
                },
                fr : {
                    name : "Le Valet des Epées",
                    court : "Valet",
                    suit : "Épées",
                    element : ["🜁 - air", "🜃 - terre" ],
                    theme : "Regarder ce que l'on pense sans préjugé",    
                    description : " Le Valet des Epées, nous parle de collecter de l’information impartiale et de les regarder avec un esprit ouvert et enfantin.",
                    questions : [ "Etes-vous curieux?", " Osez-vous poser des questions?", "Décidez- vous vous-même?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Dans le tarot, toutes les cartes symbolisent des chances. La carte \"Valet d'Épée\" vous dit d'être ouvert à toutes les nouvelles idées et à la clarté. Si vous avez un conflit avec quelqu'un, vous comprendrez soudainement ce qui a mal tourné et pourquoi. C'est vraiment le moment d'apprendre de vos expériences.</p><p>Cette carte vous montre aussi que vous avez beaucoup d'énergie en ce moment. C'est généralement une bonne chose, mais vous pouvez utiliser cette énergie d'une manière qui pourrait déranger les gens. Demandez-vous si vous n'exercez pas trop de pression ou si vous êtes trop direct. Peut-être les gens seront-ils offensés par vos commentaires. Soyons honnêtes, vous ne trouvez pas facile non plus d'accepter les critiques.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In your personal relationships there’s clearly tension in the air. Unresolved conflicts are surfacing, small frictions you didn’t address, demand your attention now. The choice is yours: do you want to let sleeping dogs lie even if they seem to be half awake already? Or do you want to confront the problems with the risk of not being able to solve them? The Page of Swords tells you that any choice you make will have consequences. Even if you should decide to do nothing at all, things will change anyway. There’s no way of pleasing everyone. Right now the best way is to go where your energy tells you to go, follow your intuition, do what you feel is best.</p><p>If you are single, now is the time to come out of your shell. Love won’t come knocking at your door, you will have to go out and look for it!</p>",
                        [GuidanceFields.WORK] : "<p>If you have a job right now, expect some conflict, some problems. Remind yourself that for some people the truth is too hard to hear and behave accordingly. You don’t have to speak your mind every time, sometimes it’s better to observe others and find out which way the wind is blowing.</p><p>If you are looking for a job, you might want to tone it down a notch in your interviews. Listen to people, show them you are open to other visions and beliefs than yours. Make it clear you can easily adapt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>As the song goes: free your mind and the rest will follow. Go explore the spiritual world with the same enthusiasm you usually reserve for worldly matters. Find books to read, people to talk to, meditations to try out. Gather as much knowledge as you can and find people to discuss it with. By exchanging information, insights and opinions you will feel yourself growing towards true spiritual experiences. This growth will have a profound influence on other aspects of your life.</p>",
                    }
                },
                sp:{
                    name:"El Soto de espadas",
                    court: "Soto",
                    suit: "Espadas",
                    element: ["🜁 - aire", "🜃 - tierra" ], 
                    theme: "mirar lo que piensas con la mente abierta",    
                    description: "El Soto de Espadas se trata de recopilar información imparcial y nos recuerda que podemos mirarla con una mentalidad infantil y abierta",
                    questions: ["¿Estás ansioso por aprender?", "¿Te atreves a hacer preguntas?", "¿Decides por ti mismo?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>En el Tarot representan todas las tarjetas de Paje de oportunidades. El Paje de Espadas le dice que usted debe estar abierto a nuevas ideas y la claridad. Si usted está en un conflicto con alguien ahora es el tiempo que va a entender de repente lo que salió mal y por qué salió mal. Ahora realmente es el momento en que usted va a aprender de sus experiencias.</p><p>Esta tarjeta también le dice que usted puede tener una gran cantidad de energía en la actualidad y puede que esté utilizando en formas que molestan a la gente que te rodea. Pregúntese si usted no está empujando a duro, demasiado sencillo, posiblemente las personas que te rodean se ofenden de los comentarios que usted hace. Y tal vez no lo encuentra fácil a ti mismo para aceptar las críticas que recibe de los demás.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                    
                nl : {
                    name: "Page van Zwaarden",
                    court : "Page",
                    suit : "Zwaarden",
                    element : [ "🜁 - lucht", "🜃 - aarde" ],
                    theme : "onbevooroordeeld kijken naar wat je denkt",    
                    description : "De Page van Zwaarden gaat over onbevooroordeeld informatie verzamelen en wijst ons er op dat we daar met kinderlijke onbevangenheid naar mogen kijken.",
                    questions : [ "Ben jij leergierig?", "Durf jij vragen te stellen?", "Beslis jij lekker zelf?" ], 
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>In de Tarot betekenen de Pagekaarten altijd nieuwe mogelijkheden en kansen. De Page van Zwaarden vertelt je dat het op dit moment belangrijk is om open te staan voor nieuwe inzichten en helderheid. Als je een conflict hebt met iemand, dan zal je nu ineens begrijpen wat er fout gegaan is en waarom dat gebeurd is. De tijd is nu echt rijp om te leren van je ervaringen.</p><p>Als je deze kaart krijgt, is het ook mogelijk dat je heel veel energie hebt op dit moment. Over het algemeen is dit een goede zaak maar het is altijd mogelijk dat je deze energie op zo'n manier gebruikt dat de mensen om je heen er last van hebben. Vraag je eens af of je sommige dingen niet te hard aan het doordrukken bent of misschien ben je te duidelijk, te openhartig. En laten we eerlijk zijn, je vindt het zelf ook niet altijd gemakkelijk om de kritiek die je krijgt ook te aanvaarden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Er hangt duidelijk nogal wat spanning in de lucht in je persoonlijke relaties. Onopgeloste conflicten komen boven, lichte wrijvingen die je niet aangepakt hebt, vragen nu je aandacht. De keuze is aan jou: besluit je om geen slapende honden wakker te maken terwijl ze eigenlijk al één oog open hebben? Of ga je de confrontatie aan ook al loop je het risico dat je de problemen mogelijk niet kan oplossen? De Page van Zwaarden vertelt je dat elke keuze die je maakt ook gevolgen zal hebben. Zelfs als je zou besluiten om helemaal niets te doen, dan nog zal de situatie veranderen. En er bestaat nu eenmaal geen manier om iedereen tevreden te houden. Op dit moment is het verstandigst om de weg te volgen die je energie je toont, doe datgene wat het beste aanvoelt.</p><p>Als je single bent en op zoek naar een relatie dan is nu de tijd gekomen om uit je schelp te kruipen. De liefde zal niet komen aankloppen, je zal de wereld in moeten en ernaar op zoek gaan!</p>",
                        [GuidanceFields.WORK] : "<p>Als je op dit moment aan het werk bent dan houd je er best rekening mee dat er wat conflicten of problemen kunnen komen. Denk eraan dat sommige mensen de waarheid nu eenmaal niet aankunnen en gedraag je daarnaar. Je hoeft niet altijd je mening te uiten, soms is het beter om anderen te observeren en zo te ontdekken vanwaar de wind komt.</p><p>Als je op zoek bent naar werk dan is het verstandig om het wat rustiger aan te doen in je sollicitatiegesprekken. Luister naar de mensen, toon hen dat je openstaat voor andere opvattingen en visies dan de jouwe. Maak hen duidelijk dat je soepel bent en je gemakkelijk kan aanpassen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het liedje klopt: free your mind and the rest will follow - als je je geest bevrijdt, dan volgt de rest vanzelf. Ontdek de spirituele wereld met hetzelfde enthousiasme dat je gewoonlijk enkel opbrengt voor meer wereldlijke zaken. Zoek boeken die je aanspreken, mensen om mee te praten, meditaties die je wil uitproberen. Verzamel zoveel mogelijk kennis en zoek dan mensen om hierover van gedachten te wisselen. Door geconfronteerd te worden met andere informatie, inzichten en meningen zal je vanzelf groeien en ook intense spirituele ervaringen krijgen. Je zal merken dat deze spirituele groei een diepgaande invloed heft op andere aspecten van je leven.</p>",
                    }
                },
                de : {
                    name: "Bube der Schwerter",
                    court : "Bube",
                    suit : "Schwerter",
                    element : [ "🜁 - Luft", "🜃 - Erde" ],
                    theme : "Schauen, was man denkt, ohne Vorurteile zu haben.",    
                    description : "Der Bube der Schwerter spricht davon, wie man unparteiische Informationen sammelt und sie mit einem offenen und kindlichen Geist betrachtet.",
                    questions : [ "Sind Sie neugierig?", "Wagen Sie es, Fragen zu stellen?", "Entscheiden Sie selbst?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Im Tarot symbolisieren alle Karten Chancen. Die Karte „Bube der Schwerter“ sagt Ihnen, dass Sie für alle neuen Erkenntnisse und Klarheit offen sein sollen. Wenn Sie mit jemanden einen Konflikt haben werden Sie plötzlich verstehen, was und warum etwas falsch gelaufen ist. Jetzt ist wirklich die Zeit, von Ihren Erfahrungen zu lernen.</p><p>Diese Karte zeigt Ihnen auch, dass Sie gerade viel Energie haben. Dies ist im Allgemeinen eine gute Sache, aber Sie verwenden möglicherweise diese Energie auf eine Art und Weise, die Leute ärgern könnte. Fragen Sie sich, ob Sie nicht zu viel Druck machen oder ob Sie zu direkt sind. Möglicherweise nehmen die Leute Anstoß an Ihren Kommentaren. Seien wir ehrlich, Sie finden es auch nicht einfach, Kritik zu akzeptieren.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In Ihren persönlichen Beziehungen gibt es eindeutig Spannung in der Luft. Ungelöste Konflikte tauchen auf, kleine Reibereien worum Sie nicht gefragt haben, fordern jetzt Ihre Aufmerksamkeit. Sie haben die Wahl: die schlafenden Hunde jetzt schlafen zu lassen, sogar wenn die schon halb wach geworden sind? Oder wollen Sie sich mit den Problemen konfrontieren mit dem Risiko nicht in der Lage zu sein sie zu lösen? Der Bube der Schwerter sagt Ihnen, dass jede Entscheidung, die Sie machen, Folgen haben wird. Auch wenn Sie sich entscheiden nichts zu tun, wird es sich auf jedem Fall ändern. Es gibt keine Möglichkeit alle zu gefallen. Gerade jetzt ist der beste Weg dahin zu gehen, wo Ihre Energie Ihnen sagt zu gehen, folgen Sie Ihre Intuition, es ist am besten zu tun was Sie fühlen.</p><p>Wenn Sie alleine sind, ist jetzt die Zeit, um aus Ihre Schale zu kriechen. Liebe kommt nicht von alleine an Ihre Tür vorbei, Sie müssen auf sie zugehen!</p>",
                        [GuidanceFields.WORK] : "<p>Wenn Sie im Augenblick einen Job haben erwarten Sie einige Konflikte, einige Probleme. Denken Sie daran, dass es für manche Leute schwierig ist die Wahrheit zu hören und handeln dann dementsprechend. Sie brauchen Sich nicht jedes Mal auszusprechen, manchmal ist es besser, erst mal andere zu beobachten und herauszufinden, woher der Wind weht.</p><p>Wenn Sie auf der Suche sind nach einem Job, möchten Sie vielleicht Ihre Motive ein bisschen abschwächen in Ihren Interviews. Hören Sie Menschen an, zeigen Sie deutlich an, dass Sie offen sind für andere Visionen und Überzeugungen und dass diese nicht desgleichen sein müssen als die Ihre. Machen Sie deutlich, dass Sie Sich leicht anpassen können.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Wie das Lied geht: Befreien Sie Ihren Verstand und der Rest wird folgen. Gehen Sie und erkunden Sie die geistige Welt mit der gleichen Begeisterung, die Sie in der Regel für die weltlichen Angelegenheiten reservieren. Suchen Sie Bücher zum Lesen, Menschen zum Sprechen, Meditationen zum Ausprobieren. Sammeln Sie so viel Wissen wie Sie können und finde Menschen, um mit ihnen darüber zu diskutieren. Durch den Austausch von Informationen, Einsichten und Ansichten werden Sie sich fühlen als wachsen Sie in die Richtung wahrer spirituellen Erfahrungen. Dieses Wachstum wird einen tief greifenden Einfluss auf andere Aspekte Ihres Lebens haben.</p>",
                    }
                }
            },            
            {
                filename: "61",
                numerology : 12,
                en : {
                    name: "Knight of Swords",
                    court : "Knight",
                    suit : "Swords",
                    element : "🜁 - air",
                    theme : "putting your own opinion out there",    
                    description : "Night of Swords talks about showing what you think to the world around you.",
                    questions : [ "Are you voicing your opinion?", "Are you willing to listen to the opinions of others?", "Are you capable to adjust your opinion based on fine arguments?", "Can you fight for what is right for you?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>If you choose this card it tells you that at this moment you have a lot of energy, and you are dying to use it. Before you go out and address issues, conflicts or people ask yourself if you really want to get into a battle. You are very energetic right now and you may win those battles but maybe your goals are better served if you decide to communicate and not overwhelm people with your presence. Strike up a conversation, tell people how you feel and ask them how they feel, this may be the most constructive way of dealing with issues right now. Carefully pick your battles.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now you are very energetic and inquisitive. You are ruled more by intellect than by emotion. This may pose a threat if you are in a loving relationship because you want to solve problems and conflicts by thinking them through. But maybe your partner is leaning more towards the emotional aspects of the problem. She/he might want to really feel your commitment instead of hearing you describe it. Look for balance, you can be rational but you will need some empathy too if you want this relationship to last.</p><p>If you’re single, now is the time to go out and show yourself to the world. Put on your party gear and get out there. Your positive energy will blow potential partners off their feet, and maybe one of them will even land at yours!</p>",
                        [GuidanceFields.WORK] : "<p>For the moment you are overflowing with energy and that doesn’t go unnoticed at work. You are bound to get more projects and tasks and all that energy makes it easy for you to keep up and even heighten the pace. Your star is really shining! Possibly the fact that you are so efficient and productive will lead to jealousy and criticism from colleagues. Don’t let that hold you back but don’t get into discussions either. Do not lower yourself to their level but be empathic, even understanding. After all, some day you may find yourself in their shoes, anything is possible.</p><p>The Knight of Swords is a very positive card if you are looking for a job. It tells you that things will probably go your way. Quite possibly someone will offer you exactly the job you were looking for!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Knight of Swords tells you that you are full of energy, you are totally ready to go out there and take action. Spiritually speaking you have two options. You can immerse yourself in teachings, books and courses and try to take in as much knowledge as you can. Or you could turn away from all that energy because after all, it represents the outside world. Maybe, if you turn towards your inner world, you can find some quiet, some harmony. And couldn’t that be exactly what you need right now to find a certain balance?</p>",
                    }
                },
                sp: {
                name: "Caballo de Espadas",
                court: "Caballo",
                suit: "Espadas",
                element: "🜁 - aire",
                theme: "Expresar tu propia opinión",    
                description: "El Caballo de Espadas se trata de mostrar lo que piensas y nos recuerda que podemos mostrar nuestra opinión al mundo que nos rodea",
                questions: ["¿Dejate escuchar tu propio opinión?", "¿Estás dispuesto a escuchar otras opiniones?", "¿Eres capaz de ajustar tu opinión sobre la base de buenos argumentos?", "¿Puedes luchar por tus derechos?" ], 

                cardoftheday: {
                    [GuidanceFields.DEFAULT] : "<p>Si saca esta carta le dice que en este momento usted tiene una gran cantidad de energía, y  está muy ansioso para usarlo. Antes de salir y abordar los problemas, conflictos preguntase si realmente quiere entrar en una batalla. Usted es muy enérgico en este momento y se puede ganar esas batallas, pero tal vez está mejor servido sus metas si usted decide comunicarse y no abrumar a la gente. Entablar una conversación, decirle a la gente cómo se siente y preguntarles cómo se sienten, esto puede ser la forma más constructiva de tratar las cuestiones en este momento. Recoja cuidadosamente sus batallas.</p>",
                    [GuidanceFields.RELATIONSHIP] : "",
                    [GuidanceFields.WORK] : "",
                    [GuidanceFields.SPIRITUAL] : "",
                },
                },
                fr : {
                    name : "Le Cavalier des Épées",
                    court : "Cavalier",
                    suit : "Épées",
                    element :  "🜁 - air",
                    theme : "exprimer sa propre opinion",    
                    description : "Le Cavalier des Épées, nous parle de montrer ce que l’on pense et que nous pouvons montrer notre opinion au monde qui nous entoure.",
                    questions : ["Faites-vous entendre votre opinion?", "Êtes-vous prêt à écouter l’opinions des autres?", "Êtes-vous capable d'ajuster votre opinion basé sur de bons arguments?", "Pouvez-vous vous battre pour vos droits?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Si vous tirez cette carte, elle vous dira que vous avez un excès d'énergie en ce moment. Et vous avez hâte de l'utiliser ! Mais avant de commencer des conflits avec les gens, vous pouvez vous demander si vous voulez vraiment vous battre. Vous êtes maintenant très énergique et vous pourriez gagner ce combat. Mais peut-être que vos objectifs seront mieux servis si vous communiquez au lieu de submerger les gens par votre simple présence. Commencez une conversation, dites aux gens ce que vous ressentez et demandez-leur ce qu'ils ressentent. Essayez de faire preuve d'empathie et de compassion. C'est peut-être la façon la plus constructive d'aborder les choses en ce moment. Choisissez vos combats avec soin.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now you are very energetic and inquisitive. You are ruled more by intellect than by emotion. This may pose a threat if you are in a loving relationship because you want to solve problems and conflicts by thinking them through. But maybe your partner is leaning more towards the emotional aspects of the problem. She/he might want to really feel your commitment instead of hearing you describe it. Look for balance, you can be rational but you will need some empathy too if you want this relationship to last.</p><p>If you’re single, now is the time to go out and show yourself to the world. Put on your party gear and get out there. Your positive energy will blow potential partners off their feet, and maybe one of them will even land at yours!</p>",
                        [GuidanceFields.WORK] : "<p>For the moment you are overflowing with energy and that doesn’t go unnoticed at work. You are bound to get more projects and tasks and all that energy makes it easy for you to keep up and even heighten the pace. Your star is really shining! Possibly the fact that you are so efficient and productive will lead to jealousy and criticism from colleagues. Don’t let that hold you back but don’t get into discussions either. Do not lower yourself to their level but be empathic, even understanding. After all, some day you may find yourself in their shoes, anything is possible.</p><p>The Knight of Swords is a very positive card if you are looking for a job. It tells you that things will probably go your way. Quite possibly someone will offer you exactly the job you were looking for!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Knight of Swords tells you that you are full of energy, you are totally ready to go out there and take action. Spiritually speaking you have two options. You can immerse yourself in teachings, books and courses and try to take in as much knowledge as you can. Or you could turn away from all that energy because after all, it represents the outside world. Maybe, if you turn towards your inner world, you can find some quiet, some harmony. And couldn’t that be exactly what you need right now to find a certain balance?</p>",
                    }
                },
                nl : {
                    name: "Ridder van Zwaarden",
                    court : "Ridder",
                    suit : "Zwaarden",
                    element : "🜁 - lucht",
                    theme : "je eigen mening naar buiten brengen",    
                    description : "De Ridder van Zwaarden gaat over laten zien wat je denkt en wijst ons er op dat we onze mening aan de wereld om ons heen mogen tonen.",
                    questions : [ "Laat jij je mening horen?", "Ben jij bereid ook naar andere meningen te luisteren?", "Ben jij in staat om je mening bij te stellen op basis van goede argumenten?", "Kun jij vechten voor je rechten?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Als je deze kaart kiest dan is het duidelijk dat je op dit ogenblik een overvloed aan energie hebt. En meer dan dat: je staat ook te trappelen om al die energie te gebruiken! Maar voor je erop uitgaat om problemen, conflicten of mensen aan te pakken, kan je je misschien eerst afvragen of je op dit moment echt wel een gevecht wil aangaan. Je bent nu energetisch behoorlijk sterk dus het is goed mogelijk dat je die gevechten ook wint. Maar misschien zal je je doelen eerder bereiken door te gaan communiceren in plaats van mensen te overweldigen met je krachtige uitstraling. Ga het gesprek aan, vertel die ander hoe jij je voelt en vraag de ander ook hoe zij/hij zich voelt. Probeer empathie en mededogen te tonen want op dit moment is dat de meest constructieve manier om problemen aan te pakken. Denk goed na en kies zorgvuldig welke strijd je wel en welke je niet wil aangaan.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Je voelt je op dit ogenblik heel energiek. Tegelijk zit je ook meer in je verstand dan in je gevoel. Dit kan in je liefdesrelatie eventueel voor problemen zorgen omdat je conflicten en onenigheid wil oplossen door erover na te denken, door te redeneren. Maar het is waarschijnlijk dat je partner meer bezig is met de emotionele aspecten van de situatie. Je partner wil op dit moment waarschijnlijk liever <strong>voelen</strong> dat je betrokken bent in plaats van te horen hoe jij je betrokkenheid beschrijft. Probeer hierin een evenwicht te vinden, je mag best rationeel zijn maar op dit moment is het belangrijk dat je ook empathisch bent, zeker als je een toekomst wil voor deze relatie.</p><p>Als je alleen bent dan is dit een goed moment om erop uit te gaan en jezelf te tonen aan de wereld. Dof jezelf op, trek je partykleren aan en ga feesten. Je positieve energie zal een diepe indruk maken op de mensen die je ontmoet en je kan hier best een mogelijke partner aan overhouden!</p>",
                        [GuidanceFields.WORK] : "<p>Op dit moment heb je een overvloed aan energie en dat valt je collega’s en meerderen echt wel op. Ga ervan uit dat je meer taken of zelfs nieuwe projecten krijgt. Je hebt nu trouwens zoveel energie dat je dit of zelfs een hoger tempo best aankan, je straalt als een ster! Het is best mogelijk dat je met wat jaloezie van collega’s te maken krijgt omdat je op dit moment zo efficiënt en productief bent. Laat je daar niet door tegenhouden en laat je ook niet verleiden tot discussies. Verlaag jezelf niet tot dat niveau, integendeel! Probeer empathisch te zijn, zelfs begrip op te brengen voor hun gevoelens en gedachten. Tenslotte is het best mogelijk dat je op een dag in hun schoenen zal staan.</p><p>Als je op zoek bent naar werk dan is de Ridder van Zwaarden een heel positieve kaart die je vertelt dat de dingen waarschijnlijk zo zullen verlopen als jij het graag wil. Het zit er dik in dat iemand je nèt die baan aanbiedt waar je al een tijd naar op zoek bent.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Ridder van Zwaarden vertelt je dat je op dit moment heel veel energie hebt. Je bent er klaar voor om de wereld tegemoet te treden en in actie te schieten! Spiritueel gezien heb je twee opties. Je kan jezelf onderdompelen in boeken, cursussen en leringen en op die manier proberen om zoveel mogelijk kennis te vergaren. Maar je kan ook afstand nemen van al die energie die in je borrelt omdat die tenslotte ook deel uitmaakt van de jachtige wereld. En als je je helemaal focust op je innerlijke rijkdom kan je misschien stilte en harmonie vinden. Is het niet mogelijk dat het net dàt is wat je nodig hebt om in evenwicht te geraken?</p>",
                    }
                },
                de : {
                    name: "Ritter der Schwerter",
                    court : "Ritter",
                    suit : "Schwerter",
                    element : "🜁 - lucht",
                    theme : "Seine eigene Meinung äußern",    
                    description : "Der Ritter der Schwerter spricht darüber zu zeigen, was wir denken und dass wir unsere Meinung der Welt um uns herum zeigen können.",
                    questions : [ "Haben Sie ihre eigene Meinung kundgetan?", "Sind Sie bereit, die Meinungen anderer anzuhören?", "Können Sie Ihre Meinung auf der Grundlage guter Argumente anpassen ?", "Können Sie für Ihre Rechte kämpfen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Wenn Sie diese Karte ziehen, wird sie Ihnen sagen, dass Sie im Moment ein Übermaß an Energie haben. Und dass Sie es kaum erwarten können, sie einzusetzen! Aber bevor Sie Konflikte mit Menschen vom Zaun brechen könnten Sie sich fragen, ob Sie wirklich in einen Kampf geraten wollen. Sie sind jetzt sehr energisch und könnten also diesen Kampf tatsächlich gewinnen. Aber vielleicht ist Ihren Zielen besser gedient, wenn Sie kommunizieren anstatt Menschen mit Ihrer bloßen Präsenz zu überwältigen. Beginnen Sie ein Gespräch, sagen Sie den Menschen, wie Sie sich fühlen und fragen Sie nach ihren Gefühlen. Versuchen Sie, Empathie und Mitgefühl zu zeigen. Dies könnte die konstruktivste Art sein, in diesem Moment mit den Dingen umzugehen. Wählen Sie Ihre Kämpfe sorgfältig aus.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Gerade jetzt sind Sie sehr energisch und neugierig. Sie werden mehr vom Verstand regiert als von Emotionen. Dies kann eine Gefahr darstellen, wenn Sie in einer liebevollen Beziehung sind, weil Sie Probleme und Konflikte durch das Denken lösen wollen. Aber vielleicht lehnt Ihr Partner mehr auf den emotionalen Aspekten des Problems. Er / Sie möchte vielleicht Ihr Verbundenheit wirklich fühlen statt hören wie Sie es beschreiben. Suchen Sie nach Gleichgewicht. Obwohl Sie rationell sein können brauchen Sie hier auch einigermaßen Empathie, wenn Sie diese Beziehung aufrecht behalten wollen.</p><p>Wenn Sie alleine sind, dann ist jetzt die Zeit aus zu gehen und Sich die Außenwelt zu zeigen. Setzen Sie Ihren Party-hut auf und gehen Sie raus. Ihre positive Energie wird wahrscheinlich einen potenzielle Partner von den Füßen blasen aber vielleicht wird einer von ihnen auch bei Ihnen landen!</p>",
                        [GuidanceFields.WORK] : "<p>Für den Moment sind Sie mit Energie überfüllt und das bleibt nicht unbemerkt bei der Arbeit. Sie sind dabei, mehr Projekte und Aufgaben zu erhalten und all diese Energie macht es einfach für Sie, um Schritt zu halten und sogar das Tempo noch zu erhöhen. Ihr Stern ist wirklich glänzend! Möglicherweise kann die Tatsache, dass Sie so effizient und produktiv sind, Anlass sein zur Eifersucht und Kritik von Kollegen. Lassen Sie Sich davon nicht zurückhalten, aber mischen Sie Sich nicht in die Diskussionen. Lassen Sie Sich nicht niedersenken auf Ihr Niveau aber seien Sie empathisch, sogar verständnisvoll Denn eines Tages können Sie sich in Ihre Schuhe befinden, alles ist ja möglich.</p><p>Der Ritter der Schwerter ist eine sehr positive Karte, wenn Sie nach einem Job suchen. Sie sagt Ihnen, dass die Dinge wahrscheinlich laufen wie Sie möchten. Sehr wahrscheinlich wird jemand Ihnen genau den Job bieten, den Sie gesucht haben!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Der Ritter der Schwerter sagt Ihnen, dass Sie voller Energie sind, dass Sie völlig bereit sind hinauszugehen und zu handeln. Spirituell gesprochen haben Sie zwei Möglichkeiten. Sie können untertauchen in Lehre, Bücher und Kurse und versuchen, um so viel Wissen wie möglich zu Ihnen zu nehmen. Oder Sie gehen weg von all dieser Energie, weil es immerhin die Außenwelt darstellt. Vielleicht sollten Sie Sich Ihrer inneren Welt zuwenden, wo Sie Ruhe und Harmonie finden können. Und könnte das nicht gerade genau das sein, was Sie jetzt brauchen, um ein gewisses Gleichgewicht zu finden?</p>",
                    }
                }
            },
            {
                filename: "62",
                numerology : 13,
                en : {
                    name: "Queen of Swords",
                    court : "Queen",
                    suit : "Swords",
                    element : [ "🜁 - air", "🜄 - water" ],
                    theme : "taking care for what you think",    
                    description : "Queen of Swords talks about being attentive to your thoughts. Make room for your thoughts.",
                    questions : [ "What do you think?", "Can you make a decision, cut to the chase?", "Are you using your mind and your intuition?", "Can you express yourself clearly?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Queen of Swords is all about intelligence, independence of thought, vigilance. She invites you to use your brain to tackle problems and questions, not your emotionality. Now is the time to look at your life from a distance and decide which issues are to be resolved immediately and which ones can wait. You probably know what you want and need, and you only have to decide what to do to accomplish it, how to go about it. Be careful, think about other people’s sensitivities and boundaries, this card has a very powerful intellectual energy that might be offensive to some. Right now you tend to connect to people by intellect rather than by emotion, which may be hard to understand or accept for others.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now you are feeling strong and rational and this reflects on your attitude in your relationships too. Especially in an intimate relationship it is important to not be overbearing. You may address every problem and question cerebrally for the moment, but that doesn’t mean your partner has the same point of view. He/she may be more emotional than you are. Try to be nice, and accept your partner’s motivations and arguments even if they’re not rational. If you want to stay in this relationship it is essential to find common ground.</p><p>If you’re looking for love it’s important not to be too self-confident because sometimes you are bordering on arrogance. That’s not what people look for in a potential partner.</p>",
                        [GuidanceFields.WORK] : "<p>For the moment you have a quick and sharp mind, Getting straight to the point seems easy for you. Still it’s important to take into account that not all your colleagues (or even superiors) are ready to hear the naked truth. Try to respect their boundaries. Maybe it would even be wise to present your convictions in a nice package. If you do that, people will find it easier to accept what you have to say.</p><p>If you are looking for a job right now, your intellectual abilities will help you to present yourself in the best way possible. You will almost certainly make a good impression in job interviews. Use this to your advantage, you are sure to find the job you want!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Even if the Queen of Swords symbolizes your brain power, it is clear that underneath all those thoughts there are emotions to be found. Go past the intellectual and dip into the source of your feelings. Let them come over you, find out where all these emotions are coming from. The things you discover will help you find a brand new spiritual path. In this period of your life you will be able to discern spiritual messages in everyday life all the time. Be open to them, they will guide you. </p>",
                    }
                },
                fr : {
                    name : "La Reine des Épées",
                    court : "Reine",
                    suit : "Épées",
                    element :  ["🜁 - air", "🜄 - eau" ],
                    theme : "prendre soin de ce que vous pensez",    
                    description : "La Reine des Épées, nous parle de faire attention à ce que l’on pense. Donnez de l'espace à ce que vous pensez.",
                    questions : [ "Que pensez-vous ?", "Pouvez-vous prendre une décision, trancher ?", "A cela, utilisez-vous et votre esprit et votre cœur ?", "Vous-êtes-t-il possible d’exprimer votre opinion ?" ],
                                        
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La \"Reine d’Épée\", c'est l'intelligence, l'indépendance de pensée et la vigilance. Elle vous invite à utiliser votre esprit, et non vos sentiments, pour résoudre des problèmes et des questions. Maintenant, il est temps de regarder votre vie d'une certaine distance. Décidez quels problèmes doivent être résolus immédiatement et lesquels peuvent attendre. Vous savez probablement ce que vous voulez et ce dont vous avez besoin. Il ne vous reste plus qu'à établir un plan pour atteindre ces objectifs. Décidez comment vous le faites. Soyez prudent, faites attention aux sensibilités et aux limites des autres. Cette carte symbolise une très forte énergie mentale que certains pourraient trouver offensante. En ce moment, vous êtes plus enclin à vous connecter avec votre esprit et non avec vos émotions. Cela peut être difficile à comprendre ou à accepter pour certains.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now you are feeling strong and rational and this reflects on your attitude in your relationships too. Especially in an intimate relationship it is important to not be overbearing. You may address every problem and question cerebrally for the moment, but that doesn’t mean your partner has the same point of view. He/she may be more emotional than you are. Try to be nice, and accept your partner’s motivations and arguments even if they’re not rational. If you want to stay in this relationship it is essential to find common ground.</p><p>If you’re looking for love it’s important not to be too self-confident because sometimes you are bordering on arrogance. That’s not what people look for in a potential partner.</p>",
                        [GuidanceFields.WORK] : "<p>For the moment you have a quick and sharp mind, Getting straight to the point seems easy for you. Still it’s important to take into account that not all your colleagues (or even superiors) are ready to hear the naked truth. Try to respect their boundaries. Maybe it would even be wise to present your convictions in a nice package. If you do that, people will find it easier to accept what you have to say.</p><p>If you are looking for a job right now, your intellectual abilities will help you to present yourself in the best way possible. You will almost certainly make a good impression in job interviews. Use this to your advantage, you are sure to find the job you want!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Even if the Queen of Swords symbolizes your brain power, it is clear that underneath all those thoughts there are emotions to be found. Go past the intellectual and dip into the source of your feelings. Let them come over you, find out where all these emotions are coming from. The things you discover will help you find a brand new spiritual path. In this period of your life you will be able to discern spiritual messages in everyday life all the time. Be open to them, they will guide you. </p>",
                    }
                },
                sp:{
                    name:"Reina de Espadas",
                    court: "Reina",
                    suit: "Espadas",
                    element: ["🜁 - aire", "🜄 - agua" ],
                    theme: "Cuidando lo que piensas",    
                    description: "La Reina de las Espadas se trata de prestar atención a lo que piensas. Dale espacio a lo que piensas",
                    questions: ["¿Qué piensas?", "¿Puedes tomar una decisión, cortar el nudo?", "¿Usas tu mente y tus sentimientos?", "¿Puedes expresar tu opinión claramente?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La Reina de Espadas es todo acerca de la inteligencia, la independencia de pensamiento, de vigilancia. Ella invita a utilizar su cerebro para hacer frente a los problemas y preguntas, no su emocionalidad. Ahora es el momento de mirar su vida desde la distancia y decidir qué cuestiones deben resolverse de inmediato y cuáles pueden esperar. Usted probablemente sabe lo que quiere y necesita, y sólo tiene que decidir qué hacer para lograrlo, la forma de hacerlo. Ten cuidado, pensar en las sensibilidades y los límites de la gente, esta tarjeta tiene una energía muy poderosa intelectual que puede ser ofensivo para algunos. Ahora inclina a conectar a las personas por el intelecto y no por la emoción, que puede ser difícil de entender o aceptar a los demás.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Koningin van Zwaarden",
                    court : "Koningin",
                    suit : "Zwaarden",
                    element : [ "🜁 - lucht", "🜄 - water" ],
                    theme : "zorgdragen voor wat je denkt",    
                    description : "De Koningin van Zwaarden gaat over aandacht schenken aan wat je denkt. Geef wat je denkt de ruimte.",
                    questions : [ "Wat denk jij?", "Kun je een beslissing nemen, de knoop doorhakken?", "Gebruik je daarbij én je verstand én je gevoel?", "Kun jij je mening helder verwoorden?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Koningin van Zwaarden draait rond intelligentie, onafhankelijk denken en alert zijn. Ze nodig je uit om je verstand te gebruiken, niet je gevoel, als je problemen en vragen wil aanpakken. En op dit moment is het verstandig om vanop een afstand naar je eigen leven te kijken. Beslis daarna welke problemen je onmiddellijk wil oplossen en welke nog even kunnen wachten. Jij weet immers het beste wat je wil en nodig hebt. Wat je nu te doen staat is een plan maken om je doelen te bereiken. Beslis welke aanpak je voorkeur geniet. Wees voorzichtig hierin, houd rekening met de gevoelens en grenzen van de mensen om je heen.</p><p>Deze kaart is symbolisch voor een sterke intellectuele energie die je er soms toe kan brengen dat je anderen kwetst of beledigt. Op dit punt heb je immer de neiging om contact te zoeken met anderen op een intellectueel niveau zonder er emoties bij te betrekken. En net deze houding kan voor sommigen moeilijk te aanvaarden en te begrijpen zijn.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Je voelt je op dit moment sterk en rationeel en deze houding is ook terug te vinden in je standpunt ten opzichte van relaties. Let hiermee op, zeker in een liefdesrelatie is het belangrijk om niet te dominant te zijn, je mening niet op te dringen. Jij hebt nu de neiging om alle problemen en vragen op een verstandelijke manier te benaderen en dat is natuurlijk je volste recht. Maar het betekent niet automatisch dat je geliefde ook in zijn of haar verstand zit. Misschien is het net het omgekeerde en is je partner zelf heel emotioneel op dit moment. Probeer vriendelijk en meevoelend te zijn, aanvaard dat de motieven en argumenten van je partner evenwaardig zijn aan de jouwe, ook al zijn ze niet rationeel. Als je wil dat deze relatie blijft duren dan is het essentieel dat je mekaar ergens in het midden vindt.</p><p>Als je op zoek bent naar een geliefde dan is het belangrijk dat je je niet te zelfverzekerd opstelt, je zou immers arrogant kunnen overkomen en dat vindt niemand aantrekkelijk.</p>",
                        [GuidanceFields.WORK] : "<p>Op dit moment is je brein sterk en snel, je vindt het gemakkelijk om de kern van een probleem of een vraagstelling te zien. Houd er toch rekening mee dat je collega’s (en misschien zelfs je superieuren) er nog niet klaar voor zijn om de naakte waarheid te horen. Probeer hun grenzen te respecteren. Probeer je inzichten aan te bieden in een aantrekkelijke verpakking. Als je het op deze manier aanpakt zal je merken dat mensen het makkelijker vinden om jouw meningen te aanvaarden.</p><p>Als je op zoek bent naar werk dan zal je intellect je helpen om jezelf voor te stellen op de best mogelijke manier. Het is bijna zeker dat je een goede indruk zal maken bij interviews. Gebruik dit voordeel en je zal vast de baan vinden waar je naar op zoek bent!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Ook al is de Koningin van Zwaarden symbolisch voor je denkvermogen, het is natuurlijk vanzelfsprekend dat onder al die gedachten ook emoties te vinden zijn. Zoek je weg voorbij het intellectuele en duik in de rivier van je emoties, laat ze over je heen spoelen en probeer bij de bron te geraken, ontdek op die manier waar al die gevoelens vandaan komen. Wat je nu ontdekt zal je de weg wijzen naar een gloednieuw spiritueel pad. Je zal merken dat je in alle mogelijke aspecten van het dagelijkse leven spirituele boodschappen kan ontdekken. Sta hiervoor open, deze boodschappen zijn je gidsen.</p>",
                    }
                },
                de : {
                    name: "Königin der Schwerter",
                    court : "Königin",
                    suit : "Schwerter",
                    element : [ "🜁 - Luft", "🜄 - Wasser" ],
                    theme : "Darauf achten, was man denkt.",    
                    description : "Die Königin der Schwerter spricht darüber, aufmerksam zu sein, worüber wir denken. Geben Sie dem Raum, worüber Sie denken.",
                    questions : [ "Was denken Sie?", "Können Sie eine Entscheidung treffen, bestimmen?", "Nutzen Sie Ihren Geist und Ihr Herz?", "Können Sie Ihre Meinung äußern?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Bei der „Königin der Schwerter“ geht es um Intelligenz, Unabhängigkeit des Denkens und Wachsamkeit. Sie lädt Sie ein, Ihren Verstand und nicht Ihre Gefühle zu verwenden, wenn es darum geht, Probleme und Fragen zu lösen. Jetzt ist es Zeit, auf Ihr Leben mit einem gewissen Abstand zu schauen. Entscheiden Sie sich, welche Probleme sofort gelöst werden müssen und welche warten können. Sie wissen wahrscheinlich, was Sie wollen und brauchen. Jetzt müssen Sie nur noch einen Plan machen, um diese Ziele zu erreichen. Entscheiden Sie sich, wie Sie das machen. Seien Sie vorsichtig, achten Sie auf Empfindlichkeiten und Grenzen anderer Menschen. Diese Karte symbolisiert eine sehr starke geistige Energie, an der manche Anstoß nehmen könnten. Zurzeit neigen Sie mehr dazu, sich mit Verstand und nicht mit Emotionen mit anderen zu verbinden. Dies könnte für manche schwierig zu verstehen oder zu akzeptieren sein.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Gerade jetzt Sie fühlen Sie Sich stark und vernünftig und dies spiegelt sich auf Ihrer Haltung in Ihren Beziehungen. Vor allem in einer intimen Beziehung ist es wichtig, nicht aufdringlich zu sein. Sie können jedes Problem anrühren und in Frage stellen, aber das bedeutet nicht, dass Ihr Partner den gleichen Standpunkt hat. Er / sie kann emotionaler sein als Sie sind. Versuchen Sie, nett zu sein und akzeptieren Sie Beweggründe und Argumente Ihres Partners, auch wenn sie nicht rationell sind. Wenn Sie in dieser Beziehung bleiben wollen ist es wichtig, eine gemeinsame Basis zu finden.</p><p>Wenn Sie nach die Liebe suchen, ist es wichtig, nicht zu selbstbewusst zu sein, weil man manchmal nach Arroganz neigen wird. Das ist nicht das, was die Menschen suchen in einem potenziellen Partner.</p>",
                        [GuidanceFields.WORK] : "<p>Zur Zeit haben Sie einen schnellen und scharfen Verstand, direkt auf Ihrem Ziel zuzugehen scheint einfach für Sie. Trotzdem ist es wichtig zu berücksichtigen, dass nicht alle Ihre Kollegen (oder sogar Vorgesetzten) bereit sind, die nackte Wahrheit zu hören. Versuchen Sie Ihre Grenzen zu respektieren. Vielleicht wäre es auch ratsam, Ihre Überzeugungen in einem netten Paket zu präsentieren. Wenn Sie das tun, werden die Leute es einfacher finden zu akzeptieren, was Sie zu sagen haben.</p><p>Wenn Sie sich jetzt nach einem Job suchen, werden Ihre spirituellen Fähigkeiten Ihnen helfen, sich in der bestmöglichen Weise zu präsentieren. Sie werden praktisch sicher einen guten Eindruck in Bewerbungsgesprächen hinterlassen. Verwenden Sie diese zu Ihrem Vorteil und Sie werden Sich sicher sein können, dass Sie die Arbeit bekommen, die Sie wollen!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Auch wenn die Königin der Schwerter der Macht Ihres Gehirns symbolisiert, ist es klar, dass unter all diesen Gedanken Emotionen zu finden sind. Gehen Sie an das geistige vorbei und tauchen Sie ein in die Quelle der Gefühle. Lassen Sie sie über Sich kommen um herauszufinden, wo all diese Emotionen herkommen. Die Dinge, die Sie entdecken, werden Ihnen helfen, einen brandneuen spirituellen Weg zu finden. In dieser Zeit Ihres Lebens werden Sie in der Lage sein, zu jeder Zeit spirituelle Botschaften im Alltag zu erkennen. Seien Sie offen für sie, sie werden Sie zu führen.</p>",
                    }
                }
            },
            {
                filename: "63",
                numerology : 14,
                en : {
                    name: "King of Swords",
                    court : "King",
                    suit : "Swords",
                    element : [ "🜁 - air", "🜂 - fire" ],
                    theme : "being pro-active from ones’ own thinking",    
                    description : "King of Swords talks about effectively acting from the own mind. Go create.",
                    questions : [ "Are you conscious about your knowledge?", "Are you acting accordingly?", "Can you clearly state what you think?", "Can you think analytically?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The King of Sword portrays a person who is intelligent, serious, intellectual, someone with a quick mind.  You can be serious and clever with an excellent judgment but it is also possible that you resort to sarcasm and aggression, maybe even turn into a bully sometimes. It is up to you to decide if you want to be understanding, analytical and insightful or stubborn and even cynical.</p><p>At this time in your life it is important for you to realize that all these aspects are part of your personality. Be conscious of this and choose who and what you really want to be.</p><p>It is also possible that you are being confronted with a stubborn person who is unwilling to change his or her mind. Maybe, after some soul searching, it would be best to decide to change yourself to make the situation bearable.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>When you draw this card it is possible that you are behaving very rationally in your personal relationships. You feel the need to approach problems and conflicts from an intellectual point of view and to not take into account the emotions involved. This standpoint will help you get to the heart of matters but at some point you will have to accept that your partner isn’t as intellectually minded as you are and emotions must be part of the equation.</p><p>It’s also possible that you are in a relationship with someone who is rather dominant and cerebral. Maybe she/he even goes too far and oversteps your boundaries now and then. You will not be able to change this person’s behavior so it’s a matter of standing your ground and protecting yourself and what’s important to you.</p><p>If you are single and looking for a partner, try to remember that the right person isn’t only to be found by following intellectual standards. What you feel has to count too.</p>",
                        [GuidanceFields.WORK] : "<p>For the moment you find it easy to do your job, you behave in a tactical, flexible manner. Maybe you even are a bit manipulative at times. Is this truly who you want to be?</p><p>Or maybe at work you are being confronted with someone who is very outspoken. If you don’t like this attitude, ask yourself why because you may be a bit too emotional right now.</p><p>If you are looking for work, no matter how secure and selfconfident you are, remember that it’s best to follow the lead the interviewer gives you. You aren’t in this interview to win a battle but to show your qualities.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Right now you are approaching spirituality in an intellectual way. You need to fully understand what is being said before you can apply it or even accept it. Now would be a good time to go out and seek a strong, ethically sound mentor who can give you all the information you require to make an assessment. And because you are very clear minded right now, it may be good for you to meditate on a regular basis. No matter how you approach spirituality, right now you need to be active.</p>",
                    }
                },
                fr : {
                    nom : "Le Roi des Épées",
                    court : "Roi",
                    suit : "Épées",
                    element :  ["🜁 - air", "🜄 - feu"],
                    theme : "Agir activement de ses propre pensées",    
                    description : "Le Roi des Épées, nous parle d’agir effectivement de ses propres pensées. Donnez forme à cela.", 
                    questions :  [ "Etes-vous conscient de ce que vous savez?", "Agissez-vous de tel ?", "Pouvez-vous vous exprimer clair et précis?", "Pouvez-vous réfléchir analytiquement?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Roi d’Épée\" représente une personne intelligente, sérieuse et intellectuelle, à l'esprit vif. Elle peut être sérieuse et intelligente, avoir un excellent jugement.  Mais il est aussi possible que vous soyez sarcastique et agressif. Est-ce que certaines attitudes vous rappellent l’attitude d'un tyran ? Vous devez décider qui vous vous voulez être, comprendre, analyser ou être têtu et même cynique.</p><p>À ce stade de votre vie, il est important de réaliser que tous ces aspects font partie de votre personnalité. Soyez conscient de cela et déterminez qui est-ce qui vous voulez vraiment être.</p><p>Il est également possible d'être confronté à une personne persistante qui n'est pas prête à changer d'avis. Après un examen de conscience, vous constaterez peut-être qu'il serait préférable que vous changiez d'avis pour rendre la situation plus supportable.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>When you draw this card it is possible that you are behaving very rationally in your personal relationships. You feel the need to approach problems and conflicts from an intellectual point of view and to not take into account the emotions involved. This standpoint will help you get to the heart of matters but at some point you will have to accept that your partner isn’t as intellectually minded as you are and emotions must be part of the equation.</p><p>It’s also possible that you are in a relationship with someone who is rather dominant and cerebral. Maybe she/he even goes too far and oversteps your boundaries now and then. You will not be able to change this person’s behavior so it’s a matter of standing your ground and protecting yourself and what’s important to you.</p><p>If you are single and looking for a partner, try to remember that the right person isn’t only to be found by following intellectual standards. What you feel has to count too.</p>",
                        [GuidanceFields.WORK] : "<p>For the moment you find it easy to do your job, you behave in a tactical, flexible manner. Maybe you even are a bit manipulative at times. Is this truly who you want to be?</p><p>Or maybe at work you are being confronted with someone who is very outspoken. If you don’t like this attitude, ask yourself why because you may be a bit too emotional right now.</p><p>If you are looking for work, no matter how secure and selfconfident you are, remember that it’s best to follow the lead the interviewer gives you. You aren’t in this interview to win a battle but to show your qualities.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Right now you are approaching spirituality in an intellectual way. You need to fully understand what is being said before you can apply it or even accept it. Now would be a good time to go out and seek a strong, ethically sound mentor who can give you all the information you require to make an assessment. And because you are very clear minded right now, it may be good for you to meditate on a regular basis. No matter how you approach spirituality, right now you need to be active.</p>",
                    }
                },
                sp:{
                    name:"El Rey de espadas",
                    court: "Rey",
                    suit: "Espadas",
                    element: ["🜁 - aire", "🜂 – fuego" ],
                    theme: "Actuar activamente a partir de los propios pensamientos",    
                    description: "El Rey de Espadas se trata de actuar desde tus propios pensamientos. Dale forma",
                    questions: ["¿Eres consciente de lo que sabes?", "¿Estás actuando en consecuencia?", "¿Puedes hablar fuertamente y claramente?", "¿Puedes pensar analíticamente?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Koning van Zwaarden",
                    court : "Koning",
                    suit : "Zwaarden",
                    element : [ "🜁 - lucht", "🜂 - vuur" ],
                    theme : "actief handelen vanuit je eigen denken",    
                    description : "De Koning van Zwaarden gaat over daadwerkelijk handelen vanuit je eigen denken. Geef het vorm.",
                    questions : [ "Ben jij je bewust van wat je weet?", "Handel je daar ook naar?", "Kun jij je helder en duidelijk uitspreken?", "Kun jij analytisch denken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Koning van Zwaarden is intelligent en ernstig. Hij is ook intellectueel en heeft een snelle, scherpe geest. Als je deze kaart trekt dan is het mogelijk dat je slim bent en een weloverwogen inzicht hebt maar je kan ook doorslaan naar sarcasme en agressie. Misschien ben je soms zelfs een beetje een tiran. Het is nu aan jou om te beslissen of je begrijpend, analytisch en rijk aan inzichten wil zijn of dat je koppig en cynisch door het leven wil gaan.</p><p>Je bent nu op een punt gekomen waar je beseft dat al deze eigenschappen deel uitmaken van je persoonlijkheid. Wees je hiervan bewust en beslis dan wie en wat je echt wil zijn.</p><p>Deze kaart kan er ook op wijzen dat je te maken hebt met een koppig iemand die totaal niet bereid is om van mening te veranderen. Als je de tijd neemt om diep na te denken over deze situatie, kom je misschien tot de conclusie dat het voor <strong>jou</strong> het beste zou zijn om van mening te veranderen zodat de situatie draaglijk wordt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je deze kaart trekt dan is het mogelijk dat je je op dit moment erg rationeel opstelt in je persoonlijke relaties. Je hebt de behoefte om problemen en conflicten op een intellectuele manier te benaderen zonder rekening te houden met de emoties die er deel van uitmaken. Dit standpunt zal je helpen om door te dringen tot de kern van de zaak. Maar op een gegeven moment zal je moeten aanvaarden dat je partner niet op dezelfde golflengte zit en dat je ook met een aantal emoties rekening zal moeten houden.</p><p>Deze kaart kan ook betekenen dat je een relatie hebt met iemand die behoorlijk dominant en verstandelijk is. Mogelijk gaat deze persoon daar te ver in en gaat hij/zij geregeld over je grenzen heen. Jij kan het gedrag van deze persoon niet veranderen dus is het belangrijk dat je jezelf en je grenzen zorgvuldig in het oog houdt. Zorg dat je snel genoeg aan de bel trekt!</p><p>Als je single bent en op zoek naar een partner, denk er dan aan dat je de ware niet vindt door enkel intellectuele normen te gebruiken. Je moet ook rekening houden met wat je voelt bij iemand.</p>",
                        [GuidanceFields.WORK] : "<p>Op dit moment is je werk doen voor jou een fluitje van een cent. Je stelt je tactisch en soepel op en balanceert soms op het randje van manipuleren. Ben je er zeker van dat je op deze manier te werk wil gaan?</p><p>Het is ook mogelijk dat je op je werkplek geconfronteerd wordt met iemand die er heel uitgesproken meningen op nahoudt die hij/zij ook met de nodige overtuiging verkondigt. Als je last hebt van dit gedrag vraag je dan af hoe het komt. Misschien ben je zelf een beetje te emotioneel op dit moment, hoe komt dit, waardoor wordt dit veroorzaakt?</p><p>Als je op dit moment op zoek bent naar werk, denk er dan aan dat het altijd verstandig is om bij een gesprek de leiding bij de interviewer te laten. Het maakt niet uit hoe zelfzeker je bent, dit gesprek is geen wedstrijd, je hoeft niet te winnen, je hoeft de ander enkel duidelijk te maken wat je kwaliteiten zijn.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je kijkt op een verstandelijke manier naar spiritualiteit. Het is erg belangrijk voor jou om helemaal te begrijpen wat er gezegd wordt voor je het kan toepassen of zelfs maar aanvaarden. Op dit moment is het een goed idee om een mentor te zoeken die een sterke persoonlijkheid en vaste, duidelijke overtuigingen heeft. Enkel zo’n persoon kan je alle informatie geven die je op dit moment nodig hebt om een mening te vormen. Het is ook belangrijk dat je geregeld mediteert. Het maakt niet uit wat jouw spirituele inzichten en overtuigingen zijn, op dit moment heb je behoefte aan een actieve aanpak.</p>",
                    }
                },
                de : {
                    name: "König der Schwerter",
                    court : "König",
                    suit : "Schwerter",
                    element : [ "🜁 - Luft", "🜂 - Feuer" ],
                    theme : "Aktives Handeln nach seinen eigenen Vorstellungen",    
                    description : "Der König der Schwerter spricht darüber, wahrhaft nach seinen eigenen Gedanken zu handeln. Geben Sie dem Ganzen Form.",
                    questions : [ "Sind Sie sich dessen bewusst, was Sie wissen?", "Benehmen Sie sich auch so?", "Können Sie sich klar und deutlich ausdrücken?", "Können Sie analytisch denken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der „König der Schwerter“ stellt eine Person dar, die intelligent, ernsthaft und intellektuell ist, jemand mit einem schnellen Verstand. Sie können seriös und intelligent sein, ein ausgezeichnetes Urteilsvermögen haben. Aber es ist auch möglich, dass Sie sarkastisch und aggressiv sind. Vielleicht werden Sie manchmal ein richtiger Schikanierer. Sie müssen entscheiden, was Sie sein möchten, verständnisvoll, voller analytischer Kraft oder stur und sogar zynisch.</p><p>Zu diesem Zeitpunkt ist es in Ihrem Leben wichtig zu erkennen, dass alle diese Aspekte Teil Ihrer Persönlichkeit sind. Seien Sie sich dessen bewusst und bestimmen Sie, wer und was Sie wirklich sein wollen.</p><p>Es ist auch möglich, dass Sie mit einer hartnäckigen Person konfrontiert werden, die nicht bereit ist, seine oder ihre Meinung zu ändern. Nach einiger Gewissensprüfung stellen Sie vielleicht fest, dass es am besten für Sie wäre, Ihre Meinung zu ändern um die Situation erträglicher zu machen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie diese Karte ziehen es möglich ist, dass Sie sehr rationell in Ihren persönlichen Beziehungen verhalten werden. Sie haben das Bedürfnis, Probleme und Konflikte von einem intellektuellen Standpunkt anzunähern und die beteiligten Emotionen nicht zu berücksichtigen. Dieser Standpunkt wird Ihnen helfen, zum Herzen der Dinge zu geraten, aber an einem gewissen Punkt müssen Sie akzeptieren, dass Ihr Partner nicht so intellektuell gesinnten ist wie Sie und die Emotionen müssen ein Teil der Gleichung sein.</p><p>Es ist auch möglich, dass Sie in einer Beziehung mit jemandem sind, der sehr dominant ist und zerebral. Vielleicht geht sie / er sogar zu weit und überschreitet ab und zu mal Ihre Grenzen. Sie sind nicht in der Lage, das Verhalten dieser Person zu ändern, so müssen Sie nur zu Ihrem Boden halten und all das schützen, was Ihnen wichtig ist.</p><p>Wenn Sie alleine sind und auf der Suche nach einem Partner, versuchen Sie daran zu denken, dass das Finden der richtigen Person nicht nur eine Sache ist von intellektuellen Ansichten, sondern auch von Gefühlen und Emotionen. Sie müssen auch zeigen können was Sie fühlen.</p>",
                        [GuidanceFields.WORK] : "<p>Zur Zeit finden Sie es einfach, Ihre Arbeit zu tun. Sie verhalten Sich auf einer taktischen, flexiblen Weise. Vielleicht sind Sie sogar ein bisschen manipulativ ab und zu. Ist das wirklich, wie Sie sein möchten?</p><p>Oder vielleicht arbeiten Sie zusammen mit jemanden, der ziemlich offen und konfrontierend sein kann. Vielleicht mögen Sie diese Haltung nicht. Wenn das der Fall ist, fragen Sie Sich warum. Sie sind im Moment ziemlich emotional.</p><p>Wenn Sie auf der Suche nach Arbeit sind, egal wie überzeugt und selbst versichert Sie sind, vergessen Sie nicht, dass es am besten ist, die Hinweise Ihrer Interviewer zu folgen. Sie sind nicht in diesem Interview um eine Schlacht zu gewinnen, sondern nur um Ihre Qualitäten zu zeigen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Gerade jetzt nähern Sie Sich die Spiritualität auf einer intellektuellen Weise an. Sie brauchen sie erst völlig zu verstehen, bevor Sie sie anwenden oder sogar akzeptieren wollen. Jetzt wäre ein guter Zeitpunkt aus zu gehen und einen starken, ethischen Mentor zu suchen, der Ihnen alle benötigen Informationen geben kann, um eine Bewertung vorzunehmen. Und weil Sie gerade jetzt sehr klar denken, könnte es gut sein, dass Sie anfangen regelmäßig zu meditieren. Egal wie man Spiritualität angeht, Sie müssen jetzt aktiv sein.</p>",
                    }
                }
            },
            {
                filename: "64",
                numerology : 1,
                en : {
                    name: "Ace of Pentacles",
                    suit : "Pentacles",
                    element : "🜃 - earth",
                    theme : "I do",    
                    description : "Ace of Pentacles, element  earth, talks about what you do in its totality. Everything is potentially present, but what will you do?",
                    questions : [ "What could you create?", "Do you have the necessary skills?", "What will you actually do?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Aces in general are cards that point out new beginnings, possibilities. The Ace of Pentacles is about happiness, inner wealth, opportunities. This doesn't mean you should expect to get it all for free, without actively going out and looking, but in general this card tells you that you are very likely at a positive point in your life, generally feeling good. Possibly things are even taking a turn for the better, this could be financially but also in terms of relationship or career.</p><p>The Ace of Pentacles can be compared to The Ace of Cups which is also a very lucky card.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a loving relationship this card signifies that you will soon enjoy or already are enjoying great times, you feel happy within your relationship, you cherish your partner and the relationship is getting more profound and meaningful. You and your partner might even be entering a whole new and intense stage of commitment.</p><p>If you are single the Ace of Pentacles tells you it is very well possible you will meet someone interesting shortly. This is a perfect time to go out and meet new people. Enjoy the company of strangers and look around for that one person you might want to be with.</p>",
                        [GuidanceFields.WORK] : "<p>The Ace of Pentacles is a generally positive card. It tells you that you are doing very well at your job.You may soon be getting a promotion or new and more responsible tasks, and of course also the financial betterment that goes with this change.</p><p>If you consider looking for a new job then this is the right time, opportunities will arise even when you are not really looking out for them.</p><p>If you are currently unemployed this is a perfect moment to find a position.Make sure you open up to all the possibilities and don’t overlook the ones right in front of you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Possibly your spiritual experiences are a very private thing to you,so you might find it difficult to share them. Still, this card tells you that now is a good time to go looking for people of the same conviction and to try and share your experiences with them. It will be a rewarding and enriching experience for you and it will change your outlook on the fundamental loneliness we are all facing from time to time.</p><p>Generally this is a good time to try out new beliefs, to seek out directions and convictions that are new to you.</p>",
                    }
                },
                fr : {
                    name : "L’As des Pentacles",
                    suit : "Pentacles",
                    element :  ["🜃 - terre"],
                    theme : "Je le fais",    
                    description : "L'As des Pentacles, élément terre, nous parle de ce que l’on fait dans son intégralité. Tout est potentiellement présent, mais qu'allez-vous faire?",
                    questions : [ "Que pouvez-vous faire?", "Avez-vous les compétences nécessaires?", "Qu'allez-vous vraiment faire?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Les as sont généralement des cartes qui indiquent de nouveaux commencements, de nouvelles possibilités.  L'\"As de Pentagramme\" est synonyme de bonheur, de richesse intérieure et d'opportunité. Cela ne signifie pas que vous pouvez attendre de tout obtenir gratuitement, il est nécessaire de chercher par soi-même, devenir actif.  Mais en général, cette carte vous indique que vous êtes probablement à un moment positif de votre vie et que vous vous sentez généralement bien. Peut-être que les choses s'améliorent ! Ce peut être le cas financièrement, mais aussi pour les relations ou la carrière.</p><p>L'\"As de Pentagramme\" peut être comparé à l'\"As de Coupe\", une carte qui représente aussi un grand bonheur.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a loving relationship this card signifies that you will soon enjoy or already are enjoying great times, you feel happy within your relationship, you cherish your partner and the relationship is getting more profound and meaningful. You and your partner might even be entering a whole new and intense stage of commitment.</p><p>If you are single the Ace of Pentacles tells you it is very well possible you will meet someone interesting shortly. This is a perfect time to go out and meet new people. Enjoy the company of strangers and look around for that one person you might want to be with.</p>",
                        [GuidanceFields.WORK] : "<p>The Ace of Pentacles is a generally positive card. It tells you that you are doing very well at your job.You may soon be getting a promotion or new and more responsible tasks, and of course also the financial betterment that goes with this change.</p><p>If you consider looking for a new job then this is the right time, opportunities will arise even when you are not really looking out for them.</p><p>If you are currently unemployed this is a perfect moment to find a position.Make sure you open up to all the possibilities and don’t overlook the ones right in front of you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Possibly your spiritual experiences are a very private thing to you,so you might find it difficult to share them. Still, this card tells you that now is a good time to go looking for people of the same conviction and to try and share your experiences with them. It will be a rewarding and enriching experience for you and it will change your outlook on the fundamental loneliness we are all facing from time to time.</p><p>Generally this is a good time to try out new beliefs, to seek out directions and convictions that are new to you.</p>",
                    }
                },
                sp:{
                    name:"As de Pentáculos",
                    suit: "Pentáculos",
                    element: "🜃 - tierra",
                    theme: "Sí, yo hago",    
                    description: "El As de Pentáculos, que se ajusta al elemento tierra, se trata de lo que haces en su totalidad. Todo está potencialmente presente, pero ¿qué vas a hacer?",
                    questions: ["¿Qué puedes hacer?", "¿Tienes la habilidad para hacerlo?", "¿Qué vas a hacer en realidad?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Ases en general son las tarjetas que señalan nuevos comienzos, posibilidades. El As de Oros es acerca de la felicidad, la riqueza interior, oportunidades. Esto no significa que usted debe esperar para obtener todo gratis, sin ir más activamente y buscando, pero en general, esta carta le dice que es muy probablemente en un punto positivo en su vida, en general, sentirse bien. Posiblemente las cosas están aún tomando un giro para mejor, esto podría ser financieramente, sino también en términos de relación o carrera. El As de Oros se puede comparar con el As de Copas que también es una tarjeta muy afortunado.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Pentakels Aas",
                    suit : "Pentakels",
                    element : "🜃 - aarde",
                    theme : "ik doe",    
                    description : "De Aas van Pentakels, passend bij het element aarde, gaat over je doen in zijn totaliteit. Alles is in potentie aanwezig, maar wat ga jij doen?",
                    questions : [ "Wat kun jij vorm gaan geven?", "Heb jij daarvoor de vaardigheid?", "Wat ga jij daadwerkelijk doen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Azen zijn in de Tarot de kaarten die symbolisch zijn voor kansen en nieuwe mogelijkheden. Pentakels Aas staat voor geluk, innerlijke rijkdom en een nieuwe start. Dit betekent niet dat je zomaar alles gratis en voor niets gepresenteerd krijgt zonder dat je er zelf iets voor hoeft te doen. Je moet er natuurlijk zelf op uit trekken en op zoek gaan naar dat geluk, die rijkdom, die start. Toch vertelt deze kaart je dat je op weg bent naar een positieve periode in je leven waarin je je echt goed voelt. En het kan zelfs nog beter gaan worden! Het positieve kan betrekking hebben op alle elementen van je leven: relatie, werk, financiën.</p><p>Je kan Pentakels Aas vergelijken met Bekers Aas, het zijn allebei heel positieve kaarten die geluk beloven op allerlei manieren.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een liefdesrelatie hebt dan zit je ofwel middenin een prachtige periode of je bent ernaar op weg. Je voelt je gelukkig in je relatie, je bent toegewijd aan je partner en het contact tussen jullie wordt alsmaar dieper, intenser en rijker aan betekenis. Het is zelfs mogelijk dat jullie samen op weg zijn aan een nieuw engagement. Dat kan gaan over samenwonen, je verloven, kinderen krijgen of trouwen.</p><p>Als je single bent dan wijst deze kaart erop dat je waarschijnlijk binnenkort iemand zal ontmoeten. Nu is echt een ideale tijd voor jou om erop uit te gaan en nieuwe mensen op te gaan zoeken. Geniet van het gezelschap van deze onbekenden en kijk uit naar die ene persoon waar je misschien mee samen zou willen zijn.</p>",
                        [GuidanceFields.WORK] : "<p>Pentakels Aas is over het algemeen een positieve kaart. Ze vertelt je dat je het goed doet op je werk. Het zit erin dat je binnenkort meer verantwoordelijkheid krijgt of zelfs een promotie. Natuurlijk zullen deze veranderingen er ook voor zorgen dat je het financieel beter krijgt.</p><p>Als je overweegt om van baan te veranderen dan is dit het juiste moment. Je zal merken dat er kansen op je pad komen zelfs als je er niet echt naar op zoek bent. Als je momenteel werkloos bent, ga dan nu volop op zoek. Stel je open voor alle mogelijke opties die je op je af ziet komen en zoek het ook even wat dichter bij huis zodat je niets over het hoofd ziet.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Voor jou zijn je spirituele vragen en inzichten een heel persoonlijke zaak en je vindt het absoluut niet gemakkelijk om hierover met anderen te praten. Toch vertelt deze kaart je dat het nu het juiste moment is om op zoek te gaan naar mensen met gelijkaardige spirituele overtuigingen en je ervaringen met hen te delen. Je zal merken dat dit een heel verrijkende ervaring voor je kan zijn. Door dit samenkomen met anderen zal je visie op de fundamentele menselijke eenzaamheid (die we allemaal af en toe ervaren) volledig veranderen.</p><p>Over het algemeen is het nu een goede tijd om nieuwe richtingen te bestuderen, om jezelf open te stellen voor zienswijzen die je tevoren nog nooit onderzocht had.</p>",
                    }
                },
                de : {
                    name: "Ass der Pentagramme",
                    suit : "Pentagramme",
                    element : "🜃 - Erde",
                    theme : "Ich tue es.",    
                    description : "Das Ass der Pentagramme, Element Erde, spricht darüber, was wir in der Gesamtheit tun. Alles ist potentiell vorhanden, aber was werden Sie tun?",
                    questions : [ "Was können Sie tun?", "Haben Sie die notwendigen Fähigkeiten?", "Was werden Sie wirklich tun?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Asse sind im Allgemeinen Karten, die auf Neuanfänge, Möglichkeiten hinweisen.  Das „Ass der Pentagramme“ steht für Glück, inneren Reichtum und Chancen. Dies bedeutet nicht, dass Sie erwarten können, alles umsonst zu bekommen, ohne aktiv  werden und suchen zu müssen. Aber im Allgemeinen sagt Ihnen diese Karte, dass Sie sich sehr wahrscheinlich an einem positiven Punkt in Ihrem Leben befinden, und dass Sie sich in der Regel gut fühlen. Möglicherweise nehmen Dinge eine Wendung zum Besseren! Dies könnte in finanzieller Hinsicht der Fall sein, aber auch für Beziehungen oder Karriere gelten.</p><p>Das „Ass der Pentagramme“ lässt sich mit dem „Ass der Kelche“ vergleichen, eine Karte, die auch für großes Glück steht.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer liebevollen Beziehung sind bedeutet diese Karte, dass Sie bald großartige Zeiten genießen werden oder bereits genießen. Sie fühlen Sich glücklich in Ihrer Beziehung und Ihr Partner schätzt Sie und diese Beziehung wird immer tiefer und sinnvoller. Sie und Ihr Partner könnten sogar eintreten in eine ganz neue und intensive Phase dieser Verbundenheit.</p><p>Wenn Sie alleine sind, sagt das Ass der Pentagramme Ihnen, es ist sehr gut möglich, dass Sie in Kurzem eine interessante Person treffen werden. Dies ist eine perfekte Zeit, aus zu gehen und neue Leute kennen zu lernen. Genießen Sie die Gesellschaft von Fremden und schauen Sie um Sich herum nach dieser einen Person, die vielleicht unter ihnen ist.</p>",
                        [GuidanceFields.WORK] : "<p>Das Ass der Pentagramme ist eine allgemein positive Karte. Es sagt Ihnen, dass Sie sich sehr wohl in Ihrem Job fühlen. Sie können bald eine Beförderung erwarten oder neue und verantwortungsvolle Aufgaben erhalten, und natürlich auch die finanzielle Verbesserung, die damit zusammen hängt.</p><p>Wenn man auf der Suche ist nach einem neuen Job, dann ist dies der richtige Zeitpunkt. Chancen werden sich anbieten, auch wenn Sie im Moment nicht richtig auf der Suche nach ihnen sind.</p><p>Wenn Sie derzeit arbeitslos sind, ist dies ein perfekter Moment, um einen Job zu suchen. Stellen Sie sicher, dass Sie offen sind für alle Möglichkeiten und versäumen Sich nicht die zu sehen, die gerade vor Ihrer Nase sind.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Möglicherweise sind Ihre spirituelle Erfahrungen eine sehr private Angelegenheit, sogar so sehr, dass Sie es schwierig finden sie zu teilen. Trotzdem sagt diese Karte, dass es jetzt ein guter Zeitpunkt ist auf der Suche zu gehen nach Menschen mit der gleichen Überzeugung und zu versuchen mit ihnen Ihre Erfahrungen zu teilen. Es wird eine lohnende und bereichernde Erfahrung für Sie sein und es wird Ihre Ansichten auf die grundlegende Einsamkeit, mit der wir alle von Zeit zu Zeit konfrontiert werden, ändern.</p><p>Im Allgemeinen ist dies ein guter Zeitpunkt neue Überzeugungen, Richtungen und Einsichten zu suchen, die für Sie neu sind.</p>",
                    }
                }
            },
            {
                filename: "65",
                numerology : 2,
                en : {
                    name: "Two of Pentacles",
                    suit : "Pentacles",
                    element : "🜃 - earth",
                    theme : "what will I do",    
                    description : "Two of Pentacles talks about doubting what to do and about hemming and hawing about the different possibilities.",
                    questions : [ "Which are your possibilities?", "Can you be playful about it?", "How long will you be hemming and hawing?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Two of Pentacles is all about balance, either being balanced or looking for balance. There may be some areas in your life that aren't balanced for the moment and you are trying to \"make them work\". Possibly there are some decisions to be made and you are in doubt which direction to go. If this is the case then it is wise to listen to your intuition. This card tells you that deep inside you already know what to do, you only have to acknowledge it.</p><p>Sometimes this card points out that you should find more balance financially, pay attention to what you earn and spend.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>if you are in a loving relationship, you may think that you are having a great time with your partner and everything seems fine.But if you take the effort to look closer you will realize that you don't spend enough time with him/her to ensure that your relationship stays balanced and happy. Make this time now, before it's too late and you lose the one you love.</p><p>If you are single, now is the time to ask yourself if you are really ready for a committed relationship. It's not about becoming the perfect partner, but still, if you want to commit, you have to be ready to sacrifice a lot of things in order to make a relationship work. Are you ready to make this commitment? Or do you need some more time to ‘play’?</p>",
                        [GuidanceFields.WORK] : "<p>Right nowyou easily adapt to different tasks and projects. This attitude also holds a risk, you might be taking on too many tasks and maybe you aren't seeing the bigger picture anymore. Make sure you focus on all the things you do and don't scatter your energy all over the place.</p><p>If you are in doubt whether you are at the right place, then maybe the time has come to broaden your horizon.</p><p>If you are looking for a job, try to focus on two or three opportunities. Don't send out dozens of applications, that won't lead to positive results right now.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Two of Pentacles tells you it is very likely that you are not satisfied with the way things in your life are balanced. You feel as if your responsibilities are taking up way too much time and energy, leaving you no space at all to focus on your spiritual growth. It is very important to carefully consider in which ways you can change your life and agenda in order to make room for spiritual nourishment. It’s not only your body that needs to be fed, your soul and spirit need that too. Try to find this balance, it is very important for your quality of life, now and in the future.</p>",
                    }
                },
                fr : {
                    name : "Le Deux de Pentacle",
                    suit : "Pentacles",
                    element :  "🜃 - terre",
                    theme : "Que vais-je faire",    
                    description : "Le Deux de Pentacle, nous parle de doute concernant ce qu'il faut faire et l’équilibre entre nos différentes possibilités.",
                    questions : ["Quelles sont vos possibilités?", "Pouvez-vous l’ approcher de manière ludique?", "Combien de temps allez-vous continuer à y songer?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Deux de Pentagramme \" tout se tourne autour de la question de balance, soit l'équilibre, soit la recherche de l'équilibre. Il se peut que certains aspects de votre vie ne soient pas équilibrés en ce moment et que vous ayez des doutes quant à la direction à prendre. Si tel est le cas, il est conseillé d'écouter votre intuition. Cette carte vous dit qu'au fond de vous, vous savez déjà ce que vous avez à faire. Vous devez juste l'accepter.</p><p>Parfois, cette carte indique que vous avez besoin de trouver un meilleur équilibre financier, de faire plus attention à vos revenus et à vos dépenses.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>if you are in a loving relationship, you may think that you are having a great time with your partner and everything seems fine.But if you take the effort to look closer you will realize that you don't spend enough time with him/her to ensure that your relationship stays balanced and happy. Make this time now, before it's too late and you lose the one you love.</p><p>If you are single, now is the time to ask yourself if you are really ready for a committed relationship. It's not about becoming the perfect partner, but still, if you want to commit, you have to be ready to sacrifice a lot of things in order to make a relationship work. Are you ready to make this commitment? Or do you need some more time to ‘play’?</p>",
                        [GuidanceFields.WORK] : "<p>Right nowyou easily adapt to different tasks and projects. This attitude also holds a risk, you might be taking on too many tasks and maybe you aren't seeing the bigger picture anymore. Make sure you focus on all the things you do and don't scatter your energy all over the place.</p><p>If you are in doubt whether you are at the right place, then maybe the time has come to broaden your horizon.</p><p>If you are looking for a job, try to focus on two or three opportunities. Don't send out dozens of applications, that won't lead to positive results right now.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Two of Pentacles tells you it is very likely that you are not satisfied with the way things in your life are balanced. You feel as if your responsibilities are taking up way too much time and energy, leaving you no space at all to focus on your spiritual growth. It is very important to carefully consider in which ways you can change your life and agenda in order to make room for spiritual nourishment. It’s not only your body that needs to be fed, your soul and spirit need that too. Try to find this balance, it is very important for your quality of life, now and in the future.</p>",
                    }
                },
                sp:{
                    name:"El Dos de pentáculos",
                    suit: "Pentáculos",
                    element: "🜃 - tierra",
                    theme: "¿qué voy a hacer?",    
                    description: "El Dos de Pentáculos es sobre la duda sobre qué hacer y nos indica nuestro acto de equilibrio entre las diferentes posibilidades",
                    questions: ["¿Qué posibilidades tienes?", "¿Puedes jugar con eso?", "¿Cuánto tiempo seguirás sopesando?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Dos de Oros es cuestión de equilibrio, ya sea estar equilibrada o en busca de equilibrio. Puede haber algunas áreas de su vida que no son equilibradas por el momento y que están tratando de \"hacer que funcionen\". Posible hay algunas decisiones que tomar y que están en duda en qué dirección ir. Si este es el caso, entonces es sabio escuchar a su intuición. Esta tarjeta le dice que en el fondo usted ya sabe qué hacer, sólo tiene que reconocerlo. A veces, esta carta señala que debería buscar un mayor equilibrio financiero, prestar atención a lo que gana y gasta.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Pentakels Twee",
                    suit : "Pentakels",
                    element : "🜃 - Erde",
                    theme : "wat zal ik doen",    
                    description : "De twee van pentakels gaat over twijfel over wat te doen en wijst ons op ons wikken en wegen tussen verschillende mogelijkheden.",
                    questions : [ "Welke mogelijkheden heb jij?", "Kun jij daar speels mee omgaan?", "Hoe lang blijf je wikken en wegen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Pentakels Twee draait rond evenwicht, ofwel ben je op zoek naar evenwicht, ofwel probeer je in evenwicht te blijven. Het is best mogelijk dat je op een aantal vlakken in je leven niet in balans bent en dat je gewoon probeert om de boel draaiende te houden. Misschien weet je wel dat je een aantal beslissingen moet nemen maar ben je er nog niet uit welke kant je op wil. Als dit het geval is, weet dan dat het verstandig is om nu naar je intuïtie te luisteren. Dat is wat deze kaart je vertelt: diep vanbinnen weet je al wat je te doen staat, je moet het enkel nog onder ogen zien.</p><p>Soms wijst deze kaart er ook op dat het belangrijk voor je is om financieel wat meer evenwicht te vinden, probeer wat je verdient en wat je uitgeeft beter in balans te brengen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een liefdesrelatie hebt dan is het best mogelijk dat je denkt dat alles in orde is, dat jullie samen een fijne tijd hebben. Maar als je de moeite doet om de situatie wat nader te bekijken dan zal je beseffen dat je niet genoeg tijd doorbrengt met je partner. Op deze manier zal je relatie niet evenwichtig zijn en blijven. Maak nu tijd voor die ander, voordat het te laat is en je hem/haar verliest. Als je op dit ogenblik alleen bent, vraag je dan af of dit wel een goede tijd is om op zoek te gaan naar liefde in je leven. Het gaat er niet om dat je de perfecte partner moet zijn maar toch, als je je wil engageren in een relatie, moet je er klaar voor zijn om een aantal dingen op te offeren, om plaats te maken in je leven voor die ander, of het werkt niet. Ben je zeker dat je hier klaar voor bent? Of heb je eerst nog wat tijd nodig om te “spelen”?</p>",
                        [GuidanceFields.WORK] : "<p>Op dit moment ben je heel soepel, je past je gemakkelijk aan op werkgebied, nieuwe taken en projecten aanpakken vind je helemaal niet moeilijk. Deze benadering houdt ook risico’s in want het is mogelijk dat je teveel hooi op je vork neemt en dat je dan het grotere plaatje uit het oog verliest. Zorg ervoor dat je geconcentreerd blijft op alles wat je doet en verspil geen energie aan heen en weer lopen van de ene taak naar de andere.</p><p>Als je twijfelt of je wel op de juiste plek zit, dan is nu het goede moment om je horizon te verbreden en op zoek te gaan naar iets anders.</p><p>Als je op zoek bent naar een baan, probeer je dan te concentreren op telkens twee of drie mogelijkheden, niet meer. Het is niet zinvol om tientallen sollicitaties te versturen want dan dreig je het overzicht te verliezen. Pak het liever wat kleinschaliger aan maar wees bij elke sollicitatie zo grondig mogelijk.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Pentakels Twee vertelt je dat het goed mogelijk is dat je niet tevreden bent met hoe de zaken verdeeld zijn in je leven. Je hebt het gevoel dat al je verantwoordelijkheden veel te veel tijd en energie van je vragen. Je hebt het idee dat je daardoor geen enkele ruimte hebt om spiritueel te groeien. Het is heel belangrijk dat je er nu grondig over nadenkt hoe je je leven en je agenda dusdanig kan veranderen dat je ook tijd kan maken om spiritueel ‘bij te tanken’. Niet enkel je lichaam moet onderhouden en gevoed worden, je geest en je ziel hebben dat ook nodig. Probeer hierin meer evenwicht te vinden, het is erg belangrijk voor je levenskwaliteit, nu en in de toekomst.</p>",
                    }
                },
                de : {
                    name: "Zwei der Pentagramme",
                    suit : "Pentagramme",
                    element : "🜃 - Erde",
                    theme : "Was soll ich tun?",    
                    description : "Die Zwei der Pentagramme spricht vom Zweifeln an der Vorgehensweise und dem Gleichgewicht zwischen unseren verschiedenen Möglichkeiten.",
                    questions : [ "Was sind Ihre Möglichkeiten?", "Können Sie spielerisch damit umgehen?", "Wie lange wollen Sie noch darüber nachdenken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Bei der „Zwei der Pentagramme“ dreht sich alles um Gleichgewicht, entweder um Ausgeglichenheit oder um die Suche nach Gleichgewicht. Möglicherweise gibt es einige Bereiche in Ihrem Leben, die zurzeit nicht ausgeglichen sind, und Sie haben Zweifel darüber, welche Richtung Sie einschlagen müssen. Möglicherweise sind einige Entscheidungen zu treffen, und Sie haben Zweifel darüber, in welche Richtung sie gehen sollen. Wenn dies der Fall ist, dann ist es ratsam, auf Ihre Intuition zu hören. Diese Karte sagt Ihnen, dass Sie tief in Ihrem Inneren bereits wissen, was Sie zu tun haben. Sie müssen es nur annehmen.</p><p>Manchmal weist diese Karte darauf hin, dass Sie ein besseres finanzielles Gleichgewicht  suchen müssen, Ihren Einnahmen und Ausgaben mehr Aufmerksamkeit schenken sollten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer liebevollen Beziehung sind, könnten Sie glauben, dass Sie eine fantastische Zeit mit Ihrem Partner erleben und alles in bester Ordnung erscheint. Aber, wenn Sie Sich die Mühe nehmen, Sich die Situation näher anzusehen, werden Sie feststellen, dass Sie nicht genügend Zeit mit Ihrem Partner verbringen, um sicherzustellen, dass Ihre Beziehung ausgeglichen und glücklich bleibt. Machen Sie jetzt diese Zeit frei bevor es zu spät ist und Sie Ihre Liebe verlieren.</p><p>Wenn Sie alleine sind, ist jetzt die Zeit, sich zu fragen, ob Sie wirklich bereit sind eine feste Beziehung einzugehen. Es ist nicht über das sein des perfekten Partners, aber viel mehr noch, wenn Sie Sich verbinden, müssen Sie bereit sein viele Dinge zu opfern. Sind Sie bereit, diese Opfer zubringen? Oder brauchen Sie noch ein bisschen Zeit zum Spielen?</p>",
                        [GuidanceFields.WORK] : "<p>Gerade jetzt passen Sie Sich leicht an unterschiedlichen Aufgaben und Projekten an. Damit gehen Sie auch ein Risiko ein. Sie könnten zu viele Aufgaben auf sich nehmen und das größere Bild damit verlieren. Stellen Sie sicher, dass Sie alle Energie gleichmäßig verteilen über alle Dinge, die Sie tun und sie nicht überall verschwenden.</p><p>Wenn Sie Sich nicht sicher sind, ob Sie an der richtigen Stelle sind, dann ist vielleicht die Zeit gekommen, um Ihren Horizont zu erweitern.</p><p>Wenn Sie auf der Suche nach einem Job sind, versuchen Sie dann Sich auf zwei oder drei Chancen zu konzentrieren. Senden Sie nicht Dutzende Erwerbungen herum, die momentan nicht zu positiven Ergebnissen führen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Zwei der Pentagramme sagt Ihnen, dass es ist sehr wahrscheinlich, dass Sie im Augenblick nicht zufrieden sind mit den Sachen, die jetzt in Ihrem Leben vor sich gehen. Ihr Leben ist jetzt nicht ausgeglichen. Sie fühlen sich, als ob Ihre Aufgaben zu viel Zeit und Energie beschlagnahmen, sodass Sie keinen Raum mehr haben um sich überhaupt auf Ihrem spirituellen Wachstum zu konzentrieren. Es ist jetzt sehr wichtig, sorgfältig Ihre Tagesordnung und Ihr Leben zu überprüfen, sodass Sie Raum finden für geistige Ernährung. Es ist nicht nur Ihren Körper, der genährt werden muss, sondern auch Ihre Seele und Ihr Geist. Versuchen Sie, dieses Gleichgewicht zu finden, ist es sehr wichtig für Ihre Lebensqualität, so wie für jetzt als auch für die Zukunft.</p>",
                    }
                }
            },
            {
                filename: "66",
                numerology : 3,
                en : {
                    name: "Three of Pentacles",
                    suit : "Pentacles",
                    element : "🜃 - earth",
                    theme : "make room for what you possess",    
                    description : "Three of Pentacles talks about committing to work with your own qualities and about cooperating, using each personal quality of all people involved.",
                    questions : [ "Are you looking up to someone?", "Which distance can be gapped?", "What are you good at?", "Do you work solo or together?", "What do you do to create something?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This is generally a very positive card. It tells you that you are doing well and no major changes are required. You may decide to do some things differently, but you don't have to. If you feel like making some changes, make sure you listen to the people around you, but don't blindly follow their opinions, consider them and then make your own decisions.</p><p>This card also tells you that who you are and what you do is appreciated by the people around you, you can be sure of that even if they don't always tell you.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Three of Pentacles tells you that you are considering an important step in your relationship that will bring you and your partner a lot closer. You aren’t having any problems but you really feel the urge to be more intimate, more together.</p><p>If you are currently single and there is someone you have taken an interest in, this card tells you the other person is interested in you too. Maybe you aren't madly in love, but you both think the other may have the qualities you are looking for in a partner.</p>",
                        [GuidanceFields.WORK] : "<p>When it comes to work and career issues this card is also a very positive sign. It tells you that you are in a good place at work, people appreciate what you do and how you do it, they are fully aware of your talents and capabilities. It is very likely that you will get a promotion or reach a new level. You are really good at what you do and in time you will get even better.</p><p>If you are looking for a job there is a very good chance you will soon find what you are looking for.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually the time is right to look for fresh inspiration in the past, in history. Maybe there are some ancient philosophies or beliefs you would like to study closer, or maybe old rituals are drawing your attention. Do not think you have to join a certain belief or \"club\", at this point it's the act of gaining knowledge in itself that’s attractive and interesting to you. Studying and exploring is a perfect way to nourish your spirit, to make your understanding more profound.</p>",
                    }
                },
                fr : {
                    name : "Le Trois de Pentacle",
                    suit : "Pentacles",
                    element :  "🜃 - terre",
                    theme : "Donner de l’espace à ce que vous avez",    
                    description : "Le Trois de Pentacles, nous parle de travailler avec ses qualités inouïes et de travailler ensemble, en utilisant les qualités de chacun.",
                    questions : ["Admirez-vous quelqu'un?", "Quelle distance pouvez-vous franchir?", "Qu'est-ce que vous savez faire?", "Travaillez-vous seul ou avec d'autres?", "Que faites-vous pour accomplir quelque chose?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>C'est généralement une carte très positive. Il vous dit que vous allez bien et qu'aucun changement majeur n'est nécessaire. Vous pourriez décider de faire les choses différemment, mais ce n'est pas la peine. Si vous sentez que vous avez besoin de faire des changements, vous devriez écouter les gens autour de vous, mais ne pas suivre aveuglément leur opinion. Réfléchissez bien et prenez vos propres décisions.</p><p>Cette carte vous montrera également qui vous êtes et ce que vous devez faire pour être apprécié par les gens autour de vous. Vous serez apprécié, même s'ils ne vous le disent pas toujours.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Three of Pentacles tells you that you are considering an important step in your relationship that will bring you and your partner a lot closer. You aren’t having any problems but you really feel the urge to be more intimate, more together.</p><p>If you are currently single and there is someone you have taken an interest in, this card tells you the other person is interested in you too. Maybe you aren't madly in love, but you both think the other may have the qualities you are looking for in a partner.</p>",
                        [GuidanceFields.WORK] : "<p>When it comes to work and career issues this card is also a very positive sign. It tells you that you are in a good place at work, people appreciate what you do and how you do it, they are fully aware of your talents and capabilities. It is very likely that you will get a promotion or reach a new level. You are really good at what you do and in time you will get even better.</p><p>If you are looking for a job there is a very good chance you will soon find what you are looking for.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually the time is right to look for fresh inspiration in the past, in history. Maybe there are some ancient philosophies or beliefs you would like to study closer, or maybe old rituals are drawing your attention. Do not think you have to join a certain belief or \"club\", at this point it's the act of gaining knowledge in itself that’s attractive and interesting to you. Studying and exploring is a perfect way to nourish your spirit, to make your understanding more profound.</p>",
                    }
                },
                sp:{
                    name:"El Tres de pentáculos",
                    suit: "Pentáculos",
                    element: "🜃 - tierra",
                    theme: "da lo que tienes espacio",    
                    description: "El tres de Pentáculos tratan sobre el trabajo con sus propias cualidades y nos señalan que podemos trabajar juntos, usando las propias cualidades de cada uno",
                    questions: ["¿Admiras a alguien?", "¿Qué distancia se puede salvar?", "¿En qué eres bueno?", "¿Trabajas solo o con otros?", "¿Qué haces para lograr algo?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esto generalmente es una tarjeta muy positiva. Se le dice que usted está haciendo bien y no se requieren cambios importantes. Usted puede decidir qué hacer algunas cosas de manera diferente, pero usted no tiene que hacerlo. Si tiene ganas de hacer algunos cambios, asegúrese de que usted escucha a las personas que le rodean, pero no sigue ciegamente sus opiniones, considere ellos y luego tomar sus propias decisiones. Esta tarjeta también le dice que lo que es y lo que hace es apreciado por la gente alrededor de usted, usted puede estar seguro de que, incluso si no siempre le dicen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Pentakels Drie",
                    suit : "Pentakels",
                    element : "🜃 - aarde",
                    theme : "geef dat wat je hebt de ruimte",    
                    description : "De drie van pentakels gaat over werken met inzet van je eigen kwaliteiten en wijst ons erop dat we samen kunnen werken, gebruikmakend van ieders eigen kwaliteiten.",
                    questions : [ "Kijk je tegen iemand op?", "Welke afstand kan overbrugd worden?", "Waar ben jij goed in?", "Werk jij alleen of samen met anderen?", "Wat doe jij om iets tot stand te brengen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart is over het algemeen positief. Ze vertelt je dat je de dingen goed aanpakt en dat er geen grote veranderingen nodig zijn. Je kan natuurlijk beslissen om bepaalde zaken anders aan te pakken, maar deze kaart vertelt je dat het niet noodzakelijk is, het kan maar het moet niet.</p><p>Stel dat je bepaalde veranderingen overweegt, praat er dan over met de mensen om je heen. Volg hun advies niet blindelings, denk erover na en volg dan toch de weg die jou het beste lijkt.</p><p>Deze kaart vertelt je ook dat wie je bent en wat je doet gewaardeerd wordt door de mensen om je heen. Ze zijn blij dat je in hun leven bent ook al laten ze je dat niet altijd weten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Pentakels Drie wijst erop dat je aan het overwegen bent om een belangrijke stap te zetten in verband met je relatie, een stap die jou en je partner dichter bij mekaar zal brengen. Jullie hebben op dit moment geen problemen maar jij voelt de behoefte aan meer intimiteit, meer samenzijn met hem/haar, vandaar die stap.</p><p>Als je op dit moment single bent en er is iemand waarin je geïnteresseerd bent, dan vertelt deze kaart je dat die persoon jou ook leuk vindt. Dit betekent natuurlijk niet dat jullie onmiddellijk smoorverliefd in mekaars armen zullen vallen maar wel dat jullie allebei denken dat de ander potentieel partnermateriaal is en dat het zinvol is om mekaar beter te leren kennen.</p>",
                        [GuidanceFields.WORK] : "<p>Op het gebied van werk en carrière brengt deze kaart een positieve boodschap. Ze vertelt je dat je goed in je vel zit op het werk, dat mensen waarderen wat je allemaal doet en hoe je het doet en dat ze zich echt wel bewust zijn van je talenten en kwaliteiten. Het is heel waarschijnlijk dat je een promotie zal krijgen of dat je binnenkort toch minstens een stap in die richting zal zetten. Je bent echt goed in wat je doet en je zal er mettertijd zelfs nog beter in worden.</p><p>Als je op zoek bent naar werk dan mag je ervan uitgaan dat je in de komende tijd vindt waar je naar op zoek bent.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritueel gezien is het nu een goed moment om naar inspiratie te gaan zoeken in het verleden, de geschiedenis. Misschien zijn er klassieke denkrichtingen of filosofen die je eens wat nader wil bestuderen, of misschien heb je interesse voor oude rituelen en gewoontes. Dit betekent zeker niet dat je je moet gaan aansluiten bij een of andere sekte of club. Op dit moment gaat het erom dat je gaat genieten van het verwerven van kennis op zichzelf, dat je daar voldoening in vindt. Studeren en onderzoeken is een fantastische manier om je geest te voeden, om ervoor te zorgen dat je de wereld om je heen op een andere, diepere manier gaat zien en begrijpen.</p>",
                    }
                },
                de : {
                    name: "Drei der Pentagramme",
                    suit : "Pentagramme",
                    element : "🜃 - Erde",
                    theme : "Dem Raum geben, was man hat",    
                    description : "Die Drei der Pentagramme spricht davon, mit seinen außergewöhnlichen Qualitäten zu arbeiten sowie der Zusammenarbeit mit anderen, bei der die Qualitäten von jedem genutzt werden.",
                    questions : [ "Bewundern Sie jemanden?", "Wie weit können Sie gehen?", "Was können Sie tun?", "Arbeiten Sie allein oder mit anderen?", "Was tun Sie, um etwas zu erreichen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Dies ist im Allgemeinen eine sehr positive Karte. Sie sagt Ihnen, dass es Ihnen gut geht und keine größeren Änderungen erforderlich sind. Sie könnten sich entscheiden, einiges anders zu machen, aber Sie müssen das nicht tun. Wenn Sie das Gefühl haben, einige Veränderungen vornehmen zu müssen, dann sollten Sie auf die Menschen in Ihrer Umgebung hören, aber nicht blindlings deren Meinung folgen. Überlegen Sie sich es gut und treffen Sie Ihre eigenen Entscheidungen.</p><p>Diese Karte zeigt Ihnen auch, wer Sie sind und was Sie tun müssen, um von den Menschen in Ihrer Umgebung geschätzt zu werden. Dessen können Sie sich sicher sein, auch wenn diese es Ihnen nicht immer zeigen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Die Drei der Pentagramme sagt Ihnen, dass Sie Sich überlegen einen wichtigen Schritt in Ihrer Beziehung zu machen, der Sie und Ihren Partner ein gutes Stück näher zu einander bringen würde. Es gibt keine richtige Probleme, aber Sie haben wirklich das Gefühl, der Drang, intimer mit einander zusammen zu sein.</p><p>Wenn Sie derzeit alleine sind und es gibt jemanden für wen Sie Sich interessieren, dann sagt diese Karte, die auch die andere Person interessiert ist an Sie. Vielleicht seit Ihr nicht ganz in einander verliebt, aber sie beide denken, der andere könnte die Eigenschaften haben, die sie suchen in einem Partner.</p>",
                        [GuidanceFields.WORK] : "<p>Wenn es sich um die Karriere und Arbeitsprobleme handelt, kann diese Karte auch ein sehr positives Zeichen sein. Sie sagt Ihnen, dass Sie an einem guten Platz in der Arbeit sind. Menschen wissen Sie zu schätzen um was Sie tun und wie Sie es tun, voller Bewusstsein. Sie sind völlig überzeugt von Ihren Talenten und Fähigkeiten. Es ist sehr wahrscheinlich, dass Sie befördert, oder ein neues Niveau erreichen werden. Sie sind wirklich gut in dem was Sie tun und mit der Zeit werden Sie noch besser werden.</p><p>Wenn Sie auf der Suche sind, nach einem Job gibt es jetzt sehr gute Chancen, dass Sie bald finden, was Sie suchen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spirituell ist die Zeit reif, um für frische Inspiration in die Vergangenheit, in die Geschichte zurück zu blicken. Vielleicht gibt es einige alten Philosophien und Überzeugungen, die Sie gerne näher studieren möchten, oder vielleicht werden alte Rituale Ihre Aufmerksamkeit wecken. Glaube nicht, dass Sie jetzt in einen bestimmten Glauben oder \"Club\" eintreten müssen. An diesem Punkt ist es der Akt der Erkenntnisgewinnung selbst, die attraktiv und interessant für Sie ist. Das Studium und die Erforschung ist ein perfekter Weg, um Ihren Geist zu nähren, um Ihr Verständnis zu vertiefen.</p>",
                    }
                }
            },
            {
                filename: "67",
                numerology : 4,
                en : {
                    name: "Four of Pentacles",
                    suit : "Pentacles",
                    element : "🜃 - earth",
                    theme : "having a handle on what you possess",    
                    description : "Four of Pentacles talks about having a handle on what is valuable to you and that you are probably holding on to it to tightly.",
                    questions : [ "Are you that tenacious?", "Do previous experiences bother you?", "Are you open to new experiences?", "What could you re-organize?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>When you look at this card you see a man literally sitting on and holding on to the coins, he seems afraid to let go of them. And that's what this card is about, holding on too tightly, being afraid of letting go of possessions, people, habits, emotions. The card is also about fear of change, an almost desperate longing for security. But clinging to things, people or possessions doesn't bring you security, it only makes you rigid. And rigidity makes it impossible for you to grow, to move, to enjoy life. The Four of Pentacles tells you that you will have to change if you really want to live life to the fullest.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card is about fear. And because you are afraid of losing your partner, of being left behind, you cling to her/him, you hold on way too tightly, making it almost impossible for your partner to even breathe. If you maintain this attitude, you will provoke exactly what is your greatest fear, and your partner will desert you.</p><p>If you truly love someone then you have to let them be free, free to do whatever they want, and to trust them not to hurt or betray you. If you face your biggest fears you will be able to let them go.Only then you can allow your partner to freely commit to you and the relationship.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are rigid, you hold on to old habits and familiar ways of doing things. Even when asked you find it very hard to adapt to new rules and procedures. It is now time to realize that letting go of the old ways will in a way set you free, allow you to learn and grow and even appreciate the \"new\" ways.</p><p>Possibly you don't like your job anymore, you keep doing it day after day because of the security it offers you, but it doesn't bring you any joy, any fulfillment. Allow yourself the freedom of thinking about changing jobs, looking for a new challenge, new opportunities. Possibly, when confronted with other possibilities you may decide to stay where you are, but then it will be a conscious choice, not a must.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Four of Pentacles tells you that the time has come to do some soul searching: what are your biggest fears? Which people are you clinging to? What objects or feelings or people are you holding too strongly? And furthermore, why do you hold on to things so tightly? What do you think might happen if you would simply let go? Look at yourself honestly and try to answer these questions. Once you do this you may realize that true freedom lies in letting go, in creating space between you and everything and everyone else. Once you do let go and you experience the space this creates first hand, you will finally be able to breathe deeply again. To live is to change!</p>",
                    }
                },
                fr : {
                    name : "Le Quatre de Pentacle",
                    suit : "Pentacles",
                    element :  "🜃 - terre",
                    theme : "Prendre en main de ce que vous avez",    
                    description : "Le Quatre de Pentacle, nous parle de saisir ce qui nous est précieux et à quoi nous nous accrochons, peut-être trop désespérément.",
                    questions : ["Êtes-vous tellement obstiné?", "Souffrez-vous d'expériences précédentes?", "Vous autorisez-vous de nouvelles expériences?", "Que pourriez-vous réorganiser?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Si vous regardez attentivement cette carte, vous verrez un homme assis littéralement sur des pièces de monnaie et s'y accrochant, comme s'il avait peur de devoir s'en séparer. C'est exactement ce que représente cette carte.  La peur de quitter les biens, les gens, les habitudes et les émotions. La carte représente la peur du changement et le désir désespéré de sécurité. Mais s'accrocher aux choses, aux personnes et aux biens ne vous apportent pas la sécurité. Cela ne fera que vous bloquer et vous rendre anxieux. La rigidité et la peur vous empêchent de grandir, de bouger, de profiter de la vie. Le \"Quatre de Pentagramme\" vous dit que vous devez changer si vous voulez vraiment profiter pleinement de la vie.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card is about fear. And because you are afraid of losing your partner, of being left behind, you cling to her/him, you hold on way too tightly, making it almost impossible for your partner to even breathe. If you maintain this attitude, you will provoke exactly what is your greatest fear, and your partner will desert you.</p><p>If you truly love someone then you have to let them be free, free to do whatever they want, and to trust them not to hurt or betray you. If you face your biggest fears you will be able to let them go.Only then you can allow your partner to freely commit to you and the relationship.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are rigid, you hold on to old habits and familiar ways of doing things. Even when asked you find it very hard to adapt to new rules and procedures. It is now time to realize that letting go of the old ways will in a way set you free, allow you to learn and grow and even appreciate the \"new\" ways.</p><p>Possibly you don't like your job anymore, you keep doing it day after day because of the security it offers you, but it doesn't bring you any joy, any fulfillment. Allow yourself the freedom of thinking about changing jobs, looking for a new challenge, new opportunities. Possibly, when confronted with other possibilities you may decide to stay where you are, but then it will be a conscious choice, not a must.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Four of Pentacles tells you that the time has come to do some soul searching: what are your biggest fears? Which people are you clinging to? What objects or feelings or people are you holding too strongly? And furthermore, why do you hold on to things so tightly? What do you think might happen if you would simply let go? Look at yourself honestly and try to answer these questions. Once you do this you may realize that true freedom lies in letting go, in creating space between you and everything and everyone else. Once you do let go and you experience the space this creates first hand, you will finally be able to breathe deeply again. To live is to change!</p>",
                    }
                },
                sp:{
                    name:"El Cuatro de Pentáculos",
                    suit: "Pentáculos",
                    element: "🜃 - tierra",
                    theme: "agarrarse a lo que se tiene",    
                    description: "El cuatro de Pentáculos tratan de agarrar lo que es valioso para ti y señala lo que tú, tal vez demasiado convulsivo, estás agarrando",
                    questions: ["¿Eres tan tenaz?", "¿Sufres de experiencias anteriores?", "¿Te permites nuevas experiencias?", "¿Qué podrías reorganizar?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cuando nos fijamos en esta tarjeta ves a un hombre literalmente sentado en y aferrándose a las monedas, que parece tener miedo de dejar ir de ellos. Y eso es lo que esta tarjeta es de, aferrarse demasiado, tener miedo de dejar ir de las posesiones, las personas, los hábitos, las emociones. La tarjeta también se trata de miedo al cambio, un deseo casi desesperado para la seguridad. Pero aferrarse a cosas, personas o posesiones no le trae seguridad, sólo le hace rígido. Y rigidez hace que sea imposible para que se desarrolle, para moverse, para disfrutar de la vida. El Cuatro de Oros le dice que usted tendrá que cambiar si realmente quiere vivir la vida al máximo.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Pentakels Vier",
                    suit : "Pentakels",
                    element : "🜃 - aarde",
                    theme : "grip op wat je hebt",    
                    description : "De vier van pentakels gaat over grip op dat wat voor jou waardevol is en wijst ons op hetgeen je, wellicht te krampachtig, vasthoudt.",
                    questions : [ "Ben jij zo vasthoudend?", "Heb je last van eerdere ervaringen?", "Sta jij jezelf nieuwe ervaringen toe?", "Wat zou jij opnieuwe kunnen ordenen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Als je naar deze kaart kijkt dan zie je een man die letterlijk op een aantal munten zit en die zich zelfs vastklampt aan eentje. Het ziet er naar uit dat hij bang is om ze los te laten. En net daar gaat het om bij deze kaart: je vastklampen aan dingen, bang zijn om mensen, bezittingen, gewoontes of gevoelens los te laten. En als logisch vervolg daarop gaat deze kaart natuurlijk ook over angst voor verandering en een bijna wanhopige behoefte aan veiligheid, je beschermd voelen.</p><p>Eén ding is duidelijk: als je vasthoudt aan mensen, gevoelens, gewoontes en bezittingen betekent dit absoluut niet dat je ook veilig zal zijn. Het zorgt er enkel voor dat je star en angstig wordt, dat je je nauwelijks nog durft te bewegen. Starheid en angst maken het onmogelijk voor je om nog te groeien, te bewegen, van het leven te genieten. Pentakels Vier vertelt je dat je dringend zal moeten veranderen als je iets aan het leven wil hebben, als je je leven echt wil gaan beleven.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart wijst op angst. Je bent op dit ogenblik bang om je partner te verliezen, om achtergelaten te worden. Daarom klamp je je vast aan hem/haar, houd je de ander in zo’n stevige greep dat ze het moeilijk krijgen om zelfs maar te ademen. Als je blijft doorgaan met deze houding dan zal je exact datgene veroorzaken wat je het meeste vreest: je partner zal je verlaten, al is het maar uit zelfbehoud. Als je echt van iemand houdt dan moet je hen vrijlaten, vrij om te doen wat zij willen. En je moet hen vertrouwen, erin geloven dat ze niets zullen doen om je te kwetsen of te verraden. Bekijk je diepste angsten, ga de confrontatie ermee aan. Je zal merken dat je ze dan achter je kan laten. Het is immers net omdat je van je angst wegloopt dat die je blijft achtervolgen. Enkel als je je angsten loslaat, kan je je partner eindelijk de ruimte geven om vrijelijk voor jou te kiezen en helemaal voor jullie relatie te gaan.</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk ben je nogal strak, bijna star, tegenwoordig, je houdt je vast aan oude gewoontes en vertrouwde manieren om bepaalde dingen aan te pakken. Je vindt het erg moeilijk om je aan te passen aan nieuwe regels en procedures, zelf als je dat uitdrukkelijk gevraagd wordt. Het wordt echt tijd dat je gaat beseffen dat er een vorm van vrijheid schuilt in het loslaten van oude dingen, de vrijheid om te leren en te groeien en misschien zelfs de ‘nieuwe’ aanpak te gaan waarderen.</p><p>Misschien vind je je baan niet meer fijn, blijf je er enkel dag na dag mee doorgaan vanwege de veiligheid die het je biedt. Misschien haal je geen vreugde meer uit je werk, geen voldoening. Als dat het geval is, sta jezelf dan eens toe om na te denken over een andere baan, een nieuwe uitdaging, nieuwe kansen. Als je andere mogelijkheden gaat onderzoeken is het mogelijk dat je blijft waar je bent, maar dan zal het een bewuste keuze zijn, geen verplichting.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Pentakels Vier vertelt je dat het nu een goede tijd is om in jezelf op zoek te gaan naar een aantal antwoorden: wat zijn je grootste angsten? Aan welke mensen klamp je je echt vast? Welke voorwerpen, gevoelens of mensen kan je moeilijk loslaten? En tenslotte: waarom vind je het nodig om bepaalde dingen, mensen, gevoelens zo stevig vast te houden? Wat denk je dat er zal gebeuren als je ze zou loslaten? Kijk open en eerlijk naar jezelf en probeer deze vragen te beantwoorden. Eens je alle antwoorden op een rijtje hebt, zal je gaan beseffen dat de ware vrijheid er net in ligt dat je loslaat, dat je ruimte creëert tussen jezelf en alle andere mensen en dingen. En als je dan uiteindelijk begint los te laten en echt ervaart hoeveel ruimte je hierdoor krijgt, zal je merken dat je voor het eerst sinds lange tijd weer diep kan ademhalen. En je zal de spanningen uit je weg voelen vloeien naarmate je beter en dieper ademhaalt. Leven is veranderen!</p>",
                    }
                },
                de : {
                    name: "Vier der Pentagramme",
                    suit : "Pentagramme",
                    element : "🜃 - Erde",
                    theme : "Die Kontrolle darüber übernehme, was man hat.",    
                    description : "Die Vier der Pentagramme spricht über das Erfassen dessen, was uns kostbar ist und woran wir uns klammern, vielleicht zu verzweifelt.",
                    questions : [ "Sind Sie so stur?", "Leiden Sie unter früheren Erfahrungen?", "Erlauben Sie sich neue Erfahrungen?", "Was könnten Sie neu organisieren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Wenn man sich diese Karte genau anschaut sieht man auf ihr einen Mann, der buchstäblich auf Münzen sitzt und sich an ihnen festhält, ganz so, als würde er sich davor fürchten, sich von ihnen trennen zu müssen. Genau dafür steht diese Karte. Die Angst vor einem Abschied von Besitz, Menschen, Gewohnheiten und Emotionen. Die Karte steht für die Angst vor Veränderung und für eine verzweifelte Sehnsucht nach Sicherheit. Aber das Festhalten an Dingen, Menschen und Besitz bringt Ihnen keine Sicherheit. Es wird Sie nur blockieren und ängstlich machen. Starrheit und Angst machen es Ihnen unmöglich, zu wachsen, sich zu bewegen, das Leben zu genießen. Die „Vier der Pentagramme“ sagt Ihnen, dass Sie sich ändern müssen, wenn Sie das Leben wirklich in vollen Zügen genießen wollen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte erzählt über die Angst. Und weil Sie Angst haben Ihr Partner zu verlieren, von ihr/ihm zurückgelassen zu worden, wollen Sie sie / ihn zu fest halten, so dass es fast unmöglich für Ihren Partner ist noch zu atmen. Wenn Sie nicht los lassen werden, werden Sie genau das provozieren, was genau Ihre größte Angst ist und Ihr Partner wird Sie verlassen.</p><p>Wenn Sie wirklich jemanden lieben, dann müssen Sie ihn/sie frei lassen, den Raum bieten, was er/sie gerne tun möchte und wollen. Vertrauen haben, dass er/sie Sie nicht verletzen oder verraten wird. Nur wenn Sie Sich Ihren größten Ängsten gegenüber stellen werden Sie in der Lage sein, sie gehen zu lassen. Erst dann gestatten Sie Ihren Partner sich frei Ihnen und der Beziehung gegenüber zu stehen.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit Sie sind starr, halten auf alten Gewohnheiten und wollen die vertraute Wege gehen. Auch wenn verlangt, finden Sie es schwierig Sich anzupassen an neuen Regeln und Verfahren. Es ist jetzt an der Zeit zu erkennen, dass das Loslassen der alten Wege in einer Weise auch befreiend sein kann. Demzufolge können Sie lernen und wachsen und sogar die \"neue\" Art und Weise schätzen.</p><p>Möglicherweise gefällt Ihnen Ihre Arbeit nicht mehr und machen Sie sie nur jeden Tag wegen der Sicherheit, die sie bietet, aber es bringt Ihnen keine Freude, keine Erfüllung mehr. Gönnen Sie sich die Freiheit des Denkens über Wechsel des Arbeitsplatzes, auf der Suche nach einer neuen Herausforderung, neue Chancen. Möglicherweise, wenn Sie mit anderen Möglichkeiten konfrontiert werden, können Sie Sich immer noch entscheiden zu bleiben, wo Sie sind, aber dann wird es eine bewusste Entscheidung sein und nicht eine Verpflichtung.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Vier der Pentagramme sagt Ihnen, dass die Zeit gekommen ist, um etwas Gewissensprüfung zu tun: Was sind Ihre größte Ängste? An welche Personen klammern Sie Sich? An welchen Objekten, Gefühlen oder Menschen halten Sie zu stark? Und außerdem, was ist der Grund, dass Sie Sich so fest an Dinge halten? Was denken Sie was passieren würde, wenn Sie einfach loslassen? Schauen Sie sich ehrlich in die Augen und versuchen Sie mal diese Fragen zu beantworten. Sobald Sie die Antwort haben können Sie erkennen, dass die wahre Freiheit liegt im Loslassen, bei der Schaffung von Raum zwischen Ihnen und alles und alle anderen. Sobald Sie das tun können, werden Sie endlich wieder durchatmen können. Um zu leben braucht man sich zu ändern!</p>",
                    }
                }
            },
            {
                filename: "68",
                numerology : 5,
                en : {
                    name: "Five of Pentacles",
                    suit : "Pentacles",
                    element : "🜃 - earth",
                    theme : "self-worth",    
                    description : "Five of Pentacles talks about your self-worth and learning not to under-estimate your own capacities (any longer).",
                    questions : [ "Are you neglecting yourself?", "Are you complicating things for yourself more than you need to?", "Can you learn to enhance your self-worth?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about deprivation, hardship, crisis, insecurity. Life is not feeling very good at the moment, you may feel like there is nothing or nobody you can count on. You may feel abandoned, lonely, deeply unhappy. Possibly you have no idea where to go from here. Still, there are positive sides to this card too: it tells you there are always people there who will help you, even if you don't see them right now, if you look really hard you will find them. Also, the situation you are in right now is a learning experience, albeit a harsh one. Once you have conquered all these problems, you will feel strong, loving, positive and you will have a clear view of where you want your life to go from that point on.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card signifies that you are probably feeling quite lonely in your relationship, you are not getting the attention and love you crave, your partner is distant. Maybe you are not sure what is wrong but you definitely feel that things aren't right the way they are now. If you don't see a possibility to change things because your partner isn't cooperating, then maybe you should consider ending the relationship. Remember there is not only one person on this earth you can be happy with.</p><p>If you are single you might feel unwanted and unnoticed. You may even think it impossible to find someone who wants to be in a relationship with you. Remember that one day you will find that special person who really cares for you, don't give up hope.</p>",
                        [GuidanceFields.WORK] : "<p>The Five of Pentacles indicates that you are feeling afraid and insecure. Maybe you are worrying about losing your job or not being able to end a project successfully. Maybe you don't have a job right now and are afraid of not finding one?</p><p>It is important for you to remember that, no matter what situation you are in, there is always a way out, even if you don't see it right now. Look around you for help and support, talk to people who might understand your plight, you are not alone!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You probably are going through a rough patch and that also has an influence on your spirituality. If you regularly meditate or do other spiritual exercises, you may find it hard to focus nowadays. It is also possible that you experience a deeply intense feeling of loneliness, as if your spiritual backup is gone. It is important that you do not try to go this road alone, look for help and support from people around you, you will notice they are there for you. Remember that helping is a two way street, both the helper and the helped feel better afterwards, they both have a sense of accomplishment. </p>",
                    }
                },
                fr : {
                    name : "Le Cinq de Pentacle",
                    suit : "Pentacles",
                    element :  "🜃 - terre",
                    theme : "valeur propre",    
                    description : "Le Cinq de Pentacle, nous parle de notre amour propre et de la capacité d'apprendre à ne pas nous sous-estimer (encore plus).",
                    questions : ["Vous oubliez vous-même?", "Rendez-vous les choses plus difficiles qu’elles ne le sont?", "Pouvez-vous apprendre à vous apprécier d’avantage?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente la privation, le besoin, la crise, l'insécurité. La vie n'est pas très bonne pour vous en ce moment. Vous sentez peut-être qu'il n'y a rien ou personne sur qui vous pouvez compter.  On peut aussi se sentir abandonné, seul, profondément malheureux. Vous ne savez peut-être pas où aller. Néanmoins, cette carte a aussi des côtés positifs : Il vous dit qu'il y a toujours des gens autour de vous qui veulent vous aider, même si vous ne les voyez pas maintenant. Regardez autour de vous très attentivement et sans préjugés et vous les trouverez. La situation dans laquelle vous vous trouvez en ce moment est aussi une expérience d'apprentissage, même si elle est peut-être difficile. Une fois que vous aurez surmonté tous ces problèmes, vous vous sentirez fort et positif. Vous aurez également une idée claire de l'orientation que vous souhaitez donner à votre vie à partir de ce moment.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card signifies that you are probably feeling quite lonely in your relationship, you are not getting the attention and love you crave, your partner is distant. Maybe you are not sure what is wrong but you definitely feel that things aren't right the way they are now. If you don't see a possibility to change things because your partner isn't cooperating, then maybe you should consider ending the relationship. Remember there is not only one person on this earth you can be happy with.</p><p>If you are single you might feel unwanted and unnoticed. You may even think it impossible to find someone who wants to be in a relationship with you. Remember that one day you will find that special person who really cares for you, don't give up hope.</p>",
                        [GuidanceFields.WORK] : "<p>The Five of Pentacles indicates that you are feeling afraid and insecure. Maybe you are worrying about losing your job or not being able to end a project successfully. Maybe you don't have a job right now and are afraid of not finding one?</p><p>It is important for you to remember that, no matter what situation you are in, there is always a way out, even if you don't see it right now. Look around you for help and support, talk to people who might understand your plight, you are not alone!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You probably are going through a rough patch and that also has an influence on your spirituality. If you regularly meditate or do other spiritual exercises, you may find it hard to focus nowadays. It is also possible that you experience a deeply intense feeling of loneliness, as if your spiritual backup is gone. It is important that you do not try to go this road alone, look for help and support from people around you, you will notice they are there for you. Remember that helping is a two way street, both the helper and the helped feel better afterwards, they both have a sense of accomplishment. </p>",
                    }
                },
                sp:{
                    name:"El Cinco de pentáculos",
                    suit: "Pentáculos",
                    element: "🜃 - tierra",
                    theme: "valor propio",    
                    description: "El Cinco de Pentáculos trata sobre la autoestima y nos muestran la habilidad de aprender a no subvalorarnos (nunca más)",
                    questions: ["¿Ponte en el frío?", "¿Lo haces más difícil para ti mismo de lo que es?", "¿Puedes aprender a apreciarte más?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es sobre las privaciones, penurias, la crisis, la inseguridad. La vida no se siente muy bien en este momento, usted puede sentir que no hay nada ni nadie que pueda contar. Usted puede sentirse abandonado, solo, profundamente infeliz. Posiblemente usted no tiene idea de dónde ir desde aquí. Sin embargo, hay aspectos positivos a esta tarjeta también: le dice que siempre hay gente allí que le ayudarán, incluso si usted no los ve ahora mismo, si se vea muy duro que va a encontrar. Además, la situación en qué se encuentra en este momento es una experiencia de aprendizaje, aunque sea dura. Una vez que haya vencido a todos estos problemas, se sentirá fuerte, amorosa, positiva y usted tendrá una visión clara de dónde quiere que su vida vaya de allí en adelante.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Pentakels Vijf",
                    suit : "Pentakels",
                    element : "🜃 - aarde",
                    theme : "eigen waarde",    
                    description : "De vijf van pentakels gaat over eigenwaarde en wijst ons op het kunnen leren jezelf niet (meer) onder te waarderen.",
                    questions : [ "Zet jij jezelf in de kou?", "Maak jij het je moeilijker dan nodig is?", "Kun jij leren om jezelf meer te waarderen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart wijst op verlies, moeilijke tijden, crisissen en onzekerheid. Het leven is op dit moment niet zo fijn voor jou. Misschien heb je zelfs het gevoel dat er niets of niemand is waar je op kan rekenen. Mogelijk voel je je verlaten, eenzaam, intens ongelukkig. En het kan best zo zijn dat je geen idee hebt welke koers je nu moet gaan varen. Toch zijn er ook positieve kanten aan deze kaart want ze geeft je de boodschap dat er altijd mensen zijn en zullen zijn die je willen helpen, ook al vind je het op dit moment moeilijk om dat te zien. Blijf rondkijken, doe dat zonder vooroordelen en je zal ontdekken wie er voor je wil zijn. Wat deze kaart je ook vertelt, is dat de situatie waarin je je nu bevindt een leermoment is, ook al gaat het hier om een harde les. Eens je al je problemen overwonnen hebt, zal je je sterk en positief voelen, je zal weer openbloeien. En je zal een duidelijk zicht hebben op wat je met je leven wil aanvangen, welke kant je nu op wil!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt dat je je op dit moment waarschijnlijk behoorlijk eenzaam voelt in je relatie, je krijgt niet de aandacht en liefde die je nodig hebt, het voelt alsof je partner afstandelijk is tegenwoordig. Misschien kan je niet helemaal duiden wat er precies aan de hand is maar je voelt dat de dingen niet kloppen zoals ze nu zijn. Als je geen mogelijkheid kan bedenken om deze situatie te veranderen of als je partner niet bereid is om mee te werken aan een oplossing, dan moet je misschien overwegen om de relatie te beëindigen. Houd jezelf voor ogen dat er meer dan één persoon op deze wereld is waarmee je gelukkig kan zijn.</p><p>Als je op dit moment alleen bent dan heb je misschien het gevoel dat niemand je wil, dat niemand je echt “ziet”. Je kan zelfs gaan denken dat er niemand bestaat die een liefdesrelatie met jou zou willen aangaan. En toch is het echt wel zo dat je op een dag iemand zal ontmoeten die echt om je geeft en met jou het leven wil delen. Geef de hoop niet op!</p>",
                        [GuidanceFields.WORK] : "<p>Pentakels Vijf wijst erop dat je je onzeker en bang voelt. Misschien ben je ongerust om je baan kwijt te raken of mogelijk is er een project of taak waarvan je denkt dat je het niet aankan, dat je het fout zal aanpakken. Of misschien heb je op dit ogenblik geen werk en vrees je ervoor om nog ooit iets te vinden dat bij jou past. Ook al zit je in een ontzettend moeilijke situatie op dit moment, waar het om gaat is dat je er nu aan denkt dat er altijd een uitweg is. Misschien zie je die uitweg, die oplossing nu even niet, maar ze is er wel. Zoek naar hulp in je omgeving, praat met mensen die begrijpen waar je mee bezig bent. Deze gesprekken zullen voor helderheid en inzichten zorgen en zo ben je voor je het weet op weg naar de juiste oplossing voor jouw problemen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je zit nu even in een zware en moeilijke periode en dat heeft ook invloed op je spirituele leven en bewustzijn. Als je geregeld mediteert of andere spirituele oefeningen doet, zal je merken dat het tegenwoordig erg moeilijk voor je is om je echt te focussen, je dwaalt makkelijk af, allerlei negatieve gedachten gaan door je hoofd ‘spoken’. Misschien ervaar je ook een diepe, intense eenzaamheid alsof je spirituele backup er plots niet meer is. De moeilijke weg die je nu te gaan hebt, leg je best niet alleen af. Richt je daarom op de mensen om je heen en je zal merken dat velen er voor je willen zijn, je willen ondersteunen bij je moeilijkheden. Denk eraan, helpen is een tweerichtingsweg, zowel de helper als de geholpene voelen zich er beter door, ze hebben allebei het gevoel dat ze iets bereikt hebben. Door je te laten helpen, help jij die ander dus ook!</p>",
                    }
                },
                de : {
                    name: "Fünf der Pentagramme",
                    suit : "Pentagramme",
                    element : "🜃 - Erde",
                    theme : "Eigener Wert",   
                    description : "Die Fünf der Pentagramme spricht von unserer reinen Liebe und der Fähigkeit zu lernen, um sich selbst nicht (noch mehr) zu unterschätzen.",
                    questions : [ "Vergessen Sie sich selbst?", "Machen Sie die Dinge schwieriger, als sie sind?", "Können Sie lernen, sich selber besser zu schätzen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für Entbehrung, Not, Krise, Unsicherheit. Das Leben ist in diesem Moment nicht sehr gut zu Ihnen. Sie können das Gefühl haben, dass es nichts oder niemanden gibt, auf den Sie sich verlassen können. Sie können sich auch im Stich gelassen, einsam und tief unglücklich fühlen. Möglicherweise haben Sie keine Ahnung, wohin Sie gehen sollen. Dennoch hat diese Karte auch positive Seiten: Sie sagt Ihnen, dass es immer Leute in Ihrer Umgebung gibt, die Ihnen helfen möchten, auch wenn Sie sie jetzt nicht sehen. Schauen Sie sich sehr gründlich und ohne Vorurteile um und Sie werden sie finden. Die Situation, in der Sie sich gerade befinden, ist auch eine Lernerfahrung, wenn auch vielleicht eine harte. Wenn Sie alle diese Probleme überwunden haben werden Sie sich stark und positiv fühlen. Sie werden dann auch ein klares Bild davon haben, wohin Sie Ihr Leben von diesem Zeitpunkt an ausrichten wollen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte bedeutet, dass Sie wahrscheinlich das Gefühl haben ganz einsam in Ihrer Beziehung zu sein, Sie spüren nicht mehr immer die Aufmerksamkeit und Liebe, wonach Sie sehnen, Ihr Partner scheint weit entfernt. Vielleicht sind Sie Sich nicht sicher, was falsch ist, aber auf jedem Fall haben Sie das Gefühl, dass die Dinge jetzt nicht so in Ordnung sind. Wenn Sie keine Möglichkeit sehen, die Dinge zu ändern, weil Ihr Partner nicht zusammen daran arbeiten will, dann müssen Sie Sich vielleicht mal fragen, ob Sie diese Beziehung nicht beenden wollen. Denken Sie daran, es gibt nicht nur eine Person auf dieser Erde, mit der man zufrieden leben kann.</p><p>Wenn Sie alleine sind haben Sie vielleicht das Gefühl unerwünscht und unbemerkt zu sein. Sie können sogar glauben, dass es unmöglich ist, dass es jemanden gibt, der in einer Beziehung mit Ihnen zusammen sein möchte. Denken Sie daran, dass es einem Tage geben wird, dass Sie eine spezielle Person, die Sie wirklich etwas bedeutet, finden werden. Geben Sie die Hoffnung nicht auf.</p>",
                        [GuidanceFields.WORK] : "<p>Die Fünf der Pentagramme zeigt an, dass Sie ängstlich und unsicher sind. Vielleicht haben Sie Angst Ihren Job zu verlieren oder sind Sie nicht in der Lage, ein Projekt erfolgreich zu beenden. Vielleicht haben Sie momentan keinen Job und haben Sie schon jetzt Angst, nicht einen zu finden?</p><p>Es ist wichtig, dass Sie daran denken, dass, egal in welcher Situation Sie Sich befinden, es immer einen Ausweg gibt, auch wenn Sie es nicht gerade sehen. Suchen Sie nach Hilfe und Unterstützung, sprechen Sie mit Menschen, die Ihre Situation verstehen. Sie sind nicht allein!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie sind wahrscheinlich durch eine schwierige Phase gegangen und das hat auch einen Einfluss auf Ihre Spiritualität. Wenn Sie regelmäßig meditieren oder andere spirituelle Übungen machen, könnten Sie es schwierig finden Sich jetzt zu konzentrieren. Es ist auch möglich, dass Sie ein tiefes intensives Gefühl der Einsamkeit erleben, als ob Ihre geistige Sicherungskopie verschwunden ist. Es ist wichtig, dass Sie nicht versuchen, diesen Weg allein zu gehen, suchen Sie nach Hilfe und Unterstützung von Menschen um Sie herum und Sie werden feststellen, dass sie für Sie da sind. Denken Sie daran, dass Hilfe keine Einbahnstraße ist, sowohl die Helfer als der Geholfen fühlen sich, nachdem besser und sie werden beide ein Gefühl der Erfüllung haben.</p>",
                    }
                }
            },
            {
                filename: "69",
                numerology : 6,
                en : {
                    name: "Six of Pentacles",
                    suit : "Pentacles",
                    element : "🜃 - earth",
                    theme : "choosing what you do",    
                    description : "Six of Pentacles talks about choosing what you do with what you have and about the importance of the balance between giving and receiving.",
                    questions : [ "Do you have a lot to offer?", "Can you also receive?", "Do you give or receive because it is the right thing to do?", "Can you find the right balance, in which you do not neglect yourself?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about being helpful and magnanimous, about tolerance. You may be the benefactor or maybe you are on the receiving end, still it is all about people helping each other out, be it materially, financially, emotionally or spiritually. One day you are the helper, the next day you may be the helped. It is important to share, to be generous, there will always be a day that we need someone or something to survive, to be happy.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>For the moment your relationship is well balanced. It is important that you do everything to keep it that way. Make sure that you not only give to and support your partner, but also accept support, allow your partner to help you. When times are rough it is important for you to be there for each other, to encourage and inspire one another. By living through difficulties together you become stronger and more united as a couple. If you are currently single, it is important for you to let everybody around you know that you are looking for a partner. It is very likely you will meet someone through friends or acquaintances.</p>",
                        [GuidanceFields.WORK] : "<p>At work things are looking up, you are being supported not only by colleagues but also by your superiors. Everybody seems to want you to be a success. Now is the right time to ask for a raise or a promotion. Or maybe you want to go out and find that job you've been dreaming about? You are very likely to succeed. </p><p>If you've been thinking about starting your own business, now is the right time, go for it!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually speaking you have been gathering a lot of knowledge and insights, your spiritual understanding is profound. Still, it is well possible you don't fully realize this, you tend to underestimate yourself. Having all this knowledge, it is important for you to start sharing it with the people around you. Let them profit from the fruits of the spiritual journey you made. And while sharing, you will realize that you too have a lot to learn from the wisdom of others. This mutual spiritual sharing will be very beneficial to everyone.</p>",
                    }
                },
                fr : {
                    name : "Le Six de Pentacle",
                    suit : "Pentacles",
                    element :  "🜃 - terre",
                    theme : "Choisissez pour ce que vous faites",    
                    description : " Le Six de Pentacle, nous parle de choisir ce que nous faisons de ce que nous avons et de l'importance de l'équilibre entre donner et recevoir.",
                    questions : ["Avez-vous beaucoup à donner?", "Pouvez-vous également recevoir?", "Donnez ou recevez-vous de par vous-même, ou parce qu’il se doit?", "Pouvez-vous fournir le bon équilibre, sans se perdre?"],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte est synonyme de serviabilité, de générosité et de tolérance. Vous pouvez être le bienfaiteur ou peut-être en êtes-vous le bénéficiaire. Il s'agit toujours des personnes qui s'entraident, que ce soit sur le plan matériel, financier, émotionnel ou spirituel. Un jour vous êtes l'assistant, le lendemain vous pouvez être celui qui sera aidé. Il est important de partager, d'être généreux. Il y aura toujours un jour où nous aurons besoin de quelqu'un ou de quelque chose pour nous aider à survivre et à trouver le bonheur.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>For the moment your relationship is well balanced. It is important that you do everything to keep it that way. Make sure that you not only give to and support your partner, but also accept support, allow your partner to help you. When times are rough it is important for you to be there for each other, to encourage and inspire one another. By living through difficulties together you become stronger and more united as a couple. If you are currently single, it is important for you to let everybody around you know that you are looking for a partner. It is very likely you will meet someone through friends or acquaintances.</p>",
                        [GuidanceFields.WORK] : "<p>At work things are looking up, you are being supported not only by colleagues but also by your superiors. Everybody seems to want you to be a success. Now is the right time to ask for a raise or a promotion. Or maybe you want to go out and find that job you've been dreaming about? You are very likely to succeed. </p><p>If you've been thinking about starting your own business, now is the right time, go for it!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually speaking you have been gathering a lot of knowledge and insights, your spiritual understanding is profound. Still, it is well possible you don't fully realize this, you tend to underestimate yourself. Having all this knowledge, it is important for you to start sharing it with the people around you. Let them profit from the fruits of the spiritual journey you made. And while sharing, you will realize that you too have a lot to learn from the wisdom of others. This mutual spiritual sharing will be very beneficial to everyone.</p>",
                    }
                },
                sp:{
                    name:"El Seis Pentáculos",
                    suit: "Pentáculos",
                    element: "🜃 - tierra",
                    theme: "elige por lo que haces",    
                    description: "El Seis de Pentáculos se trata de elegir qué hacer con lo que tienes y de señalarnos la importancia del equilibrio entre dar y recibir",
                    questions: ["¿Tienes mucho á dar?", "¿También puedes recibir?", "¿Das o recibes de ti mismo, o porque así es como debe ser?", "¿Puedes mantener el equilibrio adecuado, sin olvidarte de ti mismo?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es acerca de ser útil y magnánimo, sobre la tolerancia. Usted puede ser el benefactor o tal vez usted está en el extremo receptor, siendo que se trata de personas ayudando unos a otros, ya sea material, económica, emocional o espiritualmente. Un día es el ayudante, al día siguiente  puede ser la ayudaste. Es importante compartir, a ser generosos, siempre habrá un día en que necesitamos a alguien o algo para sobrevivir, para ser feliz.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Pentakels Zes",
                    suit : "Pentakels",
                    element : "🜃 - aarde",
                    theme : "kiezen voor wat je doet",    
                    description : "De zes van pentakels gaat over kiezen voor wat je doet met wat je hebt en wijst ons op het belang van het evenwicht tussen geven en ontvangen.",
                    questions : [ "Heb jij veel te geven?", "Kun jij ook ontvangen?", "Geef of ontvang jij uit jezelf, of omdat het 'zo' hoort?", "Kun jij voor een juiste balans zorgen, waarbij jij jezelf niet vergeet?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart gaat over behulpzaam en grootmoedig zijn maar ook over verdraagzaamheid. Of je nu de helper of de geholpene bent, hier gaat het om mensen die mekaar helpen op allerlei vlak: materieel, emotioneel, financieel, spiritueel. De ene dag help je iemand uit de nood, de volgende dag is er iemand die jou een helpende hand toesteekt. Deze kaart vertelt je dat het heel belangrijk is om te delen, om vrijgevig te zijn. Hoe goed je leven ook is, er komt altijd een dag waarop je iets of iemand nodig hebt om je te helpen overleven, iemand om je te ondersteunen in je zoektocht naar geluk.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit ogenblik is je relatie goed in evenwicht. Toch is het belangrijk dat je er alles aan doet om het zo te houden. Zorg ervoor dat je niet enkel geeft aan je partner, maar dat je ook steun aanvaardt van de ander, dat je je door je partner laat helpen. In moeilijke tijden is het belangrijk dat je er bent voor mekaar, dat je elkaar inspireert en moed inspreekt. Je wordt er als koppel sterker en meer één van als je samen moeilijkheden het hoofd biedt.</p><p>Als je op dit moment op zoek bent naar een geliefde dan is het belangrijk dat je iedereen in je omgeving laat weten dat je openstaat voor een ontmoeting. Het is immers heel waarschijnlijk dat je iemand zal leren kennen via vrienden of kennissen.</p>",
                        [GuidanceFields.WORK] : "<p>Op werkgebied begint het er beter uit te zien, je wordt niet enkel ondersteund door je collega’s maar ook door je meerderen. Iedereen lijkt samen te werken om ervoor te zorgen dat jij succesvol bent. Dit is dus echt een goed moment om een promotie of opslag te vragen. Of misschien ben je er al lang van aan het dromen om op zoek te gaan naar de ideale baan voor jou? Daarvoor is het nu absoluut een goede tijd, ga ervoor!</p><p>Als je overweegt om je eigen bedrijf op te starten dan is het nu het juiste moment om dit te doen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritueel gezien heb je een boel kennis en inzichten vergaard, je spirituele ‘begrip’ is grondig en diep. Toch is het mogelijk dat je je hier niet helemaal van bewust bent want je hebt soms de neiging om jezelf te onderschatten. Omdat je nu al die kennis hebt, is het belangrijk dat je die ook gaat delen met de mensen om je heen. Laat hen meegenieten met de vruchten van al je inspanningen. Gun anderen een blik op de resultaten van de spirituele reis die je afgelegd hebt. En terwijl je je spirituele rijkdom aan het delen bent met anderen zal je gaan inzien dat jij ook veel kan hebben aan de kennis van anderen. Deze wederzijdse spirituele uitwisseling zal een positieve stap zijn voor iedereen die eraan meedoet!</p>",
                    }
                },
                de : {
                    name: "Sechs der Pentagramme",
                    suit : "Pentagramme",
                    element : "🜃 - Erde",
                    theme : "Wählen, was man tut",    
                    description : "Die Sechs der Pentagramme spricht darüber zu wählen, was wir mit dem tun, was wir haben, und wie wichtig die Balance ist zwischen Geben und Empfangen.",
                    questions : [ "Haben Sie viel zu geben?", "Können Sie auch empfangen?", "Geben oder empfangen Sie von sich aus, oder weil es sich gehört?", "Können Sie das richtige Gleichgewicht schaffen, ohne sich zu verlieren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für Hilfsbereitschaft, Großzügigkeit und Toleranz. Sie können der Wohltäter sein oder vielleicht sind Sie auch der Empfangende. Immer geht es um Menschen, die einander helfen, sei es materiell, finanziell, emotional oder spirituell. An einem Tag sind Sie der Helfer, am nächsten Tag können Sie es sein, dem geholfen wird. Es ist wichtig zu teilen, großzügig zu sein. Es wird immer einen Tag geben, an dem wir jemanden oder etwas brauchen, der oder das uns hilft zu überleben und uns bei der Suche nach Glück unterstützt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Zur Zeit ist Ihre Beziehung gut ausgewogen. Es ist wichtig, dass Sie alles tun, um es so zu behalten. Stellen Sie sicher, dass Sie nicht Ihren Partner nicht nur Hilfe und Unterstützung geben, sondern auch Hilfe und Unterstützung von Ihrem Partner akzeptieren. Gestatten Sie Ihren Partner Ihnen zu helfen. Wenn die Zeiten auch hart sind, es ist wichtig für Sie für einander da zu sein, zu fördern und sich gegenseitig zu inspirieren. Zusammenhalten in schwierigen Zeiten macht Sie stärker und vereinter als Paar. Wenn Sie noch alleine sind, ist es wichtig, dass Sie alle um Sie herum wissen lassen, dass Sie auf der Suche sind nach einem Partner. Es ist sehr wahrscheinlich, dass Sie jemanden unter Freunden oder Bekannten begegnen.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit sehen die Dinge gut aus. Sie werden nicht nur von Kollegen, sondern auch von Ihren Vorgesetzten unterstützt. Jeder scheint zu wollen, dass Sie erfolgreich sind. Jetzt ist der richtige Zeitpunkt, um eine Gehaltserhöhung oder eine Beförderung zu bitten. Oder vielleicht möchten Sie auf der Suche gehen nach einer Arbeit, von der Sie schon immer geträumt haben? Es ist sehr wahrscheinlich, dass Sie dabei erfolgreich sind.</p><p>Wenn Sie mal auch über ein eigenes Geschäft nachgedacht haben, ist es jetzt der richtige Zeitpunkt. Mache es!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spirituell gesprochen haben Sie eine Menge Wissen und Erkenntnisse gesammelt und ist Ihr spirituelles Verständnis tiefer. Trotzdem ist es gut möglich, dass Sie nicht völlig anerkennen, dass es so ist. Sie neigen dazu, sich zu unterschätzen. Mit all diesem Wissen ist es wichtig, dass Sie anfangen mit dem Austausch dessen mit den Menschen um Sie herum. Lassen Sie sie genießen von den Früchten dieser spirituellen Reise, die Sie gerade machen. Und beim Teilen, werden Sie feststellen, dass auch Sie viel zu lernen haben von der Weisheit von anderen. Dieser gegenseitige spirituelle Austausch wird sich als sehr vorteilhaft für alle ausweisen.</p>",
                    }
                }
            },
            {
                filename: "70",
                numerology : 7,
                en : {
                    name: "Seven of Pentacles",
                    suit : "Pentacles",
                    element : "🜃 - earth",
                    theme : "going on with what you possess",    
                    description : "Seven of Pentacles talks about going on with what you have accomplished and about moving on in the same direction.",
                    questions : [ "Have you gained knowledge and experience?", "What will you actually do with it?", "Are you reaping the fruits of your labor?", "Do you need to break a cycle to do so?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Seven of Pentacles is often about patience and reaping what you have sown. Many things in life need time to grow, to develop, and during that process it is up to you to nurture, take care, invest time and effort. It is very likely that now the time has come for you to cash in on your investments, be it financially, emotionally, practically. </p><p>In general you are feeling secure, you have a sense that things are going well for you and you have high hopes for the future. This is not a time to be having doubts, if you are feeling any it would be wise to examine them and then to realize that there is no cause for them.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that your relationship is slowly and steadily growing. The hard work and energy you have invested in the past is now paying off. Be sure to enjoy this situation as much as you can.</p><p>If you have just started a new relationship it is important for you not to rush into it. Enjoy all things new and pure, take the time to get to know your partner. It is only normal that the relationship takes time and cherishing in order to grow to its full potential.</p>",
                        [GuidanceFields.WORK] : "<p>At work everything is probably going very well. You are steadily growing and evolving and you are reaping the fruits of past labor and of the energy you have putinto your job. Remember that in this chapter of your life it's not about quick fixes or results. Still, all the efforts you put into a job or project will be duly rewarded. You are and will be financially safe and contented.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Seven of Pentacles tells you that for now it is important to concentrate on the fact that life is all about cycles, what you put into it will come back to you some day. If you live with this basic truth every day of your life you know that when you invest in people, be it material, financial, emotional or spiritual, you are actually investing in yourself. One day what you are giving away right now will be given back to you at a time when you need it. To give is to receive.</p>",
                    }
                },
                fr : {
                    name : "Le Sept de Pentacle",
                    suit : "Pentacles",
                    element :  "🜃 - terre",
                    theme : "Continuer avec ce que l’on possède",    
                    description : " Le Sept de Pentacle, nous parle de continuer avec ce que nous avons construit et que nous pouvons continuer.",
                    questions : ["Avez-vous acquis de la connaissances et de l'expérience?", "Qu'allez-vous réellement en faire?", "Allez-vous récolter les fruits de vos propres efforts?", "Avez-vous besoin de percer quelque chose pour cela?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Sept de Pentagramme\" représente souvent la patience et la moisson après avoir semé. Beaucoup de choses dans la vie ont besoin de temps pour grandir, pour se développer. Au cours de ce processus, il est important que vous vous occupiez de ces choses, que vous preniez soin d'elles et que vous y consacriez du temps et de l’effort. Il est très probable que le moment est venu de profiter de vos investissements, qu'ils soient financiers, émotionnels ou engagés dans divers aspects du quotidien.</p><p>En général, vous vous sentez complètement en sécurité. Vous avez le sentiment que les choses vont bien pour vous et vous avez de grands espoirs pour l'avenir. Ce n'est pas le moment de douter. Si vous avez encore des doutes, il serait sage de les examiner et de reconnaître qu'il n'y a aucune raison de les avoir.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that your relationship is slowly and steadily growing. The hard work and energy you have invested in the past is now paying off. Be sure to enjoy this situation as much as you can.</p><p>If you have just started a new relationship it is important for you not to rush into it. Enjoy all things new and pure, take the time to get to know your partner. It is only normal that the relationship takes time and cherishing in order to grow to its full potential.</p>",
                        [GuidanceFields.WORK] : "<p>At work everything is probably going very well. You are steadily growing and evolving and you are reaping the fruits of past labor and of the energy you have putinto your job. Remember that in this chapter of your life it's not about quick fixes or results. Still, all the efforts you put into a job or project will be duly rewarded. You are and will be financially safe and contented.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Seven of Pentacles tells you that for now it is important to concentrate on the fact that life is all about cycles, what you put into it will come back to you some day. If you live with this basic truth every day of your life you know that when you invest in people, be it material, financial, emotional or spiritual, you are actually investing in yourself. One day what you are giving away right now will be given back to you at a time when you need it. To give is to receive.</p>",
                    }
                },
                sp:{
                    name:"El Siete de Pentáculos",
                    suit: "Pentáculos",
                    element: "🜃 - tierra",
                    theme: "continúa con lo que tienes",    
                    description: "El Siete de Pentáculos se trata de continuar con lo que has construido y nos recuerda que podemos continuar",
                    questions: ["¿Has adquirido conocimientos y experiencia?", "¿Qué vas a hacer con eso?", "¿Vas a cosechar las recompensas de tus propios esfuerzos?", "¿Necesitas romper algo para eso?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Siete de Oros es a menudo acerca de la paciencia y cosechando lo que han sembrado. Muchas cosas en la vida necesitan tiempo para crecer, desarrollarse, y durante ese proceso que depende de usted para nutrir, cuidar, invertir tiempo y esfuerzo. Es muy probable que ahora haya llegado el momento para que usted pueda sacar provecho de sus inversiones, ya sea económica, emocional, prácticamente.</p><p>En general se siente seguro, usted tiene la sensación de que las cosas van bien para usted y usted tiene grandes esperanzas para el futuro. Este no es un tiempo para estar teniendo dudas, si se siente ningún sería prudente para examinarlos y luego darse cuenta de que no hay causa para ellos.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Pentakels Zeven",
                    suit : "Pentakels",
                    element : "🜃 - aarde",
                    theme : "verder gaan met wat je bezit",    
                    description : "De zeven van pentakels gaat over verder gaan met wat je hebt opgebouwd en wijst ons erop dat we door kunnen gaan.",
                    questions : [ "Heb jij kennis en ervaring opgedaan?", "Wat ga jij daarmee daadwerkelijl doen?", "Pluk jij de vruchten van je eigen inzet?", "Dien jij daarvoor iets te doorbreken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Pentakels Zeven draait eigenlijk om geduld en oogsten wat je gezaaid hebt. Veel dingen in het leven hebben tijd nodig om te groeien en zich te ontwikkelen. Tijdens dat groeiproces is het jouw taak om deze dingen te voeden en te verzorgen, om tijd en energie te investeren. En net omdat je dat gedaan hebt is nu waarschijnlijk het moment gekomen waarop je investering opbrengsten zal voortbrengen, of het nu emotioneel, financieel of praktisch is.</p><p>Over het algemeen voel je je veilig en rustig, je hebt het gevoel dat het leven goed voor je is. Je hebt goede hoop voor je toekomst. Dit is geen tijd om te gaan twijfelen. Als dat wel het geval is, als je met twijfels zit, dan is het belangrijk dat je grondig onderzoekt waar ze vandaan komen. Je zal waarschijnlijk ontdekken dat er geen enkele objectieve reden voor is.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt je dat je relatie langzaam maar zeker aan het groeien is. Het harde werk en de energie die je in het verleden geïnvesteerd hebt werpen nu hun vruchten af. Geniet hier zoveel mogelijk van, je hebt het verdiend!</p><p>Als je net begonnen bent aan een nieuwe relatie dan is het belangrijk dat je hiervoor rustig de tijd neemt. Geniet volop van alle nieuwe en ongewone aspecten, neem uitgebreid de tijd om je partner te leren kennen. Het is nu eenmaal zo dat een nieuwe relatie pas haar volle potentieel kan bereiken als je aan een rustig tempo volop liefde en aandacht investeert en je nieuwe partner koestert op alle mogelijke manieren.</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk verlopen de zaken waarschijnlijk behoorlijk goed. Je bent aan een rustig maar gelijkmatig tempo aan het groeien en evolueren en je plukt volop de vruchten van de aandacht en energie die je in het verleden geïnvesteerd hebt.</p><p>Houd er rekening mee dat het huidige hoofdstuk van je leven er niet om gaat dat je snelle vorderingen maakt of oppervlakkige oplossingen overweegt, het is net het omgekeerde. En je zal merken dat alle inspanningen die je geleverd hebt ook op de juiste manier beloond zullen worden. Je bent op dit moment financieel veilig en tevreden en dit zal nog enige tijd zo door blijven gaan.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Pentakels Zeven vertelt je dat het belangrijk is om je erop te focussen dat het leven om cyclussen draait, groeiprocessen. De ene periode investeer je volop en de komende periode geniet je van de vruchten die jouw investeringen je brengen. Wat je erin stopt komt in veelvoud naar je terug. Als je elke dag van je leven baseert op deze eenvoudige waarheid dan weet je dat, als je in mensen investeert (of het nu materieel, emotioneel, financieel of spiritueel is), je dan eigenlijk in jezelf aan het investeren bent. Wat je vandaag weggeeft, dat zal je op een dag terugkrijgen, net op het moment dat je dat het meeste nodig hebt. Geven is ontvangen.</p>",
                    }
                },
                de : {
                    name: "Sieben der Pentagramme",
                    suit : "Pentagramme",
                    element : "🜃 - Erde",
                    theme : "Mit dem fortfahren, was man hat.",    
                    description : "Die Sieben der Pentagramme spricht von der Fortsetzung dessen, was wir aufgebaut haben und was wir weiterführen können.",
                    questions : [ "Haben Sie Wissen und Erfahrung gesammelt?", "Was werden Sie wirklich damit tun?", "Werden Sie die Früchte Ihrer eigenen Bemühungen ernten?", "Müssen Sie etwas dafür tun?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Sieben der Pentagramme“ steht oft für Geduld und für die Ernte, nachdem man gesät hat. Viele Dinge im Leben brauchen Zeit um zu wachsen, sich zu entwickeln. Während dieses Prozesses ist es für Sie wichtig, diese Dinge zu pflegen, sich um sie zu kümmern und Zeit und Mühe zu investieren. Es ist sehr wahrscheinlich, dass jetzt die Zeit gekommen ist, von Ihren Investitionen zu profitieren, sei es nun in finanzieller, emotionaler oder praktischer Hinsicht.</p><p>Im Allgemeinen fühlen Sie sich vollkommen sicher. Sie haben das Gefühl, dass die Dinge gut für Sie laufen und Sie haben große Hoffnungen in die Zukunft. Jetzt ist nicht die Zeit zu zweifeln. Wenn Sie dennoch Zweifel haben wäre es klug, sie zu überprüfen und dann anzuerkennen, dass es keinen Grund dafür gibt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte sagt Ihnen, dass Ihre Beziehung langsam aber stetig wachst. Die harte Arbeit und Energie, die Sie in der Vergangenheit investiert haben, zahlt sich jetzt aus. Achten Sie darauf, dass Sie diese Situation so viel wie möglich genießen.</p><p>Wenn Sie gerade eben eine neue Beziehung angefangen haben, ist es wichtig, dass Sie Sich nicht gleich in sie hinein stürzen. Genießen Sie alles was neu und rein ist, nehmen Sie die Zeit, um Ihren Partner kennen zu lernen. Es ist nur normal, dass eine Beziehung Zeit und Pflegung braucht um zu vollem Wachstum zu geraten.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit läuft alles wahrscheinlich sehr gut. Sie wachsen und entwickeln Sich ständig und ernten jetzt die Früchte der harten Arbeit und Energie, die Sie in Ihrem Job investiert haben. Denken Sie daran, dass es in diesem Kapitel in Ihrem Leben nicht geht um schnelle Lösungen oder Ergebnisse. Dennoch werden alle Anstrengungen die Sie leisten in einem Job oder einem Projekt ordnungsgemäß belohnt. Sie sind und bleiben finanziell sicher und zufrieden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Sieben der Pentakeln sagt Ihnen, dass es jetzt wichtig ist, Sich auf die Tatsache zu konzentrieren, dass das Leben alles mit Kreis laufen zu tun hat. Was Sie eingeben wird eines Tages zu Ihnen zurückkommen. Wenn Sie mit dieser grundlegenden Wahrheit jeden Tag leben wissen Sie, dass, wenn Sie in Menschen investieren, sei es materiell, finanziell, emotional oder spirituell, Sie eigentlich in Sich Selbst investieren. Eines Tages kommt das, was Sie gegeben haben, wieder bei Ihnen zurück, wenn Sie es am meisten brauchen. Geben heißt empfangen.</p>",
                    }
                }
            },
            {
                filename: "71",
                numerology : 8,
                en : {
                    name: "Eight of Pentacles",
                    suit : "Pentacles",
                    element : "🜃 - earth",
                    theme : "taking responsibility for your actions",    
                    description : "Eight of Pentacles talks about taking responsibility for what you can do and that this is a work in progress without overthinking the possible perceptions of others about it.",
                    questions : [ "What are you good at?", "Do you allow yourself to take it out the backyard?", "Are you blocked by what others think about it?", "Can you take it out the backyard?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card usually is about work and indicates that you are entering a period in which you will have to work, maybe harder than you usually do. This work may be situated in your professional or in your private life. Three things are important: be sure to focus on the goal you are trying to reach and not only on the work itself, make sure you maintain a certain balance in your life and if you think you need help, don't hesitate to ask for it.</p><p>Sometimes this card is also about literally working with your hands, creating things. If this is the case, the card tells you that a very creative period is near, enjoy it!</p><p>Finally this card may also signify beginner's luck.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Eight of Pentacles tells you that work is taking up too much space in your relationship, be it your workload or your partner's. It is absolutely necessary that you make more time and space for eachother if you want this relationship to last.</p><p>If you are currently single you may want to ask yourself if you are really committed to finding a partner. Maybe right now your job is more important to you? If the latter is true, no problem, but maybe you should temporarily stop looking for a love in your life.</p>",
                        [GuidanceFields.WORK] : "<p>This is a time when you can expect to get a lot of new tasks or assignments. Maybe your workload will even become overwhelming to such a degree it is impossible for you to do everything right, the way it should be done. If this is the case, do not hesitate to talk to your superiors and to ask them to help prioritize your tasks, maybe even transfer some tasks to a colleague.</p><p>If you are looking for a job you may find yourself confronted with high demands, a thorough testing of your skills. Don’t panic or hesitate, you are stronger than you realize and you will be able to meet those demands.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>No matter if you have a traditional job or a more alternative one, now may be the time to ask yourself why you are in the place you are today.Are you doing the things you know you are best at? Are you growing, evolving? Or have you been influenced by the opinions of others when choosing your job or career? Are you ready to allow yourself to pursue a job where your skills will be used and even welcomed? Are you ready to really (spiritually) be yourself at work too?</p>",
                    }
                },
                fr : {
                    name : "Le Huit de Pentacle",
                    suit : "Pentacles",
                    element :  "🜃 - terre",
                    theme : "Assumer la responsabilité de ce que l’on fait",    
                    description : "Le Huit de Pentacle, nous parle de prendre la responsabilité de ce que nous pouvons faire et que nous pouvons y travailler, sans avoir à trop nous soucier de ce que les autres pourraient en penser.",
                    questions : ["Que savez-vous bien faire?", "Vous permettez-vous d'en ressortir quelque chose?", "Vous laissez-vous arrêter par ce que les autres pensent?", "Pouvez-vous réaliser votre propre projet?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente habituellement le travail et signifie qu'un temps est venu pour vous où vous devrez peut-être travailler plus fort que vous n'en avez l'habitude. Cela peut affecter votre vie professionnelle et votre vie privée. Trois choses sont importantes : assurez-vous de vous concentrer sur l'objectif que vous voulez atteindre et pas seulement sur le travail lui-même. Assurez-vous d'avoir un certain équilibre dans votre vie et, si vous pensez avoir besoin d'aide, n'hésitez pas à le demander.... </p><p>Parfois, cette carte représente aussi littéralement l'artisanat, la fabrication des choses. Si tel est le cas, cette carte vous indique qu'une phase très créative est sur le point de commencer. Profitez-en bien !</p><p>Enfin, cette carte peut aussi indiquer la chance du débutant.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Eight of Pentacles tells you that work is taking up too much space in your relationship, be it your workload or your partner's. It is absolutely necessary that you make more time and space for eachother if you want this relationship to last.</p><p>If you are currently single you may want to ask yourself if you are really committed to finding a partner. Maybe right now your job is more important to you? If the latter is true, no problem, but maybe you should temporarily stop looking for a love in your life.</p>",
                        [GuidanceFields.WORK] : "<p>This is a time when you can expect to get a lot of new tasks or assignments. Maybe your workload will even become overwhelming to such a degree it is impossible for you to do everything right, the way it should be done. If this is the case, do not hesitate to talk to your superiors and to ask them to help prioritize your tasks, maybe even transfer some tasks to a colleague.</p><p>If you are looking for a job you may find yourself confronted with high demands, a thorough testing of your skills. Don’t panic or hesitate, you are stronger than you realize and you will be able to meet those demands.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>No matter if you have a traditional job or a more alternative one, now may be the time to ask yourself why you are in the place you are today.Are you doing the things you know you are best at? Are you growing, evolving? Or have you been influenced by the opinions of others when choosing your job or career? Are you ready to allow yourself to pursue a job where your skills will be used and even welcomed? Are you ready to really (spiritually) be yourself at work too?</p>",
                    }
                },
                sp:{
                    name:"El Ocho de Pentáculos",
                    suit: "Pentáculos",
                    element: "🜃 - tierra",
                    theme: "Asumir la responsabilidad de lo que se hace",    
                    description: "El Ocho de Pentáculos trata de asumir la responsabilidad de lo que puedes hacer y nos señalan que podemos trabajar así, sin tener que preocuparnos demasiado por lo que otra persona pueda pensar",
                    questions: ["¿En qué eres bueno?", "¿Te (dejas) permites hacer algo con eso?", "¿Te dejas detener por lo que otros piensan?", "¿Puedes realizar tu propio plan?"],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es generalmente sobre el trabajo e indica que está entrando en un período en el que se tendrá que trabajar, tal vez más difícil de lo normal. Este trabajo puede ser situado en su profesional o en su vida privada. Tres cosas son importantes: asegúrese de centrarse en el objetivo que usted está intentando alcanzar y no sólo en la propia obra, asegúrese de mantener un cierto equilibrio en su vida y si usted piensa que necesita ayuda, no dude en pedir ella.</p><p>A veces, esta tarjeta también se trata, literalmente, trabajar con las manos, la creación de las cosas. Si este es el caso, la tarjeta que dice que un periodo muy creativo está cerca, que lo disfruten!</p><p>Por último, esta tarjeta también puede significar la suerte del principiante.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Pentakels Acht",
                    suit : "Pentakels",
                    element : "🜃 - aarde",
                    theme : "verantwoording nemen voor wat je doet",    
                    description : "De acht van pentakels gaat over verantwoording nemen voor wat je kan en wijst ons erop dat we daarmee kunnen werken, zonder ons al te veel aan hoeven te trekken wat een ander ervan denken kan.",
                    questions : [ "Waar ben jij goed in?", "Sta jij jezelf toe om daar iets mee te doen?", "Laat jij je weerhouden door wat anderen ervan denken?", "Kun jij je eigen plan uitvoeren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Bij deze kaart gaat het vaak om werken. Ze wijst erop dat je nu in een fase komt waarin je waarschijnlijk harder zal moeten werken dan je normaal gezien doet. Het werk waarom het gaat kan zich situeren op carrièrevlak maar ook in je privéleven. Op dit moment zijn er drie dingen belangrijk: zorg ervoor dat je gefocust blijft op het doel dat je wil bereiken en niet enkel aandacht besteedt aan de inspanning zelf, besteed aandacht aan het evenwicht in je leven, en als je denkt dat je hulp nodig hebt, vraag er dan om zonder aarzelen.</p><p>Soms symboliseert deze kaart ook letterlijk werken met je handen, dingen creëren. Als dat het geval is, dan begint er nu voor jou een heel creatieve en vruchtbare periode, geniet ervan!</p><p>Tenslotte kan deze kaart er ook op wijzen dat je beginnersgeluk zal hebben.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Pentakels Acht vertelt je dat werk teveel ruimte inneemt in je relatie. Het is mogelijk dat jij te hard werkt maar het kan ook om de baan van je partner gaan die teveel ruimte inneemt. In elk geval is het absoluut noodzakelijk dat jullie meer tijd maken voor mekaar en ervoor zorgen dat je geregeld de ruimte hebt om in mekaar op te gaan. Als je dit niet doet is het mogelijk dat je relatie niet zal blijven duren. Als je op dit moment single bent, stel jezelf dan eens de vraag of je in je huidige situatie wel de ruimte hebt voor een liefdespartner en –relatie. Ben je hier absoluut zeker van? Misschien is op dit moment je werk wel belangrijker voor je dan de liefde. Als dat zo is dan is dat geen probleem, het is een kwestie van prioriteiten stellen, jij bepaalt wat je wel en niet wil. Maar misschien is het dan ook zinvol om even niet meer op zoek te gaan naar de liefde.</p>",
                        [GuidanceFields.WORK] : "<p>Het is op dit moment waarschijnlijk zo dat je veel nieuwe taken of opdrachten krijgt op het werk. Misschien is je takenpakket dusdanig groot geworden dat het je een stuk overweldigt en dat het niet meer mogelijk is om alle taken op een goede manier uit te voeren. De kwaliteit van je werk lijdt mogelijk onder je overvolle agenda. Als dit het geval is, aarzel dan niet en schakel je meerderen in om je te helpen met prioriteiten stellen. Misschien kan je tijdelijk ook een aantal taken doorgeven aan een collega.</p><p>Als je op zoek bent naar werk dan is het mogelijk dat er nu behoorlijk hoge eisen gesteld worden bij het solliciteren en dat je vaardigheden grondig getest worden. Probeer rustig te blijven, laat je niet overweldigen, je bent sterker dan je denkt en je kan deze uitdagingen zeker aan!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het maakt niet uit of je een traditionele of alternatieve baan hebt, op dit moment is het belangrijk dat je je eens afvraagt waarom je op je huidige plek zit. Doe je echt de dingen die je het beste kan en die je het meeste aanspreken? Ben je nog steeds aan het groeien en evolueren? Of heb je je door je onzekerheid en je omgeving laten beïnvloeden bij het kiezen van je huidige baan? Als dat laatste het geval is, als je nu niet (meer) op je plek zit, vraag jezelf dan of je er klaar voor bent om op zoek te gaan naar een baan waarin je vaardigheden en eigenschappen volop tot hun recht kunnen komen, een plek waar jijzelf en je kwaliteiten echt welkom zijn. Misschien ben je er wel klaar voor om ook in je werksituatie helemaal en ook spiritueel jezelf te zijn.</p>",
                    }
                },
                de : {
                    name: "Acht der Pentagramme",
                    suit : "Pentagramme",
                    element : "🜃 - Erde",
                    theme : "Die Verantwortung dafür übernehmen, was man tut.",    
                    description : "Die Acht der Pentagramme spricht darüber, die Verantwortung dafür zu übernehmen, was wir tun können und dass wir daran arbeiten können, ohne uns zu sehr darum kümmern zu müssen, was andere darüber denken könnten.",
                    questions : [ "Worin sind Sie besonders gut?", "Erlauben Sie sich, etwas daraus zu machen?", "Lassen Sie sich von dem abhalten, was andere darüber denken?", "Können Sie Ihr eigenes Projekt realisieren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht in der Regel für Arbeit und bedeutet, dass für Sie eine Zeit gekommen ist, in der Sie möglicherweise härter arbeiten müssen, als Sie das in der Regel gewohnt sind. Das kann sich sowohl auf Ihr berufliches als auch auf Ihr privates Leben beziehen. Drei Dinge sind wichtig: Achten Sie darauf, sich auf das Ziel, dass Sie erreichen möchten, zu konzentrieren und nicht nur auf die Arbeit selbst. Stellen Sie sicher, dass Sie ein gewisses Gleichgewicht in Ihrem Leben haben; falls Sie glauben, Hilfe zu brauchen, zögern Sie nicht, darum zu bitten.</p><p>Manchmal steht diese Karte auch buchstäblich für Handarbeit, dem Herstellen von Dingen. Wenn dies der Fall ist sagt Ihnen diese Karte, dass der Beginn einer sehr kreativen Phase bevorsteht. Genießen Sie sie!</p><p>Schließlich kann diese Karte auch auf Anfängerglück hindeuten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Die Acht der Pentagramme sagt Ihnen, dass Arbeit zu viel Platz in Ihrer Beziehung beschlagnahmt, sei es Ihre Arbeit oder die Ihres Partners. Es ist unbedingt notwendig, dass Sie mehr Zeit und Raum für einander machen, wenn Sie wünschen, dass diese Beziehung andauert.</p><p>Wenn Sie derzeit alleine sind fragen Sie Sich vielleicht, ob Sie wirklich einen Partner finden wollen. Vielleicht ist Ihr Job Ihnen jetzt wichtiger? Wenn letzteres der Fall ist, kein Problem, aber vielleicht sollten Sie vorübergehend aufhören mit der Suche nach einer Liebe in Ihrem Leben.</p>",
                        [GuidanceFields.WORK] : "<p>Dies ist eine Zeit, wo Sie erwarten können, dass eine Menge neuer Aufgaben oder Aufträge auf Sie zu kommen. Vielleicht wird Ihre Arbeitsbelastung zu überwältigend und ist es fast unmöglich für Sie alles so richtig zu tun, wie es getan werden sollte. Wenn dies der Fall ist, zögern Sie nicht, Ihre Vorgesetzten an zu sprechen und sie zu bitten Ihnen zu helfen. Stellen Sie Prioritäten in Ihren Aufgaben und vielleicht sollten Sie sogar einige Aufgaben Ihren Kollegen übertragen.</p><p>Wenn Sie auf der Suche nach einem Job sind, werden Sie mit hohen Anforderungen und einer gründlichen Prüfung Ihrer Fähigkeiten konfrontiert. Keine Panik oder Zögerung, Sie sind stärker als Sie denken und Sie werden in der Lage sein, diese Anforderungen zu erfüllen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Egal ob Sie einen traditionellen Job oder einen alternativen haben, jetzt ist es die Zeit, wo Sie Sich fragen können, warum Sie an dem Ort sind, wo Sie Heute sind. Tun Sie tatsächlich die Dinge, wovon Sie wissen, dass Sie die am Beste tun können? Wachsen und entwickeln Sie Sich? Oder haben Sie Sich von Meinungen anderer bei der Auswahl Ihrer Arbeit oder Karriere beeinflussen lassen? Sind Sie bereit einen anderen Job zu suchen, wo Sie Ihre Fähigkeiten anwenden können und wo man diese sogar begrüßen wird? Sind Sie bereit, wirklich (spirituell) Sich Selbst zu werden bei der Arbeit?</p>",
                    }
                }
            },
            {
                filename: "72",
                numerology : 9,
                en : {
                    name: "Nine of Pentacles",
                    suit : "Pentacles",
                    element : "🜃 - earth",
                    theme : "knowing your value",    
                    description : "Nine of Pentacles talks about being conscious of your self-worth and to show it to others.",
                    questions : [ "Have you gained knowledge and experience?", "Do you know your value?", "Do you trust your own capacities?", "What do you do with your knowledge and experience?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This is a positive, energetically uplifting card. It regularly points to financial wellbeing or improvement but generally it means that good times are awaiting you. The positive things you will experience can be in any part of your live: work, love, spirituality, … Anyway you will notice that things are going better than you had expected. Don’t forget to share this wealth and happiness with the people around you!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are currently in a relationship you will notice that the contacts with your partner are getting better than they ever were before. Together you enjoy loving and romantic times and you understand each other better than you ever have.</p><p>If you are single, the time is right for you to meet someone with great potential as a life partner and it won’t even take too much effort. Just be open and interested and you will hear opportunity knocking at your door!</p>",
                        [GuidanceFields.WORK] : "<p>If you currently have a job, now is the time to expect a raise, a promotion or some other change for the better. Don’t ask yourself too many questions, you have deserved this.</p><p>If you are looking for a job, you will notice that many opportunities are coming your way, and you will almost certainly find a new position.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>For the moment you are feeling very happy and fulfilled, your self-respect is strong and you have great confidence. It feels as if everything is going your way. You realize that you have gathered a lot of spiritual knowledge and insight. So much even that you may consider being an inspiration and help to others who are still searching for the answers you already found. Share your wealth, help others enjoy spiritual strength the way you do.</p>",
                    }
                },
                fr : {
                    name : "Le Neuf de Pentacle",
                    suit : "Pentacles",
                    element :  "🜃 - terre",
                    theme : "savoir ce que l'on vaut",    
                    description : " Le Neuf de Pentacle, nous parle d’être conscient de ce que l’on vaut et que l'on peut également le montrer aux autres.",
                    questions : ["Avez-vous acquis de la connaissance et de l'expérience?", "Savez-vous ce que vous valez?", "Faites-vous confiance à vos propres capacités?", "Que faites-vous de votre connaissance et expérience?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>C'est une carte positive et stimulante sur le plan énergétique. Elle indique souvent le bien-être ou l'amélioration de la situation financière. En règle générale, cela signifie que de bons moments  viennent vers vous. Les choses positives que vous allez vivre peuvent être vécues dans tous les aspects de votre vie : au travail, dans l'amour, dans la spiritualité, .... Cependant, vous constaterez que les choses vont mieux que prévu. Essayez de profiter non seulement des avantages matériels, mais aussi des avantages émotionnels et spirituels. N'oubliez pas de partager la richesse et le bonheur avec les gens autour de vous !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are currently in a relationship you will notice that the contacts with your partner are getting better than they ever were before. Together you enjoy loving and romantic times and you understand each other better than you ever have.</p><p>If you are single, the time is right for you to meet someone with great potential as a life partner and it won’t even take too much effort. Just be open and interested and you will hear opportunity knocking at your door!</p>",
                        [GuidanceFields.WORK] : "<p>If you currently have a job, now is the time to expect a raise, a promotion or some other change for the better. Don’t ask yourself too many questions, you have deserved this.</p><p>If you are looking for a job, you will notice that many opportunities are coming your way, and you will almost certainly find a new position.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>For the moment you are feeling very happy and fulfilled, your self-respect is strong and you have great confidence. It feels as if everything is going your way. You realize that you have gathered a lot of spiritual knowledge and insight. So much even that you may consider being an inspiration and help to others who are still searching for the answers you already found. Share your wealth, help others enjoy spiritual strength the way you do.</p>",
                    }
                },
                sp:{
                    name:"El Nueve de Pentáculos",
                    suit: "Pentáculos",
                    element: "🜃 - tierra",
                    theme: "saber lo que vales",    
                    description: "El Nueve de Pentáculos se trata sobre ser consciente de lo que vales y señalarnos que también podemos mostrar esto a otros",
                    questions: ["¿Has adquirido conocimientos y experiencia?", "¿Sabes lo que vales?", "¿Confías en tus propias habilidades?", "¿Qué haces con tus conocimientos y experiencia?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta es una carta positiva, con energía edificante. Es regularmente apunta al bienestar financiero o mejora, pero por lo general significa que los buenos tiempos le esperan. Las cosas positivas que experimentará pueden estar en cualquier parte de su vida: trabajo, amor, espiritualidad, ... De todos modos te darás cuenta de que las cosas van mejor de lo que había esperado. No se olvide de compartir esta riqueza y la felicidad con las personas que le rodean!</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Pentakels Negen",
                    suit : "Pentakels",
                    element : "🜃 - aarde",
                    theme : "weten wat je waard bent",    
                    description : "De negen van pentakels gaat over bewust zijn van wat je waard bent en wijst ons erop dat we dit ook mogen tonen aan anderen.",
                    questions : [ "Heb jij kennis en ervaring opgedaan?", "Weet jij wat je waard bent?", "Vertrouw jij op je eigen kunnen?", "Wat doe jij met je kennis en ervaring?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Pentakels Negen is een positieve, sterk energetische kaart. Ze wijst er vaak op dat je op financieel en materieel vlak goed zit of dat er zelfs een verbetering komt op dat vlak. Over het algemeen kondigt deze kaart goede tijden aan. De positieve dingen die je zal ervaren kunnen zich situeren op elk gebied: werk, liefde, spiritualiteit, ... Je zal in elk geval merken dat alles beter verloopt dan je verwacht had. Probeer niet enkel te focussen op je materiële welzijn maar ook op het emotionele en spirituele, ook al is dat een stuk minder tastbaar of opvallend. Denk er ook aan om je rijkdommen te delen met de mensen om je heen, gedeelde vreugd is dubbele vreugd!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een liefdesrelatie hebt dan zal je merken dat het contact met je partner beter en intenser is dan ooit tevoren. Jullie genieten samen van romantische en liefdevolle tijden en jullie begrijpen mekaar ook beter dan ooit, vaak zonder dat er woorden voor nodig zijn, Jullie hebben op dit moment genoeg aan een blik of een gebaar.</p><p>Als je alleen bent dan is nu het ideale moment om iemand te ontmoeten met veel potentieel, iemand die een grote kans maakt om je levenspartner te worden. Het zal je zelfs weinig moeite kosten om deze persoon te vinden. Zorg er gewoon voor dat je openstaat en geïnteresseerd bent naar mensen toe en de rest zal vanzelf naar je toe komen.</p>",
                        [GuidanceFields.WORK] : "<p>Als je een baan hebt dan is het nu een goede tijd om opslag of een promotie te krijgen, het ziet ernaar uit dat er in elk geval veranderingen ten goede komen op werkvlak. Stel je hier zeker geen vragen bij, het is duidelijk dat je deze positieve evolutie ten volle verdiend hebt!</p><p>Als je op zoek bent naar werk dan zal je merken dat er veel kansen en mogelijkheden op je weg komen. Besteed je energie eraan om deze kansen te benutten, niet om je af te vragen waar ze vandaan komen. Maak er ten volle gebruik van en je zal binnen de kortste keren een passende job vinden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment voel je je blij en tevreden, je zelfrespect is sterk en je hebt veel vertrouwen in jezelf. Het voelt alsof alles net zo loopt als jij het graag wil. Je geniet ook ten volle van het besef dat je in het verleden veel spirituele kennis vergaard hebt en daardoor duidelijke inzichten gekregen hebt. Je bent op spiritueel vlak dusdanig gegroeid dat je je zelfs kan opstellen als inspiratie voor anderen. Je kan mensen die nog zoekende zijn helpen met de antwoorden die jij al gevonden hebt. Deel je spirituele rijkdom vrijelijk, help anderen om de spirituele kracht te vinden in zichzelf die jij al verworven hebt.</p>",
                    }
                },
                de : {
                    name: "Neun der Pentagramme",
                    suit : "Pentagramme",
                    element : "🜃 - Erde",
                    theme : "Wissen, was man wert ist.",    
                    description : "Die Neun der Pentagramme spricht darüber, sich bewusst zu sein, was man wert ist und dass man dies anderen auch zeigen kann.",
                    questions : [ "Haben Sie Wissen und Erfahrung erworben?", "Wissen Sie, was sie wert sind?", "Vertrauen Sie Ihren eigenen Fähigkeiten?", "Was machen Sie mit Ihrem Wissen und Ihrer Erfahrung?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Das ist eine positive, energetisch stimulierende Karte. Sie deutet oft auf finanzielles Wohlergehen oder auf die Verbesserung hin. In der Regel bedeutet sie, dass gute Zeiten auf Sie zukommen. Die positiven Dinge, die Sie erleben werden, können Sie in jedem Teil Ihres Lebens erfahren: in der Arbeit, der Liebe, der Spiritualität, ... Wie auch immer, Sie werden feststellen, dass die Dinge besser laufen als erwartet. Versuchen Sie, nicht nur die materiellen Vorteile zu genießen, sondern auch die emotionalen und spirituellen. Vergessen Sie nicht, Reichtum und Glück mit den Menschen in Ihrer Umgebung zu teilen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer Beziehung sind, werden Sie feststellen, dass die Kontakte mit Ihrem Partner immer besser werden, als je zuvor. Gemeinsam genießen Sie liebevolle und romantische Zeiten und Sie verstehen einander besser als Sie jemals getan haben. Wenn Sie alleine sind, ist die Zeit da, dass Sie die richtigen Lebenspartner mit großem Potenzial treffen könnten und nicht einmal mit zu viel Mühe. Seien Sie nur offen und interessiert und die Chance klopft an Ihre Tür!</p>",
                        [GuidanceFields.WORK] : "<p>Wenn Sie einen Job haben, ist jetzt die Zeit, wo Sie eine Gehaltserhöhung, eine Beförderung oder eine andere Veränderung zum Besseren erwarten können. Stellen Sie Sich nicht zu viele Fragen, Sie haben es verdient.</p><p>Wenn Sie auf der Suche nach einem Job sind, werden Sie feststellen, dass viele Chancen auf Ihren Weg kommen und Sie werden mit ziemlicher Sicherheit eine neue Position finden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Zur Zeit fühlen Sie Sich sehr glücklich und erfüllt, Ihre Selbstachtung ist hoch und Sie haben großes Vertrauen. Es fühlt sich an, als ob alles, was vor sich geht, genau so läuft wie Sie möchten. Sie erkennen, dass Sie eine Menge von geistiger Kenntnis und Einsicht gesammelt haben. So sehr sogar, dass Sie spüren können, dass Sie eine Inspiration und Hilfe sein könnten für andere, die immer noch auf der Suche sind nach den Antworten, die Sie bereits gefunden haben. Teilen Sie Ihren Reichtum und helfen Sie andere die spirituelle Kraft zu genießen so wie Sie es tun.</p>",
                    }
                }
            },
            {
                filename: "73",
                numerology : 10,
                en : {
                    name: "Ten of Pentacles",
                    suit : "Pentacles",
                    element : "🜃 - earth",
                    theme : "doing a lot",    
                    description : "Ten of  Pentacles talks about doing a lot and that we could partially delegate.",
                    questions : [ "Are you doing a lot?", "Are you swamped in your occupations?", "What could you possibly delegate?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Ten of Pentacles is a very positive card that speaks of riches, security, stability, generally being carefree, not only material but also on other levels. The card tells us to not only focus on your material wealth but also pay attention to emotional and spiritual wellbeing. Make sure you are conscious of the road you had to take to get to this point and share the riches you have. One day you might be in need and then you will be happy if someone reaches out to you: we get what we give, life is a mirror.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>You are experiencing the joy of love and appreciation in all your relationships. Where your partner is concerned it is well possible that you consider taking the relationship to the next level, you may think about moving in together, having children, getting married, … now you truly realize how it feels to love unconditionally.</p><p>If you are single you may be on the verge of finding a partner. And this may go so quickly it stuns you. Don’t forget to enjoy it to the fullest!</p>",
                        [GuidanceFields.WORK] : "<p>You are currently really doing a good job and everyone around you notices and appreciates this. You may even get a raise or a promotion and you feel very fulfilled about the tasks at hand.</p><p>If you are looking for work now is the time to persevere, to continue looking with doubled efforts because a positive time is coming your way. You will find a job and it might be a lot better than you imagined!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>At this moment you will feel spiritually happy, at peace and well balanced. This is a time to enjoy these feelings and spread them around. Share your insights with everyone around you, talk about them, write them down. Do anything you can to preserve what and how you are feeling right now. Life is a series of ups and downs so you know a time will come that you will be feeling less happy, less relaxed. It is important that in those times you can recreate what you are feeling and experiencing right now in order to remember what your goal is, what you are aiming for.</p>",
                    }
                },
                fr : {
                    name : "Le Dix de Pentacle",
                    suit : "Pentacles",
                    element :  "🜃 - terre",
                    theme : "faire beaucoup",    
                    description : "Le Dix de Pentacle, nous parle de faire beaucoup et de possiblement pouvoir déléguer certaines choses à d’autres.",
                    questions : ["Faites-vous beaucoup?", "Vous enterrez-vous dans de nombreuses activités?", "Que pourriez-vous déléguer?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Dix de Pentagramme\" est une carte très positive, elle représente la richesse, la sécurité, la stabilité, généralement l'insouciance, non seulement en termes matériels, mais aussi sur d'autres plans. La carte dit qu'il faut se concentrer non seulement sur le bien-être matériel, mais aussi sur le bien-être émotionnel et spirituel. Soyez conscient du chemin que vous avez pris pour arriver à ce point et partager votre richesse. Un jour, vous serez peut-être vous-même dans le besoin et vous serez heureux quand quelqu'un vous tend la main : nous recevons ce que nous donnons. La vie est comme un miroir.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>You are experiencing the joy of love and appreciation in all your relationships. Where your partner is concerned it is well possible that you consider taking the relationship to the next level, you may think about moving in together, having children, getting married, … now you truly realize how it feels to love unconditionally.</p><p>If you are single you may be on the verge of finding a partner. And this may go so quickly it stuns you. Don’t forget to enjoy it to the fullest!</p>",
                        [GuidanceFields.WORK] : "<p>You are currently really doing a good job and everyone around you notices and appreciates this. You may even get a raise or a promotion and you feel very fulfilled about the tasks at hand.</p><p>If you are looking for work now is the time to persevere, to continue looking with doubled efforts because a positive time is coming your way. You will find a job and it might be a lot better than you imagined!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>At this moment you will feel spiritually happy, at peace and well balanced. This is a time to enjoy these feelings and spread them around. Share your insights with everyone around you, talk about them, write them down. Do anything you can to preserve what and how you are feeling right now. Life is a series of ups and downs so you know a time will come that you will be feeling less happy, less relaxed. It is important that in those times you can recreate what you are feeling and experiencing right now in order to remember what your goal is, what you are aiming for.</p>",
                    }
                },
                sp:{
                    name:"El Diez de Pentáculos",
                    suit: "Pentáculos",
                    element: "🜃 - tierra",
                    theme: "hacer muchísimo",    
                    description: "El Diez de Pentáculos se trata de hacer mucho y nos señala que podríamos ser capaces de transferir una parte de eso a otros",
                    questions: ["¿Haces mucho?", "¿Te entierras en tus muchas actividades?", "¿Qué podrías delegar?" ],
                    
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Diez de Oros es una tarjeta muy positivo que habla de las riquezas, la seguridad, la estabilidad, en general, ser, no sólo el material sin preocupaciones, sino también en otros niveles. La carta nos dice que centrarse no sólo en su riqueza material, sino también prestar atención al bienestar emocional y espiritual. Asegúrese de que está consciente del camino que había que tomar para llegar a este punto y compartir las riquezas que tiene. Un día usted podría estar en necesidad y entonces usted será feliz si alguien se acerca a usted: conseguimos lo que damos, la vida es un espejo.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Pentakels Tien",
                    suit : "Pentakels",
                    element : "🜃 - aarde",
                    theme : "heel veel doen",    
                    description : "De tien van pentakels gaat over heel veel doen en wijst ons erop dat we wellicht een gedeelte over kunnen dragen aan een ander.",
                    questions : [ "Doe jij veel?", "Raak jij bedolven onder je vele bezigheden?", "Wat zou jij kunnen delegeren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Pentakels Tien is een heel positieve kaart die wijst op rijkdom, veiligheid en stabiliteit. Ze wijst erop dat je over het algemeen vrij van zorgen bent, niet enkel op materieel vlak maar ook in andere aspecten van je leven. Als je deze kaart trekt is het belangrijk erbij stil te staan dat je best niet enkel focust op materieel welzijn maar ook op je emotionele en spirituele levenskwaliteit.</p><p>Sta even bewust stil bij de weg die je tot dit punt gebracht heeft en deel je rijkdom met anderen. Het is immers best mogelijk dat je op een dag zelf iets te kort zal komen en dat je op dat moment blij zal zijn als een ander dan bereid is om zijn rijkdommen met jou te delen. Het leven is een spiegel, een echo: we krijgen wat we geven.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In bijna al je relaties ervaar je op dit moment in je leven een liefdevolle vreugde en waardering. In verband met je liefdespartner is het best mogelijk dat je aan het overwegen bent om een volgende stap te zetten. Misschien denk je aan samenwonen, kinderen krijgen, trouwen, ... Je voelt nu immers ten volle wat het betekent om onvoorwaardelijk van iemand te houden.</p><p>Als je op dit moment single bent dan sta je waarschijnlijk op het punt om een partner te vinden. Er kan zo snel een goede kandidaat op je pad komen dat het je verrast. Ook al evolueren de dingen heel snel, probeer er toch ten volle van te genieten. Het prille begin van een liefdesrelatie is de basis van jullie verdere leven samen, beleef het bewust, ga onvoorwaardelijk voor dit avontuur samen met je nieuwe partner.</p>",
                        [GuidanceFields.WORK] : "<p>Je presteert op dit moment erg goed in je werk en het is duidelijk dat de mensen om je heen zich daarvan bewust zijn en het ook echt waarderen. Net als Pentakels Negen wijst ook Pentakels Tien erop dat er mogelijk een promotie of opslag zit aan te komen. Je voelt je ook tevreden en waardevol en je geniet echt van het werk dat je doet.</p><p>Als je op zoek bent naar werk dan is het op dit moment belangrijk dat je niet enkel volhoudt maar dat je zelfs je inspanningen verdubbelt want je bent op weg naar positievere tijden waarin je een baan zal vinden die nog fijner is dan je verwacht had!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Wat je spiritualiteit betreft ben je op dit ogenblik gelukkig, tevreden en goed in evenwicht. Het is nu zaak om intens van deze gevoelens te genieten en ze ook te delen. Vertel je inzichten aan iedereen om je heen, praat over je spiritualiteit, schrijf erover. Gebruik elke mogelijke manier om dat gevoel dat je nu ervaart vast te leggen zodat je het kan bewaren. Het leven is nu eenmaal een reeks van pieken en dalen en je weet dat er ook weer een tijd zal komen waarin je je minder goed en ontspannen voelt. Het is belangrijk dat je dan kan teruggrijpen naar je huidige gevoelens, dat je dan kan herbeleven wat je nu meemaakt. Het zal je helpen om je bewust te worden van je doel, de richting die je op wil.</p>",
                    }
                },
                de : {
                    name: "Zehn der Pentagramme",
                    suit : "Pentagramme",
                    element : "🜃 - Erde",
                    theme : "Viel tun",    
                    description : "Die Zehn der Pentagramms spricht darüber, viel zu tun und möglicherweise einige Dinge an andere delegieren zu können.",
                    questions : [ "Tun Sie viel?", "Begraben Sie sich in vielen Aktivitäten?", "Was könnten Sie delegieren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Zehn der Pentagramme“  ist ein sehr positive Karte, sie steht für Reichtum, Sicherheit, Stabilität, in der Regel für Sorglosigkeit, nicht nur in materieller Hinsicht, sondern auch auf anderen Ebenen. Die Karte sagt, dass Sie sich nicht nur auf materiellen Wohlstand konzentrieren, sondern ihre Aufmerksamkeit auch auf emotionales und geistiges Wohlbefinden richten sollten. Machen Sie sich den Weg bewusst, den Sie gegangen sind, um an diesem Punkt anzukommen und teilen Sie Ihren Reichtum. Eines Tages werden Sie selbst vielleicht in Not sein, und dann werden Sie froh sein, wenn Ihnen jemand die Hand reicht: Wir empfangen, was wir geben. Das Leben ist wie ein Spiegel.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Sie erleben die Freude der Liebe und Schätzung in all Ihren Beziehungen. Wo es Ihr Partner betrifft ist es gut möglich, dass Sie die Beziehung auf die nächste Ebene bringen werden. Sie können zusammenziehen, mit Kindern Anfängen, heiraten. Jetzt erfahren Sie wirklich was es heißt bedingungslos zu lieben.</p><p>Wenn Sie alleine sind, können Sie gerade dabei sein einen Partner zu finden. Und das kann so schnell gehen, es Sie überrascht. Vergessen Sie nicht, in vollen Zügen zu genießen!</p>",
                        [GuidanceFields.WORK] : "<p>Sie leisten zur Zeit eine wirklich gute Arbeit und alle um Sie herum bemerken und schätzen das. Sie können sogar eine Gehaltserhöhung oder eine Beförderung bekommen und Sie fühlen Sich sehr erfüllt mit Ihren Aufgaben.</p><p>Wenn Sie auf der Suche nach Arbeit sind, ist jetzt die Zeit zum Durchhalten, auch weiterhin auf der Suche mit verdoppelten Anstrengungen, weil eine positive Zeit auf Ihrem Wege kommen wird. Sie werden eine Arbeit finden, und diese könnte viel besser sein als Sie gedacht haben!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In diesem Moment fühlen Sie sich spirituell glücklich, friedlich und gut ausgewogen. Dies ist eine Zeit, um diese Gefühle zu genießen und sie um Sie herum auszubreiten. Teile Ihre Erkenntnisse mit jedem um Sie herum, sprechen Sie sich aus, schreiben Sie sie auf. Tun Sie alles, was Sie können, um zu bewahren, was und wie Sie Sich gerade fühlen. Das Leben ist eine Reihe von Höhen und Tiefen, sodass Sie wissen, es wird eine Zeit kommen, wo wir uns weniger Glücklich fühlen und weniger entspannt. Es ist wichtig, dass wir in diesen Zeiten Wiederschaffen was wir jetzt fühlen und erleben damit wir uns erinnern, was unser Ziel ist, das wir anstreben.</p>",
                    }
                }
            },
            {
                filename: "74",
                numerology : 11,
                en : {
                    name: "Page of Pentacles",
                    suit : "Pentacles",
                    element : "🜃 - earth",
                    court : "Page",
                    theme : "looking impartially at your possessions",    
                    description : "Page of Pentacles talks about looking impartially at your possessions and see our possessions, talents and experiences with a child-like light-heartedness.",
                    questions : [ "What are you good at?", "What do  you possess?", "What could you offer?", "Where is there a need for growth?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>When looking at this card you will notice that the Page is offering you a Pentacle, a coin. Consider this as a real gift, this card gives you a lucky coin for you to use wherever necessary. This card is about getting chances, opportunities, possibilities. Maybe you are at a stage in life when there are things you have to do that don’t really fascinate you, on the contrary, you may feel a bit bored, tired, as if you are in a rut. Don’t give in to those feelings but realize that somewhere, deep inside, you still have a certain amount of energy hidden and now is the time to use it. Remember what your goals are and focus on them. Once you realize what you were working towards, the energy will come to you again and you will be able to reach those goals.</p><p>Don’t try to do this without investing energy, it will not work.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a committed relationship you may have the feeling that things are getting less adventurous, less passionate. You don’t feel what you used to feel in the early days of your relationship. The magic seems to be gone. Hiding for this won’t help, it will probably even make things worse. So sit your partner down, tell her/him how you feel and try to work things out together. Maybe he/she is feeling the same way but was afraid to bring it up.</p><p>If you are single it may seem as if your efforts to find a partner are leading you nowhere. Look deep inside your heart and ask yourself if there is anything that might be holding you back: could you still have feelings for a previous partner? Are you absolutely sure you want a relationship right now? Answering these questions may give you the clue you need to make your quest a successful one.</p>",
                        [GuidanceFields.WORK] : "<p>The Page of Pentacles tells you that something is not quite right in the workplace. You seem to have a lot of work that needs to be done but somehow you lack enthusiasm, energy. You find it hard to totally commit to the job, it doesn’t interest you quite the way it used to. Even though maybe you could do this alone, it is wise to ask for help. There are many people around you who really want to help you and their support will give you the boost you need to finish the race.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Working towards spiritual growth doesn’t seem to be as big an adventure and a challenge as it used to be. Somehow you have lost most of your motivation, your drive. To regain that zeal you used to have you might ask yourself some questions: what really interests you? Which subject makes you want to find out more? Which questions would you really like answered? Don’t hesitate to go look into the farthest corners, the most unlikely places. You will notice that these new paths you choose will really excite you, replenish your energy.</p>",
                    }
                },
                fr : {
                    name : "Le Valet des Pentacles",
                    suit : "Pentacles",
                    element :  "🜃 - terre",
                    court : "Valet",
                    theme : "Regarder ce que l'on possède d’un esprit ouvert",    
                    description : "Le Valet des Pentacles, nous parle du regard impartial concernant ce que nous avons entre les mains et de regarder nos possessions, nos talents et nos expériences d’un esprit ouvert et enfantin.",
                    questions : ["Que savez-vous faire?", "Que possédez-vous?", "Que pourriez-vous offrir?", "Qu'est-il encore qu’aux balbutiements?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Quand vous regardez cette carte, vous remarquerez qu'elle vous offre une pièce de monnaie. Considérez ça comme un vrai cadeau. Cette carte vous donne une pièce porte-bonheur que vous pouvez utiliser quand et où vous en avez besoin. Cette carte représente les chances, les perspectives, les possibilités que vous obtenez. Vous avez peut-être atteint un point dans votre vie où les choses que vous faites ne vous excitent pas vraiment. Au contraire, vous pouvez vous sentir un peu ennuyé ou fatigué, comme si vous étiez dans un creux. Ne cédez pas à ces sentiments, mais essayez de réaliser que quelque part au fond de vous, il y a encore une certaine quantité d'énergie cachée. C'est le moment de l'utiliser !</p><p>Pensez à ce que sont vos objectifs et concentrez-vous. Une fois que vous aurez réalisé dans quelle direction vous souhaitez avancer, l'énergie vous reviendra et vous serez en mesure d'atteindre ces objectifs.</p><p>N'essayez pas d'y parvenir sans être en contact avec votre potentiel d'énergie, car cela ne fonctionne pas.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a committed relationship you may have the feeling that things are getting less adventurous, less passionate. You don’t feel what you used to feel in the early days of your relationship. The magic seems to be gone. Hiding for this won’t help, it will probably even make things worse. So sit your partner down, tell her/him how you feel and try to work things out together. Maybe he/she is feeling the same way but was afraid to bring it up.</p><p>If you are single it may seem as if your efforts to find a partner are leading you nowhere. Look deep inside your heart and ask yourself if there is anything that might be holding you back: could you still have feelings for a previous partner? Are you absolutely sure you want a relationship right now? Answering these questions may give you the clue you need to make your quest a successful one.</p>",
                        [GuidanceFields.WORK] : "<p>The Page of Pentacles tells you that something is not quite right in the workplace. You seem to have a lot of work that needs to be done but somehow you lack enthusiasm, energy. You find it hard to totally commit to the job, it doesn’t interest you quite the way it used to. Even though maybe you could do this alone, it is wise to ask for help. There are many people around you who really want to help you and their support will give you the boost you need to finish the race.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Working towards spiritual growth doesn’t seem to be as big an adventure and a challenge as it used to be. Somehow you have lost most of your motivation, your drive. To regain that zeal you used to have you might ask yourself some questions: what really interests you? Which subject makes you want to find out more? Which questions would you really like answered? Don’t hesitate to go look into the farthest corners, the most unlikely places. You will notice that these new paths you choose will really excite you, replenish your energy.</p>",
                    }
                },
                sp:{
                    name:"El Soto de Pentáculos",
                    suit: "Pentáculos",
                    element: "🜃 - tierra",
                    court: "Soto",
                    theme: "mirar desprevenido á lo  que tienes con la mente abierta",    
                    description: "El Soto de Pentáculos trata sobre la imparcialidad de mirar á lo que tienes en tus 'manos' y nos recuerda que podemos mirar á nuestras posesiones, nuestros talentos y nuestras experiencias con una mentalidad abierta y infantil",
                    questions: ["¿En qué eres bueno?", "¿Qué tienes en las manos?", "¿Qué podrías ofrecer?", "¿Qué es lo que todavía está en su infancia?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Al mirar esta tarjeta se dará cuenta  que la página  está ofreciendo un pináculo, una moneda. Considere esto como un verdadero regalo, esta tarjeta le da una moneda de la suerte para que pueda utilizar cuando sea necesario. Esta tarjeta es sobre conseguir oportunidades, oportunidades, posibilidades. Tal vez usted está en una etapa de la vida cuando hay cosas que tiene que hacer que no lo hacen realmente le fascina, por el contrario, se puede sentir un poco aburrido, cansado, como si estuviera en una rutina. No ceder a esos sentimientos, pero darse cuenta de que en algún lugar, en el fondo, usted todavía tiene una cierta cantidad de energía oculta y ahora es el momento de usarlo. Recuerde cuáles son sus objetivos y centrarse en ellos. Una vez que le da cuenta de lo que estaba trabajando hacia la energía vendrá a usted otra vez y usted será capaz de alcanzar esas metas. No trate de hacer esto sin tener que invertir energía, no va a funcionar.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Page van Pentakels",
                    suit : "Pentakels",
                    element : "🜃 - aarde",
                    court : "Page",
                    theme : "onbevooroordeeld kijken naar wat je hebt",    
                    description : "De page van pentakels gaat over onbevooroordeeld kijken naar wat je in je 'handen' hebt en wijst ons erop dat we met kinderlijke onbevangenheid mogen kijken naar ons bezit, naar onze talenten en ervaringen.",
                    questions : [ "Waar ben jij goed in?", "Wat heb jij in je handen?", "Wat zou jij aan kunnen bieden?", "Wat staat nog in de kinderschoenen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Op de afbeelding van deze kaart kan je zien dat de Page je een pentakel, een munt aanbiedt. Je kan dit beschouwen als een echt geschenk want de Page van Pentakels biedt je een geluksmunt die je kan inzetten waar en wanneer het nodig is. Deze kaart wijst dan ook op mogelijkheden, kansen en voorstellen.</p><p>Misschien zit je op dit moment in een fase waarin je de dingen die je doet niet meer echt boeiend vindt. Integendeel, je verveelt je misschien zelfs, je zit een stuk in een sleur. Probeer om niet toe te geven aan deze gevoelens maar je op te trekken aan het feit dat er ergens, diep binnenin, nog een bron van energie verborgen zit. Nu is het de juiste tijd om die bron aan te boren en te gebruiken!</p><p>Keer terug naar je oorspronkelijke doelen, naar de basis waar je vandaan komt en concentreer je daarop. Eens je je weer herinnert waar je naar op weg was, zal je energie terug beginnen stromen en zal je die doelen ook weer kunnen bereiken. Het is nu eenmaal zo dat je niets kan bereiken als je niet investeert, en jouw belangrijkste kapitaal is nu energie.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je een vaste partner hebt is het mogelijk dat je tegenwoordig het gevoel hebt dat er alsmaar minder avontuur en passie in je relatie te vinden is. De intense gevoelens die jullie hadden in het begin van de relatie lijken op dit moment ver weg, bijna onherkenbaar. De magie is er niet meer, zo voelt het. Ga je nu niet verbergen of je gevoelens negeren want dan maak je het alleen maar erger. Vraag je partner om samen te gaan zitten en vertel hem/haar wat je voelt. Zo maak je het begin van een oplossing mogelijk, door open communicatie. Het is trouwens best mogelijk dat je partner last heeft van dezelfde gevoelens als jij maar er niet over durfde te beginnen.</p><p>Als je op zoek bent naar een partner dan zit je waarschijnlijk een beetje vast, heb je het gevoel alsof al je inspanningen om de liefde te vinden geen resultaat boeken, je bent en komt nergens, zo voelt het. Neem nu de tijd om diep in je hart te kijken en je af te vragen of er misschien nog iets zit wat je tegenhoudt: is het mogelijk dat je nog gevoelens hebt voor je vorige partner? Ben je er helemaal zeker van dat je nu echt een relatie wil? Stel jezelf een aantal (moeilijke) vragen want de antwoorden hierop zullen je helpen om de juiste weg te vinden naar een nieuwe partner.</p>",
                        [GuidanceFields.WORK] : "<p>De Page van Pentakels vertelt je dat er iets niet helemaal goed voelt op je werk. Je zit op dit ogenblik met een pak taken die uitgevoerd moeten worden, maar op een of andere manier vind je het enthousiasme en de energie niet om er volop voor te gaan. Je vindt het moeilijk om je echt te engageren, je werk interesseert je een stuk minder dan vroeger het geval was. Ook al zou je dit probleem waarschijnlijk best wel alleen kunnen oplossen, nu is het even beter om de hulp van je omgeving in te roepen. Er zijn een pak mensen om je heen die je willen helpen en ondersteunen en die je net dat zetje kunnen geven dat je nodig hebt om hier doorheen te geraken. Laat ‘samen sterk’ je motto zijn!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Als je denkt aan werken aan je spirituele groei dan lijkt dit niet meer zo’n fijn en uitdagend avontuur te zijn als het vroeger was. Op één of andere manier ben je ergens onderweg je motivatie, je motor kwijtgeraakt. Om dat vroegere enthousiasme terug te vinden zou je jezelf een aantal vragen kunnen stellen: wat interesseert je echt? Bij welk onderwerp of thema denk je onmiddellijk dat je er meer van wil weten? Op welke vragen zou je absoluut het antwoord willen vinden? Als je een aantal antwoorden gevonden hebt dan kan je gericht op zoek gaan naar kennis. Maak het jezelf niet te gemakkelijk, ga overal zoeken, tot in de kleinste hoekjes en gaatjes, op ongewone of misschien zelfs een beetje beangstigende plaatsen. Je zal merken dat je tijdens deze zoektocht je oude energie en vuur weer terugvindt, dat je je spirituele tank terug kan vullen.</p>",
                    }
                },
                de : {
                    name: "Bube der Pentagramme",
                    suit : "Pentagramme",
                    element : "🜃 - Erde",
                    court : "Bube",
                    theme : "Mit einem offenen Geist schauen, was man hat.",    
                    description : "Der Bube der Pentagramme spricht von einem unvoreingenommenen Blick auf das, was wir in der Hand haben, und von unserem Besitz, unseren Talenten und Erfahrungen mit einem offenen und kindlichen Geist.",
                    questions : [ "Was können Sie tun?", "Was besitzen Sie?", "Was können Sie anbieten?", "Was steckt noch in den Anfängen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Bei der Betrachtung dieser Karte werden Sie feststellen, dass sie Ihnen ein Pentagramm anbietet, eine Münze. Betrachten Sie dies als echtes Geschenk. Diese Karte gibt Ihnen eine Glücksmünze, die Sie benützen können, wann oder wo immer Sie sie brauchen. Diese Karte steht für die Chancen, Aussichten, Möglichkeiten, die Sie bekommen. Möglicherweise sind Sie an einem Punkt im Leben angekommen, an dem die Dinge, die Sie tun, Sie nicht wirklich begeistern können. Im Gegenteil, Sie können sich ein wenig gelangweilt oder müde fühlen, als ob Sie in einer Talsohle stecken. Geben Sie sich diesen Gefühlen nicht hin, sondern versuchen Sie zu erkennen, dass  irgendwo, tief in Ihrem Inneren, noch eine bestimmte Menge an Energie versteckt ist. Jetzt ist die Zeit gekommen, sie zu aktivieren!</p><p>Denken Sie nach, was Ihre Ziele sind und konzentrieren Sie sich darauf. Sobald Sie erkennen, in welche Richtung Sie gehen wollen, wird die Energie wieder zu Ihnen kommen und Sie werden in der Lage sein, diese Ziele zu erreichen.</p><p>Versuchen Sie nicht, dies ohne den Einsatz von Energie zu erreichen, denn das funktioniert nicht.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer festen Beziehung sind, können Sie das Gefühl haben, dass die Dinge immer weniger abenteuerlich, weniger leidenschaftlich werden. Sie fühlen nicht mehr das Gleiche als in den früheren Tagen Ihrer Beziehung. Der Zauber scheint verschwunden zu sein. Sich verstecken davor wird nicht helfen, es wird es wahrscheinlich nur noch schlimmer machen. Also setzen Sie Sich mit Ihrem Partner zusammen, sagen Sie Ihr / ihm, wie Sie fühlen und versuchen Sie die Sachen zusammen auszuarbeiten. Vielleicht fühlt er / sie auf der gleichen Weise, aber hat Angst, es aus zu sprechen.</p><p>Wenn Sie alleine sind, mag es scheinen, als ob Ihre Bemühungen um einen Partner zu finden zu nichts führen. Schauen Sie tief in Ihrem Herzen und fragen Sie Sich, ob es etwas gibt, was Sie vielleicht zurückhält: könnten Sie immer noch Gefühle für einen früheren Partner haben? Sind Sie absolut sicher, dass Sie jetzt eine Beziehung eingehen wollen? Die Beantwortung dieser Fragen kann Ihnen ankurbeln, um Ihre Suche erfolgreich zu gestalten.</p>",
                        [GuidanceFields.WORK] : "<p>Der Bube der Pentagramme zeigt an, dass etwas nicht stimmt am Arbeitsplatz. Sie scheinen eine Menge Arbeit zu haben, die getan werden muss, aber irgendwie fehlt Ihnen die Begeisterung, die Energie. Sie finden es schwer, sich ganz auf den Job zu stürzen, es ist nicht mehr so ganz interessant so wie es früher war. Obwohl Sie vielleicht alleine gut zurecht kommen, ist es ratsam um Hilfe zu bitten. Es gibt viele Menschen um Sie herum, die wirklich helfen, und Ihre Unterstützung kurbelt Sie an die Arbeit zu besiegen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Arbeiten an spirituelles Wachstum scheint nicht mehr so ein großes Abenteuer und große Herausforderung zu sein, wie es früher war. Irgendwie haben Sie Ihre Motivation, Ihren Antrieb verloren. Um den Eifer, den Sie hatten, zurückzubekommen könnten Sie Sich ein paar Fragen stellen. Welches Thema macht Sie neugierig, um weiter zu untersuchen? Welche Fragen würden Sie wirklich gerne beantwortet haben? Zögern Sie nicht, suchen Sie in den entferntesten Winkeln, den unwahrscheinlichsten Orten. Sie werden feststellen, dass diese neuen Wege, die Sie wählen, Ihnen wirklich begeistern werden und Ihnen neue Energie geben.</p>",
                    }
                }
            },
            {
                filename: "75",
                numerology : 12,
                en : {
                    name: "Knight of Pentacles",
                    suit : "Pentacles",
                    element : [ "🜃 - earth", "🜁 - air" ],
                    court : "Knight",
                    theme : "putting yourself out there",    
                    description : "Knight of Pentacles talks about showing your worth and to show our talents to the world around us.",
                    questions : [ "Have you gained knowledge and experience?", "What are you using it for?", "Can you show it to others?", "What will you actually do?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Knight of Pentacles is a down to earth, feet on the ground card. It speaks of realism, determination, solidity, reliability. It tells you to continually take care of the little things, because if you do that, the big things will take care of themselves. Ask yourself if all aspects of your life are well organized and if this is not the case right now, take care it will be ok from now on.</p><p>Just take care that you don’t go too far, you might become rigid and overorganized.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a committed relationship it may be time to look at how responsibly you behave. Do you share the loads that need to be carried? Do you work as hard to resolve issues as your partner does? Or are you mostly in it for the good times? Answer these questions and adjust things accordingly.</p><p>If you are single and lookingfor love this card tells you that your routine might be getting worn out. You will need to make some changes in the way you look for a partner, try out new places to hang out, critically look at what you expect from a new partner. Even some minor changes can make you a lot more successful.</p>",
                        [GuidanceFields.WORK] : "<p>This card tells you that nowit is the right time to be dependable and predictable. Don’t try to change the way you are getting things done, don’t question the traditions, the habits that form the true base of your job. </p><p>If you are looking for a job it is good to realize that the people you will meet are not looking for an adventurer or an innovator. They want and need someone dependable, someone they can be sure they can count on, so try and behave accordingly.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In this period of your life you are spending a lot of energy to preserve a status quo, to keep on keeping things organized and well taken care of. That is why this may not be the right time to focus too much on the spiritual. While you are busy “just” living you are at the same time evolving spiritually. But if you should focus on this growth too much it may become confusing, maybe even overwhelming. Make sure you are aiming for positive goals en just “be”.</p>",
                    }
                },
                fr : {
                    name : "Le Cavalier des Pentacles",
                    suit : "Pentacles",
                    element :  ["🜃 - terre", "🜁 - air" ],
                    court : "Cavalier",
                    theme : "Ouvrir son cœur vers l’extérieur",    
                    description : "Le Cavalier des Pentacles, nous parle de montrer ce que l’on vaut et de pouvoir montrer ses talents au monde qui nous entoure",
                    questions : ["Avez-vous acquis de la connaissance et de l'expérience?", "Qu'en faites-vous?", "Pouvez-vous montrer cela aux autres?", "Qu'allez-vous réellement faire?"] ,

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le «Chevalier de Pentagramme» est une carte sobre et raisonnable. Elle est synonyme de réalisme, de détermination, de solidarité et de fiabilité. Elle vous dit que vous devriez constamment prendre soin des petites choses et que si vous le faites, les grandes choses s’organiseront d'elles-mêmes. Demandez-vous si tous les aspects de votre vie sont bien organisés et, si ce n'est pas le cas en ce moment, à partir d’aujourd’hui prenez le temps de le mettre en place !</p><p>Mais ne le prenez pas trop à la lettre. Vous risquez de devenir trop rigide et trop organisé.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a committed relationship it may be time to look at how responsibly you behave. Do you share the loads that need to be carried? Do you work as hard to resolve issues as your partner does? Or are you mostly in it for the good times? Answer these questions and adjust things accordingly.</p><p>If you are single and lookingfor love this card tells you that your routine might be getting worn out. You will need to make some changes in the way you look for a partner, try out new places to hang out, critically look at what you expect from a new partner. Even some minor changes can make you a lot more successful.</p>",
                        [GuidanceFields.WORK] : "<p>This card tells you that nowit is the right time to be dependable and predictable. Don’t try to change the way you are getting things done, don’t question the traditions, the habits that form the true base of your job. </p><p>If you are looking for a job it is good to realize that the people you will meet are not looking for an adventurer or an innovator. They want and need someone dependable, someone they can be sure they can count on, so try and behave accordingly.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In this period of your life you are spending a lot of energy to preserve a status quo, to keep on keeping things organized and well taken care of. That is why this may not be the right time to focus too much on the spiritual. While you are busy “just” living you are at the same time evolving spiritually. But if you should focus on this growth too much it may become confusing, maybe even overwhelming. Make sure you are aiming for positive goals en just “be”.</p>",
                    }
                },
                sp:{
                    name:"Caballo de Pentáculos",
                    suit: "Pentáculos",
                    element: ["🜃 - tierra", "🜁 - aire" ],
                    court: "Caballo",
                    theme: "Salir de ti mismo",    
                    description: "El Caballo de Pentáculos se trata de mostrar lo que vales y nos recuerda que podemos mostrar nuestros talentos al mundo que nos rodea",
                    questions: ["¿Has adquirido conocimientos y experiencia?", "¿Qué haces con eso?", "¿Puedes enseñárselo a otros?", "¿Qué vas a hacer en realidad?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Caballero de Oros es una tarjeta con los pies en la tierra. Se habla de realismo, la determinación, la solidez, la fiabilidad. Se dice que usted tome continuamente la atención de las pequeñas cosas, porque si lo hace, las cosas grandes se harán cargo de sí mismos. Pregúntese si todos los aspectos de su vida están bien organizados y si este no es el caso ahora, tenga cuidado de que estará bien de ahora en adelante. Sólo tenga cuidado de no ir demasiado lejos, ¡podría ser rígida y más organizada.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Ridder van Pentakels",
                    suit : "Pentakels",
                    element : [ "🜃 - aarde", "🜁 - lucht" ],
                    court : "Ridder",
                    theme : "zelf naar buiten treden",    
                    description : "De ridder van pentakels gaat over laten zien wat je waard bent en wijst ons erop dat we onze talenten aan de wereld om ons heen mogen tonen.",
                    questions : [ "Heb jij kennis en ervaring opgedaan?", "Wat doe jij daarmee?", "Kun jij dat ook tonen aan anderen?", "Wat ga jij daadwerkelijk doen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Ridder van Pentakels is een aardse kaart, de ridder heeft zijn voeten stevig op de grond staan. Deze kaart gaat over realisme, vastberadenheid, betrouwbaarheid, stevigheid. Ze vertelt je ook hoe belangrijk het is en blijft om goed te zorgen voor de kleine dingen, wat niet opvalt heeft ook zorg en aandacht nodig. Als je deze houding volhoudt en je aandacht richt op de details dan zal je merken dat de grotere dingen als het ware voor zichzelf gaan zorgen. Als je deze kaart trekt dan is het de juiste tijd om je af te vragen of alle onderdelen van je leven goed georganiseerd zijn. Als dat niet het geval is, maak er dan nu werk van, breng orde in de chaos en blijf die orde dan ook onderhouden.</p><p>Natuurlijk is het belangrijk dat je hier niet te ver in gaat omdat je dan het risico loopt dat je star en te gestructureerd wordt, zonder ruimte voor improvisatie en spontaniteit.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een liefdesrelatie hebt dan is het nu een goede tijd om je af te vragen hoe verantwoordelijk jij je gedraagt in deze relatie. Neem je voldoende taken op je, draag je jouw deel van het gewicht, werk je even hard als je partner als het op het oplossen van conflicten aankomt? Kortom, zijn de inspanningen evenredig verdeeld? Of probeer je gewoon zoveel mogelijk van de leuke momenten te genieten en negeer je de rest? Eens je deze vragen beantwoord hebt, kan je beslissen of je je wil gaan aanpassen om alles in evenwicht te krijgen of dat je daar de energie en motivatie niet voor hebt en je dus eigenlijk uit deze relatie wil stappen.</p><p>Als je op zoek bent naar liefde dan vertelt deze kaart je dat je een beetje vastzit in een bepaald patroon. Probeer de manier waarop je je zoektocht aanpakt eens wat te veranderen, probeer nieuwe dingen uit, ga op andere plaatsen zoeken. En ook: kijk eens kritisch naar je verwachtingspatroon, wat voor partner wil je vinden, aan welke voorwaarden moet die persoon voldoen? Is het mogelijk dat je teveel vraagt? Zelfs een aantal kleine aanpassingen kan ervoor zorgen dat je er wel in slaagt om iemand te vinden.</p>",
                        [GuidanceFields.WORK] : "<p>Deze kaart vertelt je dat het in deze periode belangrijk is dat je betrouwbaar en voorspelbaar bent, dat is waar de mensen om je heen (een ook jij zelf) behoefte aan hebben. Verander op dit moment niets aan de manier waarop je de dingen aanpakt, stel tradities niet in vraag, houd je aan de gewoontes die de ware basis vormen van je werk.</p><p>Als je op zoek bent naar werk wees je er dan van bewust dat de mensen die je ontmoet op dit moment niet op zoek zijn naar een vernieuwer, een avontuurlijk of onvoorspelbaar type. Ze hebben eerder behoefte aan iemand die betrouwbaar is, iemand op wie ze kunnen rekenen. Stel je ook zo op en je zal merken dat je zoektocht succesvoller verloopt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In dit hoofdstuk van je leven investeer je veel energie in het behouden van de situatie zoals ze is, je richt je vooral op het organiseren van en zorgen voor de dingen. Daarom is het eigenlijk ook niet zo’n goede tijd om je teveel bezig te houden met het spirituele. Je hebt misschien het gevoel dat je al je tijd en energie stopt in het structureren van je dagelijkse leven maar tegelijkertijd ben je op een natuurlijke manier ook spiritueel aan het groeien, het gaat bijna vanzelf, je hoeft er geen extra aandacht aan te besteden. Het is zelfs zo dat als je je teveel zou focussen op je spirituele groei je enkel verward zou worden, misschien zelfs overweldigd door alle opties. Houd je dus niet teveel bezig met het spirituele, sta gewoon volop in de dagelijkse realiteit en richt je op positieve, constructieve doelen. De rest volgt vanzelf.</p>",
                    }
                },
                de : {
                    name: "Ritter der Pentagramme",
                    suit : "Pentagramme",
                    element : [ "🜃 - Erde", "🜁 - Luft" ],
                    court : "Ritter",
                    theme : "Sein Herz nach außen öffnen.",    
                    description : "Der Ritter der Pentagramme  spricht darüber, zu zeigen, was wir wert sind und wie wir unsere Talente der Welt um uns herum zeigen können.",
                    questions : [ "Haben Sie Wissen und Erfahrung gesammelt?", "Was machen Sie damit?", "Können Sie das anderen zeigen?", "Was werden Sie wirklich tun?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der „Ritter der Pentagramme“ ist eine nüchterne, vernünftige Karte. Sie steht für Realismus, Entschlossenheit, Solidarität und Zuverlässigkeit. Sie sagt Ihnen, dass Sie sich ständig um die kleinen Dinge kümmern sollten und dass, wenn Sie das tun, die großen Dinge für sich selbst sorgen. Fragen Sie sich, ob alle Aspekte Ihres Lebens gut organisiert sind, und wenn dies in diesem Moment nicht der Fall ist, stellen Sie sicher, dass das ab jetzt in Ordnung sein wird.</p><p>Nehmen Sie das aber nicht zu wörtlich. Die Gefahr besteht dann, dass Sie zu unflexibel und überorganisiert werden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer festen Beziehung sind es an der Zeit zu überprüfen, wie verantwortungsvoll Sie Sich verhalten. Teilen Sie die Lasten, die Sie tragen müssen? Haben Sie genauso hart gearbeitet, um Probleme zu lösen, wie Ihr Partner das tut? Oder sind Sie meistens nur da für die guten Zeiten? Beantworten Sie diese Fragen und passen Sie die Dinge dementsprechend an.</p><p>Wenn Sie alleine sind und in einer ich-suche Liebe Situation sind, dann sagt diese Karte Ihnen, dass Ihre Routine ein wenig abgenutzt ist. Sie müssen einige Änderungen in der Art und Weise anbringen, auf welche Sie einen Partner suchen. Versuchen Sie neue Plätze zum Verweilen, seien Sie kritisch über das, was Sie von einem neuen Partner erwarten. Auch einige kleinere Änderungen könnten Sie viel erfolgreicher machen.</p>",
                        [GuidanceFields.WORK] : "<p>Diese Karte sagt, dass es jetzt der richtige Zeitpunkt ist zuverlässig und berechenbar zu sein. Versuchen Sie nicht, die Art und Weise wie Sie immer die Dinge getan haben zu ändern, nicht die Traditionen, die Gewohnheiten, die das wahre Fundament Ihrer Arbeit sind, in Frage zu stellen.</p><p>Wenn Sie auf der Suche nach einem Job sind ist es gut zu wissen, dass die Menschen, die Sie treffen, werden nicht auf der Suche sind nach einem Abenteurer oder Innovator. Sie wollen jemanden, der zuverlässig ist, jemanden bei wem sie sicher sind, dass sie sich darauf verlassen können. Verhalten Sie Sich also dementsprechend.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In dieser Zeit Ihres Lebens verwenden Sie eine Menge Energie, um einen Status quo zu bewahren, um die Sache gut organisiert und gut betreut zu haben. Deshalb ist dies nicht der richtige Zeitpunkt, sich zu sehr auf der Spiritualität zu konzentrieren. Während Sie beschäftigt sind mit \"nur\" leben, sind Sie gerade dabei Sich spirituell zu entwickeln. Aber wenn Sie Sich zu viel auf diesem Wachstum konzentrieren, dann kann es unübersichtlich werden, vielleicht sogar überwältigend. Stellen Sie sicher, dass Sie nur positive Ziele nachstreben nur das Sein.</p>",
                    }
                }
            },
            {
                filename: "76",
                numerology : 13,
                en : {
                    name: "Queen of Pentacles",
                    suit : "Pentacles",
                    element : [ "🜃 - earth", "🜄 - water" ],
                    court : "Queen",
                    theme : "taking care of what is precious",    
                    description : "Queen of Pentacles talks about paying attention to what is precious to you. Make some space for yourself",
                    questions : [ "What is precious to you?", "Are you taking care of it?", "Sufficiently?", "Are you taking care of yourself (as well)?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Queen of Pentacles is about being comfortable with your feminine, earthly side, feeling magnanimous, good-natured, stable. It is also about feeling titillating joy. The woman on the card has a gentle, open face, she is tenderly holding the Pentacle, she is surrounded by plants and flowers, by abundance. She is enjoying the pleasures of life and tells you to do so too, to feel good about yourself and enjoy life to the fullest.</p><p>You are or will be radiating a sense of wellbeing and probably people will come to you for advice and comfort.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card indicates joy and fulfillment in all your relationships. Now is the time to spend quality time with your loved ones and relax, knowing you are safe, loved, nurtured. Even if you are more of an independent person, you might consider to open up, let people in. This may also be a time of growth, of meeting new people, and maybe you are even expecting a new addition to the family.</p><p>When you are looking for a partner this is not the time to go hunting. Be yourself, be open to new experiences, show people you meet what you have to give, to offer. You will notice that they will be approaching you, not the other way round.</p>",
                        [GuidanceFields.WORK] : "<p>Now is the time to focus on ‘feminine’ values like creativity, dependability, stamina. It is also a good time to decorate your workplace, to make it your own, a home away from home. This will help you to deliver good work. Don’t expect spectacular successes right now, you will reach your goals in a more subdued manner. Take the time to enjoy life even if it costs you some money, don’t hesitate to spend if it increases your quality of life, this is the right time to do so.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>As far as your spiritual life is concerned, now is the time to focus on yourself, on your beliefs, on your philosophies. You know and are sure that you are on the right track, so do not hesitate. Continue to do what you have been doing for quite some time. It is not important that others condone or agree with you on a spiritual level, the most important thing is that you are happy with who you are, with the decisions you make.</p>",
                    }
                },
                fr : {
                    name : "La Reine des Pentacles",
                    suit : "Pentacles",
                    element :  ["🜃 - terre", "🜄 - eau" ],
                    court : "Reine",
                    theme : "prendre soin de ce qui est précieux",    
                    description : "La Reine des Pentacles, nous parle de prêter attention à ce qui est nous est précieux. Se donner de l'espace.",
                    questions : ["Que vous êtes-t-il précieux?", "Prenez-vous en soin?", "Assez?", "Prenez-vous (également) soin de vous-même?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La \"Reine de Pentagramme \" dit que vous vous sentez à l'aise avec votre côté féminin et terrestre. Vous êtes généreux, de bonne humeur, stable. Elle représente aussi le sentiment de joie pétillante. La femme sur la carte a un visage doux et ouvert et elle tient tendrement le Denier. Elle est entourée de plantes et de fleurs, d'abondance. Elle aime les plaisirs de la vie et vous conseille de faire de même pour vous sentir bien et profiter pleinement de la vie.</p><p>En ce moment vous rayonnez ou rayonnerez une sensation de bien-être et probablement les gens autour de vous viendront à vous pour des conseils et du réconfort.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card indicates joy and fulfillment in all your relationships. Now is the time to spend quality time with your loved ones and relax, knowing you are safe, loved, nurtured. Even if you are more of an independent person, you might consider to open up, let people in. This may also be a time of growth, of meeting new people, and maybe you are even expecting a new addition to the family.</p><p>When you are looking for a partner this is not the time to go hunting. Be yourself, be open to new experiences, show people you meet what you have to give, to offer. You will notice that they will be approaching you, not the other way round.</p>",
                        [GuidanceFields.WORK] : "<p>Now is the time to focus on ‘feminine’ values like creativity, dependability, stamina. It is also a good time to decorate your workplace, to make it your own, a home away from home. This will help you to deliver good work. Don’t expect spectacular successes right now, you will reach your goals in a more subdued manner. Take the time to enjoy life even if it costs you some money, don’t hesitate to spend if it increases your quality of life, this is the right time to do so.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>As far as your spiritual life is concerned, now is the time to focus on yourself, on your beliefs, on your philosophies. You know and are sure that you are on the right track, so do not hesitate. Continue to do what you have been doing for quite some time. It is not important that others condone or agree with you on a spiritual level, the most important thing is that you are happy with who you are, with the decisions you make.</p>",
                    }
                },
                sp:{
                    name:"La Reina de Pentáculos",
                    suit: "Pentáculos",
                    element: ["🜃 - tierra", "🜄 - agua" ],
                    court: "Reina",
                    theme: "Cuidando de los valiosos",    
                    description: "La Reina de Pentáculos se trata de prestar atención a lo que es valioso para ti. Date un poco de espacio",
                    questions: ["¿Qué es valioso para ti?", "¿Cuidas de eso?", "¿Suficiente?", "¿También te cuidas a ti mismo?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La Reina de Oros se trata de estar cómodo con su lado femenino, la tierra, sintiendo magnánimo, de buen carácter y estable. También se trata de sentir alegría excitante. La mujer de la tarjeta tiene un rostro amable, abierto, ella está sosteniendo tiernamente el pináculo, ella está rodeada de plantas y flores, por la abundancia. Ella está disfrutando de los placeres de la vida y le dice que lo haga también, para sentirse bien consigo mismo y disfrutar de la vida al máximo. Usted está o va a estar irradiando una sensación de bienestar y, probablemente, la gente va a venir a usted para el asesoramiento y la comodidad.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Koningin van Pentakels",
                    suit : "Pentakels",
                    element : [ "🜃 - aarde", "🜄 - water" ],
                    court : "Koningin",
                    theme : "zorgdragen voor het waardevolle",    
                    description : "De koninging van pentakels gaat over aandacht schenken aan dat wat voor jou waardevol is. Geef jezelf de ruimte",
                    questions : [ "Wat is voor jou waardevol?", "Zorg je daarvoor?", "Genoeg?", "Zorg je (ook) goed voor jezelf?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Koningin van Pentakels heeft als onderwerp je goed voelen bij het vrouwelijke, het aardse. De kaart symboliseert ruimhartigheid, evenwicht, stabiliteit, een positieve instelling. De vrouw op de kaart straalt zachtheid en openheid uit, ze wordt omringd door bloemen en planten, door overvloed. Ze geniet van de kleine vreugdes in het leven en ze nodigt je uit om dat ook te gaan doen. Richt je op het positieve in jezelf, bekijk de wereld met een roze bril en dompel je onder in levensvreugde. Op dit moment straal je waarschijnlijk een gevoel van welzijn uit. Daardoor zullen mensen je ook benaderen voor advies en troost. Probeer hen hierin tegemoet te komen, maar natuurlijk niet ten koste van je eigen geluk. Ga je niet onderdompelen in de problemen van anderen, jouw welzijn gaat voor.</p><p>Deze kaart nodigt je ook uit om extra aandacht te besteden aan de aardse aspecten van het leven; kook eens een uitgebreide maaltijd, ga eens aan de slag in je tuin, maak een lange wandeling, geniet hiervan met volle teugen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart wijst op vreugde en voldoening in je relatie. Probeer om nu zoveel mogelijk kwaliteitstijd met je geliefden door te brengen. Ontspan je, wees je ten volle bewust van het feit dat je geliefd bent, veilig, gevoed. Zelfs al ben je een behoorlijk onafhankelijk type en stel je je niet gemakkelijk open, probeer toch om de mensen waar je van houdt net iets meer ruimte te geven dan gewoonlijk, je zal ervan genieten!</p><p>De Koningin van Pentakels symboliseert ook groei, het is een goede tijd om nieuwe mensen te leren kennen. Mogelijk komt er zelfs een zwangerschap in je nabije omgeving.</p><p>Als je op zoek bent naar een partner ga dan nu niet jagen, probeer niet om indruk te maken. Wees gewoon jezelf, sta open voor nieuwe ervaringen, toon de mensen wie je bent en wat je te bieden hebt, dat is ruim voldoende! Als je je op deze manier opstelt zal je merken dat mensen vanzelf naar je toe komen, jagen zal niet nodig zijn.</p>",
                        [GuidanceFields.WORK] : "<p>Het is verstandig om nu te gaan focussen op ‘vrouwelijke’ waarden zoals creativiteit, betrouwbaarheid, uithoudingsvermogen. Tegelijk is het ook belangrijk om van je werkplek een vertrouwde, warme plaats te gaan maken. Zet wat foto’s neer, voorwerpen die je koestert en zorg er zo voor dat je je ook op het werk helemaal thuis voelt. Je zal merken dat dit de kwaliteit van je werk een stuk verbetert. Je zal natuurlijk niet ineens spectaculaire successen boeken maar je zal merken dat je je evenwichtiger, harmonischer voelt tijdens het werken.</p><p> Neem ook de tijd om van het leven te genieten, ook al kost dat misschien wat geld. Het is best goed om geld uit te geven als je levenskwaliteit daardoor enorm verbetert en die verbetering is nu heel belangrijk.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Wat je spirituele leven betreft is het op dit moment goed om je te concentreren op jezelf, op wat jij gelooft, jouw filosofie, jouw normen en waarden. Je hebt er momenteel alle vertrouwen in dat je op de goede weg zit, dus ga niet twijfelen, je weet wat je te doen staat, ga gewoon door zoals je bezig bent. Je zit nu al enige tijd op een bepaalde spirituele weg en voorlopig is dit absoluut de juiste voor jou!</p><p>Het maakt niet uit wat anderen daarvan vinden en of ze het ermee eens zijn. Waar het om gaat is dat jij gelukkig bent met wie je bent en met de beslissingen die je neemt, het gaat hier om jou.</p>",
                    }
                },
                de : {
                    name: "Königin der Pentagramme",
                    suit : "Pentagramme",
                    element : [ "🜃 - Erde", "🜄 - Wasser" ],
                    court : "Königin",
                    theme : "Sich um das Kostbare kümmern.",    
                    description : "Die Königin der Pentagramme sagt darauf zu achten, was für uns kostbar ist. Geben Sie sich selbst Freiraum.",
                    questions : [ "Was ist Ihnen kostbar?", "Kümmern Sie sich darum?", "Genug?", "Kümmern Sie sich (auch) genug um sich selbst?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Königin der Pentagramme“ sagt, dass Sie sich mit Ihrer weiblichen, irdischen Seite wohlfühlen. Sie sind großzügig, gutmütig und stabil. Sie steht auch für das Gefühl prickelnder Freude. Die Frau auf der Karte hat ein sanftes, offenes Gesicht und sie hält das Pentagramm zärtlich fest. Sie ist umgeben von Pflanzen und Blumen, von Fülle. Sie genießt die Freuden des Lebens und rät Ihnen, das Gleiche zu tun, damit Sie sich gut fühlen und das Leben in vollen Zügen genießen können.</p><p>Im Moment strahlen Sie oder werden Sie ein Gefühl von Wohlbefinden ausstrahlen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte zeigt Freude und Erfüllung in all Ihren Beziehungen an. Jetzt ist die Zeit, um Zeit mit Ihren Lieben zu verbringen und zu entspannen im Wissen, dass Sie sicher, geliebt, und genährt sind. Auch wenn Sie ein unabhängiger Person sind, sollten Sie überlegen, sich zu öffnen, damit die Menschen reinkommen können. Dies kann auch eine Zeit des Wachstums sein, zum Treffen von neuen Leute, und vielleicht könnte man auch Zuwachs in der Familie erwarten.</p><p>Wenn Sie nach einem Partner suchen, ist dies nicht die Zeit, auf die Jagd zu gehen. Seien Sie Sich selbst, offen für neue Erfahrungen, zeigen Sie die Menschen, die Sie treffen, was Sie haben und bieten können. Sie werden feststellen, dass sie Sie annähern wollen und nicht umgekehrt.</p>",
                        [GuidanceFields.WORK] : "<p>Jetzt ist die Zeit, um auf \"weiblichen\" Werten wie Kreativität, Zuverlässigkeit, Ausdauer zu konzentrieren. Es ist auch ein guter Zeitpunkt, um Ihren Arbeitsplatz zu schmücken, um es zu einem Zuhause zu machen. Das wird Ihnen helfen, gute Arbeit zu liefern. Erwarten Sie nicht gerade jetzt spektakuläre Erfolge. Sie werden Ihre Ziele auf eine ruhigere Weise erreichen. Nehmen Sie sich Zeit, um das Leben zu genießen, auch wenn es Sie was Geld kostet. Zögern Sie nicht Geld auszugeben, wenn es Ihre Lebensqualität erhöht, es ist der richtige Zeitpunkt, dies zu tun.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>So weit es Ihr spirituelles Leben betrifft, ist jetzt die Zeit, sich auf sich selbst zu konzentrieren, auf Ihren Überzeugungen, auf Ihren Philosophien. Sie wissen, und sind sicher, dass Sie auf dem richtigen Weg sind, so zögern Sie nicht, weiterhin das zu tun, was Sie schon seit einiger Zeit getan haben. Es ist nicht wichtig, dass andere dulden oder einstimmen mit Ihnen auf einer spirituellen Ebene, es ist das Wichtigste, dass Sie mit wem Sie sind, mit Ihren Entscheidungen, die Sie machen, glücklich sind.</p>",
                    }
                }
            },
            {
                filename: "77",
                numerology : 14,
                en : {
                    name: "King of Pentacles",
                    suit : "Pentacles",
                    element : [ "🜃 - earth", "🜂 - fire" ],
                    court : "King",
                    theme : "effectively acting from within",    
                    description : "King of  Pentacles talks about effectively acting from within with what is precious to you. Go create.",
                    questions : [ "Do you know what is precious to you?", "Are you acting towards it?", "Do you think it is all up to you?", "Can you delegate?" ] ,
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The King of Pentacles focuses on the more “masculine” values like tradition, authority, sense of reality, durability, the pursuit of possessions. It’s not only about words and feelings but also about facts and accomplishments, about being firmly rooted in everyday reality. Actions speak louder than words. </p><p>The dark side of this card is about being too focused on passion and the physical side of things.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In relationships this card is about loyalty and friendship. If you are currently engaged in a loving relationship things may be very comfortable, at ease. But maybe that is not enough for you, you may be hungry for more passion, deeper intimacy. It is up to you to make this clear to your partner, to be open about what you are longing for. </p><p>This card may also indicate that you are feeling jealous. If this is the case, talk about it, don’t leave it up to your partner to guess what is going on in your heart and head.</p><p>If you are single, this is the perfect time to meet someone who will be very passionate about you. Don’t try to accommodate this person, just be yourself. Who you are is the reason for their passion, not who you might become.</p>",
                        [GuidanceFields.WORK] : "<p>At work you may be striving for security, steadiness. These are worthy goals but be careful not to become too rigid, too set in your ways. You may experience your superiors to be very interested in you and your work, but maybe sometimes they’re a bit quick in passing judgment. Make sure your work can withstand criticism at all times. Be realistic in knowing what you can and cannot accomplish.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may have been focused on the more material side of life for quite a while. You probably didn’t take the time to think about spirituality and what it might mean to you. Now is the time to make an effort and realize that you too can be a spiritual being. Look at this as just another project, gather knowledge, study different ways of experiencing spirituality and choose what feels the most natural for you.</p>",
                    }
                },
                fr : {
                    name : "Le Roi des Pentacles",
                    suit : "Pentacles",
                    element :  ["🜃 - terre", "🜂 - feu" ],
                    court : "Roi",
                    theme : "Agir activement à partir de son être propre",    
                    description : "Le Roi des Pentacles, nous parle d’agir à partir de son être propre, avec ce qui lui porte de la valeur. Lui donner forme.",
                    questions : ["Savez-vous ce qui porte de la valeur pour vous?", "Agissez-vous en conséquence?", "Pensez-vous que vous devez tout faire vous-même?", "Pouvez-vous également déléguer quelque chose à quelqu'un d'autre?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Roi de Pentagramme \" se concentre sur des valeurs plus \"masculines\" comme la tradition, l'autorité, le sens des réalités, la durabilité, la recherche de la possession. Cette carte n'est pas seulement synonyme de mots et de sentiments, mais aussi de faits et de réalisations. Il s'agit d'être fermement ancré dans la vie quotidienne. Les actes sont plus éloquents que les mots.</p><p>Le côté sombre de cette carte est le danger d'être trop concentré sur la passion et le côté physique des choses.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In relationships this card is about loyalty and friendship. If you are currently engaged in a loving relationship things may be very comfortable, at ease. But maybe that is not enough for you, you may be hungry for more passion, deeper intimacy. It is up to you to make this clear to your partner, to be open about what you are longing for. </p><p>This card may also indicate that you are feeling jealous. If this is the case, talk about it, don’t leave it up to your partner to guess what is going on in your heart and head.</p><p>If you are single, this is the perfect time to meet someone who will be very passionate about you. Don’t try to accommodate this person, just be yourself. Who you are is the reason for their passion, not who you might become.</p>",
                        [GuidanceFields.WORK] : "<p>At work you may be striving for security, steadiness. These are worthy goals but be careful not to become too rigid, too set in your ways. You may experience your superiors to be very interested in you and your work, but maybe sometimes they’re a bit quick in passing judgment. Make sure your work can withstand criticism at all times. Be realistic in knowing what you can and cannot accomplish.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may have been focused on the more material side of life for quite a while. You probably didn’t take the time to think about spirituality and what it might mean to you. Now is the time to make an effort and realize that you too can be a spiritual being. Look at this as just another project, gather knowledge, study different ways of experiencing spirituality and choose what feels the most natural for you.</p>",
                    }
                },
                sp:{
                    name: "El Rey de Pentáculos",
                    suit: "Pentáculos",
                    element: ["🜃 - tierra", "🜂 - fuego" ],
                    court: "Rey",
                    theme: "Actuar activamente desde tu propio ser",    
                    description: "El Rey de Pentáculos es acerca de actuar realmente desde tu propio ser, con lo que es valioso para ti. Dale forma",
                    questions: ["¿Sabes lo que es valioso para ti?", "¿Actúas en consecuencia?", "¿Crees que tienes que hacerlo todo por ti mismo?", "¿Puedes dejar algo a otra persona?" ],
                    
                    cardoftheday: {
                            [GuidanceFields.DEFAULT] : "<p>El Rey de Oros se centra en los valores más \"masculinos\" como tradición, la autoridad, el sentido de la realidad, la durabilidad, la búsqueda de posesiones. No es sólo acerca de las palabras y sentimientos, sino también sobre hechos y logros, acerca de ser firmemente enraizada en la realidad cotidiana. Las acciones hablan más que las palabras. El lado oscuro de esta tarjeta es acerca de ser demasiado centrado en la pasión y el aspecto físico de las cosas.</p>",
                            [GuidanceFields.RELATIONSHIP] : "",
                            [GuidanceFields.WORK] : "",
                            [GuidanceFields.SPIRITUAL] : "",
                        },
                    },
                nl : {
                    name: "Koning van Pentakels",
                    suit : "Pentakels",
                    element : [ "🜃 - aarde", "🜂 - vuur" ],
                    court : "Koning",
                    theme : "actief handelen vanuit je eigen zijn",    
                    description : "De koning van pentakels gaat over daadwerkelijk handelen vanuit je eigen zijn, met dat wat voor jou waardevol is. Geef het vorm.",
                    questions : [ "Weet jij wat voor jou waardevol is?", "Handel je daar ook naar?", "Denk jij alles zelf te moeten doen?", "Kun je ook iets aan een ander overlaten?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Koning van Pentakels richt zich op de meer ‘mannelijke’ waarden zoals traditie, autoriteit, realiteitszin, duurzaamheid, het verwerven van bezittingen. Deze kaart heeft het niet enkel over woorden en gevoelens, maar ook over feiten en de dingen die je bereikt hebt. De Koning van Pentakels staat met beide voeten stevig in de realiteit van alledag en hij zegt dat daden luider praten dan woorden. Mensen luisteren niet zozeer naar wat je zegt, ze kijken eerder naar wat je doet.</p><p>Maar let op, er zit hier een addertje onder het gras: als je te ver doorslaat in de richting die deze kaart je aanwijst dan loop je het risico dat je verdwaalt in je passies, je hartstochten. Dan neig je naar het obsessieve en heb je enkel nog oog voor de fysieke kant van de dingen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wat relaties betreft focust deze kaart vooral op trouw en vriendschap. Als je een liefdesrelatie hebt dan heb je op dit moment waarschijnlijk een heel comfortabel gevoel, ben je ontspannen en rustig. Toch is het best mogelijk dat dit niet voldoende is voor jou, dat je verlangt, zelfs hongert naar meer passie en diepere intimiteit. Je wil je relatie ook lichamelijk ten volle beleven. Het is nu aan jou om dit duidelijk te maken aan je partner, om open te zijn over je verlangens en behoeftes.</p><p>Als je deze kaart trekt dan kan het er ook op wijzen dat je jaloers bent. Als dit het geval is, praat er dan over, laat het niet over aan je partner om te gokken wat er aan de hand is in je hoofd en je hart.</p><p>Als je alleen bent dan is het nu de perfecte tijd om iemand te ontmoeten die vol hartstocht naar je toe komt. Probeer je niet aan te passen aan die persoon maar wees gewoon jezelf. Het is net omdat jij ‘gewoon’ jezelf bent dat deze persoon zo passioneel is. Als je probeert te veranderen zou die passie zomaar kunnen verdwijnen. Ook al vind jij jezelf maar gewoontjes, voor die ander ben je blijkbaar heel speciaal!</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk streef je op dit moment waarschijnlijk naar zekerheid, vastigheid. Dat zijn absoluut zinvolle doelen maar let er toch op dat je niet te streng en te strak wordt, dat je niet gaat vastzitten in je gewoontes en routines. Werken is iets dynamisch, elke dag hoor je je een beetje aan te passen aan veranderende omstandigheden, mee te groeien met behoeftes en eisen.</p><p>Waarschijnlijk zijn je meerderen behoorlijk geïnteresseerd in wat je doet en hoe je het doet, maar het is mogelijk dat ze af en toe een beetje te snel oordelen. Het is dus aan jou om ervoor te zorgen dat je werk niet bekritiseerd kan worden, dat alles altijd helemaal in orde is. Wees hierbij wel realistisch bij het inschatten van wat je wel en niet aankan.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Waarschijnlijk was je in de voorbije periode eerder gefocust op de materiele kant van het leven, dit kan al een tijdje het geval zijn. Misschien heb je gewoon niet de tijd genomen om na te denken over spiritualiteit en wat dit zou kunnen betekenen voor jou. Breng daar nu verandering in, doe wat moeite, verdiep je in de meer spirituele, ontastbare kant van het leven. Je zal merken dat je diep vanbinnen echt wel een spirituele kern hebt. Beschouw dit gewoon als een van je projecten: vergaar kennis en informatie, bestudeer een aantal manieren om spiritualiteit te beleven en kies dan die manier die voor jou het meest natuurlijk aanvoelt. Enkel jij weet wat spiritualiteit voor jou betekent.</p>",
                    }
                },
                de : {
                    name: "König der Pentagramme",
                    suit : "Pentagramme",
                    element : [ "🜃 - Erde", "🜂 - Feuer" ],
                    court : "König",
                    theme : "Aktiv handeln aus dem eigenen Wesen heraus.",    
                    description : "Der König der Pentagramme spricht darüber, aus seinem eigenen Wesen heraus zu handeln, mit dem, was ihm Wert bringt. Ihm Form zu geben.",
                    questions : [ "Wissen Sie, was für Sie von Wert ist?", "Handeln Sie auch entsprechend?", "Denken Sie, dass Sie alles selbst machen müssen?", "Können Sie auch etwas an jemand anderen delegieren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der „König der Pentagramme“ konzentriert sich auf eher \"männliche\" Werte wie Tradition, Autorität, Realitätssinn, Haltbarkeit, das Streben nach Besitz. Diese Karte steht nicht nur für Worte und Gefühle, sondern auch für Fakten und Errungenschaften. Es geht darum, fest im Alltag verankert zu sein. Taten sagen mehr als Worte.</p><p>Die dunkle Seite dieser Karte ist die Gefahr, zu stark auf Leidenschaft und auf die physische Seite der Dinge konzentriert zu sein.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In Beziehungen handelt diese Karte über Treue und Freundschaft. Wenn Sie derzeit in einer liebevollen Beziehung sind, können die Dinge sehr komfortabel, ruhig sein. Aber vielleicht ist das nicht genug für Sie und sind Sie hungrig nach mehr Leidenschaft, tieferer Intimität. Es liegt an Ihnen Ihrem Partner gegenüber dieses klar zu machen. Seien Sie offen und sagen Sie was Sie verlangen.</p><p>Diese Karte kann auch bedeuten, dass Sie eifersüchtig sind. Wenn dies der Fall ist müssen Sie darüber reden. Lassen Sie Ihren Partner nicht erraten, was los ist in Ihrem Herzen und Kopf.</p><p>Wenn Sie alleine sind, ist dies die perfekte Zeit, um jemanden zu treffen, der sehr leidenschaftlich über Sie denkt. Versuchen Sie nicht, diese Person zu überzeugen, seien Sie einfach Sich selbst. Wer Sie sind, ist genau der Grund für Ihre/seine Leidenschaft, nicht, wer Sie werden könnten.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit können Sie streben nach Sicherheit, Beständigkeit. Dies sind würdige Ziele, aber seien Sie vorsichtig, nicht zu starr zu werden. Sie können bemerken, dass Ihre Vorgesetzten sehr interessiert an Ihnen und Ihrer Arbeit sind, aber vielleicht sind sie manchmal ein bisschen schnell mit Ihrem Urteil. Stellen Sie sicher, dass Ihre Arbeit jederzeit Kritik widerstehen kann. Seien Sie realistisch zu wissen, was Sie erreichen und nicht erreichen können.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie können Sich für eine längere Weile zu viel auf der materiellen Seite des Lebens konzentriert haben. Sie haben wahrscheinlich nicht die Zeit genommen über Spiritualität nachzudenken und Sich zu realisieren was es für Sie bedeutet. Jetzt ist die Zeit Sich zu bemühen und anzuerkennen, dass man auch ein spirituelles Wesen ist. Schauen Sie sich dieses als nur ein weiteres Projekt an, sammeln Sie Wissen, lernen Sie verschiedene Arten des Erlebens der Spiritualität und wählen Sie, was für Sie am meisten selbstverständlich fühlt.</p>",
                    }
                }
            }
        ]
    },
    [DeckTypes.VOYAGER] : {
        id : DeckTypes.VOYAGER,
        cards: [
            {
                filename: "00",
                numerology : 0,
                en : {
                    name : "Fool - Child", subtitle: "0",
                    planet : "♅ - Uranus",
                    archetype : "The child",
                    theme : "childlike open-mindedness",
                    description : "The Fool talks about childlike open-mindedness, curiosity  being open, limitless possibilities.",

                    bulletpoints : [],
                    questions : [ "How open-minded can you (still) be?", "Can you (still) be open to new things?", "What can you enthusiastically discover?" ],

                    soalPoints : [ "Naivety", "Receptivity", "Wonder", "Openness", "Fun", "Zest for life", "Carefree" ],
                    soalText : "Wonder is the very beginning of a (discovery) journey or of an investigation. Curiosity provides the incentive to start the experiment. A child spontaneously runs into the solution of the problem.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Fool leads his life free of worries and prejudice. He discovers the world with an open mind, not knowing nor worrying where he will end up. He has a childlike innocence and is filled with wonder and excitement. He is open to change, renewal and growth. Still, one should be careful not to be overly optimistic of naive. Sometimes it is wiser to watch your step than to go and jump in at the deep end.</p><p>Often this card also points out that now is a good time for new beginnings, for taking a fresh start.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Fool asks you to look at your personal relationships in a different way, to change your perspective. Now may be a good time to ask yourself if you are truly ready to commit. And do not worry if you are not, it’s not a problem as long as you are being honest about it. Do not give in to pressure from your surroundings. You are the only one who knows how intense you want your relationship(s) to be right now.</p><p>The Fool also tells you that fun times are coming your way and invites you to enjoy them. But don’t exaggerate, make sure you are still open for true love in case it crosses your path.</p>",
                        [GuidanceFields.WORK] : "<p>Look at your work or career with an open and inquisitive mind, ask yourself if there is room for learning and improvement. Maybe the time is right for new experiences, for change. If you have new ideas or proposals,you may notice that others don't understand or support you, that they are not interested in what you have to offer. Be clear and open minded, help them see where you're coming from, how you reached your conclusions, then they will probably understand.</p><p>The Fool may also indicate the time is right to look for a different job or career, or even to start your own business.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You are developing an interest in spirituality and sometimes the possibilities, the abundance of topics you can choose from is overwhelming. It may be hard to see the wood from the trees. You are definitely uncertain yet which direction you would like to go. Take your time to find the path that fits you best. Don’t limit yourself right now, keep an open mind.You may also have to accept that people around you possibly don't understand your need for spirituality.</p><p>Possibly you're also discovering abilities and talents you didn't even realize you had.</p>",
                    }
                },
                fr : {
                    name : "enfant insensé", subtitle : "0",
                    planet : "♅ - Uranus",
                    archetype : "L'enfant",
                    description : "Le Fou, nous parle de la confiance enfantine, la curiosité, garder l’esprit ouvert, les possibilités illimitées.",
                    questions : [ "A quel point pouvez-vous (encore) avoir l’esprit ouvert?", "Pouvez-vous (encore) être ouvert à de nouvelles choses?", "Que pouvez-vous découvrir avec enthousiasme?" ],
                    theme : "l'ouverture d'esprit enfantin",

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le fou représente toujours quelque chose de nouveau et d'innovateur. Il mène sa vie à l'abri des soucis, des pensées et des préjugés. Il découvre le monde avec un esprit ouvert, sans savoir, sans demander où il va finir. Il a une innocence enfantine et une attitude à la vie pleine d'émerveillement et d'enthousiasme. Il est toujours ouvert au changement, au renouvellement et à la croissance.</p><p>Néanmoins, il faut être prudent et ne pas être trop optimiste ou naïf. Il est parfois plus sage d'être très prudent au lieu de tomber dans un abîme à la fin.</p><p>Souvent, cette carte indique que c'est le bon moment pour un nouveau départ, pour un changement ou pour démarrer un nouveau projet.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Fool asks you to look at your personal relationships in a different way, to change your perspective. Now may be a good time to ask yourself if you are truly ready to commit. And do not worry if you are not, it’s not a problem as long as you are being honest about it. Do not give in to pressure from your surroundings. You are the only one who knows how intense you want your relationship(s) to be right now.</p><p>The Fool also tells you that fun times are coming your way and invites you to enjoy them. But don’t exaggerate, make sure you are still open for true love in case it crosses your path.</p>",
                        [GuidanceFields.WORK] : "<p>Look at your work or career with an open and inquisitive mind, ask yourself if there is room for learning and improvement. Maybe the time is right for new experiences, for change. If you have new ideas or proposals,you may notice that others don't understand or support you, that they are not interested in what you have to offer. Be clear and open minded, help them see where you're coming from, how you reached your conclusions, then they will probably understand.</p><p>The Fool may also indicate the time is right to look for a different job or career, or even to start your own business.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You are developing an interest in spirituality and sometimes the possibilities, the abundance of topics you can choose from is overwhelming. It may be hard to see the wood from the trees. You are definitely uncertain yet which direction you would like to go. Take your time to find the path that fits you best. Don’t limit yourself right now, keep an open mind.You may also have to accept that people around you possibly don't understand your need for spirituality.</p><p>Possibly you're also discovering abilities and talents you didn't even realize you had.</p>",
                    }
                },
                sp:{
                    name : "niño tonto", subtitle: "0",
                    planet: "♅ - Urano",
                    archetype: "El niño",
                    theme: "apertura de espíritu infantil",
                    description: "El Loco es acerca de la confianza infantil, de la curiosidad, de ser abierto, de las posibilidades ilimitadas",
                    questions: ["¿Qué abierto puedes ser (todavía)?", "¿Puedes (todavía) ser abierto a cosas nuevas?", "¿Qué puedes descubrir con entusiasmo?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Tonto lleva su vida libre de preocupaciones y prejuicios. Descubre el mundo con una mente abierta, sin saber ni preocuparse donde va a terminar. Él tiene una inocencia infantil y se llena de asombro y emoción. Él está abierto al cambio, la renovación y el crecimiento. Aún así, hay que tener cuidado de no ser demasiado optimista de ingenuo. A veces es más sabio que ver su paso de ir y saltar en la parte más profunda. A menudo esta tarjeta también señala que ahora es un buen momento para un nuevo comienzo, para tomar un nuevo comienzo.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name :  "Zotte kind", subtitle: "0",
                    planet : "♅ - Uranus",
                    archetype : "Het kind",
                    theme : "kinderlijke onbevangenheid",
                    description : "De zot gaat over kinderlijk vertrouwen, over nieuwsgierigheid, over open staan, over onbeperkte mogelijkheden.",
                    questions : [ "Hoe onbevangen kun jij (nog) zijn?", "Kun jij (nog) open staan voor nieuwe dingen?", "Wat kun jij met enthousiasme ontdekken?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Dwaas leidt zijn leven vrij van zorgen, piekeren en vooroordelen. Hij onderzoekt de wereld met een open geest, hij vraagt zich niet af waar hij terecht zal komen en maakt zich er ook niet druk om. Hij heeft een kinderlijke naïviteit en loopt over van verwondering en levensvreugde. Hij staat altijd open voor verandering, vernieuwing en groei. Mogelijk herken je jezelf hierin, of misschien kan je wat meer van deze eigenschappen gebruiken in je leven.</p><p>Let toch op dat je niet té optimistisch of naïef bent. Soms is het nu eenmaal verstandiger om te kijken waar je loopt dan zomaar in het diepe te springen. Als je deze kaart trekt dan wijst dit er vaak op dat het voor jou nu een goed moment is voor een nieuw begin, om een nieuwe start te nemen, een nieuw project op te zetten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Dwaas vraagt je om op een andere manier naar je relaties te kijken, vanuit een ander perspectief. Dit kan je kijk op de dingen totaal veranderen. Als het over de liefde gaat, kan je je afvragen of je er echt klaar voor bent om je te binden, wil je je wel voor langere tijd vastleggen? Maak je niet ongerust als dit niet het geval is, zolang je eerlijk bent zit je op het goede spoor. Het is ook mogelijk dat een reeds bestaande relatie betekenisvoller en diepgaander kan worden, als je daar klaar voor bent tenminste.</p><p>De Dwaas kan ook betekenen dat er leuke tijden aankomen, hij nodigt je uit om ervan te genieten. Maar overdrijf hier niet mee, zorg dat je er klaar voor bent als de ware liefde op je pad komt.</p>",
                        [GuidanceFields.WORK] : "<p>Kijk met een open en onderzoekende geest naar je werk en/of carrière, vraag je af of er ruimte en gelegenheid is voor bijleren en verbetering. Misschien is de tijd nu rijp voor verandering, voor nieuwe ervaringen. Het is wel mogelijk dat niet iedereen openstaat voor jouw nieuwe ideeën en voorstellen, dat ze er geen begrip voor hebben en je niet steunen. Probeer zo duidelijk en open mogelijk te zijn, vertel ze hoe je tot je nieuwe conclusies gekomen bent.</p><p>Soms vertelt De Dwaas ons dat het tijd is om op zoek te gaan naar een andere baan, een nieuwe carrière. Misschien kan je nu zelfs gaan denken aan het opzetten van je eigen bedrijf!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit tijdstip in je leven begin je interesse te krijgen voor spiritualiteit. Je raakt soms wat overweldigd door de veelheid aan onderwerpen en mogelijke richtingen die je kan kiezen. Misschien ontdek je op een gegeven moment ook krachten of talenten bij jezelf waarvan je nog niet wist dat je ze in je had. Het komt erop neer dat je echt nog niet weet welke kant je op wil. Maak je niet ongerust, neem gewoon de tijd om net dat pad te kiezen dat het beste bij je past en aanvaard dat de mensen om je heen mogelijk niet altijd zullen begrijpen waarom je nood hebt aan spiritualiteit in je leven.</p>",
                    }
                },
                de : {
                    name :  "Dummes Kind", subtitle: "0",
                    planet : "♅ - Uranus",
                    archetype : "Das Kind",
                    theme : "Die kindliche Offenheit",
                    description : "Der Narr spricht über kindliches Vertrauen, Neugierde, Offenheit und unbegrenzte Möglichkeiten",
                    questions : ["Wie offen können Sie (noch) sein?", " Können Sie (noch) offen sein für Neues", "Was können Sie mit Begeisterung entdecken?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der Narr steht immer für Neues und Erneuerungen. Er führt sein Leben frei von Sorgen, Grübeleien oder Vorurteilen. Er entdeckt die Welt mit einem offenen Geist, ohne zu wissen, ohne zu fragen, wo er landen wird. Er hat eine kindliche Unschuld und eine Einstellung zum Leben voller Staunen und Begeisterung. Er ist immer offen gegenüber Veränderung, Erneuerung und Wachstum.</p><p>Dennoch sollte man vorsichtig sein und nicht zu optimistisch oder naiv vorgehen. Manchmal ist es klüger, ganz vorsichtig vorzugehen anstatt am Ende in einen Abgrund zu fallen. Oft weist diese Karte darauf hin, dass jetzt ein guter Zeitpunkt für einen Neuanfang ist, für Veränderung oder für den Start eines neuen Projekts.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Der Narr fragt Sie, auf eine andere Weise Ihre persönlichen Beziehungen zu betrachten, damit Sie Ihre Perspektive ändern. Jetzt kann es eine gute Zeit sein, sich zu fragen, ob Sie wirklich bereit sind sich zu engagieren. Und keine Sorge, wenn das nicht der Fall wäre. Es ist kein Problem, solange Sie darüber ehrlich und klar sind. Geben Sie nicht auf unter Druck Ihrer Umgebung. Sie sind der Einzige, der genau weiß, wie intensiv Sie Ihre Beziehung (en) im Moment haben möchten.</p><p>Der Narr sagt Ihnen auch, dass Zeiten zum Spaß auf Ihren Weg kommen werden und lädt Sie ein, sie zu genießen. Aber übertreiben Sie nicht, stellen Sie sicher, dass Sie für die wahre Liebe offen stehen würden, falls sie an Sie vorbeikommen würde.</p>",
                        [GuidanceFields.WORK] : "<p>Betrachten Sie Ihre Arbeit oder Karriere mit einem offenen Auge und einem neugierigen Geist und fragen Sie Sich, ob es Raum und Möglichkeiten gibt zum Lernen und zur Verbesserung. Vielleicht ist die Zeit jetzt da für neue Erfahrungen, für Änderungen. Sollten Sie neue Ideen oder Vorschläge haben, könnten Sie feststellen, dass andere Sie nicht verstehen oder unterstützten und dass sie nicht interessiert sind an dem, was Sie zu bieten haben. Seien Sie ihnen gegenüber klar und offen und helfen Sie sie zu sehen, woher Sie kommen und wie Sie zu Ihren Schlussfolgerungen gekommen sind. Erst dann werden sie Sie wahrscheinlich verstehen. Der Narr kann auch bedeuten, dass die Zeit gekommen ist sich umzusehen nach einem anderen Job oder eine neue Karriere anzufangen, oder sogar Ihr eigenes Unternehmen zu gründen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie entwickeln ein großes Interesse an Spiritualität und manchmal sind die Möglichkeiten und der Überfluss an Themen, aus denen Sie wählen können, überwältigend. Es kann schwierig sein, durch die Bäume den Wald zu erkennen. Sie sind auf jedem Fall noch nicht sicher, in welche Richtung Sie gehen möchten. Nehmen Sie sich Zeit, um den Weg zu finden, der Ihnen am besten passt. Beschränken Sie Sich nicht gerade jetzt, behalten Sie einen offenen Geist. Sie müssen möglicherweise auch akzeptieren, dass die Leute um Sie herum möglicherweise nicht Ihr Bedürfnis nach Spiritualität verstehen. Möglicherweise entdecken Sie auch Fähigkeiten und Talente in Sich, die Sie Sich nimmer zugetraut hätten.</p>",
                    }
                }            
            },
            {
                filename: "01",
                numerology : 1,
                en : {
                    name : "Magician", subtitle: "I",
                    bulletpoints : ["Manifestation","Willpower","Creation"],
                    planet : "☿ - Mercury",
                    archetype : "The wizard",
                    theme : "Create your own future!",
                    description : " The Magician talks about taking action to create what you want, to act with what is potentially present.", 
                    questions : ["Do you know what your possibilities are?", "Which action could you take?", "Which could be your first step?", "What will you actually do?"],

                    soalPoints : [ "Scheppingskracht", "Wilskracht", "Vaardigheid", "Wijsheid", "Kracht", "Meesterschap en diplomatie" ],
                    soalText : "Toveren is niet magisch, het is creatief gebruik maken van al je kwaliteiten om tot een, zo goed mogelijk, resultaat te komen. Als je weet hoe het werkt, is alles eenvoudig. Je kunt alleen iets bereiken als je het ook echt wilt.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Magician invites you to look at all the possibilities, opportunities and directions you can choose from, to ponder on all life's questions. This card invites you to make better use of your spiritual, emotional and energetic powers. It asks you to be alert and active, to realize that you have the inspiration, knowledge and strength you need to face your challenges.</p><p>This card also tells you that you can be meaningful, in your own life as well as for others, maybe even for the world. You can make this a better place, even if you start with only one step.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Magician indicates that existing relationships can now grow stronger and more profound, you will be able to reach new levels of intimacy, joy and commitment. If you are looking for love you are very likely to meet someone new. Now is a good time to work on your social life, you will discover that people are attracted to you, they want to get to know you. Still, The Magician also warns you not to become selfish or self-centered, remember the world doesn’t actually revolve around you!</p>",
                        [GuidanceFields.WORK] : "<p>The Magician indicates that soon there will probably be an improvement in your work or career. The time is right to get a promotion or to find a new job, but you will have to take the first step, you will have to show initiative. Set yourself goals (raise, promotion, …) and take them up with the right people. Have faith in yourself, have faith in your talent to succeed! Just make sure you help people to see and understand your point of view by telling them about your experiences, your views. And don’t get overly emotional when discussing these topics.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may have a strong desire to spiritually grow and develop, to study and further explore certain topics. Now is the perfect time to do so. Make sure you surround yourself with kindred spirits who can teach and guide you. Right now it is very important for you to discuss the spiritual topics that interest you, to hear other people’s opinions and compare them with yours. Maybe you will even find a mentor who will stay with you for years to come.</p>",
                    }
                },
                fr : {
                    name : "Magicien", subtitle : "I",
                    planet : "☿ - Mercure",
                    archetype : "Le Sorcier",
                    theme : "Créez votre propre avenir!",
                    description : "Le magicien, nous parle d’agir pour réaliser ce que l'on veut, agir avec ce qui est potentiellement présent.", 
                    questions : ["Savez-vous quelles sont vos possibilités?", "Quelle action pourriez-vous prendre?", "Quel premier pas pourriez-vous faire?", "Qu'allez-vous réellement faire?"],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le magicien vous invite à voir les possibilités, les opportunités et les directions que vous pouvez choisir pour réfléchir sur tous les aspects de la vie. Cette carte vous invite à faire un meilleur usage de vos pouvoirs mentaux, émotionnels et énergétiques. Elle vous invite à être vigilant afin d'avoir suffisamment d'inspiration, de connaissances et de force pour relever les défis à venir.</p><p>Cette carte vous dit aussi que vous pouvez être utile, tant pour votre propre vie que pour celle des autres, peut-être même pour le monde entier. Vous serez peut-être en mesure de faire de ce monde un meilleur endroit, en commençant par un seul pas.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Magician indicates that existing relationships can now grow stronger and more profound, you will be able to reach new levels of intimacy, joy and commitment. If you are looking for love you are very likely to meet someone new. Now is a good time to work on your social life, you will discover that people are attracted to you, they want to get to know you. Still, The Magician also warns you not to become selfish or self-centered, remember the world doesn’t actually revolve around you!</p>",
                        [GuidanceFields.WORK] : "<p>The Magician indicates that soon there will probably be an improvement in your work or career. The time is right to get a promotion or to find a new job, but you will have to take the first step, you will have to show initiative. Set yourself goals (raise, promotion, …) and take them up with the right people. Have faith in yourself, have faith in your talent to succeed! Just make sure you help people to see and understand your point of view by telling them about your experiences, your views. And don’t get overly emotional when discussing these topics.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may have a strong desire to spiritually grow and develop, to study and further explore certain topics. Now is the perfect time to do so. Make sure you surround yourself with kindred spirits who can teach and guide you. Right now it is very important for you to discuss the spiritual topics that interest you, to hear other people’s opinions and compare them with yours. Maybe you will even find a mentor who will stay with you for years to come.</p>",
                    }
                },
                sp:{
                    name : "Mago", subtitle: "I",
                    planet: "☿ - Mercurio",
                    archetype: "El Mago",
                    theme: "¡crea tu propio futuro!",
                    description: "El Mago se trata de tomar acción para realizar lo que quieres, de actuar con lo que está potencialmente presente", 
                    questions: ["¿Sabes qué posibilidades tienes?", "¿Qué acción podrías tomar?", "¿Qué primer paso podrías dar? ¿Qué vas a hacer?"],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El mago invita a mirar a todas las posibilidades, oportunidades y direcciones que usted puede elegir, para reflexionar sobre cuestiones de toda la vida. Esta tarjeta le invita a hacer un mejor uso de sus poderes espirituales, emocionales y energéticos. Les pide que se mantenga alerta y activo, para darse cuenta  que usted tiene la inspiración, el conocimiento y la fuerza que necesita para hacer frente a sus desafíos. Esta tarjeta también le dice que puede ser significativo, en su propia vida, así como para otros, tal vez incluso para el mundo. Usted puede hacer esto en un lugar mejor, incluso si usted comienza con un solo paso.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name : "Magier", subtitle: "I",
                    planet : "☿ - Mercurius",
                    archetype : "De tovenaar",
                    theme : "creëer je eigen toekomst!",
                    description : "De Magiër gaat over het in actie komen om te kunnen realiseren wat jij wilt, over gaan handelen met hetgeen in potentie aanwezig is.", 
                    questions : ["Weet jij welke mogelijkheden je hebt?", "Welke actie zou je kunnen ondernemen?", "Welke eerste stap kun je zetten?", "Wat ga jij daadwerkelijk doen?"],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Magiër nodigt je uit om naar alle mogelijkheden, kansen en richtingen te kijken waar je op dit moment uit kan kiezen. Denk goed na over alle vragen die het leven je nu stelt.</p><p>Deze kaart nodigt je ook uit om beter gebruik te maken van je spirituele, emotionele en energetische krachten. Ze vraagt je om alert en actief te zijn, om te beseffen dat jij al de nodige inspiratie, kennis en kracht in je hebt om de uitdagingen aan te gaan waarmee je geconfronteerd wordt.</p><p>Ze vertelt je ook dat je van betekenis kan zijn zowel in je eigen leven als in dat van anderen, misschien zelfs in de wereld als geheel. Je kan er een betere plek van maken, al begin je met één enkele stap.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Magiër vertelt je dat de bestaande relaties in je leven steeds sterker en diepgaander worden. Je zal nieuwe hoogten bereiken wat betreft vreugde, intimiteit en betrokkenheid. Als je op zoek bent naar een liefdesrelatie dan is het erg waarschijnlijk dat je nu iemand zal ontmoeten. Als je wil werken aan je sociale leven dan is het daarvoor nu de juiste tijd. Je zal merken dat mensen zich tot jou aangetrokken voelen, ze willen je leren kennen.</p><p>Toch is het belangrijk dat je erop let niet te egoïstisch of egocentrisch te worden want ook al ben je populair, de wereld draait niet rond jou.</p>",
                        [GuidanceFields.WORK] : "<p>Volgens De Magiër is het erg waarschijnlijk dat er binnenkort verbetering komt in je job of je carrière. Als je al lang een promotie wilde vragen of als je een nieuwe job wil vinden dan is nu het geschikte moment. Let wel, jij zal de eerste stap moeten zetten, jij zal initiatief moeten nemen. Zorg dat je de juiste doelen voor ogen houdt (opslag, promotie, ...) en dat je hierover praat met de juiste mensen. Geloof in jezelf, geloof in je talent om te slagen! Het is aan jou om de anderen te overtuigen van jouw visie en toekomstplannen door hen te vertellen over je ervaringen en inzichten. Wees niet te kwistig met je emoties terwijl je deze gesprekken voert, houd het rationeel.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het is waarschijnlijk dat je op dit moment een grote behoefte hebt aan spirituele groei en ontwikkeling. Je kijkt ernaar uit om te studeren, te evolueren en bepaalde onderwerpen grondig te onderzoeken. De Magiër vertelt je dat het perfecte moment hiervoor aangebroken is. Omring jezelf met gelijkgestemde zielen die je kunnen helpen en leiden, praat met hen, vergelijk de verschillende opinies. Misschien vind je nu zelfs een mentor die je de komende jaren kan ondersteunen bij je evolutie en groei.</p>",
                    }
                },
                de : {
                    name: "Magier", subtitle: "I",
                    planet : "♅ - Merkur",
                    archetype : "Der Zauberer",
                    theme : "Seine eigene Zukunft gestalten",
                    description : "Der Magier spricht über das Handeln, um das zu erreichen, was wir wollen, mit dem zu handeln, was potenziell vorhanden ist.",
                    questions : ["Wissen Sie, welche Ihre Möglichkeiten sind?", "Welche Maßnahmen könnten Sie ergreifen?", "Welchen ersten Schritt könnten Sie tun?", "Was werden Sie wirklich tun?"],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der Magier lädt Sie ein, Möglichkeiten, Chancen und Richtungen zu sehen, die Sie wählen können, um über alle Lebensfragen nachzudenken. Diese Karte bittet Sie, Ihre geistigen, emotionalen und energetischen Kräfte besser zu nützen. Diese Karte lädt Sie ein, wachsam zu sein, damit Sie über ausreichend Inspiration, Wissen und Kraft verfügen, den auf Sie zukommenden Herausforderungen begegnen zu können.</p><p>Diese Karte sagt Ihnen auch, dass Sie sinnvoll sein können, sowohl für Ihr eigenes Leben als auch für das Leben Anderer, ja vielleicht sogar für die ganze Welt. Sie können die Welt möglicherweise zu einem besseren Ort machen, es beginnt alles mit einem Schritt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Der Magier zeigt an, dass bestehende Beziehungen sich jetzt verstärken und vertiefen können und Sie sind jetzt in der Lage erhöhte Ebene in Intimität, Freude und Engagement zu erreichen. Wenn, Sie auf der Suche nach Liebe sind Sie sehr wahrscheinlich zu neuen Bekanntschaften kommen. Jetzt ist der richtige Zeitpunkt an Ihr soziales Leben zu arbeiten und Sie werden feststellen, dass die Leute sich zu Ihnen hingezogen fühlen und Sie gerne näher kennenlernen möchten. Dennoch warnt der Magier Sie auch sich nicht zu egoistisch oder egozentrisch aufzustellen. Bedenken Sie Sich, dass die Welt sich nicht wirklich um Sie alleine herum dreht!</p>",
                        [GuidanceFields.WORK] : "<p>Der Magier zeigt an, dass es bald sehr wahrscheinlich eine Verbesserung in Ihrer Arbeit oder Karriere geben wird. Die Zeit ist reif für eine Beförderung oder um einen neuen Job zu finden, aber Sie müssen den ersten Schritt machen und die Initiative dazu nehmen. Setzen Sie Sich Ziele (zu Gehaltserhöhung, Beförderung, …), und unterhalten Sie Sich darüber mit den richtigen Leuten. Haben Sie Vertrauen in sich selbst, glauben Sie an Ihr Talent, um erfolgreich zu sein! So stellen Sie sicher, dass Sie die Leute helfen Ihren Standpunkt zu sehen und zu verstehen, indem Sie Ihre Erfahrungen und Ihre Ansichten mit ihnen teilen. Und werden Sie nicht übermäßig emotional, wenn es um diese Themen handelt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie haben einen starken Wunsch, spirituell zu wachsen und sich zu entwickeln und bestimmten Themen genauer zu untersuchen und weiterzuerforschen. Jetzt ist die perfekte Zeit, um dies zu tun. Achten Sie darauf, dass Sie von Gleichgesinnten umgeben sind, die Sie unterrichten und leiten können. Gerade jetzt ist es sehr wichtig für Sie über die spirituellen Themen, die Sie interessieren die Meinung anderer Leute zu hören und sie mit ihnen zu besprechen. Vielleicht werden Sie sogar einen Mentor begegnen, der für die kommenden Jahre zu Ihnen stehen wird.</p>",
                    }
                }
            },
            {
                filename: "02",
                numerology : 2,
                en : {
                    name : "Priestess", subtitle: "II",
                    planet : "☽ - Moon",
                    archetype : "The maiden",
                    theme : "to know intuitively",
                    description : "The High Priestess talks about what is hidden, about patiently waiting, intuitively knowing. ",
                    questions : [ "Are you in doubt?", "Are you waiting?", "What is holding you back to continue?", "Dare you listen to you intuition?" ],
                    bulletpoints : ["Intuition", "Mysteries", "Inner Voice"],

                    soalPoints : [ "Onderbewust", "Het verborgene", "Intuïtie", "Passiviteit", "Geduld", "Innerlijke stem" ],
                    soalText : "De verborgen waarheid is datgene wat niemand kan zien maar wat van binnen wordt ervaren, het innerlijke weten. Wacht af en luister naar je innerlijke stem wanneer het tijd is om te handelen. Het allerbeste is om op dit moment de dingen te laten gebeuren.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The High Priestess symbolizes feminine values and powers: intuition, sensitivity, goodness, forgiveness, faith. She urges you to reflect on your thoughts and feelings in silence, to make room for visions and intuitive realizations.</p><p>This card indicates the unconscious powers that live within you, the dark side we all harbor. If you have the strength and courage to face those shadows, you will no longer be guided by them and be free to fully explore and utilize your feminine side.</p><p>Now is also a time to pay extra attention to serendipity and synchronicity.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>You may find yourself attracted to a person who's mysterious, hard to get to know, maybe even seeming distant and uninterested. Take your time, use your intuition, she/he may very well be \"the one\".</p><p>On the other hand it's possible you find people being really attracted to you, without you making any effort. Use this power wisely, don't succumb to superficiality but show compassion, try to be understanding and caring.</p>",
                        [GuidanceFields.WORK] : "<p>The High Priestess makes you aware that now is the time to experience your work in serenity, fully conscious and aware, to be Here Now. Don't let anything distract you from listening to your inner voice, your intuition, your heart. First you observe, then you decide. Possibly these observations will help you find new and improved ways to do your job, and those findings will help you reduce stress.</p><p>One warning: for the moment it would be unwise to participate if people are judging or criticizing colleagues in your presence.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may have known for a while that you do have spiritual abilities, and now is the time to find out what your area of expertise is and to further study and grow. If you have a problem accepting your spirituality, the way to cope with this is to study, learn, evolve. One tool you can use to achieve this is talking to people with a lot of spiritual experience. Even though some of the things they tell you may be somehow frightening, they will also help you reach a higher plane.</p>",
                    }
                },
                fr : {
                    name : "Prêtresse", subtitle : "II",
                    planet : "☽ - Lune",
                    archetype : "La Vierge",
                    theme : "le savoir intuitif",
                    description : "La Prêtresse, nous parle de ce qui est caché, d’attendre patiemment, du savoir intuitif.",                  
                    questions : ["Hésitez-vous?", "Attendez-vous ?", "Qu’est ce qu’il vous empêche d'avancer?", "Osez-vous vraiment écouter votre intuition?"],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La grande prêtresse symbolise les valeurs et les forces féminines telles que l'intuition, la sensibilité, la bonté, le pardon, la confiance. Elle vous exhorte à méditer en silence sur vos pensées et vos sentiments, afin que l'espace devienne libre pour des visions et des intuitions.</p><p>Cette carte montre les forces inconscientes qui vivent en vous, un côté sombre que nous avons tous. Si vous avez la force et le courage d'affronter ces côtés moins connus, vous ne serez plus inconsciemment guidée par eux et libre de les explorer pleinement et d'utiliser pleinement ce côté féminin.</p><p>C'est aussi le moment d'accorder une attention particulière à la sensibilité et à la synchronicité (par exemple, vous pensez à quelqu'un et cette personne vous appelle au même moment).</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>You may find yourself attracted to a person who's mysterious, hard to get to know, maybe even seeming distant and uninterested. Take your time, use your intuition, she/he may very well be \"the one\".</p><p>On the other hand it's possible you find people being really attracted to you, without you making any effort. Use this power wisely, don't succumb to superficiality but show compassion, try to be understanding and caring.</p>",
                        [GuidanceFields.WORK] : "<p>The High Priestess makes you aware that now is the time to experience your work in serenity, fully conscious and aware, to be Here Now. Don't let anything distract you from listening to your inner voice, your intuition, your heart. First you observe, then you decide. Possibly these observations will help you find new and improved ways to do your job, and those findings will help you reduce stress.</p><p>One warning: for the moment it would be unwise to participate if people are judging or criticizing colleagues in your presence.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may have known for a while that you do have spiritual abilities, and now is the time to find out what your area of expertise is and to further study and grow. If you have a problem accepting your spirituality, the way to cope with this is to study, learn, evolve. One tool you can use to achieve this is talking to people with a lot of spiritual experience. Even though some of the things they tell you may be somehow frightening, they will also help you reach a higher plane.</p>",
                    }
                },
                sp:{
                    name : "Sacerdotisa", subtitle: "II",
                    planet: "☽ - Luna",
                    archetype: "La Virgen",
                    theme: "conocimiento intuitivo",
                    description: "La Suma Sacerdotisa es sobre lo oculto, sobre la espera paciente, sobre el conocimiento intuitivo",
                    questions: ["¿Estás dudando?", "¿Estás esperando?", "¿Qué te retiene seguir adelante?", "¿Te atreves a escuchar tu intuición?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La Sacerdotisa simboliza los valores femeninos y facultades: la intuición, la sensibilidad, la bondad, el perdón, la fe. Ella le insta a reflexionar sobre sus pensamientos y sentimientos en silencio, para dar cabida a visiones y comprensión intuitiva. Esta tarjeta indica los poderes inconscientes que viven dentro de usted, el lado oscuro en todos los puertos. Si usted tiene la fuerza y el coraje para enfrentarse a esas sombras, ya no se guiará por ellos y tener la libertad de explorar al fondo y utilizar su lado femenino. Ahora también es un momento de prestar más atención a la casualidad y la sin cronicidad.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name :  "Priesteres", subtitle: "II",
                    planet : "☽ - Maan",
                    archetype : "De maagd",
                    theme : "het intuïtief weten",
                    description : "De Hogepriesteres gaat over het verborgene, over het geduldig afwachtende, over het intuïtief weten",
                    questions : [ "Twijfel je?", "Wacht je af?", "Wat houdt je tegen om verder te gaan?", "Durf jij echt te luisteren naar je intuïtie?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Hogepriesteres symboliseert vrouwelijke waarden en krachten: intuïtie, gevoeligheid, goedheid, vergeving, geloof. Ze spoort je aan om in stilte na te denken over je gedachten en gevoelens, om ruimte te maken voor inzichten, visioenen en intuïtieve bewustwording.</p><p>Deze kaart wijst je op de onbewuste krachten die in je diepste kern leven, de donkere zijde die we allemaal hebben. Als je de kracht en de moed hebt om de confrontatie aan te gaan met deze minder bekende kant, dan zal je er niet langer onbewust door overheerst worden. Je zal de vrijheid verwerven om deze vrouwelijke kant vrijuit te onderzoeken en te gebruiken.</p><p>Besteed in deze periode van je leven extra aandacht aan zogenaamde toevalligheden en aan zaken die gelijktijdig gebeuren (bvb. je denkt aan iemand en die belt je).</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Het is goed mogelijk dat je je in deze fase van je leven aangetrokken voelt tot iemand die mysterieus overkomt, iemand die je niet gemakkelijk kan doorgronden. Misschien lijkt deze persoon zelfs afstandelijk en ongeïnteresseerd. Neem de tijd om die persoon te benaderen, gebruik je intuïtie, misschien is hij/zij wel de liefde van je leven.</p><p>Aan de andere kant is het ook mogelijk dat de mensen in je omgeving zich erg tot je aangetrokken voelen zonder dat jij daar enige inspanning voor hoeft te doen. Wees wijs in het omgaan hiermee, trap niet in de val van oppervlakkigheid maar toon mededogen en begrip voor die ander, behandel de mensen die je benaderen met openheid en gevoel.</p>",
                        [GuidanceFields.WORK] : "<p>De Hogepriesteres wil je duidelijk maken dat je nu in een periode zit waarin je je werk moet benaderen met sereniteit en hoger bewustzijn, het is belangrijk om in het Hier en Nu te leven.</p><p>Luister in de eerste plaats naar je innerlijke stem, naar je hart, en zorg dat niets je daarvan kan afleiden. Het is belangrijk om nu eerst rustig te observeren en dan pas beslissingen te nemen. Je observaties kunnen je helpen om manieren van werken te vinden die minder stresserend zullen zijn.</p><p>Een tip: als collega’s kritiek hebben op anderen terwijl je erbij bent, doe er dan niet aan mee.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Waarschijnlijk ben je je er nu al enige tijd van bewust dat je spirituele vaardigheden en inzichten hebt. Nu komt het erop aan om te ontdekken waar je grootste kracht zit, welk aspect je het beste ligt en van daaruit verder te gaan onderzoeken, studeren en groeien.</p><p>Als je het moeilijk vindt om je eigen spiritualiteit te aanvaarden, dan is het nog belangrijker dat je gaat onderzoeken en groeien want dat is de manier om vrede te vinden met het spirituele aspect dat in je schuilt.</p>",
                    }
                },
                de : {
                    name :  "Priesterin", subtitle: "II",
                    planet : "☽ - Mond",
                    archetype : "Die Jungfrau",
                    theme : "Das intuitive Wissen",
                    description : "Die Priesterin spricht über das Verborgene, geduldig zu warten, über intuitives Wissen.",
                    questions : [ "Zögern Sie?", "Warten Sie zu?", "Was hindert Sie daran, voranzukommen?", "Wagen Sie es wirklich, auf Ihre Intuition zu hören?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die Hohepriesterin symbolisiert weibliche Werte und Stärken wie Intuition, Sensibilität, Güte, Vergebung und Vertrauen. Sie ermahnt Sie, über Ihre Gedanken und Gefühlen in aller Stille nachzudenken, damit Platz frei wird für Visionen und intuitive Erkenntnisse.</p><p>Diese Karte zeigt die unbewussten Kräfte, die in Ihnen leben, eine dunkle Seite, die wir alle haben. Falls Sie die Kraft und den Mut haben, diesen weniger bekannten Seiten zu begegnen, dann werden Sie sich nicht mehr unbewusst von ihnen führen lassen sondern frei sein, diese in vollem Umfang zu erforschen und  diese weibliche Seite vollständig für sich zu nutzen.</p><p>Jetzt ist auch die Zeit gekommen, Spürsinn und Synchronizität (z.B. Sie denken an jemanden und diese Person ruft Sie im gleichen Augenblick an) zusätzliche Aufmerksamkeit zu schenken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Persönliche Beziehungen und Liebe</p><p>Sie können Sich zu einer Person angezogen fühlen, die geheimnisvoll und unzugänglich, sogar vielleicht scheinbar distanziert und desinteressiert ist. Nehmen Sie Sich die Zeit, benutzen Sie Ihre Intuition, er/ sie kann sehr gut “der/die Ware”sein.</p><p>Auf der anderen Seite ist es auch möglich, Menschen zu treffen, die sich wirklich zu Ihnen angezogen fühlen, ohne jede Anstrengung Ihrerseits. Verwenden Sie diese Macht weise und neige nicht zur Oberflächlichkeit aber zeigen Sie Mitgefühl, Verständnis und Fürsorge.</p>",
                        [GuidanceFields.WORK] : "<p>Die Hohepriesterin macht Ihnen bewusst, dass jetzt die Zeit da ist, um Ihre Arbeit in Ruhe und bei vollem Bewusstsein zu erfahren, und tatsächlich jetzt da zu sein. Lassen Sie Sich nicht ablenken von Ihrer inneren Stimme, Ihrer Intuition und hören Sie auf Ihrem Herzen. Beobachten Sie zuerst bevor Sie Sich entscheiden. Möglicherweise werden diese Beobachtungen Ihnen helfen Ihre Arbeit leichter zu machen und damit Stress zu reduzieren.</p><p>Eine Warnung: Zurzeit wäre es unklug Sich einzumischen, wenn die Leute Ihre Kollegen in Ihrer Gegenwart beurteilen oder kritisieren.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie könnten für eine Weile bereits gewusst haben, dass Sie über spirituelle Fähigkeiten verfügen und jetzt ist die Zeit um herauszufinden, was genau Ihr Fachgebiet ist und es weiter kennenzulernen und zu wachsen. Falls Sie ein Problem haben um Ihre Spiritualität zu akzeptieren, sollten Sie versuchen durch weiterzustudieren, lernen und entwickeln, damit zurechtzukommen. Ein Mittel um dies zu erreichen wäre, mit Menschen ins Gespräch zu kommen, die über eine Menge spirituelle Erfahrung verfügen. Auch wenn einige Dinge, die sie sagen, manchmal irgendwie beängstigend sein können, werden sie Ihnen auch helfen eine höhere Ebene zu erreichen.</p>",
                    }
                }
            },
            {
                filename: "03",
                numerology : 3,
                en : {
                    name : "Empress", subtitle: "III",
                    planet : "♀ - Venus",
                    archetype : "The mother",
                    theme : "growth and development, give it space",
                    description : " The Empress  talks about growth and development, about taking good care of that what is growing and needs time and space.",
                    questions : [ "What or who needs that extra attention or space?", "Can you give it the time and space needed?", "Is this the right time?" ],
                    bulletpoints : ["Fertility", "Harmony", "Nurturing"],

                    soalPoints : [ "Levendigheid", "Overvloed", "Moeder natuur", "Weelderigheid", "Groei", "Vruchtbare bodem", "Rijkdom" ],
                    soalText : "Moeder natuur biedt je een vruchtbare bodem waarop je al je plannen kunt laten groeien en bloeien. Alles wat je wenst kun je hier tot ontwikeling brengen, er is genoeg aanwezig om het ook echt te realiseren.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Empress symbolizes fertility. She depicts the ever recurring cycle of creating new life and expresses physical and spiritual renewal. She tells you that for every kind of growth there has to be patience and nurturing, love and attention.</p><p>This card also indicates that you may feel a strong need to listen to your emotions and passions, to follow your intuition. This may attract people to you, you may find that people around you want to talk to you and seek your advice. Now is a good time to share those life lessons you learnt at some or maybe great cost.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Empress indicates change and renewal as well as vivacity, abundance and pleasant developments.</p><p>You may find yourself being very popular, people wanting to be near you, to talk to you, to seek your advice. If you are single, true love may well be within reach.</p><p>If you are in a committed relationship, it may become more fertile (figuratively and sometimes even literally speaking).</p>",
                        [GuidanceFields.WORK] : "<p>You are experiencing an endless stream of creativity and resourcefulness. You inspire people with your ideas, your approach, your energy. Listen to your innermost self to make sure your creative energy doesn’t turn into nervousness or insecurity. Financially you're on a roll, make sure to share this abundance in order to keep the flow going. Right now you can’t go wrong if you just listen to your inner voice, your intuition.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may have very strong intuition and right now this sense is even further growing and developing. Embrace this gift, it's trying to send you messages, signs. Make certain you don't miss them, they have something important to tell you.</p><p>Even though you are used to people needing you, now is the time to help yourself rather than others. It is up to you to make the time to do so.</p>",
                    }
                },
                fr : {
                    name : "Impératrice", subtitle : "III",
                    planet : "♀ - Vénus",
                    archetype : "La mère",
                    theme : "croissance et développement, donner de l'espace à",
                    description : "L’Impératrice, nous parle de croissance et de développement, de prendre soin avec amour de ce qui a besoin de croissance, de soins et d'espace.",
                    questions : ["Quoi ou qui a besoin de plus d'attention ou d'espace? ", " Pouvez-vous donner cet espace et ce temps? ", " Est-ce le moment propice? "],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>L’impératrice symbolise la fertilité. Elle représente le cycle récurrent de la création d'une vie nouvelle et représente le renouvellement physique et spirituel. Elle vous dit que toute forme de croissance demande, patience, s’occuper, amour et attention.</p><p>Cette carte montre aussi que vous avez peut-être un fort besoin d'écouter vos sentiments, vos passions et de suivre votre intuition. Ce besoin pourrait attirer les gens vers vous. Vous apprendrez peut-être que les gens autour de vous, veulent vous parler et vous demander conseil. C'est le bon moment pour partager les leçons de la vie que vous avez apprise à un moment donné et peut-être même à un prix élevé.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Empress indicates change and renewal as well as vivacity, abundance and pleasant developments.</p><p>You may find yourself being very popular, people wanting to be near you, to talk to you, to seek your advice. If you are single, true love may well be within reach.</p><p>If you are in a committed relationship, it may become more fertile (figuratively and sometimes even literally speaking).</p>",
                        [GuidanceFields.WORK] : "<p>You are experiencing an endless stream of creativity and resourcefulness. You inspire people with your ideas, your approach, your energy. Listen to your innermost self to make sure your creative energy doesn’t turn into nervousness or insecurity. Financially you're on a roll, make sure to share this abundance in order to keep the flow going. Right now you can’t go wrong if you just listen to your inner voice, your intuition.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may have very strong intuition and right now this sense is even further growing and developing. Embrace this gift, it's trying to send you messages, signs. Make certain you don't miss them, they have something important to tell you.</p><p>Even though you are used to people needing you, now is the time to help yourself rather than others. It is up to you to make the time to do so.</p>",
                    }
                },
                sp:{
                    name : "Emperatriz", subtitle: "III",
                    planet: "♀ - Venus",
                    archetype: "La madre",
                    theme: "crecimiento y desarrollo, dale espacio",
                    description: " La Emperatriz trata sobre el crecimiento y el desarrollo, sobre el cuidado amoroso de aquello que necesita crecimiento, cuidado y espacio",
                    questions: ["¿Qué o quién necesita más atención o espacio?", "¿Puedes darle ese espacio y ese tiempo?", "¿Es el tiempo justo?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La Emperatriz simboliza la fertilidad. Ella representa el ciclo nunca se repite de crear nueva vida y expresa la renovación física y espiritual. Ella le dice que para cada tipo de crecimiento que tiene que haber paciencia y cariño, amor y atención. Esta tarjeta también indica que usted puede sentir una fuerte necesidad de escuchar a sus emociones y pasiones, a seguir su intuición. Esto puede atraer a la gente a usted, usted puede encontrar que las personas que te rodean quieren hablar con usted y buscar su consejo. Ahora es un buen momento para compartir esas lecciones de vida que aprendió en algún o tal vez un gran costo.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name :  "Keizerin", subtitle: "III",
                    planet : "♀ - Venus",
                    archetype : "De moeder",
                    theme : "groei en ontwikkeling, geef het de ruimte",
                    description : "De Keizerin gaat over groei en ontwikkeling, over het liefdevol zorg dragen voor dat wat groei, zorg en ruimte behoeft.",
                    questions : [ "Wat of wie heeft extra aandacht of ruimte nodig?", "Kun jij die ruimte en tijd geven?", "Is het nu de jusite tijd?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Keizerin staat voor vruchtbaarheid. Ze toont je de steeds weerkerende cirkel van creatie van nieuw leven en staat dus voor lichamelijke en spirituele vernieuwing. Ze vertelt je dat er voor groei altijd nood zal zijn aan geduld en koesteren, liefde en aandacht.</p><p> Als je deze kaart trekt dan is het waarschijnlijk zo dat je een grote behoefte hebt om naar je emoties en passies te luisteren, om je intuïtie te volgen. Het is goed mogelijk dat deze instelling je extra interessant maakt voor de mensen om je heen. Je zal merken dat mensen met je willen praten, je om goede raad vragen. Daarom is het nu het ideale moment om de levenslessen die je vergaard hebt ook weer door te geven, levenslessen die je soms behoorlijk wat gekost hebben voor je ze echt doorhad. Laat anderen meegenieten van je wijsheid!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Keizerin wijst op verandering en vernieuwing maar ook op levendigheid, overvloed en positieve ontwikkelingen.</p><p>Het is goed mogelijk dat je op dit moment erg populair bent, mensen vinden het fijn om in je gezelschap te zijn, ze willen met je praten, je advies inwinnen. Als je op dit ogenblik geen relatie hebt, dan is dit een goede periode om de ware liefde te vinden.</p><p>Als je een vaste relatie hebt dan zal deze relatie alsmaar vruchtbaarder worden (in figuurlijke zin maar mogelijk ook letterlijk).</p>",
                        [GuidanceFields.WORK] : "<p>Op dit ogenblik in je leven ervaar je een eindeloze stroom van creativiteit en vindingrijkheid. Je inspireert de mensen om je heen door je aanpak, je ideeën, je energie. Luister heel goed naar je diepste zelf want het risico bestaat dat deze creatieve energie zou omslaan in zenuwachtigheid en onzekerheid. Financieel verloopt alles heel vlot, zorg ervoor dat je je eigen overvloed ook deelt met anderen zodat alles blijft stromen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment heb je waarschijnlijk een heel sterke intuïtie die zelfs nog aan het groeien en ontwikkelen is. Sluit dit geschenk in je armen, geniet ervan en luister ernaar, want het is dé manier om tekenen en boodschappen door te krijgen. Let heel goed op, mis niets, want het universum heeft je een aantal belangrijke dingen te vertellen.</p><p>Ook al ben je eraan gewend dat andere mensen je nodig hebben en op je steunen, op dit moment moet je in de eerste plaats voor jezelf zorgen en dan pas aan anderen denken.</p>",
                    }
                },
                de : {
                    name : "Kaiserin", subtitle: "III",
                    planet : "♀ - Venus",
                    archetype : "Die Mutter",
                    theme : "Wachstum und Entwicklung, Raum geben",
                    description : "Die Kaiserin spricht über Wachstum und Entwicklung, über die liebevolle Pflege dessen, was Wachstum, Pflege und Raum braucht.",
                    questions : [ "Was oder wer braucht mehr Aufmerksamkeit oder Raum?", "Können Sie diesen Raum und diese Zeit geben?", "Ist das der richtige Zeitpunkt?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die Herrscherin symbolisiert Fruchtbarkeit. Sie steht für den immer wiederkehrenden Zyklus der Schöpfung neuen Lebens und vertritt körperliche und geistige Erneuerung. Sie sagt Ihnen, dass für jede Art von Wachstum Geduld, Pflege, Liebe und Aufmerksamkeit erforderlich sind.</p><p>Diese Karte zeigt auch, dass Sie ein starkes Bedürfnis haben können, auf Ihre Gefühle und Ihre Leidenschaften zu hören und Ihrer Intuition zu folgen. Dieses Bedürfnis könnte sein, Menschen für sich zu gewinnen. Sie erfahren möglicherweise, dass die Menschen in Ihrer Umgebung mit Ihnen reden möchten und Sie gerne um Ihren Rat fragen. Jetzt ist ein guter Zeitpunkt, um die Lektionen des Lebens zu teilen, die Sie irgendwann und vielleicht sogar zu einem hohen Preis gelernt haben.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Persönliche Beziehungen und Liebe</p><p>Die Herscherin deutet auf Veränderung und Erneuerung sowie Lebendigkeit, Fülle und angenehme Entwicklungen.</p><p>Sie können feststellen, dass Sie sehr beliebt sind und dass die Leute in Ihrer Nähe sein wollen und mit Ihnen sprechen möchten, um Ihren Rat zu bitten. Wenn Sie alleine sind, kann die wahre Liebe sich auch innerhalb Ihrer Reichweite befinden.</p><p>Wenn Sie eine feste Beziehung haben, kann diese fruchtbarer werden (manchmal sogar buchstäblich).</p>",
                        [GuidanceFields.WORK] : "<p>Sie erleben einen endlosen Strom an Kreativität und Einfallsreichtum. Sie begeistern die Menschen mit Ihren Ideen, Ihrem Ansatz, Ihrer Energie. Hören Sie auf Ihre inneren Selbst um sicherzustellen, dass Ihre kreative Energie sich nicht in Nervosität oder Unsicherheit umwandelt. Finanziell befinden Sie Sich in Gleichgewicht. Stellen Sie Sich sicher diesen Überfluss zu teilen, damit die Strömung weiter fließen kann. In diesem Moment können Sie nichts falsch machen, wenn Sie nur auf Ihrer inneren Stimme, Ihrer Intuition hören.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie können jetzt eine sehr starke Intuition haben die noch weiter wachsen und sich entwickeln kann. Umfassen Sie dieses Geschenk, es versucht Sie ein Zeichen zu geben. Stellen Sie sicher, dass Sie es nicht verpassen. Es könnte Ihnen etwas Wichtiges zu sagen haben.</p><p>Auch wenn Sie daran gewöhnt sind, dass Menschen Sie brauchen, ist jetzt die Zeit, um Sich Selber zuerst zu helfen, mehr als jetzt andere Leute. Es liegt an Ihnen, dies der Zeit zu überlassen.</p>",
                    }
                }
            },
            {
                filename: "04",
                numerology : 4,
                en : {
                    name : "Emperor", subtitle: "IV",
                    bulletpoints : ["Structure","Authority","Power"],
                    zodiac : "♈ - Aries",
                    archetype : "The father",
                    theme : "dealing with structure",
                    description : " The Emperor talks about structure, justice and order, defining and upholding boundaries, directing your own life (your own kingdom).", 
                    questions : [ "Are you being clear?", "Do you uphold your boundaries?", "Do you need to put structures in place?", "Could you have too many structures at hand?" ],

                    soalPoints : [ "Structuur", "Vaste vorm", "Nuchterheid", "Realiteit", "Verstrarring", "Leider", "Beperken", "Praktisch" ],
                    soalText : "Structuur is datgene wat je nodig hebt om zaken een bepaalde vorm te geven. Hierdoor komt er orde en overzicht. Het verwezenlijken van je wensen kun je doen door jezelf geen illusies te maken, consequent te zijn en spijkers met koppen te slaan. Draag daarbij de verantwoording en concentreer je op dat wat aanwezig is.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Emperor doesn't leave any room for coincidence. Stability, security, structure, rigidity and perfectionism are the values he stands for. He depicts man's struggle for independence, his intent to place himself above the rules of nature and maybe going a bit overboard. Still, The Emperor offers you valuable qualities such as responsibility and pragmatism.</p><p>If you draw this card the time may be right for putting rationality over emotion. When you are confronted with hard decisions and difficult choices, it is often wise to stay focused, to use your brain.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In personal relationships you are a stable, secure and trustworthy person, but you have to remember that good relationships need maintaining. If you don’t take care of a relationship it will get an expiration date before you know it. Accept changes and try to be flexible and patient, if you don’t you willrun the risk of becoming overbearing and rigid. </p>",
                        [GuidanceFields.WORK] : "<p>The Emperor's favorite task is creating order out of chaos. He is steadfast, logical and determined. If you are looking for a job it is very important to try and find out how others perceive you, to analyze (and even write down) the reasons why they should choose you for a certain position. It is up to you to convince them of your unique qualities. No matter what your job is, to succeed now you'll need discipline and dedication. </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You tend to approach everyday life with logic and reason but you also have a strong need for spiritual development and growth you sometimes neglect. Remember that spirituality can benefit from a logical approach.Remember that logic and spirit are not contradictory. Actually, they are both an important and integral part of who you are.</p>",
                    }
                },
                fr : {
                    name : "Empereur", subtitle : "IV",
                    zodiac : "♈ - Bélier",
                    archetype : "Le père",
                    theme : "gérer la structure",
                    description : "L'empereur nous parle d’une question de structure, d'ordre et d'autorité, de limites et de garde, de diriger sa propre vie (votre propre empire).", 
                    questions : ["Êtes-vous clair?", "Gardez-vous vos propres limites?", "Êtes-vous sensé indiquer une structure?", "Êtes-vous peut-être trop structuré?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>L’empereur ne quitte pas une pièce par accident. Stabilité, sécurité, structure, fermeté et perfectionnisme sont les valeurs qu'il représente. Il montre la lutte de l'homme pour l'indépendance, se plaçant au-dessus des règles de la nature et peut-être même en allant un peu trop loin. Néanmoins, l’empereur vous offre des qualités précieuses telles que la responsabilité et le pragmatisme.</p><p>Si vous tirez cette carte, il est peut-être temps de mettre la raison avant l'émotion. Lorsque vous êtes confronté à des décisions dures et difficiles, il est souvent conseillé d'être rationnel et concentré afin de pouvoir agir raisonnablement.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In personal relationships you are a stable, secure and trustworthy person, but you have to remember that good relationships need maintaining. If you don’t take care of a relationship it will get an expiration date before you know it. Accept changes and try to be flexible and patient, if you don’t you willrun the risk of becoming overbearing and rigid. </p>",
                        [GuidanceFields.WORK] : "<p>The Emperor's favorite task is creating order out of chaos. He is steadfast, logical and determined. If you are looking for a job it is very important to try and find out how others perceive you, to analyze (and even write down) the reasons why they should choose you for a certain position. It is up to you to convince them of your unique qualities. No matter what your job is, to succeed now you'll need discipline and dedication. </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You tend to approach everyday life with logic and reason but you also have a strong need for spiritual development and growth you sometimes neglect. Remember that spirituality can benefit from a logical approach.Remember that logic and spirit are not contradictory. Actually, they are both an important and integral part of who you are.</p>",
                    }
                },
                sp:{
                    name : "Emperador", subtitle: "IV",
                    zodiac: "♈ - Carnero",
                    archetype: "El padre",
                    theme: "cómo tratar con la estructura",
                    description: "El Emperador es sobre la estructura, sobre el orden y la autoridad, sobre establecer y guardar límites, sobre gobernar tu propia vida (tu propio imperio)", 
                    questions: ["¿Estás claro?", "¿Estás protegiendo sus propios límites", " ¿Estás quizás demasiado estructurado?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Emperador no deja lugar a la casualidad. La estabilidad, la seguridad, la estructura, la rigidez y el perfeccionismo son los valores que representa. Él representa la lucha del hombre por la independencia, su intención de colocar a su mismo por encima de las reglas de la naturaleza y tal vez ir un poco por la borda. Aún así, el emperador le ofrece valiosas cualidades como la responsabilidad y el pragmatismo. Si saca esta tarjeta el tiempo puede ser adecuado para poner racionalidad sobre la emoción. Cuando uno se enfrenta con decisiones difíciles y opciones difíciles, a menudo es aconsejable mantener la concentración, para usar su cerebro.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name :  "Keizer", subtitle: "IV",
                    zodiac : "♈ - Ram",
                    archetype : "De vader",
                    theme : "omgaan met structuur",
                    description : "De keizer gaat over structuur, over orde en gezag, over grenzen stellen en bewaken, over het regiseren van je eigen leven (je eigen keizerrijk)", 
                    questions : [ "Bij jij duidelijk?", "Bewaak jij je eigen grenzen?", "Dien jij structuur aan te geven?", "Ben jij wellicht te gestructureerd?" ], 
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Keizer laat geen ruimte voor toeval.</p><p> Stabiliteit, nuchterheid, structuur, ernst en perfectionisme zijn de waarden waar hij voor staat. Geef jij deze eigenschappen voldoende ruimte in je leven? De Keizer symboliseert ook de strijd voor onafhankelijkheid, het doel dat de mens nastreeft om boven de wetten van de natuur uit te stijgen. Misschien overdrijft hij hier zelfs een beetje in. Toch heeft De Keizer waardevolle eigenschappen te bieden zoals verantwoordelijkheid en rationaliteit.</p><p>Als je deze kaart kiest dan is de tijd waarschijnlijk rijp om eerder verstandelijk dan emotioneel te werk te gaan. Als je nu zware beslissingen moet nemen, moeilijke keuzes moet maken, dan is het verstandig om rationeel en gefocust te zijn, om je hersenen te gebruiken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In persoonlijke relaties ben je een stabiel, zelfzeker en betrouwbaar iemand. Probeer wel te onthouden dat echt goede relaties ook onderhouden nodig hebben, ze blijven niet vanzelf goed. In een relatie moet je investeren maar ook meegroeien, evolueren. Aanvaard veranderingen en probeer soepel en geduldig te zijn, anders loop je het risico dat je te dominant en streng wordt.</p>",
                        [GuidanceFields.WORK] : "<p>De favoriete bezigheid van De Keizer is om orde te scheppen vanuit chaos. Hij is standvastig, logisch en vastberaden. Als je op zoek bent naar werk dan is het erg belangrijk dat je nu aandacht besteedt aan de manier waarop anderen naar je kijken, je ervaren. Analyseer de redenen waarom ze voor jou zouden moeten kiezen in verband met een bepaalde job. Het is nu aan jou om hen te overtuigen van je unieke kwaliteiten.</p><p>Het maakt niet uit welk werk je doet, om nu succes te hebben, heb je discipline en toewijding nodig. Let er ook op dat je gestructureerd te werk gaat.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je hebt de neiging om het dagelijkse leven aan te pakken met logica en redeneren, maar je hebt ook een sterke behoefte aan spirituele ontwikkeling en groei en die behoefte verwaarloos je soms. Onthoud goed dat je spiritualiteit ook op een logische manier kan benaderen, logica en “spirit” zijn niet met mekaar in tegenspraak. Integendeel, ze zijn beide een belangrijk onderdeel van wie jij bent en je kan ze gecombineerd gebruiken om spiritueel te groeien.</p>",
                    }
                },
                de : {
                    name: "Herrscher", subtitle: "IV",
                    zodiac : "♈ - Widder",
                    archetype : "Der Vater",
                    theme : "Die Struktur verwalten",
                    description : "Der Herrscher spricht über die Frage von Struktur, Ordnung und Autorität, Grenzen und Schutz, um sein eigenes Leben (sein eigenes Reich) zu lenken",
                    questions : [ "Sind Sie im Klaren?", "Halten Sie Ihre eigenen Grenzen ein?", "Sollten Sie eine Struktur bestimmen?", "Sind Sie vielleicht zu strukturiert?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der Herrscher verlässt keinen Raum zufällig. Stabilität, Sicherheit, Struktur, Festigkeit und Perfektionismus sind die Werte, für die er steht. Er zeigt den Kampf des Menschen um Unabhängigkeit, wobei er sich über die Regeln der Natur stellt und dabei vielleicht auch ein bisschen übertreibt. Trotzdem bietet der Herrscher Ihnen wertvolle Qualitäten wie Verantwortung und Pragmatismus an.</p><p>Wenn Sie diese Karte ziehen kann es an der Zeit sein,  die Vernunft über das Gefühl zu stellen. Wenn Sie mit harten und schwierigen Entscheidungen konfrontiert sind, ist es oft ratsam, rational und konzentriert zu sein, um vernünftig entscheiden und handeln zu können.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Persönliche Beziehungen und Liebe</p><p>In persönlichen Beziehungen Sie sind eine stabile, sichere und vertrauenswürdige Person, aber Sie müssen bedenken, dass gute Beziehungen Pflege brauchen. Wenn Sie Sich nicht kümmern um eine Beziehung, wird sie zu Ende gehen bevor Sie es wissen. Akzeptieren Sie Änderungen und versuchen Sie biegsam und geduldig zu sein. Sonst besteht die Gefahr, dass Sie anmaßend und starr werden.</p>",
                        [GuidanceFields.WORK] : "<p>Lieblingsaufgabe des Herschers ist Ordnung aus Chaos zu schaffen. Er ist standhaft, logisch und entschlossen. Wenn Sie auf der Suche nach einem Job sind ist es sehr wichtig zu versuchen um herauszufinden, wie andere Sie wahrnehmen, um die Gründe zu analysieren (und sogar aufzuschreiben), warum sie Sie für eine bestimmte Position auswählen sollten. Es liegt an Ihnen, sie von Ihren einzigartigen Qualitäten zu überzeugen. Egal, was Ihr Beruf jetzt auch ist, um erfolgreich zu sein, müssen Sie über Disziplin und Hingabe verfügen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie neigen dazu, den Alltag mit Logik und Vernunft zu betrachten aber Sie haben auch ein starkes Bedürfnis nach spiritueller Entwicklung und Wachstum, die Sie manchmal vernachlässigen. Denken Sie daran, dass ein logischer Ansatzt die Spiritualität nutzen kann. Logik und Geist sind nicht miteinander in Widerspruch. Eigentlich sind sie beide wichtige und integrale Teile Ihres Seins.</p>",
                    }
                }
            },
            {
                filename: "05",
                numerology : 5,
                en : {
                    name : "Hierophant", subtitle: "V",
                    bulletpoints : ["Knowledge","Wisdom","Dogma"],
                    zodiac : "♉ - Taurus",
                    archetype : "The teacher",
                    theme : "gaining and passing on knowledge",
                    description : "The Hierophant talks about learning and teaching, finding your individuality, norms and values, learning (life) lessons.", 
                    questions : [ "Are you learning a (life) lesson?", "Which problem are you encountering time and again?", "Can you communicate about it?" ], 

                    soalPoints : [ "Vertrouwen", "Diep geloof", "Innerlijke leraar", "Religie", "Wijsheid in spirituele zaken", "Godsdienst", "Traditie", "Rituelen" ],
                    soalText : "Geloven is een overtuiging die je hebt en het weten dat deze ook is zoals jij denkt dat hij is. Het kan je kracht geven om door te gaan, houvast in moeilijke tijden en zekerheid door vertrouwen.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is sometimes called The High Priest. It symbolizes faith and profound trust. Faith is a matter of heart and soul, one doesn't need proof. Faith is founded on deep inner knowledge, and gives one great trust and inner peace.</p><p>The Hierophant stands for trust, self-confidence and belief in the future. It symbolizes a clear and distinct awareness of what's right and wrong. Still, maybe you'll ask yourself if what's generally right is also the right thing to do for you personally.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Hierophant teaches you that the trust and affection you feel towards others is slowly building which indicates that your relationships are growing and intensifying. If you are involved in a romantic relationship it will very likely be growing stronger, more profound. You're becoming more aware of your ideals and principles and your (personal) virtues will be defining your behavior. </p>",
                        [GuidanceFields.WORK] : "<p>In your work you are looking for depth, you are trying to find out if your job is really what you want to be doing in your heart of hearts. Still, The Hierophant may confront you with a dilemma: is it time to go looking for a different job or career, or should you be loyal and remain where you are?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Even if you are not a religious person, right now you are focused on spirituality. It's possible that your vision of spirituality conflicts with opinions and views of others around you. To find inner balance, it might be a good idea to afford yourself the time to meditate and try and create new rituals, these will bring you structure and harmony. </p>",
                    }
                },
                fr : {
                    name : "Hierophant", subtitle : "V",
                    zodiac : "♉ - Taureau",
                    archetype : "L'enseignant",
                    theme : "acquérir et transmettre la connaissance",
                    description : "Le Pape, nous parle d’apprendre et d’apprendre de, de (re)trouver son individualité, ses valeurs et ses normes, d’apprendre des leçons (de vie).", 
                    questions : ["Apprenez-vous une leçon (de vie)?", "Quel problème rencontrez-vous à chaque fois?", "Pouvez-vous communiquer à propos de ce sujet?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte est aussi connue sous le nom de Grand Prêtre. Il symbolise la foi et la confiance profonde. La foi est une question de cœur et d'âme et n'a besoin d'aucune preuve. La foi est basée sur une profonde connaissance intérieure et offre une grande confiance et la paix intérieure.</p><p>L'Hiérophante représente exactement cette confiance, confiance en soi et confiance en l'avenir. Il symbolise une conscience claire et sans ambiguïté de ce qui est bien et de ce qui est mal. Néanmoins, il peut être judicieux de vous demander si ce qui serait normalement bien pour vous est vraiment bon pour vous personnellement. Après tout, chaque situation est unique en son genre.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Hierophant teaches you that the trust and affection you feel towards others is slowly building which indicates that your relationships are growing and intensifying. If you are involved in a romantic relationship it will very likely be growing stronger, more profound. You're becoming more aware of your ideals and principles and your (personal) virtues will be defining your behavior. </p>",
                        [GuidanceFields.WORK] : "<p>In your work you are looking for depth, you are trying to find out if your job is really what you want to be doing in your heart of hearts. Still, The Hierophant may confront you with a dilemma: is it time to go looking for a different job or career, or should you be loyal and remain where you are?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Even if you are not a religious person, right now you are focused on spirituality. It's possible that your vision of spirituality conflicts with opinions and views of others around you. To find inner balance, it might be a good idea to afford yourself the time to meditate and try and create new rituals, these will bring you structure and harmony. </p>",
                    }
                },
                sp:{
                    name:"Sumo Sacerdote", subtitle: "V",
                    zodiac: "♉ - Toro",
                    archetype: "El maestro",
                    theme: "adquisición y transmisión de conocimientos",
                    description: "El Sumo Sacerdote se trata de aprender á y aprender de encontrar la individualidad de uno mismo, de valores y normas, de aprender lecciones (de la vida)", 
                    questions: ["¿Estás aprendiendo una lección (de vida)?", "¿con qué te encuentras siempre? ", "¿Puedes comunicarte al respecto?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es a veces llamado el sumo sacerdote. Simboliza la fe y la confianza profunda. La fe es un asunto del corazón y el alma, uno no necesita pruebas. La fe se basa en el conocimiento interior profundo, y le da una gran confianza y la paz interior. El Hierofante representa la confianza, la confianza en su mismo y la fe en el futuro. Simboliza una conciencia clara y distinta de lo que es correcto y lo incorrecto. Aún así, tal vez usted se pregunta si lo que es generalmente correcto es también lo que hay que hacer por usted personalmente.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name :  "Hiërophant", subtitle: "V",
                    zodiac : "♉ - Stier",
                    archetype : "De leraar",
                    theme : "kennis opdoen en doorgeven",
                    description : "De hogepriester gaat over leren aan en leren van, over het vinden van je eigenheid, over waarden en normen, over het leren van (levens) lessen", 
                    questions : [ "Ben jij een (levens) les aan het leren?", "Waar loop je steeds tegenaan?", "Kun je er over communiceren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart wordt soms ook De Hiërophant genoemd. Ze staat voor geloof en diep vertrouwen. Geloof speelt zich af in je hart en je ziel, je hebt geen bewijzen nodig. Geloof heeft een diep innerlijk weten als basis, en je krijgt er intens vertrouwen en innerlijke vrede door.</p><p>De Hogepriester staat voor vertrouwen, zelfvertrouwen en geloof in de toekomst. Hij staat ook voor een duidelijk en scherp bewustzijn van wat goed en fout is.</p><p>Als je hiermee geconfronteerd wordt in jouw leven, sta er dan toch even bij stil of datgene wat in het algemeen als goed beschouwd wordt, ook het juiste is voor jou. Tenslotte zijn alle situaties, alle te nemen beslissingen uniek.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Hogepriester leert je dat het vertrouwen en de genegenheid die je voelt voor anderen geleidelijk aan het vermeerderen zijn. Dit betekent dat je relaties nu groeien en meer diepgang krijgen. Als je op het ogenblik een liefdesrelatie hebt dan is het erg waarschijnlijk dat de relatie aan het evolueren is, intenser aan het worden is. Je wordt je ook steeds meer bewust van je idealen en principes en je persoonlijke deugden en waarden zullen je gedrag steeds meer gaan bepalen.</p>",
                        [GuidanceFields.WORK] : "<p>Je bent in je werk op zoek naar diepgang. Je bent aan het uitzoeken of de baan die je nu hebt echt wel datgene is wat je heel diep vanbinnen wil doen. Toch kan De Hogepriester je ook confronteren met een dilemma: is het tijd om een andere baan of carrière te overwegen of is het beter om loyaal te zijn en te blijven waar je nu bent? Vertrouw op jezelf èn op het universum, dan komt de juiste beslissing vanzelf in je op.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Zelfs als je geen religieus persoon bent, je zal toch merken dat je op het ogenblik nogal gefocust bent op het spirituele. Ook is het beste mogelijk dat het beeld dat jij hebt van spiritualiteit indruist tegen de meningen en visies van anderen. Om innerlijk evenwicht te vinden is het belangrijk dat je jezelf de tijd gunt om te mediteren en nieuwe rituelen voor jezelf te creëren. Als je dit doet dan zal je merken dat je je leven structureel en op een harmonieuze manier verrijkt.</p>",
                    }
                },
                de : {
                    name :  "Hierophant", subtitle: "V",
                    zodiac : "♉ - Stier",
                    archetype : "Der Lehrmeister",
                    theme : "Wissen erwerben und vermitteln",
                    description : "Der Hierophant spricht über das Lernen generell,  über das Lernen, unsere Individualität, seine Werte und Normen (wieder) zu finden, um (Lebens-) Lektionen zu lernen.", 
                    questions : [ "Lernen Sie eine (Lebens-)Lektion?", "Welches Problem treffen Sie jedes Mal an?", "Können Sie über dieses Thema kommunizieren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte wird auch als der Hohepriester bezeichnet. Sie symbolisiert Glauben und tiefes Vertrauen. Der Glaube ist eine Sache des Herzens und der Seele, und braucht keinen Beweis. Der Glaube ist auf tiefem, innerem Wissen begründet und bietet großes Vertrauen und inneren Frieden.</p><p>Der Hierophant steht für genau dieses Vertrauen, Selbstbewusstsein und Vertrauen in die Zukunft. Er symbolisiert ein klares und deutliches Bewusstsein davon, was richtig und falsch ist. Trotzdem kann es sinnvoll sein, sich zu fragen, ob das, was generell richtig scheint, auch tatsächlich für Sie persönlich richtig ist. Schließlich ist jede Situation auf ihre eigene Weise einzigartig.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Der Hierophant lehrt Sie, dass Vertrauen und Zuneigung anderen gegenüber sich langsam aufbauen und Ihre Beziehungen wachsen und intensiver werden. Wenn Sie in einer romantischen Beziehung beteiligt sind, wird diese sich sehr wahrscheinlich weiter vertiefen und verstärken. Sie sind sich zunehmend bewusst von Ihren Idealen und Grundsätzen und Ihre (persönlichen) Tugenden werden Ihr Verhalten bestimmen.</p>",
                        [GuidanceFields.WORK] : "<p>In Ihrer Arbeit werden Sie Tiefe suchen. Sie werden versuchen herauszufinden, ob Ihr Job wirklich den ist, was Sie in Ihrem tiefsten Herzen tun möchten. Dennoch kann der Hierophant Sie mit einem Dilemma konfrontieren: ist es Zeit auf der Suche zu gehen nach einem anderen Job oder einer neuen Karriere, oder sollten Sie treu sein und bleiben, wo Sie sind?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Auch wenn Sie nicht ein religiöser Mensch sind, sind Sie im Augenblick auf Spiritualität konzentriert. Es ist wohl möglich, dass Sie Ihre Meinung über spirituelle Konflikte sich stößt mit Meinungen und Ansichten der Leute in Ihrer Umgebung. Um das innere Gleichgewicht zu finden, könnte es eine gute Idee sein, Sich die Zeit zu nehmen zum Meditieren und damit zu versuchen neue Rituale zu schaffen die Ihnen neue Struktur und Gleichgewicht bringen könnten.</p>",
                    }
                }
            },
            {
                filename: "06",
                numerology : 6,
                en : {
                    name : "Lovers", subtitle: "VI",
                    bulletpoints : ["Unity","Harmony","Love"],
                    zodiac : "♊ - Gemini",
                    archetype : "The choice",
                    theme : "follow your hearts’ desire",
                    description : "The Lovers talk about making free choices, following your hearts’ desire, uniting the male and female.", 
                    questions : [ "Do you need to make a choice?", "Is it your choice?", "Are you following your hearts’ desire in order to make yourself feel good?" ], 

                    soalPoints : [ "Keuze van het hart", "Volmondig ja-zeggen", "Beslissing", "Liefde", "Liefdeservaring", "Verbinding" ],
                    soalText : "Keuze van het hart is dat waar je voor kiest met alles wat je in je hebt. Er zijn hierbij geen alternatieven die openblijven en ook geen voorbehouden. Het is een volledig ja-zeggen tegen die ene persoon of situatie.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Lovers symbolizes love being a higher cause between two people. Attraction, love, friendship, uniting opposites … when two people are mirroring eachother, these feelings heighten their consciousness.</p><p>Sometimes this card represents the doubts you have when being confronted with difficult choices. Often, when you have to choose, it is hard to decide if you should listen to your heart or to your head, because both may have valid points. The Lovers gives you the wisdom and courage to choose wholeheartedly. That is why this card is also called \"The Decision\".</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Lovers confronts you with the fact that there's a great love in your life. This love deeply touches you and you will have to make a choice. When you choose for this love then you have to make sure you do so with all your heart and soul. You have to be convinced you're making the right choice. The Lovers motivates you to be truly honest with yourself, to realize there are no fairytales, no knights in shining armor. When choosing for love, you accept the other person as is, without reservations.</p>",
                        [GuidanceFields.WORK] : "<p>The Lovers makes you realize you will have to make a decision. Maybe it's time to change jobs or careers, maybe there are some aspects of your current job that need changing. Analyze all available information before you choose, when looking more closely, a negative may turn into a positive.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card tells you the time may have come to start a spiritual relationship and probably the right person has already appeared in your life. Take your time to study the wisdom this person shares with you, but remember to question everything while listening to yourself. Do not blindly follow, but keep filtering all information by running it by your intuition.</p>",
                    }
                },
                fr : {
                    name : "Les amoureux ", subtitle : "VI",
                    zodiac : "♊ - Gémeaux",
                    archetype : "Le choix",
                    theme : "suivre le choix de votre cœur",
                    description : "Les amoureux, nous parle de choisir en toute liberté, de suivre le choix de son cœur, d’ unir le masculin et le féminin.", 
                    questions : ["Devez-vous faire un choix?", "Choisissez vous-même?", "Suivez-vous votre cœur afin de vous sentir bien?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte \"Les Amoureux\" fait souvent référence à une relation intense que vous avez avec quelqu'un. C'est généralement une relation intime et romantique. Il symbolise l'amour comme une affaire supérieure entre deux personnes. L’attraction, le coup de foudre, l'amitié, la tendance à unir les opposés quand deux personnes se reflètent. Ce sentiment favorise la prise de conscience, la perspicacité et la croissance.</p><p>Parfois, cette carte représente aussi le doute et l'ambivalence que vous ressentez lorsque vous êtes confronté à des décisions difficiles. Lorsque vous devez prendre une décision, il est souvent difficile de déterminer si vous devez écouter votre cœur ou votre raison, car les deux sont précieux. \"Les amoureux\" vous demandent de décider de tout votre cœur pour la sagesse et le courage. Pour cette raison, cette carte sera également appelée « la Décision ».</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Lovers confronts you with the fact that there's a great love in your life. This love deeply touches you and you will have to make a choice. When you choose for this love then you have to make sure you do so with all your heart and soul. You have to be convinced you're making the right choice. The Lovers motivates you to be truly honest with yourself, to realize there are no fairytales, no knights in shining armor. When choosing for love, you accept the other person as is, without reservations.</p>",
                        [GuidanceFields.WORK] : "<p>The Lovers makes you realize you will have to make a decision. Maybe it's time to change jobs or careers, maybe there are some aspects of your current job that need changing. Analyze all available information before you choose, when looking more closely, a negative may turn into a positive.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card tells you the time may have come to start a spiritual relationship and probably the right person has already appeared in your life. Take your time to study the wisdom this person shares with you, but remember to question everything while listening to yourself. Do not blindly follow, but keep filtering all information by running it by your intuition.</p>",
                    }
                },
                sp:{
                    name : "Amantes", subtitle: "VI",
                    zodiac: "♊ - Gemelos",
                    archetype: "La elección",
                    theme: "sigue la elección de tu corazón",
                    description: "Los Enamorados se trata de elegir desde la libertad, de seguir la elección de tu corazón, de unir lo masculino y lo femenino", 
                    questions: ["¿Deberías elegir?", "¿Te eliges a ti mismo?", "¿Sigues a tu corazón para que se sienta bien por ti?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Los amantes simbolizan el amor de ser una causa más elevada entre dos personas. Atracción, amor, amistad, uniendo los opuestos ... cuando dos personas están reflejando entre sus mismos, estos sentimientos aumentan su conciencia. A veces, esta carta representa las dudas que usted tiene cuando se enfrenta a decisiones difíciles. A menudo, cuando usted tiene que elegir, es difícil decidir si debe escuchar a su corazón o a la cabeza, ya que ambos pueden tener puntos válidos. Los amantes le dan la sabiduría y el coraje para elegir de todo corazón. Es por ello que esta tarjeta también se llama \"La Decisión\".</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name :  "Geliefden", subtitle: "VI",
                    zodiac : "♊ - Tweelingen",
                    archetype : "De keuze",
                    theme : "de keuze van je hart volgen",
                    description : "De geliefden gaat over kiezen vanuit vrijheid, over de keuze van je hart volgen, over het verenigen van het mannelijke en het vrouwelijke.", 
                    questions : [ "Dien jij een keuze te maken?", "Kies jij zelf?", "Volg jij je hart, zodat het voor jou goed voelt?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart, De Geliefden, gaat bijna altijd over een intieme, sterke relatie tussen twee personen en gaat dus vaak over een liefdesrelatie. De kaart symboliseert dan deze liefde als een belangrijk doel. Aantrekkingskracht, liefde, vriendschap, het verenigen van tegengestelden ... als twee mensen mekaar spiegelen dan kunnen deze gevoelens hun bewustzijn (en daardoor ook hun groei en inzichten) sterk verhogen.</p><p>Soms staat deze kaart ook voor de twijfels die je kunnen overvallen als je geconfronteerd wordt met belangrijke keuzes. Als je moet kiezen dan is het vaak moeilijk om te beslissen of je naar je hart of naar je hoofd moet luisteren. Beide hebben immers hun eigen unieke standpunten en kwaliteiten.</p><p>Als je De Geliefden trekt, dan belooft deze kaart je de wijsheid en moed om met volle overtuiging je keuze te maken. Daarom noemt men deze kaart ook soms “De Keuze”.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Geliefden confronteert je met de realiteit van een grote, diepe liefde in je leven. Je wordt intens geraakt door deze liefde en je zal een keuze moeten maken. Als je besluit om voor deze liefde te kiezen, zorg er dan voor dat je het ook echt doet met hart en ziel. Je moet er helemaal van overtuigd zijn dat je de juiste keuze maakt. De Geliefden wil je motiveren om echt eerlijk te zijn met jezelf, om te beseffen dat sprookjes niet bestaan, net zomin als prinsen op een wit paard. Als je voor de liefde kiest, dan aanvaard je de ander zoals hij/zij is, zonder voorbehoud.</p>",
                        [GuidanceFields.WORK] : "<p>De Geliefden wil dat je je ervan bewust wordt dat het tijd is om een keuze te maken. Misschien is de tijd gekomen om van baan of van carrière te veranderen, of misschien zijn er bepaalde aspecten of onderdelen van je huidige baan waar je verandering in wil brengen. Voordat je een keuze maakt, is het belangrijk om alle aspecten van je huidige baan grondig te analyseren, na observatie kan iets wat negatief lijkt best in iets positiefs veranderen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart vertelt je dat het waarschijnlijk een goed moment is in je leven om een spirituele relatie te gaan opbouwen met iemand. Het is best mogelijk dat de juiste persoon zelfs al in je omgeving is. Als je met deze persoon een gesprek aangaat, neem dan ruim de tijd om de wijsheid die hij/zij met jou deelt te bestuderen, maar onthoud tegelijkertijd dat het goed is om alles in vraag te stellen als je hart je dat aangeeft.</p>",
                    }
                },
                de : {
                    name :  "Geliebten", subtitle: "VI",
                    zodiac : "♊ - Zwillinge",
                    archetype : "Die Entscheidung",
                    theme : "Der Wahl seines Herzens folgen",
                    description : "Die Liebenden sprechen davon, dass wir uns frei entscheiden sollen, der Wahl unseres Herzens zu folgen, das Männliche und das Weibliche zu vereinen.", 
                    questions : [ "Müssen Sie eine Entscheidung treffen?", "Wählen Sie selbst?", "Folgen Sie Ihrem Herzen, um Sich wohl zu fühlen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte „ Die Liebenden“ bezieht sich häufig auf eine intensive Beziehung, die Sie zu jemandem haben. Es handelt sich meistens um eine intime, romantische Beziehung. Sie symbolisiert Liebe als eine höhere Angelegenheit zwischen zwei Menschen. Anziehungskraft,  Liebe, Freundschaft, die Neigung, Gegensätze zu vereinen, wenn zwei Menschen sich gegenseitig spiegeln. Dieses Gefühl fördert Bewusstsein, Einsicht und Wachstum.</p><p>Manchmal steht diese Karte auch für Zweifel und Ambivalenz, die Sie erfahren, wenn Sie mit schwierigen Entscheidungen konfrontiert sind. Wenn Sie eine Entscheidung treffen müssen, ist es oft schwer zu bestimmen, ob Sie auf Ihr Herz oder auf Ihre Vernunft hören sollen, weil beide wertvoll sind. „Die Liebenden“ bitten Sie, sich von ganzem Herzen für Weisheit und Mut zu entscheiden. Aus diesem Grund wird diese Karte auch „die Entscheidung“ genannt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Die Liebenden konfrontieren Sie mit der Tatsache, dass es eine große Liebe in Ihrem Leben gibt. Diese Liebe berührt Sie tief, und Sie müssen eine Entscheidung treffen. Wenn Sie für diese Liebe wählen, dann müssen Sie sicherstellen, dass Sie dies mit ganzem Herzen und ganzer Seele tun. Sie müssen davon überzeugt sein, dass Sie die richtige Wahl treffen. Die Liebenden motiviert Sie, um wirklich ehrlich mit Sich Selbst zu sein, um anzuerkennen, dass es keine Märchen gibt, keine Helfer in der Not. Bei der Auswahl für diese Liebe müssen Sie die andere Person völlig und ganz akzeptieren, wie sie ist, ohne Vorbehalten.</p>",
                        [GuidanceFields.WORK] : "<p>Die Liebenden deuten darauf hin, dass Sie eine Entscheidung treffen müssen. Vielleicht ist es Zeit, um der Arbeitsplatz oder die Karriere zu ändern. Vielleicht auch gibt es einige Aspekte in der aktuellen Arbeit, die Änderung benötigen. Analysieren Sie alle verfügbaren Informationen, bevor Sie sich entscheide. Wenn man genauer hinschaut, kann eine negative Information sich in eine positive verwandeln.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Diese Karte zeigt Ihnen, dass die Zeit gekommen sein kann, um eine spirituelle Beziehung anzufangen und wahrscheinlich ist die richtige Person bereits in Ihrem Leben erschienen. Nehmen Sie sich Zeit, um die Weisheit zu betrachten, die diese Person mit Ihnen teilt, aber denken Sie daran, alles infrage zu stellen, was Sie mit Sich Selbst überlegen. Folgen Sie nicht blind, aber filtern Sie alle Informationen und lassen Sie Sich führen durch Ihre Intuition.</p>",
                    }
                }
            },
            {
                filename: "07",
                numerology : 7,
                en : {
                    name : "Chariot", subtitle: "VII",
                    bulletpoints : ["Control","Balance","Willpower"],
                    zodiac : "♋ - Cancer",
                    archetype : "going on",
                    theme : "define your objective and go for it",  
                    description : "The Chariot talks about purposefully doing your own thing, defining your own objective, knowing your objective, focus on the objective.",   
                    questions : [ "Do you know what you want?", "What is your objective?", "Can you purposely take further steps to achieve this objective?" ],

                    soalPoints : [ "Nieuw (zelf)bewustzijn", "Doorbreken van routine", "Afwerpen van overgenomen opvattingen", "Vertrek naar het nieuwe", "Kracht", "Moed" ],
                    soalText : "Het vertrek is het loslaten van oude patronen en een begin van nieuwe doelen. ",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Chariot symbolizes goal oriented energy that may result in a big leap forward. It's a very positive card that urges you to get out of your comfort zone and choose a new direction. You are mainly motivated by your ambition, determination and a longing for freedom.  It's a very energetic card, the purpose being to direct this energy towards a specific, carefully chosen goal.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>A new relationship may be in the making. Before you embark on this new adventure, make sure you have properly ended the previous one. Try to do this with respect and compassion.</p><p>The Chariot may also indicate that your relationship is stuck in a rut and it's time to get rid of old patterns, habits and opinions. Or maybe someone wants a relationship with you and you aren't interested; if this is the case, let him/her down gently but firmly.</p>",
                        [GuidanceFields.WORK] : "<p>The Chariot stands for triumph, accomplishment.You have taken a big step forward on the road to success. You haven't yet reached all of your goals but you're well on the way. Still, you should be careful not to overestimate yourself. You know very well what you want to accomplish and which sacrifices you are prepared to make in order to get there.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>As far as spirituality goes it may be a good time for you to travel, and not just literally. Explore new subjects, different ways of thinking, domains you have yet to get to know. You have the energy and stamina to go great lengths. Maybe you should finally take that course you've been talking about for ages, or go to that meditation camp you read about. Trying out and learning new things will have benefits you can't even imagine yet.</p>",
                    }
                },
                fr : {
                    name : "Le Chariot", subtitle : "VII",
                    zodiac : "♋ - Cancer",
                    archetype : "continuer",
                    theme : "Fixer un objectif et aller de l’avant",  
                    description : "Le Chariot, nous parle de suivre délibérément sa propre voie, c'est définir son propre but, c'est (re-)connaître son but, pour pouvoir s'y concentrer.",   
                    questions : ["Savez-vous ce que vous voulez?", "Quel est votre objectif?", "Pouvez-vous réellement faire un pas, avancer, pour atteindre votre objectif?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le Chariot symbolise l'énergie orientée vers le but qui peut mener à un grand saut en avant. C'est une carte très positive qui vous met au défi de quitter votre zone de confort et de découvrir de nouvelles voies. Vous êtes motivé avant tout par l'ambition, la détermination et le désir de liberté. Il s'agit d'une carte très énergique dont le but est de vous diriger, vous et votre énergie, vers un but précis et soigneusement choisi.</p><p>Le seul avertissement que cette carte vous donne est de ne pas surestimer votre force et de ne pas trop compter sur elle car elle pourrait ne pas être suffisante.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>A new relationship may be in the making. Before you embark on this new adventure, make sure you have properly ended the previous one. Try to do this with respect and compassion.</p><p>The Chariot may also indicate that your relationship is stuck in a rut and it's time to get rid of old patterns, habits and opinions. Or maybe someone wants a relationship with you and you aren't interested; if this is the case, let him/her down gently but firmly.</p>",
                        [GuidanceFields.WORK] : "<p>The Chariot stands for triumph, accomplishment.You have taken a big step forward on the road to success. You haven't yet reached all of your goals but you're well on the way. Still, you should be careful not to overestimate yourself. You know very well what you want to accomplish and which sacrifices you are prepared to make in order to get there.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>As far as spirituality goes it may be a good time for you to travel, and not just literally. Explore new subjects, different ways of thinking, domains you have yet to get to know. You have the energy and stamina to go great lengths. Maybe you should finally take that course you've been talking about for ages, or go to that meditation camp you read about. Trying out and learning new things will have benefits you can't even imagine yet.</p>",
                    }
                },
                nl : {
                    name :  "Zegewagen", subtitle: "VII",
                    zodiac : "♋ - Kreeft",
                    archetype : "verder gaan",
                    theme : "stel je doel en ga ervoor",  
                    description : "De zegewagen gaat over doelbewust je eigen weg gaan, over je eigen doel bepalen, over het kennen van je doel, om je je daar op te kunnen richten.",   
                    questions : [ "Weet jij wat je wilt?", "Wat is je doel?", "Kun jij daadwerkelijk een stap zetten, verder gaan, om je doel te bereiken?" ],                 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Zegewagen staat voor doelgerichte energie die als resultaat zal hebben dat je een grote stap vooruit zet. Dit is een heel positieve kaart die je aanspoort om buiten je eigen comfortzone te treden en nieuwe, voorheen onbekende richtingen te kiezen. Je belangrijkste motieven zullen waarschijnlijk ambitie, vastberadenheid en een verlangen naar vrijheid zijn.</p><p>Dit is een sterk energetische kaart die als betekenis heeft dat ze je energie stuurt in de richting van een specifiek, zorgvuldig uitgekozen doel.</p><p>Er schuilt slechts één waarschuwing in deze kaart: wees niet té zelfzeker want dan zou je tekort kunnen schieten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Je staat mogelijk op het punt om aan een nieuwe relatie te beginnen. Het is erg belangrijk dat je je vorige relatie op een zuivere en liefdevolle manier afsluit voor je aan dit nieuwe avontuur begint.</p><p>De Zegewagen kan je er soms op wijzen dat je met je relatie een beetje in een sleur zit en dat het tijd wordt dat je oude patronen gaat loslaten.</p><p>De Zegewagen kan ook betekenen dat iemand in jou geïnteresseerd is maar dat jij niet hetzelfde voelt. Als je je in deze situatie bevindt, wees dan liefdevol maar kordaat in het afwijzen van die persoon.</p>",
                        [GuidanceFields.WORK] : "<p>De Zegewagen staat symbool voor triomf, vaardigheid, volbrenging. Je hebt een grote stap vooruit gezet op je weg naar succes. Dit betekent niet dat je al je doelen al bereikt hebt maar je zit absoluut in de goede richting. Toch is het belangrijk om voorzichtig te zijn en jezelf niet te overschatten. Je weet eigenlijk heel goed wat je wil bereiken en tot welke opofferingen je bereid bent om tot bij je doel te geraken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In verband met je spiritualiteit is het nu een goede tijd om te reizen, en dat niet alleen letterlijk. Onderzoek nieuwe onderwerpen, bekijk andere denkwijzen, vergaar informatie over materie die je nog niet bekeken had. Je hebt de energie en het uithoudingsvermogen om veel werk te verzetten. Misschien is nu wel het goede moment om eindelijk die cursus te volgen waar je al zo lang over aan het praten en nadenken bent, of om met die meditatie te beginnen waar je over gelezen hebt. Je kan je nu nog niet voorstellen hoe goed je je zal voelen als je nieuwe dingen bijleert en uitprobeert. Ga ervoor!</p>",
                    }
                },
                sp:{
                    name:"El Carro", subtitle: "VII",
                    zodiac : "♋ - Langosta",
                    archetype: "continuar",
                    theme: "Establece tu objetivo y hazlo",  
                    description: "El Carro  se trata de seguir tu propio camino deliberadamente, de definir tu propio objetivo, de conocer tu objetivo, para poder concentrarte en él",   
                    questions: ["¿Sabes lo que quieres?", "¿Cuál es tu objetivo?", "¿Puedes realmente dar un paso, ir más allá, para alcanzar tu objetivo?" ],   
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El carro simboliza objetivo orientado energía que puede resultar en un gran salto adelante. Es una tarjeta muy positiva que le insta a salir de su zona de confort y elegir una nueva dirección. Usted está motivado principalmente por su ambición, determinación y un anhelo de libertad. Es una tarjeta muy energética, con el propósito de dirigir esta energía hacia una meta específica, cuidadosamente elegido.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                de: {
                    name :  "Wagen", subtitle: "VII",
                    zodiac : "♋ - Krebs",
                    archetype : "Aufbruch",
                    theme : "Sich ein Ziel setzenund vorwärts gehen.",  
                    description : "Der Wagen spricht darüber, dass Sie bewusst Ihren eigenen Weg gehen, Ihr eigenes Ziel definieren, Ihr Ziel (wieder) erkennen, um sich darauf konzentrieren zu können.",   
                    questions : [ "Wissen Sie, was Sie wollen", "Was ist Ihr Ziel", "Können Sie wirklich einen Schritt machen, vorankommen, um Ihr Ziel zu erreichen?" ],                 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der Wagen symbolisiert zielorientierte Energie, die zu einem großen Sprung vorwärts führen kann. Dies ist eine sehr positive Karte, die Sie herausfordert, Ihre Komfort-Zone zu verlassen und neue Wege zu entdecken. Sie werden vor allem durch Ehrgeiz, Zielstrebigkeit und einer Sehnsucht nach Freiheit motiviert. Dies ist eine sehr energische Karte mit dem Ziel, Sie und Ihre Energie auf ein bestimmtes, sorgfältig ausgewähltes Ziel hin zu lenken.</p><p>Die einzige Warnung, die Ihnen diese Karte gibt, ist die, Ihre Kraft nicht zu überschätzen und sich nicht zu sehr auf sie zu verlassen, weil sie nicht ausreichend sein könnte.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Eine neue Beziehung kann auf Sie zukommen. Bevor Sie Sich mit diesem neuen Abenteuer einlassen, sollen Sie Sich sicher sein, dass die vorherige Beziehung ordnungsgemäß abgeschlossen ist. Versuchen Sie das mit Respekt und Mitgefühl zu tun.</p><p>Der Wagen kann auch bedeuten, dass die heutige Beziehung fest gelaufen ist und dass es jetzt die Zeit ist, um alte Muster, Gewohnheiten und Meinungen zu beseitigen. Oder vielleicht ist jemand interessiert in einer Beziehung mit Ihnen aber Sie nicht; Sollte dies der Fall sein, lassen Sie ihn/ sie vorsichtig, aber fest entschlossen gehen.</p>",
                        [GuidanceFields.WORK] : "<p>Der Wagen steht für Triumph und Leistung. Sie haben einen großen Schritt vorwärts gemacht auf dem Weg zum Erfolg. Obwohl Sie noch nicht alle Ziele erreicht haben, sind Sie einen Stück auf dem Wege. Dennoch sollten Sie darauf achten, Sich nicht zu überschätzen. Sie wissen sehr gut, was Sie erreichen wollen und welche Opfer Sie bereit sind zu machen um dorthin zu gelangen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>So weit es Spiritualität anbelangt kann es jetzt eine gute Zeit für Sie sein zu reisen, und nicht nur buchstäblich. Entdecken Sie neue Themen, unterschiedliche Denkweisen, Terräne, die Sie noch nicht kennengelernt haben. Sie haben die Energie und Ausdauer zu großen Strecken. Vielleicht sollten Sie endlich mal das Studium machen, worüber Sie schon seit Ewigkeiten reden, oder fahren Sie in dieses Meditation Lager wovon Sie gelesen haben. Ausprobieren und neue Dinge entdecken könnte Vorteile haben, von denen Sie noch gar keine Vorstellung haben.</p>",
                    }
                }
            },
            {
                filename: "08",
                numerology : 8,
                en : {
                    name : "Balance", subtitle: "VIII",

                    zodiac : "♎ - Libra",
                    archetype : "the judge",
                    theme : "judging objectively",    
                    description : "Justice talks about cause and effect, acting objectively, honesty and responsibility.", 
                    questions : [ "Is honesty important to you?", "Are you being confronted with dishonesty?", "Are you really the sole responsible?", "Where can you take responsibility and where can you delegate?" ],

                    bulletpoints : ["Fairness","Truth","Clarity"],
                    birthCombinationShort: "Your path unites clarity with deep intuitive understanding.",
                    birthCombinationText : "You are very intuitive in almost every situation. The Justice provides you with logical clarity, which helps you make quick decisions and remain objective. In contrast, the High Priestess keeps you highly connected with your inner voice.",

                    soalPoints : [ "Eerlijkheid", "Oordeel", "Rechtvaardigheid", "Spiegel", "Objectiviteit", "Evenwicht", "Verantwoordelijkheid" ],
                    soalText : "Oordeel is de objectieve beslissing die je neemt nadat je alles goed tegen elkaar hebt afgewogen. Helderheid en oprechtheid is hierin belangrijk. De ander zal je altijd terugspiegelen zoals je jezelf opstelt, je krijgt terug wat je weggeeft. Je bent zelf verantwoordelijk voor alles wat je doet of juist niet doet.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Justice is symbolic for being honest and true. Can you look everyone around you straight in the eye? This card is all about clear and objective insights, about decisions that are well thought through, about balance and integrity. In everyday life Justice reflects on how you experience cause and effect where behavior is concerned. If you are honest and sincere you can expect the same behavior from the people around you. If you are insincere and untrue you will find yourself getting confronted with tricky and difficult situations. People will see through you, unmask you. This card asks you to show true inner integrity and to take responsibility with all your being.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In your personal relationships this card symbolizes being well-balanced. Even if you don’t have a love interest in your life for the moment, you are at peace with your situation. You know perfectly well what you expect from a relationship and because of that, you will easily find it.</p><p>Justice also stands for harmony and reciprocity: if you treat your loved ones in a completely honest and above-board manner, they will do so too. </p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned you can count on being treated in a fair and well balanced manner. You are honest and outspoken and good at what you do. Still, at times a certain passion may be missing, you may find it hard to really be absorbed in your work, you don’t totally immerse yourself in what you do. Also, your work/life balance may be in need of some attention. Attaining this balance is absolutely necessary to find happiness and harmony.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually the Justice card is about balance and being grounded. Do you feel as if you are truly connected to the earth, to the world you are living in as well as to your fellow man? Does your connectedness have a naturally flowing feeling? Or is there a certain rigidity in your behavior and sentiments? Moderation and balance are integral parts of leading a sensible and spiritual life.</p>",
                    }
                },
                fr : {
                    name : "Balance", subtitle : "VIII",
                    zodiac : "♎ - Balance",
                    archetype : "le juge",
                    theme : "jugement objectif",    
                    description : "La justice est une question de cause à effet, d'action objective, d'honnêteté et de responsabilité.", 
                    questions : ["L'honnêteté vous-est-elle importante?", "Êtes-vous confronté à l’injustice?", "Êtes-vous réellement responsable de tout?", "Où pouvez-vous prendre votre responsabilité ou peut-être la déléguer?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La carte de la justice symbolise l'honnêteté et la vérité. Pouvez-vous regarder tout le monde autour de vous dans l'œil droit? Cette carte porte sur des idées claires et objectives, des décisions bien réfléchies, l'équilibre et l'intégrité. Dans la vie de tous les jours, la justice représente la façon dont vous vivez la cause et l'effet quand il s'agit de comportement. Si vous êtes honnête et sincère, vous pouvez vous attendre au même comportement de la part des gens qui vous entourent. Si vous êtes dans l'erreur et le mensonge, vous serez confronté à des situations difficiles et perfides. Les gens devineront vos intentions et vous dénonceront. Cette carte vous invite à montrer votre intégrité intérieure et à assumer la responsabilité de tout votre être.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In your personal relationships this card symbolizes being well-balanced. Even if you don’t have a love interest in your life for the moment, you are at peace with your situation. You know perfectly well what you expect from a relationship and because of that, you will easily find it.</p><p>Justice also stands for harmony and reciprocity: if you treat your loved ones in a completely honest and above-board manner, they will do so too. </p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned you can count on being treated in a fair and well balanced manner. You are honest and outspoken and good at what you do. Still, at times a certain passion may be missing, you may find it hard to really be absorbed in your work, you don’t totally immerse yourself in what you do. Also, your work/life balance may be in need of some attention. Attaining this balance is absolutely necessary to find happiness and harmony.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually the Justice card is about balance and being grounded. Do you feel as if you are truly connected to the earth, to the world you are living in as well as to your fellow man? Does your connectedness have a naturally flowing feeling? Or is there a certain rigidity in your behavior and sentiments? Moderation and balance are integral parts of leading a sensible and spiritual life.</p>",
                    }
                },
                sp:{
                    name : "Balans", subtitle: "VIII",
                    zodiac: "♎ - Libra",
                    archetype: "el juez",
                    theme: "juicio objetivo",    
                    description: "La justicia es causa y efecto, es acción objetiva, es honestidad y responsabilidad", 
                    questions: ["¿La honestidad es importante para tí?", "¿Te enfrentas a la deshonestidad?", "¿Eres realmente responsable de todo?", "¿Dónde puedes asumir tu responsabilidad o quizás transferirla?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La justicia es simbólica por ser honesto y verdadero. ¿Puedes mirar todos en su alrededor a los ojos? Esta tarjeta tiene que ver con ideas claras y objetivas, sobre las decisiones que están bien pensada, el equilibrio y la integridad. En la vida cotidiana Justicia reflexiona sobre cómo la experiencia de la causa y el efecto en el comportamiento se refiere. Si usted es honesto y sincero que puede esperar el mismo comportamiento de las personas que le rodean. Si no es sincero y falso usted se encuentra el conseguir confrontados con situaciones complicadas y difíciles. La gente va a ver a través de usted, desenmascarar a usted. Esta tarjeta le pide que muestre la verdadera integridad interior y asumir la responsabilidad con todo su ser.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name :  "Balans", subtitle: "VIII",
                    zodiac : "♎ - Weegschaal",
                    archetype : "de rechter",
                    theme : "objectief oordelen",    
                    description : "Gerechtigheid gaat over oorzaak en gevolg, over objectief handelen, over eerlijkheid en verantwoordelijkheid.", 
                    questions : [ "Is eerlijkheid belangrijk voor je?", "Word je geconfronteerd met oneerlijkheid?", "Ben jij echt voor alles verantwoordelijk?", "Waar kun jij je verantwoordelijkheid nemen of wellicht overdragen?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Gerechtigheid staat voor eerlijk en oprecht zijn. Kan jij iedereen om je heen recht in de ogen kijken?</p><p>Bij deze kaart gaat het ook om heldere en objectieve inzichten, weldoordachte beslissingen, evenwicht en integriteit.</p><p>Wat het leven van alledag betreft, vraagt deze kaart je om je bewust te worden van oorzaak en gevolg als het om gedrag gaat. Immers, als je zelf eerlijk en oprecht bent, dan kan je over het algemeen hetzelfde gedrag verwachten van de mensen om je heen. Als je zelf oneerlijk en onoprecht bent, dan weet je dat je vroeg of laat terecht zal komen in moeilijke en bedrieglijke situaties. Het is erg waarschijnlijk dat mensen je uiteindelijk door zullen hebben, dat ze je zullen ontmaskeren. Deze kaart vraagt je om echte innerlijke integriteit te tonen en je volle verantwoordelijkheid te nemen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Bij deze kaart gaat het erom evenwichtig te zijn en te blijven in al je relaties. Zelfs als je op het ogenblik geen liefdesrelatie in je leven hebt en er mogelijk wel naar verlangt, je voelt je toch in harmonie met je leven en je situatie. Je weet nu duidelijk wat je wil en verwacht van een relatie en net daarom wordt het makkelijker om het ook te vinden.</p><p>Gerechtigheid vertegenwoordigt ook evenwicht en wederkerigheid: als je de mensen waar je om geeft volkomen eerlijk benadert en behandelt, dan zullen zij dat meestal ook met jou doen.</p>",
                        [GuidanceFields.WORK] : "<p>Wat je werk betreft kan je er op dit moment waarschijnlijk op rekenen dat je op een rechtvaardige en evenwichtige manier behandeld wordt. Je bent eerlijk, je komt openlijk voor je mening uit en je bent goed in wat je doet. Toch is het mogelijk dat je bij momenten een stuk passie mist, dat je het moeilijk vindt om echt op te gaan in je werk. Ook is het mogelijk dat het evenwicht tussen werk en privé niet helemaal goed zit. Daarin het juiste evenwicht bereiken is absoluut nodig om geluk en harmonie te kunnen vinden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De betekenis van Gerechtigheid heeft vooral te maken met evenwicht en gegrond zijn. Voel je je echt verbonden met de aarde, met de wereld waarin je leeft en met de mensen om je heen? Geeft deze verbondenheid je een natuurlijk, vloeiend en dynamisch gevoel? Of zit er een zekere stroefheid in je gedrag en je gevoelens? Gematigdheid en evenwicht zijn onmisbare voorwaarden om een verstandig en spiritueel leven te leiden.</p>",
                    }
                },
                de : {
                    name :  "Balans", subtitle: "VIII",
                    zodiac : "♎ - Waage",
                    archetype : "Der Richter",
                    theme : "Objektives Urteil",    
                    description : "Gerechtigkeit ist eine Frage von Ursache und Wirkung, objektivem Handeln, Ehrlichkeit und Verantwortung.", 
                    questions : [ "Ist Ihnen Ehrlichkeit wichtig?", "Sind Sie mit Ungerechtigkeit konfrontiert?", "Sind Sie wirklich für alles verantwortlich?", "Wo können Sie Ihre Verantwortung übernehmen oder vielleicht delegieren?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die Gerechtigkeitskarte steht symbolisch für Ehrlichkeit und Wahrheit. Können Sie allen Menschen in Ihrer Umgebung direkt in die Augen schauen? Bei dieser Karte geht es um klare und objektive Erkenntnisse, um gut durchdachte Entscheidungen, um Ausgewogenheit und Integrität. Im Alltag steht Gerechtigkeit dafür, wie Sie Ursache und Wirkung erleben, wenn es das Verhalten betrifft. Wenn Sie ehrlich und aufrichtig sind können Sie das gleiche Verhalten von den Menschen Ihrer Umgebung erwarten. Wenn Sie falsch und unwahr sind werden Sie mit schwierigen und tückischen Situationen konfrontiert sein. Die Menschen werden Sie durchschauen und entlarven. Diese Karte fordert Sie auf, Ihre innere Integrität zu zeigen und Verantwortung für Ihr gesamtes Wesen zu übernehmen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte symbolisiert eine gute Ausgewogenheit in Ihren persönlichen Beziehungen. Auch wenn Sie nicht gerade ein Interesse an einer speziellen Liebe in Ihrem Leben haben, sind Sie in Frieden mit Ihrer Situation. Sie wissen ganz genau, was Sie von einer Beziehung erwarten und aus diesem Grund werden Sie sie leicht finden.</p><p>Gerechtigkeit steht auch für Harmonie und Gegenseitigkeit: wenn Sie Ihre Geliebten ganz ehrlich behandeln, werden sie das genau so mit Ihnen tun.</p>",
                        [GuidanceFields.WORK] : "<p>Wo es die Arbeit betrifft können Sie damit rechnen auf einer fairen und ausgewogenen Weise behandelt zu werden. Sie sind ehrlich und freimütig und gut in dem was Sie tun. Dennoch, manchmal könnte eine gewisse Leidenschaft fehlen und können Sie es schwer finden Sich völlig von Ihrer Arbeit aufgenommen zu fühlen. Auch erfahren Sie im Moment kein Gleichgewicht zwischen Arbeit und Leben. Das Erreichen dieses Gleichgewichts ist aber absolut notwendig um Glück und Harmonie zu finden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spirituell handelt die Karte der Gerechtigkeit über Gleichgewicht und Stabilität. Haben Sie das Gefühl, als ob Sie wirklich mit der Erde verbunden sind, verbunden mit der Welt in der Sie leben und mit Ihren Mitmenschen? Ist Ihre Verbundenheit ein natürlich fließendes Gefühl? Oder gibt es eine gewisse Starrheit in Ihrem Verhalten und Ihren Gefühlen? Mäßigung und Gleichgewicht sind integrale Bestandteile für die Führung eines sinnvollen und spirituellen Lebens.</p>",
                    }
                }
            },
            {
                filename: "09",
                numerology : 9,
                en : {
                    name : "Hermit", subtitle: "IX",
                    bulletpoints : ["Seeking Truth","Contemplation","Solitude"],
                    zodiac : "♍ - Virgo",
                    archetype : "the old wise man",
                    theme : "daring to trust ones’ own wisdom", 
                    description : "The Hermit talks about knowing, inner peace, daring to trust your inner wisdom.",
                    questions : [ "What are you good at?", "Do you have an idea of what you know?", "Dare you trust your inner wisdom?" ],

                    soalPoints : [ "Zoeken", "Introvert", "Afzondering", "Dieper inzicht", "Wijsheid", "Geslotenheid", "Weten wat echt belangrijk is", "Alleen-leven" ],
                    soalText : "Zelfbezinning is het goed overdenken van dingen, (zelf)bewust bezig zijn.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card shows the virtues of loneliness, of withdrawal from public life and repentance.  It's about an introvert way of life, introspection necessary to come closer to your True Self.  In order to attain this, you disconnect from external stimuli, you leave the outside world for a while. When you are in this phase of your life you may be confronted with two extremes: an in depth confrontation with your inner thoughts and feelings and the heightened insights you gain. It's very important to allow yourself the time to be alone, to clear your head, to not worry. This is not a time for brooding but for observing.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Hermit informs you it is quite possible that right now you are feeling lonely, even when being in a relationship. This may be a sign telling you that the loneliness is within yourself, that you need to reconnect with your True Self. It may also mean that you're inquisitive about the true meaning of this relationship and the security it's offering you. You are well and truly searching for insight. If at this time a former partner tries to reconnect, be sure to ask yourself if this really is what you want, even if it may come in handy right now.</p>",
                        [GuidanceFields.WORK] : "<p>The Hermit tells you that, at this time, you're reflecting on your job or career. Is this the job you really want to do? Are you happy? Does the job suit you? You may need to talk about this, but make sure that the opinions of others only inspire you and do not direct you. At the end of the day it's important for you to retreat to your own private space and sense if you really should choose another job, one that truly fits.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This is a time of being alone by choice, of retreating from everyday life, of centering yourself and your spiritual energy. You are focused on ways to get closer to your True Self, maybe through meditation, contemplation, spiritual exercises or even fasting. Possibly someone will come into your life who can guide you, coach you on this journey. Even if at times it is hard, it will certainly be worth the effort!</p>",
                    }
                },
                fr : {
                    name : "Heremite", subtitle : "IX",
                    zodiac : "♍ - Vierge",
                    archetype : "le sage",
                    theme : "Oser s'appuyer sur sa propre sagesse", 
                    description : "L'Ermite, nous parle de savoir ce que/qui vous êtes, de la paix intérieure, de faire confiance à notre propre sagesse.",
                    questions : ["Quels sont vos points forts?", "Savez-vous réellement ce que vous savez?", "Osez-vous faire confiance à votre propre sagesse?" ],
 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente les vertus de la solitude, du retrait de la vie publique et de la pénitence. Il s'agit d'un mode de vie introverti dans un monde bruyant, afin de se rapprocher de soi à travers l'auto-observation nécessaire. Pour y parvenir, vous devez vous séparer des stimuli extérieurs et ignorer le monde extérieur pendant un certain temps. Dans cette phase de votre vie, vous pouvez être confronté à deux expériences : L’une, la confrontation avec vos pensées et sentiments intérieurs et l’autre, l'amélioration de vos connaissances, vous gagnez à travers cette confrontation : Il est très important de prendre le temps d'être seul, de se vider la tête et de ne pas s'inquiéter. Ce n'est pas le moment de ruminer, mais d'observer, d'accepter ce qui s'en vient. Il s'agit donc d’observer le ruisseau et pas de le suivre.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Hermit informs you it is quite possible that right now you are feeling lonely, even when being in a relationship. This may be a sign telling you that the loneliness is within yourself, that you need to reconnect with your True Self. It may also mean that you're inquisitive about the true meaning of this relationship and the security it's offering you. You are well and truly searching for insight. If at this time a former partner tries to reconnect, be sure to ask yourself if this really is what you want, even if it may come in handy right now.</p>",
                        [GuidanceFields.WORK] : "<p>The Hermit tells you that, at this time, you're reflecting on your job or career. Is this the job you really want to do? Are you happy? Does the job suit you? You may need to talk about this, but make sure that the opinions of others only inspire you and do not direct you. At the end of the day it's important for you to retreat to your own private space and sense if you really should choose another job, one that truly fits.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This is a time of being alone by choice, of retreating from everyday life, of centering yourself and your spiritual energy. You are focused on ways to get closer to your True Self, maybe through meditation, contemplation, spiritual exercises or even fasting. Possibly someone will come into your life who can guide you, coach you on this journey. Even if at times it is hard, it will certainly be worth the effort!</p>",
                    }
                },
                sp:{
                    name:"Ermitaño", subtitle: "IX",
                    zodiac: "♍ - Virgen",
                    archetype: "el viejo sabio",
                    theme: "Atreverse a confiar en su propia sabiduría", 
                    description: "El Ermitaño se trata de saber lo que eres, de la paz interior, de atreverse a confiar en tu propia sabiduría",
                    questions: ["¿En qué eres bueno?", "¿Sabes lo que sabes?", "¿Te atreves a confiar en tu propia sabiduría?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta muestra las virtudes de la soledad, de la retirada de la vida pública y el arrepentimiento. Se trata de una forma introvertida de la vida, la introspección necesaria para acercarse a su verdadero yo. Para lograr esto, se desconecta de los estímulos externos, de salir al mundo exterior por un tiempo. Cuando usted está en esta etapa de su vida es posible que se enfrentan con dos extremos: un enfrentamiento en profundidad con sus pensamientos y sentimientos y las percepciones aumentadas usted gana. Es muy importante que le permita tener el tiempo para estar solo, para aclarar su cabeza, que no se preocupe. Este no es un tiempo para cavilaciones pero para la observación.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name :  "Heremiet", subtitle: "IX",
                    zodiac : "♍ - Maagd",
                    archetype : "de oude wijze man",
                    theme : "durven vertrouwen op je eigen wijsheid", 
                    description : "De kluizenaar gaat over weten wat je, over innerlijke rust, over het durven vertrouwen op je eigen wijsheid.",
                    questions : [ "Waar ben jij goed in?", "Weet jij eigenlijk wat je weet?", "Durf jij te vertrouwen op je eigen wijsheid?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart toont de positieve kracht van eenzaamheid, van je terugtrekken uit het sociale leven om tot inkeer te komen. Het gaat hier om een leven gericht op het innerlijke, over de introspectie die je nodig hebt om dichter bij je Ware Zelf te komen. Om dit te bereiken is het belangrijk dat je je afsluit voor prikkels en indrukken, dat je de buitenwereld even loslaat. Als je dit stadium in je leven bereikt hebt, dan kan je met twee ervaringen geconfronteerd worden: je krijgt een intense confrontatie met je diepste gedachten en gevoelens, en net daardoor bereiken de inzichten die je hierdoor krijgt een ongekend hoog niveau.</p><p>Het is erg belangrijk dat je jezelf de tijd gunt om alleen te zijn, om je hoofd leeg te maken, om niet na te denken. Het is nu niet de juiste tijd om te piekeren maar wel om te observeren, gewoon bekijken en aanvaarden wat op je afkomt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Kluizenaar vertelt je dat het goed mogelijk is dat je je op het moment eenzaam voelt, ook al heb je een liefdesrelatie. Dit kan een teken zijn dat de eenzaamheid vanbinnen zit, vanuit jezelf komt en dat je dringend weer in contact moet komen met je Innerlijke Zelf. Als je een relatie hebt, is het mogelijk dat je je vragen aan het stellen bent over de ware betekenis van deze relatie en de veiligheid en geborgenheid die je erin vindt. Je bent op dit moment echt op zoek naar inzichten. Het is mogelijk dat je in deze periode een vorige partner ontmoet die contact zoekt met jou en de oude relatie weer nieuw leven wil inblazen. Stel jezelf de vraag of dit echt is wat je wil of dat het enkel een gemakkelijkheidsoplossing zou zijn. Misschien is het zinvoller om te werken aan je huidige relatie.</p>",
                        [GuidanceFields.WORK] : "<p>Volgens deze kaart is het belangrijk om op het ogenblik diep na te denken over je baan of carrière. Wil je deze baan echt wel hebben? Ben je gelukkig? Past deze baan bij jou? Je hebt er behoefte aan om dit te bespreken met de mensen om je heen maar let er wel op dat de meningen van anderen je enkel inspireren, laat je niet in een bepaalde richting duwen.</p><p>Als je alle nodig informatie verzameld hebt, is het aan jou om je terug te trekken op je eigen plek en dan te beslissen of je huidige baan de juiste is of dat je op zoek moet gaan naar een andere, eentje die echt bij je past.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Als je eerlijk bent met jezelf dan weet je dat je in een periode zit waarin je er de voorkeur aan geeft om alleen te zijn, om je terug te trekken uit het dagelijkse leven en je te focussen op jezelf en je spirituele energie. Je bent op zoek naar manieren om dichter bij je Ware Zelf te komen. Misschien wil je dit doel bereiken door te mediteren, door bespiegeling, spirituele oefeningen of misschien zelfs door vasten. Het is waarschijnlijk dat er nu iemand in je leven komt die je kan gidsen, coachen op deze spirituele reis.</p>",
                    }
                },
                de : {
                    name :  "Heremite", subtitle: "IX",
                    zodiac : "♍ - Jungfrau",
                    archetype : "Der Weise",
                    theme : "Es wagen, sich auf seine eigene Weisheit zu verlassen.", 
                    description : "Der Eremit spricht darüber zu wissen, wer oder was wir sind, über den inneren Frieden, über das Vertrauen in unsere eigene Weisheit.",
                    questions : [ "Was sind Ihre Stärken?", "Wissen Sie wirklich, was Sie wissen?", "Trauen Sie sich, Ihrer eigenen Weisheit zu vertrauen?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für die Tugenden der Einsamkeit, des Rückzugs aus dem öffentlichen Leben und der Buße. Es geht um eine introvertierte Lebensweise in einer lauten Welt, um durch die notwendige Selbstbeobachtung näher zu Ihrem wahren Selbst zu kommen. Um dies zu erreichen, müssen Sie sich von äußeren Reizen trennen und die Außenwelt für eine Weile ignorieren. In dieser Phase Ihres Lebens können Sie mit zwei Erfahrungen konfrontiert sein:  Mit einer Konfrontation mit Ihren inneren Gedanken und Gefühlen und mit den verbesserten Einsichten, die Sie durch diese Konfrontation gewinnen: Es ist sehr wichtig, sich die Zeit zu nehmen, um allein zu sein, um den Kopf frei zu machen und sich keine Sorgen zu machen. Dies ist keine Zeit für Grübelei, sondern für Beobachtung, für das Akzeptieren, was auf Sie zukommt. Es geht also darum, den Strom zu beobachten und nicht darum, um mit ihm zu schwimmen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Der Eremit sagt Ihnen, dass es durchaus möglich ist, dass Sie Sich gerade jetzt einsam fühlen, auch wenn Sie gerade in einer Beziehung sind. Dies kann ein Zeichen sein, das besagt, dass diese Einsamkeit sich in Sie Selbst befindet und dass Sie mit Ihrem wahren Selbst wieder in Kontakt treten müssen. Es kann auch bedeuten, dass Sie Sich nicht klar sind über die wahre Bedeutung dieser Beziehung und über die Sicherheit, die sie Ihnen gibt. Sie sind wahrhaft auf der Suche nach Einsicht. Wenn zu diesem Zeitpunkt ein ehemaliger Partner versucht, die Verbindung wieder herzustellen, müssen Sie sich fragen, ob das wirklich ist, was Sie wollen, auch wenn es sich zu diesem Zeitpunkt als nützlich erscheinen lässt.</p>",
                        [GuidanceFields.WORK] : "<p>Der Eremit sagt Ihnen, dass Sie im Moment auf Ihrem Job oder Karriere reflektiert sind. Ist das die Arbeit, die Sie wirklich wollen? Sind Sie glücklich? Passt die Arbeit Ihnen? Möglicherweise müssen Sie darüber reden, aber stellen Sie sicher, dass die Meinungen der Anderen Sie nicht nur inspirieren können sondern auch führen. Am Ende des Tages ist es wichtig für Sie, um Sich in Ihren eigenen privaten Raum zurückziehen und Sich zu fragen, ob Sie Sich wirklich eine andere Arbeit suchen sollten, einer der Ihnen wirklich passt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Dies ist eine Zeit um durch Wahl alleine zu sein, Sich zurückzuziehen vom Alltag, Sich zu richten auf Ihre geistige Energie. Sie suchen nach Möglichkeiten, um näher an Ihr wahres Selbst zu kommen, vielleicht durch Meditation, Kontemplation, spirituelle Übungen oder sogar durch Fasten. Möglicherweise wird jemand in Ihr Leben kommen, der Sie führen, unterstützen kann auf dieser Reise. Auch wenn diese manchmal schwierig ist, wird es sich sicherlich die Mühe lohnen!</p>",
                    }
                }
            },
            {
                filename: "10",
                numerology : 10,
                en : {
                    name : "Fortune", subtitle: "X",
                    planet : "♃ - Jupiter",
                    archetype : "movement",
                    theme : "self-management",
                    description : "The Wheel of Fortune talks about movement, about what has been, what is and what will always be, about new cycles, new chances, self-management.",
                    questions : [ "Who decides in your life?", "Are you moving in the direction of your choice?", "What could you change about your life?" ],

                    bulletpoints : ["Change","Fate","Cycles"],
                    birthCombinationShort: "Your path is of resourcefulness in an over changing world.",
                    birthCombinationText : "The spinning Wheel represents the cycles of change that are always present in life. You recognize these changes and are easily able to adapt to them thanks to the resourcefulness of the Magician.",

                    soalPoints : [ "Het lot", "Bestemming", "(Levens)taak", "Voor alles is een tijd" ],
                    soalText : "(Levens)taak is het uitvoeren en volbrengen van datgene wat jou is opgedragen. Je kunt hier niet voor weglopen. Jouw weg gaat langs alle personen, situaties en andere zaken die voor je bedoeld zijn. Je kunt hiervan leren en door groeien daar waar het nodig is.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Wheel of Fortune is a very spiritual card that's mostly about change, sometimes pointing out that you're feeling helpless, you're confronted with situations you barely have any influence on, or maybe it's change itself that intimidates you. Yes, change can sometimes be very traumatic.</p><p>Sometimes the card stands for a positive change involving circumstances that were at first beyond your control. You may realize you truly are the master of your own fate. </p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card indicates that for the moment you're uncomfortable with your love life. Maybe you're currently single and would like to be in a relationship, or maybe the relationship you're in is problematic, unsatisfying. Anyway, you will have to ponder on what you really want and then take action to change your situation. Remember that change is an integral part of life, we need to change in order to grow and evolve. Also try to live in the Now and happily welcome anything that life throws at you.</p>",
                        [GuidanceFields.WORK] : "<p>Right now you're not happy with your work situation, you are feeling powerless, as if you have no influence whatsoever on the path you've been taking. Maybe you feel like you're in a rut, following old patterns. Quite possibly your heart is no longer in it. The time may have come for you to find out what your dream really is and follow it. Talk to as many people as possible, do your research, think deep and hard and ultimately you will find what you're looking for.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>It's time to realize that you can't possibly control every aspect of your life, but still, in nearly every situation you have some responsibility. Most of the time life doesn't just happen to you. Whatever is going on in your life, accept the impact you have on the situation and learn from it. Now is also a good time to face and analyze past experiences, work through them, find the hidden lesson and finally let go.</p>",
                    }
                },
                fr : {
                    name : "Fortune", subtitle : "X",
                    planet : "♃ - Jupiter",
                    archetype : "mouvement",
                    theme : "Prendre la barre",
                    description : "La Roue de la Fortune, nous parle du mouvement, ce qui a été, ce qui est et ce qui sera toujours, de nouveaux cycles, de nouvelles opportunités, de donner direction à et dans votre propre vie.",
                    questions : ["Qui tire aux ficelles de votre vie?", "Allez-vous dans la direction de votre choix?", "Quelle direction pourriez-vous donner à votre vie?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La \"Roue de la Fortune\" est une carte très spirituelle qui porte principalement sur le changement et indique parfois que vous vous sentez impuissant. Vous êtes confronté à des situations sur lesquelles vous avez peu d'influence. Où peut-être est-ce le changement lui-même qui vous intimide. C'est vrai : Parfois, un changement peut être vécu comme très traumatisant.</p><p>Parfois, cependant, la carte représente aussi un changement positif qui fait référence à des circonstances qui étaient initialement hors de votre contrôle. Vous pouvez voir que vous êtes vraiment maître de votre propre destin. Que la roue de la fortune tourne à gauche ou à droite, vous contrôlez où elle s'arrête !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card indicates that for the moment you're uncomfortable with your love life. Maybe you're currently single and would like to be in a relationship, or maybe the relationship you're in is problematic, unsatisfying. Anyway, you will have to ponder on what you really want and then take action to change your situation. Remember that change is an integral part of life, we need to change in order to grow and evolve. Also try to live in the Now and happily welcome anything that life throws at you.</p>",
                        [GuidanceFields.WORK] : "<p>Right now you're not happy with your work situation, you are feeling powerless, as if you have no influence whatsoever on the path you've been taking. Maybe you feel like you're in a rut, following old patterns. Quite possibly your heart is no longer in it. The time may have come for you to find out what your dream really is and follow it. Talk to as many people as possible, do your research, think deep and hard and ultimately you will find what you're looking for.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>It's time to realize that you can't possibly control every aspect of your life, but still, in nearly every situation you have some responsibility. Most of the time life doesn't just happen to you. Whatever is going on in your life, accept the impact you have on the situation and learn from it. Now is also a good time to face and analyze past experiences, work through them, find the hidden lesson and finally let go.</p>",
                    }
                },
                sp:{
                    name : "Fortuna", subtitle: "X",
                    planet: "♃ - Júpiter",
                    archetype: "movimiento",
                    theme: "Envíalo tú mismo",
                    description: "La Rueda de la Fortuna es sobre el movimiento, sobre lo que fué, lo que es y lo que siempre será, sobre nuevas rondas, nuevas oportunidades, sobre dar dirección a y en tu propia vida",
                    questions: ["¿Quién mueve los hilos de tu vida?", "¿Vas en la dirección que quieres?", "¿Qué giro podrias dar a tu vida?" ],  
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La Rueda de la Fortuna es una tarjeta muy espiritual que es sobre todo un cambio, a veces señalando que se siente impotente, usted se enfrenta con situaciones que apenas tiene ninguna influencia en, o tal vez es el cambio mismo que le intimida. Sí, el cambio puede a veces ser muy traumático. A veces la tarjeta representa un cambio positivo que concurran circunstancias que estaban en primera fuera de su control. Usted puede darse cuenta de que realmente eres el dueño de tu propio destino.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name :  "Fortuin", subtitle: "X",
                    planet : "♃ - Jupiter",
                    archetype : "beweging",
                    theme : "zelf sturen",
                    description : "Het rad van fortuin gaat over beweging, over wat was, wat is en wat altijd zal zijn, over nieuwe rondes, nieuwe kansen, over zelf richting geven aan en in je eigen leven.",
                    questions : [ "Wie trekt aan de touwtjes in jouw leven?", "Ga je de door jou gewenste kant op?", "Welke draai zou jij aan je leven kunnen geven?" ],  
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Het Rad van Fortuin is een opvallend spirituele kaart. Ze staat vooral voor verandering en maakt je duidelijk dat je je mogelijk hulpeloos voelt, geconfronteerd wordt met situaties waarop je nauwelijks invloed hebt. Mogelijk is het zelfs de verandering zelf die je als beangstigend ervaart. En het klopt natuurlijk dat verandering vaak erg traumatisch kan zijn. Toch staat ons hele leven in het teken ervan want groei kan enkel ontstaan vanuit verandering.</p><p>Soms gaat deze kaart over positieve veranderingen in je leven waarvan je in eerste instantie dacht dat je er geen invloed op had. En toch is het mogelijk dat je op een gegeven moment beseft dat je voor een stuk toch zelf je lot bepaald hebt. Of het Rad van Fortuin nu links- of rechtsom draait, jij hebt impact op de plaats waar het stopt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart wijst erop dat je je op dit moment niet goed voelt in je liefdesleven. Misschien ben je single en verlang je naar een relatie, of misschien is de relatie waar je op het moment deel van uitmaakt erg problematisch, mogelijk zelfs onbevredigend. In elk geval zal je de tijd moeten nemen om na te denken over wat je echt wil en dan beslissen wat je eraan zal doen om dat te bereiken.</p><p>Houd jezelf voor ogen dat verandering een onmisbaar deel van het leven is, dat we nu eenmaal moeten veranderen als we willen groeien en evolueren. Probeer tegelijk ook in het Nu te leven en stel je open voor wat er op je af komt.</p>",
                        [GuidanceFields.WORK] : "<p>Je bent waarschijnlijk niet echt tevreden met je werksituatie, je voelt je een stuk machteloos, alsof je geen enkele invloed hebt op de richting die je aan het uitgaan bent. Mogelijk heb je het gevoel dat je in een sleur zit, dat je gewoon maar oude patronen aan het volgen bent. Je doet je werk niet meer met hart en ziel. Misschien moet je wel op zoek gaan om erachter te komen wat je droom is en daarna plannen maken om die droom waar te maken. Praat hierover met zoveel mogelijk mensen, ga op onderzoek uit en je zal zien dat je uiteindelijk zal vinden waarnaar je op zoek bent.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het Rad van Fortuin wijst je erop dat je onmogelijk alle aspecten van je leven onder controle kan hebben, maar natuurlijk heb je in elke situatie en gebeurtenis wel een stukje verantwoordelijkheid. Meestal is het niet zo dat het leven je zomaar overkomt. Gelijk wat er op dit moment aan de hand is, aanvaard de impact die jij op de situatie hebt, neem die verantwoordelijkheid en probeer ervan te leren. Neem nu ook de tijd om naar vorige ervaringen te kijken, ze te analyseren, er de nodige lessen uit te trekken en dan alles los te laten.</p>",
                    }
                },
                de : {
                    name :  "Fortune", subtitle: "X",
                    planet : "♃ - Jupiter",
                    archetype : "Bewegung",
                    theme : "Das Ruder übernehmen",
                    description : "Das Rad des Schicksals spricht über die Bewegung, was war, was ist und was immer sein wird, neue Zyklen, neue Möglichkeiten, Ihrem Leben eine neue Richtung zu geben.",
                    questions : [ "Wer zieht die Fäden Ihres Lebens?", "Gehen Sie in die Richtung Ihrer Wahl?", "Welche Richtung könnten Sie Ihrem Leben geben?" ],  
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Das „Rad des Schicksals“ ist eine sehr spirituelle Karte, die vor allem von Veränderung handelt und manchmal darauf hinweist, dass Sie das Gefühl haben, hilflos zu sein. Sie werden mit Situationen konfrontiert, auf die man kaum Einfluss hat. Oder ist es vielleicht die Veränderung selbst, die Sie einschüchtert. Es stimmt: Manchmal kann eine Veränderung als sehr traumatisch erlebt werden.</p><p>Manchmal steht die Karte aber auch für eine positive Veränderung, die sich auf Umstände bezieht, die sich zunächst außerhalb Ihrer Kontrolle befanden. Sie können erkennen, dass Sie schließlich wirklich Meister Ihres eigenen Schicksals sind. Egal, ob das Rad des Schicksals sich linksherum oder rechtsherum dreht, Sie haben die Kontrolle darüber, wo es aufhört sich zu drehen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte zeigt an, dass Sie im Augenblick unzufrieden sind mit Ihrem Liebesleben. Vielleicht sind Sie noch alleine und möchten Sie gerne in einer Beziehung sein, oder vielleicht ist die Beziehung, in der Sie sind, problematisch, und unbefriedigend. Wie auch immer, müssen Sie über das, was Sie wirklich wollen, nachdenken, und dann Maßnahmen treffen, um Ihre Situation zu ändern. Denken Sie daran, dass Änderung ein integraler Bestandteil des Lebens ist, wir müssen ändern um zu wachsen und entwickeln. Versuchen Sie auch, im Jetzt zu leben und glücklich zu sein und begrüßen Sie alles, was das Leben Ihnen zuwirft.</p>",
                        [GuidanceFields.WORK] : "<p>Zurzeit sind Sie nicht zufrieden mit Ihrer Arbeitsumständen. Sie fühlen sich machtlos, als ob Sie keinerlei Einfluss haben auf dem Wege, den Sie gegangen sind. Vielleicht fühlen Sie Sich wie in einer Furche wobei Sie alte Mustern folgen. Ziemlich vielleicht ist Ihr Herz auch nicht mehr dabei. Die Zeit kann gekommen sein herauszufinden, was Ihr Traum wirklich ist und den zu folgen. Sprechen Sie mit so vielen Menschen wie möglich, untersuchen Sie alles, denken Sie tief und hart nach und Sie werden schließlich finden was Sie suchen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Jetzt ist die Zeit gekommen zu erkennen, dass Sie unmöglich jeden Aspekt Ihres Lebens kontrollieren können, aber trotzdem haben Sie in fast allen Fällen eine gewisse Verantwortung. Meistens kommt das Leben nicht einfach nur auf Sie zu. Was auch immer los ist in Ihrem Leben, akzeptieren Sie den Einfluss, der es auf Sie hat und lernen Sie davon. Jetzt ist auch eine gute Zeit Erfahrungen aus der Vergangenheit zu durchleben und durchzuarbeiten, die versteckte Lehre zu finden und schließlich loszulassen.</p>",
                    }
                }
            },
            {
                filename: "11",
                numerology : 11,
                en : {
                    name : "Strength", subtitle: "XI",

                    bulletpoints : ["Confidence","Inner Strength","Courage"],
                    zodiac : "♌ - Leo",
                    archetype : "inner strength",
                    theme : "using your inner strength",    
                    description : "Strength talks about the importance of putting your inner strength at work even if it seems insurmountable.",
                    questions : [ "Are you a procrastinator?", "Is something holding you back?", "What could you tackle?", "What is it that you should no longer postpone?" ],

                    soalPoints : [ "Moed", "Levenslust", "Hartstocht", "Passie", "Vurigheid", "Dierlijke instincten", "(Scheppings)kracht", "Oerkracht", "Geduld", "Zachtheid" ],
                    soalText : "Kracht is de natuurlijke drift die aangeeft hoe sterk je levenslust is. Het is je oerkracht, instinct of dierlijke kracht die in jou verborgen zit. Deze komt, als het goed is, soms wel eens aan de oppervlakte.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card depicts the man basic primal strength possesses, emerging from profound inner harmony. Once a person has learnt to control his/her emotions and to open the gates towards higher consciousness, all unconscious strength, passion and courage emerge. This card shows that only when you have lived through and transformed basic animal instincts, only then can your values be truly noble. Among others, Strength is about mind over matter.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card emphasizes zeal and physical passion in all kinds of personal relationships. It may describe a relationship that seems to be deep and passionate but turns out to be short lived and lack consistence, once the passion is gone. Only as long as the relationship lasts one feels strong and powerful, to quickly lose that strength once the relationship is over. Then it turns out that there never was any true inner strength. On the other hand: if you are in a strong relationship, right now it is virtually unbreakable! When you are single and you draw this card, the time may be exactly right for you to go out and meet new people, you will find yourself very confident and outgoing.</p>",
                        [GuidanceFields.WORK] : "<p>You are likely to succeed at any goal you set yourself because you are very passionate and energetic right now. Still, you only experience this drive as long as you are motivated; lose motivation and you will find the energy lacking. This is a good time to look for a new job or to go for that promotion or raise you've been wanting for a long time. Don't underestimate yourself, you have a multitude of options!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card reminds you of your strength, you probably are much stronger than you think or realize. As long as you pay equal attention to every aspect of your being (spiritual, physical, intellectual, …) you will discover a great source of inner strength. Be patient and persistent, go through your fears and you will reach goals you never thought possible. </p>",
                    }
                },
                fr : {
                    name : "Force", subtitle : "XI",
                                        
                    zodiac : "♌ - Lion",
                    archetype : " propre moyen",
                    theme : "utiliser ses propre moyens",    
                    description : "Le Force, nous parle de l'importance d'utiliser ses propres moyens, même si on a l'impression d’être confronté à un sentiment insurmontable.",
                    questions : ["Êtes-vous procrastinateur?", "Y-a-t-il quelque chose que vous faites à contrecœur?", "Que pourriez-vous faire?", "Que devriez-vous enfin faire?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente la force primordiale que l'homme possède et qui provient de son harmonie intérieure la plus profonde. Une fois qu'une personne a appris à contrôler ses sentiments et à ouvrir la porte à une conscience supérieure, les forces inconscientes, la passion et le courage coulent ensemble. Le pouvoir vous motive également à utiliser votre énergie pour vous concentrer positivement sur ce que vous voulez réaliser. Cette carte montre que vos valeurs sont vraiment nobles que si vous avez vécu et transformé l'instinct animal. Entre autres choses, la force représente finalement l'esprit dominant la matière.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card emphasizes zeal and physical passion in all kinds of personal relationships. It may describe a relationship that seems to be deep and passionate but turns out to be short lived and lack consistence, once the passion is gone. Only as long as the relationship lasts one feels strong and powerful, to quickly lose that strength once the relationship is over. Then it turns out that there never was any true inner strength. On the other hand: if you are in a strong relationship, right now it is virtually unbreakable! When you are single and you draw this card, the time may be exactly right for you to go out and meet new people, you will find yourself very confident and outgoing.</p>",
                        [GuidanceFields.WORK] : "<p>You are likely to succeed at any goal you set yourself because you are very passionate and energetic right now. Still, you only experience this drive as long as you are motivated; lose motivation and you will find the energy lacking. This is a good time to look for a new job or to go for that promotion or raise you've been wanting for a long time. Don't underestimate yourself, you have a multitude of options!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card reminds you of your strength, you probably are much stronger than you think or realize. As long as you pay equal attention to every aspect of your being (spiritual, physical, intellectual, …) you will discover a great source of inner strength. Be patient and persistent, go through your fears and you will reach goals you never thought possible. </p>",
                    }
                },
                sp:{
                    name : "Fuerza", subtitle: "XI",
                    
                    zodiac: "♌ - León",
                    archetype: "poder propio",
                    theme: "usar tu propio poder",    
                    description: "La Fuerza se trata de la importancia de usar tu propio poder, incluso si crees que se siente como escalar una montaña ",
                    questions: ["¿Eres un retrasado?", "¿(Tienes hipo)? Qué odías", "¿Qué podrías hacer?", "¿Qué deberías hacer finalmente?" ],                  
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta representa la fuerza primigenia básica que el hombre posee, al salir de una profunda armonía interior. Una vez que una persona ha aprendido a controlar su / sus emociones y para abrir las puertas hacia una conciencia superior, toda la fuerza inconsciente, pasión y coraje emergen. Esta tarjeta muestra que sólo cuando se ha vivido y se transforma instintos animales básicos, entonces sólo es posible que sus valores sean verdaderamente noble. Entre otros, la fuerza es acerca de la mente sobre la materia.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name :  "Kracht", subtitle: "XI",
                    
                    zodiac : "♌ - Leeuw",
                    archetype : "eigen kracht",
                    theme : "je eigen kracht inzetten",    
                    description : "Kracht gaat over het belang van het inzetten van je eigen kracht, zelfs als je daar gevoelsmatig als een berg tegenop ziet.",
                    questions : [ "Ben jij een uitsteller?", "Is er iets waar je tegenaan hikt?", "Wat zou jij aan kunnen pakken?", "Wat zou je eindelijk eens moeten doen?" ],                  
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart gaat over de fundamentele primaire kracht die elk mens bezit, een kracht die voortkomt uit diepgaande innerlijke harmonie. De Kracht stimuleert je ook om deze energie te gebruiken in positieve zin: kijk naar wat je wil in plaats van naar wat je niet wil.</p><p>Als je geleerd hebt om je gevoelens in de hand te houden en je hebt de poorten naar een hoger bewustzijn geopend, dan komt alle onbewuste kracht, passie en moed die je in je hebt tevoorschijn. Deze kaart vertelt je dat je eerst doorheen je fundamentele dierlijke instincten heen moet gaan, je moet ze erkennen en beleven.</p><p>Pas daarna kan je aan de slag om bewust je eigen morele waarden en kracht vormen. Daarom kan je de betekenis van deze kaart samenvatten als: de kracht van de geest die het materiële, het fysieke overwint.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Kracht benadrukt dat vuur en fysieke passie belangrijk zijn in alle soorten relaties. Deze kaart kan bijvoorbeeld een relatie beschrijven die in eerste instantie intens en gepassioneerd is, maar die uiteindelijk kort en inhoudsloos blijkt te zijn op het moment dat de passie uitdooft. Je voelt je als persoon sterk zolang deze relatie duurt, maar als ze dan eindigt, verlies je al die kracht heel snel en blijkt het zo te zijn dat er eigenlijk nooit sprake was van echte innerlijke sterkte. Aan de andere kant: als je relatie sterk is dan is ze op dit moment ook echt onbreekbaar!</p><p>Als je deze kaart trekt dan is het voor jou een goede tijd om erop uit te trekken en nieuwe mensen te ontmoeten, want je hebt erg veel zelfvertrouwen.</p>",
                        [GuidanceFields.WORK] : "<p>Je bent op dit moment erg gepassioneerd en energiek. Daarom is het waarschijnlijk dat je slaagt in alle doelen die je voor jezelf vooropstelt. Toch is het zo dat je deze energie enkel voelt als je echt gemotiveerd bent. Als je er niet in slaagt om gemotiveerd te blijven dan merk je dat je ook je energie stilaan kwijtraakt.</p><p>Als je een nieuwe baan wil of je wil promotie of opslag vragen, dan is het nu het juiste moment. Onderschat jezelf niet, er liggen veel wegen open voor jou, je hebt meer opties dan je op het eerste zicht zou vermoeden!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart herinnert je aan jouw eigen innerlijke kracht, je bent waarschijnlijk veel sterker dan je zelf denkt of beseft. Het is op dit moment vooral belangrijk dat je evenveel aandacht besteedt aan alle aspecten van je zelf: spiritueel, fysiek, intellectueel, ... Als je dat doet dan zal je in jezelf een diepe bron van innerlijke kracht vinden die je zal helpen om dingen aan te pakken waar je eerder tegenop zag. Wees geduldig en houd vol, ga door je angsten heen en je zal doelen bereiken die je voorheen onbereikbaar achtte!</p>",
                    }
                },
                de : {
                    name :  "Stärke", subtitle: "XI",
                    
                    zodiac : "♌ - Löwe",
                    archetype : "Eigene Mittel und Wege",
                    theme : "Seine eigenen Mittel einsetzen",    
                    description : "Die Kraft spricht über die Bedeutung des Einsatzes der eigenen Mittel, auch wenn man den Eindruck hat, mit einem unüberwindbaren Gefühl konfrontiert zu werden.",
                    questions : [ "Sind Sie ein Zauderer?", "Gibt es etwas, was Sie widerstrebend tust?", "Was könnten Sie tun?", "Was sollten Sie endlich tun?" ],                  
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte stellt die Urkraft dar, die der Mensch besitzt und die aus seiner tiefsten inneren Harmonie stammt. Sobald ein Mensch gelernt hat, seine Gefühle unter Kontrolle zu halten und die Tür zum höheren Bewusstsein zu öffnen, fließen unbewusste Kräfte, Leidenschaft und Mut zusammen. Die Kraft motiviert Sie zudem, Ihre Energie für einen positiven Fokus auf dasjenige anzuwenden, was Sie erreichen wollen, mehr als auf das, was Sie möchten. Diese Karte zeigt, dass Ihre Werte nur dann wirklich edel sind, wenn Sie die tierischen Instinkte durchlebt und transformiert haben. Unter anderem steht Kraft schließlich für Macht des Geistes über Materie.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte betont Eifer und körperliche Leidenschaft in allen Arten von persönlichen Beziehungen. Es kann eine Beziehung sein, die tief und leidenschaftlich scheint, aber die sich herausstellt als eine kurzfristige wo die Konsistenz fehlt, sobald die Leidenschaft weg ist. Nur so lange, wie die Beziehung dauert man sich stark und mächtig fuhlt. Sobald die Beziehung aber vorbei ist, verliert man schnell wieder die Kraft. Dann stellt sich heraus, dass es niemals eine wahre innere Kraft gegeben hat. Auf der anderen Seite: Wenn Sie in diesem Moment in einer starken Beziehung sind, ist sie praktisch unzerbrechlich! Wenn Sie alleine sind und Sie diese Karte ziehen, kann dieser Moment genau der richtige für Sie sein auszugehen und neue Leute kennenzulernen. Sie fühlen Sich jetzt voller Vertrauen und haben Lust zum Ausgehen.</p>",
                        [GuidanceFields.WORK] : "<p>Sie erreichen zurzeit wahrscheinlich jedes Ziel, dass Sie Sich stellen, weil Sie im Moment sehr leidenschaftlich und energisch sind. Dennoch werden Sie diese Energie nur erleben, solange Sie motiviert sind. Wenn, die Motivation verschwindet wird Ihnen auch die Energie fehlen. Dies ist ein guter Zeitpunkt, um einen neuen Job zu suchen oder für die Gehaltserhöhung oder Beförderung zu gehen, die Sie Sich schon lange gewünscht haben. Unterschätzen Sie Sich nicht, Sie haben eine Vielzahl von Möglichkeiten!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Diese Karte erinnert Sie an Ihre Stärke und Sie sind wahrscheinlich viel stärker als Sie denken oder als Sie Sich realisieren. Solange Sie auf jedem Aspekt Ihres Seins achten (die spirituellen, körperlichen, geistigen, …) werden Sie eine große Quelle Ihrer inneren Kraft entdecken. Seien Sie geduldig und ausdauernd, gehen Sie durch Ihre Ängste und Sie werden Ziele erreichen, die Sie nie für möglich gehalten haben.</p>",
                    }
                }
            },
            {
                filename: "12",
                numerology : 12,
                en : {
                    name : "Hanged Man", subtitle: "XII",

                    planet : "♆ - Neptune",
                    archetype : "seclusion",
                    theme : "time-out",
                    description : "The Hanged Man talks about purposely taking a time out to look at things from a different perspective,  \"watch-the-place\", a time-out.",
                    questions : [ "Are you stuck somewhere?", "Has this taken enough time yet?", "Can you look at it from a different perspective?", "Is it time for a change?" ],

                    bulletpoints : ["Suspension","Delays","Sacrifice"],
                    birthCombinationShort: "Yours is the path of gentle kindness progress in the face of delays",
                    birthCombinationText : "Like the Hanged Man in suspension, you are able to easily handle any delays or pauses in your life journeys. This grace, as well as your nurturing spirit, comes from the Empress tarot birth card.",

                    soalPoints : [ "Crisis", "Stilstand", "Ommekeer", "Geduld", "Innerlijke groei", "Offer", "Het verlichte denken", "Verandering" ],
                    soalText : "Stilstand is het stagneren van een natuurlijk verloop in ontwikkelingen. Dit zorgt er voor dat de energie niet door kan stromen, wat een ongezonde situatie oplevert. Hierin kan alleen verandering komen als je de tijd neemt, de stiltstand, om te groeien en de dingen anders te gaan zien. Meestal gaat de verandering gepaard met een offer dat je zult moeten brengen.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>It looks as if The Hanged Man has no way to turn, nowhere to go. This card may be reminiscent of a burnout or some other affliction that makes it impossible for you to continue your life in the same manner you are used to. Change is imminent. Even though this situation may be rather uncomfortable, you can also experience it as the start of a new era, a reversal of fortune. Circumstances are forcing you to take the time to observe your life, to really understand who you are, how you reached this point. You will truly and deeply comprehend your situation and gain totally new and profound insights.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in an intimate relationship it is well possible that currently you find yourself in a situation you're uncomfortable with. There are tensions between you that aren't being openly discussed. Possibly you don't think your partner's frustrations are important enough or vice versa. Also, you may be underestimating the impact your actions have on your partner.</p><p>If you are currently single it's very possible that you are trying way too hard to find someone. The time is right to do some soul searching and look yourself in the eye. Try to find out what you truly want and expect from an intimate relationship.</p>",
                        [GuidanceFields.WORK] : "<p>The Hanged Man confronts you with the fact that you're stuck. Maybe you don't like your job anymore, maybe there's a task or project you're unsuccessful with. Observe your situation objectively and decide if you want to wait for things to change or if you actively want to make things change. The decision is yours. </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You might well be at a crossroads. Now is the time to relax, lay back and observe your situation as well as your outlook on life. Do you want to stay where you are or do you want change? Observe yourself while realizing that everything you think and feel has an impact on reality. You may want to get rid of some negative or limiting thoughts and beliefs. Visualize the way you want yourself and your life to be and you will discover you can actively mold your own future.</p>",
                    }
                },
                fr : {
                    name : "Pendu", subtitle : "XII",
                    planet : "♆ - Neptune",
                    archetype : "isolement",
                    theme : "time-out",
                    description : "Le Pendu prend consciemment de la distance pour lavoir un point de vue différent, d'un recul par rapport à, \"d'un time-out.\"",
                    questions : ["Vous attardez-vous?", "Cela vous a pris assez de temps?", " Pouvez-vous le voir d'un autre angle?", "Est-il temps pour un changement?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>On dirait que le pendu n'a aucun moyen de tourner, aucun moyen d'aller nulle part. Cette carte peut rappeler une dépression mentale ou d'autres souffrances. Ce souvenir vous empêche de continuer votre vie de la même façon que vous l'aviez l'habitude de le faire. Le changement est imminent. Même si cette situation peut être très désagréable, vous pouvez aussi voir ce changement comme le début d'une nouvelle ère, comme un changement de fortune. Les circonstances vous obligeront à prendre le temps de regarder votre vie en face pour vraiment comprendre qui vous êtes et comment vous en êtes arrivé là. Vous comprendrez vraiment et profondément votre situation d’aujourd'hui et vous irez vers des perspectives complètement nouvelles et profondes.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in an intimate relationship it is well possible that currently you find yourself in a situation you're uncomfortable with. There are tensions between you that aren't being openly discussed. Possibly you don't think your partner's frustrations are important enough or vice versa. Also, you may be underestimating the impact your actions have on your partner.</p><p>If you are currently single it's very possible that you are trying way too hard to find someone. The time is right to do some soul searching and look yourself in the eye. Try to find out what you truly want and expect from an intimate relationship.</p>",
                        [GuidanceFields.WORK] : "<p>The Hanged Man confronts you with the fact that you're stuck. Maybe you don't like your job anymore, maybe there's a task or project you're unsuccessful with. Observe your situation objectively and decide if you want to wait for things to change or if you actively want to make things change. The decision is yours. </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You might well be at a crossroads. Now is the time to relax, lay back and observe your situation as well as your outlook on life. Do you want to stay where you are or do you want change? Observe yourself while realizing that everything you think and feel has an impact on reality. You may want to get rid of some negative or limiting thoughts and beliefs. Visualize the way you want yourself and your life to be and you will discover you can actively mold your own future.</p>",
                    }
                },
                sp:{
                    name : "Colgado", subtitle: "XII",
                    planet: "♆ - Neptuno",
                    archetype: "reclusión",
                    theme: "tiempo muerto",
                    description: "El Colgado se trata de tomar conscientemente una distancia para mirarla desde un lado diferente, de un \"pase en el lugar\", de un tiempo muerto",
                    questions: ["¿Te quedas estacado?", "¿ Eso duró lo suficiente?", "¿Puedes verlo desde otro lado?", "¿Es hora de dar un giro?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Parece como si el hombre colgado no tiene manera de convertir, a dónde ir. Esta tarjeta puede ser una reminiscencia de una quemadura o alguna otra afección que hace que sea imposible para que usted pueda continuar con su vida de la misma manera que estamos acostumbrados. El cambio es inminente. A pesar de que esta situación puede ser bastante incómoda, también puede experimentar como el comienzo de una nueva era, un cambio de fortuna. Las circunstancias le obligan a tomar el tiempo para observar su vida, para entender realmente quién es, cómo llegó a este punto. Usted realmente y profundamente comprenderá su situación y ganara totalmente nuevas y profundas penetraciones.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name :  "Gehangene", subtitle: "XII",
                    planet : "♆ - Neptunes",
                    archetype : "afzondering",
                    theme : "time-out",
                    description : "De gehangene gaat over bewust afstand nemen om het van een adere kant te kunnen bekijken, over een \"pas-op-de-plaats\", over een time-out.",
                    questions : [ "Blijf je ergens in hangen?", "Heeft dat inmiddels al lang genoeg geduurd?", "Kun jij het van een andere kant bekijken?", "Is het tijd voor een ommekeer?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Gehangene heeft geen speelruimte, hij kan nergens heen, zoveel is duidelijk. Volgens deze kaart word je mogelijk geconfronteerd met een onaangename situatie of omstandigheden. Deze maken het je onmogelijk om je leven van alledag te leiden, zoals je het tot nu toe deed. Misschien ben je depressief of zit je om andere redenen niet goed in je vel. Mogelijk zijn het nog andere dingen die je tegenhouden. Hier zal echter verandering in komen. Denk hieraan op de momenten dat je je slecht voelt in je huidige situatie. Je moet het nu kalm aan doen, maar die “vertraging” geeft je ook de kans om je leven te observeren. Nu kan je gaan begrijpen wie je bent en hoe je in deze situatie terechtgekomen bent. Neem de tijd om jezelf en je situatie grondig te onderzoeken, je zal tot verrijkende en diepgaande inzichten komen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op het ogenblik een liefdesrelatie hebt dan vertelt De Gehangene je dat je in een situatie zit die je als onaangenaam ervaart. Er hangen spanningen in de lucht, die niet duidelijk genoeg zijn verwoord of die niet door beiden even belangrijk gevonden worden. Misschien vind je de frustraties van je geliefde niet belangrijk genoeg of omgekeerd.</p><p>Indien je geen relatie hebt, is het mogelijk dat je te krampachtig op zoek bent naar een geliefde. De situatie vraagt je om naar binnen te keren en jezelf in de ogen te kijken. Vraag je eerlijk af wat je werkelijk wil en verwacht van een relatie, dat is de eerste stap.</p>",
                        [GuidanceFields.WORK] : "<p>De Gehangene confronteert je met het feit dat je vastzit. Misschien vind je je baan niet meer leuk, misschien is er een taak of project waarin je maar niet tot resultaten komt. Probeer je situatie objectief te bekijken en beslis dan: misschien wil je afwachten of de dingen vanzelf veranderen, of wil je actief voor die verandering gaan. Deze beslissing ligt helemaal in jouw handen. En uiteindelijk zullen de dingen veranderen, ook al gaat dat misschien niet zo snel als je zou willen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het is goed mogelijk dat je je op een kruispunt bevindt. Nu is het ideale moment om te ontspannen, achterover te leunen en zowel je situatie als je visie op het leven te observeren. Wil je blijven zoals je bent of wil je veranderen? Bedenk dat alles wat je voelt en doet van invloed is op de realiteit. Misschien wil je sommige negatieve of beperkende gedachten en denkwijzen wel loslaten. Probeer je beeld van jezelf en je leven zoals jij het echt wil te visualiseren. Als je dit geregeld doet, zal je merken dat je echt actief impact hebt op de vorm die jouw toekomst aanneemt.</p>",
                    }
                },
                de : {
                    name :  "Gehängte", subtitle: "XII",
                    planet : "♆ - Neptun",
                    archetype : "Distanz",
                    theme : "Auszeit",
                    description : "Der Gehängte nimmt bewusst Abstand, um einen anderen Blickwinkel zu erhalten, um Distanz zu gewinnen, eine Auszeit.",
                    questions : [ "Halten Sie sich auf?", " Haben Sie lange genug dafür gebraucht?", "Können Sie es aus einem anderen Blickwinkel betrachten?", "Ist es Zeit für eine Veränderung?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Es sieht so aus, als ob der Gehängte keine Möglichkeit hat, sich zu drehen, irgendwo hin zu gehen. Diese Karte kann an einen geistigen Zusammenbruch oder an ein anderes Leiden erinnern. Diese Erinnerung macht es Ihnen unmöglich, Ihr Leben auf die gleiche Art und Weise weiter zu führen, wie Sie es gewohnt waren. Veränderung steht unmittelbar bevor. Auch wenn diese Situation sehr unangenehm sein könnte, können Sie diese Änderung auch als den Beginn einer neuen Ära, als eine Schicksalswende sehen. Die Umstände werden Sie zwingen, sich die Zeit zu nehmen, Ihr Leben zu überschauen, um wirklich zu verstehen, wer Sie sind, wie Sie diesen Punkt erreicht haben. Sie werden Ihre heutige Lage wahrhaftig und tief verstehen und zu völlig neuen und tiefen Einsichten kommen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie gerade in einer intimen Beziehung sind, ist es gut möglich, dass Sie Sich zurzeit in einer Situation befinden, die Ihnen unangenehm ist. Es gibt Spannungen zwischen Ihr beide, die nicht offen ausgesprochen werden. Möglicherweise meinen Sie, dass die Frustrationen Ihres Partners nicht wichtig genug sind oder umgekehrt. Außerdem können Sie die Auswirkungen Ihrer Handlungen auf Ihrem Partner unterschätzen.</p><p>Wenn Sie derzeit alleine sind ist es auch sehr gut möglich, dass Sie zu hart versuchen jemanden zu finden. Die Zeit ist jetzt da, tief in Ihrer eigenen Seele zu suchen Sich richtig in die Augen zu schauen. Versuchen Sie herauszufinden, was Sie wirklich wollen und aufrecht erwarten von einer intimen Beziehung.</p>",
                        [GuidanceFields.WORK] : "<p>Der Gehängte konfrontiert Sie mit der Tatsache, dass Sie nicht weiterkommen. Vielleicht gefällt Ihnen Ihre Arbeit nicht mehr, vielleicht gibt es ein Projekt oder eine Aufgabe die nicht erfolgreich für Sie ist. Beobachten Sie Ihre Situation objektiv und entscheiden Sie, ob Sie warten möchten, bis die Dinge sich von alleine ändern oder ob Sie sich aktiv bemühen wollen, um die Dinge zu ändern. Die Entscheidung liegt bei Ihnen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie könnten in diesem Moment an eine Kreuzung anbelangt sein. Jetzt ist die Zeit zu entspannen, zurückzulehnen und Ihre Lage sowie Ihre Einstellung zum Leben zu beobachten. Möchten Sie bleiben, wo Sie sind, oder möchten Sie ändern? Beobachten Sie sich selbst genau, während Sie erkennen, dass alles, was Sie denken und fühlen, einen Einfluss hat auf die Realität. Vielleicht möchten Sie Sich von einigen negativen oder begrenzenden Gedanken und Überzeugungen befreien. Visualisieren Sie, wie Sie Sich das Leben wünschen und Sie werden entdecken, dass Sie tatsächlich Ihre eigene Zukunft bilden können.</p>",
                    }
                }
            },
            {
                filename: "13",
                numerology : 13,
                en : {
                    name : "Death", subtitle: "XIII",

                    zodiac : "♏ - Scorpio",
                    archetype : "transformation",
                    theme : "time of change",    
                    description : "Death, as a card, does not mean an actual physical death! Rather a process of transformation, a slow change, letting go of the old to make space for the new.",

                    bulletpoints : ["Ending Cycle","New Beginning","Metamorphosis"],
                    birthCombinationShort: "Your path creates stability at the end of cycles to usher the new.",
                    birthCombinationText : "When one door closes, you have the stability and authority of the Emperor to open a new one and pursue that new path. Death may bring the end of a cycle, but, for you, it is a chance for new beginnings.",

                    soalPoints : [ "Transformatie", "Loslaten", "Afscheid", "Verlossen", "Het oude en het nieuwe" ],
                    soalText : "Transformatie is de totale verandering die er voor zorgt dat het oude nooit meer kan zijn en er alleen nog maar ruimte is voor het nieuwe. Dit kan niet zonder dat er een, soms zeer pijnlijk of ingrijpend, afscheid aan vooraf is gegaan. Deze verandering kan je ook verlossen van dat wat misschien allang niet meer goed was.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Every renewal is preceded by a period of decay. In autumn the old dying leaves must fall to make room for the new fresh green leaves in spring.  This is what Death symbolizes. This card stands for letting go, saying goodbye to what is no longer necessary. New doors will open and lead us to new beginnings. Sometimes these changes will cause us emotional pain because it's hard to let go of what's familiar. If you accept this pain as being a temporary phenomenon that's a necessary part of change, it will go away soon enough and make way for all that's fresh and new.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In a loving relationship Death may signify the end of a chapter or a phase you are experiencing together. Sometimes it may signify the end of a relationship but mostly it's about realizing the growth and evolution you can go through together to get the relationship to the next level. If, in spite of all your efforts, you are confronted with a breakup, don’t linger too long, your intuition will tell you when to let go of the past.</p><p>Death may also mean that friends you've known and counted on for a long time may drift out of your life, making room for new acquaintances. </p>",
                        [GuidanceFields.WORK] : "<p>Maybe your job isn't enough of a challenge, maybe you've been thinking about leaving for quite a while. Consider all your options, today might be the right day to make some changes. Your quality of life may greatly benefit from a brand new start! Death may also mean that a certain task or project is coming to an end, making way for a new one. Even if it's hard, make sure you stay focused on this task or project until it is well and truly finished. Make sure you can be proud of the results you achieve.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Change is about feeling the pain of saying goodbye but it's also about letting go, about emotionally and spiritually stepping away from what is in the past. Make sure you don't drown in sadness and sorrow, and if you feel you're lingering, ask for help. There's many people around you who will be glad to pull you out of your negative mood. Staying stuck in sadness for too long is just another way of fighting change.</p>",
                    }
               },
               fr : {
                    name : "Mort", subtitle : "XIII",
                    zodiac : "♏ - Scorpion",
                    archetype : "transformation",
                    theme : "processus de changement",    
                    description : "La \"Mort\" ne concerne pas la mort physique ! La mort est un processus de transformation, de changement lent, de lâcher prise de l'ancien pour faire place au nouveau.", 
                    questions : ["Reconnaissez-vous votre processus de changement?", "Pour qui ou quoi voulez-vous faire de l'espace?", "Que pourriez-vous abandonner?", "Que pouvez-vous faire pour passer à la phase suivante?" ],
                
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Tout renouvellement précède un temps de désintégration. En automne, les vieilles feuilles mourantes doivent tomber pour faire place à de nouvelles feuilles vertes fraîches au printemps. Ceci est symbolisé par la mort. Cette carte signifie lâcher prise, dire adieu à ce qui n'est plus nécessaire. De nouvelles portes s'ouvrent et nous conduisent à de nouveaux départs. Parfois, ces changements vous causeront de la douleur émotionnelle parce qu'il est difficile d'abandonner ce qui vous est familier. Si vous ressentez cette douleur comme un phénomène temporaire et que vous la considérez comme un élément nécessaire de ce changement et que vous l'acceptez, la douleur disparaîtra bientôt et ouvrira la voie à tout ce qui est nouveau et frais.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In a loving relationship Death may signify the end of a chapter or a phase you are experiencing together. Sometimes it may signify the end of a relationship but mostly it's about realizing the growth and evolution you can go through together to get the relationship to the next level. If, in spite of all your efforts, you are confronted with a breakup, don’t linger too long, your intuition will tell you when to let go of the past.</p><p>Death may also mean that friends you've known and counted on for a long time may drift out of your life, making room for new acquaintances. </p>",
                        [GuidanceFields.WORK] : "<p>Maybe your job isn't enough of a challenge, maybe you've been thinking about leaving for quite a while. Consider all your options, today might be the right day to make some changes. Your quality of life may greatly benefit from a brand new start! Death may also mean that a certain task or project is coming to an end, making way for a new one. Even if it's hard, make sure you stay focused on this task or project until it is well and truly finished. Make sure you can be proud of the results you achieve.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Change is about feeling the pain of saying goodbye but it's also about letting go, about emotionally and spiritually stepping away from what is in the past. Make sure you don't drown in sadness and sorrow, and if you feel you're lingering, ask for help. There's many people around you who will be glad to pull you out of your negative mood. Staying stuck in sadness for too long is just another way of fighting change.</p>",
                    }
               },
                sp:{
                    name : "Muerte", subtitle: "XIII",
                    zodiac: "♏ - Scorpio",
                    archetype: "transformación",
                    theme: "proceso de cambio",    
                    description: "La tarjeta con el nombre \"La Muerte\" no es sobre la muerte física! La muerte se trata de un proceso de transformación, de un cambio lento, de dejar ir lo viejo para hacer espacio para  lo nuevo", 
                    questions: ["¿Reconoces tu proceso de cambio?", "¿Para qué quieres espacio?", "¿Qué puedes dejar ir?", "¿Qué puedes hacer para pasar a la siguiente fase?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cada renovación está precedida por un período de decadencia. En otoño las hojas viejas moribundos deben caer para hacer espacio para las nuevas hojas verdes frescas en la primavera. Esto es lo que simboliza la muerte. Esta tarjeta es sinónimo de dejar ir, diciendo adiós a lo que ya no es necesario. Nuevas puertas se abrirán y conducirnos a nuevos comienzos. A veces, estos cambios nos causarán dolor emocional, porque es difícil de dejar de lado lo que es familiar. Si acepta este dolor como un fenómeno temporal que es una parte necesaria del cambio, que va a desaparecer pronto y dar paso a todo lo que es fresco y nuevo.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
               nl : {
                    name :  "Dood", subtitle: "XIII",
                   zodiac : "♏ - Schorpioen",
                   archetype : "transformatie",
                   theme : "veranderingsproces",    
                   description : "De kaart met de naam \"De Dood\" gaat niet over de fysieke dood! De dood gaat over een transformatieproces, over een langzame verandering, over het oude loslaten om ruimte te maken voor het nieuwe", 
                   questions : [ "Herken je jouw veranderingsproces?", "Waar wil jij ruimte voor?", "Wat zou jij los kunnen laten?", "Wat kan jij doen om door te gaan naar een volgende fase?" ],                 
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Opdat er iets nieuws zou kunnen ontstaan, moet er eerst iets ouds afsterven. Een mooi voorbeeld hiervan vind je in de natuur: in de herfst vallen de verkleurde stervende bladeren van de bomen af. Ze maken zo plaats voor de frisse groene blaadjes die in de lente zullen groeien.</p><p>Dit is wat deze kaart betekent: een belangrijk aspect van het leven is afscheid nemen, loslaten wat je niet langer nodig hebt. Soms kunnen zo’n veranderingen je enorm verdriet doen omdat je mensen en dingen die je vertrouwd zijn, moet laten gaan. Zonder afscheid kan er echter geen verandering zijn. Daarom is het belangrijk om het verdriet te aanvaarden. De pijn zal uiteindelijk vervagen en plaats maken voor mooie nieuwe ontmoetingen en ervaringen.</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>In een liefdesrelatie kan De Dood het einde van een hoofdstuk of een fase inluiden, een evolutie waar je samen doorheen moet. Soms kan deze kaart ook het einde van een relatie betekenen, maar meestal staat ze voor het waarmaken van gezamenlijke groei en evolutie, waardoor je samen je relatie naar het volgende niveau tilt. Als dit toch niet lukt en je stevent af op een breuk, sta er dan niet te lang bij stil, luister naar je intuïtie als die je vertelt om los te laten.</p><p>De Dood kan ook betekenen dat vrienden die je al erg lang kent en waar je altijd op gerekend hebt, langzaam uit je leven zullen verdwijnen om plaats te maken voor nieuwe kennismakingen.</p>",
                       [GuidanceFields.WORK] : "<p>Misschien vind je je baan geen uitdaging meer, of mogelijk ben je al een tijdje aan het overwegen om er weg te gaan. Bekijk al je opties want misschien is vandaag wel de juiste dag om dingen te veranderen. Je levenskwaliteit kan er enorm op vooruitgaan dankzij een nieuwe start!</p><p>De Dood kan ook het einde inluiden van een bepaalde taak of project, waardoor er ruimte komt om aan iets nieuws te beginnen. Zelfs al heb je het gevoel dat je \"er bijna vanaf bent\", probeer toch om geconcentreerd te blijven op je taak of project tot het echt helemaal afgewerkt is.</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>Verandering gaat niet alleen over het voelen van de pijn van een afscheid maar ook over loslaten, je emotioneel en spiritueel vrijmaken van wat voorbij is. Let erop dat je niet verdrinkt in verdriet en droefheid, en als je toch het gevoel hebt dat je erin blijft vastzitten, zoek dan hulp. Er zijn echt wel voldoende mensen in je omgeving die voor je klaarstaan om je uit je negatieve stemming te trekken. Als je te lang blijft hangen in je verdriet dan heb je eigenlijk enkel een nieuwe manier gevonden om je te verzetten tegen verandering.</p>",
                   }
               },
               de : {
                    name :  "Tod", subtitle: "XIII",
                   zodiac : "♏ - Skorpion",
                   archetype : "Transformation",
                   theme : "Veränderungsprozess",    
                   description : "Beim \"Tod\" geht es nicht um den physischen Tod! Der Tod ist ein Prozess der Transformation, des langsamen Wandels, des Loslassens des Alten, um Platz für das Neue zu schaffen.", 
                   questions : [ "Erkennen Sie Ihren Veränderungsprozess", "Für wen oder wofür wollen Sie Raum schaffen", "Was könnten Sie aufgeben?", "Was könnten Sie tun, um zur nächsten Phase zu gelangen?" ],                 
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Jeder Erneuerung geht eine Zeit des Zerfalls vorher. Im Herbst müssen die alten sterbenden Blätter fallen, um Platz für neue, frische grüne Blätter im Frühjahr zu machen. Das symbolisiert der Tod. Diese Karte steht für das Loslassen, für den Abschied von dem, was nicht mehr notwendig ist. Neue Türen öffnen sich und führen uns zu neuen Anfängen. Manchmal werden diese Änderungen emotionalen Schmerz bei Ihnen verursachen, weil es schwer ist, aufzugeben, was vertraut ist. Wenn Sie diese Schmerzen als vorübergehende Erscheinung erfahren und sie als einen notwendigen Teil dieser Veränderung sehen und sie akzeptieren, dann werden die Schmerzen bald wieder verschwinden und den Weg frei machen für alles, was frisch und neu ist.</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>In einer liebevollen Beziehung kann der Tod das Ende eines Kapitels oder einer Phase sein, die Sie gemeinsam erlebt haben. Manchmal kann es das Ende einer Beziehung bedeuten, aber meistens geht es um die Verwirklichung von Wachstum und Evolution die man gemeinsam durchlebt, um die Beziehung auf die nächste Ebene zu führen. Wenn Sie trotz all Ihrer Bemühungen mit einer Trennung konfrontiert werden, müssen Sie nicht zu lange an diesem Punkt verweilen. Ihre Intuition wird Ihnen sagen, wann Sie die Vergangenheit loslassen müssen.</p><p>Der Tod kann auch bedeuten, dass Freunde, die Sie für lange Zeit gekannt und vertraut haben, aus Ihrem Leben vertrieben werden. Sie werden aber Platz machen für neue Bekanntschaften.</p>",
                       [GuidanceFields.WORK] : "<p>Vielleicht ist Ihre Arbeit nicht herausfordernd genug, vielleicht haben Sie darüber nachgedacht für eine lange Weile zu verschwinden. Betrachten Sie alle Möglichkeiten, vielleicht wäre Heute der richtige Tag, um einige Änderungen vorzunehmen. Ihre Lebensqualität könnte stark profitieren von einem neuen Anfang! Der Tod kann auch bedeuten, dass eine bestimmte Aufgabe oder ein Projekt zu Ende geht, um Platz für neue zu machen. Auch wenn es schwer ist, bleiben Sie Sich konzentrieren auf diese Aufgabe oder das Projekt, bis es ganz und wirklich fertig ist. Stellen Sie sicher, dass Sie stolz sein können auf die Ergebnisse, die Sie erreichen können.</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>Veränderung handelt über den Schmerz des Abschieds aber auch über das Loslassen. Emotional und spirituell wegtreten von dem, was in der Vergangenheit war. Achten Sie darauf, nicht in Trauer und Kummer zu ertränken, und wenn Sie das Gefühl haben runter zu gehen, bitten Sie um Hilfe. Es gibt viele Menschen um Sie herum, die sich freuen würden, Sie aus Ihrer negativen Stimmung ziehen zu können. Zu lange in Traurigkeit hängen bleiben ist nur eine andere Art um Veränderung zu bekämpfen.</p>",
                   }
               }
            },
            {
                filename: "14",
                numerology : 14,
                en : {
                    name : "Art", subtitle: "XIV",

                    zodiac : "♐ - Sagittarius",
                    archetype : "harmony",
                    theme : "balance",    
                    description : "Temperance talks about the conscious and the unconscious, the right balance, the ability to match, the art of life.",
                    questions : [ "Are you balanced?", "Are you on dry land but in contact with your feelings?", "Can you find the right balance?", "Can you balance usefully and have fun?" ],

                    bulletpoints : ["Patience","Calm","Serenity"],
                    birthCombinationShort: "Yours is the path of guidance by wisdom and peace.",
                    birthCombinationText : "You have the qualities to be an exceptional leader. You possess the great wisdom of the Hierophant, which is a necessary quality to effectively lead. And you also are able to resolve conflicts because of Temperance. You bring calm serenity to any situation.",

                    soalPoints : [ "Evenwicht", "Van alles genoeg", "Harmonie", "Lichaam en geest in balans", "Gelatenheid", "(Innerlijke) zielenrust", "Zielengids", "Genezing" ],
                    soalText : "Juiste mengsel is de balans tussen geest en lichaam en het evenwicht van je innerlijke wereld met de omgeving. Dit kun je bereiken door iedere keer weer vanuit je centrum te handelen. Dan ben je in harmonie met jezelf en kun je ook in harmonie omgaan met de buitenwereld.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Temperance focuses on balance, moderation, harmony, resignation, peace of mind, both in yourself and in relationships. You should be internally balanced, taking good care of yourself in order to optimally experience peace and joy.</p><p>Still, if you draw this card it may be time to have a closer look at all your relationships and ask yourself if you have issues or problems that are standing in the way of harmony. Now is the time to find out what it takes to achieve balance in every aspect of your life.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells us that, in our relationship(s), we may have experienced tumultuous times with many emotions to work through.But right now we are experiencing peace, relaxation and harmony. It's wise to use these quiet times to let our newly acquired insights come to fruition within us.</p><p>Temperance may indicate that a new level of commitment is nearby. Maybe you are thinking about moving in together or getting engaged or possibly even getting married.</p>",
                        [GuidanceFields.WORK] : "<p>At work, you accomplish your tasks with resignation. This may indicate that you're performing your tasks in a quiet and harmonious atmosphere, but it may also mean that you're leaning towards an apathetic attitude. The latter may be a sign of no longer being connected to yourself and your work, which will decrease the quality of your output and your creativity.</p><p>Temperance also tells us that, even if it may take some time, our accomplishments will not go unnoticed. Someday, people will let you know they are happy with what you do.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Temperance urges you to meditate, turn inward. Even though your life and relationships may be going very well, you may need to observe deeper personal issues and questions to find out if there's anything standing in the way of your development and growth. If you address these issues and work through them, youwill be able to integrate the insights you gain. Then you will regain balance, within yourself, in your relationships and all other aspects of your life.</p>",
                    }
                },
                fr : {
                    name : "Art", subtitle: "XIV",
                    zodiac : "♐ - Sagittaire",
                    archetype : "harmonie",
                    theme : "équilibre",    
                    description : "La modération nous parle du conscient et de l'inconscient de la bonne mesure, de l'art de mélanger, de l'art de vivre.",
                    questions : ["Êtes-vous en harmonie?", "Êtes-vous en contact avec vos sentiments, dans la réalité?", "Pouvez-vous trouver votre juste milieu?", "Pouvez-vous de manière ludique jongler entre l'utile et le plaisir?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La tempérance est synonyme d'équilibre, de modération, d'harmonie, de résignation et de tranquillité, en soi et dans les relations. Ils doivent être équilibrés intérieurement et prendre soin de soi afin de faire l'expérience optimale de la paix et la joie.</p><p>Si vous tirez cette carte, cependant, le temps est peut-être venu de repenser en profondeur toutes vos relations et de vous demander s'il y a des questions ou des problèmes qui font obstacles à l'harmonie. Il est temps de découvrir ce qui est nécessaire pour atteindre l'équilibre dans tous les aspects de votre vie.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells us that, in our relationship(s), we may have experienced tumultuous times with many emotions to work through.But right now we are experiencing peace, relaxation and harmony. It's wise to use these quiet times to let our newly acquired insights come to fruition within us.</p><p>Temperance may indicate that a new level of commitment is nearby. Maybe you are thinking about moving in together or getting engaged or possibly even getting married.</p>",
                        [GuidanceFields.WORK] : "<p>At work, you accomplish your tasks with resignation. This may indicate that you're performing your tasks in a quiet and harmonious atmosphere, but it may also mean that you're leaning towards an apathetic attitude. The latter may be a sign of no longer being connected to yourself and your work, which will decrease the quality of your output and your creativity.</p><p>Temperance also tells us that, even if it may take some time, our accomplishments will not go unnoticed. Someday, people will let you know they are happy with what you do.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Temperance urges you to meditate, turn inward. Even though your life and relationships may be going very well, you may need to observe deeper personal issues and questions to find out if there's anything standing in the way of your development and growth. If you address these issues and work through them, youwill be able to integrate the insights you gain. Then you will regain balance, within yourself, in your relationships and all other aspects of your life.</p>",
                    }
                },
                sp:{
                    name : "Arte", subtitle: "XIV",
                    zodiac: "♐ - Sagitario",
                    archetype: "armonía",
                    theme: "equilibrio",    
                    description: "La Templanza es sobre la conciencia y el inconsciente, sobre el tamaño correcto, sobre el arte de mezclar, sobre el arte de vivir",
                    questions: ["¿Estás en equilibrio?", "¿Tienes contacto con tus sentimientos, estás de pie en la realidad", "¿Puedes encontrar tu media dorada?", "¿Puedes lidiar juguetonamente con algo útil y divertido?"],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Templanza se centra en el equilibrio, la moderación, la armonía, la resignación, la paz de la mente, tanto en uno mismo y en las relaciones. Usted debe ser un equilibrio interno, teniendo buen cuidado de su mismo para experimentar de manera óptima la paz y la alegría. Aún así, si se traza esta tarjeta puede ser el momento para echar un vistazo más de cerca a todas sus relaciones y se pregunta si tiene problemas o problemas que se interponen en el camino de la armonía. Ahora es el tiempo para averiguar lo que se necesita para lograr el equilibrio en todos los aspectos de su vida.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name : "Kunst", subtitle: "XIV",
                    zodiac : "♐ - Boogschutter",
                    archetype : "harmonie",
                    theme : "evenwicht",    
                    description : "Gematigdheid gaat over bewust en onbewust, over de juiste maat, over de kunst van het mixen, over levenskunst.",
                    questions : [ "Ben jij in balans?", "Heb jij contact met je gevoel, staande in de werkelijkheid?", "Kun jij je gulden middenweg vinden?", "Kun je speels omgaan met nuttig en leuk?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart concentreert zich op evenwicht, gematigdheid, harmonie, aanvaarding en innerlijke rust, niet enkel in jezelf maar ook in je relaties. Als je innerlijk in evenwicht bent en goed voor jezelf zorgt dan kan je op de best mogelijke manier genieten van vrede en vreugde.</p><p>Wanneer je deze kaart trekt dan is het goed om even stil te staan bij je relaties: zijn er mogelijk problemen of spanningen die in de weg staan van harmonie? Wat kan je eraan doen om dit te veranderen? Vraag je ook af wat je kan doen om evenwicht terug te brengen in elk aspect van je leven.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart symboliseert dat je op dit ogenblik waarschijnlijk aan het genieten bent van een vredige, ontspannen en harmonieuze tijd in je relatie(s). Waarschijnlijk is deze fijne periode voorafgegaan door een drukke, verwarrende tijd waarin je erg veel emoties moest verwerken. En misschien heb je dankzij het verwerken van deze gevoelens een aantal nieuwe inzichten verworven. Neem er nu ruim de tijd voor om deze inzichten te laten rijpen en doordringen.</p><p>Gematigdheid kan ook een aanwijzing zijn voor het feit dat je klaar bent om een stap verder te gaan in je relatie, vooral wat engagement en toewijding betreft.</p>",
                        [GuidanceFields.WORK] : "<p>Volgens Gematigdheid is het zo dat je op dit moment de taken op je werk uitvoert met een zekere berusting. Dit kan er enerzijds op wijzen dat je aan het werken bent in een rustige en harmonieuze atmosfeer, maar anderzijds kan het ook betekenen dat je een beetje apathisch aan het worden bent, misschien zelfs wat moedeloos. Als dat laatste het geval is dan is het best mogelijk dat je de verbinding met jezelf en je werk een beetje kwijt bent, en dat zowel de kwaliteit van je werk als je creativiteit daaronder te lijden hebben.</p><p>Deze kaart kan ook betekenen dat, zelfs al duurt het nog even voor men je dat laat weten, je goede werk niet onopgemerkt blijft. Er hangen complimentjes in de lucht, geef de moed niet op!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Gematigdheid spoort je aan om te mediteren, naar je innerlijke kern te gaan. Het is best mogelijk dat alles wat je leven en je relaties betreft van een leien dakje loopt. Toch kan het nodig zijn om diepere persoonlijke problemen en vragen te bekijken. Op die manier kan je er achter komen of er iets in de weg staat van je ontwikkeling en groei. Als je problemen ontdekt en ze aanpakt en verwerkt, zal je merken dat je de inzichten die je hieruit haalt gemakkelijk kan integreren en gebruiken. En daardoor zal je je evenwicht vinden, zowel binnenin jezelf als in je relaties en andere aspecten van je leven.</p>",
                    }
                },
                de : {
                    name : "Kunst", subtitle: "XIV",
                    zodiac : "♐ - Schütze",
                    archetype : "Harmonie",
                    theme : "Gleichgewicht",    
                    description : "Die Mässigkeit spricht vom Bewussten und Unbewussten des richtigen Maßes, von der Kunst des Mischens, von der Kunst des Lebens.",
                    questions : [ "Sind Sie in Harmonie?", "Sind Sie in Kontakt mit Ihren Gefühlen, in der Wirklichkeit?", "Können Sie Ihren Mittelweg finden?", "Können Sie spielerisch zwischen Nützlichkeit und Vergnügen jonglieren?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die Mäßigkeit steht für ein Gleichgewicht, für Mäßigung, Harmonie und Ruhe, sowohl in sich selbst als auch in Beziehungen. Sie sollten innerlich ausgeglichen werden, und gut für sich selbst sorgen, um den Frieden und die Freude optimal erleben zu können.</p><p>Wenn Sie diese Karte ziehen kann dennoch die Zeit gekommen sein, alle Ihrer Beziehungen gründlich zu überdenken und sich zu fragen, ob es Fragen oder Probleme gibt, die der Harmonie im Wege stehen. Jetzt ist es Zeit, um herauszufinden, was notwendig ist, damit Sie ein Gleichgewicht in jedem Aspekt Ihres Lebens erreichen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte sagt uns, dass Sie in Ihrer Beziehung (en) stürmische Zeiten mit vielen Emotionen erlebt und durch gearbeitet haben. Aber jetzt erfahren Sie die Ruhe, Entspannung und Harmonie. Es wäre klug, diese ruhigen Zeiten zu benutzen um zu den neu erworbenen Einsichten zu geraten, um diese jetzt Früchte tragen zu lassen.</p><p>Die Mäßigkeit kann darauf hindeuten, dass eine neue Ebene in Verpflichtung auf Sie zukommt. Vielleicht denken Sie daran mit jemandem zusammenzuziehen oder sich zu verloben oder vielleicht sogar zu heiraten.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit verfüllen Sie Ihre Aufgaben mit Gelassenheit. Dies kann darauf hinweisen, dass Sie die Ausführung Ihrer Aufgaben in einer ruhigen und harmonischen Atmosphäre machen, aber es kann auch bedeuten, dass Sie zu einem apathischen Haltung neigen. Letzteres kann darauf deuten, dass Sie nicht mehr mit Sich Selbst und Ihre Arbeit verbunden sind, was die Qualität Ihrer Leistung und Kreativität verringern kann.</p><p>Die Mäßigkeit sagt uns auch, dass, auch wenn es einige Zeit dauern kann, unsere Leistungen nicht unbemerkt bleiben werden. Eines Tages werden die Leute Sie sagen, dass sie mit dem, was Sie tun, zufrieden sind.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Mäßigkeit fordert Sie auf zu meditieren, Sich nach innen zu wenden. Auch wenn Ihr Leben und Ihre Beziehungen im Moment sehr gut sein können, müssen Sie möglicherweise tiefere persönliche Probleme und Fragen näher untersuchen um herauszufinden, ob Ihre Entwicklung und Ihren Wachstum etwas im Wege steht. Falls Sie diese Probleme angehen und durcharbeiten, werden Sie imstande sein, die erworbene Erkenntnisse zu integrieren. Dann werden Sie das Gleichgewicht wieder erfinden in Sich Selbst, in Ihren Beziehungen und in allen anderen Aspekten Ihres Lebens.</p>",
                    }
                }
            },
            {
                filename: "15",
                numerology : 15,
                en : {
                    name : "Devil's Play", subtitle: "XV",

                    zodiac : "♑ - Capricorn",
                    archetype : "the manipulator",
                    theme : "self-inflicted limitation",    
                    description : "The Devil talks about seemingly being stuck, thinking to be trapped, self-inflicted limitation.",  
                    questions : [ "Are you being cheated upon, or are you cheating on yourself?", "Can you break the chains of your limitations?", "Are you open to enjoy life?", "Can you still be spontaneous?" ],

                    bulletpoints : ["Sensuality","Materialism","Excess"],
                    birthCombinationShort: "Your path is one of restoring beauty to the beastly.",
                    birthCombinationText : "You are very sensual and passionate about everything you do. The Devil card means that you are playful, which can lead to problems such as materialism. However, the addition of the Lovers adds passion into the mix, which restores a better balance.",

                    soalPoints : [ "Afhankelijkheid", "Verstrikking", "Verleiding", "Overdaad", "Verslaving", "Schaduwzijde", "Vrijwillig vastzitten" ],
                    soalText : "Verleiding is dat waarvan je denkt dat het mooi en goed is, maar waarbij je innerlijke stem weet dat het niet goed voor je is. En toch wil je steeds meer. Het is de verlokking die jou aanzet om dingen te doen die tegen je principes ingaan.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>From all the Tarot cards The Devil is the hardest to interpret, mainly because The Devil shows a different apparition to everyone. Still, there are some general aspects that can be named; dependence, apathy, impossibility to take initiative. The Devil tells us we're playing with fire and we should be very careful not to get burned. It also tells us that when we feel bound, imprisoned in a situation, we often unknowingly have the solution within ourselves. In any situation, no matter how grave, there always are options. Even if people around you are telling you that there is only one way to choose, only one path you can take, make sure that you check it all out for yourself because probably there will be other choices, you only have to find them.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Devil's temptations are stimulating and passionate which may be very pleasant. But there always is a downside. Playing with fire may be tempting, but the fire can become all consuming, destroying. The Devil tells us to look objectively at what we are doing.</p><p>If you're in a long term relationship, maybe you or your partner feel trapped, bored, tired. Try to talk about this to change and hopefully save the relationship. If you're single and looking for love you may well have reached the point of desperation. Remind yourself that this is utterly unattractive and do everything you can to change your outlook.</p>",
                        [GuidanceFields.WORK] : "<p>The Devil wants us to gain insight into our motives. Are we taking advantage of a colleague's gullibility or goodwill? Are we misleading colleagues or business partners? Observe yourself, be honest, don't judge, just decide to do better in the future. You want to be able to look at yourself in the mirror every day.</p><p>The Devil may also indicate that you feel trapped in your job, maybe even hate it, Observe the situation objectively: is the sense of security you get out of this job worth staying in a situation you intensely dislike?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Take a look around you, analyze the people you are surrounding yourself with, privately and in the workplace. Are they mostly positive, energetic, stimulating? Or do you have negative, cynical, criticizing people around you who havea destructive influence on you and your self-image? Surround yourself with the positive people, the ones who give you energy and inspiration. Use affirmations, meditation, … anything that can help you become and stay positive.</p>",
                    }
               },
               fr : {
                    name : "Jeu du diable ", subtitle : "XV",
                    zodiac : "♑ - Capricorne",
                    archetype : "le manipulateur",
                    theme : "restriction auto-imposée",    
                    description : "Le Diable, nous parle de l'impression d'être bloqué, de penser d’être en chaînés, de s'imposer des restrictions.",  
                    questions : ["Avez-vous été dupé ou trompez-vous vous-même?", "Pouvez-vous vous libérer de ce qui vous limite?", "Vous permettez-vous de profiter de la vie?", "Vous est-il encore possible de vous défouler?" ],
                
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De toutes les cartes de tarot, le diable est le plus difficile à interpréter, surtout parce que le diable se présente à chaque personne sous une forme différente. Néanmoins, il y a quelques aspects généraux que l'on peut nommer : la dépendance, l'apathie, l'impossibilité de prendre des initiatives. Le diable nous dit que nous sommes en train de jouer avec le feu et de faire très attention à ne pas nous brûler. La carte nous dit aussi que lorsque nous nous sentons liés et piégés dans une situation, nous avons souvent inconsciemment un certain contrôle sur la solution en nous. Dans n'importe quelle situation, quelle qu'en soit la gravité, il y a toujours des possibilités. Même si les gens autour de vous disent qu'il n'y a qu'un seul moyen, qu'une seule décision que vous pouvez prendre, vérifié les données par vous-même et validé ce qui est en accord avec vous .... Sûrement d'autres possibilités existent, à vous de les trouver !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Devil's temptations are stimulating and passionate which may be very pleasant. But there always is a downside. Playing with fire may be tempting, but the fire can become all consuming, destroying. The Devil tells us to look objectively at what we are doing.</p><p>If you're in a long term relationship, maybe you or your partner feel trapped, bored, tired. Try to talk about this to change and hopefully save the relationship. If you're single and looking for love you may well have reached the point of desperation. Remind yourself that this is utterly unattractive and do everything you can to change your outlook.</p>",
                        [GuidanceFields.WORK] : "<p>The Devil wants us to gain insight into our motives. Are we taking advantage of a colleague's gullibility or goodwill? Are we misleading colleagues or business partners? Observe yourself, be honest, don't judge, just decide to do better in the future. You want to be able to look at yourself in the mirror every day.</p><p>The Devil may also indicate that you feel trapped in your job, maybe even hate it, Observe the situation objectively: is the sense of security you get out of this job worth staying in a situation you intensely dislike?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Take a look around you, analyze the people you are surrounding yourself with, privately and in the workplace. Are they mostly positive, energetic, stimulating? Or do you have negative, cynical, criticizing people around you who havea destructive influence on you and your self-image? Surround yourself with the positive people, the ones who give you energy and inspiration. Use affirmations, meditation, … anything that can help you become and stay positive.</p>",
                    }
               },
               sp:{
                name : "Juego del Diablo", subtitle: "XV",
                zodiac: "♑ - Capricornio",
                archetype: "el manipulador",
                theme: "Restricción autoimpuesta",    
                description: "El Diablo se trata de estar atascado, de pensar en estar encadenado, de la restricción autoimpuesta",  
                questions: ["¿Estás siendo engañado, o estás engañándote a ti mismo?", "¿Puedes liberarte de lo que te limita?", "¿Te permites disfrutar de la vida?", "¿Alguna vez saltaste de la banda? "],
                
                cardoftheday: {
                    [GuidanceFields.DEFAULT] : "<p>De todas las cartas del Tarot El Diablo es el más difícil de interpretar, sobre todo porque el diablo muestra una aparición diferente a todos. Sin embargo, hay algunos aspectos generales que pueden ser nombradas; la dependencia, la apatía, la imposibilidad de tomar la iniciativa. El diablo nos dice que estamos jugando con fuego y debemos tener mucho cuidado de no quemarse. También nos dice que cuando nos sentimos obligados, encarcelado en una situación, a menudo sin saberlo, tenemos la solución dentro de nosotros mismos. En cualquier situación, no importa cuán grave, siempre hay opciones. Incluso si las personas que le rodean le están diciendo que sólo hay una manera de elegir, sólo un camino que puede tomar, asegúrese de que usted echa todo por su mismo, porque probablemente habrá otras opciones, es suficiente para encontrarlos.</p>",
                    [GuidanceFields.RELATIONSHIP] : "",
                    [GuidanceFields.WORK] : "",
                    [GuidanceFields.SPIRITUAL] : "",
                },
                },
               nl : {
                    name :  "Duivelsspel", subtitle: "XV",
                   zodiac : "♑ - Steenbok",
                   archetype : "de manipulator",
                   theme : "zelf opgelegde beperking",    
                   description : "De duivel gaat over schijnbaar vastzitten, over denken geketend te zijn, over zelf opgelegde beperking.",  
                   questions : [ "Wordt jij beduveld, of beduvel jij (je)zelf?", "Kun jij je losmaken van dat wat je beperkt?", "Sta jij jezelf toe om van het leven te genieten?", "Spring jij (nog) weleens uit de band?" ],  
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Van alle Tarotkaarten is deze het moeilijkst om te interpreteren. De Duivel betekent immers voor iedereen iets anders en ziet er daarom voor iedereen ook anders uit. Toch zijn er wel een aantal algemene kenmerken van “duivelse” invloed: lusteloosheid, afhankelijkheid, geen initiatief kunnen nemen. Vaak vertelt deze kaart je dat je met vuur aan het spelen bent en dus goed moet opletten dat je je niet brandt. Mogelijk heb je ook het gevoel dat je vastzit in een bepaalde situatie. En toch, misschien zonder het te weten, draag je de oplossing diep in jezelf.</p><p>Hoe ernstig en moeilijk je situatie ook is, je hebt altijd opties, mogelijkheden. Zelfs als de mensen om je heen je vertellen dat er maar één uitweg is, dan nog is het belangrijk dat je grondig nagaat of je niet toch nog een andere optie kan vinden. Probeer om niet in beperkingen te denken maar in mogelijkheden, dat is dé manier om De Duivel te verschalken!</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>De verleidingen van De Duivel zijn opwindend en vol passie, en dat kan natuurlijk erg leuk zijn. Toch is er altijd een negatieve kant aan. Met vuur spelen kan heel verleidelijk zijn maar dat vuur kan alles verslindend worden, vernietigend. De Duivel vertelt je dat het altijd goed is om eens objectief te kijken naar wat je aan het doen bent.</p><p>Als je een langdurige relatie hebt dan is het best mogelijk dat jij zelf of je partner (of mogelijk allebei) je gevangen voelt, verveeld, moe. Probeer hierover te praten, zo kan je de situatie veranderen en hopelijk de relatie redden.</p><p>Als je single bent en op zoek naar liefde dan is het best mogelijk dat je wanhopig aan het worden bent. Denk eraan dat wanhoop erg onaantrekkelijk is en doe er alles aan om je anders te voelen zodat je ook anders overkomt.</p>",
                       [GuidanceFields.WORK] : "<p>De Duivel wil dat je inzicht krijgt in je motieven. Ben je misbruik aan het maken van je collega's goedgelovigheid of goede wil? Ben je je collega's of zakenpartners aan het misleiden? Observeer jezelf, wees eerlijk, oordeel of veroordeel niet en als je inderdaad fout zit, beslis dan om het in de toekomst anders te doen. Je wil jezelf tenslotte elke dag in de spiegel in de ogen kunnen kijken.</p><p>Het is ook mogelijk dat De Duivel je erop wijst dat je je voelt vastzitten in je job, dat je wat je doet misschien zelfs haat. Kijk open en objectief naar je situatie: is de zekerheid van deze job voldoende belangrijk voor je om in een situatie te blijven waar je echt een hekel aan hebt?</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>Kijk eens om je heen, analyseer de mensen waar je je mee omringt, zowel privé als op het werk. Zijn het overwegend positieve, energieke, stimulerende mensen? Of heb je vooral negatieve, cynische, bekritiserende mensen om je heen die dus ook een negatieve invloed hebben op jou en je zelfbeeld?</p><p>Zorg ervoor dat je vooral omgaat met positieve mensen, die je energie en inspiratie geven, je een goed gevoel bezorgen. Gebruik affirmatietechnieken, meditatie, ... alles wat kan helpen om een positieve instelling te krijgen en te behouden.</p>",
                   }
               },
               de : {
                    name :  "Teufelsspiel", subtitle: "XV",
                   zodiac : "♑ - Steinbock",
                   archetype : "Der Manipulator",
                   theme : "Selbstauferlegte Einschränkung",    
                   description : "Der Teufel spricht vom Eindruck, blockiert zu sein, das Gefühl zu haben, in Ketten gelegt zu sein, uns selbst Einschränkungen aufzuerlegen.",  
                   questions : [ "Sind Sie getäuscht worden oder betrügen Sie sich selbst?", "Können Sie sich von dem befreien, was Sie einschränkt?", "Erlauben Sie sich, das Leben zu genießen?", "Ist es Ihnen noch möglich, sich auszutoben?" ],  
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Von allen Tarot-Karten ist der Teufel am schwierigsten zu interpretieren, vor allem deshalb, weil der Teufel sich bei jedem Menschen in einer anderen Erscheinungsform zeigt. Dennoch gibt es einige allgemeine Aspekte, die benannt werden können: Abhängigkeit, Apathie, die Unmöglichkeit Initiative zu ergreifen. Der Teufel sagt uns, dass wir mit dem Feuer spielen, und dass wir sehr vorsichtig sein sollten, damit wir uns nicht verbrennen. Die Karte sagt uns auch, dass, wenn wir das Gefühl haben, gebunden und in einer Situation gefangen zu sein, wir oft unbewusst über die Kontrolle über die Lösung in uns selbst verfügen. In jeder Situation, egal wie ernst diese ist, gibt es immer Möglichkeiten. Selbst wenn die Menschen in Ihrer Umgebung Ihnen sagen, dass es nur einen Weg gibt, nur eine Entscheidung, die Sie treffen können, überprüfen und verifzieren Sie alles selbst. Vielleicht gibt es dann doch noch andere Möglichkeiten, die Sie nur zu finden brauchen.</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>Die Teufels Versuchungen sind anregend und leidenschaftlich was sehr angenehm sein kann. Aber es gibt immer eine Kehrseite. Spiel mit dem Feuer kann verlockend sein, aber das Feuer kann auch alles in sich aufnehmen und zerstören. Der Teufel sagt uns, dass wir alles, was wir machen objektiv betrachten sollen.</p><p>Wenn Sie in einer langfristigen Beziehung sind, dann fühlen Sie oder Ihr Partner sich vielleicht gefangen, gelangweilt und müde. Versuchen Sie, darüber zu sprechen, um diese Gefühle zu ändern und die Beziehung hoffentlich zu retten. Wenn Sie alleine und auf der Suche nach Liebe sind können Sie mittlerweile auch den Punkt der Verzweiflung erreicht haben. Erinnern Sie sich daran, dass dies völlig unattraktiv ist und tun Sie alles was möglich ist, um Ihre Ausstrahlung zu ändern.</p>",
                       [GuidanceFields.WORK] : "<p>Der Teufel will, dass wir Einblick in unsere Motive gewinnen. Sind wir dabei der Gutgläubigkeit oder Firmenwert eines Kollegen auszunützen? Sind wir irreführende Kollegen oder Geschäftspartnern? Beobachten Sie sich, seien Sie ehrlich, urteilen Sie nicht, entscheiden Sie nur es in der Zukunft besser zu tun. Sie möchten doch gerne in der Lage sein, wo man sich selbst jeden Tag im Spiegel ansehen kann.</p><p>Der Teufel kann auch bedeuten, dass Sie Sich in Ihrer Arbeit gefangen fühlen, sie vielleicht sogar hassen. Beobachten Sie diese Situation objektiv: ist das Gefühl der Sicherheit, die diese Arbeit Ihnen gibt, groß genug um es wert zu sein darin hängenzubleiben obwohl Sie sich wirklich unwohl damit fühlen?</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>Werfen Sie einen Blick um Sich herum, die Menschen, womit Sie Sich umgeben, sowohl privat als in der Arbeit und analysieren Sie sie. Sind sie überwiegend positiv, energisch, und anregend? Oder haben Sie nur negative, zynische, kritisierende Menschen um Sie herum, die einen zerstörenden Einfluss auf Sie und Ihr Selbstbild haben? Umgeben Sie sich mit positiven Menschen, die Sie die Energie und Inspiration geben. Verwenden Sie Affirmationen, Meditationen, … alles, was Ihnen helfen kann positiv zu werden und zu bleiben.</p>",
                   }
               }
            },
            {
                filename: "16",
                numerology : 16,
                en : {
                    name :  "Tower", subtitle: "XVI",

                    planet : "♂ - Mars",
                    archetype : "break-through",
                    theme : "discharge",
                    description : "The Tower talks about high pressure, breaking old habits, sudden insights.",
                    questions : [ "Do you recognize the tension?", "Have you raised a wall?", "What do you what to break trhough?", "What could you do yourselve?" ],

                    bulletpoints : ["Revolution","Upheaval","Rage"],
                    birthCombinationShort: "Your path is of restoring balance in the face of transformation",
                    birthCombinationText : "You possess the ability to carry forward even in turbulent times. The Tower is a destructive force, but amidst the rubble, new paths emerge. Then the Chariot leads the way forward, maintaining a careful control over all impulses.",

                    soalPoints : [ "Plotseling", "Doorbraak", "Ineenstorting", "Ontsteltenis", "Donderslag bij heldere hemel", "Ingrijpende verandering van oude patronen", "Bevrijding" ],
                    soalText : "Doorbraak is soms nodig, patronen zijn vastgeroest en geven geen ruimte meer voor groei en nieuwe ontwikkeling.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>To describe The Tower one can use many terms and sometimes it will seem as if they are each other's opposite. The Tower can depict sudden insight but also a total lack of insight, depending on the person and the situation that person is in. The card can symbolize a traumatic experience, shock, carelessness. Actually, The Tower offers you clear insights into your own illusions, f.e. you think there are certain securities in your life and then you realize that these can suddenly and unexpectedly vanish. You are or were holding on to an illusion of security, that is what The Tower wants to tell you. Gaining this insight may give you a deep shock because you were totally convinced of your illusive beliefs. But getting rid of illusions can be a truly positive experience because it sets you free to explore new options and possibilities.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Tower may but does not necessarily indicate the end of a relationship. If you are absolutely sure you want to hold on to your partner then it is time to study all possible problems and obstacles there might be. It's also very important to find out what your partner really truly wants, do not interpret or guess but find out for sure, and then decide if you can and really want to live with these conditions.</p><p>The Tower can portray a change in thinking, acting or behavior in your relationship. Maybe you have held on to your partner too tightly or claimed him/her. You didn't freely decide to make a commitment because you were afraid to lose your partner, to be left behind. Sometimes The Tower tells you that even though you've been deeply hurt, now you are carefully opening up to a new relationship.</p>",
                        [GuidanceFields.WORK] : "<p>Use The Tower's height to climb on top of it and gain an overview on your career. Things may look totally different and turn out not to be what they seemed. Possibly there's a chance you may get fired, your expectations aren't met or your career is 'in ruins'. Take the time to work out what you would do in the event of losing your current job, who would you turn to, what resources would you use to find another job? After all, forewarned is forearmed.</p><p>You also may have to beware of conflict in the workplace.Try not to be too sensitive to what people say to you, and donot engage in discussions, heated or otherwise. </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This is a time of change, of letting go of illusions and fantasies. Life is about movement and transformation and there is no way you can stop that process so don't even try it. Be wise and go with the flow, see where life takes you and keep a positive attitude about it. Possibly some of your convictions and beliefs need to be torn down to make way for fresh, new insights. Try to enjoy this process.</p>",
                    }
                },
                fr : {
                    name : "Tour", subtitle : "XVI",
                    planet : "♂ - Mars",
                    archetype : "l’avancée",
                    theme : "décharge",
                    description : "La tour nous parle de la haute tension, la rupture de vieux schémas, de l'intuition soudaine.",
                    questions : ["Reconnaissez-vous la tension?", "Avez-vous construit un mur?", "Que voulez-vous avancer?", "Que pourriez-vous y remédier?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Pour décrire la tour, on pourrait utiliser de nombreux termes, et il semble parfois qu'ils soient contradictoires.... La tour peut être le symbole d'une intuition soudaine, mais aussi d'une absence totale d'intuition, selon la personne et la situation dans laquelle elle se trouve. Cela dépend de l'endroit où vous vous trouvez dans la tour.  En haut, en bas ou au milieu. La carte peut aussi symboliser une expérience traumatisante, un choc ou une négligence grave. En fait, la tour vous offre un aperçu clair de vos propres illusions, par exemple : Vous pensez qu'il y a certaines sécurités dans votre vie, et puis vous vous rendez compte qu'elles disparaissent soudainement et de façon inattendue. Vous avez ou aviez une illusion de sécurité. C'est ce que la Tour veut vous dire. Cette découverte peut vous causer un choc profond parce que vous étiez complètement convaincu de votre foi illusoire. Mais se libérer de ces illusions peut aussi être une expérience vraiment positive parce qu'elle vous permet de découvrir de nouvelles options et possibilités.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Tower may but does not necessarily indicate the end of a relationship. If you are absolutely sure you want to hold on to your partner then it is time to study all possible problems and obstacles there might be. It's also very important to find out what your partner really truly wants, do not interpret or guess but find out for sure, and then decide if you can and really want to live with these conditions.</p><p>The Tower can portray a change in thinking, acting or behavior in your relationship. Maybe you have held on to your partner too tightly or claimed him/her. You didn't freely decide to make a commitment because you were afraid to lose your partner, to be left behind. Sometimes The Tower tells you that even though you've been deeply hurt, now you are carefully opening up to a new relationship.</p>",
                        [GuidanceFields.WORK] : "<p>Use The Tower's height to climb on top of it and gain an overview on your career. Things may look totally different and turn out not to be what they seemed. Possibly there's a chance you may get fired, your expectations aren't met or your career is 'in ruins'. Take the time to work out what you would do in the event of losing your current job, who would you turn to, what resources would you use to find another job? After all, forewarned is forearmed.</p><p>You also may have to beware of conflict in the workplace.Try not to be too sensitive to what people say to you, and donot engage in discussions, heated or otherwise. </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This is a time of change, of letting go of illusions and fantasies. Life is about movement and transformation and there is no way you can stop that process so don't even try it. Be wise and go with the flow, see where life takes you and keep a positive attitude about it. Possibly some of your convictions and beliefs need to be torn down to make way for fresh, new insights. Try to enjoy this process.</p>",
                    }
                },
                sp:{
                    name : "Torre", subtitle: "XVI",
                    planet: "♂ - Marte",
                    archetype: "el gran avance",
                    theme: "Aprobación de la gestión",
                    description: "La Torre es sobre la alta tensión, sobre la ruptura de viejos patrones, sobre la visión repentina",
                    questions: ["¿Reconoces la tensión?", "¿Has construido un muro?", "¿Qué quieres romper?", "¿Qué podrías hacer al respecto?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Para describir la torre uno puede utilizar muchos términos y, a veces, parecerá como si estuvieran frente del otro. La torre puede representar una visión repentina, sino también una falta total de visión, dependiendo de la persona y de la situación de esa persona se encuentra. La tarjeta puede simbolizar una experiencia traumática, golpes, falta de cuidado. En realidad, la Torre le ofrece claras ideas sobre sus propias ilusiones, Fe Cree que hay ciertos valores en su vida y luego le da cuenta que estas pueden desaparecer de repente y de forma inesperada. Usted está o estaba aferrando a una ilusión de seguridad, que es lo que la Torre quiere decirle. Ganando esta idea puede darle un shock profundo porque estaba totalmente convencido de sus creencias esquivas. Pero deshacerse de las ilusiones puede ser una experiencia realmente positiva porque le hace libre a explorar nuevas opciones y posibilidades.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name :  "Toren", subtitle: "XVI",
                    planet : "♂ - Mars",
                    archetype : "de doorbraak",
                    theme : "ontlading",
                    description : "De toren gaat over hoog opgelopen spanning, over doorbreken van oude patronen, over plotseling inzicht.",
                    questions : [ "Herken je de spanning?", "Heb je een \"muur\" opgetrokken?", "Wat wil je doorbreken?", "Wat zou je hier aan kunnen doen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Je kan de betekenis van De Toren op vele manieren omschrijven en vaak lijken de termen die dan gebruikt worden met mekaar in tegenspraak. Deze kaart kan bijvoorbeeld staan voor een plots inzicht dat je krijgt maar ook voor een totaal gebrek aan inzicht. Het hangt er gewoon van af in welke situatie je je bevindt, waar je in De Toren staat (bovenaan, onderaan of misschien halfweg?).</p><p>De kaart kan ook een pijnlijke ervaring symboliseren, een schok, een gevoel alsof je hard wakker geschud wordt.</p><p>Waar het op neerkomt is dat De Toren je inzicht wil bieden in je eigen illusies. Je kan wel denken dat er een aantal zekerheden in je leven zijn, maar soms word je ermee geconfronteerd dat die zekerheden plots van je weggenomen worden. Je houdt of hield je eigenlijk vast aan een illusie van veiligheid, dàt wil De Toren je vertellen. Ook al is het schokkend om te beseffen dat niets eigenlijk zeker is, toch kan het ook behoorlijk bevrijdend zijn om je illusies los te laten. Je opent immers de weg voor een boel nieuwe mogelijkheden!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Toren kan wijzen op het einde van een relatie al is dat niet altijd zo. Als je er volkomen zeker van bent dat je bij je partner wil blijven, dan is het nu het juiste moment om alle problemen en obstakels te bestuderen en aan te pakken. Neem ook de tijd om er achter te komen wat je partner echt wil en beslis dan of je met die wensen en verlangens wil en kan leven.</p><p>De Toren symboliseert soms een verandering van denken, handelen of gedrag in je relatie. Misschien heb je je vastgeklampt aan je partner of hem/haar geclaimd. Dat kan betekenen dat je niet in volle vrijheid beslist hebt om je te engageren in de relatie omdat je bang was om je partner te verliezen, om verlaten te worden. Soms vertelt De Toren je dat je er klaar voor bent om je voorzichtig te gaan openstellen voor een nieuwe relatie, ook al ben je intens gekwetst geweest in een vorige verbintenis.</p>",
                        [GuidanceFields.WORK] : "<p>Maak gebruik van de hoogte van De Toren om naar de top te klimmen en van daaruit een overzicht te krijgen over je carrière. Het is best mogelijk dat de dingen er helemaal anders uitzien en niet datgene blijken wat je eerst dacht.</p><p>Deze kaart wijst erop dat je mogelijk risico loopt op ontslag, dat je verwachtingen niet tegemoetgekomen worden of dat je carrière echt \"een ruïne\" is. Neem de tijd om uit te zoeken wat je zou doen als je je huidige baan zou verliezen, tot wie zou je je wenden, welke bronnen zou je gebruiken om een andere baan te vinden? Tenslotte is een gewaarschuwd mens er twee waard.</p><p>Mogelijk word je op het moment op het werk geconfronteerd met conflicten. Probeer om niet te gevoelig te zijn voor wat mensen tegen je zeggen en doe niet mee met discussies, of ze nu verhit zijn of niet.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Volgens De Toren is het tijd voor verandering, om illusies en fantasieën los te laten. Leven is tenslotte bewegen, transformeren en dat proces kan je niet stoppen, zelfs al zou je het proberen. Wees verstandig en ga mee met de stroming, kijk waar je terechtkomt en zorgt dat je instelling positief is. Het is best mogelijk dat sommige van je overtuigingen vernietigd moeten worden om ruimte te maken voor frisse, nieuwe inzichten. Probeer om te genieten van dit gebeuren!</p>",
                    }
                },
                de : {
                    name :  "Turm", subtitle: "XVI",
                    planet : "♂ - Mars",
                    archetype : "Der Fortschritt",
                    theme : "Entladung",
                    description : "Der Turm spricht von Hochspannung, dem Aufbrechen alter Muster, plötzlicher Intuition...",
                    questions : [ "Erkennen Sie die Spannung?", "Haben Sie eine Mauer gebaut?", "Was wollen Sie vorantreiben?", "Was konnten Sie dagegen tun?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Um den Turm zu beschreiben, könnte man viele Begriffe verwenden, und manchmal hat es den Anschein, als ob diese gegensätzlich wären. Der Turm kann für eine plötzliche Einsicht stehen, aber auch für einen völligen Mangel an Einsicht, abhängig von der Person und der Lage, in der diese Person gerade ist. Das hängt davon ab, wo man sich im Turm befindet.  Ganz oben, ganz unten oder in der Mitte. Die Karte kann auch eine traumatische Erfahrung, einen Schock, oder grobe Nachlässigkeit symbolisieren. Eigentlich bietet der Turm Ihnen klare Einblicke in Ihre eigenen Illusionen, z.B.: Sie denken, dass es bestimmte Sicherheiten in Ihrem Leben gibt, und dann merken Sie, dass diese plötzlich und unerwartet verschwinden. Sie haben oder hatten an einer Illusion von Sicherheit festgehalten. Das ist es, was Ihnen der Turm sagen will. Diese Entdeckung kann bei Ihnen einen tiefen Schock auslösen, weil Sie von Ihrem illusorischen Glauben vollkommen überzeugt waren. Aber sich von diesen Illusionen zu befreien, kann auch eine wirklich positive Erfahrung sein, weil es Sie frei macht, neue Optionen und Möglichkeiten zu entdecken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Der Turm kann, aber muss nicht unbedingt auf das Ende einer Beziehung deuten. Wenn Sie absolut davon überzeugt sind, dass Sie zu Ihrem Partner halten wollen, dann ist jetzt die Zeit, um alle mögliche Probleme und Hindernisse zu untersuchen. Es ist auch sehr wichtig um herauszufinden, was Ihr Partner wirklich will. Sie sollten nicht interpretieren oder versuchen zu erraten, aber tatsächlich herausfinden, was verlangt wird. Dann können Sie Sich entscheiden, ob Sie wirklich unter diesen Bedingungen leben wollen.</p><p>Der Turm kann eine Veränderung im Denken, Handeln und Verhalten in Ihrer Beziehung darstellen. Vielleicht haben Sie Ihren Partner zu fest gehalten oder zu viel beansprucht. Sie haben Sich nicht freiwillig entschieden eine Verpflichtung einzugehen, weil Sie Angst hatten von Ihrem Partner, zurückgelassen zu werden oder sie/ihn zu verlieren. Manchmal sagt der Turm Ihnen, dass, obwohl Sie tief verletzt wurden, Sie Sich jetzt vorsichtig öffnen zu einer neuen Beziehung.</p>",
                        [GuidanceFields.WORK] : "<p>Benutzen Sie die Höhe des Turms nach oben zu klettern und Sich einen Überblick über Ihre Karriere zu geben. Es sieht dann ganz anders aus und es könnte sich herausstellen, dass es nicht so aussieht wie sie zu sein schien. Möglicherweise gibt es eine Chance, dass Sie gefeuert werden, Ihre Erwartungen nicht erfüllt werden oder Ihre Karriere ist \"in Trümmern\" gefallen. Nehmen Sie Sich Zeit herauszufinden, was Sie in dem Fall, dass Sie Ihre aktuelle Arbeit verlieren tun würden. An wem würden Sie Sich wenden, welche Quellen würden Sie beanspruchen um eine andere Arbeit zu finden? Immerhin: vorgewarnt ist gewappnet.</p><p>Sie müssen möglicherweise auch den Streit an der Arbeitsstelle eingehen. Seien Sie vorsichtig und nicht zu empfindlich für das was die Leute sagen. Beteiligen Sie nicht an Diskussionen, erhitzt oder anderweitig.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Dies ist eine Zeit der Veränderung, des Loslassens von Illusionen und Fantasien. Das Leben handelt über Bewegung und Veränderung und es gibt keine Möglichkeit, diesen Prozess zu stoppen. Sie sollen es nicht einmal versuchen. Seien Sie klug und schwimmen Sie mit dem Strom um zu sehen, wo das Leben Sie hinführt und behalten eine positive Einstellung. Möglicherweise müssen Sie Sich von einigen Ihrer Überzeugungen und Glaubenssätze verabschieden, damit für frische Platz gemacht werden kann. Versuchen Sie es, diesen Prozess zu genießen.</p>",
                    }
                }
            },
            {
                filename: "17",
                numerology : 17,
                en : {
                    name : "Star", subtitle: "XVII",

                    zodiac : "♒ - Aquarius",
                    archetype : "full-blown truth",
                    theme : "things are the way they are",    
                    description : "The Star talks about the actual self, the way it is, the full blown truth.",
                    questions : [ "How visible are you?", "Can you show the real you?", "Are you vulnerable doing so?", "Which part of yourself could you show?" ],

                    bulletpoints : ["Hope","Faith","Rejuvenation"],
                    birthCombinationShort: "This path gives you hope as the source of your courage.",
                    birthCombinationText : "A well known icon of wishing, the Star represents hope and an overall optimistic outlook on life. This stems from a great inner Strength, which can carry you through the hard times of life.",

                    soalPoints : [ "Nieuwe mooie toekomst", "Hoop", "Goede ster", "Geluk en vervulling" ],
                    soalText : "Hoop is dat waar je jezelf op kunt richten om met kracht de richting in te gaan waar je hart vol van is. Deze ster wijst je een goede weg, zij is het \"lichtpuntje\" waar je jezelf vol vertrouwen op kunt richten. Later in de tijd zul je merken dat deze richting goed voor je was.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Star is a card of hope, inspiration, wisdom and higher insight. It tells you that the results of what you are working on right now, will shed their light far into the future.  You are serving the higher good for all concerned. The Star is, along with The High Priestess and The Hierophant, a card that promises protection. Feel free to be hopeful about the future and have faith that your needs will be met, be they material, spiritual or emotional. Think big, think positive.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In personal relationships The Star symbolizes promising encounters. Maybe you will meet someone new, but it's also possible you will see someone you already know in a totally new light. This encounter will not be fleeting, it will stay with you far into the future. Relationships depicted by The Star are deemed to be under a lucky star. No matter if you meet someone new or if you find new dimensions in your current partner, these are wonderful times.</p>",
                        [GuidanceFields.WORK] : "<p>The Star indicates that you are starting a new task or even a new job. It's also possible that your own plans or proposals are finally set in motion.</p><p>If you are not happy with your current job this is the perfect time to go on a job hunt.Just make sure you are open to and ready for new opportunities. Prepare yourself for the challenge, brush up your cv, think about tactics... When you draw The Star, prospects are very bright.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Star tells you that you are truly and deeply in touch with your spiritual side. Now is the perfect time to reach out and touch the people around you. You have a lot of wisdom, kindness, serenity and happiness to share. And while you are freely giving and sharing, you will realize that you are being rewarded for your generosity over and over again. The Star tells you that you are ready to let your inner light shine.</p>",
                    }
               },
               fr : {
                    name : "Étoile ", subtitle : " XVII ",
                    zodiac : "♒ - Verseau",
                    archetype : "la pure vérité",
                    theme : "Les choses sont ce qu’elles sont",    
                    description : "L'étoile nous parle du Moi essentiel, du Moi tel qu'il est, à propos de notre pure vérité.",
                    questions : ["Dans quelle mesure êtes-vous visible", "Pouvez-vous montrer votre Moi essentiel", "Êtes-vous vulnérable dans cette situation", "Que pourriez-vous montrer de vous-même?" ],
                
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>L'étoile est une carte d'espoir, d'inspiration, de sagesse et de perspicacité supérieure. Il vous dit que les choses sur lesquelles vous travaillez jetteront leur lumière loin dans le futur. Ils servent les valeurs supérieures de toutes les personnes concernées. L'étoile, avec la Grande Prêtresse et l’Hiérophante, est une carte qui promet la protection. N'hésitez pas à regarder l'avenir avec espoir et à avoir confiance que vos besoins seront satisfaits, qu'ils soient matériels, spirituels ou émotionnels. Voyez grand et positif.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In personal relationships The Star symbolizes promising encounters. Maybe you will meet someone new, but it's also possible you will see someone you already know in a totally new light. This encounter will not be fleeting, it will stay with you far into the future. Relationships depicted by The Star are deemed to be under a lucky star. No matter if you meet someone new or if you find new dimensions in your current partner, these are wonderful times.</p>",
                        [GuidanceFields.WORK] : "<p>The Star indicates that you are starting a new task or even a new job. It's also possible that your own plans or proposals are finally set in motion.</p><p>If you are not happy with your current job this is the perfect time to go on a job hunt.Just make sure you are open to and ready for new opportunities. Prepare yourself for the challenge, brush up your cv, think about tactics... When you draw The Star, prospects are very bright.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Star tells you that you are truly and deeply in touch with your spiritual side. Now is the perfect time to reach out and touch the people around you. You have a lot of wisdom, kindness, serenity and happiness to share. And while you are freely giving and sharing, you will realize that you are being rewarded for your generosity over and over again. The Star tells you that you are ready to let your inner light shine.</p>",
                    }
               },
               sp:{
                name : "Estrella", subtitle: "XVII",
                zodiac: "♒ - Acuario",
                arquetipo: "la verdad desnuda",
                theme: "es como es",    
                description: "La Estrella es sobre el ser esencial, sobre lo que es, sobre la verdad desnuda",
                questions: ["¿Qué tan visible eres?", "¿Puedes mostrar tu ser esencial?", "¿Eres vulnerable en esto?", "¿Qué podrías mostrar de ti mismo?" ],
                
                cardoftheday: {
                    [GuidanceFields.DEFAULT] : "<p>La estrella es una tarjeta de esperanza, inspiración, sabiduría y mayor penetración. Le dice que los resultados de lo que se está trabajando en este momento, se derramaron su luz en el futuro. Usted está sirviendo el bien mayor para todos los interesados. La estrella es, junto con La Sacerdotisa y El Sumo Sacerdote, una tarjeta que promete protección. Siéntase libre de ser optimista sobre el futuro y tener fe en que se cumplan sus necesidades, ya sean materiales, espirituales o emocionales. Piensa en grande, pensar en positivo.</p>",
                    [GuidanceFields.RELATIONSHIP] : "",
                    [GuidanceFields.WORK] : "",
                    [GuidanceFields.SPIRITUAL] : "",
                },
                },
               nl : {
                    name :  "Ster", subtitle: "XVII",
                   zodiac : "♒ - Waterman",
                   archetype : "de naakte waarheid",
                   theme : "het is zoals het is",    
                   description : "De ster gaat over het wezenlijke zelf, over het is zoals het is, over de naakte waarheid.",
                   questions : [ "Hoe zichtbaar ben jij?", "Kun jij je wezenlijke zelf laten zien?", "Ben je daarin kwetsbaar?", "Wat zou jij van jezelf kunnen tonen?" ], 
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Deze kaart staat voor hoop, inspiratie, wijsheid en diepe inzichten. De Ster ligt er om je te vertellen dat je harde werk vruchten zal afwerpen tot ver in de toekomst. Je werkt niet enkel voor jezelf maar ook voor het welzijn van alle betrokkenen. De Ster is, samen met De Hogepriesteres en De Hogepriester, een kaart die bescherming belooft. Voel je vrij om hoop te hebben voor de toekomst en geloof erin dat je datgene zal krijgen wat je nodig hebt, niet enkel op materieel vlak maar ook spiritueel en emotioneel. Denk ruim, denk positief!</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>In verband met relaties staat De Ster voor ontmoetingen. Misschien komt er een nieuw iemand op je weg, maar het is ook mogelijk dat je iemand die je al kent in een totaal ander licht zal gaan zien. Deze ontmoeting waarvan sprake is niet zomaar oppervlakkig en voorbijgaand maar zal je tot ver in de toekomst bijblijven. Relaties die in verband staan met De Ster ontwikkelen zich vaak onder een goed gesternte. Of je nu een nieuwe ontmoeting hebt of je vindt nieuwe dimensies in je huidige partner, het zijn fantastische tijden op relatievlak.</p>",
                       [GuidanceFields.WORK] : "<p>Volgens De Ster begin je binnenkort aan een nieuwe taak of zelfs aan een nieuwe baan. Het is ook mogelijk dat projecten of plannen die jij voorgesteld hebt, nu eindelijk uitgevoerd worden.</p><p>Als je niet tevreden bent met je huidige job dan is het nu de perfecte tijd om op zoek te gaan naar een andere. Het is wel belangrijk dat je open staat en klaar bent voor nieuwe kansen. Zorg dat je zin hebt in de uitdaging, poets je CV op, bestudeer sollicitatietechnieken...</p><p>Als je De Ster trekt dan zijn je vooruitzichten over het algemeen erg positief.</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>De Ster vertelt je dat je oprecht en diepgaand contact hebt met je spirituele kant. Mede daarom is het een perfecte tijd om de hand te reiken naar de mensen om je heen en ook echt contact te maken. Je hebt veel wijsheid, gelijkmoedigheid, vreugde, warmte te delen. En terwijl je dat allemaal aan het uitdelen en delen bent, zal je merken dat je telkens weer beloond wordt voor je vrijgevigheid.</p><p>Volgens De Ster ben je er klaar voor om je innerlijke licht te laten schijnen op de mensen om je heen.</p>",
                   }
               },
               de : {
                   name: "Stern", subtitle: "XVII",
                   zodiac : "♒ - Wassermann",
                   archetype : "Die reine Wahrheit",
                   theme : "Die Dinge sind, was sie sind.",    
                   description : "Der Stern spricht über das wesentliche Selbst, das Selbst, wie es ist, über unsere reine Wahrheit.",
                   questions : [ "Inwieweit sind Sie sichtbar?", "Können Sie Ihr essentielles Selbst zeigen?", "Sind Sie in dieser Situation verletzlich?", "Was können Sie von sich selbst zeigen?" ], 
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Der Stern ist eine Karte der Hoffnung, der Inspiration, der Weisheit und der höheren Einsicht. Er sagt Ihnen, dass die Dinge, an denen Sie gerade arbeiten, ihr Licht weit in die Zukunft werfen werden. Sie dienen den höheren Werten aller Beteiligten. Der Stern ist zusammen mit der Hohepriesterin und dem Hierophanten eine Karte, die Schutz verspricht. Fühlen Sie sich frei, hoffnungsvoll in die Zukunft zu blicken und haben Sie den Glauben, dass Ihre Bedürfnisse erfüllt werden, seien es materielle, geistige oder emotionale. Denken Sie groß und positiv.</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>In persönlichen Beziehungen symbolisiert der Stern vielversprechende Begegnungen. Vielleicht werden Sie jemanden treffen, der bis jetzt noch unbekannt ist, aber es ist auch durchaus möglich, das es hier jemanden betrifft, den Sie bereits kennen aber auf einmal in einem völlig neuen Licht sehen. Diese Begegnung wird nicht flüchtig sein aber ganz weit mit Ihnen in die Zukunft gehen. Beziehungen, die von dem Stern dargestellt werden, gelten als stehen sie unter einem guten Stern. Egal, ob Sie jemanden treffen der bis jetzt noch unbekannt war, oder ob Sie eine neue Dimension in Ihrem aktuellen Partner finden, es werden wunderbare Zeiten.</p>",
                       [GuidanceFields.WORK] : "<p>Der Stern zeigt an, dass Sie Im Anfang einer neuen Aufgabe oder sogar einer neuen Arbeit sind. Es ist auch möglich, dass sich schließlich Ihre eigenen Pläne oder Vorschläge in Bewegung setzen.</p><p>Wenn Sie nicht in Ihrer aktuellen Arbeit glücklich sind, ist dies die perfekte Zeit, um auf eine andere Arbeit zu jagen. Stellen Sie nur sicher, dass Sie offen und bereit sind für neue Möglichkeiten. Bereiten Sie Sich vor auf die Herausforderung, putzen Sie Ihren Lebenslauf, und denke über den Taktik nach… Wenn Sie den Stern ziehen, sind die Voraussichten hell und klar.</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>Der Stern zeigt Ihnen, dass Sie jetzt tief und wirklich in Kontakt sind mit Ihrer spirituellen Seite. Jetzt ist die perfekte Zeit die Hände auszustrecken und die Leute um Sie herum zu berühren. Sie haben eine Menge Weisheit, Freundlichkeit, Ruhe und Glück zu teilen. Und während Sie diese freigeben um zu teilen, werden Sie feststellen, dass Sie für Ihre Großzügigkeit mehr als belohnt werden und das immer wieder. Der Stern sagt, dass Sie bereit sind Ihr inneres Licht leuchten zu lassen.</p>",
                   }
               }
            },
            {
                filename: "18",
                numerology : 18,
                en : {
                    name : "Moon", subtitle: "XVIII",

                    zodiac : "♓ - Pisces",
                    archetype : "the night",
                    theme : "handle insecurity",    
                    description : "The Moon talks about confusion, mood swings, feeling you need to go without knowing where to (yet).",
                    questions : [ "Do you feel you need to leave?", "Are you insecure?", "Dare you go your own way?" ],

                    bulletpoints : ["Illusion","Phantoms","Dreams"],
                    birthCombinationShort: "Your path is finding the clarity in the darkness.",
                    birthCombinationText : "As a Hermit, you tend to be solitary, preferring to follow your own inner voice and intelligence. You use this to find your path through the darkness and dreams that the Moon brings.",

                    soalPoints : [ "Duisternis", "Schaduwzijde", "Dromen", "Angsten", "Onzekerheid", "Bange voorgevoelens" ],
                    soalText : "Duisternis is het donker waardoor je geen helder (in)zicht hebt in zaken. Je bent bang en onzeker omdat je niet weet waar je aan toe bent. Ergens in jouw verleden heeft deze angst zich diep genesteld in je.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Moon tells you to explore the mysterious world of your psyche and intuition. The bright side of The Moon symbolizes romantic dreams, vivid fantasies and profound emotions.  The dark side refers to forces within you like fears, uncertainty, hesitation to explore the yet unknown, that are limiting you, maybe even unconsciously. Being confronted with your light and dark side may tip you out of balance, confuse you. Right now it may be hard to understand yourself and the people around you. Don't push yourself or others but take the time to regain balance and insight.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Where personal relationships are concerned, The Moon tells you that you tend to lose yourself in romantic dreams, you sometimes even build castles in the air. You are straying too far from reality, possibly because you are insecure and frightened. The Moon also symbolizes relationships defined by jealousy and fear of abandonment. This may not be a good time to make decisions concerning relationships, be it to end an existing one or to start a new one. Try to treat your partner primarily as a friend, in full confidence they have the best of intentions about you.</p>",
                        [GuidanceFields.WORK] : "<p>The Moon tells you there may be a reason to be afraid or unsettled. Possibly you are not certain you will be able to complete a certain task, or you are afraid people will see through you and realize you're not as good as you seemed to be. There's one thing for certain: you cannot run away from your fears. Take a deep breath, recognize your anxieties and confront them. Analyze the situation and discover if there really are things to be afraid of or if maybe you just created the fear(s) all by yourself. Often, when you look fear straight in the eye, it disappears.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Moon tells you that you are living a very spiritual phase.Now is a good time to have your cards read, get a Reiki treatment, a chakra healing, … You are spiritually open and alert, you take the time to pay attention to your thoughts, feelings and ideas. You are also very sensitive to messages that come to you from spiritual or even unknown sources. If you have to make decisions or evaluate people and relationships, go with your gut feeling, trust your instincts.</p>",
                    }
                },
                fr : {
                    name : "Lune", subtitle : "XVIII",
                    zodiac : "♓ - Poisson",
                    archetype : "la nuit",
                    theme : "faire face à l'incertitude",    
                    description : "La Lune, nous parle de la confusion, des changements d'humeur, du sentiment qu'il faut démarrer, sans savoir (encore) ce que l'on peut rencontrer...",
                    questions : ["Vous sentez-vous obligé de continuer votre chemin?", "Êtes-vous incertain?", "Osez-vous suivre votre propre chemin?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La lune vous invite à explorer le monde mystérieux de la psyché et de l'intuition. Le côté lumineux de la lune symbolise les rêves romantiques, les fantasmes vifs et les émotions profondes. Le côté obscur fait référence à des forces en vous comme la peur, l'insécurité et l'hésitation à découvrir l'inconnu. Ce sont des forces qui vous limitent, peut-être même inconsciemment. Lorsque vous êtes confronté à vos côtés clairs et sombres, vous pouvez devenir déséquilibré, vous pouvez vous sentir confus.  À ce moment, il peut être difficile de se comprendre soi-même et les gens autour de soi. Ne poussez, ni vous, ni les autres et prenez le temps de retrouver votre équilibre et votre perspicacité.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Where personal relationships are concerned, The Moon tells you that you tend to lose yourself in romantic dreams, you sometimes even build castles in the air. You are straying too far from reality, possibly because you are insecure and frightened. The Moon also symbolizes relationships defined by jealousy and fear of abandonment. This may not be a good time to make decisions concerning relationships, be it to end an existing one or to start a new one. Try to treat your partner primarily as a friend, in full confidence they have the best of intentions about you.</p>",
                        [GuidanceFields.WORK] : "<p>The Moon tells you there may be a reason to be afraid or unsettled. Possibly you are not certain you will be able to complete a certain task, or you are afraid people will see through you and realize you're not as good as you seemed to be. There's one thing for certain: you cannot run away from your fears. Take a deep breath, recognize your anxieties and confront them. Analyze the situation and discover if there really are things to be afraid of or if maybe you just created the fear(s) all by yourself. Often, when you look fear straight in the eye, it disappears.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Moon tells you that you are living a very spiritual phase.Now is a good time to have your cards read, get a Reiki treatment, a chakra healing, … You are spiritually open and alert, you take the time to pay attention to your thoughts, feelings and ideas. You are also very sensitive to messages that come to you from spiritual or even unknown sources. If you have to make decisions or evaluate people and relationships, go with your gut feeling, trust your instincts.</p>",
                    }
                },
                sp:{
                    name : "Luna", subtitle: "XVIII",
                    zodiac: "♓ - Pesca",
                    archetype: "la noche",
                    theme: " Lidiar con la incertidumbre",    
                    description: "La Luna es sobre la confusión, sobre el cambio de humor, sobre el sentimiento de que tienes que seguir tu camino, sin (todavía) saber lo que puedes encontrar",
                    questions: ["¿Sientes que tienes que seguir tu camino?", "¿Estás inseguro?", "¿Te atreves a seguir tu propio camino?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La Luna le dice  explorar el misterioso mundo de su psique y la intuición. El lado bueno de La Luna simboliza sueños románticos, vívidas fantasías y emociones profundas. El lado oscuro se refiere a las fuerzas dentro de ti como el temor, la incertidumbre, vacilación para explorar la aún desconocida, que limitan, tal vez incluso de manera inconsciente. Ser confrontado con su luz y el lado oscuro que podría volcar fuera de balance, confundirte. En este momento puede ser difícil de entender a sí mismo y a las personas que te rodean. No empuje usted mismo a otros, pero tómese el tiempo para recuperar el equilibrio y la visión.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name :  "Maan", subtitle: "XVIII",
                    zodiac : "♓ - Vissen",
                    archetype : "de nacht",
                    theme : "omgaan met onzekerheid",    
                    description : "De maan gaat over verwarring, over wisselende stemmingen, over het voelen dat je op weg dient te gaan (nog) niet wetend wat je tegen kunt komen.",
                    questions : [ "Voel je dat je op weg dient te gaan?", "Ben je onzeker?", "Durf jij je eigen weg te gaan?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Maan spoort je aan om de geheimzinnige wereld van je geest en je intuïtie te onderzoeken. De heldere kant van De Maan staat voor romantische dromen, levendige fantasieën en diepgaande emoties. De donkere kant staat voor krachten binnenin je zoals angsten, onzekerheid, huiverigheid om onbekende dingen te onderzoeken. Die laatste zijn allemaal zaken die jou, misschien zelfs onbewust, beperken en tegenhouden. Geconfronteerd worden met je lichte èn donkere kant kan je uit evenwicht brengen, je verwarren. Op dit moment hebt je het misschien moeilijk om niet alleen jezelf te begrijpen maar ook de mensen om je heen. Zet jezelf en anderen niet teveel onder druk maar neem de tijd om terug in evenwicht te komen en de nodige inzichten te verwerven.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wat je relaties betreft vertelt De Maan je dat je op het ogenblik de neiging hebt om jezelf te verliezen in romantische dromen en het bouwen van luchtkastelen. Je dwaalt nogal ver af van de realiteit, misschien omdat je diep vanbinnen onzeker en angstig bent.</p><p>De Maan kan ook staan voor relaties die bepaald of gekleurd worden door jaloezie en verlatingsangst. Het is nu geen goede tijd om belangrijke beslissingen te nemen in verband met je relatie(s), of het er nu om gaat een relatie te beëindigen of om er één te beginnen. Probeer in elk geval je partner in eerste instantie te behandelen als vriend(in), met het volste vertrouwen in zijn/haar goede bedoelingen.</p>",
                        [GuidanceFields.WORK] : "<p>De Maan vertelt je dat er misschien een reden is om angstig of onzeker, ongedurig te zijn. Het is mogelijk dat je er niet zeker van bent dat je een bepaalde taak wel kan afwerken, je bent bang om doorzien te worden, of je begint te beseffen dat je niet zo goed bent als je dacht te zijn. Eén ding is een feit: je kan niet weglopen van deze angsten. Haal eens diep adem, (h)erken je angsten en pak ze aan. Analyseer de situatie en probeer te ontdekken of er echt dingen zijn waar je bang voor hoort te zijn of dat je die angsten misschien helemaal zelf gecreëerd hebt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Volgens De Maan maak je momenteel een heel spirituele fase door. Het is nu de juiste tijd om je kaarten te laten leggen, een Reikihealing te krijgen, een Chakrabehandeling, ... Op spiritueel vlak ben je open en alert, je neemt de tijd om aandacht te besteden aan je gevoelens, gedachten en ideeën. Je bent ook erg gevoelig voor boodschappen die je doorkrijgt vanuit spirituele of zelfs onbekende bron. Als je beslissingen moet nemen of mensen en relaties moet evalueren, ga dan mee met je buikgevoel, vertrouw je intuïtie en je instinct.</p>",
                    }
                },
                de : {
                    name : "Mond", subtitle: "XVIII",
                    zodiac : "♓ - Fische",
                    archetype : "Die Nacht",
                    theme : "Umgang mit Ungewissheit",    
                    description : "Der Mond spricht von der Verwirrung, den Stimmungsschwankungen, dem Gefühl, dass man anfangen muss, ohne zu wissen, was einem begegnen kann.....",
                    questions : [ "Fühlen Sie sich verpflichtet, Ihre Reise fortzusetzen?", "Sind Sie unsicher?", "Wagen Sie es, Ihren eigenen Weg zu gehen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der Mond lädt Sie ein, die geheimnisvolle Welt der Psyche und der Intuition zu erforschen. Die helle Seite des Mondes symbolisiert romantische Träume, lebhafte Fantasien und tiefe Emotionen. Die dunkle Seite bezieht sich auf Kräften in Ihnen wie Angst, Unsicherheit und Zögerlichkeit, das Unbekannte zu entdecken. Es handelt sich um Kräfte, die Sie einschränken, vielleicht sogar unbewusst. Wenn Sie mit Ihren hellen und dunklen Seiten konfrontiert werden, können Sie aus dem Gleichgewicht geraten, Sie können sich verwirrt fühlen.  In diesem Moment kann es schwierig sein, sich selbst und die Menschen in Ihrer Umgebung zu verstehen. Treiben Sie sich oder Andere nicht an und nehmen Sie die Zeit, um Gleichgewicht und Einsicht wieder zu finden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wo es persönliche Beziehungen betrifft, sagt der Mond Ihnen, dass Sie dazu neigen Sich Selbst zu verlieren in romantischen Träumen, Sie manchmal sogar Luftschlösser bauen. Sie sind zu weit abgewichen von der Wirklichkeit, vielleicht weil Sie Sich unsicher und ängstlich fühlen. Der Mond symbolisiert auch Beziehungen, die durch Eifersucht und Angst vor dem Verlassen erfüllt sind. Es ist keine gute Zeit um Entscheidungen über Beziehungen zu treffen, sei es, um eine vorhandene zu beenden oder eine neue zu starten. Versuchen Sie, Ihr Partner in erster Linie als Ihren besten Freund zu behandeln, im vollen Vertrauen. Er/sie hat meistens das Beste mit Ihnen vor.</p>",
                        [GuidanceFields.WORK] : "<p>Der Mond sagt Ihnen, es könnte einen Grund zur Angst oder Unsicherheit geben. Möglicherweise sind Sie nicht sicher, ob Sie eine bestimmte Aufgabe erfüllen können, oder Sie haben Angst, dass die Leute Sie durchschauen werden und feststellen können, Sie sind nicht so gut, wie Sie zu sein schienen. Eine Sache ist sicher: Sie können nicht weglaufen von Ihren Ängsten. Atmen Sie tief ein, erkennen Sie Ihre Ängste und konfrontieren Sie Sich damit. Analysieren Sie die Situation und finden Sie heraus, ob es wirklich Sachen gibt, wofür Sie Angst haben müssen oder ob es vielleicht gerade die Angst selber ist. Oft, wenn Sie Angst in die Augen schauen, verschwindet sie.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Der Mond sagt Ihnen, dass Sie Sich in einer sehr spirituellen Phase Ihres Lebens befinden. Dies ist ein guter Zeitpunkt, um die Karten zu legen, eine Reiki-Behandlung zu untergehen oder eine Chakra-Heilung, … Sie sind geistig offen und wach. Sie nehmen die Zeit auf Ihre Gedanken zu hören, auf Ihren Gefühlen und Ideen. Sie sind auch sehr empfindlich für Nachrichten, die von spirituellen oder sogar unbekannten Quellen stammen. Wenn Sie Entscheidungen treffen müssen oder Menschen oder eine Beziehung beurteilen müssen, hören Sie auf Ihrem Bauchgefühl, vertrauen Sie auf Ihrem Instinkt.</p>",
                    }
                }
            },
            {
                filename: "19",
                numerology : 19,
                en : {
                    name : "Sun", subtitle: "XIX",

                    zodiac : "☉ - The Sun",
                    archetype : "the day",
                    theme : "Be your true self",    
                    description : "The Sun talks about radiating, light and warmth, energy, the true self, how you actually are.",
                    questions : [ "Do you possess that much energy?", "Or do you rather feel burned our?", "Do you guard your boundaries?", "Can you be your true self?" ],

                    bulletpoints : ["Peace","Abundance","Joy"],
                    birthCombinationShort: "Your path is adapting to change with grace, abundance and peace.",
                    birthCombinationText : "The Wheel of Fortune card keeps you in touch with ever changing world around you. You find it easy to adapt to whatever comes your way, thanks to the Magician’s powers. And much like the Sun, you always have a bright outlook on life, bringing you joy and contentment.",

                    soalPoints : [ "Levensvreugde", "Kracht", "Vitaliteit", "Scheppingskracht", "Creativiteit", "Herboren", "Voelen", "Warmte", "Helder licht" ],
                    soalText : "Levenslust is sterke energie die je de kracht geeft om alles positief te bekijken, te genieten en alles er uit te halen wat er in zit. Het zorgt voor vrolijke, zonnige en gelukkige tijden waarbij je intens van het leven kunt genieten. ",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Sun radiates warmth, strength, optimism and joy of life. Her light also shines on your darker sides to make it easier for you to get clarity about your worries, frustrations and annoyances. This card helps you to see the sunny side of life. The Sun motivates and encourages you to let the light shine into your life, to try out new experiences, to take a break, to relax. After doing this you will feel more free, more vital and energetic. Now is the time to take life 'the sunny side up'!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Sun symbolizes the warmth and pleasure of being in a committed relationship. It depicts unconditional love, we give and keep giving without wanting something in return. Chances are that you're able to fulfill your partner's deepest wishes. But be careful not to become clingy or needy, your partner may need a lot of space right now, and if you're honest with yourself, you can use some space yourself too.</p>",
                        [GuidanceFields.WORK] : "<p>The Sun tells you that you enjoy your work and on top of that, you are very successful at it right now. You radiate positive energy and your kindness helps you attain your goals in a natural and spontaneous way. Be careful to stay close to your feelings and instincts, or you might become a bit hypocritical. And no matter how well things are going for you, keep your ego under control by sharing your success with your colleagues.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Sun invites you to take a spiritual 'vacation', to look around and try out different approaches and interpretations concerning the true meaning of life. The Sun may also tell you that you might truly grasp the beauty and enjoyment that life can bring you. One only has to really look around to find them. Share this discovery with all the people around you, so they can have a great time too.Also make sure you don't forget all the (sometimes little) things that bring quality to your life.</p>",
                    }
               },
               fr : {
                    name : "Soleil", subtitle : "XIX",
                    zodiac : "☉ - Le soleil",
                    archetype : "le jour",
                    theme : "être comme vous êtes réellement",    
                    description : "Le Soleil nous parle de rayons, de lumière et de chaleur, d'énergie, d'énergie propre, de son être réel.",
                    questions : ["Avez-vous tellement d'énergie", "Ou vous sentez-vous épuisé?", "Gardez-vous vos limites?", "Pouvez-vous simplement être vous-même?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le soleil rayonne la chaleur, la force, l'optimisme et la joie de vivre. Votre lumière brille également sur vos côtés ombragés, ce qui vous permet de mieux comprendre vos soucis, vos frustrations et votre colère. Cette carte vous aide à voir le côté positif de la vie. Le soleil vous motive et vous invite à laisser la lumière briller sur votre vie, à faire de nouvelles expériences et à faire une pause. Oubliez toutes les choses matérielles pendant un certain temps et profitez des choses intangibles comme l'amour et l'amitié. Après avoir fait cela, vous vous sentirez plus libre, plus énergique et plein de vie. C'est le moment de regarder la vie du bon côté !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Sun symbolizes the warmth and pleasure of being in a committed relationship. It depicts unconditional love, we give and keep giving without wanting something in return. Chances are that you're able to fulfill your partner's deepest wishes. But be careful not to become clingy or needy, your partner may need a lot of space right now, and if you're honest with yourself, you can use some space yourself too.</p>",
                        [GuidanceFields.WORK] : "<p>The Sun tells you that you enjoy your work and on top of that, you are very successful at it right now. You radiate positive energy and your kindness helps you attain your goals in a natural and spontaneous way. Be careful to stay close to your feelings and instincts, or you might become a bit hypocritical. And no matter how well things are going for you, keep your ego under control by sharing your success with your colleagues.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Sun invites you to take a spiritual 'vacation', to look around and try out different approaches and interpretations concerning the true meaning of life. The Sun may also tell you that you might truly grasp the beauty and enjoyment that life can bring you. One only has to really look around to find them. Share this discovery with all the people around you, so they can have a great time too.Also make sure you don't forget all the (sometimes little) things that bring quality to your life.</p>",
                    }
               },
               sp:{
                name : "Sol", subtitle: "XIX",
                zodiac: "☉ - El sol",
                archetype: "el día",
                theme: "ser como realmente eres",    
                description: "El Sol es sobre los rayos, sobre la luz y el calor, sobre la energía, sobre tu verdadero yo, sobre tu propio ser real",
                questions: ["¿Tienes tanta energía?", "¿O te sientes agotado?", "¿Guardas tus límites? ¿Puedes ser tú mismo?" ],       
                
                cardoftheday: {
                    [GuidanceFields.DEFAULT] : "<p>El Sol irradia calidez, fuerza, optimismo y alegría de vivir. Su luz brilla también en sus lados más oscuros para hacer más fácil para usted para obtener claridad acerca de sus preocupaciones, frustraciones y molestias. Esta tarjeta le ayuda a ver el lado soleado de la vida. El Sol motiva y le anima a dejar que la luz brille en su vida, para probar nuevas experiencias, para tomar un descanso, para relajarse. Después de hacer esto, usted se sentirá más libre, más vital y enérgico. Ahora es el momento de tomar la vida 'el lado soleado para arriba \"!</p>",
                    [GuidanceFields.RELATIONSHIP] : "",
                    [GuidanceFields.WORK] : "",
                    [GuidanceFields.SPIRITUAL] : "",
                },
                },
               nl : {
                    name :  "Zon", subtitle: "XIX",
                   zodiac : "☉ - De zon",
                   archetype : "de dag",
                   theme : "zijn zoals je echt bent",    
                   description : "De zon gaat over stralen, over licht en warmte, over energie, over je ware zelf, over je eigen werkelijke zijn.",
                   questions : [ "Heb jij zoveel energie?", "Of voel jij je juist opgebrand?", "Bewaak jij je grenzen?", "Kun jij gewoon jezelf zijn?" ],                     
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Koester je in De Zon en je voelt warmte, energie, optimisme en vreugde. De Zon laat haar licht schijnen op jouw donkere kanten zodat jij meer duidelijkheid krijgt over je zorgen en frustraties. En zo krijg je meer ruimte om de positieve kanten van het leven te ervaren. De Zon motiveert je, ze moedigt je aan om haar licht in je leven te laten schijnen, om nieuwe dingen uit te proberen, even stil te staan en te genieten, om je te ontspannen. Denk even wat minder aan materiële zaken maar laat je vooral leiden door het niet tastbare, door vriendschap, liefde, positieve energie. Geniet van De Zon en je zal merken dat je je vrijer voelt, levendiger en energieker. Nu is het juiste moment om het leven van de zonnige kant te bekijken. Laat het zonlicht binnen!</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>De Zon symboliseert de warmte en vreugde die het je geeft als je in een toegewijde relatie zit. De kaart staat voor onvoorwaardelijke liefde, we geven en blijven geven zonder dat we er iets voor in de plaats willen. Nu is het echt waarschijnlijk dat je de diepste wensen en verlangens van je partner vervult.</p><p>Maar let erop dat je niet te aanhankelijk of behoeftig wordt, want je partner heeft op dit ogenblik echt veel ruimte nodig. En als je eerlijk bent met jezelf, dan weet je dat jij af en toe ook wel wat ruimte kan gebruiken.</p>",
                       [GuidanceFields.WORK] : "<p>De Zon vertelt je dat je op het ogenblik echt kan genieten van je werk. En er is meer dan dat, je bent waarschijnlijk ook erg succesvol. Je straalt positieve energie uit en je vriendelijkheid helpt je om je doelen te bereiken op een natuurlijke en spontane manier. Zorg ervoor dat je erg dicht bij je gevoelens en instincten blijft, want anders zou je een beetje hypocriet, onoprecht kunnen worden. En gelijk hoe fantastisch de dingen voor jou aan het lopen zijn, houd je ego onder controle door je succes te delen met je collega's.</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>De Zon nodigt je uit om spiritueel \"met vakantie\" te gaan, om rond te kijken en allerlei verschillende benaderingen en interpretaties uit te proberen, en dit in verband met alles dat te maken heeft met de ware zin van het leven. Het is ook mogelijk dat De Zon je vertelt dat je op dit moment echt doorhebt hoeveel schoonheid en vreugde het leven je kan brengen, het enige wat een mens daarvoor moet doen is echt rondkijken en zo deze dingen ontdekken.</p><p>Deel je ontdekking met de mensen om je heen, dan kunnen zij ook een fantastische tijd hebben. Zorg ervoor dat je oog blijft hebben voor die dingen die echt kwaliteit toevoegen aan je leven.</p>",
                   }
               },
               de : {
                    name :  "Sonne", subtitle: "XIX",
                   zodiac : "☉ - Die Sonne",
                   archetype : "Der Tag",
                   theme : "So zu sein, wie Sie wirklich sind.",    
                   description : "Die Sonne spricht über Strahlen, Licht und Wärme, Energie, saubere Energie, ihr wahres Wesen.",
                   questions : [ "Haben Sie so viel Energie?", "Oder fühlen Sie sich erschöpft?", "Respektieren Sie Ihre Grenzen?", "Können Sie einfach sich selbst sein?" ],                     
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Die Sonne strahlt Wärme, Kraft, Optimismus und Lebensfreude aus. Ihr Licht scheint auch auf Ihre Schattenseiten, so dass es einfacher für Sie wird, Klarheit über Ihre Sorgen, Frustrationen und Ärger zu erlangen. Diese Karte hilft Ihnen, die positive Seite des Lebens zu sehen. Die Sonne motiviert Sie und fordert Sie dazu auf, das Licht auf Ihr Leben scheinen zu lassen, neue Erfahrungen und eine Pause zu machen. Vergessen Sie für eine Weile alle materiellen Dinge und genießen Sie immaterielle Dinge wie Liebe und Freundschaft. Nachdem Sie das gemacht haben, werden Sie sich freier, vitaler und energiegeladener fühlen. Jetzt ist es Zeit, um das Leben von der Sonnenseite aus zu betrachten!</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>Die Sonne symbolisiert die Wärme und das Vergnügen in einer festen Beziehung. Er veranschaulicht die bedingungslose Liebe, zu geben und zu nehmen, geben ohne etwas dafür zurückzubekommen. Die Chancen stehen gut, dass Sie in der Lage sein werden die tiefsten Wünsche Ihres Partners zu erfüllen. Aber Vorsicht, nicht zu anhänglich oder bedürftig zu werden, Ihr Partner könnte jetzt viel Raum gebrauchen und wenn Sie ehrlich mit Sich Selbst sind, könnten Sie eigentlich im Moment auch schon ein bisschen Raum gebrauchen.</p>",
                       [GuidanceFields.WORK] : "<p>Die Sonne sagt, dass Sie jetzt Ihre Arbeit  genießen und obendrein gerade jetzt sehr erfolgreich darin sind. Sie strahlen positive Energie aus und Ihre Freundlichkeit hilft Ihnen, Ihre Ziele auf einer natürlichen und spontanen Art und Weise zu erreichen. Achten Sie darauf, nahe an Ihre Gefühle und Instinkte zu bleiben, sonst könnten Sie ein wenig heuchlerisch werden. Und egal, wie gut die Dinge für Sie gehen, behalten Sie Ihr Ego unter Kontrolle, indem Sie Ihre Erfolge mit Ihren Kollegen teilen.</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>Die Sonne lädt Sie in einen spirituellen „Urlaub„ zu nehmen, um sich mal umzusehen und verschiedene Ansätze und Interpretationen zu betrachten hinsichtlich dem wahren Sinn des Lebens. Die Sonne kann auch sagen, dass Sie jetzt vielleicht wirklich die Schönheit und die Freude, die das Leben Ihnen bietet, zu schätzen wissen und annehmen werden. Man muss nur wirklich umschauen, sie zu finden. Teilen Sie diese Entdeckung mit all den Menschen um Sie herum, sodass auch sie eine tolle Zeit haben können. Stellen Sie alle (auch kleine) Dinge sicher, die Qualität in Ihr Leben bringen könnten.</p>",
                   }
               }
            },
            {
                filename: "20",
                numerology : 20,
                en : {
                    name : "Time-Space", subtitle: "XX",

                    zodiac : "♇ - Pluto",
                    archetype : "liberation",
                    theme : "judge for yourself",    
                    description : "The Judgement talks about hearing… and being heard, your calling, \"coming out of the closet\", judging for yourself.",
                    questions : [ "Are you judging for yourself?", "Are you too harsh on yourself?", "What is your higher calling?", "Are you honest about it?", "Let yourself be heard !" ],

                    bulletpoints : ["Resurrection","Objectivity","Awakening"],
                    birthCombinationShort: "Your path is one of breaking limitations, by following your intuition",
                    birthCombinationText : "Intuition is what guides the High Priestess. Your best results always come when you trust the voice within. Because of the Judgment card, you are not afraid to re-evaluate any situation and make the best decision, even if you must break through many limitations to achieve this.",

                    soalPoints : [ "Verlossing", "Bevrijding", "Je ware schat", "Iets verlopen duikt weer op", "De kus op de kikker" ],
                    soalText : "Verlossing is het oplossen van een probleem, bevrijd worden van een nare situatie, verlost worden van het nare wat je zo erg bezig houdt. Het is de bevrijding van dat waar je al zo lang mee roncloopt, waar je over piekert of nog steeds geen oplossing voor gevonden hebt.",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Do not confuse Judgement with condemnation and punishment, that's not what this card is about, it's all about resurrection. Beyond judgement you take the important step towards self-realization and transformation from low to higher layers of consciousness. In everyday life you will become conscious of this change when fears and worries disappear and you find yourself confronting challenges with courage, creativity and decisiveness. On the other hand Judgement can also indicate that you are too quick with your judgements and conclusions. You might want to slow down a bit and give yourself and others more chances.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>When you are already in a relationship Judgment tells you that you are at a defining moment. Be honest and open about the things you want from the relationship and try your hardest to make them happen. If you're looking for a relationship Judgment tells you to be careful about judging your potential partner, be it negatively or positively.</p><p>In general you will notice that you are transforming within your relationships, you are conquering your fears and coming closer to your True Self. This helps you to come much closer to your loved one(s) and moreover, it brings you intense joy.</p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned Judgment tells you that you're entering a decisive phase, change may be imminent, be it caused by yourself or by your superiors. If you work hard it will very likely pay off, if you're not working hard it is high time to start doing so. Whatever you do right now, it does not go unnoticed, it is being ‘judged’.</p><p>This card is very often allabout choices and the consequences of all the choices you make, be they conscious or subconscious.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Judgment may remind you that it's important to be open to new ways of thinking, new experiences. Life is all about change and so is spiritual life. This doesn't mean you should immediately change your convictions or fields of interest, but a bit of shopping around doesn't hurt. It may even help you to find truths you didn't even think were there in the first place. Be open to change, to the unusual, it will help you grow.</p>",
                    }
               },
               fr : {
                    name : "Espace-temps", subtitle : "XX",
                    zodiac : "♇ - Pluton",
                    archetype : "libération",
                    theme : "Porter votre propre jugement",    
                    description : "Le Jugement nous parle d'audience..... et d'être entendu, de se sentir appelé à , de \"sortir du placard\", du propre jugement.",
                    questions : ["Vous jugez-vous vous-même?", "Êtes-vous très sévère avec vous-même?", "A quoi vous sentez-vous appelé?", "Admettez-vous?", "Faites-vous entendre !" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Il ne faut pas confondre le jugement avec la condamnation et la punition, la carte n'est pas faite pour cela. Au contraire, il représente la résurrection. Au-delà du jugement, vous faites un pas important vers la réalisation de soi et la transformation des couches inférieures vers les couches supérieures de la conscience. Dans la vie de tous les jours, vous prendrez conscience de ce changement lorsque les peurs et les inquiétudes disparaîtront.  Vous découvrirez que vous relevez les défis avec courage, créativité et détermination.</p><p>D'un autre côté, le jugement peut aussi signifier que vous êtes trop rapide dans vos jugements et conclusions. Peut-être souhaitez-vous ralentir, pour donner à vous-même et aux autres, plus qu’une seule chance.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>When you are already in a relationship Judgment tells you that you are at a defining moment. Be honest and open about the things you want from the relationship and try your hardest to make them happen. If you're looking for a relationship Judgment tells you to be careful about judging your potential partner, be it negatively or positively.</p><p>In general you will notice that you are transforming within your relationships, you are conquering your fears and coming closer to your True Self. This helps you to come much closer to your loved one(s) and moreover, it brings you intense joy.</p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned Judgment tells you that you're entering a decisive phase, change may be imminent, be it caused by yourself or by your superiors. If you work hard it will very likely pay off, if you're not working hard it is high time to start doing so. Whatever you do right now, it does not go unnoticed, it is being ‘judged’.</p><p>This card is very often allabout choices and the consequences of all the choices you make, be they conscious or subconscious.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Judgment may remind you that it's important to be open to new ways of thinking, new experiences. Life is all about change and so is spiritual life. This doesn't mean you should immediately change your convictions or fields of interest, but a bit of shopping around doesn't hurt. It may even help you to find truths you didn't even think were there in the first place. Be open to change, to the unusual, it will help you grow.</p>",
                    }
               },
               sp:{
                name : "Espacio-tiempo", subtitle: "XX",
                zodiac: "♇ - Plutón",
                archetype: "liberación",
                theme: "Juzga por ti mismo",    
                description: "El Juicio es sobre el oído.... y ser escuchado, sentirse llamado por ti, por \"salir del armario\", por el juicio mismo",
                questions: ["¿Te juzgas a ti mismo?", "¿Eres muy estricto contigo mismo?", "¿A qué te sientes llamado?", "¿Saldrás por eso?", "¡Déjate escuchado!"],
                
                cardoftheday: {
                    [GuidanceFields.DEFAULT] : "<p>No se debe confundir con la condena Juicio y castigo, que no es lo que esta tarjeta es de, es todo acerca de la resurrección. Más allá del juicio de dar el paso importante hacia la autorrealización y la transformación de bajo a altas capas de la conciencia. En la vida cotidiana se convertirá consciente de este cambio cuando los temores y las preocupaciones desaparecen y le encuentra enfrentar retos con valentía, creatividad y capacidad de decisión. Por otro lado Juicio también puede indicar que usted es demasiado rápido con sus juicios y conclusiones. Es posible que desee frenar un poco y darle a usted ya otras más posibilidades.</p>",
                    [GuidanceFields.RELATIONSHIP] : "",
                    [GuidanceFields.WORK] : "",
                    [GuidanceFields.SPIRITUAL] : "",
                },
                },
               nl : {
                    name :  "Tijd-Ruimte", subtitle: "XX",
                   zodiac : "♇ - Pluto",
                   archetype : "bevrijding",
                   theme : "oordeel zelf",    
                   description : "Het oordeel gaat over horen... en je laten horen, over je geroepen voelen, over \"kom-uit-de-kast\", over oordeel zelf",
                   questions : [ "Oordeel je zelf?", "Ben je erg streng voor jezelf?", "Waartoe voel jij je geroepen?", "Kom je daarvoor uit?", "Laat je eens horen!" ], 
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Denk bij deze kaart niet aan veroordeeld worden en straf krijgen, daar gaat het hier niet om. In tegendeel, Het Oordeel vertelt je over heropstanding. Je zet die belangrijke stap naar zelfrealisatie en transformatie, je groeit van lage naar hogere delen van je bewustzijn. Je zal je steeds bewuster worden van deze verandering en merken dat je minder angsten en zorgen hebt. Je gaat de dagelijkse uitdagingen nu aan met moed, creativiteit en vastberadenheid.</p><p>Maar er is ook een andere kant aan Het Oordeel: misschien ben je te snel met oordelen en conclusies trekken. Als dat zo is, doe het dan iets rustiger aan en geef jezelf en anderen meer dan slechts die ene kans.</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>Als je al een relatie hebt dan vertelt Het Oordeel je dat een allesbepalend moment aangebroken is. Wees eerlijk en open over alles wat je wil van de relatie en probeer om je verlangens waar te maken.</p><p>Als je op zoek bent naar een relatie dan vertelt het Oordeel je om voorzichtig te zijn bij het beoordelen van een potentiële partner, of het nu negatief of positief is. Over het algemeen zal je merken dat je aan het veranderen bent in de relatie, je bent je angsten aan het overwinnen en dichter bij je Ware Zelf aan het komen. Dit helpt je ook om toenadering te vinden tot je geliefde(n) en brengt je intense vreugde.</p>",
                       [GuidanceFields.WORK] : "<p>Het Oordeel staat voor een erg belangrijke fase als het over werk en carrière gaat. Verandering komt alsmaar dichterbij, of deze nu veroorzaakt wordt door jou of door je meerderen. Als je hard werkt zal dat zeker zijn vruchten afwerpen, maar als je niet hard werkt, begin er dan onmiddellijk mee.</p><p>Bij deze kaart gaat het heel vaak over keuzes en de gevolgen van de keuzes die je maakt, of je dat nu bewust of onbewust doet.</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>Het Oordeel herinnert je eraan dat het belangrijk is om open te staan voor nieuwe denkwijzen, nieuwe ervaringen. Het leven draait om verandering en dat geldt ook voor het spirituele leven.</p><p>Dit betekent niet dat je onmiddellijk je overtuigingen of interessegebieden moet veranderen, maar een beetje rondkijken kan echt geen kwaad. Het kan je zelfs helpen om waarheden te ontdekken waarvan je niet eens wist dat ze bestonden. Sta open voor verandering, voor het ongewone, het zal je helpen groeien.</p>",
                   }
               },
               de : {
                   name :  "Zeit-Raum", subtitle: "XX",
                   zodiac : "♇ - Pluto",
                   archetype : "Befreiung",
                   theme : "Treffen Sie Ihr eigenes Urteil",    
                   description : "Das Gericht spricht  über das Publikum - gehört zu werden, sich berufen zu fühlen, \"aus dem Schrank zu kommen\", dem eigenen Urteil.",
                   questions : [ "Verurteilen Sie sich selbst?", "Sind Sie sehr streng mit sich selbst?", "Wozu fühlen Sie sich berufen?", "Geben Sie es zu?", "Verschaffen Sie sich Gehör!" ], 
                   cardoftheday: {
                       [GuidanceFields.DEFAULT] : "<p>Das Gericht ist nicht mit Verurteilung und Bestrafung zu verwechseln, dafür steht die Karte nicht. Im Gegenteil, sie steht für die Auferstehung. Über die Beurteilung hinaus gehen Sie den wichtigen Schritt in Richtung Selbstverwirklichung und Transformation, von niedrigen zu höheren Schichten des Bewusstseins. Im Alltag werden Sie sich dieser Veränderung bewusst werden, wenn Ängste und Sorgen verschwinden. Sie werden feststellen, dass Sie Herausforderungen mit Mut, Kreativität und Entschlossenheit entgegentreten.</p><p>Auf der anderen Seite kann das Gericht auch bedeuten, dass Sie zu schnell mit Ihren Urteilen und Schlussfolgerungen sind. Vielleicht möchten Sie es ein wenig langsamer angehen und sich selbst und Anderen mehr als nur diese eine Chance geben.</p>",
                       [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie bereits in einer Beziehung sind, sagt das Gericht Ihnen, dass Sie an einem entscheidenden Punkt anbelangt sind. Seien Sie ehrlich und offen über die Dinge, die Sie aus der Beziehung erwarten und versuchen Sie Ihr Bestes diese herauszuziehen. Wenn Sie auf der Suche sind, nach einer Beziehung sagt das Urteil Ihnen, dass Sie vorsichtig sein müssen in der Beurteilung Ihrer potenziellen Partner, sei es negativ oder positiv.</p><p>Im Allgemeinen werden Sie feststellen, dass Sie Ihr Verhalten in Ihren Beziehungen ändern. Sie haben Ihre Ängste überwunden und kommen näher an Ihr wahres Selbst dran. Das hilft Ihnen viel näher an Ihre Liebsten heranzukommen und darüber hinaus bringt es Ihnen tiefe Freude.</p>",
                       [GuidanceFields.WORK] : "<p>Wo es die Arbeit betrifft das Gericht Ihnen, dass Sie Sich in einer entscheidenden Phase befinden und dass eine Änderung unmittelbar bevorstehen kann, die sogar durch Ihre Vorgesetzten verursacht werden kann. Wenn Sie hart arbeiten, wird das sich sehr wahrscheinlich ausbezahlen. Wenn Sie nicht so hart arbeiten, ist es jetzt die höchste Zeit das zu tun. Was immer Sie auch gerade jetzt tun, es bleibt nicht unbemerkt, man wird Sie beurteilen.</p><p>Diese Karte handelt sehr oft über Auswahl und die Folgen aller Entscheidungen, die Sie treffen, sei es bewusst oder unbewusst.</p>",
                       [GuidanceFields.SPIRITUAL] : "<p>Das Urteil kann darauf hinweisen, dass es wichtig ist, offen zu sein für neue Denkweisen, neue Erfahrungen. Im Leben dreht sich alles nur um Veränderung und das trifft auch zu auf dem geistigen Leben. Dies bedeutet nicht, dass Sie sofort Ihre Überzeugungen oder Interessengebieten ändern sollten, aber ein bisschen Shopping in der Umgebung tut nicht weh. Es kann sogar helfen, Wahrheiten zu finden, von denen Sie nicht einmal geglaubt haben, sie wären da. Seien Sie offen für Veränderungen, für das Ungewöhnliche, es wird Ihnen helfen zu wachsen.</p>",
                   }
               }
            },
            {
                filename: "21",
                numerology : 21,
                en : {
                    name :  "Universe", subtitle: "XXI",

                    zodiac : "♄ - Saturn",
                    archetype : "home",
                    theme : "your place in the world",    
                    description : "The World talks about the totality, completely feeling at home, having your place in the world.",
                    questions : [ "Do you feel good?", "What do you need to feel at home?", "Are you taking your place in the world?" ],

                    bulletpoints : ["Fulfillment","Culmination","Perfection"],
                    birthCombinationShort: "Your path is one of self-actualization guided by love.",
                    birthCombinationText : "As the Empress, your primary guiding force is love. The act of giving and receiving love from those in your life is very important to you. You seek to unify the various aspects of your live to create a harmonious and welcoming World.",

                    soalPoints : [ "Heelheid", "Eenheid", "Het doel", "Voltooiing", "Voldoening", "De juiste plaats innemen", "(Eind)punt" ],
                    soalText : "Het doel is datgene wat je als eindresultaat hoopt te bereiken. Een voltooing van dat waar je misschien al zo lang naar toe hebt gewerkt. ",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The World can remind you of the fact that you're carrying all your loads by yourself. Maybe it would be wise to ask for some help now and then.</p><p>This card may also point out that you've found your own spot, wherever it may be. You are experiencing the bliss of harmony and the happiness one feels when successfully finishing a task or an activity. You may have taken an important step towards true authenticity and completeness.</p><p>In general this card symbolizes happy times.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>You are feeling perfectly at home in your current relationship. Your partner isn't just some passer-by, on the contrary! She/he is of the utmost importance for your quality of life and your development. The way you mirror eachother is helping your consciousness grow.</p><p>If you're looking for a partner, it is well possible you will find someone quite a long way from home, maybe even while you are on holiday.</p>",
                        [GuidanceFields.WORK] : "<p>The World indicates that you're feeling quite at home at the workplace. You really like your job and you're good at it. Still, it may be time to ask yourself if your talents and capabilities are truly acknowledged and rewarded. If you realize this isn't the case, then it's important to get help finding another job. If you're happy to stay where you are, it might be time to further develop yourself, to evolve. Maybe you can take some courses or ask for new or extra tasks.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The World may indicate that you are getting closer to who you really are. New insights and realizations may be coming to you on a regular base. Still, these experiences may be challenging and exhausting so maybe you should take some time off to work through your new insights. Don’t try to do this alone but share your experiences with others, talk about them, do not go this journey alone. After all, this (life) journey is, in itself, just as important as the arrival and it would benefit you to have some company!</p>",
                    }
                },
                fr : {
                    name : "Univers", subtitle : "XXI",
                    zodiac : "♄ - Saturne",
                    archetype : "la maison",
                    theme : "votre place dans le monde",    
                    description : "Le Monde, nous parle de la totalité - indemne, de se sentir chez soi, d’avoir sa propre place dans le monde.",
                    questions : ["Êtes-vous à l'aise dans votre propre peau?", "De quoi avez-vous besoin pour vous sentir chez vous?", "Prenez-vous votre propre place (dans le monde) )?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le monde peut vous rappeler que vous devez porter seul vos charges et que vous pouvez sentir le poids du monde sur vos épaules. Il serait sage de demander de l'aide maintenant. Vous vous sentirez tellement mieux et plus léger !</p><p>Cette carte peut également indiquer que vous avez trouvé votre propre place, où qu'elle se trouve. Vous ressentez le bonheur lorsqu’il y a harmonie et lorsque vous accomplissez avec succès une tâche ou une activité. Peut-être avez-vous fait un pas important vers l'authenticité et l'exhaustivité.</p><p>Habituellement, cette carte symbolise les moments heureux, que ce soit dans le présent ou dans un proche avenir.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>You are feeling perfectly at home in your current relationship. Your partner isn't just some passer-by, on the contrary! She/he is of the utmost importance for your quality of life and your development. The way you mirror eachother is helping your consciousness grow.</p><p>If you're looking for a partner, it is well possible you will find someone quite a long way from home, maybe even while you are on holiday.</p>",
                        [GuidanceFields.WORK] : "<p>The World indicates that you're feeling quite at home at the workplace. You really like your job and you're good at it. Still, it may be time to ask yourself if your talents and capabilities are truly acknowledged and rewarded. If you realize this isn't the case, then it's important to get help finding another job. If you're happy to stay where you are, it might be time to further develop yourself, to evolve. Maybe you can take some courses or ask for new or extra tasks.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The World may indicate that you are getting closer to who you really are. New insights and realizations may be coming to you on a regular base. Still, these experiences may be challenging and exhausting so maybe you should take some time off to work through your new insights. Don’t try to do this alone but share your experiences with others, talk about them, do not go this journey alone. After all, this (life) journey is, in itself, just as important as the arrival and it would benefit you to have some company!</p>",
                    }
                },
                sp:{
                    name : "Universo", subtitle: "XXI",
                    zodiac: "♄ - Saturno",
                    archetype: "(hogar) en casa",
                    theme: "tu lugar en el mundo",    
                    description: "El Mundo es sobre la totalidad - la piel entera, sobre sentirse como en casa, sobre tu propio lugar en el mundo",
                    questions: ["¿Estás cómodo en tu piel?", "¿Qué necesitas para sentirte como en casa?", "¿Tomas tu propio lugar (en el mundo)?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El mundo puede recordar el hecho de que usted está llevando todas sus cargas por su mismo. Tal vez sería conveniente pedir un poco de ayuda de vez en cuando. Esta tarjeta también puede indicar que usted ha encontrado su propio lugar, donde quiera que sea. Usted está experimentando la dicha de la armonía y la felicidad que se siente al terminar con éxito una tarea o una actividad. Es posible que haya dado un paso importante hacia la verdadera autenticidad e integridad. En general, esta carta simboliza momentos felices.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name : "Universum", subtitle: "XXI",
                    zodiac : "♄ - Saturnus",
                    archetype : "thuis",
                    theme : "jouw plek in de wereld",    
                    description : "De wereld gaat over totaliteit - heelhuid, over je thuis voelen, over je eigen plek in de wereld.",
                    questions : [ "Zit jij lekker in je vel?", "Wat heb jij nodig om je thuis te kunnen voelen?", "Neem jij je eigen plek (in de wereld) in?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Als je deze kaart krijgt dan heb je misschien het gevoel dat je het gewicht van De Wereld op je schouders draagt. Denk eraan dat je niet alleen bent, er zijn mensen om je heen die je graag willen helpen met het dragen van je last. En zoals je eigenlijk wel weet: gedeelde last is halve last!</p><p>Vaak betekent deze kaart ook dat je je plek gevonden hebt, waar dat ook is. Je hebt een gevoel van harmonie, je voelt de vreugde van het succesrijk afronden van een taak, een activiteit, een opdracht. Je hebt een belangrijke stap gezet in de richting van “echtheid”, je voelt je \"geheel\".</p><p>Deze kaart vertegenwoordigt bijna altijd mooie en vreugdevolle tijden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Volgens deze kaart voel je je helemaal thuis in je huidige relatie. Je partner is niet zomaar een voorbijganger, in tegendeel! Zij/hij is ontzettend belangrijk voor je levenskwaliteit en je ontwikkeling. De manier waarop jullie mekaar spiegelen, helpt om je bewustzijn te laten groeien.</p><p>Als je op zoek bent naar een partner dan is het goed mogelijk dat je iemand zal vinden als je tamelijk ver weg van huis bent, bijvoorbeeld als je met vakantie gaat.</p>",
                        [GuidanceFields.WORK] : "<p>De Wereld vertelt je dat je je goed thuis voelt op je werkplek. Je vindt je job echt leuk en je bent er ook goed in.</p><p>Toch is het misschien tijd om je af te vragen of je talenten en capaciteiten echt wel erkend en beloond worden. Als je beseft dat dit niet het geval is, vraag dan hulp van je omgeving om een andere baan te vinden.</p><p>Als je tevreden bent en op dezelfde plek wil blijven, is het waarschijnlijk dat de tijd rijp is om je verder te ontwikkelen, te groeien. Misschien kan je een cursus volgen of andere of bijkomende taken vragen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Als je De Wereld krijgt dan kan dit betekenen dat je dichterbij aan het komen bent bij wie je echt bent. Je kan geregeld nieuwe inzichten en realisaties krijgen. Toch is het mogelijk dat deze ervaringen uitdagend en uitputtend zijn, dus misschien moet je wat vrije tijd nemen om je nieuwe inzichten te verwerken.</p><p>Probeer niet om dit alleen te doen maar deel je ervaringen met anderen, praat erover, maak deze reis niet in je eentje. Deze reis is tenslotte even belangrijk als het bereiken van je doel, dus bewandel dit (levens)pad in goed gezelschap!</p>",
                    }
                },
                de : {
                    name :  "Universum", subtitle: "XXI",
                    zodiac : "♄ - Saturn",
                    archetype : "Das Haus",
                    theme : "Ihr Platz in der Welt",    
                    description : "Die Welt spricht zu von der Gesamtheit - unversehrt zu sein, sich zu Hause zu fühlen, seinen eigenen Platz in der Welt zu haben.",
                    questions : [ "Fühlen Sie sich wohl in Ihrer eigenen Haut?", "Was brauchen Sie, um sich zu Hause zu fühlen?", "Nehmen Sie Ihren eigenen Platz (in der Welt) ein?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die Welt kann Sie daran erinnern, dass Sie Ihre Lasten alleine  tragen müssen, Sie können die Last der Welt auf Ihren Schultern spüren. Möglicherweise wäre es klug, jetzt um Hilfe zu fragen. Sie werden sich dann so viel besser und leichter fühlen!</p><p>Diese Karte kann auch darauf hinweisen, dass Sie Ihren eigenen Platz gefunden haben, wo auch immer dies sein mag. Sie erleben das Glück der Harmonie und das Glück, das man fühlt, wenn man eine Aufgabe oder eine Tätigkeit erfolgreich abschließt. Vielleicht haben Sie einen wichtigen Schritt zu wahrer Authentizität und Vollständigkeit gesetzt.</p><p>In der Regel symbolisiert diese Karte glückliche Zeiten, entweder bereits in der Gegenwart oder in der nahen Zukunft.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Sie fühlen Sich perfekt zu Hause in Ihrer aktuellen Beziehung. Ihr Partner ist nicht nur einen Passanten, im Gegenteil! Sie/ er ist für Ihre Lebensqualität und Ihre Entwicklung von größter Bedeutung. Die Art und Weise wie Sie Sich aneinander spiegeln hilft zum Wachstum Ihres Bewusstseins.</p><p>Wenn Sie jetzt auf der Suche sind nach einem Partner, ist es sehr gut möglich, dass Sie jemanden ganz weit weg von Hause finden, vielleicht sogar, während Sie im Urlaub sind.</p>",
                        [GuidanceFields.WORK] : "<p>Die Welt zeigt Ihnen, dass Sie Sich ganz wohlfühlen an Ihrem Arbeitsplatz. Sie mögen Ihren Job wirklich und Sie sind gut darin. Dennoch kann es Zeit sein, sich zu fragen, ob Ihre Talente und Fähigkeiten wirklich anerkannt und belohnt werden. Wenn Sie feststellen, ist dies nicht der Fall ist, dann ist es wichtig, Hilfe Einzurufen um einen neuen Arbeitsplatz zu finden. Wenn Sie glücklich sind, wo Sie sind, dann kann es Zeit sein, um sich weiterzuentwickeln. Vielleicht können Sie was Unterricht nehmen oder fordern Sie neue oder zusätzliche Aufgaben an.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Welt kann darauf hindeuten, dass Sie näher an den kommen, wer Sie wirklich sind. Neue Einsichten und Erkenntnisse können zu Ihnen kommen auf einem regelmäßigen Basis. Dennoch können diese Erfahrungen anspruchsvoll und anstrengend sein. Vielleicht sollten Sie Sich einige Zeit nehmen, um Ihre neuen Einsichten durch zu arbeiten. Versuchen Sie nicht, dies allein zu tun, sondern teilen Sie Ihre Erfahrungen mit anderen, reden Sie mit anderen darüber. Sie müssen ja diesen Weg nicht allein gehen. Immerhin ist dieser Lebensweg selber ebenso wichtig, wie der Ankunft und es könnte günstiger sein sich unterwegs ein bisschen Gesellschaft zu leisten.</p>",
                    }
                }
            },
            {
                filename: "22",
                numerology : 1,
                en : {
                    name: "Ace of Wands", subtitle: "Illumination",
                    suit : "Wands",
                    element : "🜂 - fire", 
                    theme : "I want",    
                    description : "Ace of Wands – element fire, talks about what you want in its totality. Everything is potentially present, but what is it that you want?",
                    questions : [ "Do you know what you want?", "Can you open up to new ideas?", "Is it fitting for you or is it \"imposed\" or non-fitting and should you not follow the idea?", "What is it that you want?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>In Tarot an ace card mostly stands for opportunities, possibilities, en generally they have a positive meaning.</p><p>Wands stand for the fire element which means that the opportunities that are presented often involve self-development, joy of life and persuasiveness.</p><p>Now is the time to reach your goals. You are feeling strong and being driven by the power of persuasion. It's very possible that you are feeling positive, goal oriented, energetic, hopeful, and with good reason!</p><p>There is almost nothing that can hold you back right now, so take your chances!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Ace of Wands symbolizes vivacity in your present relationship. It's also about the enthusiasm you share with the people you are close to. You are feeling really good and you radiate high-spiritedness. You are fully aware of the old wisdom: the feeling you radiate will come back to you threefold.</p><p>If you are single right now it is probable that a new relationship is coming your way. Make sure you show your availability in every way possible.</p><p>Generally now is the perfect time to express yourself, be it in a relationship or not.</p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned you are truly willing to take chances right now. Your passion and ambition are your guiding principles and your performance is defined by zeal and enthusiasm.</p><p>If you are looking for a new job right now, you will probably notice that great chances and opportunities are coming your way, Don not hesitate to ask for what you really want, you might be way more successful in getting it than you thought possible.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Regularly remind yourself that the connection between your body and mind is more close and intimate than you think. There is always intense interaction between the two, the body feels what the mind thinks and vice versa. </p><p>The Ace of Wands tells you that on a spiritual level something or someone new will come into your life. Do you have spiritual \"heroes\", people youlook up to? It may be time to find someone like that, someone to guide you and help you grow and evolve. You will be pleasantly surprised to see new opportunities present themselves, once you open up to them.</p>",
                    }
                },
                fr : {
                    name : "l’As de Bâtons", subtitle:  "clarité",
                    suit : "Bâtons",
                    element :  "🜂 - feu", 
                    theme : "Je veux",    
                    description : "L'As de bâtons, correspond à l'élément feu, nous parle de notre volonté dans sa totalité. Tout est potentiellement présent, mais que voulez-vous?",
                    questions : ["Savez-vous ce que vous voulez?", "Pouvez-vous être ouvert à une nouvelle idée?", "Est-ce qu'elle vous convient ou est-elle imposée?", "Ou une idée qui ne vous convient pas et que vous devriez rejeter?", "Que voulez-vous vraiment?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Dans le tarot, une carte as représenté généralement les chances et les possibilités. Habituellement, il a une signification positive.</p><p>Les bâtons représentent l'élément feu, ce qui signifie que les opportunités qui sont souvent offertes concernent le développement personnel, la joie de vivre et la persuasion. L'élément feu tourne autour de tout ce que vous voulez.</p><p>Le moment est venu d'atteindre vos objectifs. Vous vous sentez fort et motivé par la persuasion. Il est très probable que vous soyez maintenant positif, orienté vers un but, énergique et plein d'espoir. Vous avez ce sentiment pour une bonne raison ! Il n'y a presque rien qui puisse vous retenir maintenant, alors prenez le risque !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Ace of Wands symbolizes vivacity in your present relationship. It's also about the enthusiasm you share with the people you are close to. You are feeling really good and you radiate high-spiritedness. You are fully aware of the old wisdom: the feeling you radiate will come back to you threefold.</p><p>If you are single right now it is probable that a new relationship is coming your way. Make sure you show your availability in every way possible.</p><p>Generally now is the perfect time to express yourself, be it in a relationship or not.</p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned you are truly willing to take chances right now. Your passion and ambition are your guiding principles and your performance is defined by zeal and enthusiasm.</p><p>If you are looking for a new job right now, you will probably notice that great chances and opportunities are coming your way, Don not hesitate to ask for what you really want, you might be way more successful in getting it than you thought possible.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Regularly remind yourself that the connection between your body and mind is more close and intimate than you think. There is always intense interaction between the two, the body feels what the mind thinks and vice versa. </p><p>The Ace of Wands tells you that on a spiritual level something or someone new will come into your life. Do you have spiritual \"heroes\", people youlook up to? It may be time to find someone like that, someone to guide you and help you grow and evolve. You will be pleasantly surprised to see new opportunities present themselves, once you open up to them.</p>",
                    }
                },
                sp:{
                    name:"As de Bastos", subtitle:  "claridad",
                    suit:  "bastos",
                    element: "🜂 –  fuego", 
                    theme: "Quiero",    
                    description: "El As de Bastos, que coincide con el elemento fuego, es acerca de tu voluntad en tu totalidad. Todo está potencialmente presente, pero ¿qué es lo que quieres?",
                    questions: ["¿Sabes lo que quieres?", "¿Puedes abrirte a una nueva idea?", "¿Te ajusta o es una idea que no te conviene y deberías ignorarla?", "¿Qué es lo que realmente quieres?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>En el Tarot un as destaca sobre todo por las oportunidades, posibilidades, en general, tienen un significado positivo. Bastos representan el elemento fuego que significa que las oportunidades que se presentan a menudo implican el desarrollo personal, la alegría de la vida y la persuasión. Ahora es el tiempo para alcanzar sus metas. Se siente fuerte y siendo impulsado por el poder de la persuasión. Es muy posible que usted se siente positivo, orientado, enérgico, esperanzado, y con buena razón meta! No hay casi nada que se pueda celebrar de nuevo en este momento, así que tome sus posibilidades!</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Aas", subtitle:  "Helderheid",
                    suit : "Staven",
                    element : "🜂 - vuur", 
                    theme : "ik wil",    
                    description : "De aas van staven, passend bij het element vuur, gaat over je willen in zijn totaliteit. Alles is in potentie aanwezig, maar wat wil jij?",
                    questions : [ "Weet jij wat je wilt?", "Kun jij open staan voor een nieuw idee?", "Past het bij of ishet het \"opgedrongen\" of een niet bij je passend idee en kun je beter naast je neer leggen?", "Wat wil jij eigenlijk?" ],                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>In de Tarot is een Aas meestal een symbool voor kansen en mogelijkheden en heeft de kaart dus vaak een positieve betekenis.</p><p>De Staven zijn een Vuurteken wat betekent dat de mogelijkheden waar de Aas op wijst over het algemeen te maken hebben met persoonlijke groei, vreugde en vastberadenheid. Het Vuurelement draait om wat jij wil.</p><p>De Aas van Staven vertelt je dat deze periode ideaal is om je doelen te bereiken. Je voelt je sterk en hebt ook veel overtuigingskracht. Op dit moment ben je waarschijnlijk doelgericht, positief en energiek en voel je je ook vervuld van hoop.</p><p>Groot gelijk dat je je zo voelt! Er is nu bijna niets dat je kan tegenhouden dus grijp alle kansen die op je weg komen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Aas van Staven is symbolisch voor levendigheid in je liefdesrelatie maar ook voor het enthousiasme dat je deelt met de mensen die je dierbaar zijn. Je voelt je echt goed en je straalt daardoor positieve levenskracht uit. Je bent je ten volle bewust van de oude wijsheid: het gevoel dat jij uitstraalt zal driedubbel naar je terugkomen. Als je op dit moment single bent dan is het heel waarschijnlijk dat je binnenkort een potentiële partner zal ontmoeten. Zorg ervoor dat je door je gedrag en uitstraling duidelijk maakt dat je beschikbaar bent.</p><p>Over het algemeen is dit een goede periode om je te uiten, zichtbaar te zijn, of je nu een relatie hebt of niet.</p>",
                        [GuidanceFields.WORK] : "<p>Wat werk betreft ben je op dit moment echt bereid om risico’s te nemen. Je passie en ambitie zijn je leidraad en je prestaties worden gekenmerkt door vuur en energie. Als je behoefte hebt aan groei, grijp dan nu de kans om je meerdere aan te spreken, hij/zij zal onder de indruk zijn van je uitstraling.</p><p>Als je op zoek bent naar werk dan zal je merken dat er grote kansen en mogelijkheden op je weg komen. Aarzel niet om te streven naar wat je echt wil. Er is een grote kans dat je succesvoller zal zijn in je streven dan je ooit verwacht had.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Herinner jezelf er geregeld aan dat de verbinding tussen lichaam en geest intenser en intiemer is dan je eigenlijk denkt. Er is sprake van een constante interactie tussen de twee, je lichaam voelt wat je geest denkt en omgekeerd. Besteed hier voldoende aandacht aan.</p><p>Als je deze kaart krijgt dan betekent het dat er op spiritueel vlak binnenkort een nieuw iets of iemand in je leven zal komen. Heb je spirituele “helden”, mensen naar wie je opkijkt? Als dat niet het geval is, dan is het nu een goed moment om ernaar op zoek te gaan. Probeer iemand te vinden die je leiding kan geven, je kan gidsen en helpen in je groei en verandering. Stel je open voor nieuwe mogelijkheden, je zal aangenaam verrast worden door wat er allemaal op je weg komt.</p>",
                    }
                },
                de : {
                    name: "Ass der Stäbe", subtitle:  "Klarheit",
                    suit : "Stäbe",
                    element : "🜂 - Feuer", 
                    theme : "Das Spiel",    
                    description : "Das Ass der Stäbe entspricht dem Feuerelement, erzählt von unserem Willen in seiner Gesamtheit. Alles ist potentiell vorhanden, aber was wollen Sie?",
                    questions : [ "Wissen Sie, was Sie wollen?", "Können Sie offen sein für eine neue Idee?", "Entspricht sie Ihnen oder wird sie aufgezwungen?", "Oder ist es eine Idee, die die Ihnen nicht passt und die Sie ablehnen sollten?", "Was wollen Sie wirklich?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Im Tarot steht eine Ass-Karte meist für Chancen und Möglichkeiten. In der Regel hat sie eine positive Bedeutung.</p><p>Stäbe stellen das Feuer-Element dar, das heißt, dass die Chancen, die oft angeboten werden, sich auf Selbstentwicklung, Lebensfreude und Überzeugungskraft beziehen. Das Feuer-Element dreht sich um alles, was Sie wollen.</p><p>Jetzt ist die Zeit gekommen, Ihre Ziele zu erreichen. Sie fühlen sich stark und werden von Überzeugungskraft angetrieben. Es ist sehr wahrscheinlich, dass Sie jetzt positiv, zielorientiert, energisch und hoffnungsvoll sind. Sie haben dieses Gefühl aus gutem Grund! Es gibt fast nichts, was Sie jetzt zurückhalten kann, ergreifen Sie also Ihre Chancen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Das Ass der Stäbe symbolisiert Lebendigkeit in Ihre derzeitige Beziehung. Es geht auch um das Teilen der Begeisterung mit den Menschen, die in Ihrer Nähe sind. Sie fühlen sich wirklich gut und strahlen eine große Lebendigkeit aus. Sie sind Sich voll und ganz bewusst von der alten Weisheit: das Gefühl, das Sie ausstrahlen, wie in dreifach bei Ihnen zurückkommen.</p><p>Wenn Sie jetzt alleine sind, ist es wahrscheinlich, dass eine neue Beziehung auf Sie zukommt. Achten Sie darauf, Ihre Verfügbarkeit in jeder möglichen Weise zu zeigen.</p><p>Im Allgemeinen ist jetzt der perfekte Zeitpunkt, um sich klar auszudrücken, sei es in einer Beziehung oder da draußen.</p>",
                        [GuidanceFields.WORK] : "<p>Wo es die Arbeit betrifft sind Sie jetzt wirklich bereit, die Chancen sofort zu ergreifen. Ihre Leidenschaft und Ehrgeiz sind Ihre Leitprinzipien und Ihre Leistung wird durch Eifer und Begeisterung definiert.</p><p>Wenn Sie im Moment nach einem neuen Job suchen, werden Sie wahrscheinlich feststellen, dass große Chancen und Möglichkeiten auf Ihren Weg kommen werden. Zögern Sie nicht das zu verlangen was Sie wirklich wollen. Sie werden mehr erfolgreich sein als Sie je für möglich gehalten haben.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Regelmäßig erinnern Sie Sich, dass die Verbindung zwischen Körper und Geist näher und intimer ist, als Sie denken. Es gibt immer intensive Interaktionen zwischen den beiden, der Körper fühlt, was der Geist denkt und umgekehrt.</p><p>Das Ass der Stäbe sagt Ihnen, dass auf einer spirituellen Ebene etwas oder jemand neues in Ihr Leben kommen wird. Haben Sie spirituelle \"Helden\", Leute zu wen Sie hoch schauen? Es kann an der Zeit sein so einen zu finden, der Sie helft, wachsen und entwickeln Sie werden angenehm überrascht sein neue Möglichkeiten zu entdecken, sobald Sie sich dazu öffnen.</p>",
                    }
                }
            },
            {
                filename: "23",
                numerology : 2,
                en : {
                    name: "Two of Wands", subtitle: "Purity",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "duality of the will power",    
                    description : "Two of wants talks about not being certain about what you want and face sus with inner doubt, making it hard to get into action.",
                    questions : [ "Do you know your hearts’ desires?", "What is your inutition telling you?", "Are you brave enough to listen and go for it?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>These are times in which you may find it difficult to stay balanced. You may have an indifferent attitude, turning your back on life and not seeming to care about anything or anyone. You experience everything to be \"so so\" and that's what you communicate too. You may seem neutral, not being carried away by your emotions, but is that really the case? Maybe you experience a feeling of being almost paralyzed, maybe it seems impossible for you to be touched by anyone, by anything. </p><p>You may think you are ok being indifferent and neutral but possibly the time has come to take a stand, to feel the emotions flowing through you again.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that you're shielding yourself from the people around you, nothing seems to genuinely touch you, you're distant. Even love doesn't seem to interest you for the moment, as if feelings don't matter right now. Take a long hard look in the mirror and ask yourself what's really important to you, what you need and expect from a loving relationship: understanding, recognition, comforting arms around you?</p><p>If you're not currently in a relationship it's possible that someone you know is interested in you as a potential partner. Don't dismiss this person right away but take the time to really get to know him/her.Maybe what they have to offer, fits your needs much better than you could have imagined.</p>",
                        [GuidanceFields.WORK] : "<p>In a way, it may be hard for the people around you to understand if you're indifferent about your job or if you're just trying to stay neutral. Are you happy with the position you're taking? Is it artificial or is it truly who you are right now?</p><p>If you're looking for a job, things may really be flowing for you, as if you don't have to put in a lot of energy. Don't underestimate yourself, where finding a job is concerned you are pretty balanced.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually this card is also about balance. The primary and most important spiritual relationship in your life is the one you have with yourself. That is why you shouldn't forget to take the time and energy to really take good care of yourself, not only spiritually but also emotionally.</p><p>A connection with someone who will do you a world of good, may be in the making. Observe your surroundings so you don't miss out on this opportunity.</p>",
                    }
                },
                fr : {
                    name : "Le Deux de Bâton", subtitle:  "Pureté",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "dualité dans l'aspect de la volonté",    
                    description : "Les deux de Bâtons nous parlent de ne pas savoir précisément ce que nous voulons réellement et de notre doute intérieur, ce qui rend l'action difficile.",
                    questions : ["Savez-vous ce que vous voulez vraiment au fond de vous-même?", "Que vous dis votre voix intérieure?", "Osez-vous l’écouter et la suivre?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Ce sont des moments où vous trouvez difficile de rester en équilibre. Vous pouvez montrer une attitude indifférente, tourner le dos à la vie. Vous semblez vous intéresser à rien et à personne. Vous vivez tout comme \"ainsi\" et c'est ainsi que vous communiquez avec le monde extérieur. Vous semblez neutre et non guidé par vos émotions, mais est-ce vraiment le cas? Peut-être éprouvez-vous le sentiment d'être presque paralysé ou peut-être qu'il vous semble impossible d'être touché par quelqu'un ou quelque chose.</p><p>Vous pensez peut-être qu'il est normal d'être indifférent et neutre, mais le moment est peut-être venu de prendre position pour que les émotions puissent à nouveau circuler en vous.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that you're shielding yourself from the people around you, nothing seems to genuinely touch you, you're distant. Even love doesn't seem to interest you for the moment, as if feelings don't matter right now. Take a long hard look in the mirror and ask yourself what's really important to you, what you need and expect from a loving relationship: understanding, recognition, comforting arms around you?</p><p>If you're not currently in a relationship it's possible that someone you know is interested in you as a potential partner. Don't dismiss this person right away but take the time to really get to know him/her.Maybe what they have to offer, fits your needs much better than you could have imagined.</p>",
                        [GuidanceFields.WORK] : "<p>In a way, it may be hard for the people around you to understand if you're indifferent about your job or if you're just trying to stay neutral. Are you happy with the position you're taking? Is it artificial or is it truly who you are right now?</p><p>If you're looking for a job, things may really be flowing for you, as if you don't have to put in a lot of energy. Don't underestimate yourself, where finding a job is concerned you are pretty balanced.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually this card is also about balance. The primary and most important spiritual relationship in your life is the one you have with yourself. That is why you shouldn't forget to take the time and energy to really take good care of yourself, not only spiritually but also emotionally.</p><p>A connection with someone who will do you a world of good, may be in the making. Observe your surroundings so you don't miss out on this opportunity.</p>",
                    }
                },
                sp:{
                    name:"El Dos de Bastos ", subtitle:  "Pureza",
                    suit: "Bastos",
                    element: "🜂 – “Fuego",
                    theme: "dualidad en el aspecto de la voluntad",    
                    description: "El  Dos de Bastos se trata sobre no saber exactamente lo que realmente quieres y nos señalan nuestra duda interior, lo que dificulta la acción",
                    questions: ["¿Sabes lo que quieres en el fondo?", "¿Qué dice tu voz interior?", "¿Te atreves a escuchar eso y dar un paso?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Estos son tiempos en los que puede tener dificultades para mantener el equilibrio. Es posible que tenga una actitud indiferente, dando la espalda a la vida y no parecía importarle nada ni nadie. Experimenta todo para ser \"regular\" y eso es lo que se comunica también. Puede parecer neutral, no dejarse llevar por sus emociones, pero es que realmente el caso? Tal vez usted experimenta una sensación de estar casi paralizado, quizás parece imposible que pueda ser tocado por nadie, por nada. Usted puede pensar que puede parecer indiferente y neutral pero posiblemente ha llegado el momento de tomar una posición, de sentir las emociones que fluyen a través de nuevo.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Twee", subtitle:  "Zuiverheid",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "dualiteit in het wilsaspect",    
                    description : "De twee van staven gaat over het niet precies weten wat je werkelijk wilt en wijst ons op onze innerlijke twijfel, waardoor het lastig is om in actie te komen.",
                    questions : [ "Weet jij wat je eigenlijk diep van binnen wilt?", "Wat zegt je innerlijke stem?", "Durf jij daar naar te luisteren en een stap te zetten?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Het is best mogelijk dat je het op dit moment moeilijk vindt om in evenwicht te blijven. Misschien heb je een tamelijk onverschillige houding aangenomen, keer je je af van het leven, geef je weinig of niets om mensen en dingen. Je ervaart alles alsof het maar “zo zo” is en zo stel je je ook op naar de mensen om je heen en de rest van de wereld. Het kan erop lijken dat je neutraal bent, dat je je niet laat meeslepen door je emoties, maar is dat wel echt het geval? Misschien is het eerder zo dat je je bijna verlamd voelt, dat het onmogelijk voor je lijkt om echt geraakt te worden door iets of iemand.</p><p>Best mogelijk dat je het veilig en comfortabel vindt om je onverschillig en neutraal op te stellen, maar misschien is nu de tijd gekomen om een standpunt in te nemen en om alles wat er door je heen gaat ook weer echt te gaan voelen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt je dat je je afschermt van de mensen om je heen. Het lijkt alsof niets je echt kan raken, je bent afstandelijk. Zelfs de liefde interesseert je nu even niet, alsof gevoelens en emoties nu geen belang hebben. Ga eens voor de Spiegel staan en kijk naar jezelf, neem er de tijd voor. Stel jezelf de vraag wat echt belangrijk voor je is in een liefdevolle relatie: is het begrip, (h)erkenning, warme armen om je heen? Als het antwoord ja is, laat dan je afweer los.</p><p>Als je single bent is het mogelijk dat er iemand in je omgeving interesse voor je heeft als potentiële partner. Je eerste reactie is om die persoon af te wijzen, de optie weg te wuiven. Maar misschien neem je beter even de tijd om deze persoon wat beter te leren kennen. Je zou kunnen ontdekken dat wat zij/hij te bieden heeft veel beter bij je past dan je gedacht had.</p>",
                        [GuidanceFields.WORK] : "<p>Op een bepaalde manier is het misschien moeilijk voor de mensen om je heen om te begrijpen of je nu onverschillig bent over je werk of dat je juist heel hard probeert om neutraal te blijven. Ben jij tevreden met de positie die je inneemt? Doe je een beetje alsof of toon je echt wie je bent en wat je voelt?</p><p>Als je op dit moment op zoek bent naar werk dan zit je waarschijnlijk in een positieve flow en hoef je niet veel energie te investeren in je zoektocht. Onderschat jezelf niet, wat werk zoeken betreft zit je op dit moment heel goed in evenwicht!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart heeft het over spiritueel evenwicht. De meest intense en unieke spirituele relatie die je ooit in je leven zal hebben is de relatie met jezelf. Net daarom is het belangrijk dat je er altijd aan denkt om voldoende tijd en energie te besteden aan het goed voor jezelf zorgen en dat niet enkel op spiritueel vlak maar ook emotioneel. Waarschijnlijk komt er binnenkort iemand op je pad waarmee je een positief en opbouwend spiritueel contact zal hebben dat je enorm veel vreugde en verrijking zal brengen. Wees aandachtig, observeer iedereen die je ontmoet zodat je deze persoon niet over het hoofd ziet.</p>",
                    }
                },
                de : {
                    name: "Zwei der Stäbe", subtitle:  "Reinheit",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "Dualität im Aspekt des Willens",    
                    description : "Die Zwei der Stäbe spricht davon, dass wir nicht genau wissen, was wir wirklich wollen, und über unsere inneren Zweifel, der es uns schwierig macht zu handeln.",
                    questions : [ "Wissen Sie, was Sie wirklich tief in sich selbst wollen?", "Was sagt Ihnen Ihre innere Stimme?", "Wagen Sie es, auf sie zu hören und ihr zu folgen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Dies sind Zeiten, in denen Sie es schwierig finden, ausgewogen zu bleiben. Sie können eine gleichgültige Haltung zeigen, dem Leben den Rücken zukehren. Sie scheinen sich um nichts und niemanden zu kümmern. Sie erfahren alles als \"so so la la\" und so kommunizieren Sie auch mit der Außenwelt. Sie erscheinen neutral und nicht von Ihren Emotionen geleitet. Aber ist das auch wirklich der Fall? Vielleicht erleben Sie das Gefühl, fast gelähmt zu sein oder vielleicht scheint es unmöglich für Sie, von jemandem oder etwas berührt zu werden.</p><p>Sie denken vielleicht, dass es in Ordnung ist, gleichgültig und neutral zu sein, aber vielleicht ist die Zeit gekommen, Stellung zu beziehen, damit die Emotionen wieder durch Sie hindurchfließen können.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte sagt Ihnen, dass Sie Sich für die Menschen um Sie herum abschließen, nichts scheint Ihnen wirklich zu berühren, Sie bleiben Fern. Auch die Liebe scheint Ihnen im Moment nicht zu interessieren, als ob Gefühle nicht wichtig sind. Schauen Sie Sich lange und gründlich an im Spiegel und fragen Sie sich, was wirklich wichtig für Sie ist, was Sie brauchen und von einer liebevollen Beziehung erwarten: Verständnis, Anerkennung, tröstende Arme um Sie?</p><p>Wenn Sie derzeit nicht in einer Beziehung sind, es ist möglich, dass es jemanden gibt, der sehr interessiert an Ihnen ist und Sie möglicherweise als einen potenziellen Partner sieht. Weisen Sie diese Person nicht sofort ab, aber nehmen Sie die Zeit, um ihn / sie wirklich besser kennen zu lernen. Vielleicht passt das was er / sie zu bieten hat viel besser zu Ihren Ansprüchen, als Sie je gedacht haben.</p>",
                        [GuidanceFields.WORK] : "<p>In gewisser Weise kann es schwierig sein für die Menschen, um Sie herum zu verstehen, ob Sie gleichgültig Ihrem Job gegenüber stehen oder nur versuchen neutral zu bleiben. Sind Sie mit der Position die Sie einnehmen glücklich? Ist es künstlich oder ist es wirklich so wie Sie gerade sind?</p><p>Wenn Sie nach einem Job suchen, könnte es wirklich erscheinen als, ob Sie kaum Energie eingesetzt haben. Unterschätzen Sie Sich nicht, bei der Suche nach einem Arbeitsplatz sind Sie ziemlich ausgeglichen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spirituell handelt diese Karte auch über Ausgewogenheit. Die erste und wichtigste spirituelle Beziehung in Ihrem Leben ist die, die Sie mit Sich Selbst haben. Das ist, warum man nicht vergessen sollte, die Zeit und Energie zu benutzen, um wirklich gut für sich zu sorgen, nicht nur spirituell, sondern auch emotional.</p><p>Eine Verbindung mit jemandem, der Sie eine Welt von Gutes bringt, kann in Entwicklung sein. Beobachten Sie Ihre Umgebung, so dass Sie diese Gelegenheit nicht verpassen.</p>",
                    }
                }
            },
            {
                filename: "24",
                numerology : 3,
                en : {
                    name: "Three of Wands", subtitle:  "Compassion",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "make way for what you want",    
                    description : "Three of Wands talks about making way for what you want and taking the long view about your future’s perspectives.",
                    questions : [ "How do you see your future?", "Can you make way for what you desire?", "Which steps can you make in that direction?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>It may have been a long and arduous journey, but right now you can look back on this expedition with satisfaction and a sense of accomplishment. You are right where you want to be and should be feeling strong, in control.</p><p>Take the opportunity to rest and observe your surroundings from this higher level. This is a good time to ponder on the future and realistically plan your goals and aspirations.</p><p>At the moment you are fully capable of taking the right decisions, which also means there will be no big obstacles to slow you down. You're standing on a firm base to build your future on.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Even though your relationship is probably going very well at the moment, it is important for you to find out if your business and private life are well and truly balanced. You may have a tendency to put too much time into your work. With a few minor adjustments you can create healthy and harmonious surroundings for you and your loved one to continue growing and evolving within the relationship.</p><p>When you are currently single it is very likely that you will soon meet someone new. Make sure you give this new encounter the attention it deserves.</p>",
                        [GuidanceFields.WORK] : "<p>When you're on the lookout for a job, this may be the perfect time to find one. Don't be too modest, approach job interviews with a healthy dose of self-confidence and make sure you are fully aware of all your qualities and accomplishments. The job you will find may be a lot better than you expected.</p><p>If you are currently employed, you may have reached an important goal, an important achievement. Still, it may take some time for your superiors to realize that too. Now is the right time to do some long term planning.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You already have an elaborate view on spirituality, but now the time may be right to shop around, investigate. Your spiritual growth is a constant journey, it's about change and evolution. Don't be hesitant when you experience your convictions and opinions are evolving.Change is necessary and natural. Ask yourself where you want to be in a few years, on a spiritual level. Are you willing and able to give yourself the space to attain your goals?</p>",
                    }
                },
                fr : {
                    name : "Le Trois de Bâton", subtitle:  "Compassion",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "Donner de l'espace à ce que vous voulez",    
                    description : "Le Trois de Bâtons nous parle de donner l'espace à ce que nous voulons et de regarder ce que nous voulons de notre avenir.",
                    questions : ["Que voulez-vous dans le futur?", "Pouvez-vous faire de la place pour ce que vous voulez?", "Quelle mesure pouvez-vous faire dans cette direction?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Ce fut peut-être un long et fastidieux voyage, mais en ce moment, vous pouvez regarder cette expédition avec satisfaction et un sentiment d'accomplissement. Vous êtes exactement là où vous voulez être et vous devriez vous sentir fort et sous contrôle.</p><p>Profitez de l'occasion pour vous reposer et observer votre environnement depuis ce niveau supérieur. C'est le bon moment pour penser à l'avenir et planifier vos objectifs et vos désirs de façon réaliste.</p><p>En ce moment, vous êtes en mesure de prendre les bonnes décisions. Cela signifie également qu'il n'y a pas de gros obstacles pour vous ralentir maintenant. Vous possédez une base solide sur laquelle vous pouvez bâtir votre avenir.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Even though your relationship is probably going very well at the moment, it is important for you to find out if your business and private life are well and truly balanced. You may have a tendency to put too much time into your work. With a few minor adjustments you can create healthy and harmonious surroundings for you and your loved one to continue growing and evolving within the relationship.</p><p>When you are currently single it is very likely that you will soon meet someone new. Make sure you give this new encounter the attention it deserves.</p>",
                        [GuidanceFields.WORK] : "<p>When you're on the lookout for a job, this may be the perfect time to find one. Don't be too modest, approach job interviews with a healthy dose of self-confidence and make sure you are fully aware of all your qualities and accomplishments. The job you will find may be a lot better than you expected.</p><p>If you are currently employed, you may have reached an important goal, an important achievement. Still, it may take some time for your superiors to realize that too. Now is the right time to do some long term planning.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You already have an elaborate view on spirituality, but now the time may be right to shop around, investigate. Your spiritual growth is a constant journey, it's about change and evolution. Don't be hesitant when you experience your convictions and opinions are evolving.Change is necessary and natural. Ask yourself where you want to be in a few years, on a spiritual level. Are you willing and able to give yourself the space to attain your goals?</p>",
                    }
                },
                sp:{
                    name:"El Tres de Bastos ", subtitle:  "Compasión",
                    suit: "Bastos",
                    element: "🜂 – Fuego",
                    theme: "Da á lo que quieras espacio ",    
                    description: "El Tres de Bastos se trata de dar espacio a lo que quieres y nos apunta a mirar lo que queremos con nuestro futuro",
                    questions: ["¿Qué quieres en el futuro?", "¿Puedes dar espacio á lo que quieres?", "¿Qué paso puedes dar en esa dirección?" ],
                    
                    
                     cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Puede haber sido un viaje largo y arduo, pero ahora usted puede mirar hacia atrás en esta expedición con satisfacción y un sentido de logro. Usted tiene razón en la que desea ser y debe sentirse fuerte, en control. Aproveche la oportunidad de descansar y observar sus alrededores desde este nivel superior. Este es un buen momento para reflexionar sobre el futuro y realista planear sus metas y aspiraciones. En el momento en que son plenamente capaces de tomar las decisiones correctas, lo que también significa que no habrá grandes obstáculos para reducir la velocidad. Usted está de pie sobre una base firme para construir su futuro sobre.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Drie", subtitle:  "Medeleven",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "geef dat wat jij wilt de ruimte",    
                    description : "De drie van staven gaat over ruimte geven aan wat je wilt en wijst ons op het kijken naar wat wij met onze toekomst willen.",
                    questions : [ "Wat wil jij in de toekomst?", "Kun jij, dat wat je wilt, de ruimte geven?", "Welke stap kun je zetten in die richting?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Het was een lange en soms zware reis maar op dit moment kan je erop terugkijken met een tevreden gevoel van voleinding, het is volbracht! Je bevindt je exact op de plek waar je wil zijn en je voelt je sterk, je hebt alles onder controle.</p><p>Neem de gelegenheid te baat om uit te rusten en naar je omgeving te kijken met nieuwe ogen. Dit is een goede tijd om na te denken over je toekomst en realistisch te gaan plannen wat je wil bereiken.</p><p>Je bent nu perfect in staat om alle juiste beslissingen te nemen. Dit betekent ook dat je geen grote obstakels op je weg hoeft te verwachten. Je hebt voor jezelf een stevige basis gelegd en je kan nu beginnen met bouwen aan je toekomst.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Waarschijnlijk gaat het op dit ogenblik erg goed met je liefdesrelatie. Neem toch de tijd om te bekijken of je privéleven en je carrière wel goed in evenwicht zijn. Het is immers mogelijk dat je tegenwoordig de neiging hebt om wat teveel tijd in je werk te stoppen. En door (misschien kleine) aanpassingen door te voeren kan je ervoor zorgen dat je een gezonde en harmonieuze omgeving creëert waarin jij en je partner zorgeloos samen kunnen groeien.</p><p>Als je op dit moment alleen bent, is het heel waarschijnlijk dat je binnenkort iemand zal ontmoeten. Zorg ervoor dat je voldoende aandacht besteedt aan deze ontmoeting, spring er niet te zorgeloos mee om.</p>",
                        [GuidanceFields.WORK] : "<p>Als je op zoek bent naar werk dan is dit de perfecte periode om een baan te vinden. Wees niet te bescheiden, benader interviews met een gezonde dosis zelfvertrouwen en zorg ervoor dat je je ten volle bewust bent van je kwaliteiten en prestaties. Het zit er dik in dat je een job vindt die stukken beter is dan je verwacht had.</p><p>Als je op dit moment aan het werk bent dan heb je waarschijnlijk een belangrijk doel bereikt of een moeilijke taak goed volbracht. Toch is het best mogelijk dat het je oversten nog wat tijd zal kosten om dit te beseffen en te belonen, maak je daar niet druk om. Concentreer je nu op plannen voor de langere termijn.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je hebt al een duidelijke en uitgebreide visie op spiritualiteit maar toch is het nu het goede moment om wat rond te kijken en op onderzoek uit te gaan. Je spirituele evolutie is immers een voortdurende reis, de weg draait om groei en verandering.</p><p>Aarzel niet als je merkt dat je opvattingen en overtuigingen aan het veranderen zijn, omarm de nieuwe ontdekkingen. Verandering is noodzakelijk en natuurlijk. Stel jezelf de vraag waar je op spiritueel niveau binnen een paar jaar wil zijn. Ben je ertoe bereid om jezelf de ruimte te geven die doelen ook te bereiken?</p>",
                    }
                },
                de : {
                    name: "Drei der Stäbe", subtitle: "Mitgefühl",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "Geben Sie dem Raum, was Sie wollen.",    
                    description : "Die Drei der Stäbe spricht darüber, dem Raum zu geben, was wir wollen und zu sehen, was wir von unserer Zukunft erwarten.",
                    questions : [ "Was wollen Sie in Zukunft?", "Können Sie dem Platz machen, was Sie wollen?", "Welche Maßnahmen können Sie in diese Richtung ergreifen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Vielleicht war es eine lange und mühsame Reise, aber im Moment können Sie auf diese Expedition mit Befriedigung und einem Gefühl der Erfüllung zurückblicken. Sie befinden sich genau dort, wo Sie sein wollen, und Sie sollten sich stark fühlen und alles im Griff haben.</p><p>Nutzen Sie die Gelegenheit, um sich auszuruhen und Ihre Umgebung von diesem höheren Niveau aus zu beobachten. Jetzt ist ein guter Zeitpunkt, um über die Zukunft nachzudenken und Ihre Ziele und Wünsche realistisch zu planen.</p><p>Im Moment sind Sie durchaus in der Lage, die richtigen Entscheidungen zu treffen. Es bedeutet auch, dass es jetzt keine großen Hindernisse gibt, die Sie bremsen. Sie stehen auf einer festen Basis, auf die Sie Ihre Zukunft bauen können.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Auch wenn Ihre Beziehung wahrscheinlich sehr gut ist im Moment, ist es wichtig für Sie, um herauszufinden, ob Ihr Unternehmen und Privatleben wirklich gut und ausgewogen sind. Sie können eine Neigung haben zu viel Zeit in die Arbeit zu stecken. Mit einigen kleinen Anpassungen könnten Sie eine gesunde und harmonische Umgebung für Sie und Ihre Liebsten schaffen, um innerhalb der Beziehung weiter zu wachsen und entwickeln.</p><p>Wenn Sie noch alleine sind, ist es sehr wahrscheinlich, dass Sie bald neue Bekanntschaften machen. Achten Sie darauf, diese Sie diese neuen Begegnungen die Aufmerksamkeit geben, die sie verdienen.</p>",
                        [GuidanceFields.WORK] : "<p>Wenn Sie auf der Suche nach einem Job sind, kann dies die perfekte Zeit, einen zu finden. Seien Sie nicht zu bescheiden. Gehe alle Vorstellungsgespräche ein mit einer gesunden Portion Selbstvertrauen und stellen Sie sicher, dass Sie Sich voll und ganz bewusst sind von all Ihren Qualitäten und Leistungen. Den Job, den Sie finden werden ist viel besser, als Sie erwartet haben.</p><p>Wenn Sie derzeit beschäftigt sind, könnten Sie jetzt ein wichtiges Ziel, einen wichtigen Erfolg erreicht haben. Dennoch kann es einige Zeit dauern bevor Ihre Vorgesetzten das erkennen. Jetzt ist die richtige Zeit um einige langfristige Planung zu machen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie haben bereits einen ausgeklügelten Blick auf Spiritualität, aber jetzt ist die Zeit um Sich mal herum zu schauen, zu untersuchen. Ihr spirituelles Wachstum ist eine ständige Reise, es geht um Veränderung und Entwicklung. Zögern Sie nicht, wenn Sie bemerken, dass Ihre Überzeugungen und Meinungen sich entwickeln. Änderung ist notwendig und natürlich. Fragen Sie sich, wo Sie in ein paar Jahren sein wollen, auf welcher spirituellen Ebene. Sind Sie bereit und in der Lage sich den Raum zu geben, um Ihre Ziele zu erreichen?</p>",
                    }
                }
            },
            {
                filename: "25",
                numerology : 4,
                en : {
                    name: "Four of Wands", subtitle: "Aspiration",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "having a handle on what you want",    
                    description : "Four of Wands talks about the handle you have on things and shows a joyous goodbye before entering a new journey.",
                    questions : [ "Is it clear to you what you truly want?", "Which phase can you follow through joyously?", "What do you want to shape?", "Is there anything you are able to change in the direction of your choice?"],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about sociability, pleasure, happiness, joy and open mindedness, generally having a good time. Make sure you enjoy these times in the company of your loved ones. For the moment there are practically no worries or sorrows standing in your way, you can look at the world cheerfully.</p><p>Maybe the tasks lying ahead will be demanding, but you have well enough strength and energy to face them. Don't worry, approach everything that comes your way in a positive way, enjoy!</p><p>Sometimes this card stands for the purchase of a house or moving.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In your relationship it's sunny times right now, or they're about to come your way. You feel safe and comfortable, right at home with your partner. A true commitment is in the making, maybe there will even be an engagement or a wedding.</p><p>If you're single be sure to not miss out on parties and festive occasions in general, you are bound to meet someone interesting while you are having fun.</p><p>Four of Wands tells you that, for the moment, you have a caring and relaxed attitude towards your loved ones.</p>",
                        [GuidanceFields.WORK] : "<p>Normally things should be going pretty well career-wise. You enjoy your work and are motivated to turn every challenge into a success.</p><p>Maybe recently a promotion or a new job has come your way, it could also still be in the making.Anyway, you will follow your new path with a lot of enthusiasm.</p><p>But be careful, to be on top right now means that it will take some effort to stay there.Enjoy every second of today, but remember to conserve enough energy to get through tomorrow.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The choices you make in order to achieve spiritual growth are very personal. Possibly people around you may not understand or condone your choices or the roads you are exploring spiritually. If this annoys you or makes you feel insecure, remember that there is a reason for the choices you make, even if you don't see it right now. It's always wise to listen to other people's opinions, but finally choosing and deciding is your responsibility. You should do what really feels right for you.</p>",
                    }
                },
                fr : {
                    name : "Le Quatre de Bâton", subtitle:  "Aspiration",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "s’accrocher à ce que l’on veut",    
                    description : "Le Quatre de Bâtons, nous parle de prendre en main ce que nous voulons et nous rappelle la joie de dire adieu afin de pouvoir passer à la phase suivante.",
                    questions : ["Est-ce que vous savez déjà clairement ce que vous voulez réellement?", "Quelle phase pouvez-vous conclure joyeusement?", "Que voulez-vous façonner?", "Pouvez-vous changer quelque chose dans la direction que vous souhaitez?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte est synonyme de convivialité, de joie, de bonheur et d'ouverture d'esprit, le plus souvent pour un bon moment que vous appréciez. Assurez-vous de profiter de ce moment en compagnie de vos proches. En ce moment, il n'y a pratiquement pas de soucis, aucun chagrin ne se dresse sur votre chemin, vous pouvez regarder le monde avec joie.</p><p>Peut-être que les tâches qui vous attendent sont exigeantes, mais vous avez assez de force et d'énergie pour vous battre. Ne vous inquiétez pas, acceptez tout ce qui vous arrive positivement, amusez-vous bien ! Parfois, cette carte symbolise l'achat d'une maison ou un déménagement.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In your relationship it's sunny times right now, or they're about to come your way. You feel safe and comfortable, right at home with your partner. A true commitment is in the making, maybe there will even be an engagement or a wedding.</p><p>If you're single be sure to not miss out on parties and festive occasions in general, you are bound to meet someone interesting while you are having fun.</p><p>Four of Wands tells you that, for the moment, you have a caring and relaxed attitude towards your loved ones.</p>",
                        [GuidanceFields.WORK] : "<p>Normally things should be going pretty well career-wise. You enjoy your work and are motivated to turn every challenge into a success.</p><p>Maybe recently a promotion or a new job has come your way, it could also still be in the making.Anyway, you will follow your new path with a lot of enthusiasm.</p><p>But be careful, to be on top right now means that it will take some effort to stay there.Enjoy every second of today, but remember to conserve enough energy to get through tomorrow.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The choices you make in order to achieve spiritual growth are very personal. Possibly people around you may not understand or condone your choices or the roads you are exploring spiritually. If this annoys you or makes you feel insecure, remember that there is a reason for the choices you make, even if you don't see it right now. It's always wise to listen to other people's opinions, but finally choosing and deciding is your responsibility. You should do what really feels right for you.</p>",
                    }
                },
                sp:{
                    name: "El Cuatro de Bastos ", subtitle:  "Aspiración",
                    suit: "Bastos",
                    element : "🜂 – Fuego",
                    theme: "agarrarse a lo que quieres",    
                    description: "El cuatro de Bastos es para agarrar lo que quieres y nos señala la alegría de despedirnos para poder entrar en la siguiente fase",
                    questions: ["¿Ya tienes claro lo que quieres?", "¿Qué fase puedes cerrar con alegría", "¿A qué quieres dar forma", "¿Puedes cambiar algo en la dirección que deseas?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es la sociabilidad, el placer, la felicidad, la alegría y el espíritu de apertura, que generalmente tienen un buen tiempo. Asegúrese de que disfruten de estos momentos en compañía de sus seres queridos. Por el momento no hay prácticamente preocupaciones o dolores de pie en su camino, se puede ver en el mundo con alegría. Tal vez las tareas que tenemos por delante serán exigentes, pero tiene  bien suficientemente fuerza y energía para enfrentarse a ellos. No se preocupe, acércate a todo lo que se le presente de una manera positiva, disfrutar! A veces, esta carta significa la compra de una casa o en movimiento.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Vier", subtitle:  "Aspiratie",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "grip op wat je wilt",    
                    description : "De vier van staven gaat over grip op dat wat je wilt en wijst ons op het vreugdevol afscheid nemen om een volgende fase in te kunnen gaan.",
                    questions : [ "Is voor jou al duidelijk wat je werkelijk wilt?", "Welke fase kun jij vreugdevol afsluiten?", "Wat wil jij vorm gaan geven?", "Kun jij iets veranderen in je eigen gewenste richting?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart draait om gezelligheid, vreugde, geluk, genieten en openheid. Eigenlijk is de essentie gewoon om een fijne tijd te hebben. Zorg ervoor dat je in het gezelschap bent van al je geliefden als je deze mooie tijden meemaakt, doe het niet alleen. Op dit moment zijn er eigenlijk geen zorgen, is er geen verdriet dat je in de weg staat, je kan een vrolijke en positieve kijk op de wereld hebben.</p><p>Misschien liggen er moeilijkere tijden in het verschiet, dat is best mogelijk. Maar op dit moment heb je ruim voldoende kracht en energie om alles wat op je weg komt aan te pakken op een positieve en opbouwende manier. Maar ga nu niet piekeren over wat de toekomst misschien zal brengen, eerst is het tijd voor genieten!</p><p>Soms staat deze kaart ook symbool voor verhuizen of de aankoop van een woning.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op gebied van relaties ben je onderweg naar zonnige tijden. Je voelt je veilig en comfortabel en helemaal thuis met en bij je partner. Het is goed mogelijk dat je een volgende stap aan het overwegen bent, dat je misschien zelfs een verloving of een huwelijk overweegt.</p><p>Als je op zoek bent naar een partner dan is het nu de juiste tijd om alle mogelijke feestelijke gelegenheden op te zoeken. Het is heel waarschijnlijk dat je daar iemand zal ontmoeten die boeiend en aantrekkelijk is en waar je fijne tijden mee kan beleven.</p><p>De Vier van Staven vertelt je dat het op dit moment goed is om je betrokken en toch ontspannen op te stellen naar je geliefden toe. Oefen geen druk uit, wees open.</p>",
                        [GuidanceFields.WORK] : "<p>Wat je werk betreft zou op dit moment alles behoorlijk goed moeten verlopen. Je geniet van je werk en je bent echt gemotiveerd om van elke uitdaging een succes te maken.</p><p>Misschien heb je onlangs een promotie gekregen of ben je aan een nieuwe baan begonnen maar het is ook mogelijk dat zoiets binnenkort zal gebeuren. Wat ook je nieuwe pad moge zijn, je zal er vol enthousiasme aan beginnen.</p><p>Wees toch wat voorzichtig, als je aan de top staat dan betekent dit ook dat je de nodige moeite zal moeten doen om daar te blijven. Geniet van elk moment van elke dag maar zorg ervoor dat je genoeg energie overhoudt om ook morgen de dag door te komen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De keuzes die je maakt om spiritueel te groeien zijn een heel persoonlijke zaak. Het is best mogelijk dat de mensen om je heen jouw spirituele keuzes en zoektochten niet begrijpen of ze zelfs niet goedkeuren. Als je dit vervelend vindt of als je er zelfs onzeker van wordt, denk er dan aan dat er altijd een dieperliggende reden is voor alle keuzes die je maakt, ook al zijn die redenen je op dit moment niet duidelijk. Het is altijd verstandig om naar de mening van anderen te luisteren, maar uiteindelijk is het jouw verantwoordelijkheid om te kiezen en te beslissen. Het gaat erom dat <strong>jij</strong> doet wat goed voelt voor <strong>jou</strong>.</p>",
                    }
                },
                de : {
                    name: "Vier der Stäbe", subtitle:  "Anstreben",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "An dem festhalten, was Sie wollen.",    
                    description : "Die Vier der Stäbe spricht über die Übernahme der Kontrolle über das, was wir wollen, und erinnert uns an die Freude, loszulassen, damit wir zur nächsten Phase übergehen können.",
                    questions : [ "Wissen Sie schon klar, was Sie wirklich wollen?", "Welche Phase können Sie glücklich abschließen?", "Was wollen Sie gestalten?", "Können Sie etwas in die von Ihnen gewünschte Richtung ändern?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für Geselligkeit, Freude, Glück und Aufgeschlossenheit, in der Regel für eine gute Zeit, die Sie genießen. Achten Sie darauf, dass Sie diese Zeit in der Gesellschaft Ihrer Liebsten genießen. Im Moment gibt es praktisch keine Sorgen, kein Kummer steht Ihnen im Wege, Sie können die Welt fröhlich betrachten.</p><p>Vielleicht sind die Aufgaben, die auf Sie zukommen, anspruchsvoll, aber Sie haben genügend Kraft und Energie, um zu kämpfen. Keine Sorge, nehmen Sie alles, was auf Ihrem Wege kommt, positiv an, viel Spaß! Manchmal symbolisiert diese Karte den Kauf eines Hauses oder einen Umzug.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In Ihrer Beziehung sind es sonnige Zeiten im Augenblick, oder sie kommen bald auf Sie zu. Sie fühlen sich sicher und komfortabel, wie zu Hause mit Ihrem Partner. Eine echte Verbindung ist in Entwicklung, vielleicht gibt es sogar eine Verlobung oder eine Hochzeit.</p><p>Wenn Sie alleine sind, sollten Sie sicher nicht verpassen teil zu nehmen an Feier und festliche Anlässe. Sie werden jemanden interessanten treffen während Sie Spaß haben.</p><p>Vier der Stäbe sagt Ihnen, dass Sie im Moment eine liebevolle und entspannende Beziehung zu Ihren Geliebten haben.</p>",
                        [GuidanceFields.WORK] : "<p>Normalerweise laufen die Dinge ziemlich gut Karriere-weise. Sie genießen Ihre Arbeit und sind motiviert um jede Herausforderung zu einem Erfolg zu machen.</p><p>Vielleicht haben Sie vor kurzem eine Beförderung oder einen neuen Job bekommen, aber es könnte auch noch auf Sie zukommen. Auf jedem Fall werden Sie Ihren neuen Weg mit viel Begeisterung einschlagen.</p><p>Aber seien Sie vorsichtig, obwohl Sie vielleicht im Moment an der Spitze sein könnten, es kostet einige Mühe dazubleiben. Genießen Sie jede Sekunde von heute, aber behalten Sie genügend Energie übrig um auch Morgen genießen zu können.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Entscheidungen, die Sie, um spirituelles Wachstum zu erreichen machen, sind sehr persönlich. Möglicherweise können die Leute um Sie sie nicht verstehen, oder akzeptieren sie Ihre Auswahl der Straßen für spirituelle Erforschung nicht. Wenn dies Sie ärgert oder Sie unsicher macht, denken Sie daran, dass es einen Grund gibt für die Auswahl, die Sie machen, auch wenn Sie es gerade jetzt noch nicht sehen. Es ist immer ratsam die Meinungen anderer Menschen zu hören, aber schließlich liegt die Wahl und die Entscheidung in Ihrer Verantwortung. Sie sollten tun, was Sie für Richtig halten.</p>",
                    }
                }
            },
            {
                filename: "26",
                numerology : 5,
                en : {
                    name: "Five of Wands", subtitle: "Oppression",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "own desires",    
                    description : "Five of Wands talks about learning to communicate about what you want and to learn how to communicate about this to others.",
                    questions : [ "Do you know what you truly want yet?", "Do you talk to others about this?", "Can you listen to others?", "Would you like to impose your own will?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card tells you that at this moment in your life, it is very important for you to truly believe in yourself and your abilities. Five of Wands is also about competition, possibly a contest or playful battle of some kind, be it private or work related. And the challenge you meet is all about furthering yourself, growing, evolving. Even though the situation is in no way destructive, be ready to encounter some resistance and be sure to stay fair at all times.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Five of Wands often is all about choosing and communicating your choice. Maybe there is more than one person romantically interested in you.It will be up to you to choose the best candidate. Or maybe you really like someone only to find stiff competition. In that case you will have to stand your ground, prove yourself and your feelings. Be sure you didn't leave any road unexplored.</p><p>Even though this is a positive card, it's notabout romance and candlelight dinners. It's about competition, battle. In this case the battle may be necessary to bring you and your (new) partner to further heights in your relationship.</p>",
                        [GuidanceFields.WORK] : "<p>You are facing a challenge right now and it won't be easy to meet it. Still, if you carefully choose your path and tactics they will surely lead you to success. Look at this challenge as if it were a test, an exercise, not something people will judge you by. Also, sometimes it pays to listen to other’s opinions, even if you have already made your decision.</p><p>Financially you may have some problems for the moment, but don't worry too much, your situation will change shortly.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Right now it seems that life is all about competition for you.This can make you a bit overzealous and chaotic. There's no repose in your mind and heart, no serenity. That is why it's important for you to calm down, take some rest, look around you and smell the roses. Lay back and enjoy the true infinite power of the universe. Ask yourself if you really know what you want. Do you talk about your goals to others, do you listen to their opinions? Or do you prefer to hold on to your own opinions?</p>",
                    }
                },
                fr : {
                    name : "Le Cinq de Bâton", subtitle:  "Tribulation",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "propre volonté",    
                    description : " Le Cinq de Bâtons, nous parle d’apprendre à communiquer sur ce que nous voulons et que nous pouvons apprendre à échanger des idées à ce sujet avec d'autres.",
                    questions : ["Savez-vous déjà ce que vous voulez réellement?", "En parlez-vous également avec d'autres?", "Savez-vous également écouter les autres?", "Voulez-vous pousser votre propre volonté?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte dit qu'à ce moment de votre vie, il est très important que vous croyiez vraiment en vous-même et en vos capacités. Faites preuve de conviction lorsque vous communiquez cette croyance. Le \"Cinq de Bâton\" est synonyme de compétition, éventuellement une compétition ou un combat ludique sous quelque forme que ce soit, qu'elle soit privée ou professionnelle. Le défi auquel vous êtes confronté concerne avant tout l'épanouissement personnel, la croissance personnelle et le développement personnel. Bien que cette situation ne soit en aucun cas destructeur, préparez-vous à rencontrer de la résistance et soyez honnête en tout temps.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Five of Wands often is all about choosing and communicating your choice. Maybe there is more than one person romantically interested in you.It will be up to you to choose the best candidate. Or maybe you really like someone only to find stiff competition. In that case you will have to stand your ground, prove yourself and your feelings. Be sure you didn't leave any road unexplored.</p><p>Even though this is a positive card, it's notabout romance and candlelight dinners. It's about competition, battle. In this case the battle may be necessary to bring you and your (new) partner to further heights in your relationship.</p>",
                        [GuidanceFields.WORK] : "<p>You are facing a challenge right now and it won't be easy to meet it. Still, if you carefully choose your path and tactics they will surely lead you to success. Look at this challenge as if it were a test, an exercise, not something people will judge you by. Also, sometimes it pays to listen to other’s opinions, even if you have already made your decision.</p><p>Financially you may have some problems for the moment, but don't worry too much, your situation will change shortly.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Right now it seems that life is all about competition for you.This can make you a bit overzealous and chaotic. There's no repose in your mind and heart, no serenity. That is why it's important for you to calm down, take some rest, look around you and smell the roses. Lay back and enjoy the true infinite power of the universe. Ask yourself if you really know what you want. Do you talk about your goals to others, do you listen to their opinions? Or do you prefer to hold on to your own opinions?</p>",
                    }
                },
                sp:{
                    name:"El Cinco de Bastos", subtitle:  "Tribulación",
                    suit: "bastos",
                    element : "🜂 – fuego",
                    theme: "voluntad propia",    
                    description: "El Cinco de Bastos es sobre aprender a comunicar lo que quieres y nos señala que podemos aprender a intercambiar ideas sobre esto con otros",
                    questions: ["¿Ya sabes lo que quieres?", " ¿También hablas con otros sobre esto?", "¿Puedes escuchar a  otros también?", "¿Te gustaría empujar tu propia voluntad?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta carta le dice que en este momento en su vida, es muy importante que usted cree realmente en su mismo y sus habilidades. Cinco de Varitas es también acerca de la competencia, posiblemente un concurso o batalla lúdica de algún tipo, ya sea privada o el trabajo relacionado. Y el desafío que conoce es todo acerca de la promoción de su mismo, creciendo, evolucionando. Aunque la situación no es de ninguna manera destructiva, está listo para encontrarse con cierta resistencia y asegúrese de mantenerse limpio en todo momento.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Vijf", subtitle:  "Verdrukking",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "eigen willen",    
                    description : "De vijf van staven gaat over leren communiceren over wat je wilt en wijst ons op het kunnen leren om hierover van gedachten te wisselen met anderen.",
                    questions : [ "Weet jij al wat je werkelijk wilt?", "Praat jij daar ook over met anderen?", "Kun je ook naar anderen luisteren?", "Wil jij graag je eigen wil doordrukken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart vertelt je dat het op dit moment in je leven erg belangrijk is dat je oprecht gelooft in jezelf en je mogelijkheden. Toon jouw geloof ook duidelijk aan anderen, gelijk waarover je het hebt.</p><p>Staven Vijf is ook symbolisch voor concurrentie, het kan zelfs om een echte wedstrijd gaan of over een speelse soort competitie, in je privéleven of op het werk. De ware uitdaging ligt niet in het beter zijn dan anderen maar in het beter worden dan je nu bent, je eigen groei, je evolutie. En ook al gaat het hier niet om een negatieve soort van wedijver, bereid je er toch op voor dat je tegenstand kan krijgen. En let erop dat je te allen tijde eerlijk en rechtvaardig blijft.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Bij de Vijf van Staven gaat het vaak over kiezen en het kenbaar maken van je keuze. Het is best mogelijk dat er op dit moment meer dan één persoon geïnteresseerd is in jou als potentiële partner. De keuze ligt nu in jouw handen.</p><p>Het kan ook zijn dat je diepe gevoelens hebt voor iemand maar dat er nog andere geïnteresseerden zijn, je hebt concurrentie. Of misschien is je partner onder de indruk van iemand anders, is er twijfel in zijn/haar hart. Als dit het geval is, dan is het belangrijk dat je vastberaden blijft, wees duidelijk over wie je bent en wat je voor de ander voelt, ga ervoor. Zorg ervoor dat je alle mogelijke kansen grijpt en uitbuit. Staven Vijf is weliswaar een positieve kaart maar dat betekent niet dat het hier enkel om romantische gebaren gaat zoals etentjes bij kaarslicht. Je hebt hier te maken met concurrentie, competitie. Je zal strijd moeten leveren om het volgende stadium van je relatie te bereiken.</p>",
                        [GuidanceFields.WORK] : "<p>Op je werk word je geconfronteerd met een uitdaging en het wordt niet gemakkelijk om er een succes van te maken. Zorg ervoor dat je grondig nadenkt over de weg en de tactieken die je wil gaan gebruiken en je zal merken dat je toch je doel kan bereiken. Bekijk deze uitdaging alsof het een wedstrijd is of een spelletje, iets wat je voor jezelf doet. Kijk er niet naar alsof het iets is waarover andere mensen je zullen beoordelen of veroordelen. Luister wel even naar wat anderen erover te zeggen hebben. Ook al heb je al beslist welke weg je zal volgen, je kan altijd handige tips gebruiken.</p><p>Deze kaart kan wijzen op tijdelijke financiële spanningen. Maak je hier niet te druk om, je situatie zal binnenkort weer veranderen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het lijkt erop alsof alles in je leven om competitie draait op dit moment. Misschien word je daar overijverig van, soms zelfs een beetje chaotisch omdat je niet weet waar eerst aan te beginnen. Je hebt geen rust in je hoofd en je hart, geen evenwicht. Daarom is het nu erg belangrijk dat je uit alle macht probeert om tot rust te komen, wat te ontspannen. Kijk eens kalm en traag om je heen en probeer te genieten van wat je ziet. Wees eens gewoon lui en laat de oneindige kracht en macht van het universum op je inwerken. En vraag je dan af of je eigenlijk wel weet wat je wil. Praat je met anderen over de doelen die je wil bereiken? Luister je ook naar hun mening? Of vind je het op dit moment erg belangrijk om vast te houden aan je eigen opinies? Spiritueel groeien is geen wedstrijd, we zijn immers allemaal uniek en hebben elk een unieke spirituele weg te gaan.</p>",
                    }
                },
                de : {
                    name: "Fünf der Stäbe", subtitle:  "Verdrängung",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "Eigener Wille",    
                    description : "Die Fünf der Stäbe spricht darüber zu lernen, wie wir kommunizieren, was wir wollen und dass wir lernen können, Ideen mit anderen auszutauschen.",
                    questions : [ "Wissen Sie schon, was Sie wirklich wollen?", "Sprechen Sie auch mit anderen darüber?", "Können Sie auch auf andere hören?", "Wollen Sie Ihren eigenen Willen durchsetzen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte sagt, dass es in diesem Moment in Ihrem Leben sehr wichtig ist, dass Sie wirklich an sich selbst und an Ihre Fähigkeiten glauben. Zeigen Sie Überzeugung, wenn Sie diesen Glauben kommunizieren. Die „Fünf der Stäbe“ steht für Wettbewerb, möglicherweise einen Wettbewerb oder einen spielerischen Kampf in irgendeiner Form, sei es privat oder beruflich. Die Herausforderung, die auf Sie zukommt, hat vor allem mit Selbstförderung zu tun, mit Selbstwachstum und Selbstentwicklung. Auch wenn diese Situation in keiner Weise zerstörerisch ist bereiten Sie sich darauf vor, auf Widerstand zu stoßen und achten Sie darauf, zu allen Zeiten ehrlich zu bleiben.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Bei der fünf der Stäbe geht es oft um die Auswahl und die Kommunikation Ihrer Wahl. Vielleicht gibt es mehr als ein romantischer Person, der an Ihnen interessiert ist. Es ist an Ihnen den besten Kandidaten zu wählen. Oder vielleicht suchen Sie wirklich jemanden nur um starke Konkurrenz zu finden. In diesem Fall müssen Sie stand halten und sich selber und Ihre Gefühle beweisen. Seien Sie sicher, dass Sie keinen Weg unerforscht gelassen haben.</p><p>Auch wenn, dies eine positive Karte ist handelt es sich nicht um Romantik und Abendessen bei Kerzenlicht. Es geht um Wettbewerb, Kampf. In diesem Fall kann der Kampf notwendig sein, um Sie und Ihren (neuen) Partner auf weitere Höhen in Ihrer Beziehung zu bringen.</p>",
                        [GuidanceFields.WORK] : "<p>Gerade jetzt stehen Sie vor einer Herausforderung, und es wird nicht leicht, sie zu erfüllen. Dennoch, wenn Sie sorgfältig Ihren Weg auswählen wert diese Sie sicherlich zum Erfolg führen. Betrachten Sie diese Herausforderung als einen Test, eine Übung, nicht etwas, worauf Sie beurteilt werden. Auch, manchmal lohnt es sich, auf die Meinung anderer zu hören, auch wenn Sie bereits Ihre Entscheidung getroffen haben.</p><p>Finanziell können Sie einige Probleme begegnen, aber nicht zu viel Sorgen, Ihre Situation wird sich in Kürze ändern.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Im Moment scheint es, dass das Leben für Sie einen Wettbewerb ist. Das kann Sie ein bisschen übereifrig und chaotisch machen. Es gibt keine Ruhe in Ihrem Herz und Verstand, keine Gelassenheit. Deshalb ist es wichtig für Sie, sich zu beruhigen, nehmen Sie sich etwas Ruhe, schauen Sie Sich um und atme den Duft der Rosen. Lehnen Sie sich zurück und genießen Sie die wahre unendliche Macht des Universums. Fragen Sie sich, ob Sie wirklich wissen, was Sie wollen. Haben Sie über Ihre Ziele mit anderen gesprochen, haben Sie ihre Meinung gehört? Oder setzen Sie lieber auf Ihren eigenen Meinung?</p>",
                    }
                }
            },
            {
                filename: "27",
                numerology : 6,
                en : {
                    name: "Six of Wands", subtitle: "Trust",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "going for what you want",    
                    description : "Six of Wands talks about making your choice and showing it.",
                    questions : [ "What do you truly want?", "Can you truly make that choice?", "Do you show it?", "Do you make your own choices or do you follow \"the herd\"?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Getting the Six of Wands it's a very positive sign. This card is about success, fame, recognition, the satisfaction you experience from a job well done. It's about overcoming difficulties and challenges and finally coming out on top. It's also about evolving, moving, positive changes are about to come be it in business or in your private life. If you're open to it, positive things will surely come your way.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Six of Wands depicts success, it symbolizes finally reaping what you have been sowing. This means you may well be on your way towards a beautiful loving relationship, possibly with someone you've had your eye on for a while.</p><p>It may also signify a highlight in your current relationship, you feel closer to your partner than you have ever done before. It is also possible that you succeeded in solving a deep rooted relational problem you had. Now you are finally lying on a bed of roses together. </p>",
                        [GuidanceFields.WORK] : "<p>If you're in a job you really want to hang on to, you will soon notice that generally your situation is getting way better than you ever expected. You will harvest success, </p><p>appreciation and acknowledgment. Maybe you have been working extremely hard in order to achieve all this, but right now you are getting truly and abundantly rewarded.</p><p>If you are currently looking for a job, this card tells you that the position you will find will be way better than you ever expected.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Where spirituality is concerned this card tells you that you are on the right way to experience an intense love of life. Make contact with that intense love inside you and freely share it with everyone around you. You have a lot to give right now.</p><p>Now may also be a good time to ask yourself which road you really want to take, and if you have the freedom to go for it. Do you feel strong enough to march to your own drum?</p>",
                    }
                },
                fr : {
                    name : "Le Six de Bâton", subtitle:  "Confiance",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "choisir ce que vous voulez",    
                    description : " Le Six de Bâtons nous parle de choisir ce que l’on veut et qu'il faut également le montrer.",
                    questions : ["Que voulez-vous vraiment?", "Pouvez-vous vraiment choisir cela?", "Montrez-vous cela?", "Suivez-vous votre propre choix ou êtes-vous un \"larbin\"?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Si vous obtenez le « Six de Bâton », c'est un signe très positif. Cette carte est synonyme de succès, de gloire et de reconnaissance. Il symbolise la satisfaction que vous éprouvez lorsque vous avez bien fait une tâche. Il signifie surmonter les difficultés et les défis, après quoi vous atteignez enfin le sommet. Elle est aussi synonyme de développement et de mouvement. Des changements favorables s'opèrent chez vous, que ce soit dans votre travail ou dans votre vie privée. Si vous vous y ouvrez, vous ferez certainement l'expérience de choses positives. Cette carte vous montre que la confiance en vos propres croyances est toujours payante à long terme, et une fois reçue, assurez-vous de vous offrir quelque chose. Vous le méritez !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Six of Wands depicts success, it symbolizes finally reaping what you have been sowing. This means you may well be on your way towards a beautiful loving relationship, possibly with someone you've had your eye on for a while.</p><p>It may also signify a highlight in your current relationship, you feel closer to your partner than you have ever done before. It is also possible that you succeeded in solving a deep rooted relational problem you had. Now you are finally lying on a bed of roses together. </p>",
                        [GuidanceFields.WORK] : "<p>If you're in a job you really want to hang on to, you will soon notice that generally your situation is getting way better than you ever expected. You will harvest success, </p><p>appreciation and acknowledgment. Maybe you have been working extremely hard in order to achieve all this, but right now you are getting truly and abundantly rewarded.</p><p>If you are currently looking for a job, this card tells you that the position you will find will be way better than you ever expected.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Where spirituality is concerned this card tells you that you are on the right way to experience an intense love of life. Make contact with that intense love inside you and freely share it with everyone around you. You have a lot to give right now.</p><p>Now may also be a good time to ask yourself which road you really want to take, and if you have the freedom to go for it. Do you feel strong enough to march to your own drum?</p>",
                    }
                },
                sp:{
                    name:"El Seis de Bastos", subtitle:  "Confianza",
                    suit: "bastos",
                    element: "🜂 –fuego",
                    theme: "elige lo que quieras",    
                    description: "El Seis de Bastos se trata de elegir lo que quieres y nos recuerda que nosotros también tenemos que mostrarlo",
                    questions: ["¿Qué es lo que realmente quieres?", "¿Puedes elegir eso?", "¿Lo demuestras?", "¿Sigues tu propia elección o eres un \"seguidor\"?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Conseguir el Seis de Varitas es una señal muy positiva. Esta tarjeta es sobre el éxito, la fama, el reconocimiento, la satisfacción que experimenta de un trabajo bien hecho. Se trata de superar las dificultades y retos, y finalmente llegando a la cima. También se trata de evolución, movimiento, cambios positivos están a punto de llegar ya sea en los negocios o en su vida privada. Si usted está abierto a él, las cosas positivas que seguramente llegarán a usted.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Zes", subtitle:  "Vertrouwen",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "kiezen voor wat je wilt",    
                    description : "De zes van staven gaat over kiezen voor wat je wilt en wijst ons er op dat we dat tevens dienen te laten zien.",
                    questions : [ "Wat wil jij echt?", "Kun jij daar daadwerkelijk voor kiezen?", "Laat je dat ook zien?", "Volg jij je eigen keuze of ben je een \"meeloper\"?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Als je de Zes van Staven trekt dan is dit een heel positief teken. Deze kaart duidt op succes, erkenning en welslagen. Ze symboliseert de tevredenheid die je ervaart als je iets tot een goed einde gebracht hebt. Ze vertelt je dat je moeilijkheden, obstakels en uitdagingen op je weg gekregen hebt en dat je uiteindelijk de top bereikt hebt.</p><p>Deze kaart gaat ook over groeien, evolueren. Ze vertelt je dat je positieve gebeurtenissen mag verwachten, privé of wat je werk betreft. Als je jezelf er bewust voor openstelt dan zullen er aangename veranderingen jouw kant op komen.</p><p>Tenslotte, als je in jezelf en je overtuigingen blijft geloven, dan zal je uiteindelijk succes hebben. En op het moment dat je dat succes ook echt beleeft, is het belangrijk dat je jezelf eens goed in de bloemetjes zet, je hebt het verdiend!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Zes van Staven symboliseert welslagen en eindelijk oogsten wat je gezaaid hebt. Op relationeel vlak betekent dit dat je goed op weg bent om een mooie diepgaande liefdesrelatie te krijgen, mogelijk met iemand die je al een tijdje op het oog hebt. Als je al een lange periode moeite gedaan hebt voor iemand, geef het dan nu niet op want succes is nabij!</p><p>Als je al een relatie hebt, dan komt er binnenkort een intens positieve periode waarin je je dichter bij je partner voelt dan je ooit ervaren hebt. Het is ook mogelijk dat jullie eindelijk dat moeilijke probleem of die langdurige onenigheid opgelost hebben. In elk geval is nu de tijd gekomen om te genieten van wat de liefde en jouw volhouden je gebracht heeft, neem je tijd om van dit succes te proeven!</p>",
                        [GuidanceFields.WORK] : "<p>Als je op dit moment een baan hebt die je absoluut wil houden dan zal je binnenkort merken dat je positie een stuk beter geworden is dan je zelf dacht. Alle energie en inspanningen die je geïnvesteerd hebt in je baan blijken nu succesvol. Je krijgt erkenning en waardering van alle kanten. Misschien heb je ontzettend hard moeten werken om dit punt te bereiken, misschien werd het je soms teveel en dacht je het niet vol te kunnen houden. Maar je wordt er nu ook uitbundig en intens voor beloond, geniet ervan!</p><p>Als je op dit moment op zoek bent naar een baan dan wijst deze kaart erop dat je een job zal vinden die een stuk fijner en passender voor je is dan je ooit had kunnen denken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op het gebied van spiritualiteit vertelt deze kaart je dat je op de goede weg bent om binnenkort een intense liefde voor het leven en de wereld te ervaren. Erken deze diepe liefde, maak er contact mee en deel ze met iedereen om je heen. Je hebt op dit moment heel veel te bieden.</p><p>Neem nu ook eens de tijd om na te denken over de weg die je diep vanbinnen wil gaan kiezen. Voel je je vrij en sterk genoeg om helemaal op jezelf te vertrouwen? Ben je er klaar voor om jouw eigen unieke pad te gaan volgen?</p>",
                    }
                },
                de : {
                    name: "Sechs der Stäbe", subtitle: "Zuversicht",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "Wählen Sie, was Sie wollen",    
                    description : "Die Sechs der Stäbe sagt uns zu wählen, was wir wollen und dass wir es auch zeigen sollen.",
                    questions : [ " Was wollen Sie wirklich?", "Können Sie das wirklich wählen?", "Zeigen Sie sich das?", "Folgen Sie Ihrer  eigenen Wahl oder sind Sie ein \"Handlanger\"?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Ziehen Sie die „Sechs der Stäbe“, dann ist das ein sehr positives Zeichen. Diese Karte steht für Erfolg, Ruhm und Anerkennung. Sie symbolisiert die Zufriedenheit, die Sie erfahren, wenn Sie eine Aufgabe gut erledigt haben. Sie steht für die Überwindung von Schwierigkeiten und Herausforderungen, nach der Sie schließlich an die Spitze kommen. Sie steht auch für Entwicklung und Bewegung. Günstige Veränderungen kommen auf Sie zu, entweder im Beruf oder in Ihrem Privatleben. Wenn Sie Sich dafür öffnen werden Sie sicher Positives erleben. Diese Karte zeigt Ihnen, dass sich der Glaube an Ihre eigenen Überzeugungen langfristig immer auszahlt. Und sobald Sie empfangen haben stellen Sie sicher, dass Sie sich selbst etwas gönnen. Sie haben es verdient!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Die Sechs der Stäbe deutet auf Erfolg symbolisiert schließlich ernten, was Sie gesät haben. Das heißt, Sie könnten auf dem Weg zu einer schönen liebevollen Beziehung sein, möglicherweise zu jemandem auf dem Sie gerade das Auge fallen gelassen haben.</p><p>Es kann auch deuten auf einen Höhepunkt in der aktuellen Beziehung. Sie fühlen Sie Sich näher zu Ihrem Partner als jemals zuvor. Es ist auch möglich, dass Sie gerade ein tief verwurzelten rationellen Problem gelöst haben. Jetzt können Sie endlich zusammen auf einem Bett aus Rosen liegen.</p>",
                        [GuidanceFields.WORK] : "<p>Wenn Sie wirklich an Ihrem Job hängen, werden Sie schnell bemerken, dass in der Regel Ihre Situation immer besser wird, wie nie erwartet. Sie werden Erfolg ernten, Wertschätzung und Anerkennung. Vielleicht haben Sie sehr hart gearbeitet, um all dies zu erreichen, aber im Moment werden Sie wirklich und reichlich belohnt.</p><p>Wenn Sie derzeit auf der Suche nach einem Job sind, sagt diese Karte Ihnen, dass die Position, die Sie finden werden viel besser sein wird als Sie jemals erwarte haben.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Wo es die Spiritualität anbelangt, sagt diese Karte Ihnen, dass Sie auf dem richtigen Weg sind um eine intensive Liebe des Lebens zu erfahren. Nehmen Sie Kontakt auf mit dieser intensiven Liebe in Sie, befreie sie und teilen Sie sie mit jeder um Sie. Sie haben eine Menge zu geben, gerade jetzt.</p><p>Jetzt kann auch eine gute Zeit sein, sich zu fragen, welchen Weg Sie wirklich nehmen müssen und ob Sie die Freiheit haben, diesen zu gehen. Fühlen Sie sich stark genug, um zum Schlag Ihrer eigenen Trommeln zu marschieren?</p>",
                    }
                }
            },
            {
                filename: "28",
                numerology : 7,
                en : {
                    name: "Seven of Wands", subtitle:  "Courage",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "going on with what you want",    
                    description : "Seven of Wands talks about going on with what you want and the possible battle with invisible obstacles.",
                    questions : [ "Can you be opinionated?", "Do you feel the need to defend yourself?", "Against what? Against who? Why?", "Is there a need for being that headstrong?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Seven of Wands points out that you possibly have an adversary and it feels as if she/he is stronger and bigger than you are. Maybe the adversary is really someone else, maybe he/she is hidden within you. Although your enemy seems stronger, it is important that you stand your ground, don't give up, your fighting spirit will take you very far, quite possibly towards victory. Now is the time not to succumb to selfdoubt, his isn't only about power, it's also about the strength of forces like love, wisdom, intelligence. </p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that your relationship may be going through a rough patch. Possibly there's a third person involved, be it on your side or your partner's. Talk to your partner, be open and loving about how you feel and what you think, this is the only way to solve any issues you may have. It's also possible that you yourself are having a negative and disturbing influence on other relationships. Ask yourself if maybe you are being too defensive. Is it possible you are seeing problems where there aren’t any?</p><p>If you are currently single, you may have to look for love in new and different places, your present \"hunting grounds\" aren't interesting for you anymore.</p>",
                        [GuidanceFields.WORK] : "<p>This card tells you that right now you are in a difficult situation workwise, there's a lot of competition. Maybe there's a colleague who's interested in your job, maybe it's someone further away, or maybe you only suspect that your job is threatened and you feel the need to protect your position before you are actually being ‘attacked’. Question yourself, ask yourself what's really going on and once you have a clear picture, treat the situation with integrity.</p><p>Have you ever dreamed about being self-employed? Now is the time to really consider taking the step towards independence. Just remember it will take a lot of time and energy to make your dream come true.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Seven of Wands symbolizes that you may be experiencing quite some anxieties, and they could be holding you back. If you want to grow stronger and be ready to take on any challenge you might be facing, now is the time to face your fears and turn them into courage. Without fear, courage wouldn't exist. Your anxiety doesn't need to control you, so if there is a possibility right in front of you, let your fears go and replace them with the courage hidden underneath. Put aside your doubts and hesitations and go for whatever you've been dreaming of.</p><p>Possibly people will ask you for spiritual advice. Don't hesitate to give it to them, you are very clear minded right now.</p>",
                    }
                },
                fr : {
                    name : "Le Sept de Bâton", subtitle:  "Courage",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "suivre votre choix",    
                    description : " Le Sept de Bâtons nous parle de continuer ce que l’on a choisi et de la lutte contre les obstacles invisibles.",
                    questions : ["Etes-vous entêté de suivre votre propre voie?", "Avez-vous l'impression de devoir vous défendre?", "Contre quoi? Contre qui? Pourquoi?", "Avez-vous besoin d'une attitude défensive?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Sept de Bâton\" indique que vous avez peut-être un ennemi et que vous avez l'impression qu'il ou elle est plus fort et plus grand que vous. Peut-être que votre adversaire est vraiment quelqu'un d'autre, mais peut-être qu'il ou elle est caché en vous. Même si votre ennemi (intérieur) semble plus fort, il est important que vous restiez au sol, n'abandonnez pas. Votre esprit combatif vous mènera très loin dans la bonne direction, très probablement jusqu'à la victoire. Ce n'est pas le moment de douter de vous-même, qui est votre véritable ennemi intérieur. Il ne s'agit pas seulement de pouvoir et de contrôle, mais aussi de pouvoir d'amour, d'intelligence et de sagesse.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that your relationship may be going through a rough patch. Possibly there's a third person involved, be it on your side or your partner's. Talk to your partner, be open and loving about how you feel and what you think, this is the only way to solve any issues you may have. It's also possible that you yourself are having a negative and disturbing influence on other relationships. Ask yourself if maybe you are being too defensive. Is it possible you are seeing problems where there aren’t any?</p><p>If you are currently single, you may have to look for love in new and different places, your present \"hunting grounds\" aren't interesting for you anymore.</p>",
                        [GuidanceFields.WORK] : "<p>This card tells you that right now you are in a difficult situation workwise, there's a lot of competition. Maybe there's a colleague who's interested in your job, maybe it's someone further away, or maybe you only suspect that your job is threatened and you feel the need to protect your position before you are actually being ‘attacked’. Question yourself, ask yourself what's really going on and once you have a clear picture, treat the situation with integrity.</p><p>Have you ever dreamed about being self-employed? Now is the time to really consider taking the step towards independence. Just remember it will take a lot of time and energy to make your dream come true.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Seven of Wands symbolizes that you may be experiencing quite some anxieties, and they could be holding you back. If you want to grow stronger and be ready to take on any challenge you might be facing, now is the time to face your fears and turn them into courage. Without fear, courage wouldn't exist. Your anxiety doesn't need to control you, so if there is a possibility right in front of you, let your fears go and replace them with the courage hidden underneath. Put aside your doubts and hesitations and go for whatever you've been dreaming of.</p><p>Possibly people will ask you for spiritual advice. Don't hesitate to give it to them, you are very clear minded right now.</p>",
                    }
                },
                sp:{
                    name:"El Siete de Bastos", subtitle:  "Valor",
                    suit: "bastos",
                    element: "🜂 – fuego",
                    theme: "continúa con lo que quieras",    
                    description: "El Siete de Bastos se trata de continuar con lo que quieres y nos indica nuestra posible batalla contra obstáculos invisibles",
                    questions: ["¿Puedes seguir tu propio camino?", "¿Sientes que tienes que defenderte", "¿Contra qué? ¿Contra quién?", "¿Por qué?", "¿Necesitas una actitud defensiva?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Siete de Varitas señala que posiblemente tiene un adversario y se siente como si él / ella es más fuerte y más grande que usted. Tal vez el adversario es realmente otra persona, tal vez él / ella está escondida dentro de usted. Aunque su enemigo parece más fuerte, es importante que usted mantiene en su posición, no se rinde, su espíritu de lucha le llevará muy lejos, muy posiblemente hacia la victoria. Ahora es el momento de no sucumbir a la libre duda, la suya es no sólo sobre el poder, se trata también de la fortaleza de las fuerzas como el amor, la sabiduría, la inteligencia.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Zeven", subtitle:  "Moed",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "verder gaan met wat je wilt",    
                    description : "De zeven van staven gaat over verder gaan met wat je wilt en wijst ons op onze mogelijke strijd tegen onzichtbare obstakels.",
                    questions : [ "Kun jij je eigenzinnige weg gaan?", "Heb jij het gevoel dat jij je dient te verdedigen?", "Tegen wat? Tegen wie? Waarom?", "Is een verdedigende houding wel nodig?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Zeven van Staven wijst je erop dat je misschien een tegenstander hebt en dat je het gevoel kan hebben dat die tegenstander sterker en groter is dan jij bent. Het is mogelijk dat die vijand effectief iemand in je omgeving is maar het kan ook dat hij eigenlijk diep in jezelf verborgen zit, dat je je eigen grootste vijand bent op dit moment. En ook al lijkt die (innerlijke) vijand sterker dan jij, geef het toch niet op, verdedig jezelf. Je strijdlustige geest zal je helpen om ver te komen, mogelijk behaal je zelfs een overwinning. Nu is niet het moment om je te laten beïnvloeden door twijfel aan jezelf, want dat is namelijk je grootste innerlijke vijand, trap er niet in! Het gaat hier niet enkel om macht en controle, maar vooral over de kracht van liefde, inzicht en wijsheid.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt je dat het mogelijk is dat je een moeilijke periode doormaakt in je relatie. Misschien is er zelfs een derde persoon bij betrokken, aan jouw kant of bij je partner. Praat met je geliefde, vertel hem/haar open en liefdevol wat je voelt en denkt. Dit is de enige manier om de problemen die jullie hebben op te lossen. Vraag je ook even af of je misschien wat teveel in de verdediging zit. Zou het kunnen dat je op dit moment problemen ziet die er eigenlijk niet zijn?</p><p>Als je single bent is het nu het goede moment om op zoek te gaan naar liefde op nieuwe en ongewone plekken. Je huidige “jachtgebied” wordt namelijk alsmaar minder interessant.</p>",
                        [GuidanceFields.WORK] : "<p>Deze kaart geeft aan dat je op het ogenblik mogelijk in een moeilijke situatie zit op het werk die ook te maken heeft met concurrentie. Misschien is er een collega die een oogje heeft op jouw functie, misschien gaat het om iemand die wat verder van je af staat. Het kan ook zijn dat je enkel vermoedt dat je job in gevaar is en dat je het nodig vindt om jezelf te beschermen nog voordat je ook echt “aangevallen” wordt. Het is belangrijk dat je jezelf nu een stuk in vraag stelt, vraag je af wat er echt gaande is. En eens je een duidelijk en realistisch beeld hebt, pak het probleem dan aan in alle eerlijkheid en integriteit.</p><p>Droom je er soms van om zelfstandig te zijn? Dan is het nu de juiste tijd om echt te overwegen die stap naar onafhankelijkheid te zetten. Denk er wel aan dat je een massa energie en tijd zal moeten investeren om je droom waar te maken. Ben je daartoe bereid, kan je dat aan?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Volgens deze kaart hebt je op dit moment mogelijk last van nogal wat angsten die je belemmeren in je dagelijkse functioneren. De tijd is gekomen om de confrontatie met je angsten aan te gaan en ze te transformeren in moed. Je zal hier sterker door worden en elke uitdaging aankunnen die op je weg komt. Bedenk dat zonder angst het begrip moed niet zou bestaan. Toch is het belangrijk dat angst je niet overheerst.</p><p>Als er een probleem op je afkomt, laat dan de angst los en grijp de moed die vlak daaronder verborgen zit. Zet je twijfels en onzekerheden opzij en doe net datgene waar je enkel van durfde te dromen.</p><p>Deze kaart zegt ook dat het mogelijk is dat mensen je om spiritueel advies vragen. Aangezien je geest op dit moment helder en scherp is, kan je hen vrijelijk laten delen in je wijsheid.</p>",
                    }
                },
                de : {
                    name: "Sieben der Stäbe", subtitle:  "Mut",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "Folgen Sie Ihrer Wahl",    
                    description : "Die Sieben der Stäbe spricht darüber, fortzusetzen, was wir gewählt haben, und gegen unsichtbare Hindernisse zu kämpfen.",
                    questions : [ "Sind Sie hartnäckig darin, Ihren eigenen Weg zu gehen?", "Haben Sie das Gefühl, dass Sie sich verteidigen müssen?", "Gegen was? Gegen wen? Warum?", "Brauchen Sie eine defensive Haltung?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Sieben der Stäbe“ weist darauf hin, dass Sie möglicherweise einen Feind haben; es fühlt sich so an, als ob sie oder er stärker und größer ist als Sie. Vielleicht ist Ihr Gegner wirklich jemand anderer, vielleicht aber ist er oder sie in Ihnen selbst versteckt. Auch wenn Ihr (innerer) Feind stärker erscheint, ist es wichtig, dass Sie auf dem Boden bleiben, geben Sie nicht auf. Ihr Kampfgeist wird Sie sehr weit in die richtige Richtung bringen, sehr wahrscheinlich sogar zum Sieg. Jetzt ist nicht die Zeit für Selbstzweifel, der Ihr wahrer innerer Feind ist. Es geht nicht nur um Macht und Kontrolle, sondern auch um die Kraft der Liebe, um Intelligenz und Weisheit.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte sagt Ihnen, dass Ihre Beziehung jetzt durch eine schwierige Phase gehen kann. Möglicherweise ist eine dritte Person mit rein bezogen, sei es auf Ihrer Seite oder Ihres Partners. Sprechen Sie mit Ihrem Partner, offen und liebevoll, wie Sie sich fühlen und was Sie denken. Dies ist der einzige Weg, um alle Probleme die Sie haben könnten, zu lösen. Es ist auch möglich, dass Sie es selbst sind die einen negativen und störenden Einfluss auf andere Beziehungen hat. Fragen Sie sich, ob Sie vielleicht zu defensiv sind. Ist es möglich, dass Sie Probleme sehen, wo es keine gibt?</p><p>Wenn Sie noch alleine sind, müssen Sie möglicherweise für die Liebe in neuen und anderen Stellen und Orten suchen, Ihre aktuelle \"Jagdgründe\" sind für Sie nicht mehr interessant.</p>",
                        [GuidanceFields.WORK] : "<p>Diese Karte sagt, dass Sie jetzt in einer schwierigen Situation in Ihrer Arbeit sind. Es gibt eine Menge Konkurrenz. Vielleicht gibt es einen Kollegen, der sich für Ihre Arbeit interessiert, ist es vielleicht jemand der nicht in direkter Nähe ist, oder vielleicht vermuten Sie nur, dass Ihre Arbeit gefährdet ist und haben Sie das Bedürfnis, Ihre Position zu schützen bevor Sie tatsächlich 'angegriffen' werden. Fragen Sie sich, was wirklich vor sich geht, und wenn Sie ein klares Bild haben behandeln Sie die Situation dann mit Integrität.</p><p>Haben Sie jemals von Selbständigkeit geträumt? Jetzt ist die Zeit um wirklich in Betracht ziehen, den Schritt in die Selbständigkeit zu machen. Denken Sie daran, es wird eine Menge Zeit und Energie kosten um Ihren Traum zu verwirklichen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Sieben der Stäbe symbolisiert, dass Sie möglicherweise viel Ängste haben, die Sie zurück halten können. Wenn Sie bereit sind stärker zu werden und auf jeder Herausforderung mit der Sie konfrontiert werden, einzugehen, ist jetzt die Zeit, um Ihre Ängste zu erkennen und sie in Mut umzuwandeln. Ohne Angst wäre keinen Mut. Ihre Angst muss Sie nicht beherrschen und führen und wenn es eine Möglichkeit gibt direkt vor Ihre Nase, dann lassen Sie Ihre Ängste gehen und ersetzen Sie sie mit dem Mut der drinnen verborgen liegt. Legen Sie Ihre Zweifel und Bedenken beiseite und gehen Sie für das, was Sie geträumt haben.</p><p>Möglicherweise werden manche Leute Sie um spirituellen Rat fragen. Zögern Sie nicht, es ihnen zu geben, Sie haben jetzt einen ganz klaren Geist.</p>",
                    }
                }
            },
            {
                filename: "29",
                numerology : 8,
                en : {
                    name: "Eight of Wands", subtitle: "Harmony",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "taking responsibility for what you want",    
                    description : "Eight of Wands talks about taking responsibility for what you want and to take action whenever we want something.",
                    questions : [ "What do you want to achieve?", "Have you taken action to achieve it?", "Have you set things in motion?", "Do you patiently need to wait for a reaction to your action?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Out of all 78 Tarot cards The Eight of Wands is the only card that indicates something is about to happen very soon if not right now. If you're fed up with waiting, frustrated and tired, hold on just a little bit longer, change is about to come your way shortly.</p><p>You have done everything necessary to set the wheels in motion, now you only have to wait for your actions to take effect. The deeper sense is that a thing is going on in your life that has a thoroughly positive influence on you.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you're in a relationship and you feel totally ready for the next step, it is possible that your partner doesn't feel the same way. She/he might need some more time. Don't get impatient, love and commitment can't be rushed. Your partner will take the next step when ready.</p><p>If you are looking for a partner it is possible that someone you are in love with doesn't look at you the same way. Give him/her the time to get to know you and his/her feelings will surely grow.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are walking around with some questions. The Eight of Wands lets you know that your answers will be coming to you shortly. Because it's someone else who has to give you those answers, there is nothing for you to do but wait and train your patience, especially if it’s not your strong suit.</p><p>This card sometimes also indicates that a change you've been expecting for quite some time now, will soon take effect.</p><p>No matter what, the result will very likely be positive for you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In these demanding and hectic times almost everyone is struggling with the concept of patience now and then. It's not an easy thing to acquire or to systematically incorporate into your life. Now is a good time to turn to your true inner self and find quiet and serenity, in order to achieve true patience. Use any means necessary, be it meditation, affirmation, projection or a mix of all three. Maybe you will not be successful the first time you try, but don't give up. It is very important for you to find true patience within yourself right now.</p>",
                    }
                },
                fr : {
                    name : "Le Huit de Bâton", subtitle: "Harmonie",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "prendre la responsabilité de ce que l'on veut",    
                    description : " Le Huit de Bâtons nous parle de prendre la responsabilité pour ce que l’on veut et qu’il faut agir si l’on veut quelque chose.",
                    questions : ["Que voulez-vous réaliser?", "Avez-vous déjà pris des mesures pour y parvenir?", "Avez-vous mis quelque chose en marche?", "Devriez-vous attendre patiemment la réaction à votre action?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Des 78 cartes de Tarot, le \"Huit de Bâton\" est la seule carte qui indique que quelque chose va se passer très bientôt, si ce n'est pas maintenant. Si vous en avez assez d'attendre, d'être frustré et fatigué, tenez bon encore un peu. Le changement est en marche et vous le rencontrerez bientôt.</p><p>Vous avez fait tout ce qui était nécessaire pour mettre les roues en marche. Il ne vous reste plus qu'à attendre les effets. Lorsque vous vous posez la question, vous constaterez probablement que vous avez fait tout ce qui était nécessaire.</p><p>La signification profonde est que quelque chose se passe dans votre vie qui aura un impact positif et profond sur vous.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you're in a relationship and you feel totally ready for the next step, it is possible that your partner doesn't feel the same way. She/he might need some more time. Don't get impatient, love and commitment can't be rushed. Your partner will take the next step when ready.</p><p>If you are looking for a partner it is possible that someone you are in love with doesn't look at you the same way. Give him/her the time to get to know you and his/her feelings will surely grow.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are walking around with some questions. The Eight of Wands lets you know that your answers will be coming to you shortly. Because it's someone else who has to give you those answers, there is nothing for you to do but wait and train your patience, especially if it’s not your strong suit.</p><p>This card sometimes also indicates that a change you've been expecting for quite some time now, will soon take effect.</p><p>No matter what, the result will very likely be positive for you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In these demanding and hectic times almost everyone is struggling with the concept of patience now and then. It's not an easy thing to acquire or to systematically incorporate into your life. Now is a good time to turn to your true inner self and find quiet and serenity, in order to achieve true patience. Use any means necessary, be it meditation, affirmation, projection or a mix of all three. Maybe you will not be successful the first time you try, but don't give up. It is very important for you to find true patience within yourself right now.</p>",
                    }
                },
                sp:{
                    name:"El Ocho de Bastos", subtitle: "Armonía",
                    suit: "bastos",
                    element: "🜂 – fuego",
                    theme: "Asumir la responsabilidad de lo que se quiere",    
                    description: "El Ocho de Bastos (compases) se trata sobre asumir la responsabilidad de lo que quieres y nos recuerda que si queremos algo, tenemos que actuar nosotros mismos",
                    questions: ["¿Qué quieres lograr?", "¿Ya hecho algo para lograrlo?", "¿Has puesto algo en marcha?", "¿Deberías esperar pacientemente la reacción a tu acción?" ], 
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Fuera de todas las tarjetas de 78 Tarot El Ocho de Varitas es la única tarjeta que indica que algo está a punto de suceder muy pronto si no en este momento. Si está harto de esperar, frustrado y cansado, espera un poco más largo, el cambio está a punto de venir a usted. Ha hecho todo lo necesario para establecer las ruedas en movimiento, ahora sólo tiene que esperar a que sus acciones tengan efecto. El sentido más profundo es que una cosa está pasando en su vida que tiene una influencia positiva a fondo en usted.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Acht", subtitle: "Harmonie",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "verantwoording nemen voor wat je wilt",    
                    description : "De acht van staven gaat over verantwoording nemen voor wat je wilt en wijst ons dat erop dat wanneer we iets willen dat we dan zelf actie dienen te ondernemen.",
                    questions : [ "Wat wil jij bereiken?", "Ben je al in actie gekomen om het te kunnen bereiken?", "Heb je iets in gang gezet?", "Dien je geduldig de reactie op je actie af te wachten?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Van alle 78 Tarotkaarten is Staven Acht de enige kaart die je vertelt dat er op korte termijn iets te gebeuren staat of zelfs al aan het gebeuren is. Als je het wachten moe bent, als je gefrustreerd bent, houd het dan nog even vol. Verandering is onderweg naar jou!</p><p>Je hebt alles gedaan wat nodig was om de boel in gang te trekken, nu kan je enkel nog afwachten tot de gevolgen van jouw daden zichtbaar worden. Als je jezelf en je handelingen nog eens onderzoekt, zal je waarschijnlijk beseffen dat je alles gedaan hebt wat nodig was.</p><p>De diepere betekenis van deze kaart is dat er iets gaande is in je leven dat een grondige positieve invloed op je zal hebben.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je een relatie hebt en je bent er klaar voor om de volgende stap te zetten, is het mogelijk dat je partner (nog) niet op dezelfde golflengte zit. Hij/zij heeft mogelijk meer tijd nodig om tot het punt te komen waar jij nu bent. Zorg ervoor dat je niet ongeduldig wordt want liefde en engagement hebben nu eenmaal hun eigen tempo. Je partner zal de volgende stap zetten als zij/hij er klaar voor is.</p><p>Als je op zoek bent naar een relatie en je hebt gevoelens voor iemand dan is het best mogelijk dat die persoon je gevoelens (nog) niet beantwoordt. Geef die ander alle tijd en ruimte om je te leren kennen en je zal merken dat de diepere gevoelens dan ook zullen komen.</p>",
                        [GuidanceFields.WORK] : "<p>Je zit op het werk met een aantal vragen. Zwaarden Acht vertelt je dat je binnenkort de nodige antwoorden zal krijgen. Je kan op dit moment niets anders doen dan afwachten en geduld hebben, ook al is dat niet je sterkste kant. Deze kaart wijst er soms ook op dat je al een hele tijd op veranderingen aan het wachten was en dat deze nu zullen plaatsvinden.</p><p>In elk geval wijst deze kaart er duidelijk op dat de resultaten positief zullen zijn voor jou.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>We leven in hectische en veeleisende tijden waardoor iedereen af en toe wel eens geconfronteerd wordt met het concept “geduld” in allerlei vormen. Het is niet gemakkelijk om geduldig te zijn, om het geduld als vanzelfsprekend in je leven te verwerken.</p><p>Deze kaart wijst je erop dat het moment nu gekomen is om naar je diepe innerlijke zelf te gaan en daar rust en sereniteit te vinden. Daardoor zal je het ware geduld ontdekken en kunnen toepassen. Gebruik hiervoor alle mogelijke middelen zoals meditatie, affirmatie, projectie of een mengeling ervan. Misschien zal je er de eerste keer niet in slagen om dichter bij je geduld te komen maar geef het niet op. Het is op dit moment in je leven heel belangrijk voor je om intens geduld binnenin jezelf te ontdekken.</p>",
                    }
                },
                de : {
                    name: "Acht der Stäbe", subtitle: "Harmonie",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "Verantwortung für das zu übernehmen, was man will.",    
                    description : "Die Acht der Stäbe sagt, dass wir Verantwortung für das übernehmen sollen, was wir wollen, und dass wir handeln müssen, wenn wir etwas wollen.",
                    questions : [ "Was wollen Sie erreichen?", "Haben Sie schon Schritte unternommen, um es zu erreichen?", "Haben Sie schon etwas begonnen?", "Sollten Sie geduldig auf die Reaktion auf Ihre Aktion warten?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Von allen 78 Tarot-Karten ist die „Acht der Stäbe“ die einzige Karte, die anzeigt, dass etwas sehr bald geschehen wird, wenn nicht gerade jetzt. Wenn Sie des Wartens müde sind, frustriert und erschöpft, dann halten Sie nur noch ein wenig länger durch. Veränderung ist auf dem Wege und Sie werden ihr in Kürze begegnen.</p><p>Sie haben alles Notwendige getan, um die Räder in Bewegung zu setzen. Jetzt müssen Sie nur auf die Auswirkungen warten. Bei der Nachfrage in sich selbst werden Sie wahrscheinlich feststellen, dass Sie tatsächlich alles Notwendige getan haben.</p><p>Der tiefere Sinn ist, dass etwas los ist in Ihrem Leben, das einen durch und durch positiven Einfluss auf Sie haben wird.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer Beziehung sind und Sie fühlen sich völlig bereit für den nächsten Schritt ist es möglich, dass Ihr Partner das nicht in der gleichen Weise fühlt. Sie / er kann etwas mehr Zeit benötigen. Nicht ungeduldig werden, Liebe und Engagement können nicht überstürzt werden. Ihr Partner wird den nächsten Schritt machen, wenn er/sie fertig ist.</p><p>Wenn Sie auf der Suche nach einem Partner sind ist es möglich, dass jemand Ihre Liebe nicht gleich beantwortet. Geben Sie ihm / ihr die Zeit, um Sie näher kennen zu lernen und seine / ihre Gefühle werden sicher wachsen</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit laufen Sie herum mit einigen Fragen. Die Acht der Stäbe lässt Sie wissen, dass Ihre Antworten in Kurzem auf Sie zukommen. Weil es jemand anders ist, der Ihnen die Antworten geben muss, gibt es nichts Weiteres für Sie tun, als zu warten und Ihre Geduld üben, etwas, das nicht zu Ihren Stärken gehört.</p><p>Diese Karte zeigt manchmal auch an, dass eine Änderung, die Sie schon seit einiger Zeit erwartet haben, nicht mehr lange auf sich warten lässt.</p><p>Egal was das Ergebnis sein wird, es wird sehr wahrscheinlich positiv für Sie sein.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In dieser anspruchsvollen und hektischen Zeit hat fast jeder ab und zu mit dem Konzept der Geduld zu kämpfen. Es ist keine einfache Sache sie zu bewahren oder systematisch in Ihr Leben zu integrieren. Jetzt ist eine gute Zeit, um Sich zu Ihrem wahren inneren Selbst zu kehren und die Ruhe und Gelassenheit zu finden, die man braucht um die wahre Geduld zu erreichen. Verwenden Sie alle Mitteln, sei es Meditation, Affirmation, Projektion oder eine Mischung aus allen dreien. Vielleicht werden Sie nicht erfolgreich sein beim ersten Versuch, aber geben Sie nicht auf. Es ist sehr wichtig, dass Sie die wahre Geduld in Ihnen gerade jetzt finden.</p>",
                    }
                }
            },
            {
                filename: "30",
                numerology : 9,
                en : {
                    name: "Nine of Wands", subtitle: "Integrity",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "knowing what you want",    
                    description : "Nine of Wands speaks of being conscious about what you want and owning it.",
                    questions : [ "Are you aware of what you want?", "Have you bumped your \"head\" before?", "Will it happen again or have you learned your lesson now?", "Use your knowledge." ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Being stubborn is the central theme of this card. You are not about to give in and are determined to hold on to your vision, conviction or opinion.</p><p>It may be a good idea to ask yourself why you won't budge. Is there something you are afraid of? Do you feel caving might be threatening? Is it too hard to let go of your ego? Examine the answers to these questions thoroughly and rethink your decision. Maybe it's time to let go of your stubbornness.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now you are afraid of being hurt by your partner. Maybe you are afraid he/she may have intimate feelings for someone else, maybe you are afraid that she/he doesn't really ‘see’ you anymore. To avoid being hurt you have built a wall around yourself, you are wearing a symbolic suit of armor. It is important for you to realize that when you are shutting yourself off this way, you are also denying yourself love and other positive feelings. Look deeply into your own heart: is this what you truly want?</p><p>If you are single right now it may be wise to first start healing yourself emotionally from the wounds you sustained in a previous relationship. Once you’ve done that, you can consider going out and looking for a new love.</p>",
                        [GuidanceFields.WORK] : "p>The Nine of Wands tells you that you might feel threatened at work, and there might even be valid reasons for the way you feel. Maybe a colleague is trying to settle a score or possibly your position is being reconsidered. First, try to see the difference between true and perceived threats. Then you can stop worrying about the ones you only think exist. Finally it's time to consult your conscience and find out which role you have played in creating the present situation. Your first step is to recognize your mistakes, the second one is to find out if there is still time to set the record straight.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>For the moment you may be feeling rather self-indulgent, maybe even sorry for yourself and your circumstances. Now is absolutely not the time to wallow in it! On the contrary, the time is right to apply some self discipline in order to attain your spiritual goals. Focus on studying, make a plan, set yourself some goals … do everything and anything necessary for you to attain higher levels of spiritual commitment. Surround yourself with people who have a positive and proactive attitude, people who can help you reach a higher level.</p>",
                    }
                },
                fr : {
                    name : "Le Neuf de Bâton", subtitle: "Intégrité",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "savoir ce que vous voulez",    
                    description : "Le Neuf de Bâtons nous parle d'être conscient de ce que nous voulons et de pouvoir le défendre",
                    questions : ["Etes-vous conscient de ce que vous voulez?", "Vous êtes-vous déjà cogné la tête?", "Cela arrive-t-il encore ou en avez-vous appris?", "Utilisez ce que venez d’apprendre." ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>L'obstination est le thème central de cette carte. Vous n'êtes pas prêt à céder et vous êtes déterminé à insister sur votre vision, votre conviction ou votre opinion.</p><p>Ce serait peut-être une bonne idée de vous demander pourquoi vous êtes si tenace. Vous avez peur de quelque chose? S'agit-il de l'enfant brûlé qui a peur du feu? C'est peut-être trop dur de se séparer de son ego? Examinez attentivement les réponses à ces questions et reconsidérez votre décision. Il est peut-être temps de lâcher votre entêtement.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now you are afraid of being hurt by your partner. Maybe you are afraid he/she may have intimate feelings for someone else, maybe you are afraid that she/he doesn't really ‘see’ you anymore. To avoid being hurt you have built a wall around yourself, you are wearing a symbolic suit of armor. It is important for you to realize that when you are shutting yourself off this way, you are also denying yourself love and other positive feelings. Look deeply into your own heart: is this what you truly want?</p><p>If you are single right now it may be wise to first start healing yourself emotionally from the wounds you sustained in a previous relationship. Once you’ve done that, you can consider going out and looking for a new love.</p>",
                        [GuidanceFields.WORK] : "p>The Nine of Wands tells you that you might feel threatened at work, and there might even be valid reasons for the way you feel. Maybe a colleague is trying to settle a score or possibly your position is being reconsidered. First, try to see the difference between true and perceived threats. Then you can stop worrying about the ones you only think exist. Finally it's time to consult your conscience and find out which role you have played in creating the present situation. Your first step is to recognize your mistakes, the second one is to find out if there is still time to set the record straight.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>For the moment you may be feeling rather self-indulgent, maybe even sorry for yourself and your circumstances. Now is absolutely not the time to wallow in it! On the contrary, the time is right to apply some self discipline in order to attain your spiritual goals. Focus on studying, make a plan, set yourself some goals … do everything and anything necessary for you to attain higher levels of spiritual commitment. Surround yourself with people who have a positive and proactive attitude, people who can help you reach a higher level.</p>",
                    }
                },
                sp:{
                    name:"El Nueve de Bastos", subtitle: "Integridad",
                    suit: "Bastos",
                    element: "🜂 – fuego",
                    theme: "saber lo que quieres",    
                    description: "El Nueve de Bastos  se trata de ser consciente de lo que quieres y nos recuerda que podemos pararnos justo enfrente ",
                    questions: ["¿Eres consciente de lo que quieres?", "¿Alguna vez te has golpeado la cabeza?", "¿Va a ser otra vez o ya sabes mejor?", "Usa lo que sabes ahora" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Ser tenaz es el tema central de esta tarjeta. Usted no está a punto de ceder y está decidido a aferrarse a su visión, convicción ó opinión. Puede ser una buena idea que preguntarse por qué no se movió. ¿Hay algo que da miedo? ¿Se siente espeleología podría estar amenazando? ¿Es muy difícil dejar de lado su ego? Examine las respuestas a estas preguntas a fondo y repensar su decisión. Tal vez es hora de dejar de lado su terquedad.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Negen", subtitle: "Integriteit",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "weten wat je wilt",    
                    description : "De negen van staven gaat over bewust zijn van wat je wilt en wijst ons er op dat we daar dan pal voor kunnen gaan staan.",
                    questions : [ "Ben jij bewust van wat je wilt?", "Heb jij je \"kop\" al eens gestoten?", "Overkomt je dat nogmaals of weet je nu beter?", "Maak gebruik van wat je nu weet." ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Het centrale thema van deze kaart is koppigheid. Je bent niet van plan om toe te geven, je bent vastberaden om je te blijven vasthouden aan jouw visie, jouw mening, jouw overtuiging.</p><p>Misschien is het wel een goed idee om je eens af te vragen waarom je zo koppig bent. Is er iets waar je bang voor bent? Ervaar je toegeven als een soort bedreiging? Verbind je toegeven met een onaangename ervaring uit het verleden? Of misschien vind je het op dit moment te moeilijk om je ego los te laten. Onderzoek jouw antwoorden op deze vragen grondig en denk nog eens na over je beslissing(en). Misschien is het nu echt wel de juiste tijd om je koppigheid los te laten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment ben je bang dat je partner je zal kwetsen. Misschien denk je dat hij/zij gevoelens heeft voor iemand anders of heb je het gevoel dat je partner je niet meer echt “ziet”. Om te vermijden dat je gekwetst zou worden, heb je een stevige muur om je heen gebouwd, je draagt als het ware een emotioneel harnas. Nu is het heel belangrijk dat je echt gaat beseffen dat je jezelf hierdoor helemaal afsluit, niet enkel voor negatieve gevoelens en ervaringen, maar ook voor de positieve zoals liefde en tederheid. Kijk diep in je hart: is dit echt wat je wil?</p><p>Als je op dit moment alleen bent dan is het verstandig om eerst de nodige tijd te besteden aan het genezen van de wonden die een vorige relatie je toegebracht heeft. Pas als dit gebeurd is, kan je op zoek gaan naar een nieuwe liefde.</p>",
                        [GuidanceFields.WORK] : "<p>Staven Negen vertelt je dat je je mogelijk bedreigd voelt op het werk en het kan best zijn dat daar ook een aanleiding voor is. Misschien is er een collega die een rekening met jou te vereffenen heeft of mogelijk wordt er nagedacht over jouw functie. Probeer in de eerste plaats te ontdekken welke de reële bedreigingen zijn en welke enkel in je fantasie bestaan. Eens dit duidelijk is, kan je stoppen met piekeren over de vermeende problemen en enkel de reële aanpakken. En dan is het tijd om je geweten te raadplegen en te bekijken welke rol jij gespeeld hebt in het ontstaan van de huidige situatie. De eerste stap is je fouten onder ogen zien, pas daarna kan je gaan kijken of je de boel nog kan rechttrekken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment ben je behoorlijk gefocust op jezelf, misschien heb je zelfs medelijden met jezelf vanwege de situatie waarin je je bevindt. Maar het is echt geen goede tijd om je onder te dompelen in zelfmedelijden! Integendeel, net nu is het belangrijk dat je wat strenger bent voor jezelf en dat je gestructureerd te werk gaat om je spirituele doelen te bereiken. Concentreer je op kennis verwerven, maak een plan op, stel jezelf uitdagingen ... Doe alles wat je maar kan bedenken om een hoger niveau van spirituele toewijding te bereiken. Omring jezelf met mensen die een positieve en proactieve instelling hebben, mensen die je kunnen helpen om dat hogere plan te bereiken.</p>",
                    }
                },
                de : {
                    name: "Neun der Stäbe", subtitle: "Integrität",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "Wissen, was Sie wollen",    
                    description : "Die Neun von Stäbe spricht darüber, dass wir uns dessen bewusst sind, was wir wollen, und dass wir es verteidigen können.",
                    questions : [ "Sind Sie sich dessen bewusst, was Sie wollen?", "Haben Sie sich jemals den Kopf gestoßen?", "Passiert das noch oder haben Sie daraus gelernt?", "Nutzen Sie, was Sie gerade gelernt haben." ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Sturheit ist das zentrale Thema dieser Karte. Sie sind nicht bereit, nachzugeben und sind entschlossen, auf Ihrer Vision, Ihren Überzeugung oder Ihrer Meinung zu bestehen.</p><p>Es könnte eine gute Idee sein, sich zu fragen, warum Sie so hartnäckig sind. Gibt es etwas, wovor Sie Angst haben? Haben Sie das Gefühl, nachzugeben könnte in irgendeiner Weise eine Bedrohung für Sie darstellen? Ist es eine Frage des gebrannten Kindes, das das Feuer scheut? Vielleicht ist es einfach zu schwer, sich von Ihrem Ego zu trennen? Untersuchen Sie die Antworten auf diese Fragen gründlich und überdenken Sie Ihre Entscheidung. Vielleicht ist es Zeit, Ihre Sturheit loszulassen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Gerade jetzt haben Sie Angst, von Ihrem Partner verletzt zu werden. Vielleicht haben Sie Angst, dass er / sie intime Gefühle für jemand anderes haben könnte, oder vielleicht haben Sie Angst, dass sie / er Sie nicht mehr wirklich \"sieht\". Um nicht verletzt zu werden, haben Sie einen Mauer um sich aufgebaut, wie eine symbolische Rüstung. Es ist wichtig für Sie zu verstehen, dass Sie auf dieser Weise auch nicht in der Lage sind Liebe und andere positive Gefühle zu empfangen. Schauen Sie tief in Ihrem Herzen: ist es das, was Sie wirklich wollen?</p><p>Wenn Sie jetzt alleine sind, könnte es klug sein, erstmal Sich Selbst zu heilen von den emotionalen Wunden, die Sie noch haben aus einer früheren Beziehung. Sobald Sie das getan haben, können Sie Ausgehen und auf der Suche nach einer neuen Liebe gehen.</p>",
                        [GuidanceFields.WORK] : "<p>Die Neun der Stäbe sagt Ihnen, dass Sie Sich vielleicht bei der Arbeit bedroht fühlen, und es könnte sogar stichhaltige Gründe für dieses Gefühl geben. Vielleicht könnte ein Kollege versuchen, eine alte Rechnung zu begleichen oder möglicherweise wird Ihre Position neu erwogen. Versuchen Sie zunächst, den Unterschied zwischen echten und wahrgenommenen Bedrohungen zu sehen. Dann können Sie aufhören sich Sorgen zu machen über Bedrohungen von denen Sie nur denken, dass sie existieren. Schließlich ist es Zeit, Ihr Gewissen zu befragen und herauszufinden, welche Rolle Sie bei der Erstellung der gegenwärtigen Situation gespielt haben. Ihr erster Schritt muss sei, Ihre Fehler zu erkennen, der zweite wäre, herauszufinden, ob es noch Zeit gibt um die Sache noch zu reparieren.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Zur Zeit können Sie schwelgerisch sein und vielleicht sogar Mitleid mit Sich Selbst und Ihren Umständen fühlen. Jetzt ist absolut nicht die Zeit, um in sich zu schwelgen! Im Gegenteil, jetzt ist die Zeit reif, um etwas Selbstdisziplin darzustellen um Ihre spirituelle Ziele zu erreichen. Konzentrieren Sie Sich auf das Studium, einen Plan machen, setzen Sie sich einige Ziele … tun Sie alles, was notwendig ist für Sie um eine höhere spirituelle Bindung zu erreichen. Umgeben Sie Sich mit Menschen, die eine positive und proaktive Haltung haben, Menschen, die Ihnen helfen können, ein höheres Niveau zu erreichen.</p>",
                    }
                }
            },
            {
                filename: "31",
                numerology : 10,
                en : {
                    name: "Ten of Wands", subtitle: "Growth",
                    suit : "Wands",
                    element : "🜂 - fire",
                    theme : "wanting a lot",    
                    description : "Ten of Wands talks about wanting a lot and the possibility of losing perspective.",
                    questions : [ "Do you want a lot?", "At the same time?", "Is that costing you a lot of energy?", "How long can you keep that up?", "Are you taking it to heart?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Ten of Wands depicts a situation in which you are feeling down, depressed, burdened, you are feeling the weight of the world on your shoulders. Maybe you have been exerting yourself for a long period, personally and at work. You may feel afraid that the future will only hold more difficulties, more problems, more work to be done. Desperation may be close.</p><p>The first thing to do in order to get rid of this feeling of being a victim, is finding out if part of your burden is maybe belonging to someone else. Have you been taking on the problems and worries from people around you? If so, give them back, they are not yours. And then it is time to stand still, turn inward, go beyond what you are feeling and turn to your inner core, find out which role you played in all these problems and change them. If for the moment you lack courage for this confrontation, do not hesitate to seek help, there is no shame in wanting to heal!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Relationships and love</p><p>You feel a lot of self-pity right now.You think your relationship problems are caused by others, your partner, friends, external influences. Even though this may be partly true, your attitude won't help you solve those problems. The only way out of them is to look at the situation from a different angle and acknowledge your own part and doings. This will help you find a new and positive way out.</p><p>Possibly your partner has problems that have got nothing to do with you. You may feel relieved about that, but don't forget he/she still needs your help and support.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are dealing with a lot of stress and responsibility.Possibly your workload is way too big and even growing. Be honest about this and tell your supervisor you will have to put off some jobs in order to do the more urgent ones. It does nobody any good to suffer in silence, on the contrary, it is counterproductive. If you hesitate to bring up the subject, ask yourself why, what are you afraid might happen? </p><p>If you are looking for a new job you probably aren't very successful for the moment. Don't despair, times will soon change for the better.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Ten of Wands tells you that spiritually you might be in a time of need. Maybe you have reached a point beyond which you cannot evolve without help and assistance. If this is the case, don't hesitate to ask support from the people around you. You may also go out and find new sources of wisdom and spiritual energy.</p><p>But maybe you are just plain tired, maybe you have overexerted yourself, gone too far, beyond your capabilities. Now may be the perfect time to rest and relax, to give your spirit the time to get back to basics.</p>",
                    }
                },
                fr : {
                    name : "Le Dix de Bâton", subtitle: "Croissance",
                    suit : "Bâtons",
                    element :  "🜂 - feu",
                    theme : "vouloir beaucoup",    
                    description : " Le Dix de Bâtons, nous parle de vouloir beaucoup et beaucoup à la fois, on risque de perdre la vue d'ensemble",
                    questions : ["Voulez-vous beaucoup?", "En même temps?", "Cela vous coûte-t-il beaucoup d'énergie?", "Combien de temps pouvez-vous encore continuer?", "Prenez-vous cela trop au sérieux?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Dix de Bâton\" représente une situation dans laquelle vous vous sentez déprimé et alourdi. Vous sentez le poids du monde sur vos épaules. Vous avez peut-être déjà dû faire des efforts sur une plus longue période de temps, en privé ou professionnellement. Vous craignez peut-être qu'il n'y ait d'autres problèmes à l'avenir. Le sentiment de désespoir peut être près de vous.</p><p>La première chose que vous devez faire pour éviter de devenir une victime de ce sentiment est de savoir si une partie de votre fardeau appartient à quelqu'un d'autre. Avez-vous pris en charge les problèmes et les soucis des gens qui vous entourent? Si c'est le cas, retournez-les, elles ne vous appartiennent pas. Et puis il est temps de rester calme et de se tourner vers l'intérieur, d'aller au-delà de ce que vous ressentez, et de vous concentrer sur votre Soi intérieur. Découvrez le rôle que vous avez joué dans tous ces problèmes et changez votre approche. Si en ce moment vous n'avez pas le courage pour cette confrontation, n'hésitez pas à demander de l'aide. Il n'y a pas de honte à vouloir aller mieux !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Relationships and love</p><p>You feel a lot of self-pity right now.You think your relationship problems are caused by others, your partner, friends, external influences. Even though this may be partly true, your attitude won't help you solve those problems. The only way out of them is to look at the situation from a different angle and acknowledge your own part and doings. This will help you find a new and positive way out.</p><p>Possibly your partner has problems that have got nothing to do with you. You may feel relieved about that, but don't forget he/she still needs your help and support.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are dealing with a lot of stress and responsibility.Possibly your workload is way too big and even growing. Be honest about this and tell your supervisor you will have to put off some jobs in order to do the more urgent ones. It does nobody any good to suffer in silence, on the contrary, it is counterproductive. If you hesitate to bring up the subject, ask yourself why, what are you afraid might happen? </p><p>If you are looking for a new job you probably aren't very successful for the moment. Don't despair, times will soon change for the better.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Ten of Wands tells you that spiritually you might be in a time of need. Maybe you have reached a point beyond which you cannot evolve without help and assistance. If this is the case, don't hesitate to ask support from the people around you. You may also go out and find new sources of wisdom and spiritual energy.</p><p>But maybe you are just plain tired, maybe you have overexerted yourself, gone too far, beyond your capabilities. Now may be the perfect time to rest and relax, to give your spirit the time to get back to basics.</p>",
                    }
                },
                sp:{
                    name:"El Diez de Bastos", subtitle: "Crecimiento",
                    suit: "bastos",
                    element: "🜂 – fuego",
                    theme: "quiero mucho",    
                    description: "El Diez de Bastos se trata de mucha voluntad y nos recuerda que cuando queremos mucho a la vez, podemos perder la visión general",
                    questions: ["¿Quieres mucho?", "¿Al mismo tiempo?", "¿Te cuesta mucha energía?", "¿Cuánto tiempo puedes mantenerlo en alto?", "¿Levantas mucho peso?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Diez de Varitas representa una situación en la que se siente triste, deprimido, agobiado, se siente el peso del mundo sobre sus hombros. Tal vez usted ha estado ejerciendo en su mismo durante un largo periodo, en lo personal y en el trabajo. Usted puede sentir miedo de que el futuro sólo tenga más dificultades, más problemas, más trabajo por hacer. La desesperación puede estar cerca. Lo primero que debe hacer para deshacerse de este sentimiento de ser una víctima, es averiguar si parte de su carga es tal vez perteneciente a otra persona. Ha estado teniendo en los problemas y las preocupaciones de la gente que le rodea? Si es así, les dan la espalda, que no son los suyos. Y entonces es el momento de detenerse, girar hacia adentro, ir más allá de lo que está sintiendo y gire a su núcleo interior, saber qué papel que jugó en todos estos problemas y cambiarlos. Si por el momento le falta coraje para esta confrontación, no dude en buscar ayuda, no hay vergüenza en su deseo de sanar!</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Staven Tien", subtitle: "Groei",
                    suit : "Staven",
                    element : "🜂 - vuur",
                    theme : "heel veel willen",    
                    description : "De tien van staven gaat over heel veel willen en wijst ons erop dat wanneer we heel veel tegelijk willen we mogelijk het overzicht kunnen verliezen.",
                    questions : [ "Wil jij veel?", "Tegelijkertijd?", "Kost je dat veel energie?", "Hoe lang hou je dat nog vol?", "Til je er zwaar aan?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Staven Tien symboliseert een situatie waarin je je triest voelt, gedeprimeerd, overbelast. Je hebt het idee dat het volle gewicht van de wereld op je schouders rust. Misschien heb je jezelf al een tijd overbelast, zowel privé als op het werk. Het is best mogelijk dat je nu het gevoel hebt dat de toekomst enkel nog meer werk, meer problemen, meer conflicten zal brengen. Misschien zit je op het randje van de wanhoop.</p><p>Een eerste en heel belangrijke stap is dat je jezelf toestaat om uit de slachtofferrol te komen en je af te vragen of een deel van de last die je draagt misschien van iemand anders is. Heb je de verantwoordelijkheid voor een aantal problemen in je omgeving misschien overgenomen? Als dat het geval is, geef die verantwoordelijkheid dan terug aan de persoon of personen waarover het gaat, het zijn niet jouw problemen maar die van hen, geef hen dan ook de ruimte om ze zelf op te lossen. De tweede stap is de tijd nemen om stil te staan, tot rust te komen, je te richten op je innerlijk. Ga voorbij je emoties en focus op je kern, je essentie. Zoek uit welke rol je gespeeld hebt in al die problemen die je overweldigen en verander daarna die rol, zoek manieren om de dingen anders aan te pakken. Als je op dit moment niet de moed hebt om deze uitdaging alleen aan te gaan, zoek dan hulp. Heling en verandering willen is helemaal niet fout of beschamend, integendeel!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Je hebt op dit moment nogal wat medelijden met jezelf. Je hebt het idee dat de problemen in je relatie door anderen veroorzaakt worden, door je partner, vrienden, invloeden van buitenaf. Ook al is dat deels de waarheid, toch zal deze houding je niet verder helpen, de problemen geraken er niet door opgelost. De enige manier om dat te doen is de situatie vanuit een andere hoek te bekijken, zoek een nieuwe visie op het geheel. Door het zo aan te pakken zal je je eigen aandeel beter kunnen zien en ook erkennen. Eens je zover bent, zal je een positieve en constructieve manier vinden om de dingen op te lossen.</p><p>Soms wijst deze kaart er ook op dat je partner problemen heeft die niets met jou te maken hebben, waar je dus geen aandeel in hebt. Ook al is dat misschien een opluchting voor je, denk eraan dat zij/hij je steun en hulp nodig heeft. Het is aan jou om deel uit te maken van de oplossing, anders vorm je toch een probleem.</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk heb je op dit moment nogal wat stress en verantwoordelijkheid. Misschien is je takenpakket te groot en nog steeds aan het groeien. Als dat het geval is, kaart dit dan eerlijk aan met je verantwoordelijke, vertel dat je een aantal taken (tijdelijk) zal moeten laten vallen om de dringende dingen af te kunnen werken. Niemand heeft er iets aan als jij in stilte aan het lijden bent, integendeel, dat is contraproductief.</p><p>Misschien ben je wat huiverig om hierover te praten. Vraag je dan af waar je bang voor bent, wat is het ergste dat zou kunnen gebeuren?</p><p>Als je op zoek bent naar werk is het best mogelijk dat die zoektocht niet zo vlot verloopt. Wanhoop niet, dit is een tijdelijke situatie die binnenkort een positievere kant opgaat.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Staven Tien wijst erop dat je spiritueel gezien in een nogal behoeftige periode zit. Misschien ben je op een punt gekomen dat je niet verder kan groeien zonder hulp en ondersteuning van anderen. Als dit het geval is, aarzel dan niet en vraag steun aan de mensen om je heen. Misschien is het ook een goed idee om nieuwe spirituele bronnen aan te boren, onderwerpen en richtingen die je nog niet onderzocht hebt.</p><p>Deze kaart kan je ook vertellen dat je gewoon heel moe bent, dat je wat teveel hooi op je vork genomen hebt, dat je over je grenzen heen gegaan bent. Als dat het geval is, dan is nu een goed moment om te rusten en te ontspannen, geef je geest en ziel de tijd om tot zichzelf te komen, terug naar de basis.</p>",
                    }
                },
                de : {
                    name: "Zehn der Stäbe", subtitle: "Wachstum",
                    suit : "Stäbe",
                    element : "🜂 - Feuer",
                    theme : "Viel wollen",    
                    description : "Die Zehn der Stäbe spricht davon, viel zu wollen und viel gleichzeitig zu wollen, man riskiert den Blick für's Ganze zu verlieren.",
                    questions : [ "Wollen Sie viel?", "Zur gleichen Zeit?", "Kostet es Sie viel Energie?", "Wie lange können Sie noch weitermachen?", "Nehmen Sie das zu ernst?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Zehn der Stäbe“ steht für eine Situation, in der Sie sich niedergeschlagen und beschwert fühlen. Sie spüren das Gewicht der Welt auf Ihren Schultern. Vielleicht haben Sie sich bereits über einen längeren Zeitraum hinweg anstrengen müssen, privat oder beruflich. Sie können Angst haben, dass es in Zukunft nur noch mehr Probleme geben könnte. Das Gefühl der Verzweiflung kann in Ihrer Nähe sein.</p><p>Das erste, was Sie tun müssen, damit Sie nicht Opfer dieses Gefühls werden, ist herauszufinden, ob ein Teil Ihrer Belastung vielleicht jemand anderem gehört. Haben Sie die Probleme und Sorgen der Menschen Ihrer Umgebung auf sich genommen? Wenn dies der Fall ist, geben Sie diese zurück, sie sind nicht die Ihren. Und dann ist es Zeit, still zu stehen und sich nach innen zu wenden, über das hinauszugehen, was Sie fühlen, und sich auf Ihr Inneres zu konzentrieren. Finden Sie einmal raus, welche Rolle Sie bei all diesen Problemen gespielt haben und ändern Sie Ihre Vorgehensweise. Wenn es Ihnen in diesem Moment an Mut für diese Konfrontation fehlt, dann zögern Sie nicht Hilfe zu suchen. Es ist keine Schande, gesund werden zu wollen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Sie fühlen sich voller Selbstmitleid im Moment. Sie meinen, dass die Probleme in Ihrer Beziehung verursacht werden durch andere, Ihren Partner, Freunde, sonstige äußere Einflüsse. Auch wenn dies zum Teil wahr sein könnte, wird Ihre Haltung nicht dazu beitragen diese Probleme zu lösen. Der einzige Weg raus ist, dass Sie Sich die Situation aus einem anderen Blickwinkel ansehen und Ihren eigenen Teil hierin erkennen. Dies wird Ihnen helfen einen neuen und positiven Ausweg zu finden.</p><p>Möglicherweise hat Ihr Partner Probleme, die nichts mit Ihnen zu tun haben. Möglicherweise fühlen Sie Sich erleichtert darüber, aber vergessen Sie, dass er / sie immer noch Ihre Hilfe und Unterstützung braucht.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit gibt es eine Menge Stress und Verantwortlichkeiten. Möglich haben Sie viel zu viel Arbeit und das wird sogar vielleicht noch wachsen. Seien Sie ehrlich darüber und informieren Sie Ihren Vorgesetzten und erzählen Sie ihnen, Sie müssen einige Aufgaben abschieben um diejenigen zu tun, die dringender und wichtiger sind. Es tut niemanden Gut in der Stille zu leiden, im Gegenteil, es ist kontraproduktiv. Wenn Sie zögern, das Thema heraus zu bringen, fragen Sie sich, warum, für was haben Sie Angst, was könnte passieren?</p><p>Wenn Sie nach einem neuen Job suchen sind Sie wahrscheinlich nicht sehr erfolgreich für den Moment. Verzweifeln Sie nicht, bald werden bessere Zeiten kommen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Zehn der Stäbe sagt Ihnen, dass Sie spirituell jetzt in Not sein könnten. Vielleicht haben Sie einen Punkt erreicht, an dem Sie nicht ohne Hilfe und Unterstützung zur weiterer Entwicklung kommen können. Wenn dies der Fall ist, zögern Sie nicht, um die Unterstützung der Menschen um Sie herum zu bitten. Sie können auch ausgehen und neue Quellen der Weisheit suchen für spirituelle Energie.</p><p>Aber vielleicht sind Sie einfach nur müde, vielleicht haben Sie sich über angestrengt, sind Sie ein bisschen zu weit über Ihre Fähigkeiten gegangen. Jetzt kann die perfekte Zeit sein, um auszuruhen und zu entspannen, Ihren Geist die Zeit zu geben zurück zu gehen zu der Basis.</p>",
                    }
                }
            },
            {
                filename: "32",
                numerology : 11,
                en : {
                    name: "Child of wands", subtitle: "Seeker",
                    suit : "Wands",
                    court : "Page",
                    element : [ "🜂 - fire", "🜃 - earth" ],
                    theme : "impartially looking at what you want",    
                    description : "The Page of Wands talks about impartially looking at what we want with a child-like light-heartedness.",
                    questions : [ "What would you like?", "What sounds fun to you?", "Can you look at it impartially?", "Be game with your plans." ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>In Tarot the Page cards often stand for chances, opportunities being offered. The Page of Wands carries a strong positive energy, but if you don't actively use that energy it may easily get you distracted, in danger of losing focus. </p><p>If you draw this card you will probably be getting an offer shortly. If you decide to accept this offer it will bring you a lot of chances to grow and evolve and become more conscious. On top of that it may lead to fun things too! Possibly accepting the offer may require some daring and courage, don't let that scare you off. Every step we take in life leads to new experiences, to unknown situations. Staying with what we know doesn't help us grow.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Possibly you aren't very happy in your relationship. Maybe you even have doubts. First ask yourself what the problems are and if you are both willing to try and solve them, to change. Only when you work at this together you will be successful. If your partner isn't ready to commit, it's time to say goodbye and move on. A relationship has to be a two way street.</p><p>If you are single you may well meet someone who really sweeps you off your feet. Life may become a tempting adventure, a sensational journey. Take this new opportunity and enjoy it to the fullest.</p>",
                        [GuidanceFields.WORK] : "<p>At work it's very likely that you are getting a lot of chances and opportunities these days. And even if they’re unexpected, try to accept them without hesitation. They are bound to turn out positively and help you grow. You may even get a promotion and/or a raise.</p><p>If you are looking for a job, you will notice that people are happy to help you, to assist you in your search. You will have many good prospects and are sure to find a job that exceeds your expectations.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Drawing the Page of Wands tells you that spiritually it is a good time to let go of too much seriousness. Set your inner child free! Let it play and enjoy itself without wondering if any goals are being reached, any lessons are being learnt. The purpose of this period is not to study and achieve things, it is about playing, relaxing, dreaming. Maybe the only goal to reach is enriching and bettering your quality of life.</p>",
                    }
                },
                fr : {
                    name : "L'enfant de Bâtons", subtitle: "Aspirant",
                    suit : "Bâtons",
                    court : "Valet",
                    element :  ["🜂 - feu", "🜃 - terre" ],
                    theme : "regarder ce que l'on veut sans préjugé",    
                    description : "Valet de Bâtons,  nous parle de regarder ce que l'on veut sans préjugé et d’un esprit enfantin.",
                    questions : ["Que voulez-vous?", "Qu' aimez-vous?", "Pouvez-vous regarder cela avec un esprit ouvert?", "Jouez avec vos projets." ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "p>Dans le tarot, le valet est souvent synonyme d'opportunités, de possibilités offertes. Le \"Valet de Bâton\" est porteur d'une forte énergie positive. Néanmoins, il est important que vous utilisiez activement cette énergie. Si vous ne le faites pas, il peut facilement arriver que vous soyez distrait, en danger et que vous perdiez votre concentration.</p><p>Si vous tirez cette carte, vous recevrez probablement bientôt une offre. Si vous acceptez cette offre, vous aurez beaucoup d'occasions de grandir, d'évoluer et de devenir plus conscient. En plus de cela, il peut vous mener au plaisir! Accepter l'offre peut vous demander de l'audace et du courage, mais ne vous laissez pas décourager. Chaque pas que nous faisons dans la vie mène à de nouvelles expériences, à des situations inconnues. Si nous nous en tenons à ce que nous savons, cela ne nous aide pas à grandir.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Possibly you aren't very happy in your relationship. Maybe you even have doubts. First ask yourself what the problems are and if you are both willing to try and solve them, to change. Only when you work at this together you will be successful. If your partner isn't ready to commit, it's time to say goodbye and move on. A relationship has to be a two way street.</p><p>If you are single you may well meet someone who really sweeps you off your feet. Life may become a tempting adventure, a sensational journey. Take this new opportunity and enjoy it to the fullest.</p>",
                        [GuidanceFields.WORK] : "<p>At work it's very likely that you are getting a lot of chances and opportunities these days. And even if they’re unexpected, try to accept them without hesitation. They are bound to turn out positively and help you grow. You may even get a promotion and/or a raise.</p><p>If you are looking for a job, you will notice that people are happy to help you, to assist you in your search. You will have many good prospects and are sure to find a job that exceeds your expectations.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Drawing the Page of Wands tells you that spiritually it is a good time to let go of too much seriousness. Set your inner child free! Let it play and enjoy itself without wondering if any goals are being reached, any lessons are being learnt. The purpose of this period is not to study and achieve things, it is about playing, relaxing, dreaming. Maybe the only goal to reach is enriching and bettering your quality of life.</p>",
                    }
                },
                sp:{
                    name:"Niño de bastos", subtitle: "Aspirante",
                    suit: "bastos",
                    court: "Soto",
                    element: ["🜂 - fuego", "🜃 - tierra" ],
                    theme: "mirar lo que te quieres con la mente abierta",    
                    description: "El Soto de Bastos trata de mirar lo que quieres con una mente abierta y nos recuerda que podemos mirarlo con una mentalidad infantil y abierta",
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>En las cartas de Tarot las tarjetas de Sota menudo representan posibilidades, las oportunidades que se ofrecen. La Sota de Varita lleva una energía positiva muy fuerte, pero si no se utiliza de forma activa que la energía se puede conseguir fácilmente distraído, en peligro de perder el foco. Si dibuja esta carta probablemente será conseguir una oferta en breve. Si usted decide aceptar esta oferta que le traerá una gran cantidad de oportunidades para crecer y evolucionar y ser más consciente. Además de eso puede llevar a cosas divertidas también! Posiblemente la aceptación de la oferta puede requerir algún atrevido y valentía, no deje que eso le asuste. Cada paso que damos en la vida conduce a nuevas experiencias, a situaciones desconocidas. Quedarse con lo que conocemos no nos ayuda a crecer.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Kind van Staven", subtitle: "Aspirant",
                    suit : "Staven",
                    court : "Page",
                    element : [ "🜂 - vuur", "🜃 - aarde" ],
                    theme : "onbevooroordeeld kijken naar wat je wilt",    
                    description : "De page van staven gaat over onbevooroordeeld kijken naar wat je wilt en wijst ons erop dat we daar met kinderlijke onbevangenheid naar mogen kijken.",
                    questions : [ "Wat wil jij graag?", "Wat lijkt jou leuk?", "Kun jij daar met een open blik naar kijken?", "Speel eens met je plannetjes." ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>In de Tarot is de Page vaak het symbool voor nieuwe kansen en mogelijkheden die op je weg komen. De Page van Staven heeft een heel sterke positieve energie. Toch blijft het belangrijk dat je zelf actief aan de slag gaat met die energie. Als je dat niet doet en het gewoon over je heen laat komen dan zal je gemakkelijk afgeleid en verstrooid zijn en daardoor niet enkel je focus verliezen, maar ook het mogelijke positieve resultaat.</p><p>Als je deze kaart trekt dan is het heel waarschijnlijk dat je binnenkort een aanbod krijgt. Als je dit aanbod aanvaardt dan zal dit leiden tot verschillende mogelijkheden om te groeien en bewuster te worden. Daar bovenop kan het aanbod ook leiden tot leuke en fijne ervaringen!</p><p>Misschien zal je wat moed en durf nodig hebben om op dit aanbod in te gaan maar laat je dat niet afschrikken. Elke stap die we in dit leven zetten leidt ons immers naar nieuwe ervaringen en onbekende situaties. Je kan nu eenmaal niet groeien als je gewoon blijft waar je bent.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment ben je misschien niet echt gelukkig in je relatie. Misschien heb je er zelfs twijfels over of je er wel mee wil doorgaan. Voor je actie onderneemt is het verstandig om je in de eerste plaats af te vragen wat de problemen juist zijn èn of jullie allebei bereid zijn om te proberen werken aan een oplossing ervoor, om te veranderen. Enkel als jullie dit samen aanpakken, zal je succes hebben. Als je partner niet bereid is om zich te engageren bij het zoeken van een oplossing, dan is het tijd om afscheid te nemen en verder te gaan met je leven. Een relatie kan enkel werken als er sprake is van tweerichtingsverkeer.</p><p>Als je op zoek bent naar een partner dan is het goed mogelijk dat je iemand zal ontmoeten die je in vuur en vlam zet. Daardoor wordt je leven ineens een verleidelijk avontuur, een spannende reis. Grijp deze nieuwe kans aan en geniet er ten volle van!</p>",
                        [GuidanceFields.WORK] : "<p>Het zit er dik in dat je op dit moment in je werkomgeving nogal wat kansen en mogelijkheden krijgt. Probeer ze zonder aarzelen aan te pakken, ook al komen ze onverwacht. Zoals het klimaat nu is zal je waarschijnlijk succes hebben en zullen alle nieuwe initiatieven vruchten afwerpen. Je maakt zelfs kans op opslag of een promotie.</p><p>Als je een baan zoekt dan zal je merken dat de mensen er echt voor openstaan om je daarin te ondersteunen, je te helpen bij je zoektocht. Er komen veel goede vooruitzichten en de kans is groot dat je een baan vindt die je verwachtingen overtreft.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Page van Staven vertelt je dat je op dit ogenblik je spirituele leven niet al te ernstig moet nemen, til niet te zwaar aan de dingen en laat je innerlijke kind naar buiten komen! Speel, geniet en vraag je daarbij niet af of je wel bepaalde doelen zal bereiken of lessen zal leren. De bedoeling van deze (fijne) periode is niet om te studeren en jezelf uitdagingen op te leggen. Neen, het gaat hier om ontspannen, genieten en dromen zonder meer. Het enige doel wat je jezelf kan stellen is om je levenskwaliteit te verrijken en te verbeteren. Geniet ervan!</p>",
                    }
                },
                de : {
                    name: "Kind der Stäbe", subtitle: "Anwärter",
                    suit : "Stäbe",
                    court : "Bube",
                    element : [ "🜂 - Feuer", "🜃 - Erde" ],
                    theme : "Schauen, was man will, ohne Vorurteile.",    
                    description : "Der Bube der Stäbe spricht darüber zu schauen, was man will, ohne Vorurteile und mit einem kindlichen Geist.",
                    questions : [ "Was wollen Sie?", "Was mögen Sie?", "Können Sie das mit einem offenen Geist betrachten?", "Spielen Sie mit Ihren Projekten." ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Im Tarot steht der Bube oft für Chancen, für sich anbietende Möglichkeiten. Die „Bube der Stäbe“ trägt eine starke positive Energie. Dennoch ist es wichtig, dass Sie diese Energie auch aktiv nützen. Wenn Sie das nicht tun, kann es leicht passieren, dass Sie abgelenkt werden, in Gefahr geraten und den Fokus verlieren.</p><p>Wenn Sie diese Karte ziehen bekommen Sie wahrscheinlich in Kürze ein Angebot. Wenn Sie dieses Angebot annehmen wird es Ihnen eine Menge Chancen bringen um zu wachsen, sich weiter zu entwickeln und bewusster zu werden. Obendrein kann es Sie zum Spaß führen! Möglicherweise kann die Annahme des Angebots Kühnheit und Mut von Ihnen verlangen, aber lassen Sie sich nicht abschrecken. Jeder Schritt, den wir im Leben tun, führt zu neuen Erfahrungen, zu unbekannten Situationen. Wenn wir bei dem bleiben, was wir kennen, hilft uns das nicht beim Wachsen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Möglicherweise sind Sie nicht sehr glücklich in Ihrer Beziehung. Vielleicht haben Sie sogar Zweifel. Fragen Sie Sich zuerst, was die Probleme sind und ob sie beide bereit sind zu versuchen die Probleme zu lösen und die Situation zu ändern. Erst wenn Sie auf dieser Weise zusammenarbeiten, werden Sie erfolgreich sein. Wenn Ihr Partner nicht bereit ist sich dazu zu verpflichten, ist es Zeit, sich zu verabschieden und weiterzumachen. Eine Beziehung kann keine Einbahnstraße sein.</p><p>Wenn Sie alleine sind können Sie auch jemanden treffen der Sie wirklich von den Füssen bläst. Das Leben kann ein verlockendes Abenteuer, eine sensationelle Reise werden. Nutzen Sie diese neue Chance und genießen Sie es in vollen Zügen.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit ist es sehr wahrscheinlich, dass Ihnen in diesen Tagen eine Menge von Chancen und Möglichkeiten angeboten werden. Und auch wenn das unerwartet ist, versuchen Sie sie ohne Zögern zu akzeptieren. Sie sollten positiv auspacken und Ihnen helfen zu wachsen. Sie könnten sogar eine Beförderung und / oder eine Gehaltserhöhung empfangen</p><p>Wenn Sie auf der Suche nach einem Job sind, werden Sie feststellen, dass die Menschen glücklich und bereit sind Ihnen bei Ihrer Suche zu helfen. Sie haben viele gute Perspektiven und werden bestimmt einen Job finden, der Ihre Erwartungen übertrifft.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Ziehen Sie den Bube der Stäbe, dann heißt das, dass es spirituell eine gute Zeit ist zu viel Ernsthaftigkeit abzulegen. Lassen Sie Ihr inneres Kind frei! Lassen Sie es spielen und genießen ohne sich zu fragen, ob irgendwelche Ziele erreicht worden sind, oder alle Lektionen gelernt wurden. Der Zweck dieser Periode ist nicht studieren und Ziele erreichen. Sie dient zu spielen, entspannen, träumen. Vielleicht das einzige Ziel das zu erreichen wäre, ist Bereicherung und Besserung der Lebensqualität.</p>",
                    }
                }
            },
            {
                filename: "33",
                numerology : 12,
                en : {
                    name: "Sage of Wands", subtitle: "Seer",
                    suit : "Wands",
                    court : "Knight",
                    element : [ "🜂 - fire", "🜁 - air" ],
                    theme : "enthusiastically go public",    
                    description : "Knight of Wands talks about showing what we want and enthusiastically going public with it.",
                    questions : [ "What makes you go into raptures?", "Can you show that enthusiastically?", "Can you motivate others passionately?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Knight of Wands is a card with many faces. It shows you that for the moment you have great passion and enthousiasm in fulfilling a certain task or project. But it also warns us not to be overzealous, not to get carried away by that enthousiasm, to respect and acknowledge certain borders. Your motives are noble but it is very important to keep an oversight of the situation.</p><p>This is a time in which you may receive positive and uplifting messages, more often work related than personal. These messages speak of success and appreciation, of achieving goals, often better than expected.</p><p>This card may also tell us now is a good time to travel.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>For the moment you are feeling very close to your partner and very enthusiastic about your relationship. Take the time to regularly communicate, to have profound talks about how you feel and what you expect. If you do that, you can rest assured that your partner feels the same way you do.</p><p>Or maybe you are passionately and energetically on the lookout for a new love interest. You might ask yourself if your desire to be searching isn't bigger than your need to find someone. Is there a chance you are surpassing yourself unknowingly?</p>",
                        [GuidanceFields.WORK] : "<p>If you are currently looking for a job, this card tells you that good news is on the way. You will probably get the job you are hoping for and you can rest assured you will be happy and successful once you have it.</p><p>If you already have a position, you are very zealous and maybe you should be careful not to overdo it. You know your own temperament very well, but you cannot expect the same from everybody around you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>When you draw the Knight of Wands you are looking for new challenges, new subjects to study, new roads to explore. You feel like experiencing something different, something fresh. And suddenly you may find a new field or approach to spirituality that totally enchants you, gets your rapt attention. Even though it is a good thing to find new interests, it is also important to give attention to the old ones. Giving time, energy, maybe even money to spiritual causes is a powerful form of meditation too.</p>",
                    }
                },
                fr : {
                    name : "Sage des Bâtons", subtitle: "visionnaire",
                    suit : "Bâtons",
                    court : "Cavalier",
                    element :  ["🜂 - feu", "🜁 - air" ],
                    thème : " Aller de l’avant passionnément",    
                    description : "Le Cavalier des Bâtons nous parle de montrer ce que nous voulons  et que nous pouvons montrer notre enthousiasme au monde autour de nous.",
                    questions : ["Que vous excite-t-il?", "Pouvez-vous montrer votre enthousiasme?", "Pouvez-vous motiver les autres pleins de fougue?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le «Chevalier de Bâton» est une carte aux multiples visages. Il vous montre qu'en ce moment vous avez une grande passion et un grand enthousiasme pour accomplir une tâche ou un projet particulier. Mais il vous avertit aussi de ne pas faire trop de zèle. Ne vous laissez pas emporter par cet enthousiasme, mais respectez et reconnaissez certaines limites. Vos motivations sont nobles, mais il est très important de garder une vue d'ensemble de la situation.</p><p>C'est une période où vous pourriez recevoir des messages positifs et édifiants qui ont plus à voir avec les relations d'affaires qu'avec les relations personnelles. Ces messages parlent de succès et de reconnaissance, les objectifs sont généralement mieux atteints que prévu.</p><p>Cette carte peut aussi dire que c'est le bon moment pour voyager.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>For the moment you are feeling very close to your partner and very enthusiastic about your relationship. Take the time to regularly communicate, to have profound talks about how you feel and what you expect. If you do that, you can rest assured that your partner feels the same way you do.</p><p>Or maybe you are passionately and energetically on the lookout for a new love interest. You might ask yourself if your desire to be searching isn't bigger than your need to find someone. Is there a chance you are surpassing yourself unknowingly?</p>",
                        [GuidanceFields.WORK] : "<p>If you are currently looking for a job, this card tells you that good news is on the way. You will probably get the job you are hoping for and you can rest assured you will be happy and successful once you have it.</p><p>If you already have a position, you are very zealous and maybe you should be careful not to overdo it. You know your own temperament very well, but you cannot expect the same from everybody around you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>When you draw the Knight of Wands you are looking for new challenges, new subjects to study, new roads to explore. You feel like experiencing something different, something fresh. And suddenly you may find a new field or approach to spirituality that totally enchants you, gets your rapt attention. Even though it is a good thing to find new interests, it is also important to give attention to the old ones. Giving time, energy, maybe even money to spiritual causes is a powerful form of meditation too.</p>",
                    }
                },
                sp:{
                    name:"Sabio de bastos", subtitle: "visionario",
                    suit: "bastos",
                    court: "Caballo",
                    element: ["🜂 - fuego", "🜁 - aire"],
                    theme: "salir con entusiasmo",    
                    description: "El caballo de Bastos trata de mostrar lo que quieres y nos recuerda que podemos mostrar nuestro entusiasmo al mundo que nos rodea",
                    questions: ["¿De qué estás emocionado?", "¿Puedes demostrarlo con entusiasmo?", "¿Puedes motivar a otros lleno de fuego?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Caballo de Varitas es una tarjeta con muchas caras. Le muestra que para el momento en que tiene una gran pasión y entusiasmo en el cumplimiento de una determinada tarea o proyecto. Pero también advierte que no seamos demasiado entusiasta, no dejarse llevar por el entusiasmo, a respetar y reconocer ciertas fronteras. Sus motivos son nobles, pero es muy importante mantener una supervisión de la situación. Este es un momento en el que puede recibir mensajes positivos y edificantes, más a menudo relacionadas con el trabajo que personal. Estos mensajes hablan de éxito y reconocimiento, de la consecución de objetivos, a menudo mejor de lo esperado. Esta tarjeta puede también decirnos que ahora es un buen momento para viajar.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Wijze van Staven", subtitle: "Ziener",
                    suit : "Staven",
                    court : "Ridder",
                    element : [ "🜂 - vuur", "🜁 - lucht" ],
                    theme : "enthousiast naar buiten treden",    
                    description : "De ridder van staven gaat over laten zien wat je wilt en wijst ons erop dat we ons enthousiasme aan de wereld om ons heen mogen tonen.",
                    questions : [ "Waar loop jij warm voor?", "Kun jij dat enthousiast tonen?", "Kun jij anderen vol vuur motiveren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Ridder van Staven is een kaart met vele gezichten. Ze kan je vertellen dat je op dit ogenblik in je leven met grote passie en enthousiasme bezig bent met een bepaalde taak of project. Maar ze kan je ook waarschuwen om niet overijverig te zijn, niet teveel hooi op je vork te nemen. Laat je niet meeslepen door dat enthousiasme maar erken en respecteer bepaalde grenzen van anderen. Je motieven zijn weliswaar nobel genoeg maar het is toch belangrijk om af en toe naar het grotere geheel te kijken. Je bent niet alleen op de wereld.</p><p>In deze periode kan je ook positieve en opbouwende berichten krijgen en meestal is het eerder in verband met je werk dan met privézaken. Deze berichten vertellen je dat je succes hebt en gewaardeerd wordt, dat je je gestelde doelen bereikt, over het algemeen eerder en beter dan verwacht werd.</p><p>Deze kaart kan je ook vertellen dat het nu een goed moment is om op reis te gaan of een uitstap te plannen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment voel je je heel dicht bij je partner en ben je echt positief over je relatie. Neem voldoende tijd om geregeld te communiceren, om diepgaande gesprekken aan te gaan over hoe je je voelt en wat je verwachtingen zijn. Als je dat geregeld doet, dan zal je merken dat jij en je partner totaal op dezelfde golflengte zitten.</p><p>Misschien ben je op dit moment energetisch vol overtuiging op zoek naar een nieuwe liefde. Vraag je eens af of je eigenlijk niet enthousiaster bent over het zoeken zelf dan over het vinden van iemand. Gaat je interesse op dit moment niet meer naar de jacht dan naar het resultaat ervan? Is het mogelijk dat je jezelf wat aan het voorbijlopen bent? Probeer je eens te focussen op het doel van je zoektocht: wie wil je vinden aan het einde van de rit?</p>",
                        [GuidanceFields.WORK] : "<p>Als je op dit moment op zoek bent naar werk dan vertelt deze kaart je dat er binnenkort goed nieuws komt. Er is een grote kans dat je de baan krijgt waar je op hoopt en eens aan de slag zal je tevreden en succesvol zijn in je werk. Als je al aan het werk bent dan ben je op dit moment heel ijverig bezig. Misschien zou je het iets kalmer aan moeten doen? Je kent jezelf goed, je weet dat je temperamentvol en energiek bent, maar je kan hetzelfde niet verwachten van iedereen om je heen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Als je de Ridder van Staven trekt dan betekent dit dat je op zoek bent naar nieuwe uitdagingen op spiritueel niveau, je wil onbekende onderwerpen bestuderen, niet vertrouwde wegen inslaan en onderzoeken. Je hebt echt zin om je tanden te zetten in iets nieuws, iets anders. En je zal dat andere onderwerp, die andere aanpak ook vinden, je zal er vol energie induiken en er intens van genieten.</p><p>Ook al is het zinvol en positief om geregeld van focus te veranderen, geef toch ook nog even aandacht aan de interessevelden die je achterlaat. Tijd, energie, soms zelfs een schenking geven aan spirituele doelen kan een heel sterke vorm van meditatie zijn.</p>",
                    }
                },
                de : {
                    name: "Weisen der Stäbe", subtitle: "Visionär",
                    suit : "Stäbe",
                    court : "Ritter",
                    element : [ "🜂 - Feuer", "🜁 - Luft" ],
                    theme : "Mit Leidenschaft voranschreiten",    
                    description : "Der Ritter der Stäbe spricht darüber zu zeigen, was wir wollen und dass wir unsere Begeisterung der Welt um uns herum zeigen können.",
                    questions : [ "Was begeistert Sie?", "Können Sie Ihre Begeisterung zeigen?", "Können Sie andere voller Energie motivieren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der „Ritter der Stäbe“ ist eine Karte mit vielen Gesichtern. Sie zeigt Ihnen, dass Sie im Moment eine große Leidenschaft und Begeisterung für die Erledigung einer bestimmten Aufgabe oder eines Projektes haben. Aber sie warnt Sie auch davor, nicht zu übereifrig zu werden. Lassen Sie sich nicht von dieser Begeisterung mitreißen, sondern respektieren und erkennen Sie bestimmte Grenzen. Ihre Motive sind edel, aber es ist sehr wichtig, eine allgemeine Übersicht über die Situation zu behalten.</p><p>Dies ist eine Zeit, in der Sie positive und erhebende Nachrichten empfangen könnten, die eher mit geschäftlichen als mit persönlichen Beziehungen zu tun haben. Diese Nachrichten sprechen von Erfolg und Anerkennung, Ziele werden in der Regel besser als erwartet erreicht.</p><p>Diese Karte kann auch sagen, dass jetzt eine gute Zeit für eine Reise ist.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Zur Zeit fühlen Sie Sich ganz in der Nähe von Ihrem Partner und sind sehr begeistert von Ihrer Beziehung. Nehmen Sie sich Zeit, um regelmäßig zu kommunizieren, um tiefe Gespräche zu führen über wie Sie sich fühlen und was Sie erwarten können. Wenn Sie das tun, können Sei sicher sein, dass Ihr Partner sich genau so fühlt wie Sie.</p><p>Oder vielleicht sind Sie leidenschaftlich und energisch auf der Suche nach einer neuen interessanten Liebe. Sie fragen sich, ob Ihr Wunsch nach der Suche nicht größer ist als die Notwendigkeit jemanden zu finden. Könnte es sein, dass Sie Sich ungemein übertreffen?</p>",
                        [GuidanceFields.WORK] : "<p>Wenn Sie derzeit auf der Suche nach einem Job sind, sagt diese Karte, dass Sie in Kurzem eine gute Nachricht empfangen werden. Sie werden wahrscheinlich den Job bekommen und falls Sie den haben, können Sie sicher sein, dass Sie darin glücklich und erfolgreich sein werden.</p><p>Wenn Sie bereits eine gute Stellung haben, sind Sie sehr eifrig und vielleicht sollten Sie darauf achten es nicht zu übertreiben. Sie kennen Ihr eigenes Temperament sehr gut, aber man kann nicht das gleiche von jedem um Sie erwarten.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Wenn Sie den Ritter der Stäbe ziehen, sind Sie auf der Suche nach neuen Herausforderungen und wollen Sie neue Themen studieren, neue Wege entdecken. Sie wollen etwas anderes, etwas Frisches erleben. Und plötzlich finden Sie einen neuen Bereich oder Ansatz zur Spiritualität, der Sie völlig verzaubert und Ihre gespannte Aufmerksamkeit weckt. Auch wenn es eine gute Sache ist, neue Interessen zu finden, ist es auch wichtig, die Aufmerksamkeit auf den alten zu behalten. Das Schenken von Zeit, Energie, und vielleicht sogar Geld für spirituelle Ziele ist ein kräftiger Form zur Meditation.</p>",
                    }
                }
            },
            {
                filename: "34",
                numerology : 13,
                en : {
                    name: "Woman of Wands", subtitle: "Sensor",
                    suit : "Wands",
                    court : "Queen",
                    element : [ "🜂 - fire", "🜄 - water" ],
                    theme : "taking care of what you want",    
                    description : "Queen of Wands speaks of paying attention to what you want. Make way for what you want",
                    questions : [ "Do you know your heart’s desire?", "Are you aware of it?", "Can you express it?", "Are you a feisty one?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card depicts the feminine power of the element Fire, it stands for fertility, both literally and figuratively. The Queen of Wands is about life energy and inventiveness, sense of initiative, it's about looking at the world with pride and confidence.</p><p>Even though this card is very positive, it may also point out that you might be a bit self-absorbed for the moment. Take the time to ponder on how others perceive you and take that into account.</p><p>Generally, this card carries a very positive energy and meaning.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you get this card it means that you are experiencing or about to experience stormy and stirring times in your relationship. The only thing for you to do now is fully enjoy these experiences. Just make sure you don’t get sidetracked.</p><p>If you are currently single, this is absolutely not the time to be alone. Get out, go out into the world and enjoy whatever situation you get yourself into. No matter where, no matter how, you are very likely to meet someone and find what it is you are looking for.</p>",
                        [GuidanceFields.WORK] : "<p>At work you feel a strong desire to get more responsibility, more independence. Seize every opportunity that presents itself with both hands. You will soon be successful and achieve the results you were craving. This process will help you develop and grow in your job.</p><p>If you're looking for a position, you are very likely to get help from someone you meet. This person will help you find just that one chance you needed to prove yourself.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Queen of Wands indicates that you are experiencing a sort of spiritual hunger. You are on the lookout for new knowledge, new teachings and new insights. You are looking for those everywhere and anywhere. A lot of what you find will be interesting and stimulating but be aware, you might also encounter some false prophets. If you think you have finally found the true meaning of life and living, make sure you discuss this find with friends and relatives. Dissect it, study it thoroughly before you make it your life's new goal.</p>",
                    }
                },
                fr : {
                    name : "Femme des Bâtons", subtitle: "Observateur",
                    suit : "Bâtons",
                    court : "Reine",
                    element :  [ "🜂 - feu", "🜄 - eau" ],
                    theme : "prendre soin de ce que vous voulez",    
                    description : "La Reine des Bâtons, nous parle de porter de l’attention à ce que nous voulons. Donnez de l’espace à ce que vous voulez.",
                    questions : ["Savez-vous ce que vous voulez au fond de vous?", "Êtes-vous attentif à cela?", "Pouvez-vous l'exprimer?", "Êtes-vous sévère?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte montre le pouvoir féminin de l'élément feu, il représente la fertilité, au sens propre et figuré. La \"Reine de Bâton\" est synonyme de vie, d'énergie, d'inventivité et d'initiative. Il s'agit de regarder le monde avec fierté et confiance.</p><p>Même si cette carte est très positive, elle peut indiquer que vous êtes un peu trop occupé avec vous-même en ce moment. Prenez le temps de percevoir comment les autres le vivent et d'en tenir compte.</p><p>En général, cette carte a une énergie et une signification très positives.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you get this card it means that you are experiencing or about to experience stormy and stirring times in your relationship. The only thing for you to do now is fully enjoy these experiences. Just make sure you don’t get sidetracked.</p><p>If you are currently single, this is absolutely not the time to be alone. Get out, go out into the world and enjoy whatever situation you get yourself into. No matter where, no matter how, you are very likely to meet someone and find what it is you are looking for.</p>",
                        [GuidanceFields.WORK] : "<p>At work you feel a strong desire to get more responsibility, more independence. Seize every opportunity that presents itself with both hands. You will soon be successful and achieve the results you were craving. This process will help you develop and grow in your job.</p><p>If you're looking for a position, you are very likely to get help from someone you meet. This person will help you find just that one chance you needed to prove yourself.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Queen of Wands indicates that you are experiencing a sort of spiritual hunger. You are on the lookout for new knowledge, new teachings and new insights. You are looking for those everywhere and anywhere. A lot of what you find will be interesting and stimulating but be aware, you might also encounter some false prophets. If you think you have finally found the true meaning of life and living, make sure you discuss this find with friends and relatives. Dissect it, study it thoroughly before you make it your life's new goal.</p>",
                    }
                },
                sp:{
                    name:"Mujer de bastos", subtitle: "Observador",
                    suit: "bastos",
                    court: "Reina",
                    element: ["🜂 - fuego", "🜄 - agua" ],
                    theme: "cuida lo que quieres",    
                    description: "La Reina de Bastos se trata de prestar atención a lo que quieres. Da espacio a lo que quieras",
                    questions: ["¿Sabes lo que quieres en el fondo?", "¿Estás alerta en eso?", "¿Puedes expresarlo?", "¿Eres una tía picante?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta carta representa el poder femenino del elemento fuego, que representa la fertilidad, tanto en sentido literal y figurado. La Reina de Varitas se trata de la energía vital y la inventiva, sentido de la iniciativa, se trata de mirar al mundo con orgullo y confianza. A pesar de que esta tarjeta es muy positiva, también puede señalar que usted puede ser un poco de auto-absorbido por el momento. Tómese el tiempo para reflexionar sobre cómo le perciben los demás y tomar eso en cuenta. Generalmente, esta tarjeta tiene una energía muy positiva y significado.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Vrouw van Staven", subtitle: "Waarnemer",
                    suit : "Staven",
                    court : "Koningin",
                    element : [ "🜂 - vuur", "🜄 - water" ],
                    theme : "zorg dragen voor wat je wilt",    
                    description : "De Koningin van Staven gaat over aandacht schenken aan wat je wilt. Geef wat jij wilt de ruimte",
                    questions : [ "Weet jij wat je diep van binnen wilt?", "Ben jij daar alert op?", "Kun jij dat uiten?", "Ben jij een pittige tante?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart symboliseert de vrouwelijke kracht van het element Vuur. Het gaat hier om vruchtbaarheid, zowel letterlijk als figuurlijk. De Koningin van Staven staat voor levensenergie en vindingrijkheid, voor initiatief nemen. Deze kaart vraagt je om trots en vol vertrouwen naar de wereld te kijken.</p><p>Ook al is deze kaart energetisch heel positief, toch kan er ook een waarschuwing in schuilen: mogelijk ga je op dit moment een beetje teveel in jezelf op. Neem even de tijd om erover na te denken hoe anderen je zien, hoe je overkomt en houd daar dan rekening mee. In een legging geeft deze kaart altijd positieve energie en betekenis aan het geheel.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je deze kaart trekt dan betekent het dat je op dit moment een stormachtige en opwindende tijd beleeft in je relatie. Je hoeft eigenlijk niet meer te doen dan volop genieten van de ervaring. Let wel op dat je niet teveel afgeleid raakt hierdoor; er zijn nog andere dingen in je leven dan enkel je relatie.</p><p>Als je single bent dan is dit voor jou eigenlijk echt geen goede tijd om alleen te zijn. Trek erop uit, ga de wereld verkennen en geniet van alle ervaringen die op je afkomen. Het maakt niet uit waar, het maakt niet uit hoe, het zit er dik in dat je iemand zal ontmoeten en daardoor zal vinden waar je naar op zoek was.</p>",
                        [GuidanceFields.WORK] : "<p>Op werkvlak verlang je tegenwoordig heel sterk naar meer verantwoordelijkheid en onafhankelijkheid. Grijp alle kansen die zich voordoen dan ook met beide handen aan. Gelijk wat je doet, je zal er succes mee boeken en je zal bereiken waar je naar verlangt. Proactief en actief zijn zal ertoe leiden dat je groeit en je volop ontwikkelt in je baan.</p><p>Als je op dit moment op zoek bent naar werk dan zal je binnenkort waarschijnlijk iemand ontmoeten die je daarbij kan helpen. Deze persoon kan je de ideale gelegenheid bieden om jezelf te bewijzen en daardoor een fijne job te vinden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Koningin van Staven wijst je erop dat je op dit moment een soort spirituele honger ervaart. Je bent op zoek naar nieuwe kennis, nieuwe lessen en nieuwe inzichten. Je bent zo leergierig dat je niet weet waar het eerst te gaan zoeken. Je zal natuurlijk veel interessante en stimulerende dingen vinden, de wereld is er vol van. Maar let op voor de valse profeten die je op je weg zal vinden. Als er een moment komt waarop je denkt eindelijk de ware leer gevonden te hebben, ga er dan over praten met je vrienden. Denk erover na, voer er gesprekken over, analyseer alle gegevens. Pas als je dan nog altijd helemaal overtuigd bent, kan dit je nieuwe spirituele overtuiging worden.</p>",
                    }
                },
                de : {
                    name: "Frau der Stäbe", subtitle: "Beobachter",
                    suit : "Stäbe",
                    court : "Königin",
                    element : [ "🜂 - Feuer", "🜄 - Wasser" ],
                    theme : "Sich darum kümmern, was man will.",    
                    description : "Die Königin der Stäbe spricht darüber, darauf zu achten, was wir wollen. Geben Sie dem Raum, was Sie wollen.",
                    questions : [ "Wissen Sie tief im Inneren, was Sie wollen?", "Achten Sie darauf?", "Können Sie es ausdrücken?", "Sind Sie ernst?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte symbolisiert die weibliche Kraft des Elements Feuer, sie steht für Fruchtbarkeit, im wörtlichen und im übertragenen Sinne. Die „Königin der Stäbe“ steht für das Leben, für Energie, für Ideenreichtum und für Eigeninitiative. Es geht darum, die Welt mit Stolz und Zuversicht zu betrachten.</p><p>Auch wenn diese Karte sehr positiv ist kann sie darauf hinweisen, dass Sie in diesem Moment ein bisschen zu viel mit sich selbst beschäftigt sind. Nehmen Sie sich Zeit wahrzunehmen, wie anderen das erfahren und berücksichtigen Sie das.</p><p>Im Allgemeinen hat diese Karte eine sehr positive Energie und Bedeutung.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Falls Sie diese Karte bekommen bedeutet es, dass Sie stürmische und bewegte Zeiten in Ihrer Beziehung erleben. Das einzige, was für Sie jetzt zu tun ist, ist voll und ganz diese Erfahrungen genießen So stellen Sie sicher, dass Sie nicht abgelenkt werden.</p><p>Dies ist absolut nicht der richtige Zeitpunkt um allein zu sein. Raus, raus in die Welt und genießen, egal in welcher Situation Sie geraten. Egal wo, egal wie, es ist sehr wahrscheinlich, dass Sie jemanden treffen und dann gefunden haben werden was Sie suchen.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit fühlen Sie einen starken Wunsch mehr Verantwortung, mehr Unabhängigkeit zu erhalten. Nutzen Sie jede Gelegenheit, die sich anbietet mit beiden Händen. Sie werden bald erfolgreich sein und die Ergebnisse erzielen, die Sie Sich gewünscht haben. Dieser Prozess wird Ihnen helfen Sich zu entwickeln und zu wachsen in Ihrem Job.</p><p>Wenn Sie jetzt eine neue Arbeit suchen, bekommen Sie sehr wahrscheinlich Hilfe von jemanden, den Sie treffen werden. Diese Person wird Ihnen helfen, genau diese Chance zu finden, die Sie brauchen um Sich zu beweisen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Königin der Stäbe zeigt an, dass Sie eine Art von geistigen Hunger empfinden. Sie sind auf der Suche nach neuem Wissen, neuem Lehren und neuen Erkenntnissen. Sie suchen nach den überall und an jedem Ort. Eine Menge von dem, was Sie finden ist interessant und anregend, aber seien Sie Sich bewusst, dass es auch einige falsche Propheten geben kann. Wenn Sie denken, dass Sie endlich die wahre Bedeutung des Lebens und das Leben gefunden haben, dann diskutiere mit Freunden und Verwandten darüber. Zergliedern und studieren Sie es gründlich, bevor Sie es zu einem neuen Ziel in Ihrem Leben machen.</p>",
                    }
                }
            },
            {
                filename: "35",
                numerology : 14,
                en : {
                    name: "King of wands", subtitle: "Actor",
                    suit : "Wands",
                    court : "King",
                    element : "🜂 - fire",
                    theme : "actively handle from your own will",    
                    description : "King of Wands speaks of effectively handle from your won will. Shape it.",
                    questions : [ "Are you aware of what you want?", "Are you acting according to it?", "Are you dominant or not?", "Can you stand up for yourself?"],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card depicts the male power of the element Fire, it stands for self confidence, power and greatness. Right now you may rightly feel that you have a lot of confidence in your own capabilities. Be careful not to flaunt this belief. The only thing that matters is that you yourself know what you are capable of, you shouldn't want to put yourself above others. Too great an ego makes you a less great person.</p><p>Right now the only thing that matters is that you have the energy and power to accomplish anything you set your mind to.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card symbolizes the warmthand connectedness you radiate towards the people you love, feelings they reciprocate. You feel close to them and you want to help and support them without asking for anything in return. This sentiment of really unconditionally wanting to give is answered in a cosmic way by you receiving the help you need. When you are giving with an honest and open heart it is always rewarded.</p><p>These are times in which you experience a wealth of positive energy and you tend to use it wisely.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are feeling confident, goal oriented and convinced of your own course. Possibly you meet someone who will act as a mentor for you. Watch and learn, this man has great wisdom for you to feast on. It is a good time to emphasize your organizational and people skills. Be dynamic, show guts and initiative. Still, be careful not to overdo it, not to overact. You are not performing in a theatre show, you’re at a workplace.</p><p>If you are looking for a job it is very likely that you will soon find the position you have been looking for since forever. If someone offers help, accept it graciously, it will surely come in handy.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Lately you have reached a lot of your spiritual goals and you are eager to reach some more, to go forward energetically and actively.</p><p>But now may be a better time to simply stand still, to experience, to listen to silence maybe. This is also part of the spiritual process. Spirituality is not all about reaching goals and working hard, it is also about turning inward and not acting for a while. Try this out and see where it gets you.</p>",
                    }
                },
                fr : {
                    name : "L'homme de Bâtons", subtitle: "Acteur",
                    suit : "Bâtons",
                    court : "Roi",
                    element :  "🜂 - feu",
                    theme : "Agir activement de sa propre volonté",    
                    description : "Le Roi des Bâtons, nous parle d’agir à partir de ce que l'on veut. Donner-forme à.",
                    questions : ["Êtes-vous conscient de ce que vous voulez?", "Agissez-vous en conséquence?", "Êtes-vous dominant ou non?", "Pouvez-vous vous affirmer?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente le pouvoir masculin de l'élément feu, elle représente la confiance en soi, le pouvoir et la grandeur. En ce moment, vous pouvez avec raison sentir que vous avez une grande confiance en vos propres capacités. Faites attention de ne pas trop montrer cette croyance. La seule chose qui compte, c'est que vous sachiez de quoi vous êtes capable. Vous ne voulez pas vous mettre au-dessus des autres. Un ego trop grand fait de vous une personne moins grande.</p><p>Pour l'instant, la seule chose qui compte, c'est que vous ayez l'énergie et le pouvoir de réaliser tout ce que vous voulez.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card symbolizes the warmthand connectedness you radiate towards the people you love, feelings they reciprocate. You feel close to them and you want to help and support them without asking for anything in return. This sentiment of really unconditionally wanting to give is answered in a cosmic way by you receiving the help you need. When you are giving with an honest and open heart it is always rewarded.</p><p>These are times in which you experience a wealth of positive energy and you tend to use it wisely.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are feeling confident, goal oriented and convinced of your own course. Possibly you meet someone who will act as a mentor for you. Watch and learn, this man has great wisdom for you to feast on. It is a good time to emphasize your organizational and people skills. Be dynamic, show guts and initiative. Still, be careful not to overdo it, not to overact. You are not performing in a theatre show, you’re at a workplace.</p><p>If you are looking for a job it is very likely that you will soon find the position you have been looking for since forever. If someone offers help, accept it graciously, it will surely come in handy.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Lately you have reached a lot of your spiritual goals and you are eager to reach some more, to go forward energetically and actively.</p><p>But now may be a better time to simply stand still, to experience, to listen to silence maybe. This is also part of the spiritual process. Spirituality is not all about reaching goals and working hard, it is also about turning inward and not acting for a while. Try this out and see where it gets you.</p>",
                    }
                },
                sp:{
                    name:"Hombre de bastos", subtitle: "Actor",
                    suit: "bastos",
                    court: "Rey",
                    element: "🜂 – fuego",
                    theme: "Actuar activamente desde tu propia voluntad",    
                    description: "El Rey de Bastas se trata de actuar desde lo que tú quieras. Dale forma",
                    questions: ["¿Eres consciente de lo que quieres?", "¿Estás actuando en consecuencia?", "¿Eres dominante o no?", "¿Puedes defenderte?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta carta representa el poder masculino del elemento fuego, que significa confianza en uno mismo, el poder y la grandeza. En este momento es posible que con razón siente que usted tiene un montón de confianza en sus propias capacidades. Tenga cuidado de no hacer alarde de esta creencia. La única cosa que importa es que su mismo sabe lo que es capaz de hacer, usted no debe querer ponerse por encima de los demás. Demasiado grande un ego que una persona hace menos grande. En este momento lo único que importa es que usted tiene la energía y el poder para lograr cualquier cosa que le propongas.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Man van Staven", subtitle: "Acteur",
                    suit : "Staven",
                    court : "Koning",
                    element : "🜂 - vuur",
                    theme : "actief handelen vanuit je eigen willen",    
                    description : "De Koning van Staven gaat over daadwerkelijk handelen vanuit dat wat jij wilt. Geef het vorm.",
                    questions : [ "Ben jij je bewust van wat je wilt?", "Handel jij daar ook naar?", "Ben je dominant of juist niet?", "Kun jij voor jezelf opkomen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart symboliseert de mannelijke kracht van het element Vuur. Dit staat voor zelfvertrouwen, kracht en grootsheid. Op dit moment voel je waarschijnlijk dat je veel vertrouwen hebt in je eigen waarde, mogelijkheden en kracht, en terecht. Wees toch voorzichtig en loop er niet teveel mee te koop. Het enige wat telt is dat jij zelf weet wat je waard bent, je hoeft anderen er niet van te overtuigen en hen zeker niet de indruk te geven dat je je mogelijk beter of sterker voelt dan zij. Immers, naarmate je ego groter wordt, word jij kleiner als mens.</p><p>Het enige wat telt is dat je op dit moment alle nodige kracht en energie hebt om alles te bereiken wat je maar wil.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt over de warmte en verbondenheid die jij uitstraalt naar de mensen waar je van houdt, en dat zij hetzelfde voor jou voelen. Je voelt je heel dicht bij hen en je wil hen helpen en ondersteunen zonder dat je daar iets voor in de plaats wil. Je voelt op dit moment dat je echt onvoorwaardelijk wil geven, er hoeft niets terug te komen. En net omdat je dit voelt, zal je op een kosmische manier datgene krijgen wat je nodig hebt. Als je geeft met een eerlijk en open hart dan wordt dit altijd beloond op een of andere manier, al is het niet altijd op de manier die jij het liefste zou willen.</p><p>Je ervaart op dit moment een rijkdom aan positieve energie en je gaat er verstandig mee om.</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk voel je je zelfzeker, doelgericht en overtuigd van je eigen koers. Het is best mogelijk dat je binnenkort iemand zal ontmoeten die je mentor wordt, waar je veel aan zal hebben. Kijk en leer, deze persoon heeft een schat aan wijsheid waar jij je voordeel mee kan doen.</p><p>Nu is ook een goede tijd om volop aan de slag te gaan met je organisatietalent en sociale vaardigheden. Wees dynamisch, toon lef en initiatief. Maar let er toch ook op dat je niet overdrijft, dat je niet te hevig overkomt. Je staat niet op het toneel, het blijft gewoon een werkplek, ook al barst jij van de energie.</p><p>Als je op dit moment op zoek bent naar werk dan zal je binnenkort waarschijnlijk een geschikte baan vinden. Als iemand aanbiedt om je daarbij te helpen, aanvaard die hulp dan zeker, je zal er veel baat bij hebben.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je hebt de laatste tijd een behoorlijk aantal spirituele doelen bereikt die je jezelf gesteld had. Je bent ook gretig om er nog meer te bereiken, je wil energiek en actief blijven vooruitgaan.</p><p>Maar op dit moment is het beter voor jou om gewoon even stil te staan en dat bewust te ervaren. Luister eens naar de stilte, focus je daarop. Ook zo’n ervaring maakt deel uit van het spirituele groeiproces. Spiritualiteit gaat niet enkel om hard werken en doelen bereiken, het gaat er ook om dat je jezelf naar je eigen kern toekeert en even niets doet. Probeer dit eens uit, doe niets en bekijk welke invloed het op je heeft. Waarschijnlijk zal je merken dat je je geheeld voelt.</p>",
                    }
                },
                de : {
                    name: "Man der Stäbe", subtitle: "Akteur",
                    suit : "Stäbe",
                    court : "König",
                    element : "🜂 - Feuer",
                    theme : "Aus freiem Willen aktiv handeln",    
                    description : "Der König der Stäbe spricht über das Handeln gemäss dem, was wir wollen. Geben Sie ihm Form.",
                    questions : [ "Sind Sie sich dessen bewusst, was Sie wollen?", "Handeln Sie auch dementsprechend?", "Sind Sie dominant oder nicht?", "Können Sie sich durchsetzen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte stellt die männliche Kraft des Elementes Feuer dar, sie vertritt das Selbstvertrauen, die Kraft und die Größe. Gerade jetzt können Sie zu Recht das Gefühl haben, dass Sie ein großes Vertrauen in Ihren eigenen Fähigkeiten haben. Achten Sie darauf, diesen Glauben nicht zu viel zur Schau zu stellen. Das Einzige, was zählt, ist, dass Sie selbst wissen, wozu Sie in der Lage sind. Sie wollen sich ja nicht über andere stellen. Ein zu großes Ego macht Sie zu einem weniger großartigen Menschen.</p><p>Im Augenblick ist das Einzige, was zählt, dass Sie die Energie und Kraft haben, alles zu erreichen, was Sie erreichen wollen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte symbolisiert die warmherzige Verbundenheit die Sie ausstrahlen gegenüber den Menschen, die Sie lieben, Gefühle, die sie auch beantworten. Sie fühlen sich in der Nähe von ihnen und helfen und unterstützen sie ohne etwas als Gegenleistung zu verlangen. Dieses Gefühl des wirklich bedingungslos Gebens wird auf einer kosmischen Art durch von Ihnen benötigten Hilfe beantwortet. Wenn Sie mit einem ehrlichen und offenen Herzen geben, wird das immer belohnt.</p><p>Dies sind Zeiten, in denen Sie eine Fülle an positive Energie erleben und Sie neigen dazu, sie weise zu nutzen.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit fühlen Sie Sich zuversichtlich, zielorientiert und von Ihren eigenen Kurs überzeugt. Möglicherweise begegnen Sie jemanden, der als Mentor für Sie auftreten wird. Beobachten Sie ihn und lernen Sie von ihm, dieser Mann hat große Weisheit für Sie und ist ein Fest. Es ist eine gute Zeit, um Ihre organisatorische und soziale Kompetenz zu betonen. Seien Sie dynamisch, zeigen Sie Mut und Initiative. Doch seien Sie vorsichtig, nicht zu übertreiben. Sie sind nicht auf der Bühne in einem Theatershow, Sie sind an einem Arbeitsplatz.</p><p>Wenn Sie auf der Suche sind nach einem Job ist es sehr wahrscheinlich, dass Sie bald die Position die Sie schon seit ewigen Zeiten suchen, finden. Wenn jemand Hilfe bietet, nehmen Sie diese dann gnädig an, es wird sicherlich als nützlich erweisen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In letzter Zeit haben Sie einen großen Teil Ihrer spirituellen Ziele erreicht und Sie hätten gerne etwas mehr erreichen wollen, um weiter energisch und aktiv voran zu gehen.</p><p>Aber jetzt könnte es eine bessere Zeit sein, einfach still zu stehen, zu erleben, um vielleicht Stille zu hören. Dies ist auch ein Teil des spirituellen Prozesses. Spiritualität ist nicht alles über erreichte Ziele und das harte arbeiten daran, es ist auch über das nach innen Kehren und für eine Weile nicht handeln. Probieren Sie das mal aus und sehen Sie mal, wie es Ihnen bekommt.</p>",
                    }
                }
            },
            {
                filename: "36",
                numerology : 1,
                en : {
                    name: "Ace of Cups", subtitle: "Ecstasy",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "I feel",    
                    description : "Ace of Cups, element water,  talks about your emotions in their totality. Everything is potentially present, but what do you feel?",
                    questions : [ "Are you open to new feelings?", "Is it your own feeling or is it 'imposed'?", "Can you show your emotions?", "What do you actually feel?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Ace of Cups is one of the most auspicious cards of the whole Tarot deck and drawing this card tells you that you are entering a very positive stage in your life. The card signifies that you have a lot of potential. If you become more conscious of this potential and start using it your life will change for the better.</p><p>The Ace of Cups will bring you joy, gratitude and a feeling of unity, togetherness. The positivity this card brings will be focused mostly on relationships and emotions. It often also signifies that you will experience a new start, new beginnings. You will also experience a lot of good will from the people around, it will be very easy to make new friends now.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card is a good omen where love is concerned. If you already are in a loving relationship, you will probably experience deep and unconditional love. You may even want to take the relationship a step further and truly commit by getting engaged or even getting married.</p><p>If you are looking for love, right now you have every chance of finding it. Falling in love will come easy and you will probably get the whole package: butterflies, moments of intense joy and harmony, passion, … Go for it and savor every moment!</p>",
                        [GuidanceFields.WORK] : "<p>When you draw the Ace of Cups things should be going pretty well for you at work. Your job gives you a deep sense of fulfillment and it is obvious that the things you do are appreciated by your coworkers and superiors. You may be getting a promotion or a raise, or maybe you will get new tasks you will enjoy even more. Your communication skills are excellent right now, put them to good use!</p><p>If you are looking for a job you are really confident and fully aware of your qualities. Your job interviews will go very well and you will have no problem at all convincing people of your values.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card is all about positivity, energy, feeling good. On a spiritual level it may be a good idea to share some of this positivity. Use it to get closer to everyone around you, to the universe as a whole. Share some of your happiness and wellbeing, some of your positive energy. Giving a lot also means receiving a lot right now. And doing so will help you to keep connected to the universal energy we all are a part of. Meditate on feeling loved, united with the cosmos, being basked in light. In doing so you will truly feel energetic and energized!</p>",
                    }
                },             
                fr : {
                    name : "l’As de Coupes", subtitle: "Ravissement",
                    suit : " Coupes",
                    element :  "🜄 - eau",
                    theme : "Je sens",    
                    description : "L'As de Coupes, assorti à l'élément eau, nous parle de nos émotions dans leur intégralité. Tout est potentiellement présent, mais que ressentez-vous?",
                    questions : ["Êtes-vous ouvert à un nouveau sentiment?", "Est-ce votre propre sentiment ou est-il \"forcé?\"", "Pouvez-vous laisser couler votre propre sentiment?", "Que ressentez-vous réellement?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>L'As de Coupe est l'une des cartes les plus prometteuses de tout le Tarot. Si vous tirez cette carte, elle vous indique que vous êtes dans une phase très positive de votre vie. La carte indique que vous avez un grand potentiel. Au fur et à mesure que vous prendrez conscience de ce potentiel et que vous commencerez à l'utiliser, votre vie changera toujours en mieux !</p><p>L'\"As de Coupe\" vous apporte joie, gratitude et un sentiment d'unité, de communion et de solidarité. Cette carte positive fait essentiellement référence aux relations et aux émotions. Cela signifie souvent que vous prendrez un nouveau départ. Vous éprouverez aussi beaucoup de bonne volonté de la part des gens qui vous entourent. Maintenant, il sera facile de vous faire de nouveaux amis ou connaissances....  Si vous essayez de tomber enceinte, cette carte est un signe très positif.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card is a good omen where love is concerned. If you already are in a loving relationship, you will probably experience deep and unconditional love. You may even want to take the relationship a step further and truly commit by getting engaged or even getting married.</p><p>If you are looking for love, right now you have every chance of finding it. Falling in love will come easy and you will probably get the whole package: butterflies, moments of intense joy and harmony, passion, … Go for it and savor every moment!</p>",
                        [GuidanceFields.WORK] : "<p>When you draw the Ace of Cups things should be going pretty well for you at work. Your job gives you a deep sense of fulfillment and it is obvious that the things you do are appreciated by your coworkers and superiors. You may be getting a promotion or a raise, or maybe you will get new tasks you will enjoy even more. Your communication skills are excellent right now, put them to good use!</p><p>If you are looking for a job you are really confident and fully aware of your qualities. Your job interviews will go very well and you will have no problem at all convincing people of your values.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card is all about positivity, energy, feeling good. On a spiritual level it may be a good idea to share some of this positivity. Use it to get closer to everyone around you, to the universe as a whole. Share some of your happiness and wellbeing, some of your positive energy. Giving a lot also means receiving a lot right now. And doing so will help you to keep connected to the universal energy we all are a part of. Meditate on feeling loved, united with the cosmos, being basked in light. In doing so you will truly feel energetic and energized!</p>",
                    }
                },       
                sp:{
                    name:"As de copas", subtitle: "Rapto",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "Siento",    
                    description: "El As de Copas, que coincide con el elemento agua, trata de tus emociones en su totalidad. Todo está potencialmente presente, pero ¿qué sientes?",
                    questions: ["¿Estás abierto a un nuevo sentimiento?", "¿Es tu propio sentimiento o es \"forzado\"?", "¿Puedes dejar  fluir tu propio sentimiento?", "¿Qué es lo que realmente sientes?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El As de Copas es una de las cartas más propicias de toda la baraja del Tarot y sacando esta tarjeta le dice que está entrando en una etapa muy positiva en su vida. La tarjeta significa que usted tiene un gran potencial. Si se vuelves más consciente de este potencial y empezar a usarlo su vida va a cambiar para el mejor. El As de Copas le traerá alegría, gratitud y un sentimiento de unidad. La positividad esta tarjeta trae se centrará sobre todo en las relaciones y emociones. A menudo también significa que usted experimentará un nuevo comienzo. También experimentará un montón de buena voluntad de la gente alrededor, va a ser muy fácil conseguir nuevos amigos ahora.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },      
                nl : {
                    name: "Beker Aas", subtitle: "Vervoering",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "ik voel",    
                    description : "De Aas van Bekers, passend bij het element water, gaat over je emoties in zijn totaliteit. Alles is in potentie aanwezig, maar wat voel jij?",
                    questions : [ "Sta jij open voor een nieuw gevoel?", "Is het je eigen gevoel of is het 'opgedrongen'?", "Kun jij je eigen gevoel laten stromen?", "Wat voel jij eigenlijk?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Aas van Bekers is een van de meest positieve kaarten van de hele Tarot. Als je deze kaart trekt dan is het een duidelijk teken dat je onderweg bent naar een heel positieve fase in je leven. Deze Aas vertelt je dat je veel potentieel hebt. Het is nu zaak om je daar steeds bewuster van te worden en dat potentieel ook ten volle te gaan gebruiken. Je zal merken dat dit een grote verbetering zal betekenen voor je levenskwaliteit!</p><p>De Aas van Bekers zal je vreugde brengen, dankbaarheid en een gevoel van eenheid, van samenzijn. De positiviteit die deze kaart inhoudt zal vooral voelbaar zijn op het gebied van relaties, gevoelens en emoties. Vaak zal je merken dat je een nieuwe start krijgt, een nieuw begin. En je zal ook veel goede wil en een positieve instelling ervaren van de mensen om je heen. Het wordt nu echt gemakkelijk om nieuwe mensen te leren kennen, nieuwe vrienden te maken.</p><p>Als je graag zwanger wil worden dan is deze kaart een heel positief teken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart is een positief voorteken op liefdesvlak. Als je een liefdesrelatie hebt dan ervaar je nu waarschijnlijk diepe en onvoorwaardelijke liefde. Het is ook best mogelijk dat je je relatie naar het volgende niveau wil brengen en dat je je echt gaat engageren door je te verloven, te gaan samenwonen, te trouwen of aan kinderen te beginnen. Nu is er geen ruimte voor twijfel en onzekerheid: de gevoelens van jou en je partner zijn oprecht en intens, handel ernaar.</p><p>Als je op zoek bent naar liefde dan heb je nu alle kansen om die ook te vinden. Je zal merken dat het je gemakkelijk valt om verliefd te worden en dat je het ook intens beleeft: vlinders in de buik, euforie, intense vreugde en harmonie, diepe passie ... ga ervoor en geniet van elk moment!</p>",
                        [GuidanceFields.WORK] : "<p>Als je nu de Aas van Bekers trekt dan zit het waarschijnlijk behoorlijk goed wat je werk betreft. Je haalt een intens gevoel van tevredenheid en vervulling uit je baan en het is duidelijk dat je collega’s en bazen je enorm waarderen voor wat je doet en wie je bent. Het is ook best mogelijk dat je binnenkort opslag krijgt of zelfs een promotie, of je krijgt een nieuw takenpakket dat je nog fijner zal vinden dan het huidige. Je communicatieve vaardigheden zijn op dit ogenblik ook heel sterk, maak er ten volle gebruik van!</p><p>Als je op zoek bent naar werk dan mag je nu vol vertrouwen zijn en je ten volle bewust zijn van je kwaliteiten. Je zal heel goed tot je recht komen tijdens interviews en je vindt het geen enkel probleem om mensen te overtuigen van je vaardigheden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart heeft het over een positieve instelling, energie en je goed voelen. Op spiritueel niveau is het een goed idee om al deze positiviteit te delen met anderen. Gebruik je energie om nader te komen tot de mensen om je heen en zelfs tot het universum als geheel. Deel je vreugde en je goede gevoel en deel zeker wat van dat positieve uit aan iedereen die op je weg komt. Op dit moment is het zo dat hoe meer je geeft, hoe meer je zal ontvangen, het is gelijk oversteken. En net door al dat geven zal je merken dat het alsmaar gemakkelijker wordt om verbonden te zijn en te blijven met de universele energie waar we allemaal deel van uitmaken. Mediteer op je gevoel van liefde, eenheid met de kosmos, baden in het energetische licht. Hierdoor zal je je helemaal stralend en opgeladen voelen!</p>",
                    }
                },              
                de : {
                    name: "Ass der Kelche", subtitle: "Entrückung",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Ich fühle",    
                    description : "Das Ass der Kelche, dem Wasserelement zugeordnet, spricht von unseren Emotionen in ihrer Gesamtheit. Alles ist potentiell vorhanden, aber was spüren Sie?",
                    questions : [ "Sind Sie offen für ein neues Gefühl?", "Ist es Ihr eigenes Gefühl oder ist es \"erzwungen\"?", "Können Sie Ihren eigenen Gefühl freien Lauf lassen?", "Was fühlen Sie wirklich?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Das „Ass der Kelche“ ist eine der vielversprechendsten Karten des gesamten Tarot. Falls Sie diese Karte ziehen, sagt sie Ihnen, dass Sie sich in einer sehr positiven Phase Ihres Leben befinden. Die Karte zeigt an, dass Sie über großes Potenzial verfügen. Wenn Sie sich dieses Potenzials stärker bewusst werden und beginnen, es zu nützen, wird Ihr Leben sich in jedem Fall zum Besseren zu verändern!</p><p>Das „Ass der Kelche“ bringt Ihnen Freude, Dankbarkeit und ein Gefühl der Einheit, der Zusammengehörigkeit. Diese positive Karte bezieht sich im Wesentlichen auf Beziehungen und Emotionen. Dies bedeutet oft, dass Sie einen neuen Anfang machen werden. Sie werden auch viel Wohlwollen von den Menschen in Ihrer Umgebung erfahren. Jetzt wird es Ihnen leichtfallen, neue Freunde oder Bekanntschaften zu machen. Wenn Sie versuchen, schwanger zu werden, ist diese Karte ein sehr positives Zeichen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte ist ein gutes Omen, wo es die Liebe anbelangt. Wenn Sie bereits in einer liebevollen Beziehung sind, werden Sie wahrscheinlich eine tiefe und bedingungslose Liebe erleben. Sie möchten vielleicht sogar die Beziehung einen Schritt weiter helfen und sogar würden Sie Sich verloben oder sogar heiraten.</p><p>Wenn Sie auf der Suche nach Liebe sind, haben Sie jetzt alle Chancen sie zu finden. Sich verlieben kommt von alleine und Sie werden wahrscheinlich das ganze Paket dazu bekommen: Schmetterlinge, Momente intensiver Freude und Harmonie, Leidenschaft … Gehen Sie dafür und genießen Sie jeden Augenblick!</p>",
                        [GuidanceFields.WORK] : "<p>Wenn Sie das Ass der Kelchen ziehen laufen die Dinge eigentlich ziemlich gut für Sie bei der Arbeit. Ihre Arbeit gibt Ihnen ein tiefes Gefühl der Erfüllung und es ist offensichtlich, dass Sie durch Ihre Kollegen und Vorgesetzten sehr geschätzt werden. Möglicherweise steht eine Beförderung oder eine Gehaltserhöhung bevor, oder vielleicht werden Sie neue Aufgaben bekommen, die Sie noch mehr genießen. Ihre kommunikativen Fähigkeiten sind jetzt sehr gut, benützen Sie sie für einen guten Zweck!</p><p>Wenn Sie auf der Suche nach einem Job sind Sie im Moment wirklich sicher und umfassend über Ihre Qualitäten. Ihre Vorstellungsgespräche werden sehr gut verlaufen und Sie werden keine Probleme begegnen andere zu überzeugen von Ihren Fähigkeiten.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Diese Karte handelt über Positivität, Energie und ein gutes Gefühl. Auf einer spirituellen Ebene kann es eine gute Idee sein, diese Positivität zu teilen. Verwenden Sie sie um näher an jedem um Sie herum zu geraten, an dem Universum an sich. Teilen Sie Ihr Glück und Wohlbefinden, Ihre positive Energie. Viel geben bedeutet in diesem Moment auch viel empfangen. Und dies wird Ihnen helfen, verbunden zu sein mit der universellen Energie von der wir alle ein Teil sind. Meditiere über das Gefühl geliebt zu werden, vereint mit dem Kosmos, im Licht gebadet. Dabei werden Sie wirklich das Gefühl haben energisch zu sein und voll Energie empfangen zu können!</p>",
                    }
                }
            },
            {
                filename: "37",
                numerology : 2,
                en : {
                    name: "Two of Cups", subtitle: "Equilibrium",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "contradictory feelings",    
                    description : "Two of Cups talks about a duality in your feelings and the benefits of sharing our feelings equally with others.",
                    questions : [ "Are you feeling doubts?", "Can you share your feelings without feeling yourself less or better than the other?", "Can you share your deepest feelings with some one?", "Can you feel how beneficial this is?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about balance, love, passion, sympathy and spontaneous connections. If you draw Cups Two it can mean that your love relationship is going very smoothly at the moment. It can also mean that there is a cheerful, positive atmosphere at work and that you have a good contact with your colleagues. Right now is definitely a good time to get to know new people. Just be yourself and you will notice that you are welcomed with open arms.</p><p> On the other hand, this card can sometimes indicate that you have conflicting feelings related to people and/or situations in your life, feelings that you cannot reconcile. These feelings cause problems and in order to solve them it is important that you listen to your deepest core, your intuition. Then you will know which side to choose.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that your relationship is built on solid foundations and you both are truly committed. You may feel as if you are the perfect couple. Still, this doesn’t mean you can do anything you want right now. Every relationship, even an almost perfect one, needs work. The foundations are solid but you have to keep building and maintaining the house every day.</p><p>If you are currently single, this card tells you that love truly is in the air right now. Go out, have fun, relax and enjoy the ride, you will soon meet a potential partner!</p>",
                        [GuidanceFields.WORK] : "<p>No matter if you really love your job or just plain accept it, right now the atmosphere at work is very good for the moment. Your colleagues and superiors like you and appreciate the things you do. If you are thinking about changing jobs, now is a good time to go out and look for new possibilities. And if you are currently unemployed, this card tells you it’s very likely you will soon find a job that suits you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You seem to be spiritually growing, your emotions are becoming deeper and more grounded. You intensely enjoy coming into contact with others, sharing with them your love for the universe and for everything and everyone that is a part of it. You feel open and positive towards all that is. Now is the time to share that love, that joy with others. In doing so you will make a lasting impression on everyone you meet.</p>",
                    }
                },
                fr : {
                    name : "Le Deux de Coupe", subtitle: "Stabilité",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "Sentiments contradictoires",    
                    description : " Le Deux de Coupe nous parle de la dualité de nos sentiments et des effets bénéfiques de partager nos sentiments, à égalité, avec d'autres.",
                    questions : ["Avez-vous, instinctivement, des doutes?", "Osez-vous partager vos sentiments, sans vous sentir plus ou moins que l'autre?", "Pouvez-vous partager vos sentiments les plus profonds avec quelqu'un?", "Pouvez-vous sentir combien cela vous est bénéfique ?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente l'équilibre, l'amour, la passion, la compassion et les relations spontanées. Cela peut indiquer que les choses se développent très bien dans votre relation amoureuse. Cela peut aussi signifier qu'il y a une atmosphère de travail positive et agréable et une bonne coopération avec vos collègues. C'est le moment idéal pour rencontrer de nouvelles personnes et établir des relations étroites et chaleureuses. Vous pouvez même trouver une âme sœur ! N'hésitez pas à vous ouvrir aux personnes que vous rencontrez. Vous serez chaleureusement embrassés.</p><p>D'un autre côté, cette carte peut dire que vous avez des sentiments ambivalents et contradictoires au sujet de certaines personnes ou situations de votre vie.  Pour résoudre ces contradictions, il est très important que vous écoutiez vos sentiments et votre intuition. Alors vous saurez où aller.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that your relationship is built on solid foundations and you both are truly committed. You may feel as if you are the perfect couple. Still, this doesn’t mean you can do anything you want right now. Every relationship, even an almost perfect one, needs work. The foundations are solid but you have to keep building and maintaining the house every day.</p><p>If you are currently single, this card tells you that love truly is in the air right now. Go out, have fun, relax and enjoy the ride, you will soon meet a potential partner!</p>",
                        [GuidanceFields.WORK] : "<p>No matter if you really love your job or just plain accept it, right now the atmosphere at work is very good for the moment. Your colleagues and superiors like you and appreciate the things you do. If you are thinking about changing jobs, now is a good time to go out and look for new possibilities. And if you are currently unemployed, this card tells you it’s very likely you will soon find a job that suits you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You seem to be spiritually growing, your emotions are becoming deeper and more grounded. You intensely enjoy coming into contact with others, sharing with them your love for the universe and for everything and everyone that is a part of it. You feel open and positive towards all that is. Now is the time to share that love, that joy with others. In doing so you will make a lasting impression on everyone you meet.</p>",
                    }
                },
                sp:{
                    name:"El Dos de copas", subtitle: "Estabilidad",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "Sentimientos conflictivos",    
                    description: "El Dos de Copas trata sobre la dualidad en tus sentimientos y nos señala el efecto beneficioso cuando compartimos nuestros sentimientos, en igualdad de condiciones, con otra persona",
                    questions: ["¿Tienes tus dudas instintivamente?", "¿Te atreves a compartir tus sentimientos, sin sentirte más o menos que el otro", "¿Puedes compartir tus sentimientos más profundos con alguien? ], ¿Puedes sentir qué beneficioso es eso?"],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es sobre el amor, la pasión, la simpatía y conexiones espontáneas. Esto puede indicar que las cosas van muy bien en su relación romántica, o puede haber una atmósfera positiva, feliz en el trabajo y una buena relación con sus compañeros. Ahora es el momento perfecto para conocer gente nueva y construir relaciones fuertes y cálidas. Usted puede incluso descubrir un alma gemela! No dude en abrir a personas que conozca, para realmente ser su mismo. Le daremos la bienvenida con los brazos abiertos. A veces, esta carta indica que usted está teniendo contradictorio, conflictivo sentimientos sobre personas o situaciones en su vida. Para resolver estos problemas y averiguar qué hacer con ellos es importante que usted escuches su sentimiento más íntimo, su intuición. Entonces usted sabrá qué hacer.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Bekers Twee", subtitle: "Stabiliteit",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "tegenstrijdige gevoelens",    
                    description : "De Twee van Bekers gaat over dualiteit in je gevoel en wijst ons op de heilzame werking wanneer we onze gevoelens, in gelijkwaardigheid, delen met een ander.",
                    questions : [ "Heb je, gevoelsmatig, je twijfels?", "Durf jij je gevoel te delen, zonder je meer of minder dan die ander te voelen?", "Kun jij met iemand je diepste gevoelens delen?", "Kun je voelen hoe heilzaam dat is?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart heeft als onderwerp evenwicht, liefde, passie, sympathie en spontane connecties. Als je Bekers Twee trekt kan het betekenen dat je liefdesrelatie heel soepel verloopt op dit moment. Het kan ook betekenen dat er op je werk een vrolijke, positieve sfeer hangt en dat je een goed contact hebt met je collega’s. Op dit moment is het absoluut een goede tijd om nieuwe mensen te leren kennen. Wees gewoon jezelf en je zal merken dat je met open armen verwelkomd wordt.</p><p>Aan de andere kant kan deze kaart er soms ook op wijzen dat je tegenstrijdige gevoelens hebt in verband met mensen en/of situaties in je leven, gevoelens die je niet met mekaar kan verzoenen. Deze gevoelens zorgen voor problemen en om die op te lossen is het belangrijk dat je luistert naar je diepste kern, naar je intuïtie. Dan zal je weten welke kant je moet kiezen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment lijkt het erop dat je (liefdes)relatie gebouwd is op stevige fundamenten en dat jullie allebei totaal betrokken en geëngageerd zijn. Het voelt alsof jullie het perfecte koppel zijn. Toch betekent dit niet dat je zomaar alles kan doen waar je zin in hebt. Elke relatie, zelfs een bijna perfecte, vraagt werk en onderhoud. Ook al is jullie basis heel stevig, je moet toch elke dag aan de slag gaan om verder te bouwen en alles te onderhouden.</p><p>Als je op dit moment alleen bent dan vertelt deze kaart je dat er echt wel liefde in de lucht hangt. Ga op stap, doe leuke dingen, ontspan je en geniet en je zal merken dat er al gauw een potentiële geliefde op je weg komt!</p>",
                        [GuidanceFields.WORK] : "<p>Misschien houd je heel veel van je baan en ben je er totaal aan toegewijd. Of misschien aanvaard je het werken gewoon als iets dat je nu eenmaal moet doen om je leven te bekostigen. Hoe je ook in je job staat, op dit moment is de sfeer op het werk heel fijn. Je kan goed opschieten met je collega’s en je meerderen en je merkt dat ze alles wat je doet ook echt waarderen. Je voelt je positief en ontspannen.</p><p>Als je aan het overwegen bent om van baan te veranderen, dan is het er nu het juiste moment voor. Trek erop uit en ga op zoek naar andere mogelijkheden, je zal niet teleurgesteld worden!</p><p>Als je nu werkloos bent en op zoek naar een baan, dan vertelt deze kaart je dat je binnenkort succesvol zal zijn in je zoektocht. Houd het nog even vol, je zal binnenkort een aantal mooie kansen krijgen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment ben je spiritueel volop aan het groeien. Je merkt dat je emoties en inzichten diepgaander aan het worden zijn en je voelt je algemeen meer gegrond. Je geniet er nu ook intens van om in contact te komen met anderen, om met hen jouw liefde voor het universum (en voor alles en iedereen die er deel van uitmaakt) te delen. Je voelt je open en positief tegenover alles wat bestaat. Nu is het perfecte tijdstip om deze liefde en vreugde met anderen te delen. Door dit te doen zal je niet enkel een diepe en blijvende indruk maken op iedereen die je ontmoet, je zal ook je spirituele kern voelen groeien.</p>",
                    }
                },
                de : {
                    name: "Zwei der Kelche", subtitle: "Stabilität",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Widersprüchliche Empfindungen",    
                    description : "Die Zwei der Kelche spricht von der Dualität unserer Gefühle und den positiven Auswirkungen, wenn wir unsere Gefühle auf Augenhöhe mit anderen teilen.",
                    questions : [ "Haben Sie instinktiv Zweifel?", "Trauen Sie sich, Ihre Gefühle zu teilen, ohne sich besser oder geringer zu fühlen als die anderen?", "Können Sie Ihre tiefsten Gefühle mit jemandem teilen?", "Können Sie spüren, wie gut Ihnen das tut?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für Gleichgewicht, Liebe, Leidenschaft, Mitgefühl und spontane Verbindungen. Sie kann darauf hinweisen, dass sich die Dinge in Ihrer romantischen Beziehung sehr reibungslos entwickeln. Sie kann auch bedeuten, dass es eine positive, gemütliche Atmosphäre in der Arbeit und eine gute Zusammenarbeit mit Ihren Kollegen gibt. Jetzt ist die perfekte Zeit, um neue Leute kennenzulernen und enge, warme Beziehungen aufzubauen. Sie können sogar einen Seelenverwandten finden! Zögern Sie nicht, sich Menschen, denen Sie begegnen, zu öffnen. Man wird Sie warmherzig umarmen.</p><p>Auf der anderen Seite kann diese Karte sagen, dass Sie im Hinblick auf bestimmte Menschen oder Situationen in Ihrem Leben zwiespältige, widersprüchliche Gefühle haben. Damit diese Widersprüche aufgelöst werden können ist es sehr wichtig, dass Sie auf Ihre Gefühle und auf Ihre Intuition hören. Dann werden Sie wissen, welchen Weg Sie gehen müssen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte sagt Ihnen, dass Ihre Beziehung auf einem soliden Fundament gebaut ist und Sie beide wirklich einander gegenüber engagiert sind. Sie können Sich als das perfekte Paar fühlen. Dennoch bedeutet dies nicht, Sie können alles machen, was Sie wollen. Jede Beziehung, auch eine nahezu perfekte, braucht Pflege. Die Grundlagen sind solide, aber Sie müssen jeden Tag weiter bauen, um das Haus zu perfektionieren und zu pflegen. Falls Sie im Moment alleine sind sagt diese Karte, dass die Liebe jetzt in der Luft ist. Gehen Sie aus, machen Sie Spaß, entspannen Sie Sich und genießen Sie die Fahrt. Sie werden bald einen potenziellen Partner treffen!</p>",
                        [GuidanceFields.WORK] : "<p>Egal, ob Sie Ihren Job wirklich lieben oder einfach im Moment nur akzeptieren, gerade jetzt ist die Atmosphäre in der Arbeit sehr gut. Ihre Kollegen und Vorgesetzten schätzen Sie und die Dinge, die Sie tun. Wenn Sie über einen Wechsel des Arbeitsplatzes denken, ist es jetzt eine gute Zeit aus zu gehen und sich umzusehen nach neuen Möglichkeiten. Und wenn Sie jetzt arbeitslos sind, sagt diese Karte Ihnen, dass es sehr wahrscheinlich ist, dass Sie in Kurzem genau den Job finden werden, der zu Ihnen passt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie scheinen spirituell zu wachsen, Ihre Gefühle werden immer tiefer und gegründet. Sie genießen intensiv die Kontakte mit anderen, um mit ihnen Ihre Liebe für das Universum zu teilen und Ihre Liebe für alles und jeden der einen Teil von ihr ist. Sie fühlen sich offen und positiv gegenüber allem, was da ist. Jetzt ist die Zeit diese Liebe und diese Freude mit anderen zu teilen. Dabei werden Sie einen bleibenden Eindruck auf jedem von ihnen machen.</p>",
                    }
                }
            },
            {
                filename: "38",
                numerology : 3,
                en : {
                    name: "Three of Cups", subtitle: "Love",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "making room for your feelings",    
                    description : "Three of Cups talks about making room for your feelings and sharing them.",
                    questions : [ "Can you share your feelings?", "What could you celebrate joyfully?", "Which feelings would you like to share with others?", "Whom will you share those feelings with?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This is a festive card, it is about celebration. It is often a reunion that is being celebrated but it can also be a wedding or a birth or any other happy occasion.</p><p>It is also possible that you are being or will be reunited with someone of something you have been missing for some time. Or maybe you have accomplished something that makes you feel fulfilled and content.</p><p>In general, when you draw this card, you will be feeling careless and grateful and your life will be harmonious and full of joy.</p><p>Sometimes this card indicates that financial or material wealth is coming your way.</p><p>Anyway, this is a time to relax and enjoy and to share your wellbeing with the ones you love!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This is a time when you feel like helping people and they feel the same towards you. You want to be socially active, enjoy the company of others, generally be open to people and what they have to offer. You are happy and fulfilled in your relationship and you want to share this with everyone around you. Why not throw a party?</p><p>If you are single, it is very likely that you will soon meet someone who really is what you were looking for. But if you catch up with an “old” love, this will probably not be the one. Even if this cards often hints at reunions, the right person for you to have a relationship with is likely not to be found in your past.</p>",
                        [GuidanceFields.WORK] : "<p>When you draw this card you should also be rather positive about work right now. Maybe you are in line for a promotion or a raise. Or maybe your superiors are very outspoken about your qualities and achievements. If you want to change jobs, now is the perfect time.</p><p>If you are currently looking for a position, don’t give up, keep looking. Some pretty good opportunities should be coming your way right about now. If someone from your past contacts you regarding work, this may be the perfect opportunity.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Maybe you are the kind of person who enjoys spirituality on your own. You may like to meditate, visualize, ponder all by yourself. Still, it may be a good idea to go out and mingle, to find people who can inspire you, who can help you see totally new spiritual paths and challenges. If you do so, you may have a new and meaningful experience that completely changes your spiritual awareness.</p><p>These new “teachers” don’t have to be gurus or specialists, for all you know it can be your neighbor who helps you “see the light”.</p>",
                    }
                },
                fr : {
                    name : "Le Trois de Coupe", subtitle: "Amour",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "Laisser l’espace à vos sentiments",    
                    description : "Le Trois de Coupes, nous parle de donner l'espace à ce que nous ressentons et nous autorise à exprimer nos sentiments.",
                    questions : ["Pouvez-vous exprimer vos sentiments?", "Que pourriez-vous fêter joyeusement?", "Quel sentiment aimeriez-vous partager avec les autres?", "Avec qui allez-vous faire cela?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Il s'agit d'une carte solennelle qui symbolise les célébrations. Souvent, c'est une retrouvaille qui est célébrée, mais cela peut aussi être un mariage, un anniversaire ou tout autre événement joyeux.</p><p>Ce sera peut-être une réunion avec quelqu'un ou quelque chose qui vous manquait depuis un certain temps. Ou peut-être qu'il y a une réalisation qui vous comble de chaleur et de satisfaction.... Si vous tirez cette carte, vous vous sentirez généralement calme, insouciant et reconnaissant. Votre vie sera harmonieuse et pleine de joie.</p><p>Parfois, cette carte indique que vous pouvez vous attendre à une richesse financière ou matérielle. Quoi qu'il en soit, c'est le moment de vous détendre, de profiter et de partager votre bien-être avec vos proches !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This is a time when you feel like helping people and they feel the same towards you. You want to be socially active, enjoy the company of others, generally be open to people and what they have to offer. You are happy and fulfilled in your relationship and you want to share this with everyone around you. Why not throw a party?</p><p>If you are single, it is very likely that you will soon meet someone who really is what you were looking for. But if you catch up with an “old” love, this will probably not be the one. Even if this cards often hints at reunions, the right person for you to have a relationship with is likely not to be found in your past.</p>",
                        [GuidanceFields.WORK] : "<p>When you draw this card you should also be rather positive about work right now. Maybe you are in line for a promotion or a raise. Or maybe your superiors are very outspoken about your qualities and achievements. If you want to change jobs, now is the perfect time.</p><p>If you are currently looking for a position, don’t give up, keep looking. Some pretty good opportunities should be coming your way right about now. If someone from your past contacts you regarding work, this may be the perfect opportunity.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Maybe you are the kind of person who enjoys spirituality on your own. You may like to meditate, visualize, ponder all by yourself. Still, it may be a good idea to go out and mingle, to find people who can inspire you, who can help you see totally new spiritual paths and challenges. If you do so, you may have a new and meaningful experience that completely changes your spiritual awareness.</p><p>These new “teachers” don’t have to be gurus or specialists, for all you know it can be your neighbor who helps you “see the light”.</p>",
                    }
                },
                sp:{
                    name:"El Tres de copas", subtitle: "Amor",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "Da espacio a lo que sientes",    
                    description: "El Tres de Copas trata de dar espacio a lo que sientes y señalarnos que se nos permita expresar nuestros sentimientos",
                    questions: ["¿Puedes expresar tus sentimientos?", "¿Qué podrías celebrar con alegría?", "¿Qué sentimiento te gustaría compartir con otros?", "¿Con quién vas a hacer eso?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta es una tarjeta de festivo, se trata de la celebración. A menudo es una reunión que se celebra, pero también puede ser una boda o un nacimiento o cualquier otra ocasión feliz. También es posible que usted estés siendo o va a reunirse con alguien de algo que se ha estado perdiendo desde hace algún tiempo. O tal vez usted ha logrado algo que le hace sentir satisfecho y contento. En general, cuando se saca esta tarjeta, usted se sentirá descuidado y agradecidos y su vida será armoniosa y llena de alegría. A veces, esta tarjeta indica que la riqueza financiera o material está llegando a su manera. De todos modos, este es un tiempo para relajarse y disfrutar y compartir su bienestar con sus seres queridos!</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                },
                nl : {
                    name: "Bekers Drie", subtitle: "Liefde",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "geef dat wat jij voelt de ruimte",    
                    description : "De Drie van Bekers gaat over ruimte geven aan wat je voelt en wijst ons op het mogen uiten van onze gevoelens.",
                    questions : [ "Kun jij je gevoelens uiten?", "Wat zou jij vreugdevol kunnen vieren?", "Welk gevoel zou je met anderen willen delen?", "Met wie ga jij dat doen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Dit is een feestelijke kaart die vaak betrekking heeft op vieringen. Het kan gaan om een weerzien of hereniging die gevierd wordt, maar het kan ook een huwelijk, verjaardag, geboorte of gelijk welke andere heuglijke gebeurtenis zijn.</p><p>Het is ook mogelijk dat er opnieuw contact komt met iemand die je al lang hebt moeten missen. Of misschien heb je een taak of opdracht afgewerkt, heb je iets volbracht waar je trots op bent, iets dat je vervult met tevredenheid. Als je deze kaart trekt dan is dat over het algemeen een teken dat je je onbekommerd en dankbaar zal voelen en dat je leven harmonieus en vol vreugde zal zijn. Soms betekent deze kaart ook dat er op financieel en materieel gebied een merkbare verbetering in de maak is.</p><p>In elk geval duidt deze kaart erop dat het nu tijd is om te ontspannen en te genieten en om je goede gevoel ook te delen met de mensen om je heen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment heb je de behoefte om anderen te helpen en te ondersteunen. Dit gevoel is wederzijds want je zal merken dat anderen jou ook hulp en warmte willen bieden. Je hebt behoefte aan sociale activiteit, je wil genieten van het gezelschap van je geliefden en vrienden. Je staat op dit ogenblik in je leven echt open voor anderen, je hebt oog voor wat ze je te bieden hebben op menselijk vlak. Je voelt je gelukkig en bevredigd in je relatie en dit goede gevoel wil je met iedereen delen. Een ideaal moment om een feestje te bouwen!</p><p>Als je alleen bent is het heel waarschijnlijk dat je nu tegen iemand op loopt die helemaal is waar je naar op zoek bent. Maar let op: als je een ‘oude’ liefde opnieuw ontmoet, dan is het echt onwaarschijnlijk dat dit de goede partner voor je zal zijn, let op voor het herhalen van vergissingen, leg de nadruk eerder op ‘nieuwe’ ontmoetingen. Want ook al duidt deze kaart vaak op herenigingen, de juiste partner voor jou is niet in je verleden te vinden.</p>",
                        [GuidanceFields.WORK] : "<p>Deze kaart vertelt je dat je gevoel in verband met je werk en werkomgeving op dit moment grotendeels positief is. Het is ook best mogelijk dat je goed nieuws krijgt in verband met je job, een positieve evaluatie, een aantal complimenten van je meerderen. En dat kan dan weer leiden tot een promotie of opslag. Als je overweegt om van baan te veranderen dan is het nu het juiste moment, je straalt immers zelfvertrouwen uit!</p><p>Als je nu werkloos bent dan is het zaak om te blijven zoeken, geef het niet op. Er komen binnenkort een aantal mooie kansen je kant op. Schenk ook voldoende aandacht aan mensen uit het verleden die op je pad komen want zij zouden de sleutel kunnen zijn naar de perfecte baan voor jou.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het is best mogelijk dat jij iemand bent die vooral van spirituele ervaringen geniet in je eentje. Misschien mediteer je graag alleen, werk je met visualisaties en affirmaties,gewoon alleen met jezelf, daar voel je je goed bij.</p><p>Toch is het op dit moment in je leven verstandig om eens op zoek te gaan naar het gezelschap van anderen, mensen die je kunnen inspireren, die je op weg kunnen zetten naar totaal andere spirituele paden en uitdagingen. Het zijn net die anderen die je vormen van spiritueel beleven kunnen aanreiken waar je zelf nooit zou zijn opgekomen. En net door die ongewone ervaringen kan er voor jou een totaal nieuwe spirituele wereld opengaan. De mensen die je hiervoor aanspreekt hoeven geen goeroes of leermeesters te zijn, in tegendeel. Een gesprekje met je buurman of het bezoeken van een nieuwe website kan al een totaal andere zienswijze bij jou teweegbrengen. Stel jezelf open voor anderen en laat je verrassen!</p>",
                    }
                },
                de : {
                    name: "Drei der Kelche", subtitle: "Liebe",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Lassen Sie Platz für Ihre Gefühle",    
                    description : "Die Drei der Kelche spricht darüber, dem Raum zu geben, was wir empfinden und uns zu erlauben, unsere Gefühle auszudrücken.",
                    questions : [ "Können Sie Ihre Gefühle ausdrücken?", "Was könnten Sie mit Freude feiern?", "Welches Gefühl möchten Sie mit anderen teilen?", "Mit wem werden Sie das tun?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Das ist eine feierliche Karte und steht für Feiern. Oft ist es ein Wiedersehen, das gefeiert wird, aber es kann auch eine Hochzeit, ein Geburtstag oder ein anderes freudiges Ereignis sein.</p><p>Vielleicht wird es ein Wiedersehen sein mit jemandem oder mit etwas, dass Sie seit einiger Zeit vermissen. Oder vielleicht gibt es etwas, dass Sie erreicht haben, das Sie mit Wärme und Zufriedenheit erfüllt. Wenn Sie diese Karte ziehen werden Sie sich in der Regel ruhig, sorglos und dankbar fühlen. Ihr Leben wird harmonisch und voller Freude sein.</p><p>Manchmal zeigt diese Karte an, dass Sie finanziellen oder materiellen Reichtum zu erwarten haben. Wie auch immer, dies ist eine Zeit zum Entspannen und Genießen, und Ihr Wohlbefinden mit Ihren Geliebten zu teilen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Dies ist eine Zeit wo Sie das Gefühl haben Menschen helfen zu können und Sie empfinden das gleiche Gefühl Ihnen gegenüber. Sie möchten sozial aktiv sein, die Gesellschaft von anderen genießen und Sie sind in der Regel offen für Menschen und das was sie zu bieten haben. Sie sind glücklich und in Ihrer Beziehung erfüllt und Sie möchten das gerne mit jedem um Sie teilen. Warum nicht mal eine Feier machen?</p><p>Wenn Sie alleine sind, ist es sehr wahrscheinlich, dass Sie bald jemanden begegnen, der wirklich das ist, was Sie gesucht haben, um Ihr Leben zu erfüllen. Aber eine Versöhnung mit einer \"alten\" Liebe wird wahrscheinlich nicht das bringen, was Sie suchen. Auch, wenn diese Karten deutet auf ein Zusammentreffen mit der richtigen Person für Sie in einer Beziehung werden Sie die wahrscheinlich nicht in Ihrer Vergangenheit finden.</p>",
                        [GuidanceFields.WORK] : "<p>Wenn Sie diese Karte ziehen, fühlen Sie Sich gerade eher positiv über Ihre Arbeit. Vielleicht sind Sie in der Reihe für eine Beförderung oder eine Gehaltserhöhung. Oder vielleicht sind Ihre Vorgesetzten sehr positiv über Ihre Qualitäten und Leistungen. Wenn Sie die Absicht haben den Arbeitsplatz zu wechseln, ist jetzt der perfekte Zeitpunkt. Wenn Sie zur Zeit auf der Suche nach einer anderen Position sind, geben Sie nicht auf, suchen Sie weiter. Einige ziemlich gute Chancen sollten gerade jetzt auf Ihrem Weg kommen. Wenn jemand aus der Vergangenheit sich mit Ihnen in Verbindung setzt in Bezug auf Arbeit, kann dies die perfekte Gelegenheit sein.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Vielleicht sind Sie die Art von Person, die Spiritualität auf eigene Faust genießt. Sie mögen gerne alleine meditieren, visualisieren, erwägen. Dennoch kann es eine gute Idee sein aus zu gehen, um Sich zu mischen mit anderen Leuten, die Sie begeistern können, die Ihnen helfen können, völlig neue spirituelle Wege zu finden und neue Herausforderungen zu sehen. Wenn Sie dies tun, könnten Sie eine neue und sinnvolle Erfahrung haben, die Ihr spirituelles Bewusstsein völlig verändert.</p><p>Diese neuen \"Lehrer\" müssen nicht unbedingt Gurus oder Spezialisten sein. Es kann auch einfach der Nachbar sein, der Ihnen helft das “Licht zu sehen”.</p>",
                    }
                }
            },
            {
                filename: "39",
                numerology : 4,
                en : {
                    name: "Four of Cups", subtitle: "Anger",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "having a handle on your feelings",    
                    description : "Four of Cups talks about our handle on feelings and an unsettling  feeling which keeps us stuck.",
                    questions : [ "Are you stuck within your feelings?", "Are you feeling unhappy?", "What could you possibly do about it?", "Stay alert to what else may be problematic." ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Four of Cups is about mixed feelings. On one hand you are not satisfied with certain parts of your life, the grass seems greener on the other side of the fence. On the other hand, you have an abundance of things to be thankful for, in some ways your cup is overflowing, but still this doesn’t make you happy. It seems as if you are determined to focus on the negatives instead of looking at the positives, you are filling yourself to the brim with dissatisfaction. Actually the solution might be to simply look at what is instead of concentrating on what could be.</p><p>Right now it may be wise to get a grip and to count your blessings and cherish them, enjoy the positive energy this will bring you.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Generally, when you draw this card, it indicates that your situation is less than optimal. Maybe your relationship is boring you, maybe you feel empty, hollow, devoid of feelings. Possibly you can’t even remember why you ever chose this partner in the first place. It’s as if you’re thinking: this cannot be my life, I’m sure I ordered a better one! You seem to be dissatisfied and you tend to linger on this dissatisfaction.</p><p>Maybe you could think about the things that are going right for a while, it will certainly give you a lot more energy than what you’re doing right now.</p><p>If you are single, make sure you don’t put all your energy towards conquering someone who is actually beyond your reach. Instead, make sure your goals are realistic, don’t set yourself up for failure.</p>",
                        [GuidanceFields.WORK] : "<p>Nowadays, at work, it is very hard for you to stay motivated. It all seems so same old same old. No challenges, nothing new or exciting, no new people to liven it all up a bit. You feel as if you’ve been there forever, doing the same boring things day after day. So maybe you don’t have a dream job, maybe your career is in a bit of a rut. But on the upside: you still have a job! Try to be at least a bit happy about that. There are so many people nowadays who don’t even have a ‘boring’ job to go to. Shake off that ‘poor me’ attitude and go look for things you really want to do!</p><p>If you are looking for a job, it might be a good idea to ask yourself if you actually want to find one. If this isn’t the case, maybe you should first focus on what kind of work you would really love to do.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The way you think and feel has a big impact on your spirituality. Right now, you seem to be lost in emotions that are bordering on self-pity and discontentment. Your mindset is mainly passive and negative. You keep thinking along the lines of ‘if only X were different, I would feel better’. If you keep this up, nothing will change and you will feel more and more miserable. Forget the past and the future and focus on the present. Sit down and think about the things you actually like about your life, even if there’s only a few. What makes you happy? What fulfills you? Who do you like? Don’t discard anything, if it makes you happy to see your dog when you come home, then that’s a positive. Focusing on these things can change your feelings really quickly and will help get you out of this rut you are in.</p>",
                    }
                },
                fr : {
                    name : "Le Quatre de Coupe", subtitle: "Colère",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "Avoir la notion de ce que vous ressentez",    
                    description : " Le Quatre de Coupe, nous parle de la notion de nos sentiments et d’être coincé dans un sentiment d'instabilité.",
                    questions : ["Êtes-vous coincé dans vos sentiments?", "Avez-vous un sentiment insatisfaisant?", "Que pourriez-vous faire à ce sujet?", "Restez-vous attentif à ce qu'il pourrait y avoir d'autre?"],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Les \"Quatre de Coupe\" représentent des sentiments mitigés. D'une part, vous n'êtes pas satisfait de certaines parties de votre vie. L'herbe semble plus verte de l'autre côté de la clôture. D'un autre côté, vous savez que vous pouvez être reconnaissant pour beaucoup de choses. D'une certaine façon, votre tasse est plus que pleine, mais cela ne vous rend toujours pas heureux. Il semble que vous soyez déterminé à vous concentrer sur le négatif plutôt que sur le positif. Vous êtes rempli à ras bord d'insatisfaction. En fait, la solution pourrait être de vous concentrer sur ce que vous avez vraiment et non sur ce qui pourrait être.</p><p>En ce moment, il peut être utile de se ressaisir, de compter et d'apprécier ses bienfaits. Profitez de l'énergie positive que cela vous apportera.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Generally, when you draw this card, it indicates that your situation is less than optimal. Maybe your relationship is boring you, maybe you feel empty, hollow, devoid of feelings. Possibly you can’t even remember why you ever chose this partner in the first place. It’s as if you’re thinking: this cannot be my life, I’m sure I ordered a better one! You seem to be dissatisfied and you tend to linger on this dissatisfaction.</p><p>Maybe you could think about the things that are going right for a while, it will certainly give you a lot more energy than what you’re doing right now.</p><p>If you are single, make sure you don’t put all your energy towards conquering someone who is actually beyond your reach. Instead, make sure your goals are realistic, don’t set yourself up for failure.</p>",
                        [GuidanceFields.WORK] : "<p>Nowadays, at work, it is very hard for you to stay motivated. It all seems so same old same old. No challenges, nothing new or exciting, no new people to liven it all up a bit. You feel as if you’ve been there forever, doing the same boring things day after day. So maybe you don’t have a dream job, maybe your career is in a bit of a rut. But on the upside: you still have a job! Try to be at least a bit happy about that. There are so many people nowadays who don’t even have a ‘boring’ job to go to. Shake off that ‘poor me’ attitude and go look for things you really want to do!</p><p>If you are looking for a job, it might be a good idea to ask yourself if you actually want to find one. If this isn’t the case, maybe you should first focus on what kind of work you would really love to do.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The way you think and feel has a big impact on your spirituality. Right now, you seem to be lost in emotions that are bordering on self-pity and discontentment. Your mindset is mainly passive and negative. You keep thinking along the lines of ‘if only X were different, I would feel better’. If you keep this up, nothing will change and you will feel more and more miserable. Forget the past and the future and focus on the present. Sit down and think about the things you actually like about your life, even if there’s only a few. What makes you happy? What fulfills you? Who do you like? Don’t discard anything, if it makes you happy to see your dog when you come home, then that’s a positive. Focusing on these things can change your feelings really quickly and will help get you out of this rut you are in.</p>",
                    }
                },
                sp:{
                    name:"El Cuatro de copas", subtitle: "Ira",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "Agarra  lo que sientes",    
                    description: "El Cuatro de Copas trata de controlar tus sentimientos y nos indica que podemos estar atrapados en un sentimiento de inestabilidad",
                    questions: ["¿Estás atascado en tus sentimientos?", "¿Tienes un sentimiento insatisfactorio", "¿Qué podrías hacer al respecto?  Mantente alerta a lo que podría ser" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Cuatro de Copas se trata de una mezcla de sentimientos. Por un lado no está satisfecho con ciertas partes de su vida, la hierba parece más verde al otro lado de la valla. Por otra parte, usted tiene una gran cantidad de cosas que agradecer, en cierto modo su copa rebosa, pero aún esto no le hace feliz. Parece como si usted está decidido a centrarse en los aspectos negativos en lugar de buscar el lado positivo, usted está llenando a su mismo hasta el borde de la insatisfacción. En realidad, la solución podría ser simplemente mirar lo que está en lugar de concentrarse en lo que podría ser. En este momento puede ser sabio para conseguir un agarre y contar tus bendiciones y asistirla, disfrutar de la energía positiva que esto traerá.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Bekers Vier", subtitle: "Woede",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "grip op wat je voelt",    
                    description : "De Vier van Bekers gaat over grip op je gevoel en wijst ons op een wellicht onbestendig gevoel, waarin we mogelijk vastzitten.",
                    questions : [ "Zit jij vast in je gevoel?", "Heb jij een onbevredigend gevoel?", "Wat zou jij er aan kunnen doen?", "Blijf alert op wat er mogelijk nog meer kan zijn." ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Vier van Bekers heeft het over gemengde gevoelens. Aan de ene kant ben je ontevreden over bepaalde aspecten van je leven, het lijkt alsof het gras elke keer weer groener is aan de overkant. En aan de andere kant weet je eigenlijk best dat je een heleboel dingen hebt om dankbaar voor te zijn. Het lijkt alsof je glas halfvol is maar jij ervaart het als halfleeg. Je hebt op dit moment de neiging om je te focussen op het negatieve in plaats van het positieve. Je laat jezelf overspoelen met ontevredenheid en ongenoegen. Eigenlijk is het best mogelijk dat er een simpele oplossing is voor dit probleem: kijk naar wat er <strong>is</strong> en waardeer het, kijk niet naar wat jij <strong>zou willen</strong> dat er is. Je mag best een beetje streng zijn voor jezelf op dit moment: dwing jezelf om die dingen te tellen waarmee het leven je gezegend heeft, geniet ervan, koester ze. Je zal merken dat deze houding je vervult met positieve energie, en dat maakt het dan weer gemakkelijker om over die zaken heen te stappen die je op dit moment niet hebt. De kracht van positief denken verbetert altijd je levenskwaliteit!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je de Vier van Bekers trekt dan wijst dit er over het algemeen op dat je situatie niet optimaal is. Misschien verveelt je relatie je een beetje, vind je het maar een saaie boel. Of misschien voel je je leeg, lijkt je gevoel voor je partner opgebruikt te zijn. Misschien kan je je zelfs niet meer herinneren waarom je ooit voor deze persoon gekozen hebt, wat je zo aantrekkelijk vond aan hem of haar. Het lijkt alsof je denkt: dit kan mijn leven niet zijn, ik weet zeker dat ik iets beters verdien! Je bent behoorlijk ontevreden en je gaat helemaal op in dit gevoel. Probeer je eens te focussen op wat wel goed zit in je relatie. Je zal merken dat je daar meer kracht uit put dan uit de houding die je nu aanneemt. En als blijkt dat je er niet in slaagt om ook maar één positief aspect te vinden in je partner, trek dan je conclusies en ga alleen verder. Als je single ben en op zoek, let er dan op dat je niet al je energie investeert in het veroveren van iemand die geen interesse heeft of die te hoog gegrepen is voor jou. Denk er goed over na of je doelen wel realistisch zijn, anders heb je het falen al ingebouwd in je poging.</p>",
                        [GuidanceFields.WORK] : "<p>je vindt het tegenwoordig moeilijk om gemotiveerd te blijven in je werksituatie. Het lijkt allemaal zo saai en herkenbaar, zo leeg. Je ziet geen uitdagingen, je vindt geen nieuwe of opwindende aspecten in je job, er zijn geen nieuwe mensen die de boel wat kunnen opvrolijken. Je hebt het gevoel alsof je daar al een eeuwigheid zit en telkens weer, elke dag, dezelfde saaie dingen moet doen. En misschien is het ook zo dat dit niet je droomjob is, misschien zit je wel wat in een sleur. Maar toch is er ook een positieve kant: je <strong>hebt</strong> tenminste een baan! Probeer hierin toch iets van vreugde te vinden. Laat dat ‘arme ik’ los en ga op zoek naar dingen die je wel wil doen, waar je je wel goed bij zal voelen.</p><p>Als je op zoek bent naar werk dan is het mogelijk zinvol om je in de eerste plaats eens af te vragen of je echt wel werk wil vinden. Als dat niet het geval is, is het verstandiger om je energie eerst te investeren in het ontdekken welke job je graag zou doen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je gedachten en gevoelens zijn van grote invloed op je spirituele leven. Op dit moment lijkt het erop dat je jezelf verliest in emoties die heen en weer gaan tussen zelfmedelijden en ontevredenheid. Je ingesteldheid is voornamelijk passief en negatief. Je blijft maar dingen denken als ‘Als X maar anders zou zijn dan zou ik me vast beter voelen’. Als je hierin blijft hangen dan zal er niets veranderen en zal je alsmaar ongelukkiger worden. Vergeet nu zowel het verleden als de toekomst en concentreer je op het heden, het Nu. Ga er even bij zitten en denk aan de dingen die je wel fijn vindt in je leven, zelfs al zijn het er niet veel. Wat maakt je gelukkig? Waar voel je je tevreden bij? Wie vind je leuk, sympathiek? Schuif niets opzij, als het je een goed gevoel geeft dat je hond blij is als je ’s avonds thuiskomt dan is dat iets positiefs in je leven. Eens je een (misschien kort) lijstje gemaakt hebt, kan je op die dingen gaan focussen en daardoor je algemene gevoel veranderen. Je zal merken dat dit een weg is om je uit die negatieve sleur te halen waar je in zit. Jij bent de architect van wat je voelt!</p>",
                    }
                },
                de : {
                    name: "Vier der Kelche", subtitle: "Wut",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Darauf achten, was man fühlt.",    
                    description : "Die Vier der Kelche spricht über die Wahrnehmung unserer Gefühle und darüber, dass wir in einem Gefühl der Instabilität gefangen sind.",
                    questions : [ "Stecken Sie in Ihren Gefühlen fest?", "Haben Sie ein unbefriedigendes Gefühl?", "Was könnten Sie dagegen tun?", "Bleiben Sie aufmerksam, was es sonst noch geben könnte." ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Bei den „Vier der Kelche“ handelt es sich um gemischte Gefühle. Auf der einen Seite sind Sie nicht zufrieden mit bestimmten Teilen Ihres Lebens. Das Gras erscheint grüner auf der anderen Seite des Zauns. Auf der anderen Seite wissen Sie, dass Sie für viele Dinge dankbar sein können. In gewisser Weise ist Ihre Tasse übervoll, aber das macht Sie immer noch nicht glücklich. Es scheint, dass Sie entschlossen sind, sich auf das Negative anstatt auf das Positive zu konzentrieren. Sie sind bis zum Rand mit Unzufriedenheit erfüllt. Tatsächlich könnte die Lösung sein, sich einfach auf das zu konzentrieren, was Sie wirklich haben und nicht auf das, was sein könnte.</p><p>Im Moment kann es sinnvoll sein, sich in den Griff zu bekommen sowie Ihre Segnungen zu zählen und zu schätzen. Genießen Sie die positive Energie, die Ihnen das bringen wird.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Im Allgemeinen, wenn Sie diese Karte ziehen, bedeutet dies, dass Ihre Situation nicht optimal ist. Vielleicht ist Ihre Beziehung langweilig und haben Sie das Gefühl, leer, hohl, ohne Gefühle zu sein. Möglicherweise können Sie Sich nicht einmal daran erinnern, warum Sie überhaupt diesen Partner ausgewählt haben. Es ist, als ob Sie denken: Das kann nicht mein Leben sein, ich bin sicher, ich habe ein besseres bestellt! Sie scheinen unzufrieden zu sein und Sie neigen dazu, in dieser Unzufriedenheit zu verweilen.</p><p>Vielleicht müssen Sie denken über die Dinge, die gerade für eine Weile schon gut gehen. Es wird Ihnen sicherlich mehr Energie geben als das, was Sie jetzt machen.</p><p>Wenn Sie alleine sind, stellen Sie sicher, dass Sie nicht all Ihre Energie verschwenden, um jemanden zu erobern, der eigentlich unerreichbar ist. Stattdessen stellen Sie Sich sicher, dass Ihre Ziele realistisch sind und nicht zum Scheitern führen.</p>",
                        [GuidanceFields.WORK] : "<p>Im Moment ist es für Sie sehr schwierig bei der Arbeit motiviert zu bleiben. Es scheint alles so gleichmäßig zu sein, immer das alte Lied. Keine Herausforderungen, nichts Neues oder Aufregendes, keine neuen Menschen, um alles ein wenig lebendiger zu machen. Sie fühlen sich, als ob Sie für immer dort waren, die gleichen langweiligen Dinge Tag für Tag. Vielleicht müssen Sie nicht einen Traumjob haben, Ihre Karriere ist vielleicht ein wenig in einer Furche. Aber auf der andere Seite: Sie haben noch einen Job! Versuchen Sie, zumindest ein wenig glücklich zu sein. Es gibt so viele Menschen heute, die nicht einmal einen \"langweilig\" Job haben. Abschütteln, diese \"ich Armer\" Einstellung und suchen Sie Dinge, die Sie wirklich wollen!</p><p>Wenn Sie auf der Suche sind nach einem Job, könnte es eine gute Idee sein, Sich zu fragen, ob Sie wirklich einen finden wollen. Ist dies nicht der Fall ist, sollten Sie Sich vielleicht erstmal fragen welche Art von Arbeit Sie wirklich gerne tun würden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Ihre Art von Denken und Fühlen hat jetzt einen großen Einfluss auf Ihre Spiritualität. Gerade jetzt haben Sie Gefühle, die an Selbstmitleid und Unzufriedenheit grenzen. Ihre Denkweise ist vor allem passiv und negativ. Sie haben die Neigung zu denken \"wenn nur X anders war, dann würde ich mich besser fühlen\". Wenn Sie so weitermachen, wird sich nichts ändern und Sie fühlen sich mehr und mehr unglücklich. Vergessen Sie die Vergangenheit und die Zukunft und konzentrieren Sie Sich auf der Gegenwart. Setzen Sie Sich und denken Sie über die Dinge Ihres Lebens nach, die Sie tatsächlich Freude bereiten, auch wenn es nur ein Paar sind. Was macht sie glücklich? Was erfüllt sie? Wen mögen Sie? Unterlassen Sie nichts. Wenn es Sie glücklich macht, um Ihren Hund zu sehen, wenn Sie nach Hause kommen, dann ist das eine positive Gedanke. Die Konzentration auf diesen Dingen können Ihre Gefühle sehr schnell ändern und es wird Ihnen helfen, sich aus diesem Trott zu befreien, worin Sie sich befinden.</p>",
                    }
                }
            },
            {
                filename: "40",
                numerology : 5,
                en : {
                    name: "Five of Cups", subtitle: "Disappointment",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "self-worth",    
                    description : "Five of Cups talks about learning to communicate about your feelings and learning from disappointments.",
                    questions : [ "Are you disappointed?", "Are you clinging in difficult times?", "Can you sort through disappointments?", "Which feeling are you (yet) unable to let go?", "When is it enough?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card evokes feelings of loss, sadness and disappointment but it also points out that you have an influence on how painful and intense your grief is by deciding what you will focus on: that which is lost or the things that are still there. If you decide to concentrate on your sadness and to carry it all alone, coping with it may turn out to be very hard. But if you accept that you still have some blessings to count too and that one of those blessings is that there are people around you to help and support you, then going through the grief may become a bit easier. </p><p>When confronted with loss and disappointment it is often easier to go with the flow, to let those feelings come over you and while you are experiencing them, to not lose sight of the positives in your life. If you try to fight the grief, it will hit you even harder afterwards, and if you focus on it too much, you may find yourself drowning in it. Only you can get through this difficult period, but you don’t have to do it alone.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you get the Five of Cups your relationship may be on the verge of ending or it has just ended. Right now it is almost impossible for you to see what’s good about this. You are overwhelmed by grief and disappointment. You don’t (want to) understand why this relationship has to end. You want everything to stay the same as it was, you hate it that things are changing. Take your time to experience and work through this grief and disappointment, but don’t wallow in it. There’s a whole world out there with people to meet and het to know. Among them is your new partner, don’t make him/her wait too long!</p><p>If you are single right now, don’t allow yourself to be overwhelmed by your sense of loneliness. It’s way more productive to focus on the possibilities and options you have. Go out and find yourself someone!</p>",
                        [GuidanceFields.WORK] : "<p>You may have some problems at your job nowadays. Maybe your superiors aren’t happy with the work you delivered, they may even have been at bit harsh about it. Maybe you don’t get along with your colleagues as well as you would like to. Before you start taking action to change things, first allow yourself some time to find out if you really want to be and stay in this job. Possibly the time has come to open up to new opportunities, to consider changing jobs. Your feeling of disappointment could actually stem from your need to find a new challenge.</p><p>If you’re looking for a job, don’t take it personally if things aren’t quite going the way you want them to. Just quietly go about your business, things will speed up and chances will come your way soon enough.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Possibly you are feeling frustrated and disappointed right now. Some people haven’t acted the way you wanted them to. It feels as if they let you down and maybe they really have. Still, now is not the time to focus on the part others played or on their shortcomings. The most important task right now is for you to find out what you can learn from these situations. Once you discover the life lessons within, you will realize it is easy to forgive others and be at peace with yourself and them.</p>",
                    }
                },
                fr : {
                    name : "Le Cinq de Coupe", subtitle: "Déception",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "sentiment personnel",    
                    description : " Le Cinq de Coupe, nous parle de l'occasion d'apprendre à communiquer sur ce que nous ressentons et d'apprendre de nos déceptions.",
                    questions : ["Êtes-vous déçu?", "Retenez-vous votre souffle dans une période turbulente?", "Pouvez-vous donner une place à votre déception?", "Quel sentiment ne pouvez-vous pas (encore) lâcher?", "Quand allez-vous vous ressaisir?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte suscite des sentiments de perte, de chagrin et de déception. Cependant, cela indique aussi que vous avez une influence sur la douleur et l'intensité de ces sentiments en choisissant sur quoi vous vous concentrez : ce qui a été perdu ou ce qui est encore là. Si vous vous concentrez sur votre deuil et que vous portez ce sentiment tout seul, cela peut s'avérer très difficile. Mais si vous acceptez que vous soyez béni et que les gens autour de vous soient une bénédiction pour vous soutenir, alors la douleur sera un peu plus facile à supporter.</p><p>Lorsque vous êtes confronté à la perte et à la déception, il est souvent plus facile d’aller avec le courant et de laisser passer ces sentiments. Si vous ressentez ces sentiments, vous ne devriez pas perdre de vue le côté positif de votre vie. Si vous essayez de combattre la douleur, elle ne vous frappera que plus fort. Si vous vous concentrez trop dessus, vous allez vous noyer dans la douleur. Vous seul pouvez traverser cette période difficile, mais vous n'avez pas besoin de le faire seul.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you get the Five of Cups your relationship may be on the verge of ending or it has just ended. Right now it is almost impossible for you to see what’s good about this. You are overwhelmed by grief and disappointment. You don’t (want to) understand why this relationship has to end. You want everything to stay the same as it was, you hate it that things are changing. Take your time to experience and work through this grief and disappointment, but don’t wallow in it. There’s a whole world out there with people to meet and het to know. Among them is your new partner, don’t make him/her wait too long!</p><p>If you are single right now, don’t allow yourself to be overwhelmed by your sense of loneliness. It’s way more productive to focus on the possibilities and options you have. Go out and find yourself someone!</p>",
                        [GuidanceFields.WORK] : "<p>You may have some problems at your job nowadays. Maybe your superiors aren’t happy with the work you delivered, they may even have been at bit harsh about it. Maybe you don’t get along with your colleagues as well as you would like to. Before you start taking action to change things, first allow yourself some time to find out if you really want to be and stay in this job. Possibly the time has come to open up to new opportunities, to consider changing jobs. Your feeling of disappointment could actually stem from your need to find a new challenge.</p><p>If you’re looking for a job, don’t take it personally if things aren’t quite going the way you want them to. Just quietly go about your business, things will speed up and chances will come your way soon enough.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Possibly you are feeling frustrated and disappointed right now. Some people haven’t acted the way you wanted them to. It feels as if they let you down and maybe they really have. Still, now is not the time to focus on the part others played or on their shortcomings. The most important task right now is for you to find out what you can learn from these situations. Once you discover the life lessons within, you will realize it is easy to forgive others and be at peace with yourself and them.</p>",
                    }
                },
                sp:{
                    name:"El Cinco de Copas", subtitle: "Decepción",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "tu sentimiento propio",    
                    description: "El Cinco de Copas se trata de aprender a comunicar lo que sientes y nos indica la capacidad de aprender de nuestras decepciones",
                    questions: ["¿Estás decepcionado?", "¿Estás aguantando la respiración en un momento turbulento?", "¿Puedes dar un lugar a tu decepción?", "¿Qué sentimiento no puedes (todavía) dejar ir?", "¿Cuándo te das la vuelta?"],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta evoca sentimientos de pérdida, tristeza y decepción pero señala también que tiene una influencia sobre el doloroso y  el intenso.  Su queja es  por decidir en lo que se centrará: la que se perdió, y no en las cosas que están todavía allí. Si decide concentrarse en su tristeza y llevar todo solo, hacer frente a la que puede llegar a ser muy duro. Pero si usted acepta que usted todavía tiene algunas de las bendiciones que valar demasiado y que una de esas bendiciones es que hay gente que le rodea para ayudar y apoyar, a continuación, pasar por el dolor puede llegar a ser un poco más fácil. Cuando nos enfrentamos a la pérdida y decepción a menudo es más fácil ir con la corriente, a dejar que esos sentimientos vienen sobre usted y mientras usted está experimentando ellos, para no perder de vista los aspectos positivos en su vida. Si usted trata de luchar contra el dolor, que le golpeará aún más difícil después, y si usted se centra demasiado en ello, puede que le encuentre ahogarse en ella. Sólo usted puede ir a través de este período difícil, pero usted no tiene que hacerlo solo.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Bekers Vijf", subtitle: "Teleurstelling",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "eigen gevoel",    
                    description : "De Vijf van Bekers gaat over leren communiceren over wat je voelt en wijst ons op het kunnen leren van onze teleurstellingen.",
                    questions : [ "Ben jij teleurgesteld?", "Hou jij je krampachtig staande in een woelige tijd?", "Kun jij je teleurstelling een plek geven?", "Welk gevoel kun jij (nog) niet loslaten?", "Wanneer keer jij je om?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart heeft als onderwerp gevoelens van verlies, verdriet en teleurstelling. Toch wijst de Vijf van Bekers er ook op dat je zelf een invloed hebt op de grootte en intensiteit van je verdriet. Jij beslist waarop je je wil concentreren; de personen/dingen die je kwijt bent of de personen/dingen die je nog hebt. Als jij besluit om je te focussen op je verdriet en om het ook nog alleen te dragen, dan zal blijken dat ermee omgaan een zware taak wordt. Maar als je inziet en aanvaardt dat er ook nog positieve aspecten aan je leven zijn èn dat je mensen om je heen hebt die je willen steunen en helpen, dan zal je merken dat het verwerken van je verdriet iets makkelijker wordt.</p><p>Als je geconfronteerd wordt met verlies en teleurstelling dan is het zinvol om mee te gaan met de stroom, de gevoelens over je heen te laten komen. En terwijl je ze ten volle ervaart, kan je ook kijken naar de goede kanten van je leven. Als je probeert om te vechten tegen het verdriet zal het je daarna twee keer zo hard raken. En als je er teveel in opgaat, loop je het risico om erin te verdrinken. Jij bent de enige die door deze zware periode heen moet geraken, maar je hoeft het niet alleen te doen. Laat je meevoeren met de stroom van het verdriet en nodig iemand uit om je hand vast te houden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je de Vijf van Bekers trekt dan kan dit erop wijzen dat je relatie op het punt staat verbroken te worden of dat je net een breuk achter de rug hebt. Op dit moment vind je het vast onmogelijk om te aanvaarden dat er ook iets positief is aan deze situatie. Je wordt overweldigd door verdriet, teleurstelling en mogelijk zelfs frustratie. Je begrijpt niet of wil niet begrijpen waarom deze relatie moest eindigen. Je wil gewoon dat alles zo blijft als het was, je vindt het verschrikkelijk dat de dingen nu gaan veranderen. Probeer jezelf wel de tijd te gunnen om deze negatieve gevoelens te ervaren en te verwerken, maar jezelf er niet in te gaan onderdompelen. Er is nog een hele grote wereld daarbuiten met massa’s mensen die je kan ontmoeten en leren kennen. En daarbuiten loopt er ook iemand rond die jouw nieuwe partner zal worden, laat hem/haar niet te lang op je wachten en maak werk van je verwerkingsproces!</p><p>Als je op dit moment geen relatie hebt, sta jezelf dan niet toe om helemaal op te gaan in je eenzaamheid. Het is veel productiever om je te concentreren op de mogelijkheden en opties die je hebt. Ga naar buiten, treed de wereld tegemoet en vind een partner!</p>",
                        [GuidanceFields.WORK] : "<p>Het is best mogelijk dat je wat problemen hebt op je werk. Misschien zijn je meerderen niet tevreden met het werk dat je levert, misschien zijn ze zelfs nogal grof in hun mededelingen. Of misschien kan je niet zo goed opschieten met een aantal collega’s en vind je dat erg moeilijk. Gun jezelf de tijd om eens goed na te denken over de vraag of je echt wel zeker bent dat je deze job wil blijven uitoefenen, dat je op deze werkplek wil blijven. Misschien is het nu wel de goede tijd om uit te k naar nieuwe mogelijkheden, andere opties. Misschien kan je een baan vinden die beter bij je past, waar je je ontspannener en gelukkiger zal voelen. De teleurstelling en rusteloosheid die je voelt kan immers ook betekenen dat je behoefte hebt aan een nieuwe uitdaging.</p><p>Als je op zoek bent naar werk is het mogelijk dat je geconfronteerd wordt met nogal wat tegenvallers en teleurstellingen. Het feit dat de dingen op dit moment niet gaan zoals je het graag wil, zegt niets over jou als persoon of als mogelijke werknemer. Ga gewoon rustig verder zoals je bezig bent, je zal merken dat de omstandigheden geleidelijk veranderen en dat je weer kansen en mogelijkheden op je weg zal krijgen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je voelt je mogelijk nogal teleurgesteld en gefrustreerd tegenwoordig, misschien zelfs verdrietig. Er zijn immers mensen in je omgeving die zich niet gedragen hebben zoals jij het van hen verwachtte en hoopte. Je hebt het gevoel dat ze je in de steek gelaten hebben en misschien is dat ook echt zo. Toch is het nu niet de juiste tijd om te gaan focussen op het foute gedrag van anderen of op hun tekortkomingen.</p><p>Jouw belangrijkste taak is nu om door je gevoelens van verdriet en teleurstelling heen te gaan, ze een plaats te geven en te gaan bekijken wat jij kan leren van deze ervaring(en). Op het moment dat je de geboden levenslessen ontdekt in deze situatie zal je merken dat het een stuk gemakkelijker wordt om die andere(n) het slechte gedrag te vergeven en vrede te sluiten met hen en met jezelf. En dan heb je alweer een stap gezet op je persoonlijke pad van spirituele groei!</p>",
                    }
                },
                de : {
                    name: "Die Fünf der Kelche", subtitle: "Enttäuschung",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Persönliche Meinung",    
                    description : "Die Fünf der Kelche spricht von der Möglichkeit, zu lernen, wie man darüber kommuniziert, wie man sich fühlt, und aus unseren Enttäuschungen zu lernen.",
                    questions : [ "Sind Sie enttäuscht?", "Halten Sie den Atem an in turbulenten Zeiten?", "Können Sie Ihrer Enttäuschung Raum geben?", "Welches Gefühl können Sie (noch) nicht loslassen?", "Wann werden Sie sich wieder erholen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte weckt Gefühle von Verlust, Trauer und Enttäuschung. Dennoch weist sie auch darauf hin, dass Sie einen Einfluss darauf haben, wie schmerzhaft und intensiv diese Gefühle sind, indem Sie sich dafür entscheiden, worauf  Sie sich  konzentrieren müssen: das, was verloren gegangen ist, oder die Dinge, die noch da sind. Wenn Sie sich auf Ihre Trauer konzentrieren und sie dieses Gefühl ganz allein tragen kann sich das als sehr hart herausstellen. Aber wenn Sie akzeptieren, dass Sie gesegnet sind, dass die Menschen in Ihrer Umgebung ein Segen sind, die Sie unterstützen können, dann wird der Schmerz ein bisschen leichter zu ertragen sein.</p><p>Wenn Sie mit Verlust und Enttäuschung konfrontiert sind ist es oft einfacher, mit dem Strom zu schwimmen und diese Gefühle über sich ergehen zu lassen. Wenn Sie diese Gefühle empfinden sollten Sie das Positive in Ihrem Leben nicht aus den Augen verlieren. Wenn Sie versuchen, den Schmerz zu bekämpfen, wird er Sie schließlich nur noch härter treffen. Wenn Sie sich zu viel darauf konzentrieren werden Sie im Schmerz ertrinken. Nur Sie selbst können durch diese schwierige Zeit kommen, aber Sie brauchen das nicht allein zu tun.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Persönliche Beziehungen und Liebe</p><p>Wenn Sie die Fünf Cups ziehen, könnte Ihre Beziehung fast am Ende sein, oder sich gerade beendet haben. Gerade jetzt ist es fast unmöglich für Sie um zu sehen was daran gut sein könnte. Sie werden von Trauer und Enttäuschung überwältigt. Sie können (wollen) nicht verstehen, warum diese Beziehung zu Ende gehen musste. Sie möchten, dass alles beim alten geblieben wäre. Sie hassen es, dass sich die Dinge ändern. Nehmen Sie Sich die Zeit diese Erfahrung, diese Trauer und diese Enttäuschung zu verdauern, aber schwelgen Sie nicht darin. Es gibt eine ganze Welt da draußen mit Leuten, mit denen Sie Sich treffen könnten. Unter ihnen ist Ihr neuer Partner, lassen Sie ihn / sie nicht zu lange warten!</p><p>Wenn Sie jetzt alleine sind, lassen Sie sich nicht von Ihrem Gefühl der Einsamkeit überwältigen. Es ist viel produktiver sich auf den Möglichkeiten und Optionen zu konzentrieren, die Sie haben. Gehen Sie aus und finden Sie jemanden!</p>",
                        [GuidanceFields.WORK] : "<p>Sie können einige Probleme in Ihrem Job empfinden heutzutage. Vielleicht sind Ihre Vorgesetzten nicht zufrieden mit der Arbeit, die Sie geliefert haben und könnten sie darüber auch ein wenig hart gewesen sein. Vielleicht kommen Sie auch nicht so gut zurecht mit Ihren Kollegen wie Sie eigentlich möchten. Bevor Sie Maßnahmen ergreifen, um die Dinge zu ändern, lassen Sie sich zunächst einige Zeit, um herauszufinden, ob Sie wirklich in diesem Job sein und bleiben möchten. Möglicherweise ist die Zeit gekommen sich zu öffnen für neue Möglichkeiten und eine mögliche Arbeitsplatz wechsel zu berücksichtigen. Ihr Gefühl der Enttäuschung könnte tatsächlich verursacht werden von der Notwendigkeit, eine neue Herausforderung zu finden. Wenn Sie nach einem Job suchen, nehmen Sie es nicht persönlich, wenn die Dinge nicht ganz wohl vor sich gehen wie Sie es wollen. Gehen Sie nur vorsichtig voran. Der Moment zur Beschleunigung und die Chancen kommen schneller als Sie denken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Möglicherweise fühlen Sie Sich gerade jetzt frustriert und enttäuscht. Einige Leute haben nicht so gehandelt, wie Sie es wollten. Es fühlt sich an, als ob sie Sie im Stich gelassen haben und vielleicht haben sie das wirklich. Dennoch ist jetzt nicht die Zeit Sich auf Ihre Fehler zu konzentrieren. Die wichtigste Aufgabe ist jetzt für Sie, um herauszufinden, was Sie aus solchen Situationen lernen können. Sobald Sie die Lektionen des Lebens entdecken, werden Sie feststellen, dass es leicht ist, andere zu vergeben und in Frieden mit Sich Selbst und ihnen zu leben.</p>",
                    }
                }
            },
            {
                filename: "41",
                numerology : 6,
                en : {
                    name: "Six of Cups", subtitle: "Sorrow",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "choosing for what you feel",    
                    description : "Six of Cups talks about emotional memories and that whatever they are, we can choose to move on.",
                    questions : [ "Can you make a choice based on your feelings?", "Can you choose what feels good for you?", "Which emotional memory is still at play?", "Can you choose to let go of an awful feeling?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Six of Cups is often about reminiscing, remembering the way things were, the way you were. In this period of your life you think back on happy relationships you used to have, people you used to know, maybe even happy childhood memories come to mind. All these memories may leave you hungry for a sense of security and safety. Though it is good not to forget about the past, it is also important to remember the lessons you can learn from it, lessons you can apply to your present situation. And it is quite possible that you can look back on painful experiences and realize that they don’t have the power to hurt you anymore, you have grown, evolved. </p><p>Just make sure you don’t linger on the past for too long, remember that the best years haven’t gone by, they are yet to come!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Six of Cups can indicate that it may be time tothink about the way your relationship is going. Do you have clear goals, things you want to achieve together? Or are you mainly going with the flow? Now maybe the right time to decide together which way you want to go from here.</p><p>This card can also symbolize the return of a former lover in your life. Even though such an encounter can be great and reminiscing together can be fulfilling, think twice before you decide to rekindle what used to be. You both are different people now and what used to work won’t necessarily work today; after all you split up for a reason. Maybe you should let bygones be bygones.</p><p>If you are looking for love now is a good time to revisit the past and reassess the lessons you have learned. How can these experiences help you in finding the right partner?</p>",
                        [GuidanceFields.WORK] : "<p>You may be feeling quite nostalgic for the moment. You enjoy reminiscing about former jobs and ex-colleagues. Possibly you even go as far back as your childhood memories. Revisiting the past gives you a sense of security, of wellbeing. Still, this has an influence on your work too, you are not really ‘present’, you tend to be a bit dreamy. Is it simply a matter of harmless and temporary nostalgia? Or is it an indication that you don’t really enjoy your present job anymore? Is some part of you feeling unfulfilled? Do you need new challenges, new learning experiences? If this is the case, you may want to start looking into a career change.</p><p>If you don’t have a job right now, maybe it’s a good idea to revisit the past and remember all the things you used to love doing? This could really put you in the right direction towards finding the perfect position.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In order to grow spiritually, now may be the right time to consult your inner child. Do you remember the intense happiness and joy you used to experience as a child? That great feeling that life was an adventure and you were discovering new things all the time? Find your inner child and release it, give it the space it needs to open up and tell you what you can do to recapture those sentiments. And while you’re at it, you might just be able to help it recover from some less positive past experiences.</p>",
                    }
                },
                fr : {
                    name : "Le Six de Coupe", subtitle: "la tristesse",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "choisir ce que l'on ressent",    
                    description : " Le Six de Coupe, nous parle de souvenirs émotionnels et que tels qu’ils sont nous pouvons choisir de continuer dans le présent.",
                    questions : ["Pouvez-vous faire un choix en fonction de vos sentiments?", "Pouvez-vous choisir ce qu’il vous fait du bien?", "Quelle mémoire émotionnelle joue encore un rôle?", "Pouvez-vous choisir de laisser derrière vous un vilain sentiment?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Six de Coupe\" représentent souvent de baigner dans les mémoires, le souvenir de la façon dont les choses étaient autre fois, nostalgique. En ce moment de votre vie, vous vous souvenez des relations épanouies que vous avez eues avec des gens que vous avez connus et aimés. Peut-être que même les souvenirs heureux de l'enfance vous reviendront en mémoire. Tous ces souvenirs éveillent en vous un désir de sécurité. Bien qu'il soit bon de ne pas oublier le passé, il est également important de se rappeler les leçons que vous avez apprises. Vous pouvez appliquer les expériences du passé à votre situation actuelle. Et il est fort possible que vous vous souveniez d'expériences douloureuses et que vous réalisiez qu'elles ne peuvent plus vous faire de mal parce que vous avez grandi et que vous vous êtes développé.</p><p>Ne vous attardez pas trop longtemps dans le passé. Rappelez-vous que les meilleures années ne sont pas terminées, elles sont encore à venir !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Six of Cups can indicate that it may be time tothink about the way your relationship is going. Do you have clear goals, things you want to achieve together? Or are you mainly going with the flow? Now maybe the right time to decide together which way you want to go from here.</p><p>This card can also symbolize the return of a former lover in your life. Even though such an encounter can be great and reminiscing together can be fulfilling, think twice before you decide to rekindle what used to be. You both are different people now and what used to work won’t necessarily work today; after all you split up for a reason. Maybe you should let bygones be bygones.</p><p>If you are looking for love now is a good time to revisit the past and reassess the lessons you have learned. How can these experiences help you in finding the right partner?</p>",
                        [GuidanceFields.WORK] : "<p>You may be feeling quite nostalgic for the moment. You enjoy reminiscing about former jobs and ex-colleagues. Possibly you even go as far back as your childhood memories. Revisiting the past gives you a sense of security, of wellbeing. Still, this has an influence on your work too, you are not really ‘present’, you tend to be a bit dreamy. Is it simply a matter of harmless and temporary nostalgia? Or is it an indication that you don’t really enjoy your present job anymore? Is some part of you feeling unfulfilled? Do you need new challenges, new learning experiences? If this is the case, you may want to start looking into a career change.</p><p>If you don’t have a job right now, maybe it’s a good idea to revisit the past and remember all the things you used to love doing? This could really put you in the right direction towards finding the perfect position.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In order to grow spiritually, now may be the right time to consult your inner child. Do you remember the intense happiness and joy you used to experience as a child? That great feeling that life was an adventure and you were discovering new things all the time? Find your inner child and release it, give it the space it needs to open up and tell you what you can do to recapture those sentiments. And while you’re at it, you might just be able to help it recover from some less positive past experiences.</p>",
                    }
                },
                sp:{
                    name:"El Seis de Copas", subtitle: "Tristeza",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "elegir por lo que sientes",    
                    description: "El Seis de Copas es sobre recuerdos emocionales y nos recuerda que, sean lo que sean, podemos elegir continuar en el presente ahora",
                    questions: ["¿Puedes hacer una elección basada en tus sentimientos?", "¿Puedes elegir lo que te hace sentir bien?", "¿Qué memoria emocional todavía juega un papel?", "¿Puedes elegir dejar un mal presentimiento atrás?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Seis de Copas es a menudo acerca de recordar el pasado, recordando cómo eran las cosas, la forma en que estaban. En este período de su vida que usted piensa de nuevo en las relaciones de pareja que solía tener, la gente que solía conocer, tal vez incluso recuerdos felices de la niñez vienen a la mente. Todos estos recuerdos pueden dejará usted con hambre de una sensación de seguridad y la seguridad. Aunque es bueno no olvidar el pasado, también es importante recordar las lecciones que podemos aprender de ella, las lecciones se pueden aplicar a su situación actual. Y es muy posible que usted puede mirar hacia atrás en las experiencias dolorosas y darse cuenta de que ellos no tienen el poder para hacerte daño nunca más, has crecido, evolucionado. Sólo asegúrese de no quedarse en el pasado por demasiado tiempo, recuerde que los mejores años no han pasado, que aún están por venir!</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Bekers Zes", subtitle: "Verdriet",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "kiezen voor wat je voelt",    
                    description : "De Zes van Bekers gaat over gevoelsmatige herinneringen en wijst ons er op dat, hoe die ook zijn, we ervoor kunnen kiezen om in het nu verder te gaan.",
                    questions : [ "Kun jij, gebaseerd op je gevoel, een keuze maken?", "Kun jij kiezen voor wat voor jou goed voelt?", "Welke gevoelsmatige herinnering speelt nog een rol?", "Kun jij kiezen om een rotgevoel achter je te laten?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Zes van Bekers heeft het over mijmeren, je herinneren hoe de dingen vroeger waren, hoe jij vroeger was. Je maakt tijd om terug te denken aan fijne vriendschaps- en liefdesrelaties die je gehad hebt, aan mensen die je gekend en bemind hebt. Misschien keer je wel nog verder terug in de tijd en haal je een paar fijne jeugdherinneringen op. Het is positief en zinvol om je het verleden te herinneren, terug te denken aan mooie (en soms minder mooie) tijden. Toch is het ook belangrijk om je te herinneren wat je indertijd geleerd hebt en er even bij stil te staan of je nu nog iets kan leren van dat verleden. Sommige inzichten die je vroeger verworven hebt, kunnen je misschien een betere kijk geven op dingen die je nu meemaakt. Wat ook verrijkend is, dat is om erbij stil te staan dat pijnlijke en trieste herinneringen geen macht meer hebben over jou, ze kunnen je niet meer kwetsen omdat je gegroeid en veranderd bent.</p><p>Let er wel op dat je niet te lang in het verleden blijft hangen, je beste jaren zijn niet voorbij, ze komen er nu pas aan!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Zes van Bekers kan erop wijzen dat het nu tijd is om eens te gaan bekijken hoe het zit met je relatie en jullie plannen voor de toekomst. Hebben jullie duidelijke doelen, zaken die jullie samen willen bereiken? Of ga je gewoon mee met de stroom, plan je niets en laat je het aan het universum over waar jullie zullen uitkomen? Als dat laatste het geval is, dan is het nu een goed moment om samen te bekijken wat je in de toekomst wil bereiken, niet enkel op relationeel vlak maar ook qua gemeenschappelijke doelen.</p><p>Deze kaart kan je ook vertellen dat er een ex-geliefde opnieuw in je leven zal opduiken. Zo’n ontmoeting kan heel fijn zijn, het kan behoorlijk bevredigend en zelfs spannend zijn om samen herinneringen op te halen en ze ook een stuk te herbeleven. Maar denk toch eens goed na voor je zou beslissen dat je de oude vlam opnieuw wil laten branden. Jullie zijn totaal andere mensen geworden en datgene wat jullie vroeger bij mekaar bracht, doet dat daarom vandaag niet. Daar bovenop waren er waarschijnlijk ook gegronde redenen waarom jullie de relatie verbroken hebben. Misschien laat je het verleden beter gewoon waar het thuishoort, in die goeie ouwe tijd.</p><p>Als je op zoek bent naar een relatie maak dan eerst even ruimte om je relationele verleden nog eens te overlopen en de lessen die je geleerd hebt op een rijtje te zetten. Hoe kunnen die lessen je helpen om de juiste partner te vinden?</p>",
                        [GuidanceFields.WORK] : "<p>Misschien voel je je op het moment behoorlijk nostalgisch. Het kan leuk zijn om terug te denken aan vorige banen en collega’s. Misschien denk je zelfs na over je jeugd en de ambities die je toen had. Wilde jij als kind astronaut worden of clown of ...? Het verleden bezoeken kan je een gevoel van veiligheid geven, van tevredenheid over het heden. Toch is het mogelijk dat je hierdoor op het werk minder aanwezig lijkt, alsof je in je hoofd ergens anders zit dan op je werkplek, je komt wat dromerig over. Is dit simpelweg een gevoel van onschuldige en tijdelijke nostalgie? Of is het een aanwijzing dat je je huidige job niet meer zo fijn vindt? Is er misschien een stuk van jou dat zich onbevredigd voelt? Heb je het idee dat je nieuwe uitdagingen nodig hebt, nieuwe kansen om bij te leren, te groeien? Als dat laatste het geval is, ga dan op zoek naar een andere baan, je zal je een stuk tevredener voelen! Als je op zoek bent naar een baan, neem dan even de tijd om te overlopen welke dingen je vroeger fijn en bevredigend vond om te doen. Als je alles op een rijtje zet kunnen dit de perfecte aanwijzingen zijn in welke richting je best kan zoeken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Om aan je spirituele groei te werken is het op dit moment aangewezen om contact te maken met je innerlijke kind. Probeer in je geest terug te keren naar je jeugd, maak er misschien een meditatie of een ontspanningsoefening van. Herinner je je de intense vreugde, het diepe geluk dat je als kind kon ervaren? Herinner je je nog dat fantastische gevoel dat het leven één groot avontuur was en dat je elk moment nieuwe dingen ontdekte, dat je fantasie grenzeloos was?</p><p>Ga op zoek naar je innerlijke kind en geef hem/haar dan de vrijheid, geef dat kind de ruimte om open te komen en je te vertellen wat je kan doen om zo’n momenten opnieuw te beleven. Leer opnieuw te spelen en te ontdekken, rond te kijken met de ogen van een kind. En terwijl je daarmee bezig bent, kan je datzelfde kind ook helpen om te herstellen van pijnlijke ervaringen waar het misschien nog steeds mee zit. Je innerlijke kind helen en vieren is een stap vooruit zetten in je spirituele groei!</p>",
                    }
                },
                de : {
                    name: "Sechs der Kelche", subtitle: "Traurigkeit",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Wählen was man fühlt",    
                    description : "Die Sechs der Kelch spricht von emotionalen Erinnerungen und dass, egal, wie diese auch sind, wir uns entscheiden können, in der Gegenwart weiterzumachen.",
                    questions : [ "Können Sie eine Wahl unabhängig von Ihren Gefühlen treffen?", "Können Sie wählen, was Ihnen gut tut?", "Welche emotionale Erinnerung spielt noch eine Rolle?", "Können Sie sich dafür entscheiden, ein schlechtes Gefühl hinter sich zu lassen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Sechs der Kelche“ stehen oft für das sich Ergehen in Erinnerungen, die Erinnerung an die Art und Weise der Dinge, wie sie waren. In dieser Zeit Ihres Lebens denken Sie zurück an erfüllte Beziehungen, die Sie mit Menschen gehabt haben, die Sie gekannt und geliebt haben. Vielleicht kehren sogar glückliche Kindheitserinnerungen in Ihr Gedächtnis zurück. All diese Erinnerungen wecken in Ihnen die Sehnsucht nach einem Gefühl der Sicherheit. Obwohl es gut ist, die Vergangenheit nicht zu vergessen, ist es auch wichtig, sich an die Lektionen, die Sie gelernt haben, zu erinnern. Die Erfahrungen der Vergangenheit können Sie in Ihrer jetzigen Lage anwenden. Und es ist durchaus möglich, dass Sie auf schmerzliche Erfahrungen zurückblicken und erkennen, dass sie Sie nicht mehr verletzen können, weil Sie erwachsen sind und sich entwickelt haben.</p><p>Verweilen Sie nicht zu lange in der Vergangenheit. Denken Sie daran, dass die besten Jahre noch nicht vorbei sind, die kommen erst noch!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Die Sechs der Kelche kann darauf hin deuten, dass es an die Zeit ist nachzudenken über die Art und Weise worauf Ihre Beziehung sich entwickelt. Haben Sie klare Ziele, Dinge, die Sie gemeinsam erreichen möchten? Oder fließen Sie hauptsächlich mit dem Strom? Jetzt vielleicht der richtige Zeitpunkt, um gemeinsam zu entscheiden, welchen Weg Sie von hier aus gehen wollen.</p><p>Diese Karte kann auch die Rückkehr einen ehemaligen Liebhaber in Ihrem Leben symbolisieren. Auch wenn eine solche Begegnung großartig sein kann groß sein und gemeinsame Erinnerungen können erfüllend sein können, müssen Sie Sich zweimal überlegen, bevor Sie sich entscheiden, wieder zu erleben, was früher war. Sie beide sind jetzt verschiedene Menschen und was damals funktioniert hat, braucht jetzt nicht unbedingt auch wieder zu funktionieren. Schließlich haben Sie sich damals nicht ohne Grund getrennt. Vielleicht sollten Sie die Vergangenheit nicht mehr berühren.</p><p>Wenn Sie auf der Suche nach Liebe sind, ist jetzt ein guter Zeitpunkt, um die Vergangenheit zu überdenken und die Lektionen, die Sie gelernt haben neu zu beurteilen. Wie können diese Erfahrungen Ihnen behilflich sein bei der Suche nach dem richtigen Partner?</p>",
                        [GuidanceFields.WORK] : "<p>Sie haben im Moment ein ganz nostalgisches Gefühl. Sie genießen die Erinnerungen an früheren Arbeitsplätzen und Ex-Kollegen. Möglicherweise gehen Sie sogar so weit bis auf Ihre Kindheit zurück. Eine Revision der Vergangenheit gibt Ihnen ein Gefühl der Sicherheit, des Wohlbefindens. Dennoch hat es einen Einfluss auf Ihre Arbeit. Sie sind nicht wirklich anwesend, Sie sind in der Regel ein wenig verträumt. Ist es einfach eine Frage der harmlosen und vorübergehenden Nostalgie? Oder ist es ein Zeichen, dass Sie Ihrem Job nicht mehr wirklich genießen? Ist ein Teil Ihres Gefühls unerfüllt? Brauchen Sie neue Herausforderungen, neue Lernerfahrungen? Wenn dies der Fall ist, sollten Sie Sich umsehen nach einer Änderung in Ihrem beruflichen Verfassung.</p><p>Wenn Sie jetzt nicht gerade einen Job haben, ist es vielleicht eine gute Idee, um die Vergangenheit zu überdenken und Sich all diese Dinge, die Sie gerne getan haben zu erinnern. Das könnte Sie wirklich in die richtige Richtung bringen in der Suche nach der perfekten Position.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Um spirituell zu wachsen, könnte es jetzt der richtige Zeitpunkt sein, um Ihr inneres Kind zu konsultieren. Erinnern Sie sich an das tiefe Glück und die große Freude, die Sie fühlten, um als Kind zu leben? Das gute Gefühl, dass das Leben ein Abenteuer ist und Sie ständig neue Dinge entdecken konnten? Finden Sie Ihr inneres Kind und lassen Sie ihn, geben Sie ihm den Raum, den es braucht, um sich zu öffnen, um Ihnen zu sagen, was Sie tun können, um diese Gefühle wieder einzufangen. Und während Sie gerade dabei sind, können Sie es vielleicht helfen, sich von einigen weniger positiven Erfahrungen in der Vergangenheit zu erholen.</p>",
                    }
                }
            },
            {
                filename: "42",
                numerology : 7,
                en : {
                    name: "Seven of Cups", subtitle: "Fear",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "following your own feelings",    
                    description : "Seven of Cups talks about moving on with what you feel and possible illusions which can keep us from moving on.",
                    questions : [ "Are you the type of person who says, if… then-I-will?", "Do you have a lot of fantasies?", "Which one would you like to accomplish?", "If something sounds too good to be true does that keep you from doing it?", "Is that really the case, or is that an illusion?", "Dare you discover?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about choices, possibly and probably too many of them. The abundance of options and possibilities may be confusing you, making you feel as if there is way too much going on in your life. Maybe you even feel as if it is impossible to get and stay organized. If you are feeling overwhelmed there is always a danger of not seeing the wood for the trees, or possibly even take your wishes for reality and start building castles in the air. Now is the time to face up to reality, to look at what is really there and what is only going on in your mind.</p><p>Even if there are some aspects of your life you have no control over, there are things you can manage, it is up to you to take on the things you can influence and to accept the ones you can’t.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right nowit is important you stay realistic where your relationship is concerned. No matter how tempting it is to dream and fantasize about a myriad of possibilities, you have to stay real. Decide which goals you and your partner want to reach and go for them. Don’t lose yourself in superficial (though romantic) gestures and daydreams. Building a viable relationship is mainly a matter of commitment and realism.</p><p>If you are single and you fall head over heels for someone, ask yourself if there’s more than just infatuation. Is this person someone you can see yourself spending your life with? Do you have a lot in common? Could the two of you survive in the ‘real’ world together? Don’t let your (temporary) passion blind you, look beyond it.</p>",
                        [GuidanceFields.WORK] : "<p>You may be feeling very creative and inspired at work, you feel as if you have an abundance of options, many possible ways to go. Still, before you try out any of those options, it may be a good idea to investigate them with a large pinch of realism. Are all those ideas viable? Or are you getting carried away by your enthusiasm, your creativity? Make sure you only set yourself those goals that you are realistically able to reach. Don’t set yourself up for disappointment or failure. You do have a lot of qualities, but you don’t have them all.</p><p>If you are looking for a job, be sure to apply only for those positions you are actually suited for. Take a realistic look at your abilities and act accordingly. </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>On a spiritual level you may find yourself a bit overwhelmed. There seem to be so many choices and options, so many possible projects.What to choose, what to do first? Maybe the best first step would be to look for some help and guidance. There are a lot of positive and spiritual people around who can guide you through this jungle of possibilities. And your own positive attitude will provide the perfect foundation to build on.</p>",
                    }
                },
                fr : {
                    name : "Le Sept de Coupe", subtitle: "Peur",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "Suivez vos propres sentiments",    
                    description : " Le Sept de Coupe, nous parle de continuer avec ce que nous ressentons et d’illusions possibles qui peuvent inutilement nous empêcher de le faire.",
                    questions : ["Êtes-vous quelqu’un qui pose des conditions avant de le faire?", "Avez-vous beaucoup de fantasmes?", "Quels fantasmes voulez-vous réaliser?", "Vous laissez-vous arrêter si cela semble trop beau pour être vrai?", "Est-ce vraiment vrai, ou est-ce une illusion?", "Osez-vous le découvrir?"],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente des décisions, peut-être et probablement trop nombreuses. Cette abondance d'options et de possibilités peut vous embrouiller. Cela peut vous donner l'impression qu'il se passe trop de choses dans votre vie. Vous pourriez même penser qu'il est impossible de s'organiser et de rester organisé. Si vous êtes submergés, il y a toujours le danger de ne pas voir l’arbre qui cache la forêt, de croire que vos désirs sont réalité ou de construire des illusions. Maintenant, il est temps de faire face à la réalité, de se concentrer sur ce qui est réellement là et non sur ce qui vit seulement dans votre esprit.</p><p>Bien qu'il y ait certains aspects de votre vie sur lesquels vous n'avez aucun contrôle, sur d’autres c’est possible. C'est votre décision de vous concentrer sur ce que vous pouvez influencer et non sur ce qui ne l’est pas. Vous devriez l'accepter.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right nowit is important you stay realistic where your relationship is concerned. No matter how tempting it is to dream and fantasize about a myriad of possibilities, you have to stay real. Decide which goals you and your partner want to reach and go for them. Don’t lose yourself in superficial (though romantic) gestures and daydreams. Building a viable relationship is mainly a matter of commitment and realism.</p><p>If you are single and you fall head over heels for someone, ask yourself if there’s more than just infatuation. Is this person someone you can see yourself spending your life with? Do you have a lot in common? Could the two of you survive in the ‘real’ world together? Don’t let your (temporary) passion blind you, look beyond it.</p>",
                        [GuidanceFields.WORK] : "<p>You may be feeling very creative and inspired at work, you feel as if you have an abundance of options, many possible ways to go. Still, before you try out any of those options, it may be a good idea to investigate them with a large pinch of realism. Are all those ideas viable? Or are you getting carried away by your enthusiasm, your creativity? Make sure you only set yourself those goals that you are realistically able to reach. Don’t set yourself up for disappointment or failure. You do have a lot of qualities, but you don’t have them all.</p><p>If you are looking for a job, be sure to apply only for those positions you are actually suited for. Take a realistic look at your abilities and act accordingly. </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>On a spiritual level you may find yourself a bit overwhelmed. There seem to be so many choices and options, so many possible projects.What to choose, what to do first? Maybe the best first step would be to look for some help and guidance. There are a lot of positive and spiritual people around who can guide you through this jungle of possibilities. And your own positive attitude will provide the perfect foundation to build on.</p>",
                    }
                },
                sp:{
                    name:"El Siete de Copas", subtitle: "Miedo",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "Sigue tus propios sentimientos",    
                    description: "El Siete de Copas trata de continuar con lo que sientes y nos señala nuestras posibles ilusiones que pueden impedirnos (hacerlo) innecesariamente",
                    questions: ["¿Eres alguien: sí esto es lo primero ... entonces .. iré?", "¿Tienes muchas fantasías?", "¿Qué fantasías quieres realizar?", "¿Te dejas detener si te parece demasiado hermoso para ser verdad?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es acerca de elecciones, posibles y probablemente muchos de ellos. La abundancia de opciones y posibilidades puede ser confuso á usted, haciéndose sentir como si hay demasiado pasando en su vida. Tal vez incluso se siente como si esta casi  imposible conseguir y mantenerse organizado. Si se siente abrumado siempre hay un peligro de no ver el bosque por los árboles, o posiblemente incluso tomar sus deseos por la realidad y empezar a construir castillos en el aire. Ahora es el momento de afrontar la realidad, a ver lo que realmente está allí y lo único que está pasando en tu mente. Aunque hay algunos aspectos de su vida sobre que no tiene control, hay cosas que usted puede manejar, es a usted para asumir las cosas que puede influir y para aceptar las que no pueden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Bekers Zeven", subtitle: "Angst",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "je eigen gevoel volgen",    
                    description : "De Zeven van Bekers gaat over verder gaan met wat je voelt en wijst ons op onze mogelijke illusies die ons daar onnodig van kunnen weerhouden.",
                    questions : [ "Ben jij iemand van:áls eerst dit..dán..ga ik wel...?", "Heb jij veel fantasieën?", "Welke fantasie wil jij verwezenlijken?", "Laat jij je weerhouden als het te mooi lijkt om waar te kunnen zijn?", "Is dat werkelijk zo, of is dat een illusie?", "Durf jij het te ontdekken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Zeven van Bekers symboliseert keuzes, ze kan er zelfs op wijzen dat je teveel keuzes hebt op dit moment. Een overweldigende hoeveelheid mogelijkheden en opties kan verwarrend werken. Het kan je het gevoel geven dat er gewoon teveel aan de hand is in je leven. Misschien heb je zelfs het gevoel dat het onmogelijk is om wat structuur in je leven te krijgen en te houden. Als je je overweldigd voelt dan loop je altijd het gevaar om door de bomen het bos niet meer te zien. Het is dan ook mogelijk dat je je wensen voor waarheid aanneemt en luchtkastelen gaat bouwen. Nu is het nochtans zaak om de realiteit onder ogen te zien en te bekijken wat er echt aan de hand is en wat enkel in je verbeelding bestaat.</p><p>Er zijn altijd bepaalde aspecten van je leven waar je geen enkele controle over hebt, maar er zijn ook altijd dingen die jij kan bepalen. Nu is het aan jou om die dingen die je niet kan bepalen te aanvaarden en om aan de slag te gaan met de rest. Acceptatie + Actie = Groei!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment is het belangrijk dat je een realistische kijk blijft houden op je relatie. Ook al is het heel verleidelijk om te gaan dromen en fantaseren over oneindige mogelijkheden, het is de realiteit waarmee je het moet doen. Overleg met je partner, beslis samen welke doelen jullie willen bereiken en ga dan aan de slag. Verlies je niet in oppervlakkige (misschien wel romantische) gebaren en dagdromen. Een langdurige levensvatbare relatie wordt nu eenmaal opgebouwd met toewijding en realiteitszin.</p><p>Als je single bent zit het erin dat je hals over kop verliefd wordt op iemand. Als dit het geval is, vraag je dan af of er wel iets meer is dan enkel vlinders in je buik. Kijk eens objectief naar deze persoon en stel jezelf de volgende vragen: kan je je voorstellen dat je de rest van je leven samen bent met deze persoon? Hebben jullie veel dingen gemeenschappelijk? Zouden jullie het samen kunnen maken in de ‘echte’ wereld? Laat je niet verblinden door je (tijdelijke) passie, kijk ook wat verder.</p>",
                        [GuidanceFields.WORK] : "<p>Je voelt je waarschijnlijk behoorlijk creatief en geïnspireerd in verband met je werk. Het voelt alsof je onmetelijk veel opties hebt, allerlei wegen die je kan kiezen. Voordat je die nieuwe opties uitprobeert, is het verstandig om ze te kruiden met een behoorlijke dosis realiteitszin. Zijn jouw ideeën echt levensvatbaar? Of laat je je overweldigen door je enthousiasme en je creativiteit? Zorg ervoor dat de doelen die je jezelf stelt ook haalbaar zijn, dat ze gerealiseerd kunnen worden. Anders ga je voor jezelf teleurstellingen plannen in plaats van successen. Je hebt vele kwaliteiten maar je bent niet perfect!</p><p>Als je op zoek bent naar werk dan is het belangrijk dat je nu enkel gaat solliciteren voor jobs die echt geschikt zijn voor jou. Kijk realistisch naar je kwaliteiten en talenten en kies de vacatures in functie daarvan.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op spiritueel vlak is het best mogelijk dat je je wat overweldigd voelt door alle mogelijkheden die je op dit moment ziet: er zijn zoveel theorieën om te onderzoeken, zoveel mogelijke keuzes, zoveel praktische toepassingen, de opties lijken wel oneindig. Hoe beslis je wat je uitkiest, wat pak je het eerst aan?</p><p>Dit is voor jou het juiste moment om hulp en leiding te vragen. Er zijn best veel positieve en spirituele mensen die je kunnen gidsen doorheen deze jungle van mogelijkheden. Je eigen positieve instelling zal de perfecte bagage zijn om deze zoektocht mee te ondernemen!</p>",
                    }
                },
                de : {
                    name: "Sieben der Kelche", subtitle: "Angst",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Den eigenen Gefühlen folgen.",    
                    description : "Die Sieben der Kelche spricht darüber, damit fortzufahren, was wir fühlen, und mit möglichen Illusionen, die uns unnötigerweise daran hindern können, dies zu tun.",
                    questions : [ "Sind Sie jemand, der vorher Bedingungen stellt?", " Haben Sie viele Fantasien?", "Welche Fantasien wollen Sie verwirklichen?", "Lassen Sie sich aufhalten, wenn es zu gut ist, um wahr zu sein?", "Ist es wirklich wahr oder ist es eine Illusion?", "Wagen Sie es, dies zu entdecken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für Entscheidungen, möglicherweise und wahrscheinlich für zu viele. Dieser Überfluss an Optionen und Möglichkeiten kann Sie verwirren. Er kann Ihnen das Gefühl geben, als wäre in Ihrem Leben zu viel los. Man könnte sogar das Gefühl bekommen, dass es unmöglich ist, sich zu organisieren und organisiert zu bleiben. Wenn Sie überwältigt sind besteht immer die Gefahr, den Wald vor lauter Bäumen nicht mehr zu sehen, oder vielleicht sogar Ihre Wünsche für die Wirklichkeit zu halten und Luftschlösser zu bauen. Jetzt ist es Zeit, sich mit der Realität auseinanderzusetzen und auf das zu konzentrieren, was tatsächlich da ist, und nicht auf das, was nur in Ihrem Geist lebt.</p><p>Auch wenn es einige Aspekte Ihres Lebens gibt, über die Sie keine Kontrolle haben, gibt es auch Dinge, die Sie beherrschen können. Es ist Ihre Entscheidung, sich auf die Dinge zu konzentrieren,  die Sie beeinflussen <b>können</b> und nicht auf die, die Sie nicht beeinflussen können. Das sollten Sie akzeptieren.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Gerade jetzt ist es wichtig, dass Sie realistisch bleiben, wo es Ihre Beziehung anbelangt. Egal, wie verlockend es ist zu träumen und fantasieren über eine Vielzahl von Möglichkeiten, Sie haben jetzt wirklich zu tun. Entscheiden Sie, welche Ziele Sie und Ihr Partner erreichen wollen und ziele sie nach. Verlieren Sie Sich nicht in oberflächliche (wenn auch romantischen) Gesten und Tagträume. Der Aufbau einer tragfähigen Beziehung ist vor allem eine Frage des Engagements und Realismus.</p><p>Wenn Sie alleine sind und Sie sich Hals über Kopf in jemanden verlieben, fragen Sie sich, ob es tatsächlich mehr als nur Verliebtheit ist. Ist diese Person jemand, mit der Sie Ihr Leben verbringen wollen? Haben Sie viel gemeinsam? Könnte Ihr beide zusammen überleben in der \"realistischen\" Welt? Lassen Sie Sich von Ihrem (vorübergehenden) Leidenschaft nicht blind machen, schauen Sie darüber hinaus.</p>",
                        [GuidanceFields.WORK] : "<p>Sie fühlen Sich sehr kreativ und inspiriert bei der Arbeit. Sie fühlen Sich, als ob Sie eine Fülle von Optionen zur Verfügung haben, viele Möglichkeiten. Dennoch, bevor Sie diese mal ausprobieren, kann es eine gute Idee sein sie erst mal mit einem großen Sinn für Realismus zu untersuchen. Sind all diese Ideen tragfähig? Oder lassen Sie Sich führen von Ihrem Enthusiasmus und Kreativität? Setzen Sie Sich nur die Ziele, die realistisch gesehen erreichbar sind. Stellen Sie Sich nicht offen für Enttäuschung oder Misserfolg. Sie haben eine Menge von Eigenschaften, aber Sie müssen sie nicht alle haben.</p><p>Wenn Sie auf der Suche nach einem Job sind, sollten Sie nur für die für Sie geeignete Positionen wählen. Werfen Sie einen realistischen Blick auf Ihre Fähigkeiten und handeln Sie dem entsprechend.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Auf spiritueller Ebene sind Sie vielleicht etwas überfordert. Es gibt so viele Möglichkeiten und Optionen, so viele mögliche Projekte. Was zu wählen, was als Erstes zu tun? Vielleicht wäre der beste erste Schritt erst mal nach etwas Hilfe und Beratung zu suchen. Es gibt eine Menge von positiven und spirituellen Menschen, die Sie durch den Dschungel der Möglichkeiten führen könnte. Und Ihre eigene positive Einstellung wird die perfekte Grundlage bieten, um darauf aufzubauen.</p>",
                    }
                }
            },
            {
                filename: "43",
                numerology : 8,
                en : {
                    name: "Eight of Cups", subtitle: "Stagnation",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "taking responsibility for your feelings",    
                    description : "Eight of Cups talks about taking responsibilities for what you feel and know that what we feel is right, even if the end of the road is yet invisible.",
                    questions : [ "Do you feel that you have to take a different path?", "Is the result still unknown to you?", "Can you follow your feeling, even if you don't know what it leads to?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>If you draw this card it is very likely that you are being confronted with a choice. You are no longer comfortable with a situation, a person, a relationship of maybe even a living environment, and you are contemplating on putting an end to it. You are the only one who can make this choice because only you know exactly why you are no longer happy, you are aware of what is missing. It is well possible that on the outside everything is looking hunky dory but you know this is not what you want, you want more and you are ready to go for it, even if you are not entirely sure what you will be looking for. And even though you are sad about the things or persons you have to leave behind, you realize that going away is in your best interest. You have to close one door before you can start opening the next one.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Getting the Eight of Cups generally means an ending of some sorts.Maybe you are actually breaking up with your partner because the relationship is no longer offering you what you want and need. It is also possible that you suddenly see your partner in a new light. You may have been keeping the perception of her/him the way you saw them at the start of your relationship. Now reality comes knocking and you find yourself with a totally different person. If this is the case, don’t take too long to decide if you’re going to stay or go. Nobody appreciates being left in the dark.</p><p>If you consider looking for a partner, take your time to find out if you really are over the last one. Still being hung up on an ex is not exactly the best way to start a relationship.</p>",
                        [GuidanceFields.WORK] : "<p>Nowadays at work it seems as if you’ve disengaged, you have no sense of connection with your job anymore, maybe you are even feeling bored. Do you want to stay where you are and try to change the circumstances? It could be as easy as having a heart to heart with your superiors to make you feel appreciated again, to rediscover your former motivation. Or do you want a clean slate, do you want to go out and find a different job? There are no right or wrong choices here, you are the only one who knows what to do. Go with your gut, your intuition.</p><p>If you are looking for a job, it may be hard to stay motivated. Consider the possibility of taking a small break, to get rid of some of your stress. You will be surprised how motivated you’ll feel after your ‘sabbatical’.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Right now you may feel unsatisfied on a spiritual level. Books, courses, lectures … you’ve been there, done that, bought the t-shirt. The things you are looking for probably won’t be found in the outside world. Now is the time to turn inside, to seek your own company in the first place, to listen to your inner voice. You have been evolving quite a lot spiritually and you will be able to harvest at least some of the fruits of your labor by simply listening to yourself.</p>",
                    }
                },
                fr : {
                    name : "Le Huit de Coupe", subtitle: "Stagnation",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "prendre la responsabilité de ce que l'on ressent",    
                    description : "Le Huit de Coupe, nous parle de prendre la responsabilité de ce que nous ressentons et d’aller dans la direction que nous estimons être la nôtre, même lorsque le point final n'est pas encore visible.",
                    questions : ["Sentez-vous que vous devez prendre un autre chemin?", "Le résultat vous est-il encore inconnu?", "Pouvez-vous suivre vos sentiments, même si vous ne savez pas encore où ils vous mènent?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Si vous tirez cette carte, il est très probable que vous serez confronté à une décision.  Vous ne vous sentez plus à l'aise avec une situation, avec une personne, avec une relation, peut-être même avec un habitat. Vous avez atteint un point où vous pouvez mettre fin à quelque chose. Vous êtes le seul à pouvoir prendre cette décision. Parce que vous seul savez exactement pourquoi vous n'êtes plus heureux. Vous êtes le seul à être pleinement conscient de ce qui manque. Il est tout à fait possible que votre vie, de l'extérieur, semble être en parfait ordre. Mais vous savez que ce n'est pas ce que vous voulez.  Vous en voulez plus et vous êtes prêt à vous engager pour ce sujet, tout en ne sachant pas exactement ce que vous cherchez. Même si vous êtes triste par rapport à des situations ou des gens que vous laisserez derrière vous, vous savez que c'est la meilleure option. Parfois, il faut fermer une porte avant que la suivante ne s'ouvre.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Getting the Eight of Cups generally means an ending of some sorts.Maybe you are actually breaking up with your partner because the relationship is no longer offering you what you want and need. It is also possible that you suddenly see your partner in a new light. You may have been keeping the perception of her/him the way you saw them at the start of your relationship. Now reality comes knocking and you find yourself with a totally different person. If this is the case, don’t take too long to decide if you’re going to stay or go. Nobody appreciates being left in the dark.</p><p>If you consider looking for a partner, take your time to find out if you really are over the last one. Still being hung up on an ex is not exactly the best way to start a relationship.</p>",
                        [GuidanceFields.WORK] : "<p>Nowadays at work it seems as if you’ve disengaged, you have no sense of connection with your job anymore, maybe you are even feeling bored. Do you want to stay where you are and try to change the circumstances? It could be as easy as having a heart to heart with your superiors to make you feel appreciated again, to rediscover your former motivation. Or do you want a clean slate, do you want to go out and find a different job? There are no right or wrong choices here, you are the only one who knows what to do. Go with your gut, your intuition.</p><p>If you are looking for a job, it may be hard to stay motivated. Consider the possibility of taking a small break, to get rid of some of your stress. You will be surprised how motivated you’ll feel after your ‘sabbatical’.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Right now you may feel unsatisfied on a spiritual level. Books, courses, lectures … you’ve been there, done that, bought the t-shirt. The things you are looking for probably won’t be found in the outside world. Now is the time to turn inside, to seek your own company in the first place, to listen to your inner voice. You have been evolving quite a lot spiritually and you will be able to harvest at least some of the fruits of your labor by simply listening to yourself.</p>",
                    }
                },
                sp:{
                    name:"El Ocho de copas", subtitle: "Estancamiento",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "Asumir la responsabilidad de lo que sientes",    
                    description: "El Ocho de Copas se trata de tomar responsabilidad por lo que sientes y señalarnos que tenemos que ir por ese camino que sentimos que es nuestro camino, incluso cuando el punto final aún no es visible",
                    questions: ["¿Sientes que tienes que tomar un camino diferente?", "¿Sigues sin conocer el resultado?", "¿Puedes seguir tus sentimientos, aunque todavía no sepas a qué lleva eso?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Si saca esta tarjeta es muy probable que usted estés confrontado con una elección. Ya no se siente cómodo con una situación, una persona, una relación de tal vez incluso un entorno de vida, y usted está contemplando en poner fin a la misma. Usted es el único que puede tomar esta decisión porque sólo usted sabe exactamente por qué ya no es feliz, es consciente de lo que falta. Es bien posible que en el todo lo que no esté buscando miel sobre hojuelas, pero usted sepa que esto no es lo que quieres, quieres más y ya está listo para ir por ella, incluso si no está del todo seguro de lo que está buscando. Y a pesar de estar triste por las cosas o las personas que tienen que dejar atrás, le da cuenta que lo que va a desaparecer es en su mejor interés. Tiene que cerrar una puerta antes de que pueda empezar a abrir la siguiente.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Bekers Acht", subtitle: "Stagnatie",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "verantwoording nemen voor wat je voelt",    
                    description : "De Acht van Bekers gaat over verantwoording nemen voor wat je voelt en wijst ons er op dat we die weg dienen te gaan waarvan we voelen dat het onze weg is, zelfs wanneer het eindpunt nog niet zichtbaar is.",
                    questions : [ "Voel jij dat je een andere weg in dient te slaan?", "Is het gevolg daarvan je nog onbekend?", "Kun jij je gevoel volgen, ook al weet je nog niet waartoe dat leidt?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Als je deze kaart trekt dan is het goed mogelijk dat je op dit moment geconfronteerd wordt met een keuze of keuzes. Je bent niet langer tevreden met een bepaalde situatie, persoon, relatie of misschien zelfs met je woonomgeving en je overweegt om er een eind aan te breien. Jij bent de enige die deze keuze kan maken omdat jij en jij alleen exact weet waarom je niet langer gelukkig bent. Jij bent je ten volle bewust van wat je mist, welke behoefte niet vervuld wordt. Het is trouwens best mogelijk dat je leven er aan de buitenkant perfect normaal en evenwichtig uitziet. Maar jij weet dat dit niet is wat je wil, je wil meer dan dit. Je bent er klaar voor om naar dat ‘meer’ op zoek te gaan, ook al weet je misschien niet nog duidelijk wat het inhoudt, waar je naar op zoek bent. En ook al ben je verdrietig omdat je bepaalde mensen, situaties, dingen achter je zal moeten laten, je weet en gelooft dat deze stap op dit moment de beste is voor jou. Soms moet je eerst een deur dichtdoen en kan je daarna pas de weg zien naar de volgende deur.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In verband met relaties wijst de Acht van Bekers over het algemeen op een soort einde, een afsluiting. Het is mogelijk dat je effectief een punt zet achter de relatie met je geliefde omdat je er niet meer kan uithalen wat je nodig hebt en waar je naar verlangt. Het is ook mogelijk dat je je partner plots in een nieuw licht ziet. Vaak is het zo dat je je geliefde blijft waarnemen zoals je dat in het begin van je relatie deed, je beeld verandert dan niet of nauwelijks. Nu komt de realiteit aankloppen en besef je dat je partner een totaal andere persoon geworden is. Als dit het geval is, doe er dan niet te lang over om de knoop door te hakken en te beslissen of je al dan niet kan leven met deze nieuwe visie op je wederhelft. Tenslotte vindt niemand het fijn om niet te weten waar hij/zij aan toe is.</p><p>Als je van plan bent om op zoek te gaan naar een partner, bekijk dan eerst voor jezelf of je echt wel over je vorige relatie heen bent. Het is heel belangrijk dat je op geen enkele manier nog verlangt naar je ex, dat je niets mist van wat er was. Anders zal dit je nieuwe relatie van in het begin op een negatieve manier kleuren.</p>",
                        [GuidanceFields.WORK] : "<p>Het kan tegenwoordig op het werk zo lijken alsof je afgehaakt hebt, alsof je je niet meer verbonden voelt met je werk en je werkomgeving. Misschien verveel je je zelfs. Je hebt nu twee opties: wil je blijven waar je bent en proberen om de situatie te veranderen? Het is best mogelijk dat je dit relatief simpel kan bereiken door bvb. een open gesprek te hebben met je meerderen over je behoefte aan waardering.</p><p>Daardoor kan je vroegere motivatie terug openbloeien. Of wil je met een schone lei starten en een andere baan gaan zoeken? Er zijn hier geen goede of foute keuzes, jij bent de enige die echt weet wat je wil doen. Nu is het belangrijk dat je je buikgevoel vertrouwt, luister naar je intuïtie.</p><p>Als je op zoek bent naar werk is het op dit moment niet gemakkelijk om gemotiveerd te blijven. Overweeg eens of het misschien verstandig zou zijn om een korte pauze in te lassen zodat je je stressniveau wat kan verlagen. Je zal merken dat je een stuk gemotiveerder bent na je ‘vakantie’!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Waarschijnlijk voel je je op spiritueel vlak een stuk onbevredigd op dit moment. Boeken, cursussen, lezingen, oefeningen ... je hebt het allemaal al gezien en meegemaakt, er is niets dat je nog echt aanspreekt, het voelt allemaal te vertrouwd. Eigenlijk komt het erop neer dat je wat jij zoekt niet zal vinden in de wereld om je heen, hoe hard je ook zoekt. Het is nu zaak om je naar binnen te keren, je vooral te wijden aan je eigen gezelschap, naar je innerlijke stem te luisteren. Je bent in de voorbije periode behoorlijk gegroeid op spiritueel vlak, als is dat bijna ongemerkt gegaan. Nu is het zaak om de vruchten te plukken van die groei en naar je eigen spirituele stem te luisteren, stel je open voor jezelf!</p>",
                    }
                },
                de : {
                    name: "Acht der Kelche", subtitle: "Stagnation",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Die Verantwortung für seine Gefühle übernehmen",    
                    description : "Die Acht der Kelche spricht von der Verantwortung für das, was wir fühlen, und dass wir in die Richtung gehen sollen, die wir für unsere eigene halten, auch wenn der Endpunkt noch nicht sichtbar ist.",
                    questions : [ "Haben Sie das Gefühl, dass Sie einen anderen Weg gehen sollten?", "Ist Ihnen das Ergebnis noch unbekannt?", "Können Sie Ihren Gefühlen folgen, auch wenn Sie noch nicht wissen, wohin sie Sie führen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Wenn Sie diese Karte ziehen  ist es sehr wahrscheinlich, dass Sie mit einer Entscheidung konfrontiert werden. Sie fühlen sich nicht mehr wohl mit einer Situation, mit einer Person, mit einer Beziehung, vielleicht sogar mit einem Lebensraum. Sie sind an einem Punkt angelangt, etwas zu beenden. Sie sind der Einzige, der diese Entscheidung treffen kann. Denn nur Sie wissen genau, warum Sie nicht mehr glücklich sind. Sie sind der Einzige, der sich voll und ganz dessen bewusst ist, was fehlt. Es ist durchaus möglich, dass Ihr Leben, von außen betrachtet, zwar in bester Ordnung zu sein scheint. Aber Sie wissen, dass es nicht das ist, was Sie wollen. Sie wollen mehr und sind bereit, sich dafür einzusetzen. Auch wenn Sie nicht ganz sicher wissen, was Sie genau suchen. Auch wenn Sie wegen Dinge oder Personen, die Sie zurücklassen müssen, traurig sind, so wissen Sie doch, dass das die beste Option ist. Manchmal muss man eine Tür schließen, bevor sich die nächste öffnet.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Das Ziehen der Acht der Kelche bedeutet in der Regel ein Ende von etwas. Vielleicht sind Sie tatsächlich dabei aufzubrechen mit Ihrem Partner, weil die Beziehung Ihnen nicht mehr das bietet, was Sie wollen und brauchen. Es ist auch möglich, dass Sie plötzlich Ihren Partner in einem ganz neuen Licht sehen. Möglicherweise wurde die Wahrnehmung, so wie die am Anfang Ihrer Beziehung war, beibehalten. Jetzt klopft die Realität an der Tür und sehen Sie jetzt einen völlig anderen Person. Wenn dies der Fall ist, zögern Sie nicht zu lange Sich zu entscheiden, ob Sie gehen oder bleiben möchten. Niemand schätzt es lange im Dunkeln gelassen zu werden. Wenn Sie auf der Suche nach einem Partner sind, nehmen Sie sich Zeit, um herauszufinden, ob Sie wirklich über die letzte Beziehung hinweg gekommen sind. Wenn man immer noch an eine alte Beziehung hängt ist das nicht gerade das beste Moment, um eine neue Beziehung anzufangen.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit scheint es, als ob Sie Sich davon gelöst haben, keinen Sinn mehr haben für Verbindung mit Ihrem Job. Vielleicht langweilen Sie Sich sogar. Wollen Sie bleiben, wo Sie sind und versuchen, die Umstände zu ändern? Es könnte so einfach sein ein gutes Gespräch mit Ihrem Vorgesetzten zu haben, damit Sie Sich wieder geschätzt fühlen und Ihre ehemalige Motivation wieder entdecken. Oder möchten Sie mit einer sauberen Weste anfangen und Sich einen anderen Job suchen? Es gibt keine richtigen oder falschen Entscheidungen hier, Sie sind der einzige, der weiß, was zu tun ist. Gehen Sie mit Ihrem Gefühl, mit Ihrer Intuition.</p><p>Wenn Sie einen Job suchen, kann es schwierig sein, motiviert zu bleiben. Betrachten Sie die Möglichkeit, eine kleine Pause zu machen um Ihren Stress loszuwerden. Sie werden überrascht sein, wie motiviert Sie Sich nach Ihrem \"Sabbatical\" fühlen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Im Moment fühlen Sie sich möglicherweise auf einer spirituellen Ebene unzufrieden. Bücher, Kurse, Vorträge … Sie sind dort gewesen sind, haben alles getan, sogar Sich dieses T-Shirt gekauft. Die Dinge, die Sie suchen, werden Sie wahrscheinlich nicht in der Außenwelt finden. Jetzt ist die Zeit sich nach Innen zu wenden, um Ihre eigene Gesellschaft zu suchen und um auf Ihre innere Stimme zu hören. Sie haben Sich spirituell ziemlich entwickelt und Sie werden jetzt bestimmt in der Lage sein, zumindest einige der Früchte Ihrer Arbeit zu ernten, indem Sie einfach auf Sich Selbst hören.</p>",
                    }
                }
            },
            {
                filename: "44",
                numerology : 9,
                en : {
                    name: "Nine of Cups", subtitle: "Fulfillment",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "knowing what you feel",    
                    description : "Nine of Cups talks about being aware of our own feelings and that it is ok to show them.",
                    questions : [ "Are you aware of your own feelings?", "Can you show them or are you always ok?", "How open can you be about your real feelings?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Nine of Cups is generally one of the most positive cards in the Tarot card deck, it speaks of domestic bliss, being carefree, having fun, generally enjoying yourself. If you have certain hopes and dreams you really wish would come true then this card tells you that this is very likely to be happening in the somewhat near future. If you are having doubts or questions on certain issues, then drawing this card means that a positive outcome is probable.</p><p>There is one thing to watch out for: don’t become too smug, too sure of yourself.</p><p>This card is also being associated with team spirit, finding it easy to work together, experiencing harmony in your contacts (private and business). Enjoy this uplifting experience and make sure things stay this way by consciously working at maintaining this harmonious spirit.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This is a great time to be in a relationship. You and your partner are very likely to go to the next level, to reach new heights of fulfillment. Your love for eachother is getting stronger every day and you are finding new ways of expressing it. You may find new depths of emotion you didn’t realize were possible.</p><p>If you are single, now is the time to go out and have fun. You will radiate a positivity that helps you meet all kinds of people. Don’t focus on finding a partner too much but enjoy the ride. Mr or Miss Right will probably be among the people you meet and all will become clear in time.</p>",
                        [GuidanceFields.WORK] : "<p>This card is sometimes being associated with team spirit, finding it easy to work together, Right now there is a lot of harmony in your contacts (private and business). Enjoy this uplifting experience and make sure things stay this way: consciously work at maintaining the harmony.</p><p>For the moment it seems as if you can’t go wrong, there’s success in everything you do. If you were thinking about a new technique or a new way of organizing things, now is the time to set the wheels in motion. Your ideas will surely be welcomed.</p><p>If you are looking for work, be sure to set up a lot of interviews. You will dazzle the interviewers with your vibrant personality and positive outlook!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Nine of Cups isn’t the most spiritual of cards because it’s all about enjoyment and fulfilment of the senses. Still, thoroughly enjoying all aspects of everyday life can help you to find spiritual balance and harmony.</p><p>No matter how happy you are with things as they are right now, take some time to look inside yourself and to create a mental image of the things you still want to achieve spiritually. Don’t be shy, go for gold!</p>",
                    }
                },
                fr : {
                    name : "Le Neuf de Coupe", subtitle: "Exécution",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "savoir ce que l'on ressent",    
                    description : " Le Neuf de Coupe, nous parle d’être conscient de ses propres sentiments et que nous pouvons les montrer.",
                    questions : ["Êtes-vous conscient de ce que vous ressentez?", "Pouvez-vous le montrer, ou dites-vous toujours que tout va bien?", "Dans quelle mesure pouvez-vous être ouvert concernant ce que vous ressentez vraiment?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La \"Neuf de Coupe\" est l'une des cartes les plus positives du jeu de Tarot. La carte parle de bonheur domestique, d'insouciance et de plaisir. Maintenant, il est temps de profiter de toutes sortes de joies, qu'il s'agisse d'une très bonne nourriture, d'un bain de soleil ou d'une aventure érotique. Tous les souhaits peuvent maintenant être exaucés.</p><p>Sur un autre plan, cette carte vous dit que vous avez certains espoirs et rêves dont vous souhaitez la réalisation. C'est très probable dans un proche avenir. Si vous avez des doutes ou des questions sur certains sujets, cette carte signifie qu'un résultat positif est imminent. C'est pourquoi le \"Neuf de Coupe\" est aussi appelé carte de vœux.</p><p>Mais faites attention à ne pas devenir trop complaisant, trop sûr de vous. Et n'oubliez pas que toutes vos actions ont des conséquences, non seulement pour vous-même, mais pour toutes les personnes concernées. Le fait que vous vous sentiez bien dans certaines choses ne signifie pas que d'autres en font l'expérience.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This is a great time to be in a relationship. You and your partner are very likely to go to the next level, to reach new heights of fulfillment. Your love for eachother is getting stronger every day and you are finding new ways of expressing it. You may find new depths of emotion you didn’t realize were possible.</p><p>If you are single, now is the time to go out and have fun. You will radiate a positivity that helps you meet all kinds of people. Don’t focus on finding a partner too much but enjoy the ride. Mr or Miss Right will probably be among the people you meet and all will become clear in time.</p>",
                        [GuidanceFields.WORK] : "<p>This card is sometimes being associated with team spirit, finding it easy to work together, Right now there is a lot of harmony in your contacts (private and business). Enjoy this uplifting experience and make sure things stay this way: consciously work at maintaining the harmony.</p><p>For the moment it seems as if you can’t go wrong, there’s success in everything you do. If you were thinking about a new technique or a new way of organizing things, now is the time to set the wheels in motion. Your ideas will surely be welcomed.</p><p>If you are looking for work, be sure to set up a lot of interviews. You will dazzle the interviewers with your vibrant personality and positive outlook!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Nine of Cups isn’t the most spiritual of cards because it’s all about enjoyment and fulfilment of the senses. Still, thoroughly enjoying all aspects of everyday life can help you to find spiritual balance and harmony.</p><p>No matter how happy you are with things as they are right now, take some time to look inside yourself and to create a mental image of the things you still want to achieve spiritually. Don’t be shy, go for gold!</p>",
                    }
                },
                sp:{
                    name:"El Nueve de Copas", subtitle: "Cumplimiento",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "saber cómo te sientes",    
                    description: "El Nueve de Copas se trata de ser consciente de tus propios sentimientos y nos recuerda que podemos mostrar nuestros sentimientos",
                    questions: ["¿Eres consciente de lo que sientes?", "¿Puedes demostrarlo, o eres una persona  que lo haces mejor de lo que es?", "¿Qué abierto puedes estar sobre lo que realmente sientes?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Nueve de Copas es generalmente una de las cartas más positivos en la baraja del tarot, se habla de la felicidad doméstica, no tener preocupaciones, divertirse, en general disfrutar de su mismo. Si usted tiene ciertas esperanzas y sueños que realmente desea haría realidad entonces esta tarjeta le dice que esto es muy probable que se realiza en el futuro algo cercano. Si tiene dudas o preguntas sobre ciertos temas, entonces, sacando esta tarjeta significa que un resultado positivo es probable. Hay una cosa a tener en cuenta: no se haga demasiado engreído, muy seguro de su mismo. Esta tarjeta también se asocia con el espíritu de equipo, la búsqueda trabajando juntos fácilmente, experimentar la armonía en sus contactos (particulares y empresas). Disfrute de esta experiencia edificante y hacen que todo esté así por trabajar conscientemente a mantener este espíritu armonioso.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Bekers Negen", subtitle: "Vervulling",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "weten wat je voelt",    
                    description : "De Negen van Bekers gaat over bewust zijn van je eigen gevoel en wijst ons er op dat we onze gevoelens kunnen tonen.",
                    questions : [ "Ben jij je bewust van hetgeen je voelt?", "Kun jij dat tonen, of ben je een 'mooi-weer-speler'?", "Hoe open kun jij zijn over wat je echt voelt?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Negen van Bekers is een van de meest positieve kaarten van de hele Tarot. Trefwoorden zijn huiselijk geluk, zorgeloosheid, plezier en vreugde. Het komt er nu op aan dat je gaat genieten, of het nu gaat om een uurtje zonnen, een wandeling, een lekkere maaltijd of meer sensuele bezigheden. Je zintuigen staan op scherp, voed ze!</p><p>Op een ander niveau vertelt deze kaart je dat, als je bepaalde wensen of verlangens hebt waarvan je hoopt dat ze vervuld zullen worden, dit waarschijnlijk zal gaan gebeuren in de nabije toekomst. Als je vragen of twijfels hebt in verband met iets, dan vertelt deze kaart je dat je mag hopen op een positieve uitkomst. Daarom wordt de Negen van Bekers vaak ook de Wenskaart genoemd.</p><p>Let er wel voor op dat je niet te zelfzeker wordt of te zorgeloos want dat houdt risico’s in. Het blijft immers zo dat alles wat je doet gevolgen heeft, niet enkel voor jezelf maar ook voor de mensen om je heen. Het feit dat jij je heel goed voelt bij bepaalde zaken of situaties betekent niet automatisch dat dit ook voor anderen geldt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In je liefdesleven beleef je nu waarschijnlijk mooie tijden. Het zit er in dat jullie van plan zijn om jullie relatie naar het volgende niveau te tillen, en jullie beleven de liefde en betrokkenheid ook steeds intenser. Jullie gevoelens worden elke dag sterker en je vindt ook steeds weer nieuwe manieren om die emoties uit te drukken naar je partner toe. Het is best mogelijk dat je nu een intensiteit van gevoel ervaart die je nooit tevoren meegemaakt hebt. Geniet ervan!</p><p>Als je single bent dan is het nu een goede tijd om de wereld in te trekken en plezier te maken. Je straalt een sterke positiviteit uit die ervoor zorgt dat allerlei mensen zich tot je aangetrokken voelen en je willen leren kennen. Concentreer je niet teveel op het vinden van een partner maar eerder op het genieten van wat je doet. Je zal merken dat ergens in die groep nieuwe mensen zich de juiste persoon voor jou bevindt.</p>",
                        [GuidanceFields.WORK] : "<p>Deze kaart is soms symbolisch voor teamspirit, ze wijst erop dat je het gemakkelijk vindt om samen te werken, om naar een gemeenschappelijk doel toe te werken. Op dit moment ervaar je een intense harmonie in je contacten (privé en werk). Geniet hier ten volle van en zorg er ook voor dat het zo blijft: richt je op het behouden van die harmonie.</p><p>Op dit moment lijkt het alsof je geen fouten kan maken, alles wat je doet is succesvol. Misschien heb je een nieuwe techniek in je hoofd of een andere manier op bepaalde zaken te organiseren. Als dit het geval is dan is nu het juiste moment om die dingen uit te proberen. Je ideeën zullen zeker welkom zijn.</p><p>Als je op zoek bent naar een baan, plan dan nu zoveel mogelijk gesprekken. Je straalt op dit ogenblik een sterke positieve energie uit die vast indruk zal maken!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Negen van Bekers is niet direct een heel spirituele kaart omdat ze de nadruk legt op genieten en het bevredigen van je zintuigen. Toch is het zo dat als je grondig kan genieten van alle aspecten van het dagelijkse leven, je ook makkelijker spiritueel evenwicht en harmonie zal bereiken.</p><p>Ook al ben je op dit moment druk met het bevredigen van je zintuigen, neem toch even de tijd om bij jezelf naar binnen te kijken en te visualiseren welke doelen je nog wil bereiken op spiritueel vlak. Wees niet te bescheiden, the sky is the limit!</p>",
                    }
                },
                de : {
                    name: "Neun der Kelche", subtitle: "Erfüllung",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Wissen, was man fühlt",    
                    description : "Die Neun der Kelche sprechen darüber, dass wir uns unserer eigenen Gefühle bewusst sein sollen und dass wir sie zeigen können.",
                    questions : [ "Sind Sie sich dessen bewusst, was Sie fühlen?", "Können Sie es zeigen, oder sagen Sie immer, dass alles in Ordnung ist?", "Inwieweit können Sie offen darüber sein, wie Sie sich wirklich fühlen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Neun der Kelche“ ist eine der positivsten Karten im Tarot-Karten-Deck. Die Karte spricht von häuslichem Glück, von Sorglosigkeit und von Spaß. Jetzt ist es Zeit, alle Arten von Freude zu genießen, ob es nun wirklich gutes Essen, ein Sonnenbad oder ein erotisches Abenteuer ist. Alle Wünsche können jetzt erfüllt werden.</p><p>Auf einer anderen Ebene erzählt diese Karte, dass Sie bestimmte Hoffnungen und Träume haben, von denen Sie wirklich wollen, dass sie in Erfüllung gehen. Das ist sehr wahrscheinlich in der nahen Zukunft der Fall. Wenn Sie zweifeln oder Fragen zu bestimmten Themen haben bedeutet diese Karte, dass ein positives Ergebnis bevorsteht. Deshalb wird die „Neun der Kelche“ auch als Wunsch-Karte zu bezeichnen.</p><p>Achten Sie aber darauf, nicht zu selbstgefällig, zu selbstsicher zu werden. Und vergessen Sie nicht, dass alle Ihre Handlungen Folgen haben, nicht nur für Sie selbst, sondern für alle Beteiligten. Die Tatsache, dass Sie sich bezüglich bestimmter Dinge ganz gut fühlen, bedeute nicht, dass auch Andere das ebenso erfahren.</p>",
                        [GuidanceFields.RELATIONSHIP] : "p>Dies ist eine gute Zeit, um in einer Beziehung zu sein. Sie und Ihr Partner sind sehr wahrscheinlich dabei auf die nächste Ebene zu gehen, um neue Höhen der Erfüllung zu erreichen. Ihre Liebe für einander wird jeden Tag stärker und Sie finden neue Wege das auszudrücken. Sie können neue Tiefe in Gefühle finden, die Ihnen noch nicht klar waren.</p><p>Wenn Sie alleine sind, ist jetzt die Zeit aus zu gehen und Spaß zu haben. Sie strahlen eine Positivität aus, die Ihnen helfen wird alle Arten von Menschen zu begegnen. Konzentrieren Sie Sich nicht zu viel auf der Suche nach einem Partner, aber genießen Sie den Ausflug. Wahrscheinlich ist Herr oder Fräulein “Richtig” wahrscheinlich unter den Menschen, die Sie treffen und alles wird in der Zeit klar werden.</p>",
                        [GuidanceFields.WORK] : "<p>Diese Karte wird manchmal mit Teamgeist verbunden. Es fällt Ihnen leicht mit anderen zusammen zu arbeiten und gerade jetzt gibt es eine Menge von Harmonie in Ihren Kontakten (privat und geschäftlich). Genießen Sie diese erhebende Erfahrung und stellen Sie sicher, dass die Dinge so bleiben: arbeiten Sie bewusst aber mit Aufrechterhaltung der Harmonie.</p><p>Im Moment scheint es, als ob Sie nichts falsch machen können. Sie haben Erfolg bei allem, was Sie tun. Wenn Sie nachdenken würden über eine neue Technik oder eine neue Art der Organisation von Dingen ist jetzt die Zeit, um die Räder in Bewegung zu setzen. Ihre Ideen werden sicherlich warm begrüßt werden.</p><p>Wenn Sie auf der Suche nach Arbeit sind, verabreden Sie Sich dann für viele Interviews. Sie werden den Interviewer mit Ihrer lebendigen Persönlichkeit und positiven Aussicht blenden!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Neun der Kelche ist nicht die meist spirituelle der Karten, hier geht es um Genuss und Erfüllung der Sinne. Dennoch das gründlich genießen aller Aspekte des täglichen Lebens kann Ihnen helfen, geistiges Gleichgewicht und Harmonie zu finden.</p><p>Egal, wie glücklich Sie sind mit den Dingen, wie sie jetzt sind, nehmen Sie sich Zeit, um in Sich Selbst zu suchen und machen Sie in Ihrem Geist ein Bild der Dinge, die Sie spirituell noch erreichen möchten. Scheuen Sie Sich nicht, gehen Sie für Gold!</p>",
                    }
                }
            },
            {
                filename: "45",
                numerology : 10,
                en : {
                    name: "Ten of Cups", subtitle: "Passion",
                    suit : "Cups",
                    element : "🜄 - water",
                    theme : "feeling a lot",    
                    description : "Ten of Cups talks about feeling a lot and that we want to see everyone happy.",
                    questions : [ "Do you feel a lot?", "Do you want to pleasure every one?", "Are you aware that everyone includes yourself?", "What gives you the ultimate good vibe?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Ten of Cups is also a very positive and uplifting card. The difference with the Nine of Cups is that this card indicates a general sense of wellbeing, where the former primarily promised fulfilment of wishes and dreams. The Ten of Cups tells you that you can feel safe, confident, and that you are surrounded with people who love and cherish you. Now is the time to just enjoy life and be happy with your situation the way it is now. There is nothing to worry about, no need to make plans, just taste life to the fullest.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now, you can generally feel relaxed and content in your relationship. Whatever hurt, frustration and pain you have known in the past, you worked through them and let go, you have freed yourself from the burden of past experiences. There’s nothing to hold you back anymore, so now is the time to bask yourself in the love and intimacy you share with your partner. Maybe you feel ready to commit yourself even more. If this is the case, give your partner the time and space to get to this point in his/her own time. Do not worry about this, they will get there when they’re ready.</p><p>If you are single, now is the time to enjoy your social life to the fullest. Don’t go frantically looking for someone to love, just have fun! All will come to you in due time.</p>",
                        [GuidanceFields.WORK] : "<p>The Ten of Cups indicates that you are having a good time at work. Your colleagues and superiors notice your efforts and generally like working with you. You are a real team player and that is truly appreciated. Even though you are well liked, now is not the time to slow down and take it easy. To maintain this popularity you will have to keep investing your time and efforts. It’s an ongoing process of give and take.</p><p>Now is also a good time to make new contacts because your communication skills are at a high. Use those skills to get the interview that could land you your dream job!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Right now you are probably experiencing a sense of joy and inner peace. You are feeling harmony and oneness with everything and everyone. Use these emotions to create peace and harmony around you, in your relationship, your family, with friends. Share your spiritual wealth with everyone you meet. For the moment you have an abundance of positive energy and offering some of it to others will make you even happier. After all, happiness shared really is happiness doubled!</p>",
                    }
                },
                fr : {
                    name : "Le Dix de Coupe", subtitle: "Passion",
                    suit : "Coupes",
                    element :  "🜄 - eau",
                    theme : "ressentir beaucoup",    
                    description : " Le Dix de Coupe, nous parle de beaucoup de sentiments et que nous voulons que tout le monde soit heureux",
                    questions : ["Ressentez-vous beaucoup?", "Voulez-vous que tout le monde se sente heureux?", "Savez-vous que vous aussi faites partie de tout le monde?", "Que vous rend-il enfin heureux?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Dix de Coupe\" ainsi que le \"Neuf de Coupe\" sont également une carte très positive et édifiante. La différence est que cette carte indique un sentiment général de bien-être et la précédente promet surtout l'accomplissement des désirs et des rêves. Le \"Neuf de Coupe\" ont dit que les bénédictions viennent sur votre chemin. Quand vous recevez le \"Dix de Coupe\", vous comptez et appréciez vos bénédictions. Cette carte vous montre que vous pouvez vous sentir en sécurité, en confiance, que vous êtes entourés de gens qui vous aiment et vous apprécient. C'est le moment de simplement profiter de la vie et d'être satisfait de votre situation telle qu'elle est. Il n'y a rien à craindre ou à planifier. Profitez pleinement de votre vie.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now, you can generally feel relaxed and content in your relationship. Whatever hurt, frustration and pain you have known in the past, you worked through them and let go, you have freed yourself from the burden of past experiences. There’s nothing to hold you back anymore, so now is the time to bask yourself in the love and intimacy you share with your partner. Maybe you feel ready to commit yourself even more. If this is the case, give your partner the time and space to get to this point in his/her own time. Do not worry about this, they will get there when they’re ready.</p><p>If you are single, now is the time to enjoy your social life to the fullest. Don’t go frantically looking for someone to love, just have fun! All will come to you in due time.</p>",
                        [GuidanceFields.WORK] : "<p>The Ten of Cups indicates that you are having a good time at work. Your colleagues and superiors notice your efforts and generally like working with you. You are a real team player and that is truly appreciated. Even though you are well liked, now is not the time to slow down and take it easy. To maintain this popularity you will have to keep investing your time and efforts. It’s an ongoing process of give and take.</p><p>Now is also a good time to make new contacts because your communication skills are at a high. Use those skills to get the interview that could land you your dream job!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Right now you are probably experiencing a sense of joy and inner peace. You are feeling harmony and oneness with everything and everyone. Use these emotions to create peace and harmony around you, in your relationship, your family, with friends. Share your spiritual wealth with everyone you meet. For the moment you have an abundance of positive energy and offering some of it to others will make you even happier. After all, happiness shared really is happiness doubled!</p>",
                    }
                },
                sp:{
                    name:"El Diez de Copas", subtitle: "Pasión",
                    suit: "Copas",
                    element: "🜄 - agua",
                    theme: "sentir mucho",    
                    description: "El Diez de Copas es sobre un montón de sentimientos y nos recuerda que queremos ver a todos felices",
                    questions: ["Sientes mucho?", "¿Quieres dar a 'todos' un buen sentimiento?", "¿Sabes que 'todos' están incluyendo ti mismo?", "¿Qué es lo que te hace sentir bien?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Diez de Copas es también una tarjeta muy positiva y edificante. La diferencia con el Nueve de Copas es que esta carta indica una sensación general de bienestar, donde el ex prometido principalmente cumplimiento de los deseos y sueños. El Diez de Copas le dice que usted puede sentirse seguro, confiado, y que está rodeado de personas que aman y te aprecian. Ahora es el momento de disfrutar de la vida y ser feliz con su situación como está ahora. No hay nada de qué preocuparse, no hay necesidad de hacer planes, simplemente saborear la vida al máximo.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Bekers Tien", subtitle: "Hartstocht",
                    suit : "Bekers",
                    element : "🜄 - water",
                    theme : "heel veel voelen",    
                    description : "De Tien van Bekers gaat over heel veel gevoel en wijst ons er op dat we graag iedereen gelukkig willen zien.",
                    questions : [ "Voel jij veel?", "Wil jij 'iedereen' een goed gevoel geven?", "Weet jij dat 'iedereen' inclusief jezelf is?", "Waar voel jij je ultiem goed bij?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Tien van Bekers is, net zoals de Negen van Bekers, een positieve en opgewekte kaart. Het verschil met de vorige kaart is dat het hier gaat om een algemeen goed gevoel, terwijl de Negen van Bekers het vervullen van wensen en dromen belooft. De Negen vertelt je dat er zegeningen onderweg zijn naar jou, de Tien stelt vast dat je op dit moment je zegeningen aan het tellen bent en er volop van geniet. Je kan je nu veilig en vol zelfvertrouwen voelen in het bewustzijn dat je omgeven wordt door mensen die van je houden en je koesteren. Nu is het een moment om gewoon van het leven te genieten en blij te zijn met je situatie zoals die is. Ontspan je, neem de tijd om op te gaan in alle leuke dingen die je kan bedenken. Bekijk het alsof je nu even vakantie neemt van zorgen en piekeren. Je hoeft niets te plannen, geen problemen op te lossen, enkel te leven!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment kan je je gerust tevreden en ontspannen voelen in je relatie. Ook al heb je in het verleden pijn, frustratie en stress gekend, je hebt al die gevoelens doorleefd en verwerkt. Nu je al dat negatieve losgelaten hebt, ga je niet meer gebukt onder die oude bagage. Niets houdt je nu nog tegen, je kan ten volle genieten van de liefde en intimiteit die je deelt met je geliefde. Het is ook best mogelijk dat je nu een stap verder wil zetten, je nog meer wil engageren. Dat kan gaan van samenwonen tot trouwen of misschien samen een kind krijgen. Let er wel op dat je je partner de tijd en ruimte geeft om zelf ook tot op dit punt te geraken. Maak je niet ongerust, het juiste moment komt zeker. Het is gewoon een kwestie van mekaars tempo respecteren.</p><p>Als je op zoek bent naar een partner dan is het op dit moment beter om niet fanatiek op zoek te gaan maar integendeel de tijd te nemen om gewoon te genieten van je sociale leven. Ontspan je en maak plezier, de juiste persoon zal dan vanzelf in je leven komen.</p>",
                        [GuidanceFields.WORK] : "<p>De Tien van Bekers wijst erop dat alles op dit moment goed verloopt op het werk. Je collega’s en meerderen weten heel goed hoe hard je werkt en ze vinden het fijn om met jou samen te werken. Je bent een echte teamspeler en dat wordt zeker gewaardeerd. Maar ook al ben je populair, het is nu niet te goede tijd om je te ontspannen en het rustig aan te gaan doen. Om een positieve indruk te blijven maken is het belangrijk dat je tijd en moeite blijft investeren. Een goede sfeer op het werk is een kwestie van consequent geven en nemen.</p><p>Als je op zoek bent naar een (andere) baan dan is het nu het juiste moment om contacten te leggen. Je communiceert tegenwoordig namelijk heel positief en duidelijk, je straalt engagement uit. Deze kenmerken zorgen ervoor dat je je van je beste kant laat zien in interviews!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritueel gezien ervaar je nu waarschijnlijk een gevoel van intense vreugde en innerlijke vrede. Je voelt je in harmonie met alles en iedereen, je voelt je één met het universum. Gebruik deze gevoelens om ook in je omgeving, je relaties, familie en vrienden een gevoel van vrede en harmonie te creëren. Deel je spirituele rijkdom met iedereen die op je weg komt. Op dit moment geniet je van een overvloed aan positieve energie. Door dit te delen met anderen zal je je nog beter voelen. Tenslotte is het echt wel zo dat gedeelde vreugde dubbele vreugde betekent!</p>",
                    }
                },
                de : {
                    name: "Zehn der Kelche", subtitle: "Passion",
                    suit : "Kelche",
                    element : "🜄 - Wasser",
                    theme : "Viel fühlen",    
                    description : "Die Zehn der Kelche spricht von vielen Gefühlen und dass wir wollen, dass alle glücklich sind",
                    questions : [ "Empfinden Sie viel?", "Wollen Sie, dass sich alle glücklich fühlen?", "Wissen Sie, dass auch Sie Teil von allen sind?", "Was macht Sie letztendlich glücklich?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Zehn der Kelche“ ist - wie die „Neun der Kelche“ - ebenfalls eine sehr positive und erhebende Karte. Der Unterschied besteht darin, dass diese Karte ein allgemeines Gefühl des Wohlbefindens anzeigt, und die vorherige hauptsächlich die Erfüllung von Wünschen und Träumen verspricht. Die „Neun der Kelche“ sagte, dass Segnungen auf Ihren Weg kommen. Wenn Sie die „Zehn der Kelche“ bekommen, sind Sie dabei, Ihre Segnungen zu zählen und zu genießen. Diese Karte zeigt Ihnen, dass Sie sich sicher und selbstbewusst fühlen können, und dass Sie umgeben sind von Menschen, die Sie lieben und schätzen. Jetzt ist es Zeit, das Leben einfach nur zu genießen und mit Ihrer Situation, so wie sie jetzt ist, zufrieden zu sein. Es gibt nichts, worüber Sie sich Sorgen machen oder wofür sie Pläne machen müssen. Genießen Ihr Leben in vollen Zügen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Gerade jetzt können Sie das Gefühl haben entspannt und zufrieden in Ihrer Beziehung zu sein. Was auch immer in der Vergangenheit weh getan hat, welche Frustrationen und Schmerzen Sie auch gehabt haben, Sie haben sie durchgearbeitet und losgelassen. Sie haben Sich von der Last der Erfahrungen aus der Vergangenheit befreit. Es gibt jetzt nichts mehr, um Sie zurückzuhalten. Also ist jetzt die Zeit da, sich in der Liebe und Intimität die Sie mit Ihrem Partner teilen zu wärmen. Vielleicht fühlen Sie sich bereit, noch mehr zu investieren. Wenn dies der Fall ist, geben Sie Ihrem Partner die Zeit und Raum um zu diesem Punkt in seine / Ihre eigenen Moment zu kommen. Kümmern Sie Sich nicht, er / sie wird dorthin gelangen, wenn er / sie bereit sind. Wenn Sie alleine sind, ist jetzt die Zeit, um Ihr Sozialleben in vollen Zügen zu genießen. Gehen Sie nicht auf der Suche nach jemanden zum lieben, haben Sie nur Spaß! Das Andere kommt zu gegebener Zeit auf Ihnen zu.</p>",
                        [GuidanceFields.WORK] : "<p>Die Zehn der Kelche zeigt an, dass Sie eine gute Zeit in der Arbeit erleben. Ihre Kollegen und Vorgesetzten schätzen Ihre Bemühungen und arbeiten in der Regel gerne mit Ihnen zusammen. Sie sind ein echter Teamplayer und das wird wirklich geschätzt. Auch wenn Sie gut herankommen, ist jetzt nicht die Zeit zu verlangsamen aber nehmen Sie es einfach hin. Um diese Popularität zu behalten, müssen Sie Zeit und Mühe investieren. Es ist ein dauerndes Prozess von Geben und Nehmen. Jetzt ist auch eine gute Zeit, um neue Beziehungen einzugehen, weil Ihre Kommunikationsfähigkeiten sich jetzt auf einer hohen Ebene befinden. Verwenden Sie diese Fähigkeiten in dem Interview, das Sie vielleicht Ihren Traumjob bieten könnte!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Im Moment erleben Sie wahrscheinlich ein Gefühl der Freude und des inneren Friedens. Sie fühlen sich in Harmonie und Einheit mit allem und jedem. Benutzen Sie diese Gefühle von Frieden und Harmonie in Ihrer Umgebung, in Ihrer Beziehung, mit Ihrer Familie, mit Freunden. Teile Ihren geistigen Reichtum mit jedem, den Sie treffen. Im Moment haben Sie eine Fülle von positiver Energie und diese zu teilen mit anderen wird Sie noch glücklicher machen. Immerhin geteiltes Glück ist wirklich doppeltes Glück!</p>",
                    }
                }
            },
            {
                filename: "46",
                numerology : 11,
                en : {
                    name: "Child of Cups", subtitle: "Feeler",
                    court : "Page",
                    suit : "Cups",
                    element : [ "🜄 - water", "🜃 - earth" ],
                    theme : "having an impartial look at your own feelings",    
                    description : "Page of Cups talks about being impartial about our own feelings and to look at them with a child-like light-heartedness.",
                    questions : [ "Do you allow yourself your own feelings?", "Dare you follow your gut only from that child-like light-heartedness?", "How does that feel?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about playfulness, spontaneity but also about reconciliation and giving people second chances.</p><p>The Page of Cups tells you that now is a good time to enjoy life on a simple level, to be spontaneous, not to think things through too much but just follow your instincts and simply enjoy what comes to mind. Give your inner child some room to do as it pleases, you will find that this will thoroughly relax you and give you positive energy.</p><p>Another aspect of this card is that you may be in a conflict with someone and now this person is offering to make up, to reconcile. Maybe your first reflex is to keep your distance, but if you allow yourself some time you may realize that you too want to make it up with this person. Accept this peace offer, it will do you a world of good to let go of some negativity.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Now is the time to listen to your heart and not your head. You may be overflown with love for your partner and feeling very romantic … do not hesitate, act upon it. Organize a weekend getaway to that special place, buy that present of which only you two really know what it means, surprise him/her, shower him/her with love. Whatever comes to mind, no matter how corny, act upon it, don’t let your brain hold you back, be truly romantic now!</p><p>If you are having a quarrel, don’t let your mind rule but follow your heart and make up. Life is too short to spend it fighting with your loved one.</p><p>If you are single, be prepared to fall victim to total infatuation and enjoy it while it lasts!</p>",
                        [GuidanceFields.WORK] : "<p>Getting the Page of Cups means there is hope, a ray of sunshine behind the clouds. Even if you realize now that you are truly unhappy at work, even if you really are in a rut, this card tells you that now the time has come to look at your dreams and find a way to make them come true. No matter how difficult your situation, there’s always choices and possibilities. Look at your inner child and listen carefully to what it has to tell you, it will probably point you in the right direction.</p><p>If you are looking for a job, now is the time to give your dreams another chance. Reach for the stars, there’s no limits to what you might achieve!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>It is quite possible that you have formed some spiritual habits throughout the years, certain ways of approaching things you have gotten used to. No matter how comfortable you feel with those habits, now is the time to throw them out the window and go look for new ones. Sit down and listen to your intuition, your gut feeling, allow yourself to be inspired by them and see where they lead you. There’s a whole spiritual universe out there for you to dive into, feel free to explore it!</p>",
                    }
                },
                fr : {
                    name : "L'enfant des Coupes", subtitle: "Sensible",
                    court : "Valet",
                    suit : "Coupes",
                    element :  ["🜄 - eau", "🜃 - terre" ],
                    theme : "regarder ce que l'on ressent sans préjugés",    
                    description : "Le Valet des Coupes, nous parle de regarder ce que l’on ressent à  esprit ouvert et enfantin.", 
                    questions : ["Pouvez-vous vraiment sentir ce que vous ressentez?", "Osez-vous suivre vos sentiments, \"juste\" à cause de cet esprit ouvert enfantin?", "Qu'est-ce que cela vous fait?" ],


                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente la joie et la spontanéité, mais aussi la réconciliation, elle donne une seconde chance aux gens.</p><p>La carte \"Valet de Coupe\" vous dit que c'est le bon moment pour profiter de la vie à un niveau simple, pour être spontané, et peut-être même un peu enfantin. Ne ruminez pas les choses, suivez simplement votre instinct et appréciez ce qui vous vient à l'esprit. Donnez à votre enfant intérieur l'espace nécessaire pour faire ce qu'il ou elle veut. Vous trouverez que vous êtes complètement détendu et que vous pouvez puiser beaucoup d'énergie positive.</p><p>Un autre aspect de cette carte est, que vous avez peut-être été en conflit avec quelqu'un et que cette personne offre maintenant la réconciliation. Votre première réaction est peut-être de garder vos distances. Mais prenez votre temps et vous constaterez que vous voulez aussi vous réconcilier avec cette personne. Acceptez l'offre de paix. Cela vous fera du bien de vous débarrasser d'une partie de cette négativité.</p><p>Si vous voulez tomber enceinte, la carte \"Valet de Coupe\" est un signe très positif.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Now is the time to listen to your heart and not your head. You may be overflown with love for your partner and feeling very romantic … do not hesitate, act upon it. Organize a weekend getaway to that special place, buy that present of which only you two really know what it means, surprise him/her, shower him/her with love. Whatever comes to mind, no matter how corny, act upon it, don’t let your brain hold you back, be truly romantic now!</p><p>If you are having a quarrel, don’t let your mind rule but follow your heart and make up. Life is too short to spend it fighting with your loved one.</p><p>If you are single, be prepared to fall victim to total infatuation and enjoy it while it lasts!</p>",
                        [GuidanceFields.WORK] : "<p>Getting the Page of Cups means there is hope, a ray of sunshine behind the clouds. Even if you realize now that you are truly unhappy at work, even if you really are in a rut, this card tells you that now the time has come to look at your dreams and find a way to make them come true. No matter how difficult your situation, there’s always choices and possibilities. Look at your inner child and listen carefully to what it has to tell you, it will probably point you in the right direction.</p><p>If you are looking for a job, now is the time to give your dreams another chance. Reach for the stars, there’s no limits to what you might achieve!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>It is quite possible that you have formed some spiritual habits throughout the years, certain ways of approaching things you have gotten used to. No matter how comfortable you feel with those habits, now is the time to throw them out the window and go look for new ones. Sit down and listen to your intuition, your gut feeling, allow yourself to be inspired by them and see where they lead you. There’s a whole spiritual universe out there for you to dive into, feel free to explore it!</p>",
                    }
                },
                sp:{
                    name:"Niño de Copas", subtitle: "Sensible",
                    court: "soto",
                    suit: "Copas",
                    element: ["🜄 - agua", "🜃 - tierra" ],
                    theme: "mirando lo que sientes sin prejuicios",    
                    description: "El Soto de Copas se trata de mirar con la mente abierta a lo que sientes y nos recuerda que podemos mirarlo con la mente abierta de un niño",
                    questions: ["¿(Puedes) Te permites sentir lo que sientes?", "¿Te atreves a seguir tus sentimientos, ‘sólo' de esa apertura infantil?", "¿Cómo te sientes?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es la alegría, la espontaneidad, sino también acerca de la reconciliación y darle a la gente una segunda oportunidad. La paja de copas le dice que ahora es un buen momento para disfrutar de la vida en un nivel simple, ser espontáneo, no para pensar las cosas demasiado profundas, pero sólo tienes que seguir sus instintos y simplemente disfrutar de lo que viene a la mente. Da a su niño interior algo de espacio para hacer lo que quiera, usted encontrará que este fondo  se relajará y le dará energía positiva. Otro aspecto de esta tarjeta es que usted puede estar en un conflicto con alguien y ahora esta persona está ofreciendo de compensar, de conciliar. Tal vez su primer reflejo es mantener su distancia, pero si usted se permite algún tiempo usted puede darse cuenta de que usted también quiere hacer las paces con esta persona. Acepta esta oferta de paz, lo hará usted un mundo de bueno dejar de lado un poco de negatividad.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Kind van Bekers", subtitle: "Gevoelige",
                    court : "Page",
                    suit : "Bekers",
                    element : [ "🜄 - water", "🜃 - aarde" ],
                    theme : "onbevooroordeeld kijken naar wat je voelt",    
                    description : "De Page van Bekers gaat over onbevooroordeeld kijken naar wat je voelt en wijst ons er op dat we daar met kinderlijke onbevangenheid naar mogen kijken.",
                    questions : [ "Mag jij van jezelf voelen wat je voelt?", "Durf jij je gevoel te volgen, 'gewoon' vanuit die kinderlijke onbevangenheid?", "Hoe voelt dat?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart symboliseert speelsheid en spontaniteit maar ook verzoening en mensen nog een kans geven.</p><p>De Page van Bekers vertelt je dat het op dit moment goed is om op een eenvoudige manier van het leven te genieten. Je mag best spontaan zijn, mogelijk zelfs een beetje kinderlijk. Denk niet teveel na over de dingen maar volg je intuïtie en geniet gewoon van alles wat in je opkomt. Geef je innerlijke kind alle ruimte om te doen waar het zin in heeft. Je zal merken dat je op deze manier totaal  ontspannen en een heleboel positieve energie creëren en opnemen.</p><p>De tweede betekenis van deze kaart wijst je erop dat je mogelijk al enige tijd een conflict hebt met iemand. Die ander komt nu naar je toe om het goed te maken, hij/zij verlangt naar een verzoening. Misschien is je eerste reactie om afstand te houden en hier niet op in te gaan. Geef jezelf toch wat tijd om over de situatie na te denken. Best mogelijk dat je dan beseft dat je het toch goed wil maken, dat je die ander terug in je leven wil. Aanvaard dit vredesaanbod met beide handen, het zal je echt goed doen om alle negativiteit die om de situatie heen hing los te laten. De Page van Bekers trekken is ook een positief signaal als je graag zwanger wil worden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment is het belangrijk dat je naar je gevoel luistert en niet naar je verstand. Als je je hart voelt overlopen van liefde voor je partner, als je een romantische bui hebt ... aarzel dan niet, ga aan de slag. Organiseer een weekend om samen te ontspannen en te genieten van mekaar, koop een geschenk waarvan enkel je partner echt weet wat je ermee bedoelt. Gebruik je fantasie en je hart om je partner te verrassen en te verwennen. Maak je niet druk of je misschien sentimenteel overkomt, laat je verstand niet in de weg komen, volg je gevoel en wees echt romantisch voor een keer!</p><p>Als je op dit moment een conflict hebt met je partner probeer het dan zo snel mogelijk bij te leggen. Het leven is te kort om ook maar een minuut te besteden aan ruzie!</p><p>Als je op zoek bent naar een geliefde bereid je er dan op voor dat je binnenkort waarschijnlijk iemand zal ontmoeten waar je smoorverliefd op wordt. Ga helemaal op in dit gevoel en geniet ervan zolang als het duurt.</p>",
                        [GuidanceFields.WORK] : "<p>Als je de Page van Bekers krijgt in verband met je werksituatie dan betekent dit dat de zon binnenkort zal tevoorschijn komen van achter de wolken, er is hoop. Zelfs al ben je op dit moment ongelukkig in je baan, heb je het gevoel dat je in een geestdodende sleur zit, dan nog vertelt deze kaart je dat het nu het juiste moment is om je dromen en verlangens eens goed te bekijken en een manier te zoeken om ze waar te maken. Het maakt niet uit hoe moeilijk je situatie nu is, je hebt altijd keuzes en mogelijkheden. Ga eens te rade bij je innerlijke kind en luister zorgvuldig naar wat het te vertellen heeft, op die manier zal je ontdekken welke richting de juiste is voor jou.</p><p>Als je op zoek bent naar werk dan kan je nu best je dromen nog eens een kans geven, ook al heb je ze misschien al lang opgegeven. Herbekijk ze, reik naar de sterren, geloof in de mogelijkheid dat jij nu het onmogelijke kan waarmaken!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Waarschijnlijk heb je doorheen de jaren een aantal spirituele gewoontes gekregen, heb je ook een bepaalde manier om naar de dingen te kijken waar je helemaal aan gewend bent. Vergeet nu even hoe comfortabel je je voelt bij het oude vertrouwde. Zet ramen en deuren open en gooi alles buiten wat met je spirituele verleden te maken heeft. De tijd is gekomen om nieuwe dingen te ontdekken, nieuwe rituelen te vinden. Luister naar je intuïtie, je innerlijke kern, laat je inspireren door je ware zelf en kijk waar je uitkomt. Je hebt een oneindig grote spirituele vijver om in te vissen, voel je vrij en ga ervoor!</p>",
                    }
                },
                de : {
                    name: "Kind der Kelche", subtitle: "Empfindlich",
                    court : "Bube",
                    suit : "Kelche",
                    element : [ "🜄 - Wasser", "🜃 - Erde" ],
                    theme : "Schauen, was man fühlt, ohne Vorurteile.",    
                    description : "Der Bube der Kelche spricht darüber, wie wir mit einem offenen und kindlichen Geist betrachten, was wir fühlen.",
                    questions : [ "Können Sie wirklich spüren, wie Sie sich fühlen?", "Trauen Sie sich, Ihren Gefühlen zu folgen? \"nur\" aufgrund dieser kindlichen Aufgeschlossenheit?", "Was bewirkt das in Ihnen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für Spielfreude und Spontaneität, aber auch für Versöhnung, sie gibt Menschen eine zweite Chance.</p><p>Die „Bube der Kelche“-Karte sagt Ihnen, dass jetzt der richtige Zeitpunkt gekommen ist, um das Leben auf einer einfacheren Ebene zu genießen, um spontan, und vielleicht sogar ein bisschen kindlich zu sein. Grübeln Sie nicht über die Dinge nach, sondern folgen Sie einfach Ihrem Instinkt und genießen Sie, was Ihnen in den Sinn kommt. Geben Sie Ihrem inneren Kind den Raum, das zu tun, was es will. Sie werden feststellen, dass Sie dies vollkommen entspannt und Sie viel positive Energie schöpfen können.</p><p>Ein weiterer Aspekt dieser Karte ist, dass Sie sich möglicherweise mit jemandem in einem Konflikt befunden haben, und dass diese Person jetzt eine Versöhnung anbietet. Vielleicht ist Ihre erste Reaktion, Distanz zu wahren. Doch lassen Sie sich etwas Zeit, dann werden Sie nämlich feststellen, dass auch Sie sich mit dieser Person versöhnen möchten. Nehmen Sie das Friedensangebot an. Es wird Ihnen sehr gut tun, etwas von dieser Negativität loszulassen.</p><p>Wenn Sie schwanger werden wollen ist die „Bube der Kelche“-Karte ein sehr positives Zeichen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Jetzt ist die Zeit um auf Ihrem Herz zu hören und nicht auf dem Vernunft. Sie können überflogen sein mit Liebe und sehr romantischen Gefühlen für Ihren Partner … Zögern Sie nicht, dementsprechend zu reagieren. Organisieren Sie ein Wochenende in diesem für Ihr beide besonderen Ort, kauf das Geschenk, von dem nur Ihr beide wirklich weißt was es bedeutet, überraschen Sie ihn / sie, überfließe ihn / sie mit Liebe. Was auch immer in den Sinn kommt, egal wie blöd, reagieren Sie darauf, lassen Sie Sich nicht von Ihrem Vernunft zurück halten, werden Sie jetzt wirklich romantisch!</p><p>Wenn Sie gerade in einem Streit sind, folgen Sie Ihrem Herzen. Das Leben ist zu kurz, um es mit kämpfen mit Ihrem Liebsten zu verbringen.</p><p>Wenn Sie alleine sind, dann sei bereit Sich zu übergeben an völlige Vernarrtheit. Genießen Sie es so lange es dauert!</p>",
                        [GuidanceFields.WORK] : "<p>Ziehen Sie der Bube der Kelche, dann bedeutet das, es gibt Hoffnung, einen Sonnenstrahl hinter den Wolken. Auch wenn Sie jetzt wissen, dass Sie bei der Arbeit wirklich unzufrieden sind, auch wenn Sie wirklich in einer Furche liegen sagt Ihnen diese Karte, dass jetzt die Zeit gekommen ist, um Ihre Träume zu folgen und einen Weg zu finden, um sie zu verwirklichen. Egal, wie schwierig Ihre Situation sein mag, es gibt immer Entscheidungen und Möglichkeiten. Schauen Sie auf Ihr inneres Kind und hören Sie genau zu, was es Ihnen zu sagen hat. Es wird Ihnen wahrscheinlich die richtige Richtung weisen.</p><p>Wenn Sie gerade einen Job suchen ist jetzt die Zeit, um Ihre Träume eine weitere Chance zu geben. Greife nach den Sternen, es gibt keine Grenzen für was Sie vielleicht zu erreichen vermögen!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Es ist durchaus möglich, dass Sie im Laufe der Jahre einige spirituelle Gewohnheiten gebildet haben, eine bestimmte Art und Weise Dinge zu betrachten, an denen Sie Sich vertraut haben. Egal, wie gut Sie sich mit diesen Gewohnheiten fühlen, jetzt ist die Zeit, um sie aus dem Fenster zu werfen und sich neue zu suchen. Setzen Sie sich hin und hören Sie auf Ihre Intuition, Ihr Bauchgefühl. Lassen Sie Sich davon inspirieren und sehen Sie mal wo sie Sie hinführen. Es gibt ein riesiges spirituelles Universum da draußen zum Untertauchen und um näher zu erforschen!</p>",
                    }
                }
            },
            {
                filename: "47",
                numerology : 12,
                en : {
                    name: "Sage of Cups", subtitle: "Regenerator",
                    suit : "Cups",
                    court : "Knight",
                    element : [ "🜄 - water", "🜁 - air" ],
                    theme : "showing your emotions to the world",    
                    description : "Knight of Cups talks about showing our feelings to the world around us.",
                    questions : [ "What do you feel?", "Are you showing what you feel?", "Can you go with the flow?", "Are you capable of showing your real emotions?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>When you draw this card it is very likely that you will be getting some very good news rather soon. The Knight of Cups also tells you that you are entering a period of simple happiness and enjoyment. Things are generally going well for you and you are feeling energetic and active. There is no stress or conflict, just peace and equilibrium. You are enjoying a loving atmosphere everywhere you go. Possibly a new love will come into your life or maybe you will meet someone who will become a good and reliable friend. These are verygoodtimes, enjoy!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you have questions about your love life, the Knight of Cups is a very positive card to get. It tells you that you and your partner are totally committed and that you may even be ready to take the relationship to the next level (anyone hear those wedding bells?). Even if you are not ready yet for this big a step, you will be enjoying a lot of loving feelings coming your way. Reciprocate them, be open about how you feel and you will make your partner very happy!</p><p>If you are single, now may be the time when you meet someone who really blows you away, who makes a deep and lasting impression on you. Do not hesitate, go for it, the timing is perfect!</p>",
                        [GuidanceFields.WORK] : "<p>At work everything is likely to go very well, partly because right now you feel relaxed and positive. You also feel very creative, your mind is full of ideas and possibilities. Creativity is a good thing, no doubt about that. But the Knight of Cups warns you that true creativity is based in reality. No matter how wonderful an idea may seem when you dream it up, it still has to be executed and proven successful. Make sure you devote a good part of your energy towards realizing what you have fantasized about.</p><p>Same goes for those on the lookout for a job: thinking about what would be the perfect job for you makes sense, but don’t forget you will also have to go out and look for it.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Knight of Cups can be seen as a spiritual messenger, bringing you an invitation. The card tells you to be open to messages of all kinds. You may meet someone who tells you about a certain spiritual concept, but the message may also be less obvious. Keep all your senses open, pay attention to the smallest of signs, the next step of your spiritual quest will be found among them. Once you received ‘your’ sign, don’t linger upon it for too long, take action. You spiritual growth needs to be firmly rooted in reality.</p>",
                    }
                },
                fr : {
                    name : "Sage des Coupes", subtitle: "Guérisseur",
                    suit : "Coupes",
                    court : "Cavalier",
                    element :  ["🜄 - eau", "🜁 - air" ],
                    theme : "s’exprimer avec émotions",    
                    description : "Le Cavalier des Coupes, nous parle de montrer ce que l’on ressent et de montrer nos émotions au monde qui nous entoure.",
                    questions : ["Que ressentez-vous?", "Cela, le montrez- vous?", "Pouvez-vous suivre le courant?", "Êtes-vous capable de montrer vos émotions telles qu'elles sont?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Si vous tirez cette carte, il est très probable que vous recevrez bientôt de très bonnes nouvelles. Le \"Chevalier de Coupe\" vous dit aussi que vous entrez dans une période de bonheur et de joie simples. Les choses vont généralement bien pour vous et vous vous sentez énergique et actif. Il n'y a ni stress ni conflit, seulement calme et équilibre. Vous profitez d'une atmosphère d'amour partout où vous allez. C'est le moment idéal pour rencontrer des gens qui peuvent devenir de bons et fiables amis. Maintenant les temps sont très bons, amusez-vous bien !</p><p>Un avertissement : Même si vous êtes heureux, ne fermez pas les yeux sur les problèmes des autres. Ils ne disparaîtront pas juste parce que vous vous sentez mieux.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you have questions about your love life, the Knight of Cups is a very positive card to get. It tells you that you and your partner are totally committed and that you may even be ready to take the relationship to the next level (anyone hear those wedding bells?). Even if you are not ready yet for this big a step, you will be enjoying a lot of loving feelings coming your way. Reciprocate them, be open about how you feel and you will make your partner very happy!</p><p>If you are single, now may be the time when you meet someone who really blows you away, who makes a deep and lasting impression on you. Do not hesitate, go for it, the timing is perfect!</p>",
                        [GuidanceFields.WORK] : "<p>At work everything is likely to go very well, partly because right now you feel relaxed and positive. You also feel very creative, your mind is full of ideas and possibilities. Creativity is a good thing, no doubt about that. But the Knight of Cups warns you that true creativity is based in reality. No matter how wonderful an idea may seem when you dream it up, it still has to be executed and proven successful. Make sure you devote a good part of your energy towards realizing what you have fantasized about.</p><p>Same goes for those on the lookout for a job: thinking about what would be the perfect job for you makes sense, but don’t forget you will also have to go out and look for it.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Knight of Cups can be seen as a spiritual messenger, bringing you an invitation. The card tells you to be open to messages of all kinds. You may meet someone who tells you about a certain spiritual concept, but the message may also be less obvious. Keep all your senses open, pay attention to the smallest of signs, the next step of your spiritual quest will be found among them. Once you received ‘your’ sign, don’t linger upon it for too long, take action. You spiritual growth needs to be firmly rooted in reality.</p>",
                    }
                },
                sp:{
                    name:"Sabio de Copas", subtitle: "Sanador",
                    suit: "Copas",
                    court: "Caballo",
                    element: ["🜄 - agua", "🜁 - aire" ],
                    theme: "Salir con sentimiento",    
                    description: "El Caballo de Copas trata de mostrar lo que sientes y nos recuerda que podemos mostrar nuestras emociones al mundo que nos rodea",
                    questions: ["¿Qué sientes?", "¿Muestras eso?", "¿Puedes seguir la corriente?", "¿Eres capaz de mostrar tus emociones como son?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Al sacando esta tarjeta es muy probable que usted va a obtener muy buenas noticias en lugar pronto. El Caballero de Copas también le indica que está entrando en un período de felicidad simple y de disfrutarle. Las cosas por lo general van bien para usted y usted se siente con energía y activa. No hay tensión o conflicto, sólo la paz y el equilibrio. Usted está disfrutando de un ambiente de amor donde quiera que vaya. Posiblemente un nuevo amor vendrá a tu vida o tal vez usted se encontrará a alguien que va a ser un amigo bueno y fiable. Estos son muy buenos tiempos, disfrutarlos!</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Wijze van Bekers", subtitle: "Genezer",
                    suit : "Bekers",
                    court : "Ridder",
                    element : [ "🜄 - water", "🜁 - lucht" ],
                    theme : "met gevoel naar buiten treden",    
                    description : "De Ridder van Bekers gaat over laten zien wat je voelt en wijst ons er op dat we onze emoties aan de wereld om ons heen mogen tonen.",
                    questions : [ "Wat voel jij?", "Toon je dat?", "Kun jij 'meegaan op de stroom'?", "Ben jij in staat om je emoties te laten zien zoals ze zijn?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Als je deze kaart krijgt dan is het heel waarschijnlijk dat je binnenkort goed nieuws zal krijgen. De Ridder van Bekers vertelt je dat je binnenkort aan een periode in je leven begint waarbij je vooral simpele vreugde en genieten zal ervaren. Over het algemeen gaat alles goed voor jou en voel je je energiek en actief. Je hebt geen last van stress of conflicten, je voelt vooral vrede en evenwicht. Overal waar je komt is er een liefdevolle sfeer. Dit is een ideale tijd om nieuwe mensen in je leven uit te nodigen die mettertijd goede en betrouwbare vrienden kunnen worden. Dit zijn goede tijden, geniet ervan!</p><p>Een waarschuwing: ook al ben jij op dit moment heel gelukkig, houd er toch rekening mee dat anderen wel problemen kunnen hebben en besteed daar voldoende aandacht aan. Complicaties en conflicten in de levens van je vrienden en familie verdwijnen niet gewoon omdat jij je momenteel heel goed voelt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je met twijfels of vragen zit in verband met je relatie dan is de Ridder van Bekers een heel positieve kaart die je vertelt dat jij en je partner helemaal aan mekaar toegewijd zijn en dat jullie er mogelijk zelfs klaar voor zijn om een volgende stap te zetten die kan gaan van samenwonen over een verloving tot een huwelijk. En zelfs als je nog niet helemaal klaar bent om zo’n stap te zetten dan zal je toch merken dan dit een liefdevolle tijd is, je krijgt veel warmte en aandacht van je partner. Beantwoord dit met je eigen liefdevolle gebaren, je zal je geliefde heel gelukkig maken!</p><p>Als je alleen bent dan is het goed mogelijk dat je nu iemand zal ontmoeten die je echt van je sokken blaast en een diepe en blijvende indruk op je maakt. Aarzel niet, ga ervoor, de timing is perfect!</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk verloopt alles waarschijnlijk heel goed als je deze kaart krijgt. Dit is deels een gevolg van het feit dat jij er behoorlijk ontspannen en positief bij loopt. Je voelt je ook heel creatief, je hoofd zit vol ideeën en mogelijkheden. Ook al is creativiteit een positieve eigenschap, toch waarschuwt deze kaart je ervoor dat echt zinvolle creativiteit ook een basis in de realiteit heeft. Het maakt niet uit hoe fantastisch een idee kan lijken als je erover aan het mijmeren bent, je moet het nog altijd eerst uitproberen èn het moet ook nog lukken. Zorg ervoor dat je het grootste deel van je energie gebruikt om je dromen in realiteit om te zetten, niet enkel om ze te dromen. Hetzelfde geldt als je op zoek bent naar een baan: het is zeker zinvol om erover na te denken wat de ideale job voor jou zou zijn, maar je moet er natuurlijk ook op uittrekken om die baan te vinden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Ridder van Bekers kan gezien worden als een spirituele boodschapper die je een uitnodiging brengt. De kaart vraagt je om nu open te staan voor alle mogelijke signalen en berichten. Misschien ontmoet je iemand die je meer vertelt over een bepaald spiritueel concept, maar het bericht kan ook minder opvallend zijn. Houd je geest open, wees alert op alle mogelijke signalen want de boodschap die je krijgt, kondigt de volgende stap van je spirituele reis aan. Eens je het signaal ontvangen hebt, sta er dan niet te lang bij stil maar ga er actief mee aan de slag. Het is immers belangrijk dat je spirituele groei stevig gegrond is in de realiteit.</p>",
                    }
                },
                de : {
                    name: "Weisen der Kelche", subtitle: "Heiler",
                    suit : "Kelche",
                    court : "Ritter",
                    element : [ "🜄 - Wasser", "🜁 - Luft" ],
                    theme : "Sich mit Emotionen ausdrücken",    
                    description : "Der Ritter der Kelche sagt uns zu zeigen, was wir fühlen und unsere Emotionen der Welt um uns herum zu zeigen.",
                    questions : [ "Was empfinden Sie?", "Das, zeigen Sie es?", "Können Sie mit dem Strom schwimmen?", "Können Sie Ihre Emotionen so zeigen, wie sie sind?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Wenn Sie diese Karte ziehen ist es sehr wahrscheinlich, dass Sie in Kürze einige sehr gute Nachrichten erhalten werden. Der „Ritter der Kelche“ erzählt Ihnen auch, dass Sie in eine Periode des einfachen Glücks und der Freude eintreten. Die Dinge laufen in der Regel gut für Sie, und Sie fühlen sich energiegeladen und aktiv. Es gibt keinen Stress oder Konflikte, nur Ruhe und Ausgeglichenheit. Sie genießen eine liebevolle Atmosphäre, wo immer Sie auch hinkommen. Jetzt ist eine ausgezeichnete Zeit, Menschen zu begegnen, die gute und zuverlässige Freunde werden können. Jetzt sind die Zeiten sehr gut, viel Spaß!</p><p>Eine Warnung: Egal, wie glücklich Sie auch sind, schließen Sie nicht die Augen vor den Problemen Anderer.  Sie werden nicht allein deshalb verschwinden, weil Sie sich jetzt ganz wohl fühlen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie Fragen zu Ihrem Liebesleben haben, dann ist der Ritter der Schale eine sehr positive Karte. Sie sagt Ihnen, dass Sie und Ihr Partner völlig verbunden sind und dass Sie vielleicht sogar bereit sind, die Beziehung auf die nächste Stufe zu bringen (hört man bereits die Hochzeitsglöckchen?). Sogar wenn Sie noch nicht so weit sind für diesen großen Schritt, werden eine Menge liebevolle Gefühle auf Sie zukommen und Sie werden sie genießen. Öffnen Sie Sich zu ihm beantworten Sie sie und Sie und Ihren Partner werden sehr glücklich!</p><p>Wenn Sie alleine sind, kann jetzt die Zeit sein jemanden zu treffen, der Sie wirklich umbläst und einen tiefen und bleibenden Eindruck auf Sie macht. Zögern Sie nicht aber gehen Sie dafür, die Zeitwahl ist perfekt!</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit läuft im Moment wahrscheinlich alles sehr gut, zum Teil, weil Sie Sich jetzt entspannt und positiv fühlen. Sie fühlen sich auch sehr kreativ, Ihr Geist ist voll von Ideen und Möglichkeiten. Kreativität ist eine gute Sache, daran besteht kein Zweifel. Aber der Ritter der Schale warnt Sie, dass wahre Kreativität basiert ist auf der Realität. Unabhängig davon, wie wunderbar eine Idee sein mag, wenn sie im Traum existiert, muss sie immer noch ausgeführt und bewährt werden. Stellen Sie sicher, dass das größte Teil Ihrer Energie gespendet wird an die Realisierung von dem was Sie geträumt haben.</p><p>Das Gleiche gilt auch für die, die auf der Suche nach einem Job sind: denken Sie darüber nach, was der perfekte Job für Sie wäre, aber vergessen Sie nicht auch raus zu gehen und ihn zu suchen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Der Ritter der Schäle kann als ein spiritueller Botschafter gesehen werden, der Sie eine Einladung bringt. Die Karte sagt, dass Sie offen für Nachrichten aller Art sein müssen. Sie können jemanden begegnen, der Sie über ein bestimmtes spirituelles Konzept erzählt, aber die Botschaft kann auch weniger offensichtlich sein. Behalten Sie alle Ihre Sinne offen, achten Sie auf das kleinste Zeichen, der nächste Schritt Ihrer spirituellen Suche wird sich darunter befinden. Sobald Sie \"Ihr Zeichen\" erhalten, sollten Sie nicht zu lange verweilen und Maßnahmen ergreifen. Ihr spiritueller Wachstum muss in der Realität verankert werden.</p>",
                    }
                }
            },
            {
                filename: "48",
                numerology : 13,
                en : {
                    name: "Woman of Cups", subtitle: "Rejoicer",
                    court : "Queen",
                    suit : "Cups",                    
                    element : "🜄 water",
                    theme : "taking care of your own feelings",    
                    description : "Queen of Cups talks about paying attention to our feelings. Make way for your feelings.",
                    questions : [ "Are you feeling a lot?", "Can you pay attention to what is going on around you?", "Are you paying too much attention to your feelings?", "Are you sensible to your surroundings?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about love and compassion and taking care of yourself and the people around you. Right now you are empathic, sensitive and intuitive and you will feel these capacities getting even stronger and more powerful within you. Be sure to not only use these qualities to help others but also treat yourself with love and compassion, you deserve it.</p><p>The Queen of Cups sometimes indicates that there is a woman close to you who can really be supportive in a difficult situation. Don’t hesitate to accept this support, you will become a stronger and wiser person for it. </p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Queen of Cups tells you that right now you are very strong and supportive on an emotional level. You are open to other people’s feelings and you can truly sympathize. Your own emotions tend to be strong and deeply felt too. This state of mind can mean that you are feeling very close to your partner right now, you thoroughly enjoy the feelings you have for eachother.Just take care you don’t wallow in them, life is about more than just feeling.</p><p>If you are single now is a good time to go out and meet new people because you will probably encounter a prospective partner. Once you do, be careful not to get carried away, try to keep paying attention to other parts of your life too.</p>",
                        [GuidanceFields.WORK] : "<p>At work things may seem to be rather quiet, you are going with the flow. But maybe this doesn’t apply to some of your coworkers. The Queen of Cups invites you to be sensitive to the emotions of people around you. Take some time to listen to that quiet colleague’s story, to ask how things are when you are on a coffee break, to generally be open to whatever may burden the people around you. Try to create a safe haven for your colleagues by being open and receptive.</p><p>This card can also indicate that possibly you are in a bit of a rut. Keep your eyes open because your own “Queen of Cups” may be close by and wants to lend a helping hand.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Queen of Cups invites you to get in touch with your emotions, with your empathy. If you allow yourself to do so you will notice that it becomes fairly easy to get in touch with the universe, to feel oneness with all beings. Now is the perfect time to discover and develop every spiritual ability you already have or aspire. Be open to life’s energy, let it freely flow through you and awaken your senses. Share your experiences with others, offer them the benefit of your abundance. Now would be a good time to book a Reiki session, you may even feel inspired to further pursue it.</p>",
                    }
                },
                fr : {
                    name : "Femme des Coupes", subtitle: "Joyeux",
                    court : "Reine",
                    suit : "Coupes",
                    element :  "🜄 eau",
                    theme : "Prendre soin de ce que l’on ressent",    
                    description : "La Reine des Coupes, nous parle de faire attention à ce que l'on ressent. Donnez de l'espace à vos sentiments.",
                    questions : ["Ressentez-vous beaucoup?", "Voyez-vous ce qui se passe autour de vous?", "Faites-vous (trop) attention à ce que vous ressentez?", "Êtes-vous sensible à votre environnement?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente l'amour et la compassion. C'est aussi prendre soin de soi et des gens autour de soi. Vous vous sentez maintenant empathique, sensible et intuitif. Vous sentez que ces capacités en vous continuent de grandir et de se renforcer. En ce moment, vous vous entendez très bien avec les autres sur le plan émotionnel. C'est facile pour vous d'aider. Néanmoins, il est tout aussi important de vous traiter avec amour et compassion. Vous le méritiez autant que n'importe qui d'autre.</p><p>Cette carte vous dit aussi de faire confiance à vos tripes, à votre intuition. Si vous n'aimez pas quelqu'un ou quelque chose, c'est probablement vrai.</p><p>La \"Reine de Coupe\" dit parfois qu'il y a une femme près de chez vous qui pourrait être très utile dans les situations difficiles. N'hésitez pas à accepter ce soutien, vous deviendrez une personne plus forte et plus intelligente.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Queen of Cups tells you that right now you are very strong and supportive on an emotional level. You are open to other people’s feelings and you can truly sympathize. Your own emotions tend to be strong and deeply felt too. This state of mind can mean that you are feeling very close to your partner right now, you thoroughly enjoy the feelings you have for eachother.Just take care you don’t wallow in them, life is about more than just feeling.</p><p>If you are single now is a good time to go out and meet new people because you will probably encounter a prospective partner. Once you do, be careful not to get carried away, try to keep paying attention to other parts of your life too.</p>",
                        [GuidanceFields.WORK] : "<p>At work things may seem to be rather quiet, you are going with the flow. But maybe this doesn’t apply to some of your coworkers. The Queen of Cups invites you to be sensitive to the emotions of people around you. Take some time to listen to that quiet colleague’s story, to ask how things are when you are on a coffee break, to generally be open to whatever may burden the people around you. Try to create a safe haven for your colleagues by being open and receptive.</p><p>This card can also indicate that possibly you are in a bit of a rut. Keep your eyes open because your own “Queen of Cups” may be close by and wants to lend a helping hand.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Queen of Cups invites you to get in touch with your emotions, with your empathy. If you allow yourself to do so you will notice that it becomes fairly easy to get in touch with the universe, to feel oneness with all beings. Now is the perfect time to discover and develop every spiritual ability you already have or aspire. Be open to life’s energy, let it freely flow through you and awaken your senses. Share your experiences with others, offer them the benefit of your abundance. Now would be a good time to book a Reiki session, you may even feel inspired to further pursue it.</p>",
                    }
                },
                sp:{
                    name:"Mujer de Copas", subtitle: "Alegre",
                    court: "Reina",
                    suit: "Copas",
                    element: "🜄 agua",
                    theme: "Cuida lo que sientes",    
                    description: "La Reina de Copas se trata de prestar atención a lo que sientes. Dale espacio a tus sentimientos",
                    questions: ["¿Sientes mucho?", "¿Ves lo que pasa a tu alrededor?", "¿Pones (demasiada) atención a lo que sientes?", "¿Eres sensible a las atmósferas?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es sobre el amor y la compasión y el cuidado de uno mismo y las personas que le rodean. En este momento usted es empático, sensible e intuitiva y se sentirá estas capacidades para conseguir aún más fuerte y más poderoso dentro de usted. Asegúrese de no sólo utilizar estas cualidades para ayudar a los demás, sino también disfrutar de amor y compasión,  usted lo merece. La Reina de Copas a veces indica que hay una mujer cerca de usted que realmente puede ser de apoyo en una situación difícil. No dude en aceptar este apoyo, que se convertirá en una persona más fuerte y más sabio por ello.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Vrouw van Bekers", subtitle: "Vreugdevolle",
                    court : "Koningin",
                    suit : "Bekers",                    
                    element : "🜄 water",
                    theme : "zorg dragen voor wat je voelt",    
                    description : "De Koningin van Bekers gaat over aandacht schenken aan wat je voelt. Geef je gevoel de ruimte.",
                    questions : [ "Voel jij veel?", "Kun je oog hebben voor wat er om je heen gaande is?", "Schenk je (te) veel aandacht aan wat je voelt?", "Ben je gevoelig voor sferen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart heeft het over liefde en mededogen. De boodschap is ook om goed voor jezelf en de mensen om je heen te zorgen. Op dit moment bent je sterk empathisch, intuïtief en gevoelig. Deze eigenschappen zullen steeds sterker en intenser worden. Je zal merken dat je heel goed contact kan maken met anderen op het emotionele vlak. Daardoor wordt het ook gemakkelijk voor je om hen te begrijpen en te helpen. Toch blijft het even belangrijk om ook jezelf te benaderen met liefde en mededogen, je verdient dit net zoveel als anderen.</p><p>Deze kaart wijst er ook op dat het belangrijk is om op je gevoel te vertrouwen, je intuïtie. Als iets of iemand niet goed voelt, dan klopt je gevoel waarschijnlijk. De Koningin van Bekers vertelt je soms ook dat er een wijze vrouw in je omgeving is waarop je kan vertrouwen, die je door een moeilijke situatie heen kan helpen. Aarzel niet om haar steun te aanvaarden, je zal er sterker en geaarder door worden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Koningin van Bekers vertelt je dat je op dit moment heel sterk en ondersteunend bent op emotioneel vlak. Je staat open voor de gevoelens van anderen en je kan ook echt intens meeleven. Je eigen emoties zijn op dit moment ook diep en hevig. Dit kan betekenen dat je je heel dicht bij je partner voelt, dat je echt kan genieten van de gevoelens die je hebt voor die ander. Genieten is natuurlijk positief maar let ervoor op dat je je niet laat overspoelen door je emoties, het leven is meer dan enkel voelen.</p><p>Als je alleen bent is het een goed idee om erop uit te trekken en nieuwe mensen te ontmoeten want je zal waarschijnlijk een potentiële partner vinden. Als dat gebeurt, let er dan wel voor op dat je je niet teveel laat meeslepen door je nieuwe geluk. Besteed zeker voldoende aandacht aan alle andere aspecten van je leven.</p>",
                        [GuidanceFields.WORK] : "<p>Op het gebied van werk is het nogal rustig op dit moment, je laat je meevoeren door de stroom. Maar misschien geldt dat niet voor sommige van je collega’s. De Koningin van Bekers nodigt je uit om je gevoelig op te stellen voor de emoties van de mensen om je heen. Neem eens de tijd om te luisteren naar wat die stille collega te vertellen heeft, vraag tijdens je koffiepauze eens aan iemand hoe het ermee gaat. Stel je algemeen open voor de zorgen van anderen, luister en leef mee. Probeer een veilige haven te creëren voor je medemensen door open en ontvankelijk te zijn. Deze kaart kan er ook op wijzen dat je misschien wat in een sleur zit. Als dat het geval is, stel je dan alert op want er kan zomaar een “Koningin” opduiken die je de helpende hand reikt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Koningin van Bekers nodigt je uit om contact te zoeken met je emoties en je empathie. Als je jezelf dit toestaat dan zal je merken dat het ook gemakkelijker wordt om in verbinding te treden met het universum, om je één te voelen met alles wat leeft. Maak gebruik van dit gevoel van eenheid om allerlei spirituele vaardigheden bij jezelf te ontdekken en te ontwikkelen. Stel je open voor de universele levensenergie, laat die door je heen stromen en je zintuigen wakker schudden. Deel je ervaringen met anderen, laat hen meegenieten van je overvloed. Nu is het een goed moment om een Reikibehandeling te boeken. Mogelijk word je er zelfs door geïnspireerd om zelf met energieën aan de slag te gaan.</p>",
                    }
                },
                de : {
                    name: "Frau der Kelche", subtitle: "Freudig",
                    court : "Königin",
                    suit : "Kelche",                    
                    element : "🜄 Wasser",
                    theme : "Sich darum kümmern, was man fühlt.",    
                    description : "Die Königin der Kelche spricht darüber, darauf zu achten, was man fühlt. Geben Sie Ihren Gefühlen Raum.",
                    questions : [ "Empfinden Sie viel?", "Sehen Sie, was um Sie herum passiert?", "Achten Sie (zu) viel darauf, was Sie empfinden?", "Sind Sie empfindlich für Ihre Umgebung?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für Liebe und Mitgefühl. Es geht auch um die Fürsorge für sich selbst und für die Menschen in Ihrer Umgebung. Jetzt fühlen Sie sich empathisch, einfühlsam und intuitiv. Sie fühlen, dass diese Fähigkeiten in Ihrem Inneren immer noch mehr wachsen und stärker werden. In diesem Augenblick verstehen Sie sich auf  emotionaler Ebene sehr gut mit Anderen. Es fällt Ihnen leicht, Ihnen zu helfen. Dennoch ist es genauso wichtig, sich selbst mit Liebe und Mitgefühl zu behandeln. Sie haben es genauso verdient wie jeder andere auch.</p><p>Diese Karte sagt Ihnen auch, auf Ihr Bauchgefühl, auf Ihre Intuition zu vertrauen. Wenn jemand oder etwas Ihnen nicht gefällt, dann stimmt das wahrscheinlich auch.</p><p>Die „Königin der Kelche“ sagt manchmal, dass es eine Frau in Ihrer Nähe gibt, die Ihnen in schwierigen Situationen sehr behilflich sein kann. Zögern Sie nicht, diese Unterstützung anzunehmen. Sie werden ein stärkerer und klügerer Mensch werden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Die Königin der Kelche sagt Ihnen, dass Sie gerade jetzt sehr stark und unterstützend auf einer emotionalen Ebene sind. Sie sind offen für die Gefühle anderer Menschen und Sie können wirklich mitfühlend sein. Ihre eigenen Emotionen empfinden Sie jetzt auch stark und tief. Dieser Zustand des Geistes kann bedeuten, dass Sie Sich gerade jetzt ganz nahe fühlen zu Ihrem Partner. Sie genießen die tiefen Gefühle, die Sie für einander haben. Schwelgen Sie aber nicht darin, das Leben ist mehr als nur ein Gefühl.</p><p>Wenn Sie alleine sind ist es jetzt ist es eine gute Zeit aus zu gehen und neue Leute kennen zu lernen, weil Sie wahrscheinlich Ihren zukünftigen Partner begegnen werden. Sobald dies geschieht, dann achten Sie darauf nicht davon weggetragen zu werden aber versuchen Sie Ihre Aufmerksamkeit auch auf andere Teile Ihres Lebens zu richten.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit scheint es eben mal ruhig zu sein und Sie gehen mit dem Fluss. Aber vielleicht trifft das nicht zu auf Ihren Mitarbeitern. Die Königin der Kelche lädt Sie ein empfänglich für die Gefühle der Menschen, um Sie herum zu sein. Nehmen Sie Sich die Zeit, um die Geschichte dieses ruhigen Kollegen an zu hören, fragen Sie, wie die Dinge laufen, wenn Sie eine Kaffeepause haben und stellen Sie Sich offen für das was die Menschen um sie herum belastet. Versuchen Sie, einen sicheren Hafen für Ihre Kollegen zu sein, indem Sie Sich offen und empfänglich aufstellen.</p><p>Diese Karte kann auch bedeuten, dass Sie möglicherweise ein wenig in einer Furche sind. Haltet die Augen offen, weil Ihre eigene \"Königin der Schale\" in der Nähe sein kann und Ihnen eine helfende Hand reichen möchte.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Königin der Kelche lädt Sie ein mit Ihren Gefühlen in Kontakt zu kommen, mit Ihrer Empathie. Wenn Sie Sich erlauben, dies zu tun, werden Sie feststellen, dass es ziemlich einfach ist in Kontakt mit dem Universum zu geraten, um sich Eins zu fühlen mit allen Wesen. Jetzt ist die perfekte Zeit um jede geistige Fähigkeit die Sie bereits haben oder anstreben, zu entdecken und zu entwickeln. Seien Sie offen für Lebensenergie, lassen Sie es frei durch Sie fließen und Ihre Sinne wecken. Teilen Sie Ihre Erfahrungen mit anderen, bieten Sie sie den Nutzen Ihrer Hülle und Fülle. Jetzt wäre ein guter Zeitpunkt, um eine Reiki-Sitzung zu buchen. Sie können Sich sogar inspiriert fühlen diese weiter zu verfolgen.</p>",
                    }
                }
            },
            {
                filename: "49",
                numerology : 14,
                en : {
                    name: "Man of Cups", subtitle: "Surfer",
                    court : "King",
                    suit : "Cups",
                    element : [ "🜄 - water", "🜂 - fire" ],
                    theme : "acting from your own feelings",    
                    description : "King of Cups talks about actually handling from your own feelings. Go create.",
                    questions : [ "Are you acting from your own feelings?", "Are you manipulated by others?", "Can you be flexible without diverting from your own course?", "Can you trust your own feelings?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The King of Cups represents a balanced person, someone who is feeling whole, partof the universal energy, main qualities being compassion and kindness.</p><p>This card tells you that now would be a good time to turn to your core, to open yourself up for your own essence, to develop your inner strength and then letting this strength influence your life and that of others by acting upon it. Let people know who you are by showing them with your actions, not by telling them. Only if you can accept yourself for who and what you are, can you accept others.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now empathy comes easy, you sense and understand how other people feel and you can sympathize with them. Because of this attitude you feel very close to everyone around you. Helping others by exchanging feelings and experiences comes easy to you right now.</p><p>If you are having a loving relationship you will feel one with your partner, sharing a level of intimacy you didn’t know existed.</p><p>If you are single and looking for love you are likely to meet someone who really suits your emotional needs. You may feel very close to this person rather quickly, do not let that worry or unsettle you.</p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned you don’t use the intellectual approach right now. You are more open to atmospheres and sensitivities, to the needs and frustrations of others.</p><p>When there are problems or conflicts, you use your intuition to shed light on the situation and help solve it.</p><p>Even though you may not be aware of it, your colleagues really appreciate your efforts and personality. Right now it is you they come to when there’s an issue.</p><p>The King of Cups may also indicate that there’s a male figure at work who is really on your side right now. If you need help or support he is the right person to approach, he will not hesitate to lend a helping hand.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The King of Cups is about being balanced and in order tobe spiritually balanced you need a sense of boundaries. How far can you go in a certain direction and, more importantly, how far do you want to go? This card shows an actively compassionate person, someone who listens to and cares about the feelings of others and wants to help them grow. Still, it is important to set yourself and others some limits in order not to go overboard in your willingness to help. Now is a good time to meditate on these subjects in order to find spiritual serenity.</p>",
                    }
                },
                fr : {
                    name : "L'homme des Coupes", subtitle: "Surfeur",
                    court : "Roi",
                    suit : "Coupes",
                    element :  ["🜄 - eau", "🜂 - feu" ],
                    theme : "Agir activement à partir de son intuition",    
                    description : "Le Roi des Coupes, nous parle d’agir à partir de ses propres sentiments. Les donner forme",
                    questions : ["Agissez-vous en fonction de vos propres sentiments?", "Laissez-vous vous influencer par les autres?", "Pouvez-vous être flexible, sans dévier de votre propre voie?", "Pouvez-vous compter sur vos propres intuitions?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Roi de Coupe\" n'est pas une carte typiquement masculine ou féminine, elle représente simplement une personne équilibrée. Cette carte représente quelqu'un qui se sent complètement entier et fait partie de l'énergie universelle. Ses qualités sont la compassion et la gentillesse.</p><p>La carte vous montre que le moment est venu de retourner à votre cœur pour vous ouvrir à votre véritable être. Essayez de développer vos vérités intérieures et laissez-les influencer votre vie et celle des autres en agissant en conséquence. Faites savoir qui vous est par vos actes, mais pas par vos paroles. Les actes sont plus parlants que les mots. Ce n'est que lorsque vous vous acceptez tel que vous êtes que vous pouvez accepter les autres et interagir honnêtement avec eux.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now empathy comes easy, you sense and understand how other people feel and you can sympathize with them. Because of this attitude you feel very close to everyone around you. Helping others by exchanging feelings and experiences comes easy to you right now.</p><p>If you are having a loving relationship you will feel one with your partner, sharing a level of intimacy you didn’t know existed.</p><p>If you are single and looking for love you are likely to meet someone who really suits your emotional needs. You may feel very close to this person rather quickly, do not let that worry or unsettle you.</p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned you don’t use the intellectual approach right now. You are more open to atmospheres and sensitivities, to the needs and frustrations of others.</p><p>When there are problems or conflicts, you use your intuition to shed light on the situation and help solve it.</p><p>Even though you may not be aware of it, your colleagues really appreciate your efforts and personality. Right now it is you they come to when there’s an issue.</p><p>The King of Cups may also indicate that there’s a male figure at work who is really on your side right now. If you need help or support he is the right person to approach, he will not hesitate to lend a helping hand.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The King of Cups is about being balanced and in order tobe spiritually balanced you need a sense of boundaries. How far can you go in a certain direction and, more importantly, how far do you want to go? This card shows an actively compassionate person, someone who listens to and cares about the feelings of others and wants to help them grow. Still, it is important to set yourself and others some limits in order not to go overboard in your willingness to help. Now is a good time to meditate on these subjects in order to find spiritual serenity.</p>",
                    }
                },
                sp:{
                    name:"Hombre de Copas", subtitle: "Surfer",
                    court: "Rey",
                    suit: "Copas",
                    element: ["🜄 - agua", "🜂 - fuego" ],
                    theme: "Actuar activamente desde tus propios sentimientos",    
                    description: "El Rey de Copas se trata de actuar desde tus propios sentimientos. Dale forma",
                    questions: ["¿Actúas desde tus propios sentimientos?", "¿Dejas que otros te influyan?", "¿Puedes ser flexible, sin salirte de tu propio curso?", "¿Puedes confiar en tus propios sentimientos?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Rey de Copas representa una persona equilibrada, alguien que se siente todo, parte de la energía universal, las principales cualidades son la compasión y la bondad. Esta tarjeta le dice que ahora sería un buen momento para acudir a su núcleo, de abrirse para su propia esencia, para desarrollar su fuerza interior y luego dejar que esta fuerza influir en su vida y la de los demás, actuando sobre ella. Que la gente sepa quién es, mostrándole con sus acciones, no diciéndole. Sólo si  puede aceptarse a su mismo  quién y qué eres, puede aceptar a los demás.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Man van Bekers", subtitle: "Surfer",
                    court : "Koning",
                    suit : "Bekers",
                    element : [ "🜄 - water", "🜂 - vuur" ],
                    theme : "actief handelen vanuit je eigen gevoel",    
                    description : "De Koning van Bekers gaat over daadwerkelijk handelen vanuit je eigen gevoel. Geef het vorm.",
                    questions : [ "Handel jij vanuit je eigen gevoel?", "Laat jij je beïnvloeden door anderen?", "Kun jij flexibel zijn, zonder van je eigen koers te geraken?", "Kun jij op je eigen gevoel varen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Koning van Bekers is geen typisch mannelijke of vrouwelijke kaart, ze vertegenwoordigt vooral een persoon in evenwicht. Deze kaart symboliseert iemand die zich (ge)heel voelt, onderdeel van de universele energie. De belangrijkste kwaliteiten hierbij zijn medeleven en vriendelijkheid.</p><p>Er wordt je gevraagd om je te concentreren op je innerlijke kern, om jezelf open te stellen voor je ware essentie, je ware Zijn. Streef ernaar om je innerlijke waarheden en inzichten te ontwikkelen en er ook naar te handelen. Door dit te doen heb je een grote veranderende invloed op je eigen leven en ook op dat van de mensen om je heen. Toon aan anderen wie je bent door middel van je gedrag, niet enkel door wat je zegt. Daden spreken op dit moment veel luider dan woorden dat kunnen.</p><p>Aanvaard in de eerste plaats jezelf zoals je bent. Pas als je jezelf helemaal accepteert kan je anderen ook aanvaarden zoals ze zijn en kan je echt met hen communiceren.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment vind je het gemakkelijk om empathisch te zijn, je voelt en begrijpt wat in anderen omgaat en je hebt echt sympathie voor hen. Door deze houding voel je je eigenlijk dicht bij iedereen om je heen. Gevoelens en ervaringen uitwisselen en mensen op die manier verder helpen voelt heel natuurlijk aan. In je relatie voel je je nu één met je partner, jullie ervaren een niveau van intimiteit dat je nooit eerder gekend hebt.</p><p>Als je op dit moment op zoek bent naar een geliefde dan zal je waarschijnlijk iemand ontmoeten die op emotioneel vlak perfect bij je aansluit. Je zal je heel snel eigen voelen bij deze persoon, het zal mogelijk zelfs aanvoelen alsof jullie mekaar al veel langer kennen. Maak je niet ongerust, het zit goed!</p>",
                        [GuidanceFields.WORK] : "<p>Wat werk betreft voel je op dit moment niets voor de intellectuele aanpak. Je bent meer open voor sferen en gevoelens, je voelt de noden en frustraties van anderen. Als er problemen of conflicten zijn dan gebruik je je intuïtie om de dingen duidelijker te maken en mensen te helpen om het probleem op te lossen.</p><p>Je bent je er misschien niet van bewust maar je collega’s waarderen je inspanningen en je manier van zijn echt wel. Op dit moment komen ze naar jou toe als er iets op te lossen valt.</p><p>De Koning van Bekers kan erop wijzen dat je op dit moment veel steun mag verwachten van een mannelijke figuur. Hij is degene die je moet benaderen als je een probleem of een vraag hebt, als je steun nodig hebt. Hij zal je zonder aarzelen te hulp komen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Koning van Bekers gaat over evenwicht. Om spiritueel in evenwicht te komen en te blijven is het nodig dat je je bewust bent van grenzen. Hoe ver kan je gaan in een bepaalde richting? En nog belangrijker: hoe ver wil je gaan?</p><p>Deze kaart symboliseert een actief meelevende persoon die luistert naar en geeft om de gevoelens van anderen, die hen wil helpen om te groeien. En net als je veel positieve energie in anderen investeert, komt er ook een boel positieve energie terug naar jou toe. Helpen is eigenlijk een wisselwerking, al merk je dat soms niet meteen.</p><p>Toch is het belangrijk om jezelf en anderen grenzen te stellen zodat je niet gaat overdrijven in je helpersrol. Mediteer geregeld over deze onderwerpen en je zal merken dat je een diep spiritueel evenwicht gaat ervaren.</p>",
                    }
                },
                de : {
                    name: "Man der Kelche", subtitle: "Surfer",
                    court : "König",
                    suit : "Kelche",
                    element : [ "🜄 - Wasser", "🜂 - Feuer" ],
                    theme : "Aktives Handeln auf Basis seiner Intuition", 
                    description : "Der König der Kelche sagt uns, dass wir nach unseren eigenen Gefühlen handeln sollen. Geben Sie ihnen Form.",
                    questions : [ "Handeln Sie nach Ihren eigenen Gefühlen?", "Lassen Sie sich von anderen beeinflussen?", "Können Sie flexibel sein, ohne vom eigenen Weg abzuweichen?", "Können Sie sich auf Ihre eigenen Erkenntnisse verlassen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der „König der Kelche“ ist keine typisch männliche oder weibliche Karte, sie stellt einfach eine ausgewogene Person dar. Diese Karte steht für jemanden, der sich vollkommen ganz und Teil der universellen Energie fühlt. Seine Qualitäten sind Mitgefühl und Freundlichkeit.</p><p>Die Karte zeigt Ihnen, dass jetzt ein guter Zeitpunkt wäre, um zu Ihrem Kern zurückzukehren, um sich für Ihr wahres Wesen zu öffnen. Bemühen Sie sich, Ihre inneren Wahrheiten zu entwickeln und lassen Sie dann diese Wahrheiten, diese Erkenntnisse Ihr Leben und das von anderen beeinflussen, indem Sie danach handeln. Lassen Sie die Menschen durch Ihr Verhalten aber nicht durch Ihre Worte wissen, wer Sie sind. Taten sagen mehr als  Worte. Nur wenn Sie sich selbst akzeptieren so wie Sie sind können Sie Andere akzeptieren und ehrlich mit ihnen umgehen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Gerade jetzt kommt die Empathie einfach. Sie spüren und verstehen, wie andere Menschen fühlen und Sie können mit ihnen sympathisieren. Wegen dieser Haltung fühlen Sie Sich sehr nah an alle um Sie herum. Es kommt Ihnen jetzt ganz einfach vor andere zu helfen durch den Austausch von Gefühlen und Erfahrungen.</p><p>Wenn Sie in einer liebevollen Beziehung sind, werden Sie eins mit Ihrem Partner sein und ein Gefühl der Intimität haben, das Sie bisher nicht einmal kannten.</p><p>Wenn Sie alleine sind und auf der Suche nach Liebe finden Sie wahrscheinlich jemanden, der wirklich passt zu Ihren emotionalen Bedürfnissen. Sie könnten Sich sehr schnell ganz nahe an diese Person angezogen fühlen. Machen Sie Sich deswegen keine Sorgen und lassen Sie Sich nicht beunruhigen.</p>",
                        [GuidanceFields.WORK] : "<p>Wo es die Arbeit anbelangt, haben Sie jetzt nicht den intellektuellen Ansatz. Sie sind offen für Atmosphären und Empfindlichkeiten, die Bedürfnisse und die Frustrationen von anderen.</p><p>Wenn es Probleme oder Konflikte gibt, verwende dann Ihre Intuition um Licht auf die Situation zu werfen und zu helfen sie zu lösen.</p><p>Auch wenn Sie es Sich vielleicht nicht bewusst sind, schätzen Ihre Kollegen Ihre Bemühungen und Persönlichkeit. Gerade jetzt sind Sie es zu dem sie kommen, wenn es ein Problem gibt.</p><p>Der König der Kelche kann auch bedeuten, dass es eine männliche Figur in der Arbeit gibt, der wirklich gerade jetzt auf Ihrer Seite ist. Wenn Sie Hilfe oder Unterstützung brauchen ist er die richtige Person zum Ansprechen. Er wird nicht zögern Ihnen den helfenden Hand zu reichen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Der König der Kelche handelt sich um Ausgeglichenheit. Damit Sie ausgewogen und spirituell ausgeglichen sind, brauchen Sie ein Gefühl für Grenzen. Wie weit kann man in eine bestimmte Richtung gehen und, noch wichtiger, wie weit möchten Sie gehen? Diese Karte zeigt einen aktiv mitfühlenden Menschen, jemand, der zuhört und sich kümmert um die Gefühle anderer und er will ihnen helfen zu wachsen. Dennoch ist es wichtig, Sich Selbst und andere einige Grenzen zu setzen, um nicht über Bord zu fallen in Ihrer Bereitschaft zu helfen. Jetzt ist ein guter Zeitpunkt um über diese Themen zu meditieren, um geistige Ruhe zu finden.</p>",
                    }
                }
            },            
            {
                filename: "50",
                numerology : 1,
                en : {
                    name: "Ace of Crystals", subtitle: "Brilliance",
                    suit : "Crystals",
                    element : "🜁 - air",
                    theme : "I think",    
                    description : "Ace of Crystals, element air, talks about your thinking in its totality. Everything is potentially present but what do you think about it?",
                    questions : [ "Are you looking for clarity?", "Are you open to new concepts?", "Are these your thoughts or are they 'imposed'?", "Can you follow your own concepts?", "What do you think?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Ace of Crystals is about power, courage, victory, confidence. You are deciding or will decide to make a new start in life, possibly on more than one level. This new chapter can be work related, about making changes in your relationship or maybe even both at once. You have the knowledge and courage to make these decisions hidden deep inside you. Now it is up to you to find this source and make full use of it. As with everything in life it is the first step that is the hardest, and it may feel like a leap of faith. But when you think about the big picture, you know that taking this step or these steps is in your best interest.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are currently in a committed relationship The Ace of Crystals may indicate that something in your relationship doesn’t feel right. One way or another you are feeling uncomfortable, maybe even unhappy. Maybe you have been feeling this way for quite some time, but up until now you lacked the courage to do something about it. Maybe you were afraid of losing your partner, but now the time has come to have this important conversation in which you openly discuss what is bothering you. After all telling the truth is to be preferred to being unhappy.</p><p>If you are looking for love right now, it is very important to be open and outspoken about what you are looking for. It is better to have one truly meaningful encounter than ten superficial ones.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are ready to let go of old patterns and familiar habits. You have been thinking of new and different ways to do things and you are feeling inspired to bring about change. Now is the perfect moment to solve complex issues that have been there for a while. Also it may be helpful to talk through some procedures with colleagues, they might be ready for some change too. Even if you don’t get appreciation for your efforts right away, rest assured that “the powers that be” are noticing your work and in time will reward you for it.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card may be telling you that you are in a rut spiritually. You have had the same beliefs and convictions for quite a while now and you are starting to feel as if they don’t fit you anymore. You have grown out of them. Take the time to explore other beliefs and ways of thinking, talk to people who are following different paths. Allow yourself to be inspired by knowledge that didn’t interest you up until today. There is no shame in changing your mind. It doesn’t mean that what you used to believe was wrong, it only means you have thoroughly changed.</p>",
                    }
                },
                fr : {
                    name : "L’As des Crystaux", subtitle: "Brillance",
                    suit : "Crystaux",
                    element :  "🜁 - air",
                    theme : "Je pense",    
                    description : "L'As des Crystaux, élément air, nous parle de nos pensées dans son intégralité. Tout est potentiellement présent, mais qu'en pensez-vous?",
                    questions : ["Voulez-vous de la lucidité?", "Êtes-vous ouvert à une nouvelle idée?", "Est-elle la vôtre, ou est-elle \"imposée\"?", "Pouvez-vous suivre vos propres idées?", "Qu’en pensez-vous?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Les \"As d'Crystaux\" représentent le pouvoir, le courage, la victoire et la confiance en soi. Il décide d'un nouveau départ dans la vie, si possible à plusieurs niveaux. Ce nouveau chapitre peut concerner le travail, les changements dans votre relation, ou peut-être les deux à la fois. Vous avez les connaissances et le courage de prendre ces décisions qui sont cachées profondément en vous. Maintenant c'est à vous de trouver cette source et de l'utiliser pour le meilleur. Comme pour tout dans la vie, la première étape est la plus difficile. Vous pourriez la vivre comme un saut dans la confiance. Mais lorsque vous pensez à l'ensemble de la situation, vous savez qu'avec cette ou ces pas, vous faites de votre mieux dans votre propre intérêt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are currently in a committed relationship The Ace of Crystals may indicate that something in your relationship doesn’t feel right. One way or another you are feeling uncomfortable, maybe even unhappy. Maybe you have been feeling this way for quite some time, but up until now you lacked the courage to do something about it. Maybe you were afraid of losing your partner, but now the time has come to have this important conversation in which you openly discuss what is bothering you. After all telling the truth is to be preferred to being unhappy.</p><p>If you are looking for love right now, it is very important to be open and outspoken about what you are looking for. It is better to have one truly meaningful encounter than ten superficial ones.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are ready to let go of old patterns and familiar habits. You have been thinking of new and different ways to do things and you are feeling inspired to bring about change. Now is the perfect moment to solve complex issues that have been there for a while. Also it may be helpful to talk through some procedures with colleagues, they might be ready for some change too. Even if you don’t get appreciation for your efforts right away, rest assured that “the powers that be” are noticing your work and in time will reward you for it.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card may be telling you that you are in a rut spiritually. You have had the same beliefs and convictions for quite a while now and you are starting to feel as if they don’t fit you anymore. You have grown out of them. Take the time to explore other beliefs and ways of thinking, talk to people who are following different paths. Allow yourself to be inspired by knowledge that didn’t interest you up until today. There is no shame in changing your mind. It doesn’t mean that what you used to believe was wrong, it only means you have thoroughly changed.</p>",
                    }
                },
                sp:{
                    name:"El As de Cristales", subtitle: "Brillantez",
                    suit: "Cristales",
                    element: "🜁 – aire",
                    theme: "Pienso",    
                    description: "El As de Cristales, que encaja el elemento aire, es sobre tu pensamiento en su totalidad. Todo está potencialmente presente, pero ¿qué opinas?",
                    questions: ["¿Quieres claridad?", "¿Estás abierto a una nueva idea?", "¿Lo crees, o es forzado'", "¿Puedes seguir tus propias ideas?", "¿Qué piensas?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El As de Cristales es sobre el poder, el coraje, la victoria, la confianza. Usted está decidiendo o se decide hacer un nuevo comienzo en la vida, disponible en más de un nivel. Este nuevo capítulo puede ser un trabajo relacionado, en hacer cambios en su relación o incluso ambos a la vez. Usted tiene el conocimiento y el coraje para tomar estas decisiones ocultas dentro de usted. Ahora le toca a usted para encontrar esta fuente y hacer pleno uso de ella. Como con todo en la vida es el primer paso que es el más difícil, y puede sentirse como un acto de fe. Pero cuando se piensa en el cuadro grande, usted sabe que dar este paso o estos pasos es en su mejor interés.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Kristallen Aas", subtitle: "Schittering",
                    suit : "Kristallen",
                    element : "🜁 - lucht",
                    theme : "ik denk",    
                    description : "De Aas van Kristallen, passend bij het element lucht, gaat over je denken in zijn totaliteit. Alles is in potentie aanwezig, maar wat denk jij?",
                    questions : [ "Wil jij helderheid?", "Sta jij open voor een nieuw denkbeeld?", "Denk je zelf zo, of is het 'opgedrongen'?", "Kun jij je eigen denkbeelden volgen?", "Wat denk jij?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Aas van Kristallen vertegenwoordigt macht, moed, overwinning, vertrouwen. Jij alleen kan beslissen of je een nieuwe start zal maken, een start die zich op meer dan één niveau kan afspelen. Het nieuwe hoofdstuk dat je zo begint te schrijven, kan met werk te maken hebben, met veranderingen in je relatie of misschien zelfs met allebei tegelijk. De kennis en de moed om deze beslissingen te nemen kan je diep binnenin jezelf vinden. Het is nu aan jou om je bron te ontdekken en er volop gebruik van te maken. Zoals bij alles in dit leven is de eerste stap de moeilijkste, je kan zelfs het gevoel hebben dat je met je ogen dicht in het diepe duikt. Maar als je naar het grotere plaatje kijkt, dan besef je dat deze stap(pen) die je zet je in de juiste richting zullen voeren.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit ogenblik een vaste relatie hebt dan vertelt de Aas van Kristallen je dat er iets in deze relatie aan de hand is waar jij je niet goed bij voelt. Op de één of andere manier voel je je niet comfortabel bij de dingen zoals ze zijn, misschien ben je zelfs ongelukkig. Het is mogelijk dat je al een tijdje met deze gevoelens zit maar dat je tot op heden de moed nog niet had om er iets aan te doen. Misschien was je te bang om je partner te verliezen of wilde je zijn/haar kant van het verhaal liever niet horen. Maar nu is het moment gekomen om een gesprek aan te gaan waarin je openlijk praat over alle dingen die voor jou niet goed zitten. Tenslotte is eerlijk zijn, hoe moeilijk het ook is, toch een stuk beter dan ongelukkig zijn en blijven. Als je op zoek bent naar liefde is het belangrijk dat je heel open en duidelijk bent over je verlangens en wensen. Op dit moment is het beter om één diepgaand en zinvol contact te hebben dan tien oppervlakkige.</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk ben je er klaar voor om oude patronen en vertrouwde gewoontes los te laten. Je bent al een tijdje aan het nadenken over nieuwe en totaal andere manieren om de dingen aan te pakken. Je voelt je geïnspireerd en klaar om de dingen aan te pakken. Nu is het juiste moment gekomen om soms ingewikkelde problemen, waarvan je al een tijdje weet, aan te pakken. Het kan zinvol zijn om met collega’s over deze dingen te praten, misschien staan ze ook wel open voor veranderingen. Zelfs als je niet onmiddellijk waardering krijgt voor je acties, vertrouw erop dat je meerderen zien wat je doet en je ervoor zullen belonen, geef hen de tijd om erover na te denken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart vertelt je dat het mogelijk is dat je spiritueel gezien op een dood spoor zit. Je houdt nu al een tijdje vast aan dezelfde overtuigingen, hetzelfde geloof. Je begint het gevoel te krijgen alsof ze je niet meer passen, je lijkt er wel uit gegroeid te zijn. Neem je tijd, onderzoek andere manieren van denken, andere overtuigingen, praat uitgebreid met mensen die op een ander spiritueel pad zitten dan jij. Sta jezelf toe om geïnspireerd te worden door zaken die je tot nu toe niet interesseerden. Er is helemaal niets fout met veranderen van mening. Het betekent enkel dat jijzelf ook grondig veranderd bent.</p>",
                    }
                },
                de : {
                    name: "Ass der Kristalle", subtitle: "Brillanz",
                    suit : "Kristalle",
                    element : "🜁 - Luft",
                    theme : "Ich denke",    
                    description : "Das Ass der Kristalle, ein Luftelement, spricht von unseren Gedanken in ihrer Gesamtheit. Alles ist potentiell vorhanden, aber was denken Sie?",
                    questions : [ "Wollen Sie Klarheit?", "Sind Sie offen für eine neue Idee?", "Ist es Ihre Idee, oder wurde sie Ihnen \"aufgezwungen\"?", "Können Sie Ihren eigenen Ideen folgen?", "Wie denken Sie darüber?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die Karte „Ass der Kristalle“ steht für Macht, Mut, Sieg und Selbstvertrauen. Sie entscheiden sich für einen neuen Start ins Leben, möglicherweise auf mehreren Ebenen. Dieses neue Kapitel kann die Arbeit, Veränderungen in Ihrer Beziehung oder vielleicht sogar beides auf einmal betreffen. Sie haben das Wissen und den Mut, diese Entscheidungen, die tief in Ihnen verborgen sind, zu treffen. Jetzt liegt es an Ihnen, diese Quelle zu finden und sie zum Besten zu nutzen. Wie bei allem im Leben ist der erste Schritt der schwierigste. Sie könnten es als Sprung ins Ungewisse erfahren. Aber wenn Sie über das große Ganze nachdenken wissen Sie, dass Sie mit diesem Schritt oder mit diesen Schritten das Beste in Ihrem eigenem Interesse tun.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer festen Beziehung sind, kann das Ass der Kristalle anzeigen, dass etwas in der Beziehung nicht richtig fühlt. Wie es auch sein mag, es fühlt sich an als unangenehm, vielleicht sogar unglücklich. Vielleicht haben Sie dieses Gefühl bereits seit einiger Zeit, aber bis jetzt hat Ihnen den Mut, etwas dagegen zu tun gefehlt. Vielleicht haben Sie Angst Ihren Partner zu verlieren, aber jetzt ist die Zeit gekommen, um dieses wichtige Gespräch zu führen, in dem Sie angeben, was Sie stört. Denn die Wahrheit ist zu bevorzugen über Ungemütlichkeit</p><p>Wenn Sie im Augenblick nach der Liebe suchen, ist es sehr wichtig, offen und freimütig zu sein über das, was Sie suchen. Es ist besser, eine wirklich sinnvolle Begegnung zu haben als zehn oberflächlichen.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit sind Sie bereit alte Muster und Gewohnheiten aufzugeben. Sie haben daran gedacht, neue und andere Möglichkeiten zu überprüfen, andere Dinge zu tun, und Sie fühlen sich inspiriert Änderungen durchzuführen. Jetzt ist die perfekte Zeit, um komplizierte Fragen, die Sie bereits eine Weile haben, zu lösen. Es kann es hilfreich sein über einige Verfahren mit Kollegen zu sprechen, auch sie könnten bereit sein eine Veränderung durch zu führen. Auch wenn Sie nicht sofort Anerkennung bekommen für Ihre Bemühungen, dann können Sie sicher sein, dass „die Mächtigen„ aufmerksam werden auf Ihre Arbeit und in der Zeit werden sie Sie dafür belohnen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Diese Karte kann sagen, dass Sie geistig in einer Furche sind. Sie haben für eine ganze Weile den gleichen Glauben und Überzeugungen gehabt und jetzt scheinen sie Ihnen nicht mehr zu passen. Sie sind mit ihnen aufgewachsen. Nehmen Sie sich Zeit, um andere Überzeugungen und Denkweisen zu erforschen, sprechen Sie mit Menschen, die unterschiedliche Wege gegangen sind. Gestatten Sie Sich Sich inspirieren zu lassen von Wissen, das Ihnen bis heute nicht interessiert hat. Es ist keine Schande in dem Verstand zu ändern. Es bedeutet nicht, dass das, was Sie glaubten, falsch war, es bedeutet nur, dass Sie Sich gründlich verändert haben.</p>",
                    }
                }
            },
            {
                filename: "51",
                numerology : 2,
                en : {
                    name: "Two of Crystals", subtitle: "Equanimity",
                    suit : "Crystals",
                    element : "🜁 - air",
                    theme : "conflicting opinions",    
                    description : "Two of Crystals talks about not having an opinion yet and our self-doubt which makes it hard to form our own decision.",
                    questions : [ "Are you influenced by what others think?", "Can you consciously wall up against that?", "Which truth is hidden deep within?", "What is your own opinion?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The perfectly balanced Crystals you see on this card, held by a blindfolded person, show the need for a balanced and stable mind and life and for looking at both sides of a situation, a problem. This card is about doubts, hesitation and the need for balance. Now is the time to ask yourself if all situations and relationships in your life are fully balanced. Do you see things as they really are or is your judgment and vision clouded, are you blind in some ways? Do people treat you as equal? Or are you the weaker or stronger person? Before “pulling out your Crystals” and seeking confrontation with others, it may be wise to first search for answers inside, to listen to your inner voice. Once you know how committed you are to bringing more balance into your life, you can start to communicate with others in order to achieve your goals.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a committed relationship this card tells you that a difficult period is finally past you and an easier, more balanced episode is starting. This is not a time for overthinking, hesitating or being skeptical, but for listening to and following your heart. Enjoy the balanced relationship you have created together.</p><p>If you are looking for a partner you should first take the time to do some soul searching. Are you really and truly over past relationships? Or are there still issues you need to work through? If there are, address them now. You don’t want to get to know potential partners with a chip on your shoulder. Also remind yourself that a partner is not there to make you happy. In an ideal situation you are already happy and then you find someone with whom you enjoy that happiness.</p>",
                        [GuidanceFields.WORK] : "<p>In connection with work you are feeling doubts on which way to go from here. Of course, reflecting on how to proceed is fine and can be really positive. Get all your ducks in a row and make a decision. Be careful not to hesitate too long, you could become apathetic and indifferent.</p><p>It is also possible that you are waiting for a decision to be made by your superiors. If this is the case, give them all the time they need and if you are getting impatient, try not to show it! </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually you are questioning and doubting yourself, asking yourself if maybe you allow yourself to be influenced by other people’s opinions and thoughts too much, or maybe you should reassess your own beliefs. Stop worrying! You are spiritually way more balanced than you give yourself credit for! You have grown and evolved in ways you don’t even realize. The beliefs you have, suit you perfectly right now and that is the only thing that counts. Do not compare yourself to others, you are unique! </p>",
                    }
                },
                fr : {
                    name : "Le Deux d’Crystaux", subtitle: "L'équanimité",
                    suit : "Crystaux",
                    element :  "🜁 - air",
                    theme : "Opinions contradictoires",    
                    description : "Le Deux d’Crystaux, nous parle de ne pas savoir exactement notre propre opinion et nous fait part de nos doutes innés, ce qui rend difficile de prendre une décision par nous-mêmes.",
                    questions : ["Êtes-vous influencé par des pensées opposantes?", "Pouvez-vous consciemment vous en éloigner?", "Quelle vérité se cache au fond de vous-même?", "Quelle est votre propre opinion?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Les Crystaux parfaitement équilibrées que vous voyez sur cette carte sont tenues par une personne aux yeux bandés. Ils montrent le besoin d'un esprit et d'une vie équilibrées et stables. La carte symbolise également la vue des deux côtés d'un problème. Cette carte représente le doute, l'hésitation et le besoin d'équilibre. Maintenant, il est temps de vous demander si toutes les situations et les relations dans votre vie sont complètement en équilibre. Voyez-vous les choses telles qu'elles sont réellement ou votre jugement et votre vision sont-ils brouillés, êtes-vous aveugle d'une manière ou d'une autre? Les gens vous traitent-ils comme leurs égaux? Où êtes-vous la personne la plus faible ou la plus forte? Avant de sortir vos Crystaux et de confronter les autres, il est peut-être utile de chercher d'abord des réponses en vous et d'écouter votre voix intérieure. Une fois que vous savez à quel point vous êtes déterminé à trouver un meilleur équilibre dans votre vie, vous pouvez parler aux autres pour atteindre vos objectifs.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a committed relationship this card tells you that a difficult period is finally past you and an easier, more balanced episode is starting. This is not a time for overthinking, hesitating or being skeptical, but for listening to and following your heart. Enjoy the balanced relationship you have created together.</p><p>If you are looking for a partner you should first take the time to do some soul searching. Are you really and truly over past relationships? Or are there still issues you need to work through? If there are, address them now. You don’t want to get to know potential partners with a chip on your shoulder. Also remind yourself that a partner is not there to make you happy. In an ideal situation you are already happy and then you find someone with whom you enjoy that happiness.</p>",
                        [GuidanceFields.WORK] : "<p>In connection with work you are feeling doubts on which way to go from here. Of course, reflecting on how to proceed is fine and can be really positive. Get all your ducks in a row and make a decision. Be careful not to hesitate too long, you could become apathetic and indifferent.</p><p>It is also possible that you are waiting for a decision to be made by your superiors. If this is the case, give them all the time they need and if you are getting impatient, try not to show it! </p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually you are questioning and doubting yourself, asking yourself if maybe you allow yourself to be influenced by other people’s opinions and thoughts too much, or maybe you should reassess your own beliefs. Stop worrying! You are spiritually way more balanced than you give yourself credit for! You have grown and evolved in ways you don’t even realize. The beliefs you have, suit you perfectly right now and that is the only thing that counts. Do not compare yourself to others, you are unique! </p>",
                    }
                },
                sp:{
                    name:"El Dos de Cristales", subtitle: "Ecuanimidad",
                    suit: "Cristales",
                    element: "🜁 –aire",
                    theme: "Opiniones contradictorias",    
                    description: "El Dos de Cristales se trata de no saber exactamente cuál es tu propia opinión y nos señala nuestra duda interior, lo que hace difícil tomar una decisión por nuestra cuenta",
                    questions: ["¿Estás influenciado por otros pensadores?", "¿Puedes cerrarte conscientemente a ellos?", "¿Qué verdad está escondida en tu interior?", "¿Cuál es tu propia opinión?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Las Cristales perfectamente equilibradas que se ven en esta tarjeta, en manos de una persona con los ojos vendados, muestran la necesidad de una mente y vida equilibrada y estable y por mirar a ambos lados de una situación, un problema. Esta tarjeta es la duda, la vacilación y la necesidad de equilibrio. Ahora es el momento de preguntarse si todas las situaciones y relaciones en su vida son balanceadas. ¿Usted ve las cosas como realmente son o se su juicio y visión nublada, está ciego en algunos aspectos? ¿La gente le trata como iguales? ¿O es usted la persona más débil o más fuerte? Antes de \"sacando sus Cristales\" y la búsqueda de la confrontación con los demás, puede ser sabio para buscar primero para obtener respuestas en el interior, para escuchar su voz interior. Una vez que sepa lo interesado que está a traer más equilibrio en su vida, usted puede comenzar a comunicarse con los demás con el fin de lograr sus objetivos.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Kristallen Twee", subtitle: "Gelijkmoedigheid",
                    suit : "Kristallen",
                    element : "🜁 - lucht",
                    theme : "tegenstrijdige meningen",    
                    description : "De Twee van Kristallen gaat over nog niet precies weten wat je eigen mening is en wijst ons op onze innerlijke twijfel, waardoor het lastig is om zelf een beslissing te nemen.",
                    questions : [ "Word jij beïnvloed door anders denkenden?", "Kun jij je daar bewust voor afsluiten?", "Welke waarheid ligt diep in jezelf verborgen?", "Wat is je eigen mening?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Op deze kaart zie je een geblinddoekte persoon die twee Kristallen vasthoudt die perfect in evenwicht zijn. Deze Kristallen wijzen je op het belang van evenwicht in je innerlijk en in je leven. Ze symboliseren ook hoe belangrijk het is om naar beide kanten van een situatie, een probleem te kijken. Deze kaart gaat over twijfelen en aarzelen èn over de behoefte aan evenwicht. Op dit moment is het belangrijk dat je je afvraagt of alle situaties en relaties in je leven helemaal in evenwicht zijn. Zie je de dingen echt zoals ze zijn? Of is je oordeel en visie troebel, ben je mogelijk blind op bepaalde vlakken? Behandelen mensen je als hun gelijke? Of ben je de sterkere of zwakkere persoon in het contact? Alvorens je “je Kristallen trekt” en de confrontatie gaat opzoeken, is het misschien verstandig om eerst binnenin jezelf op zoek te gaan naar antwoorden, om te luisteren naar je innerlijke stem. Eens je voor jezelf vastgesteld hebt hoe belangrijk het is om meer evenwicht in je leven te krijgen, kan je met anderen gaan communiceren om je doelen te bereiken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een relatie hebt dan is Kristallen Twee een teken dat je een moeilijke periode eindelijk achter je kan laten. Je staat aan het begin van een ontspannener en evenwichtiger hoofdstuk. Denk hier niet teveel over na, twijfel niet, wees niet sceptisch ... nu is het tijd om naar je hart te luisteren en het te volgen, ga voor je gevoel. Geniet volop van deze evenwichtige relatie die jullie samen waargemaakt hebben!</p><p>Als je op zoek bent naar een relatie, gun jezelf dan nu de tijd om aan wat zelfonderzoek te doen. Heb je je vorige relatie echt helemaal verwerkt? Of zijn er nog problemen en vragen die je beter zou doornemen om ze daarna te kunnen loslaten? Als dat laatste het geval is, pak deze dingen dan allereerst aan. Het zou te gek zijn om een potentiële partner te ontmoeten terwijl je nog half in het verleden zit. Bedenk ook dat een partner er niet is om jou gelukkig te maken. De ideale situatie is dat jij al gelukkig bent en dat je dan iemand vindt waarmee je dat geluk kan delen.</p>",
                        [GuidanceFields.WORK] : "<p>Als het over werk gaat dan vertelt Kristallen Twee je dat je met wat twijfels zit in verband met je toekomst, welke weg je best zou kiezen. Het is altijd verstandig en positief om zorgvuldig na te denken over het te volgen pad en te nemen besluiten. Zet alles op een rijtje en neem dan een beslissing. Waak ervoor om niet te lang te twijfelen, blijf niet hangen in het nadenken. Je loopt het risico dat je dan apathisch wordt of zelfs onverschillig.</p><p>Het is ook mogelijk dat je op dit moment aan het wachten bent op een beslissing van je meerderen. Probeer rustig te blijven en geef hen alle tijd die ze nodig hebben. Als je ongeduldig aan het worden bent, toon dit dan zeker niet!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op spiritueel vlak stel je jezelf een aantal vragen en zit je ook met twijfels. Je vraagt je aan de ene kant af of je je teveel hebt laten beïnvloeden door de gedachten en meningen van andere mensen, aan de andere kant heb je het gevoel dat je je geloofssysteem misschien moet gaan herbekijken. Stop met piekeren!</p><p>Je bent spiritueel veel beter in evenwicht dan je van jezelf denkt. Je bent gegroeid, geëvolueerd op manieren die je zelf niet doorhebt. De overtuigingen die je hebt, passen perfect bij je en dat is het enige wat telt op dit moment. Ga je spirituele zelf niet vergelijken met anderen, je bent uniek!</p>",
                    }
                },
                de : {
                    name: "Zwei der Kristalle", subtitle: "Gleichmut",
                    suit : "Kristalle",
                    element : "🜁 - Luft",
                    theme : "Widersprüchliche Meinungen",    
                    description : "Die Zwei der Kristalle spricht darüber, dass wir unsere eigene Meinung nicht genau kennen und teilt uns unsere angeborenen Zweifel mit, was es uns schwierig macht, selber eine Entscheidung zu treffen.",
                    questions : [ "Werden Sie durch gegensätzlichen Gedanken beeinflusst?", "Können Sie sich bewusst von ihnen distanzieren?", "Welche Wahrheit verbirgt sich tief in Ihnen?", "Was ist Ihre eigene Meinung?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die perfekt ausbalancierten Kristalle, die Sie auf dieser Karte sehen, werden von einer Person mit verbundenen Augen gehalten. Sie zeigen das Bedürfnis nach einem ausgewogenen und stabilen Geist und nach einem ebensolchen Leben. Die Karte symbolisiert auch den Blick auf beiden Seiten einer Problemlage. Diese Karte steht für Zweifel, Zögern sowie die Notwendigkeit eines Gleichgewichts. Jetzt ist es Zeit sich zu fragen, ob alle Situationen und Beziehungen in Ihrem Leben wirklich im Gleichgewicht sind. Sehen Sie die Dinge vollkommen klar, so wie sie wirklich sind, oder sind Ihr Urteilsvermögen und Ihre Vision getrübt, sind Sie in gewisser Weise blind? Behandeln die Menschen Sie als ihren Ebenbürtigen? Oder sind Sie die schwächere oder stärkere Person? Bevor Sie Ihre Kristalle ziehen und die Konfrontation mit anderen angehen kann es sinnvoll sein, erst einmal nach Antworten in Ihrem Inneren zu suchen und  auf Ihre innere Stimme zu hören. Sobald Sie wissen, wie engagiert Sie sind, um mehr Gleichgewicht in Ihr Leben zu bringen, können Sie sich mit anderen unterhalten, um Ihre Ziele zu erreichen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer festen Beziehung sind sagt diese Karte, dass eine schwierige Zeit endlich vorbei ist und ein einfacher, ausgewogener Folge beginnt. Dies ist keine Zeit für Grübeln, zögern oder Skeptiker, aber für das Hören auf und das Handeln nach Deinem Herzen. Genießen Sie das zusammen geschaffene ausgewogene Verhältnis.</p><p>Wenn Sie auf der Suche nach einem Partner sind, sollten Sie zunächst die Zeit nehmen, einige Seele Untersuchungen zu machen. Sind Sie wirklich und wahrhaftig über vergangene Beziehungen hinweg? Oder gibt es noch Punkte, die Sie durcharbeiten müssen? Wenn es sie gibt, rechnen Sie jetzt damit ab. Sie wollen nicht erleben, dass ein potenzieller Partner Sie mit einem Chip auf Ihre Schulter kennenlernt. Merken Sie sich auch, dass ein Partner nicht da ist, um Sie glücklich zu machen. Im Idealfall sind Sie bereits glücklich und suchen dann jemanden, mit dem Sie dieses Glück genießen können.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit fühlen Sie Zweifel, welchen Weg sie gehen müssen von hier. Natürlich, nachdenken über was und wie zu verfahren ist in Ordnung und kann wirklich positiv sein. Erhalten Sie alle Ihre Enten in einer Reihe und treffen Sie eine Entscheidung. Seien Sie vorsichtig und zögern Sie nicht zu lange. Sie könnten apathisch und gleichgültig werden.</p><p>Es ist auch möglich, dass Sie warten auf eine Entscheidung, die von Ihren Vorgesetzten genommen werden muss. Wenn dies der Fall ist, geben Sie ihnen die ganze Zeit, die sie benötigen, und wenn Sie ungeduldig werden, versuchen Sie es nicht zu zeigen!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spirituell haben Sie Fragen und Zweifel an sich selbst, fragen Sie sich, ob Sie Sich vielleicht zu sehr beeinflussen lassen von Meinungen und Gedanken anderer Leute, oder dass Sie doch mal vielleicht erst mal Ihre eigene Überzeugungen überdenken müssen. Keine Sorgen! Sie sind spirituell ausgeglichener als Sie Selbst meinen! Sie sind gewachsen und haben Sich entwickelt in einer Weise, die Sie Sich nicht einmal eindenken könnten. Die Überzeugungen, die Sie haben, passen Sie jetzt perfekt, und das ist das einzige, was zählt. Vergleichen Sie sich nicht mit anderen, Sie sind einzigartig!</p>",
                    }
                }
            },
            {
                filename: "52",
                numerology : 3,
                en : {
                    name: "Three of Crystals", subtitle: "Creativity",
                    suit : "Crystals",
                    element : "🜁 - air",
                    theme : "make room for what you think",    
                    description : "Three of Crystals talks about airing our own thoughts and the pain when we let the mind take the upper hand over the feelings, which can be heart-breaking.",
                    questions : [ "What is bothering you?", "Which decision goes against your better judgement?", "Speak openly.", "Stick up for your opinion." ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Three of Crystals depicts a heart that is being punctured by three Crystals, an image that clearly involves pain to some degree. But don’t worry, this card isn’t all about pain, it is about growth too.</p><p>Maybe you have made a sensible decision that your heart doesn’t agree with. If this is the case, you may temporarily experience a feeling of relief because you are no longer in a difficult situation. But your emotions will catch up with you and you will have to work through them someday, or you may end up making the same decision again and again because your heart puts you in the same situation over and over.</p><p>It is also possible that you have some deep rooted emotional issues that you hesitate to work through. Remember that trying to hide will not make them go away. Face them head on and be done with them.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a loving relationship you are about to experience or are experiencing heartache. This doesn’t necessarily mean that you are headed for a breakup. Possibly you will just be going through a rough patch. Or maybe you are disappointed in your partner or in the relationship because what you hoped for doesn’t come true. Just remember that, no matter how hard you try, you cannot make someone love you or treat you in a certain way. If your partner doesn’t spontaneously experience the love you need him/her to experience, no investments from your side will change that. Maybe it’s time to reach a conclusion and act upon it.</p>",
                        [GuidanceFields.WORK] : "<p>Possibly there are situations at work that you don’t like, maybe there even are things happening that hurt your ego and self-worth. Try to remember that your value, who you are as a person, does not revolve around your work alone. There are many more aspects to who you are.</p><p>It’s also possible that you are about to make a work related decision that is not in your best interest, consider your options carefully.</p><p>If you are looking for a job then possibly you are not looking in the right place. Maybe you are under- or overestimating yourself or possibly there are areas you didn’t even consider that could be interesting for you to explore. Try to expand your view.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Now may be a good moment to take some time to yourself and consider how you are feeling: do you feel whole, balanced and happy with everything and everybody in your life? Or do you need to let go of some things? Maybe there are people you don’t want around anymore, maybe there are habits you want to let go of.</p><p>Once you have finished your “spring cleaning” it is time to consider which new options you want to explore. Who and what do you want to invite into your life? Even though the answers to these questions are within you, maybe you could use some help unearthing them. Do not hesitate to ask for assistance!</p>",
                    }
                },
                fr : {
                    name : "Le Trois d’Crystaux", subtitle: "Créativité",
                    suit : "Crystaux",
                    element :  "🜁 - air",
                    theme : "Donnez de l'espace à ce que vous pensez",    
                    description : "Le Trois d'Crystaux, nous parle de donner libre cours à ce que l’on pense de douleur quand l’esprit prends le dessus sur nos sentiments et de peines de cœur.",
                    questions : ["Que vous tracasse-t-il?", "Quelle décision va à l'envers de vos sentiments?", " Ne mangez pas votre rage", "Pouvez-vous défendre votre opinion?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le «Trois d'Crystaux» montre un cœur transpercé par trois Crystaux. Une image qui est clairement liée à la douleur d'une certaine façon. Mais ne vous inquiétez pas, cette carte n'est pas seulement synonyme de douleur, mais aussi de croissance.</p><p>Peut-être avez-vous pris une décision raisonnable et rationnelle avec laquelle votre cœur n'est pas d'accord. Si c'est le cas, il se peut que vous éprouviez temporairement un sentiment de soulagement parce que vous n'êtes plus dans une position difficile. Mais vos sentiments vous rattraperont et vous devrez les affronter un jour. Si vous ne le faites pas, vous pouvez finir par prendre la même décision encore et encore une fois parce que votre cœur continue de vous mettre dans la même situation.</p><p>Il est également possible que vous ayez des problèmes émotionnels profondément enracinés et que vous hésitiez à les traiter. Notez que lorsque vous essayez de les cacher, ils ne disparaissent pas comme ça. Traiter un problème pour les résoudre.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a loving relationship you are about to experience or are experiencing heartache. This doesn’t necessarily mean that you are headed for a breakup. Possibly you will just be going through a rough patch. Or maybe you are disappointed in your partner or in the relationship because what you hoped for doesn’t come true. Just remember that, no matter how hard you try, you cannot make someone love you or treat you in a certain way. If your partner doesn’t spontaneously experience the love you need him/her to experience, no investments from your side will change that. Maybe it’s time to reach a conclusion and act upon it.</p>",
                        [GuidanceFields.WORK] : "<p>Possibly there are situations at work that you don’t like, maybe there even are things happening that hurt your ego and self-worth. Try to remember that your value, who you are as a person, does not revolve around your work alone. There are many more aspects to who you are.</p><p>It’s also possible that you are about to make a work related decision that is not in your best interest, consider your options carefully.</p><p>If you are looking for a job then possibly you are not looking in the right place. Maybe you are under- or overestimating yourself or possibly there are areas you didn’t even consider that could be interesting for you to explore. Try to expand your view.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Now may be a good moment to take some time to yourself and consider how you are feeling: do you feel whole, balanced and happy with everything and everybody in your life? Or do you need to let go of some things? Maybe there are people you don’t want around anymore, maybe there are habits you want to let go of.</p><p>Once you have finished your “spring cleaning” it is time to consider which new options you want to explore. Who and what do you want to invite into your life? Even though the answers to these questions are within you, maybe you could use some help unearthing them. Do not hesitate to ask for assistance!</p>",
                    }
                },
                sp:{
                    name:"El Tres de Cristales", subtitle: "Creatividad",
                    suit: "Cristales",
                    element: "🜁 –  aire",
                    theme: "Da espacio a lo que piensas",    
                    description: "El Tres de Cristales se trata de dar aire a lo que piensas y señala nuestro dolor cuando usamos nuestra mente para ir en contra de nuestros sentimientos, perforando nuestro corazón",
                    questions: ["¿Qué te molesta?", "¿Qué decisión va en contra de tus sentimientos?", "No conviertas tu corazón en un hoyo de asesinato", "¿Puedes defender tu opinión?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Tres de Cristales representa un corazón que está siendo perforado por tres Cristales, una imagen que implica claramente el dolor en cierto grado. Pero no se preocupe, esta tarjeta no tiene que ver con el dolor, se trata de un crecimiento demasiado. Tal vez usted ha tomado una decisión sensata con que su corazón no está de acuerdo. Si este es el caso, puede experimentar temporalmente una sensación de alivio porque ya no está en una situación difícil. Pero sus emociones se pondrá al día con usted y usted tendrá que trabajar a través de ellos algún día, o que puede llegar a hacer la misma decisión una y otra vez porque su corazón le pone en la misma situación una y otra vez. También es posible que usted tenga algunos problemas emocionales profundamente arraigados que dudan en trabajar a través. Recuerde que tratando de ocultar no hará que desaparezcan. Encararlos de frente y acabar con ellos.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Kristallen Drie", subtitle: "Creativiteit",
                    suit : "Kristallen",
                    element : "🜁 - lucht",
                    theme : "geef dat wat jij denkt de ruimte",    
                    description : "De Drie van Kristallen gaat over lucht geven aan wat je denkt en wijst ons op onze pijn wanneer we met ons verstand tegen ons gevoel in gaan, ons hart doorboren.",
                    questions : [ "Wat zit je dwars?", "Welke beslissing gaat tegen je gevoel in?", "Maak van je hart geen moordkuil.", "Kun jij opkomen voor je mening?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De afbeelding bij Kristallen Drie is een hart dat doorboord wordt door drie Kristallen. Dit beeld maakt duidelijk dat er op een of andere manier pijn komt kijken bij de betekenis van deze kaart. Maar maak je niet ongerust, het gaat niet enkel over pijn maar ook over groeien.</p><p>Als je deze kaart krijgt dan is het mogelijk dat je een verstandige, intellectuele beslissing genomen hebt maar dat je hart het er niet mee eens kan zijn. Als dit het geval is, dan kan je weliswaar tijdelijk een gevoel van opluchting ervaren omdat je je niet langer in een moeilijke situatie bevindt. Maar op een dag zullen je gevoelens je inhalen, word je opnieuw geconfronteerd met je (verstandelijke) beslissing en zal je deze emoties moeten verwerken. Als je dat niet doet dan zal je merken dat je telkens weer dezelfde beslissing neemt omdat je hart je telkens weer in dezelfde situatie terecht doet komen.</p><p>Het is ook mogelijk dat je deze kaart krijgt omdat je diep gewortelde emotionele problemen hebt die je niet durft aan te pakken. Denk eraan dat deze problemen niet verdwijnen als je ze verstopt en negeert. Ga de confrontatie aan met jezelf zodat je alles een plek kan geven en dan loslaten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een liefdesrelatie hebt dan heb je of zal je binnenkort verdriet hebben. Dit betekent niet automatisch dat er een breuk zal volgen. Het is ook mogelijk dat jullie het samen gewoon even moeilijk hebben. Misschien ben je teleurgesteld in je partner of je relatie omdat waar je op hoopte niet uitgekomen is. De realiteit kan soms minder fijn of intens zijn dan je fantasie. Probeer voor ogen te houden dat je iemand niet kan dwingen om van je te houden of je op een bepaalde manier te behandelen, al probeer je nog zo hard. Als je partner niet spontaan van je houdt dan kan je dat niet veranderen, gelijk hoeveel je investeert in de relatie. Misschien is de tijd gekomen om je conclusies te trekken en ernaar te handelen.</p>",
                        [GuidanceFields.WORK] : "<p>Het is best mogelijk dat je de situatie op je werk op dit moment behoorlijk onaangenaam vindt. Misschien zijn er zelfs dingen gaande die je kwetsen in je ego en je eigenwaarde. Denk eraan dat jouw waarde, wie jij bent als mens, niet enkel en zelfs niet vooral afhangt van je werk. Je bent veel gevarieerder en gecompliceerder dan enkel dat ene stukje.</p><p>Wat ook kan is dat je op het punt staat een beslissing te nemen in verband met je werk die eigenlijk in je nadeel zou kunnen uitdraaien. Bekijk al je opties zorgvuldig en denk goed na voor je iets doet dat je niet meer kan terugdraaien.</p><p>Als je op zoek bent naar werk dan ben je mogelijk niet in de juiste richting aan het zoeken. Het zou kunnen dat je jezelf onder- of overschat. En er zijn ook tal van gebieden die je nog niet onderzocht hebt maar die best interessant voor je kunnen zijn. Verbreed je blikveld een beetje.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op spiritueel gebied is dit een goed moment om wat tijd voor jezelf te nemen en je af te vragen hoe je je voelt: voel je je compleet en evenwichtig, ben je gelukkig met alles en iedereen in je leven? Of zijn er toch wat dingen die je beter zou loslaten? Misschien gaat het om mensen die je liever niet meer om je heen wil, misschien gaat het om gewoontes die je wil veranderen. Wat het ook is dat je niet zint, pak het aan. Eens je klaar bent met je “voorjaarsschoonmaak” kan je je gaan afvragen welke nieuwe mogelijkheden je wil gaan onderzoeken. Wie en wat wil je verwelkomen in je leven? Ook al kan je de antwoorden op deze vragen enkel binnenin jezelf vinden, het is toch mogelijk dat je wel wat ondersteuning kan gebruiken bij je zoektocht. Aarzel niet om hulp te vragen!</p>",
                    }
                },
                de : {
                    name: "Drei der Kristalle", subtitle: "Kreativität",
                    suit : "Kristalle",
                    element : "🜁 - Luft",
                    theme : "Raum geben für das, was man denkt.",        
                    description : "Die Drei der Kristalle spricht darüber, dass wir dem, was wir von Schmerz halten, freien Lauf lassen, wenn der Geist unsere Gefühle und unseren Kummer überwältigt.",
                    questions : [ "Was bedrückt Sie?", "Welche Entscheidung widerspricht Ihren Gefühlen?", "Schlucken Sie Ihre Wut nicht herunter", "Können Sie Ihre Meinung verteidigen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Drei der Kristalle“ zeigt ein Herz, das von drei Kristallen durchbohrt wird. Ein Bild, das eindeutig in gewisser Hinsicht mit Schmerzen zusammenhängt. Aber keine Sorge, diese Karte steht nicht nur für Schmerzen, sondern auch für Wachstum.</p><p>Vielleicht haben Sie eine sinnvolle, rationale Entscheidung getroffen, mit der Ihr Herz  nicht einverstanden ist. Wenn dies der Fall ist können Sie vorübergehend ein Gefühl der Erleichterung erfahren, weil Sie sich nicht mehr in einer schwierigen Lage befinden. Aber Ihre Gefühle werden Sie einholen und Sie müssen sich ihnen eines Tages stellen. Wenn Sie das nicht tun können Sie am Ende die gleiche Entscheidung wieder und wieder treffen, weil Ihr Herz Sie immer wieder in die gleiche Situation führt.</p><p>Es ist auch möglich, dass Sie einige tief verwurzelte emotionale Probleme haben und sie zögern, diese zu verarbeiten. Beachten Sie, dass, wenn man versucht sie zu verbergen, sie nicht einfach weggehen. Setzen Sie Sich direkt mit diesen Problemen auseinander und lösen Sie sie.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer liebevollen Beziehung sind, werden Sie Herzschmerzen erleben oder Sie sind bereits mitten drin. Dies bedeutet nicht zwangsläufig, dass Sie sich auf eine Trennung zusteuern. Möglicherweise werden Sie nur durch eine schwierige Phase gehen. Oder vielleicht sind Sie enttäuscht von Ihrem Partner oder in der Beziehung weil, was Sie gehofft haben, nicht geschehen ist. Denken Sie daran, dass, egal wie sehr man es versucht, man kann jemanden nicht zwingen Sie zu lieben oder zu behandeln so wie Sie das gerne möchten. Wenn Ihr Partner nicht spontan die Liebe gibt, die Sie wünschen, wird keine einzige Investierung Ihrerseits das ändern. Vielleicht ist es Zeit, um eine Schlussfolgerung zu erreichen und darauf zu reagieren.</p>",
                        [GuidanceFields.WORK] : "<p>Möglicherweise gibt es Situationen bei der Arbeit, die Sie nicht mögen, vielleicht sind auch Dinge passiert, die Ihr Ego und Selbstwertgefühl verletzt haben. Versuchen Sie daran zu denken, dass der Wert, die Sie als Person haben, nicht nur basiert ist auf Ihrer Arbeit. Es gibt viele weitere Aspekte die bestimmen, wer Sie sind.</p><p>Es ist auch möglich, dass Sie dabei sind, eine Entscheidung zu treffen, die Ihre Arbeit anbelangt. Diese wird nicht in Ihrem besten Interesse sein. Prüfen Sie Ihre Optionen sorgfältig.</p><p>Wenn Sie nach einem Job suchen, dann suchen Sie möglicherweise nicht an der richtigen Stelle. Vielleicht unter- oder überschätzen Sie Sich Selbst oder vielleicht gibt es Bereiche, von denen Sie nicht einmal in Erwägung genommen haben, dass diese interessant sein könnten für Sie zu entdecken. Versuchen Sie, Ihre Blickweite zu vergrößern.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Jetzt könnte es ein guter Zeitpunkt sein, um etwas Zeit für sich selbst zunehmen um zu überlegen, wie Sie sich fühlen: Fühlen Sie Sich komplett, ausgewogen und zufrieden mit allem und jedem in Ihrem Leben? Oder müssten Sie einige Dinge hinterlassen? Vielleicht gibt es Menschen, die Sie nicht mehr um Sich wollen oder vielleicht gibt es Gewohnheiten, die Sie abgewöhnen sollten.</p><p>Sobald Sie Ihre „Frühjahrsputz„ abgeschlossen haben, ist es Zeit zu überlegen, welche neue Möglichkeiten Sie erforschen möchten. Wer und was wollen Sie neu zu Ihrem Leben einladen? Auch wenn Sie die Antworten auf diese Fragen bereits wissen, könnten Sie vielleicht etwas Hilfe gebrauchen sie auszugraben. Zögern Sie nicht, um Hilfe zu bitten!</p>",
                    }
                }
            },
            {
                filename: "53",
                numerology : 4,
                en : {
                    name: "Four of Crystals", subtitle: "Logic",
                    suit : "Crystals",
                    element : "🜁 - air",
                    theme : "having a handle on your thoughts",    
                    description : "Four of Crystals talks about constructive thinking and taking the time to think things through.",
                    questions : [ "Is there chaos in your mind?", "Which thoughts need to be evened out?", "Are you taking the necessary time to have those evened out?", "What could you calmly think through?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about taking a rest (be it voluntarily or not) but also about boundaries. Maybe you need a break or maybe circumstances are forcing you to take one. Or possibly someone who is important to you will need some time to her- or himself.</p><p>If it is you who needs or takes a break then it will be very important to you that people respect your boundaries and do not try to convince you to do otherwise.</p><p>Think about this when someone close to you wants a pause, and respect their boundaries too, they are sure to have good reasons to temporarily take some distance.</p><p>The Four of Crystals tells you that the time has come for you to say no or to respect it when others say no to you.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are currently in a relationship you may be feeling distant. You are having a hard time to connect to your partner and generally you feel bad about the situation. Even though it is important to openly communicate with him/her, you need to remind yourself that it is not up to them to make you feel better about yourself and the relationship. It is up to you to think about how you feel and do something about it. Possibly you just need some time to yourself.</p><p>If you are currently single this is not the right time to go looking for a partner. Take a break to decide what qualities you are looking for in a partner. Rest assured that it will be time well spent.</p>",
                        [GuidanceFields.WORK] : "<p>You may have a feeling as if things are not at all going the way you would want them to go. Still, this is not the right time to try and push people in certain directions. After all, some processes take time. It would be much better to take some time off if you can, to get yourself some well earned rest and recreation.</p><p>If you are looking for a job, it may be wise not to plan interviews for a while. You aren’t the most patient person right now and that may have a negative effect on your chances to get hired.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually speaking you are in dire need of some time to just be alone with yourself. Possibly the people around you would like to pick your brain for insights and answers, but it just isn’t the right time for this. You need to focus on yourself, calm down and then tune in to the cosmic energies and vibrations around you. Feel their soothing effects inside you. Relax, heal, become whole again.</p>",
                    }
                },
                fr : {
                    name : "Le Quatre d’ Crystaux", subtitle: "Logique",
                    suit : "Crystaux",
                    element :  "🜁 - air",
                    theme : " prendre en main ce que vous pensez",    
                    description : " Le Quatre d’ Crystaux, nous parle d’ une réflexion constructive et de prendre le temps de mettre les choses au clair.",
                    questions : ["Est-ce le chaos dans votre esprit?", "Quelles pensées doivent être réglées?", "Est-ce que vous vous donnez le temps de régler les choses?", "A quoi pourriez-vous réfléchir tranquillement?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente une pause (volontaire ou non), mais aussi des limites. Peut-être que vous avez besoin d'une pause ou que les circonstances vous obligent à faire une pause, peut-être qu'il y a quelqu'un qui est important pour vous et qui, il ou elle, a besoin d'un peu de votre temps.</p><p>Si c'est le cas, alors il est très important que les gens autour de vous respectent vos limites et n'essayez pas de vous persuader de le faire différemment.</p><p>Rappelez-vous que lorsqu'un être cher a besoin d'une pause, respectez ses limites. Vous pouvez être sûr qu'elle a de bonnes raisons de faire une pause temporaire.</p><p>Le « Quatre d'Crystaux » vous dit que le temps est venu pour vous de dire non et de respecter si les autres vous disent non.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are currently in a relationship you may be feeling distant. You are having a hard time to connect to your partner and generally you feel bad about the situation. Even though it is important to openly communicate with him/her, you need to remind yourself that it is not up to them to make you feel better about yourself and the relationship. It is up to you to think about how you feel and do something about it. Possibly you just need some time to yourself.</p><p>If you are currently single this is not the right time to go looking for a partner. Take a break to decide what qualities you are looking for in a partner. Rest assured that it will be time well spent.</p>",
                        [GuidanceFields.WORK] : "<p>You may have a feeling as if things are not at all going the way you would want them to go. Still, this is not the right time to try and push people in certain directions. After all, some processes take time. It would be much better to take some time off if you can, to get yourself some well earned rest and recreation.</p><p>If you are looking for a job, it may be wise not to plan interviews for a while. You aren’t the most patient person right now and that may have a negative effect on your chances to get hired.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually speaking you are in dire need of some time to just be alone with yourself. Possibly the people around you would like to pick your brain for insights and answers, but it just isn’t the right time for this. You need to focus on yourself, calm down and then tune in to the cosmic energies and vibrations around you. Feel their soothing effects inside you. Relax, heal, become whole again.</p>",
                    }
                },
                sp:{
                    name:"El Cuatro de Cristales", subtitle: "Lógica",
                    suit: "Cristales",
                    element: "🜁 - aire",
                    theme: "agarrarte a lo que piensas",    
                    description: "El Cuatro de Cristales es sobre el pensamiento constructivo y indica que debemos tomarnos el tiempo para aclarar las cosas",
                    questions: ["¿Hay caos en tu cabeza?", "¿Qué pensamientos hay que resolver?", "¿Te das tiempo para resolverlos?", "¿En qué podrías pensar tranquilamente?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es acerca de tomar un descanso (ya sea voluntariamente o no), pero también sobre los límites. Tal vez usted necesita un descanso o tal vez las circunstancias que están obligando a tomar uno. O posiblemente alguien que es importante para usted necesitará un poco de tiempo para ella- o él mismo. Si es usted quien necesita o se toma un descanso, entonces será muy importante para usted que la gente respetar sus límites y no tratan de convencerlo de lo contrario. Piense en esto cuando alguien cercano a usted quiere una pausa, y respeten sus límites también, que están seguros de tener buenas razones para tomar temporalmente cierta distancia. El Cuatro de Cristales le dice que ha llegado el momento para que usted diga o no respetarla cuando otros dicen que no.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Kristallen Vier", subtitle: "Logica",
                    suit : "Kristallen",
                    element : "🜁 - lucht",
                    theme : "grip op wat je denkt",    
                    description : "De Vier van Kristallen gaat over constructief denken en wijst ons op bewust de tijd nemen om het een en ander op een rijtje te kunnen zetten.",
                    questions : [ "Is het een chaos in je hoofd?", "Welke gedachten behoeven ordening?", "Gun jij jezelf de tijd om alles op een rijtje te zetten?", "Wat zou jij rustig kunnen overdenken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Kristallen Vier kondigt in de eerste plaats een (al dan niet vrijwillige) rustperiode aan maar heeft het ook over grenzen. Misschien heb je behoefte aan een pauze maar het kan ook zijn dat de omstandigheden je ertoe dwingen. Een andere mogelijkheid is dat iemand die belangrijk voor je is behoefte heeft aan wat tijd voor zichzelf.</p><p>Als jij degene bent die behoefte heeft aan een onderbreking dan zal je merken dat het heel belangrijk voor je is dat de mensen om je heen je grenzen respecteren en je niet proberen te overhalen om het anders aan te pakken.</p><p>Denk hieraan als mensen in je omgeving ook een pauze willen en respecteer hun grenzen. Ze hebben vast goede redenen om tijdelijk wat afstand te willen. Kristallen Vier vertelt je dat het nu de goede tijd is voor jou om neen te leren zeggen en om het ook te respecteren als anderen neen tegen jou zeggen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit ogenblik een relatie hebt dan is het mogelijk dat je een zekere afstand voelt. Je vindt het moeilijk om te communiceren met je partner, om je echt ‘samen’ te voelen en je voelt je daar helemaal niet goed bij. Alhoewel het heel belangrijk is om open te communiceren met je partner, denk er ook aan dat het niet de taak van jouw partner is om ervoor te zorgen dat jij je beter voelt bij hem/haar en bij de relatie. Het is jouw taak om je gevoelens te onderzoeken en er vervolgens iets mee te doen. Misschien heb je gewoon wat tijd voor jezelf nodig om dingen op een rijtje te zetten.</p><p>Als je op dit moment alleen bent dan is het niet de juiste tijd om op zoek te gaan naar een partner. Geef jezelf wat ruimte om erover na te denken waar je juist naar op zoek bent, welke eigenschappen wil je dat je toekomstige partner heeft? Je zal achteraf blij zijn dat je hiervoor de tijd genomen hebt.</p>",
                        [GuidanceFields.WORK] : "<p>In je werksituatie heb je mogelijk het gevoel dat de dingen helemaal niet gaan zoals jij zou willen dat ze gaan. En toch is het nu het verkeerde moment om veranderingen door te voeren of mensen in een bepaalde richting te duwen. Sommige veranderingsprocessen hebben tijd nodig. Op dit moment zou het beter zijn om wat vrijaf te nemen als dat mogelijk is, zodat je wat kan rusten en ontspannen. Je hebt het verdiend!</p><p>Als je op zoek bent naar werk dan is het een goed idee om de komende tijd geen sollicitatiegesprekken te plannen. Je bent op dit moment niet echt geduldig en dat kan je kansen om aangenomen te worden erg negatief beïnvloeden. Neem wat tijd voor jezelf, je hebt het nodig.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op spiritueel vlak is het op dit ogenblik erg belangrijk dat je de tijd neemt om eens helemaal alleen met jezelf te zijn. Het is best mogelijk dat de mensen om je heen op zoek zijn naar inzichten en antwoorden en dat ze denken die bij jou te vinden, maar op dit moment is dat een slecht idee. Je hebt het nu echt nodig om op je Zelf te focussen, tot rust te komen en je te concentreren op de kosmische energieën en vibraties om je heen. Laat hun helende kracht op je inwerken. Ontspan je, genees, word weer geheel, één met het universum.</p>",
                    }
                },
                de : {
                    name: "Vier der Kristalle", subtitle: "Logik",
                    suit : "Kristalle",
                    element : "🜁 - Luft",
                    theme : "Die Kontrolle darüber übernehmen, was man denkt.",     
                    description : "Die Vier der Kristalle spricht über eine konstruktive Reflexion und sich die Zeit zu nehmen, die Dinge zu klären.",
                    questions : [ "Hat es Chaos in Ihrem Kopf?", "Welche Gedanken müssen noch bereinigt werden?", "Geben Sie sich Zeit, um Dinge zu bereinigen?", "Worüber könnten Sie in Ruhe nachdenken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für eine Pause (sei es freiwillig oder nicht), aber auch für Grenzen. Vielleicht brauchen Sie eine Pause, oder Umstände zwingen Sie zu einer Pause. Vielleicht gibt es auch jemanden, der wichtig für Sie ist und der einige Zeit für sich selbst oder für Sie braucht.</p><p>Wenn Sie es sind, dann ist es sehr wichtig, dass die Menschen in Ihrer Umgebung Ihre Grenzen respektieren und nicht versuchen, Sie zu überreden, es anders zu machen.</p><p>Denken Sie daran, wenn eine geliebte Person eine Pause braucht, die Grenzen dieser Person zu respektieren. Sie können sich sicher sein, dass sie gute Gründe hat, vorübergehend ein wenig Abstand zu nehmen.</p><p>Die „Vier der Kristalle“ sagt Ihnen, dass die Zeit für Sie gekommen ist, nein zu sagen und auch zu respektieren, wenn andere nein zu Ihnen sagen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer Beziehung sind fühlen Sie Sich weit entfernt davon. Sie haben Schwierigkeiten um mit Ihrem Partner in Verbindung zu geraten und in der Regel fühlen Sie Sich schlecht darüber. Auch wenn es natürlich wichtig ist, offen mit ihm / Ihr zu kommunizieren, müssen Sie Sich realisieren, dass es nicht an seine /Ihre Verantwortlichkeit ist, dass Sie sich besser über sich selbst und die Beziehung fühlen. Es liegt an Ihnen zu bestimmen und darüber nachzudenken, wie Sie Sich fühlen. Möglicherweise brauchen Sie nur etwas Zeit für Sich Selbst.</p><p>Wenn Sie derzeit alleine sind ist dies nicht der richtige Zeitpunkt um sich einen Partner zu suchen. Machen Sie eine Pause um zu entscheiden, welche Qualitäten Sie in einem Partner suchen. Seien Sie versichert, dass es gut investierte Zeit ist.</p>",
                        [GuidanceFields.WORK] : "<p>Sie können das Gefühl haben, dass die Dinge überhaupt nicht gehen so wie Sie möchten, dass sie gehen. Dennoch ist dies nicht der richtige Zeitpunkt, um zu versuchen, die Menschen in bestimmte Richtungen zu drängen. Immerhin brauchen bestimmte Prozesse Zeit. Es wäre viel besser, etwas Zeit zu nehmen, wenn Sie könnten, um Sich Selbst etwas wohlverdiente Ruhe und Erholung zu leisten.</p><p>Wenn Sie einen Job suchen, könnte es jetzt klug sein, für eine Weile keine Interviews zu planen. Sie sind gerade nicht die meist geduldige Person und dass könnte einen negativen Einfluss auf Ihre Chancen haben.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spirituell gesprochen brauchen Sie jetzt dringend einige Zeit für Sich allein. Möglicherweise möchten die Menschen um Sie gerne Ihr Gehirn mal nützen für Ihre eigene Einsichten oder um sich Antworten zu holen, aber es ist einfach nicht der richtige Zeitpunkt dafür. Sie müssen sich auf sich selbst konzentrieren, Sich Selbst beruhigen und dann in die kosmischen Energien und Schwingungen einsteigen. Fühlen Sie Ihre wohltuende Wirkung in Sich. Sie sollten ausruhen, heilen, und danach komplett wieder weiter gehen.</p>",
                    }
                }
            },
            {
                filename: "54",
                numerology : 5,  
                en : {
                    name: "Five of Crystals", subtitle: "Negativity",
                    suit : "Crystals",
                    element : "🜁 - air",
                    theme : "own thinking",    
                    description : "Five of Crystals talks about being conscious about your own opinion and the need to learn about the futility of fighting over it.",
                    questions : [ "Do you always need to be right?", "Do you think everything is a battle?", "Which battle has become obsolete?", "Which battle are you letting go of?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>If you draw the Five of Crystals it is rather likely that you are not feeling positive at all. There seem to be a lot of things that you’re not happy with, possibly even some people who are rubbing you the wrong way. Even though it is important that you recognize these feelings and deal with the, it may be wise to avoid confrontations. For the moment you are not the most reasonable person and you may possibly break things that aren’t easily repaired. Before you confront someone it would be wise to count to 10, and then once more. Think long and hard, then only act on what you are feeling and thinking if it is truly unavoidable.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that there are serious issues in your relationship that need to be addressed. Maybe your partner is crossing boundaries in a way that is unacceptable for you. Possibly you are experiencing negative feelings such as hatred, anger, need for revenge. Try to stay focused on the problems at hand and ask yourself what is really the issue. If you are clear about that, it is time to talk to your partner. Maybe you can solve these problems together. </p><p>If you are single this may not be the right time to go looking for a partner because you are feeling frustrated and even angry about being on your own. Deal with your feelings first and only when you feel better about yourself and your situation, then you can go look for a partner.</p>",
                        [GuidanceFields.WORK] : "<p>The Five of Crystals tells you that you are unhappy and frustrated where work is concerned. Things are absolutely not going your way. Maybe you could stop to consider what your role in the matter is. Possibly your frustration is seeping through in the comments you give, maybe you are too biased to think clearly. Before you act and get into an open conflict, it would be good to realize that this is a situation in which even the winner loses. Work through your negative emotions, let them go and try to be constructive.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card tells you that you may feel too secure, convinced of the fact that your spiritual path, findings and answers are the right ones. Possibly you are trying to convince the people around you that they have been wrong all the time and you are right. Remember that what fits you doesn’t necessarily fit everybody else. Be happy that you found your answers and allow others to keep looking for theirs.</p><p>It is also possible that you feel disconnected from spiritual truths you have been holding dear for a long time. This may be very unsettling but try to see it as a challenge for you to go out and look for new answers.</p>",
                    }
                },              
                fr : {
                    name : "Le Cinq d’ Crystaux", subtitle: "Négativité",
                    suit : "Crystaux",
                    element :  "🜁 - air",
                    theme : "la propre pensée",    
                    description : "Le Cinq d'Crystaux, nous parle d’être conscience ses propres opinions et de la capacité d'apprendre à ne pas (plus) mener de batailles inutiles.",
                    questions : ["Voulez-vous toujours avoir raison?", "Pensez-vous que vous devez toujours vous battre?", "Quel combat n'a plus de raison?", "Dans quel combat ne met-on plus d'énergie?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Si vous tirez le \"Cinq d'Crystaux\", il est plus probable que vous n'ayez plus aucun sentiment positif. Il semble y avoir beaucoup de choses dont vous n'êtes pas satisfait en ce moment. Il y a peut-être aussi des gens que vous ne confrontez pas de la bonne façon. Il est important que vous reconnaissiez ces sentiments et sachiez comment les gérer. Il peut maintenant être sage d'éviter la confrontation. En ce moment, vous n'êtes pas la personne la plus sensée et vous pourriez être capable de casser des choses qui ne sont pas faciles à réparer. Avant de rencontrer quelqu'un, il serait sage de compter jusqu'à dix et d'essayer à nouveau. Réfléchissez longuement et sérieusement et n'agissez en fonction de vos sentiments et de vos pensées, que lorsque c'est vraiment inévitable.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that there are serious issues in your relationship that need to be addressed. Maybe your partner is crossing boundaries in a way that is unacceptable for you. Possibly you are experiencing negative feelings such as hatred, anger, need for revenge. Try to stay focused on the problems at hand and ask yourself what is really the issue. If you are clear about that, it is time to talk to your partner. Maybe you can solve these problems together. </p><p>If you are single this may not be the right time to go looking for a partner because you are feeling frustrated and even angry about being on your own. Deal with your feelings first and only when you feel better about yourself and your situation, then you can go look for a partner.</p>",
                        [GuidanceFields.WORK] : "<p>The Five of Crystals tells you that you are unhappy and frustrated where work is concerned. Things are absolutely not going your way. Maybe you could stop to consider what your role in the matter is. Possibly your frustration is seeping through in the comments you give, maybe you are too biased to think clearly. Before you act and get into an open conflict, it would be good to realize that this is a situation in which even the winner loses. Work through your negative emotions, let them go and try to be constructive.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>This card tells you that you may feel too secure, convinced of the fact that your spiritual path, findings and answers are the right ones. Possibly you are trying to convince the people around you that they have been wrong all the time and you are right. Remember that what fits you doesn’t necessarily fit everybody else. Be happy that you found your answers and allow others to keep looking for theirs.</p><p>It is also possible that you feel disconnected from spiritual truths you have been holding dear for a long time. This may be very unsettling but try to see it as a challenge for you to go out and look for new answers.</p>",
                    }
                },
                sp:{
                    name:"El Cinco de Cristales", subtitle: "Negatividad",
                    suit: "Cristales",
                    element: "🜁 - aire",
                    theme: "tu pensamiento propio",    
                    description: "El Cinco de Cristales es sobre ser consciente de tu propia opinión y nos señala la habilidad de aprender a no pelear (nunca más) en una batalla innecesaria",
                    questions: ["¿Siempre quieres tener razón?", "¿Crees que siempre debes luchar?", "¿Qué lucha no tiene sentido nunca más", "¿En qué lucha ya no pones energía?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Si dibuja el Cinco de Cristales es bastante probable que no se siente del todo positiva. Parece que hay un montón de cosas con que usted no está contento, posiblemente, incluso algunas personas que le están rozando en una forma incorrecta. Aunque es importante que reconozca estos sentimientos y tratar con él, puede ser prudente evitar confrontaciones. Por el momento, usted no es la persona más razonable y  posiblemente puede romper las cosas que no se reparan fácilmente. Antes se enfrenta a alguien, sería conveniente contar hasta 10, y luego una vez más. Piense largo y duro, a continuación, sólo actúa sobre lo que está sintiendo y pensando si es verdaderamente inevitable.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                de : {
                    name: "Fünf der Kristalle", subtitle: "Negativität",
                    suit : "Kristalle",
                    element : "🜁 - Luft",
                    theme : "Der eigene Gedanke",    
                    description : "Die Fünf der Kristalle spricht über das Bewusstsein seiner eigenen Meinungen und die Fähigkeit zu lernen, keine unnötigen Kämpfe (mehr) zu führen.",
                    questions : [ "Wollen Sie immer Recht haben?", "Denken Sie, Sie sollten immer kämpfen?", "Welcher Kampf hat keinen Sinn mehr?", "In welchen Kampf stecken wir keine Energie mehr?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Wenn Sie die „Fünf der Kristalle“ ziehen ist es eher wahrscheinlich, dass Sie überhaupt keine positiven Gefühle haben. Es scheint im Moment viele Dinge zu geben, mit denen Sie nicht zufrieden sind. Möglicherweise gibt es auch einige Menschen, die Ihnen nicht auf die richtige Weise begegnen. Es ist wichtig, dass Sie diese Gefühle erkennen und wissen, wie Sie damit umgehen sollen. Es kann jetzt klug sein, Konfrontationen zu vermeiden. Im Moment sind Sie nicht die vernünftigste Person, und Sie können möglicherweise Dinge kaputt machen, die nicht leicht zu reparieren sind.  Bevor Sie jemandem begegnen wäre es klug, bis zehn zu zählen, und es dann noch einmal zu versuchen. Denken Sie lange und gründlich nach und handeln Sie erst dann auf der Basis Ihrer Gefühle und Gedanken, wenn es wirklich unvermeidlich ist.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte sagt Ihnen, dass es ernsthafte Probleme in Ihrer Beziehung gibt, die Ihre Aufmerksamkeit bedürfen. Vielleicht hat Ihr Partner Grenzen überschritten in einer Weise, die für Sie nicht akzeptabel ist. Möglicherweise erleben Sie negative Gefühle wie Hass, Zorn, Bedürfnis nach Rache. Versuchen Sie Sich auf die tatsächlichen Probleme zu konzentrieren und fragen Sie Sich, was wirklich die Ursache ist. Wenn Sie Sich darüber klar sind, ist es Zeit mit Ihrem Partner zu sprechen. Vielleicht können Sie diese Probleme gemeinsam lösen.</p><p>Wenn Sie alleine sind, ist dies möglicherweise nicht der richtige Zeitpunkt um Sich einen Partner zu suchen, weil Sie frustriert und sogar wütend darüber sind, dass Sie alleine sind. Kommen Sie zuerst mal mit Ihren Gefühlen ins Klare und wenn Sie Sich besser fühlen in Ihrer Situation, dann können Sie ausgehen und Sich einen Partner suchen.</p>",
                        [GuidanceFields.WORK] : "<p>Die Fünf der Kristalle sagt Ihnen, dass Sie unglücklich und frustriert sind, wo es die Arbeit anbelangt. Die Dinge laufen absolut nicht, so wie Sie es gerne hätten. Vielleicht sollten Sie aufhören Sich zu fragen welche Rolle Sie in der Angelegenheit spielen. Möglicherweise sickert Ihre Frustration durch in die Kommentare, die Sie geben, vielleicht sind Sie zu voreingenommen um klar zu denken. Bevor Sie handeln und sich in einen offenen Konflikt begeben, wäre es gut zu erkennen, dass es sich hier um eine Situation handelt, in der sogar der Gewinner verliert. Arbeiten Sie sich durch Ihre negativen Gefühle, lassen Sie sie gehen und versuchen Sie konstruktiv zu sein.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Diese Karte sagt Ihnen, dass Sie Sich vielleicht zu sicher fühlen über die Tatsache, dass Ihr spiritueller Weg, Ihre Erkenntnisse und Antworten die einzig richtigen sind. Möglicherweise versuchen Sie die Menschen, um Sie herum zu überzeugen, dass sie es die ganze Zeit falsch gemacht haben und Sie haben immer recht gehabt. Denken Sie daran, dass das, was Ihnen passt, nicht unbedingt allen anderen auch passen muss. Seien Sie froh, dass Sie Ihre Antworten gefunden haben und gestatten Sie die Andere Ihre eigene Antworten zu suchen.</p><p>Es ist auch möglich, dass Sie Sich getrennt fühlen von geistigen Wahrheiten die Sie lange Zeit gehegt haben. Dies kann sehr beunruhigend sein, sondern versuchen Sie es, als eine Herausforderung für Sie zu sehen, um auf die Suche zu gehen nach neuen Antworten.</p>",
                    }
                },
                nl : {
                    name: "Kristallen Vijf", subtitle: "Negativiteit",
                    suit : "Kristallen",
                    element : "🜁 - lucht",
                    theme : "eigen denken",    
                    description : "De Vijf van Kristallen gaat over bewust zijn van je eigen mening en wijst ons op het kunnen leren geen onnodige strijd (meer) te voeren.",
                    questions : [ "Wil je altijd gelijk hebben?", "Denk jij dat je altijd moet strijden?", "Welke strijd heeft geen zin (meer)?", "In welke strijd stop jij geen energie meer?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Als je Kristallen Vijf trekt dan is het goed mogelijk dat je je helemaal niet positief voelt. Het lijkt erop dat er nogal wat dingen gaande zijn in je leven waar je niet gelukkig mee bent. Mogelijk zijn er ook mensen in je omgeving die de dingen op zo’n manier aanpakken dat je er nerveus van wordt. Alhoewel het belangrijk is dat je deze gevoelens onderkent en dat je ze probeert aan te pakken, is het op dit moment waarschijnlijk het slimste om even geen confrontaties aan te gaan. Je bent tegenwoordig niet de meest redelijke persoon en als je mensen op hun gedrag aanspreekt is het mogelijk dat je brokken maakt die niet gauw gelijmd kunnen worden. Als je iemand ergens op wil aanspreken, haal dan eerst diep adem en tel tot 10, en dan nog eens. Denk er goed en diep over na en voer dat gesprek enkel en alleen als je zeker bent dat het onvermijdelijk is.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt je dat er in je relatie belangrijke knelpunten zijn die aangepakt moeten worden. Het is mogelijk dat je partner op zo’n manier over je grenzen heengaat dat je het niet meer kan accepteren. Hierdoor komen er bij jou misschien bepaalde negatieve gevoelens boven zoals boosheid, tegenzin, mogelijk zelfs een behoefte om wraak te nemen. Probeer je te concentreren op de problemen zelf en vraag je af wat nu de knelpunten zijn. Als je die eenmaal duidelijk hebt, kan je de volgende stap zetten en met je partner gaan praten. Het is best mogelijk dat je deze problemen samen kan oplossen.</p><p>Als je op dit moment single bent, dan is het waarschijnlijk niet de juiste tijd om iemand te vinden. Je voelt je nu immers gefrustreerd en misschien zelfs boos omdat je alleen bent. Pak deze gevoelens eerst aan, verwerk ze, geef ze een plek. Pas als je beter in je vel zit, als je jezelf en je situatie kan aanvaarden, pas dan is de tijd gekomen om op zoek te gaan naar een partner.</p>",
                        [GuidanceFields.WORK] : "<p>Kristallen Vijf wijst je erop dat je niet gelukkig bent in je werksituatie, misschien ben je zelfs behoorlijk gefrustreerd. De dingen verlopen helemaal niet zoals jij het liefst zou willen. Misschien is het nu het juiste moment om er even bij stil te staan welke rol jij speelt in deze situatie. Misschien sijpelt je frustratie wel door in de commentaren die je geeft, mogelijk ben je op dit ogenblik iets te bevooroordeeld om echt helder en objectief te denken. Voor je de eerste stap zet in de richting van een conflict, is het misschien verstandig om te bedenken dat er in deze situatie waarschijnlijk geen winnaars zullen zijn, iedereen zal er iets bij inschieten. Pak je negatieve gevoelens aan, probeer ze te verwerken en los te laten. Eens je dat gedaan hebt, zal je merken dat je opnieuw opbouwend kan gaan denken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart vertelt je dat je op dit moment misschien wat te zelfverzekerd bent. Je bent ervan overtuigd dat jouw spirituele weg, je ontdekkingen en de antwoorden die je gevonden hebt de enige juiste zijn. Misschien probeer je zelfs de mensen om je heen ervan te overtuigen dat ze fout zitten en dat jij de goede kant aan het opgaan bent. Denk eraan dat wat past voor jou misschien niet past voor anderen, we zijn immers allemaal uniek en hebben daarom ook unieke antwoorden en inzichten.</p><p>Wees blij dat jij je antwoorden gevonden hebt en geef anderen de ruimte om die van hen te vinden.</p><p>Het is ook mogelijk dat je tegenwoordig het gevoel hebt dat je geen voeling meer hebt met de spirituele inzichten die zo lang heel belangrijk voor je waren. Dit kan je erg onzeker maken, je uit balans halen. Probeer ernaar te kijken als een uitdaging: trek erop uit en zoek nieuwe antwoorden en inzichten!</p>",
                    }
                },
            },
            {
                filename: "55",
                numerology : 6,
                en : {
                    name: "Six of Crystals", subtitle: "Confusion",
                    suit : "Crystals",
                    element : "🜁 - air",
                    theme : "opting to follow your own opinion",    
                    description : "Six of Crystals talks about consciously opting for your own opinion and to deliberately setting your own course.",
                    questions : [ "Can you opt for your own opinion?", "Despite what others might think?", "Which course are you willing to set?", "Can you freely opt for it?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Six of Crystals usually indicates a, possibly small, change for the better. This change will always be associated with a move that might be literal or figurative. This doesn’t mean that your situation or the way you are feeling will suddenly be perfect, but it will be better than it has been.</p><p>This card also indicates that this may be the right time to travel or move, to change your circumstances, to get out of your comfort zone, to try and create your own spot somewhere else. In some ways you may be preparing to take a courageous step, leaving behind some things that are familiar to you, although it doesn’t necessarily mean you are leaving behind everything you know. Maybe you are just taking a short break to get closer to yourself and what you really want and need.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card signifies that you are ready for and maybe even need a change. If you are in a relationship, try to change the patterns you and your loved one are used to. Take him/her to different places, do different things, talk about other topics than you usually do. Change is good right now.</p><p>If you are single and looking for a relationship it is time to leave old patterns behind. Don’t keep looking for love in all the old and familiar places. Be adventurous and take paths you have never walked before, go to places that are new to you. You may be more successful than you anticipated!</p>",
                        [GuidanceFields.WORK] : "<p>Where work and career are concerned, you are entering a new phase, starting a new chapter. Maybe you are being assigned new tasks, getting a promotion or maybe you will even be starting a new job. It is also possible that you are simply finding new ways to look at your “old” job. One thing is for sure, you are leaving behind what feels familiar and taking on new challenges and experiences, you are growing. Ask yourself where you want to be, what your goals are and trust the people around you to help you get there.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In your spiritual quest you may have been asking yourself the same old questions over and over without really finding satisfying answers. Now is the time to stop asking questions and to start acting. Change your routines, stop reading the same old books and having the same old conversations. You could even change the way you meditate and visualize! This is a time for experimenting, for travelling through the spiritual realm. Also pay extra attention to your dreams, they have messages for you, they can show you things that aren’t clear when you are awake. </p>",
                    }
                },
                fr : {
                    name : "Le Six d’Crystaux", subtitle: "Confusion",
                    suit : "Crystaux",
                    element :  "🜁 - air",
                    theme : "choisir de suivre sa propre opinion",    
                    description : " Le Six d’Crystaux, nous parle de choisir consciemment sa propre opinion et que l'on peut délibérément mettre le cap sur",
                    questions : ["Pouvez-vous choisir votre propre opinion?", "Peu importe ce que les autres pourraient penser?", "Quelle voie voulez-vous suivre?", "Pouvez-vous choisir cela consciemment?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Six d'Crystaux\" indique généralement un changement possiblement vers le mieux. Ce changement est toujours associé à un mouvement, le mouvement peut être littéral ou figuratif. Cela ne signifie pas que votre situation ou la façon dont vous vous sentez sera soudainement parfaite, mais elle sera meilleure qu'avant.</p><p>Cette carte indique également que c'est le bon moment pour voyager ou pour changer de situation, pour quitter votre zone de confort, pour essayer de trouver votre propre place ailleurs. D'une certaine façon, vous pourriez être prêt pour un pas en avant audacieux qui laissera derrière vous des choses familières. Même si cela ne signifie pas nécessairement que vous laisserez tout ce que vous savez derrière vous. Peut-être êtes-vous sur le point de faire une courte pause pour vous rapprocher de ce que vous voulez vraiment et de ce dont vous avez besoin.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card signifies that you are ready for and maybe even need a change. If you are in a relationship, try to change the patterns you and your loved one are used to. Take him/her to different places, do different things, talk about other topics than you usually do. Change is good right now.</p><p>If you are single and looking for a relationship it is time to leave old patterns behind. Don’t keep looking for love in all the old and familiar places. Be adventurous and take paths you have never walked before, go to places that are new to you. You may be more successful than you anticipated!</p>",
                        [GuidanceFields.WORK] : "<p>Where work and career are concerned, you are entering a new phase, starting a new chapter. Maybe you are being assigned new tasks, getting a promotion or maybe you will even be starting a new job. It is also possible that you are simply finding new ways to look at your “old” job. One thing is for sure, you are leaving behind what feels familiar and taking on new challenges and experiences, you are growing. Ask yourself where you want to be, what your goals are and trust the people around you to help you get there.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In your spiritual quest you may have been asking yourself the same old questions over and over without really finding satisfying answers. Now is the time to stop asking questions and to start acting. Change your routines, stop reading the same old books and having the same old conversations. You could even change the way you meditate and visualize! This is a time for experimenting, for travelling through the spiritual realm. Also pay extra attention to your dreams, they have messages for you, they can show you things that aren’t clear when you are awake. </p>",
                    }
                },
                sp:{
                    name:"El Seis de Cristales", subtitle: "Confusión",
                    suit: "Cristales",
                    element: "🜁 - aire",
                    theme: "elige seguir tu propia opinión",    
                    description: "El Seis de Cristales se trata de elegir conscientemente tu propia opinión y nos recuerda que podemos tomar un curso diferente" ,
                    questions: ["¿Puedes elegir tu propia opinión?", "Independientemente de lo que piensen los otros", "¿Qué curso quieres seguir?", "¿Puedes elegir conscientemente para eso?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Seis de Cristales suele indicar una, posiblemente pequeño, el cambio para mejor. Este cambio siempre estará asociado con un movimiento que podría ser literal o figurado. Esto no quiere decir que su situación o la forma en que se siente repentinamente serán perfectas, pero será mejor de lo que ha sido. Esta tarjeta también indica que este puede ser el momento adecuado para viajar o moverse, cambiar sus circunstancias, a salir de su zona de confort, para tratar de crear su propio lugar en otro lugar. En cierto modo, es posible que  esté preparando para dar un paso valiente, dejando atrás algunas cosas que son familiares para usted, aunque no significa necesariamente que usted está dejando atrás todo lo que sabes. Quizás esté sólo tomando un breve descanso para estar más cerca de usted y lo que realmente quiere y necesita.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Kristallen Zes", subtitle: "Verwarring",
                    suit : "Kristallen",
                    element : "🜁 - lucht",
                    theme : "kiezen om je eigen mening te volgen",    
                    description : "De Zes van Kristallen gaat over bewust kiezen voor je eigen mening en wijst ons er op dat we doelbewust een koers in kunnen slaan.",
                    questions : [ "Kun jij kiezen voor je eigen mening?", "Ongeacht wat anderen er van zouden kunnen denken?", "Welke koers wil jij gaan?", "Kun jij daar bewust voor kiezen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Kristallen Zes wijst je erop dat er binnenkort in je leven een (misschien kleine) verandering ten goede zal komen. Deze verbetering heeft altijd iets te maken met beweging, verplaatsing, al kan dit zowel letterlijk als figuurlijk zijn. Dit betekent niet automatisch dat je situatie en hoe jij je voelt ineens perfect zullen zijn, maar het zal wel een stukje beter gaan dan voorheen.</p><p>Deze kaart geeft ook een aanwijzing dat het nu een goede tijd is om op reis te gaan of te verhuizen, je te verplaatsen, je omstandigheden te veranderen. Nu is het moment gekomen om je buiten je comfortzone te begeven en ergens anders een plek te gaan zoeken waar je je goed kan voelen. Let op: dit kan nog steeds zowel letterlijk als figuurlijk zijn!</p><p>Op een bepaalde manier ben je je aan het voorbereiden om een moedige stap vooruit te zetten, om een aantal vertrouwde zaken achter je te laten. Dit betekent niet noodzakelijk dat je al het bekende definitief achterlaat. Het is ook mogelijk dat je gewoon een pauze neemt om dichter bij jezelf te komen en te ontdekken wat je echt wil en nodig hebt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt je dat je klaar bent voor verandering, misschien heb je die zelfs echt nodig op dit moment. Als je in een relatie zit, dan is het nu een goede tijd om een aantal patronen te doorbreken waar jullie aan gewend zijn. Zoek nieuwe plekken op, praat over andere onderwerpen, kies een andere soort films of boeken. Verandering is op dit ogenblik gewoon erg goed voor jullie, je zal er positieve resultaten mee boeken.</p><p>Als je single bent en op zoek naar een partner dan is ook voor jou de tijd rijp om oude gewoontes los te gaan laten. Ga de liefde niet meer zoeken op de bekende plekken en de bekende manieren. Wees avontuurlijk en sla wegen in die je totaal onbekend zijn. Was internetdaten nooit iets voor jou? Probeer het dan nu eens uit. Je zal merken dat je meer succes hebt dan je verwachtte met je nieuwe methodes!</p>",
                        [GuidanceFields.WORK] : "<p>Op het gebied van werk en carrière begin je nu aan een nieuwe fase, een nieuw hoofdstuk. Misschien krijg je nieuwe taken toegewezen, zit er een promotie in of verander je daadwerkelijk van baan. Het is ook mogelijk dat je volop nieuwe manieren aan het ontdekken bent om naar je ‘oude’ job te kijken. Eén ding is zeker, je laat een aantal vertrouwde zaken achter je en je gaat nu voor nieuwe uitdagingen en ervaringen, je bent volop aan het groeien. Vraag je nu af waar je heen wil, wat je wil bereiken, welke doelen je jezelf wil stellen. En vertrouw erop dat de mensen om je heen je willen ondersteunen bij deze evolutie.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>JMogelijk besef je op dit moment dat je in je spirituele zoektocht alsmaar dezelfde vragen aan het stellen bent en dat je er maar niet in slaagt om bevredigende antwoorden te vinden. Als dit het geval is, hou dan op met vragen stellen en ga actief aan de slag. Verander je gewoontes, stop met het raadplegen van alsmaar dezelfde boeken en sites, hou op met die gesprekken waarvan je het resultaat toch al kent. Verander ook eens je manier van mediteren en visualiseren. Het is een goed idee om nu aan het experimenteren te gaan, om onontgonnen terrein te verkennen, om eens nieuwe continenten te gaan bezoeken in de spirituele wereld! Besteed ook extra aandacht aan je dromen, je kan er boodschappen in vinden, ze kunnen je inzicht geven in zaken die je niet onderkent als je wakker bent.</p>",
                    }
                },
                de : {
                    name: "Sechs der Kristalle", subtitle: "Verwirrung",
                    suit : "Kristalle",
                    element : "🜁 - Luft",
                    theme : "Beschliessen, seiner eigenen Meinung zu folgen.",    
                    description : "Die Sechs der Kristalle sagt uns, dass wir uns bewusst unsere eigene Meinung bilden sollen und dass wir bewusst einen Kurs setzen können.",
                    questions : [ "Können Sie sich eine eigene Meinung aussuchen?", "Egal, was andere denken mögen?", "Welchen Weg wollen Sie gehen?", "Können Sie das bewusst wählen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Sechs der Kristalle“ deuten gewöhnlich auf eine möglicherweise kleine Veränderung zum Besseren hin. Diese Veränderung wird immer mit einer Bewegung in Verbindung gebracht, die Bewegung kann entweder wörtlich oder bildlich sein. Dies bedeutet nicht, dass Ihre Lage oder die Art und Weise, wie Sie Sich fühlen, plötzlich perfekt sein wird, aber es wird besser sein als bisher.</p><p>Diese Karte zeigt auch an, dass dies der richtige Zeitpunkt ist, um zu reisen oder um Ihre Situation zu verändern, um Ihre Komfort-Zone zu verlassen, um zu versuchen, Ihren eigenen Platz irgendwo anders  zu finden. In gewisser Weise könnten Sie für einen mutigen Schritt vorwärts bereit sein, mit dem Sie ein paar vertraute Dinge zurücklassen. Auch wenn das nicht unbedingt bedeutet, dass Sie alles, was Sie kennen, zurücklassen werden. Vielleicht sind Sie gerade dabei, eine kurze Pause zu machen, um dem näher zu kommen, was Sie wirklich wollen und brauchen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte bedeutet, dass Sie bereit sind für eine Veränderung und diese vielleicht auch brauchen. Wenn Sie in einer Beziehung sind, versuchen Sie dann das Muster, das Sie und Ihre Liebste angehängt haben zu ändern. Nehmen Sie ihn / sie mit an verschiedene Orte, machen Sie andere Dinge, reden Sie über andere Themen, als wie Sie bis jetzt gemacht haben. Veränderung ist jetzt gut.</p><p>Wenn Sie alleine sind und auf der Suche nach einer Beziehung sind, ist es Zeit, um alte Muster hinter sich zu lassen. Suchen Sie nicht nach Liebe in all den alten und vertrauten Orten. Seien Sie abenteuerlich und nehmen Sie nie die Wege, die schon gegangen sind, gehen Sie zu Orten, die neu für Sie sind. Sie können erfolgreicher sein, als Sie erwartet haben!</p>",
                        [GuidanceFields.WORK] : "<p>Wo es Arbeit und Beruf betrifft, so sind Sie in eine neue Phase anbelangt, in ein neues Kapitel. Vielleicht hat man Ihnen neue Aufgaben zugewiesen, haben Sie eine Beförderung erhalten oder vielleicht werden Sie sogar in einer neuen Stellung anfangen. Es ist auch möglich, dass Sie einfach nur neue Wege suchen, um Ihren \"alten\" Job aus einem anderen Winkel anzusehen. Eines ist sicher, lassen Sie alles was vertraut fühlt hinter Sich und gehen Sie neue Herausforderungen und Erfahrungen ein, damit Sie wachsen. Fragen Sie sich, wo Sie sein möchten, was Ihre Ziele sind und vertrauen Sie die Menschen um Sie herum, um Ihnen zu helfen, dorthin zu gelangen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In Ihrem spirituellen Suche haben Sie Sich immer wieder die gleichen alten Fragen gestellt, ohne wirklich befriedigende Antworten zu finden. Jetzt ist die Zeit aufzuhören Fragen zu stellen und anzufangen zu handeln. Ändern Sie Ihre Routinen, hören Sie auf die gleichen alten Bücher zu lesen und die gleichen alten Gespräche zu führen. Sie könnten sogar die Weise wie Sie meditieren und visualisieren ändern! Dies ist eine Zeit zum Experimentieren, für eine Reise durch den spirituellen Bereich. Achten Sie auch besonders auf Ihre Träume, haben sie Nachrichten für Sie? Sie können ihnen Dinge zeigen die nicht klar sind, wenn Sie wach sind.</p>",
                    }
                }
            },
            {
                filename: "56",
                numerology : 7,
                en : {
                    name: "Seven of Crystals", subtitle: "Dullness",
                    suit : "Crystals",
                    element : "🜁 - air",
                    theme : "follow your own course",    
                    description : "Seven of Crystals talks about setting your own course and leaving ideas behind that no longer serve us.",
                    questions : [ "Are you following your own course?", "Which information can you consciously take with you?", "Can you do it decisively and light-heartedly?", "Can you be diplomatic?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about dishonesty in all its forms and appearances. Drawing this card may mean that either you are being badly treated or that you are doing the mistreating yourself. The dishonesty can range from lying over deceit to actual theft or sabotage. If you draw the Seven of Crystals it is important to be watchful and to question people’s motives. But it is also important to look at and question your own motives because you may be the dishonest one right now.</p><p>And maybe the worst case scenario is true and you are deceiving and being deceived at the same time.</p><p>No matter how the situation turns out to be, the time has come to get rid of all the secrecy in your life.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a relationship at this moment, the time has come to ask yourself some tough questions: do you trust your partner, are you having doubts or hesitations, can you yourself be trusted or are you wandering? It is important for you to realize that these questions need to be answered. Engage in an open and honest conversation with your partner, ask the hard questions and answer them if your partner has some of those too. Now is the time to be honest, it can save your relationship. If you are alone and looking for a partner, make sure you are honest and open, don’t play games, don’t act uninterested if the opposite is true. You could lose the perfect partner just because you aren’t being real.</p>",
                        [GuidanceFields.WORK] : "<p>Prepare yourself for some hard times at work because it is possible your boss is taking a very close look at what you do and how you do it. Make sure all you do is above reproach, be the very best you can be. Try not to get mixed up in gossip and conflicts, do not choose sides, be your own person. Be as neutral as you possibly can be. If you are looking for work right now, it may be interesting to approach former colleagues and old friends or contacts, they may have a good lead for you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may be searching right now, you may feel a certain spiritual restlessness. If you are looking for answers, be sure to look for them in the right places. Don’t be tempted to listen to so-called spiritual leaders, don’t take that expensive course, don’t buy those books. Now is the right time to turn inward, to look inside and realize how much knowledge you have already gathered. Work through that knowledge, let it blossom and grow and pick the fruits of your labor. You can teach yourself many valuable lessons right now! </p>",
                    }
                },
                fr : {
                    name : "Le Sept Crystaux", subtitle: "L'ennui",
                    suit : "Crystaux",
                    element :  "🜁 - air",
                    theme : "suivre sa propre conviction",    
                    description : " Le Sept Crystaux, nous parle de suivre notre propre conviction et de la possibilité de laisser derrière nous les opinions qui ne nous conviennent plus.",
                    questions : ["Suivez-vous votre propre conviction?", "Que pouvez-vous délibérément en apprendre?", "Pouvez-vous le faire avec détermination et grâce?", "Pouvez-vous être diplomate?"],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente la malhonnêteté dans toutes ses manifestations. Tirer cette carte peut signifier que vous êtes maltraité ou que vous faites du mal à quelqu'un. La malhonnêteté pourrait être liée à des choses comme la fraude ou même le vol et le sabotage. Si vous tirez le \"Sept d'Crystaux\", il est important d'être vigilant et de remettre en question les motivations des gens. Mais il est également important d'être conscient de vos propres motifs et de les vérifier, car vous pouvez être vous-même le plus malhonnête en ce moment.</p><p>Et peut-être que le pire des cas arrivera et que vous vous séduirez et serez trompé en même temps.</p><p>Quelle que soit la situation, le moment est venu de vous libérer de tous les secrets de votre vie.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a relationship at this moment, the time has come to ask yourself some tough questions: do you trust your partner, are you having doubts or hesitations, can you yourself be trusted or are you wandering? It is important for you to realize that these questions need to be answered. Engage in an open and honest conversation with your partner, ask the hard questions and answer them if your partner has some of those too. Now is the time to be honest, it can save your relationship. If you are alone and looking for a partner, make sure you are honest and open, don’t play games, don’t act uninterested if the opposite is true. You could lose the perfect partner just because you aren’t being real.</p>",
                        [GuidanceFields.WORK] : "<p>Prepare yourself for some hard times at work because it is possible your boss is taking a very close look at what you do and how you do it. Make sure all you do is above reproach, be the very best you can be. Try not to get mixed up in gossip and conflicts, do not choose sides, be your own person. Be as neutral as you possibly can be. If you are looking for work right now, it may be interesting to approach former colleagues and old friends or contacts, they may have a good lead for you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may be searching right now, you may feel a certain spiritual restlessness. If you are looking for answers, be sure to look for them in the right places. Don’t be tempted to listen to so-called spiritual leaders, don’t take that expensive course, don’t buy those books. Now is the right time to turn inward, to look inside and realize how much knowledge you have already gathered. Work through that knowledge, let it blossom and grow and pick the fruits of your labor. You can teach yourself many valuable lessons right now! </p>",
                    }
                },
                sp:{
                    name:"El Siete de Cristales", subtitle: "Aburrimiento",
                    suit: "Cristales",
                    element: "🜁 - aire",
                    theme: "sigues tu propia opinión",    
                    description: "El Siete de Cristales trata de seguir tu propia opinión y nos señala la capacidad de dejar atrás aquellas opiniones que ya no nos convienen",
                    questions: ["¿Sigues tu propia opinión?", "¿Qué puedes llevarte a propósito?", "¿Puedes hacerlo con decisión y ligereza?", "¿Puedes ser diplomático?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es la falta de honradez en todas sus formas y apariencias. Sacando esta tarjeta puede significar que o bien está siendo tratado mal o que está haciendo el maltratar a su mismo. La falta de honradez puede variar de mentir sobre el engaño al robo real o sabotaje. Si saca el Siete de Cristales es importante estar atentos y cuestionar los motivos de la gente. Pero también es importante tener en cuenta y cuestionar sus propios motivos, ya que puede ser el deshonesto en este momento. Y tal vez el peor de los casos es cierto y usted está engañando y siendo engañados al mismo tiempo. No importa cómo la situación resulta ser, ha llegado el momento de deshacerse de todo el secreto en su vida.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Kristallen Zeven", subtitle: "Verveling",
                    suit : "Kristallen",
                    element : "🜁 - lucht",
                    theme : "je eigen mening volgen",    
                    description : "De Zeven van Kristallen gaat over het volgen van je eigen mening en wijst ons op het achter ons kunnen laten van die opvattingen die niet meer bij ons passen.",
                    questions : [ "Volg jij je eigen mening?", "Wat kun jij doelbewust meenemen?", "Kun jij dat met daadkracht en lichtvoetig doen?", "Kun jij diplomatiek zijn?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart heeft het over oneerlijkheid in alle mogelijke vormen en verschijningen. Als je Kristallen Zeven trekt dan is het goed mogelijk dat je op dit moment heel slecht behandeld wordt of dat jij dit aan het doen bent met een ander. De oneerlijkheid waarvan sprake kan gaan van liegen over bedrog tot sabotage en manipulatie. Deze kaart vertelt je dat het belangrijk is om aandachtig en alert te zijn en je altijd vragen te stellen in verband met de motieven van anderen. Het is ook belangrijk dat je aandacht besteedt aan jezelf en jouw motieven want misschien ben jij zelf ook niet echt eerlijk bezig op dit moment.</p><p>In het allerslechtste geval kan het zo zijn dat jij zelf oneerlijk bent en dat iemand anders ook oneerlijk is ten opzichte van jou.</p><p>Maar eigenlijk maakt het niet uit hoe ingewikkeld of eenvoudig de situatie in mekaar zit, het gaat erom dat je er nu, op dit moment, voor moet zorgen dat er geen oneerlijkheid, onduidelijkheid of geheimen meer zijn in je leven.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een relatie hebt dan is de tijd gekomen om jezelf wat moeilijke vragen te stellen: vertrouw jij je partner, heb je twijfels of vragen, ben je zelf betrouwbaar bezig of wijk je af van het rechte pad? Het is belangrijk om aan jezelf toe te geven dat deze en andere vragen echt gesteld en beantwoord moeten worden. Ga een open en eerlijk gesprek aan met je partner, stel die moeilijke vragen en beantwoord ze ook eerlijk als je partner ze aan jou stelt. Nu komt het erop aan om open kaart te spelen, het kan je relatie redden!</p><p>Als je alleen bent en op zoek naar een relatie, zorg er dan voor dat je dit open en eerlijk doet. Speel geen spelletjes, doe niet alsof iemand je niet interesseert als het omgekeerde net waar is. Je zou de ideale partner kunnen missen omdat je niet oprecht overkomt.</p>",
                        [GuidanceFields.WORK] : "<p>In je werksituatie is het op dit moment verstandig om je voor te bereiden op mogelijk zware tijden. Het zit erin dat je meerdere van plan is om je werkmethodes en resultaten van heel nabij te onderzoeken. Zorg ervoor dat alles in orde is, dat zij/hij zo weinig mogelijk op- of aanmerkingen kan maken. Houd zoveel mogelijk afstand van roddel en conflicten, kies niemands kant, houd je bij jezelf. Wees op alle vlakken zo neutraal mogelijk.</p><p>Als je op zoek bent naar werk is het nu een goede tijd om vroegere collega’s en oude contacten te benaderen, je zou er behoorlijk wat kansen mee kunnen creëren.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Waarschijnlijk ben je op dit moment zoekende, voel je een soort spirituele rusteloosheid. Als je op zoek bent naar antwoorden zorg er dan voor dat je dit op de juiste plekken doet. Laat je niet verleiden tot het luisteren naar zogenaamde spirituele leiders, laat die dure cursus of workshop links liggen, koop die boeken die je ontdekt hebt niet. Besteed je tijd nu aan het focussen op jezelf, keer je naar je binnenste, je diepste kern en besef ten volle hoeveel kennis je al vergaard hebt. Verwerk al deze wijsheid, laat ze groeien en bloeien en pluk de vruchten van het harde werk dat je in het verleden verzet hebt. Op dit ogenblik kan je jezelf vele waardevolle lessen leren!</p>",
                    }
                },
                de : {
                    name: "Sieben der Kristalle", subtitle: "Langeweile",
                    suit : "Kristalle",
                    element : "🜁 - Luft",
                    theme : "Seiner eigenen Überzeugung folgen",    
                    description : "Die Sieben der Kristalle spricht darüber, seiner eigenen inneren Überzeugung zu folgen und die Möglichkeit offen zu halten, die Meinungen hinter uns zu lassen,  die uns nicht mehr passen.",
                    questions : [ "Folgen Sie Ihrer eigenen Überzeugung?", "Was können Sie bewusst daraus lernen?", "Können Sie es mit Entschlossenheit und Anmut tun?", "Können Sie diplomatisch sein?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für Unehrlichkeit in allen seinen Erscheinungsformen. Das Ziehen dieser Karte kann bedeuten, dass Sie entweder schlecht behandelt werden, oder dass Sie selbst jemandem Unrecht tun. Die Unehrlichkeit könnte sich auf Dinge wie Betrug oder sogar auf Diebstahl und Sabotage beziehen. Wenn Sie die „Sieben der Kristalle“ ziehen ist es wichtig, wachsam zu sein und die Motive der Menschen in Frage zu stellen. Aber es ist auch wichtig, sich Ihrer eigenen Motive bewusst zu werden und zu überprüfen, da Sie doch selbst in diesem Augenblick der Unehrliche sein könnten.</p><p>Und vielleicht tritt der schlimmste Fall ein und Sie betrügen und werden  gleichzeitig selber betrogen.</p><p>Gleichgültig, wie die Situation ist, jetzt ist die Zeit gekommen, um sich von aller Geheimniskrämerei in Ihrem Leben zu befreien.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie zu diesem Zeitpunkt in einer Beziehung sind, ist die Zeit gekommen, sich einige schwierige Fragen zu fragen: Können Sie Ihren Partner vertrauen. Haben Sie Zweifel oder Bedenken, können Sie sich trauen oder sind Sie beim Wandern? Es ist wichtig, dass Sie erkennen, dass diese Fragen beantwortet werden müssen. Gehen Sie ein offenes und ehrliches Gespräch mit Ihrem Partner ein, fragen Sie die harten Fragen und beantworten Sie sie, wenn Ihr Partner auch einige hat. Jetzt ist die Zeit, um ehrlich zu sein, es kann Ihre Beziehung retten. Wenn Sie alleine und auf der Suche nach einem Partner sind, müssen Sie sicher sein, dass Sie ehrlich und offen sind. Sie sollten nicht spielen, nicht uninteressiert handeln, wenn das Gegenteil wahr ist. Sie könnten den perfekten Partner verlieren, nur weil Sie nicht realistisch sind.</p>",
                        [GuidanceFields.WORK] : "<p>Bereiten Sie sich auf harte Zeiten bei der Arbeit vor, weil es möglich ist, dass Ihr Vorgesetzter genau im Auge behält was Sie tun und wie Sie es tun. Sorgen Sie dafür, dass alles, was Sie tun über jeden Zweifel erhoben ist, machen Sie Ihres Bestes. Versuchen Sie nicht, Sich in Klatsch und Konflikte einzumischen, wählen Sie keine Seiten in eigener Person. Seien Sie so neutral wie Sie nur sein können. Wenn Sie jetzt nach Arbeit suchen, könnte es nützlich sein Kontakt mit ehemaligen Kollegen und alten Freunden aufzunehmen. Sie könnten eine gute Führung für Sie haben.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie können jetzt suchende sein, können eine gewisse spirituelle Unruhe fühlen. Wenn Sie auf der Suche nach Antworten sind, stellen Sie sicher, dass Sie an den richtigen Stellen suchen. Lassen Sie Sich nicht verführen diese sogenannten spirituellen Führer anzuhören, folgen Sie nicht diesen teureren Lehrgang und kaufen Sie nicht diese teureren Bücher. Jetzt ist der richtige Zeitpunkt, um Sich nach innen zu wenden, nach innen zu schauen und zu erkennen, wie viel Wissen Sie bereits gesammelt haben. Arbeiten Sie sich durch dieses Wissen, lassen Sie es blühen und wachsen und ernten Sie die Früchte Ihrer Arbeit. Sie können Sich Selbst viele wertvolle Lektionen lernen, gerade jetzt!</p>",
                    }
                }
            },
            {
                filename: "57",
                numerology : 8,
                en : {
                    name: "Eight of Crystals", subtitle: "Synthesis",
                    suit : "Crystals",
                    element : "🜁 - air",
                    theme : "taking responsibility for what you think",    
                    description : "Eight of Crystals talks about taking responsibility for what we think and that we can either block or detach ourselves. Cause and effect.",
                    questions : [ "Are you thinking you cannot get there?", "Are you sabotaging yourself by your thoughts?", "Can you detach from it?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Eight of Crystals is about being stuck and generally you are stuck because of your own doubts and fears. You are not allowing yourself to evolve, to grow, to change. There are oppurtunities and chances waiting for you and you are standing still, afraid to move towards them. You are being dictated by “Yes, but …”.</p><p>Now is the time to confront yourself with your fears and doubts and to find out where they are coming from. You will probably see that there is no need for them, that you are holding yourself back because of an illusion, like the monsters you thought were living under your bed when you were small.</p><p>Try to answer the question why you don’t allow yourself to completely be the person you could and should be, without paralyzing inhibitions or fears.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a relationship, now is the time to ask yourself: why am I here, with this person? Maybe you are staying in this relationship because you are afraid of being lonely. Maybe you feel insecure, not strong enough to do it all by yourself. Or maybe you think you can’t do better, as if this is as good as it gets even if it’s not really good. If you recognize one of these motives, you should thoroughly evaluate your relationship and decide if you really and truly want to be in it. Being alone isn’t that bad, and it sure is better than staying in a relationship you don’t believe in.</p><p>If you are single right now, take a moment to evaluate your life for yourself: do you really like yourself, are you happy with who you are? If the answer is yes, you are ready for a relationship. </p>",
                        [GuidanceFields.WORK] : "<p>The time has come to honestly and openly look at yourself and your job or career and ask yourself if you really want to stay where you are right now. Do you love your job? Do you wake up smiling every morning because you are looking forward to going to work? Or are you dragging your feet, wishing you could stay in bed, wondering why you are doing this job anyway? If you are unhappy with your work, you should realize and accept that you are the only one who has the power to change this situation. The decision is yours: either you stay where you are and accept it for what it is, or you go out and find another job. What will it be?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may be feeling insecure, in doubt, wondering who to be and what to feel. You may have a lot of questions, not knowing where to find the right answers. The answers you are looking for are to be found within yourself, you have all the keys. Try to connect to the universal energy by meditating, being alone with yourself, concentrating on your core being. Be open to signs and remember those signs may be as mundane as hearing a song on the radio or seeing the same number everywhere. Focus on the fact that the only one holding you back right now is you. Allow those insights in!</p>",
                    }
                },
                fr : {
                    name : "Le Huit d’Crystaux", subtitle: "Synthèse",
                    suit : "Crystaux",
                    element :  "🜁 - air",
                    theme : " Prendre la responsabilité de ce que l'on pense",    
                    description : " Le Huit d’Crystaux, nous parle de prendre la responsabilité de ce que l’on pense et que nous pouvons nous enfermer ou nous libérer. Cause & Conséquence",
                    questions : ["Etes-vous fixés dans votre pensée?", "Vous enfermez-vous dans vos  pensées?", "Pouvez-vous vous en détacher?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Huit d'Crystaux\" souligne que vous vous bloquez habituellement par vos propres doutes et peurs. Vous ne vous permettez pas de vous développer, de grandir, de changer. Il y a des possibilités et des opportunités qui vous attendent, mais vous restez immobile parce que vous avez peur d'aller vers elles. Vous êtes dominé par \"Oui, mais ....\".</p><p>C'est le moment de faire face à vos peurs et à vos doutes et de découvrir d'où ils viennent. Vous constaterez probablement qu'il n'y a aucune raison pour eux de se retenir à cause d'une illusion qui est comme un monstre qu'ils croyaient quand ils étaient enfants et qui vivaient sous votre lit.</p><p>Essayez de répondre à la question suivante : Pourquoi ne pas vous permettre d'être la personne que vous pourriez et devriez être sans paralyser les inhibitions et les peurs?</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a relationship, now is the time to ask yourself: why am I here, with this person? Maybe you are staying in this relationship because you are afraid of being lonely. Maybe you feel insecure, not strong enough to do it all by yourself. Or maybe you think you can’t do better, as if this is as good as it gets even if it’s not really good. If you recognize one of these motives, you should thoroughly evaluate your relationship and decide if you really and truly want to be in it. Being alone isn’t that bad, and it sure is better than staying in a relationship you don’t believe in.</p><p>If you are single right now, take a moment to evaluate your life for yourself: do you really like yourself, are you happy with who you are? If the answer is yes, you are ready for a relationship. </p>",
                        [GuidanceFields.WORK] : "<p>The time has come to honestly and openly look at yourself and your job or career and ask yourself if you really want to stay where you are right now. Do you love your job? Do you wake up smiling every morning because you are looking forward to going to work? Or are you dragging your feet, wishing you could stay in bed, wondering why you are doing this job anyway? If you are unhappy with your work, you should realize and accept that you are the only one who has the power to change this situation. The decision is yours: either you stay where you are and accept it for what it is, or you go out and find another job. What will it be?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may be feeling insecure, in doubt, wondering who to be and what to feel. You may have a lot of questions, not knowing where to find the right answers. The answers you are looking for are to be found within yourself, you have all the keys. Try to connect to the universal energy by meditating, being alone with yourself, concentrating on your core being. Be open to signs and remember those signs may be as mundane as hearing a song on the radio or seeing the same number everywhere. Focus on the fact that the only one holding you back right now is you. Allow those insights in!</p>",
                    }
                },
                sp:{
                    name:"El Ocho de Cristales", subtitle: "Síntesis",
                    suit: "Cristales",
                    element: "🜁 - aire",
                    theme: "Asumir la responsabilidad de lo que piensas",    
                    description: "El Ocho de Cristales se trata de asumir la responsabilidad de lo que piensas y nos recuerda que podemos encerrarnos y liberarnos. Causa y Consecuencia",
                    questions: ["¿Crees que no puedes hacerlo de todos modos?", "¿Te encierras con lo que piensas?", "¿Puedes desconectarte de eso?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La Ocho de Cristales es acerca de ser atrapado y generalmente usted está atascado debido a sus propias dudas y temores. No se permiten evolucionar, de crecer, de cambiar. Hay oportunidades y posibilidades que le esperan y están de pie todavía, miedo de moverse hacia ellos. Usted está siendo dictado por \"Sí, pero ...\". Ahora es el momento de enfrentarse a su mismo con sus miedos y dudas y para averiguar dónde están viniendo. Es probable que vea que no hay necesidad de ellos, que tiene en sus manos a su mismo de nuevo a causa de una ilusión, como los monstruos que pensaba vivían debajo de tu cama cuando eras pequeño. Trate de responder a la pregunta de por qué no permite ser completamente a la persona que podría y debería ser, sin paralizar inhibiciones ni temores.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Kristallen Acht", subtitle: "Synthese",
                    suit : "Kristallen",
                    element : "🜁 - lucht",
                    theme : "verantwoording nemen voor wat je denkt",    
                    description : "De Acht van Kristallen gaat over verantwoording nemen voor wat je denkt en wijst ons er op dat we ons zelf én vast kunnen zetten én los kunnen maken. Oorzaak & Gevolg.",
                    questions : [ "Denk jij dat je het toch niet kan?", "Zet jij jezelf vast door dat wat je denkt?", "Kun jij je daarvan losmaken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Acht van Kristallen heeft het over vastzitten. Over het algemeen word je geblokkeerd door je eigen twijfels en angsten. Je staat jezelf niet toe om te groeien, te evolueren, te veranderen. De mogelijkheden en kansen lachen je toe en jij blijft stilstaan. Je bent bang om ze te grijpen, je wordt overheerst door “Ja maar ...”.</p><p>De tijd is nu rijp om jezelf te confronteren met je angsten en twijfels en je af te vragen waar ze vandaan komen. Het is heel waarschijnlijk dat je ontdekt dat ze nergens voor nodig zijn, dat je jezelf tegenhoudt vanwege een fantasie, een illusie. Het is net als toen je klein was en je echt geloofde dat er monsters onder je bed of in de kast zaten.</p><p>Probeer de volgende vraag te beantwoorden: waarom sta jij jezelf niet toe om helemaal die persoon te zijn die je zou kunnen en moeten zijn? Waarom sta jij jezelf toe om verlamd te zijn door angsten en onzekerheden?</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je een partner hebt, stel jezelf dan de volgende vraag: waarom ben ik hier, met deze persoon? Misschien blijf je in deze relatie zitten omdat je bang bent voor eenzaamheid. Misschien voel je je onzeker, vrees je ervoor dat je niet sterk genoeg bent om het alleen te redden. Of misschien denk je dat je niets beters kan krijgen, dat dit het best mogelijke is, ook al is het niet echt fantastisch. Als je één van deze motieven herkent bij jezelf, evalueer je relatie dan eens grondig, beslis nu voor eens en altijd of dit het is wat je echt wil. Alleen zijn is helemaal niet zo erg, en het is zeker beter dan blijven hangen in een relatie die je eigenlijk niet wil en waar je niet in gelooft.</p><p>Als je alleen bent, neem dan eens de tijd om je huidige leven grondig te evalueren: vind jij jezelf echt ok, ben je gelukkig met wie je bent? Als het antwoord ja is, dan ben je er klaar voor om op zoek te gaan naar een partner.</p>",
                        [GuidanceFields.WORK] : "<p>Neem nu even de tijd om jezelf, je baan en je carrière open en eerlijk te bekijken en je dan de vraag te stellen of je echt wel op deze plek wil zijn en ook blijven. Houd je van je baan? Word je ‘s ochtends vrolijk wakker en ben je blij dat je aan de slag kan gaan? Of sleep je jezelf naar het werk, en zou je eigenlijk liever in bed blijven liggen, vraag je je elke dag af waarom je deze job ook alweer gekozen hebt? Als je niet gelukkig bent met je baan dan is het belangrijk dat je beseft en ook aanvaardt dat jij de enige bent die de macht heeft om deze situatie te veranderen. Het is jouw beslissing: of je blijft waar je nu bent en je aanvaardt het zoals het is met alles erop en eraan, of je gaat op zoek naar een andere baan? Wat is jouw keuze?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het is best mogelijk dat je je wat onzeker voelt, dat je twijfels hebt, je afvraagt wie je wil zijn en wat je wil voelen. Je zit misschien vol met vragen en je weet niet goed waar je de juiste antwoorden moet gaan zoeken. En net deze antwoorden kan je enkel binnenin jezelf vinden. <strong>Jij</strong> hebt alle sleutels in handen. Probeer een verbinding tot stand te brengen met de universele energie, ga mediteren, wees alleen met jezelf, concentreer je op je diepste innerlijke kern. Sta open voor tekens en signalen en denk eraan dat deze tekens zo simpel kunnen zijn als een bepaald liedje horen op de radio of hetzelfde cijfer overal zien. Concentreer je op het gegeven dat jij zelf de enige bent die je tegenhoudt. Laat dat inzicht binnenstromen, geef het de ruimte!</p>",
                    }
                },
                de : {
                    name: "Acht der Kristalle", subtitle: "Synthese",
                    suit : "Kristalle",
                    element : "🜁 - Luft",
                    theme : "Die Verantwortung dafür übernehmen, was man denkt.",    
                    description : "Die Acht der Kristalle spricht darüber, die Verantwortung für das übernehmen, was wir denken und dass wir uns entweder einschließen oder befreien können. Ursache & Wirkung",
                    questions : [ "Sind Sie in Ihren Gedanken fixiert?", "Verschliessen Sie sich in Ihren Gedanken?", "Können Sie sich von ihnen lösen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Acht der Kristalle“ verweist darauf, dass Sie sich in der Regel durch Ihre eigenen Zweifel und Ihre eigenen Ängste blockieren. Sie erlauben es sich nicht sich zu entwickeln, zu wachsen, sich zu verändern. Es gibt Möglichkeiten und Chancen, die auf Sie warten, aber Sie stehen still, weil Sie Angst davor haben, sich auf sie zu zubewegen. Sie werden beherrscht vom \"Ja, aber ...\".</p><p>Jetzt ist die Zeit gekommen, um sich mit Ihren Ängsten und Zweifeln auseinanderzusetzen und um herauszufinden, woher sie kommen. Sie werden wahrscheinlich feststellen, dass es keinen Grund für sie gibt, dass Sie sich selbst zurück halten wegen einer Illusion, die wie ein Monster ist, von dem sie als Kind geglaubt haben, dass es unter Ihrem Bett lebt.</p><p>Versuchen Sie, folgende Frage zu beantworten: Warum gestatten Sie sich selbst nicht, die Person zu sein, die Sie sein könnten und sollten, ohne lähmende Hemmungen und Ängste?</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer Beziehung sind, ist jetzt die Zeit, sich zu fragen: warum bin ich hier, mit diesem Menschen? Vielleicht wollen Sie in dieser Beziehung bleiben, weil Sie Angst haben, einsam zu sein. Vielleicht fühlen Sie Sich unsicher, nicht stark genug, um alles alleine zu tun. Oder Sie denken, es könnte nicht mehr werden als jetzt, auch wenn es eigentlich nicht wirklich gut geht. Wenn Sie eines dieser Motive erkennen, sollten Sie sorgfältig Ihre Beziehung überprüfen und entscheiden, ob Sie wirklich und wahrhaftig in dieser Beziehung sein wollen. Allein zu sein ist nicht so schlimm, und es ist immerhin besser als in einer Beziehung zu sein an der Sie nicht glauben.</p><p>Wenn Sie jetzt alleine sind, nehmen Sie sich dann einen Moment Zeit, um Ihr Leben für Sich Selbst zu beurteilen: mögen Sie Sich Selbst gerne, sind Sie mit Sich Selbst zufrieden? Wenn die Antwort ja ist, sind Sie bereit eine Beziehung einzugehen.</p>",
                        [GuidanceFields.WORK] : "<p>Die Zeit ist gekommen, um offen und ehrlich zu Sich Selbst und Ihrem Job oder Karriere zu schauen und Sich zu fragen, ob Sie wirklich bleiben wollen, wo Sie gerade sind. Lieben Sie Ihre Arbeit? Wachen Sie jeden Morgen lächelnd auf, weil Sie Sich bereits freuen auf dem Weg zur Arbeit? Oder wünschten Sie, Sie könnten im Bett bleiben, fragen Sie Sich, warum Sie diese Arbeit überhaupt tun? Wenn Sie mit Ihrer Arbeit zufrieden sind, sollten Sie wissen und akzeptieren, dass Sie der Einzige sind, der die Macht hat, diese Situation zu ändern. Die Entscheidung liegt bei Ihnen: Entweder Sie bleiben, wo Sie sind und Sie akzeptieren es so wie es ist, oder Sie suchen Sich einen anderen Job. Was wird es sein?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Möglicherweise fühlen Sie Sich unsicher, fragen Sie Sich in Verzweiflung, wer Sie sind und was Sie fühlen. Sie könnten eine Menge Fragen haben, ohne zu wissen, wo Sie die richtigen Antworten finden könnten. Die Antworten, die Sie suchen sind in Ihnen Selbst, Sie besitzen alle Schlüssel. Versuchen Sie, mit der universellen Energie durch Meditation in Verbindung zu kommen, allein zu sein mit Sich Selbst, konzentrieren Sie Sich auf Ihren Kern. Seien Sie offen für Zeichen und denken Sie daran, dass dieses Zeichen so banal sein kann als das Hören eines Liedes im Radio oder die ganze Zeit die gleiche Zahl sehen. Konzentrieren Sie Sich auf der Tatsache, dass der einzige der im Moment, zurückhält sie selber sind. Laden Sie diese Erkenntnisse ein!</p>",
                    }
                }
            },
            {
                filename: "58",
                numerology : 9,
                en : {
                    name: "Nine of Crystals", subtitle: "Narrowness",
                    suit : "Crystals",
                    element : "🜁 - air",
                    theme : "knowing what you think",    
                    description : "Nine of Crystals talks about being conscious about what we think and the power to bring peace into it ourselves.",
                    questions : [ "Are you brooding a lot?", "Does it keep you awake?", "Can you appease your mind?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Nine of Crystals is about sleepless nights caused by worrying and anxiety. You have a lot on your mind and you are feeling really down. Maybe you even have a guilty conscience that is keeping you awake at night.</p><p>Chances are that you are looking for people and circumstances that may or may not cause the way you are feeling. Forget about the rest of the world and focus on yourself and what you can do to change the way you are feeling. Even if there is a relationship or situation that causes you grief, it is up to you to analyze what is happening and what you can do to change it.</p><p>Maybe the way life feels right now makes you think “Is this all there is, shouldn’t there be more?”. If this is the case, try and concentrate on the small things that are going right, that can give you joy and inner peace. Often the beauty of life lies in the ordinary things.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Nine of Crystals is about doubts and hesitations. Are you afraid of being left behind? Or are you considering to break off a relationship before your partner can do so? Or maybe you have a feeling that something is not quite right but you’re afraid to ask. Now is not the time to ignore your gut feelings. On the contrary, you should act on them. Talk to your partner, ask those difficult questions, insist on getting answers. But don’t stay suspicious once you get those answers. If you can’t trust your partner to answer honestly, then maybe you shouldn’t be together.</p><p>If you have just started a relationship, it is important to accept that being able to really trust someone takes time. Give yourself a chance to let the relationship and the trust grow slowly and steadily.</p>",
                        [GuidanceFields.WORK] : "<p>You may be feeling depressed and down. Are these feelings caused by the contents of your job, or are you having trouble with a colleague or a superior? Or maybe you feel paralyzed by fear of failure? Where work is concerned there are many factors you can’t control: you can’t change your colleagues, you can’t assign a new boss, you can’t choose your tasks. But there are still a few things you do control, and now it’s time to start focusing on those. Let your worries and fears go, leave them behind and focus on the things you can change.</p><p>If you are looking for a job now is a good time to find some help. Talk to friends, neighbors and acquaintances, one of them may have the perfect contact, the perfect opening for you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>For the moment it may feel as if you’ve been thinking and brooding 24/7. Maybe you have the impression you aren’t able to stop your brain from worrying. And it’s not only about real conflicts and challenges, but also about imaginary ones. Maybe you are even creating a good deal of problems out of thin air. It could be an idea to try and use your spirituality to stop this negative pattern. Meditation may be the perfect way to help calm and soothe your never ending thoughts, possibly even put a stop to them. If you are ‘too far gone’ to just pick up meditation, you could try and find a book (f.e. a book of quotes) that helps you relax. Or take a yoga class. Do whatever it takes to feed your brain positive thoughts and energy, it’s urgently needed!</p>",
                    }
                },
                fr : {
                    name : "Le Neuf d’ Crystaux", subtitle: "Détresse",
                    suit : "Crystaux",
                    element :  "🜁 - air",
                    theme : "savoir ce que l'on pense",    
                    description : "Le Neuf d'Crystaux, nous parle d’être conscient de ce que l’on pense et que nous pouvons nous même apaiser la situation.",
                    questions : ["Vous inquiétez-vous beaucoup?", "En avez-vous des nuits blanches?", "Pouvez-vous calmer vos pensées?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Les \"Neuf d'Crystaux\" représentent des nuits d'insomnie causées par l'inquiétude et la peur. Vous avez beaucoup de choses en tête et vous vous sentez vraiment déprimé. Vous pouvez même avoir une conscience coupable qui vous empêche de dormir la nuit.</p><p>Il est possible que vous soyez à la recherche des personnes et des circonstances qui pourraient être à l'origine de ce sentiment. Oubliez le reste du monde et concentrez-vous sur vous-même : Que pouvez-vous changer dans vos sentiments? Même s'il y a une relation ou une situation qui vous cause du chagrin, c'est à vous d'analyser ce qui se passe et de découvrir ce que vous pouvez faire pour la changer.</p><p>Peut-être que la façon dont vous vous sentez maintenant vous amène à la question : \"C'est tout ce qu'il y a, ne devrait-il pas y en avoir plus? Si c'est le cas, essayez de vous concentrer sur les petites choses qui vont bien. Cela pourrait vous donner la joie et la paix intérieure. Souvent, la beauté de la vie réside dans les bonnes choses de la vie quotidienne, la divinité réside dans les détails.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Nine of Crystals is about doubts and hesitations. Are you afraid of being left behind? Or are you considering to break off a relationship before your partner can do so? Or maybe you have a feeling that something is not quite right but you’re afraid to ask. Now is not the time to ignore your gut feelings. On the contrary, you should act on them. Talk to your partner, ask those difficult questions, insist on getting answers. But don’t stay suspicious once you get those answers. If you can’t trust your partner to answer honestly, then maybe you shouldn’t be together.</p><p>If you have just started a relationship, it is important to accept that being able to really trust someone takes time. Give yourself a chance to let the relationship and the trust grow slowly and steadily.</p>",
                        [GuidanceFields.WORK] : "<p>You may be feeling depressed and down. Are these feelings caused by the contents of your job, or are you having trouble with a colleague or a superior? Or maybe you feel paralyzed by fear of failure? Where work is concerned there are many factors you can’t control: you can’t change your colleagues, you can’t assign a new boss, you can’t choose your tasks. But there are still a few things you do control, and now it’s time to start focusing on those. Let your worries and fears go, leave them behind and focus on the things you can change.</p><p>If you are looking for a job now is a good time to find some help. Talk to friends, neighbors and acquaintances, one of them may have the perfect contact, the perfect opening for you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>For the moment it may feel as if you’ve been thinking and brooding 24/7. Maybe you have the impression you aren’t able to stop your brain from worrying. And it’s not only about real conflicts and challenges, but also about imaginary ones. Maybe you are even creating a good deal of problems out of thin air. It could be an idea to try and use your spirituality to stop this negative pattern. Meditation may be the perfect way to help calm and soothe your never ending thoughts, possibly even put a stop to them. If you are ‘too far gone’ to just pick up meditation, you could try and find a book (f.e. a book of quotes) that helps you relax. Or take a yoga class. Do whatever it takes to feed your brain positive thoughts and energy, it’s urgently needed!</p>",
                    }
                },
                sp:{
                    name:"El Nueve de Cristales", subtitle: "Depresión",
                    suit: "Cristales",
                    element: "🜁 - aire",
                    theme: "saber lo que piensas",    
                    description: "El Nueve de Cristales se trata de ser consciente de lo que piensas y nos recuerda que podemos traer la paz a eso nosotros mismos",
                    questions: ["¿Te preocupas mucho?", "¿Te acuestas despierto?", "¿Puedes calmar tu pensamiento?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La Nueve de Cristales se trata de noches de insomnio causados por preocupación y ansiedad. Usted tiene mucho en su mente y se siente realmente mal. Tal vez incluso tiene una conciencia culpable que le mantiene despierto por la noche. Lo más probable es que usted está buscando para las personas y las circunstancias que pueden o no pueden ser la causa de la forma en que se siente. Olvidarse del resto del mundo y centrarse en su mismo y lo que puede hacer para cambiar la forma en que se siente. Incluso si hay una relación o situación que le hace el dolor, le corresponde a usted para analizar lo que está sucediendo y lo que puede hacer para cambiarlo. Tal vez la forma en la vida se siente en este momento te hace pensar \"¿Es esto todo lo que hay, no debe haber más?\". Si este es el caso, tratar de concentrarse en las pequeñas cosas que van bien, que le puede dar alegría y paz interior. A menudo, la belleza de la vida radica en las cosas ordinarias.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Kristallen Negen", subtitle: "Benauwdheid",
                    suit : "Kristallen",
                    element : "🜁 - lucht",
                    theme : "weten wat je denkt",    
                    description : "De Negen van Kristallen gaat over bewust zijn van wat je denkt en wijst ons er op dat we daar zelf rust in kunnen brengen.",
                    questions : [ "Pieker jij veel?", "Lig jij er wakker van?", "Kun jij je denken tot rust brengen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Negen van Kristallen kan wijzen op slapeloze nachten vol gepieker en angsten. Het is mogelijk dat je op dit ogenblik veel aan je hoofd hebt en dat je je gedeprimeerd voelt. Misschien heb je zelfs een stukje een schuldig geweten dat je ’s nachts wakker houdt.</p><p>Toch is het best mogelijk dat je vooral op zoek bent naar mensen en omstandigheden die de oorzaak kunnen zijn van je huidige gevoelens. Probeer de rest van de wereld los te laten en concentreer je op jezelf: wat kan jij doen om je gevoelens te veranderen? Zelfs als het zo is dat je verdriet hebt over een bepaalde relatie of een bepaalde situatie, dan nog kan jij gaan uitzoeken wat er exact aan de hand is en wat jij eraan kan doen om je gevoel erbij te veranderen.</p><p>Misschien voel je je op dit moment zo dat je denkt:”Is dit echt alles wat er is? Zou er niet meer moeten zijn?”. Als dat het geval is, probeer je dan te concentreren op de kleine dingen die wèl goed zitten en waar je vreugde en rust uit kan halen. De schoonheid van het leven zit ‘m dikwijls in de kleine dingen, er schuilt goddelijkheid in vaak onopvallende details.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Kristallen Negen gaat over twijfels en aarzelen. Ben je bang om achtergelaten te worden? Of ben je aan het overwegen om de relatie te verbreken voor je partner ertoe overgaat? Of misschien heb je het gevoel dat er iets helemaal niet goed zit maar ben je te bang om erover te beginnen? Op dit moment mag je je intuïtie echt niet negeren. Praat met je partner, stel de moeilijke vragen, sta erop dat je ook antwoorden krijgt. Maar wees niet meer wantrouwig eens je die antwoorden gekregen hebt. Als je er niet op kan vertrouwen dat je partner je eerlijk antwoordt, dan horen jullie misschien niet (meer) samen te zijn.</p><p>Als je net aan een nieuwe relatie begonnen bent, dan is het belangrijk dat je aanvaardt dat het tijd kost om vertrouwen op te bouwen. Geef jezelf en je partner de tijd en de ruimte om het vertrouwen èn de relatie langzaam maar zeker te laten groeien.</p>",
                        [GuidanceFields.WORK] : "<p>Best mogelijk dat je je op dit moment een beetje down voelt, gedeprimeerd. Vraag je eens af of deze gevoelens veroorzaakt worden door je job zelf of door problemen met je baas of een collega. Of misschien ben je op het ogenblik verlamd door angst om te falen? In verband met je werksituatie zijn er vele aspecten die je niet onder controle hebt: je kan je collega’s niet veranderen, je kan geen nieuwe baas aanstellen, je hebt je taken niet voor het uitkiezen. Maar toch zijn er een aantal dingen waar je wel invloed op hebt en het is belangrijk dat je nu op die dingen gaat focussen. Laat je zorgen en angsten los en concentreer je op alle aspecten die je wel kan veranderen.</p><p>Als je op zoek bent naar werk dan is het nu de juiste tijd om hulp in te schakelen. Praat met vrienden, buren en kennissen want het is best mogelijk dat er iemand tussen zit die de perfecte contactpersoon heeft voor jou, die de ideale vacature aan je kan doorgeven.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment kan het voelen alsof je dag en nacht aan het nadenken en zelfs piekeren bent. Misschien heb je de indruk dat je niet in staat bent om je brein stil te zetten. Je denkt niet enkel na over echte conflicten en uitdagingen maar je piekert ook over ingebeelde dingen. Het is best mogelijk dat je een aantal problemen zelf aan het creëren bent. Probeer je spiritualiteit te gebruiken om een einde te maken aan dit negatieve patroon. Meditatie kan een goede manier zijn om je tot rust te brengen en je nooit eindigende gedachtenstroom te stoppen. Als je het te moeilijk hebt om te kunnen mediteren, ga dan op zoek naar een goed boek, bv. eentje met citaten. Je kan ook een yogales volgen of een reikibehandeling nemen. Probeer allerlei dingen uit tot je erin slaagt om je brein tot positieve gedachten te brengen, het is dringend nodig!</p>",
                    }
                },
                de : {
                    name: "Neun der Kristalle", subtitle: "Depression",
                    suit : "Kristalle",
                    element : "🜁 - Luft",
                    theme : "Wissen, was man denkt",    
                    description : "Die Neun der Kristalle spricht darüber, sich bewusst zu sein, was man denkt und dass wir die Situation selbst beruhigen können.",
                    questions : [ "Machen Sie sich große Sorgen?", "Haben Sie schlaflose Nächte?", "Können Sie Ihre Gedanken beruhigen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Neun der Kristalle“ steht für durch Sorgen und Ängste verursachte schlaflose Nächte. Sie haben eine Menge auf dem Herzen und Sie fühlen sich richtig bedrückt. Vielleicht haben Sie sogar ein schlechtes Gewissen, das Sie nachts wach hält.</p><p>Es könnte möglich sein, dass Sie Menschen und Umstände suchen, die möglicherweise die Ursache für dieses Gefühl sind. Vergessen Sie den Rest der Welt und konzentrieren Sie sich auf sich selbst: Was können Sie an Ihrem Gefühl ändern? Auch wenn es eine Beziehung oder eine Situation gibt, die Ihnen Kummer bereitet, ist es an Ihnen, zu analysieren, was geschieht um heraus zu finden, was Sie tun können, um dies zu ändern.</p><p>Vielleicht führt die Art und Weise, wie Sie sich jetzt fühlen, Sie zu folgender Frage: „Ist das alles, was es gibt, sollte da nicht mehr sein?” Wenn dies der Fall ist versuchen Sie sich auf die kleinen Dinge, die gut gehen, zu konzentrieren. Das könnte Ihnen Freude und inneren Frieden bereiten. Oft liegt die Schönheit des Lebens in den guten Dingen des Alltags, liegt Göttlichkeit im Detail.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Die Neun der Kristalle handelt über Zweifel und Zögern. Haben Sie Angst zurückgelassen zu werden? Oder überlegen Sie Sich eine Beziehung abzubrechen bevor Ihr Partner es tut? Oder vielleicht haben Sie das Gefühl, dass etwas nicht ganz richtig ist, aber Sie haben es noch nicht gewagt Sich zu fragen was? Jetzt ist nicht die Zeit, um Ihr Bauchgefühl zu ignorieren. Im Gegenteil, Sie sollten darauf hören. Sprechen Sie mit Ihrem Partner, fragen Sie diese schwierigen Fragen, bestehen Sie auf Antworten. Aber bleiben Sie nicht misstrauisch, wenn Sie die Antworten bekommen. Wenn Sie Ihren Partner nicht zutrauen ehrlich zu antworten, dann könnten Sie vielleicht nicht zusammen sein.</p><p>Wenn Sie nur gerade eben eine Beziehung angefangen haben, ist es wichtig zu akzeptieren, dass wenn man einen anderen wirklich vertrauen will und kann, das Zeit braucht. Gönnen Sie sich eine Chance die Beziehung und das Vertrauen langsam und stetig wachsen zu lassen.</p>",
                        [GuidanceFields.WORK] : "<p>Möglicherweise fühlen Sie Sich deprimiert. Werden diese Gefühle von Ihrer Arbeit verursacht, oder haben Sie Probleme mit einem Kollegen oder Vorgesetzten? Oder vielleicht sind Sie durch Versagensangst gelähmt? So weit es die Arbeit betrifft es viele Faktoren, die Sie nicht beeinflussen können, Sie können Ihre Kollegen nicht ändern, Sie können Sie nicht einen neuen Chef zuordnen, Sie können nicht Ihre Aufgaben wählen. Aber es gibt doch ein paar Dinge, die Sie kontrollieren können und jetzt ist es Zeit anzufangen sich darauf zu konzentrieren Lassen Sie Ihre Sorgen und Ängste gehen, lassen Sie diese hinter Sich und konzentrieren Sie Sich auf die Dinge, die Sie ändern könnten.</p><p>Wenn Sie auf der Suche nach einem Job sind, ist jetzt ein guter Zeitpunkt, um etwas Hilfe zu finden. Sprechen Sie mit Freunden, Nachbarn und Bekannten, einer von ihnen könnte den perfekten Kontakt, die perfekte Eröffnung für Sie haben.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Zur Zeit kann es fühlen, als hätten Sie darüber Tag und Nacht nachgedacht und gebrütet. Vielleicht haben Sie den Eindruck, Sie sind nicht in der Lage Ihr Gehirn aufhören zu lassen sich Sorgen zu machen. Und es betrifft nicht nur wirkliche Konflikte und Herausforderungen, aber auch eingebildete. Vielleicht schaffen Sie sogar ein großes Teil dieser Probleme aus dem Nichts. Es könnte eine Idee sein, zu versuchen Ihre Spiritualität anzuwenden, um diese Negativität zu stoppen. Meditation kann der perfekte Weg sein um zu helfen, Ruhe zu bringen in die nie endenden Gedanken, möglicherweise sogar um ein Ende daran zu setzen. Wenn Sie \"zu weit hin sind\", um zur Meditation zu geraten, versuchen Sie dann einen Buch zu finden (z. B. ein Buch mit Zitaten), das Ihnen hilft zu entspannen. Oder nehmen Sie einen Yoga-Kurs. Tun Sie aber alles um Ihr Gehirn zu füttern mit positiven Gedanken und Energie, es ist dringend erforderlich!</p>",
                    }
                }
            },
            {
                filename: "59",
                numerology : 10,
                en : {
                    name: "Ten of Crystals", subtitle: "Delusion",
                    suit : "Crystals",
                    element : "🜁 - air",
                    theme : "brooding a lot",    
                    description : "Ten of Crystals talks about brooding a lot and that humans are deadly over-thinkers.",
                    questions : [ "Are you thinking a lot?", "Do you think you are stuck?", "Can you consciously put an end to it?",],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Firstly this card indicates a powerful, maybe even aggressive, ending and a new beginning. A situation or relationship in your life will come or is coming to an end and possibly this is not your choice. Even if you are deeply unhappy about this change it is important for you to remember that ending a chapter does not mean that the world is coming to an end. Try to relax and let go and trust the universe to guide you to a better future.</p><p>Secondly the Ten of Crystals is also about dishonesty and betrayal. If you are having doubts if someone is trustworthy or not, remember that it’s better to be safe than sorry. If an offer sounds too good to be true, it probably is. If someone is being too nice, they most possibly have ulterior motives.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you get the Ten of Crystals it’s quite possible you are experiencing a breakup or will do so in the near future. Maybe the problems were quietly building while you weren’t paying attention? And then one day you woke up and realized they had become too big, too complicated to solve? Accept that you cannot heal a relationship by yourself, it takes two. Take your losses and let go.</p><p>If you are looking for a relationship these may not be the best of times. When you meet someone who seems to be a good candidate, think twice and then again. They may not be who they seemed to be at first sight.</p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned, now is the time to be attentive, vigilant. It is quite possible you will have to be on the lookout for a new position. This doesn’t necessarily mean you weren’t doing a good job. The fact that you may be no longer needed doesn’t always reflect on the quality of your work. There may be other factors involved.</p><p>But no matter how or what, it is vital that you don’t retreat to the ‘poor me’ position right now. Even if you feel wronged, even if you think there was some backstabbing involved, try to keep an open mind. The future is literally yours now, it is up to you to shape it, so try your hardest to make it a positive and bright one!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Now may be the time that your whole spiritual belief system comes tumbling down around you. The things you thought were true turn out to be false. Gurus, guides you truly believed in seem to have their eyes on your wallet rather than on your wellbeing. The upside is that you can let go of convictions that weren’t good for you anyway. You have a brand new blank slate to fill. This is the opportunity of a lifetime to go out and seek new wisdom. Rely on your instincts this time, they will tell you who to trust!</p>",
                    }
                },
                fr : {
                    name : "Le Dix d’ Crystaux", subtitle: "Tromperie",
                    suit : "Epées",
                    element :  "🜁 - air",
                    theme : "réfléchir énormément",    
                    description : " Le Dix d’ Crystaux, nous parle de beaucoup réfléchir et de détruire en réfléchissant de trop.",
                    questions : [ "Réfléchissez-vous beaucoup?", " Réfléchissez -vous que vous êtes piégés?", "Pouvez-vous consciemment tirer une croix?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Premièrement, cette carte indique une fin puissante, peut-être même agressive, et un nouveau départ. Une situation ou une relation dans votre vie touche à sa fin et ce n'est peut-être pas votre décision. Peut-être êtes-vous profondément mécontent de ce changement. Cependant, il est important de se rappeler que la fin d'un chapitre ne signifie pas nécessairement la fin du monde. Essayez de vous détendre, de lâcher prise et de croire que l'univers vous réserve un avenir meilleur.</p><p>Deuxièmement, le \"Dix d'Crystaux\" parle aussi de malhonnêteté et de trahison. Si vous doutez de la fiabilité d'une personne, rappelez-vous qu'il vaut mieux être du bon côté que d'être blessé. Si une offre semble trop belle pour être vraie, il se peut très bien qu'il en soit ainsi. Si quelqu'un est trop gentil, il a probablement des arrière-pensées.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you get the Ten of Crystals it’s quite possible you are experiencing a breakup or will do so in the near future. Maybe the problems were quietly building while you weren’t paying attention? And then one day you woke up and realized they had become too big, too complicated to solve? Accept that you cannot heal a relationship by yourself, it takes two. Take your losses and let go.</p><p>If you are looking for a relationship these may not be the best of times. When you meet someone who seems to be a good candidate, think twice and then again. They may not be who they seemed to be at first sight.</p>",
                        [GuidanceFields.WORK] : "<p>Where work is concerned, now is the time to be attentive, vigilant. It is quite possible you will have to be on the lookout for a new position. This doesn’t necessarily mean you weren’t doing a good job. The fact that you may be no longer needed doesn’t always reflect on the quality of your work. There may be other factors involved.</p><p>But no matter how or what, it is vital that you don’t retreat to the ‘poor me’ position right now. Even if you feel wronged, even if you think there was some backstabbing involved, try to keep an open mind. The future is literally yours now, it is up to you to shape it, so try your hardest to make it a positive and bright one!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Now may be the time that your whole spiritual belief system comes tumbling down around you. The things you thought were true turn out to be false. Gurus, guides you truly believed in seem to have their eyes on your wallet rather than on your wellbeing. The upside is that you can let go of convictions that weren’t good for you anyway. You have a brand new blank slate to fill. This is the opportunity of a lifetime to go out and seek new wisdom. Rely on your instincts this time, they will tell you who to trust!</p>",
                    }
                },
                sp:{
                    name:"El Diez de Cristales", subtitle: "Engaño",
                    suit: "Cristales",
                    element: "🜁 - aire",
                    theme: "pensar muchísimo",    
                    description: "El Diez de Cristales es sobre un montón de pensamiento y nos señala que la gente tiene el capaz de pensar demasiado en todo el tipo de cosas",
                    questions: ["¿Piensas mucho?", "¿Piensas que no puedes ir más allá?", "¿Puedes poner una línea debajo de eso deliberadamente?","" ],
                    
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>En primer lugar esta carta indica un poderoso, tal vez incluso agresiva, que termina y un nuevo comienzo. Una situación o relación en su vida vendrán o está llegando a su fin y, posiblemente, este no es su opción. Incluso si usted es profundamente infeliz acerca de este cambio es importante que usted recuerde que el fin de un capítulo no significa que el mundo está llegando a su fin. Trate de relajarse y dejarse y confiar en el universo que lo guíe hacia un futuro mejor. En segundo lugar el Diez de Cristales es también acerca de la deshonestidad y traición. Si tiene dudas si alguien es de confianza o no, recuerde que es mejor prevenir que curar. Si una oferta parece demasiado buena para ser verdad, probablemente lo es. Si alguien está siendo demasiado agradable, lo más posiblemente tienen motivos ulteriores.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Kristallen Tien", subtitle: "Misleiding",
                    suit : "Kristallen",
                    element : "🜁 - lucht",
                    theme : "heel veel denken",    
                    description : "De Tien van Kristallen gaat over heel veel denken en wijst ons er op dat wij mensen in staat zijn om van alles kapot te kunnen denken.",
                    questions : [ "Denk jij veel?", "Denk jij dat je geen kant meer op kunt?", "Kun jij er bewust een streep onder zetten?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>In de eerste plaats wijst deze kaart op een krachtig, mogelijk zelfs een agressief eind en een nieuwe start. Een situatie of een relatie in je leven is net gestopt of stopt binnenkort. Het is mogelijk dat dit einde niet jouw keuze was. Misschien ben je zelfs diep ongelukkig over deze verandering. Toch is het belangrijk voor je om eraan te denken dat het einde van een hoofdstuk iets totaal anders is dan het einde van jouw wereld. Probeer je te ontspannen, los te laten, en vertrouw erop dat het universum je de weg zal wijzen naar een betere toekomst.</p><p>In de tweede plaats kan Kristallen Tien ook wijzen op oneerlijkheid en mogelijk zelfs verraad. Als je twijfels hebt over iemands betrouwbaarheid, denk er dan aan dat het beter is om op veilig te spelen dan dat je achteraf spijt krijgt. Als een aanbod of een voorstel te goed klinkt om waar te zijn, dan is het meestal ook zo. Als iemand te vriendelijk of te gedienstig is, dan heeft die persoon vaak andere motieven dan je op het eerste zicht zou denken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je Kristallen Tien krijgt dan is het goed mogelijk dat je op dit moment een relatiebreuk doormaakt of dat er eentje zit aan te komen. Misschien waren de problemen in je relatie zich stilletjes aan het opstapelen terwijl jij met iets anders bezig was? En mogelijk werd je op een dag wakker en besefte je dat de situatie zo ingewikkeld, zo moeilijk geworden was dat geloven in een oplossing misschien niet meer realistisch was. Aanvaard het gegeven dat je een relatie niet in je eentje kan repareren, daar moet je met zijn tweeën voor zijn. Leg je neer bij je verlies en laat los.</p><p>Als je op zoek bent naar een relatie dan is dit er echt niet de beste tijd voor. En als je iemand zou ontmoeten die een goede kandidaat lijkt, denk er dan goed over na en nog eens en nog eens. De persoon die je ontmoet kan zich namelijk totaal anders voordoen dan ‘ie in werkelijkheid is.</p>",
                        [GuidanceFields.WORK] : "<p>Wat je werksituatie betreft, is het belangrijk dat je op dit moment aandachtig en alert bent. Het is goed mogelijk dat je binnenkort misschien zal moeten gaan uitkijken naar een andere baan. Dit betekent niet noodzakelijk dat je je werk niet goed deed, daar gaat het hier waarschijnlijk niet om. Het feit dat men mogelijk niet langer beroep op je wil doen, heeft vaak niets te maken met de kwaliteit van je werk. Er zijn nog tal van andere factoren die meespelen.</p><p>Maar gelijk hoe de situatie in mekaar zit of wat de oorzaken zijn, het is nu erg belangrijk dat je jezelf niet toestaat om “arme ik” te zijn. Zelfs al vind je dat je onrecht is aangedaan, ook al heeft iemand je een mes in de rug gestoken, probeer je geest open te houden. De toekomst behoort jou toe, het is nu aan jou om haar vorm te geven. Doe dus je uiterste best om er een positieve en schitterende toekomst van te maken!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment zou het best kunnen dat je spirituele overtuigingen, wat je gelooft en misschien zelfs zeker denkt te weten, aan het afbrokkelen zijn. Mogelijk blijken bepaalde dingen waar je echt van overtuigd was toch onwaar te zijn. Goeroes, gidsen, raadgevers ... mensen waar je echt in geloofde blijken eerder geïnteresseerd in je geld dan in je spirituele groei. De positieve kant van dit verhaal is dat je de dingen kan gaan loslaten die toch al niet goed voor je waren. Je kan beginnen met een gloednieuwe schone lei. Dit is echt de kans van je leven: trek erop uit en ga op zoek naar wijsheid en inzichten. En vertrouw deze keer op je intuïtie, die zal je vertellen wie je wel en niet kan vertrouwen!</p>",
                    }
                },
                de : {
                    name: "Zehn der Kristalle", subtitle: "Täuschung",
                    suit : "Kristalle",
                    element : "🜁 - Luft",
                    theme : "Gründlich nachdenken",    
                    description : "Die Zehn der Kristalle spricht vom vielem Nachdenken und vom Zerstören, wenn man zu viel nachdenkt.",
                    questions : [ "Denken Sie viel nach?", "Denken Sie darüber nach, dass Sie gefangen sind?", "Können Sie bewusst einen Strich ziehen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Erstens verweist diese Karte auf ein kraftvolles, vielleicht sogar aggressives Ende und auf einen neuen Anfang. Eine Situation oder Beziehung in Ihrem Leben geht zu Ende und möglicherweise ist dies nicht Ihre Entscheidung. Vielleicht sind Sie über diese Veränderung zutiefst unglücklich. Dennoch ist es wichtig, dass Sie daran denken, dass das Ende eines Kapitels nicht unbedingt bedeutet, dass die Welt untergeht. Versuchen Sie sich zu entspannen, lassen Sie los und vertrauen Sie darauf, dass das Universum eine bessere Zukunft für bereithält.</p><p>Zweitens handelt die „Zehn der Kristalle“ auch von Unehrlichkeit und Verrat. Wenn Sie an der Vertrauenswürdigkeit einer Person zweifeln, denken Sie daran, dass es besser ist, auf der sicheren Seite zu sein als zu Schaden zu kommen. Wenn ein Angebot zu gut klingt, um wahr zu sein, dann kann das sehr wohl der Fall sein. Wenn jemand zu nett ist, hat er wahrscheinlich Hintergedanken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie die Zehn der Kristalle bekommen, es ist durchaus möglich, dass Sie gerade in einer Trennung sind oder dass diese in der nahe Zukunft auf Sie zukommt. Vielleicht haben die Probleme sich ruhig aufgebaut, während Sie nicht aufgepasst haben? Und dann sind Sie eines Tages aufgewacht und haben erkennen müssen, dass sie zu groß, zu kompliziert waren um sie noch lösen zu können? Akzeptieren Sie, dass Sie eine Beziehung nicht alleine heilen können, dazu braucht es zwei. Nehmen Sie Ihre Verluste und lassen Sie los.</p><p>Wenn Sie nach einer Beziehung suchen, wird dieser Zeitpunkt möglicherweise nicht der beste aller Zeiten sein. Wenn Sie jemanden begegnen, der ein guter Kandidat zu sein scheint, überlegen Sie Sich zweimal, und dann noch einmal. Es kann sich herausstellen, dass diese Person nicht so richtig sein mag, wie es am ersten Blick aussieht.</p>",
                        [GuidanceFields.WORK] : "<p>Wo es die Arbeit betrifft, ist es jetzt die Zeit aufmerksam und wachsam zu sein. Es ist durchaus möglich, dass Sie auf der Suche gehen müssen nach einer neuen Arbeit. Dies bedeutet nicht unbedingt, dass Sie nicht gut geleistet haben in Ihrem Job, aber dass Sie vielleicht nicht mehr benötigt sind, was nicht immer zurückzuführen ist auf die Qualität Ihrer Arbeit. Möglicherweise gibt es andere Faktoren, die daran zu Grunde liegen.</p><p>Aber egal, wie oder was, es ist es wichtig, dass Sie Sich jetzt nicht auf den \"armen ich\" Position zurückziehen. Auch wenn Sie Unrecht fühlen, auch wenn Sie denken, Sie waren an einer Messerstecherei beteiligt, versuchen Sie trotzdem, einen offenen Geist zu behalten. Die Zukunft ist buchstäblich jetzt die Ihre und es ist es an Ihnen, sie zu gestalten. So versuchen Sie Ihr Bestes, um es zu einer positiven und hellen zu machen!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Jetzt könnte es die Zeit sein, wo Ihr ganzes spirituelles Glaubenssystem um Sie herum einstürzt. Die Dinge, wovon Sie dachten Sie wären richtig, erweisen sich als falsch zu sein. Gurus, die Führer, an denen Sie wirklich geglaubt haben, scheinen Ihre Augen auf Ihren Geldbeutel gerichtet zu haben und nicht auf Ihrem Wohlbefinden. Der Vorteil ist, dass Sie jetzt die Überzeugungen, die sowieso nicht gut für Sie waren, hinter sich lassen können. Sie haben ein brandneues unbeschriebenes Blatt zu füllen. Dies ist die Chance Ihres Lebens auszugehen und neue Weisheit zu suchen. Verlassen Sie sich auf Ihrem Instinkt; dieses Mal es wird Ihnen sagen, wem man vertrauen kann!</p>",
                    }
                }
            },
            {
                filename: "60",
                numerology : 11,
                en : {
                    name: "Child of Crystals", subtitle: "Learner",
                    court : "Page",
                    suit : "Crystals",
                    element : [ "🜁 - air", "🜃 - earth" ],
                    theme : "having an impartial look at what you think",    
                    description : "Page of Crystals talks about impartially gathering information and to see it with a child-like light-heartedness.",
                    questions : [ "Are you eager to learn?", "Dare you ask questions?", "Do you decide for yourself?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>In Tarot all Page cards stand for opportunities. The Page of Crystals tells you that you should be open for new insights and clarity. If you are in a conflict with someone now is the time you will suddenly understand what went wrong and why it went wrong. Now truly is the time when you will be learning from your experiences.</p><p>This card also tells you that you may have a lot of energy nowadays and you may be using it in ways that bother the people around you. Ask yourself if you are not pushing to hard, being too straight forward, possibly the people around you take offence of the comments you make. And maybe you don’t find it easy yourself to accept the criticism you get from others.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In your personal relationships there’s clearly tension in the air. Unresolved conflicts are surfacing, small frictions you didn’t address, demand your attention now. The choice is yours: do you want to let sleeping dogs lie even if they seem to be half awake already? Or do you want to confront the problems with the risk of not being able to solve them? The Page of Crystals tells you that any choice you make will have consequences. Even if you should decide to do nothing at all, things will change anyway. There’s no way of pleasing everyone. Right now the best way is to go where your energy tells you to go, follow your intuition, do what you feel is best.</p><p>If you are single, now is the time to come out of your shell. Love won’t come knocking at your door, you will have to go out and look for it!</p>",
                        [GuidanceFields.WORK] : "<p>If you have a job right now, expect some conflict, some problems. Remind yourself that for some people the truth is too hard to hear and behave accordingly. You don’t have to speak your mind every time, sometimes it’s better to observe others and find out which way the wind is blowing.</p><p>If you are looking for a job, you might want to tone it down a notch in your interviews. Listen to people, show them you are open to other visions and beliefs than yours. Make it clear you can easily adapt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>As the song goes: free your mind and the rest will follow. Go explore the spiritual world with the same enthusiasm you usually reserve for worldly matters. Find books to read, people to talk to, meditations to try out. Gather as much knowledge as you can and find people to discuss it with. By exchanging information, insights and opinions you will feel yourself growing towards true spiritual experiences. This growth will have a profound influence on other aspects of your life.</p>",
                    }
                },
                fr : {
                    name : "L'enfant des Crystaux", subtitle: "Apprenti",
                    court : "Valet",
                    suit : "Crystaux",
                    element : ["🜁 - air", "🜃 - terre" ],
                    theme : "Regarder ce que l'on pense sans préjugé",    
                    description : " Le Valet des Crystaux, nous parle de collecter de l’information impartiale et de les regarder avec un esprit ouvert et enfantin.",
                    questions : [ "Etes-vous curieux?", " Osez-vous poser des questions?", "Décidez- vous vous-même?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Dans le tarot, toutes les cartes symbolisent des chances. La carte \"Valet d'Crystaux\" vous dit d'être ouvert à toutes les nouvelles idées et à la clarté. Si vous avez un conflit avec quelqu'un, vous comprendrez soudainement ce qui a mal tourné et pourquoi. C'est vraiment le moment d'apprendre de vos expériences.</p><p>Cette carte vous montre aussi que vous avez beaucoup d'énergie en ce moment. C'est généralement une bonne chose, mais vous pouvez utiliser cette énergie d'une manière qui pourrait déranger les gens. Demandez-vous si vous n'exercez pas trop de pression ou si vous êtes trop direct. Peut-être les gens seront-ils offensés par vos commentaires. Soyons honnêtes, vous ne trouvez pas facile non plus d'accepter les critiques.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In your personal relationships there’s clearly tension in the air. Unresolved conflicts are surfacing, small frictions you didn’t address, demand your attention now. The choice is yours: do you want to let sleeping dogs lie even if they seem to be half awake already? Or do you want to confront the problems with the risk of not being able to solve them? The Page of Crystals tells you that any choice you make will have consequences. Even if you should decide to do nothing at all, things will change anyway. There’s no way of pleasing everyone. Right now the best way is to go where your energy tells you to go, follow your intuition, do what you feel is best.</p><p>If you are single, now is the time to come out of your shell. Love won’t come knocking at your door, you will have to go out and look for it!</p>",
                        [GuidanceFields.WORK] : "<p>If you have a job right now, expect some conflict, some problems. Remind yourself that for some people the truth is too hard to hear and behave accordingly. You don’t have to speak your mind every time, sometimes it’s better to observe others and find out which way the wind is blowing.</p><p>If you are looking for a job, you might want to tone it down a notch in your interviews. Listen to people, show them you are open to other visions and beliefs than yours. Make it clear you can easily adapt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>As the song goes: free your mind and the rest will follow. Go explore the spiritual world with the same enthusiasm you usually reserve for worldly matters. Find books to read, people to talk to, meditations to try out. Gather as much knowledge as you can and find people to discuss it with. By exchanging information, insights and opinions you will feel yourself growing towards true spiritual experiences. This growth will have a profound influence on other aspects of your life.</p>",
                    }
                },
                sp:{
                    name:"El Niño de Cristales", subtitle: "Aprendiz",
                    court: "Soto",
                    suit: "Cristales",
                    element: ["🜁 - aire", "🜃 - tierra" ], 
                    theme: "mirar lo que piensas con la mente abierta",    
                    description: "El Soto de Cristales se trata de recopilar información imparcial y nos recuerda que podemos mirarla con una mentalidad infantil y abierta",
                    questions: ["¿Estás ansioso por aprender?", "¿Te atreves a hacer preguntas?", "¿Decides por ti mismo?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>En el Tarot representan todas las tarjetas de Paje de oportunidades. El Paje de Cristales le dice que usted debe estar abierto a nuevas ideas y la claridad. Si usted está en un conflicto con alguien ahora es el tiempo que va a entender de repente lo que salió mal y por qué salió mal. Ahora realmente es el momento en que usted va a aprender de sus experiencias.</p><p>Esta tarjeta también le dice que usted puede tener una gran cantidad de energía en la actualidad y puede que esté utilizando en formas que molestan a la gente que te rodea. Pregúntese si usted no está empujando a duro, demasiado sencillo, posiblemente las personas que te rodean se ofenden de los comentarios que usted hace. Y tal vez no lo encuentra fácil a ti mismo para aceptar las críticas que recibe de los demás.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                    
                nl : {
                    name: "Kind van Kristallen", subtitle: "Leerling",
                    court : "Kind",
                    suit : "Kristallen",
                    element : [ "🜁 - lucht", "🜃 - aarde" ],
                    theme : "onbevooroordeeld kijken naar wat je denkt",    
                    description : "De Page van Kristallen gaat over onbevooroordeeld informatie verzamelen en wijst ons er op dat we daar met kinderlijke onbevangenheid naar mogen kijken.",
                    questions : [ "Ben jij leergierig?", "Durf jij vragen te stellen?", "Beslis jij lekker zelf?" ], 
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>In de Tarot betekenen de Pagekaarten altijd nieuwe mogelijkheden en kansen. De Page van Kristallen vertelt je dat het op dit moment belangrijk is om open te staan voor nieuwe inzichten en helderheid. Als je een conflict hebt met iemand, dan zal je nu ineens begrijpen wat er fout gegaan is en waarom dat gebeurd is. De tijd is nu echt rijp om te leren van je ervaringen.</p><p>Als je deze kaart krijgt, is het ook mogelijk dat je heel veel energie hebt op dit moment. Over het algemeen is dit een goede zaak maar het is altijd mogelijk dat je deze energie op zo'n manier gebruikt dat de mensen om je heen er last van hebben. Vraag je eens af of je sommige dingen niet te hard aan het doordrukken bent of misschien ben je te duidelijk, te openhartig. En laten we eerlijk zijn, je vindt het zelf ook niet altijd gemakkelijk om de kritiek die je krijgt ook te aanvaarden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Er hangt duidelijk nogal wat spanning in de lucht in je persoonlijke relaties. Onopgeloste conflicten komen boven, lichte wrijvingen die je niet aangepakt hebt, vragen nu je aandacht. De keuze is aan jou: besluit je om geen slapende honden wakker te maken terwijl ze eigenlijk al één oog open hebben? Of ga je de confrontatie aan ook al loop je het risico dat je de problemen mogelijk niet kan oplossen? De Page van Kristallen vertelt je dat elke keuze die je maakt ook gevolgen zal hebben. Zelfs als je zou besluiten om helemaal niets te doen, dan nog zal de situatie veranderen. En er bestaat nu eenmaal geen manier om iedereen tevreden te houden. Op dit moment is het verstandigst om de weg te volgen die je energie je toont, doe datgene wat het beste aanvoelt.</p><p>Als je single bent en op zoek naar een relatie dan is nu de tijd gekomen om uit je schelp te kruipen. De liefde zal niet komen aankloppen, je zal de wereld in moeten en ernaar op zoek gaan!</p>",
                        [GuidanceFields.WORK] : "<p>Als je op dit moment aan het werk bent dan houd je er best rekening mee dat er wat conflicten of problemen kunnen komen. Denk eraan dat sommige mensen de waarheid nu eenmaal niet aankunnen en gedraag je daarnaar. Je hoeft niet altijd je mening te uiten, soms is het beter om anderen te observeren en zo te ontdekken vanwaar de wind komt.</p><p>Als je op zoek bent naar werk dan is het verstandig om het wat rustiger aan te doen in je sollicitatiegesprekken. Luister naar de mensen, toon hen dat je openstaat voor andere opvattingen en visies dan de jouwe. Maak hen duidelijk dat je soepel bent en je gemakkelijk kan aanpassen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het liedje klopt: free your mind and the rest will follow - als je je geest bevrijdt, dan volgt de rest vanzelf. Ontdek de spirituele wereld met hetzelfde enthousiasme dat je gewoonlijk enkel opbrengt voor meer wereldlijke zaken. Zoek boeken die je aanspreken, mensen om mee te praten, meditaties die je wil uitproberen. Verzamel zoveel mogelijk kennis en zoek dan mensen om hierover van gedachten te wisselen. Door geconfronteerd te worden met andere informatie, inzichten en meningen zal je vanzelf groeien en ook intense spirituele ervaringen krijgen. Je zal merken dat deze spirituele groei een diepgaande invloed heft op andere aspecten van je leven.</p>",
                    }
                },
                de : {
                    name: "Kind der Kristalle", subtitle: "Lehrling",
                    court : "Bube",
                    suit : "Kristalle",
                    element : [ "🜁 - Luft", "🜃 - Erde" ],
                    theme : "Schauen, was man denkt, ohne Vorurteile zu haben.",    
                    description : "Der Bube der Kristalle spricht davon, wie man unparteiische Informationen sammelt und sie mit einem offenen und kindlichen Geist betrachtet.",
                    questions : [ "Sind Sie neugierig?", "Wagen Sie es, Fragen zu stellen?", "Entscheiden Sie selbst?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Im Tarot symbolisieren alle Karten Chancen. Die Karte „Bube der Kristalle“ sagt Ihnen, dass Sie für alle neuen Erkenntnisse und Klarheit offen sein sollen. Wenn Sie mit jemanden einen Konflikt haben werden Sie plötzlich verstehen, was und warum etwas falsch gelaufen ist. Jetzt ist wirklich die Zeit, von Ihren Erfahrungen zu lernen.</p><p>Diese Karte zeigt Ihnen auch, dass Sie gerade viel Energie haben. Dies ist im Allgemeinen eine gute Sache, aber Sie verwenden möglicherweise diese Energie auf eine Art und Weise, die Leute ärgern könnte. Fragen Sie sich, ob Sie nicht zu viel Druck machen oder ob Sie zu direkt sind. Möglicherweise nehmen die Leute Anstoß an Ihren Kommentaren. Seien wir ehrlich, Sie finden es auch nicht einfach, Kritik zu akzeptieren.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In Ihren persönlichen Beziehungen gibt es eindeutig Spannung in der Luft. Ungelöste Konflikte tauchen auf, kleine Reibereien worum Sie nicht gefragt haben, fordern jetzt Ihre Aufmerksamkeit. Sie haben die Wahl: die schlafenden Hunde jetzt schlafen zu lassen, sogar wenn die schon halb wach geworden sind? Oder wollen Sie sich mit den Problemen konfrontieren mit dem Risiko nicht in der Lage zu sein sie zu lösen? Der Bube der Kristalle sagt Ihnen, dass jede Entscheidung, die Sie machen, Folgen haben wird. Auch wenn Sie sich entscheiden nichts zu tun, wird es sich auf jedem Fall ändern. Es gibt keine Möglichkeit alle zu gefallen. Gerade jetzt ist der beste Weg dahin zu gehen, wo Ihre Energie Ihnen sagt zu gehen, folgen Sie Ihre Intuition, es ist am besten zu tun was Sie fühlen.</p><p>Wenn Sie alleine sind, ist jetzt die Zeit, um aus Ihre Schale zu kriechen. Liebe kommt nicht von alleine an Ihre Tür vorbei, Sie müssen auf sie zugehen!</p>",
                        [GuidanceFields.WORK] : "<p>Wenn Sie im Augenblick einen Job haben erwarten Sie einige Konflikte, einige Probleme. Denken Sie daran, dass es für manche Leute schwierig ist die Wahrheit zu hören und handeln dann dementsprechend. Sie brauchen Sich nicht jedes Mal auszusprechen, manchmal ist es besser, erst mal andere zu beobachten und herauszufinden, woher der Wind weht.</p><p>Wenn Sie auf der Suche sind nach einem Job, möchten Sie vielleicht Ihre Motive ein bisschen abschwächen in Ihren Interviews. Hören Sie Menschen an, zeigen Sie deutlich an, dass Sie offen sind für andere Visionen und Überzeugungen und dass diese nicht desgleichen sein müssen als die Ihre. Machen Sie deutlich, dass Sie Sich leicht anpassen können.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Wie das Lied geht: Befreien Sie Ihren Verstand und der Rest wird folgen. Gehen Sie und erkunden Sie die geistige Welt mit der gleichen Begeisterung, die Sie in der Regel für die weltlichen Angelegenheiten reservieren. Suchen Sie Bücher zum Lesen, Menschen zum Sprechen, Meditationen zum Ausprobieren. Sammeln Sie so viel Wissen wie Sie können und finde Menschen, um mit ihnen darüber zu diskutieren. Durch den Austausch von Informationen, Einsichten und Ansichten werden Sie sich fühlen als wachsen Sie in die Richtung wahrer spirituellen Erfahrungen. Dieses Wachstum wird einen tief greifenden Einfluss auf andere Aspekte Ihres Lebens haben.</p>",
                    }
                }
            },            
            {
                filename: "61",
                numerology : 12,
                en : {
                    name: "Sage of Crystals", subtitle: "Knower",
                    court : "Sage",
                    suit : "Crystals",
                    element : "🜁 - air",
                    theme : "putting your own opinion out there",    
                    description : "Night of Crystals talks about showing what you think to the world around you.",
                    questions : [ "Are you voicing your opinion?", "Are you willing to listen to the opinions of others?", "Are you capable to adjust your opinion based on fine arguments?", "Can you fight for what is right for you?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>If you choose this card it tells you that at this moment you have a lot of energy, and you are dying to use it. Before you go out and address issues, conflicts or people ask yourself if you really want to get into a battle. You are very energetic right now and you may win those battles but maybe your goals are better served if you decide to communicate and not overwhelm people with your presence. Strike up a conversation, tell people how you feel and ask them how they feel, this may be the most constructive way of dealing with issues right now. Carefully pick your battles.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now you are very energetic and inquisitive. You are ruled more by intellect than by emotion. This may pose a threat if you are in a loving relationship because you want to solve problems and conflicts by thinking them through. But maybe your partner is leaning more towards the emotional aspects of the problem. She/he might want to really feel your commitment instead of hearing you describe it. Look for balance, you can be rational but you will need some empathy too if you want this relationship to last.</p><p>If you’re single, now is the time to go out and show yourself to the world. Put on your party gear and get out there. Your positive energy will blow potential partners off their feet, and maybe one of them will even land at yours!</p>",
                        [GuidanceFields.WORK] : "<p>For the moment you are overflowing with energy and that doesn’t go unnoticed at work. You are bound to get more projects and tasks and all that energy makes it easy for you to keep up and even heighten the pace. Your star is really shining! Possibly the fact that you are so efficient and productive will lead to jealousy and criticism from colleagues. Don’t let that hold you back but don’t get into discussions either. Do not lower yourself to their level but be empathic, even understanding. After all, some day you may find yourself in their shoes, anything is possible.</p><p>The Knight of Crystals is a very positive card if you are looking for a job. It tells you that things will probably go your way. Quite possibly someone will offer you exactly the job you were looking for!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Knight of Crystals tells you that you are full of energy, you are totally ready to go out there and take action. Spiritually speaking you have two options. You can immerse yourself in teachings, books and courses and try to take in as much knowledge as you can. Or you could turn away from all that energy because after all, it represents the outside world. Maybe, if you turn towards your inner world, you can find some quiet, some harmony. And couldn’t that be exactly what you need right now to find a certain balance?</p>",
                    }
                },
                sp: {
                name: "Sabio de Cristales", subtitle: "Becario",
                court: "Sabio",
                suit: "Cristales",
                element: "🜁 - aire",
                theme: "Expresar tu propia opinión",    
                description: "El Caballo de Cristales se trata de mostrar lo que piensas y nos recuerda que podemos mostrar nuestra opinión al mundo que nos rodea",
                questions: ["¿Dejate escuchar tu propio opinión?", "¿Estás dispuesto a escuchar otras opiniones?", "¿Eres capaz de ajustar tu opinión sobre la base de buenos argumentos?", "¿Puedes luchar por tus derechos?" ], 

                cardoftheday: {
                    [GuidanceFields.DEFAULT] : "<p>Si saca esta carta le dice que en este momento usted tiene una gran cantidad de energía, y  está muy ansioso para usarlo. Antes de salir y abordar los problemas, conflictos preguntase si realmente quiere entrar en una batalla. Usted es muy enérgico en este momento y se puede ganar esas batallas, pero tal vez está mejor servido sus metas si usted decide comunicarse y no abrumar a la gente. Entablar una conversación, decirle a la gente cómo se siente y preguntarles cómo se sienten, esto puede ser la forma más constructiva de tratar las cuestiones en este momento. Recoja cuidadosamente sus batallas.</p>",
                    [GuidanceFields.RELATIONSHIP] : "",
                    [GuidanceFields.WORK] : "",
                    [GuidanceFields.SPIRITUAL] : "",
                },
                },
                fr : {
                    name : "Le sage des Crystaux", subtitle: "Scholar",
                    court : "sage",
                    suit : "Crystaux",
                    element :  "🜁 - air",
                    theme : "exprimer sa propre opinion",    
                    description : "Le Cavalier des Crystaux, nous parle de montrer ce que l’on pense et que nous pouvons montrer notre opinion au monde qui nous entoure.",
                    questions : ["Faites-vous entendre votre opinion?", "Êtes-vous prêt à écouter l’opinions des autres?", "Êtes-vous capable d'ajuster votre opinion basé sur de bons arguments?", "Pouvez-vous vous battre pour vos droits?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Si vous tirez cette carte, elle vous dira que vous avez un excès d'énergie en ce moment. Et vous avez hâte de l'utiliser ! Mais avant de commencer des conflits avec les gens, vous pouvez vous demander si vous voulez vraiment vous battre. Vous êtes maintenant très énergique et vous pourriez gagner ce combat. Mais peut-être que vos objectifs seront mieux servis si vous communiquez au lieu de submerger les gens par votre simple présence. Commencez une conversation, dites aux gens ce que vous ressentez et demandez-leur ce qu'ils ressentent. Essayez de faire preuve d'empathie et de compassion. C'est peut-être la façon la plus constructive d'aborder les choses en ce moment. Choisissez vos combats avec soin.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now you are very energetic and inquisitive. You are ruled more by intellect than by emotion. This may pose a threat if you are in a loving relationship because you want to solve problems and conflicts by thinking them through. But maybe your partner is leaning more towards the emotional aspects of the problem. She/he might want to really feel your commitment instead of hearing you describe it. Look for balance, you can be rational but you will need some empathy too if you want this relationship to last.</p><p>If you’re single, now is the time to go out and show yourself to the world. Put on your party gear and get out there. Your positive energy will blow potential partners off their feet, and maybe one of them will even land at yours!</p>",
                        [GuidanceFields.WORK] : "<p>For the moment you are overflowing with energy and that doesn’t go unnoticed at work. You are bound to get more projects and tasks and all that energy makes it easy for you to keep up and even heighten the pace. Your star is really shining! Possibly the fact that you are so efficient and productive will lead to jealousy and criticism from colleagues. Don’t let that hold you back but don’t get into discussions either. Do not lower yourself to their level but be empathic, even understanding. After all, some day you may find yourself in their shoes, anything is possible.</p><p>The Knight of Crystals is a very positive card if you are looking for a job. It tells you that things will probably go your way. Quite possibly someone will offer you exactly the job you were looking for!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Knight of Crystals tells you that you are full of energy, you are totally ready to go out there and take action. Spiritually speaking you have two options. You can immerse yourself in teachings, books and courses and try to take in as much knowledge as you can. Or you could turn away from all that energy because after all, it represents the outside world. Maybe, if you turn towards your inner world, you can find some quiet, some harmony. And couldn’t that be exactly what you need right now to find a certain balance?</p>",
                    }
                },
                nl : {
                    name: "Wijze van Kristallen", subtitle: "Geleerde",
                    court : "Wijze",
                    suit : "Kristallen",
                    element : "🜁 - lucht",
                    theme : "je eigen mening naar buiten brengen",    
                    description : "De Ridder van Kristallen gaat over laten zien wat je denkt en wijst ons er op dat we onze mening aan de wereld om ons heen mogen tonen.",
                    questions : [ "Laat jij je mening horen?", "Ben jij bereid ook naar andere meningen te luisteren?", "Ben jij in staat om je mening bij te stellen op basis van goede argumenten?", "Kun jij vechten voor je rechten?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Als je deze kaart kiest dan is het duidelijk dat je op dit ogenblik een overvloed aan energie hebt. En meer dan dat: je staat ook te trappelen om al die energie te gebruiken! Maar voor je erop uitgaat om problemen, conflicten of mensen aan te pakken, kan je je misschien eerst afvragen of je op dit moment echt wel een gevecht wil aangaan. Je bent nu energetisch behoorlijk sterk dus het is goed mogelijk dat je die gevechten ook wint. Maar misschien zal je je doelen eerder bereiken door te gaan communiceren in plaats van mensen te overweldigen met je krachtige uitstraling. Ga het gesprek aan, vertel die ander hoe jij je voelt en vraag de ander ook hoe zij/hij zich voelt. Probeer empathie en mededogen te tonen want op dit moment is dat de meest constructieve manier om problemen aan te pakken. Denk goed na en kies zorgvuldig welke strijd je wel en welke je niet wil aangaan.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Je voelt je op dit ogenblik heel energiek. Tegelijk zit je ook meer in je verstand dan in je gevoel. Dit kan in je liefdesrelatie eventueel voor problemen zorgen omdat je conflicten en onenigheid wil oplossen door erover na te denken, door te redeneren. Maar het is waarschijnlijk dat je partner meer bezig is met de emotionele aspecten van de situatie. Je partner wil op dit moment waarschijnlijk liever <strong>voelen</strong> dat je betrokken bent in plaats van te horen hoe jij je betrokkenheid beschrijft. Probeer hierin een evenwicht te vinden, je mag best rationeel zijn maar op dit moment is het belangrijk dat je ook empathisch bent, zeker als je een toekomst wil voor deze relatie.</p><p>Als je alleen bent dan is dit een goed moment om erop uit te gaan en jezelf te tonen aan de wereld. Dof jezelf op, trek je partykleren aan en ga feesten. Je positieve energie zal een diepe indruk maken op de mensen die je ontmoet en je kan hier best een mogelijke partner aan overhouden!</p>",
                        [GuidanceFields.WORK] : "<p>Op dit moment heb je een overvloed aan energie en dat valt je collega’s en meerderen echt wel op. Ga ervan uit dat je meer taken of zelfs nieuwe projecten krijgt. Je hebt nu trouwens zoveel energie dat je dit of zelfs een hoger tempo best aankan, je straalt als een ster! Het is best mogelijk dat je met wat jaloezie van collega’s te maken krijgt omdat je op dit moment zo efficiënt en productief bent. Laat je daar niet door tegenhouden en laat je ook niet verleiden tot discussies. Verlaag jezelf niet tot dat niveau, integendeel! Probeer empathisch te zijn, zelfs begrip op te brengen voor hun gevoelens en gedachten. Tenslotte is het best mogelijk dat je op een dag in hun schoenen zal staan.</p><p>Als je op zoek bent naar werk dan is de Ridder van Kristallen een heel positieve kaart die je vertelt dat de dingen waarschijnlijk zo zullen verlopen als jij het graag wil. Het zit er dik in dat iemand je nèt die baan aanbiedt waar je al een tijd naar op zoek bent.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Ridder van Kristallen vertelt je dat je op dit moment heel veel energie hebt. Je bent er klaar voor om de wereld tegemoet te treden en in actie te schieten! Spiritueel gezien heb je twee opties. Je kan jezelf onderdompelen in boeken, cursussen en leringen en op die manier proberen om zoveel mogelijk kennis te vergaren. Maar je kan ook afstand nemen van al die energie die in je borrelt omdat die tenslotte ook deel uitmaakt van de jachtige wereld. En als je je helemaal focust op je innerlijke rijkdom kan je misschien stilte en harmonie vinden. Is het niet mogelijk dat het net dàt is wat je nodig hebt om in evenwicht te geraken?</p>",
                    }
                },
                de : {
                    name: "Weisen der Kristalle", subtitle: "Gelehrter",
                    court : "Weisen",
                    suit : "Kristalle",
                    element : "🜁 - lucht",
                    theme : "Seine eigene Meinung äußern",    
                    description : "Der Ritter der Kristalle spricht darüber zu zeigen, was wir denken und dass wir unsere Meinung der Welt um uns herum zeigen können.",
                    questions : [ "Haben Sie ihre eigene Meinung kundgetan?", "Sind Sie bereit, die Meinungen anderer anzuhören?", "Können Sie Ihre Meinung auf der Grundlage guter Argumente anpassen ?", "Können Sie für Ihre Rechte kämpfen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Wenn Sie diese Karte ziehen, wird sie Ihnen sagen, dass Sie im Moment ein Übermaß an Energie haben. Und dass Sie es kaum erwarten können, sie einzusetzen! Aber bevor Sie Konflikte mit Menschen vom Zaun brechen könnten Sie sich fragen, ob Sie wirklich in einen Kampf geraten wollen. Sie sind jetzt sehr energisch und könnten also diesen Kampf tatsächlich gewinnen. Aber vielleicht ist Ihren Zielen besser gedient, wenn Sie kommunizieren anstatt Menschen mit Ihrer bloßen Präsenz zu überwältigen. Beginnen Sie ein Gespräch, sagen Sie den Menschen, wie Sie sich fühlen und fragen Sie nach ihren Gefühlen. Versuchen Sie, Empathie und Mitgefühl zu zeigen. Dies könnte die konstruktivste Art sein, in diesem Moment mit den Dingen umzugehen. Wählen Sie Ihre Kämpfe sorgfältig aus.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Gerade jetzt sind Sie sehr energisch und neugierig. Sie werden mehr vom Verstand regiert als von Emotionen. Dies kann eine Gefahr darstellen, wenn Sie in einer liebevollen Beziehung sind, weil Sie Probleme und Konflikte durch das Denken lösen wollen. Aber vielleicht lehnt Ihr Partner mehr auf den emotionalen Aspekten des Problems. Er / Sie möchte vielleicht Ihr Verbundenheit wirklich fühlen statt hören wie Sie es beschreiben. Suchen Sie nach Gleichgewicht. Obwohl Sie rationell sein können brauchen Sie hier auch einigermaßen Empathie, wenn Sie diese Beziehung aufrecht behalten wollen.</p><p>Wenn Sie alleine sind, dann ist jetzt die Zeit aus zu gehen und Sich die Außenwelt zu zeigen. Setzen Sie Ihren Party-hut auf und gehen Sie raus. Ihre positive Energie wird wahrscheinlich einen potenzielle Partner von den Füßen blasen aber vielleicht wird einer von ihnen auch bei Ihnen landen!</p>",
                        [GuidanceFields.WORK] : "<p>Für den Moment sind Sie mit Energie überfüllt und das bleibt nicht unbemerkt bei der Arbeit. Sie sind dabei, mehr Projekte und Aufgaben zu erhalten und all diese Energie macht es einfach für Sie, um Schritt zu halten und sogar das Tempo noch zu erhöhen. Ihr Stern ist wirklich glänzend! Möglicherweise kann die Tatsache, dass Sie so effizient und produktiv sind, Anlass sein zur Eifersucht und Kritik von Kollegen. Lassen Sie Sich davon nicht zurückhalten, aber mischen Sie Sich nicht in die Diskussionen. Lassen Sie Sich nicht niedersenken auf Ihr Niveau aber seien Sie empathisch, sogar verständnisvoll Denn eines Tages können Sie sich in Ihre Schuhe befinden, alles ist ja möglich.</p><p>Der Ritter der Kristalle ist eine sehr positive Karte, wenn Sie nach einem Job suchen. Sie sagt Ihnen, dass die Dinge wahrscheinlich laufen wie Sie möchten. Sehr wahrscheinlich wird jemand Ihnen genau den Job bieten, den Sie gesucht haben!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Der Ritter der Kristalle sagt Ihnen, dass Sie voller Energie sind, dass Sie völlig bereit sind hinauszugehen und zu handeln. Spirituell gesprochen haben Sie zwei Möglichkeiten. Sie können untertauchen in Lehre, Bücher und Kurse und versuchen, um so viel Wissen wie möglich zu Ihnen zu nehmen. Oder Sie gehen weg von all dieser Energie, weil es immerhin die Außenwelt darstellt. Vielleicht sollten Sie Sich Ihrer inneren Welt zuwenden, wo Sie Ruhe und Harmonie finden können. Und könnte das nicht gerade genau das sein, was Sie jetzt brauchen, um ein gewisses Gleichgewicht zu finden?</p>",
                    }
                }
            },
            {
                filename: "62",
                numerology : 13,
                en : {
                    name: "Woman of Crystals", subtitle: "Guardian",
                    court : "Woman",
                    suit : "Crystals",
                    element : [ "🜁 - air", "🜄 - water" ],
                    theme : "taking care for what you think",    
                    description : "Queen of Crystals talks about being attentive to your thoughts. Make room for your thoughts.",
                    questions : [ "What do you think?", "Can you make a decision, cut to the chase?", "Are you using your mind and your intuition?", "Can you express yourself clearly?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Queen of Crystals is all about intelligence, independence of thought, vigilance. She invites you to use your brain to tackle problems and questions, not your emotionality. Now is the time to look at your life from a distance and decide which issues are to be resolved immediately and which ones can wait. You probably know what you want and need, and you only have to decide what to do to accomplish it, how to go about it. Be careful, think about other people’s sensitivities and boundaries, this card has a very powerful intellectual energy that might be offensive to some. Right now you tend to connect to people by intellect rather than by emotion, which may be hard to understand or accept for others.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now you are feeling strong and rational and this reflects on your attitude in your relationships too. Especially in an intimate relationship it is important to not be overbearing. You may address every problem and question cerebrally for the moment, but that doesn’t mean your partner has the same point of view. He/she may be more emotional than you are. Try to be nice, and accept your partner’s motivations and arguments even if they’re not rational. If you want to stay in this relationship it is essential to find common ground.</p><p>If you’re looking for love it’s important not to be too self-confident because sometimes you are bordering on arrogance. That’s not what people look for in a potential partner.</p>",
                        [GuidanceFields.WORK] : "<p>For the moment you have a quick and sharp mind, Getting straight to the point seems easy for you. Still it’s important to take into account that not all your colleagues (or even superiors) are ready to hear the naked truth. Try to respect their boundaries. Maybe it would even be wise to present your convictions in a nice package. If you do that, people will find it easier to accept what you have to say.</p><p>If you are looking for a job right now, your intellectual abilities will help you to present yourself in the best way possible. You will almost certainly make a good impression in job interviews. Use this to your advantage, you are sure to find the job you want!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Even if the Queen of Crystals symbolizes your brain power, it is clear that underneath all those thoughts there are emotions to be found. Go past the intellectual and dip into the source of your feelings. Let them come over you, find out where all these emotions are coming from. The things you discover will help you find a brand new spiritual path. In this period of your life you will be able to discern spiritual messages in everyday life all the time. Be open to them, they will guide you. </p>",
                    }
                },
                fr : {
                    name : "Femme des Crystaux", subtitle: "Vigilante",
                    court : "Femme",
                    suit : "Crystaux",
                    element :  ["🜁 - air", "🜄 - eau" ],
                    theme : "prendre soin de ce que vous pensez",    
                    description : "La Reine des Crystaux, nous parle de faire attention à ce que l’on pense. Donnez de l'espace à ce que vous pensez.",
                    questions : [ "Que pensez-vous ?", "Pouvez-vous prendre une décision, trancher ?", "A cela, utilisez-vous et votre esprit et votre cœur ?", "Vous-êtes-t-il possible d’exprimer votre opinion ?" ],
                                        
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La \"Reine d’Crystaux\", c'est l'intelligence, l'indépendance de pensée et la vigilance. Elle vous invite à utiliser votre esprit, et non vos sentiments, pour résoudre des problèmes et des questions. Maintenant, il est temps de regarder votre vie d'une certaine distance. Décidez quels problèmes doivent être résolus immédiatement et lesquels peuvent attendre. Vous savez probablement ce que vous voulez et ce dont vous avez besoin. Il ne vous reste plus qu'à établir un plan pour atteindre ces objectifs. Décidez comment vous le faites. Soyez prudent, faites attention aux sensibilités et aux limites des autres. Cette carte symbolise une très forte énergie mentale que certains pourraient trouver offensante. En ce moment, vous êtes plus enclin à vous connecter avec votre esprit et non avec vos émotions. Cela peut être difficile à comprendre ou à accepter pour certains.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Right now you are feeling strong and rational and this reflects on your attitude in your relationships too. Especially in an intimate relationship it is important to not be overbearing. You may address every problem and question cerebrally for the moment, but that doesn’t mean your partner has the same point of view. He/she may be more emotional than you are. Try to be nice, and accept your partner’s motivations and arguments even if they’re not rational. If you want to stay in this relationship it is essential to find common ground.</p><p>If you’re looking for love it’s important not to be too self-confident because sometimes you are bordering on arrogance. That’s not what people look for in a potential partner.</p>",
                        [GuidanceFields.WORK] : "<p>For the moment you have a quick and sharp mind, Getting straight to the point seems easy for you. Still it’s important to take into account that not all your colleagues (or even superiors) are ready to hear the naked truth. Try to respect their boundaries. Maybe it would even be wise to present your convictions in a nice package. If you do that, people will find it easier to accept what you have to say.</p><p>If you are looking for a job right now, your intellectual abilities will help you to present yourself in the best way possible. You will almost certainly make a good impression in job interviews. Use this to your advantage, you are sure to find the job you want!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Even if the Queen of Crystals symbolizes your brain power, it is clear that underneath all those thoughts there are emotions to be found. Go past the intellectual and dip into the source of your feelings. Let them come over you, find out where all these emotions are coming from. The things you discover will help you find a brand new spiritual path. In this period of your life you will be able to discern spiritual messages in everyday life all the time. Be open to them, they will guide you. </p>",
                    }
                },
                sp:{
                    name:"Mujer de Cristales", subtitle: "vigilante",
                    court: "Mujer",
                    suit: "Cristales",
                    element: ["🜁 - aire", "🜄 - agua" ],
                    theme: "Cuidando lo que piensas",    
                    description: "La Reina de las Cristales se trata de prestar atención a lo que piensas. Dale espacio a lo que piensas",
                    questions: ["¿Qué piensas?", "¿Puedes tomar una decisión, cortar el nudo?", "¿Usas tu mente y tus sentimientos?", "¿Puedes expresar tu opinión claramente?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La Reina de Cristales es todo acerca de la inteligencia, la independencia de pensamiento, de vigilancia. Ella invita a utilizar su cerebro para hacer frente a los problemas y preguntas, no su emocionalidad. Ahora es el momento de mirar su vida desde la distancia y decidir qué cuestiones deben resolverse de inmediato y cuáles pueden esperar. Usted probablemente sabe lo que quiere y necesita, y sólo tiene que decidir qué hacer para lograrlo, la forma de hacerlo. Ten cuidado, pensar en las sensibilidades y los límites de la gente, esta tarjeta tiene una energía muy poderosa intelectual que puede ser ofensivo para algunos. Ahora inclina a conectar a las personas por el intelecto y no por la emoción, que puede ser difícil de entender o aceptar a los demás.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Vrouw van Kristallen", subtitle: "Waakzame",
                    court : "Vrouw",
                    suit : "Kristallen",
                    element : [ "🜁 - lucht", "🜄 - water" ],
                    theme : "zorgdragen voor wat je denkt",    
                    description : "De Koningin van Kristallen gaat over aandacht schenken aan wat je denkt. Geef wat je denkt de ruimte.",
                    questions : [ "Wat denk jij?", "Kun je een beslissing nemen, de knoop doorhakken?", "Gebruik je daarbij én je verstand én je gevoel?", "Kun jij je mening helder verwoorden?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Koningin van Kristallen draait rond intelligentie, onafhankelijk denken en alert zijn. Ze nodig je uit om je verstand te gebruiken, niet je gevoel, als je problemen en vragen wil aanpakken. En op dit moment is het verstandig om vanop een afstand naar je eigen leven te kijken. Beslis daarna welke problemen je onmiddellijk wil oplossen en welke nog even kunnen wachten. Jij weet immers het beste wat je wil en nodig hebt. Wat je nu te doen staat is een plan maken om je doelen te bereiken. Beslis welke aanpak je voorkeur geniet. Wees voorzichtig hierin, houd rekening met de gevoelens en grenzen van de mensen om je heen.</p><p>Deze kaart is symbolisch voor een sterke intellectuele energie die je er soms toe kan brengen dat je anderen kwetst of beledigt. Op dit punt heb je immer de neiging om contact te zoeken met anderen op een intellectueel niveau zonder er emoties bij te betrekken. En net deze houding kan voor sommigen moeilijk te aanvaarden en te begrijpen zijn.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Je voelt je op dit moment sterk en rationeel en deze houding is ook terug te vinden in je standpunt ten opzichte van relaties. Let hiermee op, zeker in een liefdesrelatie is het belangrijk om niet te dominant te zijn, je mening niet op te dringen. Jij hebt nu de neiging om alle problemen en vragen op een verstandelijke manier te benaderen en dat is natuurlijk je volste recht. Maar het betekent niet automatisch dat je geliefde ook in zijn of haar verstand zit. Misschien is het net het omgekeerde en is je partner zelf heel emotioneel op dit moment. Probeer vriendelijk en meevoelend te zijn, aanvaard dat de motieven en argumenten van je partner evenwaardig zijn aan de jouwe, ook al zijn ze niet rationeel. Als je wil dat deze relatie blijft duren dan is het essentieel dat je mekaar ergens in het midden vindt.</p><p>Als je op zoek bent naar een geliefde dan is het belangrijk dat je je niet te zelfverzekerd opstelt, je zou immers arrogant kunnen overkomen en dat vindt niemand aantrekkelijk.</p>",
                        [GuidanceFields.WORK] : "<p>Op dit moment is je brein sterk en snel, je vindt het gemakkelijk om de kern van een probleem of een vraagstelling te zien. Houd er toch rekening mee dat je collega’s (en misschien zelfs je superieuren) er nog niet klaar voor zijn om de naakte waarheid te horen. Probeer hun grenzen te respecteren. Probeer je inzichten aan te bieden in een aantrekkelijke verpakking. Als je het op deze manier aanpakt zal je merken dat mensen het makkelijker vinden om jouw meningen te aanvaarden.</p><p>Als je op zoek bent naar werk dan zal je intellect je helpen om jezelf voor te stellen op de best mogelijke manier. Het is bijna zeker dat je een goede indruk zal maken bij interviews. Gebruik dit voordeel en je zal vast de baan vinden waar je naar op zoek bent!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Ook al is de Koningin van Kristallen symbolisch voor je denkvermogen, het is natuurlijk vanzelfsprekend dat onder al die gedachten ook emoties te vinden zijn. Zoek je weg voorbij het intellectuele en duik in de rivier van je emoties, laat ze over je heen spoelen en probeer bij de bron te geraken, ontdek op die manier waar al die gevoelens vandaan komen. Wat je nu ontdekt zal je de weg wijzen naar een gloednieuw spiritueel pad. Je zal merken dat je in alle mogelijke aspecten van het dagelijkse leven spirituele boodschappen kan ontdekken. Sta hiervoor open, deze boodschappen zijn je gidsen.</p>",
                    }
                },
                de : {
                    name: "Frau der Kristalle", subtitle: "Wachsame",
                    court : "Frau",
                    suit : "Kristalle",
                    element : [ "🜁 - Luft", "🜄 - Wasser" ],
                    theme : "Darauf achten, was man denkt.",    
                    description : "Die Königin der Kristalle spricht darüber, aufmerksam zu sein, worüber wir denken. Geben Sie dem Raum, worüber Sie denken.",
                    questions : [ "Was denken Sie?", "Können Sie eine Entscheidung treffen, bestimmen?", "Nutzen Sie Ihren Geist und Ihr Herz?", "Können Sie Ihre Meinung äußern?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Bei der „Königin der Kristalle“ geht es um Intelligenz, Unabhängigkeit des Denkens und Wachsamkeit. Sie lädt Sie ein, Ihren Verstand und nicht Ihre Gefühle zu verwenden, wenn es darum geht, Probleme und Fragen zu lösen. Jetzt ist es Zeit, auf Ihr Leben mit einem gewissen Abstand zu schauen. Entscheiden Sie sich, welche Probleme sofort gelöst werden müssen und welche warten können. Sie wissen wahrscheinlich, was Sie wollen und brauchen. Jetzt müssen Sie nur noch einen Plan machen, um diese Ziele zu erreichen. Entscheiden Sie sich, wie Sie das machen. Seien Sie vorsichtig, achten Sie auf Empfindlichkeiten und Grenzen anderer Menschen. Diese Karte symbolisiert eine sehr starke geistige Energie, an der manche Anstoß nehmen könnten. Zurzeit neigen Sie mehr dazu, sich mit Verstand und nicht mit Emotionen mit anderen zu verbinden. Dies könnte für manche schwierig zu verstehen oder zu akzeptieren sein.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Gerade jetzt Sie fühlen Sie Sich stark und vernünftig und dies spiegelt sich auf Ihrer Haltung in Ihren Beziehungen. Vor allem in einer intimen Beziehung ist es wichtig, nicht aufdringlich zu sein. Sie können jedes Problem anrühren und in Frage stellen, aber das bedeutet nicht, dass Ihr Partner den gleichen Standpunkt hat. Er / sie kann emotionaler sein als Sie sind. Versuchen Sie, nett zu sein und akzeptieren Sie Beweggründe und Argumente Ihres Partners, auch wenn sie nicht rationell sind. Wenn Sie in dieser Beziehung bleiben wollen ist es wichtig, eine gemeinsame Basis zu finden.</p><p>Wenn Sie nach die Liebe suchen, ist es wichtig, nicht zu selbstbewusst zu sein, weil man manchmal nach Arroganz neigen wird. Das ist nicht das, was die Menschen suchen in einem potenziellen Partner.</p>",
                        [GuidanceFields.WORK] : "<p>Zur Zeit haben Sie einen schnellen und scharfen Verstand, direkt auf Ihrem Ziel zuzugehen scheint einfach für Sie. Trotzdem ist es wichtig zu berücksichtigen, dass nicht alle Ihre Kollegen (oder sogar Vorgesetzten) bereit sind, die nackte Wahrheit zu hören. Versuchen Sie Ihre Grenzen zu respektieren. Vielleicht wäre es auch ratsam, Ihre Überzeugungen in einem netten Paket zu präsentieren. Wenn Sie das tun, werden die Leute es einfacher finden zu akzeptieren, was Sie zu sagen haben.</p><p>Wenn Sie sich jetzt nach einem Job suchen, werden Ihre spirituellen Fähigkeiten Ihnen helfen, sich in der bestmöglichen Weise zu präsentieren. Sie werden praktisch sicher einen guten Eindruck in Bewerbungsgesprächen hinterlassen. Verwenden Sie diese zu Ihrem Vorteil und Sie werden Sich sicher sein können, dass Sie die Arbeit bekommen, die Sie wollen!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Auch wenn die Königin der Kristalle der Macht Ihres Gehirns symbolisiert, ist es klar, dass unter all diesen Gedanken Emotionen zu finden sind. Gehen Sie an das geistige vorbei und tauchen Sie ein in die Quelle der Gefühle. Lassen Sie sie über Sich kommen um herauszufinden, wo all diese Emotionen herkommen. Die Dinge, die Sie entdecken, werden Ihnen helfen, einen brandneuen spirituellen Weg zu finden. In dieser Zeit Ihres Lebens werden Sie in der Lage sein, zu jeder Zeit spirituelle Botschaften im Alltag zu erkennen. Seien Sie offen für sie, sie werden Sie zu führen.</p>",
                    }
                }
            },
            {
                filename: "63",
                numerology : 14,
                en : {
                    name: "Man of Crystals", subtitle: "Inventor",
                    court : "Man",
                    suit : "Crystals",
                    element : [ "🜁 - air", "🜂 - fire" ],
                    theme : "being pro-active from ones’ own thinking",    
                    description : "King of Crystals talks about effectively acting from the own mind. Go create.",
                    questions : [ "Are you conscious about your knowledge?", "Are you acting accordingly?", "Can you clearly state what you think?", "Can you think analytically?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The King of Sword portrays a person who is intelligent, serious, intellectual, someone with a quick mind.  You can be serious and clever with an excellent judgment but it is also possible that you resort to sarcasm and aggression, maybe even turn into a bully sometimes. It is up to you to decide if you want to be understanding, analytical and insightful or stubborn and even cynical.</p><p>At this time in your life it is important for you to realize that all these aspects are part of your personality. Be conscious of this and choose who and what you really want to be.</p><p>It is also possible that you are being confronted with a stubborn person who is unwilling to change his or her mind. Maybe, after some soul searching, it would be best to decide to change yourself to make the situation bearable.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>When you draw this card it is possible that you are behaving very rationally in your personal relationships. You feel the need to approach problems and conflicts from an intellectual point of view and to not take into account the emotions involved. This standpoint will help you get to the heart of matters but at some point you will have to accept that your partner isn’t as intellectually minded as you are and emotions must be part of the equation.</p><p>It’s also possible that you are in a relationship with someone who is rather dominant and cerebral. Maybe she/he even goes too far and oversteps your boundaries now and then. You will not be able to change this person’s behavior so it’s a matter of standing your ground and protecting yourself and what’s important to you.</p><p>If you are single and looking for a partner, try to remember that the right person isn’t only to be found by following intellectual standards. What you feel has to count too.</p>",
                        [GuidanceFields.WORK] : "<p>For the moment you find it easy to do your job, you behave in a tactical, flexible manner. Maybe you even are a bit manipulative at times. Is this truly who you want to be?</p><p>Or maybe at work you are being confronted with someone who is very outspoken. If you don’t like this attitude, ask yourself why because you may be a bit too emotional right now.</p><p>If you are looking for work, no matter how secure and selfconfident you are, remember that it’s best to follow the lead the interviewer gives you. You aren’t in this interview to win a battle but to show your qualities.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Right now you are approaching spirituality in an intellectual way. You need to fully understand what is being said before you can apply it or even accept it. Now would be a good time to go out and seek a strong, ethically sound mentor who can give you all the information you require to make an assessment. And because you are very clear minded right now, it may be good for you to meditate on a regular basis. No matter how you approach spirituality, right now you need to be active.</p>",
                    }
                },
                fr : {
                    nom : "L'homme' des Crystaux", subtitle: "Inventeur",
                    court : "Homme",
                    suit : "Crystaux",
                    element :  ["🜁 - air", "🜄 - feu"],
                    theme : "Agir activement de ses propre pensées",    
                    description : "Le Roi des Crystaux, nous parle d’agir effectivement de ses propres pensées. Donnez forme à cela.", 
                    questions :  [ "Etes-vous conscient de ce que vous savez?", "Agissez-vous de tel ?", "Pouvez-vous vous exprimer clair et précis?", "Pouvez-vous réfléchir analytiquement?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Roi d’Crystaux\" représente une personne intelligente, sérieuse et intellectuelle, à l'esprit vif. Elle peut être sérieuse et intelligente, avoir un excellent jugement.  Mais il est aussi possible que vous soyez sarcastique et agressif. Est-ce que certaines attitudes vous rappellent l’attitude d'un tyran ? Vous devez décider qui vous vous voulez être, comprendre, analyser ou être têtu et même cynique.</p><p>À ce stade de votre vie, il est important de réaliser que tous ces aspects font partie de votre personnalité. Soyez conscient de cela et déterminez qui est-ce qui vous voulez vraiment être.</p><p>Il est également possible d'être confronté à une personne persistante qui n'est pas prête à changer d'avis. Après un examen de conscience, vous constaterez peut-être qu'il serait préférable que vous changiez d'avis pour rendre la situation plus supportable.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>When you draw this card it is possible that you are behaving very rationally in your personal relationships. You feel the need to approach problems and conflicts from an intellectual point of view and to not take into account the emotions involved. This standpoint will help you get to the heart of matters but at some point you will have to accept that your partner isn’t as intellectually minded as you are and emotions must be part of the equation.</p><p>It’s also possible that you are in a relationship with someone who is rather dominant and cerebral. Maybe she/he even goes too far and oversteps your boundaries now and then. You will not be able to change this person’s behavior so it’s a matter of standing your ground and protecting yourself and what’s important to you.</p><p>If you are single and looking for a partner, try to remember that the right person isn’t only to be found by following intellectual standards. What you feel has to count too.</p>",
                        [GuidanceFields.WORK] : "<p>For the moment you find it easy to do your job, you behave in a tactical, flexible manner. Maybe you even are a bit manipulative at times. Is this truly who you want to be?</p><p>Or maybe at work you are being confronted with someone who is very outspoken. If you don’t like this attitude, ask yourself why because you may be a bit too emotional right now.</p><p>If you are looking for work, no matter how secure and selfconfident you are, remember that it’s best to follow the lead the interviewer gives you. You aren’t in this interview to win a battle but to show your qualities.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Right now you are approaching spirituality in an intellectual way. You need to fully understand what is being said before you can apply it or even accept it. Now would be a good time to go out and seek a strong, ethically sound mentor who can give you all the information you require to make an assessment. And because you are very clear minded right now, it may be good for you to meditate on a regular basis. No matter how you approach spirituality, right now you need to be active.</p>",
                    }
                },
                sp:{
                    name:"Hombre de Cristales", subtitle: "Inventor",
                    court: "Hombre",
                    suit: "Cristales",
                    element: ["🜁 - aire", "🜂 – fuego" ],
                    theme: "Actuar activamente a partir de los propios pensamientos",    
                    description: "El Rey de Cristales se trata de actuar desde tus propios pensamientos. Dale forma",
                    questions: ["¿Eres consciente de lo que sabes?", "¿Estás actuando en consecuencia?", "¿Puedes hablar fuertamente y claramente?", "¿Puedes pensar analíticamente?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Man van Kristallen", subtitle: "Uitvinder",
                    court : "Man",
                    suit : "Kristallen",
                    element : [ "🜁 - lucht", "🜂 - vuur" ],
                    theme : "actief handelen vanuit je eigen denken",    
                    description : "De Koning van Kristallen gaat over daadwerkelijk handelen vanuit je eigen denken. Geef het vorm.",
                    questions : [ "Ben jij je bewust van wat je weet?", "Handel je daar ook naar?", "Kun jij je helder en duidelijk uitspreken?", "Kun jij analytisch denken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Koning van Kristallen is intelligent en ernstig. Hij is ook intellectueel en heeft een snelle, scherpe geest. Als je deze kaart trekt dan is het mogelijk dat je slim bent en een weloverwogen inzicht hebt maar je kan ook doorslaan naar sarcasme en agressie. Misschien ben je soms zelfs een beetje een tiran. Het is nu aan jou om te beslissen of je begrijpend, analytisch en rijk aan inzichten wil zijn of dat je koppig en cynisch door het leven wil gaan.</p><p>Je bent nu op een punt gekomen waar je beseft dat al deze eigenschappen deel uitmaken van je persoonlijkheid. Wees je hiervan bewust en beslis dan wie en wat je echt wil zijn.</p><p>Deze kaart kan er ook op wijzen dat je te maken hebt met een koppig iemand die totaal niet bereid is om van mening te veranderen. Als je de tijd neemt om diep na te denken over deze situatie, kom je misschien tot de conclusie dat het voor <strong>jou</strong> het beste zou zijn om van mening te veranderen zodat de situatie draaglijk wordt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je deze kaart trekt dan is het mogelijk dat je je op dit moment erg rationeel opstelt in je persoonlijke relaties. Je hebt de behoefte om problemen en conflicten op een intellectuele manier te benaderen zonder rekening te houden met de emoties die er deel van uitmaken. Dit standpunt zal je helpen om door te dringen tot de kern van de zaak. Maar op een gegeven moment zal je moeten aanvaarden dat je partner niet op dezelfde golflengte zit en dat je ook met een aantal emoties rekening zal moeten houden.</p><p>Deze kaart kan ook betekenen dat je een relatie hebt met iemand die behoorlijk dominant en verstandelijk is. Mogelijk gaat deze persoon daar te ver in en gaat hij/zij geregeld over je grenzen heen. Jij kan het gedrag van deze persoon niet veranderen dus is het belangrijk dat je jezelf en je grenzen zorgvuldig in het oog houdt. Zorg dat je snel genoeg aan de bel trekt!</p><p>Als je single bent en op zoek naar een partner, denk er dan aan dat je de ware niet vindt door enkel intellectuele normen te gebruiken. Je moet ook rekening houden met wat je voelt bij iemand.</p>",
                        [GuidanceFields.WORK] : "<p>Op dit moment is je werk doen voor jou een fluitje van een cent. Je stelt je tactisch en soepel op en balanceert soms op het randje van manipuleren. Ben je er zeker van dat je op deze manier te werk wil gaan?</p><p>Het is ook mogelijk dat je op je werkplek geconfronteerd wordt met iemand die er heel uitgesproken meningen op nahoudt die hij/zij ook met de nodige overtuiging verkondigt. Als je last hebt van dit gedrag vraag je dan af hoe het komt. Misschien ben je zelf een beetje te emotioneel op dit moment, hoe komt dit, waardoor wordt dit veroorzaakt?</p><p>Als je op dit moment op zoek bent naar werk, denk er dan aan dat het altijd verstandig is om bij een gesprek de leiding bij de interviewer te laten. Het maakt niet uit hoe zelfzeker je bent, dit gesprek is geen wedstrijd, je hoeft niet te winnen, je hoeft de ander enkel duidelijk te maken wat je kwaliteiten zijn.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je kijkt op een verstandelijke manier naar spiritualiteit. Het is erg belangrijk voor jou om helemaal te begrijpen wat er gezegd wordt voor je het kan toepassen of zelfs maar aanvaarden. Op dit moment is het een goed idee om een mentor te zoeken die een sterke persoonlijkheid en vaste, duidelijke overtuigingen heeft. Enkel zo’n persoon kan je alle informatie geven die je op dit moment nodig hebt om een mening te vormen. Het is ook belangrijk dat je geregeld mediteert. Het maakt niet uit wat jouw spirituele inzichten en overtuigingen zijn, op dit moment heb je behoefte aan een actieve aanpak.</p>",
                    }
                },
                de : {
                    name: "Mann der Kristalle", subtitle: "Erfinder",
                    court : "Mann",
                    suit : "Kristalle",
                    element : [ "🜁 - Luft", "🜂 - Feuer" ],
                    theme : "Aktives Handeln nach seinen eigenen Vorstellungen",    
                    description : "Der König der Kristalle spricht darüber, wahrhaft nach seinen eigenen Gedanken zu handeln. Geben Sie dem Ganzen Form.",
                    questions : [ "Sind Sie sich dessen bewusst, was Sie wissen?", "Benehmen Sie sich auch so?", "Können Sie sich klar und deutlich ausdrücken?", "Können Sie analytisch denken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der „König der Kristalle“ stellt eine Person dar, die intelligent, ernsthaft und intellektuell ist, jemand mit einem schnellen Verstand. Sie können seriös und intelligent sein, ein ausgezeichnetes Urteilsvermögen haben. Aber es ist auch möglich, dass Sie sarkastisch und aggressiv sind. Vielleicht werden Sie manchmal ein richtiger Schikanierer. Sie müssen entscheiden, was Sie sein möchten, verständnisvoll, voller analytischer Kraft oder stur und sogar zynisch.</p><p>Zu diesem Zeitpunkt ist es in Ihrem Leben wichtig zu erkennen, dass alle diese Aspekte Teil Ihrer Persönlichkeit sind. Seien Sie sich dessen bewusst und bestimmen Sie, wer und was Sie wirklich sein wollen.</p><p>Es ist auch möglich, dass Sie mit einer hartnäckigen Person konfrontiert werden, die nicht bereit ist, seine oder ihre Meinung zu ändern. Nach einiger Gewissensprüfung stellen Sie vielleicht fest, dass es am besten für Sie wäre, Ihre Meinung zu ändern um die Situation erträglicher zu machen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie diese Karte ziehen es möglich ist, dass Sie sehr rationell in Ihren persönlichen Beziehungen verhalten werden. Sie haben das Bedürfnis, Probleme und Konflikte von einem intellektuellen Standpunkt anzunähern und die beteiligten Emotionen nicht zu berücksichtigen. Dieser Standpunkt wird Ihnen helfen, zum Herzen der Dinge zu geraten, aber an einem gewissen Punkt müssen Sie akzeptieren, dass Ihr Partner nicht so intellektuell gesinnten ist wie Sie und die Emotionen müssen ein Teil der Gleichung sein.</p><p>Es ist auch möglich, dass Sie in einer Beziehung mit jemandem sind, der sehr dominant ist und zerebral. Vielleicht geht sie / er sogar zu weit und überschreitet ab und zu mal Ihre Grenzen. Sie sind nicht in der Lage, das Verhalten dieser Person zu ändern, so müssen Sie nur zu Ihrem Boden halten und all das schützen, was Ihnen wichtig ist.</p><p>Wenn Sie alleine sind und auf der Suche nach einem Partner, versuchen Sie daran zu denken, dass das Finden der richtigen Person nicht nur eine Sache ist von intellektuellen Ansichten, sondern auch von Gefühlen und Emotionen. Sie müssen auch zeigen können was Sie fühlen.</p>",
                        [GuidanceFields.WORK] : "<p>Zur Zeit finden Sie es einfach, Ihre Arbeit zu tun. Sie verhalten Sich auf einer taktischen, flexiblen Weise. Vielleicht sind Sie sogar ein bisschen manipulativ ab und zu. Ist das wirklich, wie Sie sein möchten?</p><p>Oder vielleicht arbeiten Sie zusammen mit jemanden, der ziemlich offen und konfrontierend sein kann. Vielleicht mögen Sie diese Haltung nicht. Wenn das der Fall ist, fragen Sie Sich warum. Sie sind im Moment ziemlich emotional.</p><p>Wenn Sie auf der Suche nach Arbeit sind, egal wie überzeugt und selbst versichert Sie sind, vergessen Sie nicht, dass es am besten ist, die Hinweise Ihrer Interviewer zu folgen. Sie sind nicht in diesem Interview um eine Schlacht zu gewinnen, sondern nur um Ihre Qualitäten zu zeigen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Gerade jetzt nähern Sie Sich die Spiritualität auf einer intellektuellen Weise an. Sie brauchen sie erst völlig zu verstehen, bevor Sie sie anwenden oder sogar akzeptieren wollen. Jetzt wäre ein guter Zeitpunkt aus zu gehen und einen starken, ethischen Mentor zu suchen, der Ihnen alle benötigen Informationen geben kann, um eine Bewertung vorzunehmen. Und weil Sie gerade jetzt sehr klar denken, könnte es gut sein, dass Sie anfangen regelmäßig zu meditieren. Egal wie man Spiritualität angeht, Sie müssen jetzt aktiv sein.</p>",
                    }
                }
            },            
            {
                filename: "64",
                numerology : 1,
                en : {
                    name: "Ace of Worlds", subtitle: "Success",
                    suit : "Worlds",
                    element : "🜃 - earth",
                    theme : "I do",    
                    description : "Ace of Worlds, element  earth, talks about what you do in its totality. Everything is potentially present, but what will you do?",
                    questions : [ "What could you create?", "Do you have the necessary skills?", "What will you actually do?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Aces in general are cards that point out new beginnings, possibilities. The Ace of Worlds is about happiness, inner wealth, opportunities. This doesn't mean you should expect to get it all for free, without actively going out and looking, but in general this card tells you that you are very likely at a positive point in your life, generally feeling good. Possibly things are even taking a turn for the better, this could be financially but also in terms of relationship or career.</p><p>The Ace of Worlds can be compared to The Ace of Cups which is also a very lucky card.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a loving relationship this card signifies that you will soon enjoy or already are enjoying great times, you feel happy within your relationship, you cherish your partner and the relationship is getting more profound and meaningful. You and your partner might even be entering a whole new and intense stage of commitment.</p><p>If you are single the Ace of Worlds tells you it is very well possible you will meet someone interesting shortly. This is a perfect time to go out and meet new people. Enjoy the company of strangers and look around for that one person you might want to be with.</p>",
                        [GuidanceFields.WORK] : "<p>The Ace of Worlds is a generally positive card. It tells you that you are doing very well at your job.You may soon be getting a promotion or new and more responsible tasks, and of course also the financial betterment that goes with this change.</p><p>If you consider looking for a new job then this is the right time, opportunities will arise even when you are not really looking out for them.</p><p>If you are currently unemployed this is a perfect moment to find a position.Make sure you open up to all the possibilities and don’t overlook the ones right in front of you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Possibly your spiritual experiences are a very private thing to you,so you might find it difficult to share them. Still, this card tells you that now is a good time to go looking for people of the same conviction and to try and share your experiences with them. It will be a rewarding and enriching experience for you and it will change your outlook on the fundamental loneliness we are all facing from time to time.</p><p>Generally this is a good time to try out new beliefs, to seek out directions and convictions that are new to you.</p>",
                    }
                },
                fr : {
                    name : "L’As des Mondes", subtitle: "Réussite",
                    suit : "Worlds",
                    element :  ["🜃 - terre"],
                    theme : "Je le fais",    
                    description : "L'As des Mondes, élément terre, nous parle de ce que l’on fait dans son intégralité. Tout est potentiellement présent, mais qu'allez-vous faire?",
                    questions : [ "Que pouvez-vous faire?", "Avez-vous les compétences nécessaires?", "Qu'allez-vous vraiment faire?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Les as sont généralement des cartes qui indiquent de nouveaux commencements, de nouvelles possibilités.  L'\"As de Welten\" est synonyme de bonheur, de richesse intérieure et d'opportunité. Cela ne signifie pas que vous pouvez attendre de tout obtenir gratuitement, il est nécessaire de chercher par soi-même, devenir actif.  Mais en général, cette carte vous indique que vous êtes probablement à un moment positif de votre vie et que vous vous sentez généralement bien. Peut-être que les choses s'améliorent ! Ce peut être le cas financièrement, mais aussi pour les relations ou la carrière.</p><p>L'\"As de Welten\" peut être comparé à l'\"As de Coupe\", une carte qui représente aussi un grand bonheur.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a loving relationship this card signifies that you will soon enjoy or already are enjoying great times, you feel happy within your relationship, you cherish your partner and the relationship is getting more profound and meaningful. You and your partner might even be entering a whole new and intense stage of commitment.</p><p>If you are single the Ace of Worlds tells you it is very well possible you will meet someone interesting shortly. This is a perfect time to go out and meet new people. Enjoy the company of strangers and look around for that one person you might want to be with.</p>",
                        [GuidanceFields.WORK] : "<p>The Ace of Worlds is a generally positive card. It tells you that you are doing very well at your job.You may soon be getting a promotion or new and more responsible tasks, and of course also the financial betterment that goes with this change.</p><p>If you consider looking for a new job then this is the right time, opportunities will arise even when you are not really looking out for them.</p><p>If you are currently unemployed this is a perfect moment to find a position.Make sure you open up to all the possibilities and don’t overlook the ones right in front of you.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Possibly your spiritual experiences are a very private thing to you,so you might find it difficult to share them. Still, this card tells you that now is a good time to go looking for people of the same conviction and to try and share your experiences with them. It will be a rewarding and enriching experience for you and it will change your outlook on the fundamental loneliness we are all facing from time to time.</p><p>Generally this is a good time to try out new beliefs, to seek out directions and convictions that are new to you.</p>",
                    }
                },
                sp:{
                    name:"As de Mundos", subtitle: "Éxito",
                    suit: "Mundos",
                    element: "🜃 - tierra",
                    theme: "Sí, yo hago",    
                    description: "El As de Mundos, que se ajusta al elemento tierra, se trata de lo que haces en su totalidad. Todo está potencialmente presente, pero ¿qué vas a hacer?",
                    questions: ["¿Qué puedes hacer?", "¿Tienes la habilidad para hacerlo?", "¿Qué vas a hacer en realidad?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Ases en general son las tarjetas que señalan nuevos comienzos, posibilidades. El As de Oros es acerca de la felicidad, la riqueza interior, oportunidades. Esto no significa que usted debe esperar para obtener todo gratis, sin ir más activamente y buscando, pero en general, esta carta le dice que es muy probablemente en un punto positivo en su vida, en general, sentirse bien. Posiblemente las cosas están aún tomando un giro para mejor, esto podría ser financieramente, sino también en términos de relación o carrera. El As de Oros se puede comparar con el As de Copas que también es una tarjeta muy afortunado.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Werelden Aas", subtitle: "Succes",
                    suit : "Werelden",
                    element : "🜃 - aarde",
                    theme : "ik doe",    
                    description : "De Aas van Werelden, passend bij het element aarde, gaat over je doen in zijn totaliteit. Alles is in potentie aanwezig, maar wat ga jij doen?",
                    questions : [ "Wat kun jij vorm gaan geven?", "Heb jij daarvoor de vaardigheid?", "Wat ga jij daadwerkelijk doen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Azen zijn in de Tarot de kaarten die symbolisch zijn voor kansen en nieuwe mogelijkheden. Werelden Aas staat voor geluk, innerlijke rijkdom en een nieuwe start. Dit betekent niet dat je zomaar alles gratis en voor niets gepresenteerd krijgt zonder dat je er zelf iets voor hoeft te doen. Je moet er natuurlijk zelf op uit trekken en op zoek gaan naar dat geluk, die rijkdom, die start. Toch vertelt deze kaart je dat je op weg bent naar een positieve periode in je leven waarin je je echt goed voelt. En het kan zelfs nog beter gaan worden! Het positieve kan betrekking hebben op alle elementen van je leven: relatie, werk, financiën.</p><p>Je kan Werelden Aas vergelijken met Bekers Aas, het zijn allebei heel positieve kaarten die geluk beloven op allerlei manieren.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een liefdesrelatie hebt dan zit je ofwel middenin een prachtige periode of je bent ernaar op weg. Je voelt je gelukkig in je relatie, je bent toegewijd aan je partner en het contact tussen jullie wordt alsmaar dieper, intenser en rijker aan betekenis. Het is zelfs mogelijk dat jullie samen op weg zijn aan een nieuw engagement. Dat kan gaan over samenwonen, je verloven, kinderen krijgen of trouwen.</p><p>Als je single bent dan wijst deze kaart erop dat je waarschijnlijk binnenkort iemand zal ontmoeten. Nu is echt een ideale tijd voor jou om erop uit te gaan en nieuwe mensen op te gaan zoeken. Geniet van het gezelschap van deze onbekenden en kijk uit naar die ene persoon waar je misschien mee samen zou willen zijn.</p>",
                        [GuidanceFields.WORK] : "<p>Werelden Aas is over het algemeen een positieve kaart. Ze vertelt je dat je het goed doet op je werk. Het zit erin dat je binnenkort meer verantwoordelijkheid krijgt of zelfs een promotie. Natuurlijk zullen deze veranderingen er ook voor zorgen dat je het financieel beter krijgt.</p><p>Als je overweegt om van baan te veranderen dan is dit het juiste moment. Je zal merken dat er kansen op je pad komen zelfs als je er niet echt naar op zoek bent. Als je momenteel werkloos bent, ga dan nu volop op zoek. Stel je open voor alle mogelijke opties die je op je af ziet komen en zoek het ook even wat dichter bij huis zodat je niets over het hoofd ziet.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Voor jou zijn je spirituele vragen en inzichten een heel persoonlijke zaak en je vindt het absoluut niet gemakkelijk om hierover met anderen te praten. Toch vertelt deze kaart je dat het nu het juiste moment is om op zoek te gaan naar mensen met gelijkaardige spirituele overtuigingen en je ervaringen met hen te delen. Je zal merken dat dit een heel verrijkende ervaring voor je kan zijn. Door dit samenkomen met anderen zal je visie op de fundamentele menselijke eenzaamheid (die we allemaal af en toe ervaren) volledig veranderen.</p><p>Over het algemeen is het nu een goede tijd om nieuwe richtingen te bestuderen, om jezelf open te stellen voor zienswijzen die je tevoren nog nooit onderzocht had.</p>",
                    }
                },
                de : {
                    name: "Ass der Welten", subtitle: "Erfolg",
                    suit : "Welten",
                    element : "🜃 - Erde",
                    theme : "Ich tue es.",    
                    description : "Das Ass der Welten, Element Erde, spricht darüber, was wir in der Gesamtheit tun. Alles ist potentiell vorhanden, aber was werden Sie tun?",
                    questions : [ "Was können Sie tun?", "Haben Sie die notwendigen Fähigkeiten?", "Was werden Sie wirklich tun?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Asse sind im Allgemeinen Karten, die auf Neuanfänge, Möglichkeiten hinweisen.  Das „Ass der Welten“ steht für Glück, inneren Reichtum und Chancen. Dies bedeutet nicht, dass Sie erwarten können, alles umsonst zu bekommen, ohne aktiv  werden und suchen zu müssen. Aber im Allgemeinen sagt Ihnen diese Karte, dass Sie sich sehr wahrscheinlich an einem positiven Punkt in Ihrem Leben befinden, und dass Sie sich in der Regel gut fühlen. Möglicherweise nehmen Dinge eine Wendung zum Besseren! Dies könnte in finanzieller Hinsicht der Fall sein, aber auch für Beziehungen oder Karriere gelten.</p><p>Das „Ass der Welten“ lässt sich mit dem „Ass der Kelche“ vergleichen, eine Karte, die auch für großes Glück steht.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer liebevollen Beziehung sind bedeutet diese Karte, dass Sie bald großartige Zeiten genießen werden oder bereits genießen. Sie fühlen Sich glücklich in Ihrer Beziehung und Ihr Partner schätzt Sie und diese Beziehung wird immer tiefer und sinnvoller. Sie und Ihr Partner könnten sogar eintreten in eine ganz neue und intensive Phase dieser Verbundenheit.</p><p>Wenn Sie alleine sind, sagt das Ass der Welten Ihnen, es ist sehr gut möglich, dass Sie in Kurzem eine interessante Person treffen werden. Dies ist eine perfekte Zeit, aus zu gehen und neue Leute kennen zu lernen. Genießen Sie die Gesellschaft von Fremden und schauen Sie um Sich herum nach dieser einen Person, die vielleicht unter ihnen ist.</p>",
                        [GuidanceFields.WORK] : "<p>Das Ass der Welten ist eine allgemein positive Karte. Es sagt Ihnen, dass Sie sich sehr wohl in Ihrem Job fühlen. Sie können bald eine Beförderung erwarten oder neue und verantwortungsvolle Aufgaben erhalten, und natürlich auch die finanzielle Verbesserung, die damit zusammen hängt.</p><p>Wenn man auf der Suche ist nach einem neuen Job, dann ist dies der richtige Zeitpunkt. Chancen werden sich anbieten, auch wenn Sie im Moment nicht richtig auf der Suche nach ihnen sind.</p><p>Wenn Sie derzeit arbeitslos sind, ist dies ein perfekter Moment, um einen Job zu suchen. Stellen Sie sicher, dass Sie offen sind für alle Möglichkeiten und versäumen Sich nicht die zu sehen, die gerade vor Ihrer Nase sind.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Möglicherweise sind Ihre spirituelle Erfahrungen eine sehr private Angelegenheit, sogar so sehr, dass Sie es schwierig finden sie zu teilen. Trotzdem sagt diese Karte, dass es jetzt ein guter Zeitpunkt ist auf der Suche zu gehen nach Menschen mit der gleichen Überzeugung und zu versuchen mit ihnen Ihre Erfahrungen zu teilen. Es wird eine lohnende und bereichernde Erfahrung für Sie sein und es wird Ihre Ansichten auf die grundlegende Einsamkeit, mit der wir alle von Zeit zu Zeit konfrontiert werden, ändern.</p><p>Im Allgemeinen ist dies ein guter Zeitpunkt neue Überzeugungen, Richtungen und Einsichten zu suchen, die für Sie neu sind.</p>",
                    }
                }
            },
            {
                filename: "65",
                numerology : 2,
                en : {
                    name: "Two of Worlds", subtitle: "Reflection",
                    suit : "Worlds",
                    element : "🜃 - earth",
                    theme : "what will I do",    
                    description : "Two of Worlds talks about doubting what to do and about hemming and hawing about the different possibilities.",
                    questions : [ "Which are your possibilities?", "Can you be playful about it?", "How long will you be hemming and hawing?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Two of Worlds is all about balance, either being balanced or looking for balance. There may be some areas in your life that aren't balanced for the moment and you are trying to \"make them work\". Possibly there are some decisions to be made and you are in doubt which direction to go. If this is the case then it is wise to listen to your intuition. This card tells you that deep inside you already know what to do, you only have to acknowledge it.</p><p>Sometimes this card points out that you should find more balance financially, pay attention to what you earn and spend.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>if you are in a loving relationship, you may think that you are having a great time with your partner and everything seems fine.But if you take the effort to look closer you will realize that you don't spend enough time with him/her to ensure that your relationship stays balanced and happy. Make this time now, before it's too late and you lose the one you love.</p><p>If you are single, now is the time to ask yourself if you are really ready for a committed relationship. It's not about becoming the perfect partner, but still, if you want to commit, you have to be ready to sacrifice a lot of things in order to make a relationship work. Are you ready to make this commitment? Or do you need some more time to ‘play’?</p>",
                        [GuidanceFields.WORK] : "<p>Right nowyou easily adapt to different tasks and projects. This attitude also holds a risk, you might be taking on too many tasks and maybe you aren't seeing the bigger picture anymore. Make sure you focus on all the things you do and don't scatter your energy all over the place.</p><p>If you are in doubt whether you are at the right place, then maybe the time has come to broaden your horizon.</p><p>If you are looking for a job, try to focus on two or three opportunities. Don't send out dozens of applications, that won't lead to positive results right now.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Two of Worlds tells you it is very likely that you are not satisfied with the way things in your life are balanced. You feel as if your responsibilities are taking up way too much time and energy, leaving you no space at all to focus on your spiritual growth. It is very important to carefully consider in which ways you can change your life and agenda in order to make room for spiritual nourishment. It’s not only your body that needs to be fed, your soul and spirit need that too. Try to find this balance, it is very important for your quality of life, now and in the future.</p>",
                    }
                },
                fr : {
                    name : "Le Deux de Mondes", subtitle: "Réflexion",
                    suit : "Worlds",
                    element :  "🜃 - terre",
                    theme : "Que vais-je faire",    
                    description : "Le Deux de Mondes, nous parle de doute concernant ce qu'il faut faire et l’équilibre entre nos différentes possibilités.",
                    questions : ["Quelles sont vos possibilités?", "Pouvez-vous l’ approcher de manière ludique?", "Combien de temps allez-vous continuer à y songer?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Deux de Welten \" tout se tourne autour de la question de balance, soit l'équilibre, soit la recherche de l'équilibre. Il se peut que certains aspects de votre vie ne soient pas équilibrés en ce moment et que vous ayez des doutes quant à la direction à prendre. Si tel est le cas, il est conseillé d'écouter votre intuition. Cette carte vous dit qu'au fond de vous, vous savez déjà ce que vous avez à faire. Vous devez juste l'accepter.</p><p>Parfois, cette carte indique que vous avez besoin de trouver un meilleur équilibre financier, de faire plus attention à vos revenus et à vos dépenses.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>if you are in a loving relationship, you may think that you are having a great time with your partner and everything seems fine.But if you take the effort to look closer you will realize that you don't spend enough time with him/her to ensure that your relationship stays balanced and happy. Make this time now, before it's too late and you lose the one you love.</p><p>If you are single, now is the time to ask yourself if you are really ready for a committed relationship. It's not about becoming the perfect partner, but still, if you want to commit, you have to be ready to sacrifice a lot of things in order to make a relationship work. Are you ready to make this commitment? Or do you need some more time to ‘play’?</p>",
                        [GuidanceFields.WORK] : "<p>Right nowyou easily adapt to different tasks and projects. This attitude also holds a risk, you might be taking on too many tasks and maybe you aren't seeing the bigger picture anymore. Make sure you focus on all the things you do and don't scatter your energy all over the place.</p><p>If you are in doubt whether you are at the right place, then maybe the time has come to broaden your horizon.</p><p>If you are looking for a job, try to focus on two or three opportunities. Don't send out dozens of applications, that won't lead to positive results right now.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Two of Worlds tells you it is very likely that you are not satisfied with the way things in your life are balanced. You feel as if your responsibilities are taking up way too much time and energy, leaving you no space at all to focus on your spiritual growth. It is very important to carefully consider in which ways you can change your life and agenda in order to make room for spiritual nourishment. It’s not only your body that needs to be fed, your soul and spirit need that too. Try to find this balance, it is very important for your quality of life, now and in the future.</p>",
                    }
                },
                sp:{
                    name:"El Dos de Mundos", subtitle: "Reflexión",
                    suit: "Mundos",
                    element: "🜃 - tierra",
                    theme: "¿qué voy a hacer?",    
                    description: "El Dos de Mundos es sobre la duda sobre qué hacer y nos indica nuestro acto de equilibrio entre las diferentes posibilidades",
                    questions: ["¿Qué posibilidades tienes?", "¿Puedes jugar con eso?", "¿Cuánto tiempo seguirás sopesando?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Dos de Oros es cuestión de equilibrio, ya sea estar equilibrada o en busca de equilibrio. Puede haber algunas áreas de su vida que no son equilibradas por el momento y que están tratando de \"hacer que funcionen\". Posible hay algunas decisiones que tomar y que están en duda en qué dirección ir. Si este es el caso, entonces es sabio escuchar a su intuición. Esta tarjeta le dice que en el fondo usted ya sabe qué hacer, sólo tiene que reconocerlo. A veces, esta carta señala que debería buscar un mayor equilibrio financiero, prestar atención a lo que gana y gasta.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Werelden Twee", subtitle: "Bespiegeling",
                    suit : "Werelden",
                    element : "🜃 - Erde",
                    theme : "wat zal ik doen",    
                    description : "De twee van Werelden gaat over twijfel over wat te doen en wijst ons op ons wikken en wegen tussen verschillende mogelijkheden.",
                    questions : [ "Welke mogelijkheden heb jij?", "Kun jij daar speels mee omgaan?", "Hoe lang blijf je wikken en wegen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Werelden Twee draait rond evenwicht, ofwel ben je op zoek naar evenwicht, ofwel probeer je in evenwicht te blijven. Het is best mogelijk dat je op een aantal vlakken in je leven niet in balans bent en dat je gewoon probeert om de boel draaiende te houden. Misschien weet je wel dat je een aantal beslissingen moet nemen maar ben je er nog niet uit welke kant je op wil. Als dit het geval is, weet dan dat het verstandig is om nu naar je intuïtie te luisteren. Dat is wat deze kaart je vertelt: diep vanbinnen weet je al wat je te doen staat, je moet het enkel nog onder ogen zien.</p><p>Soms wijst deze kaart er ook op dat het belangrijk voor je is om financieel wat meer evenwicht te vinden, probeer wat je verdient en wat je uitgeeft beter in balans te brengen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een liefdesrelatie hebt dan is het best mogelijk dat je denkt dat alles in orde is, dat jullie samen een fijne tijd hebben. Maar als je de moeite doet om de situatie wat nader te bekijken dan zal je beseffen dat je niet genoeg tijd doorbrengt met je partner. Op deze manier zal je relatie niet evenwichtig zijn en blijven. Maak nu tijd voor die ander, voordat het te laat is en je hem/haar verliest. Als je op dit ogenblik alleen bent, vraag je dan af of dit wel een goede tijd is om op zoek te gaan naar liefde in je leven. Het gaat er niet om dat je de perfecte partner moet zijn maar toch, als je je wil engageren in een relatie, moet je er klaar voor zijn om een aantal dingen op te offeren, om plaats te maken in je leven voor die ander, of het werkt niet. Ben je zeker dat je hier klaar voor bent? Of heb je eerst nog wat tijd nodig om te “spelen”?</p>",
                        [GuidanceFields.WORK] : "<p>Op dit moment ben je heel soepel, je past je gemakkelijk aan op werkgebied, nieuwe taken en projecten aanpakken vind je helemaal niet moeilijk. Deze benadering houdt ook risico’s in want het is mogelijk dat je teveel hooi op je vork neemt en dat je dan het grotere plaatje uit het oog verliest. Zorg ervoor dat je geconcentreerd blijft op alles wat je doet en verspil geen energie aan heen en weer lopen van de ene taak naar de andere.</p><p>Als je twijfelt of je wel op de juiste plek zit, dan is nu het goede moment om je horizon te verbreden en op zoek te gaan naar iets anders.</p><p>Als je op zoek bent naar een baan, probeer je dan te concentreren op telkens twee of drie mogelijkheden, niet meer. Het is niet zinvol om tientallen sollicitaties te versturen want dan dreig je het overzicht te verliezen. Pak het liever wat kleinschaliger aan maar wees bij elke sollicitatie zo grondig mogelijk.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Werelden Twee vertelt je dat het goed mogelijk is dat je niet tevreden bent met hoe de zaken verdeeld zijn in je leven. Je hebt het gevoel dat al je verantwoordelijkheden veel te veel tijd en energie van je vragen. Je hebt het idee dat je daardoor geen enkele ruimte hebt om spiritueel te groeien. Het is heel belangrijk dat je er nu grondig over nadenkt hoe je je leven en je agenda dusdanig kan veranderen dat je ook tijd kan maken om spiritueel ‘bij te tanken’. Niet enkel je lichaam moet onderhouden en gevoed worden, je geest en je ziel hebben dat ook nodig. Probeer hierin meer evenwicht te vinden, het is erg belangrijk voor je levenskwaliteit, nu en in de toekomst.</p>",
                    }
                },
                de : {
                    name: "Zwei der Welten", subtitle: "Reflexion",
                    suit : "Welten",
                    element : "🜃 - Erde",
                    theme : "Was soll ich tun?",    
                    description : "Die Zwei der Welten spricht vom Zweifeln an der Vorgehensweise und dem Gleichgewicht zwischen unseren verschiedenen Möglichkeiten.",
                    questions : [ "Was sind Ihre Möglichkeiten?", "Können Sie spielerisch damit umgehen?", "Wie lange wollen Sie noch darüber nachdenken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Bei der „Zwei der Welten“ dreht sich alles um Gleichgewicht, entweder um Ausgeglichenheit oder um die Suche nach Gleichgewicht. Möglicherweise gibt es einige Bereiche in Ihrem Leben, die zurzeit nicht ausgeglichen sind, und Sie haben Zweifel darüber, welche Richtung Sie einschlagen müssen. Möglicherweise sind einige Entscheidungen zu treffen, und Sie haben Zweifel darüber, in welche Richtung sie gehen sollen. Wenn dies der Fall ist, dann ist es ratsam, auf Ihre Intuition zu hören. Diese Karte sagt Ihnen, dass Sie tief in Ihrem Inneren bereits wissen, was Sie zu tun haben. Sie müssen es nur annehmen.</p><p>Manchmal weist diese Karte darauf hin, dass Sie ein besseres finanzielles Gleichgewicht  suchen müssen, Ihren Einnahmen und Ausgaben mehr Aufmerksamkeit schenken sollten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer liebevollen Beziehung sind, könnten Sie glauben, dass Sie eine fantastische Zeit mit Ihrem Partner erleben und alles in bester Ordnung erscheint. Aber, wenn Sie Sich die Mühe nehmen, Sich die Situation näher anzusehen, werden Sie feststellen, dass Sie nicht genügend Zeit mit Ihrem Partner verbringen, um sicherzustellen, dass Ihre Beziehung ausgeglichen und glücklich bleibt. Machen Sie jetzt diese Zeit frei bevor es zu spät ist und Sie Ihre Liebe verlieren.</p><p>Wenn Sie alleine sind, ist jetzt die Zeit, sich zu fragen, ob Sie wirklich bereit sind eine feste Beziehung einzugehen. Es ist nicht über das sein des perfekten Partners, aber viel mehr noch, wenn Sie Sich verbinden, müssen Sie bereit sein viele Dinge zu opfern. Sind Sie bereit, diese Opfer zubringen? Oder brauchen Sie noch ein bisschen Zeit zum Spielen?</p>",
                        [GuidanceFields.WORK] : "<p>Gerade jetzt passen Sie Sich leicht an unterschiedlichen Aufgaben und Projekten an. Damit gehen Sie auch ein Risiko ein. Sie könnten zu viele Aufgaben auf sich nehmen und das größere Bild damit verlieren. Stellen Sie sicher, dass Sie alle Energie gleichmäßig verteilen über alle Dinge, die Sie tun und sie nicht überall verschwenden.</p><p>Wenn Sie Sich nicht sicher sind, ob Sie an der richtigen Stelle sind, dann ist vielleicht die Zeit gekommen, um Ihren Horizont zu erweitern.</p><p>Wenn Sie auf der Suche nach einem Job sind, versuchen Sie dann Sich auf zwei oder drei Chancen zu konzentrieren. Senden Sie nicht Dutzende Erwerbungen herum, die momentan nicht zu positiven Ergebnissen führen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Zwei der Welten sagt Ihnen, dass es ist sehr wahrscheinlich, dass Sie im Augenblick nicht zufrieden sind mit den Sachen, die jetzt in Ihrem Leben vor sich gehen. Ihr Leben ist jetzt nicht ausgeglichen. Sie fühlen sich, als ob Ihre Aufgaben zu viel Zeit und Energie beschlagnahmen, sodass Sie keinen Raum mehr haben um sich überhaupt auf Ihrem spirituellen Wachstum zu konzentrieren. Es ist jetzt sehr wichtig, sorgfältig Ihre Tagesordnung und Ihr Leben zu überprüfen, sodass Sie Raum finden für geistige Ernährung. Es ist nicht nur Ihren Körper, der genährt werden muss, sondern auch Ihre Seele und Ihr Geist. Versuchen Sie, dieses Gleichgewicht zu finden, ist es sehr wichtig für Ihre Lebensqualität, so wie für jetzt als auch für die Zukunft.</p>",
                    }
                }
            },
            {
                filename: "66",
                numerology : 3,
                en : {
                    name: "Three of Worlds", subtitle: "Nurturing",
                    suit : "Worlds",
                    element : "🜃 - earth",
                    theme : "make room for what you possess",    
                    description : "Three of Worlds talks about committing to work with your own qualities and about cooperating, using each personal quality of all people involved.",
                    questions : [ "Are you looking up to someone?", "Which distance can be gapped?", "What are you good at?", "Do you work solo or together?", "What do you do to create something?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This is generally a very positive card. It tells you that you are doing well and no major changes are required. You may decide to do some things differently, but you don't have to. If you feel like making some changes, make sure you listen to the people around you, but don't blindly follow their opinions, consider them and then make your own decisions.</p><p>This card also tells you that who you are and what you do is appreciated by the people around you, you can be sure of that even if they don't always tell you.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Three of Worlds tells you that you are considering an important step in your relationship that will bring you and your partner a lot closer. You aren’t having any problems but you really feel the urge to be more intimate, more together.</p><p>If you are currently single and there is someone you have taken an interest in, this card tells you the other person is interested in you too. Maybe you aren't madly in love, but you both think the other may have the qualities you are looking for in a partner.</p>",
                        [GuidanceFields.WORK] : "<p>When it comes to work and career issues this card is also a very positive sign. It tells you that you are in a good place at work, people appreciate what you do and how you do it, they are fully aware of your talents and capabilities. It is very likely that you will get a promotion or reach a new level. You are really good at what you do and in time you will get even better.</p><p>If you are looking for a job there is a very good chance you will soon find what you are looking for.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually the time is right to look for fresh inspiration in the past, in history. Maybe there are some ancient philosophies or beliefs you would like to study closer, or maybe old rituals are drawing your attention. Do not think you have to join a certain belief or \"club\", at this point it's the act of gaining knowledge in itself that’s attractive and interesting to you. Studying and exploring is a perfect way to nourish your spirit, to make your understanding more profound.</p>",
                    }
                },
                fr : {
                    name : "Le Trois de Mondes", subtitle: "Nourriture",
                    suit : "Worlds",
                    element :  "🜃 - terre",
                    theme : "Donner de l’espace à ce que vous avez",    
                    description : "Le Trois de Worlds, nous parle de travailler avec ses qualités inouïes et de travailler ensemble, en utilisant les qualités de chacun.",
                    questions : ["Admirez-vous quelqu'un?", "Quelle distance pouvez-vous franchir?", "Qu'est-ce que vous savez faire?", "Travaillez-vous seul ou avec d'autres?", "Que faites-vous pour accomplir quelque chose?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>C'est généralement une carte très positive. Il vous dit que vous allez bien et qu'aucun changement majeur n'est nécessaire. Vous pourriez décider de faire les choses différemment, mais ce n'est pas la peine. Si vous sentez que vous avez besoin de faire des changements, vous devriez écouter les gens autour de vous, mais ne pas suivre aveuglément leur opinion. Réfléchissez bien et prenez vos propres décisions.</p><p>Cette carte vous montrera également qui vous êtes et ce que vous devez faire pour être apprécié par les gens autour de vous. Vous serez apprécié, même s'ils ne vous le disent pas toujours.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Three of Worlds tells you that you are considering an important step in your relationship that will bring you and your partner a lot closer. You aren’t having any problems but you really feel the urge to be more intimate, more together.</p><p>If you are currently single and there is someone you have taken an interest in, this card tells you the other person is interested in you too. Maybe you aren't madly in love, but you both think the other may have the qualities you are looking for in a partner.</p>",
                        [GuidanceFields.WORK] : "<p>When it comes to work and career issues this card is also a very positive sign. It tells you that you are in a good place at work, people appreciate what you do and how you do it, they are fully aware of your talents and capabilities. It is very likely that you will get a promotion or reach a new level. You are really good at what you do and in time you will get even better.</p><p>If you are looking for a job there is a very good chance you will soon find what you are looking for.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually the time is right to look for fresh inspiration in the past, in history. Maybe there are some ancient philosophies or beliefs you would like to study closer, or maybe old rituals are drawing your attention. Do not think you have to join a certain belief or \"club\", at this point it's the act of gaining knowledge in itself that’s attractive and interesting to you. Studying and exploring is a perfect way to nourish your spirit, to make your understanding more profound.</p>",
                    }
                },
                sp:{
                    name:"El Tres de Mundos", subtitle: "Nutrición",
                    suit: "Mundos",
                    element: "🜃 - tierra",
                    theme: "da lo que tienes espacio",    
                    description: "El tres de Mundos tratan sobre el trabajo con sus propias cualidades y nos señalan que podemos trabajar juntos, usando las propias cualidades de cada uno",
                    questions: ["¿Admiras a alguien?", "¿Qué distancia se puede salvar?", "¿En qué eres bueno?", "¿Trabajas solo o con otros?", "¿Qué haces para lograr algo?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esto generalmente es una tarjeta muy positiva. Se le dice que usted está haciendo bien y no se requieren cambios importantes. Usted puede decidir qué hacer algunas cosas de manera diferente, pero usted no tiene que hacerlo. Si tiene ganas de hacer algunos cambios, asegúrese de que usted escucha a las personas que le rodean, pero no sigue ciegamente sus opiniones, considere ellos y luego tomar sus propias decisiones. Esta tarjeta también le dice que lo que es y lo que hace es apreciado por la gente alrededor de usted, usted puede estar seguro de que, incluso si no siempre le dicen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Werelden Drie", subtitle: "Voeding",
                    suit : "Werelden",
                    element : "🜃 - aarde",
                    theme : "geef dat wat je hebt de ruimte",    
                    description : "De drie van Werelden gaat over werken met inzet van je eigen kwaliteiten en wijst ons erop dat we samen kunnen werken, gebruikmakend van ieders eigen kwaliteiten.",
                    questions : [ "Kijk je tegen iemand op?", "Welke afstand kan overbrugd worden?", "Waar ben jij goed in?", "Werk jij alleen of samen met anderen?", "Wat doe jij om iets tot stand te brengen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart is over het algemeen positief. Ze vertelt je dat je de dingen goed aanpakt en dat er geen grote veranderingen nodig zijn. Je kan natuurlijk beslissen om bepaalde zaken anders aan te pakken, maar deze kaart vertelt je dat het niet noodzakelijk is, het kan maar het moet niet.</p><p>Stel dat je bepaalde veranderingen overweegt, praat er dan over met de mensen om je heen. Volg hun advies niet blindelings, denk erover na en volg dan toch de weg die jou het beste lijkt.</p><p>Deze kaart vertelt je ook dat wie je bent en wat je doet gewaardeerd wordt door de mensen om je heen. Ze zijn blij dat je in hun leven bent ook al laten ze je dat niet altijd weten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Werelden Drie wijst erop dat je aan het overwegen bent om een belangrijke stap te zetten in verband met je relatie, een stap die jou en je partner dichter bij mekaar zal brengen. Jullie hebben op dit moment geen problemen maar jij voelt de behoefte aan meer intimiteit, meer samenzijn met hem/haar, vandaar die stap.</p><p>Als je op dit moment single bent en er is iemand waarin je geïnteresseerd bent, dan vertelt deze kaart je dat die persoon jou ook leuk vindt. Dit betekent natuurlijk niet dat jullie onmiddellijk smoorverliefd in mekaars armen zullen vallen maar wel dat jullie allebei denken dat de ander potentieel partnermateriaal is en dat het zinvol is om mekaar beter te leren kennen.</p>",
                        [GuidanceFields.WORK] : "<p>Op het gebied van werk en carrière brengt deze kaart een positieve boodschap. Ze vertelt je dat je goed in je vel zit op het werk, dat mensen waarderen wat je allemaal doet en hoe je het doet en dat ze zich echt wel bewust zijn van je talenten en kwaliteiten. Het is heel waarschijnlijk dat je een promotie zal krijgen of dat je binnenkort toch minstens een stap in die richting zal zetten. Je bent echt goed in wat je doet en je zal er mettertijd zelfs nog beter in worden.</p><p>Als je op zoek bent naar werk dan mag je ervan uitgaan dat je in de komende tijd vindt waar je naar op zoek bent.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritueel gezien is het nu een goed moment om naar inspiratie te gaan zoeken in het verleden, de geschiedenis. Misschien zijn er klassieke denkrichtingen of filosofen die je eens wat nader wil bestuderen, of misschien heb je interesse voor oude rituelen en gewoontes. Dit betekent zeker niet dat je je moet gaan aansluiten bij een of andere sekte of club. Op dit moment gaat het erom dat je gaat genieten van het verwerven van kennis op zichzelf, dat je daar voldoening in vindt. Studeren en onderzoeken is een fantastische manier om je geest te voeden, om ervoor te zorgen dat je de wereld om je heen op een andere, diepere manier gaat zien en begrijpen.</p>",
                    }
                },
                de : {
                    name: "Drei der Welten", subtitle: "Ernährung",
                    suit : "Welten",
                    element : "🜃 - Erde",
                    theme : "Dem Raum geben, was man hat",    
                    description : "Die Drei der Welten spricht davon, mit seinen außergewöhnlichen Qualitäten zu arbeiten sowie der Zusammenarbeit mit anderen, bei der die Qualitäten von jedem genutzt werden.",
                    questions : [ "Bewundern Sie jemanden?", "Wie weit können Sie gehen?", "Was können Sie tun?", "Arbeiten Sie allein oder mit anderen?", "Was tun Sie, um etwas zu erreichen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Dies ist im Allgemeinen eine sehr positive Karte. Sie sagt Ihnen, dass es Ihnen gut geht und keine größeren Änderungen erforderlich sind. Sie könnten sich entscheiden, einiges anders zu machen, aber Sie müssen das nicht tun. Wenn Sie das Gefühl haben, einige Veränderungen vornehmen zu müssen, dann sollten Sie auf die Menschen in Ihrer Umgebung hören, aber nicht blindlings deren Meinung folgen. Überlegen Sie sich es gut und treffen Sie Ihre eigenen Entscheidungen.</p><p>Diese Karte zeigt Ihnen auch, wer Sie sind und was Sie tun müssen, um von den Menschen in Ihrer Umgebung geschätzt zu werden. Dessen können Sie sich sicher sein, auch wenn diese es Ihnen nicht immer zeigen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Die Drei der Welten sagt Ihnen, dass Sie Sich überlegen einen wichtigen Schritt in Ihrer Beziehung zu machen, der Sie und Ihren Partner ein gutes Stück näher zu einander bringen würde. Es gibt keine richtige Probleme, aber Sie haben wirklich das Gefühl, der Drang, intimer mit einander zusammen zu sein.</p><p>Wenn Sie derzeit alleine sind und es gibt jemanden für wen Sie Sich interessieren, dann sagt diese Karte, die auch die andere Person interessiert ist an Sie. Vielleicht seit Ihr nicht ganz in einander verliebt, aber sie beide denken, der andere könnte die Eigenschaften haben, die sie suchen in einem Partner.</p>",
                        [GuidanceFields.WORK] : "<p>Wenn es sich um die Karriere und Arbeitsprobleme handelt, kann diese Karte auch ein sehr positives Zeichen sein. Sie sagt Ihnen, dass Sie an einem guten Platz in der Arbeit sind. Menschen wissen Sie zu schätzen um was Sie tun und wie Sie es tun, voller Bewusstsein. Sie sind völlig überzeugt von Ihren Talenten und Fähigkeiten. Es ist sehr wahrscheinlich, dass Sie befördert, oder ein neues Niveau erreichen werden. Sie sind wirklich gut in dem was Sie tun und mit der Zeit werden Sie noch besser werden.</p><p>Wenn Sie auf der Suche sind, nach einem Job gibt es jetzt sehr gute Chancen, dass Sie bald finden, was Sie suchen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spirituell ist die Zeit reif, um für frische Inspiration in die Vergangenheit, in die Geschichte zurück zu blicken. Vielleicht gibt es einige alten Philosophien und Überzeugungen, die Sie gerne näher studieren möchten, oder vielleicht werden alte Rituale Ihre Aufmerksamkeit wecken. Glaube nicht, dass Sie jetzt in einen bestimmten Glauben oder \"Club\" eintreten müssen. An diesem Punkt ist es der Akt der Erkenntnisgewinnung selbst, die attraktiv und interessant für Sie ist. Das Studium und die Erforschung ist ein perfekter Weg, um Ihren Geist zu nähren, um Ihr Verständnis zu vertiefen.</p>",
                    }
                }
            },
            {
                filename: "67",
                numerology : 4,
                en : {
                    name: "Four of Worlds", subtitle: "Commencement",
                    suit : "Worlds",
                    element : "🜃 - earth",
                    theme : "having a handle on what you possess",    
                    description : "Four of Worlds talks about having a handle on what is valuable to you and that you are probably holding on to it to tightly.",
                    questions : [ "Are you that tenacious?", "Do previous experiences bother you?", "Are you open to new experiences?", "What could you re-organize?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>When you look at this card you see a man literally sitting on and holding on to the coins, he seems afraid to let go of them. And that's what this card is about, holding on too tightly, being afraid of letting go of possessions, people, habits, emotions. The card is also about fear of change, an almost desperate longing for security. But clinging to things, people or possessions doesn't bring you security, it only makes you rigid. And rigidity makes it impossible for you to grow, to move, to enjoy life. The Four of Worlds tells you that you will have to change if you really want to live life to the fullest.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card is about fear. And because you are afraid of losing your partner, of being left behind, you cling to her/him, you hold on way too tightly, making it almost impossible for your partner to even breathe. If you maintain this attitude, you will provoke exactly what is your greatest fear, and your partner will desert you.</p><p>If you truly love someone then you have to let them be free, free to do whatever they want, and to trust them not to hurt or betray you. If you face your biggest fears you will be able to let them go.Only then you can allow your partner to freely commit to you and the relationship.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are rigid, you hold on to old habits and familiar ways of doing things. Even when asked you find it very hard to adapt to new rules and procedures. It is now time to realize that letting go of the old ways will in a way set you free, allow you to learn and grow and even appreciate the \"new\" ways.</p><p>Possibly you don't like your job anymore, you keep doing it day after day because of the security it offers you, but it doesn't bring you any joy, any fulfillment. Allow yourself the freedom of thinking about changing jobs, looking for a new challenge, new opportunities. Possibly, when confronted with other possibilities you may decide to stay where you are, but then it will be a conscious choice, not a must.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Four of Worlds tells you that the time has come to do some soul searching: what are your biggest fears? Which people are you clinging to? What objects or feelings or people are you holding too strongly? And furthermore, why do you hold on to things so tightly? What do you think might happen if you would simply let go? Look at yourself honestly and try to answer these questions. Once you do this you may realize that true freedom lies in letting go, in creating space between you and everything and everyone else. Once you do let go and you experience the space this creates first hand, you will finally be able to breathe deeply again. To live is to change!</p>",
                    }
                },
                fr : {
                    name : "Le Quatre de Mondes", subtitle: "Lancement",
                    suit : "Worlds",
                    element :  "🜃 - terre",
                    theme : "Prendre en main de ce que vous avez",    
                    description : "Le Quatre de Mondes, nous parle de saisir ce qui nous est précieux et à quoi nous nous accrochons, peut-être trop désespérément.",
                    questions : ["Êtes-vous tellement obstiné?", "Souffrez-vous d'expériences précédentes?", "Vous autorisez-vous de nouvelles expériences?", "Que pourriez-vous réorganiser?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Si vous regardez attentivement cette carte, vous verrez un homme assis littéralement sur des pièces de monnaie et s'y accrochant, comme s'il avait peur de devoir s'en séparer. C'est exactement ce que représente cette carte.  La peur de quitter les biens, les gens, les habitudes et les émotions. La carte représente la peur du changement et le désir désespéré de sécurité. Mais s'accrocher aux choses, aux personnes et aux biens ne vous apportent pas la sécurité. Cela ne fera que vous bloquer et vous rendre anxieux. La rigidité et la peur vous empêchent de grandir, de bouger, de profiter de la vie. Le \"Quatre de Welten\" vous dit que vous devez changer si vous voulez vraiment profiter pleinement de la vie.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card is about fear. And because you are afraid of losing your partner, of being left behind, you cling to her/him, you hold on way too tightly, making it almost impossible for your partner to even breathe. If you maintain this attitude, you will provoke exactly what is your greatest fear, and your partner will desert you.</p><p>If you truly love someone then you have to let them be free, free to do whatever they want, and to trust them not to hurt or betray you. If you face your biggest fears you will be able to let them go.Only then you can allow your partner to freely commit to you and the relationship.</p>",
                        [GuidanceFields.WORK] : "<p>At work you are rigid, you hold on to old habits and familiar ways of doing things. Even when asked you find it very hard to adapt to new rules and procedures. It is now time to realize that letting go of the old ways will in a way set you free, allow you to learn and grow and even appreciate the \"new\" ways.</p><p>Possibly you don't like your job anymore, you keep doing it day after day because of the security it offers you, but it doesn't bring you any joy, any fulfillment. Allow yourself the freedom of thinking about changing jobs, looking for a new challenge, new opportunities. Possibly, when confronted with other possibilities you may decide to stay where you are, but then it will be a conscious choice, not a must.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>The Four of Worlds tells you that the time has come to do some soul searching: what are your biggest fears? Which people are you clinging to? What objects or feelings or people are you holding too strongly? And furthermore, why do you hold on to things so tightly? What do you think might happen if you would simply let go? Look at yourself honestly and try to answer these questions. Once you do this you may realize that true freedom lies in letting go, in creating space between you and everything and everyone else. Once you do let go and you experience the space this creates first hand, you will finally be able to breathe deeply again. To live is to change!</p>",
                    }
                },
                sp:{
                    name:"El Cuatro de Mundos", subtitle: "despegue",
                    suit: "Mundos",
                    element: "🜃 - tierra",
                    theme: "agarrarse a lo que se tiene",    
                    description: "El cuatro de Mundos tratan de agarrar lo que es valioso para ti y señala lo que tú, tal vez demasiado convulsivo, estás agarrando",
                    questions: ["¿Eres tan tenaz?", "¿Sufres de experiencias anteriores?", "¿Te permites nuevas experiencias?", "¿Qué podrías reorganizar?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cuando nos fijamos en esta tarjeta ves a un hombre literalmente sentado en y aferrándose a las monedas, que parece tener miedo de dejar ir de ellos. Y eso es lo que esta tarjeta es de, aferrarse demasiado, tener miedo de dejar ir de las posesiones, las personas, los hábitos, las emociones. La tarjeta también se trata de miedo al cambio, un deseo casi desesperado para la seguridad. Pero aferrarse a cosas, personas o posesiones no le trae seguridad, sólo le hace rígido. Y rigidez hace que sea imposible para que se desarrolle, para moverse, para disfrutar de la vida. El Cuatro de Oros le dice que usted tendrá que cambiar si realmente quiere vivir la vida al máximo.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Werelden Vier", subtitle: "Start",
                    suit : "Werelden",
                    element : "🜃 - aarde",
                    theme : "grip op wat je hebt",    
                    description : "De vier van Werelden gaat over grip op dat wat voor jou waardevol is en wijst ons op hetgeen je, wellicht te krampachtig, vasthoudt.",
                    questions : [ "Ben jij zo vasthoudend?", "Heb je last van eerdere ervaringen?", "Sta jij jezelf nieuwe ervaringen toe?", "Wat zou jij opnieuwe kunnen ordenen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Als je naar deze kaart kijkt dan zie je een man die letterlijk op een aantal munten zit en die zich zelfs vastklampt aan eentje. Het ziet er naar uit dat hij bang is om ze los te laten. En net daar gaat het om bij deze kaart: je vastklampen aan dingen, bang zijn om mensen, bezittingen, gewoontes of gevoelens los te laten. En als logisch vervolg daarop gaat deze kaart natuurlijk ook over angst voor verandering en een bijna wanhopige behoefte aan veiligheid, je beschermd voelen.</p><p>Eén ding is duidelijk: als je vasthoudt aan mensen, gevoelens, gewoontes en bezittingen betekent dit absoluut niet dat je ook veilig zal zijn. Het zorgt er enkel voor dat je star en angstig wordt, dat je je nauwelijks nog durft te bewegen. Starheid en angst maken het onmogelijk voor je om nog te groeien, te bewegen, van het leven te genieten. Werelden Vier vertelt je dat je dringend zal moeten veranderen als je iets aan het leven wil hebben, als je je leven echt wil gaan beleven.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart wijst op angst. Je bent op dit ogenblik bang om je partner te verliezen, om achtergelaten te worden. Daarom klamp je je vast aan hem/haar, houd je de ander in zo’n stevige greep dat ze het moeilijk krijgen om zelfs maar te ademen. Als je blijft doorgaan met deze houding dan zal je exact datgene veroorzaken wat je het meeste vreest: je partner zal je verlaten, al is het maar uit zelfbehoud. Als je echt van iemand houdt dan moet je hen vrijlaten, vrij om te doen wat zij willen. En je moet hen vertrouwen, erin geloven dat ze niets zullen doen om je te kwetsen of te verraden. Bekijk je diepste angsten, ga de confrontatie ermee aan. Je zal merken dat je ze dan achter je kan laten. Het is immers net omdat je van je angst wegloopt dat die je blijft achtervolgen. Enkel als je je angsten loslaat, kan je je partner eindelijk de ruimte geven om vrijelijk voor jou te kiezen en helemaal voor jullie relatie te gaan.</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk ben je nogal strak, bijna star, tegenwoordig, je houdt je vast aan oude gewoontes en vertrouwde manieren om bepaalde dingen aan te pakken. Je vindt het erg moeilijk om je aan te passen aan nieuwe regels en procedures, zelf als je dat uitdrukkelijk gevraagd wordt. Het wordt echt tijd dat je gaat beseffen dat er een vorm van vrijheid schuilt in het loslaten van oude dingen, de vrijheid om te leren en te groeien en misschien zelfs de ‘nieuwe’ aanpak te gaan waarderen.</p><p>Misschien vind je je baan niet meer fijn, blijf je er enkel dag na dag mee doorgaan vanwege de veiligheid die het je biedt. Misschien haal je geen vreugde meer uit je werk, geen voldoening. Als dat het geval is, sta jezelf dan eens toe om na te denken over een andere baan, een nieuwe uitdaging, nieuwe kansen. Als je andere mogelijkheden gaat onderzoeken is het mogelijk dat je blijft waar je bent, maar dan zal het een bewuste keuze zijn, geen verplichting.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Werelden Vier vertelt je dat het nu een goede tijd is om in jezelf op zoek te gaan naar een aantal antwoorden: wat zijn je grootste angsten? Aan welke mensen klamp je je echt vast? Welke voorwerpen, gevoelens of mensen kan je moeilijk loslaten? En tenslotte: waarom vind je het nodig om bepaalde dingen, mensen, gevoelens zo stevig vast te houden? Wat denk je dat er zal gebeuren als je ze zou loslaten? Kijk open en eerlijk naar jezelf en probeer deze vragen te beantwoorden. Eens je alle antwoorden op een rijtje hebt, zal je gaan beseffen dat de ware vrijheid er net in ligt dat je loslaat, dat je ruimte creëert tussen jezelf en alle andere mensen en dingen. En als je dan uiteindelijk begint los te laten en echt ervaart hoeveel ruimte je hierdoor krijgt, zal je merken dat je voor het eerst sinds lange tijd weer diep kan ademhalen. En je zal de spanningen uit je weg voelen vloeien naarmate je beter en dieper ademhaalt. Leven is veranderen!</p>",
                    }
                },
                de : {
                    name: "Vier der Welten", subtitle: "Starten",
                    suit : "Welten",
                    element : "🜃 - Erde",
                    theme : "Die Kontrolle darüber übernehme, was man hat.",    
                    description : "Die Vier der Welten spricht über das Erfassen dessen, was uns kostbar ist und woran wir uns klammern, vielleicht zu verzweifelt.",
                    questions : [ "Sind Sie so stur?", "Leiden Sie unter früheren Erfahrungen?", "Erlauben Sie sich neue Erfahrungen?", "Was könnten Sie neu organisieren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Wenn man sich diese Karte genau anschaut sieht man auf ihr einen Mann, der buchstäblich auf Münzen sitzt und sich an ihnen festhält, ganz so, als würde er sich davor fürchten, sich von ihnen trennen zu müssen. Genau dafür steht diese Karte. Die Angst vor einem Abschied von Besitz, Menschen, Gewohnheiten und Emotionen. Die Karte steht für die Angst vor Veränderung und für eine verzweifelte Sehnsucht nach Sicherheit. Aber das Festhalten an Dingen, Menschen und Besitz bringt Ihnen keine Sicherheit. Es wird Sie nur blockieren und ängstlich machen. Starrheit und Angst machen es Ihnen unmöglich, zu wachsen, sich zu bewegen, das Leben zu genießen. Die „Vier der Welten“ sagt Ihnen, dass Sie sich ändern müssen, wenn Sie das Leben wirklich in vollen Zügen genießen wollen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte erzählt über die Angst. Und weil Sie Angst haben Ihr Partner zu verlieren, von ihr/ihm zurückgelassen zu worden, wollen Sie sie / ihn zu fest halten, so dass es fast unmöglich für Ihren Partner ist noch zu atmen. Wenn Sie nicht los lassen werden, werden Sie genau das provozieren, was genau Ihre größte Angst ist und Ihr Partner wird Sie verlassen.</p><p>Wenn Sie wirklich jemanden lieben, dann müssen Sie ihn/sie frei lassen, den Raum bieten, was er/sie gerne tun möchte und wollen. Vertrauen haben, dass er/sie Sie nicht verletzen oder verraten wird. Nur wenn Sie Sich Ihren größten Ängsten gegenüber stellen werden Sie in der Lage sein, sie gehen zu lassen. Erst dann gestatten Sie Ihren Partner sich frei Ihnen und der Beziehung gegenüber zu stehen.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit Sie sind starr, halten auf alten Gewohnheiten und wollen die vertraute Wege gehen. Auch wenn verlangt, finden Sie es schwierig Sich anzupassen an neuen Regeln und Verfahren. Es ist jetzt an der Zeit zu erkennen, dass das Loslassen der alten Wege in einer Weise auch befreiend sein kann. Demzufolge können Sie lernen und wachsen und sogar die \"neue\" Art und Weise schätzen.</p><p>Möglicherweise gefällt Ihnen Ihre Arbeit nicht mehr und machen Sie sie nur jeden Tag wegen der Sicherheit, die sie bietet, aber es bringt Ihnen keine Freude, keine Erfüllung mehr. Gönnen Sie sich die Freiheit des Denkens über Wechsel des Arbeitsplatzes, auf der Suche nach einer neuen Herausforderung, neue Chancen. Möglicherweise, wenn Sie mit anderen Möglichkeiten konfrontiert werden, können Sie Sich immer noch entscheiden zu bleiben, wo Sie sind, aber dann wird es eine bewusste Entscheidung sein und nicht eine Verpflichtung.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Vier der Welten sagt Ihnen, dass die Zeit gekommen ist, um etwas Gewissensprüfung zu tun: Was sind Ihre größte Ängste? An welche Personen klammern Sie Sich? An welchen Objekten, Gefühlen oder Menschen halten Sie zu stark? Und außerdem, was ist der Grund, dass Sie Sich so fest an Dinge halten? Was denken Sie was passieren würde, wenn Sie einfach loslassen? Schauen Sie sich ehrlich in die Augen und versuchen Sie mal diese Fragen zu beantworten. Sobald Sie die Antwort haben können Sie erkennen, dass die wahre Freiheit liegt im Loslassen, bei der Schaffung von Raum zwischen Ihnen und alles und alle anderen. Sobald Sie das tun können, werden Sie endlich wieder durchatmen können. Um zu leben braucht man sich zu ändern!</p>",
                    }
                }
            },
            {
                filename: "68",
                numerology : 5,
                en : {
                    name: "Five of Worlds", subtitle: "Setback",
                    suit : "Worlds",
                    element : "🜃 - earth",
                    theme : "self-worth",    
                    description : "Five of Worlds talks about your self-worth and learning not to under-estimate your own capacities (any longer).",
                    questions : [ "Are you neglecting yourself?", "Are you complicating things for yourself more than you need to?", "Can you learn to enhance your self-worth?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about deprivation, hardship, crisis, insecurity. Life is not feeling very good at the moment, you may feel like there is nothing or nobody you can count on. You may feel abandoned, lonely, deeply unhappy. Possibly you have no idea where to go from here. Still, there are positive sides to this card too: it tells you there are always people there who will help you, even if you don't see them right now, if you look really hard you will find them. Also, the situation you are in right now is a learning experience, albeit a harsh one. Once you have conquered all these problems, you will feel strong, loving, positive and you will have a clear view of where you want your life to go from that point on.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card signifies that you are probably feeling quite lonely in your relationship, you are not getting the attention and love you crave, your partner is distant. Maybe you are not sure what is wrong but you definitely feel that things aren't right the way they are now. If you don't see a possibility to change things because your partner isn't cooperating, then maybe you should consider ending the relationship. Remember there is not only one person on this earth you can be happy with.</p><p>If you are single you might feel unwanted and unnoticed. You may even think it impossible to find someone who wants to be in a relationship with you. Remember that one day you will find that special person who really cares for you, don't give up hope.</p>",
                        [GuidanceFields.WORK] : "<p>The Five of Worlds indicates that you are feeling afraid and insecure. Maybe you are worrying about losing your job or not being able to end a project successfully. Maybe you don't have a job right now and are afraid of not finding one?</p><p>It is important for you to remember that, no matter what situation you are in, there is always a way out, even if you don't see it right now. Look around you for help and support, talk to people who might understand your plight, you are not alone!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You probably are going through a rough patch and that also has an influence on your spirituality. If you regularly meditate or do other spiritual exercises, you may find it hard to focus nowadays. It is also possible that you experience a deeply intense feeling of loneliness, as if your spiritual backup is gone. It is important that you do not try to go this road alone, look for help and support from people around you, you will notice they are there for you. Remember that helping is a two way street, both the helper and the helped feel better afterwards, they both have a sense of accomplishment. </p>",
                    }
                },
                fr : {
                    name : "Le Cinq de Mondes", subtitle: "Recul",
                    suit : "Worlds",
                    element :  "🜃 - terre",
                    theme : "valeur propre",    
                    description : "Le Cinq de Mondes, nous parle de notre amour propre et de la capacité d'apprendre à ne pas nous sous-estimer (encore plus).",
                    questions : ["Vous oubliez vous-même?", "Rendez-vous les choses plus difficiles qu’elles ne le sont?", "Pouvez-vous apprendre à vous apprécier d’avantage?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente la privation, le besoin, la crise, l'insécurité. La vie n'est pas très bonne pour vous en ce moment. Vous sentez peut-être qu'il n'y a rien ou personne sur qui vous pouvez compter.  On peut aussi se sentir abandonné, seul, profondément malheureux. Vous ne savez peut-être pas où aller. Néanmoins, cette carte a aussi des côtés positifs : Il vous dit qu'il y a toujours des gens autour de vous qui veulent vous aider, même si vous ne les voyez pas maintenant. Regardez autour de vous très attentivement et sans préjugés et vous les trouverez. La situation dans laquelle vous vous trouvez en ce moment est aussi une expérience d'apprentissage, même si elle est peut-être difficile. Une fois que vous aurez surmonté tous ces problèmes, vous vous sentirez fort et positif. Vous aurez également une idée claire de l'orientation que vous souhaitez donner à votre vie à partir de ce moment.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card signifies that you are probably feeling quite lonely in your relationship, you are not getting the attention and love you crave, your partner is distant. Maybe you are not sure what is wrong but you definitely feel that things aren't right the way they are now. If you don't see a possibility to change things because your partner isn't cooperating, then maybe you should consider ending the relationship. Remember there is not only one person on this earth you can be happy with.</p><p>If you are single you might feel unwanted and unnoticed. You may even think it impossible to find someone who wants to be in a relationship with you. Remember that one day you will find that special person who really cares for you, don't give up hope.</p>",
                        [GuidanceFields.WORK] : "<p>The Five of Worlds indicates that you are feeling afraid and insecure. Maybe you are worrying about losing your job or not being able to end a project successfully. Maybe you don't have a job right now and are afraid of not finding one?</p><p>It is important for you to remember that, no matter what situation you are in, there is always a way out, even if you don't see it right now. Look around you for help and support, talk to people who might understand your plight, you are not alone!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You probably are going through a rough patch and that also has an influence on your spirituality. If you regularly meditate or do other spiritual exercises, you may find it hard to focus nowadays. It is also possible that you experience a deeply intense feeling of loneliness, as if your spiritual backup is gone. It is important that you do not try to go this road alone, look for help and support from people around you, you will notice they are there for you. Remember that helping is a two way street, both the helper and the helped feel better afterwards, they both have a sense of accomplishment. </p>",
                    }
                },
                sp:{
                    name:"El Cinco de Mundos", subtitle: "Adversidad",
                    suit: "Mundos",
                    element: "🜃 - tierra",
                    theme: "valor propio",    
                    description: "El Cinco de Mundos trata sobre la autoestima y nos muestran la habilidad de aprender a no subvalorarnos (nunca más)",
                    questions: ["¿Ponte en el frío?", "¿Lo haces más difícil para ti mismo de lo que es?", "¿Puedes aprender a apreciarte más?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es sobre las privaciones, penurias, la crisis, la inseguridad. La vida no se siente muy bien en este momento, usted puede sentir que no hay nada ni nadie que pueda contar. Usted puede sentirse abandonado, solo, profundamente infeliz. Posiblemente usted no tiene idea de dónde ir desde aquí. Sin embargo, hay aspectos positivos a esta tarjeta también: le dice que siempre hay gente allí que le ayudarán, incluso si usted no los ve ahora mismo, si se vea muy duro que va a encontrar. Además, la situación en qué se encuentra en este momento es una experiencia de aprendizaje, aunque sea dura. Una vez que haya vencido a todos estos problemas, se sentirá fuerte, amorosa, positiva y usted tendrá una visión clara de dónde quiere que su vida vaya de allí en adelante.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Werelden Vijf", subtitle: "Tegenslag",
                    suit : "Werelden",
                    element : "🜃 - aarde",
                    theme : "eigen waarde",    
                    description : "De vijf van Werelden gaat over eigenwaarde en wijst ons op het kunnen leren jezelf niet (meer) onder te waarderen.",
                    questions : [ "Zet jij jezelf in de kou?", "Maak jij het je moeilijker dan nodig is?", "Kun jij leren om jezelf meer te waarderen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart wijst op verlies, moeilijke tijden, crisissen en onzekerheid. Het leven is op dit moment niet zo fijn voor jou. Misschien heb je zelfs het gevoel dat er niets of niemand is waar je op kan rekenen. Mogelijk voel je je verlaten, eenzaam, intens ongelukkig. En het kan best zo zijn dat je geen idee hebt welke koers je nu moet gaan varen. Toch zijn er ook positieve kanten aan deze kaart want ze geeft je de boodschap dat er altijd mensen zijn en zullen zijn die je willen helpen, ook al vind je het op dit moment moeilijk om dat te zien. Blijf rondkijken, doe dat zonder vooroordelen en je zal ontdekken wie er voor je wil zijn. Wat deze kaart je ook vertelt, is dat de situatie waarin je je nu bevindt een leermoment is, ook al gaat het hier om een harde les. Eens je al je problemen overwonnen hebt, zal je je sterk en positief voelen, je zal weer openbloeien. En je zal een duidelijk zicht hebben op wat je met je leven wil aanvangen, welke kant je nu op wil!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt dat je je op dit moment waarschijnlijk behoorlijk eenzaam voelt in je relatie, je krijgt niet de aandacht en liefde die je nodig hebt, het voelt alsof je partner afstandelijk is tegenwoordig. Misschien kan je niet helemaal duiden wat er precies aan de hand is maar je voelt dat de dingen niet kloppen zoals ze nu zijn. Als je geen mogelijkheid kan bedenken om deze situatie te veranderen of als je partner niet bereid is om mee te werken aan een oplossing, dan moet je misschien overwegen om de relatie te beëindigen. Houd jezelf voor ogen dat er meer dan één persoon op deze wereld is waarmee je gelukkig kan zijn.</p><p>Als je op dit moment alleen bent dan heb je misschien het gevoel dat niemand je wil, dat niemand je echt “ziet”. Je kan zelfs gaan denken dat er niemand bestaat die een liefdesrelatie met jou zou willen aangaan. En toch is het echt wel zo dat je op een dag iemand zal ontmoeten die echt om je geeft en met jou het leven wil delen. Geef de hoop niet op!</p>",
                        [GuidanceFields.WORK] : "<p>Werelden Vijf wijst erop dat je je onzeker en bang voelt. Misschien ben je ongerust om je baan kwijt te raken of mogelijk is er een project of taak waarvan je denkt dat je het niet aankan, dat je het fout zal aanpakken. Of misschien heb je op dit ogenblik geen werk en vrees je ervoor om nog ooit iets te vinden dat bij jou past. Ook al zit je in een ontzettend moeilijke situatie op dit moment, waar het om gaat is dat je er nu aan denkt dat er altijd een uitweg is. Misschien zie je die uitweg, die oplossing nu even niet, maar ze is er wel. Zoek naar hulp in je omgeving, praat met mensen die begrijpen waar je mee bezig bent. Deze gesprekken zullen voor helderheid en inzichten zorgen en zo ben je voor je het weet op weg naar de juiste oplossing voor jouw problemen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je zit nu even in een zware en moeilijke periode en dat heeft ook invloed op je spirituele leven en bewustzijn. Als je geregeld mediteert of andere spirituele oefeningen doet, zal je merken dat het tegenwoordig erg moeilijk voor je is om je echt te focussen, je dwaalt makkelijk af, allerlei negatieve gedachten gaan door je hoofd ‘spoken’. Misschien ervaar je ook een diepe, intense eenzaamheid alsof je spirituele backup er plots niet meer is. De moeilijke weg die je nu te gaan hebt, leg je best niet alleen af. Richt je daarom op de mensen om je heen en je zal merken dat velen er voor je willen zijn, je willen ondersteunen bij je moeilijkheden. Denk eraan, helpen is een tweerichtingsweg, zowel de helper als de geholpene voelen zich er beter door, ze hebben allebei het gevoel dat ze iets bereikt hebben. Door je te laten helpen, help jij die ander dus ook!</p>",
                    }
                },
                de : {
                    name: "Fünf der Welten", subtitle: "Rückschlag",
                    suit : "Welten",
                    element : "🜃 - Erde",
                    theme : "Eigener Wert",   
                    description : "Die Fünf der Welten spricht von unserer reinen Liebe und der Fähigkeit zu lernen, um sich selbst nicht (noch mehr) zu unterschätzen.",
                    questions : [ "Vergessen Sie sich selbst?", "Machen Sie die Dinge schwieriger, als sie sind?", "Können Sie lernen, sich selber besser zu schätzen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für Entbehrung, Not, Krise, Unsicherheit. Das Leben ist in diesem Moment nicht sehr gut zu Ihnen. Sie können das Gefühl haben, dass es nichts oder niemanden gibt, auf den Sie sich verlassen können. Sie können sich auch im Stich gelassen, einsam und tief unglücklich fühlen. Möglicherweise haben Sie keine Ahnung, wohin Sie gehen sollen. Dennoch hat diese Karte auch positive Seiten: Sie sagt Ihnen, dass es immer Leute in Ihrer Umgebung gibt, die Ihnen helfen möchten, auch wenn Sie sie jetzt nicht sehen. Schauen Sie sich sehr gründlich und ohne Vorurteile um und Sie werden sie finden. Die Situation, in der Sie sich gerade befinden, ist auch eine Lernerfahrung, wenn auch vielleicht eine harte. Wenn Sie alle diese Probleme überwunden haben werden Sie sich stark und positiv fühlen. Sie werden dann auch ein klares Bild davon haben, wohin Sie Ihr Leben von diesem Zeitpunkt an ausrichten wollen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte bedeutet, dass Sie wahrscheinlich das Gefühl haben ganz einsam in Ihrer Beziehung zu sein, Sie spüren nicht mehr immer die Aufmerksamkeit und Liebe, wonach Sie sehnen, Ihr Partner scheint weit entfernt. Vielleicht sind Sie Sich nicht sicher, was falsch ist, aber auf jedem Fall haben Sie das Gefühl, dass die Dinge jetzt nicht so in Ordnung sind. Wenn Sie keine Möglichkeit sehen, die Dinge zu ändern, weil Ihr Partner nicht zusammen daran arbeiten will, dann müssen Sie Sich vielleicht mal fragen, ob Sie diese Beziehung nicht beenden wollen. Denken Sie daran, es gibt nicht nur eine Person auf dieser Erde, mit der man zufrieden leben kann.</p><p>Wenn Sie alleine sind haben Sie vielleicht das Gefühl unerwünscht und unbemerkt zu sein. Sie können sogar glauben, dass es unmöglich ist, dass es jemanden gibt, der in einer Beziehung mit Ihnen zusammen sein möchte. Denken Sie daran, dass es einem Tage geben wird, dass Sie eine spezielle Person, die Sie wirklich etwas bedeutet, finden werden. Geben Sie die Hoffnung nicht auf.</p>",
                        [GuidanceFields.WORK] : "<p>Die Fünf der Welten zeigt an, dass Sie ängstlich und unsicher sind. Vielleicht haben Sie Angst Ihren Job zu verlieren oder sind Sie nicht in der Lage, ein Projekt erfolgreich zu beenden. Vielleicht haben Sie momentan keinen Job und haben Sie schon jetzt Angst, nicht einen zu finden?</p><p>Es ist wichtig, dass Sie daran denken, dass, egal in welcher Situation Sie Sich befinden, es immer einen Ausweg gibt, auch wenn Sie es nicht gerade sehen. Suchen Sie nach Hilfe und Unterstützung, sprechen Sie mit Menschen, die Ihre Situation verstehen. Sie sind nicht allein!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie sind wahrscheinlich durch eine schwierige Phase gegangen und das hat auch einen Einfluss auf Ihre Spiritualität. Wenn Sie regelmäßig meditieren oder andere spirituelle Übungen machen, könnten Sie es schwierig finden Sich jetzt zu konzentrieren. Es ist auch möglich, dass Sie ein tiefes intensives Gefühl der Einsamkeit erleben, als ob Ihre geistige Sicherungskopie verschwunden ist. Es ist wichtig, dass Sie nicht versuchen, diesen Weg allein zu gehen, suchen Sie nach Hilfe und Unterstützung von Menschen um Sie herum und Sie werden feststellen, dass sie für Sie da sind. Denken Sie daran, dass Hilfe keine Einbahnstraße ist, sowohl die Helfer als der Geholfen fühlen sich, nachdem besser und sie werden beide ein Gefühl der Erfüllung haben.</p>",
                    }
                }
            },
            {
                filename: "69",
                numerology : 6,
                en : {
                    name: "Six of Worlds", subtitle: "Synergy",
                    suit : "Worlds",
                    element : "🜃 - earth",
                    theme : "choosing what you do",    
                    description : "Six of Worlds talks about choosing what you do with what you have and about the importance of the balance between giving and receiving.",
                    questions : [ "Do you have a lot to offer?", "Can you also receive?", "Do you give or receive because it is the right thing to do?", "Can you find the right balance, in which you do not neglect yourself?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card is about being helpful and magnanimous, about tolerance. You may be the benefactor or maybe you are on the receiving end, still it is all about people helping each other out, be it materially, financially, emotionally or spiritually. One day you are the helper, the next day you may be the helped. It is important to share, to be generous, there will always be a day that we need someone or something to survive, to be happy.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>For the moment your relationship is well balanced. It is important that you do everything to keep it that way. Make sure that you not only give to and support your partner, but also accept support, allow your partner to help you. When times are rough it is important for you to be there for each other, to encourage and inspire one another. By living through difficulties together you become stronger and more united as a couple. If you are currently single, it is important for you to let everybody around you know that you are looking for a partner. It is very likely you will meet someone through friends or acquaintances.</p>",
                        [GuidanceFields.WORK] : "<p>At work things are looking up, you are being supported not only by colleagues but also by your superiors. Everybody seems to want you to be a success. Now is the right time to ask for a raise or a promotion. Or maybe you want to go out and find that job you've been dreaming about? You are very likely to succeed. </p><p>If you've been thinking about starting your own business, now is the right time, go for it!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually speaking you have been gathering a lot of knowledge and insights, your spiritual understanding is profound. Still, it is well possible you don't fully realize this, you tend to underestimate yourself. Having all this knowledge, it is important for you to start sharing it with the people around you. Let them profit from the fruits of the spiritual journey you made. And while sharing, you will realize that you too have a lot to learn from the wisdom of others. This mutual spiritual sharing will be very beneficial to everyone.</p>",
                    }
                },
                fr : {
                    name : "Le Six de Mondes", subtitle: "Synergie",
                    suit : "Worlds",
                    element :  "🜃 - terre",
                    theme : "Choisissez pour ce que vous faites",    
                    description : " Le Six de Mondes, nous parle de choisir ce que nous faisons de ce que nous avons et de l'importance de l'équilibre entre donner et recevoir.",
                    questions : ["Avez-vous beaucoup à donner?", "Pouvez-vous également recevoir?", "Donnez ou recevez-vous de par vous-même, ou parce qu’il se doit?", "Pouvez-vous fournir le bon équilibre, sans se perdre?"],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte est synonyme de serviabilité, de générosité et de tolérance. Vous pouvez être le bienfaiteur ou peut-être en êtes-vous le bénéficiaire. Il s'agit toujours des personnes qui s'entraident, que ce soit sur le plan matériel, financier, émotionnel ou spirituel. Un jour vous êtes l'assistant, le lendemain vous pouvez être celui qui sera aidé. Il est important de partager, d'être généreux. Il y aura toujours un jour où nous aurons besoin de quelqu'un ou de quelque chose pour nous aider à survivre et à trouver le bonheur.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>For the moment your relationship is well balanced. It is important that you do everything to keep it that way. Make sure that you not only give to and support your partner, but also accept support, allow your partner to help you. When times are rough it is important for you to be there for each other, to encourage and inspire one another. By living through difficulties together you become stronger and more united as a couple. If you are currently single, it is important for you to let everybody around you know that you are looking for a partner. It is very likely you will meet someone through friends or acquaintances.</p>",
                        [GuidanceFields.WORK] : "<p>At work things are looking up, you are being supported not only by colleagues but also by your superiors. Everybody seems to want you to be a success. Now is the right time to ask for a raise or a promotion. Or maybe you want to go out and find that job you've been dreaming about? You are very likely to succeed. </p><p>If you've been thinking about starting your own business, now is the right time, go for it!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritually speaking you have been gathering a lot of knowledge and insights, your spiritual understanding is profound. Still, it is well possible you don't fully realize this, you tend to underestimate yourself. Having all this knowledge, it is important for you to start sharing it with the people around you. Let them profit from the fruits of the spiritual journey you made. And while sharing, you will realize that you too have a lot to learn from the wisdom of others. This mutual spiritual sharing will be very beneficial to everyone.</p>",
                    }
                },
                sp:{
                    name:"El Seis Mundos", subtitle: "Sinergia",
                    suit: "Mundos",
                    element: "🜃 - tierra",
                    theme: "elige por lo que haces",    
                    description: "El Seis de Mundos se trata de elegir qué hacer con lo que tienes y de señalarnos la importancia del equilibrio entre dar y recibir",
                    questions: ["¿Tienes mucho á dar?", "¿También puedes recibir?", "¿Das o recibes de ti mismo, o porque así es como debe ser?", "¿Puedes mantener el equilibrio adecuado, sin olvidarte de ti mismo?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es acerca de ser útil y magnánimo, sobre la tolerancia. Usted puede ser el benefactor o tal vez usted está en el extremo receptor, siendo que se trata de personas ayudando unos a otros, ya sea material, económica, emocional o espiritualmente. Un día es el ayudante, al día siguiente  puede ser la ayudaste. Es importante compartir, a ser generosos, siempre habrá un día en que necesitamos a alguien o algo para sobrevivir, para ser feliz.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Werelden Zes", subtitle: "Synergie",
                    suit : "Werelden",
                    element : "🜃 - aarde",
                    theme : "kiezen voor wat je doet",    
                    description : "De zes van Werelden gaat over kiezen voor wat je doet met wat je hebt en wijst ons op het belang van het evenwicht tussen geven en ontvangen.",
                    questions : [ "Heb jij veel te geven?", "Kun jij ook ontvangen?", "Geef of ontvang jij uit jezelf, of omdat het 'zo' hoort?", "Kun jij voor een juiste balans zorgen, waarbij jij jezelf niet vergeet?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart gaat over behulpzaam en grootmoedig zijn maar ook over verdraagzaamheid. Of je nu de helper of de geholpene bent, hier gaat het om mensen die mekaar helpen op allerlei vlak: materieel, emotioneel, financieel, spiritueel. De ene dag help je iemand uit de nood, de volgende dag is er iemand die jou een helpende hand toesteekt. Deze kaart vertelt je dat het heel belangrijk is om te delen, om vrijgevig te zijn. Hoe goed je leven ook is, er komt altijd een dag waarop je iets of iemand nodig hebt om je te helpen overleven, iemand om je te ondersteunen in je zoektocht naar geluk.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit ogenblik is je relatie goed in evenwicht. Toch is het belangrijk dat je er alles aan doet om het zo te houden. Zorg ervoor dat je niet enkel geeft aan je partner, maar dat je ook steun aanvaardt van de ander, dat je je door je partner laat helpen. In moeilijke tijden is het belangrijk dat je er bent voor mekaar, dat je elkaar inspireert en moed inspreekt. Je wordt er als koppel sterker en meer één van als je samen moeilijkheden het hoofd biedt.</p><p>Als je op dit moment op zoek bent naar een geliefde dan is het belangrijk dat je iedereen in je omgeving laat weten dat je openstaat voor een ontmoeting. Het is immers heel waarschijnlijk dat je iemand zal leren kennen via vrienden of kennissen.</p>",
                        [GuidanceFields.WORK] : "<p>Op werkgebied begint het er beter uit te zien, je wordt niet enkel ondersteund door je collega’s maar ook door je meerderen. Iedereen lijkt samen te werken om ervoor te zorgen dat jij succesvol bent. Dit is dus echt een goed moment om een promotie of opslag te vragen. Of misschien ben je er al lang van aan het dromen om op zoek te gaan naar de ideale baan voor jou? Daarvoor is het nu absoluut een goede tijd, ga ervoor!</p><p>Als je overweegt om je eigen bedrijf op te starten dan is het nu het juiste moment om dit te doen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritueel gezien heb je een boel kennis en inzichten vergaard, je spirituele ‘begrip’ is grondig en diep. Toch is het mogelijk dat je je hier niet helemaal van bewust bent want je hebt soms de neiging om jezelf te onderschatten. Omdat je nu al die kennis hebt, is het belangrijk dat je die ook gaat delen met de mensen om je heen. Laat hen meegenieten met de vruchten van al je inspanningen. Gun anderen een blik op de resultaten van de spirituele reis die je afgelegd hebt. En terwijl je je spirituele rijkdom aan het delen bent met anderen zal je gaan inzien dat jij ook veel kan hebben aan de kennis van anderen. Deze wederzijdse spirituele uitwisseling zal een positieve stap zijn voor iedereen die eraan meedoet!</p>",
                    }
                },
                de : {
                    name: "Sechs der Welten", subtitle: "Synergie",
                    suit : "Welten",
                    element : "🜃 - Erde",
                    theme : "Wählen, was man tut",    
                    description : "Die Sechs der Welten spricht darüber zu wählen, was wir mit dem tun, was wir haben, und wie wichtig die Balance ist zwischen Geben und Empfangen.",
                    questions : [ "Haben Sie viel zu geben?", "Können Sie auch empfangen?", "Geben oder empfangen Sie von sich aus, oder weil es sich gehört?", "Können Sie das richtige Gleichgewicht schaffen, ohne sich zu verlieren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht für Hilfsbereitschaft, Großzügigkeit und Toleranz. Sie können der Wohltäter sein oder vielleicht sind Sie auch der Empfangende. Immer geht es um Menschen, die einander helfen, sei es materiell, finanziell, emotional oder spirituell. An einem Tag sind Sie der Helfer, am nächsten Tag können Sie es sein, dem geholfen wird. Es ist wichtig zu teilen, großzügig zu sein. Es wird immer einen Tag geben, an dem wir jemanden oder etwas brauchen, der oder das uns hilft zu überleben und uns bei der Suche nach Glück unterstützt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Zur Zeit ist Ihre Beziehung gut ausgewogen. Es ist wichtig, dass Sie alles tun, um es so zu behalten. Stellen Sie sicher, dass Sie nicht Ihren Partner nicht nur Hilfe und Unterstützung geben, sondern auch Hilfe und Unterstützung von Ihrem Partner akzeptieren. Gestatten Sie Ihren Partner Ihnen zu helfen. Wenn die Zeiten auch hart sind, es ist wichtig für Sie für einander da zu sein, zu fördern und sich gegenseitig zu inspirieren. Zusammenhalten in schwierigen Zeiten macht Sie stärker und vereinter als Paar. Wenn Sie noch alleine sind, ist es wichtig, dass Sie alle um Sie herum wissen lassen, dass Sie auf der Suche sind nach einem Partner. Es ist sehr wahrscheinlich, dass Sie jemanden unter Freunden oder Bekannten begegnen.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit sehen die Dinge gut aus. Sie werden nicht nur von Kollegen, sondern auch von Ihren Vorgesetzten unterstützt. Jeder scheint zu wollen, dass Sie erfolgreich sind. Jetzt ist der richtige Zeitpunkt, um eine Gehaltserhöhung oder eine Beförderung zu bitten. Oder vielleicht möchten Sie auf der Suche gehen nach einer Arbeit, von der Sie schon immer geträumt haben? Es ist sehr wahrscheinlich, dass Sie dabei erfolgreich sind.</p><p>Wenn Sie mal auch über ein eigenes Geschäft nachgedacht haben, ist es jetzt der richtige Zeitpunkt. Mache es!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spirituell gesprochen haben Sie eine Menge Wissen und Erkenntnisse gesammelt und ist Ihr spirituelles Verständnis tiefer. Trotzdem ist es gut möglich, dass Sie nicht völlig anerkennen, dass es so ist. Sie neigen dazu, sich zu unterschätzen. Mit all diesem Wissen ist es wichtig, dass Sie anfangen mit dem Austausch dessen mit den Menschen um Sie herum. Lassen Sie sie genießen von den Früchten dieser spirituellen Reise, die Sie gerade machen. Und beim Teilen, werden Sie feststellen, dass auch Sie viel zu lernen haben von der Weisheit von anderen. Dieser gegenseitige spirituelle Austausch wird sich als sehr vorteilhaft für alle ausweisen.</p>",
                    }
                }
            },
            {
                filename: "70",
                numerology : 7,
                en : {
                    name: "Seven of Worlds", subtitle: "Breakthrough",
                    suit : "Worlds",
                    element : "🜃 - earth",
                    theme : "going on with what you possess",    
                    description : "Seven of Worlds talks about going on with what you have accomplished and about moving on in the same direction.",
                    questions : [ "Have you gained knowledge and experience?", "What will you actually do with it?", "Are you reaping the fruits of your labor?", "Do you need to break a cycle to do so?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Seven of Worlds is often about patience and reaping what you have sown. Many things in life need time to grow, to develop, and during that process it is up to you to nurture, take care, invest time and effort. It is very likely that now the time has come for you to cash in on your investments, be it financially, emotionally, practically. </p><p>In general you are feeling secure, you have a sense that things are going well for you and you have high hopes for the future. This is not a time to be having doubts, if you are feeling any it would be wise to examine them and then to realize that there is no cause for them.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that your relationship is slowly and steadily growing. The hard work and energy you have invested in the past is now paying off. Be sure to enjoy this situation as much as you can.</p><p>If you have just started a new relationship it is important for you not to rush into it. Enjoy all things new and pure, take the time to get to know your partner. It is only normal that the relationship takes time and cherishing in order to grow to its full potential.</p>",
                        [GuidanceFields.WORK] : "<p>At work everything is probably going very well. You are steadily growing and evolving and you are reaping the fruits of past labor and of the energy you have putinto your job. Remember that in this chapter of your life it's not about quick fixes or results. Still, all the efforts you put into a job or project will be duly rewarded. You are and will be financially safe and contented.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Seven of Worlds tells you that for now it is important to concentrate on the fact that life is all about cycles, what you put into it will come back to you some day. If you live with this basic truth every day of your life you know that when you invest in people, be it material, financial, emotional or spiritual, you are actually investing in yourself. One day what you are giving away right now will be given back to you at a time when you need it. To give is to receive.</p>",
                    }
                },
                fr : {
                    name : "Le Sept de Mondes", subtitle: "Percée",
                    suit : "Worlds",
                    element :  "🜃 - terre",
                    theme : "Continuer avec ce que l’on possède",    
                    description : " Le Sept de Mondes, nous parle de continuer avec ce que nous avons construit et que nous pouvons continuer.",
                    questions : ["Avez-vous acquis de la connaissances et de l'expérience?", "Qu'allez-vous réellement en faire?", "Allez-vous récolter les fruits de vos propres efforts?", "Avez-vous besoin de percer quelque chose pour cela?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Sept de Welten\" représente souvent la patience et la moisson après avoir semé. Beaucoup de choses dans la vie ont besoin de temps pour grandir, pour se développer. Au cours de ce processus, il est important que vous vous occupiez de ces choses, que vous preniez soin d'elles et que vous y consacriez du temps et de l’effort. Il est très probable que le moment est venu de profiter de vos investissements, qu'ils soient financiers, émotionnels ou engagés dans divers aspects du quotidien.</p><p>En général, vous vous sentez complètement en sécurité. Vous avez le sentiment que les choses vont bien pour vous et vous avez de grands espoirs pour l'avenir. Ce n'est pas le moment de douter. Si vous avez encore des doutes, il serait sage de les examiner et de reconnaître qu'il n'y a aucune raison de les avoir.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card tells you that your relationship is slowly and steadily growing. The hard work and energy you have invested in the past is now paying off. Be sure to enjoy this situation as much as you can.</p><p>If you have just started a new relationship it is important for you not to rush into it. Enjoy all things new and pure, take the time to get to know your partner. It is only normal that the relationship takes time and cherishing in order to grow to its full potential.</p>",
                        [GuidanceFields.WORK] : "<p>At work everything is probably going very well. You are steadily growing and evolving and you are reaping the fruits of past labor and of the energy you have putinto your job. Remember that in this chapter of your life it's not about quick fixes or results. Still, all the efforts you put into a job or project will be duly rewarded. You are and will be financially safe and contented.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Seven of Worlds tells you that for now it is important to concentrate on the fact that life is all about cycles, what you put into it will come back to you some day. If you live with this basic truth every day of your life you know that when you invest in people, be it material, financial, emotional or spiritual, you are actually investing in yourself. One day what you are giving away right now will be given back to you at a time when you need it. To give is to receive.</p>",
                    }
                },
                sp:{
                    name:"El Siete de Mundos", subtitle: "Avance",
                    suit: "Mundos",
                    element: "🜃 - tierra",
                    theme: "continúa con lo que tienes",    
                    description: "El Siete de Mundos se trata de continuar con lo que has construido y nos recuerda que podemos continuar",
                    questions: ["¿Has adquirido conocimientos y experiencia?", "¿Qué vas a hacer con eso?", "¿Vas a cosechar las recompensas de tus propios esfuerzos?", "¿Necesitas romper algo para eso?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Siete de Oros es a menudo acerca de la paciencia y cosechando lo que han sembrado. Muchas cosas en la vida necesitan tiempo para crecer, desarrollarse, y durante ese proceso que depende de usted para nutrir, cuidar, invertir tiempo y esfuerzo. Es muy probable que ahora haya llegado el momento para que usted pueda sacar provecho de sus inversiones, ya sea económica, emocional, prácticamente.</p><p>En general se siente seguro, usted tiene la sensación de que las cosas van bien para usted y usted tiene grandes esperanzas para el futuro. Este no es un tiempo para estar teniendo dudas, si se siente ningún sería prudente para examinarlos y luego darse cuenta de que no hay causa para ellos.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Werelden Zeven", subtitle: "Doorbraak",
                    suit : "Werelden",
                    element : "🜃 - aarde",
                    theme : "verder gaan met wat je bezit",    
                    description : "De zeven van Werelden gaat over verder gaan met wat je hebt opgebouwd en wijst ons erop dat we door kunnen gaan.",
                    questions : [ "Heb jij kennis en ervaring opgedaan?", "Wat ga jij daarmee daadwerkelijl doen?", "Pluk jij de vruchten van je eigen inzet?", "Dien jij daarvoor iets te doorbreken?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Werelden Zeven draait eigenlijk om geduld en oogsten wat je gezaaid hebt. Veel dingen in het leven hebben tijd nodig om te groeien en zich te ontwikkelen. Tijdens dat groeiproces is het jouw taak om deze dingen te voeden en te verzorgen, om tijd en energie te investeren. En net omdat je dat gedaan hebt is nu waarschijnlijk het moment gekomen waarop je investering opbrengsten zal voortbrengen, of het nu emotioneel, financieel of praktisch is.</p><p>Over het algemeen voel je je veilig en rustig, je hebt het gevoel dat het leven goed voor je is. Je hebt goede hoop voor je toekomst. Dit is geen tijd om te gaan twijfelen. Als dat wel het geval is, als je met twijfels zit, dan is het belangrijk dat je grondig onderzoekt waar ze vandaan komen. Je zal waarschijnlijk ontdekken dat er geen enkele objectieve reden voor is.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt je dat je relatie langzaam maar zeker aan het groeien is. Het harde werk en de energie die je in het verleden geïnvesteerd hebt werpen nu hun vruchten af. Geniet hier zoveel mogelijk van, je hebt het verdiend!</p><p>Als je net begonnen bent aan een nieuwe relatie dan is het belangrijk dat je hiervoor rustig de tijd neemt. Geniet volop van alle nieuwe en ongewone aspecten, neem uitgebreid de tijd om je partner te leren kennen. Het is nu eenmaal zo dat een nieuwe relatie pas haar volle potentieel kan bereiken als je aan een rustig tempo volop liefde en aandacht investeert en je nieuwe partner koestert op alle mogelijke manieren.</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk verlopen de zaken waarschijnlijk behoorlijk goed. Je bent aan een rustig maar gelijkmatig tempo aan het groeien en evolueren en je plukt volop de vruchten van de aandacht en energie die je in het verleden geïnvesteerd hebt.</p><p>Houd er rekening mee dat het huidige hoofdstuk van je leven er niet om gaat dat je snelle vorderingen maakt of oppervlakkige oplossingen overweegt, het is net het omgekeerde. En je zal merken dat alle inspanningen die je geleverd hebt ook op de juiste manier beloond zullen worden. Je bent op dit moment financieel veilig en tevreden en dit zal nog enige tijd zo door blijven gaan.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Werelden Zeven vertelt je dat het belangrijk is om je erop te focussen dat het leven om cyclussen draait, groeiprocessen. De ene periode investeer je volop en de komende periode geniet je van de vruchten die jouw investeringen je brengen. Wat je erin stopt komt in veelvoud naar je terug. Als je elke dag van je leven baseert op deze eenvoudige waarheid dan weet je dat, als je in mensen investeert (of het nu materieel, emotioneel, financieel of spiritueel is), je dan eigenlijk in jezelf aan het investeren bent. Wat je vandaag weggeeft, dat zal je op een dag terugkrijgen, net op het moment dat je dat het meeste nodig hebt. Geven is ontvangen.</p>",
                    }
                },
                de : {
                    name: "Sieben der Welten", subtitle: "Durchbruch",
                    suit : "Welten",
                    element : "🜃 - Erde",
                    theme : "Mit dem fortfahren, was man hat.",    
                    description : "Die Sieben der Welten spricht von der Fortsetzung dessen, was wir aufgebaut haben und was wir weiterführen können.",
                    questions : [ "Haben Sie Wissen und Erfahrung gesammelt?", "Was werden Sie wirklich damit tun?", "Werden Sie die Früchte Ihrer eigenen Bemühungen ernten?", "Müssen Sie etwas dafür tun?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Sieben der Welten“ steht oft für Geduld und für die Ernte, nachdem man gesät hat. Viele Dinge im Leben brauchen Zeit um zu wachsen, sich zu entwickeln. Während dieses Prozesses ist es für Sie wichtig, diese Dinge zu pflegen, sich um sie zu kümmern und Zeit und Mühe zu investieren. Es ist sehr wahrscheinlich, dass jetzt die Zeit gekommen ist, von Ihren Investitionen zu profitieren, sei es nun in finanzieller, emotionaler oder praktischer Hinsicht.</p><p>Im Allgemeinen fühlen Sie sich vollkommen sicher. Sie haben das Gefühl, dass die Dinge gut für Sie laufen und Sie haben große Hoffnungen in die Zukunft. Jetzt ist nicht die Zeit zu zweifeln. Wenn Sie dennoch Zweifel haben wäre es klug, sie zu überprüfen und dann anzuerkennen, dass es keinen Grund dafür gibt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte sagt Ihnen, dass Ihre Beziehung langsam aber stetig wachst. Die harte Arbeit und Energie, die Sie in der Vergangenheit investiert haben, zahlt sich jetzt aus. Achten Sie darauf, dass Sie diese Situation so viel wie möglich genießen.</p><p>Wenn Sie gerade eben eine neue Beziehung angefangen haben, ist es wichtig, dass Sie Sich nicht gleich in sie hinein stürzen. Genießen Sie alles was neu und rein ist, nehmen Sie die Zeit, um Ihren Partner kennen zu lernen. Es ist nur normal, dass eine Beziehung Zeit und Pflegung braucht um zu vollem Wachstum zu geraten.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit läuft alles wahrscheinlich sehr gut. Sie wachsen und entwickeln Sich ständig und ernten jetzt die Früchte der harten Arbeit und Energie, die Sie in Ihrem Job investiert haben. Denken Sie daran, dass es in diesem Kapitel in Ihrem Leben nicht geht um schnelle Lösungen oder Ergebnisse. Dennoch werden alle Anstrengungen die Sie leisten in einem Job oder einem Projekt ordnungsgemäß belohnt. Sie sind und bleiben finanziell sicher und zufrieden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Die Sieben der Pentakeln sagt Ihnen, dass es jetzt wichtig ist, Sich auf die Tatsache zu konzentrieren, dass das Leben alles mit Kreis laufen zu tun hat. Was Sie eingeben wird eines Tages zu Ihnen zurückkommen. Wenn Sie mit dieser grundlegenden Wahrheit jeden Tag leben wissen Sie, dass, wenn Sie in Menschen investieren, sei es materiell, finanziell, emotional oder spirituell, Sie eigentlich in Sich Selbst investieren. Eines Tages kommt das, was Sie gegeben haben, wieder bei Ihnen zurück, wenn Sie es am meisten brauchen. Geben heißt empfangen.</p>",
                    }
                }
            },
            {
                filename: "71",
                numerology : 8,
                en : {
                    name: "Eight of Worlds", subtitle: "Change",
                    suit : "Worlds",
                    element : "🜃 - earth",
                    theme : "taking responsibility for your actions",    
                    description : "Eight of Worlds talks about taking responsibility for what you can do and that this is a work in progress without overthinking the possible perceptions of others about it.",
                    questions : [ "What are you good at?", "Do you allow yourself to take it out the backyard?", "Are you blocked by what others think about it?", "Can you take it out the backyard?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This card usually is about work and indicates that you are entering a period in which you will have to work, maybe harder than you usually do. This work may be situated in your professional or in your private life. Three things are important: be sure to focus on the goal you are trying to reach and not only on the work itself, make sure you maintain a certain balance in your life and if you think you need help, don't hesitate to ask for it.</p><p>Sometimes this card is also about literally working with your hands, creating things. If this is the case, the card tells you that a very creative period is near, enjoy it!</p><p>Finally this card may also signify beginner's luck.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Eight of Worlds tells you that work is taking up too much space in your relationship, be it your workload or your partner's. It is absolutely necessary that you make more time and space for eachother if you want this relationship to last.</p><p>If you are currently single you may want to ask yourself if you are really committed to finding a partner. Maybe right now your job is more important to you? If the latter is true, no problem, but maybe you should temporarily stop looking for a love in your life.</p>",
                        [GuidanceFields.WORK] : "<p>This is a time when you can expect to get a lot of new tasks or assignments. Maybe your workload will even become overwhelming to such a degree it is impossible for you to do everything right, the way it should be done. If this is the case, do not hesitate to talk to your superiors and to ask them to help prioritize your tasks, maybe even transfer some tasks to a colleague.</p><p>If you are looking for a job you may find yourself confronted with high demands, a thorough testing of your skills. Don’t panic or hesitate, you are stronger than you realize and you will be able to meet those demands.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>No matter if you have a traditional job or a more alternative one, now may be the time to ask yourself why you are in the place you are today.Are you doing the things you know you are best at? Are you growing, evolving? Or have you been influenced by the opinions of others when choosing your job or career? Are you ready to allow yourself to pursue a job where your skills will be used and even welcomed? Are you ready to really (spiritually) be yourself at work too?</p>",
                    }
                },
                fr : {
                    name : "Le Huit de Mondes", subtitle: "Changement",
                    suit : "Worlds",
                    element :  "🜃 - terre",
                    theme : "Assumer la responsabilité de ce que l’on fait",    
                    description : "Le Huit de Mondes, nous parle de prendre la responsabilité de ce que nous pouvons faire et que nous pouvons y travailler, sans avoir à trop nous soucier de ce que les autres pourraient en penser.",
                    questions : ["Que savez-vous bien faire?", "Vous permettez-vous d'en ressortir quelque chose?", "Vous laissez-vous arrêter par ce que les autres pensent?", "Pouvez-vous réaliser votre propre projet?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Cette carte représente habituellement le travail et signifie qu'un temps est venu pour vous où vous devrez peut-être travailler plus fort que vous n'en avez l'habitude. Cela peut affecter votre vie professionnelle et votre vie privée. Trois choses sont importantes : assurez-vous de vous concentrer sur l'objectif que vous voulez atteindre et pas seulement sur le travail lui-même. Assurez-vous d'avoir un certain équilibre dans votre vie et, si vous pensez avoir besoin d'aide, n'hésitez pas à le demander.... </p><p>Parfois, cette carte représente aussi littéralement l'artisanat, la fabrication des choses. Si tel est le cas, cette carte vous indique qu'une phase très créative est sur le point de commencer. Profitez-en bien !</p><p>Enfin, cette carte peut aussi indiquer la chance du débutant.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>The Eight of Worlds tells you that work is taking up too much space in your relationship, be it your workload or your partner's. It is absolutely necessary that you make more time and space for eachother if you want this relationship to last.</p><p>If you are currently single you may want to ask yourself if you are really committed to finding a partner. Maybe right now your job is more important to you? If the latter is true, no problem, but maybe you should temporarily stop looking for a love in your life.</p>",
                        [GuidanceFields.WORK] : "<p>This is a time when you can expect to get a lot of new tasks or assignments. Maybe your workload will even become overwhelming to such a degree it is impossible for you to do everything right, the way it should be done. If this is the case, do not hesitate to talk to your superiors and to ask them to help prioritize your tasks, maybe even transfer some tasks to a colleague.</p><p>If you are looking for a job you may find yourself confronted with high demands, a thorough testing of your skills. Don’t panic or hesitate, you are stronger than you realize and you will be able to meet those demands.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>No matter if you have a traditional job or a more alternative one, now may be the time to ask yourself why you are in the place you are today.Are you doing the things you know you are best at? Are you growing, evolving? Or have you been influenced by the opinions of others when choosing your job or career? Are you ready to allow yourself to pursue a job where your skills will be used and even welcomed? Are you ready to really (spiritually) be yourself at work too?</p>",
                    }
                },
                sp:{
                    name:"El Ocho de Mundos", subtitle: "Cambio",
                    suit: "Mundos",
                    element: "🜃 - tierra",
                    theme: "Asumir la responsabilidad de lo que se hace",    
                    description: "El Ocho de Mundos trata de asumir la responsabilidad de lo que puedes hacer y nos señalan que podemos trabajar así, sin tener que preocuparnos demasiado por lo que otra persona pueda pensar",
                    questions: ["¿En qué eres bueno?", "¿Te (dejas) permites hacer algo con eso?", "¿Te dejas detener por lo que otros piensan?", "¿Puedes realizar tu propio plan?"],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta tarjeta es generalmente sobre el trabajo e indica que está entrando en un período en el que se tendrá que trabajar, tal vez más difícil de lo normal. Este trabajo puede ser situado en su profesional o en su vida privada. Tres cosas son importantes: asegúrese de centrarse en el objetivo que usted está intentando alcanzar y no sólo en la propia obra, asegúrese de mantener un cierto equilibrio en su vida y si usted piensa que necesita ayuda, no dude en pedir ella.</p><p>A veces, esta tarjeta también se trata, literalmente, trabajar con las manos, la creación de las cosas. Si este es el caso, la tarjeta que dice que un periodo muy creativo está cerca, que lo disfruten!</p><p>Por último, esta tarjeta también puede significar la suerte del principiante.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Werelden Acht", subtitle: "Verandering",
                    suit : "Werelden",
                    element : "🜃 - aarde",
                    theme : "verantwoording nemen voor wat je doet",    
                    description : "De acht van Werelden gaat over verantwoording nemen voor wat je kan en wijst ons erop dat we daarmee kunnen werken, zonder ons al te veel aan hoeven te trekken wat een ander ervan denken kan.",
                    questions : [ "Waar ben jij goed in?", "Sta jij jezelf toe om daar iets mee te doen?", "Laat jij je weerhouden door wat anderen ervan denken?", "Kun jij je eigen plan uitvoeren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Bij deze kaart gaat het vaak om werken. Ze wijst erop dat je nu in een fase komt waarin je waarschijnlijk harder zal moeten werken dan je normaal gezien doet. Het werk waarom het gaat kan zich situeren op carrièrevlak maar ook in je privéleven. Op dit moment zijn er drie dingen belangrijk: zorg ervoor dat je gefocust blijft op het doel dat je wil bereiken en niet enkel aandacht besteedt aan de inspanning zelf, besteed aandacht aan het evenwicht in je leven, en als je denkt dat je hulp nodig hebt, vraag er dan om zonder aarzelen.</p><p>Soms symboliseert deze kaart ook letterlijk werken met je handen, dingen creëren. Als dat het geval is, dan begint er nu voor jou een heel creatieve en vruchtbare periode, geniet ervan!</p><p>Tenslotte kan deze kaart er ook op wijzen dat je beginnersgeluk zal hebben.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Werelden Acht vertelt je dat werk teveel ruimte inneemt in je relatie. Het is mogelijk dat jij te hard werkt maar het kan ook om de baan van je partner gaan die teveel ruimte inneemt. In elk geval is het absoluut noodzakelijk dat jullie meer tijd maken voor mekaar en ervoor zorgen dat je geregeld de ruimte hebt om in mekaar op te gaan. Als je dit niet doet is het mogelijk dat je relatie niet zal blijven duren. Als je op dit moment single bent, stel jezelf dan eens de vraag of je in je huidige situatie wel de ruimte hebt voor een liefdespartner en –relatie. Ben je hier absoluut zeker van? Misschien is op dit moment je werk wel belangrijker voor je dan de liefde. Als dat zo is dan is dat geen probleem, het is een kwestie van prioriteiten stellen, jij bepaalt wat je wel en niet wil. Maar misschien is het dan ook zinvol om even niet meer op zoek te gaan naar de liefde.</p>",
                        [GuidanceFields.WORK] : "<p>Het is op dit moment waarschijnlijk zo dat je veel nieuwe taken of opdrachten krijgt op het werk. Misschien is je takenpakket dusdanig groot geworden dat het je een stuk overweldigt en dat het niet meer mogelijk is om alle taken op een goede manier uit te voeren. De kwaliteit van je werk lijdt mogelijk onder je overvolle agenda. Als dit het geval is, aarzel dan niet en schakel je meerderen in om je te helpen met prioriteiten stellen. Misschien kan je tijdelijk ook een aantal taken doorgeven aan een collega.</p><p>Als je op zoek bent naar werk dan is het mogelijk dat er nu behoorlijk hoge eisen gesteld worden bij het solliciteren en dat je vaardigheden grondig getest worden. Probeer rustig te blijven, laat je niet overweldigen, je bent sterker dan je denkt en je kan deze uitdagingen zeker aan!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het maakt niet uit of je een traditionele of alternatieve baan hebt, op dit moment is het belangrijk dat je je eens afvraagt waarom je op je huidige plek zit. Doe je echt de dingen die je het beste kan en die je het meeste aanspreken? Ben je nog steeds aan het groeien en evolueren? Of heb je je door je onzekerheid en je omgeving laten beïnvloeden bij het kiezen van je huidige baan? Als dat laatste het geval is, als je nu niet (meer) op je plek zit, vraag jezelf dan of je er klaar voor bent om op zoek te gaan naar een baan waarin je vaardigheden en eigenschappen volop tot hun recht kunnen komen, een plek waar jijzelf en je kwaliteiten echt welkom zijn. Misschien ben je er wel klaar voor om ook in je werksituatie helemaal en ook spiritueel jezelf te zijn.</p>",
                    }
                },
                de : {
                    name: "Acht der Welten", subtitle: "Ändern",
                    suit : "Welten",
                    element : "🜃 - Erde",
                    theme : "Die Verantwortung dafür übernehmen, was man tut.",    
                    description : "Die Acht der Welten spricht darüber, die Verantwortung dafür zu übernehmen, was wir tun können und dass wir daran arbeiten können, ohne uns zu sehr darum kümmern zu müssen, was andere darüber denken könnten.",
                    questions : [ "Worin sind Sie besonders gut?", "Erlauben Sie sich, etwas daraus zu machen?", "Lassen Sie sich von dem abhalten, was andere darüber denken?", "Können Sie Ihr eigenes Projekt realisieren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Diese Karte steht in der Regel für Arbeit und bedeutet, dass für Sie eine Zeit gekommen ist, in der Sie möglicherweise härter arbeiten müssen, als Sie das in der Regel gewohnt sind. Das kann sich sowohl auf Ihr berufliches als auch auf Ihr privates Leben beziehen. Drei Dinge sind wichtig: Achten Sie darauf, sich auf das Ziel, dass Sie erreichen möchten, zu konzentrieren und nicht nur auf die Arbeit selbst. Stellen Sie sicher, dass Sie ein gewisses Gleichgewicht in Ihrem Leben haben; falls Sie glauben, Hilfe zu brauchen, zögern Sie nicht, darum zu bitten.</p><p>Manchmal steht diese Karte auch buchstäblich für Handarbeit, dem Herstellen von Dingen. Wenn dies der Fall ist sagt Ihnen diese Karte, dass der Beginn einer sehr kreativen Phase bevorsteht. Genießen Sie sie!</p><p>Schließlich kann diese Karte auch auf Anfängerglück hindeuten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Die Acht der Welten sagt Ihnen, dass Arbeit zu viel Platz in Ihrer Beziehung beschlagnahmt, sei es Ihre Arbeit oder die Ihres Partners. Es ist unbedingt notwendig, dass Sie mehr Zeit und Raum für einander machen, wenn Sie wünschen, dass diese Beziehung andauert.</p><p>Wenn Sie derzeit alleine sind fragen Sie Sich vielleicht, ob Sie wirklich einen Partner finden wollen. Vielleicht ist Ihr Job Ihnen jetzt wichtiger? Wenn letzteres der Fall ist, kein Problem, aber vielleicht sollten Sie vorübergehend aufhören mit der Suche nach einer Liebe in Ihrem Leben.</p>",
                        [GuidanceFields.WORK] : "<p>Dies ist eine Zeit, wo Sie erwarten können, dass eine Menge neuer Aufgaben oder Aufträge auf Sie zu kommen. Vielleicht wird Ihre Arbeitsbelastung zu überwältigend und ist es fast unmöglich für Sie alles so richtig zu tun, wie es getan werden sollte. Wenn dies der Fall ist, zögern Sie nicht, Ihre Vorgesetzten an zu sprechen und sie zu bitten Ihnen zu helfen. Stellen Sie Prioritäten in Ihren Aufgaben und vielleicht sollten Sie sogar einige Aufgaben Ihren Kollegen übertragen.</p><p>Wenn Sie auf der Suche nach einem Job sind, werden Sie mit hohen Anforderungen und einer gründlichen Prüfung Ihrer Fähigkeiten konfrontiert. Keine Panik oder Zögerung, Sie sind stärker als Sie denken und Sie werden in der Lage sein, diese Anforderungen zu erfüllen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Egal ob Sie einen traditionellen Job oder einen alternativen haben, jetzt ist es die Zeit, wo Sie Sich fragen können, warum Sie an dem Ort sind, wo Sie Heute sind. Tun Sie tatsächlich die Dinge, wovon Sie wissen, dass Sie die am Beste tun können? Wachsen und entwickeln Sie Sich? Oder haben Sie Sich von Meinungen anderer bei der Auswahl Ihrer Arbeit oder Karriere beeinflussen lassen? Sind Sie bereit einen anderen Job zu suchen, wo Sie Ihre Fähigkeiten anwenden können und wo man diese sogar begrüßen wird? Sind Sie bereit, wirklich (spirituell) Sich Selbst zu werden bei der Arbeit?</p>",
                    }
                }
            },
            {
                filename: "72",
                numerology : 9,
                en : {
                    name: "Nine of Worlds", subtitle: "Harvest",
                    suit : "Worlds",
                    element : "🜃 - earth",
                    theme : "knowing your value",    
                    description : "Nine of Worlds talks about being conscious of your self-worth and to show it to others.",
                    questions : [ "Have you gained knowledge and experience?", "Do you know your value?", "Do you trust your own capacities?", "What do you do with your knowledge and experience?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>This is a positive, energetically uplifting card. It regularly points to financial wellbeing or improvement but generally it means that good times are awaiting you. The positive things you will experience can be in any part of your live: work, love, spirituality, … Anyway you will notice that things are going better than you had expected. Don’t forget to share this wealth and happiness with the people around you!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are currently in a relationship you will notice that the contacts with your partner are getting better than they ever were before. Together you enjoy loving and romantic times and you understand each other better than you ever have.</p><p>If you are single, the time is right for you to meet someone with great potential as a life partner and it won’t even take too much effort. Just be open and interested and you will hear opportunity knocking at your door!</p>",
                        [GuidanceFields.WORK] : "<p>If you currently have a job, now is the time to expect a raise, a promotion or some other change for the better. Don’t ask yourself too many questions, you have deserved this.</p><p>If you are looking for a job, you will notice that many opportunities are coming your way, and you will almost certainly find a new position.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>For the moment you are feeling very happy and fulfilled, your self-respect is strong and you have great confidence. It feels as if everything is going your way. You realize that you have gathered a lot of spiritual knowledge and insight. So much even that you may consider being an inspiration and help to others who are still searching for the answers you already found. Share your wealth, help others enjoy spiritual strength the way you do.</p>",
                    }
                },
                fr : {
                    name : "Le Neuf de Mondes", subtitle: "Récolte",
                    suit : "Worlds",
                    element :  "🜃 - terre",
                    theme : "savoir ce que l'on vaut",    
                    description : " Le Neuf de Mondes, nous parle d’être conscient de ce que l’on vaut et que l'on peut également le montrer aux autres.",
                    questions : ["Avez-vous acquis de la connaissance et de l'expérience?", "Savez-vous ce que vous valez?", "Faites-vous confiance à vos propres capacités?", "Que faites-vous de votre connaissance et expérience?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>C'est une carte positive et stimulante sur le plan énergétique. Elle indique souvent le bien-être ou l'amélioration de la situation financière. En règle générale, cela signifie que de bons moments  viennent vers vous. Les choses positives que vous allez vivre peuvent être vécues dans tous les aspects de votre vie : au travail, dans l'amour, dans la spiritualité, .... Cependant, vous constaterez que les choses vont mieux que prévu. Essayez de profiter non seulement des avantages matériels, mais aussi des avantages émotionnels et spirituels. N'oubliez pas de partager la richesse et le bonheur avec les gens autour de vous !</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are currently in a relationship you will notice that the contacts with your partner are getting better than they ever were before. Together you enjoy loving and romantic times and you understand each other better than you ever have.</p><p>If you are single, the time is right for you to meet someone with great potential as a life partner and it won’t even take too much effort. Just be open and interested and you will hear opportunity knocking at your door!</p>",
                        [GuidanceFields.WORK] : "<p>If you currently have a job, now is the time to expect a raise, a promotion or some other change for the better. Don’t ask yourself too many questions, you have deserved this.</p><p>If you are looking for a job, you will notice that many opportunities are coming your way, and you will almost certainly find a new position.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>For the moment you are feeling very happy and fulfilled, your self-respect is strong and you have great confidence. It feels as if everything is going your way. You realize that you have gathered a lot of spiritual knowledge and insight. So much even that you may consider being an inspiration and help to others who are still searching for the answers you already found. Share your wealth, help others enjoy spiritual strength the way you do.</p>",
                    }
                },
                sp:{
                    name:"El Nueve de Mundos", subtitle: "Cosecha",
                    suit: "Mundos",
                    element: "🜃 - tierra",
                    theme: "saber lo que vales",    
                    description: "El Nueve de Mundos se trata sobre ser consciente de lo que vales y señalarnos que también podemos mostrar esto a otros",
                    questions: ["¿Has adquirido conocimientos y experiencia?", "¿Sabes lo que vales?", "¿Confías en tus propias habilidades?", "¿Qué haces con tus conocimientos y experiencia?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Esta es una carta positiva, con energía edificante. Es regularmente apunta al bienestar financiero o mejora, pero por lo general significa que los buenos tiempos le esperan. Las cosas positivas que experimentará pueden estar en cualquier parte de su vida: trabajo, amor, espiritualidad, ... De todos modos te darás cuenta de que las cosas van mejor de lo que había esperado. No se olvide de compartir esta riqueza y la felicidad con las personas que le rodean!</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Werelden Negen", subtitle: "Oogst",
                    suit : "Werelden",
                    element : "🜃 - aarde",
                    theme : "weten wat je waard bent",    
                    description : "De negen van Werelden gaat over bewust zijn van wat je waard bent en wijst ons erop dat we dit ook mogen tonen aan anderen.",
                    questions : [ "Heb jij kennis en ervaring opgedaan?", "Weet jij wat je waard bent?", "Vertrouw jij op je eigen kunnen?", "Wat doe jij met je kennis en ervaring?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Werelden Negen is een positieve, sterk energetische kaart. Ze wijst er vaak op dat je op financieel en materieel vlak goed zit of dat er zelfs een verbetering komt op dat vlak. Over het algemeen kondigt deze kaart goede tijden aan. De positieve dingen die je zal ervaren kunnen zich situeren op elk gebied: werk, liefde, spiritualiteit, ... Je zal in elk geval merken dat alles beter verloopt dan je verwacht had. Probeer niet enkel te focussen op je materiële welzijn maar ook op het emotionele en spirituele, ook al is dat een stuk minder tastbaar of opvallend. Denk er ook aan om je rijkdommen te delen met de mensen om je heen, gedeelde vreugd is dubbele vreugd!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een liefdesrelatie hebt dan zal je merken dat het contact met je partner beter en intenser is dan ooit tevoren. Jullie genieten samen van romantische en liefdevolle tijden en jullie begrijpen mekaar ook beter dan ooit, vaak zonder dat er woorden voor nodig zijn, Jullie hebben op dit moment genoeg aan een blik of een gebaar.</p><p>Als je alleen bent dan is nu het ideale moment om iemand te ontmoeten met veel potentieel, iemand die een grote kans maakt om je levenspartner te worden. Het zal je zelfs weinig moeite kosten om deze persoon te vinden. Zorg er gewoon voor dat je openstaat en geïnteresseerd bent naar mensen toe en de rest zal vanzelf naar je toe komen.</p>",
                        [GuidanceFields.WORK] : "<p>Als je een baan hebt dan is het nu een goede tijd om opslag of een promotie te krijgen, het ziet ernaar uit dat er in elk geval veranderingen ten goede komen op werkvlak. Stel je hier zeker geen vragen bij, het is duidelijk dat je deze positieve evolutie ten volle verdiend hebt!</p><p>Als je op zoek bent naar werk dan zal je merken dat er veel kansen en mogelijkheden op je weg komen. Besteed je energie eraan om deze kansen te benutten, niet om je af te vragen waar ze vandaan komen. Maak er ten volle gebruik van en je zal binnen de kortste keren een passende job vinden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment voel je je blij en tevreden, je zelfrespect is sterk en je hebt veel vertrouwen in jezelf. Het voelt alsof alles net zo loopt als jij het graag wil. Je geniet ook ten volle van het besef dat je in het verleden veel spirituele kennis vergaard hebt en daardoor duidelijke inzichten gekregen hebt. Je bent op spiritueel vlak dusdanig gegroeid dat je je zelfs kan opstellen als inspiratie voor anderen. Je kan mensen die nog zoekende zijn helpen met de antwoorden die jij al gevonden hebt. Deel je spirituele rijkdom vrijelijk, help anderen om de spirituele kracht te vinden in zichzelf die jij al verworven hebt.</p>",
                    }
                },
                de : {
                    name: "Neun der Welten", subtitle: "Ernte",
                    suit : "Welten",
                    element : "🜃 - Erde",
                    theme : "Wissen, was man wert ist.",    
                    description : "Die Neun der Welten spricht darüber, sich bewusst zu sein, was man wert ist und dass man dies anderen auch zeigen kann.",
                    questions : [ "Haben Sie Wissen und Erfahrung erworben?", "Wissen Sie, was sie wert sind?", "Vertrauen Sie Ihren eigenen Fähigkeiten?", "Was machen Sie mit Ihrem Wissen und Ihrer Erfahrung?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Das ist eine positive, energetisch stimulierende Karte. Sie deutet oft auf finanzielles Wohlergehen oder auf die Verbesserung hin. In der Regel bedeutet sie, dass gute Zeiten auf Sie zukommen. Die positiven Dinge, die Sie erleben werden, können Sie in jedem Teil Ihres Lebens erfahren: in der Arbeit, der Liebe, der Spiritualität, ... Wie auch immer, Sie werden feststellen, dass die Dinge besser laufen als erwartet. Versuchen Sie, nicht nur die materiellen Vorteile zu genießen, sondern auch die emotionalen und spirituellen. Vergessen Sie nicht, Reichtum und Glück mit den Menschen in Ihrer Umgebung zu teilen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer Beziehung sind, werden Sie feststellen, dass die Kontakte mit Ihrem Partner immer besser werden, als je zuvor. Gemeinsam genießen Sie liebevolle und romantische Zeiten und Sie verstehen einander besser als Sie jemals getan haben. Wenn Sie alleine sind, ist die Zeit da, dass Sie die richtigen Lebenspartner mit großem Potenzial treffen könnten und nicht einmal mit zu viel Mühe. Seien Sie nur offen und interessiert und die Chance klopft an Ihre Tür!</p>",
                        [GuidanceFields.WORK] : "<p>Wenn Sie einen Job haben, ist jetzt die Zeit, wo Sie eine Gehaltserhöhung, eine Beförderung oder eine andere Veränderung zum Besseren erwarten können. Stellen Sie Sich nicht zu viele Fragen, Sie haben es verdient.</p><p>Wenn Sie auf der Suche nach einem Job sind, werden Sie feststellen, dass viele Chancen auf Ihren Weg kommen und Sie werden mit ziemlicher Sicherheit eine neue Position finden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Zur Zeit fühlen Sie Sich sehr glücklich und erfüllt, Ihre Selbstachtung ist hoch und Sie haben großes Vertrauen. Es fühlt sich an, als ob alles, was vor sich geht, genau so läuft wie Sie möchten. Sie erkennen, dass Sie eine Menge von geistiger Kenntnis und Einsicht gesammelt haben. So sehr sogar, dass Sie spüren können, dass Sie eine Inspiration und Hilfe sein könnten für andere, die immer noch auf der Suche sind nach den Antworten, die Sie bereits gefunden haben. Teilen Sie Ihren Reichtum und helfen Sie andere die spirituelle Kraft zu genießen so wie Sie es tun.</p>",
                    }
                }
            },
            {
                filename: "73",
                numerology : 10,
                en : {
                    name: "Ten of Worlds", subtitle: "Reward",
                    suit : "Worlds",
                    element : "🜃 - earth",
                    theme : "doing a lot",    
                    description : "Ten of  Worlds talks about doing a lot and that we could partially delegate.",
                    questions : [ "Are you doing a lot?", "Are you swamped in your occupations?", "What could you possibly delegate?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Ten of Worlds is a very positive card that speaks of riches, security, stability, generally being carefree, not only material but also on other levels. The card tells us to not only focus on your material wealth but also pay attention to emotional and spiritual wellbeing. Make sure you are conscious of the road you had to take to get to this point and share the riches you have. One day you might be in need and then you will be happy if someone reaches out to you: we get what we give, life is a mirror.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>You are experiencing the joy of love and appreciation in all your relationships. Where your partner is concerned it is well possible that you consider taking the relationship to the next level, you may think about moving in together, having children, getting married, … now you truly realize how it feels to love unconditionally.</p><p>If you are single you may be on the verge of finding a partner. And this may go so quickly it stuns you. Don’t forget to enjoy it to the fullest!</p>",
                        [GuidanceFields.WORK] : "<p>You are currently really doing a good job and everyone around you notices and appreciates this. You may even get a raise or a promotion and you feel very fulfilled about the tasks at hand.</p><p>If you are looking for work now is the time to persevere, to continue looking with doubled efforts because a positive time is coming your way. You will find a job and it might be a lot better than you imagined!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>At this moment you will feel spiritually happy, at peace and well balanced. This is a time to enjoy these feelings and spread them around. Share your insights with everyone around you, talk about them, write them down. Do anything you can to preserve what and how you are feeling right now. Life is a series of ups and downs so you know a time will come that you will be feeling less happy, less relaxed. It is important that in those times you can recreate what you are feeling and experiencing right now in order to remember what your goal is, what you are aiming for.</p>",
                    }
                },
                fr : {
                    name : "Le Dix de Mondes", subtitle: "Récompense",
                    suit : "Worlds",
                    element :  "🜃 - terre",
                    theme : "faire beaucoup",    
                    description : "Le Dix de Mondes, nous parle de faire beaucoup et de possiblement pouvoir déléguer certaines choses à d’autres.",
                    questions : ["Faites-vous beaucoup?", "Vous enterrez-vous dans de nombreuses activités?", "Que pourriez-vous déléguer?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Dix de Welten\" est une carte très positive, elle représente la richesse, la sécurité, la stabilité, généralement l'insouciance, non seulement en termes matériels, mais aussi sur d'autres plans. La carte dit qu'il faut se concentrer non seulement sur le bien-être matériel, mais aussi sur le bien-être émotionnel et spirituel. Soyez conscient du chemin que vous avez pris pour arriver à ce point et partager votre richesse. Un jour, vous serez peut-être vous-même dans le besoin et vous serez heureux quand quelqu'un vous tend la main : nous recevons ce que nous donnons. La vie est comme un miroir.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>You are experiencing the joy of love and appreciation in all your relationships. Where your partner is concerned it is well possible that you consider taking the relationship to the next level, you may think about moving in together, having children, getting married, … now you truly realize how it feels to love unconditionally.</p><p>If you are single you may be on the verge of finding a partner. And this may go so quickly it stuns you. Don’t forget to enjoy it to the fullest!</p>",
                        [GuidanceFields.WORK] : "<p>You are currently really doing a good job and everyone around you notices and appreciates this. You may even get a raise or a promotion and you feel very fulfilled about the tasks at hand.</p><p>If you are looking for work now is the time to persevere, to continue looking with doubled efforts because a positive time is coming your way. You will find a job and it might be a lot better than you imagined!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>At this moment you will feel spiritually happy, at peace and well balanced. This is a time to enjoy these feelings and spread them around. Share your insights with everyone around you, talk about them, write them down. Do anything you can to preserve what and how you are feeling right now. Life is a series of ups and downs so you know a time will come that you will be feeling less happy, less relaxed. It is important that in those times you can recreate what you are feeling and experiencing right now in order to remember what your goal is, what you are aiming for.</p>",
                    }
                },
                sp:{
                    name:"El Diez de Mundos", subtitle: "Recompensa",
                    suit: "Mundos",
                    element: "🜃 - tierra",
                    theme: "hacer muchísimo",    
                    description: "El Diez de Mundos se trata de hacer mucho y nos señala que podríamos ser capaces de transferir una parte de eso a otros",
                    questions: ["¿Haces mucho?", "¿Te entierras en tus muchas actividades?", "¿Qué podrías delegar?" ],
                    
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Diez de Oros es una tarjeta muy positivo que habla de las riquezas, la seguridad, la estabilidad, en general, ser, no sólo el material sin preocupaciones, sino también en otros niveles. La carta nos dice que centrarse no sólo en su riqueza material, sino también prestar atención al bienestar emocional y espiritual. Asegúrese de que está consciente del camino que había que tomar para llegar a este punto y compartir las riquezas que tiene. Un día usted podría estar en necesidad y entonces usted será feliz si alguien se acerca a usted: conseguimos lo que damos, la vida es un espejo.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Werelden Tien", subtitle: "Beloning",
                    suit : "Werelden",
                    element : "🜃 - aarde",
                    theme : "heel veel doen",    
                    description : "De tien van Werelden gaat over heel veel doen en wijst ons erop dat we wellicht een gedeelte over kunnen dragen aan een ander.",
                    questions : [ "Doe jij veel?", "Raak jij bedolven onder je vele bezigheden?", "Wat zou jij kunnen delegeren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Werelden Tien is een heel positieve kaart die wijst op rijkdom, veiligheid en stabiliteit. Ze wijst erop dat je over het algemeen vrij van zorgen bent, niet enkel op materieel vlak maar ook in andere aspecten van je leven. Als je deze kaart trekt is het belangrijk erbij stil te staan dat je best niet enkel focust op materieel welzijn maar ook op je emotionele en spirituele levenskwaliteit.</p><p>Sta even bewust stil bij de weg die je tot dit punt gebracht heeft en deel je rijkdom met anderen. Het is immers best mogelijk dat je op een dag zelf iets te kort zal komen en dat je op dat moment blij zal zijn als een ander dan bereid is om zijn rijkdommen met jou te delen. Het leven is een spiegel, een echo: we krijgen wat we geven.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In bijna al je relaties ervaar je op dit moment in je leven een liefdevolle vreugde en waardering. In verband met je liefdespartner is het best mogelijk dat je aan het overwegen bent om een volgende stap te zetten. Misschien denk je aan samenwonen, kinderen krijgen, trouwen, ... Je voelt nu immers ten volle wat het betekent om onvoorwaardelijk van iemand te houden.</p><p>Als je op dit moment single bent dan sta je waarschijnlijk op het punt om een partner te vinden. Er kan zo snel een goede kandidaat op je pad komen dat het je verrast. Ook al evolueren de dingen heel snel, probeer er toch ten volle van te genieten. Het prille begin van een liefdesrelatie is de basis van jullie verdere leven samen, beleef het bewust, ga onvoorwaardelijk voor dit avontuur samen met je nieuwe partner.</p>",
                        [GuidanceFields.WORK] : "<p>Je presteert op dit moment erg goed in je werk en het is duidelijk dat de mensen om je heen zich daarvan bewust zijn en het ook echt waarderen. Net als Werelden Negen wijst ook Werelden Tien erop dat er mogelijk een promotie of opslag zit aan te komen. Je voelt je ook tevreden en waardevol en je geniet echt van het werk dat je doet.</p><p>Als je op zoek bent naar werk dan is het op dit moment belangrijk dat je niet enkel volhoudt maar dat je zelfs je inspanningen verdubbelt want je bent op weg naar positievere tijden waarin je een baan zal vinden die nog fijner is dan je verwacht had!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Wat je spiritualiteit betreft ben je op dit ogenblik gelukkig, tevreden en goed in evenwicht. Het is nu zaak om intens van deze gevoelens te genieten en ze ook te delen. Vertel je inzichten aan iedereen om je heen, praat over je spiritualiteit, schrijf erover. Gebruik elke mogelijke manier om dat gevoel dat je nu ervaart vast te leggen zodat je het kan bewaren. Het leven is nu eenmaal een reeks van pieken en dalen en je weet dat er ook weer een tijd zal komen waarin je je minder goed en ontspannen voelt. Het is belangrijk dat je dan kan teruggrijpen naar je huidige gevoelens, dat je dan kan herbeleven wat je nu meemaakt. Het zal je helpen om je bewust te worden van je doel, de richting die je op wil.</p>",
                    }
                },
                de : {
                    name: "Zehn der Welten", subtitle: "Belohnung",
                    suit : "Welten",
                    element : "🜃 - Erde",
                    theme : "Viel tun",    
                    description : "Die Zehn der Pentagramms spricht darüber, viel zu tun und möglicherweise einige Dinge an andere delegieren zu können.",
                    questions : [ "Tun Sie viel?", "Begraben Sie sich in vielen Aktivitäten?", "Was könnten Sie delegieren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Zehn der Welten“  ist ein sehr positive Karte, sie steht für Reichtum, Sicherheit, Stabilität, in der Regel für Sorglosigkeit, nicht nur in materieller Hinsicht, sondern auch auf anderen Ebenen. Die Karte sagt, dass Sie sich nicht nur auf materiellen Wohlstand konzentrieren, sondern ihre Aufmerksamkeit auch auf emotionales und geistiges Wohlbefinden richten sollten. Machen Sie sich den Weg bewusst, den Sie gegangen sind, um an diesem Punkt anzukommen und teilen Sie Ihren Reichtum. Eines Tages werden Sie selbst vielleicht in Not sein, und dann werden Sie froh sein, wenn Ihnen jemand die Hand reicht: Wir empfangen, was wir geben. Das Leben ist wie ein Spiegel.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Sie erleben die Freude der Liebe und Schätzung in all Ihren Beziehungen. Wo es Ihr Partner betrifft ist es gut möglich, dass Sie die Beziehung auf die nächste Ebene bringen werden. Sie können zusammenziehen, mit Kindern Anfängen, heiraten. Jetzt erfahren Sie wirklich was es heißt bedingungslos zu lieben.</p><p>Wenn Sie alleine sind, können Sie gerade dabei sein einen Partner zu finden. Und das kann so schnell gehen, es Sie überrascht. Vergessen Sie nicht, in vollen Zügen zu genießen!</p>",
                        [GuidanceFields.WORK] : "<p>Sie leisten zur Zeit eine wirklich gute Arbeit und alle um Sie herum bemerken und schätzen das. Sie können sogar eine Gehaltserhöhung oder eine Beförderung bekommen und Sie fühlen Sich sehr erfüllt mit Ihren Aufgaben.</p><p>Wenn Sie auf der Suche nach Arbeit sind, ist jetzt die Zeit zum Durchhalten, auch weiterhin auf der Suche mit verdoppelten Anstrengungen, weil eine positive Zeit auf Ihrem Wege kommen wird. Sie werden eine Arbeit finden, und diese könnte viel besser sein als Sie gedacht haben!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In diesem Moment fühlen Sie sich spirituell glücklich, friedlich und gut ausgewogen. Dies ist eine Zeit, um diese Gefühle zu genießen und sie um Sie herum auszubreiten. Teile Ihre Erkenntnisse mit jedem um Sie herum, sprechen Sie sich aus, schreiben Sie sie auf. Tun Sie alles, was Sie können, um zu bewahren, was und wie Sie Sich gerade fühlen. Das Leben ist eine Reihe von Höhen und Tiefen, sodass Sie wissen, es wird eine Zeit kommen, wo wir uns weniger Glücklich fühlen und weniger entspannt. Es ist wichtig, dass wir in diesen Zeiten Wiederschaffen was wir jetzt fühlen und erleben damit wir uns erinnern, was unser Ziel ist, das wir anstreben.</p>",
                    }
                }
            },
            {
                filename: "74",
                numerology : 11,
                en : {
                    name: "Child of Worlds", subtitle : "Player",
                    suit : "Worlds",
                    element : "🜃 - earth",
                    court : "Page",
                    theme : "looking impartially at your possessions",    
                    description : "Page of Worlds talks about looking impartially at your possessions and see our possessions, talents and experiences with a child-like light-heartedness.",
                    questions : [ "What are you good at?", "What do  you possess?", "What could you offer?", "Where is there a need for growth?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>When looking at this card you will notice that the Page is offering you a Pentacle, a coin. Consider this as a real gift, this card gives you a lucky coin for you to use wherever necessary. This card is about getting chances, opportunities, possibilities. Maybe you are at a stage in life when there are things you have to do that don’t really fascinate you, on the contrary, you may feel a bit bored, tired, as if you are in a rut. Don’t give in to those feelings but realize that somewhere, deep inside, you still have a certain amount of energy hidden and now is the time to use it. Remember what your goals are and focus on them. Once you realize what you were working towards, the energy will come to you again and you will be able to reach those goals.</p><p>Don’t try to do this without investing energy, it will not work.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a committed relationship you may have the feeling that things are getting less adventurous, less passionate. You don’t feel what you used to feel in the early days of your relationship. The magic seems to be gone. Hiding for this won’t help, it will probably even make things worse. So sit your partner down, tell her/him how you feel and try to work things out together. Maybe he/she is feeling the same way but was afraid to bring it up.</p><p>If you are single it may seem as if your efforts to find a partner are leading you nowhere. Look deep inside your heart and ask yourself if there is anything that might be holding you back: could you still have feelings for a previous partner? Are you absolutely sure you want a relationship right now? Answering these questions may give you the clue you need to make your quest a successful one.</p>",
                        [GuidanceFields.WORK] : "<p>The Page of Worlds tells you that something is not quite right in the workplace. You seem to have a lot of work that needs to be done but somehow you lack enthusiasm, energy. You find it hard to totally commit to the job, it doesn’t interest you quite the way it used to. Even though maybe you could do this alone, it is wise to ask for help. There are many people around you who really want to help you and their support will give you the boost you need to finish the race.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Working towards spiritual growth doesn’t seem to be as big an adventure and a challenge as it used to be. Somehow you have lost most of your motivation, your drive. To regain that zeal you used to have you might ask yourself some questions: what really interests you? Which subject makes you want to find out more? Which questions would you really like answered? Don’t hesitate to go look into the farthest corners, the most unlikely places. You will notice that these new paths you choose will really excite you, replenish your energy.</p>",
                    }
                },
                fr : {
                    name : "L'enfant des Mondes", subtitle : "Joueur",
                    suit : "Worlds",
                    element :  "🜃 - terre",
                    court : "Valet",
                    theme : "Regarder ce que l'on possède d’un esprit ouvert",    
                    description : "Le Valet des Mondes, nous parle du regard impartial concernant ce que nous avons entre les mains et de regarder nos possessions, nos talents et nos expériences d’un esprit ouvert et enfantin.",
                    questions : ["Que savez-vous faire?", "Que possédez-vous?", "Que pourriez-vous offrir?", "Qu'est-il encore qu’aux balbutiements?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Quand vous regardez cette carte, vous remarquerez qu'elle vous offre une pièce de monnaie. Considérez ça comme un vrai cadeau. Cette carte vous donne une pièce porte-bonheur que vous pouvez utiliser quand et où vous en avez besoin. Cette carte représente les chances, les perspectives, les possibilités que vous obtenez. Vous avez peut-être atteint un point dans votre vie où les choses que vous faites ne vous excitent pas vraiment. Au contraire, vous pouvez vous sentir un peu ennuyé ou fatigué, comme si vous étiez dans un creux. Ne cédez pas à ces sentiments, mais essayez de réaliser que quelque part au fond de vous, il y a encore une certaine quantité d'énergie cachée. C'est le moment de l'utiliser !</p><p>Pensez à ce que sont vos objectifs et concentrez-vous. Une fois que vous aurez réalisé dans quelle direction vous souhaitez avancer, l'énergie vous reviendra et vous serez en mesure d'atteindre ces objectifs.</p><p>N'essayez pas d'y parvenir sans être en contact avec votre potentiel d'énergie, car cela ne fonctionne pas.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a committed relationship you may have the feeling that things are getting less adventurous, less passionate. You don’t feel what you used to feel in the early days of your relationship. The magic seems to be gone. Hiding for this won’t help, it will probably even make things worse. So sit your partner down, tell her/him how you feel and try to work things out together. Maybe he/she is feeling the same way but was afraid to bring it up.</p><p>If you are single it may seem as if your efforts to find a partner are leading you nowhere. Look deep inside your heart and ask yourself if there is anything that might be holding you back: could you still have feelings for a previous partner? Are you absolutely sure you want a relationship right now? Answering these questions may give you the clue you need to make your quest a successful one.</p>",
                        [GuidanceFields.WORK] : "<p>The Page of Worlds tells you that something is not quite right in the workplace. You seem to have a lot of work that needs to be done but somehow you lack enthusiasm, energy. You find it hard to totally commit to the job, it doesn’t interest you quite the way it used to. Even though maybe you could do this alone, it is wise to ask for help. There are many people around you who really want to help you and their support will give you the boost you need to finish the race.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Working towards spiritual growth doesn’t seem to be as big an adventure and a challenge as it used to be. Somehow you have lost most of your motivation, your drive. To regain that zeal you used to have you might ask yourself some questions: what really interests you? Which subject makes you want to find out more? Which questions would you really like answered? Don’t hesitate to go look into the farthest corners, the most unlikely places. You will notice that these new paths you choose will really excite you, replenish your energy.</p>",
                    }
                },
                sp:{
                    name:"Niño de Mundos", subtitle : "Jugador",
                    suit: "Mundos",
                    element: "🜃 - tierra",
                    court: "Soto",
                    theme: "mirar desprevenido á lo  que tienes con la mente abierta",    
                    description: "El Soto de Mundos trata sobre la imparcialidad de mirar á lo que tienes en tus 'manos' y nos recuerda que podemos mirar á nuestras posesiones, nuestros talentos y nuestras experiencias con una mentalidad abierta y infantil",
                    questions: ["¿En qué eres bueno?", "¿Qué tienes en las manos?", "¿Qué podrías ofrecer?", "¿Qué es lo que todavía está en su infancia?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Al mirar esta tarjeta se dará cuenta  que la página  está ofreciendo un pináculo, una moneda. Considere esto como un verdadero regalo, esta tarjeta le da una moneda de la suerte para que pueda utilizar cuando sea necesario. Esta tarjeta es sobre conseguir oportunidades, oportunidades, posibilidades. Tal vez usted está en una etapa de la vida cuando hay cosas que tiene que hacer que no lo hacen realmente le fascina, por el contrario, se puede sentir un poco aburrido, cansado, como si estuviera en una rutina. No ceder a esos sentimientos, pero darse cuenta de que en algún lugar, en el fondo, usted todavía tiene una cierta cantidad de energía oculta y ahora es el momento de usarlo. Recuerde cuáles son sus objetivos y centrarse en ellos. Una vez que le da cuenta de lo que estaba trabajando hacia la energía vendrá a usted otra vez y usted será capaz de alcanzar esas metas. No trate de hacer esto sin tener que invertir energía, no va a funcionar.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Kind van Werelden", subtitle : "Speler",
                    suit : "Werelden",
                    element : "🜃 - aarde",
                    court : "Page",
                    theme : "onbevooroordeeld kijken naar wat je hebt",    
                    description : "De page van Werelden gaat over onbevooroordeeld kijken naar wat je in je 'handen' hebt en wijst ons erop dat we met kinderlijke onbevangenheid mogen kijken naar ons bezit, naar onze talenten en ervaringen.",
                    questions : [ "Waar ben jij goed in?", "Wat heb jij in je handen?", "Wat zou jij aan kunnen bieden?", "Wat staat nog in de kinderschoenen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Op de afbeelding van deze kaart kan je zien dat de Page je een pentakel, een munt aanbiedt. Je kan dit beschouwen als een echt geschenk want de Page van Werelden biedt je een geluksmunt die je kan inzetten waar en wanneer het nodig is. Deze kaart wijst dan ook op mogelijkheden, kansen en voorstellen.</p><p>Misschien zit je op dit moment in een fase waarin je de dingen die je doet niet meer echt boeiend vindt. Integendeel, je verveelt je misschien zelfs, je zit een stuk in een sleur. Probeer om niet toe te geven aan deze gevoelens maar je op te trekken aan het feit dat er ergens, diep binnenin, nog een bron van energie verborgen zit. Nu is het de juiste tijd om die bron aan te boren en te gebruiken!</p><p>Keer terug naar je oorspronkelijke doelen, naar de basis waar je vandaan komt en concentreer je daarop. Eens je je weer herinnert waar je naar op weg was, zal je energie terug beginnen stromen en zal je die doelen ook weer kunnen bereiken. Het is nu eenmaal zo dat je niets kan bereiken als je niet investeert, en jouw belangrijkste kapitaal is nu energie.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je een vaste partner hebt is het mogelijk dat je tegenwoordig het gevoel hebt dat er alsmaar minder avontuur en passie in je relatie te vinden is. De intense gevoelens die jullie hadden in het begin van de relatie lijken op dit moment ver weg, bijna onherkenbaar. De magie is er niet meer, zo voelt het. Ga je nu niet verbergen of je gevoelens negeren want dan maak je het alleen maar erger. Vraag je partner om samen te gaan zitten en vertel hem/haar wat je voelt. Zo maak je het begin van een oplossing mogelijk, door open communicatie. Het is trouwens best mogelijk dat je partner last heeft van dezelfde gevoelens als jij maar er niet over durfde te beginnen.</p><p>Als je op zoek bent naar een partner dan zit je waarschijnlijk een beetje vast, heb je het gevoel alsof al je inspanningen om de liefde te vinden geen resultaat boeken, je bent en komt nergens, zo voelt het. Neem nu de tijd om diep in je hart te kijken en je af te vragen of er misschien nog iets zit wat je tegenhoudt: is het mogelijk dat je nog gevoelens hebt voor je vorige partner? Ben je er helemaal zeker van dat je nu echt een relatie wil? Stel jezelf een aantal (moeilijke) vragen want de antwoorden hierop zullen je helpen om de juiste weg te vinden naar een nieuwe partner.</p>",
                        [GuidanceFields.WORK] : "<p>De Page van Werelden vertelt je dat er iets niet helemaal goed voelt op je werk. Je zit op dit ogenblik met een pak taken die uitgevoerd moeten worden, maar op een of andere manier vind je het enthousiasme en de energie niet om er volop voor te gaan. Je vindt het moeilijk om je echt te engageren, je werk interesseert je een stuk minder dan vroeger het geval was. Ook al zou je dit probleem waarschijnlijk best wel alleen kunnen oplossen, nu is het even beter om de hulp van je omgeving in te roepen. Er zijn een pak mensen om je heen die je willen helpen en ondersteunen en die je net dat zetje kunnen geven dat je nodig hebt om hier doorheen te geraken. Laat ‘samen sterk’ je motto zijn!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Als je denkt aan werken aan je spirituele groei dan lijkt dit niet meer zo’n fijn en uitdagend avontuur te zijn als het vroeger was. Op één of andere manier ben je ergens onderweg je motivatie, je motor kwijtgeraakt. Om dat vroegere enthousiasme terug te vinden zou je jezelf een aantal vragen kunnen stellen: wat interesseert je echt? Bij welk onderwerp of thema denk je onmiddellijk dat je er meer van wil weten? Op welke vragen zou je absoluut het antwoord willen vinden? Als je een aantal antwoorden gevonden hebt dan kan je gericht op zoek gaan naar kennis. Maak het jezelf niet te gemakkelijk, ga overal zoeken, tot in de kleinste hoekjes en gaatjes, op ongewone of misschien zelfs een beetje beangstigende plaatsen. Je zal merken dat je tijdens deze zoektocht je oude energie en vuur weer terugvindt, dat je je spirituele tank terug kan vullen.</p>",
                    }
                },
                de : {
                    name: "Kind der Welten", subtitle : "Spieler",
                    suit : "Welten",
                    element : "🜃 - Erde",
                    court : "Bube",
                    theme : "Mit einem offenen Geist schauen, was man hat.",    
                    description : "Der Bube der Welten spricht von einem unvoreingenommenen Blick auf das, was wir in der Hand haben, und von unserem Besitz, unseren Talenten und Erfahrungen mit einem offenen und kindlichen Geist.",
                    questions : [ "Was können Sie tun?", "Was besitzen Sie?", "Was können Sie anbieten?", "Was steckt noch in den Anfängen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Bei der Betrachtung dieser Karte werden Sie feststellen, dass sie Ihnen ein Pentagramm anbietet, eine Münze. Betrachten Sie dies als echtes Geschenk. Diese Karte gibt Ihnen eine Glücksmünze, die Sie benützen können, wann oder wo immer Sie sie brauchen. Diese Karte steht für die Chancen, Aussichten, Möglichkeiten, die Sie bekommen. Möglicherweise sind Sie an einem Punkt im Leben angekommen, an dem die Dinge, die Sie tun, Sie nicht wirklich begeistern können. Im Gegenteil, Sie können sich ein wenig gelangweilt oder müde fühlen, als ob Sie in einer Talsohle stecken. Geben Sie sich diesen Gefühlen nicht hin, sondern versuchen Sie zu erkennen, dass  irgendwo, tief in Ihrem Inneren, noch eine bestimmte Menge an Energie versteckt ist. Jetzt ist die Zeit gekommen, sie zu aktivieren!</p><p>Denken Sie nach, was Ihre Ziele sind und konzentrieren Sie sich darauf. Sobald Sie erkennen, in welche Richtung Sie gehen wollen, wird die Energie wieder zu Ihnen kommen und Sie werden in der Lage sein, diese Ziele zu erreichen.</p><p>Versuchen Sie nicht, dies ohne den Einsatz von Energie zu erreichen, denn das funktioniert nicht.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer festen Beziehung sind, können Sie das Gefühl haben, dass die Dinge immer weniger abenteuerlich, weniger leidenschaftlich werden. Sie fühlen nicht mehr das Gleiche als in den früheren Tagen Ihrer Beziehung. Der Zauber scheint verschwunden zu sein. Sich verstecken davor wird nicht helfen, es wird es wahrscheinlich nur noch schlimmer machen. Also setzen Sie Sich mit Ihrem Partner zusammen, sagen Sie Ihr / ihm, wie Sie fühlen und versuchen Sie die Sachen zusammen auszuarbeiten. Vielleicht fühlt er / sie auf der gleichen Weise, aber hat Angst, es aus zu sprechen.</p><p>Wenn Sie alleine sind, mag es scheinen, als ob Ihre Bemühungen um einen Partner zu finden zu nichts führen. Schauen Sie tief in Ihrem Herzen und fragen Sie Sich, ob es etwas gibt, was Sie vielleicht zurückhält: könnten Sie immer noch Gefühle für einen früheren Partner haben? Sind Sie absolut sicher, dass Sie jetzt eine Beziehung eingehen wollen? Die Beantwortung dieser Fragen kann Ihnen ankurbeln, um Ihre Suche erfolgreich zu gestalten.</p>",
                        [GuidanceFields.WORK] : "<p>Der Bube der Welten zeigt an, dass etwas nicht stimmt am Arbeitsplatz. Sie scheinen eine Menge Arbeit zu haben, die getan werden muss, aber irgendwie fehlt Ihnen die Begeisterung, die Energie. Sie finden es schwer, sich ganz auf den Job zu stürzen, es ist nicht mehr so ganz interessant so wie es früher war. Obwohl Sie vielleicht alleine gut zurecht kommen, ist es ratsam um Hilfe zu bitten. Es gibt viele Menschen um Sie herum, die wirklich helfen, und Ihre Unterstützung kurbelt Sie an die Arbeit zu besiegen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Arbeiten an spirituelles Wachstum scheint nicht mehr so ein großes Abenteuer und große Herausforderung zu sein, wie es früher war. Irgendwie haben Sie Ihre Motivation, Ihren Antrieb verloren. Um den Eifer, den Sie hatten, zurückzubekommen könnten Sie Sich ein paar Fragen stellen. Welches Thema macht Sie neugierig, um weiter zu untersuchen? Welche Fragen würden Sie wirklich gerne beantwortet haben? Zögern Sie nicht, suchen Sie in den entferntesten Winkeln, den unwahrscheinlichsten Orten. Sie werden feststellen, dass diese neuen Wege, die Sie wählen, Ihnen wirklich begeistern werden und Ihnen neue Energie geben.</p>",
                    }
                }
            },
            {
                filename: "75",
                numerology : 12,
                en : {
                    name: "Sage of Worlds", subtitle : "Master",
                    suit : "Worlds",
                    element : [ "🜃 - earth", "🜁 - air" ],
                    court : "Knight",
                    theme : "putting yourself out there",    
                    description : "Knight of Worlds talks about showing your worth and to show our talents to the world around us.",
                    questions : [ "Have you gained knowledge and experience?", "What are you using it for?", "Can you show it to others?", "What will you actually do?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Knight of Worlds is a down to earth, feet on the ground card. It speaks of realism, determination, solidity, reliability. It tells you to continually take care of the little things, because if you do that, the big things will take care of themselves. Ask yourself if all aspects of your life are well organized and if this is not the case right now, take care it will be ok from now on.</p><p>Just take care that you don’t go too far, you might become rigid and overorganized.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a committed relationship it may be time to look at how responsibly you behave. Do you share the loads that need to be carried? Do you work as hard to resolve issues as your partner does? Or are you mostly in it for the good times? Answer these questions and adjust things accordingly.</p><p>If you are single and lookingfor love this card tells you that your routine might be getting worn out. You will need to make some changes in the way you look for a partner, try out new places to hang out, critically look at what you expect from a new partner. Even some minor changes can make you a lot more successful.</p>",
                        [GuidanceFields.WORK] : "<p>This card tells you that nowit is the right time to be dependable and predictable. Don’t try to change the way you are getting things done, don’t question the traditions, the habits that form the true base of your job. </p><p>If you are looking for a job it is good to realize that the people you will meet are not looking for an adventurer or an innovator. They want and need someone dependable, someone they can be sure they can count on, so try and behave accordingly.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In this period of your life you are spending a lot of energy to preserve a status quo, to keep on keeping things organized and well taken care of. That is why this may not be the right time to focus too much on the spiritual. While you are busy “just” living you are at the same time evolving spiritually. But if you should focus on this growth too much it may become confusing, maybe even overwhelming. Make sure you are aiming for positive goals en just “be”.</p>",
                    }
                },
                fr : {
                    name : "Sage des Mondes", subtitle: "Maître",
                    suit : "Worlds",
                    element :  ["🜃 - terre", "🜁 - air" ],
                    court : "Cavalier",
                    theme : "Ouvrir son cœur vers l’extérieur",    
                    description : "Le Cavalier des Mondes, nous parle de montrer ce que l’on vaut et de pouvoir montrer ses talents au monde qui nous entoure",
                    questions : ["Avez-vous acquis de la connaissance et de l'expérience?", "Qu'en faites-vous?", "Pouvez-vous montrer cela aux autres?", "Qu'allez-vous réellement faire?"] ,

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le «Chevalier de Welten» est une carte sobre et raisonnable. Elle est synonyme de réalisme, de détermination, de solidarité et de fiabilité. Elle vous dit que vous devriez constamment prendre soin des petites choses et que si vous le faites, les grandes choses s’organiseront d'elles-mêmes. Demandez-vous si tous les aspects de votre vie sont bien organisés et, si ce n'est pas le cas en ce moment, à partir d’aujourd’hui prenez le temps de le mettre en place !</p><p>Mais ne le prenez pas trop à la lettre. Vous risquez de devenir trop rigide et trop organisé.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>If you are in a committed relationship it may be time to look at how responsibly you behave. Do you share the loads that need to be carried? Do you work as hard to resolve issues as your partner does? Or are you mostly in it for the good times? Answer these questions and adjust things accordingly.</p><p>If you are single and lookingfor love this card tells you that your routine might be getting worn out. You will need to make some changes in the way you look for a partner, try out new places to hang out, critically look at what you expect from a new partner. Even some minor changes can make you a lot more successful.</p>",
                        [GuidanceFields.WORK] : "<p>This card tells you that nowit is the right time to be dependable and predictable. Don’t try to change the way you are getting things done, don’t question the traditions, the habits that form the true base of your job. </p><p>If you are looking for a job it is good to realize that the people you will meet are not looking for an adventurer or an innovator. They want and need someone dependable, someone they can be sure they can count on, so try and behave accordingly.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In this period of your life you are spending a lot of energy to preserve a status quo, to keep on keeping things organized and well taken care of. That is why this may not be the right time to focus too much on the spiritual. While you are busy “just” living you are at the same time evolving spiritually. But if you should focus on this growth too much it may become confusing, maybe even overwhelming. Make sure you are aiming for positive goals en just “be”.</p>",
                    }
                },
                sp:{
                    name:"Sabio de Mundos", subtitle: "Maestro",	
                    suit: "Mundos",
                    element: ["🜃 - tierra", "🜁 - aire" ],
                    court: "Caballo",
                    theme: "Salir de ti mismo",    
                    description: "El Caballo de Mundos se trata de mostrar lo que vales y nos recuerda que podemos mostrar nuestros talentos al mundo que nos rodea",
                    questions: ["¿Has adquirido conocimientos y experiencia?", "¿Qué haces con eso?", "¿Puedes enseñárselo a otros?", "¿Qué vas a hacer en realidad?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>El Caballero de Oros es una tarjeta con los pies en la tierra. Se habla de realismo, la determinación, la solidez, la fiabilidad. Se dice que usted tome continuamente la atención de las pequeñas cosas, porque si lo hace, las cosas grandes se harán cargo de sí mismos. Pregúntese si todos los aspectos de su vida están bien organizados y si este no es el caso ahora, tenga cuidado de que estará bien de ahora en adelante. Sólo tenga cuidado de no ir demasiado lejos, ¡podría ser rígida y más organizada.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Wijze van Werelden", subtitle: "Meester",
                    suit : "Werelden",
                    element : [ "🜃 - aarde", "🜁 - lucht" ],
                    court : "Ridder",
                    theme : "zelf naar buiten treden",    
                    description : "De ridder van Werelden gaat over laten zien wat je waard bent en wijst ons erop dat we onze talenten aan de wereld om ons heen mogen tonen.",
                    questions : [ "Heb jij kennis en ervaring opgedaan?", "Wat doe jij daarmee?", "Kun jij dat ook tonen aan anderen?", "Wat ga jij daadwerkelijk doen?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Ridder van Werelden is een aardse kaart, de ridder heeft zijn voeten stevig op de grond staan. Deze kaart gaat over realisme, vastberadenheid, betrouwbaarheid, stevigheid. Ze vertelt je ook hoe belangrijk het is en blijft om goed te zorgen voor de kleine dingen, wat niet opvalt heeft ook zorg en aandacht nodig. Als je deze houding volhoudt en je aandacht richt op de details dan zal je merken dat de grotere dingen als het ware voor zichzelf gaan zorgen. Als je deze kaart trekt dan is het de juiste tijd om je af te vragen of alle onderdelen van je leven goed georganiseerd zijn. Als dat niet het geval is, maak er dan nu werk van, breng orde in de chaos en blijf die orde dan ook onderhouden.</p><p>Natuurlijk is het belangrijk dat je hier niet te ver in gaat omdat je dan het risico loopt dat je star en te gestructureerd wordt, zonder ruimte voor improvisatie en spontaniteit.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een liefdesrelatie hebt dan is het nu een goede tijd om je af te vragen hoe verantwoordelijk jij je gedraagt in deze relatie. Neem je voldoende taken op je, draag je jouw deel van het gewicht, werk je even hard als je partner als het op het oplossen van conflicten aankomt? Kortom, zijn de inspanningen evenredig verdeeld? Of probeer je gewoon zoveel mogelijk van de leuke momenten te genieten en negeer je de rest? Eens je deze vragen beantwoord hebt, kan je beslissen of je je wil gaan aanpassen om alles in evenwicht te krijgen of dat je daar de energie en motivatie niet voor hebt en je dus eigenlijk uit deze relatie wil stappen.</p><p>Als je op zoek bent naar liefde dan vertelt deze kaart je dat je een beetje vastzit in een bepaald patroon. Probeer de manier waarop je je zoektocht aanpakt eens wat te veranderen, probeer nieuwe dingen uit, ga op andere plaatsen zoeken. En ook: kijk eens kritisch naar je verwachtingspatroon, wat voor partner wil je vinden, aan welke voorwaarden moet die persoon voldoen? Is het mogelijk dat je teveel vraagt? Zelfs een aantal kleine aanpassingen kan ervoor zorgen dat je er wel in slaagt om iemand te vinden.</p>",
                        [GuidanceFields.WORK] : "<p>Deze kaart vertelt je dat het in deze periode belangrijk is dat je betrouwbaar en voorspelbaar bent, dat is waar de mensen om je heen (een ook jij zelf) behoefte aan hebben. Verander op dit moment niets aan de manier waarop je de dingen aanpakt, stel tradities niet in vraag, houd je aan de gewoontes die de ware basis vormen van je werk.</p><p>Als je op zoek bent naar werk wees je er dan van bewust dat de mensen die je ontmoet op dit moment niet op zoek zijn naar een vernieuwer, een avontuurlijk of onvoorspelbaar type. Ze hebben eerder behoefte aan iemand die betrouwbaar is, iemand op wie ze kunnen rekenen. Stel je ook zo op en je zal merken dat je zoektocht succesvoller verloopt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In dit hoofdstuk van je leven investeer je veel energie in het behouden van de situatie zoals ze is, je richt je vooral op het organiseren van en zorgen voor de dingen. Daarom is het eigenlijk ook niet zo’n goede tijd om je teveel bezig te houden met het spirituele. Je hebt misschien het gevoel dat je al je tijd en energie stopt in het structureren van je dagelijkse leven maar tegelijkertijd ben je op een natuurlijke manier ook spiritueel aan het groeien, het gaat bijna vanzelf, je hoeft er geen extra aandacht aan te besteden. Het is zelfs zo dat als je je teveel zou focussen op je spirituele groei je enkel verward zou worden, misschien zelfs overweldigd door alle opties. Houd je dus niet teveel bezig met het spirituele, sta gewoon volop in de dagelijkse realiteit en richt je op positieve, constructieve doelen. De rest volgt vanzelf.</p>",
                    }
                },
                de : {
                    name: "Weisen der Welten", subtitle: "Meister",
                    suit : "Welten",
                    element : [ "🜃 - Erde", "🜁 - Luft" ],
                    court : "Ritter",
                    theme : "Sein Herz nach außen öffnen.",    
                    description : "Der Ritter der Welten  spricht darüber, zu zeigen, was wir wert sind und wie wir unsere Talente der Welt um uns herum zeigen können.",
                    questions : [ "Haben Sie Wissen und Erfahrung gesammelt?", "Was machen Sie damit?", "Können Sie das anderen zeigen?", "Was werden Sie wirklich tun?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der „Ritter der Welten“ ist eine nüchterne, vernünftige Karte. Sie steht für Realismus, Entschlossenheit, Solidarität und Zuverlässigkeit. Sie sagt Ihnen, dass Sie sich ständig um die kleinen Dinge kümmern sollten und dass, wenn Sie das tun, die großen Dinge für sich selbst sorgen. Fragen Sie sich, ob alle Aspekte Ihres Lebens gut organisiert sind, und wenn dies in diesem Moment nicht der Fall ist, stellen Sie sicher, dass das ab jetzt in Ordnung sein wird.</p><p>Nehmen Sie das aber nicht zu wörtlich. Die Gefahr besteht dann, dass Sie zu unflexibel und überorganisiert werden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wenn Sie in einer festen Beziehung sind es an der Zeit zu überprüfen, wie verantwortungsvoll Sie Sich verhalten. Teilen Sie die Lasten, die Sie tragen müssen? Haben Sie genauso hart gearbeitet, um Probleme zu lösen, wie Ihr Partner das tut? Oder sind Sie meistens nur da für die guten Zeiten? Beantworten Sie diese Fragen und passen Sie die Dinge dementsprechend an.</p><p>Wenn Sie alleine sind und in einer ich-suche Liebe Situation sind, dann sagt diese Karte Ihnen, dass Ihre Routine ein wenig abgenutzt ist. Sie müssen einige Änderungen in der Art und Weise anbringen, auf welche Sie einen Partner suchen. Versuchen Sie neue Plätze zum Verweilen, seien Sie kritisch über das, was Sie von einem neuen Partner erwarten. Auch einige kleinere Änderungen könnten Sie viel erfolgreicher machen.</p>",
                        [GuidanceFields.WORK] : "<p>Diese Karte sagt, dass es jetzt der richtige Zeitpunkt ist zuverlässig und berechenbar zu sein. Versuchen Sie nicht, die Art und Weise wie Sie immer die Dinge getan haben zu ändern, nicht die Traditionen, die Gewohnheiten, die das wahre Fundament Ihrer Arbeit sind, in Frage zu stellen.</p><p>Wenn Sie auf der Suche nach einem Job sind ist es gut zu wissen, dass die Menschen, die Sie treffen, werden nicht auf der Suche sind nach einem Abenteurer oder Innovator. Sie wollen jemanden, der zuverlässig ist, jemanden bei wem sie sicher sind, dass sie sich darauf verlassen können. Verhalten Sie Sich also dementsprechend.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In dieser Zeit Ihres Lebens verwenden Sie eine Menge Energie, um einen Status quo zu bewahren, um die Sache gut organisiert und gut betreut zu haben. Deshalb ist dies nicht der richtige Zeitpunkt, sich zu sehr auf der Spiritualität zu konzentrieren. Während Sie beschäftigt sind mit \"nur\" leben, sind Sie gerade dabei Sich spirituell zu entwickeln. Aber wenn Sie Sich zu viel auf diesem Wachstum konzentrieren, dann kann es unübersichtlich werden, vielleicht sogar überwältigend. Stellen Sie sicher, dass Sie nur positive Ziele nachstreben nur das Sein.</p>",
                    }
                }
            },
            {
                filename: "76",
                numerology : 13,
                en : {
                    name: "Woman of Worlds", subtitle: "Preserver",
                    suit : "Worlds",
                    element : [ "🜃 - earth", "🜄 - water" ],
                    court : "Queen",
                    theme : "taking care of what is precious",    
                    description : "Queen of Worlds talks about paying attention to what is precious to you. Make some space for yourself",
                    questions : [ "What is precious to you?", "Are you taking care of it?", "Sufficiently?", "Are you taking care of yourself (as well)?" ],
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The Queen of Worlds is about being comfortable with your feminine, earthly side, feeling magnanimous, good-natured, stable. It is also about feeling titillating joy. The woman on the card has a gentle, open face, she is tenderly holding the Pentacle, she is surrounded by plants and flowers, by abundance. She is enjoying the pleasures of life and tells you to do so too, to feel good about yourself and enjoy life to the fullest.</p><p>You are or will be radiating a sense of wellbeing and probably people will come to you for advice and comfort.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card indicates joy and fulfillment in all your relationships. Now is the time to spend quality time with your loved ones and relax, knowing you are safe, loved, nurtured. Even if you are more of an independent person, you might consider to open up, let people in. This may also be a time of growth, of meeting new people, and maybe you are even expecting a new addition to the family.</p><p>When you are looking for a partner this is not the time to go hunting. Be yourself, be open to new experiences, show people you meet what you have to give, to offer. You will notice that they will be approaching you, not the other way round.</p>",
                        [GuidanceFields.WORK] : "<p>Now is the time to focus on ‘feminine’ values like creativity, dependability, stamina. It is also a good time to decorate your workplace, to make it your own, a home away from home. This will help you to deliver good work. Don’t expect spectacular successes right now, you will reach your goals in a more subdued manner. Take the time to enjoy life even if it costs you some money, don’t hesitate to spend if it increases your quality of life, this is the right time to do so.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>As far as your spiritual life is concerned, now is the time to focus on yourself, on your beliefs, on your philosophies. You know and are sure that you are on the right track, so do not hesitate. Continue to do what you have been doing for quite some time. It is not important that others condone or agree with you on a spiritual level, the most important thing is that you are happy with who you are, with the decisions you make.</p>",
                    }
                },
                fr : {
                    name : "Femme des Mondes", subtitle: "Donner la vie",
                    suit : "Worlds",
                    element :  ["🜃 - terre", "🜄 - eau" ],
                    court : "Reine",
                    theme : "prendre soin de ce qui est précieux",    
                    description : "La Reine des Mondes, nous parle de prêter attention à ce qui est nous est précieux. Se donner de l'espace.",
                    questions : ["Que vous êtes-t-il précieux?", "Prenez-vous en soin?", "Assez?", "Prenez-vous (également) soin de vous-même?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La \"Reine de Welten \" dit que vous vous sentez à l'aise avec votre côté féminin et terrestre. Vous êtes généreux, de bonne humeur, stable. Elle représente aussi le sentiment de joie pétillante. La femme sur la carte a un visage doux et ouvert et elle tient tendrement le Denier. Elle est entourée de plantes et de fleurs, d'abondance. Elle aime les plaisirs de la vie et vous conseille de faire de même pour vous sentir bien et profiter pleinement de la vie.</p><p>En ce moment vous rayonnez ou rayonnerez une sensation de bien-être et probablement les gens autour de vous viendront à vous pour des conseils et du réconfort.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>This card indicates joy and fulfillment in all your relationships. Now is the time to spend quality time with your loved ones and relax, knowing you are safe, loved, nurtured. Even if you are more of an independent person, you might consider to open up, let people in. This may also be a time of growth, of meeting new people, and maybe you are even expecting a new addition to the family.</p><p>When you are looking for a partner this is not the time to go hunting. Be yourself, be open to new experiences, show people you meet what you have to give, to offer. You will notice that they will be approaching you, not the other way round.</p>",
                        [GuidanceFields.WORK] : "<p>Now is the time to focus on ‘feminine’ values like creativity, dependability, stamina. It is also a good time to decorate your workplace, to make it your own, a home away from home. This will help you to deliver good work. Don’t expect spectacular successes right now, you will reach your goals in a more subdued manner. Take the time to enjoy life even if it costs you some money, don’t hesitate to spend if it increases your quality of life, this is the right time to do so.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>As far as your spiritual life is concerned, now is the time to focus on yourself, on your beliefs, on your philosophies. You know and are sure that you are on the right track, so do not hesitate. Continue to do what you have been doing for quite some time. It is not important that others condone or agree with you on a spiritual level, the most important thing is that you are happy with who you are, with the decisions you make.</p>",
                    }
                },
                sp:{
                    name:"Mujer de Mundos", subtitle: "Dador de vida",
                    suit: "Mundos",
                    element: ["🜃 - tierra", "🜄 - agua" ],
                    court: "Reina",
                    theme: "Cuidando de los valiosos",    
                    description: "La Reina de Mundos se trata de prestar atención a lo que es valioso para ti. Date un poco de espacio",
                    questions: ["¿Qué es valioso para ti?", "¿Cuidas de eso?", "¿Suficiente?", "¿También te cuidas a ti mismo?" ],
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>La Reina de Oros se trata de estar cómodo con su lado femenino, la tierra, sintiendo magnánimo, de buen carácter y estable. También se trata de sentir alegría excitante. La mujer de la tarjeta tiene un rostro amable, abierto, ella está sosteniendo tiernamente el pináculo, ella está rodeada de plantas y flores, por la abundancia. Ella está disfrutando de los placeres de la vida y le dice que lo haga también, para sentirse bien consigo mismo y disfrutar de la vida al máximo. Usted está o va a estar irradiando una sensación de bienestar y, probablemente, la gente va a venir a usted para el asesoramiento y la comodidad.</p>",
                        [GuidanceFields.RELATIONSHIP] : "",
                        [GuidanceFields.WORK] : "",
                        [GuidanceFields.SPIRITUAL] : "",
                    },
                    },
                nl : {
                    name: "Vrouw van Werelden", subtitle: "Levengevende",
                    suit : "Werelden",
                    element : [ "🜃 - aarde", "🜄 - water" ],
                    court : "Koningin",
                    theme : "zorgdragen voor het waardevolle",    
                    description : "De koninging van Werelden gaat over aandacht schenken aan dat wat voor jou waardevol is. Geef jezelf de ruimte",
                    questions : [ "Wat is voor jou waardevol?", "Zorg je daarvoor?", "Genoeg?", "Zorg je (ook) goed voor jezelf?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Koningin van Werelden heeft als onderwerp je goed voelen bij het vrouwelijke, het aardse. De kaart symboliseert ruimhartigheid, evenwicht, stabiliteit, een positieve instelling. De vrouw op de kaart straalt zachtheid en openheid uit, ze wordt omringd door bloemen en planten, door overvloed. Ze geniet van de kleine vreugdes in het leven en ze nodigt je uit om dat ook te gaan doen. Richt je op het positieve in jezelf, bekijk de wereld met een roze bril en dompel je onder in levensvreugde. Op dit moment straal je waarschijnlijk een gevoel van welzijn uit. Daardoor zullen mensen je ook benaderen voor advies en troost. Probeer hen hierin tegemoet te komen, maar natuurlijk niet ten koste van je eigen geluk. Ga je niet onderdompelen in de problemen van anderen, jouw welzijn gaat voor.</p><p>Deze kaart nodigt je ook uit om extra aandacht te besteden aan de aardse aspecten van het leven; kook eens een uitgebreide maaltijd, ga eens aan de slag in je tuin, maak een lange wandeling, geniet hiervan met volle teugen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart wijst op vreugde en voldoening in je relatie. Probeer om nu zoveel mogelijk kwaliteitstijd met je geliefden door te brengen. Ontspan je, wees je ten volle bewust van het feit dat je geliefd bent, veilig, gevoed. Zelfs al ben je een behoorlijk onafhankelijk type en stel je je niet gemakkelijk open, probeer toch om de mensen waar je van houdt net iets meer ruimte te geven dan gewoonlijk, je zal ervan genieten!</p><p>De Koningin van Werelden symboliseert ook groei, het is een goede tijd om nieuwe mensen te leren kennen. Mogelijk komt er zelfs een zwangerschap in je nabije omgeving.</p><p>Als je op zoek bent naar een partner ga dan nu niet jagen, probeer niet om indruk te maken. Wees gewoon jezelf, sta open voor nieuwe ervaringen, toon de mensen wie je bent en wat je te bieden hebt, dat is ruim voldoende! Als je je op deze manier opstelt zal je merken dat mensen vanzelf naar je toe komen, jagen zal niet nodig zijn.</p>",
                        [GuidanceFields.WORK] : "<p>Het is verstandig om nu te gaan focussen op ‘vrouwelijke’ waarden zoals creativiteit, betrouwbaarheid, uithoudingsvermogen. Tegelijk is het ook belangrijk om van je werkplek een vertrouwde, warme plaats te gaan maken. Zet wat foto’s neer, voorwerpen die je koestert en zorg er zo voor dat je je ook op het werk helemaal thuis voelt. Je zal merken dat dit de kwaliteit van je werk een stuk verbetert. Je zal natuurlijk niet ineens spectaculaire successen boeken maar je zal merken dat je je evenwichtiger, harmonischer voelt tijdens het werken.</p><p> Neem ook de tijd om van het leven te genieten, ook al kost dat misschien wat geld. Het is best goed om geld uit te geven als je levenskwaliteit daardoor enorm verbetert en die verbetering is nu heel belangrijk.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Wat je spirituele leven betreft is het op dit moment goed om je te concentreren op jezelf, op wat jij gelooft, jouw filosofie, jouw normen en waarden. Je hebt er momenteel alle vertrouwen in dat je op de goede weg zit, dus ga niet twijfelen, je weet wat je te doen staat, ga gewoon door zoals je bezig bent. Je zit nu al enige tijd op een bepaalde spirituele weg en voorlopig is dit absoluut de juiste voor jou!</p><p>Het maakt niet uit wat anderen daarvan vinden en of ze het ermee eens zijn. Waar het om gaat is dat jij gelukkig bent met wie je bent en met de beslissingen die je neemt, het gaat hier om jou.</p>",
                    }
                },
                de : {
                    name: "Frau der Welten", subtitle: "Lebenschenker",
                    suit : "Welten",
                    element : [ "🜃 - Erde", "🜄 - Wasser" ],
                    court : "Königin",
                    theme : "Sich um das Kostbare kümmern.",    
                    description : "Die Königin der Welten sagt darauf zu achten, was für uns kostbar ist. Geben Sie sich selbst Freiraum.",
                    questions : [ "Was ist Ihnen kostbar?", "Kümmern Sie sich darum?", "Genug?", "Kümmern Sie sich (auch) genug um sich selbst?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Die „Königin der Welten“ sagt, dass Sie sich mit Ihrer weiblichen, irdischen Seite wohlfühlen. Sie sind großzügig, gutmütig und stabil. Sie steht auch für das Gefühl prickelnder Freude. Die Frau auf der Karte hat ein sanftes, offenes Gesicht und sie hält das Pentagramm zärtlich fest. Sie ist umgeben von Pflanzen und Blumen, von Fülle. Sie genießt die Freuden des Lebens und rät Ihnen, das Gleiche zu tun, damit Sie sich gut fühlen und das Leben in vollen Zügen genießen können.</p><p>Im Moment strahlen Sie oder werden Sie ein Gefühl von Wohlbefinden ausstrahlen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Diese Karte zeigt Freude und Erfüllung in all Ihren Beziehungen an. Jetzt ist die Zeit, um Zeit mit Ihren Lieben zu verbringen und zu entspannen im Wissen, dass Sie sicher, geliebt, und genährt sind. Auch wenn Sie ein unabhängiger Person sind, sollten Sie überlegen, sich zu öffnen, damit die Menschen reinkommen können. Dies kann auch eine Zeit des Wachstums sein, zum Treffen von neuen Leute, und vielleicht könnte man auch Zuwachs in der Familie erwarten.</p><p>Wenn Sie nach einem Partner suchen, ist dies nicht die Zeit, auf die Jagd zu gehen. Seien Sie Sich selbst, offen für neue Erfahrungen, zeigen Sie die Menschen, die Sie treffen, was Sie haben und bieten können. Sie werden feststellen, dass sie Sie annähern wollen und nicht umgekehrt.</p>",
                        [GuidanceFields.WORK] : "<p>Jetzt ist die Zeit, um auf \"weiblichen\" Werten wie Kreativität, Zuverlässigkeit, Ausdauer zu konzentrieren. Es ist auch ein guter Zeitpunkt, um Ihren Arbeitsplatz zu schmücken, um es zu einem Zuhause zu machen. Das wird Ihnen helfen, gute Arbeit zu liefern. Erwarten Sie nicht gerade jetzt spektakuläre Erfolge. Sie werden Ihre Ziele auf eine ruhigere Weise erreichen. Nehmen Sie sich Zeit, um das Leben zu genießen, auch wenn es Sie was Geld kostet. Zögern Sie nicht Geld auszugeben, wenn es Ihre Lebensqualität erhöht, es ist der richtige Zeitpunkt, dies zu tun.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>So weit es Ihr spirituelles Leben betrifft, ist jetzt die Zeit, sich auf sich selbst zu konzentrieren, auf Ihren Überzeugungen, auf Ihren Philosophien. Sie wissen, und sind sicher, dass Sie auf dem richtigen Weg sind, so zögern Sie nicht, weiterhin das zu tun, was Sie schon seit einiger Zeit getan haben. Es ist nicht wichtig, dass andere dulden oder einstimmen mit Ihnen auf einer spirituellen Ebene, es ist das Wichtigste, dass Sie mit wem Sie sind, mit Ihren Entscheidungen, die Sie machen, glücklich sind.</p>",
                    }
                }
            },
            {
                filename: "77",
                numerology : 14,
                en : {
                    name: "Man of Worlds", subtitle: "Achiever",
                    suit : "Worlds",
                    element : [ "🜃 - earth", "🜂 - fire" ],
                    court : "King",
                    theme : "effectively acting from within",    
                    description : "King of  Worlds talks about effectively acting from within with what is precious to you. Go create.",
                    questions : [ "Do you know what is precious to you?", "Are you acting towards it?", "Do you think it is all up to you?", "Can you delegate?" ] ,
                    
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>The King of Worlds focuses on the more “masculine” values like tradition, authority, sense of reality, durability, the pursuit of possessions. It’s not only about words and feelings but also about facts and accomplishments, about being firmly rooted in everyday reality. Actions speak louder than words. </p><p>The dark side of this card is about being too focused on passion and the physical side of things.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In relationships this card is about loyalty and friendship. If you are currently engaged in a loving relationship things may be very comfortable, at ease. But maybe that is not enough for you, you may be hungry for more passion, deeper intimacy. It is up to you to make this clear to your partner, to be open about what you are longing for. </p><p>This card may also indicate that you are feeling jealous. If this is the case, talk about it, don’t leave it up to your partner to guess what is going on in your heart and head.</p><p>If you are single, this is the perfect time to meet someone who will be very passionate about you. Don’t try to accommodate this person, just be yourself. Who you are is the reason for their passion, not who you might become.</p>",
                        [GuidanceFields.WORK] : "<p>At work you may be striving for security, steadiness. These are worthy goals but be careful not to become too rigid, too set in your ways. You may experience your superiors to be very interested in you and your work, but maybe sometimes they’re a bit quick in passing judgment. Make sure your work can withstand criticism at all times. Be realistic in knowing what you can and cannot accomplish.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may have been focused on the more material side of life for quite a while. You probably didn’t take the time to think about spirituality and what it might mean to you. Now is the time to make an effort and realize that you too can be a spiritual being. Look at this as just another project, gather knowledge, study different ways of experiencing spirituality and choose what feels the most natural for you.</p>",
                    }
                },
                fr : {
                    name : "L'homme des Mondes", subtitle: "Entrepreneur",
                    suit : "Worlds",
                    element :  ["🜃 - terre", "🜂 - feu" ],
                    court : "Roi",
                    theme : "Agir activement à partir de son être propre",    
                    description : "Le Roi des Mondes, nous parle d’agir à partir de son être propre, avec ce qui lui porte de la valeur. Lui donner forme.",
                    questions : ["Savez-vous ce qui porte de la valeur pour vous?", "Agissez-vous en conséquence?", "Pensez-vous que vous devez tout faire vous-même?", "Pouvez-vous également déléguer quelque chose à quelqu'un d'autre?" ],

                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Le \"Roi de Welten \" se concentre sur des valeurs plus \"masculines\" comme la tradition, l'autorité, le sens des réalités, la durabilité, la recherche de la possession. Cette carte n'est pas seulement synonyme de mots et de sentiments, mais aussi de faits et de réalisations. Il s'agit d'être fermement ancré dans la vie quotidienne. Les actes sont plus éloquents que les mots.</p><p>Le côté sombre de cette carte est le danger d'être trop concentré sur la passion et le côté physique des choses.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In relationships this card is about loyalty and friendship. If you are currently engaged in a loving relationship things may be very comfortable, at ease. But maybe that is not enough for you, you may be hungry for more passion, deeper intimacy. It is up to you to make this clear to your partner, to be open about what you are longing for. </p><p>This card may also indicate that you are feeling jealous. If this is the case, talk about it, don’t leave it up to your partner to guess what is going on in your heart and head.</p><p>If you are single, this is the perfect time to meet someone who will be very passionate about you. Don’t try to accommodate this person, just be yourself. Who you are is the reason for their passion, not who you might become.</p>",
                        [GuidanceFields.WORK] : "<p>At work you may be striving for security, steadiness. These are worthy goals but be careful not to become too rigid, too set in your ways. You may experience your superiors to be very interested in you and your work, but maybe sometimes they’re a bit quick in passing judgment. Make sure your work can withstand criticism at all times. Be realistic in knowing what you can and cannot accomplish.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>You may have been focused on the more material side of life for quite a while. You probably didn’t take the time to think about spirituality and what it might mean to you. Now is the time to make an effort and realize that you too can be a spiritual being. Look at this as just another project, gather knowledge, study different ways of experiencing spirituality and choose what feels the most natural for you.</p>",
                    }
                },
                sp:{
                    name: "Hombre de Mundos", subtitle: "Empresario",
                    suit: "Mundos",
                    element: ["🜃 - tierra", "🜂 - fuego" ],
                    court: "Rey",
                    theme: "Actuar activamente desde tu propio ser",    
                    description: "El Rey de Mundos es acerca de actuar realmente desde tu propio ser, con lo que es valioso para ti. Dale forma",
                    questions: ["¿Sabes lo que es valioso para ti?", "¿Actúas en consecuencia?", "¿Crees que tienes que hacerlo todo por ti mismo?", "¿Puedes dejar algo a otra persona?" ],
                    
                    cardoftheday: {
                            [GuidanceFields.DEFAULT] : "<p>El Rey de Oros se centra en los valores más \"masculinos\" como tradición, la autoridad, el sentido de la realidad, la durabilidad, la búsqueda de posesiones. No es sólo acerca de las palabras y sentimientos, sino también sobre hechos y logros, acerca de ser firmemente enraizada en la realidad cotidiana. Las acciones hablan más que las palabras. El lado oscuro de esta tarjeta es acerca de ser demasiado centrado en la pasión y el aspecto físico de las cosas.</p>",
                            [GuidanceFields.RELATIONSHIP] : "",
                            [GuidanceFields.WORK] : "",
                            [GuidanceFields.SPIRITUAL] : "",
                        },
                    },
                nl : {
                    name: "Man van Werelden", subtitle: "Ondernemer",
                    suit : "Werelden",
                    element : [ "🜃 - aarde", "🜂 - vuur" ],
                    court : "Koning",
                    theme : "actief handelen vanuit je eigen zijn",    
                    description : "De koning van Werelden gaat over daadwerkelijk handelen vanuit je eigen zijn, met dat wat voor jou waardevol is. Geef het vorm.",
                    questions : [ "Weet jij wat voor jou waardevol is?", "Handel je daar ook naar?", "Denk jij alles zelf te moeten doen?", "Kun je ook iets aan een ander overlaten?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>De Koning van Werelden richt zich op de meer ‘mannelijke’ waarden zoals traditie, autoriteit, realiteitszin, duurzaamheid, het verwerven van bezittingen. Deze kaart heeft het niet enkel over woorden en gevoelens, maar ook over feiten en de dingen die je bereikt hebt. De Koning van Werelden staat met beide voeten stevig in de realiteit van alledag en hij zegt dat daden luider praten dan woorden. Mensen luisteren niet zozeer naar wat je zegt, ze kijken eerder naar wat je doet.</p><p>Maar let op, er zit hier een addertje onder het gras: als je te ver doorslaat in de richting die deze kaart je aanwijst dan loop je het risico dat je verdwaalt in je passies, je hartstochten. Dan neig je naar het obsessieve en heb je enkel nog oog voor de fysieke kant van de dingen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wat relaties betreft focust deze kaart vooral op trouw en vriendschap. Als je een liefdesrelatie hebt dan heb je op dit moment waarschijnlijk een heel comfortabel gevoel, ben je ontspannen en rustig. Toch is het best mogelijk dat dit niet voldoende is voor jou, dat je verlangt, zelfs hongert naar meer passie en diepere intimiteit. Je wil je relatie ook lichamelijk ten volle beleven. Het is nu aan jou om dit duidelijk te maken aan je partner, om open te zijn over je verlangens en behoeftes.</p><p>Als je deze kaart trekt dan kan het er ook op wijzen dat je jaloers bent. Als dit het geval is, praat er dan over, laat het niet over aan je partner om te gokken wat er aan de hand is in je hoofd en je hart.</p><p>Als je alleen bent dan is het nu de perfecte tijd om iemand te ontmoeten die vol hartstocht naar je toe komt. Probeer je niet aan te passen aan die persoon maar wees gewoon jezelf. Het is net omdat jij ‘gewoon’ jezelf bent dat deze persoon zo passioneel is. Als je probeert te veranderen zou die passie zomaar kunnen verdwijnen. Ook al vind jij jezelf maar gewoontjes, voor die ander ben je blijkbaar heel speciaal!</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk streef je op dit moment waarschijnlijk naar zekerheid, vastigheid. Dat zijn absoluut zinvolle doelen maar let er toch op dat je niet te streng en te strak wordt, dat je niet gaat vastzitten in je gewoontes en routines. Werken is iets dynamisch, elke dag hoor je je een beetje aan te passen aan veranderende omstandigheden, mee te groeien met behoeftes en eisen.</p><p>Waarschijnlijk zijn je meerderen behoorlijk geïnteresseerd in wat je doet en hoe je het doet, maar het is mogelijk dat ze af en toe een beetje te snel oordelen. Het is dus aan jou om ervoor te zorgen dat je werk niet bekritiseerd kan worden, dat alles altijd helemaal in orde is. Wees hierbij wel realistisch bij het inschatten van wat je wel en niet aankan.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Waarschijnlijk was je in de voorbije periode eerder gefocust op de materiele kant van het leven, dit kan al een tijdje het geval zijn. Misschien heb je gewoon niet de tijd genomen om na te denken over spiritualiteit en wat dit zou kunnen betekenen voor jou. Breng daar nu verandering in, doe wat moeite, verdiep je in de meer spirituele, ontastbare kant van het leven. Je zal merken dat je diep vanbinnen echt wel een spirituele kern hebt. Beschouw dit gewoon als een van je projecten: vergaar kennis en informatie, bestudeer een aantal manieren om spiritualiteit te beleven en kies dan die manier die voor jou het meest natuurlijk aanvoelt. Enkel jij weet wat spiritualiteit voor jou betekent.</p>",
                    }
                },
                de : {
                    name: "Mann der Welten", subtitle: "Entrepreneur",
                    suit : "Welten",
                    element : [ "🜃 - Erde", "🜂 - Feuer" ],
                    court : "König",
                    theme : "Aktiv handeln aus dem eigenen Wesen heraus.",    
                    description : "Der König der Welten spricht darüber, aus seinem eigenen Wesen heraus zu handeln, mit dem, was ihm Wert bringt. Ihm Form zu geben.",
                    questions : [ "Wissen Sie, was für Sie von Wert ist?", "Handeln Sie auch entsprechend?", "Denken Sie, dass Sie alles selbst machen müssen?", "Können Sie auch etwas an jemand anderen delegieren?" ], 
                    cardoftheday: {
                        [GuidanceFields.DEFAULT] : "<p>Der „König der Welten“ konzentriert sich auf eher \"männliche\" Werte wie Tradition, Autorität, Realitätssinn, Haltbarkeit, das Streben nach Besitz. Diese Karte steht nicht nur für Worte und Gefühle, sondern auch für Fakten und Errungenschaften. Es geht darum, fest im Alltag verankert zu sein. Taten sagen mehr als Worte.</p><p>Die dunkle Seite dieser Karte ist die Gefahr, zu stark auf Leidenschaft und auf die physische Seite der Dinge konzentriert zu sein.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In Beziehungen handelt diese Karte über Treue und Freundschaft. Wenn Sie derzeit in einer liebevollen Beziehung sind, können die Dinge sehr komfortabel, ruhig sein. Aber vielleicht ist das nicht genug für Sie und sind Sie hungrig nach mehr Leidenschaft, tieferer Intimität. Es liegt an Ihnen Ihrem Partner gegenüber dieses klar zu machen. Seien Sie offen und sagen Sie was Sie verlangen.</p><p>Diese Karte kann auch bedeuten, dass Sie eifersüchtig sind. Wenn dies der Fall ist müssen Sie darüber reden. Lassen Sie Ihren Partner nicht erraten, was los ist in Ihrem Herzen und Kopf.</p><p>Wenn Sie alleine sind, ist dies die perfekte Zeit, um jemanden zu treffen, der sehr leidenschaftlich über Sie denkt. Versuchen Sie nicht, diese Person zu überzeugen, seien Sie einfach Sich selbst. Wer Sie sind, ist genau der Grund für Ihre/seine Leidenschaft, nicht, wer Sie werden könnten.</p>",
                        [GuidanceFields.WORK] : "<p>Bei der Arbeit können Sie streben nach Sicherheit, Beständigkeit. Dies sind würdige Ziele, aber seien Sie vorsichtig, nicht zu starr zu werden. Sie können bemerken, dass Ihre Vorgesetzten sehr interessiert an Ihnen und Ihrer Arbeit sind, aber vielleicht sind sie manchmal ein bisschen schnell mit Ihrem Urteil. Stellen Sie sicher, dass Ihre Arbeit jederzeit Kritik widerstehen kann. Seien Sie realistisch zu wissen, was Sie erreichen und nicht erreichen können.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Sie können Sich für eine längere Weile zu viel auf der materiellen Seite des Lebens konzentriert haben. Sie haben wahrscheinlich nicht die Zeit genommen über Spiritualität nachzudenken und Sich zu realisieren was es für Sie bedeutet. Jetzt ist die Zeit Sich zu bemühen und anzuerkennen, dass man auch ein spirituelles Wesen ist. Schauen Sie sich dieses als nur ein weiteres Projekt an, sammeln Sie Wissen, lernen Sie verschiedene Arten des Erlebens der Spiritualität und wählen Sie, was für Sie am meisten selbstverständlich fühlt.</p>",
                    }
                }
            }
        ]
    }
}

_deckCards[DeckTypes.TAROT_MAYOR_ARCANA] = {
    id : DeckTypes.TAROT_MAYOR_ARCANA,
    cards: _deckCards[DeckTypes.TAROT].cards.slice(0, 22)
}

_deckCards[DeckTypes.LENORMAND] = {
    id : DeckTypes.LENORMAND,
    cards: _deckCards[DeckTypes.EXTENDED_LENORMAND].cards.slice(0, 36)
}

export const DeckCards = _deckCards;