import './navigation.scss';
import logo from '../../../assets/images/yourtarot-logo.png';
import directContact from '../../../assets/images/directContact.png';
import React, { useEffect } from 'react';
import WhenAuth from '../../parts/auth/whenAuth';

import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Toolbar,
  BottomNavigation,
  BottomNavigationAction,
  AppBar,
  Typography,
  Icon
} from '@material-ui/core';

import {
  NavigateNext, NavigateBefore,
  Home,
  CropPortrait as Card,
  Collections as Layers,
  Menu,
  AnnouncementTwoTone as New,
  QuestionAnswer as Messages,
  DoneAllOutlined as DoneAll,
  DoneOutlined as Done
} from '@material-ui/icons';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { ReaderSmall } from '../../parts/reader/small';
import { useSelector } from "react-redux";

const mapState = state => ({
    hasLoggedIn: !!(state.auth && state.auth.activationCode && state.auth.mobilePhone),
    isLoggedIn : !!(state.auth && state.auth.accessToken)
});

export const Navigation = () => {
  const [state, setState] = React.useState({
    drawerVisible : false
  });
  const { hasLoggedIn, isLoggedIn } = useSelector(mapState);
  const history = useHistory();

  let location = useLocation();
  let showToolbar = location.pathname.split("/")[1] !== "store";
  if (!showToolbar) return null;

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ drawerVisible: open });
  };

  function ListItemLink(props) {
    const { icon, primary, to } = props;
  
    const renderLink = React.useMemo(
      () => React.forwardRef((itemProps, ref) => <Link to={{ pathname: to, key: to }} ref={ref} {...itemProps} />),
      [to],
    );
  
    return (
      <ListItem onClick={toggleDrawer(false)} button component={renderLink} className="listItem">
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>      
    );
  }

  function ToolbarLink(props) {
    const { icon, to } = props;
  
    const renderLink = React.useMemo(
      () => React.forwardRef((itemProps, ref) => <Link to={{ pathname: to, key: to }} ref={ref} {...itemProps} />),
      [to],
    );
  
    return (
      <IconButton onClick={toggleDrawer(false)} component={renderLink}>
        {icon}        
      </IconButton>      
    );
  }

  const drawerContent = <>
      <div className="content">
        <Toolbar className="toolbar"> 
          <div className="logo">
            <img src={logo} alt="YourTarot" title="YourTarot" />              
          </div>
        </Toolbar>  
        
        <Toolbar className="toolbar">
          <ToolbarLink to="/" icon={<Home />} />
          <ToolbarLink to="/spread/cardoftheday/TAROT" icon={<Card />} />            
          <ToolbarLink to="/spreads" icon={<Layers />} />
        </Toolbar>

        <div className="readerBar">
          <IconButton><NavigateBefore /></IconButton>
          <div className="slider">
            <Link to="/reader/3a1a3269-f857-4223-8fdc-96faa620a917" >
              <ReaderSmall consultant={{ name: "Simone", profileImage: "https://24uploads.blob.core.windows.net/profile/ca1ff2db-0ca5-43a5-9e2b-1c653742cc68.jpg" }} />
            </Link>
          </div>
          <IconButton><NavigateNext /></IconButton>
        </div>
        
        <List>
          <ListItemLink to="/readers" primary="Nieuw gesprek" icon={<New />} />
          <WhenAuth>
              <>
                <ListItemLink to="/conversations" primary="All Messages" icon={<Messages />} />
              </>
              <ListItemLink to="/signup" primary="Sign up" icon={<New />} />                                  
          </WhenAuth>
        </List>
      </div>
      <div className="background"></div>
    </>

  return (
    <>
    <AppBar>
      <Toolbar>
          <tablet className="hide-on-desktop">
            <IconButton onClick={toggleDrawer(true)} >
              <Menu />
            </IconButton>    
          </tablet>

          <Typography variant="h6" noWrap>
            Responsive drawer {hasLoggedIn && (isLoggedIn ? <DoneAll color={'secondary'} /> : <Done />)}
          </Typography>


      </Toolbar>
    </AppBar>

    <nav>
        <desktop>
          <Drawer className="drawerMenu" variant="permanent">
            {drawerContent}
          </Drawer>
        </desktop>

        <tablet className="hide-on-desktop">          
          <Drawer
            variant="temporary"
            anchor="left"
            className="drawerMenu"
            open={state.drawerVisible}
            onClose={toggleDrawer(false)}          
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawerContent}
          </Drawer>
        </tablet>
        
        <phone>
            <BottomNavigation
              value={state.menuItem}
              onChange={(event, newValue) => {
                setState({menuItem : newValue});
                history.push(newValue);
              }}
              
              showLabels
            >
              <BottomNavigationAction label="Home" value="/" icon={<Home />} />
              <BottomNavigationAction label="Dagkaart" value="/spread/cardoftheday/TAROT" icon={<Card />} />
              <BottomNavigationAction label="Nieuw gesprek" value="/readers" icon={<New />} />
              <BottomNavigationAction label="Berichten" value="/conversations" icon={<Messages />} />
            </BottomNavigation>
        </phone>
    </nav>
    </>
  );
};