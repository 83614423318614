import './conversations.scss';
import React, { useState, useRef } from 'react';
import { getConsultant } from "../../../services/consultants";

import { useParams } from 'react-router-dom';

import { IconButton, Paper, InputBase, Divider } from '@material-ui/core';
import { SendTwoTone as Send } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { startCall } from '../../../actions/rtcActions';
import { sendDraft as sendDraftAct, sendInvite } from '../../../actions/chatActions';

import { Spreads, Decks } from '../../../constants'


export const ConversationDetailsScreen = () => {
    const { conversationId } = useParams();
    const [draftText, setDraftText] = useState('');
    const messagesDivRef = useRef(null);

    const theme = useTheme();
    
    const { self, profile, chat } = useSelector(state => ({
      self : state.chats.self,
      profile : state.profile,
      chat : state.chats.conversations[conversationId] }), shallowEqual);
    const dispatch = useDispatch();
   
   
    /* Run this side effect everytime the chat changes */
    React.useEffect(() => {
      messagesDivRef.current.scrollIntoView({ block : 'end' });
    }, [ chat ]);
    

    const call = (e, conversationId) => {
      dispatch(startCall(conversationId, false));
      e.preventDefault();
    }

    const videoCall = (e, conversationId) => {
        dispatch(startCall(conversationId, true));
      e.preventDefault();
    }

    const sendDraft = () => {
      let sd = sendDraftAct (conversationId, {                            
        type: "text",
        conversationId : conversationId,
        textMessage: draftText
      });

      dispatch( sd );
      setDraftText('');
    }

    const handleChange = (e, fn) => {
      const { value } = e.target;
      fn( value );
    };

    const renderTextMessage = (msg) => {
      return <li key={`${msg.identifier}-${msg.raised}`} className={`side-${msg.side}`}>
        <div style={{
              color : theme.palette.primary.contrastText,
              background : msg.side === "from" ? theme.palette.primary.light : theme.palette.primary.dark
          }}>

          {msg.message}

          <span className={`tri-${msg.side}`} style={{
              borderRightColor:msg.side === "from" ? theme.palette.primary.light : theme.palette.primary.dark,
              borderLeftColor: msg.side === "from" ? theme.palette.primary.light : theme.palette.primary.dark,
          }} />
        </div>
      </li>
    }

    const renderSpreadMessage = (msg) => {
      let spread = Spreads[msg.spreadId];
      let deck = Decks[msg.deckId];
      let cards = msg.cards;

      let edge = spread.cardWidth * 0.03;

      let images = [];
      let pos = spread.positions.map((x, i) => ({...x, idx : i}));
      pos.sort((a, b) => a.z - b.z);

      pos.forEach((p) => {
          let j = p.idx;
          images.push(<rect
              key={`rect-${j}`}
              style={{ pointerEvents : 'none', transformOrigin: `${p.centerX}px ${p.centerY}px`, transform: `rotate(${p.rotation}rad)` }}
              rx="15"
              ry="15"
              x={p.centerX - spread.cardWidth / 2 - edge}
              y={p.centerY - spread.cardHeight / 2 - edge}
              width={spread.cardWidth + edge + edge}
              height={spread.cardHeight + edge + edge}
              fill="white"
              stroke="white"
          />)

          images.push(<image
              key={`cardpos-${j}`}
              style={{ pointerEvents : 'none', transformOrigin: `${p.centerX}px ${p.centerY}px`, transform: `rotate(${p.rotation}rad)` }}
              preserveAspectRatio="xMidYMid slice"
              xlinkHref={`${deck.directory}${cards[j]}.${deck.extension}`}
              x={p.centerX - spread.cardWidth / 2}
              y={p.centerY - spread.cardHeight / 2}
              width={spread.cardWidth}
              height={spread.cardHeight} />);
      });
      
      return <li key={`${msg.identifier}-${msg.raised}`} className={`side-${msg.side}`}>
        <div style={{
              color : theme.palette.primary.contrastText,
              background : msg.side === "from" ? theme.palette.primary.light : theme.palette.primary.dark
          }}>

          <figure>
              <svg viewBox={`-${edge} -${edge} ${spread.width + edge + edge}  ${spread.height + edge + edge}`}>
                  {images}
              </svg>
          </figure>
          <span>{msg.message}</span>

          <span className={`tri-${msg.side}`} style={{
              borderRightColor:msg.side === "from" ? theme.palette.primary.light : theme.palette.primary.dark,
              borderLeftColor: msg.side === "from" ? theme.palette.primary.light : theme.palette.primary.dark,
          }} />
        </div>
      </li>
    }

    //todo make smarter:
    const renderMessage = (msg) => {
      switch (msg.messageType.toLowerCase()) {
        case "text" : return renderTextMessage(msg);
        case "spread" : return renderSpreadMessage(msg);
      }
      return null;
    }

    const renderDraft = (fromName, toName) => {
      return <div className="draft">
        <Paper className={"input"}>
            <InputBase className="inputbox"
                value={draftText}
                multiline={true}
                onChange={(e) => handleChange( e, setDraftText )}
            />
            <Divider />
            <IconButton color="primary" onClick={sendDraft}><Send /></IconButton>                    
        </Paper>
      </div>
    }
    
    let fromPersonKey = Object.keys(chat.audience).filter(k => k != self)[0];
    let fromName = fromPersonKey ? chat.audience[fromPersonKey].displayName : "";
    let toName = profile.displayName;

    return <div className="conversationdetails clearfix">
        <div className="messages-list">
          <ul>
            {chat.messages.map(msg => renderMessage(msg))}          
          </ul>
          <div className="spacer" ref={messagesDivRef}/>      
        </div>
        {renderDraft(fromName, toName)}
    </div>
}    