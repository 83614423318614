import './activeCall.scss';

import React, { Component } from 'react';
import { useState, useEffect, createRef } from 'react';
import useDimensions from "react-cool-dimensions";

import { sendWebRTCSdp, sendWebRTCCandidate, sendWebRTCHangup, answerCall } from '../../../actions/rtcActions'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import { CallEndTwoTone as HangUpIcon } from '@material-ui/icons';

import { red } from '@material-ui/core/colors';

import { Decks } from '../../../constants/decks';
import { Table } from '../../parts/spread/Table';

function setMediaBitrates(sdp, videoRate = 250, audioRate = 50) {
    let f = navigator.userAgent.search("Firefox");
    let modifier = 'AS';
    // if (f > -1) {
    //     videoRate = (videoRate >>> 0) * 1000;
    //     audioRate = (audioRate >>> 0) * 1000;
    //     modifier = 'TIAS';
    // }

    //find the correct m lines (video and audio)
    var lines = sdp.sdp.split("\n");
    let i = 0;
    while (i < lines.length) {
        //if we find an m-line
        if (lines[i].indexOf("m=") === 0) {
            let video = lines[i].indexOf("m=video") === 0;
            let audio = lines[i].indexOf("m=audio") === 0;

            if (video || audio) {
                while(i < lines.length && (lines[i].indexOf("i=") === 0 || lines[i].indexOf("c=") === 0)) {
                    i++;
                }

                // If we're on a b line, replace it
                if (i < lines.length && lines[i].indexOf("b") === 0) {
                    lines[i] = `b=${modifier}:${video ? videoRate : audioRate}`;
                } else if (i == lines.length) {
                    lines.push(`b=${modifier}:${video ? videoRate : audioRate}`);
                    i++;
                } else {
                    i++;
                    lines.splice(i, 0, `b=${modifier}:${video ? videoRate : audioRate}`);
                }
            }
        }
        i++;
    }
    
    let s = lines.join("\n");    
    return { type : sdp.type, sdp : s };
}   

const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[700]),
      backgroundColor: red[700],
      '&:hover': {
        backgroundColor: red[900],
      },
    },
  }))(Button);

const mapState = state => ({
    activeConversationId : state.rtc.activeConversation?.id,
    initiator : state.rtc.activeConversation?.initiator,
    useVideo : state.rtc.activeConversation?.useVideo,
    media : state.rtc.media,
    peers : state.rtc.activeConversation?.peers
  });


const pc_config = {
    iceServers : [
        {
            urls : 'stun:stun.l.google.com:19302',                
        },
        {
            urls : 'turn:turn.24readings.com:3478',
            credential: 'password',
            username: 'readings'
        }
    ]
};

//globallt define 1 peerconnection (1 conversation at 1 moment in time (for now))
let peerConnection = null;
let remoteRtcProcessed = 0;

const PeerLink = (props) => {
    const { activeConversationId, initiator, useVideo, media, peers } = useSelector(mapState);
    const dispatch = useDispatch();

    //run this effect everytime the activeConverstaion changes
    useEffect(() => {
        //active converstion has changed
        if (activeConversationId) {
            //set up a peer connection                        
            let pc = peerConnection = new RTCPeerConnection(pc_config);
            pc.theCurrentConnection = activeConversationId;

            pc.onicecandidate = (e) => {                
                if (e.candidate?.candidate) {
                    dispatch(sendWebRTCCandidate(activeConversationId, e.candidate));
                }
            };

            pc.oniceconnectionstatechange = (e) => {
                if (e.target.iceConnectionState.toLowerCase() === "connected") {
                    //do we need this??
                    console.log("CONNECTED");
                }
            };

            pc.ontrack = (e) => {
                if (props.remoteVideoRef?.current) {
                    let vd = props.remoteVideoRef.current;
                    vd.srcObject = e.streams[0];
                }                
            };

            if (initiator) {
                //we set up the channel:
                let dataChannel = pc.createDataChannel("cardEvt", {
                    ordered: true,
                    maxPacketLifeTime: 3000, // in milliseconds
                });
                pc.myDataConnecton = dataChannel;

                dataChannel.onmessage = (event) => {
                    if (event.data.indexOf("CHOOSESPREAD:") > -1) {
                        props.remoteSpreadChosen(event.data.substring(13));
                        return;
                    }
                    if (event.data.indexOf("SHUFFLE") > -1) {
                        props.remoteCardShuffle();
                        return;
                    }
                    if (event.data.indexOf("ZOOM") > -1) {   
                        props.remoteCardZoom(event.data.substring(5));
                        return;
                    }
    
                    props.remoteCardChosen(JSON.parse(event.data));                
                };
            }

            return () => {
                //stop all stuff
                //senders are stopped in the media dependant useEffect
                pc.getReceivers().forEach(s => s.track && s.track.stop());
                pc.close(); 

                props.remoteSpreadChosen(null);
                peerConnection = null;
                remoteRtcProcessed = 0;
            }
        }        
    }, [ activeConversationId ]);

    // Run this side effect everytime the overview changes 
    //most should be run only once
    useEffect(() => {
        let stream = media?.stream;

        if (stream) {
            //also set the streams onto the peerconnection tracks
            if (peerConnection) {
                let pc = peerConnection;
                stream.getTracks().forEach(track => {
                    pc.addTrack(track, stream);
                });

                if (initiator) {
                    pc
                        .createOffer({ offerToReceiveVideo : useVideo ? 1 : 0 })
                        .then(sdp => {
                            pc.setLocalDescription(sdp);

                            let newSdp = setMediaBitrates(sdp);
                            dispatch(sendWebRTCSdp(activeConversationId, newSdp));
                        })
                        .catch(e => {});
                }
            }

            return () => {
                stream.getTracks().forEach(track => track.stop());
            }
        }
    }, [ media ]);

    useEffect(() => {
        if (peers) {
            //presume only 1 peer (for now)
            var thePeers = Object.keys(peers);
            for (let p of thePeers) {
                let rtcObj = peers[p];
                let pc = peerConnection;

                if (remoteRtcProcessed === 0) {
                    remoteRtcProcessed = 1;

                    pc.setRemoteDescription(new RTCSessionDescription(rtcObj.sdp));
                    for (let candidate of rtcObj.candidates) {
                        pc.addIceCandidate(new RTCIceCandidate(candidate));
                    }
                    
                    if (!initiator) {
                        pc
                            .createAnswer({ offerToReceiveVideo : useVideo ? 1 : 0 })
                            .then(sdp => {
                                pc.setLocalDescription(sdp);

                                let newSdp = setMediaBitrates(sdp);
                                dispatch(sendWebRTCSdp(activeConversationId, newSdp));                            
                            })
                            .catch(e => { console.log(e) });
                        
                        // when we receive a channel:
                        pc.ondatachannel = (evt) => {
                            let dataChannel = pc.myDataConnecton = evt.channel;                
                            dataChannel.onmessage = (event) => {
                                if (event.data.indexOf("CHOOSESPREAD:") > -1) {
                                    props.remoteSpreadChosen(event.data.substring(13));
                                    return;
                                }
                                if (event.data.indexOf("SHUFFLE") > -1) {
                                    props.remoteCardShuffle();
                                    return;
                                }
                                if (event.data.indexOf("ZOOM") > -1) {   
                                    props.remoteCardZoom(event.data.substring(5));
                                    return;
                                }
                
                                props.remoteCardChosen(JSON.parse(event.data));                
                            };        
                        };
                    }
                } else {                    
                    if (rtcObj.lastMsg === 'SDP') {
                        pc.setRemoteDescription(new RTCSessionDescription(rtcObj.sdp));
                    } else {
                        pc.addIceCandidate(new RTCIceCandidate(rtcObj.lastMsg));
                    }
                }
            }
            
            return () => {
                
            }
        }
    }, [ peers ]);

    useEffect(() => {
        if (props.userSelected?.length) {
            //get the last card
            let channel = peerConnection.myDataConnecton;
            let card = props.userSelected[props.userSelected.length - 1];
            
            let msg = JSON.stringify(card);
            channel.send(msg);
        }
    }, [ props.userSelected ])

    return null;
}

const RealTimeTable = (props) => {
    const decktype = "TAROT";
    const deckKey = "vibrant";

    const { ref, width, height } = useDimensions();

    return <div className="tableContainer" ref={ref}>
        {props.spread &&
        <Table
            deckType={decktype}                
            deck={deckKey}
            spread={props.spread}

            rnd={props.r}
            cardPositions={props.cardPositions}
            enlarged={props.enlarged}
            onSelectCard={props.selectCard}

            width={width}
            height={height}
            showReaderAids={false}                
        />}
    </div>
}


class ActiveCall extends Component {
    constructor(props) {
        super(props);

        this.localVideoRef = createRef();
        this.remoteVideoRef = createRef();
        this.state = {};
    }

    remoteSpreadChosen = (spread) => {
        this.setState({ spread : spread, r: Math.random(), cardPositions : [], enlarged : {}, userSelected : [] });
    }

    remoteCardChosen = (data) => {
        let newPositions = this.state.cardPositions.concat([data]);
        this.setState({ cardPositions : newPositions });
    }

    remoteCardZoom = (cardNr) => {
        let enlarged = { ...this.state.enlarged };
        enlarged[cardNr] = !enlarged[cardNr];
        this.setState({ enlarged : enlarged });
    }

    remoteCardShuffle = () => {
        this.setState({ cardPositions : [], r: Math.random(), enlarged : {}, userSelected : [] });
    }

    hangupClicked() {
        this.props.sendWebRTCHangup(this.props.activeConversation?.id);
    }

    localSelectedCard(card) {
        let userSelected = this.state.userSelected || [];
        
        //also local
        let newPositions = this.state.cardPositions.concat([card]);
        this.setState({ cardPositions : newPositions, userSelected : userSelected.concat([card]) });        
    }

    render() {
        let props = this.props;
        
        return <div id="activeCall" className={props.activeConversation?.id ? 'active' : ('')} >
            
            <PeerLink
                localVideoRef={this.localVideoRef}
                remoteVideoRef={this.remoteVideoRef}

                remoteSpreadChosen={(spread) => this.remoteSpreadChosen(spread)}
                remoteCardChosen={(card) => this.remoteCardChosen(card)}
                remoteCardZoom={(cardNr) => this.remoteCardZoom(cardNr)}
                remoteCardShuffle={() => this.remoteCardShuffle()}              

                userSelected={this.state.userSelected}                
            />

            <RealTimeTable {...this.state} selectCard={(c) => this.localSelectedCard(c)} />

            <video className="localvideo" ref={this.localVideoRef} autoPlay={true} playsInline={true} muted={true}></video>
            <video className="remotevideo secondary" ref={this.remoteVideoRef} autoPlay={true} playsInline={true}></video>

            <div className="controls">
                <ColorButton
                    onClick={(e) => this.hangupClicked()}
                    variant="contained"
                    startIcon={<HangUpIcon />}
                >Hang up</ColorButton>
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        activeConversation : state.rtc.activeConversation
    };  
};

const mapDispatchToProps = dispatch => {
    return {
        sendWebRTCHangup : (conversationId) => {
            dispatch( sendWebRTCHangup(conversationId) )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)( ActiveCall );