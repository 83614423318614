import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';

import configureStore from './configureStore'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

const {store, persistor} = configureStore();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />    
            </PersistGate>
        </Provider>
    </React.StrictMode>
, document.getElementById('root'));