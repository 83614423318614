import { ActionTypes } from '../actions/profileActions';

export default function profileReducer(state = { }, action) {
    if (action.domain !== ActionTypes.DOMAIN)
        return state;

    switch (action.type) {
        case ActionTypes.SAVE_PROFILE:
            return action.profile;

        default:
            return state;
    }   
}